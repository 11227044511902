import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import MoneyInput from '@app/components/common/MoneyInput';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  settingsErrorsSelector,
  selectCurrentSettingsData,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  ITaxSetting,
  ITaxTypeData,
  ITableTax,
  ITaxesSettings,
  SettingsSectionId,
  actionCreatorsSettingsChangeData,
  actionCreatorsSettings,
  CoreRootState,
  formatMoney,
  deleteTaxTableRow,
} from '@westondev/tableturn-core';
import max from 'lodash/max';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, AnyAction } from 'redux';
import { getTaxesId } from '../helpers';

interface ITaxTypeFormProps {
  taxData: ITaxSetting;
  updateRow: (
    newValue: number | string | ITaxTypeData | ITableTax | null,
    id: number,
    field: keyof ITaxSetting,
  ) => void;
  addTaxTableTypeRow: (id: keyof ITaxesSettings) => void;
  isDisabled: boolean;
}

interface ITableTypeTax {
  id: number;
  from: number;
  to: number;
  tax: number;
  priceDifference: number;
  repeat: boolean;
  delete: string;
}

const SECTION_ID = SettingsSectionId.TAXES;
const TaxTableForm = ({
  t,
  taxData,
  updateRow,
  addTaxTableTypeRow,
  isDisabled,
}: WithTranslation & ITaxTypeFormProps) => {
  const dispatch = useDispatch();
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const deleteRow = (id: number, errorIds: string[]) => {
    errorIds.forEach(errorId => clearSettingsError(errorId));

    const currentData = store.getState().settings.changeData
      .data as ITaxesSettings;
    const newValues = deleteTaxTableRow(currentData, taxData?.id, id);

    setValue<ITaxesSettings>(newValues, SECTION_ID, true);
  };

  const columnHelper = createColumnHelper<ITableTypeTax>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('from', {
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.from'),
        cell: info => (
          <MoneyInput
            disabled
            required
            value={info.getValue() ?? 0}
            onValueChange={() => null}
          />
        ),
      }),
      columnHelper.accessor('to', {
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.to'),
        cell: info => (
          <ToInput
            isDisabled={isDisabled}
            info={info}
            taxData={taxData}
            updateRow={updateRow}
          />
        ),
      }),
      columnHelper.accessor('tax', {
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.tax'),
        cell: info => (
          <MoneyInput
            required
            disabled={isDisabled}
            value={info.getValue() ? info.getValue() : 0}
            onValueChange={value =>
              updateRow(
                {
                  [info.row.original.id]: {
                    id: info.row.original.id,
                    from: info.row.original.from,
                    to: info.row.original.to,
                    tax: value,
                    repeat: info.row.original.repeat,
                  },
                } as ITableTax,
                taxData?.id,
                'tableTypeData',
              )
            }
          />
        ),
      }),
      columnHelper.accessor('priceDifference', {
        header: t(
          'settingsModule.taxesSettings.taxesTable.taxTableType.priceDifference',
        ),
        cell: info => (
          <Typography>{formatMoney(null, info.getValue())}</Typography>
        ),
      }),
      columnHelper.accessor('repeat', {
        header: t(
          'settingsModule.taxesSettings.taxesTable.taxTableType.repeat',
        ),
        cell: info => (
          <Checkbox
            isDisabled={isDisabled}
            checked={info.getValue()}
            onChange={value =>
              updateRow(
                {
                  [info.row.original.id]: {
                    id: info.row.original.id,
                    from: info.row.original.from,
                    to: info.row.original.to,
                    tax: info.row.original.tax,
                    repeat: value,
                  },
                } as ITableTax,
                taxData?.id,
                'tableTypeData',
              )
            }
          />
        ),
        size: 70,
      }),
      columnHelper.accessor('delete', {
        header: t(
          'settingsModule.taxesSettings.taxesTable.taxTableType.delete',
        ),
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}
            disabled={isDisabled}
            onClick={() => deleteRow(info.row.original.id, [])}
          />
        ),
        size: 70,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, taxData?.id, isDisabled],
  );

  const taxTypeTableArray = useMemo(
    () =>
      Object.keys((taxData?.tableTypeData as ITableTax) || {}).map(key => ({
        ...(taxData?.tableTypeData as ITableTax)[key],
        id: Number(key),
        priceDifference:
          (taxData?.tableTypeData as ITableTax)[key].to -
          (taxData?.tableTypeData as ITableTax)[key].from,
        delete: '',
      })),

    [taxData?.tableTypeData],
  );

  return (
    <Table
      renderEmptyValues
      columns={COLUMN_VALUES}
      data={taxTypeTableArray}
      cellCsx={{ height: '70px' }}
      onAddClick={
        isDisabled ? undefined : () => addTaxTableTypeRow(taxData?.id)
      }
    />
  );
};

const ToInput = ({
  isDisabled,
  info,
  taxData,
  updateRow,
}: {
  isDisabled: boolean;
  info: CellContext<ITableTypeTax, number>;
  taxData: ITaxSetting;
  updateRow: (
    newValue: number | string | ITaxTypeData | ITableTax | null,
    id: number,
    field: keyof ITaxSetting,
  ) => void;
}) => {
  const dispatch = useDispatch();

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const settingsErrors = useSelector(settingsErrorsSelector);

  return (
    <MoneyInput
      required
      disabled={isDisabled}
      value={info.getValue() ? info.getValue() : 0}
      onValueChange={value => {
        const state = store.getState();
        const taxes = state.settings.changeData.data as ITaxesSettings;

        const runValidator = () =>
          dispatch(
            actionCreatorsSettings.checkTaxesValidation(
              selectCurrentSettingsData(
                store.getState() as unknown as CoreRootState,
              ) as ITaxesSettings,
              false,
              () => null,
              false,
            ) as unknown as AnyAction,
          );

        const currentTax = taxes[taxData?.id];

        const taxTableArray = Object.entries(currentTax?.tableTypeData || {});

        const rowIndex = taxTableArray.findIndex(
          ([id]) => Number(id) === info.row.original.id,
        );

        updateRow(
          {
            [info.row.original.id]: {
              from: info.row.original.from,
              to: value,
              tax: info.row.original.tax,
              repeat: info.row.original.repeat,
            },
          } as ITableTax,
          taxData?.id,
          'tableTypeData',
        );

        if (rowIndex + 2 > taxTableArray.length) {
          return runValidator();
        }

        const nextRow = taxTableArray[rowIndex + 1][1];
        const newFrom =
          max([
            ...taxTableArray.slice(0, rowIndex + 1).map(([, row]) => row.from),
            value + 0.01,
          ]) || 0;

        const nextRowId = Number(taxTableArray[rowIndex + 1][0]);

        updateRow(
          {
            [nextRowId]: {
              from: newFrom,
              to: nextRow.to,
              tax: nextRow.tax,
              repeat: nextRow.repeat,
            },
          } as ITableTax,
          taxData?.id,
          'tableTypeData',
        );
        runValidator();
      }}
      error={Boolean(
        settingsErrors[getTaxesId(taxData.id, 'to', info.row.original.id)],
      )}
      onFocus={() =>
        clearSettingsError(getTaxesId(taxData.id, 'to', info.row.original.id))
      }
    />
  );
};

export default TaxTableForm;
