import { Theme, css } from '@emotion/react';

export const thumbStyles = (theme: Theme) =>
  css({
    background: theme.colors.persistentDarkerBlue,
    borderRadius: '50%',
    position: 'absolute',
    cursor: 'grab',
    touchAction: 'none',
  });

export const trackStyles = (theme: Theme) =>
  css({
    width: '100%',
    background: theme.colors.lightestGrey,
    borderRadius: '8px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    touchAction: 'none',
  });

export const labelStyles = (theme: Theme) =>
  css({
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
    minWidth: 'max-content',
    maxWidth: 'max-content',
    paddingInline: '2px',
    height: '25px',
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    userSelect: 'none',
    display: 'none',
    opacity: 0,
    alignItems: 'center',
    justifyContent: 'center',
  });
