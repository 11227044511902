import { Theme, css } from '@emotion/react';

export const actionDropdownStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '> button': {
      width: '100%',
      justifyContent: 'flex-start',
      fontSize: theme.fontSizes.body,
      ':hover': {
        backgroundColor: theme.colors.lighterBlue,
        filter: 'none !important',
        color: theme.colors.semanticBlue,
        svg: {
          fill: theme.colors.semanticBlue,
        },
      },
      svg: {
        borderRadius: '50%',
        padding: '5px',
      },
    },
  });
