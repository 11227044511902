import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import {
  makeSelectItemData,
  selectCombos,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IMenuType,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { cloneDeep, upperCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AssignedCategoriesSection from './AssignedCategoriesSection';
import AssignedCombosSection from './AssignedCombosSection';
import AssignedItemsSection from './AssignedItemsSection';
import AssignedSubcategoriesSection from './AssignedSubcategoriesSection';
import { ICategoryCountMap } from './types';

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);

const AssociationsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { updateMenuType: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { itemData } = useSelector(getMemoizedItemData);
  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);

  const assignedItemIds = itemData?.itemIds;
  const assignedComboIds = itemData?.comboIds;
  const menuTypeId = itemData?.menuTypeId;
  const assignedCategoryIds = itemData?.categoryIds;
  const itemMenuTypeTemplateIds = itemData?.itemMenuTypeTemplateIds;
  const comboMenuTypeTemplateIds = itemData?.comboMenuTypeTemplateIds;

  const [categoryCountMap, setCategoryCountMap] = useState<ICategoryCountMap>({
    items: {},
    combos: {},
  });

  const [subcategoryCountMap, setSubcategoryCountMap] =
    useState<ICategoryCountMap>({
      items: {},
      combos: {},
    });

  useEffect(() => {
    const newItemsCategoryMap = (assignedItemIds ?? []).reduce(
      (acc: Record<number, number>, itemId: number) => {
        const item = items[itemId];
        const categoriesSubcategoriesArray = item
          ? Object.values(
              item.menuTypeVersions[menuTypeId]?.categoriesSubcategories || {},
            )
          : [];
        if (item && categoriesSubcategoriesArray.length > 0) {
          categoriesSubcategoriesArray.forEach(({ categoryId }) => {
            acc[categoryId || 0] = (acc[categoryId || 0] || 0) + 1;
          });
        }
        return acc;
      },
      {},
    );

    const newCombosCategoryMap = (assignedComboIds ?? []).reduce(
      (acc: Record<number, number>, itemId: number) => {
        const combo = combos[itemId];
        const categoriesSubcategoriesArray = combo
          ? Object.values(
              combo.menuTypeVersions[menuTypeId]?.categoriesSubcategories || {},
            )
          : [];
        if (combo && categoriesSubcategoriesArray.length > 0) {
          categoriesSubcategoriesArray.forEach(({ categoryId }) => {
            acc[categoryId || 0] = (acc[categoryId || 0] || 0) + 1;
          });
        }
        return acc;
      },
      {},
    );
    setCategoryCountMap({
      items: newItemsCategoryMap,
      combos: newCombosCategoryMap,
    });

    const newItemsSubcategoryMap = (assignedItemIds ?? []).reduce(
      (acc: Record<number, number>, itemId: number) => {
        const item = items[itemId];
        const categoriesSubcategoriesArray = item
          ? Object.values(
              item.menuTypeVersions[menuTypeId]?.categoriesSubcategories || {},
            )
          : [];
        if (item && categoriesSubcategoriesArray.length > 0) {
          categoriesSubcategoriesArray.forEach(({ subcategoryId }) => {
            acc[subcategoryId || 0] = (acc[subcategoryId || 0] || 0) + 1;
          });
        }
        return acc;
      },
      {},
    );

    const newCombosSubcategoryMap = (assignedComboIds ?? []).reduce(
      (acc: Record<number, number>, itemId: number) => {
        const combo = combos[itemId];
        const categoriesSubcategoriesArray = combo
          ? Object.values(
              combo.menuTypeVersions[menuTypeId]?.categoriesSubcategories || {},
            )
          : [];
        if (combo && categoriesSubcategoriesArray.length > 0) {
          categoriesSubcategoriesArray.forEach(({ subcategoryId }) => {
            acc[subcategoryId || 0] = (acc[subcategoryId || 0] || 0) + 1;
          });
        }
        return acc;
      },
      {},
    );

    setSubcategoryCountMap({
      items: newItemsSubcategoryMap,
      combos: newCombosSubcategoryMap,
    });
  }, [assignedItemIds, items, assignedComboIds, combos, menuTypeId]);

  const handleDisassociate = useCallback(
    (itemId: number, isCombo: boolean) => {
      const item = items[itemId];
      const combo = combos[itemId];
      const newCategoryCountMap = cloneDeep(categoryCountMap);
      const _itemMenuTypeTemplateIds = itemData.itemMenuTypeTemplateIds;
      const _comboMenuTypeTemplateIds = itemData.comboMenuTypeTemplateIds;

      const categoriesSubcategories = isCombo
        ? combo.menuTypeVersions[menuTypeId]?.categoriesSubcategories
        : item.menuTypeVersions[menuTypeId]?.categoriesSubcategories;

      const categoryIdsSet = new Set(
        Object.values(categoriesSubcategories || {})
          .map(({ categoryId }) => categoryId)
          .filter(Boolean),
      );

      const emptiedCategoryIds = new Set<number | null>();

      categoryIdsSet.forEach(categoryId => {
        newCategoryCountMap[isCombo ? 'combos' : 'items'][categoryId || 0] -= 1;
        if (
          newCategoryCountMap[isCombo ? 'combos' : 'items'][categoryId || 0] ===
          0
        ) {
          emptiedCategoryIds.add(categoryId);
        }
      });

      setCategoryCountMap(newCategoryCountMap);

      const newMenuTypeAssociationsData: Partial<IMenuType> = isCombo
        ? {
            comboIds: (assignedComboIds ?? []).filter(
              (id: number) => id !== itemId,
            ),
            comboMenuTypeTemplateIds: { ..._comboMenuTypeTemplateIds },
          }
        : {
            itemIds: (assignedItemIds ?? []).filter(
              (id: number) => id !== itemId,
            ),
            itemMenuTypeTemplateIds: { ..._itemMenuTypeTemplateIds },
          };

      emptiedCategoryIds.forEach(categoryId => {
        delete newCategoryCountMap.combos[categoryId || 0];
        delete newCategoryCountMap.items[categoryId || 0];

        newMenuTypeAssociationsData.categoryIds = (
          assignedCategoryIds ?? []
        ).filter((id: number) => id !== categoryId);
      });

      if (
        !isCombo &&
        newMenuTypeAssociationsData.itemMenuTypeTemplateIds &&
        itemData.itemMenuTypeTemplateIds?.[itemId]
      ) {
        delete newMenuTypeAssociationsData.itemMenuTypeTemplateIds[itemId];
      } else if (
        isCombo &&
        newMenuTypeAssociationsData.comboMenuTypeTemplateIds &&
        itemData.comboMenuTypeTemplateIds?.[itemId]
      ) {
        delete newMenuTypeAssociationsData.comboMenuTypeTemplateIds[itemId];
      }

      setValue({ ...newMenuTypeAssociationsData });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      assignedCategoryIds,
      assignedItemIds,
      categoryCountMap,
      items,
      combos,
      assignedComboIds,
      setValue,
    ],
  );

  const handleAssociate = useCallback(
    (buttons: IItemSelection[], isCombo: boolean) => {
      const newItemIds: number[] = [];
      const newCategoryIds = new Set<number>(assignedCategoryIds);
      const newCategoryMap: Record<number, number> = {};
      buttons.forEach(button => {
        const itemId = button.id as number;
        newItemIds.push(itemId);
        const categoriesSubcategories = isCombo
          ? combos[itemId].menuTypeVersions[menuTypeId]?.categoriesSubcategories
          : items[itemId].menuTypeVersions[menuTypeId]?.categoriesSubcategories;

        const categoryIdsSet = new Set([
          ...Object.values(categoriesSubcategories || {})
            .map(({ categoryId }) => categoryId)
            .filter(Boolean),
        ]);

        categoryIdsSet.forEach(categoryId => {
          if (categoryId) {
            newCategoryIds.add(categoryId);
            newCategoryMap[categoryId] = (newCategoryMap[categoryId] || 0) + 1;
          }
        });
      });
      const newItemMenuTypeTemplateIds: { [key: number]: number } = {};

      buttons.forEach(button => {
        const item = items[Number(button.id)];
        const combo = combos[Number(button.id)];
        const menuTypes = Object.values(
          isCombo ? combo.menuTypeVersions : item.menuTypeVersions,
        );
        const activeMenuTypes = menuTypes.filter(menuType => menuType.active);
        newItemMenuTypeTemplateIds[Number(button.id)] =
          activeMenuTypes.length > 0
            ? activeMenuTypes[0].menuTypeId
            : menuTypes.length > 0
            ? menuTypes[0].menuTypeId
            : 0;
      });
      setValue({
        ...(isCombo
          ? {
              comboIds: [...(assignedComboIds ?? []), ...newItemIds],
              comboMenuTypeTemplateIds: {
                ...comboMenuTypeTemplateIds,
                ...newItemMenuTypeTemplateIds,
              },
            }
          : {
              itemIds: [...(assignedItemIds ?? []), ...newItemIds],
              itemMenuTypeTemplateIds: {
                ...itemMenuTypeTemplateIds,
                ...newItemMenuTypeTemplateIds,
              },
            }),

        categoryIds: [...Array.from(newCategoryIds)],
      });
    },
    [
      assignedCategoryIds,
      setValue,
      assignedComboIds,
      comboMenuTypeTemplateIds,
      assignedItemIds,
      itemMenuTypeTemplateIds,
      combos,
      menuTypeId,
      items,
    ],
  );

  return (
    <AccordionSection
      title={
        <Box>
          <Typography>
            {upperCase(t('commonTexts.associated'))}
            {': '}
            <Typography
              csx={{
                fontStyle: 'italic',
                display: 'inline',
              }}>
              {t('menuScreen.menuTypeDetails.associations.title')}
            </Typography>
          </Typography>
        </Box>
      }
      {...props}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Card.SubCard
          csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <AssignedItemsSection
            onAssociate={buttons => handleAssociate(buttons, false)}
            onDisassociate={itemId => handleDisassociate(Number(itemId), false)}
          />
          <AssignedCombosSection
            onAssociate={buttons => handleAssociate(buttons, true)}
            onDisassociate={comboId =>
              handleDisassociate(Number(comboId), true)
            }
          />
        </Card.SubCard>
        <AssignedCategoriesSection categoryCount={categoryCountMap} />
        <AssignedSubcategoriesSection subcategoryCount={subcategoryCountMap} />
      </Box>
    </AccordionSection>
  );
};

export default AssociationsSection;
