import HeaderDiffModal from '../../common/HeaderDiffModal';
import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { useTheme } from '@emotion/react';
import {
  diffLockScreenFactory,
  LockScreenBasicFieldLabels,
  TChange,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RenderChange from '../common/RenderChange';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import Grid from '@app/components/common/Grid';

const COLOR_FIELDS = ['backgroundColor', 'textColor'];

const LockScreenDiffModal = () => {
  const theme = useTheme();

  const differences = useSelector(settingsDifferencesSelector);

  const formattedDifferences = useMemo(
    () => diffLockScreenFactory(differences),
    [differences],
  );

  const defaultColor = {
    [COLOR_FIELDS[0]]: theme.colors.semanticBlue,
    [COLOR_FIELDS[1]]: theme.colors.textWhite,
  };
  const isThereAnyBasicChange = formattedDifferences.basic.length > 0;
  const isThereAnyTabsChange = formattedDifferences.tabs.length > 0;

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    const isTextFieldValue = [
      LockScreenBasicFieldLabels.announcementTitle,
      LockScreenBasicFieldLabels.announcementDescription,
    ].includes(field as LockScreenBasicFieldLabels);

    if (isTextFieldValue) {
      return textValue;
    }

    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;

    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={COLOR_FIELDS}
        defaultColor={defaultColor}
      />
    );
  };
  return (
    <>
      {isThereAnyBasicChange && (
        <>
          <HeaderDiffModal name="Basic" color="black" />
          <Grid columnGap={20} rowGap={10}>
            {formattedDifferences.basic.map(change => (
              <Grid.Item
                mb={12}
                sm={6}
                md={4}
                lg={4}
                key={`lockScreenSettings_basic_${change.field}`}>
                {renderChange(change as TChange)}
              </Grid.Item>
            ))}
          </Grid>
        </>
      )}

      {isThereAnyTabsChange && (
        <>
          <HeaderDiffModal name="Tabs" color="black" />
          <Grid columnGap={20} rowGap={10}>
            {formattedDifferences.tabs.map(change => (
              <Grid.Item
                mb={12}
                sm={6}
                md={4}
                lg={4}
                key={`lockScreenSettings_tabs_${change.field}`}>
                {renderChange(change as TChange)}
              </Grid.Item>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default LockScreenDiffModal;
