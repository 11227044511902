import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { ISelectLocationRow } from '@westondev/tableturn-core';
import { selectLocationRowStyles } from './styles';

interface IHandleLocation {
  handleLocationToggle: (id: number) => void;
}

const SelectLocationRow = ({
  selected,
  name,
  locationId,
  handleLocationToggle,
}: ISelectLocationRow & IHandleLocation) => {
  return (
    <Box
      csx={selectLocationRowStyles}
      onClick={() => handleLocationToggle(locationId)}>
      <Box className="content">
        <Icon
          name={selected ? 'FaCircle' : 'FaRegCircle'}
          color="semanticBlue"
          className="icon"
        />
        <Typography color={selected ? 'semanticBlue' : 'black'}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectLocationRow;
