import { useSelector } from 'react-redux';
import ElementPanHandlerMemo, {
  TElement,
} from '../ElementPanHandler/ElementPanHandler';
import { DEFAULT_ELEMENT_SIZES } from '../types';
import { ILayoutProps } from '@westondev/tableturn-core';
import { elementStyles } from '../styles';
import { selectSelectedElement } from '@app/state/selectors/tableLayoutSelectors';

interface IWall extends TElement {
  isAnimated?: boolean;
}
const Wall = ({ element, layoutProps, isAnimated = false }: IWall) => {
  // Redux
  const selectedElement = useSelector(selectSelectedElement);

  // Local state
  const showControls =
    selectedElement?.id === element.id && selectedElement.showControls;
  const width = DEFAULT_ELEMENT_SIZES.wall.width;
  const height = DEFAULT_ELEMENT_SIZES.wall.height;

  if (isAnimated)
    return (
      <ElementPanHandlerMemo
        element={element}
        isSelected={selectedElement?.id === element.id}
        showControls={showControls}
        elementStyles={[
          elementStyles,
          {
            width: element.width,
            height: element.height,
          },
        ]}
        defaultElementProps={{ width, height }}
        layoutProps={layoutProps as ILayoutProps}
      />
    );

  // TODO: Implement table layout for register module
  return null;
};

export default Wall;
