import { Theme, css } from '@emotion/react';

export const resizeStyles = {
  horizontalResizing: {
    position: 'absolute',
    marginTop: '10px',
    alignItems: 'center',
    right: -10,
    width: 20,
    height: 20,
    backgroundColor: 'white',
    cursor: 'ew-resize',
  } as React.CSSProperties,
  verticalResizing: {
    position: 'absolute',
    backgroundColor: 'white',
    bottom: -9,
    width: 20,
    height: 20,
    justifyContent: 'center',
    cursor: 'ns-resize',
  } as React.CSSProperties,
  diagonalResizing: (theme: Theme) =>
    css({
      position: 'absolute',
      right: -5,
      bottom: -5,
      height: 10,
      width: 10,
      borderRadius: 100,
      backgroundColor: theme.colors.persistentSemanticBlue,
      cursor: 'nwse-resize',
    }),
  rotateIcon: {
    width: 25,
    height: 25,
    position: 'absolute',
    right: -24,
    top: -15,
    cursor: 'crosshair',
  } as React.CSSProperties,
};
