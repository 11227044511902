export const INITIAL_FORM_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneNumberMasked: '',
  password: '',
  confirmPassword: '',
};

export enum ESignupErrors {
  EMAIL_ALREADY_EXISTS = 14,
  PHONE_ALREADY_EXISTS = 86,
  GENERAL_ERROR = 38,
}
