import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { accountCreatedStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import Confetti from '@app/components/common/Animations/ConfettiExplosion';

interface IAccountCreatedMessage extends WithTranslation {
  user: string;
  titleOverride?: string;
  descriptionOverride?: string;
  buttonTextOverride?: string;
  onGetStarted?: () => void;
}
const AccountCreatedMessage = ({
  t,
  user = 'User',
  titleOverride,
  descriptionOverride,
  buttonTextOverride,
  onGetStarted,
}: IAccountCreatedMessage) => {
  const navigate = useNavigate();
  return (
    <>
      <Confetti />
      <Box csx={accountCreatedStyles}>
        <Box className="message">
          <Box className="messageBody">
            <Box className="iconsContainer">
              <Icon name="MdPerson" size="110px" />
              <Icon
                name="MdCheckCircle"
                color="semanticGreen"
                size="45px"
                csx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  background: 'white',
                  borderRadius: '100%',
                }}
              />
            </Box>
            <Typography
              align="center"
              variant="heading"
              fontWeight="medium"
              className="title">
              {titleOverride ??
                t('authentication.signUp.accountCreated.title', { user })}
            </Typography>
            <Typography align="center" color="darkestGrey">
              {descriptionOverride ??
                t('authentication.signUp.accountCreated.description')}
            </Typography>
          </Box>
          <Button
            variant="active"
            csx={{ width: '100%', marginTop: '30px', flexGrow: 0 }}
            onClick={
              onGetStarted ? onGetStarted : () => navigate('/auth/sign-in')
            }>
            {buttonTextOverride ??
              t('authentication.signUp.accountCreated.continueToSignIn')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AccountCreatedMessage;
