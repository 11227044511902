import Typography from '@app/components/common/Typography';
import { css, TCsx } from '@emotion/react';
import useReportsFilterText from '@app/hooks/useReportsFilterText';

const filtersBlockValueStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
});

const ItemComboTableCategoryCell = ({
  categoryIds,
  csx,
  ...props
}: {
  categoryIds: number[] | null;
  csx?: TCsx;
}) => {
  const categoryName = useReportsFilterText('categories', categoryIds);
  return (
    <Typography csx={[filtersBlockValueStyle, csx]} {...props}>
      {categoryName}
    </Typography>
  );
};

export default ItemComboTableCategoryCell;
