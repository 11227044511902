import { Theme, css } from '@emotion/react';

export const layoutGridStyles =
  (gridSize: string, mode: 'small' | 'big') => (theme: Theme) =>
    css({
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundImage: `repeating-linear-gradient(
        0deg,
        transparent,
        transparent calc(${gridSize} - 1px),
        ${
          mode === 'small'
            ? theme.colors.lightestGrey
            : theme.colors.lighterGrey
        } calc(${gridSize} - 1px),
        ${
          mode === 'small'
            ? theme.colors.lightestGrey
            : theme.colors.lighterGrey
        } ${gridSize}
      ),
      repeating-linear-gradient(
        -90deg,
        transparent,
        transparent calc(${gridSize} - 1px),
        ${
          mode === 'small'
            ? theme.colors.lightestGrey
            : theme.colors.lighterGrey
        } calc(${gridSize} - 1px),
        ${
          mode === 'small'
            ? theme.colors.lightestGrey
            : theme.colors.lighterGrey
        } ${gridSize}
      )`,
      backgroundSize: '${gridSize} ${gridSize}',
      zIndex: 0,
      pointerEvents: 'none',
    });
