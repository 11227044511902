import { Theme, css } from '@emotion/react';

export const passwordChangedStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingInline: '20px',
  marginTop: '-20px',
  '.title': {
    marginBottom: '15px',
  },
});

export const separatorStyles = (theme: Theme) =>
  css({
    height: '1px',
    backgroundColor: theme.colors.lightGrey,
    width: '100%',
    marginBlock: '35px',
  });
