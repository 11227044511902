import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import MoneyInput from '@app/components/common/MoneyInput';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsSettings,
  IRegisterSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY;

function GeneralSettings({
  t,
  data,
}: WithTranslation & { data: IRegisterSettings }) {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  return (
    <Card.SubCard
      title={t(
        'settingsModule.registerModeSettings.quickService.general.title',
      )}>
      <label css={labelStyles}>
        {t(
          'settingsModule.registerModeSettings.customerFacingDisplay.general.receiptScreenTimeout',
        )}
      </label>
      <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'center' }}>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
          <MoneyInput
            prefix=""
            suffix=""
            value={data?.receiptScreenTimeout || 0}
            precision={0}
            onValueChange={receiptScreenTimeout =>
              setValue<IRegisterSettings>({ receiptScreenTimeout }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={2}>
          <Checkbox
            label={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.general.requireClientSignature',
            )}
            checked={data?.requireClientSignature}
            onChange={requireClientSignature =>
              setValue<IRegisterSettings>(
                { requireClientSignature },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={2}>
          <Checkbox
            label={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.general.payThroughCfdOnly',
            )}
            checked={data?.payThroughCfdOnly}
            onChange={payThroughCfdOnly =>
              setValue<IRegisterSettings>({ payThroughCfdOnly }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={2}>
          <Checkbox
            label={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.general.promptStarRatings',
            )}
            checked={data?.promptStarRatings}
            onChange={promptStarRatings =>
              setValue<IRegisterSettings>({ promptStarRatings }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={2}>
          <Checkbox
            label={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.general.darkMode',
            )}
            checked={data?.darkMode}
            onChange={darkMode =>
              setValue<IRegisterSettings>({ darkMode }, SECTION_ID)
            }
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
}

export default GeneralSettings;
