import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import GeneralSettings from '@app/components/LoggedIn/Settings/General/GeneralSettings';
import HoursOfOperation from '@app/components/LoggedIn/Settings/General/HoursOfOperation';
import LocationInformationSettings from '@app/components/LoggedIn/Settings/General/LocationInformationSettings';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const General = () => {
  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    useDispatch(),
  );

  const { t } = useTranslation();

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'general',
  );

  useEffect(() => {
    if (!isSet)
      loadSettingsBucketChangeData({
        setting: 'general',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return (
    <>
      {isSet && (
        <>
          <HeaderTitle title={t('settingsModule.generalSettings.title')} />
          <Accordion onExpandAll={() => null} onCollapseAll={() => null}>
            <LocationInformationSettings isExpanded />
            <AccordionSection title={t('settingsModule.generalSettings.title')}>
              <GeneralSettings />
            </AccordionSection>
            <AccordionSection
              title={t(
                'settingsModule.generalSettings.hoursOfOperation.title',
              )}>
              <HoursOfOperation />
            </AccordionSection>
          </Accordion>
        </>
      )}
    </>
  );
};

export default General;
