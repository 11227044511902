import { MQ_MAX_LARGE, MQ_MIN_X_LARGE } from '@app/theme/types';
import { css } from '@emotion/react';

export const registerDisplayNavigationCheckbosStyles = css({
  height: '100%',
  justifyContent: 'center',
});

export const leftTwoCheckboxesSpacing = css({
  justifyContent: 'center',
  [MQ_MAX_LARGE]: {
    paddingBottom: '20px',
  },

  [MQ_MIN_X_LARGE]: {
    paddingRight: '15px',
  },
});

export const rightTwoCheckboxesStyles = css({
  justifyContent: 'center',
  // [MQ_MAX_LARGE]: { paddingTop: '20px' },
  [MQ_MIN_X_LARGE]: { paddingLeft: '15px' },
});
