import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import { CHECKBOX_SIZE } from '@app/components/common/Checkbox/styles';
import Icon from '@app/components/common/Icon';
import RadioCircle from '@app/components/common/Icon/custom/RadioCircle';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { RadioSelectionStates } from '@app/types';
import { ISelectLocationGroupRow } from '@westondev/tableturn-core';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { selectLocationGroupRowStyles } from './styles';

interface IHandleLocationGroup {
  handleLocationGroupToggle: (id: number, state: RadioSelectionStates) => void;
  handleLocationToggle: (locationGroupId: number, locationId: number) => void;
  isFirst: boolean;
  isLast: boolean;
  hideExpand?: boolean;
  hideCounter?: boolean;
}
const SelectLocationGroupRow = ({
  locationGroupId,
  name,
  locations,
  handleLocationGroupToggle,
  handleLocationToggle,
  isFirst,
  isLast,
  t,
  hideExpand,
  hideCounter,
}: ISelectLocationGroupRow & IHandleLocationGroup & WithTranslation) => {
  const blueText = 'semanticBlue';
  const [groupIsExpanded, setGroupIsExpanded] = React.useState(false);
  const locationsSelectedCount = React.useMemo<number>(
    () => locations.filter(location => location.selected).length,
    [locations],
  );
  const groupState = React.useMemo<RadioSelectionStates>(
    () =>
      locationsSelectedCount === 0
        ? RadioSelectionStates.EMPTY
        : locationsSelectedCount === locations.length
        ? RadioSelectionStates.FULL
        : RadioSelectionStates.SOME,
    [locations.length, locationsSelectedCount],
  );

  return (
    <>
      <Box
        csx={[
          selectLocationGroupRowStyles,
          {
            borderTopRightRadius: isFirst ? 10 : 0,
            borderTopLeftRadius: isFirst ? 10 : 0,
            borderBottomLeftRadius: isLast && !groupIsExpanded ? 10 : 0,
            borderBottomRightRadius: isLast && !groupIsExpanded ? 10 : 0,
          },
        ]}>
        <Box csx={[displayFlexRowStyles, { marginRight: '2px' }]}>
          <Button
            variant="transparent"
            onClick={() =>
              handleLocationGroupToggle(locationGroupId, groupState)
            }
            icon={
              <RadioCircle
                size={CHECKBOX_SIZE - 2}
                state={groupState}
                csx={{ marginLeft: '5px' }}
              />
            }>
            <Typography
              color={locationsSelectedCount > 0 ? blueText : 'darkestGrey'}
              fontWeight="medium">
              {name}
            </Typography>
          </Button>
        </Box>
        <Button
          variant="icon"
          whileTap={{ scale: 1 }}
          csx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '10px',
          }}
          iconPosition="right"
          icon={
            hideExpand ? null : (
              <Icon
                name={groupIsExpanded ? 'MdChevronRight' : 'MdChevronRight'}
                csx={{ rotate: groupIsExpanded ? '-90deg' : '90deg' }}
              />
            )
          }
          onClick={() => {
            if (!groupIsExpanded && hideExpand) {
              return handleLocationGroupToggle(locationGroupId, groupState);
            }
            setGroupIsExpanded(current => !current);
          }}>
          {!hideCounter && (
            <Typography color="darkGrey" csx={{ whiteSpace: 'nowrap' }}>
              {`[${locationsSelectedCount}/${locations.length}] `}
              {t('commonTexts.selected')}
            </Typography>
          )}
        </Button>
      </Box>
      <Box>
        {groupIsExpanded && (
          <CardsContainer
            csx={{ borderRadius: 0, borderTop: '0px' }}
            data={locations.map(location => ({
              id: location.locationId,
              title: location.name,
              subTitle: location.description,
              type: 'location',
              key: `location-${location.locationId}`,
              isSelected: location.selected,
              isActive: location.enabled,
              onClick: () =>
                location.enabled
                  ? handleLocationToggle(locationGroupId, location.locationId)
                  : null,
            }))}
            noElementsMessage={t(
              'menuScreen.selectionModal.locations.noAvailableCardsText',
            )}
            renderItem={item => <Card.Item {...item} />}
          />
        )}
      </Box>
    </>
  );
};

export default SelectLocationGroupRow;
