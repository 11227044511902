import { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadConfettiPreset } from '@tsparticles/preset-confetti';
import { LightTheme } from '@app/theme/themes';
import { ParticlesOptions } from '@tsparticles/engine';

const ConfettiExplosion = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadConfettiPreset(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const confetti = useMemo(
    () =>
      ({
        preset: 'confetti',
        fullScreen: {
          zIndex: 1,
        },
        particles: {
          number: {
            value: 0,
          },
          color: {
            value: [
              LightTheme.colors.semanticYellow,
              LightTheme.colors.darkestBlue,
              LightTheme.colors.darkerBlue,
              LightTheme.colors.darkBlue,
              LightTheme.colors.semanticBlue,
              LightTheme.colors.lightBlue,
              LightTheme.colors.lighterBlue,
              LightTheme.colors.lightestBlue,
              LightTheme.colors.deepBlue,
              LightTheme.colors.deeperBlue,
            ],
          },
          shape: {
            type: ['circle', 'square'],
            options: {},
          },
          opacity: {
            value: {
              min: 0,
              max: 1,
            },
            animation: {
              enable: true,
              speed: 2,
              startValue: 'max',
              destroy: 'min',
            },
          },
          size: {
            value: {
              min: 3,
              max: 5,
            },
          },
          links: {
            enable: false,
          },
          life: {
            duration: {
              sync: true,
              value: 5,
            },
            count: 1,
          },
          move: {
            enable: true,
            gravity: {
              enable: true,
              acceleration: 10,
            },
            speed: {
              min: 10,
              max: 20,
            },
            decay: 0.1,
            direction: 'none',
            straight: false,
            outModes: {
              default: 'destroy',
              top: 'none',
            },
          },
          rotate: {
            value: {
              min: 0,
              max: 360,
            },
            direction: 'random',
            move: true,
            animation: {
              enable: true,
              speed: 60,
            },
          },
          tilt: {
            direction: 'random',
            enable: true,
            move: true,
            value: {
              min: 0,
              max: 360,
            },
            animation: {
              enable: true,
              speed: 60,
            },
          },
          roll: {
            darken: {
              enable: true,
              value: 25,
            },
            enable: true,
            speed: {
              min: 15,
              max: 25,
            },
          },
          wobble: {
            distance: 30,
            enable: true,
            move: true,
            speed: {
              min: -15,
              max: 15,
            },
          },
        },
        emitters: {
          life: {
            count: 10,
            duration: 0.1,
            delay: 0.5,
          },
          rate: {
            delay: 0.1,
            quantity: 120,
          },
          size: {
            width: 0,
            height: 0,
          },
        },
      }) as Partial<ParticlesOptions>,
    [],
  );

  return init && <Particles options={confetti} />;
};

export default ConfettiExplosion;
