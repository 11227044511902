import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Item from './Item';
import { ItemProps } from './Item/types';

const SortableItem = ({
  id,
  children,
  disabled,
  ...props
}: ItemProps & { disabled: boolean }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id || '', disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...props}
      {...attributes}
      {...listeners}>
      {children}
    </Item>
  );
};

export default SortableItem;
