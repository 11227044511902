import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import DraggableContainer from '@app/components/LoggedIn/Menu/common/DragAndDrop/DraggableContainer';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import MoneyInput from '@app/components/common/MoneyInput';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import itemGroupsAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/itemGroups/itemGroupsAssignedItemsFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectCombos,
  selectIsMenuMasterMode,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IItemOfItemGroup,
  ITEM_OF_ITEM_GROUP_INITIAL_STATE,
  SectionId,
  actionCreatorsMenu,
  itemGroupsParentCombosFactoryCore,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
  hideEdit?: boolean;
  hideMoreOptions?: boolean;
  bucket: 'items' | 'combos';
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('itemGroups', SECTION_ID);

const AssignedItemsCombosSection = ({
  csx,
  t,
  bucket,
}: IAssignedItemsCombosSection) => {
  // Redux
  const isItemGroupBucket = bucket === 'items';
  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);
  const { itemData: itemGroup } = useSelector(getMemoizedItemData);

  const itemGroupId = itemGroup?.itemGroupId;
  const isMasterMode = useSelector(selectIsMenuMasterMode);

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();

  const {
    updateItemGroup,
    updateCombo,
    updateLastItemsOfItemGroupOrder,
    removeItemFromItemGroup,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [showUpCharge, setShowUpCharge] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);

  const theme = useTheme();

  const itemIds = useMemo(
    () => Object.keys(itemGroup?.items || {}),
    [itemGroup?.items],
  );

  const setValue = useCallback(
    (
      value:
        | Parameters<typeof updateItemGroup>[0]
        | Parameters<typeof updateCombo>[0],
      id?: number,
    ) => {
      if (isItemGroupBucket) {
        return updateItemGroup(value, SECTION_ID, id);
      }

      updateCombo(value, SECTION_ID, 0, itemGroupId, id);
    },
    [isItemGroupBucket, itemGroupId, updateCombo, updateItemGroup],
  );

  const assignedElementsCardList = useMemo(() => {
    return bucket === 'items'
      ? itemGroupsAssignedItemsFactory(
          items,
          itemGroup?.items || {},
          (deletedItemId: number) => {
            const clonedItems = cloneDeep(itemGroup.items || {});
            delete clonedItems[deletedItemId];
            setValue({ items: clonedItems });
          },
        )
      : itemGroupsParentCombosFactoryCore(
          combos,
          itemGroup.parentComboIds || [],
        );
  }, [items, itemGroup, bucket, combos, setValue]);

  const closeModal = () => setOpenSelectionModal(false);

  const handleAssociate = (buttons: IItemSelection[]) => {
    if (!isItemGroupBucket) {
      return;
    }
    const clonedItems = cloneDeep(itemGroup.items || {});
    buttons.forEach((button, index) => {
      const id = button.id as number;
      clonedItems[id] = {
        ...ITEM_OF_ITEM_GROUP_INITIAL_STATE,
        itemId: id,
        sortPriority: index + 1 + itemIds.length,
      } as IItemOfItemGroup;
    });

    setValue({ items: clonedItems });
    closeModal();
  };

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = assignedElementsCardList.findIndex(
          ({ id }) => id === Number(active.id),
        );
        const newIndex = assignedElementsCardList.findIndex(
          ({ id }) => id === Number(over?.id),
        );

        const newItemsGroupOrder = arrayMove(
          assignedElementsCardList,
          oldIndex,
          newIndex,
        );

        const itemsTmp = newItemsGroupOrder.map(
          ({ id }) => itemGroup.items[id as number],
        );

        const newSortPriorities = itemsTmp
          .map(_itemGroup => _itemGroup.sortPriority)
          .sort((a, b) => a - b);

        const newCardsPositions = Object.assign(
          {},
          ...newItemsGroupOrder.map((child, index) => ({
            [child.id]: {
              index,
              sortPriority: newSortPriorities[index],
            },
          })),
        );

        updateLastItemsOfItemGroupOrder(newCardsPositions);
      }

      setActiveId(null);
    },
    [
      setActiveId,
      assignedElementsCardList,
      updateLastItemsOfItemGroupOrder,
      itemGroup.items,
    ],
  );

  return (
    <DraggableContainer
      draggableItems={itemIds}
      setActiveId={setActiveId}
      handleDragEnd={handleDragEnd}>
      <AssignedElements
        type={bucket}
        detailsScreenProps={{
          currentRelationsIds: itemIds,
          wantedEntity: bucket,
        }}
        noElementsMessage={t(
          `menuScreen.itemGroupsDetails.associations.${bucket}.emptyCardsMessage`,
        )}
        active={openSelectionModal}
        onAssociate={handleAssociate}
        onModalClose={closeModal}
        elements={assignedElementsCardList}
        subCardProps={{
          title:
            bucket === 'combos'
              ? t('loggedIn.menuModule.tabs.combos.combos')
              : t('loggedIn.menuModule.tabs.items'),
          csx,
          onEditMode:
            bucket === 'items' ? () => setIsEditMode(!isEditMode) : undefined,
          actionOptions:
            bucket === 'items'
              ? [
                  {
                    text: t(
                      'menuScreen.menuTypeDetails.assignedItems.actionButtons.addNewItem',
                    ),
                    handler: () =>
                      checkForChangesAndNavigateWeb(
                        () =>
                          addBreadcrumbLocationWeb({
                            action: BreadCrumbAction.ADD,
                            text: t(
                              'menuScreen.menuLocation.actionButtons.addNewItemTag',
                            ),
                            onPress: () => {
                              navigate('/menu/items/add');
                            },
                            pathURL: '/menu/items/add',
                          }),
                        onRefresh,
                        true,
                        false,
                        'items',
                      ),
                  },
                  {
                    text: t(
                      'menuScreen.menuTypeDetails.assignedItems.actionButtons.addExistingItem',
                    ),
                    handler: () => setOpenSelectionModal(true),
                  },
                ]
              : undefined,
        }}
        groupByMenuType={false}
        subCardDescription={
          <>
            <Divider direction="horizontal" />
            {bucket === 'items' && (
              <Box csx={{ padding: '20px 0px' }}>
                <Checkbox
                  checked={showUpCharge}
                  label={t(
                    'menuScreen.itemGroupsDetails.itemAssociationsSection.showUpCharge',
                  )}
                  onChange={setShowUpCharge}
                />
              </Box>
            )}
          </>
        }
        renderItem={item => {
          const typedItem = item as IItemOfItemGroup;

          return (
            <Box
              csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {bucket === 'items' ? (
                <>
                  <Card.ItemButton
                    {...item}
                    itemId={item.id as number}
                    draggable={isEditMode}
                    draggedItemId={activeId}
                    key={typedItem.id.toString() + typedItem.active.toString()}
                    switchValue={typedItem.active}
                    showSwitch
                    onSwitchChange={isActive =>
                      setValue({ active: isActive }, item.id as number)
                    }
                    nextPrice={typedItem.additionalPrice}
                    totalPrice={typedItem.additionalPrice}
                    showRemoveIcon={isEditMode && isMasterMode}
                    onRemoveClick={() => {
                      removeItemFromItemGroup(item.id as number);
                    }}
                  />
                  {showUpCharge && (
                    <Box
                      csx={{
                        position: 'relative',
                      }}>
                      <Box
                        csx={{
                          position: 'absolute',
                          top: '-3px',
                          justifyContent: 'center',
                          display: 'flex',
                          width: '100%',
                          left: 0,
                          right: 0,
                        }}>
                        <Icon
                          name="IoTriangleSharp"
                          size="20px"
                          color={theme.colors.lighterGrey}
                        />
                      </Box>
                      <Box
                        csx={{
                          backgroundColor: theme.colors.white,
                          width: 'inherit',
                          border: `1px solid ${theme.colors.lightGrey}`,
                          borderRadius: '8px',
                          alignItems: 'center',
                          display: 'flex',
                          marginTop: '15px',
                          flexDirection: 'column',
                          overflow: 'hidden',
                        }}>
                        <Box
                          csx={{
                            width: '100%',
                            backgroundColor: theme.colors.lighterGrey,
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '5px',
                            padding: '5px',
                          }}>
                          <Typography>
                            {t('menuScreen.itemGroupsDetails.basic.upCharge')}
                          </Typography>
                        </Box>
                        <Box
                          csx={{
                            padding: '5px',
                          }}>
                          <MoneyInput
                            value={typedItem.additionalPrice}
                            onValueChange={newValue =>
                              setValue(
                                { additionalPrice: newValue },
                                item.id as number,
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Card.Item
                  onClick={openInNewTab => {
                    const pathURL = `/menu/combos/combos/${item.id}`;
                    if (openInNewTab) return openNewTabWithOrgData(pathURL);

                    checkForChangesAndNavigateWeb(
                      () =>
                        addBreadcrumbLocationWeb({
                          action: BreadCrumbAction.NAV,
                          text:
                            typeof item?.title === 'string' ? item?.title : '',
                          onPress: () => navigate(pathURL),
                          pathURL,
                        }),
                      onRefresh,
                    );
                  }}
                  csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
                  isLink={getPathWithOrgData(`/menu/combos/combos/${item.id}`)}
                  {...item}
                />
              )}
            </Box>
          );
        }}
      />
    </DraggableContainer>
  );
};

export default AssignedItemsCombosSection;
