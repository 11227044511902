import AssociationsSection from '@app/components/LoggedIn/Menu/Ingredients/IngredientDetalis/AssociationsSection';
import BasicSection from '@app/components/LoggedIn/Menu/Ingredients/IngredientDetalis/BasicSection';
import InventorySection from '@app/components/LoggedIn/Menu/Ingredients/IngredientDetalis/InventorySection';
import Accordion from '@app/components/common/Accordion';
import useIsSet from '@app/hooks/useIsSet';
import { actionCreatorsMenuWeb } from '@app/state';
import { actionCreatorsMenu } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const BUCKET = 'ingredients';

const IngredientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loadMenuBucketChangeData, setActiveStateInBreadcrumbWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  useEffect(() => {
    if (!isSet)
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSet) return;
    setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'update');
    return () => {
      setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'reset');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isSet]);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <InventorySection bucket={BUCKET} />
        <AssociationsSection />
      </Accordion>
    )
  );
};

export default IngredientDetails;
