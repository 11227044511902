import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import { selectMenuTypes } from '@app/state/menu/menuSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IMenuTypeTableRow,
  menuTypesTableFactoryCore,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TaxColumn from '../../../Items/ItemsList/ItemsTable/TaxColumn';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const MenuTypesTable = ({ filterParams, t }: IMainScreenTable) => {
  const menuTypes = useSelector(selectMenuTypes);
  const taxes = useSelector(currentTaxesSettingsSelector);

  const onSort = useSort<IMenuTypeTableRow>('title');

  const menuTypesArray = menuTypesTableFactoryCore(menuTypes, taxes);

  const menuTypesArrayFiltered = useMemo(
    () =>
      filter<IMenuTypeTableRow>(
        menuTypesArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, menuTypesArray],
  );

  const customFilterFn = (row: Row<IMenuTypeTableRow>) => {
    return (
      row.depth > 0 ||
      menuTypesArrayFiltered.findIndex(
        menuType => menuType.id === row.original.id,
      ) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          return (
            <NameLink
              name={info.getValue()}
              path={`/menu/menus/${info.row.original.id}`}
              status={info.row.original.isActive}
              statusPath={`selectedMode.currentMenu.menuTypes.${info.row.original.id}.active`}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.menuTypesMain.table.headers.itemCount'),
        cell: info => {
          return (
            <Box csx={{ display: 'flex', width: '100%' }}>
              <Box csx={{ flex: 1 }}>{info.row.original.itemCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.comboCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.categoryCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.subcategoryCount}</Box>
            </Box>
          );
        },
        size: 230,
      }),
      columnHelper.accessor('enableSimpleMode', {
        header: t('menuScreen.menuTypeDetails.basic.enableSimpleMode'),
        cell: info => {
          return (
            <Box
              csx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {info.getValue() && (
                <Icon name="MdCheck" color="black" size="30px" />
              )}
            </Box>
          );
        },
        size: 200,
      }),
      columnHelper.accessor('enableRounding', {
        header: t('menuScreen.menuTypeDetails.basic.enableRounding'),
        cell: info => {
          return (
            <Box
              csx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {info.getValue() && (
                <Icon name="MdCheck" color="black" size="30px" />
              )}
            </Box>
          );
        },
        size: 200,
      }),
      columnHelper.accessor('disableCoursing', {
        header: t('menuScreen.menuTypeDetails.basic.disableCoursing'),
        cell: info => {
          return (
            <Box
              csx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {info.getValue() && (
                <Icon name="MdCheck" color="black" size="30px" />
              )}
            </Box>
          );
        },
        size: 200,
      }),
      columnHelper.accessor('taxes', {
        header: t('menuScreen.itemsMain.table.headers.taxes'),
        cell: info => {
          return <TaxColumn truncateLength={20} value={info.getValue()} />;
        },
        minSize: 200,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={menuTypesArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default MenuTypesTable;
