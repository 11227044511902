import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import {
  makeSelectItemData,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@app/components/common/Checkbox';
import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { useMemo } from 'react';

const SECTION_ID = SectionId.INVENTORY;

interface IInventorySection extends WithTranslation, IAccordionSection {
  bucket: 'ingredients' | 'modifierItems';
}

const InventorySection = ({ t, bucket, ...props }: IInventorySection) => {
  const dispatch = useDispatch();
  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const memoizedDataSelector = useMemo(
    () => makeSelectItemData(bucket, SECTION_ID),
    [bucket],
  );

  const { itemData } = useSelector(memoizedDataSelector);

  const isMasterMode = useSelector(selectIsMenuMasterMode);

  return (
    <AccordionSection
      title={t('loggedIn.commonTitles.inventory')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      disabledLayer={
        isMasterMode ? (
          <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
            <Icon size="30px" name="MdSubtitlesOff" color="textBlack" />
            <Typography variant="subtitle">
              {t('commonTexts.noLocationMode')}
            </Typography>
          </Box>
        ) : undefined
      }
      {...props}>
      <Checkbox
        // FIXME: Uncomment when item details is implemented
        // label={t(
        //   'menuScreen.itemDetails.inventorySection.86ItemWhenCurrentStockIs0',
        // )}
        label="Is 86'd"
        checked={itemData ? itemData?.is86ed : false}
        onChange={is86ed => setValue({ is86ed }, SECTION_ID)}
        csx={{
          padding: '0 0 10px 0',
        }}
      />
    </AccordionSection>
  );
};

export default InventorySection;
