import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';

const ToolTipExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Tooltip
      </Typography>
      <Grid columnGap={10}>
        <Grid.Item centered sm={2}>
          <ToolTip direction="top" content="top">
            <Button>top</Button>
          </ToolTip>
        </Grid.Item>
        <Grid.Item centered sm={2}>
          <ToolTip direction="left" content="left">
            <Button>left</Button>
          </ToolTip>
        </Grid.Item>
        <Grid.Item centered sm={2}>
          <ToolTip direction="right" content="right">
            <Button>right</Button>
          </ToolTip>
        </Grid.Item>
        <Grid.Item centered sm={2}>
          <ToolTip direction="bottom" content="bottom">
            <Button>bottom</Button>
          </ToolTip>
        </Grid.Item>
        <Grid.Item centered sm={2}>
          <ToolTip
            direction="bottom"
            content={
              <Box>
                <Typography>Tooltip with more content</Typography>
                <Typography variant="caption" color="darkRed">
                  More Content
                </Typography>
              </Box>
            }>
            <Button>Custom</Button>
          </ToolTip>
        </Grid.Item>
        <Grid.Item centered sm={2}>
          <ToolTip
            mode="click"
            direction="bottom"
            content={
              <Box>
                <Typography>Activated By Click</Typography>
              </Box>
            }>
            <Button>Click</Button>
          </ToolTip>
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default ToolTipExample;
