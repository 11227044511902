import BreadCrumb from '@app/components/common/BreadCrumb/BreadCrumb';
import startCase from 'lodash/startCase';
import { WithTranslation } from 'react-i18next';
import StickyContainer from '../../Menu/common/NavSwitch/StickyContainer';

interface IBreadCrumbSettings extends WithTranslation {
  title: string;
  currentPage?: string;
  onClick?: () => void;
  isSticky?: boolean;
  isSettings?: boolean;
  showActiveStyle?: boolean;
}

const BreadCrumbSettings = ({
  title,
  currentPage,
  onClick = () => null,
  isSticky = true,
  isSettings = true,
  showActiveStyle = true,
}: IBreadCrumbSettings) => {
  return (
    <StickyContainer isSticky={isSticky}>
      <BreadCrumb
        isLast={false}
        breadCrumb={{
          action: 'nav-main',
          text: title,
          onPress: onClick,
          pathURL: document.location.pathname,
        }}
        onClick={onClick}
        showActiveStyle={false}
        isSettings={isSettings}
      />
      <BreadCrumb
        isLast={true}
        breadCrumb={{
          action: 'edit',
          text: startCase(currentPage ?? ''),
          onPress: () => null,
          pathURL: document.location.pathname,
        }}
        onClick={() => null}
        showActiveStyle={showActiveStyle}
        isSettings={isSettings}
      />
    </StickyContainer>
  );
};
export default BreadCrumbSettings;
