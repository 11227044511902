import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import CustomizingReceipts from '@app/components/LoggedIn/Settings/Printing/Ticket/KitchenTicket/CustomizingReceipts';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  makeSelectSettingsData,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import { IGenericError } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ELEMENT_SPACING_OPTIONS,
  HEADER_BLANK_SPACE_OPTIONS,
  IKitchenTicketSettings,
  IPrepStationVersion,
  KITCHEN_PRINTER_COLOR_OPTIONS,
  KITCHEN_TICKET_FONT_SIZE_OPTIONS,
  KITCHEN_TICKET_PREP_STATION_VERSION,
  KITCHEN_TICKET_TEXT_POSITION_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  elementSpacingOptionsFactory,
  headerBlankSpaceOptionsFactory,
  kitchenPrinterColorOptionsFactory,
  kitchenTicketFontSizeOptionsFactory,
  textPositionOptionsFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModifierDisplay from './ModifierDisplay';
import PrepStationsSection from './PrepStationsSection';
import { PrepStationsSectionRef } from './PrepStationsSection/types';
import PrintTestTicket from './PrintTestTicket';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

const KitchenTicket = ({ t }: WithTranslation) => {
  const { loadSettingsBucketChangeData } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const { updateKitchenTicketSettings } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const [selectedPrepStationVersionId, setSelectedPrepStationVersionId] =
    useState(0);

  const prepStationsRef = useRef<PrepStationsSectionRef>(null);

  const { data: kitchenTicketData } = useSelector(getMemoizedItemData);

  const data =
    kitchenTicketData && selectedPrepStationVersionId
      ? kitchenTicketData.prepStationVersions[selectedPrepStationVersionId]
      : KITCHEN_TICKET_PREP_STATION_VERSION;

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'kitchenTicket',
  );

  const headerBlankSpaceOptions = useMemo(
    () => headerBlankSpaceOptionsFactory(t),
    [t],
  );
  const kitchenPrinterColorOptions = useMemo(
    () => kitchenPrinterColorOptionsFactory(t),
    [t],
  );

  const ticketOrderTagsFontSizeOptions = useMemo(
    () => kitchenTicketFontSizeOptionsFactory(t),
    [t],
  );

  const elementSpacingOptions = useMemo(
    () => elementSpacingOptionsFactory(),
    [],
  );

  const textPositionOptions = useMemo(() => textPositionOptionsFactory(t), [t]);
  const settingsErrors = useSelector(settingsErrorsSelector);

  const hasUpdateOrderNewText =
    (settingsErrors?.updateOrderNewText?.data as IGenericError)?.message || '';

  const hasUpdateOrderVoidText =
    (settingsErrors?.updateOrderVoidText?.data as IGenericError)?.message || '';

  useEffect(() => {
    if (!isSet) {
      loadSettingsBucketChangeData({
        setting: 'kitchenTicket',
      });
    } else if (
      !selectedPrepStationVersionId &&
      prepStationsRef.current &&
      Object.keys(kitchenTicketData?.prepStationVersions || {}).length
    ) {
      prepStationsRef.current?.setFirstActivePrepStation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, prepStationsRef.current, kitchenTicketData]);

  const setValue = (values: Partial<IPrepStationVersion>) => {
    updateKitchenTicketSettings(
      values,
      SECTION_ID,
      selectedPrepStationVersionId,
    );
  };

  return isSet ? (
    <>
      <HeaderTitle
        title={t('loggedIn.settingsModule.kitchenTicketSettings.title')}
        options={<PrintTestTicket />}
      />

      <Box csx={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
        <PrepStationsSection
          ref={prepStationsRef}
          selectedPrepStationVersionId={selectedPrepStationVersionId}
          setSelectedPrepStationVersionId={setSelectedPrepStationVersionId}
        />
        {!!selectedPrepStationVersionId && (
          <Accordion>
            <AccordionSection
              title={t('loggedIn.commonTitles.basic')}
              isExpanded
              titleTextContainerCsx={{ minWidth: '150px' }}>
              <Grid columnGap={15} rowGap={15}>
                <Grid.Item sm={6}>
                  <Card.SubCard
                    title={t(
                      'settingsModule.kitchenTicketSettings.ticketDisplay.title',
                    )}
                    csx={{ height: '100%' }}>
                    <Grid columnGap={15} rowGap={15}>
                      <Grid.Item md={12} lg={6}>
                        <Checkbox
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.printUnfiredItems',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.printUnfiredItemstoolTip',
                          )}
                          checked={data ? data.printUnfiredItems : false}
                          onChange={printUnfiredItems =>
                            setValue({ printUnfiredItems })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item md={12} lg={6}>
                        <Checkbox
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.showCourses',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.showCoursestoolTip',
                          )}
                          checked={data ? data.showCourses : false}
                          onChange={showCourses => setValue({ showCourses })}
                        />
                      </Grid.Item>
                      <Grid.Item md={12} lg={6}>
                        <Checkbox
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.showOrderTags',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.showOrderTagstooltip',
                          )}
                          checked={data ? data.showOrderTags : false}
                          onChange={showOrderTags =>
                            setValue({ showOrderTags })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item md={12} lg={6} />
                      <Grid.Item
                        md={12}
                        lg={6}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagsFontSize.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagsFontSize.label',
                          )}
                          data={[ticketOrderTagsFontSizeOptions]}
                          value={
                            KITCHEN_TICKET_FONT_SIZE_OPTIONS.indexOf(
                              data?.orderTagsFontSize,
                            ) + 1
                          }
                          onChange={newOrderTagsFontSize =>
                            setValue({
                              orderTagsFontSize:
                                KITCHEN_TICKET_FONT_SIZE_OPTIONS[
                                  newOrderTagsFontSize - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item
                        md={12}
                        lg={6}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagColors.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagColors.label',
                          )}
                          data={[kitchenPrinterColorOptions]}
                          value={
                            KITCHEN_PRINTER_COLOR_OPTIONS.indexOf(
                              data?.orderTagColors,
                            ) + 1
                          }
                          onChange={newOrderTagColors =>
                            setValue({
                              orderTagColors:
                                KITCHEN_PRINTER_COLOR_OPTIONS[
                                  newOrderTagColors - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item
                        md={12}
                        lg={6}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.headerBlankSpace.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.headerBlankSpace.label',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.headerBlankSpace.toolTip',
                          )}
                          data={[headerBlankSpaceOptions]}
                          value={
                            HEADER_BLANK_SPACE_OPTIONS.indexOf(
                              data?.headerBlankSpace,
                            ) + 1
                          }
                          onChange={newHeaderBlankSpace =>
                            setValue({
                              headerBlankSpace:
                                HEADER_BLANK_SPACE_OPTIONS[
                                  newHeaderBlankSpace - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item
                        md={12}
                        lg={6}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.toolTip',
                          )}
                          data={[elementSpacingOptions]}
                          value={
                            ELEMENT_SPACING_OPTIONS.indexOf(
                              data?.elementSpacing,
                            ) + 1
                          }
                          onChange={newHeaderElementSpacing =>
                            setValue({
                              elementSpacing:
                                ELEMENT_SPACING_OPTIONS[
                                  newHeaderElementSpacing - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                    </Grid>
                  </Card.SubCard>
                </Grid.Item>
                <Grid.Item sm={6}>
                  <ModifierDisplay
                    prepStationVersionId={selectedPrepStationVersionId}
                    hiddenElements={[
                      'onlyPrintModItemsWithPrice',
                      'printPriceForFreeModItems',
                    ]}
                  />
                </Grid.Item>
                <Grid.Item sm={12}>
                  <Card.SubCard title="Update Order Display">
                    <Grid columnGap={15} rowGap={15}>
                      <Grid.Item
                        sm={6}
                        lg={6}
                        xl={3}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderTextPosition.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderTextPosition.label',
                          )}
                          data={[textPositionOptions]}
                          value={
                            KITCHEN_TICKET_TEXT_POSITION_OPTIONS.indexOf(
                              data?.updateOrderTextPosition,
                            ) + 1
                          }
                          onChange={newUpdateOrderTextPosition =>
                            setValue({
                              updateOrderTextPosition:
                                KITCHEN_TICKET_TEXT_POSITION_OPTIONS[
                                  newUpdateOrderTextPosition - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item
                        sm={6}
                        lg={6}
                        xl={3}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Dropdown
                          label={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagColors.label',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.ticketDisplay.orderTagColors.label',
                          )}
                          data={[kitchenPrinterColorOptions]}
                          value={
                            KITCHEN_PRINTER_COLOR_OPTIONS.indexOf(
                              data?.updateOrderTextColor,
                            ) + 1
                          }
                          onChange={newUpdateOrderTextColor =>
                            setValue({
                              updateOrderTextColor:
                                KITCHEN_PRINTER_COLOR_OPTIONS[
                                  newUpdateOrderTextColor - 1
                                ],
                            })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item
                        sm={6}
                        lg={6}
                        xl={3}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Input
                          label={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderNewText',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderNewText',
                          )}
                          required
                          value={data?.updateOrderNewText}
                          onChange={({
                            currentTarget: { value: updateOrderNewText },
                          }) => setValue({ updateOrderNewText })}
                          error={hasUpdateOrderNewText ? true : false}
                          caption={hasUpdateOrderNewText}
                        />
                      </Grid.Item>
                      <Grid.Item
                        sm={6}
                        lg={6}
                        xl={3}
                        csx={{
                          justifyContent: 'flex-end',
                        }}>
                        <Input
                          label={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderVoidText',
                          )}
                          placeholder={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderVoidText',
                          )}
                          info={t(
                            'settingsModule.kitchenTicketSettings.updateOrderDisplay.updateOrderVoidTexttoolTip',
                          )}
                          required
                          value={data?.updateOrderVoidText}
                          onChange={({
                            currentTarget: { value: updateOrderVoidText },
                          }) => setValue({ updateOrderVoidText })}
                          error={hasUpdateOrderVoidText ? true : false}
                          caption={hasUpdateOrderVoidText}
                        />
                      </Grid.Item>
                    </Grid>
                  </Card.SubCard>
                </Grid.Item>
              </Grid>
            </AccordionSection>
            <AccordionSection
              title={t(
                'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTitle',
              )}
              isTitleUpperCase={false}
              titleTextContainerCsx={{ minWidth: '150px' }}>
              <Grid columnGap={15} rowGap={15}>
                <Grid.Item sm={12}>
                  <CustomizingReceipts
                    prepStationVersionId={selectedPrepStationVersionId}
                  />
                </Grid.Item>
              </Grid>
            </AccordionSection>
          </Accordion>
        )}
      </Box>
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default KitchenTicket;
