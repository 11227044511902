import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Switch from '@app/components/common/Switch';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRoleSettingsStore,
  RoleDefaultModuleType,
  SettingsSectionId,
  actionCreatorsSettings,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.MENU;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const MenuSettings = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const activeSwitch = (
    <Switch
      label={t(
        'settingsModule.roleDetailsSettings.allowAccessToThisModule',
      )}
      checked={data ? data.homeScreenPermissions.menuModuleAvailable : false}
      onChange={isActive => {
        const currentData = store.getState().settings.changeData
          .data as IRoleSettingsStore;

        const currentDefaultModule = currentData.defaultModule;

        const newDefaultModule =
          !isActive &&
          currentDefaultModule === RoleDefaultModuleType.MENU_MODULE
            ? RoleDefaultModuleType.HOME_MODULE
            : currentDefaultModule;

        const newHomeScreenPermissions = {
          ...currentData?.homeScreenPermissions,
          menuModuleAvailable: isActive,
        };
        setValue<IRoleSettingsStore>(
          {
            homeScreenPermissions: newHomeScreenPermissions,
            defaultModule: newDefaultModule,
          },
          SECTION_ID,
        );
        if (currentData?.homeScreenPermissions.menuModuleDefault && !isActive) {
          setValue<IRoleSettingsStore>(
            {
              homeScreenPermissions: {
                ...newHomeScreenPermissions,
                menuModuleDefault: false,
              },
            },
            SettingsSectionId.HOME,
          );
        } else {
          setValue<IRoleSettingsStore>({}, SettingsSectionId.HOME);
        }
      }}
    />
  );

  return (
    <AccordionSection
      title={t('loggedIn.settingsModule.roleDetailsSettings.menu.title')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...props}>
      {activeSwitch}
      <Divider csx={{ marginBlock: '20px' }} />
      <Checkbox
        label={t(
          'loggedIn.settingsModule.roleDetailsSettings.menu.accessMasterMenu',
        )}
        checked={data ? data.menuPermissions?.accessMasterMenu : false}
        onChange={accessMasterMenu =>
          setValue<IRoleSettingsStore>(
            {
              menuPermissions: {
                ...data.menuPermissions,
                accessMasterMenu,
              },
            },
            SECTION_ID,
          )
        }
      />
    </AccordionSection>
  );
};

export default MenuSettings;
