import AddOrUpdatePricingVersion from '@app/components/LoggedIn/Menu/ModifierItems/ModifierItemsDetails/PricingSection/AddOrUpdatePricingVersion';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import CardsContainer from '@app/components/common/CardsContainer';
import Icon from '@app/components/common/Icon';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { RootState } from '@app/state/store';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IModifierGroupPricingVersion,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export interface IItemCardModifierGroup {
  menuTypeId: number;
}

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
  selectedPricingVersionId: number;
  setSelectedPricingVersionId: (value: number) => void;
  isEditMode: boolean;
  editablePricingVersionData: {
    data: { name: string; id: number } | null;
    mode: 'edit' | 'add';
  };
  setEditablePricingVersion: (value: {
    data: {
      name: string;
      id: number;
    } | null;
    mode: 'add' | 'edit';
  }) => void;
  showAllPricing: boolean;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const modifierGroupPricingVersionFactory = (modifierGroupPricingVersions: {
  [key: number]: IModifierGroupPricingVersion;
}) => {
  // Generate the data for the pricing version dropdowns
  const formattedModifierGroupPricingVersions =
    Object.values(modifierGroupPricingVersions).length > 0
      ? (Object.values(modifierGroupPricingVersions).map(
          modifierGroupPricingVersion => ({
            id:
              modifierGroupPricingVersion?.modifierGroupPricingVersionId ||
              modifierGroupPricingVersion.id,
            title: modifierGroupPricingVersion.name,
            type: 'pos',
            onPress: () => null,
            onDelete: () => null,
          }),
        ) as IItemCard[])
      : ([] as IItemCard[]);

  return formattedModifierGroupPricingVersions;
};

const AssignedPricingVersions = ({
  csx,
  t,
  selectedPricingVersionId,
  setSelectedPricingVersionId,
  isEditMode,
  editablePricingVersionData,
  setEditablePricingVersion,
  showAllPricing,
}: IAssignedItemsCombosSection) => {
  // Redux
  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const isLoadingModal = useSelector(
    (state: RootState) => state.app.isLoadingModal,
  );

  const { removePricingVersionFromModifierGroup } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const theme = useTheme();

  const modifierGroupPricingVersions = useMemo(
    () => itemData?.modifierGroupPricingVersions || {},
    [itemData],
  );

  const assignedElementsCardList = useMemo(
    () => modifierGroupPricingVersionFactory(modifierGroupPricingVersions),
    [modifierGroupPricingVersions],
  );

  const pricingVersionsData = modifierGroupPricingVersionFactory(
    modifierGroupPricingVersions,
  );

  useEffect(() => {
    if (pricingVersionsData.length > 0 && pricingVersionsData[0].id) {
      setSelectedPricingVersionId(Number(pricingVersionsData[0].id) || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isLoadingModal.active &&
      pricingVersionsData.length > 0 &&
      pricingVersionsData[0].id
    ) {
      setSelectedPricingVersionId(Number(pricingVersionsData[0].id) || 0);
    }
  }, [isLoadingModal, pricingVersionsData, setSelectedPricingVersionId]);

  const handleEditPricingVersion = (pricingVersion: number) => {
    // Open edit pricing version modal
    setEditablePricingVersion({
      data: {
        name: modifierGroupPricingVersions[pricingVersion].name,
        id:
          modifierGroupPricingVersions[pricingVersion]
            ?.modifierGroupPricingVersionId ||
          modifierGroupPricingVersions[pricingVersion].id,
      },
      mode: 'edit',
    });
  };

  return (
    <>
      <AddOrUpdatePricingVersion
        pricingVersion={editablePricingVersionData.data}
        selectedPricingVersionId={selectedPricingVersionId ?? -1}
        setEditablePricingVersion={setEditablePricingVersion}
        setSelectedPricingVersionId={setSelectedPricingVersionId}
        mode={editablePricingVersionData.mode}
        modifier="groups"
        isDisableDelete={pricingVersionsData.length <= 1}
      />

      <Card.SubCardOptions
        extraOptionsCsx={{
          padding: 0,
        }}
        csx={csx}>
        {((showAllPricing && assignedElementsCardList.length === 1) ||
          assignedElementsCardList.length > 1) && (
          <CardsContainer
            data={assignedElementsCardList}
            noElementsMessage={t(
              'settingsModule.userDetailsSettings.associations.emptyRolesMessage',
            )}
            csx={{
              border: 'none',
              padding: 0,
            }}
            renderItem={pricingVersion => (
              <Box
                csx={{
                  position: 'relative',
                  marginBottom: '5px',
                }}>
                <Card.Item
                  isAnimated={false}
                  csx={{
                    borderColor: `${theme.colors.lightGrey} !important`,
                  }}
                  {...pricingVersion}
                  showRemoveIcon={isEditMode}
                  showEditIcon={!isEditMode && isMasterMode}
                  isSelected={pricingVersion.id === selectedPricingVersionId}
                  onClick={() => {
                    setSelectedPricingVersionId(Number(pricingVersion.id) || 0);
                  }}
                  onRemoveClick={() => {
                    if (!pricingVersion.id) return;

                    removePricingVersionFromModifierGroup(
                      Number(pricingVersion.id) || 0,
                      (newPricingVersionsIds: string[]) => {
                        !newPricingVersionsIds.includes(
                          `${selectedPricingVersionId}`,
                        ) &&
                          setSelectedPricingVersionId(
                            Number(newPricingVersionsIds[0]),
                          );
                      },
                    );
                  }}
                  onEditClick={() =>
                    handleEditPricingVersion(Number(pricingVersion?.id) || 0)
                  }
                />
                {pricingVersion.id === selectedPricingVersionId && (
                  <Box
                    csx={{
                      position: 'absolute',
                      justifyContent: 'center',
                      display: 'flex',
                      width: '100%',
                      left: 0,
                      right: 0,
                      bottom: '-18px',
                    }}>
                    <Icon
                      csx={{
                        rotate: '180deg',
                      }}
                      name="IoTriangleSharp"
                      size="20px"
                      color={theme.colors.semanticBlue}
                    />
                  </Box>
                )}
              </Box>
            )}
          />
        )}
      </Card.SubCardOptions>
    </>
  );
};

export default AssignedPricingVersions;
