import Modal from '@app/components/common/Modal';
import Typography from '@app/components/common/Typography';
import Grid from '@app/components/common/Grid';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { WithTranslation } from 'react-i18next';
import RoleButton from '../../MyOrganizations/LocationAndRoleSelectorModal/RoleButton';
import { itemsContainerStyles } from '../../MyOrganizations/LocationAndRoleSelectorModal/styles';
import { useSelector } from 'react-redux';
import {
  selectCurrentLocationName,
  selectLocationUserRoles,
} from '@app/state/selectors/appSelectors';
import { useAppDispatch } from '@app/state/store';
import { setLoggedInUserAndRole } from '@app/state/app/actions';
import Box from '@app/components/common/Box';
import { useEffect } from 'react';

interface IRoleSelectorModal extends WithTranslation {
  isActive: boolean;
  onModalClose: () => void;
}

const RoleSelectorModal = ({
  t,
  isActive,
  onModalClose,
}: IRoleSelectorModal) => {
  const handleModalClose = () => {
    onModalClose();
  };
  const dispatch = useAppDispatch();

  const roles = useSelector(selectLocationUserRoles);
  const locationName = useSelector(selectCurrentLocationName);

  useEffect(() => {
    if (roles.length === 1) {
      handleModalClose();
      dispatch(setLoggedInUserAndRole(roles[0].roleId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  return (
    <Modal
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '3px' }}>
            {t('loggedIn.myOrganizations.selectRoleForLocation')}
          </Typography>
          <Typography color="darkBlue">{locationName}</Typography>
        </Box>
      }
      size={600}
      dismissOnOutsideClick={false}
      showXCloser={false}
      isActive={isActive}
      onModalClose={handleModalClose}>
      <Grid rowGap={10} columnGap={10} csx={itemsContainerStyles}>
        {roles.length ? (
          roles.map(role => (
            <Grid.Item mb={6} sm={4} key={`role_${role.roleId}`}>
              <RoleButton
                name={role.name}
                onClick={() => {
                  handleModalClose();
                  dispatch(setLoggedInUserAndRole(role.roleId));
                }}
              />
            </Grid.Item>
          ))
        ) : (
          <Grid.Item mb={12} csx={displayFlexRowCenterStyles}>
            <Typography variant="body" fontWeight="medium">
              {t('loggedIn.myOrganizations.rolesSelectorModal.noRoles')}
            </Typography>
          </Grid.Item>
        )}
      </Grid>
    </Modal>
  );
};

export default RoleSelectorModal;
