import { useAnimate } from 'framer-motion';
import { TCsx } from '@emotion/react';
import { arrowContainerStyles, collapsibleStyles } from './styles';
import { useEffect } from 'react';
import Box from '../../Box';
import ListItem from '../ListItem';
import { IListItem } from '../ListItem/ListItem';
import Icon from '../../Icon';

interface ICollapsible {
  children: React.ReactNode;
  collapsibleKey?: string;
  isExpanded?: boolean;
  csx?: TCsx;
  listItemProps?: IListItem;
}

const Collapsible = ({
  collapsibleKey,
  children,
  isExpanded = false,
  listItemProps,
  csx,
}: ICollapsible) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      `.collapsible${collapsibleKey}`,
      {
        height: isExpanded ? 'auto' : 0,
      },
      { duration: 0.1 },
    );
    animate(
      `.arrow${collapsibleKey}`,
      { rotate: isExpanded ? 180 : 0 },
      { duration: 0.1 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <>
      <Box ref={scope}>
        {listItemProps && (
          <ListItem
            rightIcon={
              <Box
                csx={arrowContainerStyles}
                className={`arrow${collapsibleKey}`}>
                <Icon
                  name="MdChevronRight"
                  csx={{
                    marginTop: '2px',
                    rotate: '90deg',
                  }}
                />
              </Box>
            }
            {...listItemProps}
          />
        )}
        <Box className={`collapsible${collapsibleKey}`} csx={collapsibleStyles}>
          <Box csx={csx}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

export default Collapsible;
