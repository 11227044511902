import { MQ_MIN_SMALL, ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const inputWrapperStyles = () =>
  css({ display: 'flex', flexDirection: 'column', width: '100%' });

export const INPUT_HEIGHT = 90;
export const inputContainerStyles = (theme: Theme) =>
  css({
    height: `${INPUT_HEIGHT}px`,
    width: 'auto',
    borderRadius: '5px',
    backgroundColor:
      theme.mode === ThemeModes.DARK ? theme.colors.lightestGrey : 'white',
    border: `2px solid ${theme.colors.lightGrey}`,
    cursor: 'text',
    display: 'inline-flex',
    overflow: 'hidden',
    // css
    '&.active': {
      borderColor: theme.colors.semanticBlue,
    },
    '&.error': {
      borderColor: theme.colors.semanticRed,
      '> textarea, input': {
        color: theme.colors.semanticRed,
      },
    },
    '> button': {
      marginRight: '5px',
      padding: '10px',
      height: 'fit-content',
      borderRadius: '100%',
      alignSelf: 'center',
      transition: 'ease .3s',
      ':hover': {
        backgroundColor:
          theme.mode === ThemeModes.DARK
            ? theme.colors.lighterGrey
            : theme.colors.lightestGrey,
      },
    },
  });

export const inputPadding = 15;
export const inputStyles = (theme: Theme) =>
  css({
    resize: 'none',
    height: '100%',
    width: '100%',
    border: '0px',
    appearance: 'none',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    padding: `${inputPadding}px`,
    backgroundColor:
      theme.mode === ThemeModes.DARK ? theme.colors.lightestGrey : 'white',
    // text props
    color: theme.colors.text,
    fontSize: theme.fontSizes.body,
    fontWeight: theme.fontWeights.regular,
    // css
    '::placeholder': {
      color: theme.colors.semanticGrey,
    },
    '::-ms-reveal, ::-ms-clear': {
      display: 'none',
    },
    ':disabled': {
      backgroundColor: theme.colors.lightestGrey,
      color: theme.colors.lightGrey,
      cursor: 'not-allowed',
      '::placeholder': {
        color: theme.colors.lightGrey,
      },
    },
  });

export const labelStyles = (theme: Theme) =>
  css({
    marginBottom: '7px',
    display: 'inline-flex',
    // text props
    color: theme.colors.black,
    fontSize: theme.fontSizes.body,
    fontWeight: theme.fontWeights.medium,
    '&.disabled': {
      color: theme.colors.lightGrey,
      '> .required': {
        color: theme.colors.lightGrey,
      },
      '.infoButton': {
        button: {
          filter: 'none !important',
        },
        '.infoIcon': {
          cursor: 'not-allowed',
          backgroundColor: theme.colors.lighterGrey,
          svg: {
            fill: theme.colors.lightGrey,
          },
        },
      },
    },
    '.infoButton': {
      marginLeft: '8px',
      alignSelf: 'flex-start',
      '> span > svg': {
        fill: theme.colors.lightGrey,
      },
    },
    '> .required': {
      color: 'red',
      marginLeft: '8px',
    },
  });

export const captionStyles = (theme: Theme) =>
  css({
    color: theme.colors.darkGrey,
    fontSize: theme.fontSizes.caption2,
    fontWeight: theme.fontWeights.regular,
    marginTop: '5px',
  });

export const errorCaptionStyles = (theme: Theme) =>
  css(captionStyles(theme), {
    color: theme.colors.semanticRed,
  });

export const CAPTION_HEIGHT = 21;

export const emptyCaptionStyles = () =>
  css({
    display: 'block',
    height: '20px',
    [MQ_MIN_SMALL]: {
      height: `${CAPTION_HEIGHT}px`,
    },
  });
