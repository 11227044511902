import { tableLayoutSelectors } from '@westondev/tableturn-core';

export const {
  selectSelectedAction,
  selectSelectedTables,
  selectTables,
  selectServiceAreas,
  selectSelectedServiceAreas,
  selectSelectedServiceArea,
  selectRevenueCenters,
  selectSelectedElement,
} = tableLayoutSelectors;
