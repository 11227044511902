import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { IItem } from '@westondev/tableturn-core';

const ingredientAssignedItemsFactory = (
  items: Record<number, IItem>,
  itemIds: number[],
  handleDeleteItem: (n: number) => void,
): IItemCard[] =>
  itemIds
    .map((id: number): IItemCard | undefined => {
      const item = items[id];
      if (item)
        return {
          id: item.itemId,
          title: item.name,
          onRemoveClick: () => handleDeleteItem(item.itemId),
        };
    })
    .filter(value => !!value) as IItemCard[];

export default ingredientAssignedItemsFactory;
