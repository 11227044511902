import BlueDot from '@app/components/common/BlueDot';
import Box from '@app/components/common/Box';
import { IBox } from '@app/components/common/Box/Box';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import { css, TCsx, useTheme } from '@emotion/react';
import { formatMoney } from '@westondev/tableturn-core';
import { Children, ReactNode, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { IShowAllPricing, PartialIModifierGroupModifierItem } from '../types';
import { showAllPricingInputsStyles } from './styles';

type DataType = Omit<PartialIModifierGroupModifierItem, 'modifierItemId'>;

type IShowAllPricingInputs = Pick<
  IShowAllPricing,
  | 'isSizeItem'
  | 'isMultipleQuantity'
  | 'isEditable'
  | 'isMultiselect'
  | 'hasBorders'
  | 'inputContainerProps'
> &
  WithTranslation & {
    data: DataType;
    onChange?: (field: keyof DataType, value: number) => void;
    columnWidth?: number;
  };

const customInputContainerStyles = css({
  height: 35,
  borderWidth: '1px',
});

const ShowAllPricingInputs = ({
  isSizeItem,
  isMultipleQuantity,
  //isMultiselect,
  isEditable,
  hasBorders,
  t,
  inputContainerProps,
  data,
  onChange = () => null,
  columnWidth,
}: IShowAllPricingInputs) => {
  const theme = useTheme();

  const inputBackgroundColor = theme.colors.white;

  const [activeInput, setActiveInput] = useState('');

  return isSizeItem ? (
    <InputsContainer isActive={data.active} csx={showAllPricingInputsStyles}>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {t('menuScreen.itemDetails.modifierGroupsSection.included')}
        </HeaderContainer>
        <Box className="container">
          {data.override?.includedLightPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Lt
          </Typography>
        </Box>
        {activeInput === 'lgt1' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.includedLightPrice}
            onValueChange={includedLightPrice =>
              onChange('includedLightPrice', includedLightPrice)
            }
            onBlur={() => setActiveInput('')}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('lgt1')}>
              <Typography color="black">
                {formatMoney(null, data.includedLightPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
        <Box className="container">
          {data.override?.includedRegularPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Reg
          </Typography>
        </Box>
        {activeInput === 'reg1' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.includedRegularPrice}
            onValueChange={includedRegularPrice =>
              onChange('includedRegularPrice', includedRegularPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('reg1')}>
              <Typography color="black">
                {formatMoney(null, data.includedRegularPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}

        <Box className="container">
          {data.override?.includedExtraPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Ext
          </Typography>
        </Box>

        {activeInput === 'ext1' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.includedExtraPrice}
            onValueChange={includedExtraPrice =>
              onChange('includedExtraPrice', includedExtraPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('ext1')}>
              <Typography color="black">
                {formatMoney(null, data.includedExtraPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {t('menuScreen.itemDetails.modifierGroupsSection.additional')}
        </HeaderContainer>
        <Box className="container">
          {data.override?.additionalLightPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Lt
          </Typography>
        </Box>
        {activeInput === 'lgt2' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.additionalLightPrice}
            onValueChange={additionalLightPrice =>
              onChange('additionalLightPrice', additionalLightPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('lgt2')}>
              <Typography color="black">
                {formatMoney(null, data.additionalLightPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
        <Box className="container">
          {data.override?.additionalRegularPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Reg
          </Typography>
        </Box>
        {activeInput === 'reg2' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.additionalRegularPrice}
            onValueChange={additionalRegularPrice =>
              onChange('additionalRegularPrice', additionalRegularPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('reg2')}>
              <Typography color="black">
                {formatMoney(null, data.additionalRegularPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
        <Box className="container">
          {data.override?.additionalExtraPrice && <BlueDot />}
          <Typography variant="caption" color="lightBlue">
            Ext
          </Typography>
        </Box>
        {activeInput === 'ext2' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.additionalExtraPrice}
            onValueChange={additionalExtraPrice =>
              onChange('additionalExtraPrice', additionalExtraPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('ext2')}>
              <Typography color="black">
                {formatMoney(null, data.additionalExtraPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
    </InputsContainer>
  ) : isMultipleQuantity ? (
    <InputsContainer isActive={data.active} csx={showAllPricingInputsStyles}>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {data.override?.includedMasterPrice && <BlueDot />}
          {t('menuScreen.itemDetails.modifierGroupsSection.included')}
        </HeaderContainer>
        {activeInput === 'inc' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.includedMasterPrice}
            onValueChange={includedMasterPrice =>
              onChange('includedMasterPrice', includedMasterPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('inc')}>
              <Typography color="black">
                {formatMoney(null, data.includedMasterPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {data.override?.additionalMasterPrice && <BlueDot />}
          {t('menuScreen.itemDetails.modifierGroupsSection.additional')}
        </HeaderContainer>
        {activeInput === 'add' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.additionalMasterPrice}
            onValueChange={additionalMasterPrice =>
              onChange('additionalMasterPrice', additionalMasterPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('add')}>
              <Typography color="black">
                {formatMoney(null, data.additionalMasterPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
    </InputsContainer>
  ) : (
    <InputsContainer isActive={data.active}>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {data.override?.includedMasterPrice && <BlueDot />}
          {t('menuScreen.itemDetails.modifierGroupsSection.included')}
        </HeaderContainer>
        {activeInput === 'inc' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.includedMasterPrice}
            onValueChange={includedMasterPrice =>
              onChange('includedMasterPrice', includedMasterPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('inc')}>
              <Typography color="black">
                {formatMoney(null, data.includedMasterPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
      <ColumnContainer columnWidth={columnWidth} hasBorders={hasBorders}>
        <HeaderContainer>
          {data.override?.additionalMasterPrice && <BlueDot />}
          {t('menuScreen.itemDetails.modifierGroupsSection.additional')}
        </HeaderContainer>
        {activeInput === 'add' ? (
          <MoneyInput
            autoFocus={true}
            editable={isEditable}
            value={data.additionalMasterPrice}
            onValueChange={additionalMasterPrice =>
              onChange('additionalMasterPrice', additionalMasterPrice)
            }
            onBlur={() => {
              setActiveInput('');
            }}
            customInputContainerStyles={customInputContainerStyles}
          />
        ) : (
          <Box csx={{ width: '100%' }} {...inputContainerProps}>
            <InputBox
              csx={{ backgroundColor: inputBackgroundColor }}
              onClick={() => isEditable && setActiveInput('add')}>
              <Typography color="black">
                {formatMoney(null, data.additionalMasterPrice)}
              </Typography>
            </InputBox>
          </Box>
        )}
      </ColumnContainer>
    </InputsContainer>
  );
};

const InputBox = ({ csx, ...props }: IBox) => {
  return (
    <Box
      csx={theme => [
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 35,
          backgroundColor: theme.colors.lightestGrey,
          border: `2px solid ${theme.colors.lighterGrey}`,
        },
        csx,
      ]}
      {...props}
    />
  );
};

const InputsContainer = ({
  children,
  isActive,
  csx,
}: {
  children: ReactNode;
  isActive: boolean;
  csx?: TCsx;
  css?: never;
}) => {
  return (
    <Box
      csx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          opacity: isActive ? undefined : 0.5,
        },
        csx,
      ]}>
      {children}
    </Box>
  );
};

const ColumnContainer = ({
  children,
  columnWidth,
  hasBorders,
  csx,
}: {
  children: ReactNode;
  columnWidth?: number;
  hasBorders?: boolean;
  csx?: TCsx;
  css?: never;
}) => {
  const theme = useTheme();
  return (
    <Box
      csx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: columnWidth ? columnWidth : '100%',
          borderWidth: hasBorders ? 1 : 0,
          borderColor: theme.colors.lightGrey,
        },
        csx,
      ]}>
      {children}
    </Box>
  );
};

const HeaderContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      csx={theme => ({
        backgroundColor: theme.colors.lightestGrey,
        width: '100%',
        height: 25,
        display: 'flex',
        gap: '2px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      })}>
      {Children.map(children, child => (
        <Typography>{child}</Typography>
      ))}
    </Box>
  );
};

export default ShowAllPricingInputs;
