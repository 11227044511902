import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import ColorPicker from '@app/components/common/ColorPicker';
import Grid from '@app/components/common/Grid';
import ResetButton from '@app/components/common/ResetButton';
import useRootSelector from '@app/hooks/useRootSelector';
import { MQ_MAX_LARGE, MQ_MAX_MEDIUM } from '@app/theme/types';
import { useTheme } from '@emotion/react';
import {
  DEFAULT_SPECIAL_TAG_COLOR,
  FormMode,
  IComboMenuTypeVersion,
  IMenuTypeVersion,
  MassUpdateValues,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import BgAndTextColorPickers from '../../../common/BgAndTextColorPickers';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import RegisterDisplayDisplayCheckboxesSubsection from './RegisterDisplayDisplayCheckboxesSubsection';
import RegisterDisplayDisplayDropdownsSubsection from './RegisterDisplayDisplayDropdownsSubsection';
import RegisterDisplayGeneralSubsection from './RegisterDisplayGeneralSubsection';
import RegisterDisplayImagesSubsection from './RegisterDisplayImagesSubsection';
import RegisterDisplayNavigationSubsection from './RegisterDisplayNavigationSubsection';

const RegisterDisplaySection = ({
  t,
  menuTypeVersionId,
  mode,
  itemId,
  itemData,
  itemName,
  sectionId,
  isCombo,
  isDisabled,
  ...props
}: IItemDetailsSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const specialTags = useRootSelector(
    state => state.settings.selectedMode.currentSettings.register.specialTags,
  );

  // Local state

  const theme = useTheme();

  const backgroundColor = itemData?.backgroundColor || theme.colors.cardWhite;
  const textColor = itemData?.textColor || theme.colors.black;
  const kdsColor = itemData?.kdsColor || theme.colors.cardWhite;

  const imageUri =
    itemData && itemData.imageUrl ? (itemData.imageUrl as string) : undefined;
  const secondaryImageUri =
    itemData && (itemData as IMenuTypeVersion)?.secondaryImageUrl
      ? ((itemData as IMenuTypeVersion)?.secondaryImageUrl as string)
      : undefined;

  const selectedSpecialTagId = itemData ? itemData?.specialTagId : 0;

  const selectedSpecialTagObject = useMemo(
    () =>
      selectedSpecialTagId === null ? null : specialTags[selectedSpecialTagId],
    [selectedSpecialTagId, specialTags],
  );

  const onReset = (isKds = false) => {
    if (itemData) {
      if (!isKds)
        setValue(
          { backgroundColor: null, textColor: null },
          sectionId,
          menuTypeVersionId,
        );
      else setValue({ kdsColor: null }, sectionId, menuTypeVersionId);
    }
  };

  const onResetBackground = () => {
    if (itemData) {
      setValue({ backgroundColor: null }, sectionId, menuTypeVersionId);
    }
  };

  const onResetText = () => {
    if (itemData) {
      setValue({ textColor: null }, sectionId, menuTypeVersionId);
    }
  };

  const hideItemName = itemData
    ? isCombo
      ? (itemData as unknown as IComboMenuTypeVersion)?.hideComboName
      : (itemData as IMenuTypeVersion)?.hideItemName
    : false;

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  return (
    <AccordionSection
      title={t('menuScreen.itemDetails.registerDisplay.title')}
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      {...props}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}>
        <RegisterDisplayGeneralSubsection
          menuTypeVersionId={menuTypeVersionId}
          courseId={itemData?.courseId}
          allowSharing={itemData?.allowSharing}
          activeOnline={itemData?.activeOnline}
          delivery={itemData?.delivery}
          mode={mode}
          customizer={itemData?.customizer}
          isCombo={isCombo}
        />

        <RegisterDisplayNavigationSubsection
          menuTypeVersionId={menuTypeVersionId}
          enterItemDetails={itemData?.enterItemDetails}
          exitItemDetailsOnRequiredModCompletion={
            itemData?.exitItemDetailsOnRequiredModCompletion
          }
          doneIsForTable={itemData?.doneIsForTable}
          defaultNoSeat={itemData?.defaultNoSeat}
          isCombo={isCombo}
          mode={mode}
        />

        <Card.SubCard
          title={t(
            'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.title',
          )}
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
          <RegisterDisplayImagesSubsection
            menuTypeVersionId={menuTypeVersionId}
            mode={mode}
            isCombo={isCombo}
            imageUri={imageUri}
            secondaryImageUri={secondaryImageUri}
            itemName={itemName ?? ''}
          />

          <RegisterDisplayDisplayCheckboxesSubsection
            isCombo={isCombo}
            mode={mode}
            menuTypeVersionId={menuTypeVersionId}
            displayItemPriceOnRegister={
              isCombo
                ? (itemData as unknown as IComboMenuTypeVersion)
                    ?.displayComboPriceOnRegister
                : (itemData as IMenuTypeVersion)?.displayItemPriceOnRegister
            }
            displayPictureOnCard={itemData?.displayPictureOnCard}
            transparentBackground={itemData?.transparentBackground}
            hideItemName={hideItemName}
          />

          <RegisterDisplayDisplayDropdownsSubsection
            menuTypeVersionId={menuTypeVersionId}
            isCombo={isCombo}
            mode={mode}
            specialTagId={selectedSpecialTagId}
            customizerShape={(itemData as IMenuTypeVersion)?.customizerShape}
            customizer={itemData?.customizer}
          />
          <Grid>
            <Grid.Item
              sm={12}
              md={12}
              lg={6}
              xl={4}
              csx={{
                padding: '20px 10px 0 0',
                borderWidth: '1px 1px 0 0',
                borderColor: theme.colors.lightGrey,
                borderStyle: 'solid',
                [MQ_MAX_MEDIUM]: {
                  borderRightWidth: 0,
                  paddingBottom: '20px',
                },
              }}>
              <BgAndTextColorPickers
                backgroundColor={backgroundColor}
                textColor={textColor}
                onBackgroundColorChange={color =>
                  setValue(
                    { backgroundColor: color },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                onTextColorChange={color =>
                  setValue({ textColor: color }, sectionId, menuTypeVersionId)
                }
                onReset={() => onReset()}
                onResetBackground={onResetBackground}
                onResetText={onResetText}
                mode={mode}
              />
            </Grid.Item>

            <Grid.Item
              sm={12}
              md={12}
              lg={6}
              xl={4}
              csx={{
                padding: '20px',
                borderWidth: '1px 1px 0 0',
                borderColor: theme.colors.lightGrey,
                borderStyle: 'solid',
                [MQ_MAX_LARGE]: {
                  borderRightWidth: 0,
                },
              }}>
              <DetailsSubtitle>
                {t(
                  'menuScreen.itemDetails.registerDisplay.registerPreview.title',
                )}
              </DetailsSubtitle>

              <Box
                csx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Card.ItemButton
                  image={
                    itemData?.displayPictureOnCard
                      ? imageUri === MassUpdateValues.NO_CHANGES
                        ? undefined
                        : imageUri
                      : null
                  }
                  itemId={Number(itemId)}
                  isSelected={false}
                  customTag={
                    selectedSpecialTagObject && selectedSpecialTagObject.active
                      ? {
                          backgroundColor:
                            selectedSpecialTagObject.color ||
                            theme.colors[DEFAULT_SPECIAL_TAG_COLOR],
                          textColor: 'textWhite',
                          text: selectedSpecialTagObject.abbreviation,
                        }
                      : undefined
                  }
                  title={
                    hideItemName
                      ? ''
                      : itemData && 'posName' in itemData
                      ? itemData.posName
                      : mode === FormMode.MASS_UPDATE &&
                        textColor !== MassUpdateValues.NO_CHANGES
                      ? 'Mass Update Preview'
                      : ''
                  }
                  totalPrice={
                    itemData && mode !== FormMode.MASS_UPDATE
                      ? isCombo
                        ? (itemData as unknown as IComboMenuTypeVersion)
                            ?.comboBasePrice
                        : (itemData as IMenuTypeVersion)?.itemPrice
                      : 0
                  }
                  nextPrice={
                    itemData && mode !== FormMode.MASS_UPDATE
                      ? isCombo
                        ? (itemData as unknown as IComboMenuTypeVersion)
                            ?.comboBasePrice
                        : (itemData as IMenuTypeVersion)?.itemPrice
                      : 0
                  }
                  hidePrice={
                    itemData &&
                    (isCombo
                      ? !(itemData as unknown as IComboMenuTypeVersion)
                          ?.displayComboPriceOnRegister
                      : !(itemData as IMenuTypeVersion)
                          ?.displayItemPriceOnRegister)
                  }
                  containerCsx={{
                    minWidth: '140px',
                    maxWidth: '150px',
                  }}
                  boxProps={{
                    csx: {
                      backgroundColor:
                        backgroundColor === MassUpdateValues.NO_CHANGES ||
                        itemData?.transparentBackground
                          ? 'transparent'
                          : `${backgroundColor} !important`,
                    },
                  }}
                  textColor={textColor}
                  backgroundColor={backgroundColor}
                  headerTextProps={{
                    includedPrice: {
                      csx: { color: textColor },
                    },
                  }}
                />
              </Box>
            </Grid.Item>

            <Grid.Item
              sm={12}
              md={12}
              lg={12}
              xl={4}
              csx={{
                padding: '20px',
                borderWidth: '1px 0 0 0',
                borderColor: theme.colors.lightGrey,
                borderStyle: 'solid',
                [MQ_MAX_LARGE]: {
                  paddingLeft: '0',
                },
              }}>
              <DetailsSubtitle>
                {t('menuScreen.itemDetails.registerDisplay.kdsDisplay.title')}
              </DetailsSubtitle>
              <Box
                csx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '20px',
                }}>
                <ColorPicker
                  label={t(
                    'menuScreen.itemDetails.registerDisplay.kdsDisplay.kdsColor',
                  )}
                  info={t(
                    'menuScreen.itemDetails.registerDisplay.kdsDisplay.kdsColortoolTip',
                  )}
                  color={kdsColor}
                  onChange={color =>
                    setValue({ kdsColor: color }, sectionId, menuTypeVersionId)
                  }
                  showHexInput
                  onReset={() => onReset(true)}
                  showNoChanges={isMassUpdate}
                />

                <Box
                  csx={{
                    paddingBottom: '55px',
                  }}>
                  <ResetButton onClick={() => onReset(true)} />
                </Box>
              </Box>
            </Grid.Item>
          </Grid>
        </Card.SubCard>
      </Box>
    </AccordionSection>
  );
};

export default RegisterDisplaySection;
