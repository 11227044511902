import { store } from '@app/state/store';
import {
  CoreRootState,
  IDefaultMenuTypeTimes,
  IOperationalDayHours,
  ISpecialAvailableTimes,
  TOnlineAvailabilityTimes,
  TOnlineMenuTimes,
  getDayWithErrorsCore,
} from '@westondev/tableturn-core';

export const getDayWithErrors = (
  days:
    | IDefaultMenuTypeTimes
    | ISpecialAvailableTimes
    | IOperationalDayHours
    | TOnlineMenuTimes
    | TOnlineAvailabilityTimes,
) => getDayWithErrorsCore(days, store.getState() as CoreRootState);
