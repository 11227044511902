import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const storageKey = 'path-data';

const useScrollRestoration = (elementId: string) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const getScrollData = () => {
    const savedData = localStorage.getItem(storageKey);
    return savedData ? JSON.parse(savedData) : {};
  };

  // Function to save scroll position and pathname for the element in localStorage
  const saveScrollPosition = (position: number, pathname: string) => {
    const scrollData = getScrollData();

    // Update the scrollData object with the current element's data
    scrollData[pathname] = {
      ...scrollData[pathname],
      position,
      pathname,
    };
    // Save the updated scrollData back to localStorage
    localStorage.setItem(storageKey, JSON.stringify(scrollData));
  };

  useEffect(() => {
    const element = document.getElementById(elementId);

    if (!element) {
      return;
    }

    // Load the saved scroll data from localStorage
    const scrollData = getScrollData();

    // Check if there's a saved scroll position for this element and the current pathname
    if (
      scrollData[location.pathname] &&
      scrollData[location.pathname].pathname === location.pathname
    ) {
      const { position } = scrollData[location.pathname];
      // Add timeout to restore the scroll position
      setTimeout(() => {
        element.scrollTo({ behavior: 'smooth', top: position, left: 0 });
      }, 100);

      setScrollPosition(position);
    }

    const handleScroll = () => {
      const newScrollPosition = element.scrollTop;
      setScrollPosition(newScrollPosition);

      // Save the new scroll position and current pathname to localStorage
      saveScrollPosition(newScrollPosition, location.pathname);
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, location.pathname]);

  return { scrollPosition, pathname: location.pathname };
};

export default useScrollRestoration;
