import MoneyInput from '@app/components/common/MoneyInput';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ITaxSetting,
  ITaxTypeData,
  TaxType,
  VolumeUnitType,
  actionCreatorsSettingsChangeData,
  taxVolumeUnitDropdownFactory,
  unitToValue,
  valueToUnit,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxesId } from '../helpers';

interface ITaxTypeForm extends WithTranslation {
  taxData: ITaxSetting;
  updateRow: (
    newValue: number | string | ITaxTypeData | null,
    id: number,
    field: keyof ITaxSetting,
  ) => void;
  isDisabled: boolean;
}

const TaxTypeForm = ({ taxData, updateRow, isDisabled, t }: ITaxTypeForm) => {
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const settingsErrors = useSelector(settingsErrorsSelector);

  return (
    <Grid rowGap={20} columnGap={15}>
      {taxData.taxType === TaxType.VOLUMETRIC && (
        <>
          <Grid.Item mb={12} sm={6} md={6} xl={3}>
            <Dropdown
              label={t('settingsModule.taxesSettings.taxesTable.unit')}
              placeholder={t('commonTexts.placeholderDropdown')}
              required
              data={taxVolumeUnitDropdownFactory()}
              value={unitToValue[taxData?.typeData?.unit || VolumeUnitType.ML]}
              onChange={value =>
                updateRow(
                  {
                    ...taxData?.typeData,
                    unit: valueToUnit?.[value],
                  } as ITaxTypeData,
                  taxData?.id,
                  'typeData',
                )
              }
              isDisabled={isDisabled}
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={6} xl={3}>
            <MoneyInput
              label={t('settingsModule.taxesSettings.taxesTable.interval')}
              required
              placeholder="0.00"
              prefix=""
              precision={2}
              disabled={isDisabled}
              value={taxData?.typeData?.interval || 0}
              onValueChange={n =>
                updateRow(
                  {
                    ...taxData?.typeData,
                    interval: n,
                  } as ITaxTypeData,
                  taxData?.id,
                  'typeData',
                )
              }
              caption={
                settingsErrors[getTaxesId(taxData.id, 'interval')]?.data
                  ?.message
              }
              error={Boolean(
                settingsErrors[getTaxesId(taxData.id, 'interval')]?.data
                  ?.message,
              )}
              onFocus={() =>
                clearSettingsError(getTaxesId(taxData.id, 'interval'))
              }
            />
          </Grid.Item>
        </>
      )}
      <Grid.Item mb={12} sm={6} md={6} xl={3}>
        <MoneyInput
          label={t('settingsModule.taxesSettings.taxesTable.rate')}
          required
          placeholder="$0.00"
          precision={taxData.taxType === TaxType.PERCENT ? 3 : 2}
          suffix={taxData.taxType === TaxType.PERCENT ? '%' : ''}
          prefix={taxData.taxType === TaxType.PERCENT ? '' : '$'}
          disabled={isDisabled}
          value={taxData?.typeData?.rate ?? 0}
          onValueChange={n =>
            updateRow(
              {
                ...taxData?.typeData,
                rate: n,
              } as ITaxTypeData,
              taxData?.id,
              'typeData',
            )
          }
          caption={
            settingsErrors[getTaxesId(taxData.id, 'rate')]?.data?.message
          }
          error={Boolean(
            settingsErrors[getTaxesId(taxData.id, 'rate')]?.data?.message,
          )}
          onFocus={() => clearSettingsError(getTaxesId(taxData.id, 'rate'))}
        />
      </Grid.Item>
    </Grid>
  );
};

export default TaxTypeForm;
