import ImageNotFound from '@app/assets/img/imageNotFound.jpg';
import { TCsx } from '@emotion/react';
import { MassUpdateValues } from '@westondev/tableturn-core';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { WithTranslation } from 'react-i18next';
import Box from '../Box';
import Button from '../Button';
import Divider from '../Divider';
import Icon from '../Icon';
import Modal from '../Modal';
import Typography from '../Typography';
import { imageUploaderStyles, uploadContainerStyles } from './styles';

interface IImageUploader extends WithTranslation {
  modalProps?: {
    title: string;
    subtitle: string;
  };
  source?: string | null;
  onChange: (uri: string | null) => void;
  csx?: TCsx;
  uploadButtonText?: string;
  deleteButtonText?: string;
  showNoChanges?: boolean;
}
const ImageUploader = ({
  modalProps,
  source,
  t,
  onChange,
  csx,
  uploadButtonText,
  deleteButtonText,
  showNoChanges,
}: IImageUploader) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isModalActive, setIsModalActive] = useState(false);

  const [imgSrc, setImgSrc] = useState<string>(source || '');

  useEffect(() => {
    setImgSrc(source || '');
  }, [source]);

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.stopPropagation();
    setImgSrc(ImageNotFound);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return onChange(null);

    onChange(URL.createObjectURL(e.target.files[0]));
  };

  const handleDownload = () => {
    if (imgSrc.split(':')[0] === 'blob') {
      window.open(imgSrc, '_blank');
    } else {
      const a = document.createElement('a');
      a.href = imgSrc;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
      <Box csx={[imageUploaderStyles, csx]}>
        <Button
          variant="transparent"
          className="imageButton"
          onClick={() => setIsModalActive(true)}
          csx={{ gap: '8px' }}>
          {source && (
            <img
              css={{ width: 80, objectFit: 'contain' }}
              src={imgSrc}
              onError={handleError}
            />
          )}
          <Icon name="MdFileUpload" color="darkGrey" />
          <Typography color="darkGrey">
            {t('components.imageUploader.uploadFile')}
          </Typography>
        </Button>
      </Box>

      <Modal
        title={
          modalProps?.title ? (
            <Box csx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
              <Typography variant="subtitle" fontWeight="medium">
                {modalProps?.title}
              </Typography>
              <Typography variant="body" color="semanticBlue">
                {modalProps?.subtitle}
              </Typography>
            </Box>
          ) : undefined
        }
        size={650}
        isActive={isModalActive}
        onModalClose={() => setIsModalActive(false)}
        footer={
          <Button
            csx={{ minWidth: '120px' }}
            variant="primary"
            onClick={() => setIsModalActive(false)}>
            {t('commonButtons.done')}
          </Button>
        }>
        <Box csx={uploadContainerStyles}>
          <img className="imagePreview" src={imgSrc} onError={handleError} />
          <Box className="uploadArea">
            <Box className="uploadButtons">
              <Button
                variant="active"
                csx={uploadButtonText ? { height: 70 } : undefined}
                icon={<Icon name="MdFileUpload" />}
                onClick={() => inputRef.current && inputRef.current?.click()}>
                {uploadButtonText || t('components.imageUploader.uploadFile')}
              </Button>
              <input
                ref={inputRef}
                type="file"
                css={{ display: 'none' }}
                accept="image/*"
                onChange={handleChange}
              />
              <Button
                variant="danger"
                csx={deleteButtonText ? { height: 70 } : undefined}
                icon={<Icon name="MdDeleteForever" />}
                onClick={() => onChange(null)}>
                {deleteButtonText || t('components.imageUploader.deleteFile')}
              </Button>
              {showNoChanges ? (
                <Button
                  variant="secondary"
                  onClick={() => onChange(MassUpdateValues.NO_CHANGES)}>
                  {t('commonTexts.noChanges')}
                </Button>
              ) : (
                <Button
                  disabled={imgSrc === ImageNotFound}
                  variant="secondary"
                  icon={<Icon name="MdDownload" />}
                  onClick={() => handleDownload()}>
                  {t('components.imageUploader.downloadFile')}
                </Button>
              )}
            </Box>
            <Divider />
            <Typography color="darkGrey" align="center">
              {t('components.imageUploader.formatsAllowed')}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImageUploader;
