import Box from '@app/components/common/Box';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrinterCenterTrigger from '../../PrinterCenter/PrinterCenterTrigger';
import { navBarButtonsStyles } from './styles';
import UserDropdown from './UserDropdown';

const NavBarButtons = () => {
  const location = useLocation();
  const isMyOrganizationsPage = location.pathname === '/my-organizations';

  const [showModal, setShowModal] = useState(false);

  return (
    <Box csx={navBarButtonsStyles}>
      <Box className="navBarButtons">
        {!isMyOrganizationsPage && (
          <PrinterCenterTrigger
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <UserDropdown isMyOrganizationsPage={isMyOrganizationsPage} onClick={() => setShowModal(false)} />
      </Box>
    </Box>
  );
};

export default NavBarButtons;
