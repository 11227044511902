import useFiltersStateRestoration from '@app/hooks/useFiltersRestoration';
import { scrollBarStyles } from '@app/theme/commonStyles';
import { BREAKPOINTS } from '@app/theme/themes';
import { MQ_MAX_SMALL } from '@app/theme/types';
import { SortOptions, filter, useSort } from '@westondev/tableturn-core';
import { t } from 'i18next';
import { isNumber } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ActionButtons from '../ActionButtons';
import { IActionButtons } from '../ActionButtons/types';
import Box from '../Box';
import Button from '../Button';
import Card from '../Card';
import Divider from '../Divider';
import Dropdown from '../Dropdown';
import {
  ELabelVariants,
  TLabelVariants,
  TSingleSelectDropdown,
} from '../Dropdown/SingleSelectDropdown/types';
import FiltersDropdown from '../FiltersDropdown';
import Grid from '../Grid';
import Icon from '../Icon';
import NoElementsFound from '../NoElementsFound';
import SearchInput from '../SearchInput';
import SelectionModal from '../SelectionModal';
import { IItemSelection } from '../SelectionModal/GenericSelectionModal/types';
import SortButton from '../SortButton';
import ToolTip from '../ToolTip';

type PartialParam = Partial<{
  equal: number;
  greaterThan: number;
  includes: number;
}>;

type IParam = undefined | string | number | PartialParam;

export interface IFilterParams {
  title: string;
  categoryId: number;
  subcategoryId: number;
  menuTypeId: number;
  [key: string]: IParam;
}
export type ICategoryFilteredScreenContainerDropdown<T> = {
  id: keyof T;
  data: TSingleSelectDropdown['data'];
  getData?: (params: IFilterParams) => TSingleSelectDropdown['data'];
  label: TSingleSelectDropdown['label'];
  labelVariant?: TLabelVariants;
  defaultValue?: number;
  dataExample: unknown;
  getIsDisabled?: (
    data: TSingleSelectDropdown['data'],
    params: IFilterParams,
  ) => boolean;
  isEditable?: TSingleSelectDropdown['isEditable'];
};
export type ICategoryFilteredScreenContainer<
  T extends {
    title: string;
  },
> = {
  data: T[];
  dropdowns?: ICategoryFilteredScreenContainerDropdown<T>[];
  sortAvailable?: boolean;
  renderItem: (item: T, index: number) => JSX.Element;
  breakpoint?: keyof typeof BREAKPOINTS;
  optionList?: IActionButtons['optionList'] | React.ReactNode;
  optionDisabled?: boolean;
  onOptionClickDisabled?: IActionButtons['onClickDisabled'];
  noItemsText?: string;
  menuTypeOnChange?: (dropdown: keyof T, value: number) => void;
  menusSet: Set<number>;
  customButtons?: React.ReactNode;
  children?: (
    filterParams: IFilterParams,
    sort: SortOptions | null,
  ) => React.ReactNode;
  showChildren?: boolean;
  groupFilters?: boolean;
  optionalFiltersMatch?: string[];
};
const EMPTY_ARRAY = [] as ICategoryFilteredScreenContainerDropdown<{
  title: string;
}>[];

const EMPTY_FILTER_PARAMS = {
  title: '',
  categoryId: 0,
  subcategoryId: 0,
  menuTypeId: 0,
};
const CategoryFilteredScreenContainer = <
  T extends {
    title: string;
  },
>({
  data,
  dropdowns = EMPTY_ARRAY,
  sortAvailable,
  renderItem,
  breakpoint = 'small',
  optionList,
  optionDisabled,
  onOptionClickDisabled,
  noItemsText,
  menuTypeOnChange,
  menusSet,
  customButtons,
  children,
  showChildren,
  groupFilters,
  optionalFiltersMatch,
}: ICategoryFilteredScreenContainer<T>) => {
  const [_showTooltip, setShowTooltip] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [sort, setSort] = useState<null | SortOptions>(null);
  const onSort = useSort<T>('title');
  const [sortedList, setSortedList] = useState<T[]>([]);
  const [filterParams, setFilterParams] =
    useState<IFilterParams>(EMPTY_FILTER_PARAMS);

  const { seFiltersState, filtersState } = useFiltersStateRestoration();

  const showTooltip = groupFilters || _showTooltip;

  const [openMenusModal, setOpenMenusModal] = useState(false);

  useEffect(() => {
    setSortedList(
      filter<T>(
        data,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        sort,
        onSort,
        optionalFiltersMatch,
      ),
    );
    seFiltersState({ ...filterParams, sort });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, sort, data, optionalFiltersMatch]);

  useEffect(() => {
    if (filtersState && Object.keys(filtersState).length) {
      const { sort: _sort, ...filters } = filtersState;
      setFilterParams(filters);
      setInputValue(filtersState.title || '');
      setSort(_sort || null);
      menuTypeOnChange &&
        menuTypeOnChange('menuTypeId' as keyof T, filters.menuTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth > parseFloat(BREAKPOINTS[breakpoint as 'small'].max)
      ) {
        setShowTooltip(false);
      } else if (showTooltip === false) {
        setShowTooltip(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showTooltip, breakpoint]);

  const handleActiveFilter = useCallback(
    (v: number, id: keyof T, dataValue: unknown) => {
      if (!v)
        return setFilterParams(prev => ({
          ...prev,
          [id]: undefined,
        }));
      return setFilterParams(prev => ({
        ...prev,
        ...(id === 'categoryId'
          ? {
              categoryId: v,
              subcategoryId: 0,
            }
          : id === 'menuTypeId'
          ? {
              menuTypeId: v,
              categoryId: 0,
              subcategoryId: 0,
            }
          : {
              [id]:
                id === 'subcategoryId'
                  ? v
                  : Array.isArray(dataValue)
                  ? { includes: v }
                  : { equal: v },
            }),
      }));
    },
    [],
  );

  const closeTooltip = () => {
    setIsTooltipActive(false);
  };

  useEffect(() => {
    if (!dropdowns.length) {
      return setFilterParams(prev => ({
        title: prev.title,
        categoryId: 0,
        subcategoryId: 0,
        menuTypeId: 0,
      }));
    }
  }, [dropdowns]);

  const hideSortInFilters = groupFilters && !_showTooltip;

  const isSomeFilterActive = dropdowns.some(
    dropdown => !!filterParams[dropdown.id as string],
  );

  const renderActionButtons = Array.isArray(optionList) ? (
    <Box
      csx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
      <ActionButtons
        optionList={optionList}
        disabled={optionDisabled}
        onClickDisabled={onOptionClickDisabled}
      />
    </Box>
  ) : (
    optionList
  );

  //FIXME: try to change the values of the dropdowns to fill the remaining space
  return (
    <>
      <SelectionModal
        type="menuTypes"
        detailsScreenProps={{
          currentRelationsIds: [],
          wantedEntity: 'menuTypes',
          filterFunction: (newElements: string[]) =>
            newElements.filter(id => menusSet.has(Number(id))),
        }}
        onAssociate={(buttons: IItemSelection[]) => {
          if (menuTypeOnChange) {
            menuTypeOnChange('menuTypeId' as keyof T, Number(buttons[0].id));

            handleActiveFilter(
              Number(buttons[0].id),
              'menuTypeId' as keyof T,
              0,
            );
          }

          setOpenMenusModal(false);
        }}
        description={t('menuScreen.selectionModal.menuTypes.emptySectionText')}
        active={openMenusModal}
        onModalClose={() => {
          setOpenMenusModal(false);
        }}
        associateOnSelect
        showDescriptionPanel={false}
        scrollCardSectionNumberOfColumns={6}
        showSelectedButtons={false}
        btnSuccessText={t('commonButtons.done')}
      />
      <Card
        csx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
        }}>
        <Grid columnGap={15} rowGap={15}>
          <Grid.Item mb={12} csx={{ padding: '15px' }}>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item
                mb={8}
                sm={7}
                md={4}
                lg={4}
                mbOffset={0}
                smOffset={showTooltip && customButtons ? 0 : 1}
                mdOffset={0}>
                <Box
                  csx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    height: '100%',
                  }}>
                  <SearchInput
                    onChange={setInputValue}
                    debounceTimeout={300}
                    onDebounce={text =>
                      setFilterParams(prev => ({ ...prev, title: text }))
                    }
                    value={inputValue}
                  />
                </Box>
              </Grid.Item>

              <Grid.Item
                csx={{
                  display:
                    (!showTooltip || hideSortInFilters) && sortAvailable
                      ? undefined
                      : 'none !important',
                }}
                mb={1}
                mbOffset={0}
                mdOffset={
                  showTooltip
                    ? 0
                    : 5 - dropdowns.length * 4 < 0
                    ? 0
                    : 5 - dropdowns.length * 4
                }
                lgOffset={
                  showTooltip
                    ? 0
                    : 6 - dropdowns.length * 3 < 0
                    ? 0
                    : 6 - dropdowns.length * 3
                }
                xlOffset={
                  showTooltip
                    ? 0
                    : 6 - dropdowns.length * 2 < 0
                    ? 0
                    : 6 - dropdowns.length * 2
                }>
                <Box
                  csx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}>
                  <SortButton onSort={sortOption => setSort(sortOption)} />
                </Box>
              </Grid.Item>

              {customButtons}
              {showTooltip && !customButtons && (
                <Grid.Item
                  csx={{
                    [MQ_MAX_SMALL]: {
                      display: 'none',
                    },
                  }}
                  sm={0}
                  md={1}
                  lg={1}
                  xl={2}
                />
              )}
              <>
                {!showTooltip &&
                  dropdowns.map((dropdown, index) => {
                    const dropdownData =
                      dropdown.getData?.(filterParams) ?? dropdown.data;

                    const isDisabled =
                      dropdown.getIsDisabled?.(dropdownData, filterParams) ??
                      false;

                    return (
                      <Grid.Item
                        key={`dropdown-${index}`}
                        mb={2}
                        sm={3}
                        md={4}
                        lg={3}
                        xl={2}>
                        <Box
                          csx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            height: '100%',
                          }}>
                          <Dropdown
                            label={dropdown.label}
                            labelVariant={
                              dropdown.labelVariant || ELabelVariants.INSIDE
                            }
                            data={dropdownData}
                            isDisabled={isDisabled}
                            isEditable={dropdown.isEditable}
                            value={
                              isNumber(filterParams[dropdown.id] as IParam)
                                ? (filterParams[dropdown.id] as number)
                                : (filterParams[dropdown.id] as PartialParam)
                                    ?.equal ||
                                  (filterParams[dropdown.id] as PartialParam)
                                    ?.includes ||
                                  0
                            }
                            onActive={() => {
                              if (dropdown.id === 'menuTypeId') {
                                setOpenMenusModal(true);
                              }
                            }}
                            onChange={value => {
                              handleActiveFilter(
                                value,
                                dropdown.id,
                                dropdown.dataExample,
                              );
                            }}
                            csx={{ minWidth: 0 }}
                          />
                        </Box>
                      </Grid.Item>
                    );
                  })}
              </>
              {showTooltip && (
                <Grid.Item
                  csx={{ justifyContent: 'center' }}
                  mb={12}
                  sm={customButtons ? 6 : 4}
                  md={customButtons ? 12 : 7}
                  lg={3}
                  xl={2}>
                  <Grid columnGap={10} rowGap={20}>
                    <Grid.Item
                      mb={6}
                      sm={6}
                      md={customButtons ? 3 : 6}
                      lg={6}
                      xl={6}>
                      <ToolTip
                        isToolTipActive={isTooltipActive}
                        onClickOutside={closeTooltip}
                        content={
                          <FiltersDropdown
                            sortValue={sort}
                            hideSort={hideSortInFilters}
                            data={dropdowns.map(dropdown => ({
                              label: dropdown.label,
                              value: isNumber(
                                filterParams[dropdown.id] as IParam,
                              )
                                ? (filterParams[
                                    dropdown.id as keyof IFilterParams
                                  ] as number)
                                : (filterParams[dropdown.id] as PartialParam)
                                    ?.equal ||
                                  (filterParams[dropdown.id] as PartialParam)
                                    ?.includes ||
                                  0,
                              data:
                                dropdown.getData?.(filterParams) ??
                                dropdown.data,
                              onChange: value => {
                                handleActiveFilter(
                                  value,
                                  dropdown.id,
                                  dropdown.dataExample,
                                ),
                                  menuTypeOnChange &&
                                    menuTypeOnChange(dropdown.id, value);
                              },
                              defaultValue: dropdown.defaultValue,
                            }))}
                            onSortChange={value => {
                              setSort(value as SortOptions);
                            }}
                            onDone={closeTooltip}
                          />
                        }
                        mode="click"
                        dismissOnContentClick={false}>
                        <Button
                          variant={isSomeFilterActive ? 'active' : 'secondary'}
                          csx={{ width: '100%' }}
                          icon={<Icon name="MdFilterAlt" />}
                          onClick={() => setIsTooltipActive(true)}
                        />
                      </ToolTip>
                    </Grid.Item>
                    <Grid.Item
                      mb={6}
                      sm={6}
                      md={customButtons ? 3 : 6}
                      lg={6}
                      xl={6}>
                      {renderActionButtons}
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              )}
              {!showTooltip && (
                <Grid.Item mb={2} sm={2} md={2} lg={1}>
                  {renderActionButtons}
                </Grid.Item>
              )}
            </Grid>
          </Grid.Item>
        </Grid>
        <Divider />
        {children && (
          <Box
            csx={{
              display: showChildren ? undefined : 'none',
              overflowY: 'auto',
            }}>
            {children(filterParams, sort)}
          </Box>
        )}
        {!showChildren && (
          <Box
            id="items-list"
            csx={[
              scrollBarStyles,
              {
                padding: '15px',
                overflowY: 'auto',
              },
            ]}>
            <Grid columnGap={15} rowGap={15}>
              {sortedList.length > 0 ? (
                sortedList.map((element, index) => (
                  <Grid.Item
                    mb={6}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={2}
                    key={`element-${index}`}>
                    {renderItem(element, index)}
                  </Grid.Item>
                ))
              ) : noItemsText ? (
                <Grid.Item mb={12}>
                  <NoElementsFound
                    text={noItemsText}
                    csx={{
                      padding: '30px',
                    }}
                    showBorder
                  />
                </Grid.Item>
              ) : null}
            </Grid>
          </Box>
        )}
      </Card>
    </>
  );
};
export default CategoryFilteredScreenContainer;
