import Box from '@app/components/common/Box';
import Tools from '../Tools';
import LayoutGrid from '../LayoutGrid';
import ElementsList from './ElementsList';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import useRootSelector from '@app/hooks/useRootSelector';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import TableLayoutSideBar from '../TableLayoutSideBar';
import TableLayoutBottomSheet from '../TableLayoutBottomSheet';
import { tableLayoutCreatorStyles } from './styles';
import { useTheme } from '@emotion/react';

const TableLayoutCreator = () => {
  // Redux
  const { gridWidth, gridHeight } = useRootSelector(
    state => state.tableLayout.toolSettings,
    shallowEqual,
  );

  // Local state
  const [containerRect, setContainerRect] = useState<DOMRect | null>(null);
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const gridSizes = {
    width: gridWidth,
    height: gridHeight,
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerRect(containerRef.current.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    const handleResizeLayout = () => {
      if (containerRef.current) {
        setContainerRect(containerRef.current.getBoundingClientRect());
      }
    };

    window.addEventListener('resize', handleResizeLayout);
    return () => {
      window.removeEventListener('resize', handleResizeLayout);
    };
  }, []);

  return (
    <Box ref={containerRef} csx={tableLayoutCreatorStyles}>
      {containerRect && (
        <TransformWrapper
          centerOnInit
          minScale={0.4}
          maxScale={1}
          limitToBounds={false}
          doubleClick={{ disabled: true }}
          panning={{
            allowLeftClickPan: false,
            velocityDisabled: true,
            allowRightClickPan: false,
          }}>
          <Box className="container">
            <Tools
              layoutStartPosition={
                containerRect.height / 2 - gridSizes.height / 2
              }
              containerRect={containerRect}
              gridWidth={gridSizes.width}
              gridHeight={gridSizes.height}
            />
            <Box className="layoutAndSidebarContainer">
              <TransformComponent
                contentProps={{
                  id: 'layoutArea',
                }}
                wrapperStyle={{
                  display: 'flex',
                  flexGrow: 1,
                  width: '100%',
                  height: '100%',
                  backgroundColor: theme.colors.lightestExtraGrey,
                }}
                contentStyle={{
                  width: gridSizes.width,
                  height: gridSizes.height,
                  position: 'absolute',
                  backgroundColor: 'white',
                }}>
                <LayoutGrid />
                <ElementsList />
              </TransformComponent>
              <TableLayoutSideBar />
            </Box>
            <TableLayoutBottomSheet />
          </Box>
        </TransformWrapper>
      )}
    </Box>
  );
};

export default TableLayoutCreator;
