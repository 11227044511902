import TaxesSection from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/TaxesSection';
import AssociationsSection from '@app/components/LoggedIn/Menu/Subcategories/SubcategoryDetails/AssociationsSection';
import BasicSection from '@app/components/LoggedIn/Menu/Subcategories/SubcategoryDetails/BasicSection';
import HiddenSection from '@app/components/LoggedIn/Menu/common/HiddenSection';
import Accordion from '@app/components/common/Accordion';
import { IAccordionRef } from '@app/components/common/Accordion/types';
import Box from '@app/components/common/Box';
import { getIsMenuTypeItemHidden } from '@app/helpers/factories/menu/cardFactories/items/menuItemCardFactory';
import useIsSet from '@app/hooks/useIsSet';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectCategories,
  selectCurrentItem,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  FormMode,
  HiddenBucket,
  ISubcategory,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useRef } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const BUCKET = 'subcategories';

const SubcategoryDetails = ({ t }: WithTranslation) => {
  const { id } = useParams();
  const categoryIdToSet = useLocation().state?.categoryId;
  const subcategoryId = Number(id);

  const dispatch = useDispatch();

  const { loadMenuBucketChangeData, setActiveStateInBreadcrumbWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  const categories = useSelector(selectCategories);
  const currentCategoryId = useRootSelector(
    state => (selectCurrentItem(state) as ISubcategory)?.categoryId,
  );
  const currentName = useRootSelector(
    state => (selectCurrentItem(state) as ISubcategory)?.name,
  );

  const associationSectionRef = useRef<IAccordionRef>(null);

  const {
    isHidden,
    id: bucketId,
    name: bucketElementName,
  } = useMemo(() => {
    const _isHidden = getIsMenuTypeItemHidden(
      {
        1: { categoryId: currentCategoryId, subcategoryId: subcategoryId },
      },
      'items',
    );
    return {
      isHidden: _isHidden.isHidden,
      hiddenBucket: _isHidden.bucket,
      id: _isHidden.id,
      name: _isHidden.name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, currentCategoryId, subcategoryId]);

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  const showAssociateButton = !currentCategoryId;
  const categoryId = currentCategoryId || 0;

  useEffect(() => {
    if (!isSet) {
      const isCreate = !id;
      const prefillData = isCreate
        ? { categoryId: categoryIdToSet }
        : undefined;
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(id),
        mode: isCreate ? FormMode.CREATE : FormMode.UPDATE,
        prefillData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSet) return;
    setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'update');
    return () => {
      setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'reset');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isSet]);

  return (
    isSet && (
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}>
        {isHidden && Boolean(subcategoryId) && (
          <HiddenSection
            name={currentName}
            bucket={HiddenBucket.category}
            bucketOrigin="subcategory"
            description={showAssociateButton ? 'empty' : 'inactive'}
            menuTypeName=""
            navigationInfo={{
              id: bucketId || 0,
              text: bucketElementName,
            }}
            scrollText={t('commonButtons.associate')}
            onScrollClick={
              showAssociateButton
                ? () => {
                    const section = document.getElementById(
                      SectionId.ASSOCIATION,
                    );

                    associationSectionRef.current?.expandSection(1);

                    setTimeout(() => {
                      section?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                    }, 0);
                  }
                : undefined
            }
          />
        )}
        <Accordion ref={associationSectionRef}>
          <BasicSection isExpanded />
          <AssociationsSection categoryId={categoryId} />
          <TaxesSection mode="subcategories" />
        </Accordion>
      </Box>
    )
  );
};

export default SubcategoryDetails;
