import { getPathWithOrgData } from '@app/helpers/navigation';
import router from '@app/router/router';
import { selectSettingsChangeData } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import {
  CoreRootState,
  ISettingsStore,
  useRefreshSettings,
} from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';

export const onRefreshSettingsBreadCrumbPress = () => {
  const state = store.getState();
  const { settingsSelected } = selectSettingsChangeData(
    state as unknown as CoreRootState,
  );
  const screenBySettingsName: Record<keyof ISettingsStore, string> = {
    general: '/settings/general',
    online: '/settings/general/online-ordering',
    users: '/settings/accounts/users',
    roles: '/settings/accounts/roles',
    printerRules: '/settings/printing/printing-rules',
    prepStations: '/settings/printing/prep-stations',
    registerTicket: '/settings/printing/ticket/register-ticket',
    kitchenTicket: '/settings/printing/ticket/kitchen-ticket',
    guestCheck: '/settings/printing/ticket/guest-check',
    guestReceipt: '/settings/printing/ticket/guest-receipt',
    // TODO: Missing Management Screen
    lockScreen: '/settings/lock-screen',
    // TODO: Missing Home Screen
    register: '/settings/register',
    payments: '/settings/payments',
    taxes: '/settings/taxes',

    // TODO: license settings not implemented through paths
    licenseSpecificPrinterRules: '/settings/devices-and-licenses',
    licenseSettings: '/settings/devices-and-licenses',
    devices: '/settings/devices-and-licenses',
    licenses: '/settings/devices-and-licenses',
  };

  if (!settingsSelected) {
    return;
  }
  if (
    screenBySettingsName[settingsSelected] ===
    'LicenseSpecificPrinterRulesSettingsScreen'
  ) {
    return;
  }
  router.navigate(getPathWithOrgData(screenBySettingsName[settingsSelected]));
};

const useRefreshSettingsWeb = () => {
  const dispatch = useDispatch();
  const onRefresh = useRefreshSettings(
    dispatch,
    onRefreshSettingsBreadCrumbPress,
  );

  return onRefresh;
};

export default useRefreshSettingsWeb;
