import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Typography from '@app/components/common/Typography';
import { accountCreatedStyles } from './styles';
import { WithTranslation } from 'react-i18next';
import Logo from '@app/assets/logos/bellandnanchor.jpg';

interface IInvitationWelcomeMessage extends WithTranslation {
  onGetStarted: () => void;
}
const InvitationWelcomeMessage = ({
  t,
  onGetStarted,
}: IInvitationWelcomeMessage) => {
  return (
    <Box csx={accountCreatedStyles}>
      <Box className="message">
        <Box className="messageBody">
          <Box className="iconsContainer">
            <img src={Logo} width="130px" placeholder="Logo" title="Logo" />
          </Box>
          <Typography
            align="center"
            variant="heading"
            fontWeight="medium"
            className="title">
            {t('authentication.signUpInvitation.welcome')}
          </Typography>
          <Typography align="center" color="darkestGrey">
            <b>Bell & Anchor</b>{' '}
            {t('authentication.signUpInvitation.description')}
          </Typography>
        </Box>
        <Button
          variant="success"
          csx={{ width: '100%', marginTop: '30px', flexGrow: 0 }}
          onClick={onGetStarted}>
          {t('authentication.signUpInvitation.getStarted')}
        </Button>
      </Box>
    </Box>
  );
};

export default InvitationWelcomeMessage;
