import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import {
  ICombo,
  IItem,
  IMenuType,
  menuCategoryAssignedMenuTypesFactoryCore,
} from '@westondev/tableturn-core';

const menuCategoryAssignedMenuTypesFactory = (
  menuTypes: Record<number, IMenuType>,
  menuCategoryAssignedTypesIds: number[],
  items: Record<number, IItem>,
  combos: Record<number, ICombo>,
  assignedItems: number[],
  assignedCombos: number[],
  categoryId: number,
): IItemCard[] => <IItemCard[]>menuCategoryAssignedMenuTypesFactoryCore(
    menuTypes,
    menuCategoryAssignedTypesIds,
    items,
    combos,
    assignedItems,
    assignedCombos,
    categoryId,
  ).map(item => ({
    ...item,
    tag: item?.customTag,
    isActive: item?.status,
    showStatus: true,
  }));

export default menuCategoryAssignedMenuTypesFactory;
