import AuthCard from '@app/components/Authentication/AuthCard';
import Typography from '@app/components/common/Typography';
import Box from '@app/components/common/Box';
import Input from '@app/components/common/Input';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import Grid from '@app/components/common/Grid';
import PasswordMustBe from '@app/components/Authentication/PasswordMustBe';
import Button from '@app/components/common/Button';
import Stepper from '@app/components/common/Stepper';
import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import StepperForm from '@app/components/common/StepperForm';
import VerifyPhoneStep from '../../../components/Authentication/SignUp/VerifyPhoneStep';
import { IStep } from '@app/components/common/Stepper/types';
import { useLocation, useNavigate } from 'react-router-dom';
import InvitationWelcomeMessage from '@app/components/Authentication/SignUp/InvitationWelcomeMessage';
import { EOrgInviteSteps, INITIAL_FORM_DATA, IOrgInviteProps } from './types';
import { apiCall } from '@app/helpers/apiCall';
import { CallMethods } from '@app/constants';
import { actionCreatorsApp, apiEndpoints } from '@westondev/tableturn-core';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { signUp } from '@app/state/app/actions';
import { gridContainerStyles, registrationCardStyles } from '../SignUp/styles';
import AccountCreatedMessage from '../SignUp/AccountCreatedMessage';
import AuthContainerLayout from '@app/components/Authentication/AuthContainerLayout';
import { MQ_MIN_LARGE, MQ_MIN_SMALL, ThemeModes } from '@app/theme/types';
import { BroadcastChannel } from 'broadcast-channel';

const SignUp = ({
  t,
  userId,
  organizationName,
  organizationId,
  digitalLogoUrl,
}: IOrgInviteProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast, setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const signUpAction = bindActionCreators(signUp, dispatch);

  // Local state
  const location = useLocation();
  const isInSignUpInvite = useMemo(() => {
    return location.pathname === '/auth/sign-up-invite';
  }, [location.pathname]);

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [steps, setSteps] = useState<IStep>({
    [EOrgInviteSteps.PASSWORD]: {
      name: t('authentication.signUp.steps.accountInformation'),
      active: !isInSignUpInvite,
      completed: false,
    },
    [EOrgInviteSteps.PHONE_NUMBER]: {
      name: t('authentication.signUp.steps.confirmPhone'),
      active: false,
      completed: false,
    },
  });

  const [passwordErrors, setPasswordErrors] = useState({
    hasError: false,
    atLeast8Chars: false,
    atLeastOneUpperCase: false,
    atLeastOneLoweCase: false,
    atLeastOneNumberOrSymbol: false,
  });

  const [errors, setErrors] = useState({
    password: '',
  });

  const isInviteActive = useMemo(() => {
    return !Object.values(steps).some(step => step.active);
  }, [steps]);

  const passwordConfirmationHasError =
    (formData.confirmPassword.length > 0 || !passwordErrors.hasError) &&
    formData.password !== formData.confirmPassword;

  const activeStep = useMemo(() => {
    return Object.values(steps).findIndex(step => step.active);
  }, [steps]);

  const areAllCompleted = useMemo(() => {
    return Object.values(steps).some(step => !step.completed) ? false : true;
  }, [steps]);

  const hasUnsavedChanges = useMemo(() => {
    return Object.values(formData).some(value => value.length > 0);
  }, [formData]);

  const handleCheckFields = (onSuccess?: () => void) => {
    const cleanedFormData = {
      password: formData.password.trim(),
    };

    if (
      cleanedFormData.password.length > 0 &&
      !passwordErrors.hasError &&
      !passwordConfirmationHasError
    ) {
      onSuccess && onSuccess();
    } else {
      if (cleanedFormData.password.length === 0) {
        setErrors(prev => ({
          ...prev,
          password: t('authentication.signUp.errors.password'),
        }));
        document.getElementById('password')?.focus();
      }
    }
  };

  const showLogOutModal = (onContinue: () => void) => {
    const doesCurrentSessionExist = !!localStorage.getItem('auth');
    if (!doesCurrentSessionExist) return onContinue();

    setShowConfirmationModal({
      type: 'warning',
      active: true,
      title: t('authentication.signUpOrgInvite.logOutTitle'),
      description: t('authentication.signUpOrgInvite.logOutDescription'),
      hideCancel: true,
      onSuccess: () => {
        onContinue();
      },
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleCheckFields(() =>
      showLogOutModal(async () => {
        if (steps[EOrgInviteSteps.PASSWORD].completed)
          return setSteps(prev => ({
            ...prev,
            [EOrgInviteSteps.PASSWORD]: {
              ...prev[EOrgInviteSteps.PASSWORD],
              active: false,
            },
            [EOrgInviteSteps.PHONE_NUMBER]: {
              ...prev[EOrgInviteSteps.PHONE_NUMBER],
              active: true,
            },
          }));
        if (Object.values(errors).some(error => error)) return;

        setIsLoading(true);
        apiCall(
          CallMethods.POST,
          apiEndpoints.user.newUserAcceptInvite?.(userId as number),
          false,
          {
            password: formData.password,
            organizationId,
          },
        )
          .then(r => {
            signUpAction(r);

            const broadcastChannel = new BroadcastChannel('auth');
            broadcastChannel.postMessage({ action: 'login' });

            setPhoneNumber(r.phone);

            setIsLoading(false);
            setSteps(prev => ({
              ...prev,
              [EOrgInviteSteps.PASSWORD]: {
                ...prev[EOrgInviteSteps.PASSWORD],
                completed: true,
                active: false,
              },
              [EOrgInviteSteps.PHONE_NUMBER]: {
                ...prev[EOrgInviteSteps.PHONE_NUMBER],
                active: true,
              },
            }));
          })
          .catch(error => {
            setIsLoading(false);

            showToast({
              type: 'error',
              title: 'Error',
              description: error?.response?.data?.error?.message,
            });
          });
      }),
    );
  };

  const handleSetFormData = (
    e: FormEvent<HTMLInputElement> | string,
    field: string,
  ) => {
    const value = typeof e === 'string' ? e : e.currentTarget.value;
    const atLeast8Chars = /.{8,}/;
    const atLeastOneUpperCase = /(?=.*[A-Z])/;
    const atLeastOneLoweCase = /(?=.*[a-z])/;
    const atLeastOneNumberOrSymbol = /(?=.*\d|[^A-Za-z])/;
    const validationRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d|[^A-Za-z])[\w\d\S]{8,}$/;

    if (field === 'password') {
      setPasswordErrors(prev => ({
        ...prev,
        atLeast8Chars: atLeast8Chars.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneUpperCase: atLeastOneUpperCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneLoweCase: atLeastOneLoweCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneNumberOrSymbol: atLeastOneNumberOrSymbol.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        hasError: !validationRegex.test(value),
      }));
    }

    setFormData(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: value.length === 0 }));
  };

  const handleChangeStep = (index?: number) => {
    if (index !== undefined && !Object.values(errors).some(error => error)) {
      setSteps(prev => ({
        ...prev,
        [index]: { ...prev[index], active: true },
        [activeStep]: { ...prev[activeStep], active: false },
      }));
    }
  };

  const handleContinueVerifyPhone = async (code: string) => {
    return apiCall(CallMethods.POST, apiEndpoints.user.verifyPhone(), false, {
      phoneNumber,
      code,
    }).then(() => {
      setSteps(prev => ({
        ...prev,
        [EOrgInviteSteps.PHONE_NUMBER]: {
          ...prev[EOrgInviteSteps.PHONE_NUMBER],
          completed: true,
          active: false,
        },
      }));

      const broadcastChannel = new BroadcastChannel('auth');
      broadcastChannel.postMessage({ action: 'login' });
    });
  };

  const handleSetPhone = (_phoneNumber: string) => {
    setPhoneNumber(_phoneNumber);
  };

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges && !areAllCompleted) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges, areAllCompleted]);

  if (!userId) return null;

  return (
    <AuthContainerLayout
      subtitle={
        <Box
          csx={theme => ({
            width: '100%',
            backgroundColor:
              theme.mode === ThemeModes.DARK
                ? theme.colors.background
                : theme.colors.white,

            boxShadow: theme.shadows[0.8],
            zIndex: 2,
          })}>
          <Box
            csx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box csx={registrationCardStyles}>
              <img
                css={{
                  display: 'block',
                  maxHeight: '50px',
                  paddingTop: '30px',
                  margin: '0 auto',
                  objectFit: 'contain',
                  [MQ_MIN_SMALL]: {
                    maxHeight: '70px',
                  },
                  [MQ_MIN_LARGE]: {
                    maxHeight: '90px',
                  },
                }}
                src={digitalLogoUrl}
                alt={organizationName}
              />
              <Box
                csx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}>
                <Box
                  csx={{
                    width: '100%',
                    paddingInline: '20px',
                  }}>
                  <Typography variant="body">
                    <Typography
                      variant="body"
                      fontWeight="bold"
                      component="span">
                      {organizationName}
                    </Typography>{' '}
                    {t('authentication.signUpOrgInvite.description', {
                      organizationName,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }>
      <AuthCard csx={registrationCardStyles} width="100%" showLogo={false}>
        <Typography
          variant="heading"
          align="center"
          fontWeight="medium"
          className={`title ${areAllCompleted ? 'success' : ''}`}
          csx={{ marginTop: '15px' }}>
          {t(
            areAllCompleted
              ? 'authentication.signUp.steps.accountCreated'
              : 'authentication.signUp.accountInfo.createAnAccount',
          )}
        </Typography>
        <Box className={`stepperArea ${!areAllCompleted ? 'sticky' : ''}`}>
          {!areAllCompleted && (
            <Stepper
              steps={steps}
              getStepIndex={handleChangeStep}
              clickable={false}
            />
          )}
        </Box>
        {areAllCompleted ? (
          <AccountCreatedMessage
            user=""
            titleOverride={t('authentication.signUpOrgInvite.welcome')}
            descriptionOverride={t(
              'authentication.signUpOrgInvite.getStartedDescription',
            )}
            buttonTextOverride={t('authentication.signUpOrgInvite.getStarted')}
            onGetStarted={() => navigate('/my-organizations')}
          />
        ) : isInSignUpInvite && isInviteActive ? (
          <InvitationWelcomeMessage
            onGetStarted={() =>
              setSteps(prev => ({
                ...prev,
                [EOrgInviteSteps.PASSWORD]: {
                  ...prev[EOrgInviteSteps.PASSWORD],
                  active: true,
                },
              }))
            }
          />
        ) : (
          <>
            <StepperForm activeStep={activeStep}>
              <Box className="form">
                <form
                  className="formContent"
                  css={{ maxWidth: '550px' }}
                  onSubmit={handleSubmit}>
                  <Box csx={{ height: '100%' }}>
                    <Grid container rowGap={18} csx={gridContainerStyles}>
                      <Grid.Item sm={6}>
                        <Input
                          id="password"
                          name="password"
                          label={t('commonTexts.password')}
                          placeholder={t('authentication.login.insertPassword')}
                          type="password"
                          value={formData.password}
                          required
                          onChange={e => handleSetFormData(e, 'password')}
                          error={!!(passwordErrors.hasError || errors.password)}
                        />
                      </Grid.Item>
                      <Grid.Item sm={6}>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          label={t(
                            'authentication.resetPassword.confirmPassword',
                          )}
                          placeholder={t(
                            'authentication.resetPassword.confirmPassword',
                          )}
                          type="password"
                          value={formData.confirmPassword}
                          error={passwordConfirmationHasError}
                          caption={
                            passwordConfirmationHasError
                              ? t(
                                  'authentication.resetPassword.thePasswordConfirmationDoesntMatch',
                                )
                              : undefined
                          }
                          required
                          showCaptionSpace
                          onChange={e =>
                            handleSetFormData(e, 'confirmPassword')
                          }
                        />
                      </Grid.Item>
                    </Grid>
                    <PasswordMustBe
                      csx={{ marginTop: `${25 - CAPTION_HEIGHT}px` }}
                      passwordErrors={{
                        atLeast8Chars: passwordErrors.atLeast8Chars,
                        atLeastOneUpperCase: passwordErrors.atLeastOneUpperCase,
                        atLeastOneLoweCase: passwordErrors.atLeastOneLoweCase,
                        atLeastOneNumberOrSymbol:
                          passwordErrors.atLeastOneNumberOrSymbol,
                      }}
                    />
                  </Box>
                  <Box
                    csx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <Button
                      type="submit"
                      variant="active"
                      isLoading={isLoading}
                      loadingText={t('commonTexts.continue')}
                      onClick={() => handleCheckFields()}>
                      {t('commonTexts.continue')}
                    </Button>
                  </Box>
                </form>
              </Box>
              <VerifyPhoneStep
                phoneNumber={phoneNumber}
                onContinue={handleContinueVerifyPhone}
                setPhone={handleSetPhone}
              />
            </StepperForm>
          </>
        )}
      </AuthCard>
    </AuthContainerLayout>
  );
};

export default SignUp;
