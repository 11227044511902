import { css } from '@emotion/react';

export const skeletonStyles = () =>
  css({
    borderRadius: '8px',
    backgroundColor: '#e6e6e6',
    animation: 'pulse 1.2s cubic-bezier(0.4, 0, 0.6, 1) infinite;',
    keyframes: {
      '@keyframes pulse': {
        '0%': {
          opacity: 0.6,
        },
        '50%': {
          opacity: 0.3,
        },
        '100%': {
          opacity: 0.6,
        },
      },
    },
  });
