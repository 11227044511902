import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import ItemTaxesSubsection from '../TaxesSection/ItemTaxesSubsection';
import { IItemDetailsSection } from '../types';

const MassUpdateTaxesSection = (props: IItemDetailsSection) => {
  const { t } = props;

  return (
    <AccordionSection
      title={t('menuScreen.itemDetails.taxesSection.title')}
      {...props}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <ItemTaxesSubsection {...props} />
        <Divider />
        <ItemTaxesSubsection taxField="removeTaxIds" {...props} />
      </Box>
    </AccordionSection>
  );
};

export default MassUpdateTaxesSection;
