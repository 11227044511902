import { RootState } from '@app/state/store';
import { IRegisterSettings } from '@westondev/tableturn-core';
import React, { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ITimeBasedMenuTypeError } from './types';
import Typography from '@app/components/common/Typography';
import { ErrorRow } from '../../BucketValidationConfirmationModal/BucketValidationResolver';

const TimeBasedMenuTypeError = ({
  ruleId,
  day,
  t,
}: ITimeBasedMenuTypeError & WithTranslation) => {
  const ruleIndex = useSelector((state: RootState) => {
    if (!day || !ruleId) {
      return -1;
    }
    const index = (
      state.settings.changeData.data as IRegisterSettings
    ).defaultMenuTypeTimes[day].findIndex(rule => rule.id === ruleId);

    return index;
  });

  const rule = useSelector((state: RootState) =>
    ruleIndex >= 0
      ? (state.settings.changeData.data as IRegisterSettings)
          .defaultMenuTypeTimes[day][ruleIndex]
      : null,
  );

  const menuType = useSelector((state: RootState) => {
    if (!rule) {
      return '';
    }
    const menuTypeId = rule.menuTypeId;
    return state.menu.selectedMode.currentMenu.menuTypes[menuTypeId].name;
  });

  const formattedComponent = useMemo(() => {
    const formatString: string = t(
      'validations.settings.timeBasedMenuTypeError',
    );
    const divider = '|divider|';
    const elementsArray: React.ReactNode[] = formatString.split(divider);
    elementsArray.splice(
      1,
      0,
      <>
        {' '}
        <Typography fontWeight="medium">{ruleIndex + 1}</Typography>{' '}
      </>,
    );
    elementsArray.splice(
      3,
      0,
      <>
        {' '}
        <Typography fontWeight="medium">
          {t(`commonTexts.${day}`)}
        </Typography>{' '}
      </>,
    );
    elementsArray.splice(
      5,
      0,
      <>
        {' '}
        <Typography fontWeight="medium">{menuType}</Typography>{' '}
      </>,
    );
    return elementsArray;
  }, [t, ruleIndex, menuType, day]);

  return (
    <>
      {ruleIndex >= 0 && (
        <ErrorRow>
          {formattedComponent.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
        </ErrorRow>
      )}
    </>
  );
};

export default TimeBasedMenuTypeError;
