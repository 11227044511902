import Button from '../../Button';
import { IButton } from '../../Button/Button';

interface IActionItem extends IButton {
  handler?: () => void;
  disabledHandler?: () => void;
}
const ActionItem = ({
  handler,
  disabledHandler,
  ...otherProps
}: IActionItem) => {
  return (
    <Button
      variant="transparent"
      onClick={handler}
      onClickDisabled={disabledHandler}
      {...otherProps}
    />
  );
};

export default ActionItem;
