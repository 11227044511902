import { IBreadCrumb } from '@westondev/tableturn-core';

export interface IStoreInterfaceBase {
  id: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
}

export type PullingDataTypes = {
  isDownloading: boolean;
  percentCompleted: number;
};

export type lngItemType = {
  key: string;
  name: string;
};

export enum UpdateType {
  NONE,
  PERCENT,
  FIXED,
  SET,
}

export enum OperationType {
  INCREASE,
  DECREASE,
}

export enum RadioSelectionStates {
  EMPTY = 'EMPTY',
  SOME = 'SOME',
  FULL = 'FULL',
}

export type TChange = {
  field: string;
  label: string;
  value:
    | string
    | { kind: string; value: string | number }
    | { action: string; value: string | number }[]
    | { updateType: UpdateType; operation: OperationType; price: number }
    | boolean
    | number
    | null
    | React.ReactElement;
  textValue?: string;
  id?: number | null;
};

export interface IGenericError {
  message: string;
}

export const BASE_TRANSLATION = 'menuScreen.itemDetails.modalPricingList';

export type IBreadCrumbWeb = IBreadCrumb & {
  pathURL: string;
};
