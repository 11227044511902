export const indexSliderHasErrorValue = ({
  index,
  value,
  bannedRanges,
}: {
  index: number;
  value: number;
  bannedRanges: Array<[number, number]>;
}) => {
  return bannedRanges?.some(range => {
    if (index === 0) {
      return value >= range[0] && value < range[1];
    } else {
      return value > range[0] && value <= range[1];
    }
  });
};
