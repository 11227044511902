import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import ToolTip from '@app/components/common/ToolTip';
import { RootState } from '@app/state/store';
import { actionCreatorsTableLayout } from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const GridButton = () => {
  // Redux
  const toolSettings = useSelector(
    (state: RootState) => state.tableLayout.toolSettings,
  );

  const { setToolSettings } = bindActionCreators(
    actionCreatorsTableLayout,
    useDispatch(),
  );

  return (
    <ToolTip
      content={toolSettings.showGrid ? 'Hide Grid' : 'Show Grid'}
      direction="bottom">
      <Button
        variant={toolSettings.showGrid ? 'active' : 'secondary'}
        csx={{ width: '50px' }}
        onClick={() => {
          setToolSettings({
            ...toolSettings,
            showGrid: !toolSettings.showGrid,
          });
        }}
        icon={<Icon name="MdGridOn" />}
      />
    </ToolTip>
  );
};
export default GridButton;
