import { m } from 'framer-motion';
import Box from '../Box';
import Typography from '../Typography';
import { listStyles } from './styles';

interface IList {
  children: React.ReactNode;
  title?: string;
  isCollapsible?: boolean;
  isExpanded?: boolean;
}

const List = ({
  children,
  title,
  isCollapsible = false,
  isExpanded = false,
}: IList) => {
  const isVisible = !isCollapsible || (isCollapsible && isExpanded);

  return (
    <Box csx={listStyles}>
      {title && <Typography className="title">{title}</Typography>}

      <m.div
        initial={{
          display: isVisible ? 'flex' : 'none',
          opacity: isExpanded ? 0 : 1,
          height: isExpanded ? 0 : 'auto',
        }}
        animate={{ opacity: 1, height: 'auto' }}
        css={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
        {children}
      </m.div>
    </Box>
  );
};

export default List;
