import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import {
  IOnlineOrderingStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SettingsSectionId.PAGE_DESIGN;
const getMemoizedItemData =
  makeSelectSettingsData<IOnlineOrderingStore>(SECTION_ID);

const TipSettings = () => {
  //Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedItemData);

  const { t } = useTranslation();

  return (
    <Box>
      <Grid rowGap={15}>
        <Grid.Item>
          <Switch
            label={t(
              'settingsModule.onlineSettings.tips.showTippingAtCheckout',
            )}
            checked={data.showTippingAtCheckout}
            onChange={showTippingAtCheckout => {
              setValue({ showTippingAtCheckout }, SECTION_ID);
            }}
          />
        </Grid.Item>
        <Grid.Item>
          <Divider />
        </Grid.Item>
        <Grid.Item>
          <Box>
            <DetailsSubtitle>
              {t('settingsModule.onlineSettings.pickup.title')}
            </DetailsSubtitle>
            <Grid rowGap={15} columnGap={15}>
              <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
                <Dropdown
                  label={t(
                    'settingsModule.onlineSettings.tips.suggestedTipDefault',
                  )}
                  placeholder={t(
                    'settingsModule.onlineSettings.tips.suggestedTipDefault',
                  )}
                  data={[
                    [
                      {
                        value: 0,
                        label: t(
                          'settingsModule.onlineSettings.tips.noDefaultTip',
                        ),
                      },
                      {
                        value: 1,
                        label: t(
                          'settingsModule.onlineSettings.tips.suggestedTip1',
                        ),
                      },
                      {
                        value: 2,
                        label: t(
                          'settingsModule.onlineSettings.tips.suggestedTip2',
                        ),
                      },
                      {
                        value: 3,
                        label: t(
                          'settingsModule.onlineSettings.tips.suggestedTip3',
                        ),
                      },
                    ],
                  ]}
                  value={data.pickupTipDefault}
                  onChange={pickupTipDefault => {
                    setValue({ pickupTipDefault }, SECTION_ID);
                  }}
                />
              </Grid.Item>
              <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
                <MoneyInput
                  label={t('settingsModule.onlineSettings.tips.suggestedTip1')}
                  suffix=" %"
                  prefix=""
                  value={data.pickupTip1}
                  onValueChange={pickupTip1 => {
                    setValue({ pickupTip1 }, SECTION_ID);
                  }}
                />
              </Grid.Item>
              <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
                <MoneyInput
                  label={t('settingsModule.onlineSettings.tips.suggestedTip2')}
                  suffix=" %"
                  prefix=""
                  value={data.pickupTip2}
                  onValueChange={pickupTip2 => {
                    setValue({ pickupTip2 }, SECTION_ID);
                  }}
                />
              </Grid.Item>
              <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
                <MoneyInput
                  label={t('settingsModule.onlineSettings.tips.suggestedTip3')}
                  suffix=" %"
                  prefix=""
                  value={data.pickupTip3}
                  onValueChange={pickupTip3 => {
                    setValue({ pickupTip3 }, SECTION_ID);
                  }}
                />
              </Grid.Item>
            </Grid>
          </Box>
        </Grid.Item>
        <Grid.Item>
          <DetailsSubtitle>
            {t('settingsModule.onlineSettings.delivery.title')}
          </DetailsSubtitle>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
              <Dropdown
                label={t(
                  'settingsModule.onlineSettings.tips.suggestedTipDefault',
                )}
                placeholder={t(
                  'settingsModule.onlineSettings.tips.suggestedTipDefault',
                )}
                data={[
                  [
                    {
                      value: 0,
                      label: t(
                        'settingsModule.onlineSettings.tips.noDefaultTip',
                      ),
                    },
                    {
                      value: 1,
                      label: t(
                        'settingsModule.onlineSettings.tips.suggestedTip1',
                      ),
                    },
                    {
                      value: 2,
                      label: t(
                        'settingsModule.onlineSettings.tips.suggestedTip2',
                      ),
                    },
                    {
                      value: 3,
                      label: t(
                        'settingsModule.onlineSettings.tips.suggestedTip3',
                      ),
                    },
                  ],
                ]}
                value={data.deliveryTipDefault}
                onChange={deliveryTipDefault => {
                  setValue({ deliveryTipDefault }, SECTION_ID);
                }}
              />
            </Grid.Item>
            <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
              <MoneyInput
                label={t('settingsModule.onlineSettings.tips.suggestedTip1')}
                suffix=" %"
                prefix=""
                value={data.deliveryTip1}
                onValueChange={deliveryTip1 => {
                  setValue({ deliveryTip1 }, SECTION_ID);
                }}
              />
            </Grid.Item>
            <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
              <MoneyInput
                label={t('settingsModule.onlineSettings.tips.suggestedTip2')}
                suffix=" %"
                prefix=""
                value={data.deliveryTip2}
                onValueChange={deliveryTip2 => {
                  setValue({ deliveryTip2 }, SECTION_ID);
                }}
              />
            </Grid.Item>
            <Grid.Item sm={6} lg={3} csx={displayFlexEndColumnStyles}>
              <MoneyInput
                label={t('settingsModule.onlineSettings.tips.suggestedTip3')}
                suffix=" %"
                prefix=""
                value={data.deliveryTip3}
                onValueChange={deliveryTip3 => {
                  setValue({ deliveryTip3 }, SECTION_ID);
                }}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Box>
  );
};

export default TipSettings;
