import { MQ_MAX_LARGE, MQ_MIN_X_LARGE } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const generalSettingsDropdownSubsectionStyles = (theme: Theme) =>
  css({
    [MQ_MAX_LARGE]: {
      borderBottom: `1px solid ${theme.colors.lightGrey}`,
      paddingBottom: '20px',
    },

    [MQ_MIN_X_LARGE]: {
      borderRight: `1px solid ${theme.colors.lightGrey}`,
      paddingRight: '15px',
    },
  });

export const dropdownContainerStyles = css({
  justifyContent: 'flex-end',
});

export const generalSettingsCheckboxesSubsectionStyles = css({
  justifyContent: 'flex-end',

  [MQ_MAX_LARGE]: { paddingTop: '20px' },
  [MQ_MIN_X_LARGE]: { paddingLeft: '15px' },
});

export const generalSettingsCheckboxesStyles = css({
  marginBottom: '10px',
  height: '100%',
  justifyContent: 'center',
});
