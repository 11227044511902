import { MQ_MIN_HEIGHT_SMALL, MQ_MIN_LARGE } from '@app/theme/types';
import { css } from '@emotion/react';

export const linkWithText = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
  });

export const loginAuthBodySeparation = () =>
  css({
    marginTop: '10px',
    [MQ_MIN_LARGE]: {
      marginTop: '30px',
    },
    [MQ_MIN_HEIGHT_SMALL]: {
      marginTop: '50px',
    },
  });
