import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IRegisterSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';

export interface IAdsTable {
  id: number;
  active: boolean;
  sortPriority: number;
  imageUrl: string | null;
  title: string;
  text: string;
  price: number;
  delete: string;
}

export const columnHelper = createColumnHelper<IAdsTable>();

export const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY_ADS;
export const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

export const ROW_ID_PREFIX = 'ad-row-';
export const ADS_TABLE_ID = 'ads-table';
