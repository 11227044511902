import { store } from '@app/state/store';
import {
  CoreRootState,
  IKitchenTicketSettings,
  prepStationVersionsFactoryCore,
  prepStationVersionsSelectionFactoryCore,
} from '@westondev/tableturn-core';

export const prepStationVersionsFactory = (
  prepStationVersions: IKitchenTicketSettings['prepStationVersions'],
) =>
  prepStationVersionsFactoryCore(
    prepStationVersions,
    store.getState() as CoreRootState,
  );

export const prepStationVersionsSelectionFactory = (
  prepStationVersions: IKitchenTicketSettings['prepStationVersions'],
) =>
  prepStationVersionsSelectionFactoryCore(
    prepStationVersions,
    store.getState() as CoreRootState,
  );
