import { WithTranslation } from 'react-i18next';
import Box from '../Box';
import Button from '../Button';
import { IButton } from '../Button/Button';
import { colorPickerLabelStyles } from '../ColorPicker/styles';
import Icon from '../Icon';
import Typography from '../Typography';

type IResetButton = WithTranslation &
  IButton & {
    showLabel?: boolean;
  };

function ResetButton({ showLabel, t, ...otherProps }: IResetButton) {
  return (
    <Box>
      {showLabel && (
        <Typography csx={colorPickerLabelStyles}>
          {t('commonButtons.reset')}
        </Typography>
      )}
      <Button
        csx={{ width: '50px' }}
        icon={<Icon name="FaUndo" size="18px" />}
        variant="primary"
        {...otherProps}
      />
    </Box>
  );
}

export default ResetButton;
