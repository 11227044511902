import Grid from '@app/components/common/Grid';
import InputNumberWithButtons from '@app/components/common/Input/InputNumberWithButtons';
import { getNewPositionWithRotation } from '@app/helpers/settings/tableLayoutCreator';
import { actionCreatorsApp } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { IElement, actionCreatorsTableLayout } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const ROTATION_LIMIT = 90;
const ROTATION_INCREMENT = 15;

interface IRotationInput extends WithTranslation {
  elementData: IElement;
}
const RotationInput = ({ t, elementData }: IRotationInput) => {
  // Redux
  const dispatch = useDispatch();
  const updateElementAndSaveLastChange = bindActionCreators(
    actionCreatorsTableLayout.updateElementAndSaveLastChange,
    dispatch,
  );

  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);

  const handleUpdateRotation = (values: { [key: string]: unknown }) => {
    if (elementData) {
      const newElement = {
        ...elementData,
        ...values,
      };
      updateElementAndSaveLastChange(elementData, newElement);
    }
  };

  const calculateElementPositionIfRotation = (
    element: IElement,
    newRotation: number,
  ) => {
    const values = getNewPositionWithRotation(element, newRotation);

    if (!values) {
      setShowToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });

      return;
    }

    handleUpdateRotation(values);
  };

  return (
    <Grid.Item>
      <InputNumberWithButtons
        isEditable={false}
        min={-ROTATION_LIMIT}
        max={ROTATION_LIMIT}
        step={ROTATION_INCREMENT}
        suffix="deg"
        label={t('settingsModule.tableLayoutCreator.sideBar.rotate')}
        value={elementData.rotation}
        onChange={seats => {
          calculateElementPositionIfRotation(elementData, seats);
        }}
      />
    </Grid.Item>
  );
};

export default RotationInput;
