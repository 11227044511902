import Accordion from '@app/components/common/Accordion';
import Box from '@app/components/common/Box';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsSettingsChangeData } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { TUserCreationData } from '../UsersList/AddUserModal/types';
import AssociationsSettings from './AssociationsSettings';
import BasicSettings from './BasicSettings';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const UserDetails = () => {
  // redux
  const dispatch = useDispatch();
  const loadUserSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadUserSettingsChangeData,
    dispatch,
  );

  const resetSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsChangeData.resetSettingsChangeData,
    dispatch,
  );

  // Local state
  const { id } = useParams();

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'users',
  );

  const userCreationData = useLocation().state?.userData as TUserCreationData;
  const navigate = useNavigateWithOrg();

  useEffect(() => {
    if (!id && !userCreationData?.user?.email) {
      navigate('/settings/accounts/users');
      return;
    }
    if (!isSet) {
      loadUserSettingsChangeData(
        {
          id: Number(id),
        },
        userCreationData,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetSettingsChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {isSet ? (
        <Box>
          <Accordion>
            <BasicSettings isExpanded />
            <AssociationsSettings isExpanded />
          </Accordion>
        </Box>
      ) : null}
    </Box>
  );
};

export default UserDetails;
