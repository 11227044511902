import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import useRootSelector from '@app/hooks/useRootSelector';
import { handleReportsChange } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  indexToOrderStatus,
  orderStatusOptionsArray,
  orderStatusToIndex,
} from '@westondev/tableturn-core';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const ReportOrderStatusFilters = () => {
  const setValues = bindActionCreators(handleReportsChange, useDispatch());
  const orderStatuses = useRootSelector(
    state => state.reports.changeData?.data?.orderStatuses,
  );

  const orderStatusesValueText = useReportsFilterText(
    'orderStatuses',
    orderStatuses,
  );

  const currentOrderStatusesSelected = useMemo(() => {
    const orderStatusIndexSet = new Set(
      orderStatuses?.map(s => orderStatusToIndex[s]) ?? [],
    );
    return orderStatusOptionsArray.filter(v =>
      orderStatusIndexSet.has(v.value),
    );
  }, [orderStatuses]);

  const handleOnChangeDone = useCallback(
    (selectedSubcategoryOptions: IOption[]) => {
      if (
        !selectedSubcategoryOptions?.length ||
        selectedSubcategoryOptions?.some(v => v.value === 0)
      ) {
        setValues(prev => ({ ...prev, orderStatuses: null }));
        return;
      }

      const selectedOrderStatuses = selectedSubcategoryOptions.map(
        v => indexToOrderStatus[v.value],
      );

      setValues(prev => ({ ...prev, orderStatuses: selectedOrderStatuses }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid.Item mb={12} sm={6} md={3}>
      <Typography csx={labelStyles}>
        {t('reports.fields.orderStatuses')}
      </Typography>
      <OptionsModal
        title={t('reports.fields.orderStatuses')}
        customText={orderStatusesValueText}
        options={[...orderStatusOptionsArray]}
        currentOptionsSelected={currentOrderStatusesSelected}
        onChangeDone={handleOnChangeDone}
        clearOptionsLabel={t('commonButtons.selectAll')}
        isSearchable
        returnItemOnChange
        allowMultipleSelection
        buttonProps={{
          csx: {
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
      />
    </Grid.Item>
  );
};

export default ReportOrderStatusFilters;
