import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { useTheme } from '@emotion/react';

interface IHeaderDiffModal {
  name: string;
  color?: string;
}

const HeaderDiffModal = ({ name, color }: IHeaderDiffModal) => {
  const theme = useTheme();
  const itemChangeColor = 'textBlack';
  return (
    <Box
      csx={{
        display: 'flex',
        alignItems: 'center',
        marginBlock: '20px',
      }}>
      <Typography
        fontWeight="bold"
        variant="subtitle"
        csx={{
          marginRight: '10px',
          whiteSpace: 'nowrap',
          textTransform: 'uppercase',
        }}
        color={color || itemChangeColor}>
        {name}
      </Typography>
      <Box
        csx={{
          width: '100%',
          borderTop: `1px solid ${theme.colors.semanticBlue}`,
        }}
      />
    </Box>
  );
};

export default HeaderDiffModal;
