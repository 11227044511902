import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IToggleSideBarButton {
  onToggleSideBar: (value: boolean) => void;
}
const ToggleSideBarButton = ({ onToggleSideBar }: IToggleSideBarButton) => {
  const location = useLocation();

  const isOnMyOrganizationsPage = location.pathname === '/my-organizations';

  const handleToggleSideBar = () => {
    const sideBarOpen = localStorage.getItem('sideBarOpen');
    let isOpen = false;
    if (sideBarOpen === 'true') isOpen = false;
    else isOpen = true;

    localStorage.setItem('sideBarOpen', isOpen.toString());
    onToggleSideBar(isOpen);
  };

  useEffect(() => {
    const sideBarOpen = localStorage.getItem('sideBarOpen');

    if (!sideBarOpen || sideBarOpen === 'false') onToggleSideBar(false);
    else onToggleSideBar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isOnMyOrganizationsPage ? (
    <Button
      className="sideBarButton"
      variant="icon"
      icon={<Icon name="MdMenu" color="textWhite" />}
      onClick={handleToggleSideBar}
    />
  ) : null;
};

export default ToggleSideBarButton;
