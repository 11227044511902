import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import {
  areSettingsMasterModeSelector,
  makeSelectSettingsData,
  selectLocationCourses,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  COURSE_INITIAL_STATE,
  ICourse,
  IRegisterSettings,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getCourseId,
  propagateString,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICoursesTable } from './types';

const SECTION_ID = SettingsSectionId.TABLE_SERVICE;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const columnHelperCustom = createColumnHelper<ICoursesTable>();

const ROW_ID_PREFIX = 'courses-row-';

const Coursing = ({ t }: WithTranslation) => {
  // Redux
  const { data } = useSelector(getMemoizedItemData);
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );
  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const actualCourses = useSelector(selectLocationCourses);

  const handleNewRowScroll = useScrollToNewRow();

  const onNewCourse = () => {
    const nextId =
      (max(Object.keys(data?.courses || {}).map(key => Number(key)) || []) ||
        0) + 1;

    const nextSortPriority =
      (max(
        Object.values(data?.courses || {}).map(course => course.sortPriority) ||
          [],
      ) || 0) + 1;

    const nextIndex = Object.values(data?.courses || {}).length;

    setValue<IRegisterSettings>(
      {
        courses: {
          ...data?.courses,
          [nextId]: {
            ...COURSE_INITIAL_STATE,
            id: nextId,
            courseId: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const updateCourse = (newValue: any, id: number, field: keyof ICourse) => {
    const courses = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.courses;
    setValue<IRegisterSettings>(
      {
        courses: {
          ...courses,
          [id]: {
            ...courses[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateName = (newName: string, id: number) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentCourse = registerData.courses[id];
    const prevName = currentCourse.name;
    const propagateObj = {
      name: prevName,
      posName: currentCourse.posName,
      kitchenName: currentCourse.kitchenName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        courses: {
          ...registerData.courses,
          [id]: {
            ...registerData.courses[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const coursesArray = useMemo(
    () =>
      orderBy(
        Object.keys(data?.courses || {}).map(key => ({
          id: Number(key),
          status: data?.courses[Number(key)]?.active,
          course: data?.courses[Number(key)]?.name,
          sortPriority: data?.courses[Number(key)]?.sortPriority,
          kitchenName: data?.courses[Number(key)]?.kitchenName,
          posName: data?.courses[Number(key)]?.posName,
          delete: actualCourses[Number(key)] ? false : true,
        })),
        'sortPriority',
      ),
    [data?.courses, actualCourses],
  );

  const handleDelete = (id: number) => {
    const courses = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.courses;

    let sortPriority = 1;
    const coursesCloned = cloneDeep(courses);
    delete coursesCloned[id];
    const modTagsClonedArray = Object.values(coursesCloned);

    orderBy(modTagsClonedArray, 'sortPriority').forEach(value => {
      coursesCloned[value.courseId].sortPriority = sortPriority;
      sortPriority++;
    });

    setValue<IRegisterSettings>(
      {
        courses: coursesCloned,
      },
      SECTION_ID,
    );
  };

  const handleSort = (newData: ICoursesTable[]) => {
    const clonedCourses = cloneDeep(data?.courses);
    newData.forEach((item, index) => {
      clonedCourses[item.id].sortPriority = index + 1;
    });

    setValue<IRegisterSettings>(
      {
        courses: clonedCourses,
      },
      SECTION_ID,
    );
  };

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('status', {
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.status',
        ),
        cell: info => (
          <Switch
            key={`switch-coursing-${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isActive => {
              updateCourse(isActive, info.row.original.id, 'active');
            }}
          />
        ),
        size: 70,
      }),
      columnHelperCustom.accessor('course', {
        minSize: 160,
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.course',
        ),
        cell: info => (
          <InputCell
            required
            placeholder={t(
              'settingsModule.registerModeSettings.tableService.coursing.course',
            )}
            value={info.getValue()}
            disabled={!info.row.original.delete && !isMasterMode}
            onChange={e =>
              updateName(e.currentTarget.value, info.row.original.id)
            }
            errorId={`${getCourseId(`${info.row.original.id}`, 'name')}`}
            onFocusError={(errorId, hasError) => {
              hasError && clearSettingsError(errorId);
            }}
          />
        ),
      }),
      columnHelperCustom.accessor('posName', {
        minSize: 160,
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.posName',
        ),
        cell: info => (
          <InputCell
            required
            value={info.getValue()}
            placeholder={t(
              'settingsModule.registerModeSettings.tableService.coursing.posName',
            )}
            onChange={e => {
              updateCourse(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              );
            }}
            errorId={`${getCourseId(`${info.row.original.id}`, 'posName')}`}
            onFocusError={(errorId, hasError) => {
              hasError && clearSettingsError(errorId);
            }}
          />
        ),
      }),
      columnHelperCustom.accessor('kitchenName', {
        minSize: 160,
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.kitchenName',
        ),
        cell: info => (
          <InputCell
            required
            value={info.getValue()}
            placeholder={t(
              'settingsModule.registerModeSettings.tableService.coursing.kitchenName',
            )}
            onChange={e => {
              updateCourse(
                e.currentTarget.value,
                info.row.original.id,
                'kitchenName',
              );
            }}
            errorId={`${getCourseId(`${info.row.original.id}`, 'kitchenName')}`}
            onFocusError={(errorId, hasError) => {
              hasError && clearSettingsError(errorId);
            }}
          />
        ),
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ width: '50px' }}
              disabled={!info.getValue() && !isMasterMode}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() => handleDelete(info.row.original.id)}
            />
          ) as unknown as string,
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  return (
    <Box
      csx={{
        padding: '15px',
      }}>
      <HeaderTitle
        title="Coursing"
        options={
          <Button
            variant="primary"
            icon={<Icon name="MdAdd" />}
            onClick={onNewCourse}>
            Add Course
          </Button>
        }
      />
      <Table
        rowIdPrefix={ROW_ID_PREFIX}
        mode={ETableModes.SORT_VERTICALLY}
        data={data ? coursesArray : []}
        columns={COLUMNS}
        cellCsx={{ height: '70px' }}
        onSortEnd={handleSort}
        alignHeaders={{
          delete: 'center',
        }}
        align={{
          delete: 'center',
        }}
        requiredColumns={['course', 'posName', 'kitchenName']}
      />
    </Box>
  );
};

export default Coursing;
