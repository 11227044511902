import Box from '@app/components/common/Box';
import ButtonLink from '@app/components/common/Button/ButtonLink';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { openNewTabWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, HiddenBucket } from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import upperFirst from 'lodash/upperFirst';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { eyeIconStyles } from './styles';

interface IHiddenItemSection extends WithTranslation {
  bucketOrigin?: 'subcategory' | 'item' | 'combo';
  bucket?: string;
  navigationInfo: {
    text: string;
    id: number;
  };
  name: string;
  menuTypeName: string;
  onScrollClick?: () => void;
  scrollText?: string;
  description: 'empty' | 'inactive' | 'simpleMode';
}

const COMMON_DESCRIPTIONS: IHiddenItemSection['description'][] = [
  'empty',
  'simpleMode',
];

const HiddenSection = ({
  t,
  bucket = '',
  bucketOrigin = 'item',
  name,
  menuTypeName = '',
  description,
  navigationInfo,
  onScrollClick,
  scrollText,
}: IHiddenItemSection) => {
  // Redux

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state
  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const isSubcategoryScreen = bucketOrigin === 'subcategory';

  const pathURL = `${
    bucket === HiddenBucket.category
      ? '/menu/categories'
      : '/menu/subcategories'
  }/${navigationInfo.id}`;

  return (
    <Card>
      <Grid columnGap={15} rowGap={15}>
        <Grid.Item
          md={8}
          lg={9}
          xl={10}
          csx={{ flexDirection: 'row', gap: '15px' }}>
          <Box csx={eyeIconStyles}>
            <Icon name="MdVisibilityOff" size="26px" />
          </Box>
          <Divider direction="vertical" />
          <Box>
            <Typography fontWeight="medium" csx={{ marginBottom: '5px' }}>
              {isSubcategoryScreen
                ? t(
                    `menuScreen.itemDetails.hiddenItemSection.title.${description}`,
                  )
                : COMMON_DESCRIPTIONS.includes(description)
                ? t(
                    `menuScreen.itemDetails.hiddenItemSection.title.${description}`,
                  )
                : t(
                    'menuScreen.itemDetails.hiddenItemSection.itemsTitle.inactive' as ParseKeys,
                  )}
            </Typography>
            <Typography>
              {COMMON_DESCRIPTIONS.includes(description) || isSubcategoryScreen
                ? t(
                    `menuScreen.itemDetails.hiddenItemSection.${
                      bucket === 'menuType' ? 'item' : bucketOrigin
                    }Description.${bucket}${upperFirst(
                      description,
                    )}` as ParseKeys,
                    {
                      name,
                      bucket: t(
                        `menuScreen.common.bucket.${bucketOrigin}s.singular` as ParseKeys,
                      ),
                      menuTypeName,
                    },
                  )
                : t(
                    'menuScreen.itemDetails.hiddenItemSection.itemDescription.couple' as ParseKeys,
                  )}
            </Typography>
          </Box>
        </Grid.Item>
        <Grid.Item
          md={4}
          lg={3}
          xl={2}
          csx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}>
          <Divider
            direction="vertical"
            data-hide-on-mobile
            csx={{ height: '100%' }}
          />
          <ButtonLink
            to={pathURL}
            variant="primary"
            iconPosition="right"
            csx={{ minWidth: '140px' }}
            onClick={openInNewTab => {
              if (onScrollClick) return onScrollClick();

              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.NAV,
                    text: navigationInfo.text,
                    onPress: () => navigate(pathURL),
                    pathURL,
                  }),
                onRefresh,
              );
            }}>
            {onScrollClick
              ? scrollText || 'Scroll'
              : t(
                  `menuScreen.itemDetails.hiddenItemSection.button.${bucket}` as ParseKeys,
                )}
          </ButtonLink>
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default HiddenSection;
