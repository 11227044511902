import { IActionOption } from '../ActionButtons/types';
import ActionDropdown from '../ActionDropdown';
import Button from '../Button';
import ThreeDots from '../Icon/custom/ThreeDots';

interface IDropdownDots {
  isDisabled?: boolean;
  options?: IActionOption[];
  onClickDisabled?: () => void;
}
const DropdownDots = ({
  options,
  isDisabled,
  onClickDisabled,
}: IDropdownDots) => {
  return (
    <ActionDropdown
      options={options}
      isDisabled={isDisabled}
      trigger={
        <Button
          variant="transparent"
          icon={<ThreeDots isDisabled={isDisabled} />}
          disabled={isDisabled}
          onClickDisabled={onClickDisabled}
          csx={{ marginRight: '5px', height: '20px' }}
        />
      }
    />
  );
};

export default DropdownDots;
