import Box from '@app/components/common/Box';
import UndoRedoButtons from './UndoRedoButtons';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import GridButton from './GridButton';
import { toolStyles } from './styles';
import { useControls } from 'react-zoom-pan-pinch';
import ToolTip from '@app/components/common/ToolTip';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SIDEBAR_WIDTH } from '@app/components/LoggedIn/SideBar/styles';
import { BREAKPOINTS } from '@app/theme/themes';

interface ITools {
  gridWidth: number;
  gridHeight: number;
  layoutStartPosition: number;
  containerRect: DOMRect;
}
const Tools = ({
  gridWidth,
  gridHeight,
  layoutStartPosition,
  containerRect,
}: ITools) => {
  const { setTransform, zoomToElement } = useControls();

  const [maxScale, setMaxScale] = useState<1 | undefined>(undefined);
  const debounce = useRef<NodeJS.Timeout | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleCenterView = useCallback(() => {
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      zoomToElement('layoutArea', maxScale, 100);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxScale]);

  useEffect(() => {
    const handleResizeLayout = () => {
      if (window.innerWidth > gridWidth + parseInt(SIDEBAR_WIDTH)) {
        setMaxScale(1);
      } else {
        setMaxScale(undefined);
      }
      handleCenterView();

      if (window.innerWidth <= parseFloat(BREAKPOINTS.small.max)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResizeLayout);
    return () => {
      window.removeEventListener('resize', handleResizeLayout);
    };
  }, [gridWidth, handleCenterView]);

  useEffect(() => {
    const handleInitializeLayoutPosition = () => {
      if (window.innerWidth > gridWidth + parseInt(SIDEBAR_WIDTH)) {
        handleCenterView();
      } else {
        setTimeout(() => {
          handleCheckSizeAndFocusView();
        }, 0);
      }
    };
    handleInitializeLayoutPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridWidth]);

  useEffect(() => {
    if (window.innerWidth <= parseFloat(BREAKPOINTS.small.max)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleFocusViewClick = () => {
    handleCheckSizeAndFocusView();
  };
  const handleCenterViewClick = () =>
    zoomToElement('layoutArea', maxScale, 100);

  const handleCheckSizeAndFocusView = () => {
    if (containerRect.height < gridHeight) {
      setTransform(
        15,
        window.innerWidth <= parseFloat(BREAKPOINTS.small.max) ? 50 : 80,
        1,
      );
    } else {
      setTransform(15, layoutStartPosition, 1);
    }
  };

  return (
    <Box csx={toolStyles}>
      <UndoRedoButtons isMobile={isMobile} />
      <Box
        csx={[
          displayFlexRowCenterStyles,
          { gap: '10px', alignItems: 'center' },
        ]}>
        <ToolTip content="Focus" direction="bottom">
          {maxScale !== 1 && (
            <Button
              disabled={maxScale !== 1 ? false : true}
              onClick={handleFocusViewClick}
              csx={{ width: '50px' }}
              icon={<Icon name="MdOutlineZoomOutMap" />}
            />
          )}
        </ToolTip>
        <ToolTip content="Center" direction="bottom">
          <Button
            onClick={handleCenterViewClick}
            csx={{ width: '50px' }}
            icon={<Icon name="MdCenterFocusStrong" />}
          />
        </ToolTip>

        <GridButton />
      </Box>
    </Box>
  );
};

export default Tools;
