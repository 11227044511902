import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectModifierItems,
} from '@app/state/menu/menuSelectors';
import {
  BreadCrumbAction,
  ITEM_LINKED_MODIFIER_ITEM_INITIAL_STATE,
  SectionId,
  actionCreatorsMenu,
  menuItemsLinkedModifierItemsFactory,
} from '@westondev/tableturn-core';
import { pick, uniq } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SectionId.ASSOCIATION;
const getMemoizedItemData = makeSelectItemData('items', SECTION_ID);

const LinkedModItems = ({ t }: WithTranslation) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const { itemData } = useSelector(getMemoizedItemData);
  const modifierItems = useSelector(selectModifierItems);

  const linkedModifierItems = itemData?.linkedModifierItems;
  const itemName = itemData?.name;

  const linkedModifierItemsCardList = useMemo(
    () =>
      menuItemsLinkedModifierItemsFactory(
        linkedModifierItems,
        modifierItems,
        itemName,
      ).map(modifierItem => {
        return {
          ...modifierItem,
          isActive: true,
        };
      }),
    [linkedModifierItems, modifierItems, itemName],
  );

  const [isEditMode, setEditMode] = useState(false);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const linkedModifierItemIds = useMemo(
    () => Object.keys(linkedModifierItems || {}),
    [linkedModifierItems],
  );

  const alreadyLinkedModifierItemIds = useMemo(
    () =>
      Object.values(modifierItems)
        .filter(modifierItem => modifierItem.linkedItemId)
        .map(modifierItem => `${modifierItem.modifierItemId}`),
    [modifierItems],
  );

  const forbiddenLinkedModifierItemIds = useMemo(
    () => uniq([...linkedModifierItemIds, ...alreadyLinkedModifierItemIds]),
    [linkedModifierItemIds, alreadyLinkedModifierItemIds],
  );

  const isCopyLinkedModItemCreated = useMemo(
    () => linkedModifierItemIds.includes('0'),
    [linkedModifierItemIds],
  );

  const linkedModifierItemsLength = useMemo(
    () => linkedModifierItemIds.length,
    [linkedModifierItemIds],
  );

  const handleDeleteLinkedModifierItem = (linkedModifierItemId: number) => {
    setValue(
      {
        linkedModifierItems: pick(
          linkedModifierItems,
          without(
            Object.keys(linkedModifierItems).map(linkedModifierItem =>
              Number(linkedModifierItem),
            ),
            linkedModifierItemId,
          ),
        ),
      },
      SECTION_ID,
    );
  };

  const createCopyLinkedModItem = () => {
    if (isCopyLinkedModItemCreated) return;
    setValue(
      {
        linkedModifierItems: {
          ...linkedModifierItems,
          0: ITEM_LINKED_MODIFIER_ITEM_INITIAL_STATE,
        },
      },
      SECTION_ID,
    );
  };

  const closeModal = () => setOpenSelectionModal(false);

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const clonedLinkedModifierItems = cloneDeep(linkedModifierItems);
    buttons.forEach(button => {
      const buttonId = Number(button.id);
      if (!clonedLinkedModifierItems[buttonId]) {
        clonedLinkedModifierItems[buttonId] = {
          ...ITEM_LINKED_MODIFIER_ITEM_INITIAL_STATE,
          modifierItemId: buttonId,
        };
      }
    });
    setValue({ linkedModifierItems: clonedLinkedModifierItems }, SECTION_ID);
    closeModal();
  };

  return (
    <>
      <SelectionModal
        type="modifierItems"
        detailsScreenProps={{
          currentRelationsIds: forbiddenLinkedModifierItemIds,
          wantedEntity: 'modifierItems',
        }}
        onAssociate={handleOnAssociate}
        onModalClose={closeModal}
        active={openSelectionModal}
      />

      <AssignedElements
        elements={linkedModifierItemsCardList}
        noElementsComponent={
          linkedModifierItemsLength === 0 ? (
            <Button
              variant="primary"
              icon={<Icon name="MdAddCircle" />}
              onClick={createCopyLinkedModItem}>
              Create Linked mod item
            </Button>
          ) : undefined
        }
        subCardProps={{
          title: t('menuScreen.itemDetails.associations.linkedModItems'),
          actionOptions: [
            {
              text: t(
                'menuScreen.itemDetails.linkedModItems.options.createNew',
              ),
              handler: () => createCopyLinkedModItem(),
              isDisabled: isCopyLinkedModItemCreated,
            },
            {
              text: t(
                'menuScreen.itemDetails.linkedModItems.options.addExisting',
              ),
              handler: () => setOpenSelectionModal(true),
            },
          ],
          onEditMode: setEditMode,
        }}
        renderItem={modifierItem => {
          const pathURL = `/menu/modifiers/modifier-items/${modifierItem.id}`;
          return (
            <Card.Item
              key={`modifierItemId${modifierItem.id}`}
              {...modifierItem}
              showRemoveIcon={isEditMode}
              onRemoveClick={id => {
                handleDeleteLinkedModifierItem(id as number);
              }}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                modifierItem.id &&
                  checkForChangesAndNavigateWeb(
                    () =>
                      addBreadcrumbLocationWeb({
                        action: BreadCrumbAction.NAV,
                        text: modifierItem.title as string,
                        onPress: () => navigate(pathURL),
                        pathURL,
                      }),
                    onRefresh,
                  );
              }}
              footerIcon={
                <Icon
                  name="FaLink"
                  color="persistentSemanticBlue"
                  size="18px"
                />
              }
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}
      />
    </>
  );
};

export default LinkedModItems;
