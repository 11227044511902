import { MQ_MAX_LARGE, MQ_MAX_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';

export const commonSubsectionStyles = () =>
  css({
    borderWidth: '0',
    paddingBottom: '20px',
    [MQ_MAX_MEDIUM]: {
      borderRightWidth: '0',
      marginTop: '0',
    },
  });

export const registerDisplaySubsectionStyles = () =>
  css({
    borderRightWidth: '1px',
    paddingRight: '20px',
    [MQ_MAX_MEDIUM]: {
      marginTop: '20px',
      paddingRight: '0',
    },
  });

export const previewSubsectionStyles = () =>
  css({
    borderRightWidth: '1px',
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    [MQ_MAX_LARGE]: {
      borderRightWidth: '0',
    },
  });

export const categoryChildrenSubsectionStyles = () =>
  css({
    padding: '20px 20px 10px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [MQ_MAX_LARGE]: {
      paddingLeft: '0',
    },
  });

export const categoryChildrenDropdownStyles = () =>
  css({
    maxWidth: '200px',
  });
