import { useSelector } from 'react-redux';
import { elementStyles } from '../styles';
import ElementPanHandlerMemo, {
  TElement,
} from '../ElementPanHandler/ElementPanHandler';
import { DEFAULT_ELEMENT_SIZES } from '../types';
import Typography from '@app/components/common/Typography';
import { ILayoutProps } from '@westondev/tableturn-core';
import { selectSelectedElement } from '@app/state/selectors/tableLayoutSelectors';

interface IFreeForm extends TElement {
  isAnimated?: boolean;
}
const FreeForm = ({ element, layoutProps, isAnimated = false }: IFreeForm) => {
  // Redux
  const selectedElement = useSelector(selectSelectedElement);

  // Local State
  const showControls =
    selectedElement?.id === element.id && selectedElement?.showControls;

  const width = DEFAULT_ELEMENT_SIZES.custom.width;
  const height = DEFAULT_ELEMENT_SIZES.custom.height;

  if (isAnimated)
    return (
      <ElementPanHandlerMemo
        element={element}
        isSelected={selectedElement?.id === element.id}
        showControls={showControls}
        elementStyles={[
          elementStyles,
          {
            width: element.width,
            height: element.height,
          },
        ]}
        defaultElementProps={{ width, height }}
        layoutProps={layoutProps as ILayoutProps}>
        <Typography
          fontWeight="medium"
          color="darkBlue"
          style={{
            rotate: `${element.rotation * -1}deg`,
          }}>
          {element.name}
        </Typography>
      </ElementPanHandlerMemo>
    );
  // TODO:  Implement table layout for register module
  return null;
};

export default FreeForm;
