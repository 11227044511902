import { loadReportBucketChangeData as loadReportBucketChangeDataThunk } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsReports,
  IReportsStore,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRootSelector from './useRootSelector';

const useLoadReportsChangeData = <K extends keyof IReportsStore>(
  id: number,
  bucket: K,
): IReportsStore[K][number] | null => {
  const dispatch = useDispatch();
  const loadReportBucketChangeData = bindActionCreators(
    loadReportBucketChangeDataThunk,
    dispatch,
  );
  const resetReportsChangeData = bindActionCreators(
    actionCreatorsReports.resetReportsChangeData,
    dispatch,
  );

  const report = useRootSelector(
    state => state.reports.changeData.data as IReportsStore[K][number] | null,
  );

  const isSet = useRootSelector(state => state.reports.changeData.id === id);

  const reportFilled = useRootSelector(
    state => state.reports.selectedMode.currentReports[bucket][id]?.id === id,
  );

  useEffect(() => {
    !isSet && loadReportBucketChangeData({ id, bucket });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, bucket, isSet, reportFilled]);

  useEffect(() => {
    return () => {
      resetReportsChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return report;
};

export default useLoadReportsChangeData;
