import { MQ_MIN_LARGE, MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const needAssistanceStyles = (theme: Theme) =>
  css({
    position: 'absolute',
    bottom: '45px',
    width: '100%',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '7px',
    '> .title': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '>span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '> .links': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '> .separator': {
        marginInline: '4px',
        height: 'auto',
        width: '1px',
      },
      '.dot': {
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: theme.colors.textWhite,
        marginInline: '4px',
      },
      [MQ_MIN_LARGE]: {
        '& p': {
          display: 'block',
        },
      },
    },
    '&.inline': {
      position: 'relative',
      display: 'flex',
      '> .title p': {
        color: theme.colors.darkGrey,
      },
      marginTop: '100px',
    },
    [MQ_MIN_LARGE]: {
      display: 'flex',
      '&.inline': {
        display: 'none',
      },
      '.needAssistanceButton': {
        display: 'none',
      },
    },
  });

export const needAssistanceButtonStyles = () =>
  css({
    position: 'absolute',
    right: '30px',
    '.caption': {
      display: 'none',
    },
    '> button': {
      width: '40px',
      svg: {
        marginRight: '-10px',
      },
    },
    [MQ_MIN_SMALL]: {
      position: 'relative',
      right: '0',
      '> button': {
        svg: {
          marginRight: 0,
        },
      },
    },
    [MQ_MIN_MEDIUM]: {
      '> button': {
        minWidth: '140px',
      },
      '.caption': {
        display: 'block',
      },
    },
    [MQ_MIN_LARGE]: {
      display: 'none',
    },
  });
