import Box from '../Box';
import GridItem from './GridItem';
import {
  columnGapStyles,
  flexStyles,
  gridContainer,
  rowGapStyles,
} from './styles';
import { IGrid } from './types';

const Grid = ({
  children,
  container,
  simpleMode,
  columnGap,
  rowGap,
  columns = 12,
  csx,
  divRef,
  boxProps,
}: IGrid) => {
  const gridCustomStyles = [
    simpleMode ? flexStyles : gridContainer(columns),
    columnGap ? columnGapStyles(columnGap) : undefined,
    rowGap ? rowGapStyles(rowGap) : undefined,
  ];

  return (
    <Box
      className={container ? 'grid-container' : 'grid'}
      csx={[gridCustomStyles, csx]}
      ref={divRef}
      {...boxProps}>
      {children}
    </Box>
  );
};

Grid.Item = GridItem;

export default Grid;
