import { css } from '@emotion/react';

export const showAllPricingInputsStyles = () =>
  css({
    '& .container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '2px',
    },
  });
