import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { selectMenu } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import {
  IItem,
  menuTypeAssignedItemsFactoryCore,
} from '@westondev/tableturn-core';

const menuTypeAssignedItemsFactory = (
  items: Record<number, IItem>,
  menuTypeAssignedItemIds: number[],
  menuTypeId: number,
  onClick: (id: number, text: string, openInNewTab?: boolean) => void,
  onIsLink: (id: number) => void,
  enableSimpleMode: boolean,
): IItemCard[] => {
  const { categories, subcategories, menuTypes } = selectMenu(store.getState());
  return <IItemCard[]>menuTypeAssignedItemsFactoryCore(
    items,
    menuTypeAssignedItemIds,
    menuTypeId,
    () => null,
    categories,
    subcategories,
    menuTypes,
    enableSimpleMode,
  ).map(item => ({
    id: item.id,
    onRemoveClick: item.onDelete,
    title: item.title,
    isActive: item.status === undefined ? true : item?.status,
    showStatus: true,
    isHidden: item.isHidden,
    onClick: (openInNewTab?: boolean) =>
      onClick(item.id, item.title, openInNewTab),
    isLink: onIsLink(item.id),
  }));
};

export default menuTypeAssignedItemsFactory;
