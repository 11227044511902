import { selectMenu } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import {
  CoreRootState,
  ICombo,
  IComboCard,
  menuComboCardFactoryCore,
} from '@westondev/tableturn-core';

export type { IComboCard } from '@westondev/tableturn-core';

export const menuComboCardFactory = (combos: {
  [key: number]: ICombo;
}): IComboCard[] => {
  const state = store.getState();
  const currentMenu = selectMenu(state as CoreRootState & RootState);
  const { categories, subcategories, menuTypes } = currentMenu;
  return menuComboCardFactoryCore(combos, categories, subcategories, menuTypes);
};
