import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  SettingsSectionId,
  IRegisterSettings,
} from '@westondev/tableturn-core';

export interface ISpecialTagsTable {
  id: number;
  status: boolean;
  name: string;
  posName: string;
  abbreviation: string;
  color: string | null;
  textColor: string | null;
  canDelete: boolean;
}
export const SECTION_ID = SettingsSectionId.TAGS;
export const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

export const columnHelper = createColumnHelper<ISpecialTagsTable>();

export const SPECIAL_TAGS_TABLE_ID = 'special-tags-table';
export const ROW_ID_PREFIX = 'special-tag-row-';
