import { MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const bottomSheetHeaderStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    paddingBlock: '5px',
    borderBottom: `1px solid ${theme.colors.lightestGrey}`,
    '.tabsContainer': {
      display: 'flex',
      alignItems: 'center',
      gap: '0px',
      '> button': {
        height: '30px',
      },
      [MQ_MIN_MEDIUM]: {
        gap: '5px',
        '> button': {
          height: '50px',
        },
      },
    },
  });

export const newElementContainer = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    gap: '10px',
    maxHeight: '150px',
    overflowY: 'auto',
    '.newElement': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      '.header': {
        paddingBottom: '5px',
        borderBottom: `1px solid ${theme.colors.lightestGrey}`,
      },
      '.objectsList': {
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
      },
    },
    [MQ_MIN_SMALL]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxHeight: '100%',
      '.newElement': {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        '.header': {
          height: '65px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '0px',
          paddingInline: '10px',
          borderRight: `1px solid ${theme.colors.lightestGrey}`,
        },
        '.objectsList': {
          flexWrap: 'nowrap',
        },
      },
    },
  });
