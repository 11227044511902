import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  IKitchenTicketSettings,
  IPrepStationVersion,
  IWithPrepStationVersionId,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import CustomizingReceiptsTable from '../CustomizingReceiptsTable';

const SECTION_ID = SettingsSectionId.CUSTOMIZING_RECEIPTS_HEADER;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

interface ITicketHeader {
  title?: string;
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

const TicketHeaderTable = ({ title, prepStationVersionId }: ITicketHeader) => {
  const { data } = useSelector(getMemoizedItemData);
  const ticketHeader = prepStationVersionId
    ? data?.prepStationVersions[prepStationVersionId].ticketHeader
    : (data as unknown as IPrepStationVersion)?.ticketHeader;
  return (
    <CustomizingReceiptsTable
      sectionId={SECTION_ID}
      data={ticketHeader}
      tableField="ticketHeader"
      title={title}
      prepStationVersionId={prepStationVersionId}
    />
  );
};

export default TicketHeaderTable;
