import Box from '../../Box';
import Typography from '../../Typography';

interface ITag86 {
  width?: string | number;
}

const Tag86 = ({ width }: ITag86) => {
  return (
    <Box
      csx={theme => ({
        backgroundColor: theme.colors.semanticOrange,
        height: 25,
        width: width || 50,
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}>
      <Typography color="textWhite" variant="caption">
        86
      </Typography>
    </Box>
  );
};

export default Tag86;
