import { useEffect, useState } from 'react';
import Input from '..';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import { InputNumberWithButtonStyles } from './styles';
import Typography from '../../Typography';
import { labelStyles } from '../styles';

interface IInputNumberWithButtons {
  value: number;
  onChange: (value: number) => void;
  type?: 'number' | 'string';
  suffix?: string;
  isEditable?: boolean;
  step?: number;
  min?: number;
  max?: number;
  label?: string;
  onBlur?: () => void;
  onIncrementClick?: () => void;
  onDecrementClick?: () => void;
}
const InputNumberWithButtons = ({
  value,
  onChange,
  type = 'string',
  suffix,
  isEditable = true,
  step = 1,
  min,
  max,
  label,
  onBlur,
  onIncrementClick,
  onDecrementClick,
}: IInputNumberWithButtons) => {
  const [currentValue, setCurrentValue] = useState(value);

  const incrementValue = () => {
    if (onIncrementClick) return onIncrementClick();
    setCurrentValue(prev => prev + step);
  };

  const decrementValue = () => {
    if (onDecrementClick) return onDecrementClick();
    setCurrentValue(prev => prev - step);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (currentValue === value) return;
    onChange(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <Box csx={InputNumberWithButtonStyles}>
      <Typography csx={labelStyles}>{label}</Typography>
      <Box className="inputContainer">
        <Button
          variant="active"
          csx={{
            minWidth: '50px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={decrementValue}
          icon={<Icon name="MdRemove" color="textWhite" />}
          disabled={currentValue === min}
        />
        <Input
          type={type}
          min={min}
          readOnly={!isEditable}
          containerCsx={{ borderInline: '0px', borderRadius: 0 }}
          value={type === 'string' ? `${currentValue} ${suffix || ''}` : value}
          onBlur={onBlur}
          onChange={({ currentTarget: { value: newValue } }) => {
            setCurrentValue(parseInt(newValue || '1'));
          }}
          style={{ textAlign: 'center' }}
        />
        <Button
          variant="active"
          csx={{
            minWidth: '50px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={incrementValue}
          icon={<Icon name="MdAdd" color="textWhite" />}
          disabled={currentValue === max}
        />
      </Box>
    </Box>
  );
};

export default InputNumberWithButtons;
