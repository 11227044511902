import { RootState } from '@app/state/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ErrorRow,
  SectionText,
  SubSectionText,
} from './BucketValidationResolver';
import { ErrorTemplates } from './ErrorTemplates';
import { ISpecialErrorFields } from '@westondev/tableturn-core';
import Box from '@app/components/common/Box';
import { ParseKeys } from 'i18next';
import { displayFlexColumnStyles } from '@app/theme/commonStyles';

const RenderSpecialErrorBody = () => {
  const { t } = useTranslation();
  const formErrors = useSelector(
    (state: RootState) => state.menu.changeData.errors,
  );
  const errors = formErrors as ISpecialErrorFields;
  const errorsArray = Object.entries(errors);
  const menuTypes = useSelector(
    (state: RootState) => state.menu.masterMenu.menuTypes,
  );

  const commonIssues: [string, string][] = errorsArray.filter(
    ([key]) => key !== 'menuTypeVersions',
  );
  const menuTypeIssues =
    'menuTypeVersions' in errors ? errors.menuTypeVersions : null;

  return (
    <>
      {commonIssues.length > 0 && (
        <Box style={{ marginBottom: '15px' }}>
          {commonIssues.map(([key, value]) => (
            <ErrorRow key={key}>
              <ErrorTemplates
                errorKey={value}
                field={t(
                  `menuScreen.itemDetails.validationFields.${key}` as ParseKeys,
                )}
              />
            </ErrorRow>
          ))}
        </Box>
      )}
      {menuTypeIssues && (
        <>
          <Box csx={[displayFlexColumnStyles, { gap: '15px' }]}>
            <SectionText>{t('validations.issuesInMenuTypes')}</SectionText>
            {Object.entries(menuTypeIssues).map(([key, value]) => (
              <Box key={key} csx={[displayFlexColumnStyles, { gap: '10px' }]}>
                <SubSectionText>
                  {t('validations.menuTypeTitle', {
                    menuType: menuTypes[Number(key)]?.posName,
                  })}
                </SubSectionText>
                <Box>
                  {Object.entries(value).map(([menuTypeKey, menuTypeError]) => (
                    <ErrorRow key={menuTypeKey}>
                      <ErrorTemplates
                        field={t(
                          `menuScreen.itemDetails.validationFields.${menuTypeKey}` as ParseKeys,
                        )}
                        errorKey={
                          typeof menuTypeError === 'string'
                            ? menuTypeError
                            : 'specials.availabilityError'
                        }
                      />
                    </ErrorRow>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default RenderSpecialErrorBody;
