import Card from '@app/components/common/Card';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import TaxesSelectionModal from '@app/components/common/SelectionModal/TaxesSelectionModal';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  selectChangeMassUpdateData,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import {
  actionCreatorsMenu,
  FormMode,
  generateTaxDescription,
  IMassUpdate,
} from '@westondev/tableturn-core';
import without from 'lodash/without';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IItemTaxesSubsection } from './types';

const ItemTaxesSubsection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  mode,
  taxField = 'taxIds',
  isCombo,
}: IItemTaxesSubsection) => {
  const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isRemoveTaxIds = taxField === 'removeTaxIds';

  const settingsTaxes = useSelector(currentTaxesSettingsSelector);
  const items = useSelector(selectItems);
  const allTaxIds = useRootSelector(
    state => Object.keys(currentTaxesSettingsSelector(state)).map(Number),
    shallowEqual,
  );

  const allItemIds = useRootSelector(
    state => selectChangeMassUpdateData(state)?.bucketIds || [],
    shallowEqual,
  );

  const allItemMenuTypeIds = useRootSelector(
    state =>
      selectChangeMassUpdateData(state)?.selectionCriteria?.menuTypeIds || [],
    shallowEqual,
  );

  const allItemTaxIds = useMemo(() => {
    const newArray: number[] = [];
    allItemIds.forEach(id => {
      const item = items[id];
      if (item) {
        allItemMenuTypeIds.forEach(currentMenuTypeVersionId => {
          newArray.push(
            ...item.menuTypeVersions[currentMenuTypeVersionId].taxIds,
          );
        });
      }
    });
    return Array.from(new Set(newArray));
  }, [allItemIds, items, allItemMenuTypeIds]);

  const allowedTaxIds = useMemo(
    () =>
      isRemoveTaxIds
        ? without(allTaxIds, ...allItemTaxIds)
        : (itemData as IMassUpdate)[taxField],
    [allTaxIds, itemData, taxField, isRemoveTaxIds, allItemTaxIds],
  );

  const generateCustomCards = useCallback(
    (taxes: number[]) => {
      const cards = taxes.map(id => ({
        id: id,
        title: settingsTaxes[id].name,
        subTitle: generateTaxDescription(
          settingsTaxes[id].taxType,
          settingsTaxes[id].typeData,
        ),
        isActive: true,
        onRemoveClick: (taxId: number | string) => {
          const newItemMenuTypeTaxes = without(
            (itemData as IMassUpdate)[taxField] as number[],
            Number(taxId),
          );

          setValue(
            {
              [taxField]: newItemMenuTypeTaxes,
            },
            sectionId,
            menuTypeVersionId,
          );
        },
        onClick: () => null,
      }));

      return cards;
    },
    [settingsTaxes, itemData, menuTypeVersionId, sectionId, setValue, taxField],
  );

  const itemMenuTypeTaxCards = useMemo(() => {
    const taxes = ((itemData as IMassUpdate)?.[taxField] as number[]) || [];

    const cards = generateCustomCards(taxes);

    return cards;
  }, [itemData, generateCustomCards, taxField]);

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));

    const newTaxes = [
      ...((itemData as IMassUpdate)[taxField] || []),
      ...newButtonIds,
    ];

    setValue({ [taxField]: newTaxes }, sectionId, menuTypeVersionId);
    setIsTaxesModalOpen(false);
  };

  const sectionContainerProps = isMassUpdate
    ? {
        csx: { borderWidth: 0 },
        cardsContainerCsx: { padding: 0 },
      }
    : {};

  const translationPath = isRemoveTaxIds ? 'remove' : 'add';

  return (
    <>
      <TaxesSelectionModal
        onAssociate={handleOnAssociate}
        active={isTaxesModalOpen}
        onModalClose={() => setIsTaxesModalOpen(false)}
        taxIds={allowedTaxIds}
        scrollCardSectionNumberOfColumns={6}
      />
      <AssignedElements
        elements={itemMenuTypeTaxCards}
        subCardProps={{
          title: t(
            isMassUpdate
              ? `menuScreen.itemDetails.taxesSection.${translationPath}TaxesSection.title`
              : isCombo
              ? 'menuScreen.comboDetails.taxesSection.comboTaxes'
              : 'menuScreen.itemDetails.taxesSection.itemTaxes',
          ),
          subtitle: isMassUpdate
            ? t(
                `menuScreen.itemDetails.taxesSection.${translationPath}TaxesSection.description`,
              )
            : undefined,
          actionOptions: [
            {
              text: t('components.actionButtons.addExistingTax'),
              icon: false,
              handler: () => setIsTaxesModalOpen(true),
            },
          ],
          onEditMode: () => setIsEditMode(!isEditMode),
          ...sectionContainerProps,
        }}
        noElementsMessage={t(
          isMassUpdate
            ? `menuScreen.itemDetails.taxesSection.${translationPath}TaxesSection.empty`
            : 'menuScreen.itemDetails.taxesSection.noTaxes',
        )}
        renderItem={card => (
          <Card.Item
            key={`itemMenuTypeTaxCard${card.id}`}
            showRemoveIcon={isEditMode}
            {...card}
          />
        )}
      />
    </>
  );
};

export default ItemTaxesSubsection;
