import { css } from '@emotion/react';

export const InputNumberWithButtonStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    '.inputContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
