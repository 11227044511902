import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import {
  IKitchenTicketPrepStationVersionDiff,
  TComboMenuTypeVersionDiff,
  TMenuTypeVersionDiff,
  TPricingVersionDiff,
  TSpecialMenuTypeVersionDiff,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { menuTypeVersionsButtonsStyles } from './styles';

interface IMenuTypeVersionsButtons extends WithTranslation {
  menuTypeVersions:
    | TPricingVersionDiff[]
    | TMenuTypeVersionDiff[]
    | TComboMenuTypeVersionDiff[]
    | TSpecialMenuTypeVersionDiff[]
    | IKitchenTicketPrepStationVersionDiff[];
  selectedMenuTypeVersion: number;
  onPress: (newMenuTypeVersionIndex: number) => void;
}

const MenuTypeVersionsButtons = ({
  menuTypeVersions,
  selectedMenuTypeVersion,
  onPress,
  t,
}: IMenuTypeVersionsButtons) => {
  return (
    <Box
      csx={{
        position: 'relative',
        top: 20,
        cursor: 'pointer',
        overflowX: 'auto',
      }}>
      <Box csx={{ display: 'flex', minWidth: '1000px' }}>
        {menuTypeVersions.map((menuType, index) => (
          <Box
            key={`menuType_${index}`}
            onClick={() =>
              selectedMenuTypeVersion === index ? null : onPress(index)
            }
            csx={[
              menuTypeVersionsButtonsStyles(
                menuType.action,
                selectedMenuTypeVersion === index,
                selectedMenuTypeVersion,
                index,
                menuTypeVersions.length,
              ),
            ]}>
            <Box className="content">
              <Typography className="actionText" variant="caption">
                {menuType.action === 'add'
                  ? t('app.modals.itemsDiff.menuType.newMenuType')
                  : menuType.action === 'remove'
                  ? t('app.modals.diffModal.removed')
                  : `${menuType.totalOfChanges} ${
                      menuType.totalOfChanges > 1
                        ? t('app.modals.diffModal.changes')
                        : t('app.modals.diffModal.change')
                    }`}
              </Typography>
            </Box>
            <Typography
              color={
                selectedMenuTypeVersion === index
                  ? 'persistentSemanticBlue'
                  : 'black'
              }
              fontWeight="medium">
              {menuType.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MenuTypeVersionsButtons;
