import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BEHAVIOR_ON_NEW_ORDER_OPTIONS,
  IRoleSettingsStore,
  RoleDefaultModuleType,
  SettingsSectionId,
  actionCreatorsSettings,
  behaviorOnNewOrderFactory,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.REGISTER;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const RegisterSettings = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const activeSwitch = (
    <Switch
      label={t('settingsModule.roleDetailsSettings.allowAccessToThisModule')}
      checked={
        data ? data.homeScreenPermissions.registerModuleAvailable : false
      }
      onChange={isActive => {
        const currentData = store.getState().settings.changeData
          .data as IRoleSettingsStore;

        const currentDefaultModule = currentData.defaultModule;

        const newDefaultModule =
          !isActive &&
          currentDefaultModule === RoleDefaultModuleType.REGISTER_MODULE
            ? RoleDefaultModuleType.HOME_MODULE
            : currentDefaultModule;

        const newHomeScreenPermissions = {
          ...currentData?.homeScreenPermissions,
          registerModuleAvailable: isActive,
        };

        setValue<IRoleSettingsStore>(
          {
            homeScreenPermissions: newHomeScreenPermissions,
            defaultModule: newDefaultModule,
          },
          SECTION_ID,
        );

        if (
          currentData?.homeScreenPermissions.registerModuleDefault &&
          !isActive
        ) {
          setValue<IRoleSettingsStore>(
            {
              homeScreenPermissions: {
                ...newHomeScreenPermissions,
                registerModuleDefault: false,
              },
            },
            SettingsSectionId.HOME,
          );
        } else {
          setValue<IRoleSettingsStore>({}, SettingsSectionId.HOME);
        }
      }}
    />
  );

  const behaviorDropdownOptions = useMemo(
    () => behaviorOnNewOrderFactory(t),
    [t],
  );

  const dropdownValue = useMemo(
    () =>
      Number(
        Object.keys(BEHAVIOR_ON_NEW_ORDER_OPTIONS).find(
          key =>
            BEHAVIOR_ON_NEW_ORDER_OPTIONS[Number(key)] ===
            data?.registerPermissions.behaviorOnNewOrder,
        ),
      ),
    [data?.registerPermissions.behaviorOnNewOrder],
  );

  return (
    <AccordionSection
      title={t('loggedIn.commonTitles.register')}
      expandedHeight="70px"
      description={t('settingsModule.roleDetailsSettings.register.description')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...props}>
      <>
        {activeSwitch}
        <Divider csx={{ marginBlock: '20px' }} />
        <Grid columnGap={20} rowGap={20}>
          <Grid.Item>
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.title',
              )}
            </DetailsSubtitle>
            <Typography variant="caption">
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.description',
              )}
            </Typography>
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                width: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOrders',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOrderstoolTip',
                )}
                checked={data ? data.registerPermissions.createOrders : false}
                onChange={createOrders =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        createOrders,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Dropdown
                label={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.label',
                )}
                placeholder={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.label',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.toolTip',
                )}
                data={[behaviorDropdownOptions]}
                onChange={defaultModule => {
                  const newValue = BEHAVIOR_ON_NEW_ORDER_OPTIONS[defaultModule];

                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        behaviorOnNewOrder: newValue,
                      },
                    },
                    SECTION_ID,
                  );
                }}
                value={dropdownValue}
              />
              <Divider />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.deleteOrders',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.deleteOrderstoolTip',
                )}
                checked={data ? data.registerPermissions.deleteOrders : false}
                onChange={deleteOrders =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        deleteOrders,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.manageTables',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.manageTablestoolTip',
                )}
                checked={data ? data.registerPermissions.manageTables : false}
                onChange={manageTables =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        manageTables,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allowChangeMenuType',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allowChangeMenuTypetoolTip',
                )}
                checked={
                  data ? data.registerPermissions.showMenuSelector : false
                }
                onChange={showMenuSelector =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        showMenuSelector,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.editItemPrice',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.editItemPricetoolTip',
                )}
                checked={data ? data.registerPermissions.editItemPrice : false}
                onChange={editItemPrice =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        editItemPrice,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allow86',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allow86toolTip',
                )}
                checked={data ? data.registerPermissions.allow86 : false}
                onChange={allow86 =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        allow86,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.modifyItemsSentToKitchen',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.modifyItemsSentToKitchentoolTip',
                )}
                checked={
                  data
                    ? data.registerPermissions.modifyItemsSentToKitchen
                    : false
                }
                onChange={modifyItemsSentToKitchen =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        modifyItemsSentToKitchen,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOpenItemsOrModItems',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOpenItemsOrModItemstoolTip',
                )}
                checked={
                  data
                    ? data.registerPermissions.createOpenItemsOrModItems
                    : false
                }
                onChange={createOpenItemsOrModItems =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        createOpenItemsOrModItems,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={3}
            xl={3}
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addAnyDiscount',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addAnyDiscounttoolTip',
              )}
              checked={data ? data.registerPermissions.addAnyDiscount : false}
              onChange={addAnyDiscount =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      addAnyDiscount,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addCustomDiscount',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addCustomDiscounttoolTip',
              )}
              checked={
                data ? data.registerPermissions.addCustomDiscount : false
              }
              onChange={addCustomDiscount =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      addCustomDiscount,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>

          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.title',
              )}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.reprint',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.reprinttoolTip',
              )}
              checked={data ? data.registerPermissions.reprint : false}
              onChange={reprint =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      reprint,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.deletePrintJobs',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.deletePrintJobstoolTip',
              )}
              checked={data ? data.registerPermissions.deletePrintJobs : false}
              onChange={deletePrintJobs =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      deletePrintJobs,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>

          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.title',
              )}
            </DetailsSubtitle>
            <Typography variant="caption">
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.description',
              )}
            </Typography>
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.editOtherUsersOrders',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.editOtherUsersOrderstoolTip',
              )}
              checked={
                data ? data.registerPermissions.editOtherUsersOrders : false
              }
              onChange={editOtherUsersOrders =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      editOtherUsersOrders,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.overrideLicenseEditingId',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.overrideLicenseEditingIdtoolTip',
              )}
              checked={
                data ? data.registerPermissions.overrideLicenseEditingId : false
              }
              onChange={overrideLicenseEditingId =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      overrideLicenseEditingId,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.transferOrderOwner',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.transferOrderOwnertoolTip',
              )}
              checked={
                data ? data.registerPermissions.transferOrderOwner : false
              }
              onChange={transferOrderOwner =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      transferOrderOwner,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t('settingsModule.roleDetailsSettings.register.payments.title')}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.createChecks',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.createCheckstoolTip',
              )}
              checked={data ? data.registerPermissions.createChecks : false}
              onChange={createChecks =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      createChecks,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.createPayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.createPaymentstoolTip',
              )}
              checked={data ? data.registerPermissions.createPayments : false}
              onChange={createPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      createPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.transferPayments',
              )}
              checked={data ? data.registerPermissions.transferPayments : false}
              onChange={transferPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      transferPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.voidRefundPayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.voidRefundPaymentstoolTip',
              )}
              checked={
                data ? data.registerPermissions.voidRefundPayments : false
              }
              onChange={voidRefundPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      voidRefundPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.batch',
              )}
              checked={data ? data.registerPermissions.batch : false}
              onChange={batch =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      batch,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t('settingsModule.roleDetailsSettings.register.drawers.title')}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.manageDrawers',
              )}
              checked={data ? data.registerPermissions.manageDrawers : false}
              onChange={manageDrawers =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      manageDrawers,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.viewDrawerBalance',
              )}
              checked={
                data ? data.registerPermissions.viewDrawerBalance : false
              }
              onChange={viewDrawerBalance =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      viewDrawerBalance,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.serverBankEnabled',
              )}
              checked={
                data ? data.registerPermissions.serverBankEnabled : false
              }
              onChange={serverBankEnabled =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      serverBankEnabled,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
        </Grid>
      </>
    </AccordionSection>
  );
};

export default RegisterSettings;
