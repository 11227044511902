import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const passwordMustBeStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.lightestGrey,
    borderRadius: '10px',
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    '> p:first-of-type': {
      marginBottom: '10px',
    },
    '.validations': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: '3px',
      '.validation': {
        display: 'flex',
        alignItems: 'center',
        '> svg,span': {
          marginRight: '5px',
        },
      },
      [MQ_MIN_SMALL]: {
        rowGap: '5px',
        gridTemplateColumns: '.9fr 1fr',
      },
    },
  });
