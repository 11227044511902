import Grid from '@app/components/common/Grid';
import {
  IRegisterSettings,
  SettingsSectionId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import AdsSettings from './AdsSettings';
import GeneralSettings from './GeneralSettings';
import Tips from './Tips';

const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY;
const getMemoizedSettingsData =
  settingsSelectors.makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const CustomerFacingDisplay = () => {
  const { data } = useSelector(getMemoizedSettingsData);

  return (
    <>
      <Grid rowGap={15}>
        <Grid.Item mb={12}>
          <GeneralSettings data={data} />
        </Grid.Item>
        <Grid.Item mb={12}>
          <AdsSettings data={data} />
        </Grid.Item>
        <Grid.Item mb={12}>
          <Tips data={data} />
        </Grid.Item>
      </Grid>
    </>
  );
};

export default CustomerFacingDisplay;
