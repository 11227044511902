import { ILoadingModal } from '@westondev/tableturn-core';

export type TLoadingModalVariants = 'loader' | 'refresh-data' | 'refresh-error';

export enum ELoadingModalVariants {
  Loader = 'loader',
  RefreshData = 'refresh-data',
  RefreshError = 'refresh-error',
}
export interface ILoadingModalExtended extends ILoadingModal {
  variant?: TLoadingModalVariants;
}

export interface ILoadingModalProps {
  mode?: 'state' | 'local';
  isLoadingLocal?: ILoadingModalExtended;
  variant?: TLoadingModalVariants;
}
