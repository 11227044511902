import { scrollBarStyles } from '@app/theme/commonStyles';
import { Theme, css } from '@emotion/react';

export const tagsContainer = (theme: Theme) =>
  css(scrollBarStyles(theme), {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    gap: '5px',
    overflowX: 'auto',
    overflowY: 'hidden',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 5px',
    borderRadius: '5px',
  });
