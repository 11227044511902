import Accordion from '@app/components/common/Accordion';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  currentLicensesSelector,
  currentTabletsSelector,
  makeSelectSettingsData,
} from '@app/state/selectors/settingsSelectors';
import { RootState } from '@app/state/store';
import PrintingRules from '@app/views/LoggedIn/Settings/Edit/Printing/PrintingRules';
import {
  FormMode,
  ILicenseSettingsStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import React, { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TSelectedLicenseSettings } from '../types';
import DeviceDeactivationSettings from './DeviceDeactivationSettings';
import LicenseBasicSettings from './LicenseBasicSettings';
import PrinterSettings from './PrinterSettings';
import QuickServiceOrderSettings from './QuickServiceOrderSettings';
import TableServiceOrderSettings from './TableServiceOrderSettings';

const SECTION_ID = SettingsSectionId.SETTINGS;
const getMemoizedItemData =
  makeSelectSettingsData<ILicenseSettingsStore>(SECTION_ID);

interface ILicenseSettings extends WithTranslation {
  selectedLicense: TSelectedLicenseSettings;
  onBackClick: () => void;
}

const LicenseSettings = ({
  t,
  selectedLicense,
  onBackClick,
}: ILicenseSettings) => {
  // Redux
  const {
    loadSettingsBucketChangeData,
    checkForSettingsChangesAndNavigateWeb,
  } = bindActionCreators(actionCreatorsSettingsWeb, useDispatch());

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const isSet = useSelector(
    (state: RootState) =>
      state.settings.changeData.settingsSelected === 'licenseSettings',
  );
  const { licenseSettingsId, licenseId } = selectedLicense;

  const { data } = useSelector(getMemoizedItemData);

  const hasChanges = useSelector(
    (state: RootState) => state.settings.changeData.hasChanges,
  );

  const licenses = useSelector(currentLicensesSelector);
  const tablets = useSelector(currentTabletsSelector);

  // Local state
  const [showPrintingRules, setShowPrintingRules] = useState<{
    licenseId: string | number;
    data: ILicenseSettingsStore;
  } | null>(null);

  const [hasChanged, setHasChanged] = useState(false);
  const deviceHasLicense = useMemo(() => {
    if (licenseId) {
      const licenseDeviceId = licenses[licenseId].deviceId;

      return tablets[licenseDeviceId]?.license.id === licenseId;
    }
  }, [licenses, tablets, licenseId]);

  useEffect(() => {
    if (!isSet && !showPrintingRules) {
      handleLoadLicenseSettingsChangeData(licenseSettingsId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, showPrintingRules, licenseSettingsId]);

  useEffect(() => {
    if (hasChanges && !licenseSettingsId) {
      setHasChanged(true);
    }
  }, [hasChanges, licenseSettingsId]);

  const handleLoadLicenseSettingsChangeData = (
    currentLicenseId: string | number,
  ) => {
    if (hasChanged && !currentLicenseId) {
      onBackClick();
      setHasChanged(false);
      return;
    }
    loadSettingsBucketChangeData({
      setting: 'licenseSettings',
      id: currentLicenseId,
      mode: currentLicenseId === 0 ? FormMode.CREATE : FormMode.UPDATE,
    });
  };

  const updateInfo = (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => {
    setValue<ILicenseSettingsStore>(
      {
        [field]: newValue,
      },
      SECTION_ID,
    );
  };

  const handleOnBackClick = () => {
    checkForSettingsChangesAndNavigateWeb(
      () => {
        onBackClick();
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  const handleOnPrintingRulesGoBackClick = () => {
    if (!showPrintingRules) return;

    checkForSettingsChangesAndNavigateWeb(
      () => {
        setShowPrintingRules(null);
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  return (
    <Box>
      {selectedLicense &&
        data &&
        (!showPrintingRules ? (
          <>
            <Box
              csx={{
                marginBottom: '15px',
                position: 'sticky',
                top: '0px',
                zIndex: 5,
              }}>
              <BreadCrumbSettings
                onClick={handleOnBackClick}
                title="Licenses Settings"
                currentPage={
                  licenseSettingsId ? `${data.nickname}` : 'Adding new license'
                }
              />
            </Box>
            <HeaderTitle
              headerCsx={{
                padding: '15px',
              }}
              title={
                licenseSettingsId ? 'Edit License Settings' : 'Add New License'
              }
              description={
                licenseSettingsId
                  ? `${t('settingsModule.licenseSettings.overrides')}:${t(
                      'settingsModule.licenseSettings.description',
                    )}`
                  : undefined
              }
              options={
                licenseSettingsId ? (
                  <Typography
                    fontWeight="medium"
                    csx={{ minWidth: '40px' }}>{`ID: ${
                    isSet ? String(data.id) : ''
                  }`}</Typography>
                ) : undefined
              }
            />
          </>
        ) : (
          <>
            <Box csx={{ marginBottom: '15px' }}>
              <BreadCrumbSettings
                onClick={handleOnPrintingRulesGoBackClick}
                title={showPrintingRules ? showPrintingRules.data.nickname : ''}
                currentPage="Edit Printing Rules"
              />
            </Box>
            <PrintingRules selectedLicenseId={licenseId} />
          </>
        ))}
      {isSet && !showPrintingRules && (
        <Box
          csx={{
            padding: '15px',
          }}>
          <Accordion showExpandCollapseButtons={false}>
            <LicenseBasicSettings data={data} updateInfo={updateInfo} />
            <TableServiceOrderSettings data={data} updateInfo={updateInfo} />
            <QuickServiceOrderSettings data={data} updateInfo={updateInfo} />
            <PrinterSettings
              data={data}
              updateInfo={updateInfo}
              licenseSettingsId={licenseSettingsId}
              licenseId={licenseId}
              onShowPrintingRules={() =>
                setShowPrintingRules({
                  licenseId: licenseSettingsId,
                  data,
                })
              }
            />
            {licenseSettingsId !== 0 && deviceHasLicense && licenseId ? (
              <DeviceDeactivationSettings
                data={data}
                licenseId={licenseId}
                deviceId={licenses[licenseId].deviceId}
              />
            ) : (
              <React.Fragment />
            )}
          </Accordion>
        </Box>
      )}
    </Box>
  );
};

export default LicenseSettings;
