import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { FormMode } from '@westondev/tableturn-core';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import LinkedModItems from './LinkedModItems';

type TAssociationsSection = Exclude<IItemDetailsSection, 'isCombo'>;

const AssociationsSection = ({
  t,
  sectionId,
  mode,
  menuTypeVersionId,
  ...accordionSectionProps
}: TAssociationsSection) => {
  const isSectionDisabled =
    mode === FormMode.MASS_UPDATE ||
    (mode === FormMode.CREATE && menuTypeVersionId === 0);

  return (
    <AccordionSection
      id={sectionId}
      title={t('menuScreen.itemDetails.associations.title')}
      disabledLayer={isSectionDisabled ? <NoMenuTypeLayer /> : undefined}
      {...accordionSectionProps}>
      <LinkedModItems />
    </AccordionSection>
  );
};

export default AssociationsSection;
