import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import RightClickLink from '@app/components/common/RightClickLink';
import Typography from '@app/components/common/Typography';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { itemsTableNameStyles } from '../styles';

interface IItemName {
  text: string;
  depth: number;
  pathWithId: string;
  isHidden: boolean;
  status: boolean;
  statusPath: string;
  menuTypeId: number;
  breadcrumbTitle: string;
}

const ItemName = ({
  text,
  depth,
  pathWithId,
  isHidden,
  status,
  statusPath,
  menuTypeId,
  breadcrumbTitle,
}: IItemName) => {
  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const navigate = useNavigate();

  const isSubRow = depth !== 0;
  const pathUrl = getPathWithOrgData(pathWithId);
  return (
    <RightClickLink
      url={pathUrl}
      csx={{ overflow: 'hidden' }}
      onClick={() => {
        addBreadcrumbLocationWeb({
          action: BreadCrumbAction.NAV,
          text: breadcrumbTitle,
          onPress: () => {
            navigate(
              pathUrl,
              isSubRow
                ? {
                    state: {
                      menuTypeId,
                    },
                  }
                : undefined,
            );
          },
          statusPath,
          pathURL: pathUrl,
        });
      }}>
      <Box csx={itemsTableNameStyles(status)}>
        {isHidden ? (
          <Icon className="svgColor" name="MdVisibilityOff" size="20px" />
        ) : isSubRow ? (
          <Box className="dot color" />
        ) : null}
        <Typography
          csx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flex: 1,
          }}
          color={!isSubRow ? 'semanticBlue' : undefined}>
          {text}
        </Typography>
      </Box>
    </RightClickLink>
  );
};

export default ItemName;
