import { ReactNode, useMemo } from 'react';
import { IActionOption } from '../ActionButtons/types';
import Box from '../Box';
import Divider from '../Divider';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import ActionItem from './ActionItem';
import { actionDropdownStyles } from './styles';

interface IActionDropdown {
  trigger: ReactNode;
  children?: ReactNode;
  options?: IActionOption[];
  isDisabled?: boolean;
}
const ActionDropdown = ({
  trigger,
  options,
  children,
  isDisabled: isTooltipDisabled = false,
}: IActionDropdown) => {
  const optionsList = useMemo(
    () =>
      options?.map(
        ({ text, handler, icon, isDisabled, disabledHandler }, index) =>
          text === 'divider' ? (
            <Divider key={`divider_${index}`} />
          ) : (
            <ActionItem
              key={text}
              onClick={handler}
              icon={icon ? <Icon name="MdAdd" /> : undefined}
              iconPosition={icon ? 'right' : undefined}
              disabled={isDisabled}
              disabledHandler={disabledHandler}
              csx={{
                minWidth: '80px',
                justifyContent: 'space-between !important',
              }}>
              {text}
            </ActionItem>
          ),
      ) ?? null,
    [options],
  );

  return (
    <ToolTip
      mode="click"
      direction="bottom-left"
      triggerCsx={{ width: '100%' }}
      isDisabled={isTooltipDisabled}
      content={
        <Box csx={actionDropdownStyles}>{options ? optionsList : children}</Box>
      }>
      {trigger}
    </ToolTip>
  );
};

ActionDropdown.Item = ActionItem;

export default ActionDropdown;
