import { TCsx } from '@emotion/react';
import { m } from 'framer-motion';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../Box';
import Icon from '../../Icon';
import Typography from '../../Typography';
import { editIconStyles, itemCardStyles, removeIconStyles } from './styles';

export interface IItemCard {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subTitle?: string;
  price?: string;
  quantity?: string;
  tag?: React.ReactNode;
  isActive?: boolean;
  isStatusActive?: boolean;
  isHidden?: boolean;
  onClick?: (
    openInNewTab?: boolean,
  ) => MouseEventHandler<HTMLDivElement> | void | null;
  showStatus?: boolean;
  isSelected?: boolean;
  csx?: TCsx;
  showEditIcon?: boolean;
  showRemoveIcon?: boolean;
  footerIcon?: React.ReactNode;
  showTag?: boolean;
  onEditClick?: () => void;
  onRemoveClick?: (n: string | number) => void;
  id?: number | string;
  isAnimated?: boolean;
  count?: number;
  isDisabled?: boolean;
  isLink?: string;
  topTag?: string;
}

const ItemCard = ({
  id,
  csx,
  children,
  title,
  subTitle,
  tag,
  isActive,
  isHidden,
  onClick,
  showStatus,
  isSelected,
  showEditIcon,
  showRemoveIcon,
  footerIcon,
  showTag = true,
  onEditClick,
  onRemoveClick,
  isAnimated = true,
  count,
  isDisabled = false,
  isLink = '',
  topTag,
}: IItemCard) => {
  const listItemRef = useRef<HTMLAnchorElement>(null);
  const t = useTranslation().t;

  useEffect(() => {
    if (!isLink) return;

    const closestAnchor = listItemRef?.current;

    const handleLeft = (e: any) => {
      e.preventDefault();

      if (e.button === 0) {
        handleClick(false);
      } else if (e.button === 1) {
        handleClick(true);
      }
    };
    const handleMiddleClickOtherBrowsers = (e: MouseEvent | Event) => {
      e.preventDefault();
      handleClick(true);
    };

    const handleClick = (openInNewTab: boolean) => {
      onClick && onClick(openInNewTab);
    };

    closestAnchor?.addEventListener('click', handleLeft);
    closestAnchor?.addEventListener('auxclick', handleMiddleClickOtherBrowsers);

    return () => {
      closestAnchor?.removeEventListener('click', handleLeft);
      closestAnchor?.removeEventListener(
        'auxclick',
        handleMiddleClickOtherBrowsers,
      );
    };
  }, [isLink, onClick]);

  const content = (
    <>
      <Box className="itemCardContent">
        {title && <Typography className="title">{title}</Typography>}
        {subTitle && (
          <Typography
            className="subtitle"
            variant="caption"
            csx={{
              marginTop: title ? 3 : 0,
            }}>
            {subTitle}
          </Typography>
        )}
        {tag && showTag && (
          <Typography variant="caption2" className="title">
            (
            {count !== undefined && count === 0 && (
              <Icon
                csx={{ top: 2, position: 'relative' }}
                className="title"
                size="15px"
                name="MdWarning"
              />
            )}
            {tag})
          </Typography>
        )}
        {children}
      </Box>

      {showStatus && (
        <Box className={`itemCardStatus ${isActive ? 'active' : 'inactive'}`}>
          <Box className="dot" />
          <Typography variant="caption2">
            {t(`commonTexts.${isActive ? 'active' : 'inactive'}`)}
          </Typography>
        </Box>
      )}

      {topTag && (
        <Box className="itemCardTopTag">
          <Typography variant="caption2">{topTag}</Typography>
        </Box>
      )}

      {isHidden && (
        <Box className="hiddenLabel">
          <Icon name="MdVisibilityOff" size="18px" />
          <Typography
            variant="caption2"
            color={isDisabled ? 'lightGrey' : 'black'}>
            Hidden
          </Typography>
        </Box>
      )}

      {footerIcon && <Box className="footerIcon">{footerIcon}</Box>}
    </>
  );

  return (
    <Box csx={{ width: 'inherit', height: 'inherit', position: 'relative' }}>
      <m.div
        whileHover={isAnimated ? { scale: 1.01 } : {}}
        whileTap={isAnimated ? { scale: 1 } : {}}
        css={[
          itemCardStyles(isSelected || false, isDisabled, showStatus || false),
        ]}>
        {isLink ? (
          <a ref={listItemRef} href={isLink} css={{ textDecoration: 'none' }}>
            <Box
              csx={csx}
              data-active={isActive}
              className={`itemCard ${
                showStatus ? (isActive ? 'active' : 'inactive') : ''
              } ${isSelected ? 'isSelected' : ''}`}>
              {content}
            </Box>
          </a>
        ) : (
          <Box
            csx={csx}
            data-active={isActive}
            onClick={e => {
              if (onClick) {
                onClick(false)?.(e);
              }
            }}
            className={`itemCard ${
              showStatus ? (isActive ? 'active' : 'inactive') : ''
            } ${isSelected ? 'isSelected' : ''}`}>
            {content}
          </Box>
        )}
      </m.div>
      {showRemoveIcon && !isDisabled && (
        <Box
          csx={removeIconStyles}
          onClick={() => onRemoveClick && onRemoveClick(id as number)}>
          <Icon name="MdClear" color="textWhite" size="20px" />
        </Box>
      )}
      {!showRemoveIcon && showEditIcon && !isDisabled && (
        <Box csx={editIconStyles} onClick={onEditClick}>
          <Icon name="MdEdit" size="20px" />
        </Box>
      )}
    </Box>
  );
};

export default ItemCard;
