import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import {
  IGuestCheckSettings,
  IGuestReceiptSettings,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData = makeSelectSettingsData(SECTION_ID);

const gridSubNameSizing = {
  mb: 15,
  sm: 5,
  md: 5,
  lg: 3,
  xl: 3,
};

interface ICustomOrderTypeNames extends WithTranslation {
  bucket: 'guestCheck' | 'guestReceipt';
}

const CustomOrderTypeNames = ({ t, bucket }: ICustomOrderTypeNames) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(
    getMemoizedSettingsData,
  ) as typeof bucket extends 'guestCheck'
    ? { data: IGuestCheckSettings }
    : { data: IGuestReceiptSettings };

  const customTypeNameDineIn = data?.customTypeNameDineIn ?? '';
  const customTypeNameCurbside = data?.customTypeNameCurbside ?? '';
  const customTypeNameToGo = data?.customTypeNameToGo ?? '';
  const customTypeNameDelivery = data?.customTypeNameDelivery ?? '';
  const customTypeNamePickup = data?.customTypeNamePickup ?? '';

  const translationPreface =
    'app.modals.kitchenTicketDiffModal.ticketDisplay' as const;

  return (
    <Card.SubCard
      title={t('settingsModule.guestCheckSettings.customOrderTypeNames.title')}>
      <Grid
        rowGap={15}
        columnGap={15}
        columns={15}
        csx={{ alignItems: 'flex-start' }}>
        <Grid.Item
          {...gridSubNameSizing}
          csx={[displayFlexEndColumnStyles, { height: '100%' }]}>
          <Input
            label={t(`${translationPreface}.customTypeNameDineIn`)}
            placeholder={t(`${translationPreface}.customTypeNameDineIn`)}
            value={customTypeNameDineIn}
            onChange={e =>
              setValue(
                { customTypeNameDineIn: e.currentTarget.value },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item
          {...gridSubNameSizing}
          csx={[displayFlexEndColumnStyles, { height: '100%' }]}>
          <Input
            label={t(`${translationPreface}.customTypeNameCurbside`)}
            placeholder={t(`${translationPreface}.customTypeNameCurbside`)}
            value={customTypeNameCurbside}
            onChange={e =>
              setValue(
                { customTypeNameCurbside: e.currentTarget.value },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item
          {...gridSubNameSizing}
          csx={[displayFlexEndColumnStyles, { height: '100%' }]}>
          <Input
            label={t(`${translationPreface}.customTypeNameToGo`)}
            placeholder={t(`${translationPreface}.customTypeNameToGo`)}
            value={customTypeNameToGo}
            onChange={e =>
              setValue(
                { customTypeNameToGo: e.currentTarget.value },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item
          {...gridSubNameSizing}
          csx={[displayFlexEndColumnStyles, { height: '100%' }]}>
          <Input
            label={t(`${translationPreface}.customTypeNameDelivery`)}
            placeholder={t(`${translationPreface}.customTypeNameDelivery`)}
            value={customTypeNameDelivery}
            onChange={e =>
              setValue(
                { customTypeNameDelivery: e.currentTarget.value },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item
          {...gridSubNameSizing}
          csx={[displayFlexEndColumnStyles, { height: '100%' }]}>
          <Input
            label={t(`${translationPreface}.customTypeNamePickup`)}
            placeholder={t(`${translationPreface}.customTypeNamePickup`)}
            value={customTypeNamePickup}
            onChange={e =>
              setValue(
                { customTypeNamePickup: e.currentTarget.value },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default CustomOrderTypeNames;
