import Box from '@app/components/common/Box';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import Tabs from '@app/components/common/Tabs';
import Typography from '@app/components/common/Typography';
import { formatValue } from '@app/helpers/modals/diffModal';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import {
  selectCurrentSettingsData,
  selectCurrentSettingsOriginalData,
} from '@app/state/selectors/settingsSelectors';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DateTime,
  IGeneralSettingsDiffObject,
  IGeneralSettingsStore,
  ITimeBasedMenuType,
  ITimeBasedMenuTypeDiff,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderTable from '../../../common/HeaderTable';
import {
  ACTIVE_TAB_DAY_OF_WEEK,
  TABS_DATA,
} from '../../RegisterModeDiffModal/types';
import RenderChange from '../../common/RenderChange';

interface IHoursOfOperationTableDiff extends WithTranslation {
  menuTypeRules: ITimeBasedMenuType;
  formattedDifferences: IGeneralSettingsDiffObject | null;
}
const HoursOfOperationTableDiff = ({
  menuTypeRules,
  formattedDifferences,
  t,
}: IHoursOfOperationTableDiff) => {
  // Redux
  const changeData = useSelector(
    selectCurrentSettingsData,
  ) as IGeneralSettingsStore;

  const originalData = useSelector(
    selectCurrentSettingsOriginalData,
  ) as IGeneralSettingsStore;

  // Local state
  const theme = useTheme();

  const [activeTab, setSelectedTab] = useState<{
    tabId: number;
    subTabId: number | null;
  }>({ tabId: 1, subTabId: null });

  useEffect(() => {
    const defaultMenuTypeTimes = Object.entries(
      formattedDifferences?.hoursOfOperation?.hoursOfOperation || {},
    );
    let index = 0;
    for (const menuTypeTime of defaultMenuTypeTimes) {
      index++;
      if (menuTypeTime[1].length > 0) {
        setSelectedTab({ tabId: index, subTabId: null });
        break;
      }
    }
  }, [formattedDifferences]);

  const columnHelperCustom = createColumnHelper<ITimeBasedMenuTypeDiff>();

  const dayOfWeek = ACTIVE_TAB_DAY_OF_WEEK[
    activeTab.tabId
  ] as keyof typeof menuTypeRules;
  const tableDataOfDay = menuTypeRules[dayOfWeek];

  const COLUMNS = [
    columnHelperCustom.accessor('changes', {
      id: 'time',
      header: t(
        'app.modals.registerModeDiffModal.general.timeBasedMenuType.time',
      ),
      minSize: 670,
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = Boolean(rowData);

        const originalMenuTypeData =
          originalData.operationalDayHours?.[dayOfWeek][rowData.index];
        const changedMenuTypeData =
          changeData.operationalDayHours?.[dayOfWeek][rowData.index];

        return (
          <RenderChange
            height="85px"
            change={{
              field: 'time',
              label: '',
              value: (
                <CustomSlider
                  step={900000}
                  value={
                    !hasChanged
                      ? [
                          getHourMs(changedMenuTypeData.startTime),
                          getHourMs(changedMenuTypeData.endTime),
                        ]
                      : rowData.action === 'remove'
                      ? [
                          getHourMs(originalMenuTypeData.startTime),
                          getHourMs(originalMenuTypeData.endTime),
                        ]
                      : [
                          getHourMs(changedMenuTypeData.startTime),
                          getHourMs(changedMenuTypeData.endTime),
                        ]
                  }
                  min={0}
                  max={86399000}
                  showStepMarks={false}
                  valueLabelDisplay="always"
                  labelDirection="bottom"
                  customValueLabel={currentVale => {
                    return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                      .toUTC()
                      .toFormat('hh:mm a');
                  }}
                  isDisabled
                  debounceTimeout={200}
                  renderLimits={value => (
                    <Box csx={{ width: '70px' }}>
                      <Typography fontWeight="medium" variant="caption">
                        {DateTime.fromISO(dateTimeMsToTodayDate(value))
                          .toUTC()
                          .toFormat('hh:mm a')}
                      </Typography>
                    </Box>
                  )}
                />
              ),
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={
              hasChanged
                ? rowData.changes.some(change =>
                    ['startTime', 'endTime'].includes(change.field),
                  )
                : false
            }
          />
        );
      },
    }),
  ];

  const disabledTabs = Object.values(menuTypeRules).reduce(
    (acc: number[], menuTypeRule, index) => {
      if (menuTypeRule.length === 0) {
        return [...acc, index + 1];
      } else return acc;
    },
    [],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  return (
    <>
      <HeaderTable
        title={t(
          'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuTypeRules.title',
        )}
      />
      <Tabs
        data={TABS_DATA}
        activeTab={activeTab}
        onSelectedTabChange={handleOnSelectedTabChange}
        csx={{
          boxShadow: 'none',
          border: `1px solid ${theme.colors.lighterGrey}`,
        }}
        disabledTabs={disabledTabs}
      />
      <Table
        columns={COLUMNS}
        data={tableDataOfDay}
        cellCsx={{ height: '100px' }}
        showShadow={false}
      />
    </>
  );
};

export default HoursOfOperationTableDiff;
