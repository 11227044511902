import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import ColorPicker from '@app/components/common/ColorPicker';
import Icon from '@app/components/common/Icon';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import DropdownCell from '@app/components/common/Table/DropdownCell';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DEFAULT_ORDER_SEAT_TAG_COLOR,
  DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR,
  IOrderSeatTag,
  IRegisterSettings,
  OrderOrSeatTagEnum,
  OrderSeatTagType,
  REGISTER_ORDER_SEAT_TAG_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  generateTabletGeneratedId,
  getOrderOrSeatTagString,
  getOrderOrSeatTagValue,
  getOrderSeatTagId,
  propagateString,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const {
  orderSeatTagsSelector,
  areSettingsMasterModeSelector,
  makeSelectSettingsData,
} = settingsSelectors;

const ORDER_SEAT_TAG_TYPES = [
  OrderSeatTagType.ALLERGY,
  OrderSeatTagType.ATTRIBUTE,
  OrderSeatTagType.MILESTONE,
];
const findOrderSeatTagType = (id: OrderSeatTagType | null) => {
  const index = ORDER_SEAT_TAG_TYPES.findIndex(option => option === id);
  return index > -1 ? index : 0;
};

const ORDER_OR_SEAT_TAGS_OPTIONS = [
  OrderOrSeatTagEnum.SEAT_ONLY,
  OrderOrSeatTagEnum.ORDER_ONLY,
  OrderOrSeatTagEnum.BOTH,
];
const findOrderSeatTag = (id: OrderOrSeatTagEnum | null) => {
  const index = ORDER_OR_SEAT_TAGS_OPTIONS.findIndex(option => option === id);
  return index > -1 ? index : 0;
};

// TODO: Add translations
interface ISeatOrderTagsTable {
  id: number | string;
  status: boolean;
  orderOrSeatTag: OrderOrSeatTagEnum | null;
  type: OrderSeatTagType | null;
  name: string;
  posName: string;
  seatTagFavorite: boolean;
  orderTagFavorite: boolean;
  backgroundColor: string | null;
  textColor: string | null;
  canDelete: boolean;
  sortPriority: number;
}

const ROW_ID_PREFIX = 'seat-order-tag-row-';

const SECTION_ID = SettingsSectionId.TAGS;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const columnHelper = createColumnHelper<ISeatOrderTagsTable>();

const SeatOrderTags = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );

  const theme = useTheme();

  const y = useMotionValue(0);

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const actualSeatOrderTags = useSelector(orderSeatTagsSelector);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const { data: bigData } = useSelector(getMemoizedItemData);

  // Local state
  const data = bigData?.orderSeatTags;

  const updateOrderSeatTag = (
    newValue: any,
    id: number | string,
    field: keyof IOrderSeatTag,
  ) => {
    const orderSeatTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.orderSeatTags;
    setValue<IRegisterSettings>(
      {
        orderSeatTags: {
          ...orderSeatTags,
          [id]: {
            ...orderSeatTags[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateOrderSeatTagObject = (
    newValue: Partial<IOrderSeatTag>,
    id: number | string,
  ) => {
    const orderSeatTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.orderSeatTags;
    setValue<IRegisterSettings>(
      {
        orderSeatTags: {
          ...orderSeatTags,
          [id]: {
            ...orderSeatTags[id],
            ...newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateName = (newName: string, id: number | string) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentOrderSeatTag = registerData.orderSeatTags[id];
    const prevName = currentOrderSeatTag.name;
    const propagateObj = {
      name: prevName,
      posName: currentOrderSeatTag.posName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        orderSeatTags: {
          ...registerData.orderSeatTags,
          [id]: {
            ...registerData.orderSeatTags[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number | string) => {
    const orderSeatTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.orderSeatTags;

    const orderSeatTagsCloned = cloneDeep(orderSeatTags);
    delete orderSeatTagsCloned[id];

    setValue<IRegisterSettings>(
      {
        orderSeatTags: orderSeatTagsCloned,
      },
      SECTION_ID,
    );
  };

  const handleNewRowScroll = useScrollToNewRow();

  const onNewOrderSeatTag = () => {
    const nextId = generateTabletGeneratedId();

    const nextSortPriority =
      (max(
        Object.values(data || {}).map(
          orderSeatTag => orderSeatTag.sortPriority,
        ) || [],
      ) || 0) + 1;

    const nextIndex = Object.keys(data || {}).length;

    setValue<IRegisterSettings>(
      {
        orderSeatTags: {
          ...data,
          [nextId]: {
            ...REGISTER_ORDER_SEAT_TAG_INITIAL_STATE,
            id: nextId,
            orderSeatTagId: nextId,
            sortPriority: nextSortPriority,
            type: OrderSeatTagType.ALLERGY,
            isSeatTag: true,
            isOrderTag: true,
          },
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const orderOrSeatTagOptions = useMemo(
    () => [
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.orderOrSeatTag.options.seatOnly',
        ),
        value: ORDER_OR_SEAT_TAGS_OPTIONS.indexOf(OrderOrSeatTagEnum.SEAT_ONLY),
      },
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.orderOrSeatTag.options.orderOnly',
        ),
        value: ORDER_OR_SEAT_TAGS_OPTIONS.indexOf(
          OrderOrSeatTagEnum.ORDER_ONLY,
        ),
      },
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.orderOrSeatTag.options.both',
        ),
        value: ORDER_OR_SEAT_TAGS_OPTIONS.indexOf(OrderOrSeatTagEnum.BOTH),
      },
    ],
    [t],
  );

  const typeOptions = useMemo(
    () => [
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.type.options.allergy',
        ),
        value: ORDER_SEAT_TAG_TYPES.indexOf(OrderSeatTagType.ALLERGY),
      },
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.type.options.attribute',
        ),
        value: ORDER_SEAT_TAG_TYPES.indexOf(OrderSeatTagType.ATTRIBUTE),
      },
      {
        label: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.type.options.milestone',
        ),
        value: ORDER_SEAT_TAG_TYPES.indexOf(OrderSeatTagType.MILESTONE),
      },
    ],
    [t],
  );

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: 'Status',
        cell: info => {
          return (
            <Switch
              key={`switch-${info.row.original.id}`}
              checked={info.getValue()}
              onChange={active => {
                updateOrderSeatTag(active, info.row.original.id, 'active');
              }}
            />
          );
        },
        size: 70,
      }),
      columnHelper.accessor('orderOrSeatTag', {
        header: 'Order or Seat Tag',
        cell: info => (
          <DropdownCell
            key={`orderOrSeatTag-dropdown-${info.row.original.id}`}
            data={[orderOrSeatTagOptions]}
            value={findOrderSeatTag(info.getValue())}
            onChange={newIndex => {
              const newOrderOrSeatTag = ORDER_OR_SEAT_TAGS_OPTIONS[newIndex];
              updateOrderSeatTagObject(
                getOrderOrSeatTagValue(newOrderOrSeatTag),
                info.row.original.id,
              );
            }}
            errorsId={[
              `${getOrderSeatTagId(
                `${info.row.original.id}`,
                'orderOrSeatTag',
              )}`,
            ]}
            onPressError={errorId => clearSettingsError(errorId)}
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => (
          <DropdownCell
            data={[typeOptions]}
            value={findOrderSeatTagType(info.getValue())}
            onChange={newIndex =>
              updateOrderSeatTag(
                ORDER_SEAT_TAG_TYPES[newIndex],
                info.row.original.id,
                'type',
              )
            }
            errorsId={[
              `${getOrderSeatTagId(`${info.row.original.id}`, 'type')}`,
            ]}
            onPressError={errorId => clearSettingsError(errorId)}
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => (
          <InputCell
            disabled={!info.row.original.canDelete && !isMasterMode}
            required
            placeholder="Name"
            value={info.getValue()}
            onChange={e => {
              updateName(e.currentTarget.value, info.row.original.id);
            }}
            errorId={`${getOrderSeatTagId(`${info.row.original.id}`, 'Name')}`}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 140,
      }),
      columnHelper.accessor('posName', {
        header: 'POS Name',
        cell: info => (
          <InputCell
            required
            placeholder="POS Name"
            value={info.getValue()}
            onChange={e => {
              updateOrderSeatTag(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              );
            }}
            errorId={`${getOrderSeatTagId(
              `${info.row.original.id}`,
              'posName',
            )}`}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 140,
      }),
      columnHelper.accessor('seatTagFavorite', {
        header: 'Seat Tag Favorite',
        cell: info => {
          return (
            <Checkbox
              key={`seatTag-checkbox-${info.row.original.id}-${info.row.original.orderOrSeatTag}`}
              checked={info.getValue()}
              isDisabled={
                info.row.original.orderOrSeatTag ===
                OrderOrSeatTagEnum.ORDER_ONLY
              }
              onChange={checked =>
                updateOrderSeatTag(
                  checked,
                  info.row.original.id,
                  'seatTagFavorite',
                )
              }
            />
          );
        },
        size: 70,
      }),
      columnHelper.accessor('orderTagFavorite', {
        header: 'Order Favorite',
        cell: info => {
          return (
            <Checkbox
              key={`orderTag-checkbox-${info.row.original.id}-${info.row.original.orderOrSeatTag}`}
              checked={info.getValue()}
              isDisabled={
                info.row.original.orderOrSeatTag ===
                OrderOrSeatTagEnum.SEAT_ONLY
              }
              onChange={checked =>
                updateOrderSeatTag(
                  checked,
                  info.row.original.id,
                  'orderTagFavorite',
                )
              }
            />
          );
        },
        size: 70,
      }),
      columnHelper.accessor('backgroundColor', {
        header: 'Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || '#ffffff'}
            onChange={color =>
              updateOrderSeatTag(color, info.row.original.id, 'backgroundColor')
            }
            onReset={() =>
              updateOrderSeatTag(null, info.row.original.id, 'backgroundColor')
            }
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('textColor', {
        header: 'Text Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || '#000000'}
            onChange={color =>
              updateOrderSeatTag(color, info.row.original.id, 'textColor')
            }
            onReset={() =>
              updateOrderSeatTag(null, info.row.original.id, 'textColor')
            }
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('canDelete', {
        header: 'Delete',
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}
          />
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMasterMode],
  );

  const orderSeatTagsArray = useMemo<ISeatOrderTagsTable[]>(() => {
    return orderBy(
      Object.entries(data || {}).map(([id, orderSeatTag]) => {
        return {
          id: id,
          status: orderSeatTag?.active,
          orderOrSeatTag: getOrderOrSeatTagString({
            isOrderTag: orderSeatTag?.isOrderTag,
            isSeatTag: orderSeatTag?.isSeatTag,
          }),
          type: orderSeatTag?.type,
          name: orderSeatTag?.name,
          posName: orderSeatTag?.posName,
          seatTagFavorite: orderSeatTag?.seatTagFavorite,
          orderTagFavorite: orderSeatTag?.orderTagFavorite,
          backgroundColor:
            orderSeatTag?.backgroundColor ||
            theme.colors[DEFAULT_ORDER_SEAT_TAG_COLOR],
          textColor:
            orderSeatTag?.textColor ||
            theme.colors[DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR],
          canDelete: actualSeatOrderTags[id] ? false : true,
          sortPriority: orderSeatTag?.sortPriority,
        };
      }),
      'sortPriority',
    );
  }, [data, theme.colors, actualSeatOrderTags]);

  const handleSort = (newData: ISeatOrderTagsTable[]) => {
    const clonedOrderSeatTags = cloneDeep(data);
    newData.forEach((item, index) => {
      clonedOrderSeatTags[item.id].sortPriority = index + 1;
    });
    setValue<IRegisterSettings>(
      {
        orderSeatTags: clonedOrderSeatTags,
      },
      SECTION_ID,
    );
  };

  return (
    <div>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title="Seat/Order Tags"
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewOrderSeatTag}>
              Add Tag
            </Button>
          }
        />
      </m.div>
      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          mode={ETableModes.SORT_VERTICALLY}
          data={orderSeatTagsArray}
          columns={COLUMN_VALUES}
          onSortEnd={handleSort}
          cellCsx={{ height: '70px' }}
          alignHeaders={{
            seatTagFavorite: 'center',
            orderTagFavorite: 'center',
            delete: 'center',
            backgroundColor: 'center',
            textColor: 'center',
          }}
          align={{
            seatTagFavorite: 'center',
            orderTagFavorite: 'center',
            delete: 'center',
            backgroundColor: 'center',
            textColor: 'center',
          }}
          requiredColumns={['orderOrSeatTag', 'type', 'name', 'posName']}
          rowIdPrefix={ROW_ID_PREFIX}
          renderEmptyValues
        />
      </Box>
    </div>
  );
};

export default SeatOrderTags;
