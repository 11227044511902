import OptionsModal from '@app/components/common/OptionsModal';
import { ILicenseSettingsStore } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

interface IDefaultPrinterModal extends WithTranslation {
  active?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
  availablePrinters: {
    label: string;
    value: number;
    id: number;
  }[];
}

const DefaultPrinterModal = ({
  t,
  active = false,
  onCancel = () => null,
  updateInfo,
  availablePrinters,
}: IDefaultPrinterModal) => {
  return (
    <OptionsModal
      title={t(
        'settingsModule.licenseSettings.printerSettings.defaultCheckPrinter.modalTitle',
      )}
      options={availablePrinters}
      onChange={(printerId: number | null) => {
        updateInfo('defaultCheckPrinterId', printerId === 0 ? null : printerId);
        onCancel();
      }}
      isActiveByDefault={active}
      hideTriggerButton
      onCancel={onCancel}
      onOutsidePress={onCancel}
    />
  );
};

export default DefaultPrinterModal;
