import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ModifierTagPositions,
  SettingsSectionId,
  IRegisterSettings,
} from '@westondev/tableturn-core';

export interface IModTagsTable {
  id: number;
  status: boolean;
  name: string;
  posName: string;
  checkName: string;
  canDelete: boolean;
  tagPosition: ModifierTagPositions;
  backgroundColor: string | null;
  textColor: string | null;
  sortPriority: number;
  isSpecial: boolean;
  menuTypeIds: number[] | null;
}

export const columnHelper = createColumnHelper<IModTagsTable>();

export const TAG_POSITION_OPTIONS = [
  ModifierTagPositions.BEFORE,
  ModifierTagPositions.AFTER,
];

export const SECTION_ID = SettingsSectionId.TAGS;
export const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

export const ROW_ID_PREFIX = 'mod-tag-row-';
export const MOD_TAG_TABLE_ID = 'mod-tag-table';
