import Checkbox from '@app/components/common/Checkbox';
import { ICheckbox } from '@app/components/common/Checkbox/types';
import { MassUpdateValues } from '@westondev/tableturn-core';

const CheckboxMassUpdate = (
  props: Omit<ICheckbox, 'checked' | 'showNeutral' | 'showNoChanges'> & {
    checked: boolean | MassUpdateValues;
    isMassUpdate?: boolean;
    onMassUpdateChange?: (checked: boolean | MassUpdateValues) => void;
  },
) => {
  const isMassUpdateNeutral = props.checked === MassUpdateValues.NO_CHANGES;

  const { isMassUpdate, onMassUpdateChange } = props;

  const getISChecked = () => {
    if (isMassUpdateNeutral) {
      return false;
    } else return props.checked as boolean;
  };

  const onChange = (checkboxChecked: boolean) => {
    if (isMassUpdate) {
      switch (props.checked) {
        case MassUpdateValues.NO_CHANGES:
          return onMassUpdateChange?.(true);
        case true:
          return onMassUpdateChange?.(false);
        case false:
          return onMassUpdateChange?.(MassUpdateValues.NO_CHANGES);
      }
    }

    props.onChange?.(checkboxChecked);
  };
  return (
    <Checkbox
      {...props}
      onChange={onChange}
      checked={getISChecked()}
      showNoChanges={isMassUpdate && isMassUpdateNeutral}
      showNeutral={isMassUpdate}
    />
  );
};

export default CheckboxMassUpdate;
