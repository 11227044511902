import { getPrinterJobsWeb } from '@app/state/register/registerWebActions';
import { useAppDispatch } from '@app/state/store';
import { TPrinterJobRow } from '@westondev/tableturn-core';
import { useCallback, useEffect, useState } from 'react';

const useGetPrinterJobs = (printerId: number) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [printerJobs, setPrinterJobs] = useState<TPrinterJobRow[]>([]);
  const [_refresh, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(_refresh + 1);
  }, [_refresh]);

  useEffect(() => {
    const handleGetUserOrganizations = async () => {
      setIsLoading(true);
      const result = await dispatch(getPrinterJobsWeb(printerId));

      setIsLoading(false);
      setPrinterJobs(result);
    };
    handleGetUserOrganizations();
  }, [dispatch, printerId, _refresh]);

  return { printerJobs, isLoading, refresh };
};

export default useGetPrinterJobs;
