import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';

export const tableCardStyles = () =>
  css({
    paddingTop: '15px',
    '.cardCellContent': {
      marginBlock: '20px',
      width: '100%',
    },
    [MQ_MIN_MEDIUM]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: '0px',
      '.cardCellHeader': {
        display: 'none',
      },
      '.cardCellContent': {
        marginBlock: '0px',
      },
    },
  });
