import { IBreadCrumbWeb, PullingDataTypes } from '@app/types';
import { TCsx } from '@emotion/react';
import {
  IConfirmationModal as IConfirmationModalCore,
  IAppStore as ICoreAppStore,
  ILoadingModal,
  IRefreshToast,
  SET_HIDE_REFRESH_TOAST,
  SET_IS_LOADING_MODAL,
  SET_SHOW_REFRESH_TOAST,
  SET_SHOW_SETTINGS_REFRESH_TOAST,
} from '@westondev/tableturn-core';

//Action types
export const SET_SHOW_TOAST = 'SET_SHOW_TOAST';
export const SET_HIDE_TOAST = 'SET_HIDE_TOAST';
export const SET_PULLING_DATA = 'SET_PULLING_DATA';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PORTALS = 'SET_PORTALS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const RESET_GLOBAL_STATE = 'RESET_GLOBAL_STATE';
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID';
export const SET_LOCATION_ID = 'SET_LOCATION_ID';
export const SET_SHOW_CONFORMATION_MODAL = 'SET_SHOW_CONFORMATION_MODAL';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_VIRTUALIZED_NAVIGATION = 'SET_VIRTUALIZED_NAVIGATION';

// Data Types and interfaces
type toastCodes = 'success' | 'error' | 'info' | 'warning';

export enum EToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IToastData {
  title: string;
  description: string | React.ReactNode;
  type: toastCodes;
  isActive?: boolean;
  showCloseIcon?: boolean;
  timeout?: number;
}

//TODO: MERGE TYPES WITH CORE
export type IAppStore = ICoreAppStore & {
  userDetails: IUserDetails;
  portals: string[];
  organizationId: number;
  locationId: number;
  organizations: IUserOrganization[];
  virtualizedNavigation: TVirtualizedNavigation;
};

export type TVirtualizedNavigation = {
  breadcrumb: IBreadCrumbWeb[];
  index: number;
  initialIndex: number;
};

export interface ISignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  organizationId: null;
}

export interface IUserLocations {
  id: number;
  name: string;
  streetLine1: string;
  roles: {
    id: number;
    name: string;
  }[];
}

export interface IUserOrganization {
  id: number;
  name: string;
  digitalLogoUrl: string;
  locations: IUserLocations[];
}

export interface IUserDetails {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

export enum ConfirmationTypes {
  info = 'info',
  delete = 'delete',
  create = 'create',
  diff = 'diff',
  settingsDiff = 'settingsDiff',
  reportsDiff = 'reportsDiff',
  warning = 'warning',
  error = 'error',
  orange = 'orange',
  validation = 'validation',
  validationDiscard = 'validationDiscard',
  refresh = 'refresh',
  locationSelection = 'locationSelection',
  locationSettingsSelection = 'locationSettingsSelection',
  locationReportsSelection = 'locationReportsSelection',
  unassociate = 'unassociate',
  permissionDenied = 'permissionDenied',
  settingsValidation = 'settingsValidation',
  settingsValidationDiscard = 'settingsValidationDiscard',
  prepStations = 'prepStations',
  reprintTicketElement = 'reprintTicketElement',
  prepStationsReprint = 'prepStationsReprint',
  tableOptions = 'tableOptions',
  transferOrderOwner = 'transferOrderOwner',
  eightySixed = 'eightySixed',
  drawerActions = 'drawerActions',
  drawerSelection = 'drawerSelection',
}

export type TConfirmationTypes = keyof typeof ConfirmationTypes;

export type IConfirmationModal = IConfirmationModalCore & {
  btnCancelCsx?: TCsx;
  btnSuccessCsx?: TCsx;
  btnTerciaryCsx?: TCsx;
  btnCommonCsx?: TCsx;
};

export type IConfirmationModalExtended = IConfirmationModal;

//Action creator types
export type ActionTypes =
  | {
      type: typeof SET_SHOW_TOAST;
      payload: IToastData;
    }
  | {
      type: typeof SET_HIDE_TOAST;
    }
  | {
      type: typeof SET_PULLING_DATA;
      payload: PullingDataTypes;
    }
  | {
      type: typeof SET_IS_LOADING;
      payload: boolean;
    }
  | {
      type: typeof SET_IS_LOADING_MODAL;
      payload: ILoadingModal;
    }
  | {
      type: typeof SET_TOKEN;
      payload: string;
    }
  | {
      type: typeof SET_USER_DETAILS;
      payload: IUserDetails;
    }
  | {
      type: typeof SET_PORTALS;
      payload: string[];
    }
  | {
      type: typeof RESET_GLOBAL_STATE;
    }
  | {
      type: typeof SET_ORGANIZATION_ID | typeof SET_LOCATION_ID;

      payload: number;
    }
  | {
      type: typeof SET_SHOW_CONFORMATION_MODAL;
      payload: IConfirmationModalExtended;
    }
  | {
      type: typeof SET_SHOW_REFRESH_TOAST;
      payload: IRefreshToast;
    }
  | {
      type: typeof SET_SHOW_SETTINGS_REFRESH_TOAST;
      payload: IRefreshToast;
    }
  | {
      type: typeof SET_HIDE_REFRESH_TOAST;
    }
  | {
      type: typeof SET_ORGANIZATIONS;
      payload: IUserOrganization[];
    }
  | {
      type: typeof SET_VIRTUALIZED_NAVIGATION;
      payload: Partial<TVirtualizedNavigation>;
    };
