import Box from '@app/components/common/Box';
import { resetPasswordCardStyles } from './styles';
import Typography from '@app/components/common/Typography';
import Button from '@app/components/common/Button';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import PasswordChangedMessage from './PasswordChangedMessage';
import Input from '@app/components/common/Input';
import AuthCard from '@app/components/Authentication/AuthCard';
import { WithTranslation } from 'react-i18next';
import {
  linkWithText,
  loginAuthBodySeparation,
} from '@app/components/Authentication/styles';
import Link from '@app/components/common/Link';
import PasswordMustBe from '@app/components/Authentication/PasswordMustBe';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/state/store';
import { resetPassword } from '@app/state/app/actions';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '@app/state/selectors/appSelectors';

const ResetPassword = ({ t }: WithTranslation) => {
  //Redux
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);

  // Local state
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [passwordErrors, setPasswordErrors] = useState({
    hasError: true,
    atLeast8Chars: false,
    atLeastOneUpperCase: false,
    atLeastOneLoweCase: false,
    atLeastOneNumberOrSymbol: false,
  });

  const [showPasswordChangedMessage, setShowPasswordChangedMessage] =
    useState(false);

  const currentEmail = location.state?.email;
  const currentCode = location.state?.code;

  useEffect(() => {
    if (!currentEmail) navigate('/auth/forgot-password');
  }, [currentEmail, navigate]);

  const passwordConfirmationHasError =
    (formData.confirmPassword.length > 0 || !passwordErrors.hasError) &&
    formData.password !== formData.confirmPassword;

  const hasUnsavedChanges = useMemo(() => {
    return Object.values(formData).some(value => value.length > 0);
  }, [formData]);

  const handleSubmitForm = async () => {
    if (formData.password === formData.confirmPassword) {
      const isValid = await dispatch(
        resetPassword(formData.password, currentEmail, currentCode),
      );

      if (isValid) {
        setShowPasswordChangedMessage(true);
      }
    }
  };

  const handleSetFormData = (e: FormEvent<HTMLInputElement>, field: string) => {
    const value = e.currentTarget.value;
    const atLeast8Chars = /.{8,}/;
    const atLeastOneUpperCase = /(?=.*[A-Z])/;
    const atLeastOneLoweCase = /(?=.*[a-z])/;
    const atLeastOneNumberOrSymbol = /(?=.*\d|[^A-Za-z])/;
    const validationRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d|[^A-Za-z])[\w\d\S]{8,}$/;

    if (field === 'password') {
      setPasswordErrors(prev => ({
        ...prev,
        atLeast8Chars: atLeast8Chars.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneUpperCase: atLeastOneUpperCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneLoweCase: atLeastOneLoweCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneNumberOrSymbol: atLeastOneNumberOrSymbol.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        hasError: !validationRegex.test(value),
      }));
    }

    setFormData(prev => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <AuthCard csx={resetPasswordCardStyles} maxHeight="670px">
      {!showPasswordChangedMessage ? (
        <>
          <Box className="form" csx={loginAuthBodySeparation}>
            <Box csx={{ position: 'relative' }}>
              <Typography
                variant="heading"
                fontWeight="medium"
                className="title">
                {t('authentication.resetPassword.resetPassword')}
              </Typography>
              <Typography
                variant="caption"
                className="title"
                csx={{ position: 'absolute' }}>
                {t('authentication.resetPassword.pleaseCreateNewPassword')}
              </Typography>
            </Box>
            <form className="formContent">
              <Input
                name="password"
                label={t('authentication.resetPassword.newPassword')}
                placeholder={t('authentication.resetPassword.newPassword')}
                type="password"
                value={formData.password}
                required
                hideRequiredSymbol
                error={passwordErrors.hasError && formData.password.length > 0}
                onChange={e => handleSetFormData(e, 'password')}
              />
              <Input
                name="passwordConfirmation"
                label={t('authentication.resetPassword.confirmPassword')}
                placeholder={t('authentication.resetPassword.confirmPassword')}
                type="password"
                value={formData.confirmPassword}
                required
                hideRequiredSymbol
                error={passwordConfirmationHasError}
                caption={
                  passwordConfirmationHasError
                    ? t(
                        'authentication.resetPassword.thePasswordConfirmationDoesntMatch',
                      )
                    : undefined
                }
                showCaptionSpace
                onChange={e => handleSetFormData(e, 'confirmPassword')}
              />

              <PasswordMustBe
                passwordErrors={{
                  atLeast8Chars: passwordErrors.atLeast8Chars,
                  atLeastOneUpperCase: passwordErrors.atLeastOneUpperCase,
                  atLeastOneLoweCase: passwordErrors.atLeastOneLoweCase,
                  atLeastOneNumberOrSymbol:
                    passwordErrors.atLeastOneNumberOrSymbol,
                }}
              />
            </form>
          </Box>
          <Box className="formButtons">
            <Button
              type="submit"
              variant="active"
              isLoading={isLoading}
              loadingText={t('authentication.resetPassword.resettingPassword')}
              disabled={passwordErrors.hasError || passwordConfirmationHasError}
              onClick={handleSubmitForm}>
              {t('authentication.resetPassword.resetPassword')}
            </Button>
            <Box csx={linkWithText}>
              <Typography variant="body" fontWeight="medium" color="darkGrey">
                {t('authentication.forgotPassword.rememberPassword')}
              </Typography>
              <Link to="/auth/sign-in">{t('commonButtons.signIn')}</Link>
            </Box>
          </Box>
        </>
      ) : (
        <PasswordChangedMessage />
      )}
    </AuthCard>
  );
};

export default ResetPassword;
