import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  GUEST_CHECK_SECOND_SORT_PRIORITY_OPTIONS,
  GUEST_CHECK_SORT_ITEMS_BY_OPTIONS,
  IGuestCheckSettings,
  SettingsSectionId,
  actionCreatorsSettings,
  guestCheckSecondSortPriorityFactory,
  guestCheckSortItemsByFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CourseSortPriorityTable from '../CourseSortPriorityTable';

const SECTION_ID = SettingsSectionId.ITEM_SORT_PRIORITY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestCheckSettings>(SECTION_ID);

const ItemSortPriority = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  const sortItemsByOptions = useMemo(
    () => guestCheckSortItemsByFactory(t),
    [t],
  );
  const secondSortItemsByOptions = useMemo(
    () => guestCheckSecondSortPriorityFactory(t),
    [t],
  );
  return (
    <Card.SubCard
      title={t(
        'loggedIn.settingsModule.guestCheckSettings.itemSortPriority.title',
      )}>
      <Grid rowGap={15} columnGap={15}>
        <Grid.Item mb={12}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item mb={12} md={6} lg={3}>
              <Dropdown
                label={t(
                  'loggedIn.settingsModule.guestCheckSettings.itemSortPriority.sortItemsBy.label',
                )}
                placeholder={t(
                  'loggedIn.settingsModule.guestCheckSettings.itemSortPriority.sortItemsBy.label',
                )}
                data={[sortItemsByOptions]}
                value={
                  GUEST_CHECK_SORT_ITEMS_BY_OPTIONS.indexOf(data?.sortItemsBy) +
                  1
                }
                onChange={newSortItemsBy =>
                  setValue<IGuestCheckSettings>(
                    {
                      sortItemsBy:
                        GUEST_CHECK_SORT_ITEMS_BY_OPTIONS[newSortItemsBy - 1],
                    },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item mb={12} md={6} lg={3}>
              <Dropdown
                label={t(
                  'loggedIn.settingsModule.guestCheckSettings.itemSortPriority.secondSortItemsBy.label',
                )}
                placeholder={t(
                  'loggedIn.settingsModule.guestCheckSettings.itemSortPriority.secondSortItemsBy.label',
                )}
                data={[secondSortItemsByOptions]}
                value={
                  GUEST_CHECK_SECOND_SORT_PRIORITY_OPTIONS.indexOf(
                    data?.secondSortItemsBy,
                  ) + 1
                }
                onChange={newSecondSortItemsBy =>
                  setValue<IGuestCheckSettings>(
                    {
                      secondSortItemsBy:
                        GUEST_CHECK_SECOND_SORT_PRIORITY_OPTIONS[
                          newSecondSortItemsBy - 1
                        ],
                    },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item mb={12}>
          <CourseSortPriorityTable />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default ItemSortPriority;
