import { scrollBarStyles } from '@app/theme/commonStyles';
import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const topBarStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    gap: '10px',
    paddingInline: '15px',
    paddingBlock: '4px',
  });

export const servicesAreasContainerStyles = (theme: Theme) =>
  css(scrollBarStyles(theme), {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    gap: '7px',
    width: '100%',
    minHeight: '70px',
    paddingRight: '8px',
    [MQ_MIN_MEDIUM]: {
      gap: '10px',
      minHeight: '70px',
    },
  });

export const actionsContainerStyles = () =>
  css({
    display: 'flex',
    gap: '7px',
    '.reorderButton': {
      '> span.icon': {
        paddingRight: '0px !important',
      },
      [MQ_MIN_MEDIUM]: {
        '> span.icon': {
          paddingRight: '7px !important',
        },
      },
    },
    '> button': {
      minWidth: '30px',
      height: '30px',
      '> .buttonText': {
        display: 'none',
      },
      [MQ_MIN_MEDIUM]: {
        minWidth: '80px',
        height: '50px',
        '> .buttonText': {
          display: 'flex',
        },
      },
    },
  });
