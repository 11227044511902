import {
  CORE_APP_INITIAL_STATE,
  SET_HIDE_REFRESH_TOAST,
  SET_IS_LOADING_MODAL,
  SET_SHOW_REFRESH_TOAST,
  SET_SHOW_SETTINGS_REFRESH_TOAST,
} from '@westondev/tableturn-core';
import { Reducer } from 'redux';
import {
  ActionTypes,
  IAppStore,
  SET_HIDE_TOAST,
  SET_IS_LOADING,
  SET_LOCATION_ID,
  SET_ORGANIZATION_ID,
  SET_PORTALS,
  SET_PULLING_DATA,
  SET_SHOW_CONFORMATION_MODAL,
  SET_SHOW_TOAST,
  SET_TOKEN,
  SET_USER_DETAILS,
  SET_ORGANIZATIONS,
  SET_VIRTUALIZED_NAVIGATION,
} from './types';

export { CONFIRMATION_MODAL_INITIAL_STATE } from '@westondev/tableturn-core';

const APP_INITIAL_STATE: IAppStore = {
  ...CORE_APP_INITIAL_STATE,
  userDetails: {
    userId: 0,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailVerified: false,
    phoneVerified: false,
  },
  organizations: [],
  portals: [],
  organizationId: 0,
  locationId: 0,
  virtualizedNavigation: {
    breadcrumb: [],
    index: 0,
    initialIndex: 0,
  },
};

const appReducer: Reducer<IAppStore, ActionTypes> = (
  state = APP_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SET_SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } };
    case SET_HIDE_TOAST:
      return { ...state, toast: { ...state.toast, isActive: false } };
    case SET_PULLING_DATA:
      return { ...state, isPullingData: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_IS_LOADING_MODAL:
      return { ...state, isLoadingModal: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case SET_PORTALS:
      return { ...state, portals: action.payload };
    case SET_LOCATION_ID:
      return { ...state, locationId: action.payload };
    case SET_ORGANIZATION_ID:
      return { ...state, organizationId: action.payload };
    case SET_ORGANIZATIONS:
      return { ...state, organizations: action.payload };
    case SET_SHOW_CONFORMATION_MODAL:
      return { ...state, confirmationModal: action.payload };
    case SET_SHOW_REFRESH_TOAST:
    case SET_SHOW_SETTINGS_REFRESH_TOAST:
      return { ...state, refreshToast: { ...action.payload, isActive: true } };
    case SET_HIDE_REFRESH_TOAST:
      return {
        ...state,
        refreshToast: { ...state.refreshToast, isActive: false },
      };
    case SET_VIRTUALIZED_NAVIGATION:
      return {
        ...state,
        virtualizedNavigation: {
          ...state.virtualizedNavigation,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default appReducer;
