import { css, Theme } from '@emotion/react';

export const selectedOptionsStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    gap: '5px',
    backgroundColor: theme.colors.lightestExtraGrey,
    '.selected': {
      border: `1px solid ${theme.colors.semanticBlue}`,
      backgroundColor: theme.colors.lighterBlue,
      flexShrink: 0,
    },
  });
