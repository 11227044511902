import Box, { IBox } from '@app/components/common/Box/Box';
import Typography, {
  ITypography,
} from '@app/components/common/Typography/Typography';
import { useTheme } from '@emotion/react';
import { formatMoney } from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import TopRightLabel from '../TopRightLabel';

interface IItemButtonHeader {
  inventoryLabel?: string;
  hidePrice?: boolean;
  totalPrice?: number;
  nextPrice?: number;
  hasNextPrice?: boolean;
  includedPrice?: number;
  additionalPrice?: number;
  priceDifference?: number;
  priceOverride?: string;
  isMultipleQuantity?: boolean;
  isSelected: boolean;
  is86ed?: boolean;
  isSpecial?: boolean;
  isTaxInclusive?: boolean;
  isSizeItem?: boolean;
  quantity?: number;
  textProps?: {
    prefix?: string;
    includedPrice?: Partial<ITypography>;
    additionalPrice?: Partial<ITypography>;
    inventoryLabel?: Partial<ITypography>;
  };
  boxProps?: IBox;
  quantityOverride?: boolean;
}

const ItemButtonHeader = ({
  hidePrice = false,
  totalPrice,
  priceDifference = 0,
  nextPrice,
  priceOverride,
  isMultipleQuantity,
  isSelected,
  is86ed,
  isSpecial,
  isTaxInclusive,
  quantityOverride,
  quantity = 0,
  inventoryLabel,
  isSizeItem,
  textProps,
  hasNextPrice = true,
  boxProps,
}: IItemButtonHeader) => {
  const theme = useTheme();
  const inactiveTextColor = theme.colors.semanticBlue;
  const { t } = useTranslation();

  return (
    <Box
      {...boxProps}
      csx={[
        {
          display: 'flex',
          width: '100%',
          paddingLeft: 6,
          paddingRight: 5,
          flexShrink: 1,
          justifyContent:
            nextPrice !== undefined ? 'space-between' : 'flex-end',
        },
        boxProps?.csx,
      ]}>
      {/* Price */}
      {!hidePrice && (
        <Box csx={{ paddingTop: isSelected && priceDifference !== 0 ? 5 : 5 }}>
          <Typography
            color={isSelected ? 'textWhite' : inactiveTextColor}
            fontWeight="medium"
            {...textProps?.includedPrice}>
            {!!textProps?.prefix && textProps.prefix}
            {priceOverride
              ? priceOverride
              : !isSelected
              ? totalPrice === 0
                ? t('commonTexts.free')
                : formatMoney('', totalPrice)
              : nextPrice === 0
              ? t('commonTexts.free')
              : formatMoney('', nextPrice)}
          </Typography>
          {isSelected && hasNextPrice && (
            <Typography
              color="textWhite"
              variant="caption"
              {...textProps?.additionalPrice}>
              +
              {nextPrice === 0
                ? t('commonTexts.free')
                : formatMoney('', nextPrice)}
            </Typography>
          )}
          {isTaxInclusive && !isSelected && !isSpecial && (
            <Typography
              color={inactiveTextColor}
              variant="caption"
              fontWeight="medium"
              {...textProps?.inventoryLabel}>
              {t('commonTexts.taxInc')}.
            </Typography>
          )}
          {isSpecial && !isSelected && (
            <Box
              csx={{
                height: '25px',
                paddingInline: '8px',
                borderRadius: '100px',
                border: `1px solid ${theme.colors.darkYellow}`,
                backgroundColor: theme.colors.semanticYellow,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography color="textBlack" variant="caption">
                Special
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {/* Top Right Label */}
      <TopRightLabel
        is86ed={is86ed}
        quantity={quantity}
        quantityOverride={quantityOverride}
        isActive={isSelected}
        isMultipleQuantity={isMultipleQuantity}
        isSizeItem={isSizeItem}
        inventoryLabel={inventoryLabel}
        textProps={textProps?.inventoryLabel}
      />
    </Box>
  );
};

export default ItemButtonHeader;
