import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import SortButton from '@app/components/common/SortButton';
import Typography from '@app/components/common/Typography';
import Card from '@app/components/common/Card';

const ButtonExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Buttons
      </Typography>
      <Grid columnGap={{ mb: 10 }} rowGap={{ mb: 10, sm: 15 }}>
        <Grid.Item sm={3} md={2}>
          <Button variant="primary">Primary</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="active">Active</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="secondary">Secondary</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="disabled" disabled>
            Disabled
          </Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="success">Success</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="warning">Warning</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button variant="danger">Danger</Button>
        </Grid.Item>
      </Grid>

      <Typography variant="body" csx={{ marginBlock: '10px' }}>
        With Icon
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={3} md={2}>
          <Button variant="primary" icon={<Icon name="MdInsertEmoticon" />}>
            Left
          </Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button
            variant="primary"
            icon={<Icon name="MdInsertEmoticon" />}
            iconPosition="right">
            Right
          </Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button
            variant="primary"
            icon={<Icon name="MdInsertEmoticon" />}
            iconPosition="top">
            Top
          </Button>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <Button
            variant="primary"
            icon={<Icon name="MdInsertEmoticon" />}
            iconPosition="bottom">
            Bottom
          </Button>
        </Grid.Item>
      </Grid>
      <Typography variant="body" csx={{ marginBlock: '10px' }}>
        Loading
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={3} md={2}>
          <Button isLoading>Button</Button>
        </Grid.Item>
        <Grid.Item sm={3} md={4}>
          <Button variant="active" isLoading loadingText="Sending code...">
            Button
          </Button>
        </Grid.Item>
      </Grid>
      <Typography variant="body" csx={{ marginBlock: '10px' }}>
        Sort buttons
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={3} md={2}>
          <SortButton>With Text</SortButton>
        </Grid.Item>
        <Grid.Item sm={3} md={2}>
          <SortButton />
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default ButtonExample;
