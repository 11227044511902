import { useSelector } from 'react-redux';
import ElementPanHandlerMemo, {
  TElement,
} from '../ElementPanHandler/ElementPanHandler';
import { DEFAULT_ELEMENT_SIZES } from '../types';
import { ILayoutProps, Shapes } from '@westondev/tableturn-core';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { elementStyles } from '../styles';
import SeatIcon from '@app/components/common/Icon/custom/SeatIcon';
import { selectSelectedElement } from '@app/state/selectors/tableLayoutSelectors';

const TableElement = ({ element, layoutProps }: TElement) => {
  // Redux
  const selectedElement = useSelector(selectSelectedElement);

  // Local State
  const showControls =
    selectedElement?.id === element.id && selectedElement?.showControls;

  const width =
    element.shape === Shapes.RECTANGLE
      ? DEFAULT_ELEMENT_SIZES['table-rectangle'].width
      : DEFAULT_ELEMENT_SIZES.table.width;
  const height =
    element.shape === Shapes.RECTANGLE
      ? DEFAULT_ELEMENT_SIZES['table-rectangle'].height
      : DEFAULT_ELEMENT_SIZES.table.height;

  return (
    <ElementPanHandlerMemo
      element={element}
      isSelected={selectedElement?.id === element.id}
      showControls={showControls}
      defaultElementProps={{ width, height }}
      layoutProps={layoutProps as ILayoutProps}
      elementStyles={[
        elementStyles,
        {
          width: element.width - 1,
          height: element.height - 1,
        },
      ]}>
      <Box
        style={{
          rotate: `${element.rotation * -1}deg`,
        }}>
        <>
          <Typography
            variant={element.height < 100 ? 'caption' : 'body'}
            fontWeight="medium"
            color={element.name ? 'semanticBlue' : 'persistentSemanticRed'}
            align="center"
            csx={{
              marginBottom: element.height < 100 ? 0 : 5,
              lineHeight: '12px',
            }}>
            {element.name ? element.name : 'No name'}
          </Typography>
          <Box
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: element.height < 100 ? '5px' : '10px',
            }}>
            <Box
              style={{
                flexDirection: 'row',
              }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <SeatIcon
                  size={element.height < 100 ? '12px' : '16px'}
                  color="textBlack"
                />
                <Typography
                  variant={element.height < 100 ? 'caption2' : 'caption'}
                  fontWeight="regular">
                  {element.seats}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      </Box>
    </ElementPanHandlerMemo>
  );
};

export default TableElement;
