import { css, Theme } from '@emotion/react';

const background = 'lightestBlue';
const border = 'semanticBlue';

export const massUpdateInformationSectionStyles = (theme: Theme) =>
  css({
    '& .chip-container': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
    },
    '& .chip': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 18,
      paddingRight: 18,
      backgroundColor: theme.colors[background],
      border: `1px solid ${theme.colors[border]}`,
      borderRadius: 5,
    },
    '& .button': {
      width: '100%',
      height: 100,
    },
  });
