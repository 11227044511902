import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { passwordMustBeStyles } from './styles';
import { IBox } from '@app/components/common/Box/Box';

interface IPasswordMustBe extends IBox {
  passwordErrors: {
    atLeast8Chars: boolean;
    atLeastOneUpperCase: boolean;
    atLeastOneLoweCase: boolean;
    atLeastOneNumberOrSymbol: boolean;
  };
}
const PasswordMustBe = ({
  passwordErrors,
  csx,
  ...otherProps
}: IPasswordMustBe) => {
  const { t } = useTranslation();
  return (
    <Box csx={[passwordMustBeStyles, csx]} {...otherProps}>
      <Typography variant="caption2" color="darkestGrey">
        {t('authentication.resetPassword.passwordMustBe.passwordMustBe')}:
      </Typography>
      <Box className="validations">
        <PassWordRule
          label={t(
            'authentication.resetPassword.passwordMustBe.atLeast8Characters',
          )}
          valid={passwordErrors.atLeast8Chars}
        />
        <PassWordRule
          label={t(
            'authentication.resetPassword.passwordMustBe.atLeastOneCapitalLetter',
          )}
          valid={passwordErrors.atLeastOneUpperCase}
        />
        <PassWordRule
          label={t(
            'authentication.resetPassword.passwordMustBe.atLeastOneSmallLetter',
          )}
          valid={passwordErrors.atLeastOneLoweCase}
        />
        <PassWordRule
          label={t(
            'authentication.resetPassword.passwordMustBe.atLeastOneNumberOrSymbol',
          )}
          valid={passwordErrors.atLeastOneNumberOrSymbol}
        />
      </Box>
    </Box>
  );
};

interface IPassWordRule {
  valid: boolean;
  label: string;
}

const PassWordRule = ({ valid = false, label }: IPassWordRule) => {
  return (
    <div className="validation">
      {valid ? (
        <m.span
          initial={{ rotate: '-90deg', opacity: 0.5 }}
          animate={{ rotate: '0deg', opacity: 1 }}
          transition={{ duration: 0.2 }}
          css={{ display: 'flex' }}>
          <Icon name="MdCheckCircle" color="semanticGreen" />
        </m.span>
      ) : (
        <Icon
          name="MdAddCircle"
          color="semanticRed"
          csx={{ rotate: '45deg' }}
        />
      )}
      <Typography variant="caption2" color="darkestGrey">
        {label}
      </Typography>
    </div>
  );
};

export default PasswordMustBe;
