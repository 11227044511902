import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import WeekTabs from '@app/components/common/Tabs/WeekTabs';
import { WEEK_TABS_DATA } from '@app/components/common/Tabs/WeekTabs/types';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import { ITypography } from '@app/components/common/Typography/Typography';
import { getDayWithErrors } from '@app/helpers/settings/timeBasedMenuTypes';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DayOfWeek,
  HOUR_MS,
  IMenuTypeServiceTime,
  IRegisterSettings,
  REGISTER_MENU_TYPE_SERVICE_TIME_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  dateSliderHasErrorValue,
  generateTabletGeneratedId,
  menuSelectors,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { INVALID_DAYS_INITIAL_STATE } from './initialState';
import { sliderContainerStyles } from './styles';
import { IInvalidDays } from './types';

interface IRule {
  id: number;
  menuType: number;
  time: [number, number];
  bannedRanges: Array<[number, number]>;
  delete: string;
  ruleId: number | string;
}

const MS_8_AM = HOUR_MS * 8;
const MS_12_PM = HOUR_MS * 12;

const { makeSelectSettingsData } = settingsSelectors;
const { selectCardMenuTypeList } = menuSelectors;

const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const TimeBasedMenuTypeSection = ({ t }: WithTranslation) => {
  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >({ tabId: 1, subTabId: null });
  const [showHover, setShowHover] = useState(false);
  const [invalidDays, setInvalidDaysState] = useState<IInvalidDays>(
    INVALID_DAYS_INITIAL_STATE,
  );
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);
  const menuTypesFactory = useSelector(selectCardMenuTypeList);

  const invalidDaysRef = useRef(invalidDays);

  const selectedDay = useMemo(
    () =>
      activeTab?.tabId
        ? WEEK_TABS_DATA[activeTab?.tabId - 1].dayOfWeek
        : DayOfWeek.MONDAY,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab, t],
  );

  const updateTimeMenuType = (
    newValue: any,
    id: number,
    field: keyof IMenuTypeServiceTime,
  ) => {
    const clonedDefaultMenuTypeTimes = cloneDeep(
      store.getState().settings.changeData.data as IRegisterSettings,
    )?.defaultMenuTypeTimes;

    clonedDefaultMenuTypeTimes[selectedDay][id][field as 'id'] = newValue;
    setValue<IRegisterSettings>(
      {
        defaultMenuTypeTimes: clonedDefaultMenuTypeTimes,
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number, ruleId: number | string) => {
    const defaultMenuTypeTimes = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.defaultMenuTypeTimes;
    const defaultMenuTypeTimesCloned = cloneDeep(defaultMenuTypeTimes);
    defaultMenuTypeTimesCloned[selectedDay].splice(id, 1);

    setValue<IRegisterSettings>(
      {
        defaultMenuTypeTimes: defaultMenuTypeTimesCloned,
      },
      SECTION_ID,
    );

    clearInvalidDayById(ruleId);
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  const menuTypesOptions = useMemo(() => {
    const currentMenuTypesOptions = menuTypesFactory.map(value => ({
      value: value.id,
      label: [
        value.title,
        value.isActive ? '' : ` (${t('commonTexts.inactive')})`,
      ].join(''),
      textProps: value.isActive
        ? undefined
        : ({ color: 'persistentSemanticRed' } as ITypography),
    }));

    return currentMenuTypesOptions;
  }, [menuTypesFactory, t]);

  const columnHelperCustom = createColumnHelper<IRule>();

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('menuType', {
        header: 'Menu',
        cell: info => {
          const menuTypeId = info.getValue();
          const hasExistingMenuType = menuTypesOptions.some(
            menuType => menuType.value === menuTypeId,
          );
          return (
            <Dropdown
              onChange={newMenuType =>
                updateTimeMenuType(
                  newMenuType,
                  info.row.original.id,
                  'menuTypeId',
                )
              }
              customText={
                !hasExistingMenuType ? t('commonTexts.notAvailable') : undefined
              }
              placeholder={t(
                'settingsModule.registerModeSettings.general.timeBasedMenuType.menuType',
              )}
              customTextColor={
                !hasExistingMenuType ? 'persistentSemanticRed' : undefined
              }
              data={[menuTypesOptions]}
              value={menuTypeId}
            />
          );
        },
        size: 180,
      }),
      columnHelperCustom.accessor('time', {
        header: 'Time',
        minSize: 500,
        cell: info => {
          const bannedRanges = info.row.original.bannedRanges.sort(
            (a, b) => a[0] - b[0],
          );

          const hasError =
            invalidDaysRef?.current?.[selectedDay] &&
            invalidDaysRef.current[selectedDay].includes(
              info.row.original.ruleId,
            );

          return (
            <Box
              key={`slider-time-${info.row.original.id}`}
              csx={theme => sliderContainerStyles(theme, hasError)}>
              <CustomSlider
                step={900000}
                value={info.getValue()}
                min={0}
                max={86399000}
                showStepMarks={false}
                bannedRanges={bannedRanges}
                valueLabelDisplay="always"
                labelDirection="bottom"
                customValueLabel={currentVale => {
                  return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                    .toUTC()
                    .toFormat('hh:mm a');
                }}
                onValueChange={newTime => {
                  if (!Array.isArray(newTime)) {
                    return;
                  }
                  updateTimeMenuType(
                    dateTimeMsToTodayDate(newTime[0], true),
                    info.row.original.id,
                    'startTime',
                  );
                  updateTimeMenuType(
                    dateTimeMsToTodayDate(newTime[1], true),
                    info.row.original.id,
                    'endTime',
                  );
                }}
                renderLimits={value => (
                  <Box csx={{ width: '70px' }}>
                    <Typography fontWeight="medium" variant="caption">
                      {DateTime.fromISO(dateTimeMsToTodayDate(value))
                        .toUTC()
                        .toFormat('hh:mm a')}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
          );
        },
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                handleDelete(info.row.original.id, info.row.original.ruleId)
              }
            />
          ) as unknown as string,
        size: 50,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedDay, menuTypesOptions],
  );

  const setInvalidDays = (
    newInvalidDays:
      | IInvalidDays
      | ((prevInvalidDays: IInvalidDays) => IInvalidDays),
  ) => {
    if (typeof newInvalidDays === 'function') {
      setInvalidDaysState(prevInvalidDays => {
        const newInvalidDaysResult = newInvalidDays(prevInvalidDays);
        invalidDaysRef.current = newInvalidDaysResult;
        return newInvalidDaysResult;
      });
    } else {
      invalidDaysRef.current = newInvalidDays;
      setInvalidDaysState(newInvalidDays);
    }
  };

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const clearInvalidDayById = (ruleId: string | number) => {
    setInvalidDays(prevInvalidDays => ({
      ...prevInvalidDays,
      [selectedDay]: prevInvalidDays[selectedDay].filter(
        invalidDay => invalidDay !== ruleId,
      ),
    }));
  };

  const timeMenuTypesArray: IRule[] = useMemo(() => {
    if (!data?.defaultMenuTypeTimes[selectedDay]) {
      return [];
    }
    const _timeMenuTypesArray = [
      ...(data?.defaultMenuTypeTimes[selectedDay] || []),
    ];

    return _timeMenuTypesArray.map((value, index) => {
      const bannedRanges: Array<[number, number]> = _timeMenuTypesArray
        .slice(0, index)
        .map(prevMenuTypes => [
          getHourMs(prevMenuTypes.startTime),
          getHourMs(prevMenuTypes.endTime),
        ]);
      const time: [number, number] = [
        getHourMs(value.startTime),
        getHourMs(value.endTime),
      ];

      const hasError = dateSliderHasErrorValue(time, bannedRanges);
      if (!hasError) {
        clearInvalidDayById(value.id);
      } else if (!invalidDays[selectedDay].includes(value.id)) {
        setInvalidDays(prevInvalidDays => ({
          ...prevInvalidDays,
          [selectedDay]: [...prevInvalidDays[selectedDay], value.id],
        }));
      }
      return {
        id: index,
        menuType: value.menuTypeId,
        time,
        delete: 'canDelete',
        bannedRanges,
        ruleId: value.id,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.defaultMenuTypeTimes, selectedDay]);

  const isDefaultMenuTypeIdAvailable = useMemo(
    () =>
      menuTypesOptions.some(
        menuType => menuType.value === data?.defaultMenuTypeId,
      ) || !data?.defaultMenuTypeId,
    [menuTypesOptions, data?.defaultMenuTypeId],
  );

  const onNewMenuType = () => {
    const menuTypes = data?.defaultMenuTypeTimes[selectedDay] || [];
    setValue<IRegisterSettings>(
      {
        defaultMenuTypeTimes: {
          ...data?.defaultMenuTypeTimes,
          [selectedDay]: [
            ...menuTypes,
            {
              ...REGISTER_MENU_TYPE_SERVICE_TIME_INITIAL_STATE,
              id: generateTabletGeneratedId(),
              menuTypeId:
                menuTypes.length > 0
                  ? menuTypes[menuTypes.length - 1].menuTypeId
                  : menuTypesOptions[0]?.value,
              startTime: dateTimeMsToTodayDate(MS_8_AM, true),
              endTime: dateTimeMsToTodayDate(MS_12_PM, true),
            },
          ],
        },
      },
      SECTION_ID,
    );
  };

  const onCopyRules = (selectedDays: Record<DayOfWeek, boolean>) => {
    const clonedDefaultMenuTypeTimes = cloneDeep(
      (store.getState().settings.changeData.data as IRegisterSettings)
        .defaultMenuTypeTimes,
    );
    Object.entries(selectedDays).forEach(([day, value]) => {
      if (day === selectedDay || !value) {
        return;
      }
      clonedDefaultMenuTypeTimes[day as 'monday'] = cloneDeep(
        clonedDefaultMenuTypeTimes[selectedDay as 'monday'],
      );
    });
    setValue<IRegisterSettings>(
      { defaultMenuTypeTimes: clonedDefaultMenuTypeTimes },
      SECTION_ID,
    );
  };

  const invalidDaysObject = useMemo(
    () =>
      data?.defaultMenuTypeTimes
        ? getDayWithErrors(data?.defaultMenuTypeTimes)
        : {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
    [data?.defaultMenuTypeTimes],
  );

  return (
    <Card.SubCard title="Time Based Menu">
      <Grid>
        <Grid.Item mb={12} sm={6} md={6} lg={4}>
          <Dropdown
            data={[menuTypesOptions]}
            value={data?.defaultMenuTypeId}
            info={t(
              'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuType.toolTip',
            )}
            label={t(
              'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuType.label',
            )}
            placeholder={t(
              'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuType.label',
            )}
            customTextColor={
              !isDefaultMenuTypeIdAvailable
                ? 'persistentSemanticRed'
                : undefined
            }
            customText={
              !isDefaultMenuTypeIdAvailable
                ? t('commonTexts.notAvailable')
                : undefined
            }
            onChange={defaultMenuTypeId =>
              setValue<IRegisterSettings>({ defaultMenuTypeId }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item mb={12} csx={{ marginTop: '15px' }}>
          <Card.SubCard csx={{ paddingInline: 0, overflow: 'hidden' }}>
            <Box
              csx={{
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div className="infoButton">
                <ToolTip
                  showHover={showHover}
                  content={t(
                    'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuTypeRules.toolTip',
                  )}>
                  <div />
                </ToolTip>
              </div>
              <Typography
                onMouseOver={handlePopUp}
                onMouseOut={() => {
                  if (newTimeout) clearTimeout(newTimeout);
                  setShowHover(false);
                }}
                fontWeight="medium"
                color="semanticBlue"
                align="center"
                csx={{ textTransform: 'uppercase' }}>
                {t(
                  'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuTypeRules.title',
                )}
              </Typography>
            </Box>

            <WeekTabs
              activeTab={activeTab}
              onSelectedTabChange={handleOnSelectedTabChange}
              error={invalidDaysObject}
              csx={theme => ({
                borderRadius: 0,
                boxShadow: 'none',
                paddingInline: '5px',
                borderTop: `1px solid ${theme.colors.lighterGrey}`,
              })}
            />

            <Table
              columns={COLUMNS}
              data={data ? timeMenuTypesArray : []}
              cellCsx={{ height: '100px' }}
              containerCsx={{ border: 'none', borderRadius: 0 }}
              alignHeaders={{
                delete: 'center',
              }}
              align={{
                delete: 'center',
              }}
              styleForSmallerScreens="card"
              noDataComponent={
                <Box
                  csx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBlock: '20px',
                  }}>
                  <Typography align="center">
                    {t(
                      'settingsModule.registerModeSettings.general.timeBasedMenuType.noData',
                    )}
                  </Typography>
                </Box>
              }
              showShadow={false}
              newRowComponent={
                <Box
                  csx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}>
                  <DayOfTheWeekPicker
                    selectedDays=""
                    onApply={onCopyRules}
                    disabledDays={[selectedDay]}>
                    {onToggle => (
                      <Button
                        icon={<Icon name="MdFileCopy" />}
                        csx={{ minWidth: '150px' }}
                        onClick={onToggle}
                        disabled={timeMenuTypesArray.length === 0}>
                        {t(
                          'settingsModule.registerModeSettings.general.timeBasedMenuType.copyRules.button',
                        )}
                      </Button>
                    )}
                  </DayOfTheWeekPicker>
                  <Button
                    onClick={onNewMenuType}
                    variant="primary"
                    csx={{ minWidth: '150px' }}
                    icon={<Icon name="MdAdd" />}>
                    {t(
                      'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuTypeRules.addRule',
                    )}
                  </Button>
                </Box>
              }
            />
          </Card.SubCard>
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default TimeBasedMenuTypeSection;
