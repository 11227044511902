import { MENU_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { getPathWithOrgData } from '@app/helpers/navigation';
import {
  BreadCrumbAction,
  BUCKET_TEXT_DICTIONARY,
} from '@westondev/tableturn-core';
import { NavigateFunction } from 'react-router-dom';

export const getMainBreadcrumbBucket = (
  tabId: number,
  subTabId: number | null,
  navigate: NavigateFunction,
) => {
  let mainBreadcrumb = null;
  switch (tabId) {
    case 1:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.menuTypes,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.menuTypes),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.menuTypes),
      };

      break;
    case 2:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.categories,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.categories),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.categories),
      };
      break;
    case 3:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.subcategories,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.subcategories),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.subcategories),
      };
      break;
    case 4:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.items,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.items),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.items),
      };
      break;
    case 5:
      {
        switch (subTabId) {
          case 51:
            mainBreadcrumb = {
              action: BreadCrumbAction.NAV_MAIN,
              text: BUCKET_TEXT_DICTIONARY.modifierGroups,
              onPress: () =>
                navigate(MENU_SCREEN_BY_BUCKET_NAME.modifierGroups),
              pathURL: getPathWithOrgData(
                MENU_SCREEN_BY_BUCKET_NAME.modifierGroups,
              ),
            };
            break;
          case 52:
            mainBreadcrumb = {
              action: BreadCrumbAction.NAV_MAIN,
              text: BUCKET_TEXT_DICTIONARY.modifierItems,
              onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.modifierItems),
              pathURL: getPathWithOrgData(
                MENU_SCREEN_BY_BUCKET_NAME.modifierItems,
              ),
            };
            break;
          case 53:
            mainBreadcrumb = {
              action: BreadCrumbAction.NAV_MAIN,
              text: BUCKET_TEXT_DICTIONARY.ingredients,
              onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.ingredients),
              pathURL: getPathWithOrgData(
                MENU_SCREEN_BY_BUCKET_NAME.ingredients,
              ),
            };
            break;
        }
      }
      break;
    case 6:
      {
        switch (subTabId) {
          case 64:
            mainBreadcrumb = {
              action: BreadCrumbAction.NAV_MAIN,
              text: BUCKET_TEXT_DICTIONARY.combos,
              onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.combos),
              pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.combos),
            };
            break;

          case 65:
            mainBreadcrumb = {
              action: BreadCrumbAction.NAV_MAIN,
              text: BUCKET_TEXT_DICTIONARY.itemGroups,
              onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.itemGroups),
              pathURL: getPathWithOrgData(
                MENU_SCREEN_BY_BUCKET_NAME.itemGroups,
              ),
            };
            break;
        }
      }
      break;
    case 7:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.specials,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.specials),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.specials),
      };
      break;
    case 8:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.discounts,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.discounts),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.discounts),
      };
      break;
    case 9:
      mainBreadcrumb = {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY.reportingGroups,
        onPress: () => navigate(MENU_SCREEN_BY_BUCKET_NAME.reportingGroups),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME.reportingGroups),
      };
      break;
    default:
      break;
  }
  return mainBreadcrumb;
};
