import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { CHECKBOX_SIZE } from '@app/components/common/Checkbox/styles';
import RadioCircle from '@app/components/common/Icon/custom/RadioCircle';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { useTheme } from '@emotion/react';
import { IPricingLocationRow, formatMoney } from '@westondev/tableturn-core';
import { ReactNode, useState } from 'react';
import { RadioSelectionStates, TPricesNames } from '../types';

interface IHandleLocation {
  handleLocationToggle: (id: number) => void;
  handleLocationPrice: (
    id: number,
    priceName: TPricesNames,
    payload: number,
  ) => void;
  isCombo?: boolean;
  isLast?: boolean;
}

const PricingLocationRow = ({
  selected,
  name,
  deliveryPrice,
  itemPrice,
  comboBasePrice,
  onlinePrice,
  locationId,
  handleLocationToggle,
  handleLocationPrice,
  disabled = false,
  isCombo,
  isLast,
}: IPricingLocationRow & IHandleLocation) => {
  const theme = useTheme();
  const selectedTextColor = theme.colors.lightBlue;
  const disabledTextColor = theme.colors.lightGrey;

  const [activeInput, setActiveInput] = useState('');

  return (
    <Box
      csx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '70px',
        borderBottom: !isLast ? `1px solid ${theme.colors.lightGrey}` : '0px',
        paddingRight: '20px',
        backgroundColor: 'white',
      }}>
      <Box
        csx={{
          width: '5px',
          height: '70px',
          backgroundColor: theme.colors.persistentSemanticBlue,
        }}
      />
      <Box
        csx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '15px',
        }}>
        <Button
          csx={{
            opacity: disabled ? 0.5 : undefined,
            minWidth: '140px',
            justifyContent: 'flex-start',
            [MQ_MIN_MEDIUM]: {
              minWidth: '210px',
            },
          }}
          variant="transparent"
          icon={
            <RadioCircle
              size={CHECKBOX_SIZE - 2}
              state={
                selected
                  ? RadioSelectionStates.FULL
                  : RadioSelectionStates.EMPTY
              }
              csx={{
                marginRight: '10px',
              }}
            />
          }
          onClick={() => !disabled && handleLocationToggle(locationId)}>
          <Typography
            color={selected ? selectedTextColor : 'darkestGrey'}
            fontWeight="medium">
            {name || '- - -'}
          </Typography>
        </Button>
        {activeInput === 'i1' ? (
          <MoneyInput
            onBlur={() => setActiveInput('')}
            autoFocus
            disabled={disabled}
            value={isCombo ? comboBasePrice : itemPrice}
            onValueChange={newValue =>
              handleLocationPrice(
                locationId,
                isCombo ? 'comboBasePrice' : 'itemPrice',
                newValue,
              )
            }
          />
        ) : (
          <RestyledPressable
            onClick={() => setActiveInput('i1')}
            disabled={disabled}>
            <Typography color={disabled ? disabledTextColor : 'darkestGrey'}>
              {formatMoney(null, isCombo ? comboBasePrice : itemPrice)}
            </Typography>
          </RestyledPressable>
        )}
        {activeInput === 'i2' ? (
          <MoneyInput
            onBlur={() => setActiveInput('')}
            autoFocus
            disabled={disabled}
            value={onlinePrice}
            onValueChange={newValue =>
              handleLocationPrice(locationId, 'onlinePrice', newValue)
            }
          />
        ) : (
          <RestyledPressable
            onClick={() => setActiveInput('i2')}
            disabled={disabled}>
            <Typography color={disabled ? disabledTextColor : 'darkestGrey'}>
              {formatMoney(null, onlinePrice)}
            </Typography>
          </RestyledPressable>
        )}

        {activeInput !== 'i3' ? (
          <MoneyInput
            onBlur={() => setActiveInput('')}
            autoFocus
            disabled={disabled}
            value={deliveryPrice}
            onValueChange={newValue =>
              handleLocationPrice(locationId, 'deliveryPrice', newValue)
            }
          />
        ) : (
          <RestyledPressable
            onClick={() => setActiveInput('i3')}
            disabled={disabled}>
            <Typography color={disabled ? disabledTextColor : 'darkestGrey'}>
              {formatMoney(null, deliveryPrice)}
            </Typography>
          </RestyledPressable>
        )}
      </Box>
    </Box>
  );
};

const RestyledPressable = ({
  disabled,
  children,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <Box
      onClick={!disabled ? onClick : () => null}
      csx={theme => ({
        height: 50,
        display: 'flex',
        width: '100%',
        border: `2px solid ${
          disabled ? theme.colors.lighterGrey : theme.colors.lightGrey
        }`,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
      })}>
      {children}
    </Box>
  );
};

export default PricingLocationRow;
