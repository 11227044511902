import { createRef, useEffect, useState } from 'react';
import Modal from '../Modal';
import { ICloneModal } from './types';
import Button from '../Button';
import Input from '../Input';

const CloneModal = ({
  t,
  active,
  label,
  title,
  onCancel,
  onSuccess,
}: ICloneModal) => {
  const [newName, setNewName] = useState('');
  const inputRef = createRef<HTMLInputElement>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleCancel = () => {
    setNewName('');
    onCancel();
  };

  const handleSuccess = () => {
    const name = newName.trim();
    if (name.length > 0) {
      onSuccess(name);
      setNewName('');
    }
  };

  useEffect(() => {
    if (active) {
      inputRef.current?.focus();
    }
  }, [active, inputRef]);

  return (
    <Modal
      size={600}
      isActive={active as boolean}
      title={title}
      onModalClose={handleCancel}
      footer={
        <>
          <Button
            csx={{ width: '80px' }}
            variant="secondary"
            onClick={handleCancel}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            csx={{ width: '80px' }}
            disabled={newName.trim().length > 0 ? false : true}
            variant="primary"
            onClick={handleSuccess}>
            {t('commonButtons.clone')}
          </Button>
        </>
      }>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSuccess();
        }}>
        <Input
          ref={inputRef}
          value={newName}
          label={label}
          placeholder={t('app.modals.clone.placeholder')}
          onChange={handleChange}
          csx={{ marginTop: '20px' }}
        />
      </form>
    </Modal>
  );
};

export default CloneModal;
