import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const verifyCodeStepStyles = () =>
  css({
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.title': {
      marginBottom: '40px',
    },
    [MQ_MIN_SMALL]: {
      '.title': {
        marginBottom: '48px',
        paddingInline: '20px',
      },
    },
  });
