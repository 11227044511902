import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';

interface IRearrangeSection {
  isFirst: boolean;
  isLast: boolean;
  onArrowUpPress: () => void;
  onArrowDownPress: () => void;
  onDoubleArrowUpPress: () => void;
  onDoubleArrowDownPress: () => void;
}

const RearrangeButtons = ({
  isFirst,
  isLast,
  onArrowDownPress,
  onArrowUpPress,
  onDoubleArrowDownPress,
  onDoubleArrowUpPress,
}: IRearrangeSection) => {
  return (
    <Box
      csx={theme => ({
        minWidth: '50px',
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderLeft: `1px solid ${theme.colors.lightGrey}`,
      })}>
      <Box csx={{ width: '100%' }}>
        <Button
          variant="primary"
          disabled={isFirst}
          csx={{
            borderWidth: 0,
            borderRadius: 0,
            borderBottom: '1px solid white',
            height: '60px',
          }}
          icon={<Icon name="MdKeyboardDoubleArrowUp" />}
          onClick={onDoubleArrowUpPress}
        />
        <Button
          variant="primary"
          disabled={isFirst}
          csx={{
            borderWidth: 0,
            borderRadius: 0,
            height: '60px',
          }}
          icon={<Icon name="MdKeyboardArrowUp" />}
          onClick={onArrowUpPress}
        />
      </Box>
      <Box csx={{ width: '100%' }}>
        <Button
          variant="primary"
          disabled={isLast}
          csx={{
            borderWidth: 0,
            borderRadius: 0,
            height: '60px',
          }}
          icon={<Icon name="MdKeyboardArrowDown" />}
          onClick={onArrowDownPress}
        />
        <Button
          variant="primary"
          disabled={isLast}
          csx={{
            borderWidth: 0,
            borderRadius: 0,
            borderTop: '1px solid white',
            height: '60px',
          }}
          icon={<Icon name="MdKeyboardDoubleArrowDown" />}
          onClick={onDoubleArrowDownPress}
        />
      </Box>
    </Box>
  );
};

export default RearrangeButtons;
