import { TCsx, css } from '@emotion/react';
import { HTMLMotionProps, m } from 'framer-motion';
import { ReactNode, forwardRef, useMemo } from 'react';
import Spinner from '../Spinner';
import { buttonVariants } from './styles';

export type IButton = HTMLMotionProps<'button'> & {
  children?: ReactNode;
  variant?:
    | 'primary'
    | 'active'
    | 'secondary'
    | 'success'
    | 'disabled'
    | 'warning'
    | 'danger'
    | 'transparent'
    | 'icon';
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom';
  isLoading?: boolean;
  loadingText?: string;
  onClickDisabled?: () => void;
  csx?: TCsx;
  css?: never;
  disableHoverEffect?: boolean;
  disableClickEffect?: boolean;
};

const POSITION_DIRECTION = {
  left: 'row' as const,
  right: 'row-reverse' as const,
  top: 'column' as const,
  bottom: 'column-reverse' as const,
};

const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      children,
      variant = 'secondary',
      icon,
      iconPosition = 'left',
      isLoading = false,
      disableHoverEffect = false,
      loadingText,
      onClickDisabled,
      csx,
      disableClickEffect,
      ...otherProps
    },
    ref,
  ) => {
    const isButtonDisabled = otherProps.disabled || isLoading;

    const buttonVariantStyles =
      buttonVariants[
        variant !== 'transparent' && isButtonDisabled ? 'disabled' : variant
      ];

    const dynamicButtonStyles = css({
      flexDirection: POSITION_DIRECTION[iconPosition],
    });

    const iconClass = useMemo(() => {
      return children
        ? iconPosition === 'left'
          ? ' icon-left'
          : iconPosition === 'right'
          ? ' icon-right'
          : iconPosition === 'top'
          ? ' icon-top'
          : ' icon-bottom'
        : '';
    }, [iconPosition, children]);

    return (
      <m.button
        ref={ref}
        whileTap={
          !isButtonDisabled && !disableClickEffect ? { scale: 0.97 } : undefined
        }
        transition={{ duration: 0.05 }}
        css={theme => [
          buttonVariantStyles(theme, {
            isDisabled: isButtonDisabled,
            disableHoverEffect,
          }),
          dynamicButtonStyles,
          csx,
        ]}
        {...otherProps}
        disabled={onClickDisabled ? false : otherProps.disabled}
        onClick={isButtonDisabled ? onClickDisabled : otherProps.onClick}>
        {isLoading ? (
          <>
            <Spinner className="spinner" />
            {loadingText || children}
          </>
        ) : (
          <>
            {icon && <span className={`icon${iconClass}`}>{icon}</span>}
            {children}
          </>
        )}
      </m.button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
