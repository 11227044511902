import { RadioSelectionStates } from '@app/types';
import { ISelectLocationGroupRow } from '@westondev/tableturn-core';

type LocationSelectionActions =
  | { type: 'selectAll' }
  | { type: 'toggleLocation'; locationGroup: number; location: number }
  | {
      type: 'toggleLocationGroup';
      locationGroup: number;
      state: RadioSelectionStates;
    };

const selectLocationReducer = (
  state: ISelectLocationGroupRow[],
  action: LocationSelectionActions,
) => {
  switch (action.type) {
    case 'selectAll':
      return state.map(locationGroup => ({
        ...locationGroup,
        locations: locationGroup.locations.map(location => ({
          ...location,
          selected: true,
        })),
      }));
    case 'toggleLocationGroup':
      return state.map(locationGroup =>
        locationGroup.locationGroupId === action.locationGroup
          ? {
              ...locationGroup,
              locations: locationGroup.locations.map(location => ({
                ...location,
                selected: !location.enabled
                  ? location.selected
                  : action.state === RadioSelectionStates.FULL
                  ? false
                  : true,
              })),
            }
          : locationGroup,
      );
    case 'toggleLocation':
      return state.map(locationGroup =>
        locationGroup.locationGroupId === action.locationGroup
          ? {
              ...locationGroup,
              locations: locationGroup.locations.map(location =>
                location.locationId === action.location
                  ? { ...location, selected: !location.selected }
                  : location,
              ),
            }
          : locationGroup,
      );

    default:
      return state;
  }
};

export default selectLocationReducer;
