import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  CONFIRMATION_MODAL_INITIAL_STATE,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import { actionCreatorsMenuWeb } from '@app/state';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SUB_NAMES = ['posName' as const, 'checkName' as const];

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('discounts', SECTION_ID);

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();

  const dispatch = useDispatch();
  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );
  const { itemData } = useSelector(getMemoizedItemData);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');
  const checkNameError = useFormError('checkName');

  const errorMap = {
    posName: posNameError,
    checkName: checkNameError,
  };

  const hasErrors =
    Boolean(nameError?.error) ||
    Boolean(posNameError?.error) ||
    Boolean(checkNameError?.error);

  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const handleDeleteDiscount = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.discounts.singular'),
      type: isMasterMode ? 'delete' : 'unassociate',
      name: itemData?.name,
      onSuccess: () => {
        setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
        deleteBucketElementWeb();
      },
    });
  }, [
    deleteBucketElementWeb,
    isMasterMode,
    itemData?.name,
    setShowConfirmationModal,
    t,
  ]);
  const handleNameChange = (name: string) => {
    const changes: Record<string, string> = {};
    const previousName = itemData?.name;
    SUB_NAMES.forEach(subName => {
      const value = itemData?.[subName];
      if (!value || value === previousName) {
        changes[subName] = name;
        if (errorMap[subName]?.error) {
          errorMap[subName]?.clear();
        }
      }
    });
    setValue({ ...changes, name }, SECTION_ID);
  };

  const showDeleteDisabled = () => {
    showToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: t('menuScreen.deleteToast', {
        bucket: t('menuScreen.common.bucket.discounts.plural'),
      }),
    });
  };

  const options = useMemo(() => {
    if (isCreation) {
      return undefined;
    }

    const _options = [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalOpen(true),
      },
      {
        text: t('commonTexts.delete'),
        handler: handleDeleteDiscount,
        isDisabled: !isMasterMode,
        disabledHandler: showDeleteDisabled,
      },
    ];

    return _options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeleteDiscount, isCreation, isMasterMode, t]);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active }, SECTION_ID)}
      showDefaultLabel
    />
  );

  return (
    <>
      <MenuCloneModal
        bucket="discounts"
        active={isCloneModalOpen}
        onCancel={() => setIsCloneModalOpen(false)}
        onClone={newId => {
          checkForChangesAndNavigateWeb(
            () => {
              const updatedMenu =
                store.getState().menu.selectedMode.currentMenu;
              const menuType = updatedMenu.discounts[newId];
              const _navigate = () => navigate(`/menu/discounts/${newId}`);
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: menuType.name,
                onPress: _navigate,
                pathURL: `/menu/discounts/${newId}`,
              });
              _navigate();
            },
            () => null,
          );
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasErrors}
        titleTextContainerCsx={{ minWidth: '170px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
            description: t(
              'loggedIn.menuModule.discounts.basic.optionsDisabled.creation',
            ),
          })
        }
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid rowGap={20} columnGap={20}>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.name')}
                placeholder={t('loggedIn.commonFields.basic.name')}
                value={itemData?.name || ''}
                disabled={!isMasterMode && !isCreation}
                onChange={e => {
                  handleNameChange(e.currentTarget.value);
                }}
                error={Boolean(nameError?.error)}
                onFocus={() => {
                  nameError?.clear();
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.posName')}
                placeholder={t('loggedIn.commonFields.basic.posName')}
                value={itemData?.posName || ''}
                onChange={posName => {
                  setValue(
                    {
                      posName: posName.currentTarget.value,
                    },
                    SECTION_ID,
                  );
                  if (
                    itemData.posName === '' ||
                    itemData.posName === itemData.posName
                  ) {
                    setValue(
                      {
                        posName: posName.currentTarget.value,
                      },
                      SECTION_ID,
                    );
                  }

                  if (itemData.posName === '' && posNameError) {
                    posNameError.clear();
                  }
                }}
                error={Boolean(posNameError?.error)}
                onFocus={() => {
                  posNameError?.clear();
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.checkName')}
                placeholder={t('loggedIn.commonFields.basic.checkName')}
                value={itemData?.checkName || ''}
                onChange={checkName => {
                  setValue(
                    {
                      checkName: checkName.currentTarget.value,
                    },
                    SECTION_ID,
                  );
                  if (
                    itemData.checkName === '' ||
                    itemData.checkName === itemData.checkName
                  ) {
                    setValue(
                      {
                        checkName: checkName.currentTarget.value,
                      },
                      SECTION_ID,
                    );
                  }

                  if (itemData.checkName === '' && checkNameError) {
                    checkNameError.clear();
                  }
                }}
                error={Boolean(checkNameError?.error)}
                onFocus={() => {
                  checkNameError?.clear();
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={[displayFlexEndColumnStyles, { paddingBottom: '13px' }]}>
              <Checkbox
                checked={itemData?.requireReason}
                onChange={requireReason =>
                  setValue({ requireReason }, SECTION_ID)
                }
                csx={theme => ({
                  '.checkboxLabel': {
                    fontWeight: theme.fontWeights.medium,
                  },
                })}
                label={t('loggedIn.menuModule.discounts.basic.requireReason')}
              />
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
