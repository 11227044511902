// TODO: Move to Core
export const getSelectionModalMenuTypeFilterValue = (
  option: number,
  menuTypeFilter: number[],
) => {
  if (option === 0) {
    return [0];
  }
  let newMenuTypeFilter = [...menuTypeFilter];
  if (newMenuTypeFilter.includes(option)) {
    newMenuTypeFilter = newMenuTypeFilter.filter(
      menuTypeId => menuTypeId !== option,
    );
  } else {
    newMenuTypeFilter.push(option);
  }
  if (newMenuTypeFilter.length === 0) {
    newMenuTypeFilter = [0];
  }

  if (newMenuTypeFilter.length > 1 && newMenuTypeFilter.includes(0)) {
    newMenuTypeFilter = newMenuTypeFilter.filter(
      menuTypeId => menuTypeId !== 0,
    );
  }

  return newMenuTypeFilter;
};
