import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import { MQ_MIN_LARGE, MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const forgotPasswordCardStyles = () =>
  css({
    ' .form': {
      '> .formContent': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '53px',
        '> div:nth-of-type(1)': {
          marginBottom: `${163 - CAPTION_HEIGHT}px`,
        },
        [MQ_MIN_SMALL]: {
          marginTop: '50px',
          '> div:nth-of-type(1)': {
            marginBottom: `${166 - CAPTION_HEIGHT}px`,
          },
        },
      },
    },
    [MQ_MIN_LARGE]: {
      paddingTop: '20px',
    },
  });
