import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { ITypography } from '@app/components/common/Typography/Typography';
import { TCsx } from '@emotion/react';
import React from 'react';

type IDetailsSubtitle = {
  children?: React.ReactNode | string;
  required?: boolean;
  textCsx?: TCsx;
  noMargin?: boolean;
} & ITypography;

const DetailsSubtitle = ({ textCsx, noMargin, ...props }: IDetailsSubtitle) => {
  return (
    <Box
      csx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: noMargin ? '0px' : '15px',
      }}>
      <Typography
        color="semanticBlue"
        variant="body"
        fontWeight="medium"
        csx={[textCsx ?? {}]}
        {...props}
      />
      {props.required && (
        <Typography
          color="semanticRed"
          variant="body"
          fontWeight="medium"
          csx={{ marginLeft: '8px' }}>
          *
        </Typography>
      )}
    </Box>
  );
};

export default DetailsSubtitle;
