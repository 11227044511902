import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { WithTranslation } from 'react-i18next';

interface INoMenuTypeLayer {
  t: WithTranslation['t'];
}
const NoMenuTypeLayer = ({ t }: INoMenuTypeLayer) => {
  return (
    <>
      <Box csx={[displayFlexRowStyles, { gap: '15px', paddingInline: '20px' }]}>
        <Icon size="40px" name="MdSubtitlesOff" color="textBlack" />
        <Box>
          <Typography color="black" variant="subtitle" fontWeight="medium">
            {t('menuScreen.itemDetails.noMenuType.title')}
          </Typography>
          <Typography color="black">
            {t('menuScreen.itemDetails.noMenuType.description')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default NoMenuTypeLayer;
