import { SortOptions } from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { sortButtonStyles } from './styles';
import { SortButtonMode, SortButtonModeTypes } from './types';

interface ISortButton {
  mode?: SortButtonModeTypes;
  children?: React.ReactNode;
  showText?: boolean;
  disabled?: boolean;
  defaultSortDirection?: SortOptions | null;
  initialSortDirection?: SortOptions | null;
  onSort?: (sortType: SortOptions | null) => void;
  value?: SortOptions | null;
}

const SortButton = ({
  mode = SortButtonMode.TOGGLE_DEFAULT,
  onSort,
  children,
  disabled = false,
  defaultSortDirection = null,
  initialSortDirection = SortOptions.asc,
  value,
}: ISortButton) => {
  const [sortDirection, setSortDirection] = useState<SortOptions | null>(null);

  const _sortDirection = value !== undefined ? value : sortDirection;

  useEffect(() => {
    if (defaultSortDirection) {
      setSortDirection(defaultSortDirection);
    }
  }, [defaultSortDirection]);

  const sortType =
    ((mode === SortButtonMode.ACTIVE_INACTIVE ? initialSortDirection : null) ||
      _sortDirection) === 'desc'
      ? 'Up'
      : 'Down';

  const handleOnSort = () => {
    if (mode === SortButtonMode.ACTIVE_INACTIVE) {
      const newSortOption: SortOptions | null =
        _sortDirection || initialSortDirection;
      if (
        _sortDirection === SortOptions.asc ||
        _sortDirection === SortOptions.desc
      ) {
        setSortDirection(null);
        onSort && onSort(null);
      } else {
        setSortDirection(newSortOption);
        onSort && onSort(newSortOption);
      }
    } else {
      let newSortOption: SortOptions | null = SortOptions.asc;
      if (_sortDirection === SortOptions.asc) {
        newSortOption = SortOptions.desc;
      }
      if (_sortDirection === SortOptions.desc) {
        newSortOption =
          mode === SortButtonMode.TOGGLE_NO_DEFAULT
            ? (newSortOption = SortOptions.asc)
            : null;
      }
      setSortDirection(newSortOption);
      onSort && onSort(newSortOption);
    }
  };

  return (
    <Button
      variant={_sortDirection ? 'active' : 'secondary'}
      icon={<Icon name={`FaSortAlpha${sortType}`} />}
      iconPosition="right"
      onClick={handleOnSort}
      disabled={disabled}
      csx={sortButtonStyles}>
      {children}
    </Button>
  );
};

export default SortButton;
