import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRegisterSettings,
  QSR_ACTION_ON_PAY_OPTIONS,
  QsrSendAndPayActionButtonsType,
  SendOrderToKitchenType,
  SettingsSectionId,
  actionCreatorsSettings,
  registerModeQsrActionOnPayOptionsFactory,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SEND_ORDER_OPTIONS: Record<number, SendOrderToKitchenType> = {
  1: SendOrderToKitchenType.PAYMENT_COMPLETED,
  2: SendOrderToKitchenType.PAYMENT_INITIATED,
};

const SEND_AND_PAY_ACTION_OPTIONS: Record<
  number,
  QsrSendAndPayActionButtonsType
> = {
  1: QsrSendAndPayActionButtonsType.PAY_ONLY,
  2: QsrSendAndPayActionButtonsType.SEND_ONLY,
  3: QsrSendAndPayActionButtonsType.BOTH,
};

const SECTION_ID = SettingsSectionId.QUICK_SERVICE;
const getMemoizedSettingsData =
  settingsSelectors.makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const QuickServiceSettings = ({ t }: WithTranslation) => {
  const { data } = useSelector(getMemoizedSettingsData);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const sendAndPayActionDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(SEND_AND_PAY_ACTION_OPTIONS).find(
          key =>
            SEND_AND_PAY_ACTION_OPTIONS[Number(key)] ===
            data?.qsrSendAndPayActionButtons,
        ),
      ),
    [data?.qsrSendAndPayActionButtons],
  );

  const sendToKitchenDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(SEND_ORDER_OPTIONS).find(
          key =>
            SEND_ORDER_OPTIONS[Number(key)] === data?.sendOrderToKitchenTrigger,
        ),
      ),
    [data?.sendOrderToKitchenTrigger],
  );

  const qsrActionOnPayOptions = useMemo(
    () => registerModeQsrActionOnPayOptionsFactory(t),
    [t],
  );

  const qsrActionOnPayValue = useMemo(
    () => QSR_ACTION_ON_PAY_OPTIONS.indexOf(data?.qsrActionOnPay) + 1,
    [data?.qsrActionOnPay],
  );

  return (
    <>
      <Grid>
        <Grid.Item sm={12}>
          <Grid rowGap={15}>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tableService.general.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
                    <Dropdown
                      label={t(
                        'settingsModule.registerModeSettings.quickService.general.qsrSendAndPayActionButtons.label',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.quickService.general.qsrSendAndPayActionButtons.toolTip',
                      )}
                      placeholder="Select Action"
                      data={[
                        [
                          {
                            value: 1,
                            label: t(
                              'settingsModule.registerModeSettings.quickService.general.qsrSendAndPayActionButtons.options.payOnly',
                            ),
                          },
                          {
                            value: 2,
                            label: t(
                              'settingsModule.registerModeSettings.quickService.general.qsrSendAndPayActionButtons.options.sendOnly',
                            ),
                          },
                          {
                            value: 3,
                            label: t(
                              'settingsModule.registerModeSettings.quickService.general.qsrSendAndPayActionButtons.options.both',
                            ),
                          },
                        ],
                      ]}
                      onChange={qsrSendAnPayAction => {
                        const newValue =
                          qsrSendAnPayAction in SEND_AND_PAY_ACTION_OPTIONS
                            ? SEND_AND_PAY_ACTION_OPTIONS[qsrSendAnPayAction]
                            : undefined;
                        setValue(
                          {
                            qsrSendAndPayActionButtons: newValue,
                          },
                          SECTION_ID,
                        );
                      }}
                      value={sendAndPayActionDropdownValue}
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
                    <Dropdown
                      label={t(
                        'settingsModule.registerModeSettings.quickService.general.sendOrderToKitchenTrigger.label',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.quickService.general.sendOrderToKitchenTrigger.toolTip',
                      )}
                      placeholder="Select Action"
                      data={[
                        [
                          {
                            value: 1,
                            label: t(
                              'settingsModule.registerModeSettings.quickService.general.sendOrderToKitchenTrigger.options.paymentCompleted',
                            ),
                          },
                          {
                            value: 2,
                            label: t(
                              'settingsModule.registerModeSettings.quickService.general.sendOrderToKitchenTrigger.options.paymentInitiated',
                            ),
                          },
                        ],
                      ]}
                      onChange={sendToKitchen => {
                        const newValue =
                          sendToKitchen in SEND_ORDER_OPTIONS
                            ? SEND_ORDER_OPTIONS[sendToKitchen]
                            : undefined;
                        setValue(
                          {
                            sendOrderToKitchenTrigger: newValue,
                          },
                          SECTION_ID,
                        );
                      }}
                      value={sendToKitchenDropdownValue}
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
                    <Dropdown
                      label={t(
                        'settingsModule.registerModeSettings.quickService.general.qsrActionOnPay.label',
                      )}
                      placeholder={t(
                        'settingsModule.registerModeSettings.quickService.general.qsrActionOnPay.label',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.quickService.general.qsrActionOnPay.toolTip',
                      )}
                      data={[qsrActionOnPayOptions]}
                      onChange={qsrActionOnPay => {
                        setValue(
                          {
                            qsrActionOnPay:
                              QSR_ACTION_ON_PAY_OPTIONS[qsrActionOnPay - 1],
                          },
                          SECTION_ID,
                        );
                      }}
                      value={qsrActionOnPayValue}
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.quickService.general.displayOrderDetailsOnNewOrderQsr',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.quickService.general.displayOrderDetailsOnNewOrderQsrtoolTip',
                      )}
                      checked={data?.displayOrderDetailsOnNewOrderQsr}
                      onChange={displayOrderDetailsOnNewOrderQsr =>
                        setValue<IRegisterSettings>(
                          { displayOrderDetailsOnNewOrderQsr },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default QuickServiceSettings;
