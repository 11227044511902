import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import TextAreaInput from '@app/components/common/TextAreaInput';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IWithMenuTypeVersionId,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SectionId.MORE_INFO;
const getMemoizedItemData = makeSelectItemData('specials', SECTION_ID);

type ISpecialMoreInformationSection = WithTranslation &
  IWithMenuTypeVersionId &
  IAccordionSection;

const MoreInformationSection = ({
  t,
  menuTypeVersionId,
  ...accordionSectionProps
}: ISpecialMoreInformationSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const { itemData } = useSelector(getMemoizedItemData);

  return (
    <AccordionSection
      title={t('menuScreen.itemDetails.moreInformation.title')}
      {...accordionSectionProps}>
      <TextAreaInput
        maxLength={1000}
        value={
          itemData
            ? itemData.menuTypeVersions[menuTypeVersionId]?.description
            : ''
        }
        onChange={({ currentTarget: { value: description } }) => {
          setValue({ description }, SECTION_ID, menuTypeVersionId);
        }}
        placeholder={t('loggedIn.commonFields.moreInformation.description')}
        label={t('loggedIn.commonFields.moreInformation.description')}
      />
    </AccordionSection>
  );
};

export default MoreInformationSection;
