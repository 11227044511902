import Box from '@app/components/common/Box';
import Chip from '@app/components/common/Chip';
import Table from '@app/components/common/Table';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  printersSelector,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { tagsContainer } from '@app/views/LoggedIn/Settings/Edit/RegisterMode/OpenItemTypes/styles';
import { useTheme } from '@emotion/react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  IItemPrepStationDiff,
  IPrepStationsSettings,
  diffPrepStationsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderTable from '../../common/HeaderTable';
import RenderChange from '../common/RenderChange';
import some from 'lodash/some';

const RegisterModeDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(settingsDifferencesSelector);
  const printers = useSelector(printersSelector);
  const changeData = useRootSelector(
    state => state.settings.changeData.data,
  ) as IPrepStationsSettings;

  const originalData = useRootSelector(
    state => state.settings.changeData.originalData,
  ) as IPrepStationsSettings;

  const formattedDifferences = useMemo(() => {
    if (!differences) return null;
    const diffObject = diffPrepStationsFactory(
      differences,
      store.getState() as unknown as CoreRootState,
    );
    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      [
        'defaultMenuTypeId',
        'qsrSendAndPayActionButtons',
        'defaultRestaurantMode',
        'coursingMode',
        'seatingMode',
        'defaultPrepStationId',
      ].includes(field)
    ) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const theme = useTheme();

  const renderPrepStationsTable = (
    prepStationsDiff: IItemPrepStationDiff[],
  ) => {
    const columnHelper = createColumnHelper<IItemPrepStationDiff>();

    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'active',
        header: t('app.modals.registerModeDiffModal.tags.openItemTypes.active'),
        meta: {
          getCellContext: (
            context: CellContext<IItemPrepStationDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.prepStations[rowData.id].active
                    : changeData.prepStations[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('app.modals.registerModeDiffModal.tags.openItemTypes.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.prepStations[rowData.id].name
                    : changeData.prepStations[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.tags.openItemTypes.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.prepStations[rowData.id].posName
                    : changeData.prepStations[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'printerIds',
        header: 'Printers',
        minSize: 280,
        cell: info => {
          const rowData = info.row.original;
          const printerIds =
            originalData.prepStations[rowData.id]?.printerIds || [];

          const changedPrinters = rowData.changes.filter(
            change => change.field === 'printerIds',
          );

          return changedPrinters.length > 0 ? (
            <Box
              csx={[
                tagsContainer,
                {
                  width: '100%',
                },
              ]}>
              {changedPrinters.map(printer => {
                const value = (
                  printer.value as {
                    action: string;
                    value: string;
                  }[]
                )[0];
                const isPrinterAdded = value.action === 'add';
                const printerId = value.value;

                return (
                  <Chip
                    key={`${rowData.id}-printer-${printerId}`}
                    csx={{
                      backgroundColor: isPrinterAdded
                        ? theme.colors.lighterBlue
                        : theme.colors.lighterRed,
                      border: isPrinterAdded
                        ? `1px solid ${theme.colors.persistentSemanticBlue}`
                        : `1px solid ${theme.colors.persistentSemanticRed}`,
                      height: '40px',
                      padding: '0px 5px',
                    }}
                    textColor={
                      isPrinterAdded
                        ? theme.colors.persistentSemanticBlue
                        : theme.colors.persistentSemanticRed
                    }>
                    {printers[printerId].name}
                  </Chip>
                );
              })}
            </Box>
          ) : (
            printerIds.length > 0 &&
              printerIds.map(printerId => {
                return (
                  <Chip
                    key={`${rowData.id}-printer-${printerId}`}
                    csx={{
                      backgroundColor: theme.colors.white,
                      border: `1px solid ${theme.colors.lightGrey}`,
                      height: '40px',
                    }}
                    textColor={theme.colors.textBlack}>
                    {printers[printerId].name}
                  </Chip>
                );
              })
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <HeaderTable title={t('settingsModule.prepStationsSettings.title')} />
        <Table
          data={prepStationsDiff}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            name: 'center',
            posName: 'center',
            prepStation: 'center',
            printerIds: 'center',
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      {formattedDifferences?.defaultPrepStation &&
        formattedDifferences?.defaultPrepStation.length > 0 &&
        some(formattedDifferences, item => item.length > 0) &&
        formattedDifferences?.defaultPrepStation.map(change => (
          <RenderChange
            key={change.field}
            formatValue={formatValue}
            change={change}
            renderAsString={!!change.textValue || change.field === 'imageUrl'}
          />
        ))}

      {formattedDifferences?.prepStations &&
        formattedDifferences?.prepStations.length > 0 &&
        renderPrepStationsTable(formattedDifferences?.prepStations)}
    </Box>
  );
};

export default RegisterModeDiffModal;
