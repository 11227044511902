import { css } from '@emotion/react';

export const disabledBlockLayerStyles = () =>
  css({
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    '.backdrop': [
      {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        opacity: 0.8,
        zIndex: 1,
      },
    ],
    '.container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
    },
  });
