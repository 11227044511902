import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Slider from '@app/components/common/Slider';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Typography from '@app/components/common/Typography';

const SliderExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Slider
      </Typography>
      <Typography variant="body" csx={{ marginBottom: '15px' }}>
        Simple Slider Continuous
      </Typography>
      <Grid>
        <Grid.Item mb={12} sm={6}>
          <Typography variant="body" csx={{ marginBottom: '15px' }}>
            No Value
          </Typography>
          <Slider />
        </Grid.Item>
        <Grid.Item mb={12} sm={6}>
          <Typography variant="body" csx={{ marginBottom: '15px' }}>
            With controlled Value
          </Typography>
          <Slider value={30} />
        </Grid.Item>
      </Grid>

      <code css={{ marginBlock: '15px' }}>min: 10, max: 80</code>
      <Slider min={10} max={80} />
      <code css={{ marginBlock: '15px' }}>valueLabelDisplay: hover</code>
      <Slider valueLabelDisplay="hover" />
      <code css={{ marginBlock: '15px' }}>valueLabelDisplay: always</code>
      <Box csx={{ paddingTop: '35px' }}>
        <Slider valueLabelDisplay="always" />
      </Box>
      <code css={{ marginTop: '15px' }}>
        valueLabelDisplay: always, labelDirection: bottom, min: 10, max: 30
      </code>
      <code css={{ marginBottom: '15px' }}>
        customValueLabel:{' '}
        {`currentVale => {
            return 'Weight: ' + currentVale + 'kg';
          }`}
      </code>
      <Box csx={{ paddingBottom: '55px' }}>
        <Slider
          min={10}
          max={30}
          valueLabelDisplay="always"
          labelDirection="bottom"
          customValueLabel={currentVale => {
            return 'Weight: ' + currentVale + ' kg';
          }}
        />
      </Box>
      <Typography variant="body" csx={{ marginBottom: '20px' }}>
        Steps Slider
      </Typography>
      <Slider valueLabelDisplay="hover" step={20} />
      <Typography variant="body" csx={{ marginBlock: '20px' }}>
        Steps Range slider
      </Typography>
      <Slider step={5} value={[20, 60]} showDebugValues />
      <Typography variant="body" csx={{ marginBlock: '20px' }}>
        Range Slider Continuous
      </Typography>
      <Slider value={[10, 80]} />
      <Typography variant="body" csx={{ marginBlock: '20px' }}>
        With Min Max
      </Typography>
      <Slider value={[10, 60]} min={30} max={80} />

      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBlock: '20px' }}>
        Custom Slider
      </Typography>
      <Typography csx={{ marginBlock: '20px' }}>
        Show Limits to the sides
      </Typography>
      <CustomSlider
        step={10}
        value={[20, 60]}
        min={0}
        max={100}
        showStepMarks={true}
        showDebugValues={false}
        debounceTimeout={200}
        renderLimits={value => (
          <Typography fontWeight="medium">{value}</Typography>
        )}
      />

      <Typography csx={{ marginBlock: '20px' }}>
        Change thumb color on specific range
      </Typography>

      <Box csx={{ marginBottom: '50PX' }}>
        <CustomSlider
          step={1}
          value={[20, 60]}
          min={0}
          max={100}
          showStepMarks={false}
          bannedRanges={[
            [20, 40],
            [60, 80],
          ]}
          valueLabelDisplay="always"
          labelDirection="bottom"
          customValueLabel={currentVale => {
            return currentVale + ' Kg';
          }}
          debounceTimeout={200}
          onValueChange={value => console.info(value)}
          renderLimits={value => (
            <Typography fontWeight="medium">{value}</Typography>
          )}
        />
      </Box>
    </Card>
  );
};

export default SliderExample;
