import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  areSettingsMasterModeSelector,
  makeSelectSettingsData,
} from '@app/state/selectors/settingsSelectors';
import { checkForSettingsChangesAndNavigateWeb as checkForSettingsChangesAndNavigateWebAction } from '@app/state/settings/settingsWebActions';
import { store, useAppDispatch } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ITableTax,
  ITaxSetting,
  ITaxTypeData,
  ITaxesSettings,
  OrderType,
  SettingsSectionId,
  TAX_SETTING_INITIAL_STATE,
  TaxType,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  formatTax,
  orderTypesText,
  typesText,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
interface ITaxesTable {
  id: number;
  name: string;
  orderTypes: string;
  taxType: TaxType;
  tableTypeData: ITableTax | null;
  typeData: ITaxTypeData | null;
  actions: string;
}

const ROW_ID_PREFIX = 'taxesTable';

const SECTION_ID = SettingsSectionId.TAXES;
const getMemoizedItemData = makeSelectSettingsData<ITaxesSettings>(SECTION_ID);
const columnHelper = createColumnHelper<ITaxesTable>();
const TaxesTable = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const { data } = useSelector(getMemoizedItemData);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'taxes',
  );

  const dispatch = useAppDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );
  const { setSettingsChangeData } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );
  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    checkForSettingsChangesAndNavigateWebAction,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const joinOrderTypes = (orderTypes: string) => {
    const arr = orderTypes.split(',');
    const displayedOrderTypes = arr.map(
      orderType => orderTypesText[orderType as OrderType],
    );
    displayedOrderTypes.sort();
    return displayedOrderTypes.join(', ');
  };

  const deleteRule = (id: number, errorIds: string[]) => {
    errorIds.forEach(errorId => clearSettingsError(errorId));

    const currentData = store.getState().settings.changeData
      .data as ITaxesSettings;
    const clonedTaxes = cloneDeep(currentData);
    delete clonedTaxes[id];
    setValue<ITaxesSettings>(
      {
        ...clonedTaxes,
      },
      SECTION_ID,
      true,
    );
  };

  const navigateToTax = (id: number) => {
    checkForSettingsChangesAndNavigateWeb(
      () => navigate(`/settings/taxes/${id}`),
      () => null,
      navigate,
    );
  };

  const addRule = async () => {
    checkForSettingsChangesAndNavigateWeb(
      async () => {
        const changeData = store.getState().settings.changeData;
        const taxData = changeData.data as ITaxesSettings;
        const originalData = changeData.originalData as ITaxesSettings;

        let maxId = 0;
        for (const [key] of Object.entries(originalData || {})) {
          maxId = Math.max(maxId, Number(key));
        }
        for (const [key] of Object.entries(taxData || {})) {
          maxId = Math.max(maxId, Number(key));
        }

        maxId++;

        const defaultData: ITaxSetting = {
          ...TAX_SETTING_INITIAL_STATE,
          id: maxId,
        };

        navigate('/settings/taxes/add', {
          state: { id: maxId },
        });
        await setSettingsChangeData({
          ...changeData,
          data: {
            ...(changeData.originalData as ITaxesSettings),
            [maxId]: {
              ...defaultData,
            },
          },
          hasChanges: true,
        });
      },
      () => null,
      navigate,
      false,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('settingsModule.taxesSettings.taxesTable.name') as string,
        cell: info => (
          <Typography>{info.getValue() ? info.getValue() : '- - -'}</Typography>
        ),
        minSize: 130,
      }),
      columnHelper.accessor('orderTypes', {
        header: t(
          'settingsModule.taxesSettings.taxesTable.orderTypes',
        ) as string,
        cell: info => (
          <Typography>
            {info.getValue() === null
              ? t('commonTexts.all')
              : joinOrderTypes(info.getValue())}
          </Typography>
        ),
        minSize: 160,
      }),
      columnHelper.accessor('taxType', {
        header: t('settingsModule.taxesSettings.taxesTable.type') as string,
        cell: info => <Typography>{typesText[info.getValue()]}</Typography>,
        minSize: 120,
      }),
      columnHelper.accessor('typeData', {
        header: t('settingsModule.taxesSettings.taxesTable.tax') as string,
        cell: info => (
          <Typography>
            {formatTax(info.row.original.taxType, info.getValue())}
          </Typography>
        ),
        minSize: 130,
      }),
      columnHelper.accessor('actions', {
        header: t(
          'settingsModule.devices_licenses.licensesTable.actions',
        ) as string,
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}>
            <Button
              variant="secondary"
              onClick={() => navigateToTax(info.row.original.id)}
              icon={
                isMasterMode ? (
                  <Icon name="MdEdit" />
                ) : (
                  <Icon name="MdVisibility" />
                )
              }
              csx={{ width: '50px' }}
            />
            <Button
              disabled={!isMasterMode}
              variant="danger"
              csx={{ width: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() => deleteRule(info.row.original.id, [])}
            />
          </Box>
        ),
        size: 110,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  const taxesTableArray = useMemo(
    () =>
      Object.keys(data || {}).map(key => ({
        ...data[key],
        id: Number(key),
        actions: '',
      })),

    [data],
  );

  return (
    <Box>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '0px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title={t('settingsModule.taxesSettings.title')}
          headerCsx={{
            padding: '15px',
          }}
          options={
            isMasterMode ? (
              <Button
                variant="primary"
                icon={<Icon name="MdAdd" />}
                onClick={addRule}
                csx={{ minWidth: '120px' }}>
                {t('settingsModule.taxesSettings.addTax')}
              </Button>
            ) : null
          }
        />
      </m.div>
      {isSet && (
        <Box
          csx={{
            padding: '15px',
          }}>
          <Table
            data={taxesTableArray}
            columns={COLUMN_VALUES}
            cellCsx={{ height: '70px' }}
            alignHeaders={{
              actions: 'center',
            }}
            rowIdPrefix={ROW_ID_PREFIX}
            renderEmptyValues
          />
        </Box>
      )}
    </Box>
  );
};

export default TaxesTable;
