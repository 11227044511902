import Input from '@app/components/common/Input';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IInputCell } from './types';
import { useFormError } from '@westondev/tableturn-core';

// eslint-disable-next-line react-refresh/only-export-components
const InputCell = ({
  errorId,
  onFocusError,
  onFocus,
  onChange,
  value,
  placeholder,
  disabled,
  hasOverrideError,
  isMenuInput,
  ...otherProps
}: IInputCell) => {
  const settingsErrors = useSelector(settingsErrorsSelector);
  const errorMenu = useFormError(errorId as 'name');

  const hasError = isMenuInput
    ? !!errorMenu?.error
    : errorId === undefined
    ? false
    : Boolean(settingsErrors[errorId]);

  return (
    <Input
      disabled={disabled}
      error={hasOverrideError || hasError}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={e => {
        isMenuInput && errorMenu?.error
          ? errorMenu.clear()
          : onFocusError &&
            errorId !== undefined &&
            onFocusError(errorId, hasError);
        onFocus && onFocus(e);
      }}
      {...otherProps}
    />
  );
};

const arePropsEqual = (prevProps: IInputCell, nextProps: IInputCell) =>
  prevProps.value === nextProps.value &&
  prevProps.placeholder === nextProps.placeholder &&
  prevProps.onFocus === nextProps.onFocus &&
  prevProps.disabled === nextProps.disabled &&
  prevProps.errorId === nextProps.errorId;

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(InputCell, arePropsEqual);
