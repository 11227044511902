import { Theme, css } from '@emotion/react';

export const accordionSectionStyles =
  (variant: 'default' | 'danger', noPadding: boolean) => (theme: Theme) =>
    css({
      borderRadius: '8px',
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[1],
      overflow: 'hidden',
      border:
        variant === 'danger'
          ? `1px solid ${theme.colors.persistentSemanticRed}`
          : 'none',
      '.contentContainer': {
        width: '100%',
        height: '0px',
        position: 'relative',
      },
      '.content': {
        height: '100%',
        width: '100%',
        padding: noPadding ? '0px' : '20px',
        paddingTop: noPadding ? '0px' : '15px',
        backgroundColor: theme.colors.white,
      },
      'p.sectionHeaderTitle': {
        color: variant === 'danger' ? 'red' : 'initial',
      },
    });
