import DotsDisabled from '@app/assets/icons/dots-disabled.svg?react';
import Icon from '../..';
import { IIcon } from '../../Icon';
import { useTheme } from '@emotion/react';

const ThreeDots = ({
  size = '40px',
  color = 'persistentDarkerBlue',
  isDisabled = false,
}: IIcon & { isDisabled?: boolean }) => {
  const theme = useTheme();

  return (
    <>
      {isDisabled ? (
        <DotsDisabled
          css={{
            width: size,
            height: size,
            marginTop: '2px',
          }}
          color={theme.colors.lightGrey}
        />
      ) : (
        <Icon name="MdMoreHoriz" size={size} color={color} />
      )}
    </>
  );
};

export default ThreeDots;
