import Box from '@app/components/common/Box';
import RightClickLink from '@app/components/common/RightClickLink';
import Typography from '@app/components/common/Typography';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { itemsTableNameStyles } from '../../../Items/ItemsList/ItemsTable/styles';

interface INameLink {
  name: string;
  path: string;
  status: boolean;
  statusPath: string;
  hideStatus?: boolean;
  addToBreadcrumb?: boolean;
}

const NameLink = ({
  name,
  path,
  status,
  statusPath,
  hideStatus,
  addToBreadcrumb = true,
}: INameLink) => {
  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const pathUrl = getPathWithOrgData(path);
  const navigate = useNavigateWithOrg();
  const handleClick = useCallback(
    () => {
      if (!addToBreadcrumb) {
        navigate(path);
        return;
      }
      addBreadcrumbLocationWeb({
        action: BreadCrumbAction.NAV,
        text: name,
        onPress: () => navigate(path),
        statusPath,
        pathURL: pathUrl,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathUrl, name, statusPath, path],
  );

  return (
    <RightClickLink csx={{ width: '100%' }} url={pathUrl} onClick={handleClick}>
      <Box csx={itemsTableNameStyles(status)} onClick={() => {}}>
        {!hideStatus && <Box className="dot color" />}

        <Typography
          csx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flex: 1,
          }}
          color="semanticBlue">
          {name}
        </Typography>
      </Box>
    </RightClickLink>
  );
};

export default NameLink;
