import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import { selectDiscounts } from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IDiscountTableRow,
  menuDiscountsTableFactoryCore,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const DiscountsTable = ({ filterParams, t }: IMainScreenTable) => {
  const discounts = useSelector(selectDiscounts);

  const onSort = useSort<IDiscountTableRow>('title');

  const discountsArray = menuDiscountsTableFactoryCore(discounts);

  const discountsArrayFiltered = useMemo(
    () =>
      filter<IDiscountTableRow>(
        discountsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, discountsArray],
  );

  const customFilterFn = (row: Row<IDiscountTableRow>) => {
    return (
      row.depth > 0 ||
      discountsArrayFiltered.findIndex(
        discount => discount.id === row.original.id,
      ) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/discounts/${info.row.original.id}`}
            status={info.row.original.isActive}
            statusPath={`selectedMode.currentMenu.discounts.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('applyTo', {
        header: t('app.modals.discountsDiffModal.discountScheme.applyTo'),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
      columnHelper.accessor('discountType', {
        header: t('app.modals.discountsDiffModal.discountScheme.type'),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
      columnHelper.accessor('taxes', {
        header: t(
          'menuScreen.discountDetails.discountScheme.inputs.taxes.label',
        ),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
      columnHelper.accessor('reasonRequired', {
        header: t('app.modals.discountsDiffModal.basic.requireReason'),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={discountsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default DiscountsTable;
