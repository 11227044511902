import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { TCsx } from '@emotion/react';
import { ReactElement } from 'react';

interface ISectionLayer {
  title: string;
  description?: string;
  icon?: ReactElement;
  csx?: TCsx;
}

const SectionLayer = ({ title, description, icon, csx }: ISectionLayer) => {
  return (
    <>
      <Box csx={[displayFlexRowStyles, { gap: '15px' }, csx]}>
        {icon ? (
          icon
        ) : (
          <Icon size="40px" name="MdSubtitlesOff" color="textBlack" />
        )}
        <Box>
          <Typography color="black" variant="subtitle" fontWeight="medium">
            {title}
          </Typography>
          {description && <Typography color="black">{description}</Typography>}
        </Box>
      </Box>
    </>
  );
};

export default SectionLayer;
