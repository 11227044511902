import ImageNotFound from '@app/assets/img/imageNotFound.jpg';
import Box from '@app/components/common/Box';
import Chip from '@app/components/common/Chip';
import Grid from '@app/components/common/Grid';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  currentPrepStationsSelector,
  currentTaxesSettingsSelector,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { RootState, store } from '@app/state/store';
import {
  displayFlexRowCenterStyles,
  displayFlexRowStyles,
} from '@app/theme/commonStyles';
import { TChange } from '@app/types';
import { tagsContainer } from '@app/views/LoggedIn/Settings/Edit/RegisterMode/OpenItemTypes/styles';
import { useTheme } from '@emotion/react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR,
  DEFAULT_SPECIAL_TAG_TEXT_COLOR,
  IAdTableDiff,
  ICoursingDiff,
  IDiscountReasonDiff,
  IModTagDiff,
  IOpenItemTypeDiff,
  IOrderSeatTagsDiff,
  IRegisterSettings,
  ISpecialTagDiff,
  ITimeBasedMenuType,
  diffRegisterModeFactory,
  formatMoney,
  getOrderOrSeatTagString,
  getVoidOrDiscountReasonString,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import some from 'lodash/some';
import { Fragment, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import HeaderTable from '../../common/HeaderTable';
import RenderChange from '../common/RenderChange';
import DefaultMenuTypeRulesTableDiff from './DefaultMenuTypeRulesTableDiff';

const COLOR_FIELDS = ['backgroundColor', 'textColor'];
const COURSING_FIELD = 'coursing';
const AUTO_TRIGGERING_FIELD = 'autoTriggering';

const COURSING_TABLE_FIELDS = [COURSING_FIELD, AUTO_TRIGGERING_FIELD];

const RegisterModeDiffModal = ({ t }: WithTranslation) => {
  const theme = useTheme();

  const differences = useSelector(settingsDifferencesSelector);
  const prepStations = useSelector(currentPrepStationsSelector);
  const taxes = useSelector(currentTaxesSettingsSelector);

  const defaultColor = {
    [COLOR_FIELDS[0]]: theme.colors.semanticBlue,
    [COLOR_FIELDS[1]]: theme.colors.textWhite,
  };

  const changeData = useRootSelector(
    state => state.settings.changeData.data,
  ) as IRegisterSettings;

  const originalData = useRootSelector(
    state => state.settings.selectedMode.currentSettings.register,
  ) as IRegisterSettings;

  const menuTypes = useSelector(
    (state: RootState) => state.menu.selectedMode.currentMenu.menuTypes,
  );

  const formattedDifferences = useMemo(() => {
    if (!differences) return null;
    const diffObject = diffRegisterModeFactory(
      differences,
      store.getState() as unknown as CoreRootState,
    );

    return diffObject;
  }, [differences]);

  const coursingTableChanges =
    formattedDifferences?.tableService.coursing.filter(change =>
      COURSING_TABLE_FIELDS.includes(change.field),
    ) || [];

  const coursingNonTableChanges =
    formattedDifferences?.tableService.coursing.filter(
      change => !COURSING_TABLE_FIELDS.includes(change.field),
    ) || [];

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      [
        'defaultMenuTypeId',
        'qsrSendAndPayActionButtons',
        'defaultRestaurantMode',
        'coursingMode',
        'seatingMode',
        'qsrActionOnPay',
        'prepStationForFireTicketId',
        'ticketSeatBar',
      ].includes(field)
    ) {
      return textValue;
    }

    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;

    if (change.field === 'prepStationForFireTicketId') {
      change.value = -1;
    }

    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={COLOR_FIELDS}
        defaultColor={defaultColor}
      />
    );
  };

  const renderSpecialTagsTable = (specialTags: ISpecialTagDiff[]) => {
    const columnHelper = createColumnHelper<ISpecialTagDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'status',
        header: t(
          'app.modals.registerModeDiffModal.general.specialTags.active',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].active
                    : changeData.specialTags[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('app.modals.registerModeDiffModal.general.specialTags.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].name
                    : changeData.specialTags[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.general.specialTags.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].name
                    : changeData.specialTags[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'abbreviation',
        header: t(
          'app.modals.registerModeDiffModal.general.specialTags.abbreviation',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'abbreviation');
          return (
            <RenderChange
              change={{
                field: 'abbreviation',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].abbreviation
                    : changeData.specialTags[rowData.id].abbreviation,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'color',
        header: t('app.modals.registerModeDiffModal.general.specialTags.color'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'color');
          return (
            <RenderChange
              change={{
                field: 'color',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].color || ''
                    : changeData.specialTags[rowData.id].color || '',
              }}
              colorFields={['color', 'textColor']}
              defaultColor={{
                color: DEFAULT_SPECIAL_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'textColor',
        header: t(
          'app.modals.registerModeDiffModal.general.specialTags.textColor',
        ),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'textColor');
          return (
            <RenderChange
              change={{
                field: 'textColor',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.specialTags[rowData.id].textColor || ''
                    : changeData.specialTags[rowData.id].textColor || '',
              }}
              colorFields={['textColor']}
              defaultColor={{
                textColor: DEFAULT_SPECIAL_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={specialTags}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            status: 'center',
            name: 'center',
            posName: 'center',
            abbreviation: 'center',
            color: 'center',
            textColor: 'center',
          }}
          title={t(
            'settingsModule.registerModeSettings.general.specialTags.title',
          )}
        />
      </Box>
    );
  };

  const renderOrderSeatTagsTable = (orderSeatTags: IOrderSeatTagsDiff[]) => {
    const columnHelper = createColumnHelper<IOrderSeatTagsDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const activeChange = info
            .getValue()
            .find(change => change.field === 'sortPriority');
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={
                (activeChange as TChange) || {
                  field: 'sortPriority',
                  label: '',
                  value:
                    rowData.action === 'remove'
                      ? originalData.orderSeatTags[rowData.id].sortPriority
                      : changeData.orderSeatTags[rowData.id].sortPriority,
                }
              }
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.status',
        ),
        cell: info => {
          const rowData = info.row.original;
          const activeChange = info
            .getValue()
            .find(change => change.field === 'active');
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={
                (activeChange as TChange) || {
                  field: 'active',
                  label: '',
                  value:
                    rowData.action === 'remove'
                      ? originalData.orderSeatTags[rowData.id].active
                      : changeData.orderSeatTags[rowData.id].active,
                }
              }
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'isSeatTag',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.orderOrSeatTag.title',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(
              change =>
                change.field === 'isOrderTag' || change.field === 'isSeatTag',
            );
          return (
            <RenderChange
              change={{
                field: 'isSeatTag',
                label: '',
                value: t(
                  `settingsModule.registerModeSettings.tags.seatOrderTags.orderOrSeatTag.options.${
                    rowData.action === 'remove'
                      ? getOrderOrSeatTagString({
                          isOrderTag:
                            originalData.orderSeatTags[
                              rowData.tabletGeneratedId
                            ].isOrderTag,
                          isSeatTag:
                            originalData.orderSeatTags[
                              rowData.tabletGeneratedId
                            ].isSeatTag,
                        })
                      : getOrderOrSeatTagString({
                          isOrderTag:
                            changeData.orderSeatTags[rowData.tabletGeneratedId]
                              .isOrderTag,
                          isSeatTag:
                            changeData.orderSeatTags[rowData.tabletGeneratedId]
                              .isSeatTag,
                        })
                  }` as ParseKeys,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'type',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.type.title',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'type',
          );

          return (
            <RenderChange
              change={{
                field: 'type',
                label: '',
                value: t(
                  `settingsModule.registerModeSettings.tags.seatOrderTags.type.options.${
                    rowData.action === 'remove'
                      ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                          .type
                      : changeData.orderSeatTags[rowData.tabletGeneratedId].type
                  }` as ParseKeys,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.name',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.orderSeatTags[rowData.tabletGeneratedId].name
                    : changeData.orderSeatTags[rowData.tabletGeneratedId].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.orderSeatTags[rowData.tabletGeneratedId].name
                    : changeData.orderSeatTags[rowData.tabletGeneratedId].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'seatFavorite',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.seatTagFavorite',
        ),
        cell: info => {
          const rowData = info.row.original;

          const activeChange = info
            .getValue()
            .find(change => change.field === 'seatFavorite');
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'seatFavorite');
          return (
            <RenderChange
              change={
                (activeChange as TChange) || {
                  field: 'seatFavorite',
                  label: '',
                  value:
                    rowData.action === 'remove'
                      ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                          .seatTagFavorite
                      : changeData.orderSeatTags[rowData.tabletGeneratedId]
                          .seatTagFavorite,
                }
              }
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'orderTagFavorite',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.orderTagFavorite',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'orderTagFavorite');
          return (
            <RenderChange
              change={{
                field: 'orderTagFavorite',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                        .orderTagFavorite
                    : changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .orderTagFavorite,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'color',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.color',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'color');
          return (
            <RenderChange
              change={{
                field: 'color',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                        .backgroundColor &&
                      typeof originalData.orderSeatTags[
                        rowData.tabletGeneratedId
                      ].backgroundColor === 'string'
                      ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                          .backgroundColor ?? ''
                      : DEFAULT_SPECIAL_TAG_TEXT_COLOR
                    : changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .backgroundColor &&
                      typeof changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .backgroundColor === 'string'
                    ? changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .backgroundColor ?? ''
                    : DEFAULT_SPECIAL_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              colorFields={['color', 'textColor']}
              defaultColor={{
                color: DEFAULT_SPECIAL_TAG_TEXT_COLOR,
              }}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'textColor',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.textColor',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'textColor');
          return (
            <RenderChange
              change={{
                field: 'textColor',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                        .backgroundColor &&
                      typeof originalData.orderSeatTags[
                        rowData.tabletGeneratedId
                      ].textColor === 'string'
                      ? originalData.orderSeatTags[rowData.tabletGeneratedId]
                          .textColor ?? ''
                      : DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR
                    : changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .textColor &&
                      typeof changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .textColor === 'string'
                    ? changeData.orderSeatTags[rowData.tabletGeneratedId]
                        .textColor ?? ''
                    : DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              colorFields={['color', 'textColor']}
              defaultColor={{
                color: DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR,
              }}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={orderSeatTags}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            isSeatTag: 'center',
            type: 'center',
            name: 'center',
            posName: 'center',
            seatFavorite: 'center',
            orderTagFavorite: 'center',
            color: 'center',
            textColor: 'center',
            sortPriority: 'center',
          }}
          title={t(
            'settingsModule.registerModeSettings.tags.seatOrderTags.title',
          )}
        />
      </Box>
    );
  };

  const renderModTagsTable = (orderSeatTags: IModTagDiff[]) => {
    const columnHelper = createColumnHelper<IModTagDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].sortPriority
                    : changeData.modifierTags[rowData.id].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.active',
        ),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].active
                    : changeData.modifierTags[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('app.modals.registerModeDiffModal.general.modifierTags.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].name
                    : changeData.modifierTags[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].posName
                    : changeData.modifierTags[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'checkName',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.checkName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'checkName');
          return (
            <RenderChange
              change={{
                field: 'checkName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].checkName
                    : changeData.modifierTags[rowData.id].checkName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'menuTypeIds',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.menuTypeIds',
        ),
        cell: info => {
          const rowData = info.row.original;

          const menuTypeIds =
            rowData.action === 'remove'
              ? originalData.modifierTags[rowData.id].menuTypeIds
              : changeData.modifierTags[rowData.id].menuTypeIds;

          const menuTypesText = menuTypeIds
            ? menuTypeIds.length
              ? menuTypeIds
                  .map(menuTypeId => menuTypes[menuTypeId].name)
                  .join(', ')
              : t('menuScreen.selectionModal.menuTypes.allMenuTypes')
            : t('menuScreen.selectionModal.menuTypes.allMenuTypes');

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'menuTypeIds');

          return (
            <RenderChange
              change={{
                field: 'menuTypeIds',
                label: '',
                value: menuTypesText,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
              autoHeight
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'tagPosition',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.tagPosition',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'tagPosition');
          return (
            <RenderChange
              change={{
                field: 'tagPosition',
                label: '',
                value: t(
                  `commonTexts.${
                    rowData.action === 'remove'
                      ? originalData.modifierTags[rowData.id].tagPosition
                      : changeData.modifierTags[rowData.id].tagPosition
                  }` as ParseKeys,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'textColor',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.textColor',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'textColor');
          return (
            <RenderChange
              change={{
                field: 'textColor',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].textColor || ''
                    : changeData.modifierTags[rowData.id].textColor || '',
              }}
              colorFields={['textColor']}
              defaultColor={{
                textColor: DEFAULT_ORDER_SEAT_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'backgroundColor',
        header: t(
          'settingsModule.registerModeSettings.tags.seatOrderTags.textColor',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(change => change.field === 'backgroundColor');
          return (
            <RenderChange
              change={{
                field: 'backgroundColor',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.modifierTags[rowData.id].backgroundColor ||
                      ''
                    : changeData.modifierTags[rowData.id].backgroundColor || '',
              }}
              colorFields={['backgroundColor']}
              defaultColor={{
                backgroundColor: DEFAULT_SPECIAL_TAG_TEXT_COLOR,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={orderSeatTags}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            name: 'center',
            posName: 'center',
            tagPosition: 'center',
            sortPriority: 'center',
            checkName: 'center',
            menuTypeIds: 'center',
            textColor: 'center',
            backgroundColor: 'center',
          }}
          title={t('settingsModule.registerModeSettings.general.modTags.title')}
        />
      </Box>
    );
  };
  const renderAdsTable = (orderSeatTags: IAdTableDiff[]) => {
    const columnHelper = createColumnHelper<IAdTableDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.ads[rowData.id].sortPriority
                    : changeData.ads[rowData.id].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.active',
        ),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.ads[rowData.id].active
                    : changeData.ads[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'imageUrl',
        header: t(
          'app.modals.registerModeDiffModal.customerFacingDisplay.adsTable.imageUrl',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'imageUrl');
          return (
            <RenderChange
              change={{
                field: 'imageUrl',
                label: '',
                value: ((rowData.action === 'remove'
                  ? originalData.ads[rowData.id].imageUrl
                  : changeData.ads[rowData.id].imageUrl) ||
                  ImageNotFound) as string,
              }}
              isImage
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'title',
        header: t(
          'app.modals.registerModeDiffModal.customerFacingDisplay.adsTable.title',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'title');
          return (
            <RenderChange
              change={{
                field: 'title',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.ads[rowData.id].title
                    : changeData.ads[rowData.id].title,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'text',
        header: t(
          'app.modals.registerModeDiffModal.customerFacingDisplay.adsTable.text',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'text');
          return (
            <RenderChange
              change={{
                field: 'text',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.ads[rowData.id].text
                    : changeData.ads[rowData.id].text,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'price',
        header: t(
          'app.modals.registerModeDiffModal.customerFacingDisplay.adsTable.price',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'price');
          return (
            <RenderChange
              change={{
                field: 'price',
                label: '',
                value: formatMoney(
                  null,
                  rowData.action === 'remove'
                    ? originalData.ads[rowData.id].price
                    : changeData.ads[rowData.id].price,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={orderSeatTags}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            name: 'center',
            posName: 'center',
            tagPosition: 'center',
            sortPriority: 'center',
            checkName: 'center',
            menuTypeIds: 'center',
          }}
          title={t('settingsModule.registerModeSettings.general.modTags.title')}
        />
      </Box>
    );
  };

  const renderDiscountReasonsTable = (orderSeatTags: IDiscountReasonDiff[]) => {
    const columnHelper = createColumnHelper<IDiscountReasonDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.general.discountReasons.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.discountReasons[rowData.id].sortPriority
                    : changeData.discountReasons[rowData.id].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t(
          'app.modals.registerModeDiffModal.general.discountReasons.active',
        ),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.discountReasons[rowData.id].active
                    : changeData.discountReasons[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'isVoid',
        header: t(
          'settingsModule.registerModeSettings.general.discountReasons.voidOrDiscountReasons.title',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = info
            .getValue()
            .some(
              change =>
                change.field === 'isVoid' || change.field === 'isDiscount',
            );
          return (
            <RenderChange
              change={{
                field: 'isVoid',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? getVoidOrDiscountReasonString({
                        isVoid: originalData.discountReasons[rowData.id].isVoid,
                        isDiscount:
                          originalData.discountReasons[rowData.id].isDiscount,
                      })
                    : getVoidOrDiscountReasonString({
                        isVoid: changeData.discountReasons[rowData.id].isVoid,
                        isDiscount:
                          changeData.discountReasons[rowData.id].isDiscount,
                      }),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t(
          'app.modals.registerModeDiffModal.general.discountReasons.name',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.discountReasons[rowData.id].name
                    : changeData.discountReasons[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.general.discountReasons.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.discountReasons[rowData.id].posName
                    : changeData.discountReasons[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={orderSeatTags}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            name: 'center',
            posName: 'center',
            checkName: 'center',
            sortPriority: 'center',
          }}
          title={t(
            'settingsModule.registerModeSettings.general.discountReasons.title',
          )}
        />
      </Box>
    );
  };

  const renderOpenItemTypesTable = (openItemTypes: IOpenItemTypeDiff[]) => {
    const columnHelper = createColumnHelper<IOpenItemTypeDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.tags.openItemTypes.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.openItemTypes[rowData.id].sortPriority
                    : changeData.openItemTypes[rowData.id].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t('app.modals.registerModeDiffModal.tags.openItemTypes.active'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.openItemTypes[rowData.id].active
                    : changeData.openItemTypes[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('app.modals.registerModeDiffModal.tags.openItemTypes.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.openItemTypes[rowData.id].name
                    : changeData.openItemTypes[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.tags.openItemTypes.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.openItemTypes[rowData.id].posName
                    : changeData.openItemTypes[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'prepStationId',
        header: t(
          'app.modals.registerModeDiffModal.tags.openItemTypes.prepStationId',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(
                  change => change.field === 'prepStationId',
                );
          return (
            <RenderChange
              change={{
                field: 'prepStationId',
                label: '',
                value:
                  prepStations[
                    rowData.action === 'remove'
                      ? originalData.openItemTypes[rowData.id].prepStationId
                      : changeData.openItemTypes[rowData.id].prepStationId
                  ]?.name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'taxIds',
        header: t('app.modals.registerModeDiffModal.tags.openItemTypes.taxIds'),
        minSize: 280,
        cell: info => {
          const rowData = info.row.original;
          const taxIds =
            rowData.action === 'remove'
              ? originalData.openItemTypes[rowData.id].taxIds
              : changeData.openItemTypes[rowData.id].taxIds;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'taxIds') ||
                rowData.taxesDiff.length > 0;

          return taxIds.length === 0 ? (
            <Fragment />
          ) : (
            <Box
              csx={[
                tagsContainer,
                {
                  backgroundColor:
                    rowData.action !== 'remove'
                      ? theme.colors.lighterBlue
                      : hasChanged
                      ? theme.colors.lighterBlue
                      : 'transparent',
                },
              ]}>
              {taxIds.map(taxId => (
                <Chip
                  key={`openItemType-${rowData.id}-taxes-${taxId}`}
                  csx={{
                    backgroundColor: hasChanged
                      ? theme.colors.lighterBlue
                      : 'transparent',
                    border: hasChanged
                      ? `1px solid ${theme.colors.semanticBlue}`
                      : `1px solid ${theme.colors.lightGrey}`,
                    height: '40px',
                  }}
                  textColor={
                    hasChanged ? theme.colors.semanticBlue : theme.colors.black
                  }>
                  {taxes[taxId]?.name}
                </Chip>
              ))}
            </Box>
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={openItemTypes}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            active: 'center',
            name: 'center',
            posName: 'center',
            prepStation: 'center',
            prepStationId: 'center',
            sortPriority: 'center',
            taxIds: 'center',
          }}
          title={t(
            'settingsModule.registerModeSettings.tags.openItemTypes.title',
          )}
        />
      </Box>
    );
  };

  const renderDefaultMenuTypeRulesTable = (
    menuTypeRules: ITimeBasedMenuType,
  ) => {
    return (
      <DefaultMenuTypeRulesTableDiff
        menuTypeRules={menuTypeRules}
        originalData={originalData}
        changeData={changeData}
        formattedDifferences={formattedDifferences}
      />
    );
  };

  const renderCoursingTable = (
    courses: ICoursingDiff[],
    coursingDiff: TChange[],
  ) => {
    const coursingHasChanged = coursingDiff.some(
      change => change.field === 'coursing',
    );
    const autoTriggeringHasChanged = coursingDiff.some(
      change => change.field === 'autoTriggering',
    );

    const columnHelper = createColumnHelper<IDiscountReasonDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.sortPriority',
        ),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].sortPriority
                    : changeData.courses[rowData.id].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.active',
        ),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].active
                    : changeData.courses[rowData.id].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.name',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].name
                    : changeData.courses[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.posName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].posName
                    : changeData.courses[rowData.id].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'kitchenName',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.kitchenName',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'kitchenName');
          return (
            <RenderChange
              change={{
                field: 'kitchenName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].kitchenName
                    : changeData.courses[rowData.id].kitchenName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'triggerTime',
        header: t(
          'app.modals.registerModeDiffModal.tableService.coursing.triggerTime',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'triggerTime');
          return (
            <RenderChange
              change={{
                field: 'triggerTime',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.courses[rowData.id].triggerTime
                    : changeData.courses[rowData.id].triggerTime,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ marginTop: '15px' }}>
        <Box
          csx={[
            displayFlexRowStyles,
            {
              border: '1px solid lightGrey',
              gap: '10px',
              paddingInline: '10px',
            },
          ]}>
          <Box csx={[displayFlexRowStyles]}>
            <Typography fontWeight="medium">
              {t(
                'app.modals.registerModeDiffModal.tableService.coursing.coursing',
              )}
              :
            </Typography>
            <Box csx={{ width: '130px' }}>
              <RenderChange
                change={{
                  field: 'coursing',
                  label: '',
                  value: changeData.coursing
                    ? t('commonTexts.active')
                    : t('commonTexts.inactive'),
                }}
                formatValue={formatValue}
                hasChanged={changeData.coursing ? coursingHasChanged : false}
              />
            </Box>
          </Box>
          <Box csx={displayFlexRowCenterStyles}>
            <Typography fontWeight="medium">
              {t(
                'app.modals.registerModeDiffModal.tableService.coursing.autoTriggering',
              )}
              :
            </Typography>
            <Box csx={{ width: '130px' }}>
              <RenderChange
                change={{
                  field: 'coursing',
                  label: '',
                  value: changeData.autoTriggering
                    ? t('commonTexts.active')
                    : t('commonTexts.inactive'),
                }}
                formatValue={formatValue}
                hasChanged={
                  changeData.autoTriggering ? autoTriggeringHasChanged : false
                }
              />
            </Box>
          </Box>
        </Box>
        {courses.length > 0 && (
          <Box csx={{ paddingBlock: '10px' }}>
            <HeaderTable
              title={t(
                'app.modals.registerModeDiffModal.tableService.coursing.coursing',
              )}
            />
            <Table
              columns={defaultColumns}
              data={courses}
              cellCsx={{ height: '70px' }}
              showShadow={false}
              alignHeaders={{
                active: 'center',
                name: 'center',
                posName: 'center',
                tagPosition: 'center',
                sortPriority: 'center',
                triggerTime: 'center',
                kitchenName: 'center',
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {some(formattedDifferences.general, (item, key) => {
            if (key === 'defaultMenuTypeTimes') {
              return some(
                item as ITimeBasedMenuType,
                menuType => menuType.length > 0,
              );
            }
            return Array.isArray(item) && item.length > 0;
          }) && (
            <>
              {/* TODO: add translations to header separators */}
              <HeaderDiffModal name="GENERAL SETTINGS" color="black" />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.general.registerDisplay.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`registerModeSettings_general_registerDisplay_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
                {formattedDifferences.general.orderTypesAvailable.map(
                  change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`registerModeSettings_general_orderTypesAvailable_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ),
                )}
                {formattedDifferences.general.timeBasedMenuType.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`registerModeSettings_general_timeBasedMenuType_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
              {some(
                formattedDifferences.general.defaultMenuTypeTimes,
                days => days.length > 0,
              ) &&
                renderDefaultMenuTypeRulesTable(
                  formattedDifferences.general.defaultMenuTypeTimes,
                )}
            </>
          )}
          {some(formattedDifferences.tags, item => item.length > 0) && (
            <>
              <HeaderDiffModal name="TAGS SETTINGS" color="black" />
              {formattedDifferences.tags.specialTags.length > 0 &&
                renderSpecialTagsTable(formattedDifferences.tags.specialTags)}
            </>
          )}
          {formattedDifferences.tags.orderSeatTags.length > 0 &&
            renderOrderSeatTagsTable(formattedDifferences.tags.orderSeatTags)}
          {formattedDifferences.tags.modTags.length > 0 &&
            renderModTagsTable(formattedDifferences.tags.modTags)}
          {formattedDifferences.tags.discountReasons.length > 0 &&
            renderDiscountReasonsTable(
              formattedDifferences.tags.discountReasons,
            )}
          {formattedDifferences.tags.openItemTypes.length > 0 &&
            renderOpenItemTypesTable(formattedDifferences.tags.openItemTypes)}
          {some(formattedDifferences.tableService, item => item.length > 0) && (
            <>
              <HeaderDiffModal name="TABLE SERVICE SETTINGS" color="black" />
              <Box csx={{ display: 'flex', flexWrap: 'wrap' }}>
                {formattedDifferences.tableService.general.length > 0 && (
                  <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    {formattedDifferences.tableService.general.map(change => (
                      <Box
                        key={`registerModeSettings_tableService_general_${change.field}`}>
                        {renderChange(change)}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              {coursingNonTableChanges.length > 0 && (
                <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                  {coursingNonTableChanges.map(change => (
                    <Box
                      key={`registerModeSettings_tableService_coursingNonTable_${change.field}`}>
                      {renderChange(change)}
                    </Box>
                  ))}
                </Box>
              )}
              {(coursingTableChanges.length > 0 ||
                formattedDifferences.tableService.coursingTable.length > 0) &&
                renderCoursingTable(
                  formattedDifferences.tableService.coursingTable,
                  coursingTableChanges,
                )}
              {formattedDifferences.tableService.tableLayout.length > 0 && (
                <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                  {formattedDifferences.tableService.tableLayout.map(change => (
                    <Box
                      key={`registerModeSettings_tableService_tableLayout_${change.field}`}>
                      {renderChange(change)}
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
          {some(formattedDifferences.quickService, item => item.length > 0) && (
            <>
              <HeaderDiffModal name="QUICK SERVICE SETTINGS" color="black" />
              <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                {formattedDifferences.quickService.general.map(change => (
                  <Box
                    key={`registerModeSettings_quickService_general_${change.field}`}>
                    {renderChange(change)}
                  </Box>
                ))}
              </Box>
            </>
          )}
          {some(
            formattedDifferences.customerFacingDisplay,
            item => item.length > 0,
          ) && (
            <>
              <HeaderDiffModal
                name={t(
                  'settingsModule.registerModeSettings.customerFacingDisplay.title',
                )}
                color="black"
              />

              {formattedDifferences.customerFacingDisplay.adsTable.length > 0 &&
                renderAdsTable(
                  formattedDifferences.customerFacingDisplay.adsTable,
                )}
              <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                {formattedDifferences.customerFacingDisplay.general.map(
                  change => (
                    <Box
                      key={`registerModeSettings_customerFacingDisplay_general_${change.field}`}>
                      {renderChange(change)}
                    </Box>
                  ),
                )}
                {formattedDifferences.customerFacingDisplay.ads.map(change => (
                  <Box
                    key={`registerModeSettings_customerFacingDisplay_ads_${change.field}`}>
                    {renderChange(change)}
                  </Box>
                ))}
                {formattedDifferences.customerFacingDisplay.tips.map(change => (
                  <Box
                    key={`registerModeSettings_customerFacingDisplay_tips_${change.field}`}>
                    {renderChange(change)}
                  </Box>
                ))}
              </Box>
            </>
          )}

          {some(formattedDifferences.endOfDay, item => item.length > 0) && (
            <>
              <HeaderDiffModal name="END OF DAY SETTINGS" color="black" />
              <Box csx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                {formattedDifferences.endOfDay.clockOut.map(change => (
                  <Box
                    key={`registerModeSettings_endOfDay_clockOut_${change.field}`}>
                    {renderChange(change)}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default RegisterModeDiffModal;
