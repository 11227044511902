import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue } from '@app/helpers/modals/diffModal';
import { selectChangeDataDifferences } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  diffReportingGroupFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';

const ReportingGroupsDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(selectChangeDataDifferences);

  const formattedDifferences = useMemo(() => {
    return diffReportingGroupFactory(
      differences,
      store.getState() as CoreRootState & RootState,
    );
  }, [differences]);

  const renderChange = (change: TChange, idx = 0) => {
    if (!change.field) return null;
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        key={`${change.field}_${change.id}_${idx}`}
      />
    );
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.associations.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.associations')}
              />
              {formattedDifferences.associations.map(renderChange)}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ReportingGroupsDiffModal;
