import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const tableLayoutSidebarStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    height: '100%',
    right: 0,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    zIndex: 1,
    [MQ_MIN_SMALL]: {
      width: '260px',
    },
  });

export const tableLayoutHeaderStyles = (theme: Theme) =>
  css({
    display: 'flex',
    minHeight: '55px',
    alignItems: 'center',
    paddingInline: '15px',
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  });

export const tableLayoutContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
});
