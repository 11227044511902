import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import { actionCreatorsApp, actionCreatorsTableLayoutWeb } from '@app/state';
import { selectRevenueCenters } from '@app/state/selectors/tableLayoutSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DATA_INITIAL_STATE,
  IData,
  TAddOrUpdateServiceAreaModal,
} from './types';
import { actionCreatorsTableLayout } from '@westondev/tableturn-core';

const AddOrUpdateServiceAreaModal = ({
  t,
  mode = 'add',
  showAddLabel = false,
  serviceArea,
  setServiceArea,
  onAddServiceArea,
}: TAddOrUpdateServiceAreaModal) => {
  // Redux
  const dispatch = useDispatch();
  const { updateServiceAreaDetails, removeServiceAreAndResetState } =
    bindActionCreators(actionCreatorsTableLayout, dispatch);
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const revenueCenters = useSelector(selectRevenueCenters);

  const revenueCentersList = useMemo(() => {
    return Object.values(revenueCenters).map(revenueCenter => ({
      label: revenueCenter.name,
      value: revenueCenter.id,
    }));
  }, [revenueCenters]);

  const addServiceAreaWeb = bindActionCreators(
    actionCreatorsTableLayoutWeb.addServiceAreaWeb,
    useDispatch(),
  );

  // Local state
  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useState<IData>(DATA_INITIAL_STATE);

  const isAddMode = mode === 'add';

  useEffect(() => {
    if (mode === 'update' && serviceArea) {
      setData(serviceArea);
      setShowModal(true);
    } else setData(DATA_INITIAL_STATE);
  }, [mode, serviceArea]);

  const handleChangeStatus = (status: boolean) => {
    setData(prev => ({ ...prev, isActive: status }));
  };

  const handleOnCancel = () => {
    setData(DATA_INITIAL_STATE);
    setShowModal(false);
  };

  const handleShowAddingMode = () => {
    setShowModal(true);
  };

  const handleCreateServiceArea = () => {
    addServiceAreaWeb(
      {
        name: data.name,
        revenueCenterId: data.revenueCenterId,
        isActive: data.isActive,
      },
      () => {
        setShowModal(false);
      },
    );
    setData(DATA_INITIAL_STATE);
    onAddServiceArea!();
  };

  const handleUpdateServiceArea = () => {
    if (!data.id) return;
    updateServiceAreaDetails(
      {
        serviceAreaId: data.id,
        serviceAreaName: data.name,
        revenueCenterId: data.revenueCenterId,
        isActive: data.isActive,
      },
      () => {
        setServiceArea!(null);
      },
    );
    setShowModal(false);
  };

  const handleRemoveServiceArea = () => {
    if (!data.id) return;
    removeServiceAreAndResetState(data.id);
    setServiceArea!(null);
    setShowModal(false);
  };

  const handleOnClickDelete = () => {
    setShowConfirmationModal({
      title: t('settingsModule.tableLayoutCreator.serviceArea'),
      active: true,
      name: data.name,
      type: 'delete',
      onSuccess: () => handleRemoveServiceArea(),
      runCancelOnClickOutside: true,
    });
  };

  return (
    <>
      {isAddMode && (
        <Button
          variant="primary"
          csx={{ minWidth: '50px !important' }}
          icon={<Icon name="MdAddCircle" />}
          onClick={handleShowAddingMode}>
          {showAddLabel
            ? t('settingsModule.tableLayoutCreator.addServiceArea')
            : undefined}
        </Button>
      )}
      <Modal
        isActive={showModal}
        size={500}
        title={
          isAddMode
            ? t('settingsModule.tableLayoutCreator.addServiceArea')
            : t('settingsModule.tableLayoutCreator.updateServiceArea')
        }
        onModalClose={handleOnCancel}
        footer={
          <>
            <Button csx={{ minWidth: '100px' }} onClick={handleOnCancel}>
              {t('commonButtons.cancel')}
            </Button>
            <Button
              disabled={
                data.name.length > 0 && data.revenueCenterId !== 0
                  ? false
                  : true
              }
              variant="primary"
              csx={{ minWidth: '120px' }}
              onClick={
                isAddMode ? handleCreateServiceArea : handleUpdateServiceArea
              }
              icon={<Icon name="MdSave" />}>
              {t('commonButtons.save')}
            </Button>
          </>
        }>
        <Grid rowGap={15}>
          <Grid.Item>
            <Input
              value={data.name}
              label={t('settingsModule.tableLayoutCreator.modals.name')}
              placeholder={t('settingsModule.tableLayoutCreator.modals.name')}
              onChange={({ currentTarget: { value: name } }) => {
                setData(prev => ({ ...prev, name }));
              }}
            />
          </Grid.Item>
          <Grid.Item>
            <Dropdown
              isDisabled={revenueCentersList.length === 0}
              placeholder={
                revenueCentersList.length > 0
                  ? t(
                      'settingsModule.tableLayoutCreator.modals.selectRevenueCenter',
                    )
                  : t(
                      'settingsModule.tableLayoutCreator.modals.noRevenueCenters',
                    )
              }
              label={t(
                'settingsModule.tableLayoutCreator.modals.revenueCenter',
              )}
              required
              data={[revenueCentersList]}
              onChange={revenueCenterId => {
                setData(prev => ({ ...prev, revenueCenterId }));
              }}
              value={data.revenueCenterId}
            />
          </Grid.Item>
          <Grid.Item>
            <Checkbox
              checked={data.isActive}
              label={t(
                'settingsModule.tableLayoutCreator.modals.isActiveInRegister',
              )}
              onChange={handleChangeStatus}
            />
          </Grid.Item>
          {!isAddMode && (
            <Grid.Item>
              <Button
                variant="danger"
                icon={<Icon name="MdDeleteForever" />}
                onClick={handleOnClickDelete}>
                {t(
                  'settingsModule.tableLayoutCreator.modals.removeServiceArea',
                )}
              </Button>
            </Grid.Item>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default AddOrUpdateServiceAreaModal;
