import { actionCreatorsApp } from '@app/state';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '@app/state/store';
import Checkbox from '@app/components/common/Checkbox';
import { WithTranslation } from 'react-i18next';
import {
  ElementTypes,
  IElement,
  ISelectedElement,
  Shapes,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { getNewPositionWithRotation } from '@app/helpers/settings/tableLayoutCreator';
import Typography from '@app/components/common/Typography';
import { DEFAULT_ELEMENT_SIZES } from '../../TableLayoutCreator/types';
import Slider from '@app/components/common/Slider';
import Grid from '@app/components/common/Grid';
import Divider from '@app/components/common/Divider';
import RotationInput from './RotationInput';
import { useCallback, useMemo } from 'react';

interface ISizeOptions extends WithTranslation {
  selectedElement: ISelectedElement;
  elementData: IElement;
}
const SizeOptions = ({ t, elementData, selectedElement }: ISizeOptions) => {
  // Redux
  const { gridWidth, gridHeight } = useSelector(
    (state: RootState) => state.tableLayout.toolSettings,
    shallowEqual,
  );

  const showToast = bindActionCreators(
    actionCreatorsApp.showToast,
    useDispatch(),
  );

  const { updateElementAndSaveLastChange, setSelectedElement } =
    bindActionCreators(actionCreatorsTableLayout, useDispatch());

  // Local state
  const handleUpdateElementData = (values: IElement) => {
    updateElementAndSaveLastChange(elementData, values);
  };

  const handleShowControls = () => {
    setSelectedElement({
      ...selectedElement,
      showControls: !selectedElement.showControls,
    });
  };

  const getElementDefaultSizes = useCallback((): {
    elementWidth: number;
    defaultWidth: number;
    defaultHeight: number;
    growXSize: number;
    growYSize: number;
    maxSize: number;
    steps: number;
  } => {
    let elementWidth = 0;
    let defaultWidth = 0;
    let defaultHeight = 0;
    let growXSize = 0;
    let growYSize = 0;
    let maxSize = 0;
    let steps = 5;

    if (
      elementData.type === ElementTypes.WALL ||
      elementData.type === ElementTypes.CUSTOM
    ) {
      growXSize = DEFAULT_ELEMENT_SIZES[elementData.type].growXSize;
      growYSize = DEFAULT_ELEMENT_SIZES[elementData.type].growYSize;
      elementWidth =
        elementData.width -
        (DEFAULT_ELEMENT_SIZES[elementData.type].width - growXSize);
      defaultWidth = DEFAULT_ELEMENT_SIZES[elementData.type].width;
      defaultHeight = DEFAULT_ELEMENT_SIZES[elementData.type].height;
      maxSize = DEFAULT_ELEMENT_SIZES[elementData.type].maxSize;
      steps = 5;
    } else if (
      elementData.type === ElementTypes.DOOR ||
      elementData.type === ElementTypes.PLANT ||
      elementData.type === ElementTypes.STATION ||
      elementData.type === ElementTypes.WINDOW
    ) {
      growXSize = DEFAULT_ELEMENT_SIZES.object.growXSize;
      growYSize = DEFAULT_ELEMENT_SIZES.object.growYSize;
      elementWidth = elementData.width;
      defaultWidth = DEFAULT_ELEMENT_SIZES.object.width;
      defaultHeight = DEFAULT_ELEMENT_SIZES.object.height;
      maxSize = DEFAULT_ELEMENT_SIZES.object.maxSize;
      steps = 2;
    } else {
      if (elementData.shape === Shapes.RECTANGLE) {
        growXSize = DEFAULT_ELEMENT_SIZES['table-rectangle'].growXSize;
        growYSize = DEFAULT_ELEMENT_SIZES['table-rectangle'].growYSize;
        elementWidth = elementData.width;
        defaultWidth = DEFAULT_ELEMENT_SIZES['table-rectangle'].width;
        defaultHeight = DEFAULT_ELEMENT_SIZES['table-rectangle'].height;
        maxSize = DEFAULT_ELEMENT_SIZES['table-rectangle'].maxSize;
      } else {
        growXSize = DEFAULT_ELEMENT_SIZES[elementData.type].growXSize;
        growYSize = DEFAULT_ELEMENT_SIZES[elementData.type].growYSize;
        elementWidth = elementData.width;
        defaultWidth = DEFAULT_ELEMENT_SIZES.table.width;
        defaultHeight = DEFAULT_ELEMENT_SIZES.table.height;
        maxSize = DEFAULT_ELEMENT_SIZES.table.maxSize;
      }
      steps = 3;
    }
    return {
      elementWidth,
      defaultWidth,
      defaultHeight,
      growXSize,
      growYSize,
      maxSize,
      steps,
    };
  }, [elementData.shape, elementData.type, elementData.width]);

  const {
    elementWidth,
    defaultWidth,
    defaultHeight,
    growXSize,
    growYSize,
    steps,
  } = getElementDefaultSizes();

  const getNewWidthValue = (value: number) => {
    return Math.ceil(Math.abs((value * steps) / 100)) + 1;
  };

  const currentValue = useMemo(() => {
    return Math.ceil(Math.abs(elementWidth / defaultWidth - 1)) * (100 / steps);
  }, [defaultWidth, elementWidth, steps]);

  const handleOnChangeSize = (value: number) => {
    const newWidth =
      defaultWidth + getNewWidthValue(value) * growXSize - growXSize;
    const newHeight =
      defaultHeight + getNewWidthValue(value) * growYSize - growYSize;

    const spaceLeftX = gridWidth - elementData.xCoordinate;
    const spaceLeftY = gridHeight - elementData.yCoordinate;

    let newElement: IElement;

    if (newWidth !== elementData.width || newHeight !== elementData.height) {
      if (newHeight <= spaceLeftY && newWidth <= spaceLeftX) {
        newElement = {
          ...elementData,
          width: newWidth,
          height: newHeight,
        };
      } else {
        if (newHeight > spaceLeftY && newWidth > spaceLeftX) {
          newElement = {
            ...elementData,
            width: newWidth,
            height: newHeight,
            yCoordinate: elementData.yCoordinate - (newHeight - spaceLeftY),
            xCoordinate: elementData.xCoordinate - (newWidth - spaceLeftX),
          };
        } else if (newHeight > spaceLeftY) {
          newElement = {
            ...elementData,
            width: newWidth,
            height: newHeight,
            yCoordinate: elementData.yCoordinate - (newHeight - spaceLeftY),
          };
        } else {
          newElement = {
            ...elementData,
            width: newWidth,
            height: newHeight,
            xCoordinate: elementData.xCoordinate - (newWidth - spaceLeftX),
          };
        }
      }

      const values = getNewPositionWithRotation(
        newElement,
        newElement.rotation,
        true,
      );

      if (!values) {
        showToast({
          title:
            'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
          description:
            'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
          isActive: true,
          type: 'error',
        });
        return;
      }

      if (values.xCoordinate && values.yCoordinate) {
        newElement.xCoordinate = values.xCoordinate as number;
        newElement.yCoordinate = values.yCoordinate as number;
      }

      handleUpdateElementData(newElement);
    }
  };

  return (
    <>
      {(elementData.type === ElementTypes.CUSTOM ||
        elementData.type === ElementTypes.TABLE) && (
        <Grid.Item>
          <Divider />
        </Grid.Item>
      )}

      <Grid.Item>
        <Typography fontWeight="medium">
          {t('settingsModule.tableLayoutCreator.sideBar.geometryOptions')}
        </Typography>
      </Grid.Item>
      {elementData.type === ElementTypes.CUSTOM && (
        <Grid.Item>
          <Checkbox
            label="Enable Free Form"
            checked={selectedElement.showControls}
            onChange={handleShowControls}
          />
        </Grid.Item>
      )}
      {elementData.type !== ElementTypes.CUSTOM && (
        <Grid.Item csx={{ marginBottom: '5px' }}>
          <Typography csx={{ marginBottom: '15px' }} fontWeight="medium">
            {t('settingsModule.tableLayoutCreator.sideBar.size')}
          </Typography>
          <Slider
            key="tlc-slider"
            step={100 / steps}
            value={currentValue}
            min={0}
            max={100}
            onValueChange={value => handleOnChangeSize(value as number)}
          />
        </Grid.Item>
      )}
      {elementData.shape !== Shapes.CIRCLE && (
        <RotationInput elementData={elementData} />
      )}
    </>
  );
};

export default SizeOptions;
