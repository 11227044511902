import {
  MotionValue,
  m,
  useMotionValue,
  useTransform,
  useMotionValueEvent,
  animate,
} from 'framer-motion';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { labelStyles, thumbStyles, trackStyles } from './styles';
import { useTheme } from '@emotion/react';
import Typography from '../Typography';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import Box from '../Box';
import { indexSliderHasErrorValue } from '@app/helpers/settings/register';

const THUMB_SIZE = 24;
const TRACK_HEIGHT = 8;

export interface ISlider {
  value?: number | Array<number>;
  width?: number;
  min?: number;
  max?: number;
  valueLabelDisplay?: 'always' | 'hover' | 'none';
  labelDirection?: 'top' | 'bottom';
  step?: number;
  showStepMarks?: boolean;
  showDebugValues?: boolean;
  bannedThumbColor?: string;
  trackHitSlop?: number;
  thumbHitSlop?: number;
  debounceTimeout?: number | null;
  onValueChange?: (value: number | number[]) => void;
  customValueLabel?: (value: number) => React.ReactNode;
  bannedRanges?: Array<[number, number]>;
  isDisabled?: boolean;
}

const Slider = (props: ISlider) => {
  const {
    value = 0,
    width = 0,
    onValueChange,
    min = 0,
    max = 100,
    showStepMarks = true,
    showDebugValues = false,
    trackHitSlop = 8,
    thumbHitSlop = 8,
    debounceTimeout = null,
    bannedRanges,
    isDisabled,
  } = props;
  const step = props.step ? props.step : 0;

  const theme = useTheme();
  const [localValues, setLocalValues] = useState<number | number[]>(
    value || min,
  );
  const [trackRect, setTrackRect] = useState<DOMRect | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const isResizingRef = useRef<NodeJS.Timeout | null>(null);
  const xPositions = useMotionValue<number[]>([0]);
  const labelPositions = useMotionValue<number[]>([-1]);
  const isPanning = useMotionValue(false);
  const isResizing = useMotionValue(false);

  const [realSliderWidth, setRealSliderWidth] = useState(width || 0);
  const [, setBackgroundValue] = useState<
    | {
        outsideGradient: string;
        insideGradient?: undefined;
      }
    | {
        outsideGradient: string;
        insideGradient: string;
      }
    | undefined
  >();

  useEffect(() => {
    if (value !== undefined) {
      setLocalValues(value);
    }
  }, [value]);

  const handleGetBackground = () => {
    const xPositionsArray = xPositions.get();

    if (!trackRef.current) return;
    if (xPositionsArray.length === 1) {
      const firstValue = handleGetPercentage(
        xPositionsArray[0],
        [0, realSliderWidth],
        [min, max],
      );
      const newPercentage =
        firstValue * (100 / (max - min)) - min * (100 / (max - min));

      return {
        outsideGradient: `linear-gradient(90deg, ${theme.colors.semanticBlue} ${newPercentage}%, ${theme.colors.lightestGrey} ${newPercentage}%)`,
      };
    } else {
      const lastValue = xPositionsArray[xPositionsArray.length - 1];

      const firstValuePercentage = handleGetPercentage(
        xPositionsArray[0],
        [0, realSliderWidth],
        [min, max],
      );

      const newFirstValuePercentage =
        firstValuePercentage * (100 / (max - min)) - min * (100 / (max - min));
      const lastValuePercentage = handleGetPercentage(
        lastValue,
        [0, realSliderWidth],
        [min, max],
      );
      const newLastValuePercentage =
        lastValuePercentage * (100 / (max - min)) - min * (100 / (max - min));

      if (!bannedRanges || bannedRanges.length === 0)
        return {
          outsideGradient: `linear-gradient(90deg, ${theme.colors.lightestGrey} ${newFirstValuePercentage}%, ${theme.colors.semanticBlue} ${newFirstValuePercentage}%, ${theme.colors.semanticBlue} ${newLastValuePercentage}%, ${theme.colors.lightestGrey} ${newLastValuePercentage}%)`,
        };
      // calculate colors for banned ranges
      const bannedTracks = bannedRanges.map(range => {
        const [start, end] = range;
        const startPercentage = handleGetPercentage(
          Math.max(0, Math.round((start * realSliderWidth) / max)),
          [0, realSliderWidth],
          [min, max],
        );
        const endPercentage = handleGetPercentage(
          Math.max(0, Math.round((end * realSliderWidth) / max)),
          [0, realSliderWidth],
          [min, max],
        );

        const newStartPercentage =
          startPercentage * (100 / (max - min)) - min * (100 / (max - min));
        const newEndPercentage =
          endPercentage * (100 / (max - min)) - min * (100 / (max - min));

        return `${theme.colors.semanticBlue} ${newStartPercentage}%, ${theme.colors.semanticRed} ${newStartPercentage}%, ${theme.colors.semanticRed} ${newEndPercentage}%, ${theme.colors.semanticBlue} ${newEndPercentage}%`;
      });

      const bannedRangesGradient = bannedTracks.join(', ');
      const outsideGradient = `linear-gradient(90deg, 
        ${theme.colors.lightestGrey} ${newFirstValuePercentage}%, 
        transparent ${newFirstValuePercentage}%,
        transparent ${newLastValuePercentage}%, 
        ${theme.colors.lightestGrey} ${newLastValuePercentage}%)`;
      const insideGradient = `linear-gradient(90deg, ${bannedRangesGradient})`;
      return { outsideGradient, insideGradient };
    }
  };

  useEffect(() => {
    if (value === undefined) return;

    (isArray(value) ? value : [value]).map((_value, index) => {
      const thumbPosition = Math.max(
        0,
        Math.round((_value * realSliderWidth) / max),
      );
      const newThumbPosition = _value !== min ? thumbPosition : 0;

      const newValues = [...xPositions.get()];
      newValues[index] = newThumbPosition;
      xPositions.set(newValues);
    });
    setBackgroundValue(handleGetBackground());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, min, realSliderWidth, value]);

  const background = useTransform(() => {
    return handleGetBackground();
  });

  useEffect(() => {
    const handleUpdateXPositionsOnResize = (newSliderSize: number) => {
      const xPositionsArray = xPositions.get();
      const newValues = [...xPositionsArray];

      for (let i = 0; i < xPositionsArray.length; i++) {
        const newThumbPosition =
          (xPositionsArray[i] / realSliderWidth) * newSliderSize;
        newValues[i] = newThumbPosition;
      }
      xPositions.set(newValues);
    };

    const handleUpdateValue = () => {
      isResizing.set(true);
      if (trackRef.current) {
        const newTrackRect = trackRef.current.getBoundingClientRect();
        setTrackRect(newTrackRect);
      }
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newSliderSize = containerRect.width - THUMB_SIZE;
        setRealSliderWidth(newSliderSize);
        handleUpdateXPositionsOnResize(newSliderSize);
      }
      isResizingRef.current && clearTimeout(isResizingRef.current);
      isResizingRef.current = setTimeout(() => {
        isResizing.set(false);
      }, 100);
    };

    window.addEventListener('resize', handleUpdateValue);

    return () => window.removeEventListener('resize', handleUpdateValue);
  }, [isResizing, realSliderWidth, xPositions]);

  useLayoutEffect(() => {
    if (trackRef.current && !trackRect) {
      const initialTrackRect = trackRef.current.getBoundingClientRect();
      setTrackRect(initialTrackRect);
    }
  }, [trackRect]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setRealSliderWidth(containerRect.width - THUMB_SIZE);
    }
  }, []);

  const updateThumbByClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!trackRect) return;

    const newThumbPosition = event.clientX - trackRect.x;
    if (step > 0) {
      const newSnappedValue = handleCalculateSnappedValue(
        newThumbPosition,
        step,
        realSliderWidth,
        max,
      );
      xPositions.set([newSnappedValue.xValue]);
      setLocalValues(newSnappedValue.stepValue);
      handleUpdateFinalValue(newSnappedValue.stepValue);
    } else {
      const newXValue = Math.max(0, Math.round(newThumbPosition));
      xPositions.set([newXValue]);
      const newValue = handleGetPercentage(
        newXValue,
        [0, realSliderWidth],
        [min, max],
      );
      setLocalValues(newValue);
      handleUpdateFinalValue(newValue);
    }
  };

  const handleTrackPan = async (event: PointerEvent) => {
    if (!trackRect) return;

    const xPositionsArray = xPositions.get();
    const newThumbPosition = event.clientX - trackRect.x;
    if (newThumbPosition > realSliderWidth) return;
    const newXValue = Math.max(0, Math.round(newThumbPosition));
    const newSnappedValue = handleCalculateSnappedValue(
      newXValue,
      step,
      realSliderWidth,
      max,
    );

    if (xPositionsArray[0] !== newSnappedValue.xValue) {
      isPanning.set(true);

      if (isArray(value)) {
        const closestThumbX = xPositionsArray.reduce((prev, currentValue) =>
          Math.abs(currentValue - newSnappedValue.xValue) <
          Math.abs(prev - newSnappedValue.xValue)
            ? currentValue
            : prev,
        );
        const closestThumbId = xPositionsArray.indexOf(closestThumbX);

        const newValues = [...xPositionsArray];
        newValues[closestThumbId] = newSnappedValue.xValue;
        xPositions.set(newValues);

        const newValue = [...(localValues as number[])];
        newValue[closestThumbId] = Math.round(newSnappedValue.stepValue);
        handleUpdateFinalValue(newValue);
        setLocalValues(newValue);
      } else {
        xPositions.set([newSnappedValue.xValue]);
        handleUpdateFinalValue(newSnappedValue.stepValue);
        setLocalValues(newSnappedValue.stepValue);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((newValue: number | number[]) => {
      onValueChange && onValueChange(newValue);
    }, debounceTimeout || undefined),
    [value],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnAction = useCallback(
    debounce((newValue: number | number[]) => {
      handleUpdateFinalValue(newValue);
    }, undefined),
    [value],
  );

  const handleUpdateFinalValue = (newValue: number | number[]) => {
    if (isPanning.get() || isResizing.get()) return debounceOnAction(newValue);

    if (debounceTimeout !== null && debounceTimeout !== undefined) {
      debounceOnChange(newValue);
    } else onValueChange && onValueChange(newValue);
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        position: 'relative',
      }}>
      <span
        onMouseDown={
          isDisabled
            ? undefined
            : isArray(value)
            ? undefined
            : updateThumbByClick
        }
        css={[
          trackStyles,
          {
            left: THUMB_SIZE / 2,
            width: `calc(100% - ${THUMB_SIZE}px)`,
            height: `${TRACK_HEIGHT + trackHitSlop}px`,
            position: 'absolute',
            top: -(trackHitSlop / 2),
            backgroundColor: 'transparent',
          },
        ]}
      />
      {background.get()?.insideGradient && (
        <Box
          csx={[
            trackStyles,
            {
              position: 'absolute',
              left: THUMB_SIZE / 2,
              width: `calc(100% - ${THUMB_SIZE}px)`,
              height: '100%',
              background: background.get()?.insideGradient,
            },
          ]}
        />
      )}

      <m.div
        ref={trackRef}
        onPan={isDisabled ? undefined : step > 0 ? handleTrackPan : undefined}
        onPanEnd={() => {
          isPanning.set(false);
        }}
        onMouseDown={
          isDisabled
            ? undefined
            : isArray(value)
            ? undefined
            : updateThumbByClick
        }
        css={[
          trackStyles,
          {
            left: THUMB_SIZE / 2,
            width: `calc(100% - ${THUMB_SIZE}px)`,
            height: `${TRACK_HEIGHT}px`,
            position: 'relative',
          },
        ]}
        style={{ background: background.get()?.outsideGradient }}>
        {step && step > 0 && showStepMarks
          ? Array.from({ length: max / step + 1 }).map((_, index) => {
              const stepPosition = (realSliderWidth / (max / step)) * index;
              return (
                <m.div
                  key={index}
                  css={{
                    position: 'absolute',
                    left: stepPosition - 1.5,
                    width: 3,
                    height: 3,
                    borderRadius: '100%',
                    backgroundColor: theme.colors.lightGrey,
                  }}
                />
              );
            })
          : null}
      </m.div>

      {isArray(localValues) ? (
        localValues.map((thumbValue, index) => (
          <SliderThumb
            {...props}
            xPositions={xPositions}
            labelPositions={labelPositions}
            values={localValues}
            id={index}
            key={`thumb-${index}`}
            currentValue={thumbValue}
            realSliderWidth={realSliderWidth}
            trackRect={trackRect}
            onUpdateValue={newValue => {
              if (index === undefined) return;
              const newLocalValue = [...localValues];
              newLocalValue[index] = newValue;
              setLocalValues(newLocalValue);
              handleUpdateFinalValue(newLocalValue);
            }}
            step={step}
            isPanning={isPanning}
            isResizing={isResizing}
            thumbHitSlop={thumbHitSlop}
          />
        ))
      ) : (
        <SliderThumb
          {...props}
          id={0}
          xPositions={xPositions}
          labelPositions={labelPositions}
          values={localValues}
          currentValue={localValues}
          realSliderWidth={realSliderWidth}
          trackRect={trackRect}
          onUpdateValue={newValue => {
            setLocalValues(newValue);
            if (newValue === localValues || isEqual(newValue, localValues))
              return;
            handleUpdateFinalValue(newValue);
          }}
          step={step}
          isPanning={isPanning}
          isResizing={isResizing}
          thumbHitSlop={thumbHitSlop}
        />
      )}

      {showDebugValues && (
        <p style={{ textAlign: 'center', marginBlock: '10px' }}>
          value:{' '}
          {isArray(localValues)
            ? `[${localValues.map(lVal => Math.round(lVal)).join(', ')}]`
            : Math.round(localValues)}
        </p>
      )}
    </div>
  );
};

const SliderThumb = (
  props: ISlider & {
    values: number | number[];
    xPositions: MotionValue<number[]>;
    labelPositions: MotionValue<number[]>;
    currentValue: number;
    realSliderWidth: number;
    id: number;
    trackRect?: DOMRect;
    onUpdateValue?: (value: number) => void;
    isPanning: MotionValue<boolean>;
    thumbHitSlop: number;
    isResizing: MotionValue<boolean>;
  },
) => {
  const {
    valueLabelDisplay = 'none',
    labelDirection = 'top',
    customValueLabel,
    step = 0,
    min = 0,
    max = 100,
    id,
    values,
    xPositions,
    labelPositions,
    currentValue,
    realSliderWidth,
    trackRect,
    onUpdateValue,
    isPanning,
    thumbHitSlop,
    isResizing,
    bannedThumbColor,
    isDisabled,
  } = props;

  const [localValue, setLocalValue] = useState(0);
  const numberOfThumbs = isArray(values) ? values.length : 1;
  const theme = useTheme();
  const thumbRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const isDragging = useMotionValue(false);
  const showLabel = useMotionValue(false);

  const x = useMotionValue(0);
  const percentage = useTransform(x, [0, realSliderWidth], [min, max]);

  const handleThumbDrag = () => {
    const xPositionsArray = xPositions.get();
    if (!trackRect || !thumbRef.current || !xPositionsArray) return;

    const thumbRect = thumbRef.current.getBoundingClientRect();
    const currentThumbPosition = thumbRect.x - trackRect.x + THUMB_SIZE / 2;
    if (currentThumbPosition < 0 || currentThumbPosition > realSliderWidth)
      return;

    if (currentThumbPosition < xPositionsArray[id - 1] + THUMB_SIZE) return;

    const newXValue = Math.max(0, Math.round(currentThumbPosition));

    isDragging.set(true);
    x.set(newXValue);
    const newValues = [...xPositionsArray];
    newValues[id] = newXValue;
    xPositions.set(newValues);
    onUpdateValue && onUpdateValue(Math.round(percentage.get()));
    setLocalValue(Math.round(percentage.get()));
  };

  const handleThumbPan = (event: PointerEvent) => {
    if (!trackRect) return;

    const xPositionsArray = xPositions.get();
    const newThumbPosition = event.clientX - trackRect.x;

    if (newThumbPosition > realSliderWidth) return;

    const stepSize = step;
    const left = id === 0 ? 0 : xPositionsArray[id - 1] + THUMB_SIZE;

    const right =
      id + 1 === numberOfThumbs
        ? realSliderWidth
        : xPositionsArray[id + 1] -
          (step > 10 ? THUMB_SIZE : stepSize > 0 ? stepSize : THUMB_SIZE);

    if (
      newThumbPosition < left - THUMB_SIZE / 1.5 ||
      newThumbPosition > right + THUMB_SIZE / 1.5
    )
      return;

    const newXValue = Math.max(0, Math.round(newThumbPosition));

    const newSnappedValue = handleCalculateSnappedValue(
      newXValue,
      step,
      realSliderWidth,
      max,
    );

    isPanning.set(true);
    if (xPositionsArray[0] !== newSnappedValue.xValue) {
      if (isArray(values)) {
        const newValues = [...xPositionsArray];
        newValues[id] = newSnappedValue.xValue;
        xPositions.set(newValues);
      } else {
        xPositions.set([newSnappedValue.xValue]);
      }
      onUpdateValue && onUpdateValue(newSnappedValue.stepValue);
      setLocalValue(newSnappedValue.stepValue);
    }
  };

  useEffect(() => {
    const moveToPosition = () => {
      if (
        !isPanning.get() &&
        !isResizing.get() &&
        !isDragging.get() &&
        thumbRef.current
      ) {
        if (currentValue > max || currentValue === localValue) return;

        const thumbPosition = Math.max(
          0,
          Math.round((currentValue * realSliderWidth) / max),
        );
        const newThumbPosition = currentValue !== min ? thumbPosition : 0;
        thumbRef.current.style.transform = `translateX(${newThumbPosition}px)`;
        x.set(newThumbPosition);
        setLocalValue(currentValue);
      }
    };

    moveToPosition();
  }, [
    currentValue,
    min,
    max,
    realSliderWidth,
    thumbRef,
    isPanning,
    isDragging,
    isResizing,
    x,
    xPositions,
    id,
    localValue,
    labelPositions,
  ]);

  const labelMarginLeft = useTransform(() => {
    const xPositionsArray = xPositions.get();
    if (!labelRef.current || !xPositionsArray) return 0;

    const newLabelPositions = [...labelPositions.get()];
    const labelRect = labelRef.current.getBoundingClientRect();

    if (xPositionsArray[0] + THUMB_SIZE * 2 >= xPositionsArray[1]) {
      newLabelPositions[0] = labelRect.width - THUMB_SIZE + 5;
      newLabelPositions[1] = -5;
    } else {
      newLabelPositions[0] = labelRect.width / 2 - THUMB_SIZE / 2;
      newLabelPositions[1] = labelRect.width / 2 - THUMB_SIZE / 2;
    }

    return -newLabelPositions[id];
  });

  useMotionValueEvent(xPositions, 'change', latest => {
    x.set(latest[id]);
  });

  useMotionValueEvent(showLabel, 'change', latest => {
    if (labelRef.current) {
      const isLabelShowing = latest || isDragging.get() || isPanning.get();
      animate(labelRef.current, {
        opacity: isLabelShowing ? 1 : 0,
        display: isLabelShowing ? 'flex' : 'none',
      });
    }
  });

  useMotionValueEvent(isDragging, 'change', latest => {
    if (
      labelRef.current &&
      valueLabelDisplay === 'hover' &&
      !latest &&
      !showLabel.get()
    ) {
      animate(labelRef.current, {
        opacity: 0,
        display: 'none',
      });
    }
  });

  useMotionValueEvent(isPanning, 'change', latest => {
    if (
      labelRef.current &&
      valueLabelDisplay === 'hover' &&
      !latest &&
      !showLabel.get()
    ) {
      animate(labelRef.current, {
        opacity: 0,
        display: 'none',
      });
    }
  });

  useEffect(() => {
    if (labelRef.current && valueLabelDisplay === 'always') {
      animate(labelRef.current, { opacity: 1, display: 'flex' });
    }
  }, [valueLabelDisplay]);

  const thumbColor = useMemo(() => {
    if (!props.bannedRanges) return theme.colors.persistentDarkerBlue;

    const startThumbHasError = indexSliderHasErrorValue({
      index: 0,
      value: currentValue,
      bannedRanges: props.bannedRanges,
    });

    return startThumbHasError
      ? bannedThumbColor || theme.colors.darkRed
      : theme.colors.persistentDarkerBlue;
  }, [theme, currentValue, props.bannedRanges, bannedThumbColor]);

  return (
    <>
      <m.div
        ref={thumbRef}
        drag={step > 0 ? undefined : 'x'}
        dragConstraints={{
          left: id === 0 ? 0 : xPositions.get()[id - 1] + THUMB_SIZE,
          right:
            id + 1 === numberOfThumbs
              ? realSliderWidth
              : xPositions.get()[id + 1] - THUMB_SIZE,
        }}
        dragElastic={false}
        dragMomentum={false}
        onDrag={isDisabled ? undefined : handleThumbDrag}
        onDragEnd={() => {
          isDragging.set(false);
        }}
        whileDrag={{
          backgroundColor: theme.colors.persistentSemanticBlue,
          cursor: 'grabbing',
        }}
        onPan={isDisabled ? undefined : step > 0 ? handleThumbPan : undefined}
        onPanEnd={() => {
          isPanning.set(false);
        }}
        whileHover={{ boxShadow: isDisabled ? 'none' : theme.shadows[1] }}
        onMouseOver={
          isDisabled
            ? undefined
            : () => {
                valueLabelDisplay === 'hover' && showLabel.set(true);
              }
        }
        onMouseLeave={() => {
          valueLabelDisplay === 'hover' && showLabel.set(false);
        }}
        css={[
          thumbStyles,
          {
            top: -THUMB_SIZE / 2 + TRACK_HEIGHT / 2,
            width: `${THUMB_SIZE}px`,
            height: `${THUMB_SIZE}px`,
            backgroundColor: thumbColor || theme.colors.persistentDarkerBlue,
            cursor: isDisabled ? 'default' : 'grab',
            border: `1px solid ${theme.colors.white}`,
          },
        ]}
        animate={{
          x: isDragging.get() ? 'unset' : xPositions.get()[id],
          transition: {
            duration: 0,
          },
        }}>
        <Box
          csx={[
            thumbStyles,
            {
              top: -thumbHitSlop / 2,
              left: -thumbHitSlop / 2,
              width: `${THUMB_SIZE + thumbHitSlop}px`,
              height: `${THUMB_SIZE + thumbHitSlop}px`,
              cursor: isDisabled ? 'default' : 'grab',
              backgroundColor: 'transparent',
            },
          ]}
        />
        <m.div
          ref={labelRef}
          className="thumbLabel"
          css={[
            labelStyles,
            {
              marginTop: labelDirection === 'bottom' ? '28px' : '-30px',
              borderColor: thumbColor || 'transparent',
            },
          ]}
          style={{
            marginLeft: labelMarginLeft.get(),
          }}>
          <Typography variant="caption">
            {(customValueLabel && customValueLabel(Math.round(currentValue))) ||
              Math.round(currentValue)}
          </Typography>
        </m.div>
      </m.div>
    </>
  );
};

const handleGetPercentage = (
  newXValue: number,
  inputRange: number[],
  outputRange: number[],
) => {
  // Ensure value is within input range
  const clampedValue = Math.min(
    Math.max(newXValue, inputRange[0]),
    inputRange[1],
  );

  // Map clamped value from input range to output range
  const mappedValue =
    outputRange[0] +
    ((outputRange[1] - outputRange[0]) * (clampedValue - inputRange[0])) /
      (inputRange[1] - inputRange[0]);

  return mappedValue;
};

const handleCalculateSnappedValue = (
  newXValue: number,
  step: number,
  sliderWidth: number,
  maxValue: number,
) => {
  const steps = maxValue / step;
  const snappedStep = Math.round((newXValue / sliderWidth) * steps);
  const newSnappedXValue = Math.round((snappedStep / steps) * sliderWidth);
  const stepValue = snappedStep * step;
  return {
    xValue: newSnappedXValue,
    stepValue: stepValue > maxValue ? maxValue : stepValue,
  };
};

export default Slider;
