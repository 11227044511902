import Box from '@app/components/common/Box';
import { layoutContentStyles } from './styles';
import useScrollRestoration from '@app/hooks/useScrollRestoration';

interface ILayoutContent {
  children: React.ReactNode;
  noPadding?: boolean;
}

const LayoutContent = ({ children, noPadding = false }: ILayoutContent) => {
  useScrollRestoration('layout-content');
  useScrollRestoration('items-list');
  return (
    <Box csx={layoutContentStyles(noPadding)} id="layout-content">
      {children}
    </Box>
  );
};

export default LayoutContent;
