export type FlexAlignType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'stretch'
  | 'baseline';

export const boxAlign = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};
