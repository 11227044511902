import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (elementId?: string) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = elementId ? document.getElementById(elementId) : window;
    if (element) element.scrollTo(0, 0);
  }, [pathname, elementId]);
};

export default useScrollToTop;
