import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import {
  EAccordionSectionOptionType,
  IAccordionSection,
} from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import SubCard from '@app/components/common/Card/SubCard';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { RootState } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  actionCreatorsApp,
  actionCreatorsMenu,
  FormMode,
  IModifierItemPricingVersion,
  SectionId,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignedElements from '../../../AssignedElements';
import AddOrUpdatePricingVersion from './AddOrUpdatePricingVersion';

const sectionId = SectionId.PRICING;
const getMemoizedItemData = makeSelectItemData('modifierItems', sectionId);

const modifierItemPricingVersionFactory = (
  modifierItemPricingVersions: {
    [key: number]: IModifierItemPricingVersion;
  },
  selectedPricingVersionId: number,
) => {
  const formattedModifierItemPricingVersions: IItemCard[] =
    Object.values(modifierItemPricingVersions).length > 0
      ? Object.values(modifierItemPricingVersions).map(
          (modifierItemPricingVersion, index) => ({
            id: modifierItemPricingVersion.modifierItemPricingVersionId,
            title: modifierItemPricingVersion.name,
            type: 'pos',
            isActive: true,
            isSelected:
              selectedPricingVersionId < 0
                ? index === 0
                  ? true
                  : false
                : modifierItemPricingVersion.modifierItemPricingVersionId ===
                  selectedPricingVersionId,
            onPress: () => null,
            onDelete: () => null,
          }),
        )
      : [];

  return formattedModifierItemPricingVersions;
};

const PricingSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  // Redux
  const { itemData: modifierItemData } = useSelector(getMemoizedItemData);

  const isLoadingModal = useSelector(
    (state: RootState) => state.app.isLoadingModal,
  );

  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const isCreation = useSelector(
    (state: RootState) => state.menu.changeData.mode === FormMode.CREATE,
  );

  const { removePricingVersionFromModifierItem, updateModifierItem: setValue } =
    bindActionCreators(actionCreatorsMenu, useDispatch());

  const { showToast } = bindActionCreators(actionCreatorsApp, useDispatch());

  // Local state
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPricingVersionId, setSelectedPricingVersionId] = useState<
    number | undefined
  >(-1);
  const [editablePricingVersionData, setEditablePricingVersion] = useState<{
    data: { name: string; id: number } | null;
    mode: 'edit' | 'add';
  }>({
    data: null,
    mode: 'edit',
  });

  const [options, setOptions] = useState<{
    hasAdvancedPricing: boolean;
    isApplyPercentageEnabled: boolean;
    lastToggle: {
      [key: string]: boolean;
    };
  }>({
    hasAdvancedPricing: false,
    isApplyPercentageEnabled: false,
    lastToggle: {},
  });

  const theme = useTheme();

  const modifierItemPricingVersions = useMemo(() => {
    return modifierItemData ? modifierItemData.modifierItemPricingVersions : {};
  }, [modifierItemData]);

  const pricingVersionsArray = modifierItemPricingVersionFactory(
    modifierItemPricingVersions,
    selectedPricingVersionId ?? -1,
  );

  const pricingVersionData = useMemo(() => {
    return pricingVersionsArray.length > 0
      ? modifierItemPricingVersions[
          Number(
            pricingVersionsArray.filter(
              pricingVersion => pricingVersion.isSelected === true,
            )[0]?.id,
          ) || 0
        ]
      : undefined;
  }, [pricingVersionsArray, modifierItemPricingVersions]);

  useEffect(() => {
    if (
      pricingVersionsArray.length > 0 &&
      ((selectedPricingVersionId || -1) < 0 ||
        !modifierItemPricingVersions[selectedPricingVersionId || -1])
    ) {
      setSelectedPricingVersionId(Number(pricingVersionsArray[0].id) || 0);
    }
  }, [
    pricingVersionsArray,
    selectedPricingVersionId,
    modifierItemPricingVersions,
  ]);

  useEffect(() => {
    if (isLoadingModal.active && pricingVersionsArray.length > 0) {
      setSelectedPricingVersionId(Number(pricingVersionsArray[0].id) || 0);
    }
  }, [isLoadingModal, pricingVersionsArray]);

  const handleEditPricingVersion = (pricingVersion: number) => {
    // Open edit pricing version modal
    setEditablePricingVersion({
      data: {
        name: modifierItemPricingVersions[pricingVersion].name,
        id: modifierItemPricingVersions[pricingVersion]
          .modifierItemPricingVersionId,
      },
      mode: 'edit',
    });
  };

  const handleChangeAdvancePricingMode = (hasAdvancedPricing: boolean) => {
    if (hasAdvancedPricing === false) {
      setValue(
        {
          includedLightPrice: pricingVersionData?.includedMasterPrice,
          includedRegularPrice: pricingVersionData?.includedMasterPrice,
          includedExtraPrice: pricingVersionData?.includedMasterPrice,
          additionalMasterPrice: pricingVersionData?.includedMasterPrice,
          additionalLightPrice: pricingVersionData?.includedMasterPrice,
          additionalRegularPrice: pricingVersionData?.includedMasterPrice,
          additionalExtraPrice: pricingVersionData?.includedMasterPrice,
          onSidePrice: 0,
          quarterPricePercent: 100,
          halfPricePercent: 100,
        },
        sectionId,
        selectedPricingVersionId,
      );
      setOptions(prev => ({
        ...prev,
        lastToggle: { hasAdvancedPricing },
      }));
    } else {
      setOptions(prev => ({
        ...prev,
        hasAdvancedPricing,
        lastToggle: { hasAdvancedPricing },
      }));
      setValue(
        {
          quarterPricePercent: 25,
          halfPricePercent: 50,
        },
        sectionId,
        selectedPricingVersionId,
      );
    }
  };

  const handleChangeIsApplyPercentage = (isApplyPercentageEnabled: boolean) => {
    if (isApplyPercentageEnabled === false) {
      setValue(
        {
          quarterPricePercent: 100,
          halfPricePercent: 100,
        },
        sectionId,
        selectedPricingVersionId,
      );
      setOptions(prev => ({
        ...prev,
        lastToggle: { isApplyPercentageEnabled },
      }));
    } else {
      setOptions(prev => ({
        ...prev,
        isApplyPercentageEnabled,
        lastToggle: { isApplyPercentageEnabled },
      }));
    }
  };

  useEffect(() => {
    const fields = {
      hasAdvancedPricing: false,
      isApplyPercentageEnabled: false,
      lastToggle: {},
    };
    if (pricingVersionData) {
      // Quarter, half and whole pricing
      if (
        pricingVersionData.halfPricePercent === 100 &&
        pricingVersionData.quarterPricePercent === 100
      ) {
        fields.isApplyPercentageEnabled = false;
      } else {
        fields.isApplyPercentageEnabled = true;
      }

      if (
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.additionalMasterPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.includedLightPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.includedRegularPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.includedExtraPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.additionalLightPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.additionalRegularPrice ||
        pricingVersionData.includedMasterPrice !==
          pricingVersionData.additionalExtraPrice ||
        pricingVersionData.halfPricePercent !== 100 ||
        pricingVersionData.quarterPricePercent !== 100
      ) {
        fields.hasAdvancedPricing = true;
      } else {
        fields.hasAdvancedPricing = false;
      }
      setOptions(prev => ({
        ...prev,
        ...(Object.values(prev.lastToggle).length > 0
          ? 'hasAdvancedPricing' in prev.lastToggle
            ? fields
            : {}
          : fields),
        ...prev.lastToggle,
        lastToggle: {},
      }));
    }
  }, [pricingVersionData]);

  const getAdditionalPricesObject = (price: number) => {
    let changes: Partial<IModifierItemPricingVersion> = {};
    if (
      pricingVersionData?.additionalMasterPrice ===
      pricingVersionData?.additionalLightPrice
    ) {
      changes = {
        ...changes,
        additionalLightPrice: price,
      };
    }
    if (
      pricingVersionData?.additionalMasterPrice ===
      pricingVersionData?.additionalRegularPrice
    ) {
      changes = {
        ...changes,
        additionalRegularPrice: price,
      };
    }
    if (
      pricingVersionData?.additionalMasterPrice ===
      pricingVersionData?.additionalExtraPrice
    ) {
      changes = {
        ...changes,
        additionalExtraPrice: price,
      };
    }

    return changes;
  };

  const handleMasterPriceChange = (includedMasterPrice: number) => {
    if (!pricingVersionData) {
      return;
    }
    if (options.hasAdvancedPricing) {
      let changes: Partial<IModifierItemPricingVersion> = {
        includedMasterPrice,
      };

      if (
        pricingVersionData.includedMasterPrice ===
        pricingVersionData.includedLightPrice
      ) {
        changes = {
          ...changes,
          includedLightPrice: includedMasterPrice,
        };
      }

      if (
        pricingVersionData.includedMasterPrice ===
        pricingVersionData.includedRegularPrice
      ) {
        changes = {
          ...changes,
          includedRegularPrice: includedMasterPrice,
        };
      }

      if (
        pricingVersionData.includedMasterPrice ===
        pricingVersionData.includedExtraPrice
      ) {
        changes = {
          ...changes,
          includedExtraPrice: includedMasterPrice,
        };
      }

      if (
        pricingVersionData.includedMasterPrice ===
        pricingVersionData.additionalMasterPrice
      ) {
        changes = {
          ...changes,
          additionalMasterPrice: includedMasterPrice,
          ...getAdditionalPricesObject(includedMasterPrice),
        };
      }

      setValue(changes, sectionId, selectedPricingVersionId);
    } else {
      setValue(
        {
          includedMasterPrice,
          includedLightPrice: includedMasterPrice,
          includedRegularPrice: includedMasterPrice,
          includedExtraPrice: includedMasterPrice,
          additionalMasterPrice: includedMasterPrice,
          additionalLightPrice: includedMasterPrice,
          additionalRegularPrice: includedMasterPrice,
          additionalExtraPrice: includedMasterPrice,
        },
        sectionId,
        selectedPricingVersionId,
      );
    }
  };

  return (
    <AccordionSection
      noPadding
      title={t('menuScreen.modifierItemDetails.pricing.title') as string}
      options={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewPricingVersion'),
          icon: false,
          handler: () => {
            setEditablePricingVersion({
              data: { name: '', id: 0 },
              mode: 'add',
            });
          },
        },
      ]}
      onOptionsClickDisabled={() =>
        showToast({
          type: 'info',
          title: t('menuScreen.categoryMain.disabledActionToast.title'),
          description: t('menuScreen.modifierItemDetails.pricing.addDisabled'),
        })
      }
      optionType={EAccordionSectionOptionType.ACTION_BUTTON}
      isEditButtonDisabled={!isMasterMode && !isCreation}
      onEditAction={() => setIsEditMode(!isEditMode)}
      onEditClickDisabled={() =>
        showToast({
          type: 'info',
          title: t('menuScreen.categoryMain.disabledActionToast.title'),
          description: t('menuScreen.modifierItemDetails.pricing.editDisabled'),
        })
      }
      {...props}>
      <AddOrUpdatePricingVersion
        pricingVersion={editablePricingVersionData.data}
        selectedPricingVersionId={selectedPricingVersionId ?? -1}
        setEditablePricingVersion={setEditablePricingVersion}
        setSelectedPricingVersionId={setSelectedPricingVersionId}
        mode={editablePricingVersionData.mode}
        modifier="items"
        isDisableDelete={pricingVersionsArray.length <= 1}
      />
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 20px',
        }}>
        <AssignedElements
          noCardsPadding
          noCardsContainerPadding
          elements={pricingVersionsArray}
          showHeader={false}
          cardsContainerCsx={{
            border: 'none',
            padding:
              pricingVersionsArray.length > 1 || isEditMode
                ? '10px 0px 20px'
                : '0px',
          }}
          subCardProps={{
            extraOptions: <></>,
            searchBarProps: undefined,
            csx: {
              border: 'none',
            },
          }}
          renderItem={pricingVersion =>
            pricingVersionsArray.length > 1 || isEditMode ? (
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  position: 'relative',
                }}>
                <Card.Item
                  csx={{
                    borderColor: `${theme.colors.lightGrey} !important`,
                  }}
                  {...pricingVersion}
                  showRemoveIcon={isEditMode && pricingVersionsArray.length > 1}
                  showEditIcon={
                    ((!isEditMode && pricingVersionsArray.length > 1) ||
                      (isEditMode && pricingVersionsArray.length === 1)) &&
                    isMasterMode
                  }
                  isAnimated={false}
                  onClick={() => {
                    setSelectedPricingVersionId(Number(pricingVersion.id) || 0);
                  }}
                  onRemoveClick={() => {
                    removePricingVersionFromModifierItem(
                      Number(pricingVersion.id) || 0,
                      (newPricingVersionsIds: string[]) => {
                        !newPricingVersionsIds.includes(
                          `${selectedPricingVersionId}`,
                        ) &&
                          setSelectedPricingVersionId(
                            Number(newPricingVersionsIds[0]),
                          );
                      },
                    );
                  }}
                  onEditClick={() =>
                    handleEditPricingVersion(Number(pricingVersion.id) || 0)
                  }
                />
                {pricingVersion.id === selectedPricingVersionId && (
                  <Box
                    csx={{
                      position: 'absolute',
                      justifyContent: 'center',
                      display: 'flex',
                      width: '100%',
                      left: 0,
                      right: 0,
                      bottom: '-18px',
                    }}>
                    <Icon
                      csx={{
                        rotate: '180deg',
                      }}
                      name="IoTriangleSharp"
                      size="20px"
                      color={theme.colors.semanticBlue}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <></>
            )
          }
        />
        {pricingVersionData && (
          <SubCard
            csx={{
              backgroundColor:
                pricingVersionsArray.length > 1
                  ? theme.colors.lightestGrey
                  : 'transparent',
              marginBottom: '20px',
              border: 'none',
            }}>
            <Grid rowGap={20} columnGap={20}>
              <Grid.Item
                mb={12}
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: '10px',
                }}>
                <Switch
                  label={t(
                    'menuScreen.modifierItemDetails.pricing.labels.advancedPricingMode',
                  )}
                  info={t(
                    'menuScreen.modifierItemDetails.pricing.labels.advancedPricingModetoolTip',
                  )}
                  checked={options.hasAdvancedPricing}
                  onChange={handleChangeAdvancePricingMode}
                />
              </Grid.Item>
            </Grid>

            <Divider
              direction="horizontal"
              csx={{
                marginBlock: '10px',
              }}
              lineCsx={{
                borderWidth: '2px',
              }}
            />

            <SubCard
              csx={{
                border: 'none',
                padding: '0px',
                backgroundColor: 'transparent',
              }}
              title={
                options.hasAdvancedPricing
                  ? `x1, x2, x3 ${t(
                      'menuScreen.modifierItemDetails.pricing.labels.pricingMode',
                    )}`
                  : t(
                      'menuScreen.modifierItemDetails.pricing.labels.masterPrice',
                    )
              }
              description={
                options.hasAdvancedPricing
                  ? t(
                      'menuScreen.modifierItemDetails.pricing.labels.x1x2x3PricingModeTitle',
                    )
                  : undefined
              }>
              <Grid rowGap={20} columnGap={20}>
                <Grid.Item mb={12} sm={6} md={6} lg={3}>
                  <MoneyInput
                    required
                    label={
                      options.hasAdvancedPricing
                        ? t(
                            'menuScreen.modifierItemDetails.pricing.labels.includedPrice',
                          )
                        : t(
                            'menuScreen.modifierItemDetails.pricing.labels.price',
                          )
                    }
                    placeholder={
                      options.hasAdvancedPricing
                        ? t(
                            'menuScreen.modifierItemDetails.pricing.labels.includedPrice',
                          )
                        : t(
                            'menuScreen.modifierItemDetails.pricing.labels.price',
                          )
                    }
                    info={t(
                      'menuScreen.modifierItemDetails.pricing.labels.x1x2x3IncludedPrice',
                    )}
                    value={pricingVersionData.includedMasterPrice}
                    onValueChange={handleMasterPriceChange}
                  />
                </Grid.Item>
                {options.hasAdvancedPricing && (
                  <Grid.Item mb={12} sm={6} md={6} lg={3}>
                    <MoneyInput
                      required
                      label={t(
                        'menuScreen.modifierItemDetails.pricing.labels.additionalPrice',
                      )}
                      placeholder={t(
                        'menuScreen.modifierItemDetails.pricing.labels.additionalPrice',
                      )}
                      info={t(
                        'menuScreen.modifierItemDetails.pricing.labels.x1x2x3AdditionalPrice',
                      )}
                      value={pricingVersionData.additionalMasterPrice}
                      onValueChange={additionalMasterPrice =>
                        setValue(
                          {
                            additionalMasterPrice,
                            ...getAdditionalPricesObject(additionalMasterPrice),
                          },
                          sectionId,
                          selectedPricingVersionId,
                        )
                      }
                    />
                  </Grid.Item>
                )}
              </Grid>
            </SubCard>
            {options.hasAdvancedPricing && (
              <>
                <Divider
                  direction="horizontal"
                  csx={{
                    marginBlock: '10px',
                  }}
                  lineCsx={{
                    borderWidth: '2px',
                  }}
                />
                <SubCard
                  csx={{
                    border: 'none',
                    padding: '0px',
                    backgroundColor: 'transparent',
                  }}
                  title={`Lt, Reg, Ext ${t(
                    'menuScreen.modifierItemDetails.pricing.labels.pricingMode',
                  )}`}
                  description={t(
                    'menuScreen.modifierItemDetails.pricing.labels.LtRegExtPricingModeTitle',
                  )}>
                  <Grid rowGap={20} columnGap={20}>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedLightPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedLightPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtIncludedLightPrice',
                        )}
                        value={pricingVersionData.includedLightPrice}
                        onValueChange={includedLightPrice =>
                          setValue(
                            { includedLightPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalLightPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalLightPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtAdditionalLightPrice',
                        )}
                        value={pricingVersionData.additionalLightPrice}
                        onValueChange={additionalLightPrice =>
                          setValue(
                            { additionalLightPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                  </Grid>
                  <Grid rowGap={20} columnGap={20} csx={{ marginTop: '20px' }}>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedRegularPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedRegularPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtIncludedRegularPrice',
                        )}
                        value={pricingVersionData.includedRegularPrice}
                        onValueChange={includedRegularPrice =>
                          setValue(
                            { includedRegularPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalRegularPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalRegularPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtAdditionalRegularPrice',
                        )}
                        value={pricingVersionData.additionalRegularPrice}
                        onValueChange={additionalRegularPrice =>
                          setValue(
                            { additionalRegularPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                  </Grid>
                  <Grid rowGap={20} columnGap={20} csx={{ marginTop: '20px' }}>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedExtraPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.includedExtraPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtIncludedExtraPrice',
                        )}
                        value={pricingVersionData.includedExtraPrice}
                        onValueChange={includedExtraPrice =>
                          setValue(
                            { includedExtraPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalExtraPrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.additionalExtraPrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.LtRegExtAdditionalExtraPrice',
                        )}
                        value={pricingVersionData.additionalExtraPrice}
                        onValueChange={additionalExtraPrice =>
                          setValue(
                            { additionalExtraPrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                  </Grid>
                </SubCard>
                <Divider
                  direction="horizontal"
                  csx={{
                    marginBlock: '10px',
                  }}
                  lineCsx={{
                    borderWidth: '2px',
                  }}
                />
                <SubCard
                  csx={{
                    border: 'none',
                    padding: '0px',
                    backgroundColor: 'transparent',
                  }}
                  title={t(
                    'menuScreen.modifierItemDetails.pricing.labels.otherOptions',
                  )}>
                  <Grid rowGap={20} columnGap={20}>
                    <Grid.Item mb={12} sm={6} md={6} lg={3}>
                      <MoneyInput
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.onSidePrice',
                        )}
                        placeholder={t(
                          'menuScreen.modifierItemDetails.pricing.labels.onSidePrice',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.onSidePricetoolTip',
                        )}
                        value={pricingVersionData.onSidePrice}
                        onValueChange={onSidePrice =>
                          setValue(
                            { onSidePrice },
                            sectionId,
                            selectedPricingVersionId,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={6}
                      lg={3}
                      csx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        paddingBottom: '13px',
                      }}>
                      <Checkbox
                        label={t(
                          'menuScreen.modifierItemDetails.pricing.labels.applyQuarterHalfAndWholePricing',
                        )}
                        info={t(
                          'menuScreen.modifierItemDetails.pricing.labels.applyQuarterHalfAndWholePricingtooltip',
                        )}
                        checked={options.isApplyPercentageEnabled}
                        onChange={handleChangeIsApplyPercentage}
                      />
                    </Grid.Item>
                  </Grid>
                  {options.isApplyPercentageEnabled && (
                    <Grid
                      rowGap={20}
                      columnGap={20}
                      csx={{ marginTop: '20px' }}>
                      <Grid.Item mb={12} sm={6} md={6} lg={3}>
                        <MoneyInput
                          label={t(
                            'menuScreen.modifierItemDetails.pricing.labels.quarterPrice',
                          )}
                          placeholder={t(
                            'menuScreen.modifierItemDetails.pricing.labels.quarterPrice',
                          )}
                          prefix=""
                          suffix="%"
                          precision={0}
                          value={pricingVersionData.quarterPricePercent}
                          onValueChange={quarterPricePercent =>
                            setValue(
                              { quarterPricePercent },
                              sectionId,
                              selectedPricingVersionId,
                            )
                          }
                        />
                      </Grid.Item>
                      <Grid.Item mb={12} sm={6} md={6} lg={3}>
                        <MoneyInput
                          label={t(
                            'menuScreen.modifierItemDetails.pricing.labels.halfPrice',
                          )}
                          placeholder={t(
                            'menuScreen.modifierItemDetails.pricing.labels.halfPrice',
                          )}
                          prefix=""
                          suffix="%"
                          precision={0}
                          value={pricingVersionData.halfPricePercent}
                          onValueChange={halfPricePercent =>
                            setValue(
                              { halfPricePercent },
                              sectionId,
                              selectedPricingVersionId,
                            )
                          }
                        />
                      </Grid.Item>
                      <Grid.Item mb={12} sm={6} md={6} lg={3}>
                        <MoneyInput
                          label={t(
                            'menuScreen.modifierItemDetails.pricing.labels.wholePrice',
                          )}
                          placeholder={t(
                            'menuScreen.modifierItemDetails.pricing.labels.wholePrice',
                          )}
                          prefix=""
                          suffix="%"
                          precision={0}
                          value={100}
                          maxValue={100}
                          disabled={true}
                          onValueChange={() => null}
                        />
                      </Grid.Item>
                    </Grid>
                  )}
                </SubCard>
              </>
            )}
          </SubCard>
        )}
      </Box>
    </AccordionSection>
  );
};

export default PricingSection;
