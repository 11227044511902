import { TCsx } from '@emotion/react';
import { m } from 'framer-motion';
import { Children } from 'react';

interface IStepperForm {
  isAnimated?: boolean;
  activeStep?: number;
  children: React.ReactNode;
  csx?: TCsx;
}

const StepperForm = ({
  isAnimated = true,
  activeStep = 0,
  children,
  csx,
}: IStepperForm) => {
  return (
    <m.div
      key={activeStep}
      initial={isAnimated ? { x: 100 } : false}
      animate={{ x: 0, transition: { duration: 0.2 } }}
      css={[{ width: '100%', height: '100%' }, csx]}>
      {Children.toArray(children)[activeStep]}
    </m.div>
  );
};

export default StepperForm;
