import { useLocation } from 'react-router-dom';

const useAccordionStateRestoration = () => {
  const location = useLocation();

  const getScrollData = () => {
    const savedData = localStorage.getItem('path-data');
    return savedData ? JSON.parse(savedData) : {};
  };

  const getAccordionState = () => {
    const scrollData = getScrollData();
    return (
      (scrollData[location.pathname] &&
        scrollData[location.pathname].accordion) ||
      []
    );
  };

  const setAccordionState = (accordionState: Array<boolean | number>) => {
    const scrollData = getScrollData();

    scrollData[location.pathname] = {
      ...scrollData[location.pathname],
      accordion: accordionState,
    };

    localStorage.setItem('path-data', JSON.stringify(scrollData));
  };

  return { setAccordionState, accordionState: getAccordionState() };
};
export default useAccordionStateRestoration;
