import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';

import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import { store } from '@app/state/store';
import {
  CoreRootState,
  ILicenseSettingsStore,
  ILicenses,
  ITablets,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IDeviceDeactivationSettings extends IAccordionSection {
  data: ILicenseSettingsStore;
  licenseId: keyof ILicenses;
  deviceId: keyof ITablets;
}

const DeviceDeactivationSettings = ({
  data,
  licenseId,
  deviceId,
  ...sectionProps
}: IDeviceDeactivationSettings) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;

  const { deactivateLicenseWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const onRefreshSettings = useRefreshSettingsWeb();

  return (
    <AccordionSection
      isExpanded={true}
      isExpandable={false}
      expandedHeight="70px"
      title={t('settingsModule.licenseSettings.deviceDeactivation.title')}
      description={t(
        'settingsModule.licenseSettings.deviceDeactivation.description',
      )}
      variant="danger"
      {...otherProps}>
      <Grid>
        <Grid.Item md={6} lg={4}>
          <Button
            variant="danger"
            onClick={() =>
              deactivateLicenseWeb(
                () =>
                  onRefreshSettings(
                    store.getState() as unknown as CoreRootState,
                  ),
                deviceId,
                licenseId,
                data.nickname,
              )
            }>
            {t('settingsModule.licenseSettings.deviceDeactivation.button')}
          </Button>
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default DeviceDeactivationSettings;
