import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  IKitchenTicketSettings,
  IWithPrepStationVersionId,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import CustomizingReceiptsTableBody from '../CustomizingReceiptsTableBody';

const SECTION_ID = SettingsSectionId.CUSTOMIZING_RECEIPTS_BODY;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

interface ITicketBody extends IWithPrepStationVersionId {
  title?: string;
}

const TicketBodyTable = ({ title, prepStationVersionId }: ITicketBody) => {
  const { data } = useSelector(getMemoizedItemData);

  const bodyData = data?.prepStationVersions[prepStationVersionId];

  return (
    <CustomizingReceiptsTableBody
      sectionId={SECTION_ID}
      data={bodyData}
      title={title}
      prepStationVersionId={prepStationVersionId}
    />
  );
};

export default TicketBodyTable;
