import Button from '@app/components/common/Button';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import { INVISIBLE_CHAR } from '@westondev/tableturn-core';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import {
  BASE_TRANSLATION,
  COMBO_BASE_TRANSLATION,
  PRICES_TYPES_NAMES,
  PriceName,
  TPricesNames,
  UpdateType,
} from '../types';

interface IMassPriceUpdateSection {
  hasLocationSelected: boolean;
  handleUpdatePrice: (
    priceName: TPricesNames,
    updateType: UpdateType,
    amount: number,
  ) => void;
  isCombo?: boolean;
}

const MassPriceUpdateSection = ({
  hasLocationSelected,
  handleUpdatePrice,
  t,
  isCombo,
}: IMassPriceUpdateSection & WithTranslation) => {
  const [priceName, setPriceName] = React.useState<PriceName>(
    PriceName.ITEM_PRICE,
  );
  const [updateType, setUpdateType] = React.useState<UpdateType>(
    UpdateType.PERCENT,
  );
  const [priceInput, setPriceInput] = React.useState(0);

  const PRICES_NAMES = React.useMemo(
    () => [
      [{ label: t(`${BASE_TRANSLATION}.all`), value: PriceName.ALL }],
      [
        {
          label: t(
            isCombo
              ? `${COMBO_BASE_TRANSLATION}.comboBasePrice`
              : `${BASE_TRANSLATION}.itemPrice`,
          ),
          value: PriceName.ITEM_PRICE,
        },
        {
          label: t(`${BASE_TRANSLATION}.onlinePrice`),
          value: PriceName.ONLINE_PRICE,
        },
        {
          label: t(`${BASE_TRANSLATION}.deliveryPrice`),
          value: PriceName.DELIVERY_PRICE,
        },
      ],
    ],
    [t, isCombo],
  );
  const UPDATE_TYPES = React.useMemo(
    () => [
      [
        { label: t(`${BASE_TRANSLATION}.percent`), value: UpdateType.PERCENT },
        { label: t(`${BASE_TRANSLATION}.fixed`), value: UpdateType.FIXED },
        { label: t(`${BASE_TRANSLATION}.setPrice`), value: UpdateType.SET },
      ],
    ],
    [t],
  );
  const isPercent = React.useMemo(
    () => updateType === UpdateType.PERCENT,
    [updateType],
  );
  const disableButton = React.useMemo(
    () =>
      !hasLocationSelected || (updateType !== UpdateType.SET && !priceInput),
    [priceInput, hasLocationSelected, updateType],
  );

  React.useEffect(() => {
    if (isPercent) setPriceInput(current => Math.trunc(current));
  }, [isPercent]);

  return (
    <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'flex-end' }}>
      <Grid.Item>
        <Typography color="black" fontWeight="medium">
          {t(`${BASE_TRANSLATION}.updateSectionTitle`)}
        </Typography>
      </Grid.Item>
      <Grid.Item mb={6} sm={4} md={3}>
        <Dropdown
          label={t(`${BASE_TRANSLATION}.priceToModify`)}
          data={PRICES_NAMES}
          placeholder={t(`${BASE_TRANSLATION}.itemPrice`)}
          value={priceName}
          onChange={value => setPriceName(value)}
        />
      </Grid.Item>
      <Grid.Item mb={6} sm={4} md={3}>
        <Dropdown
          label={t(`${BASE_TRANSLATION}.updateType`)}
          data={UPDATE_TYPES}
          placeholder={t(`${BASE_TRANSLATION}.updateType`)}
          value={updateType}
          onChange={value => setUpdateType(value)}
        />
      </Grid.Item>
      <Grid.Item mb={6} sm={4} md={3}>
        <MoneyInput
          placeholder="0"
          prefix={!isPercent ? '$' : INVISIBLE_CHAR}
          suffix={isPercent ? '%' : INVISIBLE_CHAR}
          precision={isPercent ? 0 : 2}
          maxValue={isPercent ? 100 : undefined}
          value={priceInput}
          label={t(`${BASE_TRANSLATION}.modifyPricesBy`)}
          onValueChange={setPriceInput}
        />
      </Grid.Item>
      <Grid.Item mb={6} sm={12} md={3}>
        <Button
          variant="primary"
          disabled={disableButton}
          onClick={() =>
            handleUpdatePrice(
              PRICES_TYPES_NAMES[priceName],
              updateType,
              Number(priceInput),
            )
          }>
          {t(`${BASE_TRANSLATION}.applyToSelected`)}
        </Button>
      </Grid.Item>
    </Grid>
  );
};

export default MassPriceUpdateSection;
