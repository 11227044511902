import {
  makeSelectItemData,
  selectChangeMassUpdateData,
  selectMenuMode,
} from '@app/state/menu/menuSelectors';
import { FormMode } from '@westondev/tableturn-core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ISectionData } from './types';

const sectionHOC = <T extends ISectionData>(
  component: React.FC<any>,
  type: 'combos' | 'items',
) => {
  const Component = component;
  const WrappedComponent = (props: T) => {
    const getMemoizedItemData = makeSelectItemData(
      type,
      props.sectionId,
      props.sectionId1,
    );

    const { itemData } = useSelector(getMemoizedItemData);

    const changeDataMode = useSelector(selectMenuMode);
    const changeMassUpdateData = useSelector(selectChangeMassUpdateData);

    const mode = useMemo(() => {
      if (props.menuTypeVersionId) {
        return changeDataMode;
      }
      return FormMode.MASS_UPDATE;
    }, [changeDataMode, props.menuTypeVersionId]);

    const menuTypeVersions = useMemo(() => {
      if (props.menuTypeVersionId) {
        return itemData.menuTypeVersions;
      }
      return {};
    }, [itemData, props.menuTypeVersionId]);

    return (
      <Component
        itemData={
          changeMassUpdateData.data
            ? changeMassUpdateData.data
            : itemData.menuTypeVersions[props.menuTypeVersionId || 0]
        }
        item={itemData}
        itemName={itemData?.name}
        menuTypeVersions={menuTypeVersions}
        mode={mode}
        {...props}
      />
    );
  };
  return WrappedComponent;
};

export default sectionHOC;
