import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal/Modal';
import Typography from '@app/components/common/Typography';
import { prepStationVersionsSelectionFactory } from '@app/helpers/factories/settings/kitchenTicketSettings/prepStationVersionsFactory';
import { selectPrepStationVersionsOfCurrentKitchenTicketSettings } from '@app/state/selectors/settingsSelectors';
import { useTheme } from '@emotion/react';
import { IPrepStationToSelect } from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectLocationRow from '../../../SelectLocationsModal/SelectLocationRow/SelectLocationRow';
import Grid from '@app/components/common/Grid';

interface ISelectPrepStationsToApplyChangesModal extends WithTranslation {
  isActive: boolean;
  prepStationId: number;
  onApplyChanges: (value: IPrepStationToSelect[]) => void;
  setModalActive: () => void;
  title?: string;
  description?: string;
}

const SelectPrepStationsToApplyChangesModal = ({
  t,
  isActive,
  prepStationId,
  onApplyChanges,
  setModalActive,
  title,
  description,
}: ISelectPrepStationsToApplyChangesModal) => {
  // Redux
  const prepStationVersions = useSelector(
    selectPrepStationVersionsOfCurrentKitchenTicketSettings,
  );

  const onCloseModal = () => setModalActive();

  const [prepStations, setSelectedPrepStations] = useState<
    IPrepStationToSelect[]
  >([]);

  const theme = useTheme();

  useEffect(() => {
    if (isActive) {
      const formattedPrepStations = prepStationVersions
        ? prepStationVersionsSelectionFactory(prepStationVersions).filter(
            prepStationVersion =>
              prepStationVersion.prepStationId !== prepStationId,
          )
        : [];
      setSelectedPrepStations(formattedPrepStations);
    }
  }, [prepStationId, prepStationVersions, isActive]);

  const handleSelectAllPrepStations = () => {
    const newPrepStations = prepStations.map(prepStation => {
      return {
        ...prepStation,
        selected: true,
      };
    });
    setSelectedPrepStations(newPrepStations);
  };

  const handleUpdateSelectedPrepStations = (selectedPrepStationId: number) => {
    const newPrepStations = prepStations.map(prepStation => {
      if (selectedPrepStationId === prepStation.prepStationId)
        return {
          ...prepStation,
          selected: !prepStation.selected,
        };
      return prepStation;
    });
    setSelectedPrepStations(newPrepStations);
  };

  const handleApplyChanges = () => {
    onApplyChanges(prepStations.filter(prepStation => prepStation.selected));
    onCloseModal();
  };

  return (
    <Modal
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title ||
              t(
                'app.modals.kitchenTicketDiffModal.prepStations.applyChangesToOtherPrepStation.title',
              )}
          </Typography>
          <Typography color="darkestGrey">
            {description ||
              t(
                'app.modals.kitchenTicketDiffModal.prepStations.applyChangesToOtherPrepStation.description',
              )}
          </Typography>
        </Box>
      }
      isActive={isActive}
      size={700}
      onModalClose={onCloseModal}
      noPadding
      modalContainerCsx={{
        '& .modalContent': { backgroundColor: theme.colors.lightestGrey },
      }}
      footer={
        <>
          <Button csx={{ minWidth: '120px' }} onClick={onCloseModal}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            csx={{ minWidth: '120px' }}
            variant="primary"
            onClick={handleApplyChanges}>
            {t(
              'app.modals.kitchenTicketDiffModal.prepStations.applyChangesToOtherPrepStation.applyChanges',
            )}
          </Button>
        </>
      }>
      <Grid csx={{ padding: '15px' }} rowGap={10}>
        <Grid.Item>
          <Button variant="primary" onClick={handleSelectAllPrepStations}>
            {t(
              'app.modals.kitchenTicketDiffModal.prepStations.applyChangesToOtherPrepStation.selectAllPrepStations',
            )}
          </Button>
        </Grid.Item>
        <Grid.Item>
          {prepStations.map(prepStation => (
            <SelectLocationRow
              key={prepStation.prepStationId}
              selected={prepStation.selected}
              name={prepStation.name}
              locationId={prepStation.prepStationId}
              handleLocationToggle={value =>
                handleUpdateSelectedPrepStations(value)
              }
            />
          ))}
        </Grid.Item>
      </Grid>
    </Modal>
  );
};

export default SelectPrepStationsToApplyChangesModal;
