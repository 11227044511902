import { css } from '@emotion/react';

export const scrollCardsSectionStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    position: 'relative',
    gap: '15px',
    '.buttonsContainer': {
      overflow: 'auto',
      display: 'grid',
      width: '100%',
      gap: '10px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    },
    '.noAvailableOptionsContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  });
