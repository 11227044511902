import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import FreshSheetMenu from '@app/components/LoggedIn/Menu/FreshSheet';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsApp } from '@app/state';
import { selectIsMenuMasterMode } from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsMenu } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const FreshSheet = () => {
  // Redux
  const dispatch = useAppDispatch();
  const setFreshSheetDataFromMenu = bindActionCreators(
    actionCreatorsMenu.setFreshSheetDataFromMenu,
    dispatch,
  );
  const replaceMasterWithLocation = bindActionCreators(
    actionCreatorsApp.replaceMasterWithLocation,
    dispatch,
  );
  const isMasterMode = useSelector(selectIsMenuMasterMode);

  const isSet = useRootSelector(state =>
    Boolean(state.menu.changeFreshSheetData.data),
  );

  useEffect(() => {
    if (!isSet) {
      if (isMasterMode) replaceMasterWithLocation();
      setFreshSheetDataFromMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, isMasterMode]);

  return <LayoutContent>{isSet && <FreshSheetMenu />}</LayoutContent>;
};

export default FreshSheet;
