import { store } from '@app/state/store';
import {
  CoreRootState,
  IModifierGroupsItems,
  modifierGroupsFactoryCore,
} from '@westondev/tableturn-core';

export const modifierGroupsFactory = (modifierGroups: {
  [key: number]: IModifierGroupsItems;
}) =>
  modifierGroupsFactoryCore(modifierGroups, store.getState() as CoreRootState);
