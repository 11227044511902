import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import ColorPicker from '@app/components/common/ColorPicker';
import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import ResetButton from '@app/components/common/ResetButton';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsSettings,
  IRegisterSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SocialMediaBox from './SocialMediaBox';

const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY;

function AdsSettings({
  t,
  data,
}: WithTranslation & { data: IRegisterSettings }) {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const theme = useTheme();

  const navigate = useNavigateWithOrg();
  const location = useLocation();

  const onReset = () => {
    setValue<IRegisterSettings>(
      {
        placeholderBackground: null,
        adTextBackground: null,
        mediaLinksBackground: null,
      },
      SECTION_ID,
    );
  };

  return (
    <Card.SubCard
      title={t(
        'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.title',
      )}
      description={t(
        'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.description',
      )}>
      <Card.SubCard
        title={t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.ads.title',
        )}>
        <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
          <Grid.Item mb={12} sm={6} lg={8}>
            <Typography>
              {t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.ads.description',
              )}
            </Typography>
          </Grid.Item>
          <Grid.Item mb={12} sm={6} lg={4}>
            <Button
              variant="primary"
              onClick={() =>
                navigate('/settings/register/ads', {
                  state: location.state,
                })
              }>
              {t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.ads.viewEditAds',
              )}
            </Button>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
      <Grid columnGap={15} csx={{ marginTop: 15 }}>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={4}>
          <SocialMediaBox
            socialMedia="Facebook"
            switchField="isFacebookActive"
            switchValue={data?.isFacebookActive}
            accountValue={data?.facebookAccount}
            accountField="facebookAccount"
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={4}>
          <SocialMediaBox
            socialMedia="X"
            switchField="isXActive"
            switchValue={data?.isXActive}
            accountValue={data?.xAccount}
            accountField="xAccount"
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={4}>
          <SocialMediaBox
            socialMedia="Instagram"
            switchField="isInstagramActive"
            switchValue={data?.isInstagramActive}
            accountValue={data?.instagramAccount}
            accountField="instagramAccount"
          />
        </Grid.Item>
      </Grid>
      <Card.SubCard
        title={t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.colorSettings.title',
        )}
        csx={{ marginTop: 15 }}>
        <Grid csx={{ alignItems: 'flex-end' }}>
          <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
            <ColorPicker
              color={data?.placeholderBackground || theme.colors.darkerBlue}
              onChange={placeholderBackground =>
                setValue<IRegisterSettings>(
                  { placeholderBackground },
                  SECTION_ID,
                )
              }
              label={t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.colorSettings.placeholderBackground',
              )}
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
            <ColorPicker
              color={data?.adTextBackground || theme.colors.lightestGrey}
              onChange={adTextBackground =>
                setValue<IRegisterSettings>({ adTextBackground }, SECTION_ID)
              }
              label={t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.colorSettings.adTextBackground',
              )}
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
            <ColorPicker
              color={data?.mediaLinksBackground || theme.colors.black}
              onChange={mediaLinksBackground =>
                setValue<IRegisterSettings>(
                  { mediaLinksBackground },
                  SECTION_ID,
                )
              }
              label={t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.colorSettings.mediaLinksBackground',
              )}
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
            <Box>
              <label css={labelStyles}>{t('commonButtons.reset')}</label>
              <ResetButton onClick={() => onReset()} />
            </Box>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
    </Card.SubCard>
  );
}

export default AdsSettings;
