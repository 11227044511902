import { selectRoles } from '@app/state/selectors/settingsSelectors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../Button';
import Card from '../../Card';
import Grid from '../../Grid';
import Icon from '../../Icon';
import MultiSelect from '../../MultiSelect';
import { IOption } from '../../OptionsModal/types';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';

const LocationWithRolesSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  btnSuccessText,
  ...others
}: ICustomSelectionModal<unknown>) => {
  const [selectedLocation, setSelectedLocation] = useState<IItemSelection>({
    id: 0,
    title: '',
    description: '',
  });
  const [selectedRoles, setSelectedRoles] = useState<IOption[]>([]);

  const roles = useSelector(selectRoles);

  const handleOnPress = (button: IItemSelection) => {
    setSelectedLocation(button);
  };

  const preselectedLocation = useMemo(
    () =>
      buttons.length === 1 && selectedLocation.id === 0
        ? buttons[0]
        : undefined,

    [buttons, selectedLocation.id],
  );

  useEffect(() => {
    const selectedUserRoles = selectedLocation.roleIds;

    if (selectedUserRoles) {
      const _selectedRoles = Object.values(roles)
        .filter(role => selectedUserRoles.includes(role.id))
        .map(role => ({
          value: role.id,
          label: role.name,
        }));

      setSelectedRoles(_selectedRoles);
    } else setSelectedRoles([]);
  }, [roles, selectedLocation]);

  return (
    <GenericSelectionModal
      {...others}
      onPress={handleOnPress}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      preSelectedItem={preselectedLocation}
      currentButtonInfo={{
        id: selectedLocation?.id || preselectedLocation?.id || 0,
        roleIds: selectedRoles.map(role => role.value),
      }}
      title={t('settingsModule.usersSettings.selectionModal.title')}
      selectedSectionTitle={t(
        'settingsModule.usersSettings.selectionModal.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'settingsModule.usersSettings.selectionModal.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'settingsModule.usersSettings.selectionModal.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'settingsModule.usersSettings.selectionModal.noAvailableCardsText',
      )}
      allowAssociateMultiple
      showDescriptionPanel
      btnSuccessText={btnSuccessText}
      showSelectAll={false}>
      {({ onSelect, isButtonSelected }) => (
        <Grid rowGap={10}>
          <Grid.Item>
            <Card.SubCard
              title={selectedLocation.title || preselectedLocation?.title}>
              <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'center' }}>
                <Grid.Item mb={9} md={7} lg={9}>
                  <Typography
                    color="darkestGrey"
                    csx={{ wordWrap: 'break-word' }}>
                    {selectedLocation.description ||
                      preselectedLocation?.description}
                  </Typography>
                </Grid.Item>
                <Grid.Item mb={3} md={5} lg={3}>
                  {isButtonSelected ? (
                    <Button
                      csx={{ width: '100px' }}
                      icon={<Icon name="MdDeleteForever" />}
                      variant="danger"
                      onClick={() =>
                        onSelect({
                          roleIds: selectedRoles.map(role => role.value),
                        })
                      }
                    />
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() =>
                        onSelect({
                          roleIds: selectedRoles.map(role => role.value),
                        })
                      }
                      disabled={!selectedRoles.length}>
                      {t(
                        'settingsModule.usersSettings.selectionModal.selectButton',
                      )}
                    </Button>
                  )}
                </Grid.Item>
              </Grid>
            </Card.SubCard>
          </Grid.Item>
          <Grid.Item>
            <Card.SubCard
              title={t('settingsModule.usersSettings.roles')}
              csx={{ flexGrow: 1 }}>
              <MultiSelect
                options={Object.values(roles).map(role => ({
                  value: role.id,
                  label: role.name,
                }))}
                selectedOptions={selectedRoles}
                onChange={setSelectedRoles}
              />
            </Card.SubCard>
          </Grid.Item>
        </Grid>
      )}
    </GenericSelectionModal>
  );
};

export default LocationWithRolesSelectionModal;
