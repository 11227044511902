import { layoutGridStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '@app/state/store';
import Box from '@app/components/common/Box';
import { useTransformEffect } from 'react-zoom-pan-pinch';
import { useMemo, useState } from 'react';

const LayoutGrid = () => {
  // Redux
  const toolSettings = useSelector(
    (state: RootState) => state.tableLayout.toolSettings,
  );

  const SMALL_UNIT_SIZE = toolSettings.smallUnitSize;
  const BIG_UNIT_SIZE = toolSettings.bigUnitSize;
  const GRID_WIDTH = toolSettings.gridWidth;
  const GRID_HEIGHT = toolSettings.gridHeight;

  const [scale, setScale] = useState(1);

  useTransformEffect(({ state }) => {
    setScale(state.scale);
  });

  const smallUnitSize = useMemo(() => {
    return SMALL_UNIT_SIZE;
  }, [SMALL_UNIT_SIZE]);

  return (
    <Box
      csx={theme => ({
        width: GRID_WIDTH,
        height: GRID_HEIGHT,
        position: 'absolute',
        boxShadow: theme.shadows[1],
      })}>
      {toolSettings.showGrid && (
        <>
          <div css={layoutGridStyles(`${smallUnitSize}px`, 'small')} />
          {scale >= 1 && (
            <div css={layoutGridStyles(`${BIG_UNIT_SIZE}px`, 'big')} />
          )}
        </>
      )}
    </Box>
  );
};

export default LayoutGrid;
