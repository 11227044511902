import { css } from '@emotion/react';

export const noElementsFoundStyles = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: '5px',
  });
