import SortableItem from '@app/components/LoggedIn/Menu/common/DragAndDrop/SortableItem';
import { TCsx, useTheme } from '@emotion/react';
import { QuantitySizes } from '@westondev/tableturn-core';
import { m } from 'framer-motion';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { memo, useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import Box from '../../Box';
import { IBox } from '../../Box/Box';
import Icon from '../../Icon';
import Switch from '../../Switch';
import Typography from '../../Typography';
import { ITypography } from '../../Typography/Typography';
import { IItemCard } from '../ItemCard/ItemCard';
import {
  dragIconStyles,
  editIconStyles,
  itemCardStyles,
  removeIconStyles,
} from '../ItemCard/styles';
import ItemButtonFooter from './ItemButtonFooter';
import ItemButtonHeader from './ItemButtonHeader';
import { EItemTypes } from './types';

export interface IItemButton
  extends Omit<IItemCard, 'tag' | 'showTag' | 'subTitle' | 'csx'>,
    WithTranslation {
  itemId: number;
  showHeader?: boolean;
  inventoryLabel?: string;
  hidePrice?: boolean;
  totalPrice?: number;
  includedPrice?: number;
  additionalPrice?: number;
  priceDifference?: number;
  nextPrice?: number;
  priceOverride?: string;
  isQuantityOff?: boolean;
  isMultipleQuantity?: boolean;
  quantityOverride?: boolean;
  showFooter?: boolean;
  isSpecial?: boolean;
  is86ed?: boolean;
  isTaxInclusive?: boolean;
  itemType?: EItemTypes;
  selectedQuantity?: number;
  selectedSize?: number;
  customTag?: {
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
    text?: string;
  };
  headerTextProps?: {
    prefix?: string;
    includedPrice?: Partial<ITypography>;
    additionalPrice?: Partial<ITypography>;
    inventoryLabel?: Partial<ITypography>;
  };
  headerAbsoluteOverride?: boolean;
  containerCsx?: TCsx;
  boxProps?: Partial<IBox>;
  textCsx?: TCsx;
  textColor?: string;
  backgroundColor?: string;
  specialAction?: () => void;
  onChange?: (isActive: boolean) => void;
  onIncrement?: (value: number | QuantitySizes) => void;
  onDecrement?: (value: number | QuantitySizes) => void;
  onTotalPriceChanged?: (value: number) => void;
  isHidden?: boolean;
  image?: string | null;
  bottomLabel?: string;
  showSwitch?: boolean;
  switchValue?: boolean;
  onSwitchChange?: (isActive: boolean) => void;
  draggable?: boolean;
  draggedItemId?: string | null | undefined;
  renderHiddenIfIsActive?: boolean;
}

const ItemButton = ({
  title,
  isSelected = false,
  itemId,
  hidePrice,
  totalPrice,
  showEditIcon,
  showRemoveIcon,
  onEditClick,
  onRemoveClick,
  isAnimated = true,
  isDisabled = false,
  showSwitch = false,
  switchValue,
  onSwitchChange,
  priceDifference,
  nextPrice,
  priceOverride,
  isQuantityOff = false,
  isMultipleQuantity,
  inventoryLabel,
  specialAction, // Action for buttons like "None" or "Split Item"
  isSpecial,
  is86ed,
  isTaxInclusive,
  itemType,
  selectedQuantity,
  quantityOverride,
  selectedSize,
  onChange = () => null,
  onIncrement = () => null,
  onDecrement = () => null,
  onTotalPriceChanged = () => null,
  bottomLabel,
  customTag,
  containerCsx,
  boxProps,
  textCsx,
  textColor,
  backgroundColor,
  headerTextProps,
  headerAbsoluteOverride = false,
  showHeader = true,
  showFooter = true,
  isHidden,
  image,
  draggable,
  draggedItemId,
  renderHiddenIfIsActive,
  showStatus,
  isActive,
  t,
}: IItemButton & WithTranslation) => {
  const theme = useTheme();

  useEffect(() => {
    if (!isNil(totalPrice)) onTotalPriceChanged(totalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  const ItemButtonContent = () => {
    const showIsHiddenTag = isHidden && (!isSelected || renderHiddenIfIsActive);
    return (
      <m.div
        whileHover={isAnimated ? { scale: 1.01 } : {}}
        whileTap={isAnimated ? { scale: 1 } : {}}
        css={[
          itemCardStyles(
            isSelected || false,
            false,
            false,
            textColor ? textColor : specialAction ? 'black' : undefined,
            backgroundColor ? backgroundColor : undefined,
          ),
        ]}>
        {draggable && (
          <Icon
            name="MdDragIndicator"
            color="white"
            size="24px"
            csx={dragIconStyles}
          />
        )}
        <Box
          data-active={true}
          onClick={specialAction ? specialAction : () => onChange(!isSelected)}
          className="itemCard"
          {...boxProps}
          csx={[
            {
              minHeight: '130px',
              backgroundColor: specialAction
                ? theme.colors.lighterGrey
                : isSelected
                ? theme.colors.semanticBlue
                : theme.colors.cardWhite,
              border: `2px solid ${
                customTag?.backgroundColor
                  ? customTag?.backgroundColor
                  : isSelected
                  ? theme.colors.darkBlue
                  : theme.colors.lightGrey
              } !important`,
              padding: '0 !important',
              opacity: isDisabled ? 0.5 : 1,
              overflow: 'hidden',
            },
            boxProps?.csx,
          ]}>
          {/* Header */}
          {showHeader && (
            <ItemButtonHeader
              isSelected={isSelected}
              hidePrice={hidePrice}
              totalPrice={totalPrice}
              inventoryLabel={inventoryLabel}
              quantityOverride={quantityOverride}
              boxProps={
                showFooter &&
                isSelected &&
                !headerAbsoluteOverride &&
                (itemType === EItemTypes.SIZE || isMultipleQuantity)
                  ? { csx: { zIndex: 1 } }
                  : {
                      csx: { position: 'absolute', top: 0, zIndex: 1 },
                    }
              }
              hasNextPrice={
                isQuantityOff
                  ? false
                  : itemType === EItemTypes.QUANTITY
                  ? true
                  : Number(selectedSize) < 2
              }
              nextPrice={nextPrice}
              priceDifference={priceDifference}
              priceOverride={priceOverride}
              textProps={headerTextProps}
              is86ed={is86ed}
              isSpecial={isSpecial}
              isTaxInclusive={isTaxInclusive}
              isMultipleQuantity={isMultipleQuantity}
              quantity={
                itemType === EItemTypes.SIZE ? selectedSize : selectedQuantity
              }
              isSizeItem={itemType === EItemTypes.SIZE}
            />
          )}
          <Box
            className="itemCardContent"
            csx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            {title && (
              <Typography
                component="div"
                className="title"
                fontWeight={isSelected ? 'medium' : 'regular'}
                csx={[
                  {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    zIndex: 1,
                  },
                  textCsx,
                ]}
                color="red">
                {title}
              </Typography>
            )}

            {image && (
              <>
                <img
                  src={image}
                  css={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  placeholder="Item Image"
                  title="Item Image"
                />
                <Box
                  csx={[
                    {
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      opacity: 0.5,
                      backgroundColor: (
                        boxProps?.csx as { backgroundColor?: string }
                      )?.backgroundColor,
                    },
                  ]}
                />
              </>
            )}
          </Box>
          {bottomLabel && (
            <Box
              csx={{
                position: 'absolute',
                bottom: 0,
                paddingInline: '8px',
                border: `1px solid ${theme.colors.semanticBlue}`,
                borderRadius: '100px',
                backgroundColor: theme.colors.lightestGrey,
                marginBottom: 5,
                zIndex: 2,
              }}>
              <Typography color="persistentSemanticBlue">
                {bottomLabel}
              </Typography>
            </Box>
          )}

          {showIsHiddenTag && (
            <Box className="hiddenLabel">
              <Icon
                name="MdVisibilityOff"
                color={isSelected ? 'white' : 'black'}
                size="18px"
              />
              <Typography
                variant="caption2"
                color={
                  isDisabled ? 'lightGrey' : isSelected ? 'white' : 'black'
                }>
                Hidden
              </Typography>
            </Box>
          )}

          {showStatus && !showIsHiddenTag && (
            <Box
              className={`itemCardStatus ${isActive ? 'active' : 'inactive'}`}>
              <Box className="dot" />
              <Typography variant="caption2">
                {t(`commonTexts.${isActive ? 'active' : 'inactive'}`)}
              </Typography>
            </Box>
          )}
          {/* Footer */}
          {showFooter && (
            <ItemButtonFooter
              isSelected={isSelected || false}
              isSizeItem={itemType === EItemTypes.SIZE}
              isMultipleQuantity={isMultipleQuantity}
              increment={
                (itemType === EItemTypes.SIZE && Number(selectedSize) < 2) ||
                itemType === EItemTypes.QUANTITY
                  ? () => onIncrement(itemId)
                  : undefined
              }
              decrement={
                (itemType === EItemTypes.SIZE && selectedSize) ||
                itemType === EItemTypes.QUANTITY
                  ? () => onDecrement(itemId)
                  : undefined
              }
              customTag={customTag}
              itemType={itemType}
            />
          )}
        </Box>
      </m.div>
    );
  };

  return (
    <Box
      csx={[
        {
          width: 'inherit',
          height: 'inherit',
          position: 'relative',
        },
        containerCsx,
      ]}>
      {draggable ? (
        <SortableItem
          csx={{
            width: '100%',
            height: '100%',
          }}
          disabled={false}
          key={itemId}
          id={itemId.toString()}>
          {ItemButtonContent()}
        </SortableItem>
      ) : (
        ItemButtonContent()
      )}

      {showRemoveIcon && !isDisabled && !draggedItemId && (
        <Box
          csx={removeIconStyles}
          onClick={() => onRemoveClick && onRemoveClick(itemId as number)}>
          <Icon name="MdClear" color="textWhite" size="20px" />
        </Box>
      )}
      {!showRemoveIcon && showEditIcon && !isDisabled && (
        <Box csx={editIconStyles} onClick={onEditClick}>
          <Icon name="MdEdit" size="20px" />
        </Box>
      )}
      {showSwitch && !isSelected && !showRemoveIcon && !draggedItemId && (
        <Box csx={{ position: 'absolute', right: 0, top: 6, zIndex: 2 }}>
          <Switch
            size="small"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        </Box>
      )}
    </Box>
  );
};

const MemoizedItemButton = memo(ItemButton, (prev, next) => {
  return (
    prev.isSelected === next.isSelected &&
    prev.title === next.title &&
    prev.inventoryLabel === next.inventoryLabel &&
    prev.selectedQuantity === next.selectedQuantity &&
    prev.selectedSize === next.selectedSize &&
    prev.isMultipleQuantity === next.isMultipleQuantity &&
    prev.totalPrice === next.totalPrice &&
    prev.priceDifference === next.priceDifference &&
    prev.nextPrice === next.nextPrice &&
    prev.showHeader === next.showHeader &&
    prev.showFooter === next.showFooter &&
    prev.hidePrice === next.hidePrice &&
    prev.quantityOverride === next.quantityOverride &&
    prev.isHidden === next.isHidden &&
    isEqual(prev.containerCsx, next.containerCsx) &&
    isEqual(prev.boxProps, next.boxProps) &&
    isEqual(prev.textCsx, next.textCsx) &&
    isEqual(prev.textColor, next.textColor) &&
    isEqual(prev.backgroundColor, next.backgroundColor) &&
    isEqual(prev.headerTextProps, next.headerTextProps) &&
    isEqual(prev.customTag, next.customTag) &&
    prev.onChange === next.onChange &&
    prev.onIncrement === next.onIncrement &&
    prev.onDecrement === next.onDecrement &&
    prev.onTotalPriceChanged === next.onTotalPriceChanged &&
    prev.image === next.image &&
    prev.showStatus === next.showStatus &&
    prev.isStatusActive === next.isStatusActive &&
    prev.t === next.t &&
    isEqual(prev.draggable, next.draggable)
  );
});

export default MemoizedItemButton;
