import { MQ_MIN_SMALL, ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const elementsContainer = css({
  display: 'flex',
  justifyContent: 'center',
  gap: '11px',
  marginBlock: '1px',
  [MQ_MIN_SMALL]: {
    gap: '16px',
  },
});

export const codeElementStyles = (theme: Theme) =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '30px',
    width: '100%',
    maxWidth: '60px',
    height: '50px',
    backgroundColor:
      theme.mode === ThemeModes.DARK ? theme.colors.lightestGrey : 'white',
    border: `2px solid ${theme.colors.lightGrey}`,
    userSelect: 'none',
    caretColor: 'transparent',
    overflow: 'hidden',
    borderRadius: '5px',
    textAlign: 'center',
    '::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    // text props
    fontSize: theme.fontSizes.subtitle,
    fontWeight: theme.fontWeights.medium,
    color: theme.colors.text,
    // css
    ':focus': {
      border: `2px solid ${theme.colors.semanticBlue}`,
      outline: 'none',
    },
    '&.error': {
      border: `2px solid ${theme.colors.semanticRed}`,
      ':focus': {
        border: `2px solid ${theme.colors.semanticBlue}`,
      },
    },
    ':disabled': {
      opacity: 0.6,
      color: theme.colors.semanticGrey,
      backgroundColor: 'transparent',
    },
    [MQ_MIN_SMALL]: {
      width: '100%',
    },
  });
