import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import { RootState } from '@app/state/store';
import { displayFlexRowStyles } from '@app/theme/commonStyles';

import {
  TDifferences,
  actionCreatorsTableLayout,
  ignoredFieldsToSave,
} from '@westondev/tableturn-core';
import { diff } from 'deep-diff';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IUndoRedoButtons {
  t: WithTranslation['t'];
  isMobile: boolean;
}

const UndoRedoButtons = ({ t, isMobile }: IUndoRedoButtons) => {
  // Redux
  const { undoList, redoList } = useSelector(
    (state: RootState) => state.tableLayout.currentEditLayout,
  );

  const savedData = useSelector(
    (state: RootState) => state.tableLayout.savedData,
  );
  const currentEditData = useSelector(
    (state: RootState) => state.tableLayout.currentEditData,
  );

  const { undoLastElementChange, redoLastElementChange } = bindActionCreators(
    actionCreatorsTableLayout,
    useDispatch(),
  );

  // Local state
  const areAllChangesSaved = useMemo(() => {
    const differences = diff(savedData, currentEditData);
    if (differences) {
      const filteredDifferences = (differences as TDifferences).filter(
        difference => {
          return !ignoredFieldsToSave.includes(difference.path[3]);
        },
      );
      return filteredDifferences.length === 0 && undoList.length === 0;
    }
    return true;
  }, [savedData, currentEditData, undoList.length]);

  const handleUndoLastChange = () => {
    undoLastElementChange();
  };

  const handleRedoLastChange = () => {
    redoLastElementChange();
  };

  return (
    <Box csx={[displayFlexRowStyles, { gap: '10px', flexWrap: 'wrap' }]}>
      <>
        <ToolTip content="Undo">
          <Button
            disabled={undoList.length > 0 ? false : true}
            csx={{ width: '50px' }}
            onClick={handleUndoLastChange}
            icon={<Icon name="MdUndo" />}
          />
        </ToolTip>
        <ToolTip content="Redo">
          <Button
            disabled={redoList.length > 0 ? false : true}
            csx={{ width: '50px' }}
            onClick={handleRedoLastChange}
            icon={<Icon name="MdRedo" />}
          />
        </ToolTip>

        {!isMobile && (
          <Box>
            {areAllChangesSaved && (
              <Box csx={[displayFlexRowStyles, { gap: '5px' }]}>
                <Icon name="MdCheckCircle" color="lightGrey" />
                <Typography color="semanticGrey">
                  {t('validations.allChangesSaved')}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
    </Box>
  );
};

export default UndoRedoButtons;
