import useFiltersStateRestoration from '@app/hooks/useFiltersRestoration';
import { scrollBarStyles } from '@app/theme/commonStyles';
import { BREAKPOINTS } from '@app/theme/themes';
import { MQ_MAX_SMALL } from '@app/theme/types';
import { SortOptions, filter, useSort } from '@westondev/tableturn-core';
import React, { useCallback, useEffect, useState } from 'react';
import ActionButtons from '../ActionButtons';
import { IActionButtons } from '../ActionButtons/types';
import Box from '../Box';
import Button from '../Button';
import Card from '../Card';
import Divider from '../Divider';
import Dropdown from '../Dropdown';
import {
  ELabelVariants,
  TLabelVariants,
  TSingleSelectDropdown,
} from '../Dropdown/SingleSelectDropdown/types';
import FiltersDropdown from '../FiltersDropdown';
import Grid from '../Grid';
import Icon from '../Icon';
import NoElementsFound from '../NoElementsFound';
import SearchInput from '../SearchInput';
import SortButton from '../SortButton';
import ToolTip from '../ToolTip';

type PartialParam = Partial<{
  equal: number;
  greaterThan: number;
  includes: number;
}>;

type IParam = undefined | string | PartialParam;

interface IFilterParams {
  title: string;
  [key: string]: IParam;
}

export type IMainScreenContainerDropdown<T> = {
  id: keyof T;
  data: TSingleSelectDropdown['data'];
  getData?: (params: IFilterParams) => TSingleSelectDropdown['data'];
  label: TSingleSelectDropdown['label'];
  labelVariant?: TLabelVariants;
  defaultValue?: number;
  dataExample: unknown;
  getIsDisabled?: (
    data: TSingleSelectDropdown['data'],
    params: IFilterParams,
  ) => boolean;
};

export type IMainScreenContainer<T extends { title: string }> = {
  data: T[];
  dropdowns?: IMainScreenContainerDropdown<T>[];
  sortAvailable?: boolean;
  renderItem: (item: T, index: number) => JSX.Element;
  breakpoint?: keyof typeof BREAKPOINTS;
  optionList?: IActionButtons['optionList'] | React.ReactNode;
  optionDisabled?: boolean;
  onOptionClickDisabled?: IActionButtons['onClickDisabled'];
  noItemsText?: string;
};

const EMPTY_ARRAY = [] as IMainScreenContainerDropdown<{ title: string }>[];
const MainScreenContainer = <T extends { title: string }>({
  data,
  dropdowns = EMPTY_ARRAY,
  sortAvailable,
  renderItem,
  breakpoint = 'small',
  optionList,
  optionDisabled,
  onOptionClickDisabled,
  noItemsText,
}: IMainScreenContainer<T>) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [sort, setSort] = useState<null | SortOptions>(null);
  const onSort = useSort<T>('title');
  const [sortedList, setSortedList] = useState<T[]>([]);
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    title: '',
  });

  const { seFiltersState, filtersState } = useFiltersStateRestoration();

  useEffect(() => {
    setSortedList(
      filter<T>(
        data,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        sort,
        onSort,
      ),
    );
    seFiltersState({ ...filterParams, sort });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, sort, data]);

  useEffect(() => {
    if (filtersState && Object.keys(filtersState).length) {
      const { sort: _sort, ...filters } = filtersState;
      setFilterParams(filters);
      setInputValue(filtersState.title || '');
      setSort(_sort || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth > parseFloat(BREAKPOINTS[breakpoint as 'small'].max)
      ) {
        setShowTooltip(false);
      } else if (showTooltip === false) {
        setShowTooltip(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showTooltip, breakpoint]);

  const handleActiveFilter = useCallback(
    (v: number, id: keyof T, dataValue: unknown) => {
      if (!v)
        return setFilterParams(prev => ({
          ...prev,
          [id]: undefined,
        }));
      return setFilterParams(prev => ({
        ...prev,
        [id]: Array.isArray(dataValue) ? { includes: v } : { equal: v },
      }));
    },
    [],
  );

  const closeTooltip = () => {
    setIsTooltipActive(false);
  };

  useEffect(() => {
    if (!dropdowns.length) return;

    if (!filtersState || !Object.keys(filtersState).length) {
      dropdowns.forEach(dropdown => {
        if (!filterParams[dropdown.id]?.includes) {
          handleActiveFilter(
            dropdown.defaultValue || 0,
            dropdown.id,
            dropdown.dataExample,
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdowns, filtersState]);

  useEffect(() => {
    if (!dropdowns.length) {
      return setFilterParams(prev => ({ title: prev.title }));
    }
  }, [dropdowns]);

  //FIXME: try to change the values of the dropdowns to fill the remaining space

  return (
    <>
      <Card
        csx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
        }}>
        <Grid columnGap={15} rowGap={15}>
          <Grid.Item mb={12} csx={{ padding: '15px' }}>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item
                mb={8}
                sm={7}
                md={4}
                lg={4}
                mbOffset={0}
                smOffset={1}
                mdOffset={0}>
                <Box
                  csx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    height: '100%',
                  }}>
                  <SearchInput
                    onChange={setInputValue}
                    debounceTimeout={300}
                    onDebounce={text =>
                      setFilterParams(prev => ({ ...prev, title: text }))
                    }
                    value={inputValue}
                  />
                </Box>
              </Grid.Item>
              {!showTooltip && (
                <Grid.Item
                  mb={1}
                  mbOffset={0}
                  mdOffset={
                    5 - dropdowns.length * 4 < 0 ? 0 : 5 - dropdowns.length * 4
                  }
                  lgOffset={
                    6 - dropdowns.length * 3 < 0 ? 0 : 6 - dropdowns.length * 3
                  }
                  xlOffset={
                    6 - dropdowns.length * 2 < 0 ? 0 : 6 - dropdowns.length * 2
                  }>
                  {sortAvailable && (
                    <Box
                      csx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}>
                      <SortButton onSort={sortOption => setSort(sortOption)} />
                    </Box>
                  )}
                </Grid.Item>
              )}
              {showTooltip && (
                <Grid.Item
                  csx={{
                    [MQ_MAX_SMALL]: {
                      display: 'none',
                    },
                  }}
                  sm={2}
                  md={0}
                />
              )}
              <>
                {!showTooltip &&
                  dropdowns.map((dropdown, index) => {
                    const dropdownData =
                      dropdown.getData?.(filterParams) ?? dropdown.data;
                    const isDisabled =
                      dropdown.getIsDisabled?.(dropdownData, filterParams) ??
                      false;
                    return (
                      <Grid.Item
                        key={`dropdown-${index}`}
                        mb={2}
                        sm={3}
                        md={4}
                        lg={3}
                        xl={2}>
                        <Box
                          csx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            height: '100%',
                          }}>
                          <Dropdown
                            label={dropdown.label}
                            labelVariant={
                              dropdown.labelVariant || ELabelVariants.INSIDE
                            }
                            data={dropdownData}
                            isDisabled={isDisabled}
                            value={
                              (filterParams[dropdown.id] as PartialParam)
                                ?.equal ||
                              (filterParams[dropdown.id] as PartialParam)
                                ?.includes ||
                              0
                            }
                            onChange={value =>
                              handleActiveFilter(
                                value,
                                dropdown.id,
                                dropdown.dataExample,
                              )
                            }
                            csx={{ minWidth: 0 }}
                          />
                        </Box>
                      </Grid.Item>
                    );
                  })}
              </>
              {showTooltip && (
                <Grid.Item mb={2} sm={2} md={4} lg={3} xl={2}>
                  <ToolTip
                    isToolTipActive={isTooltipActive}
                    onClickOutside={closeTooltip}
                    content={
                      <FiltersDropdown
                        sortValue={sort}
                        data={dropdowns.map(dropdown => ({
                          label: dropdown.label,
                          value:
                            (filterParams[dropdown.id] as PartialParam)
                              ?.equal ||
                            (filterParams[dropdown.id] as PartialParam)
                              ?.includes ||
                            0,
                          data: dropdown.data,
                          onChange: value =>
                            handleActiveFilter(
                              value,
                              dropdown.id,
                              dropdown.dataExample,
                            ),
                          defaultValue: dropdown.defaultValue,
                        }))}
                        onSortChange={value => {
                          setSort(value as SortOptions);
                        }}
                        onDone={closeTooltip}
                      />
                    }
                    mode="click"
                    dismissOnContentClick={false}>
                    <Button
                      variant="secondary"
                      csx={{ width: '100%' }}
                      icon={<Icon name="MdFilterAlt" />}
                      onClick={() => setIsTooltipActive(true)}
                    />
                  </ToolTip>
                </Grid.Item>
              )}

              <Grid.Item mb={2} sm={2} md={2} lg={1}>
                {Array.isArray(optionList) ? (
                  <Box
                    csx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                    <ActionButtons
                      optionList={optionList}
                      disabled={optionDisabled}
                      onClickDisabled={onOptionClickDisabled}
                    />
                  </Box>
                ) : (
                  optionList
                )}
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
        <Divider />
        <Box
          id="items-list"
          csx={[{ padding: '15px', overflowY: 'auto' }, scrollBarStyles]}>
          <Grid columnGap={15} rowGap={15}>
            {sortedList.length > 0 ? (
              sortedList.map((element, index) => (
                <Grid.Item
                  mb={6}
                  sm={4}
                  md={4}
                  lg={3}
                  xl={2}
                  key={`element-${index}`}>
                  {renderItem(element, index)}
                </Grid.Item>
              ))
            ) : noItemsText ? (
              <Grid.Item mb={12}>
                <NoElementsFound
                  text={noItemsText}
                  csx={{
                    padding: '30px',
                  }}
                  showBorder
                />
              </Grid.Item>
            ) : null}
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default MainScreenContainer;
