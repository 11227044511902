import { RootState, store } from '@app/state/store';
import {
  CoreRootState,
  apiEndpoints,
  generateUniqueUserPinCore,
  pinAlreadyExistsCore,
} from '@westondev/tableturn-core';
import { apiCall } from '../apiCall';
import { CallMethods } from '@app/constants';

export const pinAlreadyExists = (
  pin: string,
  currentOrganizationUserId: number,
  userEmail?: string,
) =>
  pinAlreadyExistsCore(
    pin,
    currentOrganizationUserId,
    store.getState() as CoreRootState & RootState,
    userEmail,
  );

export const generateUniqueUserPin = () =>
  generateUniqueUserPinCore(store.getState() as CoreRootState & RootState);

export const checkExistingUser = (email: string) => {
  const organizationId = store.getState().app.organizationId;

  const url = apiEndpoints.settings.userExists(
    organizationId,
    email.toLowerCase(),
  );

  return apiCall(CallMethods.GET, url, true)
    .then(
      (
        userData: {
          id: number;
          organizationUserId: number;
          email: string;
          firstName: string;
          lastName: string;
          phoneNumber: string;
          password: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          deletedAt?: string | null;
          isUserVerifiedToTableTurn?: boolean;
        } | null,
      ) => userData,
    )
    .catch(err => console.error(err));
};
