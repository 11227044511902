import Switch from '@app/components/common/Switch';
import { ISwitch } from '@app/components/common/Switch/types';
import { MassUpdateValues } from '@westondev/tableturn-core';

const SwitchMassUpdate = (
  props: Omit<ISwitch, 'checked' | 'showNoChanges' | 'showNeutral'> & {
    checked: boolean | MassUpdateValues;
    isMassUpdate?: boolean;
    onMassUpdateChange?: (checked: boolean | MassUpdateValues) => void;
  },
) => {
  const isMassUpdateNeutral = props.checked === MassUpdateValues.NO_CHANGES;

  const { isMassUpdate, onMassUpdateChange } = props;

  const getISChecked = () => {
    if (isMassUpdateNeutral) {
      return false;
    } else return props.checked as boolean;
  };
  const onChange = (switchChecked: boolean) => {
    if (isMassUpdate) {
      switch (props.checked) {
        case MassUpdateValues.NO_CHANGES:
          return onMassUpdateChange?.(true);
        case true:
          return onMassUpdateChange?.(false);
        case false:
          return onMassUpdateChange?.(MassUpdateValues.NO_CHANGES);
      }
    }

    props.onChange?.(switchChecked);
  };
  return (
    <Switch
      {...props}
      onChange={onChange}
      checked={getISChecked()}
      showNoChanges={isMassUpdate && isMassUpdateNeutral}
    />
  );
};

export default SwitchMassUpdate;
