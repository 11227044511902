import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { css } from '@emotion/react';

interface IQuantityButtons {
  increment?: () => void;
  decrement?: () => void;
}

const ButtonStyles = css({
  borderRadius: '0',
  borderBottom: 0,
  borderLeft: 0,
  borderWidth: '1px',
});

const QuantityButtons = ({ increment, decrement }: IQuantityButtons) => {
  return (
    <>
      <Button
        variant="active"
        icon={<Icon name="MdRemove" />}
        onClick={e => {
          e.stopPropagation();
          if (decrement) {
            decrement();
          }
        }}
        csx={[ButtonStyles, { borderBottomLeftRadius: 8 }]}
      />
      <Button
        variant="active"
        icon={<Icon name="MdAdd" />}
        onClick={e => {
          e.stopPropagation();
          if (increment) {
            increment();
          }
        }}
        csx={[ButtonStyles, { borderRight: 0, borderBottomRightRadius: 8 }]}
      />
    </>
  );
};

export default QuantityButtons;
