import {
  IModifierGroupPricingVersionFront,
  TModifierGroupsDiff,
} from '@westondev/tableturn-core';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { WithTranslation } from 'react-i18next';
import { pricingVersionsButtonsStyles } from './styles';

interface IPricingVersionsButtons extends WithTranslation {
  pricingVersions: IModifierGroupPricingVersionFront[];
  selectedPricingVersion: number;
  onPress: (newPricingVersionIndex: number) => void;
  currentFormattedDifferences: TModifierGroupsDiff;
}

const PricingVersionsButtons = ({
  pricingVersions,
  selectedPricingVersion,
  onPress,
  currentFormattedDifferences,
  t,
}: IPricingVersionsButtons) => {
  return (
    <Box
      csx={{
        position: 'relative',
        top: 20,
        cursor: 'pointer',
        overflowX: 'auto',
      }}>
      <Box csx={{ display: 'flex', minWidth: '1000px' }}>
        {pricingVersions.map((pricingVersion, index) => (
          <Box
            key={`pricingVersion_${index}`}
            onClick={() =>
              selectedPricingVersion === index ? null : onPress(index)
            }
            csx={[
              pricingVersionsButtonsStyles(
                pricingVersion.action as 'add' | 'remove' | 'update',
                selectedPricingVersion === index,
                selectedPricingVersion,
                index,
                pricingVersions.length,
              ),
            ]}>
            <Box className="content">
              <Typography className="actionText" variant="caption">
                {pricingVersion.action === 'add'
                  ? t('app.modals.modifierItemsDiffModal.newPricingVersion')
                  : pricingVersion.action === 'remove'
                  ? t('app.modals.diffModal.removed')
                  : `${
                      pricingVersion.totalOfChanges +
                      currentFormattedDifferences.totalModifierItemsChanges
                    } ${
                      pricingVersion.totalOfChanges +
                        currentFormattedDifferences.totalModifierItemsChanges >
                      1
                        ? t('app.modals.diffModal.changes')
                        : t('app.modals.diffModal.change')
                    }`}
              </Typography>
            </Box>
            <Typography
              color={
                selectedPricingVersion === index
                  ? 'persistentSemanticBlue'
                  : 'black'
              }
              fontWeight="medium">
              {pricingVersion.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PricingVersionsButtons;
