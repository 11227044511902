import { Theme, css } from '@emotion/react';

export const selectLocationRowStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.lightGrey,
    height: 50,
    cursor: 'pointer',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      width: 338,
    },
    '& .icon': {
      marginLeft: 20,
      marginRight: 16,
    },
  });
