import { ICategoryCountMap } from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/AssociationsSection/types';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import {
  ICategory,
  menuTypeAssignedCategoriesFactoryCore,
} from '@westondev/tableturn-core';
import i18n from '../../../../../i18n.config';

const menuTypeAssignedCategoriesFactory = (
  menuTypeCategoryAssociationIds: number[],
  categories: Record<number, ICategory>,
  categoryCount: ICategoryCountMap,
  onClick: (id: number, text: string, openInNewTab?: boolean) => void,
  onIsLink: (id: number) => void,
): IItemCard[] => <IItemCard[]>menuTypeAssignedCategoriesFactoryCore(
    menuTypeCategoryAssociationIds,
    categories,
  ).map(menuTypeCategory => ({
    ...menuTypeCategory,
    isActive: menuTypeCategory?.status,
    showStatus: true,
    tag: `${i18n.t('components.menuCard.tag.itemCount', {
      count: categoryCount.items[menuTypeCategory.id] ?? 0,
    })} | ${i18n.t('components.menuCard.tag.comboCount', {
      count: categoryCount.combos[menuTypeCategory.id] ?? 0,
    })}`,
    onClick: (openInNewTab?: boolean) =>
      onClick(menuTypeCategory.id, menuTypeCategory.title, openInNewTab),
    isLink: onIsLink(menuTypeCategory.id),
  }));

export default menuTypeAssignedCategoriesFactory;
