import { Theme, css } from '@emotion/react';

export const roleButtonStyles = (theme: Theme) =>
  css({
    height: '80px',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.persistentSemanticBlue}`,
    p: {
      textAlign: 'center',
    },
    borderRadius: '10px',
    cursor: 'pointer',
    filter: 'none !important',
    ':hover': {
      backgroundColor: theme.colors.lighterBlue,
      border: `2px solid ${theme.colors.semanticBlue}`,
      p: {
        color: theme.colors.semanticBlue,
        fontWeight: theme.fontWeights.medium,
      },
    },
    display: 'flex',
    flexDirection: 'column',
  });
