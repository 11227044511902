import GenericSelectionModal from '../GenericSelectionModal';
import { IGenericSelectionModal } from '../GenericSelectionModal/types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';
import { ICustomSelectionModal } from '../types';

const ModifierGroupsSelectionModal = <T,>({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  btnSuccessText,
  onFilter,
  ...others
}: ICustomSelectionModal<T>) => {
  return (
    <GenericSelectionModal
      {...others}
      onFilter={onFilter as IGenericSelectionModal<T>['onFilter']}
      onPress={() => null}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.modifierGroups.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.modifierGroups.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.modifierGroups.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.modifierGroups.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.modifierGroups.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={false}
      showSelectedButtons={true}
      btnSuccessText={btnSuccessText}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}
    />
  );
};

export default ModifierGroupsSelectionModal;
