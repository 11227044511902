import ActionButtons from '@app/components/common/ActionButtons';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import SwitchMassUpdate from '@app/components/common/SwitchMassUpdate';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { modifierGroupsFactory } from '@app/helpers/factories/menu/cardFactories/modifierGroups/modifierGroupsFactory';
import { generateNewItemModifierGroup } from '@app/helpers/menu/buckets/items';
import { ISectionData } from '@app/hocs/types';
import {
  selectCurrentMenuType,
  selectModifierGroups,
  selectModifierItems,
} from '@app/state/menu/menuSelectors';
import { useTheme } from '@emotion/react';
import {
  FormMode,
  IMassUpdate,
  IMenuTypeVersion,
  IModifierGroupsItems,
  IWithMenuTypeVersionId,
  MassUpdateValues,
  TAllModsBehavior,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { cloneDeep, pickBy, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModifierGroup from '../ModifierGroup';
import { SPACING } from '../styles';
import { ALL_MODS_OPTIONS } from './types';

type IAllModsSection = WithTranslation & ISectionData & IWithMenuTypeVersionId;

const AllModsSection = ({
  menuTypeVersionId,
  t,
  itemData,
  sectionId,
  mode,
}: IAllModsSection) => {
  const {
    updateItem: setValue,
    handleHideModifierGroup: _handleHideModifierGroup,
    handleDeleteModifierGroup: _handleDeleteModifierGroup,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const { showToast } = bindActionCreators(actionCreatorsApp, useDispatch());

  const currentMenuModifierGroups = useSelector(selectModifierGroups);
  const currentMenuModifierItems = useSelector(selectModifierItems);
  const menuTypeMode = useSelector(selectCurrentMenuType);

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const theme = useTheme();

  const modifierGroups =
    itemData && menuTypeVersionId !== 0 ? itemData.modifierGroups : {};

  const allModsBehaviorError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.modifierGroups`,
  );

  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const closeModal = () => {
    setOpenSelectionModal(false);
  };

  const allModsOptions = useMemo(
    () =>
      Object.values(ALL_MODS_OPTIONS).map((option, index) => ({
        label: t(
          `menuScreen.itemDetails.modifierGroupsSection.allMods.allModsBehavior.options.${option}`,
        ),
        value: index + 1,
        isDisabled:
          option === TAllModsBehavior.ASSOCIATED
            ? Object.keys(itemData?.modifierGroups || {}).length === 0
            : false,
      })),
    [t, itemData?.modifierGroups],
  );

  const allModsBehavior = itemData?.allModsBehavior;
  const premadeModifierGroups = useMemo(
    () =>
      pickBy(
        itemData?.modifierGroups || {},
        modifierGroup => modifierGroup.isAllMod,
      ),
    [itemData?.modifierGroups],
  );

  const modifierGroupsFormatted = useMemo(
    () =>
      premadeModifierGroups
        ? sortBy(
            modifierGroupsFactory(premadeModifierGroups),
            premadeModifierGroup => premadeModifierGroup.sortPriority,
          )
        : [],
    [premadeModifierGroups],
  );

  const premadeModifierGroupIds = useMemo(
    () =>
      modifierGroupsFormatted.map(
        modifierGroupFormatted => `${modifierGroupFormatted.modifierGroupId}`,
      ),
    [modifierGroupsFormatted],
  );

  const handleHideModifierGroup = (
    modifierGroupId: number,
    active: boolean,
  ) => {
    _handleHideModifierGroup(
      modifierGroupId,
      active,
      menuTypeVersionId,
      sectionId,
    );
  };

  const handleDeleteModifierGroup = (
    modifierGroupId: number,
    name: string,
    active: boolean,
  ) => {
    _handleDeleteModifierGroup(
      modifierGroupId,
      name,
      active,
      menuTypeVersionId,
      sectionId,
    );
  };

  const isDisabled = isMassUpdate
    ? (itemData as IMassUpdate)?.showAllMods === MassUpdateValues.NO_CHANGES
      ? true
      : !(itemData as IMassUpdate)?.showAllMods
    : !(itemData as IMenuTypeVersion)?.showAllMods;

  const handleOnAssociate = (modifiersSelected: IItemSelection[]) => {
    try {
      allModsBehaviorError?.error && allModsBehaviorError?.clear();
      let clonedModifierGroups = cloneDeep(modifierGroups);
      const clonedPremadeModifierGroups = cloneDeep(premadeModifierGroups);

      const nextSortPriority = modifierGroupsFormatted.length + 1;

      modifiersSelected.forEach(modifierSelected => {
        const modifierGroupId = Number(modifierSelected.id);
        if (clonedModifierGroups[modifierGroupId]) {
          clonedModifierGroups[modifierGroupId].isAllMod = true;
        } else {
          const newModifierGroup = generateNewItemModifierGroup(
            [modifierSelected],
            modifierGroups,
            currentMenuModifierGroups,
            currentMenuModifierItems,
            true,
          );
          clonedModifierGroups = {
            ...clonedModifierGroups,
            ...newModifierGroup,
          };
        }
      });

      const newModifierGroups: { [key: number]: IModifierGroupsItems } = {
        ...clonedModifierGroups,
        ...clonedPremadeModifierGroups,
      };

      modifierGroupsFormatted.forEach((modifierGroup, index) => {
        newModifierGroups[modifierGroup.modifierGroupId].sortPriority =
          index + 1;
      });

      modifiersSelected.forEach((modifierSelected, index) => {
        newModifierGroups[Number(modifierSelected.id)].sortPriority =
          nextSortPriority + index;
      });
      setValue(
        {
          modifierGroups: newModifierGroups,
        },
        sectionId,
        menuTypeVersionId,
      );
    } catch (error) {
      showToast({
        title: 'Error',
        description: 'Error on Mapping Modifier Groups',
        isActive: true,
        type: 'error',
      });
    } finally {
      closeModal();
    }
  };

  const isPremade = allModsBehavior === TAllModsBehavior.PREMADE;

  return (
    <>
      <SelectionModal
        type="modifierGroups"
        detailsScreenProps={{
          currentRelationsIds: premadeModifierGroupIds,
          wantedEntity: 'modifierGroups',
        }}
        onAssociate={handleOnAssociate}
        onModalClose={closeModal}
        active={openSelectionModal}
      />

      <Box
        csx={{
          margin: `0 ${SPACING} 10px ${SPACING}`,
        }}>
        <DetailsSubtitle noMargin>
          {t('menuScreen.itemDetails.modifierGroupsSection.allMods.title')}
        </DetailsSubtitle>
        <Typography variant="caption">
          {t(
            'menuScreen.itemDetails.modifierGroupsSection.allMods.description',
          )}
          {' ('}
          <span
            style={{
              borderRadius: 100,
              minWidth: '8px',
              maxWidth: '8px',
              minHeight: '8px',
              maxHeight: '8px',
              display: 'inline-block',
              backgroundColor: theme.colors.persistentSemanticBlue,
            }}></span>
          &#41;.
        </Typography>
      </Box>

      <Box csx={{ display: 'flex', padding: `0 ${SPACING}` }}>
        <Box
          csx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
          }}>
          <Box>
            <SwitchMassUpdate
              checked={
                isMassUpdate
                  ? (itemData as IMenuTypeVersion)?.showAllMods
                  : !isDisabled
              }
              onChange={showAllMods => {
                setValue({ showAllMods }, sectionId, menuTypeVersionId);
              }}
              label={t(
                'menuScreen.itemDetails.modifierGroupsSection.allMods.showAllMods',
              )}
              isMassUpdate={isMassUpdate}
              onMassUpdateChange={showAllMods => {
                setValue({ showAllMods }, sectionId, menuTypeVersionId);
              }}
            />
          </Box>

          <Divider
            direction="vertical"
            csx={{
              padding: `${SPACING} 30px`,
              height: '100%',
              minHeight: '70px',
            }}
            lineCsx={{ borderColor: theme.colors.lightGrey }}
          />

          <Typography>
            {t(
              'menuScreen.itemDetails.modifierGroupsSection.allMods.allModsBehavior.label',
            )}
          </Typography>
          <Box csx={{ display: 'flex', width: '300px', paddingLeft: '15px' }}>
            <Dropdown
              placeholder={t(
                'menuScreen.itemDetails.modifierGroupsSection.allMods.allModsBehavior.label',
              )}
              data={[
                ...(isMassUpdate
                  ? [
                      [
                        {
                          label: t('commonTexts.noChanges'),
                          value: -1,
                        },
                      ],
                    ]
                  : []),
                allModsOptions,
              ]}
              onChange={newAllModsBehavior => {
                batch(() => {
                  if (
                    allModsBehavior === TAllModsBehavior.PREMADE &&
                    premadeModifierGroups
                  ) {
                    batch(() => {
                      Object.values(premadeModifierGroups).forEach(
                        premadeModifierGroup => {
                          setValue(
                            { isAllMod: false },
                            sectionId,
                            menuTypeVersionId,
                            premadeModifierGroup.modifierGroupId,
                          );
                        },
                      );
                    });
                  }

                  setValue(
                    {
                      allModsBehavior: isMassUpdate
                        ? newAllModsBehavior === -1
                          ? -1
                          : ALL_MODS_OPTIONS[newAllModsBehavior - 1]
                        : ALL_MODS_OPTIONS[newAllModsBehavior - 1],
                    },
                    sectionId,
                    menuTypeVersionId,
                  );
                });
              }}
              isDisabled={isDisabled}
              value={
                itemData
                  ? isMassUpdate
                    ? allModsBehavior === -1
                      ? -1
                      : ALL_MODS_OPTIONS.indexOf(
                          allModsBehavior as TAllModsBehavior,
                        ) + 1 || 0
                    : ALL_MODS_OPTIONS.indexOf(
                        allModsBehavior as TAllModsBehavior,
                      ) + 1 || 0
                  : 0
              }
            />
          </Box>
        </Box>

        {isPremade && (
          <ActionButtons
            optionList={[
              {
                text: 'add modifier group',
                handler: () => setOpenSelectionModal(true),
              },
            ]}
          />
        )}
      </Box>

      {isPremade && (
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            padding: `10px ${SPACING} ${SPACING} ${SPACING}`,
            rowGap: '15px',
          }}>
          {modifierGroupsFormatted.length > 0 ? (
            modifierGroupsFormatted.map((modifierGroup, index) => (
              <ModifierGroup
                menuTypeMode={menuTypeMode}
                mode={mode}
                isPreMadeModifierGroup
                key={`modifier_group_premade_${modifierGroup.modifierGroupId}`}
                onDeleteModifierGroupPress={(modifierGroupId, name) =>
                  handleDeleteModifierGroup(
                    modifierGroupId,
                    name,
                    modifierGroup.active && modifierGroup.inheritedActive,
                  )
                }
                menuTypeVersionId={menuTypeVersionId}
                isFirst={index === 0}
                isLast={index + 1 === modifierGroupsFormatted.length}
                prevModifierGroupId={
                  index > 0
                    ? modifierGroupsFormatted[index - 1].modifierGroupId
                    : -1
                }
                nextModifierGroupId={
                  index < modifierGroupsFormatted.length - 1
                    ? modifierGroupsFormatted[index + 1].modifierGroupId
                    : -1
                }
                handleHideModifierGroup={modifierGroupId =>
                  handleHideModifierGroup(modifierGroupId, modifierGroup.active)
                }
                disabledMessage={t(
                  'menuScreen.itemDetails.modifierGroupsSection.allMods.disabledOverlay',
                )}
                isDisabled={isDisabled}
                {...modifierGroup}
                active
              />
            ))
          ) : (
            <Card.SubCard
              csx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <SectionLayer
                title={
                  !isMassUpdate
                    ? t(
                        'menuScreen.itemDetails.modifierGroupsSection.noModifierGroupsAssignedToThisItem',
                      )
                    : t(
                        'menuScreen.itemDetails.modifierGroupsSection.addModifiersGroupSection.empty',
                      )
                }
              />
            </Card.SubCard>
          )}
        </Box>
      )}
    </>
  );
};

export default AllModsSection;
