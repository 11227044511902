import Box from '../common/Box';
import TTLogoAnimated from '@app/assets/animated/TTLogoAnimated';
import { useEffect } from 'react';
import { logoBackgroundStyles } from './styles';

interface IAppLoader {
  onAnimationEnd?: () => void;
}
const AppLoader = ({ onAnimationEnd }: IAppLoader) => {
  useEffect(() => {
    setTimeout(() => {
      onAnimationEnd && onAnimationEnd();
    }, 1500);
  }, [onAnimationEnd]);

  return (
    <Box csx={logoBackgroundStyles}>
      <Box
        csx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '40px',
        }}>
        <TTLogoAnimated width="100%" />
      </Box>
    </Box>
  );
};

export default AppLoader;
