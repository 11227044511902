import { TCsx } from '@emotion/react';
import { Fragment } from 'react';
import Box from '../Box';
import Card from '../Card';
import Typography from '../Typography';
import {
  cardContainerStyles,
  cardContainerSubCardStyles,
  emptyCardContainerStyles,
} from './styles';

export interface ICardsContainer<T> {
  data: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  noElementsMessage?: string;
  contentCsx?: TCsx;
  csx?: TCsx;
  noCardsPadding?: boolean;
}

const COMMON_MESSAGE = 'No elements';

const CardsContainer = <T,>({
  data,
  renderItem,
  noElementsMessage = COMMON_MESSAGE,
  contentCsx,
  csx,
  noCardsPadding,
}: ICardsContainer<T>) => {
  return (
    <Card.SubCard
      csx={[cardContainerSubCardStyles, csx]}
      noCardsPadding={noCardsPadding}>
      {data.length === 0 && (
        <Box csx={emptyCardContainerStyles}>
          <Typography color="semanticGrey" align="center">
            {noElementsMessage}
          </Typography>
        </Box>
      )}

      <Box csx={[cardContainerStyles, contentCsx]}>
        {data.map((dataElement, index) => (
          <Fragment key={index}>{renderItem(dataElement, index)}</Fragment>
        ))}
      </Box>
    </Card.SubCard>
  );
};

export default CardsContainer;
