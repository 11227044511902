import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import CustomerFacingDisplay from '@app/components/LoggedIn/Settings/RegisterMode/CustomerFacingDisplay';
import EndOfDaySettings from '@app/components/LoggedIn/Settings/RegisterMode/EndOfDaySettings';
import GeneralSettings from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings';
import QuickServiceSettings from '@app/components/LoggedIn/Settings/RegisterMode/QuickServiceSettings';
import TableServiceSettings from '@app/components/LoggedIn/Settings/RegisterMode/TableServiceSettings';
import TagsSettings from '@app/components/LoggedIn/Settings/RegisterMode/TagsSettings';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import useScrollToTop from '@app/hooks/useScrollToTop';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useMatch } from 'react-router-dom';

const RegisterMode = ({ t }: WithTranslation) => {
  const match = useMatch(getPathWithOrgData('/settings/register'));
  const navigate = useNavigateWithOrg();
  const location = useLocation();

  useScrollToTop('layout-content');

  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    useDispatch(),
  );

  const { checkForSettingsChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'register',
  );

  useEffect(() => {
    if (!isSet)
      loadSettingsBucketChangeData({
        setting: 'register',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return (
    <>
      {!match ? (
        <BreadCrumbSettings
          title="Register Mode"
          currentPage={location.pathname
            .split('/')
            .at(-1)
            ?.split('-')
            .join(' ')}
          onClick={() => {
            checkForSettingsChangesAndNavigateWeb(
              () => navigate('/settings/register'),
              () => null,
              navigate,
            );
          }}
          isSticky
        />
      ) : (
        <>
          <HeaderTitle title="Register Mode Settings" />
          <Box>
            <Accordion>
              <AccordionSection
                id="general"
                expandedHeight="70px"
                isExpanded={true}
                title={t(
                  'settingsModule.registerModeSettings.general.title',
                )}
                description={t(
                  'settingsModule.registerModeSettings.general.description',
                )}>
                <GeneralSettings />
              </AccordionSection>
              <AccordionSection
                id="tags"
                expandedHeight="70px"
                title={t('settingsModule.registerModeSettings.tags.title')}
                description={t(
                  'settingsModule.registerModeSettings.tags.description',
                )}>
                <TagsSettings />
              </AccordionSection>
              <AccordionSection
                id="table-service"
                expandedHeight="70px"
                title={t(
                  'settingsModule.registerModeSettings.tableService.title',
                )}
                info={t(
                  'settingsModule.registerModeSettings.tableService.toolTip',
                )}
                description={t(
                  'settingsModule.registerModeSettings.tableService.description',
                )}>
                <TableServiceSettings />
              </AccordionSection>
              <AccordionSection
                id="quick-service"
                expandedHeight="70px"
                title={t(
                  'settingsModule.registerModeSettings.quickService.title',
                )}
                description={t(
                  'settingsModule.registerModeSettings.quickService.description',
                )}>
                <QuickServiceSettings />
              </AccordionSection>
              <AccordionSection
                id="customer-facing-display"
                expandedHeight="70px"
                title={t(
                  'settingsModule.registerModeSettings.customerFacingDisplay.title',
                )}
                description={t(
                  'settingsModule.registerModeSettings.customerFacingDisplay.description',
                )}>
                <CustomerFacingDisplay />
              </AccordionSection>
              <AccordionSection
                id="end-of-day"
                expandedHeight="70px"
                title={t('settingsModule.registerModeSettings.endOfDay.title')}
                description={t(
                  'settingsModule.registerModeSettings.endOfDay.description',
                )}>
                <EndOfDaySettings />
              </AccordionSection>
            </Accordion>
          </Box>
        </>
      )}
      <Outlet />
    </>
  );
};

export default RegisterMode;
