import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import useRootSelector from '@app/hooks/useRootSelector';
import { handleReportsChange } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { OrderType } from '@westondev/tableturn-core';
import { useCallback, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const orderTypeOptionsArray = Object.values(OrderType).map((value, index) => ({
  label: value,
  value: index + 1,
}));
const orderTypeToIndex = Object.values(OrderType).reduce(
  (acc, value, index) => {
    acc[value] = index + 1;
    return acc;
  },
  {} as Record<OrderType, number>,
);
const indexToOrderType = Object.values(OrderType).reduce(
  (acc, value, index) => {
    acc[index + 1] = value;
    return acc;
  },
  {} as Record<number, OrderType>,
);

const ReportOrderTypesFilters = ({ t }: WithTranslation) => {
  const setValues = bindActionCreators(handleReportsChange, useDispatch());
  const orderTypes = useRootSelector(
    state => state.reports.changeData?.data?.orderTypes,
  );

  const orderTypesValueText = useReportsFilterText('orderTypes', orderTypes);

  const orderTypeOptions = useMemo(() => {
    return orderTypeOptionsArray.map(v => ({
      ...v,
      label: t(`reports.fields.orderTypeOptions.${v.label}`),
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentOrderTypesSelected = useMemo(() => {
    const orderTypeIndexSet = new Set(
      orderTypes?.map(s => orderTypeToIndex[s]) ?? [],
    );
    return orderTypeOptions.filter(v => orderTypeIndexSet.has(v.value));
  }, [orderTypeOptions, orderTypes]);

  const handleOnChangeDone = useCallback(
    (selectedSubcategoryOptions: IOption[]) => {
      if (
        !selectedSubcategoryOptions?.length ||
        selectedSubcategoryOptions?.some(v => v.value === 0)
      ) {
        setValues(prev => ({ ...prev, orderTypes: null }));
        return;
      }

      const selectedOrderTypes = selectedSubcategoryOptions.map(
        v => indexToOrderType[v.value],
      );

      setValues(prev => ({ ...prev, orderTypes: selectedOrderTypes }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid.Item mb={12} sm={6} md={3}>
      <Typography csx={labelStyles}>
        {t('reports.fields.orderTypes')}
      </Typography>
      <OptionsModal
        title={t('reports.fields.orderTypes')}
        customText={orderTypesValueText}
        options={orderTypeOptions}
        currentOptionsSelected={currentOrderTypesSelected}
        onChangeDone={handleOnChangeDone}
        clearOptionsLabel={t('commonButtons.selectAll')}
        isSearchable
        returnItemOnChange
        allowMultipleSelection
        buttonProps={{
          csx: {
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
      />
    </Grid.Item>
  );
};

export default ReportOrderTypesFilters;
