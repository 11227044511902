import { bindActionCreators } from '@reduxjs/toolkit';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';
import RearrangeButtons from './RearrangeButtons';

interface IRearrangeSection {
  isFirst: boolean;
  isLast: boolean;
  prevModifierGroupId: number;
  nextModifierGroupId: number;
  modifierGroupId: number;
  menuTypeVersionId: number;
  isPreMadeModifierGroup?: boolean;
}

const SECTION_ID = SectionId.MODIFIER_GROUPS;

const RearrangeSection = ({
  isFirst,
  isLast,
  prevModifierGroupId,
  nextModifierGroupId,
  modifierGroupId,
  menuTypeVersionId,
  isPreMadeModifierGroup = false,
}: IRearrangeSection) => {
  //Redux
  const { rearrangeModifierGroups, rearrangeModifierGroupsToLastOrFirst } =
    bindActionCreators(actionCreatorsMenu, useDispatch());

  const handleRearrangePress = (order: number) => {
    try {
      rearrangeModifierGroups(
        modifierGroupId,
        SECTION_ID,
        order,
        prevModifierGroupId,
        nextModifierGroupId,
        menuTypeVersionId,
      );
    } catch (error) {
      console.error('Error on sorting Modifier Groups:', error);
    }
  };

  const handleToTopRearrangePress = (toTop: boolean) => {
    try {
      rearrangeModifierGroupsToLastOrFirst(
        modifierGroupId,
        SECTION_ID,
        toTop ? 'first' : 'last',
        menuTypeVersionId,
        isPreMadeModifierGroup,
      );
    } catch (error) {
      console.error('Error on sorting Modifier Groups:', error);
    }
  };

  return (
    <RearrangeButtons
      isFirst={isFirst}
      isLast={isLast}
      onArrowUpPress={() => handleRearrangePress(-1)}
      onArrowDownPress={() => handleRearrangePress(1)}
      onDoubleArrowUpPress={() => handleToTopRearrangePress(true)}
      onDoubleArrowDownPress={() => handleToTopRearrangePress(false)}
    />
  );
};

export default RearrangeSection;
