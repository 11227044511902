import { ICardsContainer } from '../CardsContainer/CardsContainer';

export enum EStatusCardsContainer {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export type IStatusCardsContainer<
  T extends { groupStatus: EStatusCardsContainer },
> = {
  selectedStatus: Status;
  activeText?: string;
  inactiveText?: string;
  data: T[];
} & ICardsContainer<T>;

type Status =
  | EStatusCardsContainer.ACTIVE
  | EStatusCardsContainer.INACTIVE
  | 'all';
