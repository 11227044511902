import { ThemeColors } from '@app/theme/types';
import { TCsx, Theme, useTheme } from '@emotion/react';
import { HTMLAttributes } from 'react';
import { iconStyles } from '../Icon/styles';

interface ISpinner extends HTMLAttributes<HTMLOrSVGElement> {
  size?: string;
  color?: ThemeColors;
  strokeWidth?: string;
  csx?: TCsx;
  css?: never;
}

const Spinner = ({
  size,
  color = 'black',
  strokeWidth = '10px',
  csx,
  ...otherProps
}: ISpinner) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      css={[
        size
          ? {
              width: size,
              height: size,
            }
          : iconStyles,
        csx,
      ]}
      {...otherProps}>
      <circle
        cx="50px"
        cy="50px"
        fill="none"
        stroke={
          theme.colors[color as keyof Theme['colors']] ||
          color ||
          theme.colors.darkRed
        }
        strokeWidth={strokeWidth}
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(95.4265 50 50)">
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="spline"
          values="0 50 50;360 50 50"
          dur="1s"
          keySplines="1, 1, 1, 1"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default Spinner;
