import { store } from '@app/state/store';
import {
  ISettingsStore,
  getSettingsMainScreenDataCore,
  CoreRootState,
} from '@westondev/tableturn-core';

export const getSettingsMainScreenData = (entity: keyof ISettingsStore) =>
  getSettingsMainScreenDataCore(
    entity,
    store.getState() as unknown as CoreRootState,
  );
