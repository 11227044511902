import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import Box from '@app/components/common/Box';
import { Outlet } from 'react-router-dom';

interface IMenu {
  noPadding?: boolean;
}

const Menu = ({ noPadding = true }: IMenu) => {
  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}>
      <LayoutContent noPadding={noPadding}>
        <Outlet />
      </LayoutContent>
    </Box>
  );
};

export default Menu;
