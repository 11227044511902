import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import WeekTabs from '@app/components/common/Tabs/WeekTabs';
import { WEEK_TABS_DATA } from '@app/components/common/Tabs/WeekTabs/types';
import Typography from '@app/components/common/Typography';
import { ITypography } from '@app/components/common/Typography/Typography';
import { INVALID_DAYS_INITIAL_STATE } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/initialState';
import { sliderContainerStyles } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/styles';
import { IInvalidDays } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/types';
import { getDayWithErrors } from '@app/helpers/settings/timeBasedMenuTypes';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import { selectCardMenuTypeList } from '@app/state/menu/menuSelectors';
import { defaultMenuTypeTimesSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DayOfWeek,
  HOUR_MS,
  IMenuTypeServiceTime,
  IOnlineOrderingStore,
  ONLINE_MENU_TIME_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  dateSliderHasErrorValue,
  dayOperationalHours,
  generateTabletGeneratedId,
} from '@westondev/tableturn-core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FAKE_OPERATIONAL_DAY_HOURS_DATA } from './fakedata';

interface TOnlineMenuTimesTable {
  id: number;
  menuType: number;
  time: [number, number];
  delete: string;
  bannedRanges: Array<[number, number]>;
  ruleId: number | string;
}

const TEST_MENU_TYPE_ID = 286;
const MS_8_AM = HOUR_MS * 8;
const MS_12_PM = HOUR_MS * 12;
const SECTION_ID = SettingsSectionId.BASIC;

interface IOnlineMenuTimesTable extends WithTranslation {
  data: IOnlineOrderingStore;
}
const OnlineMenuTimesTable = ({ t, data }: IOnlineMenuTimesTable) => {
  // Redux
  const menuTypesFactory = useSelector(selectCardMenuTypeList);
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const currentDefaultMenuTypeTimes = useSelector(defaultMenuTypeTimesSelector);

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >({ tabId: 1, subTabId: null });
  const [invalidDays, setInvalidDaysState] = useState<IInvalidDays>(
    INVALID_DAYS_INITIAL_STATE,
  );

  const invalidDaysRef = useRef(invalidDays);

  const columnHelperCustom = createColumnHelper<TOnlineMenuTimesTable>();

  const selectedDay = useMemo(
    () =>
      activeTab?.tabId
        ? WEEK_TABS_DATA[activeTab?.tabId - 1].dayOfWeek
        : DayOfWeek.MONDAY,
    [activeTab],
  );

  const operationalDayHours = useMemo(
    () => FAKE_OPERATIONAL_DAY_HOURS_DATA,
    [],
  );

  const updateTime = (
    newValue: any,
    id: number,
    field: keyof dayOperationalHours,
  ) => {
    const clonedOnlineMenuTimes = cloneDeep(
      store.getState().settings.changeData.data as IOnlineOrderingStore,
    )?.onlineMenuTimes;

    clonedOnlineMenuTimes[selectedDay][id][field as 'id'] = newValue;

    setValue<IOnlineOrderingStore>(
      {
        onlineMenuTimes: clonedOnlineMenuTimes,
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number, ruleId: number | string) => {
    const defaultMenuTypeTimes = (
      store.getState().settings.changeData.data as IOnlineOrderingStore
    )?.onlineMenuTimes;
    const defaultMenuTypeTimesCloned = cloneDeep(defaultMenuTypeTimes);
    defaultMenuTypeTimesCloned[selectedDay].splice(id, 1);
    batch(() => {
      setValue<IOnlineOrderingStore>(
        {
          onlineMenuTimes: defaultMenuTypeTimesCloned,
        },
        SECTION_ID,
      );
    });
    clearInvalidDayById(ruleId);
  };

  const menuTypesOptions = useMemo(() => {
    const currentMenuTypesOptions = menuTypesFactory.map(value => ({
      value: value.id,
      label: [
        value.title,
        value.isActive ? '' : ` (${t('commonTexts.inactive')})`,
      ].join(''),
      textProps: value.isActive
        ? undefined
        : ({ color: 'persistentSemanticRed' } as ITypography),
    }));

    return currentMenuTypesOptions;
  }, [menuTypesFactory, t]);

  const isDefaultMenuTypeIdAvailable = useMemo(
    () =>
      menuTypesOptions.some(menuType => menuType.value === TEST_MENU_TYPE_ID) ||
      TEST_MENU_TYPE_ID,
    [menuTypesOptions],
  );

  const setInvalidDays = (
    newInvalidDays:
      | IInvalidDays
      | ((prevInvalidDays: IInvalidDays) => IInvalidDays),
  ) => {
    if (typeof newInvalidDays === 'function') {
      setInvalidDaysState(prevInvalidDays => {
        const newInvalidDaysResult = newInvalidDays(prevInvalidDays);
        invalidDaysRef.current = newInvalidDaysResult;
        return newInvalidDaysResult;
      });
    } else {
      invalidDaysRef.current = newInvalidDays;
      setInvalidDaysState(newInvalidDays);
    }
  };

  const clearInvalidDayById = (ruleId: string | number) => {
    setInvalidDays(prevInvalidDays => ({
      ...prevInvalidDays,
      [selectedDay]: prevInvalidDays[selectedDay].filter(
        invalidDay => invalidDay !== ruleId,
      ),
    }));
  };

  const updateTimeMenuType = (
    newValue: any,
    id: number,
    field: keyof IMenuTypeServiceTime,
  ) => {
    const clonedOnlineMenuTimes = cloneDeep(
      store.getState().settings.changeData.data as IOnlineOrderingStore,
    )?.onlineMenuTimes;

    clonedOnlineMenuTimes[selectedDay][id][field as 'id'] = newValue;
    setValue<IOnlineOrderingStore>(
      {
        onlineMenuTimes: clonedOnlineMenuTimes,
      },
      SECTION_ID,
    );
  };

  const timeMenuTypesArray: TOnlineMenuTimesTable[] = useMemo(() => {
    return data?.onlineMenuTimes[selectedDay]
      ? data?.onlineMenuTimes[selectedDay].map((value, key) => {
          const bannedRanges: Array<[number, number]> = data?.onlineMenuTimes[
            selectedDay
          ]
            .slice(0, key)
            .map(prevMenuTypes => [
              getHourMs(prevMenuTypes.startTime),
              getHourMs(prevMenuTypes.endTime),
            ]);
          const time: [number, number] = [
            getHourMs(value.startTime),
            getHourMs(value.endTime),
          ];

          const hasError = dateSliderHasErrorValue(time, bannedRanges);
          if (!hasError) {
            clearInvalidDayById(value.id);
          } else if (!invalidDays[selectedDay].includes(value.id)) {
            setInvalidDays(prevInvalidDays => ({
              ...prevInvalidDays,
              [selectedDay]: [...prevInvalidDays[selectedDay], value.id],
            }));
          }
          return {
            id: key,
            menuType: value.menuTypeId,
            time,
            delete: currentDefaultMenuTypeTimes[selectedDay].some(
              menuTypeTimes => menuTypeTimes.id === value.id,
            )
              ? ''
              : 'canDelete',
            bannedRanges,
            ruleId: value.id,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.onlineMenuTimes, selectedDay, currentDefaultMenuTypeTimes]);

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('menuType', {
        header: 'Menu',
        cell: info => {
          const menuTypeId = info.getValue();
          const hasExistingMenuType = menuTypesOptions.some(
            menuType => menuType.value === menuTypeId,
          );
          return (
            <Dropdown
              onChange={newMenuType =>
                updateTimeMenuType(
                  newMenuType,
                  info.row.original.id,
                  'menuTypeId',
                )
              }
              customText={
                !hasExistingMenuType ? t('commonTexts.notAvailable') : undefined
              }
              placeholder={t(
                'settingsModule.registerModeSettings.general.timeBasedMenuType.menuType',
              )}
              customTextColor={
                !hasExistingMenuType ? 'persistentSemanticRed' : undefined
              }
              data={[menuTypesOptions]}
              value={menuTypeId}
            />
          );
        },
        size: 180,
      }),
      columnHelperCustom.accessor('time', {
        header: 'Time',
        cell: info => {
          const bannedRanges = info.row.original.bannedRanges.sort(
            (a, b) => a[0] - b[0],
          );

          const hasError =
            invalidDaysRef?.current?.[selectedDay] &&
            invalidDaysRef.current[selectedDay].includes(
              info.row.original.ruleId,
            );

          return (
            <Box
              key={`slider-time-${info.row.original.id}`}
              csx={theme => sliderContainerStyles(theme, hasError)}>
              <CustomSlider
                step={900000}
                value={info.getValue()}
                min={0}
                max={86399000}
                showStepMarks={false}
                bannedRanges={bannedRanges}
                valueLabelDisplay="always"
                labelDirection="bottom"
                customValueLabel={currentVale => {
                  return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                    .toUTC()
                    .toFormat('hh:mm a');
                }}
                debounceTimeout={200}
                onValueChange={range => {
                  if (!Array.isArray(range)) {
                    return;
                  }

                  updateTime(
                    dateTimeMsToTodayDate(range[0], true),
                    info.row.original.id,
                    'startTime',
                  );
                  updateTime(
                    dateTimeMsToTodayDate(range[1], true),
                    info.row.original.id,
                    'endTime',
                  );
                }}
                renderLimits={value => (
                  <Typography
                    fontWeight="medium"
                    variant="caption"
                    csx={{ whiteSpace: 'nowrap' }}>
                    {DateTime.fromISO(dateTimeMsToTodayDate(value))
                      .toUTC()
                      .toFormat('hh:mm a')}
                  </Typography>
                )}
              />
            </Box>
          );
        },
        minSize: 300,
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                handleDelete(info.row.original.id, info.row.original.ruleId)
              }
            />
          ) as unknown as string,
        size: 50,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedDay, menuTypesOptions],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const onNewTime = () => {
    const menuTypes = data?.onlineMenuTimes[selectedDay] || [];
    setValue(
      {
        onlineMenuTimes: {
          ...data?.onlineMenuTimes,
          [selectedDay]: [
            ...menuTypes,
            {
              ...ONLINE_MENU_TIME_INITIAL_STATE,
              id: generateTabletGeneratedId(),
              menuTypeId:
                menuTypes.length > 0
                  ? menuTypes[menuTypes.length - 1].menuTypeId
                  : menuTypesOptions[0]?.value,
              startTime: dateTimeMsToTodayDate(MS_8_AM, true),
              endTime: dateTimeMsToTodayDate(MS_12_PM, true),
            },
          ],
        },
      },
      SECTION_ID,
    );
  };

  const invalidDaysObject = useMemo(
    () => getDayWithErrors(operationalDayHours),
    [operationalDayHours],
  );

  const onCopyRules = (selectedDays: Record<DayOfWeek, boolean>) => {
    const clonedDefaultOnlineMenuTimes = cloneDeep(
      (store.getState().settings.changeData.data as IOnlineOrderingStore)
        .onlineMenuTimes,
    );
    Object.entries(selectedDays).forEach(([day, value]) => {
      if (day === selectedDay || !value) {
        return;
      }
      clonedDefaultOnlineMenuTimes[day as 'monday'] = cloneDeep(
        clonedDefaultOnlineMenuTimes[selectedDay as 'monday'],
      );
    });

    setValue<IOnlineOrderingStore>(
      { onlineMenuTimes: clonedDefaultOnlineMenuTimes },
      SECTION_ID,
    );
  };

  return (
    <Grid rowGap={15}>
      <Grid.Item mb={12} sm={6} md={6} lg={4}>
        <Dropdown
          data={[menuTypesOptions]}
          value={data.defaultMenuTypeId}
          label={t(
            'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuType.label',
          )}
          placeholder={t(
            'settingsModule.registerModeSettings.general.timeBasedMenuType.defaultMenuType.label',
          )}
          info="Restaurant Mode"
          customTextColor={
            !isDefaultMenuTypeIdAvailable ? 'persistentSemanticRed' : undefined
          }
          customText={
            !isDefaultMenuTypeIdAvailable
              ? t('commonTexts.notAvailable')
              : undefined
          }
          onChange={defaultMenuTypeId =>
            setValue({ defaultMenuTypeId }, SECTION_ID)
          }
        />
      </Grid.Item>
      <Grid.Item mb={12}>
        <Card.SubCard csx={{ paddingInline: 0, overflow: 'hidden' }}>
          <>
            <Box csx={{ marginBottom: '10px' }}>
              <Typography
                fontWeight="medium"
                color="semanticBlue"
                align="center"
                csx={{ textTransform: 'uppercase' }}>
                {t('settingsModule.onlineSettings.basic.onlineMenuTimes')}
              </Typography>
            </Box>
            <WeekTabs
              activeTab={activeTab}
              onSelectedTabChange={handleOnSelectedTabChange}
              error={invalidDaysObject}
              csx={theme => ({
                borderRadius: 0,
                boxShadow: 'none',
                paddingInline: '5px',
                borderTop: `1px solid ${theme.colors.lighterGrey}`,
              })}
            />
            <Table
              columns={COLUMNS}
              data={data ? timeMenuTypesArray : []}
              cellCsx={{ height: '100px' }}
              containerCsx={{ border: 'none', borderRadius: 0 }}
              align={{
                delete: 'center',
              }}
              alignHeaders={{
                delete: 'center',
              }}
              showShadow={false}
              noDataComponent={
                <Box
                  csx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBlock: '20px',
                  }}>
                  <Typography align="center">
                    {t(
                      'settingsModule.registerModeSettings.general.timeBasedMenuType.noData',
                    )}
                  </Typography>
                </Box>
              }
              styleForSmallerScreens="card"
              newRowComponent={
                <Box
                  csx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}>
                  <DayOfTheWeekPicker
                    selectedDays=""
                    onApply={onCopyRules}
                    disabledDays={[selectedDay]}>
                    {onToggle => (
                      <Button
                        icon={<Icon name="MdFileCopy" />}
                        csx={{ minWidth: '150px' }}
                        onClick={onToggle}
                        disabled={timeMenuTypesArray.length === 0}>
                        {t(
                          'settingsModule.registerModeSettings.general.timeBasedMenuType.copyRules.button',
                        )}
                      </Button>
                    )}
                  </DayOfTheWeekPicker>
                  <Button
                    onClick={onNewTime}
                    variant="primary"
                    csx={{ minWidth: '150px' }}
                    icon={<Icon name="MdAdd" />}>
                    {t(
                      'loggedIn.settingsModule.generalSettings.hoursOfOperation.addTime',
                    )}
                  </Button>
                </Box>
              }
            />
          </>
        </Card.SubCard>
      </Grid.Item>
    </Grid>
  );
};

export default OnlineMenuTimesTable;
