import Box from '../Box';
import { skeletonStyles } from './styles';
import { IBox } from '../Box/Box';

interface ISkeleton extends IBox {}
const Skeleton = ({ csx, ...otherProps }: ISkeleton) => {
  return <Box csx={[skeletonStyles, csx]} {...otherProps} />;
};

export default Skeleton;
