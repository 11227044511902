import { useEffect, useState } from 'react';
import { IOrgInviteData, IOrgInviteProps } from '../types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import LoadingModal from '@app/components/common/LoadingModal';
import { actionCreatorsApp, apiEndpoints } from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiCall } from '@app/helpers/apiCall';
import { CallMethods } from '@app/constants';

const orgInviteTokenValidationHOC = (component: React.FC<IOrgInviteProps>) => {
  const Component = component;

  const WrappedComponent = (props: WithTranslation) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') || '';
    const orgId = searchParams.get('orgId') || '';

    const navigate = useNavigate();

    const { showToast } = bindActionCreators(actionCreatorsApp, useDispatch());

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<IOrgInviteData | null | undefined>();

    useEffect(() => {
      const onError = () => {
        showToast({
          type: 'error',
          title: props.t('authentication.signUpOrgInvite.invalidInviteTitle'),
          description: props.t(
            'authentication.signUpOrgInvite.invalidInviteDescription',
          ),
        });
        navigate('/auth/sign-in');
      };

      if (!token || !orgId) {
        onError();
        return;
      }

      apiCall(
        CallMethods.GET,
        `${apiEndpoints.user.verifyInviteToken()}?token=${token}&orgId=${orgId}`,
        false,
      )
        .then(response => {
          const { organizationName, organizationId, digitalLogoUrl, userId } =
            response;
          setData({ organizationName, organizationId, digitalLogoUrl, userId });
          setIsLoading(false);
        })
        .catch(e => {
          console.error(e);
          onError();
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading)
      return (
        <LoadingModal
          mode="local"
          isLoadingLocal={{ active: isLoading, variant: 'loader' }}
        />
      );

    return <Component {...(data as IOrgInviteData)} {...props} />;
  };

  return WrappedComponent;
};

export default orgInviteTokenValidationHOC;
