import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { css } from '@emotion/react';
import Spinner from '@app/components/common/Spinner';
import Grid from '@app/components/common/Grid';
import { MQ_MIN_SMALL } from '@app/theme/types';
import ButtonExample from '@app/components/DesignSystem/ButtonExample';
import InputExample from '@app/components/DesignSystem/InputExample';
import GridExample from '@app/components/DesignSystem/GridExample';
import CodeVerifierExample from '@app/components/DesignSystem/CodeVerifierExample';
import ToolTipExample from '@app/components/DesignSystem/ToolTipExample';
import CheckBoxesExample from '@app/components/DesignSystem/CheckBoxesExample';
import DropdownExample from '@app/components/DesignSystem/DropdownExample';
import AccordionExample from '@app/components/DesignSystem/AccordionExample';
import TablesExample from '@app/components/DesignSystem/TablesExample';
import Card from '@app/components/common/Card';
import SliderExample from '@app/components/DesignSystem/SliderExample';

const DesignSystem = () => {
  return (
    <Box
      csx={theme =>
        css({
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          padding: '20px',
          [MQ_MIN_SMALL]: {
            padding: '40px',
          },
          backgroundColor: theme.colors.background,
        })
      }>
      <Box
        csx={{
          maxWidth: '1000px',
        }}>
        <Typography
          variant="heading"
          fontWeight="bold"
          align="center"
          csx={{ marginBottom: '30px', marginTop: '30px' }}>
          TableTurn Design System
        </Typography>
        <Box csx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <ButtonExample />
          <InputExample />
          <DropdownExample />
          <CheckBoxesExample />
          <SliderExample />
          <AccordionExample />
          <TablesExample />
          <GridExample />
          <Card>
            <Typography
              variant="subtitle"
              fontWeight="bold"
              csx={{ marginBottom: '10px' }}>
              Spinner
            </Typography>
            <Grid columnGap={10} rowGap={10}>
              <Grid.Item mb={1}>
                <Spinner />
              </Grid.Item>
              <Grid.Item mb={1}>
                <Spinner color="semanticBlue" size="40px" />
              </Grid.Item>
            </Grid>
          </Card>
          <ToolTipExample />
          <Card>
            <Typography
              variant="subtitle"
              fontWeight="bold"
              csx={{ marginBottom: '10px' }}>
              Icon
            </Typography>
            <Grid columnGap={10} rowGap={10}>
              <Grid.Item mb={1}>
                <Icon name="FaSearch" />
              </Grid.Item>
              <Grid.Item mb={1}>
                <Icon name="MdVisibility" color="darkerBlue" />
              </Grid.Item>
              <Grid.Item mb={1}>
                <Icon name="MdAddCircle" color="darkGreen" size="40px" />
              </Grid.Item>
            </Grid>
          </Card>
          <CodeVerifierExample />
        </Box>
      </Box>
    </Box>
  );
};

export default DesignSystem;
