import Box from '../Box';
import Icon from '../Icon';
import Typography from '../Typography';

interface IUnderDevelopment {
  showPageName?: boolean;
  pageName?: string;
}
const UnderDevelopment = ({
  showPageName = true,
  pageName,
}: IUnderDevelopment) => {
  return (
    <Box
      csx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Icon name="MdCode" size="50px" color="darkGrey" />
        {showPageName && pageName && (
          <Typography
            color="darkGrey"
            fontWeight="bold"
            csx={{ marginBottom: '5px' }}>
            {pageName || 'Current Page'}
          </Typography>
        )}
        <Typography color="darkGrey">Under Development</Typography>
      </Box>
    </Box>
  );
};

export default UnderDevelopment;
