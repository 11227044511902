import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import Icon from '@app/components/common/Icon';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { makeSelectItemData, selectItems } from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import {
  BreadCrumbAction,
  SectionId,
  menuModifierItemsLinkedItemsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierItems', SECTION_ID);

const LinkedItemsSection = ({ csx, t }: IAssignedItemsCombosSection) => {
  const items = useSelector(selectItems);
  const { itemData: modifierItemData } = useSelector(getMemoizedItemData);
  const linkedItemId = modifierItemData?.linkedItemId;
  const linkedItemsCards = useMemo(
    () =>
      menuModifierItemsLinkedItemsFactory(
        linkedItemId ? [linkedItemId] : [],
        items,
      ),
    [items, linkedItemId],
  );

  return (
    <AssignedElements
      type="items"
      detailsScreenProps={{
        currentRelationsIds: [`${linkedItemId}`],
        wantedEntity: 'items',
      }}
      noElementsMessage={t(
        'menuScreen.modifierItemDetails.associations.linkedItem.emptyCardsMessage',
      )}
      noCardsPadding
      cardsContainerCsx={{
        border: 'none',
      }}
      elements={linkedItemsCards}
      subCardProps={{
        title: t(
          'menuScreen.modifierItemDetails.associations.linkedItem.title',
        ),
        isEditButtonDisabled: undefined,
        onEditMode: undefined,
        actionOptions: undefined,
        csx,
      }}
      renderItem={LinkedItemsSectionCardItem}
    />
  );
};

const LinkedItemsSectionCardItem = (item: IItemCard) => {
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();
  const theme = useTheme();

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const borderColor = theme.colors.semanticBlue;

  const pathURL = `/menu/items/${item.id}`;

  return (
    <>
      <Box>
        <Card.Item
          csx={{
            borderColor: `${borderColor} !important`,
          }}
          {...item}
          showRemoveIcon={false}
          onClick={openInNewTab => {
            if (openInNewTab) return openNewTabWithOrgData(pathURL);

            checkForChangesAndNavigateWeb(
              () =>
                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: item.title as string,
                  onPress: () => navigate(pathURL),
                  pathURL,
                }),
              onRefresh,
            );
          }}
          footerIcon={<Icon name="FaLink" color={borderColor} size="18px" />}
          isLink={getPathWithOrgData(pathURL)}
        />
      </Box>
    </>
  );
};

export default LinkedItemsSection;
