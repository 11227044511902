import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  selectChangeDataDifferences,
  selectChangeDataId,
  selectCurrentItem,
  selectMenu,
} from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  IMenuType,
  diffMenuTypesFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import EmptyField from '../../common/EmptyField';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import TextAreaChange from '../../common/TextAreaChange';

const MenuTypesDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(selectChangeDataDifferences);

  const changeData = useSelector(selectCurrentItem) as IMenuType;
  const menuTypeId = useSelector(selectChangeDataId, shallowEqual);
  const currentMenu = useSelector(selectMenu);

  const formattedDifferences = useMemo(() => {
    return diffMenuTypesFactory(
      menuTypeId,
      differences,
      currentMenu,
      changeData,
      store.getState() as CoreRootState & RootState,
    );
  }, [menuTypeId, differences, currentMenu, changeData]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      [
        'defaultMenuTypeId',
        'qsrSendAndPayActionButtons',
        'defaultRestaurantMode',
        'coursingMode',
        'seatingMode',
      ].includes(field)
    ) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return (
      <RenderChange
        key={change.field + change?.id || change.label}
        change={change}
        formatValue={formatValue}
      />
    );
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges
                  .filter(change => change.field !== 'locationIds')
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`master_change_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
              </Grid>
            </>
          )}
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.moreInfo.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.moreInformation')}
              />
              <Box>
                {formattedDifferences.moreInfo.map(change =>
                  change.value ? (
                    <TextAreaChange
                      key={`change-${change.field}`}
                      change={change}
                    />
                  ) : (
                    <EmptyField
                      key={`change-${change.field}`}
                      label={change.label}
                    />
                  ),
                )}
              </Box>
            </>
          )}
          {formattedDifferences.associations.items.length > 0 && (
            <>
              {formattedDifferences.associations.items
                .filter(change => change.field === 'itemIds')
                .map(renderChange)}
            </>
          )}

          {formattedDifferences.associations.categories.length > 0 && (
            <>
              {formattedDifferences.associations.categories
                .filter(change => change.field === 'categoryIds')
                .map(renderChange)}
            </>
          )}
          {formattedDifferences.associations.combos.length > 0 && (
            <>
              {formattedDifferences.associations.combos
                .filter(change => change.field === 'comboIds')
                .map(renderChange)}
            </>
          )}

          {formattedDifferences.associations.taxes.length > 0 && (
            <>
              <RenderChange
                change={{
                  field: 'taxIds',
                  label: t('menuScreen.menuTypeDetails.taxes.title'),
                  value: formattedDifferences.associations.taxes,
                }}
                formatValue={formatValue}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default MenuTypesDiffModal;
