import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import Typography from '@app/components/common/Typography';
import { useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';

interface INewUserEmailModalProps extends WithTranslation {
  title: string;
  subtitle: string;
  isActive: boolean;
  onClose: () => void;
  onContinue: (
    email: string,
    setErrors: React.Dispatch<
      React.SetStateAction<{
        email: string;
      }>
    >,
  ) => void;
}

const UserEmailModal = ({
  t,
  title,
  subtitle,
  isActive,
  onClose,
  onContinue,
}: INewUserEmailModalProps) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const formRef = useRef<HTMLFormElement>(null);

  const handleModalClose = () => {
    formRef.current?.reset();
    setEmail('');
    setErrors({ email: '' });
    onClose();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reset();
    setEmail('');
    handleModalClose();
    onContinue(email, setErrors);
  };

  return (
    <Modal
      modalContainerCsx={{ '.modalContent': { padding: '0' } }}
      size={500}
      title={title}
      isActive={isActive}
      onModalClose={handleModalClose}>
      <Box csx={{ gap: '15px', display: 'flex', flexDirection: 'column' }}>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Box csx={{ padding: '20px' }}>
            <Typography csx={{ marginBottom: '15px' }}>{subtitle}</Typography>
            <Input
              label="Email"
              placeholder="user@example.com"
              type="email"
              name="newUserEmail"
              id="newUserEmail"
              required
              onChange={e => setEmail(e.target.value)}
              error={!!errors.email}
              caption={errors.email}
              onFocus={() => setErrors({ email: '' })}
            />
          </Box>

          <Box className="footer">
            <Button
              type="button"
              variant="secondary"
              csx={{ width: '100px' }}
              onClick={handleModalClose}>
              {t('commonButtons.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              icon={<Icon name="MdArrowForward" />}
              iconPosition="right"
              csx={{ width: '110px' }}>
              {t('commonButtons.next')}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default UserEmailModal;
