import { IInitializationParams } from './types';

const endpoints = {
  auth: {
    signIn: '/login',
    sigUp: '/user',
    verifyCode: '/password/verify',
    resetPassword: '/password/reset',
  },
  users: {
    getOrganizations: '/users',
  },
  initialization: ({ organizationId, locationId }: IInitializationParams) =>
    `/organization/${organizationId}/location/${locationId}/initialize`,
};

export default endpoints;
