export enum mainTabs {
  DEVICES = 1,
  LICENSES = 2,
}

export enum devicesTabs {
  TABLETS = 1,
  PRINTERS = 2,
  TERMINALS = 3,
}
