import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  IKitchenTicketSettings,
  IPrepStationVersion,
  IWithPrepStationVersionId,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import CustomizingReceiptsTable from '../CustomizingReceiptsTable';

const SECTION_ID = SettingsSectionId.CUSTOMIZING_RECEIPTS_FOOTER;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

interface ITicketFooter {
  title?: string;
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

const TicketFooterTable = ({ title, prepStationVersionId }: ITicketFooter) => {
  const { data } = useSelector(getMemoizedItemData);
  const ticketFooter = prepStationVersionId
    ? data?.prepStationVersions[prepStationVersionId].ticketFooter
    : (data as unknown as IPrepStationVersion)?.ticketFooter;
  return (
    <CustomizingReceiptsTable
      sectionId={SECTION_ID}
      data={ticketFooter}
      tableField="ticketFooter"
      title={title}
      prepStationVersionId={prepStationVersionId}
    />
  );
};

export default TicketFooterTable;
