import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import ImageUploader from '@app/components/common/ImageUploader';
import Input from '@app/components/common/Input';
import Typography from '@app/components/common/Typography';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { IGenericError } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IGeneralSettingsStore,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  settingsSelectors,
  statesDropdownFactory,
  statesList,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData, areSettingsMasterModeSelector } =
  settingsSelectors;

const SECTION_ID = SettingsSectionId.ORGANIZATION_INFORMATION;
const getMemoizedItemData =
  makeSelectSettingsData<IGeneralSettingsStore>(SECTION_ID);

const LocationInformationSettings = (
  accordionSectionProps: IAccordionSection,
) => {
  //Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const settingsErrors = useSelector(settingsErrorsSelector);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);

  const locationPath =
    isMasterMode && hasMultipleLocations ? 'master' : 'location';

  // Local state
  const { t } = useTranslation();

  const sectionTitle = t(
    `settingsModule.generalSettings.organizationInformation.title.${locationPath}` as ParseKeys,
  );

  const mainTranslationKey =
    'loggedIn.settingsModule.generalSettings.organizationInformation';

  const organizationNameLabel = t(
    `${mainTranslationKey}.organizationName.label.${locationPath}` as ParseKeys,
  );

  const organizationWebsiteLabel = t(
    `${mainTranslationKey}.organizationWebsite.label.${locationPath}` as ParseKeys,
  );

  const organizationLogoTitle = t(
    'settingsModule.generalSettings.organizationInformation.organizationLogo.title',
  );

  const organizationAddressTitle = t(
    `${mainTranslationKey}.organizationAddress.title.${locationPath}` as ParseKeys,
  );

  const { states, options: statesOptions } = statesDropdownFactory();

  const stateValue = useMemo(
    () => states.indexOf(data?.state as statesList) + 1,
    [data?.state, states],
  );

  const nameError =
    (settingsErrors?.name?.data as IGenericError)?.message || '';
  const websiteUrlError =
    (settingsErrors?.websiteUrl?.data as IGenericError)?.message || '';
  const streetLine1Error =
    (settingsErrors?.streetLine1?.data as IGenericError)?.message || '';
  const cityError =
    (settingsErrors?.city?.data as IGenericError)?.message || '';
  const postalCodeError =
    (settingsErrors?.postalCode?.data as IGenericError)?.message || '';

  return (
    <AccordionSection
      title={sectionTitle}
      {...accordionSectionProps}
      hasError={Boolean(
        nameError ||
          websiteUrlError ||
          streetLine1Error ||
          cityError ||
          postalCodeError,
      )}>
      <Grid columnGap={20} rowGap={15}>
        <Grid.Item
          mb={12}
          sm={6}
          md={6}
          xl={3}
          csx={displayFlexEndColumnStyles}>
          <Input
            label={organizationNameLabel}
            required
            placeholder={organizationNameLabel}
            value={data?.name}
            onChange={newName =>
              setValue<IGeneralSettingsStore>(
                {
                  name: newName.currentTarget.value,
                },
                SECTION_ID,
              )
            }
            error={Boolean(nameError)}
            caption={nameError}
            onFocus={() => {
              nameError && clearSettingsError('name');
            }}
          />
        </Grid.Item>
        <Grid.Item
          mb={12}
          sm={6}
          md={6}
          xl={3}
          csx={displayFlexEndColumnStyles}>
          <Input
            label={organizationWebsiteLabel}
            required
            placeholder={organizationWebsiteLabel}
            value={data?.websiteUrl}
            onChange={newWebsiteUrl =>
              setValue<IGeneralSettingsStore>(
                {
                  websiteUrl: newWebsiteUrl.currentTarget.value,
                },
                SECTION_ID,
              )
            }
            error={Boolean(websiteUrlError)}
            caption={websiteUrlError}
            onFocus={() => {
              websiteUrlError && clearSettingsError('websiteUrl');
            }}
          />
        </Grid.Item>
        <Grid.Item mb={12}>
          <Divider csx={{ marginBottom: '15px' }} />
          <DetailsSubtitle>{organizationAddressTitle}</DetailsSubtitle>
          <Grid columnGap={20} rowGap={15}>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              xl={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                label={t(
                  `${mainTranslationKey}.organizationAddress.streetLine1`,
                )}
                required
                placeholder={t(
                  `${mainTranslationKey}.organizationAddress.streetLine1`,
                )}
                value={data?.streetLine1}
                onChange={newStreetLine1 =>
                  setValue<IGeneralSettingsStore>(
                    {
                      streetLine1: newStreetLine1.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                error={Boolean(streetLine1Error)}
                caption={streetLine1Error}
                onFocus={() => {
                  streetLine1Error && clearSettingsError('streetLine1');
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              xl={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                label={t(
                  `${mainTranslationKey}.organizationAddress.streetLine2`,
                )}
                placeholder={t(
                  `${mainTranslationKey}.organizationAddress.streetLine2`,
                )}
                value={data?.streetLine2}
                onChange={newStreetLine2 =>
                  setValue<IGeneralSettingsStore>(
                    {
                      streetLine2: newStreetLine2.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              xl={2}
              csx={displayFlexEndColumnStyles}>
              <Input
                label={t(`${mainTranslationKey}.organizationAddress.city`)}
                required
                placeholder={t(
                  `${mainTranslationKey}.organizationAddress.city`,
                )}
                value={data?.city}
                onChange={newCity =>
                  setValue<IGeneralSettingsStore>(
                    {
                      city: newCity.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                error={Boolean(cityError)}
                caption={cityError}
                onFocus={() => {
                  cityError && clearSettingsError('city');
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              xl={2}
              csx={displayFlexEndColumnStyles}>
              <Dropdown
                required
                label={t(
                  'loggedIn.settingsModule.generalSettings.organizationInformation.organizationAddress.state',
                )}
                placeholder={t(
                  'loggedIn.settingsModule.generalSettings.organizationInformation.organizationAddress.state',
                )}
                data={[statesOptions]}
                value={stateValue}
                onChange={newState => {
                  const newStateString = states[newState - 1];
                  setValue<IGeneralSettingsStore>(
                    { state: newStateString },
                    SECTION_ID,
                  );
                }}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              xl={2}
              csx={displayFlexEndColumnStyles}>
              <Input
                label={t(
                  `${mainTranslationKey}.organizationAddress.postalCode`,
                )}
                required
                placeholder={t(
                  `${mainTranslationKey}.organizationAddress.postalCode`,
                )}
                value={data?.postalCode}
                onChange={newPostalCode =>
                  setValue<IGeneralSettingsStore>(
                    {
                      postalCode: newPostalCode.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                error={Boolean(postalCodeError)}
                caption={postalCodeError}
                onFocus={() => {
                  postalCodeError && clearSettingsError('postalCode');
                }}
              />
            </Grid.Item>
          </Grid>
          <Divider csx={{ marginBlock: '15px' }} />
          <DetailsSubtitle required>{organizationLogoTitle}</DetailsSubtitle>
          <Grid rowGap={15} columnGap={20}>
            <Grid.Item mb={12} sm={6} lg={4} xl={3}>
              <Box csx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Typography>
                  {t(
                    'settingsModule.generalSettings.organizationInformation.organizationLogo.digitalLogoUrl.title',
                  )}
                </Typography>
                <Typography variant="caption2">
                  {t(
                    'settingsModule.generalSettings.organizationInformation.organizationLogo.digitalLogoUrl.description',
                  )}
                </Typography>
                <ImageUploader
                  csx={{ marginTop: 0 }}
                  modalProps={{
                    title: t(
                      'settingsModule.generalSettings.organizationInformation.organizationLogo.modalTitle.digital',
                    ),
                    subtitle: data ? data.name : '',
                  }}
                  source={data?.digitalLogoUrl}
                  onChange={digitalLogoUrl =>
                    setValue<IGeneralSettingsStore>(
                      { digitalLogoUrl },
                      SECTION_ID,
                    )
                  }
                />
              </Box>
            </Grid.Item>
            <Grid.Item mb={12} sm={6} lg={4} xl={3}>
              <Box csx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Typography>
                  {t(
                    'settingsModule.generalSettings.organizationInformation.organizationLogo.printLogoUrl.title',
                  )}
                </Typography>
                <Typography variant="caption2">
                  {t(
                    'settingsModule.generalSettings.organizationInformation.organizationLogo.printLogoUrl.description',
                  )}
                </Typography>
                <ImageUploader
                  csx={{ marginTop: 0 }}
                  modalProps={{
                    title: t(
                      'settingsModule.generalSettings.organizationInformation.organizationLogo.modalTitle.print',
                    ),
                    subtitle: data ? data.name : '',
                  }}
                  source={data?.printLogoUrl}
                  onChange={printLogoUrl =>
                    setValue<IGeneralSettingsStore>(
                      { printLogoUrl },
                      SECTION_ID,
                    )
                  }
                />
              </Box>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default LocationInformationSettings;
