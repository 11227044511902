import { useTheme } from '@emotion/react';
import Slider from '..';
import Box from '../../Box';
import { ISlider } from '../Slider';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
export interface ICustomSlider extends ISlider {
  bannedRanges?: Array<[number, number]>;
  bannedColor?: string;
  debounceTimeout?: number;
  renderLimits?: (value: number, index: number) => React.ReactNode;
  onError?: () => void;
  minimumRange?: number;
}

const CustomSlider = (props: ICustomSlider) => {
  const { renderLimits, ...otherProps } = props;
  const theme = useTheme();
  const [localValues, setLocalValues] = useState<number[]>([]);

  useEffect(() => {
    if (otherProps.value && Array.isArray(otherProps.value)) {
      setLocalValues(otherProps.value);
    }
  }, [otherProps.value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((newValue: number | number[]) => {
      props.onValueChange && props.onValueChange(newValue);
    }, props.debounceTimeout),
    [],
  );

  return (
    <Box csx={{ display: 'flex', alignItems: 'center' }}>
      {renderLimits && (
        <Box csx={{ marginRight: '10px' }}>
          {renderLimits(otherProps.min || 0, 0)}
        </Box>
      )}

      <Slider
        {...props}
        bannedThumbColor={theme.colors.darkRed}
        onValueChange={newValues => {
          setLocalValues(newValues as number[]);
          if (isEqual(newValues, localValues)) return;

          props.debounceTimeout !== null && props.debounceTimeout !== undefined
            ? debounceOnChange(newValues)
            : props.onValueChange && props.onValueChange(newValues);
        }}
      />

      {renderLimits && (
        <Box csx={{ marginLeft: '10px' }}>
          {renderLimits(otherProps.max || 100, 0)}
        </Box>
      )}
    </Box>
  );
};

export default CustomSlider;
