import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { SectionId } from '@westondev/tableturn-core';
import { upperCase } from 'lodash';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ElementsSection from './ElementsSection';

const sectionId = SectionId.ASSOCIATION;
const getMemoizedReportingGroupData = makeSelectItemData(
  'reportingGroups',
  sectionId,
);

const AssociationsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { itemData: reportingGroupData } = useSelector(
    getMemoizedReportingGroupData,
  );

  return (
    <AccordionSection
      title={
        <Box>
          <Typography>
            {upperCase(t('commonTexts.associated'))}
            {': '}
            <Typography
              csx={{
                fontStyle: 'italic',
                display: 'inline',
              }}>
              {t('menuScreen.itemGroupsDetails.associations.title')}
            </Typography>
          </Typography>
        </Box>
      }
      {...props}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <ElementsSection field="itemIds" data={reportingGroupData} />
        <ElementsSection field="comboIds" data={reportingGroupData} />
      </Box>
    </AccordionSection>
  );
};

export default AssociationsSection;
