import ListItem from '@app/components/common/List/ListItem';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsTableLayout } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface INavigateToTableLayoutCreator extends WithTranslation {
  currentPath: string;
  handleNavigate: (path: string, openNewTab: boolean) => void;
}
const NavigateToTableLayoutCreator = ({
  t,
  currentPath,
  handleNavigate,
}: INavigateToTableLayoutCreator) => {
  // Redux
  const { initializeLayout } = bindActionCreators(
    actionCreatorsTableLayout,
    useDispatch(),
  );

  const handleNavigateToTableLayout = (openNewTab: boolean) => {
    handleNavigate('settings/table-layout', openNewTab);
    initializeLayout();
  };

  return (
    <ListItem
      isSubItem
      isActive={currentPath.includes('settings/table-layout')}
      text={t('loggedIn.sideBar.settings.tableLayout')}
      onClick={openNewTab => handleNavigateToTableLayout(openNewTab)}
      isLink={getPathWithOrgData('settings/table-layout')}
    />
  );
};

export default NavigateToTableLayoutCreator;
