import { buttonStyles } from '../../NavBar/NavBarButtons/styles';
import Icon from '@app/components/common/Icon';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal';
import { useState } from 'react';
import PrinterCenter from '..';
import { useSelector } from 'react-redux';
import { selectSomePrinterHasError } from '@app/state/selectors/settingsSelectors';
import { RedDot } from '@app/components/common/ConfirmationModal/BucketValidationConfirmationModal/BucketValidationResolver';
import Box from '@app/components/common/Box';
import { IPrinterJobSelected } from '@westondev/tableturn-core';

interface IPrinterCenterTrigger {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}
const PrinterCenterTrigger = ({
  showModal,
  setShowModal,
}: IPrinterCenterTrigger) => {
  // Redux
  const somePrinterHasError = useSelector(selectSomePrinterHasError);

  // Local state
  const [selectedPrinter, setSelectedPrinter] =
    useState<IPrinterJobSelected | null>(null);

  const handleClose = () => {
    setShowModal(false);
    setSelectedPrinter(null);
  };

  const handleToggleModal = () => {
    if (showModal) handleClose();
    else setShowModal(true);
  };

  return (
    <>
      <Modal
        position="right"
        isActive={showModal}
        onModalClose={handleClose}
        noPadding
        showXCloser={false}
        displayAfterNavBar
        modalContainerCsx={{
          '.modalContent': {
            overflow: 'hidden',
          },
        }}>
        <PrinterCenter
          onCloseClick={handleClose}
          selectedPrinter={selectedPrinter}
          setSelectedPrinter={setSelectedPrinter}
        />
      </Modal>
      <Button
        icon={
          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '50px',
            }}>
            <Icon name="MdPrint" color="white" />
            <Box csx={{ position: 'absolute', top: 0, right: 0 }}>
              {somePrinterHasError && <RedDot />}
            </Box>
          </Box>
        }
        variant={showModal ? 'active' : 'primary'}
        csx={theme => [
          buttonStyles,
          {
            width: '70px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            backgroundColor: showModal
              ? `${theme.colors.semanticBlue} !important`
              : theme.colors.persistentDarkerBlue,
          },
        ]}
        onClick={handleToggleModal}
      />
    </>
  );
};

export default PrinterCenterTrigger;
