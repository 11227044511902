import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import Box from '@app/components/common/Box';
import { selectIsReportsModuleAvailable } from '@app/state/reports/reportsSelectors';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

const Reports = () => {
  const isReportsModuleAvailable = useSelector(selectIsReportsModuleAvailable);

  if (!isReportsModuleAvailable) {
    return null;
  }

  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}>
      <LayoutContent noPadding>
        <Outlet />
      </LayoutContent>
    </Box>
  );
};

export default Reports;
