import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Dropdown from '@app/components/common/Dropdown';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import Typography from '@app/components/common/Typography';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { RootState, store } from '@app/state/store';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import {
  IModifierTag,
  IRegisterSettings,
  ModifierTagPositions,
  REGISTER_MODIFIER_TAG_INITIAL_STATE,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getModifierTagId,
  propagateString,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { t } from 'i18next';
import { isNumber } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  IModTagsTable,
  MOD_TAG_TABLE_ID,
  ROW_ID_PREFIX,
  SECTION_ID,
  TAG_POSITION_OPTIONS,
  columnHelper,
  getMemoizedItemData,
} from './types';
import ColorPicker from '@app/components/common/ColorPicker';

const { modifierTagsSelector, areSettingsMasterModeSelector } =
  settingsSelectors;

const disabledPosName = {
  NO: true,
  EXTRA: true,
  LIGHT: true,
};

const disabledCheckNameTagPosition = {
  NO: true,
};

const ModTags = () => {
  // redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const menuTypes = useSelector(
    (state: RootState) => state.menu.masterMenu.menuTypes,
  );
  const actualModifierTags = useSelector(modifierTagsSelector);
  const { data: bigData } = useSelector(getMemoizedItemData);

  // Local state
  const data = bigData?.modifierTags;

  const [openItemsModal, setOpenItemsModal] = useState(false);
  const [currentMenuTypesIds, setCurrentMenuTypesIds] = useState<number[]>([]);
  const [currentRowId, setCurrentRowId] = useState<number | null>(null);

  const handleScrollToNewRow = useScrollToNewRow();

  const updateModTag = (
    newValue: any,
    id: number,
    field: keyof IModifierTag,
  ) => {
    const modifierTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.modifierTags;
    setValue<IRegisterSettings>(
      {
        modifierTags: {
          ...modifierTags,
          [id]: {
            ...modifierTags[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const onNewModTag = () => {
    const nextId =
      (max(Object.keys(data || {}).map(key => Number(key)) || []) || 0) + 1;

    const nextSortPriority =
      (max(
        Object.values(data || {}).map(modTag => modTag.sortPriority) || [],
      ) || 0) + 1;

    const nextIndex = Object.keys(data || {}).length;

    setValue<IRegisterSettings>(
      {
        modifierTags: {
          ...data,
          [nextId]: {
            ...REGISTER_MODIFIER_TAG_INITIAL_STATE,
            id: nextId,
            modifierTagId: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      SECTION_ID,
    );
    handleScrollToNewRow(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const modTagsArray = useMemo(
    () =>
      orderBy(
        Object.keys(data || {}).map(key => ({
          id: Number(key),
          status: data?.[Number(key)]?.active,
          name: data?.[Number(key)]?.name,
          posName: data?.[Number(key)]?.posName,
          checkName: data?.[Number(key)]?.checkName,
          tagPosition: data?.[Number(key)]?.tagPosition,
          canDelete: actualModifierTags[Number(key)] ? false : true,
          sortPriority: data?.[Number(key)]?.sortPriority,
          isSpecial: data?.[Number(key)]?.isSpecial,
          menuTypeIds: data?.[Number(key)]?.menuTypeIds ?? [],
          backgroundColor: data?.[Number(key)]?.backgroundColor,
          textColor: data?.[Number(key)]?.textColor,
        })),
        'sortPriority',
      ),
    [data, actualModifierTags],
  );

  const handleDelete = (id: number) => {
    const modTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.modifierTags;

    let sortPriority = 1;
    const modTagsCloned = cloneDeep(modTags);
    delete modTagsCloned[id];
    const modTagsClonedArray = Object.values(modTagsCloned);

    orderBy(modTagsClonedArray, 'sortPriority').forEach(value => {
      modTagsCloned[value.modifierTagId].sortPriority = sortPriority;
      sortPriority++;
    });

    setValue<IRegisterSettings>(
      {
        modifierTags: modTagsCloned,
      },
      SECTION_ID,
    );
  };

  const handleDeleteMenuType = (
    menuTypeId: number | string,
    modTagId: number | null,
  ) => {
    if (!modTagId) return;

    if (!currentMenuTypesIds.includes(Number(menuTypeId))) return;

    const newMenuTypesIds = currentMenuTypesIds.filter(
      value => Number(value) !== Number(menuTypeId),
    );

    setCurrentMenuTypesIds(newMenuTypesIds);
    updateModTag(
      newMenuTypesIds.length === 0 ? null : newMenuTypesIds,
      modTagId,
      'menuTypeIds',
    );
  };

  const deleteAllMenuTypes = () => {
    if (!currentRowId) return;
    updateModTag([], currentRowId, 'menuTypeIds');
    setCurrentMenuTypesIds([]);
  };

  const updateName = (newName: string, id: number) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentModTag = registerData.modifierTags[id];
    const prevName = currentModTag.name;
    const propagateObj = {
      name: prevName,
      posName: currentModTag.posName,
      checkName: currentModTag.checkName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        modifierTags: {
          ...registerData.modifierTags,
          [id]: {
            ...registerData.modifierTags[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'Before',
        value: TAG_POSITION_OPTIONS.indexOf(ModifierTagPositions.BEFORE) + 1,
      },
      {
        label: 'After',
        value: TAG_POSITION_OPTIONS.indexOf(ModifierTagPositions.AFTER) + 1,
      },
    ],
    [],
  );

  const handleOnSort = (newData: IModTagsTable[]) => {
    const clonedModTags = cloneDeep(data);
    newData.forEach((item, index) => {
      clonedModTags[item.id].sortPriority = index + 1;
    });
    setValue<IRegisterSettings>(
      {
        modifierTags: clonedModTags,
      },
      SECTION_ID,
    );
  };

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: t('settingsModule.registerModeSettings.general.modTags.status'),
        cell: info => (
          <Switch
            key={`switch-modTag-${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isActive => {
              updateModTag(isActive, info.row.original.id, 'active');
            }}
          />
        ),
        size: 70,
      }),
      columnHelper.accessor('name', {
        header: t('settingsModule.registerModeSettings.general.modTags.name'),
        cell: info =>
          info.row.original.isSpecial ? (
            <Box csx={[displayFlexRowCenterStyles, { gap: '5px' }]}>
              <Icon name="MdLock" color="textYellow" />
              <Typography csx={{ marginTop: '5px' }}>
                {info.getValue()}
              </Typography>
            </Box>
          ) : (
            <InputCell
              disabled={!info.row.original.canDelete && !isMasterMode}
              required
              placeholder={t(
                'settingsModule.registerModeSettings.general.modTags.name',
              )}
              value={info.getValue()}
              onChange={e =>
                updateName(e.currentTarget.value, info.row.original.id)
              }
              errorId={`${getModifierTagId(`${info.row.original.id}`, 'name')}`}
              onFocusError={(errorId, hasError) =>
                hasError && clearSettingsError(errorId)
              }
            />
          ),
      }),
      columnHelper.accessor('posName', {
        header: t(
          'settingsModule.registerModeSettings.general.modTags.posName',
        ),
        cell: info => (
          <InputCell
            required
            placeholder={t(
              'settingsModule.registerModeSettings.general.modTags.posName',
            )}
            disabled={
              disabledPosName[
                info.row.original.name as keyof typeof disabledPosName
              ]
            }
            value={info.getValue()}
            onChange={e => {
              updateModTag(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              );
            }}
            errorId={`${getModifierTagId(
              `${info.row.original.id}`,
              'posName',
            )}`}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
      }),
      columnHelper.accessor('checkName', {
        header: t(
          'settingsModule.registerModeSettings.general.modTags.checkName',
        ),
        cell: info => (
          <InputCell
            required
            placeholder={t(
              'settingsModule.registerModeSettings.general.modTags.checkName',
            )}
            disabled={
              disabledCheckNameTagPosition[
                info.row.original
                  .name as keyof typeof disabledCheckNameTagPosition
              ]
            }
            value={info.getValue()}
            onChange={e => {
              updateModTag(
                e.currentTarget.value,
                info.row.original.id,
                'checkName',
              );
            }}
            errorId={`${getModifierTagId(
              `${info.row.original.id}`,
              'checkName',
            )}`}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
      }),
      columnHelper.accessor('menuTypeIds', {
        header: t(
          'settingsModule.registerModeSettings.general.modTags.menuType',
        ),
        cell: info => {
          const values = info.getValue() ?? [];

          const [head, ...tail] = [...values];

          const dropdownLabel = `${head ? menuTypes[head]?.posName : ''} ${
            tail.length
              ? `& ${tail.length} More`
              : head
              ? ''
              : t(
                  'settingsModule.registerModeSettings.general.modTags.allMenuTypes',
                )
          }`;

          return (
            <>
              <Button
                csx={{
                  color: !tail.length && !head ? 'grey' : 'text',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
                icon={<Icon color="textWhite" name="MdKeyboardArrowDown" />}
                onClick={() => {
                  setCurrentRowId(info.row.original.id);
                  setCurrentMenuTypesIds(values);
                  setOpenItemsModal(true);
                }}>
                {dropdownLabel}
              </Button>
            </>
          );
        },
        size: 250,
      }),
      columnHelper.accessor('tagPosition', {
        header: t(
          'settingsModule.registerModeSettings.general.modTags.tagPosition',
        ),
        cell: info => (
          <Dropdown
            data={[dropdownOptions]}
            isDisabled={
              disabledCheckNameTagPosition[
                info.row.original
                  .name as keyof typeof disabledCheckNameTagPosition
              ]
            }
            value={
              TAG_POSITION_OPTIONS.indexOf(
                info.getValue() || ModifierTagPositions.AFTER,
              ) + 1
            }
            onChange={newTagPosition =>
              updateModTag(
                TAG_POSITION_OPTIONS[newTagPosition - 1],
                info.row.original.id,
                'tagPosition',
              )
            }
          />
        ),
        size: 130,
      }),
      columnHelper.accessor('backgroundColor', {
        header: 'Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || '#ffffff'}
            onChange={color =>
              updateModTag(color, info.row.original.id, 'backgroundColor')
            }
            onReset={() =>
              updateModTag(null, info.row.original.id, 'backgroundColor')
            }
          />
        ),
        size: 70,
      }),
      columnHelper.accessor('textColor', {
        header: 'Text Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || '#000000'}
            onChange={color =>
              updateModTag(color, info.row.original.id, 'textColor')
            }
            onReset={() =>
              updateModTag(null, info.row.original.id, 'textColor')
            }
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('canDelete', {
        header: t('settingsModule.registerModeSettings.general.modTags.delete'),
        cell: info => (
          <Button
            color="danger"
            disabled={
              info.row.original.isSpecial || (!info.getValue() && !isMasterMode)
            }
            onClick={() => handleDelete(info.row.original.id)}
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}></Button>
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMasterMode],
  );

  return (
    <Box id={MOD_TAG_TABLE_ID}>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title="Mod Tags"
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewModTag}>
              Add Mod Tag
            </Button>
          }
        />
      </m.div>
      <SelectionModal
        type="menuTypes"
        detailsScreenProps={{
          currentRelationsIds: [],
          wantedEntity: 'menuTypes',
        }}
        onAssociate={(buttons: IItemSelection[]) => {
          if (!currentRowId) return;

          const newMenuTypesIds = [...currentMenuTypesIds];

          buttons.forEach(button => {
            const buttonId = Number(button.id);

            if (isNumber(buttonId) && !currentMenuTypesIds.includes(buttonId)) {
              newMenuTypesIds.push(buttonId);
            }
          });

          setCurrentMenuTypesIds(newMenuTypesIds);

          updateModTag(
            newMenuTypesIds.length === 0 ? null : newMenuTypesIds,
            currentRowId,
            'menuTypeIds',
          );
        }}
        selectedMenuTypeIds={currentMenuTypesIds}
        description={t(
          'settingsModule.registerModeSettings.general.modTags.menuTypeModalDescription',
        )}
        onModalClose={() => {
          setOpenItemsModal(false);
          setCurrentMenuTypesIds([]);
          setCurrentRowId(null);
        }}
        active={openItemsModal}
        deleteAllMenuTypes={deleteAllMenuTypes}
        allowAssociateMultiple
        showDescriptionPanel={false}
        scrollCardSectionNumberOfColumns={6}
        btnSuccessText={t('commonButtons.done')}
        showSelectedButtons
        customOnDelete={(id: number | string) =>
          handleDeleteMenuType(id, currentRowId)
        }
      />
      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          mode={ETableModes.SORT_VERTICALLY}
          data={modTagsArray}
          columns={COLUMNS}
          onSortEnd={handleOnSort}
          cellCsx={{ height: '70px' }}
          alignHeaders={{
            textColor: 'center',
            backgroundColor: 'center',
            delete: 'center',
          }}
          align={{
            textColor: 'center',
            backgroundColor: 'center',
            delete: 'center',
          }}
          requiredColumns={['name', 'posName']}
          renderEmptyValues
          rowIdPrefix={ROW_ID_PREFIX}
        />
      </Box>
    </Box>
  );
};

export default ModTags;
