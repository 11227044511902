import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { RootState } from '@app/state/store';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, IItem, SectionId } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export interface IItemCardModifierGroup {
  menuTypeId: number;
}

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const menuModifierGroupAssignedItemsFactory = (
  items: Record<number, IItem>,
  menuModifierGroupAssignedItemIds: Record<number, number[]>,
): IItemCard[] => {
  return Object.keys(menuModifierGroupAssignedItemIds)
    .map((id: string) => {
      const item = items[Number(id)];
      if (item)
        return {
          id: Number(id),
          title: item.name,
          menuTypeId:
            menuModifierGroupAssignedItemIds[Number(id)].length > 0
              ? menuModifierGroupAssignedItemIds[Number(id)][0]
              : -1,
          onPress: () => console.info(id),
        };
      else return null;
    })
    .filter(value => value !== null) as IItemCard[];
};

const AssignedItemsSection = ({ csx, t }: IAssignedItemsCombosSection) => {
  // Redux
  const { itemData } = useSelector(getMemoizedItemData);

  const items = useSelector(
    (state: RootState) => state.menu.selectedMode.currentMenu.items,
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedItemIds = useMemo(() => itemData?.itemIds || [], [itemData]);

  const assignedElementsCardList = useMemo(
    () => menuModifierGroupAssignedItemsFactory(items, assignedItemIds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedItemIds],
  );

  return (
    <AssignedElements
      type="items"
      detailsScreenProps={{
        currentRelationsIds: assignedElementsCardList.map(({ id }) => `${id}`),
        wantedEntity: 'items',
      }}
      noElementsMessage={t(
        'menuScreen.modifierGroupDetails.associations.items.emptyCardsMessage',
      )}
      elements={assignedElementsCardList}
      subCardProps={{
        title: t('menuScreen.modifierGroupDetails.associations.items.title', {
          name: itemData ? itemData.name : '',
        }),
        subtitle: t(
          'menuScreen.modifierGroupDetails.associations.items.description',
        ),
        isEditButtonDisabled: undefined,
        onEditMode: undefined,
        actionOptions: undefined,
        csx,
      }}
      noCardsPadding
      cardsContainerCsx={{
        border: 'none',
      }}
      renderItem={item => {
        const pathURL = `/menu/items/${item.id}`;
        return (
          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Card.Item
              csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
              {...item}
              showRemoveIcon={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: item.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          </Box>
        );
      }}
    />
  );
};

export default AssignedItemsSection;
