import { Theme, css } from '@emotion/react';
import { BUTTON_HEIGHT } from '../Button/styles';

export const colorPickerStyles = (
  theme: Theme,
  color: string,
  isActive: boolean,
  isMassUpdateNoChanges: boolean,
) => {
  return css({
    position: 'relative',
    background: isMassUpdateNoChanges
      ? `linear-gradient(to bottom left, ${theme.colors.lightestGrey} 48%, ${theme.colors.lightGrey} 49%, ${theme.colors.lightGrey} 51%, ${theme.colors.lightestGrey} 52%)`
      : undefined,
    backgroundColor: isMassUpdateNoChanges
      ? theme.colors.lightestGrey
      : theme.colors[color as keyof Theme['colors']] || color,
    borderRadius: '5px',
    minWidth: '50px',
    maxWidth: '100px',
    height: BUTTON_HEIGHT,
    border: `2px solid ${
      isActive ? theme.colors.persistentSemanticBlue : theme.colors.lightGrey
    }`,
    cursor: 'pointer',
  });
};

export const hexInputStyles = () =>
  css({
    minWidth: '50px',
    maxWidth: '100px',
    marginTop: '5px',
  });

export const colorPickerContainerStyles = () =>
  css({
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

export const colorPickerLabelStyles = (theme: Theme) =>
  css({
    color: theme.colors.black,
    fontWeight: theme.fontWeights.medium,
    marginBottom: '7px',
  });

export const PickerAndButtonContainerStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    padding: '15px',
  });
