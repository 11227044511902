import { TChange } from '@app/types';
import RenderChange from '../common/RenderChange';
import { store } from '@app/state/store';
import {
  diffOnlineSettingsFactory,
  CoreRootState,
  TOnlineMenuTimesDiff,
  tipsPickupFieldLabels,
  tipsDeliveryFieldLabels,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import OnlineMenuTimesTableDiff from './OnlineMenuTimesTableDiff/OnlineMenuTimesTableDiff';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import some from 'lodash/some';
import Grid from '@app/components/common/Grid';
import { WithTranslation } from 'react-i18next';
import TextAreaChange from '../../common/TextAreaChange';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import Box from '@app/components/common/Box';
import AvailabilityTableDiff from './AvailabilityTableDiff';

const COLOR_FIELDS = [
  'primaryColor',
  'secondaryColor',
  'tertiaryColor',
  'backgroundColor',
];

const OnlineOrderingSettingsDiffModal = ({ t }: WithTranslation) => {
  //Redux
  const differences = useSelector(settingsDifferencesSelector);

  const formattedDifferences = useMemo(() => {
    if (!differences) return null;
    const diffObject = diffOnlineSettingsFactory(
      differences,
      store.getState() as unknown as CoreRootState,
    );

    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      [
        'defaultMenuTypeId',
        'pickupBufferTime',
        'deliveryBufferTime',
        'pickupTipDefault',
        'deliveryTipDefault',
        'pickupTip1',
        'pickupTip2',
        'pickupTip3',
        'deliveryTip1',
        'deliveryTip2',
        'deliveryTip3',
        'deliveryRadius',
      ].includes(field)
    ) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;

    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={COLOR_FIELDS}
        defaultColor={{ backgroundColor: '', textColor: '' }}
      />
    );
  };

  const renderOlineMenuTimesTable = (menuTypeRules: TOnlineMenuTimesDiff) => {
    return (
      <OnlineMenuTimesTableDiff
        menuTypeRules={menuTypeRules}
        formattedDifferences={formattedDifferences}
      />
    );
  };

  const renderAvailabilityTable = (
    menuTypeRules: TOnlineMenuTimesDiff,
    sectionField: 'pickupAvailability' | 'deliveryAvailability',
  ) => {
    return (
      <AvailabilityTableDiff
        menuTypeRules={menuTypeRules}
        formattedDifferences={formattedDifferences}
        sectionField={sectionField}
      />
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {(formattedDifferences.basic.changes.length > 0 ||
            some(
              formattedDifferences.basic.onlineMenuTimes,
              day => day.length > 0,
            )) && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.onlineSettings.basic.title')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.changes.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
                {some(
                  formattedDifferences.basic.onlineMenuTimes,
                  day => day.length > 0,
                ) && (
                  <Grid.Item>
                    {renderOlineMenuTimesTable(
                      formattedDifferences.basic.onlineMenuTimes,
                    )}
                  </Grid.Item>
                )}
              </Grid>
            </>
          )}
          {(formattedDifferences.pickup.changes.length > 0 ||
            some(
              formattedDifferences.pickup.pickupAvailability,
              day => day.length > 0,
            )) && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.onlineSettings.pickup.title')}
                color="black"
              />
              {formattedDifferences.pickup.changes.length > 0 && (
                <Grid columnGap={20} rowGap={10}>
                  {formattedDifferences.pickup.changes.map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`pickup_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
                </Grid>
              )}
              {some(
                formattedDifferences.pickup.pickupAvailability,
                day => day.length > 0,
              ) && (
                <Box csx={{ marginTop: 20 }}>
                  {renderAvailabilityTable(
                    formattedDifferences.pickup.pickupAvailability,
                    'pickupAvailability',
                  )}
                </Box>
              )}
            </>
          )}
          {(formattedDifferences.delivery.changes.length > 0 ||
            some(
              formattedDifferences.delivery.deliveryAvailability,
              day => day.length > 0,
            )) && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.onlineSettings.delivery.title')}
                color="black"
              />
              {formattedDifferences.delivery.changes.length > 0 && (
                <Grid columnGap={20} rowGap={10}>
                  {formattedDifferences.delivery.changes.map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`delivery_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
                </Grid>
              )}
              {some(
                formattedDifferences.delivery.deliveryAvailability,
                day => day.length > 0,
              ) && (
                <Box csx={{ marginTop: 20 }}>
                  {renderAvailabilityTable(
                    formattedDifferences.delivery.deliveryAvailability,
                    'deliveryAvailability',
                  )}
                </Box>
              )}
            </>
          )}
          {formattedDifferences.pageDesign.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.onlineSettings.pageDesign.title')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.pageDesign
                  .filter(change => change.field !== 'bannerDescription')
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`page_design_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
              </Grid>
              {formattedDifferences.pageDesign
                .filter(change => change.field === 'bannerDescription')
                .map(change => (
                  <Grid.Item key={`page_design_${change.field}`}>
                    {TextAreaChange({ change })}
                  </Grid.Item>
                ))}
            </>
          )}
          {formattedDifferences.tips.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('settingsModule.onlineSettings.tips.title')}
                color="black"
              />
              {formattedDifferences.tips.filter(
                change => change.field === 'showTippingAtCheckout',
              ).length > 0 && (
                <Grid columnGap={20} rowGap={10}>
                  {formattedDifferences.tips
                    .filter(change => change.field === 'showTippingAtCheckout')
                    .map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`tips_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                </Grid>
              )}
              {formattedDifferences.tips.filter(
                change => change.field in tipsPickupFieldLabels,
              ).length > 0 && (
                <Box csx={{ marginTop: 20 }}>
                  <DetailsSubtitle>
                    {t('settingsModule.onlineSettings.pickup.title')}
                  </DetailsSubtitle>
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.tips
                      .filter(change => change.field.includes('pickup'))
                      .map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`page_design_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                  </Grid>
                </Box>
              )}
              {formattedDifferences.tips.filter(
                change => change.field in tipsDeliveryFieldLabels,
              ).length > 0 && (
                <Box csx={{ marginTop: 20 }}>
                  <DetailsSubtitle>
                    {t('settingsModule.onlineSettings.delivery.title')}
                  </DetailsSubtitle>
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.tips
                      .filter(change => change.field.includes('delivery'))
                      .map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`page_design_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default OnlineOrderingSettingsDiffModal;
