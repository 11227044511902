import { selectCategories } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { categoriesDropdownOptionsFactoryCore } from '@westondev/tableturn-core';

export const categoriesDropdownOptionsFactory = (
  categoryIds: number[],
  showNoCategoryOption = false,
) =>
  categoriesDropdownOptionsFactoryCore(
    categoryIds,
    selectCategories(store.getState()),
    showNoCategoryOption,
  );
