import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import NoElementsFound from '@app/components/common/NoElementsFound';
import SearchInput from '@app/components/common/SearchInput';
import { filter, useSort } from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
import FilterGenericSelectionModal from '../../FilterGenericSelectionModal';
import { IItemSelection } from '../types';
import { scrollCardsSectionStyles } from './styles';
import { IScrollCardsSection } from './types';

interface IScrollCardsFilterParams {
  title: string;
}

const ScrollCardsSection = <T,>({
  items = [],
  selectedItems,
  itemPressed,
  onClick,
  noAvailableCardsMessage,
  filterInfo,
  showSearchBar = true,
  filterSelectedItems = true,
  showDescriptionPanel = false,
  t,
  isFilterActive,
  onFilter = () => null,
  filterOptions,
  filterValue,
  onFilterClear = () => null,
  isFilterClearDisabled,
  onFilterClearAll,
  isFilterClearAllDisabled,
  showSelectAll,
  onSelect,
  groupByMenuType,
  onIsLink,
}: IScrollCardsSection<T>) => {
  const onSort = useSort<IItemSelection>('title');

  const [inputValue, setInputValue] = useState('');
  const [filteredList, setFilteredList] = useState<IItemSelection[]>([]);
  const [filterParams, setFilterParams] = useState<IScrollCardsFilterParams>({
    title: '',
  });

  useEffect(() => {
    setFilteredList(
      filter<IItemSelection>(items, { ...filterParams }, null, onSort),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, items]);

  const renderedButtons = filterSelectedItems
    ? filteredList.filter(item => {
        return !selectedItems?.some?.(selectedItem => {
          const a = selectedItem.id === item.id;
          const b = groupByMenuType
            ? selectedItem.menuTypeId?.[0] === item.menuTypeId?.[0]
            : true;
          return a && b;
        });
      })
    : filteredList;

  const handleSelectAll = () => {
    onSelect?.(items);
  };
  return (
    <Box csx={scrollCardsSectionStyles}>
      {showSearchBar && (
        <Box
          csx={{
            display: 'flex',
            zIndex: 1,
            position: 'relative',
          }}>
          <SearchInput
            onDebounce={text =>
              setFilterParams(prev => ({ ...prev, title: text }))
            }
            debounceTimeout={300}
            onChange={setInputValue}
            value={inputValue}
          />
          {filterOptions && (
            <FilterGenericSelectionModal
              onFilter={onFilter}
              options={filterOptions}
              currentValue={filterValue}
              onClear={onFilterClear}
              isClearDisabled={isFilterClearDisabled}
              onClearAll={onFilterClearAll}
              isClearAllDisabled={isFilterClearAllDisabled}
              isActive={isFilterActive}
            />
          )}
          {showSelectAll && (
            <>
              <Divider direction="vertical" />
              <Button onClick={handleSelectAll}>
                {t('commonButtons.selectAll')}
              </Button>
            </>
          )}
        </Box>
      )}
      {filterInfo}
      {renderedButtons.length > 0 ? (
        <Box className="buttonsContainer">
          {renderedButtons.map(item => {
            const isActive =
              item.isSelected ||
              (showDescriptionPanel
                ? itemPressed === item.id
                : (selectedItems || []).some(
                    selectedItem =>
                      item.id === selectedItem.id &&
                      (groupByMenuType
                        ? selectedItem.menuTypeId?.[0] === item.menuTypeId?.[0]
                        : true),
                  ));
            return (
              <Card.Item
                key={`scroll-card-${item.id}-${
                  JSON.stringify(item.menuTypeId) as string
                }`}
                {...item}
                title={t(item.title as ParseKeys)}
                subTitle={item.description}
                onClick={openInNewTab => {
                  if (openInNewTab) return onClick(item, true);
                  onClick(item, false);
                }}
                showStatus={item.isActive !== undefined}
                showTag={!!item.entity}
                tag={
                  item.entity
                    ? t(`menuScreen.common.bucket.${item.entity}.singular`)
                    : undefined
                }
                isActive={item.isActive}
                isSelected={isActive}
                isAnimated={false}
                isLink={onIsLink ? onIsLink(Number(item.id)) : ''}
              />
            );
          })}
        </Box>
      ) : (
        <Box className="noAvailableOptionsContainer">
          <NoElementsFound
            text={noAvailableCardsMessage}
            vertical
            showBorder={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default ScrollCardsSection;
