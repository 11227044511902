import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { CHECKBOX_SIZE } from '@app/components/common/Checkbox/styles';
import Icon from '@app/components/common/Icon';
import RadioCircle from '@app/components/common/Icon/custom/RadioCircle';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { RadioSelectionStates } from '@app/types';
import React, { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { TPricesNames } from '../types';
import { IPricingLocationGroupRow } from '@westondev/tableturn-core';
import useRootSelector from '@app/hooks/useRootSelector';
import { selectLocationGroupRowStyles } from '@app/components/common/ConfirmationModal/SelectLocationsModal/SelectLocationGroupRow/styles';
import some from 'lodash/some';
import PricingLocationRow from '../PricingLocationRow';

interface IHandleLocationGroup {
  handleLocationGroupToggle: (id: number, state: RadioSelectionStates) => void;
  handleLocationToggle: (locationGroupId: number, locationId: number) => void;
  handlePriceChange: (
    locationGroupId: number,
    locationId: number,
    priceName: TPricesNames,
    payload: number,
  ) => void;
  isCombo?: boolean;
}
const PricingLocationGroupRow = ({
  t,
  locationGroupId,
  name,
  locations,
  handleLocationGroupToggle,
  handleLocationToggle,
  handlePriceChange,
  isCombo,
}: IPricingLocationGroupRow & IHandleLocationGroup & WithTranslation) => {
  const menuAccessLocations = useRootSelector(
    state => state.loggedInUser.menuAccessLocations,
  );
  const blueText = 'semanticBlue';
  const [groupIsExpanded, setGroupIsExpanded] = React.useState(false);
  const locationsSelectedCount = React.useMemo<number>(
    () => locations.filter(location => location.selected).length,
    [locations],
  );
  const groupState = React.useMemo<RadioSelectionStates>(
    () =>
      locationsSelectedCount === 0
        ? RadioSelectionStates.EMPTY
        : locationsSelectedCount === locations.length
        ? RadioSelectionStates.FULL
        : RadioSelectionStates.SOME,
    [locations.length, locationsSelectedCount],
  );

  const isDisabled = useMemo(
    () =>
      !some(locations, location =>
        menuAccessLocations.includes(location.locationId),
      ),
    [locations, menuAccessLocations],
  );

  const allowedLocations = useMemo(
    () =>
      locations.filter(location =>
        menuAccessLocations.includes(location.locationId),
      ),
    [locations, menuAccessLocations],
  );

  return (
    <>
      <Box csx={[selectLocationGroupRowStyles]}>
        <Box csx={[displayFlexRowStyles, { marginRight: '2px' }]}>
          <Button
            variant="transparent"
            onClick={() =>
              !isDisabled &&
              handleLocationGroupToggle(
                locationGroupId,
                allowedLocations.length === locationsSelectedCount
                  ? RadioSelectionStates.FULL
                  : groupState,
              )
            }
            csx={{ opacity: isDisabled ? 0.5 : 1 }}
            icon={
              <RadioCircle
                size={CHECKBOX_SIZE - 2}
                state={groupState}
                csx={{ marginLeft: '5px' }}
              />
            }>
            <Typography
              color={locationsSelectedCount > 0 ? blueText : 'darkestGrey'}
              fontWeight="medium"
              csx={{ whiteSpace: 'nowrap' }}>
              {name}
            </Typography>
          </Button>
        </Box>
        <Button
          variant="icon"
          whileTap={{ scale: 1 }}
          csx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '10px',
          }}
          iconPosition="right"
          icon={
            <Icon
              name={groupIsExpanded ? 'MdChevronRight' : 'MdChevronRight'}
              csx={{ rotate: groupIsExpanded ? '-90deg' : '90deg' }}
            />
          }
          onClick={() => setGroupIsExpanded(current => !current)}>
          <Typography color="darkGrey" csx={{ whiteSpace: 'nowrap' }}>
            {`[${locationsSelectedCount}/${locations.length}] `}
            {t('commonTexts.selected')}
          </Typography>
        </Button>
      </Box>

      {groupIsExpanded && (
        <Box
          csx={theme => ({
            border: `1px solid ${theme.colors.lightGrey}`,
            display: 'flex',
            flexDirection: 'column',
          })}>
          {locations.map((location, index) => (
            <PricingLocationRow
              key={`${locationGroupId}-${location.locationId}-${index}`}
              isCombo={isCombo}
              {...location}
              disabled={!menuAccessLocations.includes(location.locationId)}
              handleLocationToggle={locationId =>
                handleLocationToggle(locationGroupId, locationId)
              }
              handleLocationPrice={(locationId, priceName, payload) =>
                handlePriceChange(
                  locationGroupId,
                  locationId,
                  priceName,
                  payload,
                )
              }
              isLast={index === locations.length - 1}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default PricingLocationGroupRow;
