import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { SectionId } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AssignedModifierGroupsSection from './AssignedModifierGroupsSection';
import LinkedItemsSection from './LinkedItemsSection';

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierItems', SECTION_ID);

const AssociationsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { itemData } = useSelector(getMemoizedItemData);

  return (
    <AccordionSection
      title={t('menuScreen.modifierItemDetails.associations.title', {
        name: itemData.posName,
      })}
      {...props}>
      <AssignedModifierGroupsSection />
      <LinkedItemsSection csx={{ marginTop: 20 }} />
    </AccordionSection>
  );
};

export default AssociationsSection;
