import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';
import Background from '@app/assets/logos/BackgroundLeft.jpg';
import BackgroundMobile from '@app/assets/logos/BackgroundMobile.jpg';

export const logoBackgroundStyles = css({
  backgroundImage: `url(${BackgroundMobile})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '100vh',
  minWidth: '100vw',
  [MQ_MIN_MEDIUM]: {
    backgroundImage: `url(${Background})`,
  },
});
