import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import {
  makeSelectFreshSheetData,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import max from 'lodash/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../../HeaderTitle';
import FreshSheetTable from './FreshSheetTable';
import { FRESH_SHEET_TABLE_ID, ROW_ID_PREFIX } from './FreshSheetTable/types';
import { isShowedInRegister } from '@app/helpers/factories/LockScreen/LockScreen';

const SECTION_ID = SectionId.BASIC;
const getMemoizedItemData = makeSelectFreshSheetData(SECTION_ID);

const FreshSheet = () => {
  const { updateFreshSheet: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { freshSheetData } = useSelector(getMemoizedItemData);
  const items = useSelector(selectItems);

  // Local state
  const [openItemsModal, setOpenItemsModal] = useState(false);
  const { t } = useTranslation();
  const handleNewRowScroll = useScrollToNewRow();

  const freshSheetItemsIds = useMemo(() => {
    if (!items || !freshSheetData) return [];
    const hiddenItems = Object.values(items)
      .filter(item => !isShowedInRegister(item))
      .map(item => `${item.itemId}`);
    return [...Object.keys(freshSheetData.items || {}), ...hiddenItems];
  }, [freshSheetData, items]);

  const onNewFreshSheetItem = (newItems: IItemSelection[]) => {
    const totalOfItems =
      Object.keys(freshSheetData.items || {}).length + (newItems.length - 1);
    let nextSortPriority =
      (max(
        Object.values(freshSheetData.items || {}).map(
          freshSheetItem => freshSheetItem.freshSheetSortPriority,
        ) || [],
      ) || 0) + 1;

    const newItemsObject = newItems.reduce((prev, current) => {
      const itemObject = items[Number(current.id)];
      const menuTypeVersion = Object.values(itemObject.menuTypeVersions)?.[0];
      return {
        ...prev,
        [current.id]: {
          id: itemObject.itemId,
          name: itemObject.name,
          currentAmount: menuTypeVersion.currentAmount,
          is86ed: menuTypeVersion.is86ed,
          freshSheetSortPriority: nextSortPriority++,
        },
      };
    }, {});

    setValue(
      {
        items: {
          ...freshSheetData.items,
          ...newItemsObject,
        },
      },
      SECTION_ID,
    );
    setOpenItemsModal(false);
    handleNewRowScroll(`${ROW_ID_PREFIX}${totalOfItems}`);
  };

  return (
    <Box id={FRESH_SHEET_TABLE_ID}>
      <SelectionModal
        type="items"
        detailsScreenProps={{
          currentRelationsIds: freshSheetItemsIds,
          wantedEntity: 'items',
        }}
        onAssociate={onNewFreshSheetItem}
        onModalClose={() => setOpenItemsModal(false)}
        active={openItemsModal}
        allowAssociateMultiple
        showDescriptionPanel={false}
        btnSuccessText={t('commonButtons.done')}
        showSelectedButtons
      />
      <HeaderTitle
        title={t('loggedIn.sideBar.menu.freshSheet')}
        options={
          <Button
            variant="primary"
            onClick={() => setOpenItemsModal(true)}
            icon={<Icon name="MdAdd" />}>
            {t('menuScreen.freshSheetDetails.addFreshSheetItem')}
          </Button>
        }
      />
      <FreshSheetTable />
    </Box>
  );
};

export default FreshSheet;
