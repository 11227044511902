import Box from '@app/components/common/Box';
import Link from '@app/components/common/Link';
import Modal from '@app/components/common/Modal';
import Typography from '@app/components/common/Typography';
import { FormEvent, useState } from 'react';
import { linkWithText } from '../../../styles';
import { WithTranslation } from 'react-i18next';
import Button from '@app/components/common/Button';
import Input from '@app/components/common/Input';
import Icon from '@app/components/common/Icon';
import {
  AboveIconStyles,
  buttonsStyles,
  modalContentContainerStyles,
} from './styles';

interface IChangeEmailModal extends WithTranslation {
  onEmailChanged: (email: string) => void;
}

const ChangeEmailModal = ({ t, onEmailChanged }: IChangeEmailModal) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSetFormData = (e: FormEvent<HTMLInputElement>, field: string) => {
    const value = e.currentTarget.value;

    setFormData(prev => ({ ...prev, [field]: value }));
    setFormData(prev => ({ ...prev, error: false }));
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.email.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        onEmailChanged(formData.email);
        setIsModalActive(false);
        setIsLoading(false);
        setFormData(prev => ({ ...prev, email: '' }));
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    setIsModalActive(false);
    setFormData(prev => ({ ...prev, email: '' }));
  };

  return (
    <>
      <Box csx={[linkWithText, { marginTop: '15px' }]}>
        <Typography
          variant="body"
          align="center"
          fontWeight="medium"
          color="darkGrey">
          {t('authentication.signUp.wrongEmail')}
        </Typography>
        <Link to="#" onClick={() => setIsModalActive(true)}>
          {t('authentication.signUp.changeEmail.changeEmail')}
        </Link>
      </Box>
      <Modal
        isActive={isModalActive}
        onModalClose={handleCloseModal}
        size="600px">
        <form
          id="changeEmailForm"
          css={modalContentContainerStyles}
          onSubmit={handleSubmitForm}>
          <Box className="content">
            <Box className="iconsContainer">
              <Icon name="MdOutlineEmail" size="90px" />
              <Icon
                name="MdCached"
                color="semanticBlue"
                size="40px"
                csx={[AboveIconStyles, { right: '-5px', bottom: '5px' }]}
              />
            </Box>
            <Typography variant="heading" fontWeight="medium" className="title">
              {t('authentication.signUp.changeEmail.title')}
            </Typography>
            <Typography align="center" className="description">
              {t('authentication.signUp.changeEmail.description')}
            </Typography>
            <Input
              inputWrapperClassName="emailInput"
              id="email"
              name="email"
              label={t('authentication.signUp.changeEmail.newEmail')}
              placeholder={t('authentication.signUp.changeEmail.newEmail')}
              type="email"
              value={formData.email}
              required
              showCaptionSpace
              onChange={e => handleSetFormData(e, 'email')}
              autoFocus
            />
            <Box csx={buttonsStyles}>
              <Button type="submit" variant="active" isLoading={isLoading}>
                {t('authentication.signUp.changeEmail.sendVerificationCode')}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseModal}
                disabled={isLoading}>
                {t('commonButtons.cancel')}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default ChangeEmailModal;
