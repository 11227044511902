import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowAllPricingInputs from './ShowAllPricingInputs';
import { IShowAllPricing } from './types';

const SECTION_ID = SectionId.MODIFIER_GROUPS;

const ShowAllPricing = ({
  modifier,
  isMultipleQuantity,
  isSizeItem,
  isMultiselect,
  menuTypeVersionId,
  modifierGroupId,
  isEditable = true,
  hasBorders = true,
  inputContainerProps,
  columnWidth,
}: IShowAllPricing) => {
  //Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  return (
    <ShowAllPricingInputs
      isMultipleQuantity={isMultipleQuantity}
      isSizeItem={isSizeItem}
      isMultiselect={isMultiselect}
      isEditable={isEditable}
      data={modifier}
      onChange={(key, value) => {
        setValue(
          { [key]: value },
          SECTION_ID,
          menuTypeVersionId,
          modifierGroupId,
          modifier.modifierItemId,
        );
      }}
      hasBorders={hasBorders}
      inputContainerProps={inputContainerProps}
      columnWidth={columnWidth}
    />
  );
};

const MemoizedShowAllPricing = React.memo(ShowAllPricing, (prev, next) => {
  return (
    prev.isMultipleQuantity === next.isMultipleQuantity &&
    prev.isSizeItem === next.isSizeItem &&
    prev.isMultiselect === next.isMultiselect &&
    isEqual(prev.modifier, next.modifier) &&
    prev.t === next.t &&
    prev.isEditable === next.isEditable &&
    prev.hasBorders === next.hasBorders &&
    isEqual(prev.inputContainerProps, next.inputContainerProps)
  );
});

export default MemoizedShowAllPricing;
