import AssociationsSection from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/AssociationsSection';
import BasicSection from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/BasicSection';
import MoreInformationSection from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/MoreInformationSection';
import TaxesSection from '@app/components/LoggedIn/Menu/MenuTypes/MenuTypesDetails/TaxesSection';
import Accordion from '@app/components/common/Accordion';
import useIsSet from '@app/hooks/useIsSet';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsMenu } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const BUCKET = 'menuTypes';

function MenuTypesDetails() {
  // Redux
  const dispatch = useDispatch();
  const { loadMenuTypesMenuChangeData, setActiveStateInBreadcrumbWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  // Local state
  const { id } = useParams();

  const { assignedItemIds, assignedItemMenuTypeId } =
    (useLocation().state as {
      assignedItemIds: number[] | undefined;
      assignedItemMenuTypeId: number | undefined;
    }) || {};

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  useEffect(() => {
    if (!isSet)
      loadMenuTypesMenuChangeData({
        menuTypeId: Number(id),
        assignedItemIds,
        assignedItemMenuTypeId,
        isRefreshingData: false,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSet) return;

    setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'update');
    return () => {
      setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'reset');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isSet]);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <MoreInformationSection />
        <AssociationsSection />
        <TaxesSection />
      </Accordion>
    )
  );
}

export default MenuTypesDetails;
