import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';

export interface ITipSharingRulesTable {
  id: number;
  contributor: string;
  contribution: number;
  recipients: string;
  actions: string;
}

export interface ITipSharingRulesTableProps extends WithTranslation {
  data: ITipSharingRulesTable[];
  onNewRow: () => void;
  onRowDelete: (id: number) => void;
  onRowEdit: (id: number) => void;
}

const TipSharingRulesTable = ({
  t,
  data,
  onNewRow,
  onRowDelete,
  onRowEdit,
}: ITipSharingRulesTableProps) => {
  const theme = useTheme();
  const columnHelper = createColumnHelper<ITipSharingRulesTable>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('contributor', {
        header: t('settingsModule.paymentsSettings.tipSettings.from'),
        cell: info => <Typography>{info.getValue()}</Typography>,
      }),
      columnHelper.accessor('contribution', {
        header: t('settingsModule.paymentsSettings.tipSettings.contribution'),
        cell: info => (
          <Typography
            csx={{
              maxLines: 2,
            }}>
            {info.getValue() + '%'}
          </Typography>
        ),
      }),
      columnHelper.accessor('recipients', {
        header: t('settingsModule.paymentsSettings.tipSettings.to'),
        cell: info => <Typography>{info.getValue()}</Typography>,
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              gap: '10px',
            }}>
            <Button
              variant="primary"
              csx={{
                width: '50px',
              }}
              icon={<Icon name="MdEdit" />}
              onClick={() => onRowEdit(info.row.original.id)}
            />
            <Button
              icon={<Icon name="MdDeleteForever" />}
              csx={{
                width: '50px',
              }}
              variant="danger"
              onClick={() => onRowDelete(info.row.original.id)}
            />
          </Box>
        ),
        enableSorting: false,
        size: 110,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, theme, onRowDelete, onRowEdit],
  );

  return (
    <>
      <Table
        title={t('settingsModule.paymentsSettings.tipSettings.sharingRules')}
        key="drawersUsersTable"
        renderEmptyValues
        columns={COLUMN_VALUES}
        data={data}
        cellCsx={{ height: '70px' }}
        manualSorting={false}
        enableAlphabeticalSorting
        alignHeaders={{
          actions: 'center',
        }}
        align={{
          actions: 'center',
        }}
        noDataPlaceholder={
          <Box
            csx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70px',
            }}>
            <Typography align="center">
              {t(
                'settingsModule.paymentsSettings.tipSettings.emptySharingRules',
              )}
            </Typography>
          </Box>
        }
        addRowText={t(
          'settingsModule.paymentsSettings.tipSettings.sharingRule',
        )}
        onAddClick={onNewRow}
      />
    </>
  );
};

export default TipSharingRulesTable;
