import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import itemGroupsAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/itemGroups/itemGroupsAssignedItemsFactory';
import {
  selectIsMenuMasterMode,
  selectItems,
} from '@app/state/menu/menuSelectors';
import {
  IComboErrorFields,
  IItemCard,
  IItemGroupOfCombo,
  SectionId,
  SortOptions,
  actionCreatorsApp,
  actionCreatorsMenu,
  filter,
  useFormError,
  useSort,
} from '@westondev/tableturn-core';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Content from '../Content';
import ItemsGroupCardHeaderOptions from './ItemsGroupCardHeaderOptions';

interface IItemsGroupCard extends WithTranslation, IItemGroupOfCombo {
  isFirst: boolean;
  isLast: boolean;
  menuTypeVersionId: number;
  itemGroup: IItemGroupOfCombo;
  hasNameError: boolean;
  onArrowUpPress: () => void;
  onArrowDownPress: () => void;
  onToTopPress: () => void;
  onToBottomPress: () => void;
}

const SECTION_ID = SectionId.ITEM_GROUPS;

const ItemsGroupCard = ({
  items,
  isFirst,
  isLast,
  itemGroupId,
  menuTypeVersionId,
  required,
  itemGroup,
  hasNameError,
  onArrowUpPress,
  onArrowDownPress,
  onToTopPress,
  onToBottomPress,
}: IItemsGroupCard) => {
  const { updateCombo: setValue, deleteItemGroupFromCombo } =
    bindActionCreators(actionCreatorsMenu, useDispatch());

  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const allItems = useSelector(selectItems);

  const isMasterMode = useSelector(selectIsMenuMasterMode);

  const [showUpCharge, setShowUpCharge] = useState(false);

  const onSort = useSort<IItemCard>('title');
  const [inputValue, setInputValue] = useState('');
  const [searchFilter, setSearchFilter] = useState(inputValue);
  const [sort, setSort] = useState<SortOptions | null>(null);
  const [sortedList, setSortedList] = useState<IItemCard[]>([]);

  const assignedItemsCardList = useMemo(() => {
    return itemGroupsAssignedItemsFactory(
      allItems,
      itemGroup?.items || {},
      (deletedItemId: number) => {
        const clonedItems = cloneDeep(itemGroup.items || {});
        delete clonedItems[deletedItemId];
        setValue(
          { items: clonedItems },
          SECTION_ID,
          menuTypeVersionId,
          itemGroupId,
        );
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemGroup, allItems, SECTION_ID]);

  useEffect(() => {
    setSortedList(
      filter<IItemCard>(
        assignedItemsCardList as unknown as IItemCard[],
        { title: searchFilter },
        sort,
        onSort,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, sort, assignedItemsCardList]);

  const nameError = useFormError<IComboErrorFields>(
    `menuTypeVersions.${menuTypeVersionId}.itemGroups.${itemGroupId}.posName`,
  );

  const quantityError = useFormError<IComboErrorFields>(
    `menuTypeVersions.${menuTypeVersionId}.itemGroups.${itemGroupId}.quantity`,
  );

  const filteredItemIds = useMemo(
    () => Object.values(sortedList).map(item => item.id),
    [sortedList],
  );

  useEffect(() => {
    if (nameError?.error && !hasNameError) {
      nameError?.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNameError]);

  const renderItems = itemGroup.active || isMasterMode;

  const handleDeleteItemGroup = () => {
    const name = itemGroup.posName;
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.itemGroups.singular'),
      type: 'delete',
      name,
      onSuccess: () => {
        deleteItemGroupFromCombo(menuTypeVersionId, itemGroupId);
      },
    });
  };

  return (
    <Box csx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Card.SubCardOptions
        extraOptions={
          itemGroup.active || isMasterMode ? (
            <Checkbox
              checked={showUpCharge}
              label={t(
                'menuScreen.itemGroupsDetails.itemAssociationsSection.showUpCharge',
              )}
              onChange={value => {
                setShowUpCharge(value);
              }}
            />
          ) : undefined
        }
        extraOptionsCsx={{
          width: '100%',
          justifyContent: 'flex-start',
        }}
        titleComponent={
          <Grid
            rowGap={25}
            columnGap={25}
            csx={{ width: '100%', padding: '15px' }}>
            <Grid.Item lg={3}>
              <Input
                onChange={newValue => {
                  setValue(
                    { posName: newValue.target.value },
                    SECTION_ID,
                    menuTypeVersionId,
                    itemGroupId,
                  );
                }}
                disabled={!itemGroup.active && !isMasterMode}
                error={hasNameError}
                caption={
                  nameError?.error ||
                  (hasNameError
                    ? t('validations.itemGroupUniqueNameRequired')
                    : undefined)
                }
                label={t('commonTexts.name')}
                placeholder={t('commonTexts.name')}
                value={itemGroup.posName}
                onFocus={() => nameError && nameError.clear()}
              />
            </Grid.Item>
            <Grid.Item lg={3}>
              <MoneyInput
                label={t(
                  'menuScreen.itemDetails.modifierGroupsSection.quantity',
                )}
                prefix=""
                suffix=""
                disabled={!itemGroup.active && !isMasterMode}
                value={itemGroup.quantity || 0}
                precision={0}
                onValueChange={newValue =>
                  setValue(
                    { quantity: newValue },
                    SECTION_ID,
                    menuTypeVersionId,
                    itemGroupId,
                  )
                }
                maxValue={99}
                error={Boolean(quantityError?.error) || false}
                onFocus={() => quantityError && quantityError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              lg={6}
              csx={{
                justifyContent: 'flex-end',
                alignItems: 'end',
              }}>
              <ItemsGroupCardHeaderOptions
                isMasterMode={isMasterMode}
                required={required}
                menuTypeVersionId={menuTypeVersionId}
                itemGroupId={itemGroupId}
                itemGroup={itemGroup}
              />
            </Grid.Item>
          </Grid>
        }
        csx={{
          padding: 0,
          '.subCardTitle': { padding: '15px' },
          borderRight: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          flex: 1,
        }}
        searchBarCsx={{
          padding: '15px',
        }}
        searchBarProps={
          renderItems
            ? {
                value: inputValue,
                onDebounce: setSearchFilter,
                onSort: sortOption => setSort(sortOption),
                setInputValue: setInputValue,
              }
            : undefined
        }>
        {(itemGroup.active || isMasterMode) && (
          <>
            <Divider
              csx={{ marginTop: '-15px' }}
              lineCsx={theme => ({
                borderColor: theme.colors.lightGrey,
              })}
            />
            <Box
              csx={{
                display: 'flex',
              }}>
              <Box csx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Content
                  showUpCharge={showUpCharge}
                  filteredItemIds={filteredItemIds}
                  items={items}
                  itemGroupId={itemGroupId}
                  isEditMode={false}
                  menuTypeVersionId={menuTypeVersionId}
                />
              </Box>
            </Box>
          </>
        )}
      </Card.SubCardOptions>

      <Box
        csx={{
          width: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <Box
          csx={{
            width: '100%',
            height: '100%',
            flex: 1,
          }}>
          <Button
            variant={!isMasterMode ? 'primary' : 'danger'}
            csx={{
              borderWidth: 0,
              borderRadius: 0,
              borderBottomWidth: 1,
              borderTopWidth: 1,
              height: '100%',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: !(itemGroup.active || isMasterMode)
                ? '8px'
                : '0px',
            }}
            disableClickEffect
            iconPosition="top"
            onClick={() => {
              if (!isMasterMode) {
                setValue(
                  {
                    active: !itemGroup.active,
                  },
                  SECTION_ID,
                  menuTypeVersionId,
                  itemGroup.itemGroupId,
                );
              } else {
                handleDeleteItemGroup();
              }
            }}
            icon={
              <Icon
                name={
                  isMasterMode
                    ? 'FaTrashAlt'
                    : itemGroup.active
                    ? 'MdVisibility'
                    : 'MdVisibilityOff'
                }
                size="20px"
              />
            }>
            {!itemGroup.active && !isMasterMode && (
              <Typography variant="caption2" color="white">
                {t('commonTexts.hidden')}
              </Typography>
            )}
          </Button>
        </Box>
        {(itemGroup.active || isMasterMode) && (
          <Box
            csx={{
              flex: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Box
              csx={{
                width: '100%',
              }}>
              <>
                <Button
                  variant="primary"
                  disabled={isFirst}
                  csx={{
                    borderWidth: 0,
                    borderRadius: 0,
                    borderBottomWidth: 1,
                    height: '60px',
                  }}
                  onClick={() => onToTopPress()}
                  icon={<Icon name="MdKeyboardDoubleArrowUp" />}
                />

                <Button
                  variant="primary"
                  disabled={isFirst}
                  csx={{
                    borderWidth: 0,
                    borderRadius: 0,
                    borderBottomWidth: 1,
                    height: '60px',
                  }}
                  onClick={() => onArrowUpPress()}
                  icon={<Icon name="MdKeyboardArrowUp" />}
                />
              </>
            </Box>

            <Box
              csx={{
                width: '100%',
              }}>
              <Button
                variant="primary"
                disabled={isLast}
                csx={{
                  borderWidth: 0,
                  borderRadius: 0,
                  borderTopWidth: 1,
                  height: '60px',
                }}
                onClick={() => onArrowDownPress()}
                icon={<Icon name="MdKeyboardArrowDown" />}
              />
              <Button
                variant="primary"
                disabled={isLast}
                csx={{
                  borderWidth: 0,
                  borderRadius: 0,
                  borderBottomRightRadius: '8px',
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  height: '60px',
                }}
                onClick={() => onToBottomPress()}
                icon={<Icon name="MdKeyboardDoubleArrowDown" />}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ItemsGroupCard;
