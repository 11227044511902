import Accordion from '@app/components/common/Accordion';
import Box from '@app/components/common/Box';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import MenuTypesSection from '@app/components/LoggedIn/Menu/common/MenuTypesSection';
import NoMenuTypeLayer from '@app/components/LoggedIn/Menu/common/NoMenuTypeLayer';
import AssociationsSection from '@app/components/LoggedIn/Menu/Specials/SpecialDetails/AssociationsSection';
import AvailabilitySection from '@app/components/LoggedIn/Menu/Specials/SpecialDetails/AvailabilitySection';
import BasicSection from '@app/components/LoggedIn/Menu/Specials/SpecialDetails/BasicSection';
import MoreInformationSection from '@app/components/LoggedIn/Menu/Specials/SpecialDetails/MoreInformationSection';
import SpecialsSchemeSection from '@app/components/LoggedIn/Menu/Specials/SpecialDetails/SpecialsSchemeSection';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useIsSet from '@app/hooks/useIsSet';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectBreadCrumbs,
  selectMenuTypeVersionsIds,
  selectSelectedModeId,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  FormMode,
  MenuTypesSectionRef,
  SPECIAL_INITIAL_STATE,
  actionCreatorsApp,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const BUCKET = 'specials';

const SpecialDetails = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useDispatch();
  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);

  const { addNewMenuTypesToSpecial, setBreadCrumbStatusPath } =
    bindActionCreators(actionCreatorsMenu, dispatch);

  const loadMenuBucketChangeData = bindActionCreators(
    actionCreatorsMenuWeb.loadMenuBucketChangeData,
    dispatch,
  );

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  const { id: specialId } = useParams();
  const location = useLocation();

  const { menuTypeId, assignedMenuTypeIds } = useMemo(() => {
    if (location.state) {
      return {
        menuTypeId: location.state.menuTypeId as number,
        assignedMenuTypeIds: location.state.assignedMenuTypeIds as number[],
      };
    }
    return {
      menuTypeId: undefined,
      assignedMenuTypeIds: undefined,
    };
  }, [location]);

  const newId = useRootSelector(
    // @ts-expect-error - specialId is needed for specials
    state => state.menu.changeData.data?.specialId ?? 0,
  );

  const currentSpecial = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.specials[
        Number(specialId) || Number(newId)
      ] ?? SPECIAL_INITIAL_STATE,
  );

  const menuTypeVersions = useSelector(selectMenuTypeVersionsIds, shallowEqual);
  const breadCrumbs = useSelector(selectBreadCrumbs);
  const selectedModeId = useSelector(selectSelectedModeId);

  const isFocused =
    location.pathname === getPathWithOrgData(`/menu/specials/${specialId}`);

  // Local state
  const [selectedMenuTypeVersionId, setSelectedMenuTypeVersionId] =
    useState<number>(menuTypeId || -1);

  const navigate = useNavigateWithOrg();
  const menuTypesRef = useRef<MenuTypesSectionRef>(null);
  const prevMenuTypesRef = useRef(false);
  const prevSelectedMenuType = useRef(0);

  const mode = useMemo(
    () => (!specialId ? FormMode.CREATE : FormMode.UPDATE),
    [specialId],
  );
  const isSet = useIsSet(
    BUCKET,
    specialId === undefined ? undefined : Number(specialId),
  );

  const disabledSections =
    mode === FormMode.CREATE && selectedMenuTypeVersionId === 0;

  const currentBreadcrumbMenuTypeId = useMemo(() => {
    let _currentBreadcrumbMenuTypeId = -1;

    if (!isFocused) return _currentBreadcrumbMenuTypeId;

    if (breadCrumbs.length > 0) {
      const statusPath = breadCrumbs[breadCrumbs.length - 1].statusPath;

      if (statusPath && statusPath.split('.').length >= 5) {
        const splittedPath = statusPath.split('.');
        if (!splittedPath.includes('menuTypeVersions'))
          return _currentBreadcrumbMenuTypeId;

        _currentBreadcrumbMenuTypeId = splittedPath.includes('changeData')
          ? Number(splittedPath[3])
          : splittedPath.includes('selectedMode') && splittedPath[5]
          ? Number(splittedPath[5])
          : _currentBreadcrumbMenuTypeId;
      }
    }

    return _currentBreadcrumbMenuTypeId;
  }, [breadCrumbs, isFocused]);

  useEffect(() => {
    if (Object.values(currentSpecial.menuTypeVersions).length > 0 && !isSet) {
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(specialId),
      });
      prevMenuTypesRef.current = false;
    } else if (Object.values(currentSpecial.menuTypeVersions).length === 0) {
      setShowToast({
        title: 'No Menus',
        description: 'This Special has no Menu versions assigned.',
        type: 'error',
      });
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialId, currentSpecial, selectedModeId]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let menuTypeVersionId =
      menuTypeId ||
      Object.values(currentSpecial.menuTypeVersions)[0].menuTypeId;

    menuTypeVersionId =
      menuTypeVersions.length > 0 &&
      !menuTypeVersions.includes(menuTypeVersionId)
        ? menuTypeVersions[0]
        : menuTypeVersionId;

    if (
      selectedMenuTypeVersionId <= 0 ||
      currentBreadcrumbMenuTypeId > 0 ||
      (!currentSpecial.menuTypeVersions[selectedMenuTypeVersionId] && !isSet) ||
      !menuTypeVersions.includes(selectedMenuTypeVersionId)
    ) {
      if (currentBreadcrumbMenuTypeId > 0) {
        if (currentBreadcrumbMenuTypeId !== prevSelectedMenuType.current) {
          setSelectedMenuTypeVersionId(currentBreadcrumbMenuTypeId);
        }
      } else {
        if (!menuTypeVersionId && menuTypeId && menuTypeVersions.length === 0) {
          setSelectedMenuTypeVersionId(menuTypeId);
        } else {
          setSelectedMenuTypeVersionId(menuTypeVersionId);
        }
      }
    }

    if (menuTypeVersions.includes(0) && assignedMenuTypeIds) {
      addNewMenuTypesToSpecial(
        assignedMenuTypeIds.map(_id => ({ id: _id, title: '' })),
        0,
        () => {
          assignedMenuTypeIds.length > 0 &&
            setSelectedMenuTypeVersionId(assignedMenuTypeIds[0]);
        },
      );
    }

    if (
      menuTypesRef.current &&
      !prevMenuTypesRef.current &&
      !menuTypeVersions.includes(0) &&
      currentBreadcrumbMenuTypeId < 0
    ) {
      !menuTypeId && menuTypesRef.current?.setFirstActiveMenuType();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuTypeVersions,
    menuTypesRef.current,
    currentBreadcrumbMenuTypeId,
    selectedMenuTypeVersionId,
    currentSpecial,
  ]);

  useEffect(() => {
    if (!prevMenuTypesRef.current) {
      mode === FormMode.CREATE &&
        !assignedMenuTypeIds &&
        menuTypesRef.current?.openExistingTypes();
      prevMenuTypesRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTypesRef.current]);

  useEffect(() => {
    // Check for the selectedMenuTypeVersionId change and update the list of sections
    if (selectedMenuTypeVersionId >= 0) {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `changeData.data.menuTypeVersions.${selectedMenuTypeVersionId}.active`,
        true,
      );
      prevSelectedMenuType.current = selectedMenuTypeVersionId;
    }
    if (selectedMenuTypeVersionId > 0) {
      prevSelectedMenuType.current = selectedMenuTypeVersionId;
    }
    return () => {
      setBreadCrumbStatusPath(
        breadCrumbs.length - 1,
        `selectedMode.currentMenu.specials.${specialId}.menuTypeVersions.${
          selectedMenuTypeVersionId || prevSelectedMenuType.current
        }.active`,
        true,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbs.length, selectedMenuTypeVersionId, specialId]);

  useEffect(() => {
    if (isSet && mode === FormMode.CREATE) {
      menuTypesRef.current?.openExistingTypes();
      menuTypesRef.current?.setFirstActiveMenuType();
    }
    prevMenuTypesRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModeId, isSet]);

  const handleNewMenuType = (
    menuTypeIds: IItemSelection[],
    firstActiveMenuType?: IItemSelection,
  ) => {
    addNewMenuTypesToSpecial(menuTypeIds, selectedMenuTypeVersionId, () => {
      menuTypeIds[0] &&
        setSelectedMenuTypeVersionId(
          Number(
            firstActiveMenuType ? firstActiveMenuType.id : menuTypeIds[0].id,
          ),
        );
    });
  };

  return (
    isSet &&
    menuTypeVersions.includes(selectedMenuTypeVersionId) &&
    ((selectedMenuTypeVersionId > 0 && mode !== FormMode.CREATE) ||
      mode === FormMode.CREATE) && (
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}>
        <MenuTypesSection
          ref={menuTypesRef}
          selectedMenuTypeVersionId={selectedMenuTypeVersionId}
          setSelectedMenuTypeVersionId={setSelectedMenuTypeVersionId}
          title={t('menuScreen.specialDetails.menuTypes.title')}
          description={t('menuScreen.specialDetails.menuTypes.description')}
          sectionDescription={t(
            'menuScreen.specialDetails.menuTypes.sectionDescription',
          )}
          onNewMenuType={handleNewMenuType}
          emptyMenuTypesPath="specialDetails"
        />
        <Accordion>
          <BasicSection
            isExpanded
            disabledLayer={disabledSections ? <NoMenuTypeLayer /> : undefined}
            menuTypeVersionId={selectedMenuTypeVersionId}
          />
          <MoreInformationSection
            disabledLayer={disabledSections ? <NoMenuTypeLayer /> : undefined}
            menuTypeVersionId={selectedMenuTypeVersionId}
          />
          <AvailabilitySection
            disabledLayer={disabledSections ? <NoMenuTypeLayer /> : undefined}
            menuTypeVersionId={selectedMenuTypeVersionId}
          />
          <SpecialsSchemeSection
            disabledLayer={disabledSections ? <NoMenuTypeLayer /> : undefined}
            menuTypeVersionId={selectedMenuTypeVersionId}
          />
          <AssociationsSection
            disabledLayer={disabledSections ? <NoMenuTypeLayer /> : undefined}
            menuTypeVersionId={selectedMenuTypeVersionId}
          />
        </Accordion>
      </Box>
    )
  );
};

export default SpecialDetails;
