import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import Box from '../Box';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import Typography from '../Typography';
import { switchStyles } from './styles';
import { ISwitch } from './types';

const Switch = ({
  t,
  showDefaultLabel,
  label,
  checked = false,
  isDisabled,
  onChange,
  onClickDisabled = () => null,
  size = 'normal',
  labelCsx,
  info = label || 'TOOLTIP_INFO',
  showNoChanges,
}: ISwitch & WithTranslation) => {
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleCheckBoxClick = () => {
    if (isDisabled) return onClickDisabled();

    onChange && onChange(!checked);
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box
      csx={switchStyles(size, showNoChanges || false)}
      className={isDisabled ? 'disabled' : ''}
      onClick={handleCheckBoxClick}>
      <Box className="switchContainer" data-ischecked={checked}>
        <input
          type="checkbox"
          checked={checked}
          disabled={isDisabled}
          readOnly
        />
        <Box className="checkMark">
          <Box className="checkIcon">
            <Icon
              name={
                checked
                  ? 'MdCheck'
                  : showNoChanges
                  ? 'MdBlockFlipped'
                  : 'MdClear'
              }
              size="20"
            />
          </Box>
        </Box>
      </Box>
      {info && (
        <div className="infoButton">
          <ToolTip
            showHover={showHover}
            content={info}
            tooltipCsx={{ maxWidth: '250px' }}>
            <div />
          </ToolTip>
        </div>
      )}
      {(label || showDefaultLabel) && (
        <Typography
          onMouseOver={handlePopUp}
          onMouseOut={() => {
            if (newTimeout) clearTimeout(newTimeout);
            setShowHover(false);
          }}
          className="switchLabel"
          csx={labelCsx}>
          {showDefaultLabel
            ? t(
                `commonTexts.${
                  checked || showNoChanges ? 'active' : 'inactive'
                }`,
              )
            : label}
        </Typography>
      )}
    </Box>
  );
};

export default Switch;
