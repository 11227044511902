import Button from '@app/components/common/Button';
import { selectLocations } from '@app/state/menu/menuSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ILocationStore,
  LOCATION_INITIAL_STORE,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../Card';
import Grid from '../../Grid';
import Icon from '../../Icon';
import Table from '../../Table';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';

interface IDetailsAddress {
  address1: string | undefined | null;
  address2: string | undefined | null;
  zipCode: string | undefined | null;
  state: string | undefined | null;
  city: string | undefined | null;
  locationGroup: string | undefined | null;
}

const LocationsSelectionModal = ({
  t,
  active = false,
  onModalClose,
  buttons = [],
  onAssociate,
  allowAssociateMultiple = true,
  btnSuccessText,
  emptySectionMessage,
  showDescriptionPanel,
  showSelectedButtons,
  scrollCardSectionNumberOfColumns,
  showSelectAll = true,
  ...others
}: ICustomSelectionModal<unknown>) => {
  // Redux
  const locationsGroups = useSelector(selectLocations);

  // Local state
  const [selectedLocation, setSelectedLocation] = useState<ILocationStore>();
  const [dataAddress, setDataAddress] = useState<IDetailsAddress[]>([]);

  const findLocation = (id: number | string): ILocationStore | undefined => {
    let _location;

    for (const key in locationsGroups) {
      _location = locationsGroups[key].locations.find(
        location => location.id === id,
      );

      if (_location) {
        break;
      }
    }

    return _location;
  };

  const handleOnPress = (button: IItemSelection) => {
    if (Number(button.id) >= 0) {
      const _location = findLocation(button.id) || LOCATION_INITIAL_STORE;
      setSelectedLocation(_location);
      setDataAddress([
        {
          address1: _location?.streetLine1,
          address2: _location?.streetLine2,
          zipCode: _location?.postalCode,
          state: _location?.state,
          city: _location?.city,
          locationGroup: locationsGroups[_location?.locationGroupId]?.name,
        },
      ]);
    }
  };

  const columnHelperAddress = createColumnHelper<IDetailsAddress>();

  const COLUMN_VALUES_ADDRESS = [
    columnHelperAddress.accessor('address1', {
      header: 'Address 1',
      cell: info => info.getValue(),
      size: 200,
    }),
    columnHelperAddress.accessor('address2', {
      header: 'Address 2',
      cell: info => info.getValue(),
      size: 200,
    }),
    columnHelperAddress.accessor('zipCode', {
      header: 'Zip Code',
      cell: info => info.getValue(),
      size: 200,
    }),
    columnHelperAddress.accessor('state', {
      header: 'State',
      cell: info => info.getValue(),
      size: 200,
    }),
    columnHelperAddress.accessor('city', {
      header: 'City',
      cell: info => info.getValue(),
      size: 200,
    }),
    columnHelperAddress.accessor('locationGroup', {
      header: 'Location Group',
      cell: info => info.getValue(),
      size: 200,
    }),
  ];

  return (
    <GenericSelectionModal
      {...others}
      onPress={button => handleOnPress(button)}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={
        allowAssociateMultiple
          ? t('menuScreen.selectionModal.locations.title')
          : t('menuScreen.selectionModal.locations.titleSingular')
      }
      btnSuccessText={btnSuccessText}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.locations.selectedSectionTitle',
      )}
      onAssociate={onAssociate}
      emptySectionMessage={
        emptySectionMessage
          ? emptySectionMessage
          : t('menuScreen.selectionModal.locations.emptySectionText')
      }
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.locations.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.locations.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      showSelectedButtons={showSelectedButtons}
      scrollCardSectionNumberOfColumns={scrollCardSectionNumberOfColumns}
      showSelectAll={showSelectAll}>
      {({ onSelect, isButtonSelected }) => (
        <Grid rowGap={10}>
          <Grid.Item>
            <Card.SubCard title={selectedLocation?.streetLine1}>
              <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'center' }}>
                <Grid.Item mb={9} md={9} lg={10}>
                  <Typography color="darkestGrey">
                    ID: {selectedLocation?.id}
                  </Typography>
                </Grid.Item>
                <Grid.Item mb={3} md={3} lg={2}>
                  {isButtonSelected ? (
                    <Button
                      icon={<Icon name="MdDeleteForever" />}
                      variant="danger"
                      onClick={() => onSelect()}
                    />
                  ) : (
                    <Button onClick={() => onSelect()} variant="primary">
                      {t('menuScreen.selectionModal.selectButton')}
                    </Button>
                  )}
                </Grid.Item>
              </Grid>
            </Card.SubCard>
          </Grid.Item>
          <Grid.Item>
            <Card.SubCard title="Details">
              <Table
                columns={COLUMN_VALUES_ADDRESS}
                isInverted
                data={dataAddress}
              />
            </Card.SubCard>
          </Grid.Item>
        </Grid>
      )}
    </GenericSelectionModal>
  );
};

export default LocationsSelectionModal;
