import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Tag from '@app/components/LoggedIn/Settings/RegisterMode/TagsSettings/OpenItemTypes/Tag';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import OptionsModal from '@app/components/common/OptionsModal';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import useRootSelector from '@app/hooks/useRootSelector';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  makeSelectSettingsData,
  selectCurrentSettingsData,
} from '@app/state/selectors/settingsSelectors';
import { RootState, store, useAppDispatch } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  IPrepStationsSettings,
  IPrinter,
  PREP_STATION_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getPrepStationId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { cloneDeep, max } from 'lodash';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tagsContainer } from '../../RegisterMode/OpenItemTypes/styles';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import DefaultPrepStation from '@app/components/LoggedIn/Settings/Printing/PrepStations/DefaultPrepStation';

const { areSettingsMasterModeSelector, printersSelector } = settingsSelectors;

interface IItemPrepStationsTable {
  id: number;
  active: boolean;
  name: string;
  posName: string;
  printers: IPrinter[];
  delete: string;
}

const ROW_ID_PREFIX = 'prep-station-';
const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IPrepStationsSettings>(SECTION_ID);

const columnHelper = createColumnHelper<IItemPrepStationsTable>();

const PrepStations = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const loadPrepStationsSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadPrepStationsSettingsChangeData,
    dispatch,
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const { data } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const hasMultipleLocations = useRootSelector(
    selectShouldRenderLocationDropdown,
  );

  const originalData = useRootSelector(
    state => state.settings.changeData.originalData,
  ) as IPrepStationsSettings;

  const printers = useSelector(printersSelector);

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'prepStations',
  );

  useEffect(
    () => {
      if (!isSet) loadPrepStationsSettingsChangeData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSet],
  );

  // Local state
  const PRINTER_TYPES = useMemo(
    () => [
      {
        type: 'receipt',
        label: t('settingsModule.prepStationsSettings.printerTypes.receipt'),
      },
      {
        type: 'kitchen',
        label: t('settingsModule.prepStationsSettings.printerTypes.kitchen'),
      },
      {
        type: 'sticky',
        label: t('settingsModule.prepStationsSettings.printerTypes.sticky'),
      },
    ],
    [t],
  );

  const updatePrepStationsItem = (
    newValue: any,
    id: number,
    field: keyof IPrepStationsSettings['prepStations'],
  ) => {
    const currentPrepStationsData = (
      selectCurrentSettingsData(
        store.getState() as CoreRootState & RootState,
      ) as IPrepStationsSettings
    ).prepStations;
    const currentPrepStationsItem = currentPrepStationsData[id];
    setValue(
      {
        prepStations: {
          ...currentPrepStationsData,
          [id]: {
            ...currentPrepStationsItem,
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updatePrepStationsName = (newValue: any, id: number) => {
    const currentPrepStationsData = (
      selectCurrentSettingsData(
        store.getState() as CoreRootState & RootState,
      ) as IPrepStationsSettings
    ).prepStations;
    const currentPrepStationsItem = currentPrepStationsData[id];

    setValue(
      {
        prepStations: {
          ...currentPrepStationsData,
          [id]: {
            ...currentPrepStationsItem,
            name: newValue,
            ...(currentPrepStationsItem.posName ===
              currentPrepStationsItem.name && { posName: newValue }),
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number) => {
    const currentPrepStationsItems = (
      selectCurrentSettingsData(
        store.getState() as CoreRootState & RootState,
      ) as IPrepStationsSettings
    ).prepStations;

    const prepStationsItemsCloned = cloneDeep(currentPrepStationsItems);
    delete prepStationsItemsCloned[id];

    setValue({ prepStations: prepStationsItemsCloned }, SECTION_ID);
  };

  const handleScrollOnNewPrepStation = useScrollToNewRow();

  const handleNewPrepStation = () => {
    const currentPrepStationsData = (
      selectCurrentSettingsData(store.getState()) as IPrepStationsSettings
    ).prepStations;

    const nextId =
      (max(
        Object.keys(currentPrepStationsData || {})?.map(key => Number(key)) ||
          [],
      ) || 0) + 1;

    const nextIndex = Object.keys(currentPrepStationsData || {}).length;

    setValue(
      {
        prepStations: {
          ...currentPrepStationsData,
          [nextId]: {
            ...PREP_STATION_INITIAL_STATE,
            id: nextId,
          },
        },
      },
      SECTION_ID,
    );

    handleScrollOnNewPrepStation(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('active', {
        header: 'Status',
        cell: info => (
          <Switch
            checked={info.getValue()}
            onChange={isActive =>
              updatePrepStationsItem(isActive, info.row.original.id, 'active')
            }
          />
        ),
        invertSorting: true,
        sortDescFirst: false,
        size: 70,
      }),
      columnHelper.accessor('name', {
        header: t('settingsModule.prepStationsSettings.general.name'),
        cell: info => (
          <InputCell
            disabled={!info.row.original.delete && !isMasterMode}
            value={info.getValue() || ''}
            required
            placeholder={t('settingsModule.prepStationsSettings.general.name')}
            onChange={e => {
              updatePrepStationsName(
                e.currentTarget.value,
                info.row.original.id,
              );
            }}
            errorId={getPrepStationId(`${info.row.original.id}`, 'name')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('posName', {
        header: t('settingsModule.prepStationsSettings.general.posName'),
        cell: info => (
          <InputCell
            value={info.getValue() || ''}
            required
            placeholder={t(
              'settingsModule.prepStationsSettings.general.posName',
            )}
            onChange={e =>
              updatePrepStationsItem(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              )
            }
            errorId={getPrepStationId(`${info.row.original.id}`, 'posName')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('printers', {
        header: t('settingsModule.prepStationsSettings.general.printers'),
        cell: info => {
          return (
            <Box
              csx={{
                display: 'flex',
                gap: '10px',
                width: '100%',
                alignItems: 'center',
              }}>
              <OptionsModal
                title={t('settingsModule.prepStationsSettings.printers')}
                options={Object.keys(printers)?.map(key => ({
                  label: printers?.[key]?.name,
                  value: printers?.[key]?.id,
                  type: ['kitchen'],
                }))}
                currentOptionsSelected={info.getValue()?.map(printer => ({
                  label: printer.name,
                  value: printer.id,
                  type: ['kitchen'],
                }))}
                onChangeDone={selectedPrinters => {
                  updatePrepStationsItem(
                    selectedPrinters?.map(printer => printer.value),
                    info.row.original.id,
                    'printerIds',
                  );
                }}
                isSearchable
                returnItemOnChange
                activateItemOnSelect={false}
                clearSelectedOptionsOnModalClose={false}
                allowMultipleSelection
                groupOptionsByType={PRINTER_TYPES}
                customText={t(
                  'settingsModule.prepStationsSettings.addPrinterButtonText',
                )}
                buttonProps={{
                  icon: <Icon name="MdAdd" />,
                  iconPosition: 'left',
                  variant: 'primary',
                  style: {
                    minWidth: '140px',
                    maxWidth: '140px',
                    justifyContent: 'center',
                  },
                  disabled: isMasterMode && hasMultipleLocations,
                }}
                selectedOptionsLabel={t(
                  'settingsModule.prepStationsSettings.selectedPrinters',
                )}
                noOptionsSelectedLabel={t(
                  'settingsModule.prepStationsSettings.noPrintersSelected',
                )}
              />
              <Divider csx={{ height: '50px' }} direction="vertical" />
              <Box csx={tagsContainer}>
                {info.getValue()?.map((printer, index) => (
                  <Tag
                    key={index}
                    width="130px"
                    onClick={() => {
                      const newPrinterIds = info
                        .getValue()
                        ?.filter(i_printer => i_printer.id !== printer.id)
                        .map(i_printer => i_printer.id);
                      updatePrepStationsItem(
                        newPrinterIds,
                        info.row.original.id,
                        'printerIds',
                      );
                    }}>
                    {printer?.name}
                  </Tag>
                ))}
              </Box>
            </Box>
          );
        },
        minSize: 440,
        sortingFn: (rowA, rowB, columnId) => {
          return (
            ((rowA.getValue(columnId) as number[]).length || 0) -
            ((rowB.getValue(columnId) as number[]).length || 0)
          );
        },
        invertSorting: true,
        sortDescFirst: false,
      }),
      columnHelper.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info => (
          <Button
            color="danger"
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}></Button>
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMasterMode, t, printers],
  );

  const prepStationsItemsArray = useMemo(
    () =>
      Object.keys(data?.prepStations || {})?.map(
        key =>
          ({
            id: Number(key),
            active: data.prepStations[key]?.active,
            name: data.prepStations[key]?.name,
            posName: data.prepStations[key]?.posName,
            printers: data.prepStations[key]?.printerIds?.map(
              printerId => printers[printerId],
            ),
            delete: originalData.prepStations[key] ? '' : 'canDelete',
          }) as IItemPrepStationsTable,
      ),
    [data?.prepStations, originalData?.prepStations, printers],
  );

  return (
    <Box>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '0px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title={t('settingsModule.prepStationsSettings.title')}
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={handleNewPrepStation}>
              {t(
                'settingsModule.prepStationsSettings.addPrepStationButtonText',
              )}
            </Button>
          }
        />
      </m.div>

      <Box
        csx={{
          display: 'flex',
          padding: '0 15px 15px 15px',
          flexDirection: 'column',
          gap: '15px',
        }}>
        <DefaultPrepStation />

        <Table
          data={prepStationsItemsArray}
          columns={COLUMN_VALUES}
          cellCsx={{ height: '80px' }}
          alignHeaders={{
            delete: 'center',
          }}
          align={{
            delete: 'center',
          }}
          requiredColumns={['name', 'posName']}
          renderEmptyValues
          rowIdPrefix={ROW_ID_PREFIX}
          noDataMessage={t(
            'settingsModule.kitchenTicketSettings.prepStationsSection.emptyCardsMessage',
          )}
        />
      </Box>
    </Box>
  );
};

export default PrepStations;
