import Typography from '@app/components/common/Typography';
import { ICategory } from '@westondev/tableturn-core';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { buttonCategoryStyles } from './styles';

export interface IButtonCategory {
  category: ICategory;
  isActive: boolean;
  isLastElement: boolean;
  onClick: (category: ICategory) => void;
}

const ButtonCategory = ({
  category,
  isActive = false,
  isLastElement,
  onClick,
}: IButtonCategory) => {
  const { name, backgroundColor, textColor } = category;

  return (
    <button
      css={theme =>
        buttonCategoryStyles(theme, isActive, isLastElement, backgroundColor)
      }
      onClick={() => onClick(category)}>
      <Typography
        color={isActive ? (textColor ? textColor : undefined) : 'black'}
        csx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {name}
      </Typography>
    </button>
  );
};

const ButtonCategoryMemo = React.memo(ButtonCategory, (prev, next) => {
  return (
    prev.isLastElement === next.isLastElement &&
    prev.isActive === next.isActive &&
    isEqual(prev.category, next.category)
  );
});

export default ButtonCategoryMemo;
