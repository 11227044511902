import { TCsx } from '@emotion/react';
import React, { useState } from 'react';
import Box from '../../Box';
import Grid from '../../Grid';
import ToolTip from '../../ToolTip';
import Typography from '../../Typography';
import { subCardStyles } from './styles';

interface ISubCard {
  children?: React.ReactNode;
  title?: string;
  titleComponent?: React.ReactNode;
  description?: string;
  required?: boolean;
  options?: React.ReactNode;
  csx?: TCsx;
  showCardShadow?: boolean;
  optionsLeft?: React.ReactNode;
  noCardsPadding?: boolean;
  info?: string;
}

const SubCard = ({
  children,
  title,
  options,
  description,
  required,
  info,
  csx,
  showCardShadow = false,
  titleComponent,
  optionsLeft,
  noCardsPadding = false,
}: ISubCard) => {
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box csx={[subCardStyles(showCardShadow, noCardsPadding), csx]}>
      {(title || description || titleComponent) && (
        <Box
          className="subCardHeader"
          csx={{ marginBottom: title || options ? '15px' : '0px' }}>
          <Grid columnGap={10} rowGap={10}>
            <Grid.Item mb={12} sm={12} md={12} lg={6} xl={8}>
              <Box className="subCardTitle">
                {(title || titleComponent) && (
                  <Box
                    csx={{ display: 'flex', alignItems: 'center' }}
                    onMouseOver={handlePopUp}
                    onMouseOut={() => {
                      if (newTimeout) clearTimeout(newTimeout);
                      setShowHover(false);
                    }}>
                    {info && (
                      <ToolTip showHover={showHover} content={info}>
                        <div />
                      </ToolTip>
                    )}
                    {title && (
                      <Typography
                        csx={{ display: 'flex', gap: '5px' }}
                        color="persistentSemanticBlue"
                        fontWeight="medium">
                        {title}
                      </Typography>
                    )}
                    {required && (
                      <Typography
                        fontWeight="bold"
                        color="red"
                        csx={{
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        {' '}
                        *
                      </Typography>
                    )}
                    {titleComponent && titleComponent}
                    {optionsLeft && (
                      <Box csx={{ marginLeft: '15px' }}>{optionsLeft}</Box>
                    )}
                  </Box>
                )}

                {description && (
                  <Typography color="darkestGrey" variant="caption">
                    {description}
                  </Typography>
                )}
              </Box>
            </Grid.Item>
            <Grid.Item mb={12} sm={12} md={12} lg={6} xl={4}>
              <Box
                csx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  height: '100%',
                }}>
                {options && options}
              </Box>
            </Grid.Item>
          </Grid>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SubCard;
