import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Typography from '@app/components/common/Typography';
import { categoriesDropdownOptionsFactory } from '@app/helpers/factories/menu/categoriesDropdownFactory';
import {
  makeSelectItemData,
  selectCategories,
} from '@app/state/menu/menuSelectors';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import { upperCase } from 'lodash';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignedItemsCombosSection from './AssignedItemsCombosSection';

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('subcategories', SECTION_ID);

const AssociationsSection = ({
  categoryId,
  t,
  ...props
}: IAccordionSection & WithTranslation & { categoryId: number }) => {
  const { updateSubcategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { itemData } = useSelector(getMemoizedItemData);
  const categories = useSelector(selectCategories);

  const options = useMemo(
    () => [
      categoriesDropdownOptionsFactory(
        Object.keys(categories).map(Number),
        true,
      ),
    ],
    [categories],
  );

  return (
    <AccordionSection
      id={SECTION_ID}
      title={
        <Box>
          <Typography>
            {upperCase(t('commonTexts.associated'))}
            {': '}
            <Typography
              csx={{
                fontStyle: 'italic',
                display: 'inline',
              }}>
              {t('menuScreen.subcategoryDetails.associations.title')}
            </Typography>
          </Typography>
        </Box>
      }
      {...props}>
      <Box csx={{ marginBottom: '20px', maxWidth: '240px' }}>
        <Dropdown
          label={t('menuScreen.subcategoryDetails.associations.category.label')}
          placeholder={t(
            'menuScreen.subcategoryDetails.associations.category.placeholder',
          )}
          info={t(
            'menuScreen.subcategoryDetails.associations.category.toolTip',
          )}
          data={options}
          value={itemData?.categoryId || 0}
          onChange={_categoryId =>
            setValue({ categoryId: _categoryId === 0 ? null : _categoryId })
          }
        />
      </Box>
      <Card.SubCard>
        <AssignedItemsCombosSection
          bucket="items"
          categoryId={categoryId}
          csx={{
            borderWidth: 0,
            padding: 0,
            borderRadius: 0,
          }}
        />
        <AssignedItemsCombosSection
          bucket="combos"
          categoryId={categoryId}
          csx={{ borderWidth: 0, paddingLeft: 0, paddingRight: 0 }}
        />
      </Card.SubCard>
    </AccordionSection>
  );
};

export default AssociationsSection;
