import {
  IMenuStore,
  ISettingsStore,
  MENU_INITIAL_STATE,
} from '@westondev/tableturn-core';
import useRootSelector from './useRootSelector';

const useIsSet = (
  bucket: keyof ISettingsStore | keyof IMenuStore,
  id: number | undefined = undefined,
) => {
  const isMenu = bucket in MENU_INITIAL_STATE;

  const isSet = useRootSelector(data => {
    const isRightBucket = isMenu
      ? data.menu.changeData.bucketSelected === bucket ||
        data.menu.changeMassUpdateData.bucketSelected === bucket
      : data.settings.changeData.settingsSelected === bucket;

    if (!isRightBucket || id === undefined) {
      return isRightBucket;
    }

    return isMenu
      ? data.menu.changeData.id === id
      : data.settings.changeData.id === id;
  });

  return isSet;
};

export default useIsSet;
