import { IPricingLocationGroupRow } from '@westondev/tableturn-core';
import { RadioSelectionStates, TPricesNames, UpdateType } from './types';
import { store } from '@app/state/store';

type ItemPriceListActions =
  | {
      isCombo: boolean;
      type: 'toggleLocation';
      locationGroup: number;
      location: number;
    }
  | {
      isCombo: boolean;
      type: 'toggleLocationGroup';
      locationGroup: number;
      state: RadioSelectionStates;
    }
  | {
      isCombo: boolean;
      type: 'changePrice';
      locationGroup: number;
      location: number;
      priceName: TPricesNames;
      payload: number;
    }
  | {
      isCombo: boolean;
      type: 'massChangePrice';
      priceName: TPricesNames;
      updateType: UpdateType;
      amount: number;
    };

const itemPricingModalReducer = (
  state: IPricingLocationGroupRow[],
  action: ItemPriceListActions,
) => {
  const menuAccessLocations = store.getState().loggedInUser.menuAccessLocations;
  switch (action.type) {
    case 'toggleLocationGroup':
      return state.map(locationGroup =>
        locationGroup.locationGroupId === action.locationGroup
          ? {
              ...locationGroup,
              locations: locationGroup.locations.map(location => ({
                ...location,
                selected: !menuAccessLocations.includes(location.locationId)
                  ? false
                  : action.state === RadioSelectionStates.FULL
                  ? false
                  : true,
              })),
            }
          : locationGroup,
      );
    case 'toggleLocation':
      return state.map(locationGroup =>
        locationGroup.locationGroupId === action.locationGroup
          ? {
              ...locationGroup,
              locations: locationGroup.locations.map(location =>
                location.locationId === action.location
                  ? { ...location, selected: !location.selected }
                  : location,
              ),
            }
          : locationGroup,
      );
    case 'changePrice':
      return state.map(locationGroup =>
        locationGroup.locationGroupId === action.locationGroup
          ? {
              ...locationGroup,
              locations: locationGroup.locations.map(location =>
                location.locationId === action.location
                  ? {
                      ...location,
                      [action.isCombo && action.priceName === 'itemPrice'
                        ? 'comboBasePrice'
                        : action.priceName]: action.payload,
                    }
                  : location,
              ),
            }
          : locationGroup,
      );
    case 'massChangePrice':
      return state.map(locationGroup => ({
        ...locationGroup,
        locations: locationGroup.locations.map(location =>
          location.selected
            ? action.priceName === 'all'
              ? action.isCombo
                ? {
                    ...location,
                    comboBasePrice: getPriceByParams(
                      location.comboBasePrice,
                      action.updateType,
                      action.amount,
                    ),
                    onlinePrice: getPriceByParams(
                      location.onlinePrice,
                      action.updateType,
                      action.amount,
                    ),
                    deliveryPrice: getPriceByParams(
                      location.deliveryPrice,
                      action.updateType,
                      action.amount,
                    ),
                  }
                : {
                    ...location,
                    itemPrice: getPriceByParams(
                      location.itemPrice,
                      action.updateType,
                      action.amount,
                    ),
                    onlinePrice: getPriceByParams(
                      location.onlinePrice,
                      action.updateType,
                      action.amount,
                    ),
                    deliveryPrice: getPriceByParams(
                      location.deliveryPrice,
                      action.updateType,
                      action.amount,
                    ),
                  }
              : {
                  ...location,
                  [action.isCombo && action.priceName === 'itemPrice'
                    ? 'comboBasePrice'
                    : action.priceName]: getPriceByParams(
                    location[
                      action.isCombo && action.priceName === 'itemPrice'
                        ? 'comboBasePrice'
                        : action.priceName
                    ],
                    action.updateType,
                    action.amount,
                  ),
                }
            : location,
        ),
      }));

    default:
      return state;
  }
};

const getPriceByParams = (
  currentPrice: number,
  updateType: UpdateType,
  amount: number,
) => {
  switch (updateType) {
    case UpdateType.PERCENT:
      return (
        (currentPrice * 100 +
          Math.trunc(((amount * currentPrice) / 100) * 100)) /
        100
      );
    case UpdateType.FIXED:
      return currentPrice + amount;
    case UpdateType.SET:
    default:
      return amount;
  }
};

export default itemPricingModalReducer;
