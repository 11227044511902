import Grid from '@app/components/common/Grid';
import {
  ITaxesSettings,
  OrderType,
  TChange,
  TaxType,
  VolumeUnitType,
  formatMoney,
  orderTypesText,
  typesText,
  unitsText,
} from '@westondev/tableturn-core';
import { ITaxesTableDiff } from '../types';
import RenderChange from '../../../common/RenderChange';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { selectCurrentSettingsData } from '@app/state/selectors/settingsSelectors';
import { useSelector } from 'react-redux';
import { WithTranslation } from 'react-i18next';

const TaxesSingleRowDiff = ({
  t,
  taxes,
}: ITaxesTableDiff & WithTranslation) => {
  const tax = taxes[0];
  const changeData = useSelector(selectCurrentSettingsData) as ITaxesSettings;

  const formatValue = (value: unknown, field: string) => {
    const type = taxes?.length
      ? changeData[taxes[0].id].taxType
      : TaxType.PERCENT;
    if (field === 'unit') {
      return unitsText[value as VolumeUnitType];
    }
    if (field === 'interval') return Number(value).toLocaleString();
    if (field === 'rate') {
      if (type === TaxType.PERCENT) return `${value}%`;
      return formatMoney(null, value as number);
    }
    return _formatValue(value, field);
  };

  const joinOrderTypes = (orderTypes: string) => {
    const arr = orderTypes.split(',');
    const displayedOrderTypes = arr.map(
      orderType => orderTypesText[orderType as OrderType],
    );
    displayedOrderTypes.sort();
    return displayedOrderTypes.join(', ');
  };

  const renderChange = (change: TChange) => (
    <Grid.Item
      mb={12}
      sm={6}
      md={4}
      lg={4}
      key={`lockScreenSettings_basic_${change.field}`}>
      <RenderChange
        change={{
          ...change,
          label: t(
            `settingsModule.taxesSettings.taxesTable.${change.field}` as any,
          ),
          value:
            change.field === 'orderTypes'
              ? joinOrderTypes(change.value as string)
              : change.field === 'taxType'
              ? typesText[change.value as keyof typeof typesText]
              : change.value,
        }}
        formatValue={formatValue}
      />
    </Grid.Item>
  );

  return (
    <Grid columnGap={20} rowGap={10}>
      {tax.changes
        .filter(({ field }) => field !== 'tableTypeData' && field !== 'id')
        .map(renderChange)}
    </Grid>
  );
};

export default TaxesSingleRowDiff;
