import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { IFilterModalOption } from '../types';
import Typography from '@app/components/common/Typography';
import { Theme, css } from '@emotion/react';

export const FilterModalOption = <T,>({
  t,
  index,
  option,
  currentValue,
  onClear,
  isClearDisabled,
  onFilter,
}: IFilterModalOption<T>) => {
  return (
    <Box key={`filterOption-${index}`} csx={filterModalOptionStyles}>
      <Box className="optionHeader">
        {option.label && (
          <Box className="labelBox">
            <Typography color="semanticBlue">{option.label}</Typography>
          </Box>
        )}
        {onClear && (
          <Button
            color="primaryDark"
            className="clearButton"
            onClick={onClear}
            disabled={isClearDisabled}>
            {t('commonButtons.clear')}
          </Button>
        )}
      </Box>
      <Box className="divider" />
      <Box className="optionsBox">
        {option.options.map((nestedOption, buttonIndex) => {
          const isActive =
            currentValue
              ?.find?.(value => value.id === option.id)
              ?.values?.includes?.(nestedOption.value) || false;
          return (
            <Button
              key={`filterOption-${index}-filterButton-${buttonIndex}`}
              variant={isActive ? 'active' : 'secondary'}
              className="optionButton"
              onClick={() => {
                onFilter(nestedOption.value, option.id);
              }}>
              {nestedOption.label}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

const filterModalOptionStyles = (theme: Theme) =>
  css({
    gap: '10px',
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: '8px',
    '.optionHeader': {
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center',
    },
    '.labelBox': {
      flexGrow: 1,
    },
    '.clearButton': {
      width: '140px',
    },
    '.divider': {
      height: '1px',
      width: '100%',
      backgroundColor: theme.colors.lightGrey,
    },
    '.optionsBox': {
      padding: '10px 15px',
      overflow: 'auto',
      display: 'grid',
      width: '100%',
      gap: '10px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  });
