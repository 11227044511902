import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import {
  specialCombosFactory,
  specialItemsFactory,
} from '@app/helpers/factories/menu/cardFactories/specials';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectCombos, selectItems } from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ISpecial,
  ISpecialMenuTypeVersion,
  IWithMenuTypeVersionId,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type ISpecialItemsSection = WithTranslation &
  IWithMenuTypeVersionId & {
    variant: 'get' | 'buy';
    data: ISpecial;
  };

const SECTION_ID = SectionId.ASSOCIATION;

const ItemsSection = ({
  t,
  variant,
  menuTypeVersionId,
  data,
}: ISpecialItemsSection) => {
  // Redux
  const dispatch = useDispatch();
  const navigate = useNavigateWithOrg();

  const { updateSpecial: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);

  // Local state
  const onRefresh = useRefreshMenuWeb();
  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const menuTypeVersion = data?.menuTypeVersions?.[menuTypeVersionId];

  const buyItemIds = menuTypeVersion?.buyItemIds ?? [];
  const getItemIds = menuTypeVersion?.getItemIds ?? [];
  const buyComboIds = menuTypeVersion?.buyComboIds ?? [];
  const getComboIds = menuTypeVersion?.getComboIds ?? [];

  const applyTo = data?.menuTypeVersions?.[menuTypeVersionId]?.specialApplyTo;

  const mimicItems = data?.menuTypeVersions?.[menuTypeVersionId]?.mimicItems;

  const isBuy = variant === 'buy';

  const currentItemIds = isBuy ? buyItemIds : getItemIds;
  const currentComboIds = isBuy ? buyComboIds : getComboIds;

  const fieldItemName = isBuy ? 'buyItemIds' : 'getItemIds';
  const fieldComboName = isBuy ? 'buyComboIds' : 'getComboIds';

  const itemsCardList = useMemo(() => {
    return specialItemsFactory(
      items,
      currentItemIds,
      (id, text, openInNewTab) => {
        const pathURL = `/menu/items/${id}`;
        if (openInNewTab) return openNewTabWithOrgData(pathURL);

        const _navigate = () => navigate(pathURL);
        checkForChangesAndNavigateWeb(
          () =>
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.NAV,
              text,
              onPress: _navigate,
              pathURL,
            }),
          onRefresh,
        );
      },
      (deletedItemId: number) => {
        const newItemIds = currentItemIds.filter(
          itemId => deletedItemId !== itemId,
        );
        const values = mimicItems
          ? { buyItemIds: newItemIds, getItemIds: newItemIds }
          : { [fieldItemName]: newItemIds };
        setValue(values, SECTION_ID, menuTypeVersionId);
      },
      (id: number) => getPathWithOrgData(`/menu/items/${id}`),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, currentItemIds, mimicItems]);

  const combosCardList = useMemo(() => {
    return specialCombosFactory(
      combos,
      currentComboIds,
      (deletedComboId: number) => {
        const newComboIds = currentComboIds.filter(
          comboId => deletedComboId !== comboId,
        );
        const values = mimicItems
          ? { buyComboIds: newComboIds, getComboIds: newComboIds }
          : { [fieldComboName]: newComboIds };
        setValue(values, SECTION_ID, menuTypeVersionId);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combos, currentComboIds, mimicItems]);

  const elementsCardList = useMemo(
    () => orderBy([...itemsCardList, ...combosCardList], element => element.id),
    [itemsCardList, combosCardList],
  );

  const filterItemsFunction = (selectedItems: IItemSelection[]) => {
    return selectedItems.filter(element => {
      if (
        element.entity === 'combos' &&
        (!combos[Number(element.id)]?.menuTypeVersions?.[menuTypeVersionId] ||
          currentComboIds.includes(Number(element.id)))
      ) {
        return false;
      } else if (
        element.entity === 'items' &&
        (!items[Number(element.id)]?.menuTypeVersions?.[menuTypeVersionId] ||
          currentItemIds.includes(Number(element.id)))
      ) {
        return false;
      }
      return true;
    });
  };

  const handleAssociate = (buttons: IItemSelection[]) => {
    const newItemIds = [
      ...currentItemIds,
      ...buttons
        .filter(item => item.entity === 'items')
        .map(item => Number(item.id)),
    ];

    const newComboIds = [
      ...currentComboIds,
      ...buttons
        .filter(combo => combo.entity === 'combos')
        .map(combo => Number(combo.id)),
    ];

    const values = mimicItems
      ? {
          buyItemIds: newItemIds,
          getItemIds: newItemIds,
          buyComboIds: newComboIds,
          getComboIds: newComboIds,
        }
      : { [fieldItemName]: newItemIds, [fieldComboName]: newComboIds };

    setValue(values, SECTION_ID, menuTypeVersionId);
    setOpenAddExistingItem(false);
  };

  const handleClearAllItems = () => {
    setValue(
      { getItemIds: [], getComboIds: [] },
      SECTION_ID,
      menuTypeVersionId,
    );
  };

  const handleDisassociate = (id: number | string) => {
    const currentItem = elementsCardList.find(item => item.id === id);
    if (currentItem && currentItem.onRemoveClick) {
      currentItem.onRemoveClick(id);
    }
  };

  return (
    <>
      <AssignedElements
        type="itemsAndCombos"
        filterFunction={filterItemsFunction}
        elements={elementsCardList}
        active={openAddExistingItem}
        onModalClose={() => setOpenAddExistingItem(false)}
        onDisassociate={handleDisassociate}
        onAssociate={handleAssociate}
        cardsDisabledLayer={
          !isBuy && mimicItems ? (
            <Typography>
              {t(
                'menuScreen.specialDetails.associations.getItemIds.mimicOverlay',
              )}
            </Typography>
          ) : undefined
        }
        subCardProps={{
          title: t(
            `menuScreen.specialDetails.associations.${fieldItemName}.title.${applyTo}` as ParseKeys,
          ),
          subtitle: t(
            `menuScreen.specialDetails.associations.${fieldItemName}.description.${applyTo}` as ParseKeys,
          ),
          actionOptions: [
            {
              text: t(
                'menuScreen.menuTypeDetails.assignedItems.actionButtons.addNewItem',
              ),
              icon: true,
              handler: () =>
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.ADD,
                      text: t(
                        'menuScreen.menuLocation.actionButtons.addNewItemTag',
                      ),
                      onPress: () => {
                        //  TODO: navigate to the correct screen
                      },
                      pathURL: '',
                    }),
                  onRefresh,
                  true,
                  false,
                  'items',
                ),
            },
            {
              text: t('menuScreen.menuLocation.actionButtons.addNewCombo'),
              icon: true,
              handler: () =>
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.ADD,
                      text: t(
                        'menuScreen.menuLocation.actionButtons.addNewComboTag',
                      ),
                      onPress: () => {
                        // TODO: navigate to the correct screen
                      },
                      pathURL: '',
                    }),
                  onRefresh,
                  true,
                  false,
                  'combos',
                ),
            },
            {
              text: t(
                'menuScreen.specialDetails.associations.addExistingItemCombo',
              ),
              icon: false,
              handler: () => setOpenAddExistingItem(true),
            },
          ],
          csx: { borderWidth: 0, padding: 0 },
          isEditButtonDisabled: mimicItems && !isBuy,
          isActionButtonDisabled: mimicItems && !isBuy,
          extraOptions: !isBuy && (
            <>
              <Checkbox
                label={t(
                  'menuScreen.specialDetails.associations.getItemIds.mimicItems',
                )}
                checked={mimicItems || false}
                onChange={newMimicItems => {
                  let values: Partial<ISpecialMenuTypeVersion> = {
                    mimicItems: newMimicItems,
                  };
                  if (newMimicItems) {
                    values = {
                      ...values,
                      getItemIds: buyItemIds,
                      getComboIds: buyComboIds,
                    };
                  }
                  setValue(values, SECTION_ID, menuTypeVersionId);
                }}
                csx={{ '.checkboxLabel': { maxWidth: 'min-content' } }}
              />
              {elementsCardList.length > 0 && (
                <Button
                  variant="primary"
                  disabled={mimicItems}
                  onClick={handleClearAllItems}
                  csx={{ maxWidth: '120px', minWidth: '80px' }}>
                  {t(
                    'menuScreen.specialDetails.associations.getItemIds.clearAllItems',
                  )}
                </Button>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default ItemsSection;
