import { MQ_MIN_LARGE, MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const searchBarStyles = (changeOrderOnMedium: boolean) =>
  css({
    order: 1,
    [MQ_MIN_SMALL]: {
      order: changeOrderOnMedium ? 1 : 0,
    },
    [MQ_MIN_MEDIUM]: {
      order: changeOrderOnMedium ? 1 : 0,
    },
    [MQ_MIN_LARGE]: {
      order: 0,
    },
  });
