import { TCsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../Box';
import Icon from '../Icon';
import Typography from '../Typography';
import { noElementsFoundStyles } from './styles';
import { ITypography } from '../Typography/Typography';

interface INoElementsFound {
  text?: string;
  icon?: React.ReactNode;
  csx?: TCsx;
  textProps?: Partial<ITypography>;
  vertical?: boolean;
  showBorder?: boolean;
}

const NoElementsFound = ({
  text,
  icon,
  vertical = false,
  csx,
  showBorder = true,
  textProps,
}: INoElementsFound) => {
  const { t } = useTranslation();

  return (
    <Box
      csx={[
        noElementsFoundStyles,
        theme => ({
          flexDirection: vertical ? 'column' : 'row',
          border: showBorder ? `1px solid ${theme.colors.lightGrey}` : 0,
        }),
        csx,
      ]}>
      <Box csx={{ marginRight: vertical ? 0 : 8 }}>
        {icon || <Icon name="MdSubtitlesOff" color="semanticGrey" />}
      </Box>
      <Typography
        variant="body"
        color="semanticGrey"
        fontWeight="medium"
        align={vertical ? 'center' : 'left'}
        {...textProps}>
        {text ||
          t('commonTexts.noneFound', { elementName: t('commonTexts.element') })}
      </Typography>
    </Box>
  );
};

export default NoElementsFound;
