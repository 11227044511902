import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { selectLocationId } from '@app/state/selectors/appSelectors';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import {
  selectIsSettingsCreationMode,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import {
  IUserDetailsChangeData,
  IUserObject,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  settingsSelectors,
} from '@westondev/tableturn-core';
import get from 'lodash/get';
import without from 'lodash/without';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import LocationsSubsection from './LocationsSubsection';
import RolesSubsection from './RolesSubsection';

const SECTION_ID = SettingsSectionId.ASSOCIATIONS;
const getMemoizedUserData =
  settingsSelectors.makeSelectSettingsData<IUserObject>(SECTION_ID);

const AssociationsSettings = (accordionSectionProps: IAccordionSection) => {
  // Redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const isMasterMode = useSelector(
    settingsSelectors.areSettingsMasterModeSelector,
  );
  const { data } = useSelector(getMemoizedUserData);

  const settingsErrors = useSelector(settingsErrorsSelector);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);
  const isCreation = useSelector(selectIsSettingsCreationMode);

  const showLocations = isMasterMode && hasMultipleLocations;

  const deviceLocationId = useSelector(selectLocationId);

  const doLocationAction = isCreation && !hasMultipleLocations;

  const isSingleLocationCreation =
    isMasterMode && isCreation && !hasMultipleLocations;

  const associationsHasErrors = useMemo(() => {
    return 'roleIds' in settingsErrors || 'locationIds' in settingsErrors;
  }, [settingsErrors]);

  // Local State
  const { t } = useTranslation();
  const locationsWithRoleIds = get(data, 'locationsWithRoleIds') as unknown as {
    [key: string]: number[];
  };

  const handleAssociate = useCallback(
    (selectedElements: IItemSelection[]) => {
      if ((showLocations || doLocationAction) && !isSingleLocationCreation) {
        const newLocationsWithRoleIds: { [key: number]: number[] } = {};

        selectedElements.forEach(location => {
          if (doLocationAction) {
            return (newLocationsWithRoleIds[deviceLocationId] = [
              ...(newLocationsWithRoleIds[deviceLocationId] || []),
              location.id as number,
            ]);
          }
          newLocationsWithRoleIds[Number(location.id)] = location.roleIds || [];
        });
        setValue(
          {
            locationIds: doLocationAction
              ? [deviceLocationId]
              : [
                  ...data.locationIds,
                  ...selectedElements.map(location => location.id),
                ],
            locationsWithRoleIds: {
              ...locationsWithRoleIds,
              ...newLocationsWithRoleIds,
            },
          },
          SECTION_ID,
        );
      } else {
        setValue(
          {
            roleIds: [
              ...data.roleIds,
              ...selectedElements.map(role => role.id),
            ],
            locationsWithRoleIds: {
              [deviceLocationId]: [
                ...data.roleIds,
                ...selectedElements.map(role => role.id),
              ],
            },
          },
          SECTION_ID,
        );
      }
      if (associationsHasErrors) {
        clearSettingsError('roleIds');
        clearSettingsError('locationIds');
      }
    },
    [
      showLocations,
      associationsHasErrors,
      clearSettingsError,
      setValue,
      data.locationIds,
      data.roleIds,
      locationsWithRoleIds,
      deviceLocationId,
      doLocationAction,
      isSingleLocationCreation
    ],
  );

  const handleDisassociate = useCallback(
    (id: number) => {
      let locationIds = data.locationIds;
      if ((showLocations || doLocationAction) && !isSingleLocationCreation) {
        const newLocationsWithRoleIds = { ...locationsWithRoleIds };

        if (doLocationAction) {
          newLocationsWithRoleIds[deviceLocationId] = without(
            newLocationsWithRoleIds[deviceLocationId],
            id,
          );

          if (newLocationsWithRoleIds[deviceLocationId].length === 0) {
            delete newLocationsWithRoleIds[deviceLocationId];
            locationIds = without(data.locationIds, deviceLocationId);
          }
        } else {
          delete newLocationsWithRoleIds[id];
          locationIds = without(data.locationIds, id);
        }

        setValue(
          {
            locationIds,
            locationsWithRoleIds: newLocationsWithRoleIds,
          },
          SECTION_ID,
        );
      } else {
        setValue({ roleIds: without(data.roleIds, id) }, SECTION_ID);
      }
    },

    [
      setValue,
      showLocations,
      data.locationIds,
      data.roleIds,
      locationsWithRoleIds,
      doLocationAction,
      deviceLocationId,
      isSingleLocationCreation
    ],
  );

  return (
    <AccordionSection
      required
      title={t('menuScreen.common.associations')}
      hasError={associationsHasErrors}
      {...accordionSectionProps}>
      {showLocations ? (
        <LocationsSubsection
          locationIds={data.locationIds || []}
          onAssociate={handleAssociate}
          onDisassociate={handleDisassociate}
        />
      ) : (
        <RolesSubsection
          onAssociate={handleAssociate}
          onDisassociate={handleDisassociate}
          roleIds={
            (doLocationAction && !isSingleLocationCreation
              ? (data as IUserDetailsChangeData).locationsWithRoleIds?.[
                  deviceLocationId
                ]
              : data.roleIds) || []
          }
        />
      )}
    </AccordionSection>
  );
};

export default AssociationsSettings;
