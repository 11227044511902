import { IItemCard as IBaseItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import {
  ICategory,
  ICombo,
  IItem,
  IMenuType,
  menuCategoryAssignedItemsFactoryCore,
} from '@westondev/tableturn-core';

interface ICategoryAssignedItemCard extends IBaseItemCard {
  menuTypeId: number;
}

const menuCategoryAssignedItemsFactory = (
  elements: Record<number, IItem> | Record<number, ICombo>,
  menuTypes: Record<number, IMenuType>,
  subcategoryMenuTypeElementIds: ICategory['subcategoryMenuTypeItemsIds'],
  onClick: (id: number, text: string, openInNewTab?: boolean) => void,
  handleDeleteItem: (n: number, menuTypeId: number) => void,
): ICategoryAssignedItemCard[] =>
  <ICategoryAssignedItemCard[]>menuCategoryAssignedItemsFactoryCore(
    elements,
    menuTypes,
    subcategoryMenuTypeElementIds,
    handleDeleteItem,
  ).map(item => ({
    ...(item ?? {}),
    onClick: openInNewTab =>
      onClick(item?.id || 0, item?.title || '', openInNewTab),
    onRemoveClick: () => item?.onDelete(item.id, item.menuTypeId),
    subTitle: item?.description ? `(${item.description})` : '',
  }));

export default menuCategoryAssignedItemsFactory;
