import Card from '../../Card';
import Icon from '../../Icon';
import Box from '../../Box';
import { optionTypeButtonStyles } from './styles';
import { MouseEvent, MouseEventHandler } from 'react';

interface IOptionTypeButton {
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (openInNewTab: boolean) => MouseEventHandler<HTMLDivElement>;
}

const OptionTypeButton = ({
  label,
  isSelected = false,
  onClick,
  isDisabled = false,
}: IOptionTypeButton) => {
  const handleOnClick =
    (openInNewTab?: boolean) => (e: MouseEvent<HTMLDivElement>) => {
      if (isDisabled) return null;
      onClick && onClick(openInNewTab ?? false)(e);
    };

  return (
    <Box csx={optionTypeButtonStyles}>
      {isSelected && (
        <Box className="triangleIcon">
          <Icon name="IoTriangleSharp" color="persistentSemanticBlue" />
        </Box>
      )}
      <Card.Item
        title={label}
        onClick={handleOnClick}
        isSelected={isSelected}
        csx={{ width: '130px !important', zIndex: 1 }}
      />
    </Box>
  );
};

export default OptionTypeButton;
