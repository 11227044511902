import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  actionCreatorsSettings,
  IGuestReceiptSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestReceiptSettings>(SECTION_ID);

const Items = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  return (
    <Grid columnGap={25} rowGap={25}>
      <Grid.Item mb={12} sm={6} md={4}>
        <Switch
          checked={data ? data?.showItems : true}
          onChange={showItems =>
            setValue<IGuestReceiptSettings>({ showItems }, SECTION_ID)
          }
          label={t('settingsModule.guestReceiptSettings.items.showItems')}
        />
      </Grid.Item>
      <Grid.Item mb={12} sm={6}>
        <Checkbox
          label={t(
            'loggedIn.settingsModule.guestCheckSettings.checkDisplay.showItemUnitPrice',
          )}
          checked={data ? data.showItemUnitPrice : false}
          onChange={showItemUnitPrice =>
            setValue<IGuestReceiptSettings>({ showItemUnitPrice }, SECTION_ID)
          }
        />
      </Grid.Item>
    </Grid>
  );
};

{
  /* <ModifierDisplay
columns={3}
hiddenElements={['consolidateByItem', 'groupBySeat']}
/> */
}

export default Items;
