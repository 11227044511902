import { getUserIsVerified } from '@app/helpers/users';
import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRouteVerified = () => {
  // Local state
  const userIsVerified = useMemo(() => {
    return getUserIsVerified();
  }, []);

  const continuePath = `?continue=${window.location.pathname}`;

  return userIsVerified.isVerified ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/verify${continuePath}`}
      state={userIsVerified.data}
      relative="route"
    />
  );
};

export default PrivateRouteVerified;
