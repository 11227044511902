import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal/Modal';
import Typography from '@app/components/common/Typography';
import { useTheme } from '@emotion/react';
import { IMenuTypeToSelect } from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectLocationRow from '../../../SelectLocationsModal/SelectLocationRow/SelectLocationRow';
import Grid from '@app/components/common/Grid';
import { selectMenuTypeVersionsOfCurrentItem } from '@app/state/menu/menuSelectors';
import { menuTypeVersionsSelectionFactory } from '@app/helpers/factories/menu/cardFactories/menuTypes/menuTypeVersionsFactory';

interface ISelectMenuTypeToApplyChangesModal extends WithTranslation {
  isActive: boolean;
  menuTypeId: number;
  onApplyChanges: (value: IMenuTypeToSelect[]) => void;
  setModalActive: () => void;
  title?: string;
  description?: string;
}

const SelectMenuTypeToApplyChangesModal = ({
  t,
  isActive,
  menuTypeId,
  onApplyChanges,
  setModalActive,
  title,
  description,
}: ISelectMenuTypeToApplyChangesModal) => {
  // Redux
  const menuTypeVersions = useSelector(selectMenuTypeVersionsOfCurrentItem);

  // Local State
  const onCloseModal = () => setModalActive();
  const [menuTypes, setSelectedMenuTypes] = useState<IMenuTypeToSelect[]>([]);
  const theme = useTheme();

  useEffect(() => {
    if (isActive) {
      const formattedMenuTypes = menuTypeVersions
        ? menuTypeVersionsSelectionFactory(menuTypeVersions).filter(
            menuTypeVersion => menuTypeVersion.menuTypeId !== menuTypeId,
          )
        : [];
      setSelectedMenuTypes(formattedMenuTypes);
    }
  }, [menuTypeId, menuTypeVersions, isActive]);

  const areAllMenuSelected = menuTypes.every(menuType => menuType.selected);

  const handleSelectAllMenus = () => {
    const newMenuTypes = menuTypes.map(menuType => {
      return {
        ...menuType,
        selected: true,
      };
    });
    setSelectedMenuTypes(newMenuTypes);
  };

  const handleDeselectAllMenus = () => {
    const newMenuTypes = menuTypes.map(menuType => {
      return {
        ...menuType,
        selected: false,
      };
    });
    setSelectedMenuTypes(newMenuTypes);
  };

  const handleUpdateSelectedPrepStations = (selectedMenuTypeId: number) => {
    const newPrepStations = menuTypes.map(menuType => {
      if (selectedMenuTypeId === menuType.menuTypeId)
        return {
          ...menuType,
          selected: !menuType.selected,
        };
      return menuType;
    });
    setSelectedMenuTypes(newPrepStations);
  };

  const handleApplyChanges = () => {
    onApplyChanges(menuTypes.filter(menuType => menuType.selected));
    onCloseModal();
  };

  return (
    <Modal
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title ||
              t(
                'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.title',
              )}
          </Typography>
          <Typography color="darkestGrey">
            {description ||
              t(
                'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.description',
              )}
          </Typography>
        </Box>
      }
      isActive={isActive}
      size={700}
      onModalClose={onCloseModal}
      noPadding
      modalContainerCsx={{
        '& .modalContent': { backgroundColor: theme.colors.lightestGrey },
      }}
      footer={
        <>
          <Button csx={{ minWidth: '120px' }} onClick={onCloseModal}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            csx={{ minWidth: '120px' }}
            variant="primary"
            onClick={handleApplyChanges}>
            {t(
              'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.applyChanges',
            )}
          </Button>
        </>
      }>
      <Grid csx={{ padding: '15px' }} rowGap={10}>
        <Grid.Item>
          <Button
            variant="primary"
            onClick={
              areAllMenuSelected ? handleDeselectAllMenus : handleSelectAllMenus
            }>
            {areAllMenuSelected
              ? t(
                  'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.deselectAllMenuTypes',
                )
              : t(
                  'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.selectAllMenuTypes',
                )}
          </Button>
        </Grid.Item>
        <Grid.Item>
          {menuTypes.map(menuType => (
            <SelectLocationRow
              key={menuType.menuTypeId}
              selected={menuType.selected}
              name={menuType.name}
              locationId={menuType.menuTypeId}
              handleLocationToggle={value =>
                handleUpdateSelectedPrepStations(value)
              }
            />
          ))}
        </Grid.Item>
      </Grid>
    </Modal>
  );
};

export default SelectMenuTypeToApplyChangesModal;
