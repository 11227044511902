import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import i18n from '../../../../../i18n.config';
import {
  ICombo,
  IItem,
  specialCombosFactoryCore,
  specialItemsFactoryCore,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';

export const specialItemsFactory = (
  items: Record<number, IItem>,
  menuTypeAssignedItemIds: number[],
  onClick: (id: number, text: string, openInNewTab?: boolean) => void,
  handleDeleteItem: (n: number) => void,
  onIsLink: (id: number) => void,
) => {
  return <IItemCard[]>specialItemsFactoryCore(
    items,
    menuTypeAssignedItemIds,
    handleDeleteItem,
  ).map(item => ({
    id: item.id,
    showStatus: false,
    tag: i18n.t(
      `menuScreen.common.bucket.${item.entity}s.singular` as ParseKeys,
    ),
    title: item.title,
    isActive: true,
    onClick: openInNewTab => onClick(item.id, item.title, openInNewTab),
    onRemoveClick: item.onDelete,
    isLink: onIsLink(item.id),
  }));
};

export const specialCombosFactory = (
  combos: Record<number, ICombo>,
  menuTypeAssignedComboIds: number[],
  handleDeleteItem: (n: number) => void,
) => {
  return <IItemCard[]>specialCombosFactoryCore(
    combos,
    menuTypeAssignedComboIds,
    handleDeleteItem,
  ).map(item => ({
    id: item.id,
    showStatus: false,
    tag: i18n.t(
      `menuScreen.common.bucket.${item.entity}s.singular` as ParseKeys,
    ),
    title: item.title,
    isActive: true,
    onRemoveClick: item.onDelete,
  }));
};
