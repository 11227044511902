import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import Box from '@app/components/common/Box';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { Outlet, useMatch } from 'react-router-dom';

const Settings = () => {
  const isUserDetailsScreen = !!useMatch(
    getPathWithOrgData('/settings/accounts/users/:id'),
  );
  const isRoleDetailsScreen = !!useMatch(
    getPathWithOrgData('/settings/accounts/roles/:id'),
  );
  const isTaxDetailsScreen = !!useMatch(
    getPathWithOrgData('/settings/taxes/:id'),
  );
  const isModTagsScreen = !!useMatch(
    getPathWithOrgData('/settings/register/mod-tags'),
  );
  const isSeatOrderTagsScreen = !!useMatch(
    getPathWithOrgData('/settings/register/seat-and-order-tags'),
  );
  const isSpecialTagsScreen = !!useMatch(
    getPathWithOrgData('/settings/register/special-tags'),
  );
  const isAdsScreen = !!useMatch(getPathWithOrgData('/settings/register/ads'));
  const isDiscountReasonScreen = !!useMatch(
    getPathWithOrgData('/settings/register/discount-reasons'),
  );
  const isOpenItemTypesScreen = !!useMatch(
    getPathWithOrgData('/settings/register/open-item-types'),
  );
  const isCoursingScreen = !!useMatch(
    getPathWithOrgData('/settings/register/coursing'),
  );
  const isPrepStationsScreen = !!useMatch(
    getPathWithOrgData('/settings/printing/prep-stations'),
  );
  const isPrintingRulesScreen = !!useMatch(
    getPathWithOrgData('/settings/printing/printing-rules'),
  );
  const isTaxesScreen = !!useMatch(getPathWithOrgData('/settings/taxes'));

  const isNoPadding =
    isUserDetailsScreen ||
    isRoleDetailsScreen ||
    isTaxDetailsScreen ||
    isModTagsScreen ||
    isSeatOrderTagsScreen ||
    isSpecialTagsScreen ||
    isAdsScreen ||
    isDiscountReasonScreen ||
    isOpenItemTypesScreen ||
    isCoursingScreen ||
    isPrepStationsScreen ||
    isPrintingRulesScreen ||
    isTaxesScreen;

  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}>
      <LayoutContent noPadding={isNoPadding}>
        <Outlet />
      </LayoutContent>
    </Box>
  );
};

export default Settings;
