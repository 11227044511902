import { forwardRef, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Box from '../Box';
import Button from '../Button';
import {
  captionStyles,
  emptyCaptionStyles,
  errorCaptionStyles,
  inputContainerStyles,
  inputPadding,
  inputStyles,
  inputWrapperStyles,
  labelStyles,
} from '../Input/styles';
import ToolTip from '../ToolTip';
import Typography from '../Typography';
import { IMoneyInput } from './types';

const MoneyInput = forwardRef<HTMLInputElement, IMoneyInput>(
  (
    {
      // Value related props
      value,
      maxLength,
      onValueChange = () => null,
      onChangeEmpty,
      maxValue,
      minValue = 0,
      precision = 2,
      delimiter = ',',
      minValueCallback,

      // Input related props
      width,
      placeholder = '',
      required = false,
      disabled = false,
      editable = true,
      autoFocus = false,
      onFocus,
      onBlur,
      showPlaceholder = false,

      // Icon related props
      icon,
      onIconPress,

      // Text related props
      label,
      suffix = '',
      prefix = '$',
      emptyLabel,
      info = label || 'TOOLTIP_INFO',

      // Error related props
      error = false,
      caption = '',
      showCaptionSpace,

      // Other props
      inputWrapperClassName,
      hideRequiredSymbol,
      customInputStyles,
      customInputContainerStyles,
    }: IMoneyInput,
    ref,
  ) => {
    const [inputActive, setInputActive] = useState(false);
    const [localValue, setLocalValue] = useState(
      value?.toString() ?? undefined,
    );
    const [showHover, setShowHover] = useState(false);
    const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

    const otherStyles = {
      paddingRight: `${inputPadding}px`,
    };

    const handleChange = (_valueStr: string | undefined) => {
      const newValue = Number(_valueStr ?? 0);

      if (maxValue && newValue > maxValue) return;

      setLocalValue(_valueStr);

      onValueChange(newValue);
      onChangeEmpty?.(newValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, target } = e;

      if (precision <= 0 || !/\d/g.test(key)) return;

      const {
        selectionStart,
        selectionEnd,
        value: currentValue,
      } = target as HTMLInputElement;
      const lastDigitPosition = suffix
        ? currentValue.length - suffix.length
        : currentValue.length;
      const lastPosition = currentValue.length;

      if (
        selectionStart !== selectionEnd ||
        (selectionStart !== lastDigitPosition &&
          selectionStart !== lastPosition) ||
        !currentValue.includes('.')
      )
        return;

      const _currentValue = currentValue
        .replace(delimiter, '')
        .replace(/[^\d.]/g, '');

      const decimalPlaces = _currentValue.split('.')[1].length;
      const isMaxDecimalPlaces = decimalPlaces >= precision;
      if (!isMaxDecimalPlaces) return;

      const decimalPointShifts = decimalPlaces - precision + 1;
      const _numericValue = Number(`${_currentValue}${key}`);

      const newNumericValue = _numericValue * 10 ** decimalPointShifts;
      const newStringValue = newNumericValue.toFixed(precision);

      handleChange(newStringValue);
    };

    const handleFocus = () => {
      setInputActive(true);
      onFocus?.();
    };
    const handleBlur = () => {
      setInputActive(false);
      onBlur?.();
    };

    useEffect(() => {
      setLocalValue(currentLocalValue => {
        const currentNumericValue = value ?? 0;
        const currentLocalNumericValue = Number(currentLocalValue || 0);
        if (currentNumericValue === currentLocalNumericValue)
          return currentLocalValue;
        return value?.toString() ?? undefined;
      });
    }, [value]);

    const handlePopUp = () => {
      if (newTimeout) clearTimeout(newTimeout);
      setNewTimeout(
        setTimeout(() => {
          setShowHover(true);
        }, 500),
      );
    };

    return (
      <Box csx={inputWrapperStyles} className={inputWrapperClassName}>
        {(label || emptyLabel) && (
          <label
            onMouseOver={handlePopUp}
            onMouseOut={() => {
              if (newTimeout) clearTimeout(newTimeout);
              setShowHover(false);
            }}
            css={labelStyles}
            className={`${disabled ? 'disabled' : ''}`}>
            {info && (
              <div className="infoButton">
                <ToolTip
                  showHover={showHover}
                  content={info}
                  tooltipCsx={{ maxWidth: '250px' }}>
                  <div
                    style={{
                      height: '10px',
                    }}
                  />
                </ToolTip>
              </div>
            )}
            {!emptyLabel ? label : ''}
            {required && !hideRequiredSymbol && (
              <span className="required">*</span>
            )}
          </label>
        )}
        <Box
          csx={[inputContainerStyles, { width }, customInputContainerStyles]}
          className={`${inputActive ? 'active' : ''} ${error ? 'error' : ''}`}>
          <CurrencyInput
            ref={ref}
            onFocus={() => setInputActive(true)}
            onBlur={() => setInputActive(false)}
            css={[
              inputStyles,
              otherStyles,
              customInputStyles,
              {
                borderColor: error ? 'red' : 'lightGrey',
              },
            ]}
            placeholder={placeholder ?? 'Insert Text'}
            value={
              showPlaceholder
                ? localValue === '0'
                  ? undefined
                  : localValue
                : localValue
            }
            onValueChange={handleChange}
            onKeyDown={handleKeyDown}
            prefix={prefix}
            suffix={suffix}
            decimalScale={precision}
            decimalsLimit={precision}
            max={maxValue}
            min={minValueCallback ? minValueCallback(value || 0) : minValue}
            groupSeparator={delimiter}
            contentEditable={editable}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocusCapture={handleFocus}
            onBlurCapture={handleBlur}
            allowDecimals={precision > 0}
            maxLength={maxLength}
            disableAbbreviations
          />
          {icon && (
            <Button
              type="button"
              whileHover={{ filter: 'none' }}
              whileTap={{ scale: 1 }}
              variant="icon"
              icon={icon}
              onClick={onIconPress}
            />
          )}
        </Box>
        {(!caption && !error && showCaptionSpace) ||
        (!caption && error && showCaptionSpace) ? (
          <span css={emptyCaptionStyles}></span>
        ) : (
          (caption || (error && caption)) && (
            <Typography csx={error ? errorCaptionStyles : captionStyles}>
              {caption || error}
            </Typography>
          )
        )}
      </Box>
    );
  },
);

MoneyInput.displayName = 'MoneyInput';

export default MoneyInput;
