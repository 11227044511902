import { Theme, css } from '@emotion/react';
import { adjustShade } from '@westondev/tableturn-core';

export const sectionHeaderStyles = (hasError: boolean) => (theme: Theme) =>
  css({
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    '.arrowButton': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
      minWidth: '50px',
      backgroundColor: hasError ? theme.colors.lighterRed : 'white',
      border: 'none',
      filter: 'none !important',
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '0px',
      transition: 'background-color 0.1s ease-in-out',
      '&[data-active="true"]': {
        svg: {
          fill: hasError
            ? theme.colors.persistentSemanticRed
            : theme.colors.persistentSemanticBlue,
        },
      },
      '&[data-active="false"]': {
        svg: {
          fill: hasError
            ? theme.colors.persistentSemanticRed
            : theme.colors.black,
        },
      },
      ':hover': {
        backgroundColor: hasError
          ? adjustShade(theme.colors.lighterRed, 5)
          : theme.colors.lighterBlue,
        svg: {
          fill: hasError
            ? theme.colors.persistentSemanticRed
            : theme.colors.persistentSemanticBlue,
        },
      },
    },
  });
