import { makeSelectIsActiveAndName } from '@app/state/menu/menuSelectors';
import { Theme, css } from '@emotion/react';
import { ParseKeys } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from '../Icon';
import Typography from '../Typography';
import { IBreadCrumbWeb } from '@app/types';
import ButtonLink from '../Button/ButtonLink';

const getBreadCrumbStyle = (isLast: boolean, isActive: boolean | undefined) => {
  const lightStyles = {
    unselectedActive: {
      textProps: {
        color: 'darkBlue',
      },
      selectedStyle: (theme: Theme) =>
        css({
          backgroundColor: theme.colors.lighterBlue,
          borderColor: theme.colors.darkBlue,
        }),
    },
    unselectedInactive: {
      textProps: {
        color: 'darkRed',
      },
      selectedStyle: (theme: Theme) =>
        css({
          backgroundColor: theme.colors.lighterRed,
          borderColor: theme.colors.darkRed,
        }),
    },
    selectedActive: {
      textProps: {
        color: 'textWhite',
      },
      selectedStyle: (theme: Theme) =>
        css({
          backgroundColor: theme.colors.semanticBlue,
          borderWidth: '0',
        }),
    },
    selectedInactive: {
      textProps: {
        color: 'textWhite',
      },
      selectedStyle: (theme: Theme) =>
        css({
          backgroundColor: theme.colors.persistentSemanticRed,
          borderWidth: '0',
        }),
    },
  };

  const styles = lightStyles;

  if (isLast) return isActive ? styles.selectedActive : styles.selectedInactive;

  return isActive ? styles.unselectedActive : styles.unselectedInactive;
};

const BreadCrumb = ({
  breadCrumb,
  onClick: handleOnPress,
  isLast,
  showActiveStyle,
  isSettings = false,
}: {
  breadCrumb: IBreadCrumbWeb;
  onClick: (openInNewTab: boolean) => void;
  isLast: boolean;
  showActiveStyle?: boolean;
  isSettings?: boolean;
}) => {
  const { t } = useTranslation();

  const selectIsActiveAndName = useMemo(
    () => makeSelectIsActiveAndName(isSettings),
    [isSettings],
  );
  const { isActive, menuTypeName } = useSelector(state =>
    selectIsActiveAndName(state, breadCrumb),
  );

  const { textProps, selectedStyle } = getBreadCrumbStyle(
    showActiveStyle || false,
    isActive === undefined || breadCrumb.action === 'nav-main'
      ? true
      : isActive,
  );

  return (
    <>
      <ButtonLink
        variant="primary"
        onClick={handleOnPress}
        to={breadCrumb.pathURL}
        csx={[{ padding: '0 20px', borderWidth: 1 }, selectedStyle]}>
        <Typography fontWeight="medium" {...textProps}>
          {t(breadCrumb.text as ParseKeys)}
        </Typography>
        {breadCrumb.action !== 'nav-main' && (
          <>
            {breadCrumb.isItem && menuTypeName && (
              <Typography
                fontWeight="medium"
                csx={{ paddingLeft: '5px' }}
                {...textProps}>
                -
              </Typography>
            )}
            {menuTypeName && (
              <Typography
                fontWeight="medium"
                csx={{ fontStyle: 'italic', paddingLeft: '5px' }}
                {...textProps}>
                {menuTypeName}
                {isActive && ' '}
              </Typography>
            )}
            {!isActive && (
              <Typography
                fontWeight="medium"
                csx={{ fontStyle: 'italic' }}
                {...textProps}>
                {isActive !== undefined &&
                  ` - ${t(`commonTexts.${isActive ? 'active' : 'inactive'}`)}`}
              </Typography>
            )}
          </>
        )}
      </ButtonLink>
      {!isLast && <Icon name="MdChevronRight" color="darkerGrey" size="25px" />}
    </>
  );
};

export default BreadCrumb;
