import { SerializedStyles, Theme, css } from '@emotion/react';
import { adjustShade } from '@westondev/tableturn-core';

export const BUTTON_BORDER_RADIUS = '8px';
export const BUTTON_HEIGHT = '50px';
const SHADE_ADJUSTMENT = -5;

const baseButtonStyles = (theme: Theme) =>
  css({
    height: BUTTON_HEIGHT,
    width: 'inherit',
    border: `2px solid ${theme.colors.lightGrey}`,
    borderRadius: BUTTON_BORDER_RADIUS,
    backgroundColor: theme.colors.cardWhite,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    paddingInline: '8px',
    overflow: 'hidden',
    // text props
    color: theme.colors.black,
    fontSize: theme.fontSizes.body,
    fontWeight: theme.fontWeights.regular,
    // css
    '>span.icon': {
      display: 'inherit',
      height: 'fit-content',
      lineHeight: 'normal',
    },
    '> span.icon.icon-left': {
      paddingRight: '8px',
      position: 'relative',
    },
    '> span.icon.icon-right': {
      paddingLeft: '8px',
      position: 'relative',
    },

    '> .spinner': {
      marginRight: '8px',
    },
  });

export const buttonVariants: Record<
  string,
  (
    theme: Theme,
    {
      isDisabled,
      disableHoverEffect,
    }: { isDisabled: boolean; disableHoverEffect: boolean },
  ) => SerializedStyles
> = {
  primary: (theme, disableHoverEffect) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.persistentDarkerBlue,
      borderColor: theme.colors.darkestBlue,
      color: theme.colors.textWhite,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.persistentDarkerBlue,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.textWhite,
      },
    }),
  active: (theme, { disableHoverEffect }) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.semanticBlue,
      borderColor: theme.colors.darkBlue,
      color: theme.colors.textWhite,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.semanticBlue,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.textWhite,
      },
    }),
  secondary: (theme, { disableHoverEffect }) => {
    return css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.cardWhite,
      borderColor: theme.colors.lightGrey,
      color: theme.colors.black,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.cardWhite,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.black,
      },
    });
  },
  success: (theme, { disableHoverEffect }) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.semanticGreen,
      borderColor: theme.colors.darkGreen,
      color: theme.colors.textWhite,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.semanticGreen,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.textWhite,
      },
    }),
  warning: (theme, { disableHoverEffect }) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.semanticYellow,
      borderColor: theme.colors.semanticYellow,
      color: theme.colors.black,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.semanticYellow,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.black,
      },
    }),
  danger: (theme, { disableHoverEffect }) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.persistentSemanticRed,
      borderColor: theme.colors.darkRed,
      color: theme.colors.textWhite,
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.persistentSemanticRed,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon > svg': {
        fill: theme.colors.textWhite,
      },
    }),
  disabled: (theme, { disableHoverEffect }) =>
    css(baseButtonStyles(theme), {
      backgroundColor: theme.colors.lightestGrey,
      borderColor: theme.colors.lightGrey,
      color: theme.colors.lightGrey,
      cursor: 'not-allowed',
      ':hover': !disableHoverEffect
        ? {
            backgroundColor: adjustShade(
              theme.colors.lightestGrey,
              SHADE_ADJUSTMENT,
            ),
          }
        : {},
      '> .icon> svg': {
        fill: theme.colors.lightGrey,
      },
      '> svg.spinner > circle': {
        stroke: theme.colors.lightGrey,
      },
    }),
  transparent: (theme, { isDisabled }) =>
    css(
      baseButtonStyles(theme),
      isDisabled ? ButtonDisabledStyles(theme) : {},
      {
        backgroundColor: 'transparent',
        border: '0px',
      },
    ),
  icon: () =>
    css({
      backgroundColor: 'transparent',
      border: '0px',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      '>span.icon': {
        display: 'inherit',
        lineHeight: 0,
      },
    }),
};

const ButtonDisabledStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.lightestGrey,
    borderColor: theme.colors.lightGrey,
    color: theme.colors.lightGrey,
    cursor: 'not-allowed',
    '> .icon> svg': {
      fill: theme.colors.lightGrey,
    },
    '> svg.spinner > circle': {
      stroke: theme.colors.lightGrey,
    },
  });

export const buttonLinkStyles = () =>
  css({
    textDecoration: 'none',
    width: 'inherit',
    height: 'inherit',
  });
