import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const imageUploaderStyles = (theme: Theme) =>
  css({
    marginTop: '15px',
    '.imageButton': {
      paddingInline: '10px',
      height: '60px',
      backgroundColor: theme.colors.white,
      border: `1px dashed ${theme.colors.lightGrey}`,
      filter: 'none !important',
      ':hover': {
        backgroundColor: theme.colors.lighterBlue,
        border: `1px dashed ${theme.colors.semanticBlue}`,
        p: {
          color: theme.colors.semanticBlue,
        },
        svg: {
          fill: theme.colors.semanticBlue,
        },
      },
    },
  });

export const uploadContainerStyles = (theme: Theme) =>
  css({
    display: 'grid',
    gridTemplateAreas: `
        "imagePreview"
        "uploadArea"
    `,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '180px 1fr',
    gap: '15px',
    minHeight: '250px',
    '.imagePreview': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      border: `1px dashed ${theme.colors.lightGrey}`,
      borderRadius: '8px',
      gridArea: 'imagePreview',
      cursor: 'pointer',
      ':active': {
        border: `1px dashed ${theme.colors.semanticBlue}`,
      },
    },
    '.uploadArea': {
      width: '100%',
      border: `1px solid ${theme.colors.lightGrey}`,
      borderRadius: '8px',
      gridArea: 'uploadArea',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px',
      gap: '10px',
    },
    '.uploadButtons': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      button: {
        flexGrow: 1,
      },
    },
    [MQ_MIN_MEDIUM]: {
      gridTemplateAreas: '"imagePreview uploadArea"',
      gridTemplateColumns: '1fr 200px',
      gridTemplateRows: '300px',
      '.uploadButtons': {
        flexDirection: 'column',
        gap: '10px',
        button: {
          width: '170px',
        },
      },
    },
  });
