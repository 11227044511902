import Box from '@app/components/common/Box';
import { IOption } from '../OptionsModal/types';
import CardsContainer from '../CardsContainer';
import Card from '../Card';

interface IMultiSelect {
  options: IOption[];
  selectedOptions: IOption[];
  elementsPerPage?: number;
  onChange: (value: IOption[]) => void;
}

const MultiSelect = ({ options, selectedOptions, onChange }: IMultiSelect) => {
  const handleSelectOption = (option: IOption) => {
    let newSelectedOptions = [];
    if (selectedOptions.some(opt => opt.value === option.value)) {
      newSelectedOptions = [...selectedOptions].filter(
        opt => opt.value !== option.value,
      );
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    onChange(newSelectedOptions);
  };

  return (
    <Box>
      {options.length > 0 ? (
        <CardsContainer
          data={options}
          renderItem={option => (
            <Card.Item
              key={option.label + option.value}
              isSelected={selectedOptions.some(
                opt => opt.value === option.value,
              )}
              title={option.label}
              onClick={() => handleSelectOption(option)}
            />
          )}
        />
      ) : null}
    </Box>
  );
};

export default MultiSelect;
