import { useEffect, useState } from 'react';
import { LightTheme, DarkTheme, getFontSizes, BREAKPOINTS } from './themes';
import { ThemeProvider as EmotionThemeProvider, Theme } from '@emotion/react';
import { IFontSizes } from './types';

interface IThemeProvider {
  children: React.ReactNode;
}

function ThemeProvider({ children }: IThemeProvider) {
  const [displayMode, setDisplayMode] = useState('light');
  const [fontSizes, setFontSizes] = useState<IFontSizes>(getFontSizes());
  const [mode, setMode] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseFloat(BREAKPOINTS.mobile.max)) {
        if (mode !== 'mobile') {
          setFontSizes(getFontSizes());
          setMode('mobile');
        }
      } else if (window.innerWidth > parseFloat(BREAKPOINTS.mobile.max)) {
        if (mode !== 'desktop') {
          setFontSizes(getFontSizes());
          setMode('desktop');
        }
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mode]);

  //read local storage for theme after click
  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme) {
      setDisplayMode(localTheme);
    }
  }, []);

  const darkTheme: Theme = { ...DarkTheme, fontSizes };
  const lightTheme: Theme = { ...LightTheme, fontSizes };

  return (
    <EmotionThemeProvider
      theme={displayMode === 'dark' ? darkTheme : lightTheme}>
      {children}
    </EmotionThemeProvider>
  );
}

export default ThemeProvider;
