import {
  IItem,
  IItemOfItemGroup,
  itemGroupsAssignedItemsFactoryCore,
} from '@westondev/tableturn-core';

export const itemGroupsAssignedItemsFactory = (
  items: Record<number, IItem>,
  itemGroupAssignedItemIds: Record<number, IItemOfItemGroup>,
  handleDeleteItem: (n: number) => void,
) =>
  itemGroupsAssignedItemsFactoryCore(
    items,
    itemGroupAssignedItemIds,
    handleDeleteItem,
  );

export default itemGroupsAssignedItemsFactory;
