import Background from '@app/assets/logos/BackgroundLeft.jpg';
import BackgroundMobile from '@app/assets/logos/BackgroundMobile.jpg';
import { MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const navBarStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '25px',
    width: '100%',
    height: '80px',
    backgroundImage: `url(${BackgroundMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    boxShadow: theme.shadows[2],
    '.logo': {
      svg: {
        width: '160px',
        height: '35px',
      },
      [MQ_MIN_SMALL]: {
        svg: {
          width: '190px',
          height: '45px',
        },
      },
      ':hover': {
        cursor: 'pointer',
      },
    },
    '.orgName': {
      display: 'none',
      [MQ_MIN_MEDIUM]: {
        display: 'block',
      },
    },
    '.left': {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
    },
    '.sideBarButton': {
      display: 'block',
      width: 'auto',
      svg: {
        width: '24px',
        height: '24px',
      },
      padding: '8px',
      marginLeft: '-8px',
      ':hover': {
        [MQ_MIN_SMALL]: {
          backgroundColor: theme.colors.persistentDarkerBlue,
          borderRadius: '50%',
        },
      },
      [MQ_MIN_MEDIUM]: {
        display: 'none',
      },
    },
    '.right': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      '.user': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        userSelect: 'none',
        ':hover': {
          cursor: 'pointer',
        },
      },
      '.toggleThemeButton': {
        display: 'none',
      },
      [MQ_MIN_SMALL]: {
        '.toggleThemeButton': {
          display: 'block',
        },
      },
    },
    [MQ_MIN_MEDIUM]: {
      backgroundImage: `url(${Background})`,
    },
  });
