import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface ContainerProps {
  draggableItems: string[];
  children: React.ReactNode;
  setActiveId: Dispatch<SetStateAction<string | null>>;
  handleDragEnd: (event: DragEndEvent) => void;
}

const DraggableContainer = ({
  draggableItems,
  children,
  setActiveId,
  handleDragEnd,
}: ContainerProps) => {
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    setItems(draggableItems);
  }, [draggableItems]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id as string);
    },
    [setActiveId],
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, [setActiveId]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}>
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DraggableContainer;
