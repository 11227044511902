import { useLocation } from 'react-router-dom';

const useFiltersStateRestoration = () => {
  const location = useLocation();

  const getScrollData = () => {
    const savedData = localStorage.getItem('path-data');
    return savedData ? JSON.parse(savedData) : {};
  };

  const getFiltersState = () => {
    const scrollData = getScrollData();
    return (
      (scrollData[location.pathname] &&
        scrollData[location.pathname].filters) ||
      []
    );
  };

  const seFiltersState = (filtersState: Record<string, any>) => {
    const scrollData = getScrollData();

    scrollData[location.pathname] = {
      ...scrollData[location.pathname],
      filters: filtersState,
    };

    localStorage.setItem('path-data', JSON.stringify(scrollData));
  };

  return { seFiltersState, filtersState: getFiltersState() };
};
export default useFiltersStateRestoration;
