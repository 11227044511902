import Grid from '@app/components/common/Grid';
import RenderChange from '../../../SettingsDiffConfirmationModal/common/RenderChange';

import { TChangeItems } from '@westondev/tableturn-core';
import ItemTotalPricesDiff from './ItemTotalPricesDiff';

interface IItemPricesDiff {
  prices: TChangeItems[];
  formatValue: (value: any, field: string, textValue?: string) => string;
  isMassUpdate?: boolean;
  hideTotals?: boolean;
}

const ItemPricesDiff = ({
  prices,
  formatValue,
  isMassUpdate,
  hideTotals,
}: IItemPricesDiff) => {
  return (
    <>
      {!isMassUpdate && !hideTotals ? (
        prices.find(change => change.field === 'defaultModifiersPrice') ? (
          <ItemTotalPricesDiff
            prices={prices.filter(
              change => change.field !== 'defaultModifiersPrice',
            )}
            hasDefaultModifiersPrice
          />
        ) : (
          <ItemTotalPricesDiff
            prices={prices}
            hasDefaultModifiersPrice={false}
          />
        )
      ) : null}

      <Grid columnGap={20} rowGap={10}>
        {prices
          .filter(
            change => change.field !== 'defaultModifiersPrice' && !change.fo,
          )
          .map(change => (
            <Grid.Item
              mb={12}
              sm={6}
              md={4}
              lg={4}
              key={`pricing_change_${change.field}`}>
              <RenderChange change={change} formatValue={formatValue} />
            </Grid.Item>
          ))}
      </Grid>
    </>
  );
};

export default ItemPricesDiff;
