import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { EPrinterStatus } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { printerStatusTagStyles } from './styles';

interface IPrinterStatusTag extends WithTranslation {
  status: EPrinterStatus;
  showStatusText?: boolean;
}

const PrinterStatusTag = ({ status, showStatusText, t }: IPrinterStatusTag) => {
  const isActive = status === EPrinterStatus.ACTIVE;
  const showText = !isActive || showStatusText;
  return (
    <Box csx={printerStatusTagStyles(showText ? true : false, isActive)}>
      {showText && (
        <Typography color="textWhite" variant="caption" fontWeight="medium">
          {t(`printerStatus.${status}`).toUpperCase()}
        </Typography>
      )}
    </Box>
  );
};

export default PrinterStatusTag;
