import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Card from '@app/components/common/Card';
import Typography from '@app/components/common/Typography';

const AccordionExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Accordion
      </Typography>
      <Accordion onExpandAll={() => null} onCollapseAll={() => null}>
        <AccordionSection title="Section 1" description="Description">
          <Typography>Content Section 1</Typography>
        </AccordionSection>
        <AccordionSection title="Section 2" description="Description">
          <Typography>Content Section 2</Typography>
        </AccordionSection>
        <AccordionSection title="Section 3" description="Description">
          <Typography>Content Section 3</Typography>
        </AccordionSection>
        <AccordionSection title="Section 4" description="Description">
          <Typography>Content Section 4</Typography>
        </AccordionSection>
      </Accordion>
    </Card>
  );
};

export default AccordionExample;
