import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CategoryFilteredScreenContainer from '@app/components/common/CategoryFilteredScreenContainer';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCategories,
  selectMenuTypes,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  dropdownMenuTypeOptionFactory,
  menuCategoryCardFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CategoriesTable from './CategoriesTable';

const CategoriesList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );
  const categories = useSelector(selectCategories);
  const menuTypes = useSelector(selectMenuTypes);
  const breadcrumbs = useSelector(selectBreadCrumbs);

  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  // Local state
  const navigate = useNavigateWithOrg();

  const categoriesCardList = useMemo(
    () => menuCategoryCardFactory(categories),
    [categories],
  );

  const menuTypesOptions = useMemo(
    () => dropdownMenuTypeOptionFactory(menuTypes),
    [menuTypes],
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(2, null, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewCategory = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewCategoryTag'),
      onPress: () => navigate('/menu/categories/add'),
      pathURL: '/menu/categories/add',
    });
  };

  return (
    <CategoryFilteredScreenContainer
      menusSet={new Set()}
      dropdowns={[
        {
          id: 'activeFilter',
          label: t('commonTexts.status'),
          data: dropdownOptions,
          defaultValue: 1,
          dataExample: 1,
        },
        {
          id: 'menuTypeIds',
          label: t('menuScreen.categoryMain.menuTypesDropdown.label'),
          data: [[{ label: t('commonTexts.all'), value: 0 }], menuTypesOptions],
          dataExample: [],
        },
      ]}
      breakpoint="medium"
      data={categoriesCardList}
      optionList={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewCategory'),
          handler: handleAddNewCategory,
        },
      ]}
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.CategoriesScreen'),
      })}
      onOptionClickDisabled={() => {
        setShowToast({
          type: 'info',
          title: t('menuScreen.categoryMain.disabledActionToast.title'),
          description: t(
            'menuScreen.categoryMain.disabledActionToast.description',
          ),
        });
      }}
      sortAvailable={!showListView}
      groupFilters
      showChildren={showListView}
      customButtons={
        <Grid.Item
          mb={12}
          sm={showListView ? 7 : 6}
          md={showListView ? 7 : 6}
          lg={showListView ? 5 : 4}
          xl={showListView ? 6 : 5}>
          <Box
            csx={theme => ({
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              borderLeft: `1px solid ${theme.colors.lightGrey}`,
              paddingLeft: '15px',
            })}>
            <Switch
              checked={showListView}
              onChange={isActive => setShowListView(isActive)}
              label={t('menuScreen.itemsMain.showListView')}
            />
          </Box>
        </Grid.Item>
      }
      renderItem={category => {
        const pathURL = `/menu/categories/${category.id}`;

        return (
          <Card.Item
            title={category.title}
            isActive={category.status}
            showStatus
            tag={category.customTag}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: category.title,
                onPress: () => {
                  navigate(pathURL);
                },
                statusPath: `selectedMode.currentMenu.categories.${category.id}.active`,
                pathURL,
              });
            }}
            count={category.itemCount}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}>
      {filterParams => <CategoriesTable filterParams={filterParams} />}
    </CategoryFilteredScreenContainer>
  );
};

export default CategoriesList;
