import { INVALID_DAYS_INITIAL_STATE } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/initialState';
import { sliderContainerStyles } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/styles';
import { IInvalidDays } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/types';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import Icon from '@app/components/common/Icon';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import WeekTabs from '@app/components/common/Tabs/WeekTabs';
import { WEEK_TABS_DATA } from '@app/components/common/Tabs/WeekTabs/types';
import Typography from '@app/components/common/Typography';
import { getDayWithErrors } from '@app/helpers/settings/timeBasedMenuTypes';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
} from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  actionCreatorsMenu,
  dateSliderHasErrorValue,
  DayOfWeek,
  dayOperationalHours,
  generateTabletGeneratedId,
  HOUR_MS,
  ISpecial,
  IWithMenuTypeVersionId,
  SectionId,
  SPECIAL_AVAILABILITY_TIME_INITIAL_STATE,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const MS_8_AM = HOUR_MS * 8;
const MS_12_PM = HOUR_MS * 12;

interface ITimeAvailabilityTable {
  id: number;
  time: [number, number];
  bannedRanges: Array<[number, number]>;
  delete: string;
  ruleId: number | string;
}

type IAvailabilitySectionSection = WithTranslation &
  IWithMenuTypeVersionId &
  IAccordionSection;

const SECTION_ID = SectionId.AVAILABILITY;
const getMemoizedSpecialData = makeSelectItemData('specials', SECTION_ID);

const AvailabilitySection = ({
  t,
  menuTypeVersionId,
  ...accordionSectionProps
}: IAvailabilitySectionSection) => {
  // Redux
  const { updateSpecial: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const isCreating = useSelector(selectIsMenuCreationMode);

  const { itemData: data } = useSelector(getMemoizedSpecialData);

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >({ tabId: 1, subTabId: null });
  const [invalidDays, setInvalidDaysState] = useState<IInvalidDays>(
    INVALID_DAYS_INITIAL_STATE,
  );

  const invalidDaysRef = useRef(invalidDays);

  const columnHelperCustom = createColumnHelper<ITimeAvailabilityTable>();

  const selectedDay = useMemo(
    () =>
      activeTab?.tabId
        ? WEEK_TABS_DATA[activeTab?.tabId - 1].dayOfWeek
        : DayOfWeek.MONDAY,
    [activeTab],
  );

  const availableTimes = useMemo(() => {
    return data?.menuTypeVersions?.[menuTypeVersionId]?.availableTimes;
  }, [data, menuTypeVersionId]);

  const updateTime = (
    newValue: any,
    id: number,
    field: keyof dayOperationalHours,
  ) => {
    const clonedAvailableTimes = cloneDeep(
      store.getState().menu.changeData.data as ISpecial,
    )?.menuTypeVersions?.[menuTypeVersionId]?.availableTimes;

    clonedAvailableTimes[selectedDay][id][field as 'id'] = newValue;

    setValue(
      {
        availableTimes: clonedAvailableTimes,
      },
      SECTION_ID,
      menuTypeVersionId,
    );
  };

  const handleDelete = (id: number, ruleId: number | string) => {
    const defaultMenuTypeTimes = (
      store.getState().menu.changeData.data as ISpecial
    )?.menuTypeVersions?.[menuTypeVersionId]?.availableTimes;
    const defaultMenuTypeTimesCloned = cloneDeep(defaultMenuTypeTimes);
    defaultMenuTypeTimesCloned[selectedDay].splice(id, 1);

    setValue(
      {
        availableTimes: defaultMenuTypeTimesCloned,
      },
      SECTION_ID,
      menuTypeVersionId,
    );

    clearInvalidDayById(ruleId);
  };

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('time', {
        header: 'Time',
        cell: info => {
          const bannedRanges = info.row.original.bannedRanges.sort(
            (a, b) => a[0] - b[0],
          );

          const hasError =
            invalidDaysRef?.current?.[selectedDay] &&
            invalidDaysRef.current[selectedDay].includes(
              info.row.original.ruleId,
            );

          return (
            <Box
              key={`slider-time-${info.row.original.id}`}
              csx={theme => sliderContainerStyles(theme, hasError)}>
              <CustomSlider
                step={900000}
                value={info.getValue()}
                min={0}
                max={86399000}
                showStepMarks={false}
                bannedRanges={bannedRanges}
                valueLabelDisplay="always"
                labelDirection="bottom"
                customValueLabel={currentVale => {
                  return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                    .toUTC()
                    .toFormat('hh:mm a');
                }}
                debounceTimeout={200}
                onValueChange={range => {
                  if (!Array.isArray(range)) {
                    return;
                  }

                  updateTime(
                    dateTimeMsToTodayDate(range[0], true),
                    info.row.original.id,
                    'startTime',
                  );
                  updateTime(
                    dateTimeMsToTodayDate(range[1], true),
                    info.row.original.id,
                    'endTime',
                  );
                }}
                renderLimits={value => (
                  <Box csx={{ width: '70px' }}>
                    <Typography fontWeight="medium" variant="caption">
                      {DateTime.fromISO(dateTimeMsToTodayDate(value))
                        .toUTC()
                        .toFormat('hh:mm a')}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
          );
        },
        minSize: 300,
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                handleDelete(info.row.original.id, info.row.original.ruleId)
              }
            />
          ) as unknown as string,
        size: 50,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedDay, menuTypeVersionId],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const setInvalidDays = (
    newInvalidDays:
      | IInvalidDays
      | ((prevInvalidDays: IInvalidDays) => IInvalidDays),
  ) => {
    if (typeof newInvalidDays === 'function') {
      setInvalidDaysState(prevInvalidDays => {
        const newInvalidDaysResult = newInvalidDays(prevInvalidDays);
        invalidDaysRef.current = newInvalidDaysResult;
        return newInvalidDaysResult;
      });
    } else {
      invalidDaysRef.current = newInvalidDays;
      setInvalidDaysState(newInvalidDays);
    }
  };

  const clearInvalidDayById = (ruleId: string | number) => {
    setInvalidDays(prevInvalidDays => ({
      ...prevInvalidDays,
      [selectedDay]: prevInvalidDays[selectedDay].filter(
        invalidDay => invalidDay !== ruleId,
      ),
    }));
  };

  const availabilityArray: ITimeAvailabilityTable[] = useMemo(() => {
    return availableTimes[selectedDay]
      ? availableTimes[selectedDay].map((value, key) => {
          const bannedRanges: Array<[number, number]> = availableTimes[
            selectedDay
          ]
            .slice(0, key)
            .map(prevMenuTypes => [
              getHourMs(prevMenuTypes.startTime),
              getHourMs(prevMenuTypes.endTime),
            ]);
          const time: [number, number] = [
            getHourMs(value.startTime),
            getHourMs(value.endTime),
          ];

          const hasError = dateSliderHasErrorValue(time, bannedRanges);
          if (!hasError) {
            clearInvalidDayById(value.id);
          } else if (!invalidDays[selectedDay].includes(value.id)) {
            setInvalidDays(prevInvalidDays => ({
              ...prevInvalidDays,
              [selectedDay]: [...prevInvalidDays[selectedDay], value.id],
            }));
          }
          return {
            id: key,
            time,
            delete: 'canDelete',
            bannedRanges,
            ruleId: value.id,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTimes, selectedDay]);

  const onNewTime = () => {
    const menuTypes = availableTimes[selectedDay] || [];
    setValue(
      {
        availableTimes: {
          ...availableTimes,
          [selectedDay]: [
            ...menuTypes,
            {
              ...SPECIAL_AVAILABILITY_TIME_INITIAL_STATE,
              day: selectedDay,
              id: isCreating ? 0 : generateTabletGeneratedId(),
              startTime: dateTimeMsToTodayDate(MS_8_AM, true),
              endTime: dateTimeMsToTodayDate(MS_12_PM, true),
            },
          ],
        },
      },
      SECTION_ID,
      menuTypeVersionId,
    );
  };

  const invalidDaysObject = useMemo(
    () => getDayWithErrors(availableTimes),
    [availableTimes],
  );

  const onCopyRules = (selectedDays: Record<DayOfWeek, boolean>) => {
    const clonedAvailableTimes = cloneDeep(
      (store.getState().menu.changeData.data as ISpecial)?.menuTypeVersions?.[
        menuTypeVersionId
      ]?.availableTimes,
    );

    Object.entries(selectedDays).forEach(([day, value]) => {
      if (day === selectedDay || !value) {
        return;
      }
      const availableTimesOfMainDay =
        clonedAvailableTimes[selectedDay as DayOfWeek];
      const availableTimesOfCurrentDay = clonedAvailableTimes[day as DayOfWeek];

      clonedAvailableTimes[day as DayOfWeek] = availableTimesOfMainDay.map(
        (time, index) => {
          const existingTime = availableTimesOfCurrentDay[index];

          if (existingTime) {
            return {
              ...existingTime,
              startTime: time.startTime,
              endTime: time.endTime,
            };
          }
          return {
            ...time,
            day: day as DayOfWeek,
            id: isCreating ? 0 : generateTabletGeneratedId(),
          };
        },
      );
    });

    setValue(
      { availableTimes: clonedAvailableTimes },
      SECTION_ID,
      menuTypeVersionId,
    );
  };

  return (
    <AccordionSection
      title={t('menuScreen.specialDetails.availability.title')}
      info={t('menuScreen.specialDetails.availability.toolTip')}
      {...accordionSectionProps}>
      <Card.SubCard>
        <>
          <Box csx={{ marginBottom: '10px' }}>
            <Typography fontWeight="medium" color="semanticBlue" align="center">
              {t('menuScreen.specialDetails.availability.table.title')}
            </Typography>
          </Box>
          <WeekTabs
            activeTab={activeTab}
            onSelectedTabChange={handleOnSelectedTabChange}
            error={invalidDaysObject}
            csx={theme => ({
              borderRadius: 0,
              boxShadow: 'none',
              paddingInline: '5px',
              borderTop: `1px solid ${theme.colors.lighterGrey}`,
            })}
          />
          <Table
            columns={COLUMNS}
            data={data ? availabilityArray : []}
            cellCsx={{ height: '100px' }}
            containerCsx={{
              border: 'none',
              borderRadius: 0,
            }}
            align={{
              delete: 'center',
            }}
            alignHeaders={{
              delete: 'center',
            }}
            showShadow={false}
            noDataComponent={
              <Box
                csx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBlock: '20px',
                }}>
                <Typography align="center">
                  {t(
                    'settingsModule.registerModeSettings.general.timeBasedMenuType.noData',
                  )}
                </Typography>
              </Box>
            }
            styleForSmallerScreens="card"
            newRowComponent={
              <Box
                csx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                }}>
                <DayOfTheWeekPicker
                  selectedDays=""
                  onApply={onCopyRules}
                  disabledDays={[selectedDay]}>
                  {onToggle => (
                    <Button
                      icon={<Icon name="MdFileCopy" />}
                      csx={{ minWidth: '150px' }}
                      onClick={onToggle}
                      disabled={availabilityArray.length === 0}>
                      {t(
                        'settingsModule.registerModeSettings.general.timeBasedMenuType.copyRules.button',
                      )}
                    </Button>
                  )}
                </DayOfTheWeekPicker>
                <Button
                  onClick={onNewTime}
                  variant="primary"
                  csx={{ minWidth: '150px' }}
                  icon={<Icon name="MdAdd" />}>
                  {t(
                    'loggedIn.settingsModule.generalSettings.hoursOfOperation.addTime',
                  )}
                </Button>
              </Box>
            }
          />
        </>
      </Card.SubCard>
    </AccordionSection>
  );
};

export default AvailabilitySection;
