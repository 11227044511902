import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  makeSelectSettingsData,
  selectLocationCourses,
} from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IGuestCheckCourse,
  IGuestCheckSettings,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.ITEM_SORT_PRIORITY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestCheckSettings>(SECTION_ID);

type ICourseSortPriorityTable = Omit<IGuestCheckCourse, 'courseId'> & {
  id: number;
  name: string;
};

const columnHelper = createColumnHelper<ICourseSortPriorityTable>();

const CourseSortPriorityTable = ({ t }: WithTranslation) => {
  const { data } = useSelector(getMemoizedSettingsData);

  const courses = useSelector(selectLocationCourses);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const customizingReceiptTableArray = useMemo(
    () =>
      orderBy(
        (data?.courseOrder || []).map((course, index) => ({
          ...course,
          id: index,
          name: courses[course.courseId]?.name || '',
        })),
        'sortPriority',
      ),
    [data?.courseOrder, courses],
  );

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.course',
        ),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
    ],
    [t],
  );

  const handleOnSort = (newData: ICourseSortPriorityTable[]) => {
    const clonedCourseTable = cloneDeep(data.courseOrder);
    newData.forEach((item, index) => {
      clonedCourseTable[item.id].sortPriority = index + 1;
    });
    setValue<IGuestCheckSettings>(
      {
        courseOrder: clonedCourseTable,
      },
      SECTION_ID,
    );
  };
  return (
    <Table
      mode="sort-vertically"
      noPadding
      renderEmptyValues
      nestedScrollEnabled
      onSortEnd={handleOnSort}
      alignHeaders={{
        prepend: 'center',
        append: 'center',
        justify: 'center',
        fontSize: 'center',
        fontWeight: 'center',
        color: 'center',
        line: 'center',
      }}
      cellCsx={{
        height: 70,
      }}
      title={t(
        'loggedIn.settingsModule.guestCheckSettings.courseSortPriority.title',
      )}
      columns={COLUMN_VALUES}
      data={data ? customizingReceiptTableArray : []}
    />
  );
};

export default CourseSortPriorityTable;
