import ActionDropdown from '@app/components/common/ActionDropdown';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import StatusIndicator from '@app/components/common/StatusIndicator';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { getDateFromUTC } from '@app/helpers/time/time';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  currentDevicesSelector,
  currentLicenseSettingsSettingsSelector,
} from '@app/state/selectors/settingsSelectors';
import { associateLicenseLocationWeb } from '@app/state/settings/settingsWebActions';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  ILicense,
  ILicensesSettings,
  NULLISH_DATE,
} from '@westondev/tableturn-core';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TSelectedLicenseSettings } from './types';

interface ILicensesTable {
  id: number;
  locationId: number;
  licenseType: string;
  activationDate: string;
  purchaseDate: string;
  status: string;
  activationCode: string;
  deviceId: number;
  actions: string;
  nickname: string;
  licenseSettingsId: keyof ILicensesSettings;
}

interface ILicensesTableProps {
  data: (ILicense & { nickname: string; actions: string })[];
  emptyBySearch: boolean;
  setShowLicenseDetails: React.Dispatch<
    React.SetStateAction<TSelectedLicenseSettings | null>
  >;
}

const LicensesTable = ({
  data,
  emptyBySearch,
  setShowLicenseDetails,
}: ILicensesTableProps) => {
  // Redux
  const devices = useSelector(currentDevicesSelector);
  const licensesSettings = useSelector(currentLicenseSettingsSettingsSelector);

  const { deactivateLicenseWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const onRefreshSettings = useRefreshSettingsWeb();

  // Local state
  const { t } = useTranslation();
  const [licenseLocationModal, setLicenseLocationModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  const [selectedLicenseId, setSelectedLicenseId] = useState(0);

  const columnHelper = createColumnHelper<ILicensesTable>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('nickname', {
        header: t('settingsModule.devices_licenses.licensesTable.nickname'),
        cell: info => info.getValue(),
        minSize: 170,
      }),
      columnHelper.accessor('activationDate', {
        header: t(
          'settingsModule.devices_licenses.licensesTable.activationDate',
        ),
        cell: info =>
          getDateFromUTC(info.getValue() ? info.getValue() : NULLISH_DATE),
        sortingFn: (rowA, rowB, columnId) => {
          const dateA = new Date(rowA.getValue(columnId));
          const dateB = new Date(rowB.getValue(columnId));
          return dateA.getTime() - dateB.getTime();
        },
        sortDescFirst: false,
        invertSorting: true,
        minSize: 170,
      }),
      columnHelper.accessor('licenseType', {
        header: t('settingsModule.devices_licenses.licensesTable.licenseType'),
        cell: info => (info.getValue() ? capitalize(info.getValue()) : '- - -'),
        invertSorting: true,
        minSize: 160,
      }),
      columnHelper.accessor('status', {
        header: t(
          'settingsModule.devices_licenses.licensesTable.associatedDevice',
        ),
        cell: info => {
          return (
            <Box csx={{ width: '100%', maxWidth: '350px' }}>
              <StatusIndicator
                titleColor={
                  info.getValue() === 'active' ? 'black' : 'semanticRed'
                }
                title={
                  info.getValue() === 'active'
                    ? devices?.tablets[info.row.original.deviceId]?.name ??
                      '- - -'
                    : t(
                        'settingsModule.devices_licenses.licensesTable.noDevice',
                      )
                }
                subtitle={undefined}
                circleColor={
                  info.getValue() === 'active' ? 'semanticGreen' : 'semanticRed'
                }
                buttonText={t(
                  'settingsModule.devices_licenses.licensesTable.deactivate',
                )}
                onPressButton={() =>
                  deactivateLicenseWeb(
                    () =>
                      onRefreshSettings(
                        store.getState() as unknown as CoreRootState,
                      ),
                    devices?.tablets[info.row.original.deviceId]?.id,
                    Number(info.row.original.id),
                    licensesSettings[info.row.original.licenseSettingsId]
                      ?.nickname ?? '',
                  )
                }
              />
            </Box>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          if (
            rowA.getValue(columnId) === 'active' &&
            rowB.getValue(columnId) !== 'active'
          )
            return -1;
          if (
            rowA.getValue(columnId) !== 'active' &&
            rowB.getValue(columnId) === 'active'
          )
            return 1;

          const nameA =
            rowA.getValue(columnId) === 'active'
              ? devices?.tablets[rowA.original.deviceId]?.name ?? 'z'
              : '';
          const nameB =
            rowB.getValue(columnId) === 'active'
              ? devices?.tablets[rowB.original.deviceId]?.name ?? 'z'
              : '';
          return nameA.localeCompare(nameB);
        },
        minSize: 180,
      }),
      columnHelper.accessor('status', {
        id: 'activationCode',
        header: t(
          'settingsModule.devices_licenses.licensesTable.activationCode',
        ).replace(':', ''),
        cell: info => {
          const activationCode = info.row.original.activationCode;
          return (
            <Typography>
              {`${activationCode.slice(0, 3)}-${activationCode.slice(3)}`}
            </Typography>
          );
        },
        enableSorting: false,
        minSize: 150,
      }),
      columnHelper.accessor('actions', {
        header: t('settingsModule.devices_licenses.licensesTable.actions'),
        cell: info => (
          <Box csx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Button
              csx={{ minWidth: '100px' }}
              variant="danger"
              disabled={info.row.original.status !== 'active'}
              onClick={() =>
                deactivateLicenseWeb(
                  () =>
                    onRefreshSettings(
                      store.getState() as unknown as CoreRootState,
                    ),
                  devices?.tablets[info.row.original.deviceId]?.id,
                  Number(info.row.original.id),
                  licensesSettings[info.row.original.licenseSettingsId]
                    ?.nickname ?? '',
                )
              }>
              {t('settingsModule.devices_licenses.tabletsTable.deactivate')}
            </Button>
            <ActionDropdown
              trigger={
                <Button
                  csx={{ width: '50px' }}
                  icon={<Icon name="MdEdit" />}
                  onClick={() => {
                    setSelectedLocationId(info.row.original.locationId);
                    setSelectedLicenseId(info.row.original.id);
                  }}
                />
              }>
              <ActionDropdown.Item
                onClick={() => {
                  setShowLicenseDetails({
                    licenseSettingsId: info.row.original.licenseSettingsId,
                    deviceId: devices?.tablets[info.row.original.deviceId]?.id,
                    licenseId: Number(info.row.original.id),
                  });
                }}>
                {t(
                  'settingsModule.devices_licenses.licensesTable.actionsButtons.editLicenseSettings',
                )}
              </ActionDropdown.Item>
              <ActionDropdown.Item
                onClick={() => {
                  setLicenseLocationModal(true);
                }}>
                {t(
                  'settingsModule.devices_licenses.licensesTable.actionsButtons.changeLocation',
                )}
              </ActionDropdown.Item>
            </ActionDropdown>
          </Box>
        ),
        size: 160,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleSelectLocation = (locations: IItemSelection[]) => {
    associateLicenseLocationWeb(
      () => onRefreshSettings(store.getState() as unknown as CoreRootState),
      [selectedLicenseId],
      Number(locations[0].id),
    );
    setLicenseLocationModal(false);
    return locations;
  };

  return (
    <>
      <SelectionModal
        type="locations"
        active={licenseLocationModal}
        onModalClose={() => setLicenseLocationModal(false)}
        onAssociate={locations => handleSelectLocation(locations)}
        allowAssociateMultiple={false}
        extraLocationIds={[selectedLocationId]}
        isSettingsAccessLocations={true}
      />
      <Table
        renderEmptyValues
        columns={COLUMN_VALUES}
        data={data}
        cellCsx={{ height: '70px' }}
        manualSorting={false}
        alwaysShowSortIcon
        enableAlphabeticalSorting
        noDataMessage={
          emptyBySearch
            ? t(
                'settingsModule.devices_licenses.licensesTable.emptyBySearchTitle',
              )
            : t('settingsModule.devices_licenses.licensesTable.emptyDataTitle')
        }
        alignHeaders={{
          actions: 'center',
          activationCode: 'center',
        }}
        align={{
          actions: 'center',
          activationCode: 'center',
        }}
      />
    </>
  );
};

export default LicensesTable;
