import { MQ_MIN_LARGE, MQ_MIN_SMALL, ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

const authContainerBaseStyles = (theme: Theme) =>
  css({
    display: 'grid',
    width: '100%',
    minHeight: '100vh',
    justifyItems: 'center',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "logoArea" 
      "contentArea"
    `,
    '.content': {
      gridArea: 'contentArea',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      boxShadow: theme.shadows[3],
    },
    '.logoContent': {
      gridArea: 'logoArea',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingBlock: '10px',
      position: 'relative',
      paddingInline: '40px',
      paddingRight: '30px',
      '.logo': {
        width: '190px',
        height: '45px',
        top: '0',
        right: '0',
      },
    },
    '.body': {
      width: '100%',
      height: '100%',
      backgroundColor:
        theme.mode === ThemeModes.DARK
          ? theme.colors.background
          : theme.colors.white,
      display: 'flex',
      justifyContent: 'center',
      zIndex: 1,
    },
  });

export const authenticationContainerStyles = (theme: Theme) =>
  css(authContainerBaseStyles(theme), {
    [MQ_MIN_SMALL]: {
      '.content': {
        alignItems: 'center',
      },
      '.logoContent': {
        justifyContent: 'space-between',
      },
    },
    [MQ_MIN_LARGE]: {
      gap: 0,
      gridTemplateColumns: 'minmax(370px, 500px) minmax(620px, 1fr)',
      gridTemplateAreas: '"logoArea contentArea"',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      '.content': {
        flexBasis: '780px',
      },
      '.logoContent': {
        width: 'auto',
        paddingInline: '0px',
        '.logo': {
          width: 'auto',
          height: 'auto',
          maxHeight: '80px',
        },
      },
      '.body': {
        alignItems: 'center',
      },
    },
  });

export const authenticationContainerStylesCentered = (theme: Theme) =>
  css(authContainerBaseStyles(theme), {
    '.needAssistance': {
      display: 'none',
    },
    [MQ_MIN_SMALL]: {
      '.logoContent': {
        justifyContent: 'space-between',
      },
    },
    [MQ_MIN_LARGE]: {
      gridTemplateColumns: 'auto',
    },
  });
