import { useParams } from 'react-router-dom';

const useOrgData = () => {
  const params = useParams();
  const orgId = params.orgId;
  const locationId = params.locationId;

  return orgId && locationId && parseInt(orgId) && parseInt(locationId)
    ? true
    : false;
};

export default useOrgData;
