import AuthCard from '@app/components/Authentication/AuthCard';
import Typography from '@app/components/common/Typography';
import Box from '@app/components/common/Box';
import { WithTranslation } from 'react-i18next';
import Input from '@app/components/common/Input';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import Grid from '@app/components/common/Grid';
import { gridContainerStyles, registrationCardStyles } from './styles';
import PasswordMustBe from '@app/components/Authentication/PasswordMustBe';
import Button from '@app/components/common/Button';
import { linkWithText } from '@app/components/Authentication/styles';
import Link from '@app/components/common/Link';
import Stepper from '@app/components/common/Stepper';
import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import StepperForm from '@app/components/common/StepperForm';
import VerifyEmailStep from '../../../components/Authentication/SignUp/VerifyEmailStep';
import VerifyPhoneStep from '../../../components/Authentication/SignUp/VerifyPhoneStep';
import { IStep } from '@app/components/common/Stepper/types';
import AccountCreatedMessage from './AccountCreatedMessage';
import { useLocation } from 'react-router-dom';
import InvitationWelcomeMessage from '@app/components/Authentication/SignUp/InvitationWelcomeMessage';
import { ESignupErrors, INITIAL_FORM_DATA } from './types';
import { InputMask } from '@react-input/mask';
import { apiCall } from '@app/helpers/apiCall';
import { CallMethods } from '@app/constants';
import { actionCreatorsApp, apiEndpoints } from '@westondev/tableturn-core';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { signUp } from '@app/state/app/actions';
import { BroadcastChannel } from 'broadcast-channel';

const SignUp = ({ t }: WithTranslation) => {
  const { showToast } = bindActionCreators(actionCreatorsApp, useDispatch());
  const signUpAction = bindActionCreators(signUp, useDispatch());

  // Local state
  const location = useLocation();
  const isInSignUpInvite = useMemo(() => {
    return location.pathname === '/auth/sign-up-invite';
  }, [location.pathname]);

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const [steps, setSteps] = useState<IStep>({
    [0]: {
      name: t('authentication.signUp.steps.accountInformation'),
      active: !isInSignUpInvite,
      completed: false,
    },
    [1]: {
      name: t('authentication.signUp.steps.confirmEmail'),
      active: false,
      completed: false,
    },
    [2]: {
      name: t('authentication.signUp.steps.confirmPhone'),
      active: false,
      completed: false,
    },
  });

  const [passwordErrors, setPasswordErrors] = useState({
    hasError: false,
    atLeast8Chars: false,
    atLeastOneUpperCase: false,
    atLeastOneLoweCase: false,
    atLeastOneNumberOrSymbol: false,
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
  });

  const isInviteActive = useMemo(() => {
    return !Object.values(steps).some(step => step.active);
  }, [steps]);

  const passwordConfirmationHasError =
    (formData.confirmPassword.length > 0 || !passwordErrors.hasError) &&
    formData.password !== formData.confirmPassword;

  const activeStep = useMemo(() => {
    return Object.values(steps).findIndex(step => step.active);
  }, [steps]);

  const areAllCompleted = useMemo(() => {
    return Object.values(steps).some(step => !step.completed) ? false : true;
  }, [steps]);

  const hasUnsavedChanges = useMemo(() => {
    return Object.values(formData).some(value => value.length > 0);
  }, [formData]);

  const handleCheckFields = (onSuccess?: () => void) => {
    const cleanedFormData = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      phoneNumber: formData.phoneNumber.trim(),
      password: formData.password.trim(),
    };

    if (
      cleanedFormData.firstName.length > 0 &&
      cleanedFormData.lastName.length > 0 &&
      cleanedFormData.email.length > 0 &&
      cleanedFormData.phoneNumber.length > 0 &&
      cleanedFormData.password.length > 0 &&
      !passwordErrors.hasError &&
      !passwordConfirmationHasError
    ) {
      onSuccess && onSuccess();
    } else {
      if (cleanedFormData.firstName.length === 0) {
        setErrors(prev => ({
          ...prev,
          firstName: t('authentication.signUp.errors.firstName'),
        }));
        document.getElementById('firstName')?.focus();
      } else if (cleanedFormData.lastName.length === 0) {
        setErrors(prev => ({
          ...prev,
          lastName: t('authentication.signUp.errors.lastName'),
        }));
      } else if (cleanedFormData.email.length === 0) {
        setErrors(prev => ({
          ...prev,
          email: t('authentication.signUp.errors.email'),
        }));
        document.getElementById('email')?.focus();
      } else if (
        cleanedFormData.phoneNumber.length === 0 ||
        cleanedFormData.phoneNumber.length < 10 ||
        cleanedFormData.phoneNumber.length > 10
      ) {
        setErrors(prev => ({
          ...prev,
          phoneNumber: t('authentication.signUp.errors.phoneNumber'),
        }));
        document.getElementById('phoneNumber')?.focus();
      } else if (cleanedFormData.password.length === 0) {
        setErrors(prev => ({
          ...prev,
          password: t('authentication.signUp.errors.password'),
        }));
        document.getElementById('password')?.focus();
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleCheckFields(async () => {
      if (steps[0].completed)
        return setSteps(prev => ({
          ...prev,
          [0]: { ...prev[0], active: false },
          [1]: { ...prev[1], active: true },
        }));
      if (Object.values(errors).some(error => error)) return;

      setIsLoading(true);
      apiCall(CallMethods.POST, apiEndpoints.user.signUp(), false, formData)
        .then(r => {
          signUpAction(r);

          setIsLoading(false);
          setSteps(prev => ({
            ...prev,
            [0]: { ...prev[0], completed: true, active: false },
            [1]: { ...prev[1], active: true },
          }));
        })
        .catch(error => {
          setIsLoading(false);

          switch (error?.response?.data?.error?.code) {
            case ESignupErrors.EMAIL_ALREADY_EXISTS:
              setErrors(prev => ({
                ...prev,
                email: error?.response?.data?.error?.message,
              }));
              break;

            case ESignupErrors.PHONE_ALREADY_EXISTS:
              setErrors(prev => ({
                ...prev,
                phoneNumber: error?.response?.data?.error?.message,
              }));
              break;

            case ESignupErrors.GENERAL_ERROR:
            default:
              break;
          }
          showToast({
            type: 'error',
            title: 'Error',
            description: error?.response?.data?.error?.message,
          });
        });
    });
  };

  const handleSetFormData = (
    e: FormEvent<HTMLInputElement> | string,
    field: string,
  ) => {
    const value = typeof e === 'string' ? e : e.currentTarget.value;
    const atLeast8Chars = /.{8,}/;
    const atLeastOneUpperCase = /(?=.*[A-Z])/;
    const atLeastOneLoweCase = /(?=.*[a-z])/;
    const atLeastOneNumberOrSymbol = /(?=.*\d|[^A-Za-z])/;
    const validationRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d|[^A-Za-z])[\w\d\S]{8,}$/;

    let valid = true;
    if (field === 'password') {
      setPasswordErrors(prev => ({
        ...prev,
        atLeast8Chars: atLeast8Chars.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneUpperCase: atLeastOneUpperCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneLoweCase: atLeastOneLoweCase.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        atLeastOneNumberOrSymbol: atLeastOneNumberOrSymbol.test(value),
      }));
      setPasswordErrors(prev => ({
        ...prev,
        hasError: !validationRegex.test(value),
      }));
    } else if (field === 'phoneNumber') {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        setFormData(prev => ({ ...prev, [field]: value }));
        if (value.length !== 10) {
          setErrors(prev => ({ ...prev, [field]: 'Invalid phone number' }));
          valid = false;
        } else {
          setErrors(prev => ({ ...prev, [field]: '' }));
        }
      } else {
        valid = false;
      }
    }

    if (valid) {
      setFormData(prev => ({ ...prev, [field]: value }));
      setErrors(prev => ({ ...prev, [field]: value.length === 0 }));

      if (
        field === 'email' ||
        field === 'phoneNumber' ||
        field === 'phoneNumberMasked'
      )
        setSteps(prev => ({
          ...prev,
          [0]: { ...prev[0], completed: false },
          [1]: {
            ...prev[1],
            completed: false,
          },
        }));
    }
  };

  const handleChangeStep = (index?: number) => {
    if (index !== undefined && !Object.values(errors).some(error => error)) {
      setSteps(prev => ({
        ...prev,
        [index]: { ...prev[index], active: true },
        [activeStep]: { ...prev[activeStep], active: false },
      }));
    }
  };

  const handleContinueVerifyEmail = async (code: string) => {
    return apiCall(CallMethods.POST, apiEndpoints.user.verifyEmail(), false, {
      email: formData.email,
      code,
    }).then(() => {
      setSteps(prev => ({
        ...prev,
        [1]: { ...prev[1], completed: true, active: false },
        [2]: { ...prev[2], active: true },
      }));
    });
  };
  const handleContinueVerifyPhone = async (code: string) => {
    return apiCall(CallMethods.POST, apiEndpoints.user.verifyPhone(), false, {
      phoneNumber: formData.phoneNumber,
      code,
    }).then(() => {
      setSteps(prev => ({
        ...prev,
        [2]: { ...prev[2], completed: true, active: false },
      }));

      const broadcastChannel = new BroadcastChannel('auth');
      broadcastChannel.postMessage({ action: 'login' });
    });
  };

  const handleSetEmail = (email: string) => {
    setFormData(prev => ({ ...prev, email }));
  };
  const handleSetPhone = (phoneNumber: string) => {
    setFormData(prev => ({ ...prev, phoneNumber }));
  };

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges && !areAllCompleted) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges, areAllCompleted]);

  return (
    <AuthCard
      csx={registrationCardStyles}
      maxHeight="700px"
      width="100%"
      showLogo={false}>
      <Typography
        variant="heading"
        align="center"
        fontWeight="medium"
        className={`title ${areAllCompleted ? 'success' : ''}`}>
        {t(
          areAllCompleted
            ? 'authentication.signUp.steps.accountCreated'
            : 'authentication.signUp.accountInfo.createAnAccount',
        )}
      </Typography>
      <Box className={`stepperArea ${!areAllCompleted ? 'sticky' : ''}`}>
        {!areAllCompleted && (
          <Stepper
            steps={steps}
            getStepIndex={handleChangeStep}
            clickable={false}
          />
        )}
      </Box>
      {areAllCompleted ? (
        <AccountCreatedMessage user={formData.firstName} />
      ) : isInSignUpInvite && isInviteActive ? (
        <InvitationWelcomeMessage
          onGetStarted={() =>
            setSteps(prev => ({
              ...prev,
              [0]: { ...prev[0], active: true },
            }))
          }
        />
      ) : (
        <>
          <StepperForm activeStep={activeStep}>
            <Box className="form">
              <form
                className="formContent"
                css={{ maxWidth: '550px' }}
                onSubmit={handleSubmit}>
                <Box csx={{ height: '100%' }}>
                  <Grid container rowGap={18} csx={gridContainerStyles}>
                    <Grid.Item mb={6}>
                      <Input
                        id="firstName"
                        name="firstName"
                        label={t('authentication.signUp.accountInfo.firstName')}
                        placeholder={t(
                          'authentication.signUp.accountInfo.firstName',
                        )}
                        type="text"
                        value={formData.firstName}
                        required
                        onChange={e => handleSetFormData(e, 'firstName')}
                        error={!!errors.firstName}
                        caption={errors.firstName}
                        autoFocus
                      />
                    </Grid.Item>
                    <Grid.Item mb={6}>
                      <Input
                        id="lastName"
                        name="lastName"
                        label={t('authentication.signUp.accountInfo.lastName')}
                        placeholder={t(
                          'authentication.signUp.accountInfo.lastName',
                        )}
                        type="text"
                        value={formData.lastName}
                        required
                        onChange={e => handleSetFormData(e, 'lastName')}
                        error={!!errors.lastName}
                        caption={errors.lastName}
                      />
                    </Grid.Item>
                    <Grid.Item sm={6}>
                      <Input
                        id="email"
                        name="email"
                        label={t('commonTexts.email')}
                        placeholder={t('commonTexts.emailExample')}
                        type="email"
                        value={formData.email}
                        required
                        onChange={e => handleSetFormData(e, 'email')}
                        error={!!errors.email}
                        caption={errors.email}
                      />
                    </Grid.Item>
                    <Grid.Item sm={6}>
                      <InputMask
                        label={t(
                          'authentication.signUp.accountInfo.phoneNumber',
                        )}
                        placeholder={t(
                          'authentication.signUp.accountInfo.phoneNumber',
                        )}
                        value={formData.phoneNumberMasked}
                        component={Input}
                        mask="(___) ___-____"
                        replacement={{ _: /\d/ }}
                        onChange={value => {
                          handleSetFormData(value, 'phoneNumberMasked');
                        }}
                        onMask={e => {
                          setFormData(prev => ({
                            ...prev,
                            phoneNumber: e.detail.input,
                            phoneNumberMasked: e.detail.value,
                          }));
                          handleSetFormData(e.detail.input, 'phoneNumber');
                        }}
                        required
                        error={!!errors.phoneNumber}
                        caption={errors.phoneNumber}
                      />
                    </Grid.Item>
                    <Grid.Item sm={6}>
                      <Input
                        id="password"
                        name="password"
                        label={t('commonTexts.password')}
                        placeholder={t('authentication.login.insertPassword')}
                        type="password"
                        value={formData.password}
                        required
                        onChange={e => handleSetFormData(e, 'password')}
                        error={!!(passwordErrors.hasError || errors.password)}
                      />
                    </Grid.Item>
                    <Grid.Item sm={6}>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        label={t(
                          'authentication.resetPassword.confirmPassword',
                        )}
                        placeholder={t(
                          'authentication.resetPassword.confirmPassword',
                        )}
                        type="password"
                        value={formData.confirmPassword}
                        error={passwordConfirmationHasError}
                        caption={
                          passwordConfirmationHasError
                            ? t(
                                'authentication.resetPassword.thePasswordConfirmationDoesntMatch',
                              )
                            : undefined
                        }
                        required
                        showCaptionSpace
                        onChange={e => handleSetFormData(e, 'confirmPassword')}
                      />
                    </Grid.Item>
                  </Grid>
                  <PasswordMustBe
                    csx={{ marginTop: `${25 - CAPTION_HEIGHT}px` }}
                    passwordErrors={{
                      atLeast8Chars: passwordErrors.atLeast8Chars,
                      atLeastOneUpperCase: passwordErrors.atLeastOneUpperCase,
                      atLeastOneLoweCase: passwordErrors.atLeastOneLoweCase,
                      atLeastOneNumberOrSymbol:
                        passwordErrors.atLeastOneNumberOrSymbol,
                    }}
                  />
                </Box>
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Button
                    type="submit"
                    variant="active"
                    isLoading={isLoading}
                    loadingText={t('commonTexts.continue')}
                    onClick={() => handleCheckFields()}>
                    {t('commonTexts.continue')}
                  </Button>
                  <Box csx={[linkWithText, { marginTop: '10px' }]}>
                    <Typography
                      variant="body"
                      align="center"
                      fontWeight="medium"
                      color="darkGrey">
                      {t(
                        'authentication.signUp.accountInfo.alreadyHaveAnAccount',
                      )}
                    </Typography>
                    <Link to="/auth/sign-in" reloadDocument>
                      {t('commonButtons.signIn')}
                    </Link>
                  </Box>
                </Box>
              </form>
            </Box>
            <VerifyEmailStep
              email={formData.email}
              onContinue={handleContinueVerifyEmail}
              setEmail={handleSetEmail}
            />
            <VerifyPhoneStep
              phoneNumber={formData.phoneNumberMasked}
              onContinue={handleContinueVerifyPhone}
              setPhone={handleSetPhone}
            />
          </StepperForm>
        </>
      )}
    </AuthCard>
  );
};

export default SignUp;
