import { getUserIsVerified } from '@app/helpers/users';

import { useMemo } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

const PrivateRouteUnverified = () => {
  // Local state
  const userIsVerified = useMemo(() => {
    return getUserIsVerified();
  }, []);

  return !userIsVerified.isVerified ? (
    <Outlet />
  ) : (
    <Navigate to="/my-organizations" />
  );
};

export default PrivateRouteUnverified;
