import { ITaxesTableDiff } from './types';
import TaxesSingleRowDiff from './TaxesSingleRowDiff';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  ITaxesDiff,
  ITaxesSettings,
  OrderType,
  orderTypesText,
  typesText,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import RenderChange from '../../common/RenderChange';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { useMemo } from 'react';
import Table from '@app/components/common/Table';
import {
  selectCurrentSettingsData,
  currentTaxesSettingsSelector,
} from '@app/state/selectors/settingsSelectors';
import { useSelector } from 'react-redux';

const TaxesMultipleRowDiff = (props: ITaxesTableDiff) => {
  const { t } = useTranslation();

  const changeData = useSelector(selectCurrentSettingsData) as ITaxesSettings;
  const originalData = useSelector(
    currentTaxesSettingsSelector,
  ) as ITaxesSettings;

  const formatValue = (value: unknown, field: string) => {
    return _formatValue(value, field);
  };

  const joinOrderTypes = (orderTypes: string) => {
    const arr = orderTypes.split(',');
    const displayedOrderTypes = arr.map(
      orderType => orderTypesText[orderType as OrderType],
    );
    displayedOrderTypes.sort();
    return displayedOrderTypes.join(', ');
  };

  const columnHelper = createColumnHelper<ITaxesDiff>();
  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('settingsModule.taxesSettings.taxesTable.name'),
        meta: {
          getCellContext: (context: CellContext<ITaxesDiff, unknown>) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].name
                    : changeData[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={rowData.changes.some(
                change => change.field === 'name',
              )}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'orderTypes',
        header: t('settingsModule.taxesSettings.taxesTable.orderTypes'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'orderTypes',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? joinOrderTypes(originalData[rowData.id].orderTypes)
                    : joinOrderTypes(changeData[rowData.id].orderTypes),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={rowData.changes.some(
                change => change.field === 'orderTypes',
              )}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'taxType',
        header: t('settingsModule.taxesSettings.taxesTable.taxType'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'taxType',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? typesText[originalData[rowData.id].taxType]
                    : typesText[changeData[rowData.id].taxType],
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={rowData.changes.some(
                change => change.field === 'taxType',
              )}
            />
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper, t],
  );

  return (
    <Table
      data={props.taxes}
      columns={COLUMNS}
      alignHeaders={{
        name: 'center',
        orderTypes: 'center',
        taxType: 'center',
      }}
    />
  );
};

const TaxesTableDiff = (props: ITaxesTableDiff) => {
  if (props.taxes.length === 0) {
    return null;
  } else if (props.taxes.length === 1 && props.taxes[0].action !== 'remove') {
    return <TaxesSingleRowDiff {...props} />;
  } else {
    return <TaxesMultipleRowDiff {...props} />;
  }
};

export default TaxesTableDiff;
