import { baseURL } from './apiCall';

export const createSocket = (
  authToken: string | undefined,
  orgId: number,
  locationId: number,
) => {
  const token = authToken || '';
  const socket = new WebSocket(
    `${baseURL.replace(
      'https://',
      'wss://',
    )}/s/organization/${orgId}/location/${locationId}/ws`,
    ['Authorization', token],
  );
  return socket;
};
