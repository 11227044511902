import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Icon from '@app/components/common/Icon';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import { LANGUAGES } from '@app/constants';
import { actionCreatorsApp } from '@app/state';
import { selectUserDetails } from '@app/state/selectors/appSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import upperCase from 'lodash/upperCase';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { buttonStyles } from '../styles';
import {
  dropdownInputStyles,
  languageContainerStyles,
  userDropdownStyles,
} from './styles';
import { BroadcastChannel } from 'broadcast-channel';
import { getPathWithOrgData } from '@app/helpers/navigation';
import ButtonLink from '@app/components/common/Button/ButtonLink';

interface IUserDropdown {
  onClick: () => void;
  isMyOrganizationsPage: boolean;
}

const UserDropdown = ({ onClick, isMyOrganizationsPage }: IUserDropdown) => {
  //Redux
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const checkForAllChangesAndNavigate = bindActionCreators(
    actionCreatorsApp.checkForAllChangesAndNavigate,
    dispatch,
  );

  // Local state
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  const currentLanguage = useMemo(() => {
    const language = LANGUAGES.find(lan => lan.key === i18n.language);
    return language ? LANGUAGES.indexOf(language) + 1 : 1;
  }, [i18n.language]);

  const userName = useMemo(() => {
    return userDetails.firstName
      ? `${userDetails.firstName.split(' ')[0]} ${
          userDetails.lastName.split(' ')[0][0]
        }.`
      : userDetails.email;
  }, [userDetails]);

  const languages = LANGUAGES.map((language, index) => ({
    value: index + 1,
    label: language.name,
  }));

  const handleChangeLanguage = (value: number) => {
    i18n.changeLanguage(LANGUAGES[value - 1].key);
  };

  const handleLogout = () => {
    checkForAllChangesAndNavigate(() => {
      const broadcastChannel = new BroadcastChannel('auth');
      broadcastChannel.postMessage({ action: 'logout' });
    });
  };

  const handleNavigate: typeof navigate = (...args) => {
    checkForAllChangesAndNavigate(() => {
      navigate(...(args as Parameters<typeof navigate>));
    });
  };

  const handleGoToMyOrganizations = () => {
    const channelName = getPathWithOrgData('organization');
    const broadcastChannel = new BroadcastChannel(channelName);
    const tabKey = sessionStorage.getItem('tabKey');

    if (!tabKey) return;
    broadcastChannel.postMessage({ action: 'switchOrg', tabKey });
  };

  return (
    <ToolTip
      mode="click"
      direction="bottom"
      dismissOnContentClick={false}
      dismissOnClickOutside={false}
      isToolTipActive={isActive}
      onClickOutside={() => setIsActive(false)}
      content={
        <Box csx={userDropdownStyles}>
          <Button
            animate={false}
            icon={<Icon name="MdPerson" color="black" />}
            variant="transparent"
            onClick={() => {
              handleNavigate('/profile');
            }}>
            {t('loggedIn.navBar.Profile')}
          </Button>
          {!location.pathname.includes('/my-organizations') &&
            !location.pathname.includes('/verify') && (
              <ButtonLink
                to="/my-organizations"
                animate={false}
                icon={<Icon name="MdMultipleStop" color="black" />}
                variant="transparent"
                onClick={openInNewTab => {
                  if (openInNewTab) return window.open('/my-organizations');
                  handleGoToMyOrganizations();
                }}
                csx={{ textAlign: 'left' }}>
                {t('loggedIn.navBar.changeOrganization')}
              </ButtonLink>
            )}
          <Divider csx={{ marginBlock: '5px' }} />
          <Box csx={languageContainerStyles}>
            <Typography
              variant="body"
              fontWeight="medium"
              csx={{ marginRight: '10px' }}>
              {upperCase(LANGUAGES[currentLanguage - 1].key)}
            </Typography>
            <Box ref={dropdownRef} csx={{ width: '100%' }}>
              <Dropdown
                value={currentLanguage}
                placeholder="Select Language"
                data={[languages]}
                onChange={handleChangeLanguage}
                csx={{ boxShadow: 'none !important' }}
                inputContainerCsx={dropdownInputStyles}
              />
            </Box>
          </Box>
          <Button
            animate={false}
            icon={<Icon name="MdHelp" color="black" />}
            variant="transparent"
            onClick={() => {
              handleNavigate('/help');
            }}>
            {t('loggedIn.navBar.help')}
          </Button>
          <Divider csx={{ marginBlock: '5px' }} />
          <Button
            animate={false}
            icon={<Icon name="MdExitToApp" />}
            variant="transparent"
            onClick={handleLogout}>
            {t('commonButtons.logOut')}
          </Button>
        </Box>
      }>
      <Button
        className="navBarUserButton"
        icon={<Icon name="MdPerson" color="black" />}
        variant="primary"
        csx={[
          buttonStyles,
          {
            width: '150px',
            borderRadius: isMyOrganizationsPage ? '10px' : '0px 10px 10px 0px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        ]}
        onClick={() => {
          setIsActive(prev => !prev);
          onClick();
        }}>
        <span
          className="userName"
          css={{
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            overflow: 'hidden',
          }}>
          {userName}
        </span>
        <Box className="userDropdownChevronIcon">
          <Icon
            name="MdChevronRight"
            csx={{
              rotate: isActive ? '-90deg' : '90deg',
              marginLeft: '2px',
              marginTop: '3px',
            }}
            color="textWhite"
          />
        </Box>
      </Button>
    </ToolTip>
  );
};

export default UserDropdown;
