import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import Devices from '@app/components/LoggedIn/Settings/DevicesAndLicenses/Devices';
import Licenses from '@app/components/LoggedIn/Settings/DevicesAndLicenses/Licenses';
import Tabs from '@app/components/common/Tabs';
import { IActiveTab, ITab } from '@app/components/common/Tabs/types';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsApp, actionCreatorsSettingsWeb } from '@app/state';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TABS_DATA: ITab[] = [
  {
    id: 1,
    name: 'loggedIn.settingsModule.devicesAndLicenses.tabs.devices.devices',
  },
  {
    id: 2,
    name: 'loggedIn.settingsModule.devicesAndLicenses.tabs.licenses',
  },
];

const DevicesAndLicenses = () => {
  // Redux
  const dispatch = useAppDispatch();
  const { checkForSettingsChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    dispatch,
  );
  const replaceMasterWithLocation = bindActionCreators(
    actionCreatorsApp.replaceMasterWithLocation,
    dispatch,
  );
  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  // Local state
  const [activeTab, setSelectedTab] = useState<IActiveTab | undefined>();

  useEffect(() => {
    setSelectedTab({ tabId: 1, subTabId: null });
  }, []);

  useEffect(() => {
    if (isMasterMode) {
      replaceMasterWithLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMasterMode]);

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    checkForSettingsChangesAndNavigateWeb(
      () => {
        setSelectedTab({ tabId, subTabId });
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  return (
    <>
      <Tabs
        data={TABS_DATA}
        activeTab={activeTab}
        onSelectedTabChange={handleOnSelectedTabChange}
        csx={{ zIndex: 1 }}
      />
      {!isMasterMode && (
        <LayoutContent noPadding>
          {activeTab?.tabId === 1 ? (
            <Devices mainActiveTab={activeTab} />
          ) : activeTab?.tabId === 2 ? (
            <Licenses />
          ) : null}
        </LayoutContent>
      )}
    </>
  );
};

export default DevicesAndLicenses;
