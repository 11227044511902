import Card from '@app/components/common/Card';
import Typography from '@app/components/common/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '@app/components/common/Table';
import Switch from '@app/components/common/Switch';
import Input from '@app/components/common/Input';
import { ETableModes } from '@app/components/common/Table/types';

const TablesExample = () => {
  const columnHelper = createColumnHelper<{
    id: number;
    name: string;
    lastName: string;
    phoneNumber: string;
  }>();

  const columnHelperCustom = createColumnHelper<{
    id: number;
    name: string;
    lastName: string;
    active: boolean;
  }>();
  const DATA = [
    { id: 1, name: 'John', lastName: 'Deer', phoneNumber: '1234567890' },
    { id: 2, name: 'Jane', lastName: 'Doe', phoneNumber: '1234567890' },
    { id: 3, name: 'Maria', lastName: 'Smith', phoneNumber: '1234567890' },
  ];
  const MORE_DATA = [
    { id: 1, name: 'John', lastName: 'Deer', phoneNumber: '1234567890' },
    { id: 2, name: 'Jane', lastName: 'Doe', phoneNumber: '1234567890' },
    { id: 3, name: 'Maria', lastName: 'Smith', phoneNumber: '1234567890' },
    { id: 4, name: 'Jose', lastName: 'Morales', phoneNumber: '1234567890' },
    {
      id: 5,
      name: 'Leonardo',
      lastName: 'Gutierrez',
      phoneNumber: '1234567890',
    },
    { id: 6, name: 'Miguel', lastName: 'Lopez', phoneNumber: '1234567890' },
    {
      id: 7,
      name: 'Ricardo',
      lastName: 'Rodriguez',
      phoneNumber: '1234567890',
    },
    { id: 8, name: 'Juan', lastName: 'Perez', phoneNumber: '1234567890' },
    { id: 9, name: 'Luis', lastName: 'Garcia', phoneNumber: '1234567890' },
  ];

  const DATA_CUSTOM = [
    { id: 1, name: 'John', lastName: 'Deer', active: true },
    { id: 2, name: 'Jane', lastName: 'Doe', active: false },
    { id: 3, name: 'Maria', lastName: 'Smith', active: true },
  ];

  const COLUMN_VALUES = [
    columnHelper.accessor('id', {
      header: 'Id',
      cell: info => info.getValue(),
      size: 50,
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      size: 250,
    }),
    columnHelper.accessor('lastName', {
      header: 'Last Name',
      cell: info => info.getValue(),
      size: 250,
    }),
    columnHelper.accessor('phoneNumber', {
      header: 'Phone Number',
      cell: info => info.getValue(),
      size: 250,
    }),
  ];

  const COLUMN_VALUES_WITH_AZ_SORT = [
    columnHelper.accessor('id', {
      header: 'Id',
      cell: info => info.getValue(),
      size: 70,
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      size: 250,
    }),
    columnHelper.accessor('lastName', {
      header: 'Last Name',
      cell: info => info.getValue(),
      size: 250,
    }),
    columnHelper.accessor('phoneNumber', {
      header: 'Phone Number',
      cell: info => info.getValue(),
      size: 250,
    }),
  ];

  const COLUMN_CUSTOM = [
    columnHelperCustom.accessor('id', {
      header: 'Id',
      cell: info => info.getValue(),
      size: 50,
    }),
    columnHelperCustom.accessor('name', {
      header: 'Name',
      cell: info => <Input value={info.getValue()} readOnly />,
      size: 250,
    }),
    columnHelperCustom.accessor('lastName', {
      header: 'Last Name',
      cell: info => <Input value={info.getValue()} readOnly />,
      size: 250,
    }),
    columnHelperCustom.accessor('active', {
      header: 'Active',
      cell: info => <Switch checked={info.getValue()} />,
      size: 80,
    }),
  ];

  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Tables
      </Typography>
      <Typography variant="body" csx={{ marginBottom: '15px' }}>
        Simple Table
      </Typography>
      <Table data={DATA} columns={COLUMN_VALUES} />
      <Typography variant="body" csx={{ marginBlock: '15px' }}>
        Striped
      </Typography>
      <code css={{ marginBlock: '10px' }}>isStriped: true</code>
      <Table isStriped data={DATA} columns={COLUMN_VALUES} />
      <Typography variant="body" csx={{ marginBlock: '15px' }}>
        Column sorting
      </Typography>
      <code css={{ marginBlock: '10px' }}>
        enableAlphabeticalSorting: true, alwaysShowSortIcon: true
      </code>
      <Table
        enableAlphabeticalSorting
        alwaysShowSortIcon
        data={DATA}
        columns={COLUMN_VALUES_WITH_AZ_SORT}
        manualSorting={false}
      />
      <Typography variant="body" csx={{ marginBlock: '10px' }}>
        Sortable Table
      </Typography>
      <Table
        mode={ETableModes.SORT_VERTICALLY}
        data={MORE_DATA}
        columns={COLUMN_VALUES}
        bodyCsx={{ height: '250px' }}
      />
      <Typography variant="body" csx={{ marginBlock: '15px' }}>
        Sortable Table with elements
      </Typography>
      <Table
        mode={ETableModes.SORT_VERTICALLY}
        data={DATA_CUSTOM}
        columns={COLUMN_CUSTOM}
        cellCsx={{ height: '70px' }}
      />
      <Typography variant="body" csx={{ marginBlock: '15px' }}>
        Card style for mobile
      </Typography>
      <code css={{ marginBlock: '10px' }}>
        {' columnStyleForSmallerScreens: "card"'}
      </code>
      <Table
        styleForSmallerScreens="card"
        data={DATA_CUSTOM}
        columns={COLUMN_CUSTOM}
        cellCsx={{ height: '70px' }}
      />
    </Card>
  );
};

export default TablesExample;
