import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import {
  selectCurrentSettingsData,
  selectCurrentSettingsFinalDiffObject,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import {
  CoreRootState,
  ICustomizingReceiptDiff,
  IKitchenTicketDiffObject,
  IKitchenTicketPrepStationVersionDiff,
  IKitchenTicketSettings,
  IPrepStationToSelect,
  TChange,
  actionCreatorsSettingsChangeData,
  applyKitchenTicketChangesToPrepStations,
} from '@westondev/tableturn-core';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import RenderChange from '../common/RenderChange';
import ApplyChangesToOtherPrepStationsButton from './ApplyChangesToOtherPrepStationsButton';
import CustomizingReceiptDiffTable from './CustomizingReceiptDiffTable';
import MenuTypeVersionsButtons from '../../common/MenuTypeVersionsButtons';
import SelectPrepStationsToApplyChangesModal from './SelectPrepStationsToApplyChangesModal';
import { kitchenTicketDiffModalStyles } from './styles';

const KitchenTicketDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const finalDiffObject = useSelector(selectCurrentSettingsFinalDiffObject);

  const { setSettingsDiffObject } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const reduxChangeData = useSelector(
    selectCurrentSettingsData,
  ) as IKitchenTicketSettings;

  const [selectedPrepStationVersion, setSelectedPrepStationVersion] =
    useState(0);
  const [changeData, setChangeData] = useState(reduxChangeData);
  const [applyChangesModalActive, setApplyChangesModalActive] = useState(false);

  const { selectedColor, borderContainerColor } = useItemChangeColor();

  const formattedDifferences = finalDiffObject as IKitchenTicketDiffObject;

  useEffect(() => {
    setChangeData(reduxChangeData);
  }, [reduxChangeData]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    return <RenderChange change={change} formatValue={formatValue} />;
  };

  const renderCustomizingReceiptTable = (
    data: ICustomizingReceiptDiff[],
    field: 'ticketHeader' | 'ticketFooter' | 'ticketBody',
  ) => {
    return (
      <CustomizingReceiptDiffTable
        data={data}
        field={field}
        changeData={
          changeData?.prepStationVersions[
            formattedDifferences?.prepStationVersions?.[
              selectedPrepStationVersion
            ]?.id || 0
          ]
        }
        formatValue={formatValue}
      />
    );
  };

  const renderPrepStationVersionsButtons = (
    prepStationVersions: IKitchenTicketPrepStationVersionDiff[],
  ) => {
    return (
      <MenuTypeVersionsButtons
        menuTypeVersions={prepStationVersions}
        selectedMenuTypeVersion={selectedPrepStationVersion}
        onPress={setSelectedPrepStationVersion}
      />
    );
  };

  const handleApplyChanges = (prepStations: IPrepStationToSelect[]) => {
    const applyChangesObject = {
      currentPrepStation: selectedPrepStationVersion,
      prepStationsToApplyChanges: prepStations,
    };

    const newPrepStationVersions = applyKitchenTicketChangesToPrepStations(
      formattedDifferences.prepStationVersions,
      applyChangesObject,
      store.getState() as CoreRootState,
    );
    if (!newPrepStationVersions.prepStationVersions) return;

    const newFinalDiffObject: IKitchenTicketDiffObject = {
      ...formattedDifferences,
      prepStationVersions: newPrepStationVersions.prepStationVersions,
    };

    setSettingsDiffObject(newFinalDiffObject);
    setChangeData(newPrepStationVersions.changeData);
  };

  return (
    <>
      <SelectPrepStationsToApplyChangesModal
        isActive={applyChangesModalActive}
        setModalActive={() => setApplyChangesModalActive(false)}
        onApplyChanges={handleApplyChanges}
        prepStationId={
          formattedDifferences &&
          formattedDifferences.prepStationVersions.length > 0
            ? formattedDifferences.prepStationVersions[
                selectedPrepStationVersion
              ].id
            : 0
        }
      />
      {formattedDifferences &&
        !!formattedDifferences?.prepStationVersions.length && (
          <>
            <Box csx={{ display: 'flex' }}>
              {renderPrepStationVersionsButtons(
                formattedDifferences?.prepStationVersions,
              )}
            </Box>
            <Box
              csx={[
                kitchenTicketDiffModalStyles,
                {
                  backgroundColor: selectedColor,
                  border: `2px solid ${borderContainerColor}`,
                },
              ]}>
              <ApplyChangesToOtherPrepStationsButton
                onPress={() => setApplyChangesModalActive(true)}
              />
              {formattedDifferences.prepStationVersions[
                selectedPrepStationVersion
              ]?.ticketDisplay.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.diffModal.sectionTitles.settings.ticketDisplay',
                    )}
                    color="black"
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.prepStationVersions[
                      selectedPrepStationVersion
                    ]?.ticketDisplay.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`kitchenTicketSettings_ticketDisplay_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}
              {some(
                formattedDifferences.prepStationVersions[
                  selectedPrepStationVersion
                ]?.customizingReceipts,
                item => item.length > 0,
              ) && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.diffModal.sectionTitles.settings.customizingReceipts',
                    )}
                    color="black"
                  />
                  {formattedDifferences.prepStationVersions[
                    selectedPrepStationVersion
                  ]?.customizingReceipts.ticketHeader.length > 0 &&
                    renderCustomizingReceiptTable(
                      formattedDifferences.prepStationVersions[
                        selectedPrepStationVersion
                      ]?.customizingReceipts.ticketHeader,
                      'ticketHeader',
                    )}

                  {formattedDifferences.prepStationVersions[
                    selectedPrepStationVersion
                  ]?.customizingReceipts.ticketBody.length > 0 &&
                    renderCustomizingReceiptTable(
                      formattedDifferences.prepStationVersions[
                        selectedPrepStationVersion
                      ]?.customizingReceipts.ticketBody,
                      'ticketBody',
                    )}
                  {formattedDifferences.prepStationVersions[
                    selectedPrepStationVersion
                  ]?.customizingReceipts.ticketFooter.length > 0 &&
                    renderCustomizingReceiptTable(
                      formattedDifferences.prepStationVersions[
                        selectedPrepStationVersion
                      ]?.customizingReceipts.ticketFooter,
                      'ticketFooter',
                    )}
                </>
              )}
            </Box>
          </>
        )}
    </>
  );
};

export default KitchenTicketDiffModal;
