import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import Chip from '@app/components/common/Chip';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import { selectIsMenuMasterMode } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IFreshSheet,
  IFreshSheetItem,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { chipStyles } from './styles';
import { IItemFreshSheetTable, ROW_ID_PREFIX } from './types';

const SECTION_ID = SectionId.BASIC;

const columnHelper = createColumnHelper<IItemFreshSheetTable>();

const FreshSheetTable = () => {
  // Redux
  const { updateFreshSheet: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const freshSheetData = useRootSelector(
    state => state.menu.changeFreshSheetData.data,
  );
  const actualFreshSheet = useRootSelector(
    state => state.menu.changeFreshSheetData.originalData,
  );
  const isMasterMode = useSelector(selectIsMenuMasterMode);

  // Local state
  const { t } = useTranslation();

  const freshSheetItemsArray = useMemo(() => {
    if (!freshSheetData || !actualFreshSheet) return [];
    return orderBy(
      Object.keys(freshSheetData.items || {}).map(key => ({
        id: Number(key),
        name: freshSheetData.items?.[Number(key)]?.name,
        onHandQuantity: freshSheetData.items?.[Number(key)]?.currentAmount,
        is86ed: freshSheetData.items?.[Number(key)]?.is86ed,
        delete: actualFreshSheet.items[Number(key)] ? false : true,
        sortPriority:
          freshSheetData.items?.[Number(key)]?.freshSheetSortPriority,
      })),
      'sortPriority',
    );
  }, [freshSheetData, actualFreshSheet]);

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('menuScreen.freshSheetDetails.items.name'),
        cell: info => (
          <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
            <Typography>{info.getValue()}</Typography>
            {info.row.original.is86ed && (
              <Chip csx={chipStyles} textColor="textWhite">
                86
              </Chip>
            )}
          </Box>
        ),
        minSize: 220,
      }),
      columnHelper.accessor('onHandQuantity', {
        header: t('menuScreen.freshSheetDetails.items.currentAmount'),
        cell: info => {
          return (
            <Input
              placeholder="Amount"
              type="number"
              value={info.getValue()}
              onChange={e => {
                const newValue = e.currentTarget.valueAsNumber;
                updateFreshSheetItem(
                  !newValue ? 0 : newValue,
                  info.row.original.id,
                  'currentAmount',
                );
              }}
            />
          );
        },
        minSize: 140,
      }),
      columnHelper.accessor('is86ed', {
        header: t('menuScreen.freshSheetDetails.items.is86ed'),
        cell: info => (
          <Checkbox
            key={`checkBox-is86ed-%${info.row.original.id}`}
            checked={info.getValue()}
            onChange={newValue =>
              updateFreshSheetItem(newValue, info.row.original.id, 'is86ed')
            }
          />
        ),
        minSize: 70,
      }),
      columnHelper.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            disabled={info.getValue()}
            icon={<Icon name="MdDeleteForever" />}
            onClick={() => handleDelete(info.row.original.id)}
          />
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  const updateFreshSheetItem = (
    newValue: any,
    id: number,
    field: keyof IFreshSheetItem,
  ) => {
    const currentFreshSheetData = store.getState().menu.changeFreshSheetData
      .data as IFreshSheet;
    const currentFreshSheetItem = currentFreshSheetData.items[id];
    setValue(
      {
        items: {
          ...currentFreshSheetData.items,
          [id]: {
            ...currentFreshSheetItem,
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number) => {
    const currentFreshSheetData = store.getState().menu.changeFreshSheetData
      .data as IFreshSheet;
    const currentFreshSheetItems = currentFreshSheetData?.items;

    const freshSheetItemsCloned = cloneDeep(currentFreshSheetItems);
    delete freshSheetItemsCloned[id];

    setValue(
      {
        items: freshSheetItemsCloned,
      },
      SECTION_ID,
    );
  };

  const handleOnSort = (newData: IItemFreshSheetTable[]) => {
    if (!freshSheetData) return;

    const clonedFreshSheetItems = cloneDeep(freshSheetData.items);
    newData.forEach((item, index) => {
      clonedFreshSheetItems[item.id].freshSheetSortPriority = index + 1;
    });
    setValue(
      {
        items: clonedFreshSheetItems,
      },
      SECTION_ID,
    );
  };

  return (
    <Table
      mode="sort-vertically"
      data={freshSheetItemsArray}
      cellCsx={{ height: '65px' }}
      columns={COLUMN_VALUES}
      onSortEnd={handleOnSort}
      alignHeaders={{
        is86ed: 'center',
        delete: 'center',
      }}
      align={{
        is86ed: 'center',
        delete: 'center',
      }}
      rowIdPrefix={ROW_ID_PREFIX}
    />
  );
};

export default FreshSheetTable;
