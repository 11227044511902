import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { TCsx } from '@emotion/react';
import { headerTitleStyles } from './styles';

interface IHeaderTitle {
  title: string;
  description?: string;
  options?: React.ReactNode;
  headerCsx?: TCsx;
}
const HeaderTitle = ({
  title,
  description,
  options,
  headerCsx,
}: IHeaderTitle) => {
  return (
    <Box csx={[headerTitleStyles, headerCsx]}>
      <Box>
        <Typography variant="heading">{title}</Typography>
        {description && (
          <Typography csx={{ marginTop: '5px' }}>{description}</Typography>
        )}
      </Box>
      {options}
    </Box>
  );
};

export default HeaderTitle;
