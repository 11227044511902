import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Typography from '@app/components/common/Typography';
import { upperCase } from 'lodash';
import { WithTranslation } from 'react-i18next';
import AssignedItemsCombosSection from './AssignedItemsCombosSection';
import AssignedMenuTypesSection from './AssignedMenuTypesSection';
import AssignedSubcategoriesSection from './AssignedSubcategoriesSection';

const AssociationsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  return (
    <AccordionSection
      isTitleUpperCase={false}
      title={
        <Box>
          <Typography>
            {upperCase(t('commonTexts.associated'))}
            {': '}
            <Typography
              csx={{
                fontStyle: 'italic',
                display: 'inline',
              }}>
              {t('menuScreen.categoryDetails.associations.title')}
            </Typography>
          </Typography>
        </Box>
      }
      {...props}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Card.SubCard>
          <AssignedSubcategoriesSection />
          <AssignedItemsCombosSection
            bucket="items"
            csx={{
              borderWidth: 0,
              padding: 0,
              borderRadius: 0,
            }}
            showSubcategory
          />
          <AssignedItemsCombosSection
            bucket="combos"
            csx={{ borderWidth: 0, paddingLeft: 0, paddingRight: 0 }}
            showSubcategory
          />
          <AssignedMenuTypesSection />
        </Card.SubCard>
      </Box>
    </AccordionSection>
  );
};

export default AssociationsSection;
