import Box from '@app/components/common/Box';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import {
  selectChangeMassUpdateData,
  selectItems,
  selectModifierGroups,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  IMassUpdate,
  SectionId,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export interface IRemoveModifierGroups extends WithTranslation {
  itemData: IMassUpdate;
}

const RemoveModifierGroups = ({ t, itemData }: IRemoveModifierGroups) => {
  const [additionModal, setAdditionModal] = useState(false);

  const modifierGroups = useSelector(selectModifierGroups);
  const changeMassUpdateData = useSelector(selectChangeMassUpdateData);
  const items = useSelector(selectItems);

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const handleAddModifierGroup = (modifiersSelected: IItemSelection[]) => {
    const ids: string[] = modifiersSelected.map(modifier =>
      String(modifier.id),
    );
    setValue(
      { removeModifierGroups: [...itemData.removeModifierGroups, ...ids] },
      SectionId.MODIFIER_GROUPS,
    );
    setAdditionModal(false);
  };

  const buttons: IItemCard[] = useMemo(() => {
    return itemData.removeModifierGroups?.map?.(id => ({
      id: Number(id),
      title: modifierGroups?.[Number(id)].name,
      isActive: false,
    }));
  }, [itemData.removeModifierGroups, modifierGroups]);

  const notAvailableModifierGroupsIds = useMemo(() => {
    const notRemoveIds = new Set<string>();
    changeMassUpdateData.bucketIds.forEach(id => {
      for (const menuType of changeMassUpdateData.selectionCriteria
        ?.menuTypeIds as number[]) {
        if (!(menuType in items[id].menuTypeVersions)) continue;
        const menuTypeVersion = items[id].menuTypeVersions[menuType];
        Object.keys(menuTypeVersion.modifierGroups || {}).forEach(
          modifierGroupId => {
            notRemoveIds.add(modifierGroupId);
          },
        );
      }
    });
    return Object.keys(modifierGroups || {}).filter(
      id => !notRemoveIds.has(id),
    );
  }, [
    items,
    changeMassUpdateData.bucketIds,
    modifierGroups,
    changeMassUpdateData.selectionCriteria,
  ]);

  const notSelectableModifierGroupsIds = useMemo(() => {
    const removedSelected = [...notAvailableModifierGroupsIds];
    for (const id of itemData.removeModifierGroups || []) {
      if (removedSelected.indexOf(id) === -1) removedSelected.push(id);
    }
    for (const key of Object.keys(itemData.modifierGroups || {})) {
      if (removedSelected.indexOf(key) === -1) removedSelected.push(key);
    }
    return removedSelected;
  }, [
    notAvailableModifierGroupsIds,
    itemData.removeModifierGroups,
    itemData.modifierGroups,
  ]);

  const onDisassociate = useCallback(
    (deletedModifierGroupId: number | string) => {
      setValue(
        {
          removeModifierGroups: itemData.removeModifierGroups.filter(
            modifierGroupId => modifierGroupId !== `${deletedModifierGroupId}`,
          ),
        },
        SectionId.MODIFIER_GROUPS,
      );
    },
    [itemData.removeModifierGroups, setValue],
  );

  return (
    <Box csx={{ marginBottom: 40 }}>
      <AssignedElements
        type="modifierGroups"
        detailsScreenProps={{
          currentRelationsIds: notSelectableModifierGroupsIds,
          wantedEntity: 'modifierGroups',
        }}
        noElementsMessage={t(
          'menuScreen.itemDetails.modifierGroupsSection.removeModifiersGroupSection.empty',
        )}
        active={additionModal}
        onAssociate={handleAddModifierGroup}
        onDisassociate={onDisassociate}
        onModalClose={() => setAdditionModal(false)}
        elements={buttons}
        subCardProps={{
          title: t(
            'menuScreen.itemDetails.modifierGroupsSection.removeModifiersGroupSection.title',
          ),
          subtitle: t(
            'menuScreen.itemDetails.modifierGroupsSection.removeModifiersGroupSection.description',
          ),
          actionOptions: [
            {
              text: t(
                'menuScreen.itemDetails.prepStationsSection.prepStations.actionButtons.addExistingStation',
              ),
              icon: false,
              handler: () => setAdditionModal(true),
            },
          ],
          csx: { borderWidth: 0, padding: 20 },
        }}
      />
    </Box>
  );
};

export default RemoveModifierGroups;
