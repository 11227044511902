import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import OptionsModal from '@app/components/common/OptionsModal';
import { actionCreatorsSettingsWeb } from '@app/state';
import { printersSelector } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { IWithPrepStationVersionId } from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IPrintTestTicket extends WithTranslation {
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

const PrintTestTicket = ({ t, prepStationVersionId }: IPrintTestTicket) => {
  const printers = useSelector(printersSelector);

  const [printerId, setPrinterId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { printTestKitchenTicketWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  useEffect(() => {
    if (!isModalOpen) {
      setPrinterId(0);
    }
  }, [isModalOpen]);

  const printerOptions = useMemo(
    () =>
      Object.values(printers).map(printer => ({
        label: printer.name,
        value: printer.id,
      })),
    [printers],
  );

  const closeModal = () => setIsModalOpen(false);

  const handleOnChange = (newPrinterId: number | null) => {
    setPrinterId(newPrinterId || 0);
    closeModal();
    if (newPrinterId) {
      printTestKitchenTicketWeb(newPrinterId, prepStationVersionId);
    }
  };

  return (
    <>
      <OptionsModal
        isActiveByDefault={isModalOpen}
        options={printerOptions}
        title={t(
          'loggedIn.settingsModule.kitchenTicketSettings.printTest.modal.title',
        )}
        value={printerId}
        onChange={handleOnChange}
        onOutsidePress={closeModal}
        onCancel={closeModal}
        hideFooterButtons
        hideTriggerButton
        disableSelectedStyle
      />
      <Button
        variant="primary"
        icon={<Icon name="MdPrint" />}
        onClick={() => setIsModalOpen(true)}>
        {t('loggedIn.settingsModule.kitchenTicketSettings.printButtonText')}
      </Button>
    </>
  );
};
export default PrintTestTicket;
