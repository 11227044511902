import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { EAccordionSectionOptionType } from '@app/components/common/Accordion/AccordionSection/types';
import ActionButtons from '@app/components/common/ActionButtons';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { modifierGroupsFactory } from '@app/helpers/factories/menu/cardFactories/modifierGroups/modifierGroupsFactory';
import { generateNewItemModifierGroup } from '@app/helpers/menu/buckets/items';
import {
  selectCurrentMenuType,
  selectModifierGroups,
  selectModifierItems,
} from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  FormMode,
  IItem,
  IMassUpdate,
  MenuTypes,
  actionCreatorsApp,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { cloneDeep, pickBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import SectionLayer from '../../../common/SectionLayer';
import { IItemDetailsSection } from '../types';
import AllModsSection from './AllModsSection';
import ModifierGroup from './ModifierGroup';
import RemoveModifierGroups from './RemoveModifierGroups';
import { SPACING } from './styles';

const ModifierGroupsSection = ({
  t,
  menuTypeVersionId,
  itemData,
  mode,
  sectionId,
  isDisabled,
  ...accordionSectionProps
}: IItemDetailsSection) => {
  // Redux
  const dispatch = useDispatch();
  const menuTypeMode = useSelector(selectCurrentMenuType);

  const currentMenuModifierGroups = useSelector(selectModifierGroups);
  const currentMenuModifierItems = useSelector(selectModifierItems);

  const theme = useTheme();

  const {
    updateItem: setValue,
    deleteModifierGroupFromItem,
    hideModifierGroup,
  } = bindActionCreators(actionCreatorsMenu, dispatch);

  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const { showToast } = bindActionCreators(actionCreatorsApp, dispatch);

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const modifierGroups = useMemo(
    () =>
      itemData?.modifierGroups && (menuTypeVersionId !== 0 || isMassUpdate)
        ? pickBy(itemData.modifierGroups, value => !value.isAllMod)
        : {},
    [itemData?.modifierGroups, menuTypeVersionId, isMassUpdate],
  );

  // Local state
  const [additionModal, setAdditionModal] = useState(false);

  const modifierGroupsFormatted = useMemo(
    () =>
      modifierGroupsFactory(modifierGroups).filter(
        modifierGroup => !modifierGroup.isAllMod,
      ),
    [modifierGroups],
  );

  const currentModifierGroupsIds = useMemo(() => {
    return Object.keys(modifierGroups);
  }, [modifierGroups]);

  const handleAddModifierGroup = (modifiersSelected: IItemSelection[]) => {
    if (modifiersSelected && modifiersSelected.length > 0) {
      try {
        const newModifierGroups = generateNewItemModifierGroup(
          modifiersSelected,
          modifierGroups,
          currentMenuModifierGroups,
          currentMenuModifierItems,
        );
        const currentModifiers = itemData?.modifierGroups;
        setValue(
          {
            modifierGroups: {
              ...currentModifiers,
              ...newModifierGroups,
            },
          },
          sectionId,
          menuTypeVersionId,
        );
      } catch (error) {
        showToast({
          title: 'Error',
          description: 'Error on Mapping Modifier Groups',
          isActive: true,
          type: 'error',
        });
      }
    }
    setAdditionModal(false);
  };

  const handleHideModifierGroup = (
    modifierGroupId: number,
    active: boolean,
  ) => {
    hideModifierGroup(menuTypeVersionId, modifierGroupId, sectionId, !active);

    if (active) {
      const clonedModifierGroups =
        mode !== FormMode.MASS_UPDATE
          ? cloneDeep(
              (store.getState().menu.changeData.data as IItem).menuTypeVersions[
                menuTypeVersionId
              ].modifierGroups,
            )
          : cloneDeep(
              (store.getState().menu.changeMassUpdateData.data as IMassUpdate)
                .modifierGroups,
            );

      setValue(
        {
          modifierGroups: clonedModifierGroups,
        },
        sectionId,
        menuTypeVersionId,
      );
    }
  };

  const handleDeleteModifierGroup = (
    modifierGroupId: number,
    name: string,
    active: boolean,
  ) => {
    const massUpdateDeleteModifier = () => {
      const newModifierGroups = {
        ...(itemData as IMassUpdate).modifierGroups,
      };
      delete newModifierGroups[modifierGroupId];
      setValue(
        {
          modifierGroups: newModifierGroups,
        },
        sectionId,
      );
    };

    menuTypeMode === MenuTypes.MASTER_MENU
      ? setShowConfirmationModal({
          active: true,
          title: t(
            'menuScreen.itemDetails.modifierGroupsSection.modifierGroup',
          ),
          type: 'delete',
          name,
          onSuccess: () => {
            if (mode !== FormMode.MASS_UPDATE) {
              deleteModifierGroupFromItem(
                menuTypeVersionId,
                modifierGroupId,
                sectionId,
              );
            } else {
              massUpdateDeleteModifier();
            }
          },
        })
      : mode !== FormMode.MASS_UPDATE
      ? handleHideModifierGroup(modifierGroupId, active)
      : massUpdateDeleteModifier();
  };

  return (
    <>
      <SelectionModal
        type="modifierGroups"
        detailsScreenProps={{
          currentRelationsIds: currentModifierGroupsIds,
          wantedEntity: 'modifierGroups',
        }}
        active={additionModal}
        onModalClose={() => setAdditionModal(false)}
        onAssociate={handleAddModifierGroup}
      />
      <AccordionSection
        title={t('menuScreen.itemDetails.modifierGroupsSection.modifierGroups')}
        expandedHeight="75px"
        disabledLayer={isDisabled && <NoMenuTypeLayer />}
        noPadding
        optionType={EAccordionSectionOptionType.ACTION_BUTTON}
        {...accordionSectionProps}>
        <Box
          csx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            margin: `0 ${SPACING} ${SPACING} ${SPACING}`,
          }}>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}>
            <DetailsSubtitle noMargin>
              {t(
                isMassUpdate
                  ? 'menuScreen.itemDetails.modifierGroupsSection.addModifiersGroupSection.title'
                  : 'menuScreen.itemDetails.modifierGroupsSection.itemModsSubsectionTitle',
              )}
            </DetailsSubtitle>
            <Typography variant="caption">
              {t(
                isMassUpdate
                  ? 'menuScreen.itemDetails.modifierGroupsSection.addModifiersGroupSection.description'
                  : 'menuScreen.itemDetails.modifierGroupsSection.description',
              )}
              {!isMassUpdate && (
                <>
                  {' ('}
                  <span
                    style={{
                      borderRadius: 100,
                      minWidth: '8px',
                      maxWidth: '8px',
                      minHeight: '8px',
                      maxHeight: '8px',
                      display: 'inline-block',
                      backgroundColor: theme.colors.persistentSemanticBlue,
                    }}></span>
                  &#41;.
                </>
              )}
            </Typography>
          </Box>

          <ActionButtons
            optionList={[
              {
                text: t(
                  'menuScreen.itemDetails.modifierGroupsSection.addModifierGroup',
                ),
                handler: () => setAdditionModal(true),
              },
            ]}
          />
        </Box>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            padding: `0 ${SPACING}`,
          }}>
          {modifierGroupsFormatted.length > 0 ? (
            modifierGroupsFormatted.map((modifierGroup, index) => (
              <ModifierGroup
                mode={mode}
                key={`modifier_group_${modifierGroup.modifierGroupId}`}
                onDeleteModifierGroupPress={(modifierGroupId, name) =>
                  handleDeleteModifierGroup(
                    modifierGroupId,
                    name,
                    modifierGroup.active && modifierGroup.inheritedActive,
                  )
                }
                menuTypeVersionId={menuTypeVersionId}
                isFirst={
                  modifierGroup.sortPriority ===
                  modifierGroupsFormatted[0].sortPriority
                }
                isLast={
                  modifierGroup.sortPriority ===
                  modifierGroupsFormatted[modifierGroupsFormatted.length - 1]
                    .sortPriority
                }
                prevModifierGroupId={
                  index > 0
                    ? modifierGroupsFormatted[index - 1].modifierGroupId
                    : -1
                }
                nextModifierGroupId={
                  index < Object.values(modifierGroups).length - 1
                    ? modifierGroupsFormatted[index + 1].modifierGroupId
                    : -1
                }
                menuTypeMode={menuTypeMode}
                handleHideModifierGroup={modifierGroupId =>
                  handleHideModifierGroup(modifierGroupId, modifierGroup.active)
                }
                {...modifierGroup}
              />
            ))
          ) : (
            <Card.SubCard
              csx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <SectionLayer
                title={
                  !isMassUpdate
                    ? t(
                        'menuScreen.itemDetails.modifierGroupsSection.noModifierGroupsAssignedToThisItem',
                      )
                    : t(
                        'menuScreen.itemDetails.modifierGroupsSection.addModifiersGroupSection.empty',
                      )
                }
              />
            </Card.SubCard>
          )}
        </Box>

        <Divider
          csx={{ margin: `20px ${SPACING}` }}
          lineCsx={{ borderColor: theme.colors.lightGrey }}
        />

        <AllModsSection
          itemData={itemData}
          sectionId={sectionId}
          menuTypeVersionId={menuTypeVersionId}
          mode={mode}
        />
        {isMassUpdate && (
          <Box
            csx={{
              paddingTop: 23,
              borderTop: `1px solid ${theme.colors.lightGrey}`,
            }}>
            <RemoveModifierGroups itemData={itemData as IMassUpdate} />
          </Box>
        )}
      </AccordionSection>
    </>
  );
};

export default ModifierGroupsSection;
