import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import {
  actionCreatorsCustomers,
  cardInfoTableFactory,
  CustomersSectionId,
  ICustomer,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';

import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Table from '@app/components/common/Table';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import {
  makeSelectCustomersData,
  selectCurrentCustomer,
} from '@app/state/customers/customersSelectors';

import Checkbox from '@app/components/common/Checkbox';
import Typography from '@app/components/common/Typography';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { columnHelper, ROW_ID_PREFIX } from './types';

interface ICardInfoSection extends IAccordionSection {}

const SECTION_ID = CustomersSectionId.CARD;

const getMemoizedCustomerData = makeSelectCustomersData(SECTION_ID);

const CardInfoSection = ({ ...accordionSectionProps }: ICardInfoSection) => {
  const { t } = useTranslation();

  const { updateCustomer: setValue } = bindActionCreators(
    actionCreatorsCustomers,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedCustomerData);

  const cardsArray = useMemo(
    () => cardInfoTableFactory(data?.cards),
    [data?.cards],
  );

  const setDefaultCard = (id: number) => {
    const cards = cloneDeep(
      (selectCurrentCustomer(store.getState()) as ICustomer)?.cards || {},
    );
    Object.keys(cards)
      .map(Number)
      .forEach(key => {
        cards[key].isDefault = id === key;
      });

    setValue({ cards }, SECTION_ID);
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('isDefault', {
        header: t('customersScreen.addressInfo.table.isDefault'),
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Checkbox
              checked={info.getValue()}
              onChange={() => setDefaultCard(info.row.original.id)}
            />
          </Box>
        ),
        size: 60,
      }),
      columnHelper.accessor('lastFourDigits', {
        header: t('customersScreen.cardInfo.table.lastFourDigits'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 200,
      }),
      columnHelper.accessor('cardType', {
        header: t('customersScreen.cardInfo.table.cardType'),
        cell: info => (
          <Typography>
            {t(`customersScreen.cardInfo.cardType.${info.getValue()}`)}
          </Typography>
        ),
        minSize: 150,
      }),
      columnHelper.accessor('dateOfLastUsed', {
        header: t('customersScreen.cardInfo.table.dateOfLastUsed'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <>
      <AccordionSection
        title={t('customersScreen.cardInfo.title')}
        titleTextContainerCsx={{ minWidth: '150px' }}
        {...accordionSectionProps}>
        {cardsArray.length > 0 ? (
          <Box
            csx={{
              height: '100%',
            }}>
            <Table
              scrollEnabled
              nestedScrollEnabled
              data={cardsArray}
              columns={COLUMN_VALUES}
              cellCsx={{
                height: '70px',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              rowIdPrefix={ROW_ID_PREFIX}
            />
          </Box>
        ) : (
          <SectionLayer
            title={t('customersScreen.cardInfo.emptyDataText')}
            csx={{
              justifyContent: 'center',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          />
        )}
      </AccordionSection>
    </>
  );
};

export default CardInfoSection;
