import { WithTranslation } from 'react-i18next';

export interface IOrgInviteData {
  userId: number;
  organizationName: string;
  organizationId: number;
  digitalLogoUrl: string;
}

export interface IOrgInviteProps extends WithTranslation, IOrgInviteData {}

export const INITIAL_FORM_DATA = {
  password: '',
  confirmPassword: '',
};

export enum EOrgInviteSteps {
  PASSWORD = 0,
  PHONE_NUMBER = 1,
}
