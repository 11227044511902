import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { useTheme } from '@emotion/react';
import {
  CoreRootState,
  TAssociationDiff,
  TChange,
  diffUserDetailsFactory,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import RenderChange from '../common/RenderChange';
import Grid from '@app/components/common/Grid';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import { WithTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { store } from '@app/state/store';

const UserSettingsDiffModal = ({ t }: WithTranslation) => {
  const theme = useTheme();

  // Redux
  const differences = useSelector(settingsDifferencesSelector);
  const formattedDifferences = useMemo(() => {
    const diffObject = diffUserDetailsFactory(
      differences,
      store.getState() as unknown as CoreRootState,
    );
    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderArrayChange = (changes: TAssociationDiff[], title: string) => {
    return (
      <>
        <HeaderDiffModal name="Association" color="black" />
        <RenderChange
          change={{
            field: title,
            label: title,
            value: changes.map(change => ({
              ...change,
              value: change.label || '',
            })),
          }}
          formatValue={formatValue}
          colorFields={['backgroundColor', 'textColor']}
          defaultColor={{
            backgroundColor: theme.colors.cardWhite,
            textColor: theme.colors.black,
          }}
        />
      </>
    );
  };

  const renderChange = (change: TChange) => {
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={['backgroundColor', 'textColor']}
        defaultColor={{
          backgroundColor: theme.colors.cardWhite,
          textColor: theme.colors.black,
        }}
      />
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_basic_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.associations.locations.length > 0 &&
            renderArrayChange(
              formattedDifferences.associations.locations,
              t('app.modals.userDetailsDiffModal.associations.locations'),
            )}

          {formattedDifferences.associations.roles.length > 0 &&
            renderArrayChange(
              formattedDifferences.associations.roles,
              t('app.modals.userDetailsDiffModal.associations.roles'),
            )}
        </>
      )}
    </>
  );
};

export default UserSettingsDiffModal;
