export enum RadioSelectionStates {
  EMPTY = 'EMPTY',
  SOME = 'SOME',
  FULL = 'FULL',
}

export enum PriceName {
  ALL,
  ITEM_PRICE,
  ONLINE_PRICE,
  DELIVERY_PRICE,
}

export enum UpdateType {
  NONE,
  PERCENT,
  FIXED,
  SET,
}

export enum OperationType {
  INCREASE,
  DECREASE,
}

export const ICONS_BY_STATE = {
  [RadioSelectionStates.EMPTY]: 'circle-outline',
  [RadioSelectionStates.FULL]: 'circle',
  [RadioSelectionStates.SOME]: 'circle-half-full',
};

export const PRICES_TYPES_NAMES: Record<PriceName, TPricesNames> = {
  [PriceName.ALL]: 'all',
  [PriceName.ITEM_PRICE]: 'itemPrice',
  [PriceName.ONLINE_PRICE]: 'onlinePrice',
  [PriceName.DELIVERY_PRICE]: 'deliveryPrice',
};

export type TPricesNames =
  | 'itemPrice'
  | 'comboBasePrice'
  | 'onlinePrice'
  | 'deliveryPrice'
  | 'all';

export const BASE_TRANSLATION = 'menuScreen.itemDetails.modalPricingList';
export const COMBO_BASE_TRANSLATION = 'menuScreen.comboDetails.pricingSection';
