import {
  CreateSettingsBucketRequestType,
  FormMode,
  IPrinterDiff,
  ITablet,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import GridItem from '@app/components/common/Grid/GridItem';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import { editSettingsElementWeb } from '@app/state/settings/settingsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

interface IEditTabletModal extends WithTranslation {
  active?: boolean;
  isEdit?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  onDeactivate?: () => void;
  tabletData?: Partial<ITablet> | null;
}

const EditTabletModal = ({
  t,
  active = false,
  onCancel = () => null,
  onSuccess = () => null,
  onDeactivate = () => null,
  tabletData,
  isEdit = false,
}: IEditTabletModal) => {
  const editSettingsElement = bindActionCreators(
    editSettingsElementWeb,
    useDispatch(),
  );

  const [formData, setFormData] = useState({
    name: tabletData?.name || '',
    androidId: tabletData?.androidId || '',
  });

  useEffect(() => {
    setFormData({
      name: tabletData?.name || '',
      androidId: tabletData?.androidId || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const handleOnSuccess = async () => {
    const deviceChanges = [
      {
        field: 'name',
        label: t('commonTexts.name'),
        value: formData.name.trim(),
      },
      {
        field: 'androidId',
        label: t('settingsModule.devices_licenses.tabletsTable.androidId'),
        value: formData.androidId.trim(),
      },
    ];

    const request: CreateSettingsBucketRequestType = {
      settingsSelected: 'devices',
      id: tabletData?.id || 0,
      data: {},
      diffLocationIds: [],
      diff: {
        id: tabletData?.id || 0,
        changes: deviceChanges,
        action: isEdit ? 'update' : 'add',
      } as IPrinterDiff,
      successMessage: isEdit
        ? t('settingsModule.devices_licenses.editTabletModal.success')
        : t('settingsModule.devices_licenses.editTabletModal.successAdd'),
    };
    onCancel();

    await editSettingsElement(
      () => null,
      () => null,
      false,
      request,
      FormMode.UPDATE,
    );

    onSuccess();
  };
  const handleOnDeactivate = async () => {
    onCancel();
    onDeactivate();
  };

  const handleOnChange = (
    field: keyof ITablet,
    value: string | number | null,
  ) => {
    setFormData({ ...formData, [field]: value });
  };

  const enableSave = () => {
    return formData.name.trim().length > 0 &&
      formData.androidId.trim().length > 0
      ? false
      : true;
  };
  return (
    <Modal
      size={700}
      isActive={active}
      title={t(
        `settingsModule.devices_licenses.editTabletModal.${
          isEdit ? 'title' : 'titleAdd'
        }`,
      )}
      onModalClose={onCancel}
      footer={
        <Box
          csx={{
            gap: '10px',
            display: 'flex',
          }}>
          <Button onClick={onCancel} csx={{ width: '120px' }}>
            {t('commonButtons.cancel')}
          </Button>
          {isEdit && (
            <Button
              variant="danger"
              onClick={handleOnDeactivate}
              disabled={tabletData?.status !== 'active' || !tabletData?.license}
              csx={{ width: '120px' }}>
              {t('settingsModule.devices_licenses.tabletsTable.deactivate')}
            </Button>
          )}
          <Button
            variant="primary"
            disabled={enableSave()}
            onClick={() => handleOnSuccess()}
            csx={{ width: '120px' }}>
            {t('commonButtons.save')}
          </Button>
        </Box>
      }>
      <Grid columnGap={30} rowGap={30}>
        <GridItem>
          <Input
            value={formData.name}
            label={t('commonTexts.name')}
            placeholder={t('commonTexts.name')}
            onChange={_name => handleOnChange('name', _name.target.value)}
          />
        </GridItem>
        <GridItem>
          <Input
            value={formData.androidId}
            label={t('settingsModule.devices_licenses.tabletsTable.androidId')}
            placeholder={t(
              'settingsModule.devices_licenses.tabletsTable.androidId',
            )}
            onChange={_androidId =>
              handleOnChange('androidId', _androidId.target.value)
            }
          />
        </GridItem>
      </Grid>
    </Modal>
  );
};

export default EditTabletModal;
