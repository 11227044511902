import '@app/i18n.config.ts';
import { Global } from '@emotion/react';
import { LazyMotion } from 'framer-motion';
import 'intl-pluralrules';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ListenerComponent from './components/ListenerComponent/ListenerComponent.tsx';
import RefreshBucketToast from './components/LoggedIn/RefreshBucketToast/RefreshBucketToast.tsx';
import ConfirmationModal from './components/common/ConfirmationModal';
import LoadingModal from './components/common/LoadingModal/LoadingModal.tsx';
import './index.css';
import router from './router/router.tsx';
import { store } from './state/store.ts';
import ThemeProvider from './theme/ThemeProvider.tsx';
import { globalStyles } from './theme/commonStyles.ts';
import Toast from './components/common/Toast/Toast.tsx';

const loadFeatures = () =>
  import('@app/motion/features.ts').then(res => res.default);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <ThemeProvider>
      <ListenerComponent store={store}>
        <Global styles={globalStyles} />
        <LazyMotion features={loadFeatures} strict>
          <LoadingModal />
          <RouterProvider router={router} />
          <ConfirmationModal />
          <RefreshBucketToast />
          <Toast />
        </LazyMotion>
      </ListenerComponent>
    </ThemeProvider>
  </Provider>,
);
