import { Theme, css } from '@emotion/react';

export const buttonCategoryStyles = (
  theme: Theme,
  isActive: boolean,
  isLastElement: boolean,
  backgroundColor: string | null,
) => {
  return css({
    backgroundColor: isActive
      ? backgroundColor || theme.colors.semanticBlue
      : theme.colors.white,
    borderRadius: '5px',
    width: '120px',
    height: '80px',
    border: `${isActive ? '0px' : '1px'} solid ${
      backgroundColor || theme.colors.persistentSemanticBlue
    }`,
    cursor: 'pointer',
    borderRightWidth: isActive ? 0 : '10px',
    marginBottom: isLastElement ? 0 : '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
};
