import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import { menuComboCardFactory } from '@app/helpers/factories/menu/cardFactories/combos/menuComboCardFactory';
import { menuItemCardFactory } from '@app/helpers/factories/menu/cardFactories/items/menuItemCardFactory';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectCombos, selectItems } from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IComboCard,
  IItemCard,
  IMenuStore,
  IReportingGroup,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { pickBy } from 'lodash';
import without from 'lodash/without';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IElementsSection extends WithTranslation {
  csx?: TCsx;
  field: 'itemIds' | 'comboIds';
  data: IReportingGroup;
}

const SECTION_ID = SectionId.ASSOCIATION;

const SCREENS: Record<
  IElementsSection['field'],
  '/menu/items' | '/menu/combos'
> = {
  itemIds: '/menu/items',
  comboIds: '/menu/combos',
};

const FACTORIES: Record<
  IElementsSection['field'],
  | ((elements: IMenuStore['items']) => IItemCard[])
  | ((elements: IMenuStore['combos']) => IComboCard[])
> = {
  itemIds: menuItemCardFactory,
  comboIds: menuComboCardFactory,
};

const ElementsSection = ({ csx, field, data, t }: IElementsSection) => {
  // Redux
  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);

  const bucket = field === 'itemIds' ? 'items' : 'combos';
  const isItem = bucket === 'items';

  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedElements = data[field];

  const assignedElementsString = useMemo(
    () => assignedElements.map(String),
    [assignedElements],
  );

  const assignedItemsCardList = useMemo(() => {
    const elementsBucket = field === 'itemIds' ? items : combos;

    const filteredBucketElements = pickBy(elementsBucket, (_, id) =>
      assignedElements.includes(Number(id)),
    );
    return FACTORIES[field](
      filteredBucketElements as IMenuStore['combos'] & IMenuStore['items'],
    ) as IItemCard[];
  }, [assignedElements, combos, items, field]);

  const closeModal = () => setOpenSelectionModal(false);

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const newElementIds = [
      ...assignedElements,
      ...buttons.map(button => Number(button.id)),
    ];
    setValue<IReportingGroup>({ [field]: newElementIds }, SECTION_ID);
    closeModal();
  };

  const onDisassociate = (id: number) => {
    const newElementIds = without(assignedElements, id);

    setValue<IReportingGroup>({ [field]: newElementIds }, SECTION_ID);
  };

  return (
    <AssignedElements
      type={bucket}
      detailsScreenProps={{
        currentRelationsIds: assignedElementsString,
        wantedEntity: bucket,
      }}
      noElementsMessage={t(
        `menuScreen.reportingGroupDetails.associations.${bucket}.emptyCardsMessage`,
      )}
      active={openSelectionModal}
      onAssociate={handleOnAssociate}
      onModalClose={closeModal}
      elements={assignedItemsCardList}
      subCardProps={{
        title: t(`menuScreen.common.bucket.${bucket}.plural`),
        subtitle: t(
          `menuScreen.reportingGroupDetails.associations.${bucket}.description`,
        ),

        onEditMode: !assignedItemsCardList.length ? undefined : setEditMode,
        actionOptions: [
          {
            text: t(
              `menuScreen.menuLocation.actionButtons.${
                isItem ? 'addNewItem' : 'addNewCombo'
              }`,
            ),
            icon: true,
            handler: () =>
              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.ADD,
                    text: t(
                      `menuScreen.menuLocation.actionButtons.${
                        isItem ? 'addNewItemTag' : 'addNewComboTag'
                      }`,
                    ),
                    onPress: () => {
                      navigate(`${SCREENS[field]}/add`);
                    },
                    pathURL: `${SCREENS[field]}/add`,
                  }),
                onRefresh,
                true,
                false,
                bucket,
              ),
          },
          {
            text: t(
              `menuScreen.menuLocation.actionButtons.${
                isItem ? 'addExistingItem' : 'addExistingCombo'
              }`,
            ),
            icon: false,
            handler: () => setOpenSelectionModal(true),
          },
        ],
        csx,
      }}
      renderItem={item => {
        const pathURL = `${SCREENS[field]}/${item.id}`;
        return (
          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Card.Item
              csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
              {...item}
              onRemoveClick={() => onDisassociate(Number(item.id))}
              showRemoveIcon={isEditMode}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                if (isEditMode) {
                  item?.onClick?.(
                    item.id as unknown as any, //FIXME: The onClick expects a MouseEvent type incorrectly
                  );
                  return;
                }
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: item.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          </Box>
        );
      }}
    />
  );
};

export default ElementsSection;
