import { Theme, css } from '@emotion/react';

export const listStyles = (theme: Theme) =>
  css({
    '.title': {
      marginLeft: '20px',
      marginBottom: '20px',
      color: theme.colors.darkGrey,
      fontWeight: theme.fontWeights.regular,
    },
  });
