import {
  ElementTypes,
  IAddServiceAreaData,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import {
  selectLocationId,
  selectOrganizationId,
} from '../selectors/appSelectors';
import { AppThunk } from '../store';
import cloneDeep from 'lodash/cloneDeep';
import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import { selectSelectedLayout } from '../selectors/settingsSelectors';
import { selectSelectedElement } from '../selectors/tableLayoutSelectors';
import { setShowToast } from '../app/actions';
import i18n from '../../i18n.config';

export const addServiceAreaWeb =
  (
    { name, revenueCenterId, isActive }: IAddServiceAreaData,
    onContinue: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsTableLayout.addServiceArea(
        { name, revenueCenterId, isActive },
        organizationId,
        locationId,
        onContinue,
      ) as unknown as AppThunk<void>,
    );
  };

export const updateLastServiceAreasOrderWeb = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const serviceAreas = getState().tableLayout.currentEditData.serviceAreas;
    const lastServiceAreasOrder =
      getState().tableLayout.currentEditLayout.lastServiceAreasOrder;
    const newServicesAreas = cloneDeep(serviceAreas);

    if (lastServiceAreasOrder) {
      lastServiceAreasOrder.map(serviceArea => {
        newServicesAreas[serviceArea.id].sortPriority =
          serviceArea.sortPriority;
      });

      dispatch(actionCreatorsTableLayout.updateServiceAreas(newServicesAreas));
      dispatch(actionCreatorsTableLayout.setLastServiceAreasOrder(null));
    }
  };
};

export const checkForLayoutChangesWeb = (
  onContinue: () => void,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsTableLayout.checkForLayoutChanges(
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
        onContinue,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const saveAllChangesWeb = (onSuccess?: () => void): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsTableLayout.saveAllChanges(
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
        onSuccess ? onSuccess : () => null,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const initializeTableLayoutOnRefreshWeb = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const serviceAreas = getState().tableLayout.savedData.serviceAreas;
    dispatch(actionCreatorsTableLayout.initializeLayout());
    if (Object.keys(serviceAreas).length > 0)
      dispatch(
        actionCreatorsTableLayout.setServiceArea(
          getState().tableLayout.savedData.serviceAreas[0],
        ),
      );
  };
};

export const checkIfTableNameHasError = (): AppThunk<Promise<boolean>> => {
  return async (dispatch, getState) => {
    const selectedElement = selectSelectedElement(getState());
    const selectedLayout = selectSelectedLayout(getState());

    try {
      if (selectedElement && selectedLayout) {
        const elementData = selectedLayout[selectedElement.id];
        if (
          elementData.name.length === 0 &&
          elementData.type === ElementTypes.TABLE
        ) {
          dispatch(
            setShowToast({
              title: i18n.t('validations.notEmpty').toLocaleUpperCase(),
              description: i18n.t('validations.nameRequired'),
              type: 'error',
            }),
          );
          return true;
        } else {
          return false;
        }
      } else false;
    } catch (error) {
      return true;
    }
    return false;
  };
};
