import Box from '@app/components/common/Box';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@app/components/common/Button';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/state/store';
import { useTranslation } from 'react-i18next';
import useElementTypes from '@app/hooks/useElementTypes';
import {
  ElementTypes,
  Shapes,
  TElementType,
  TShape,
  TableStatuses,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { useTheme } from '@emotion/react';
import { DEFAULT_ELEMENT_SIZES } from '../TableLayoutCreator/types';
import { IBlueDot, TDotPosition } from './types';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { useControls } from 'react-zoom-pan-pinch';
import { bottomSheetHeaderStyles, newElementContainer } from './styles';
import useOutsideClick from '@app/hooks/useOutsideClick';
import { AnimatePresence } from 'framer-motion';
import { AnimatedOpacityContainer } from '../TableLayoutSideBar/TableLayoutSideBar';

const BottomSheet = () => {
  // Redux
  const serviceAreas = useSelector(
    (state: RootState) => state.tableLayout.currentEditData.serviceAreas,
  );
  const isBottomSheetOpen = useSelector(
    (state: RootState) => state.tableLayout.isBottomSheetOpen,
  );

  const { addElement, setIsBottomSheetOpen } = bindActionCreators(
    actionCreatorsTableLayout,
    useDispatch(),
  );

  // Local state
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  useOutsideClick([bottomSheetRef], true, () => {
    setIsBottomSheetOpen(false);
    setTab(null);
  });
  const ELEMENT_TYPES = useElementTypes({
    color: 'semanticBlue',
    size: '24px',
  });

  const { zoomToElement } = useControls();
  const { t } = useTranslation();
  const theme = useTheme();
  const [tab, setTab] = useState<'new-table' | 'new-element' | null>(null);

  useEffect(() => {
    if (!tab && isBottomSheetOpen) setTab('new-table');
  }, [isBottomSheetOpen, tab]);

  const iconColor = theme.colors.semanticBlue;

  const handleCreateTable = (shape: TShape) => {
    const width =
      shape === Shapes.RECTANGLE
        ? DEFAULT_ELEMENT_SIZES['table-rectangle'].width
        : DEFAULT_ELEMENT_SIZES.table.width;
    const height =
      shape === Shapes.RECTANGLE
        ? DEFAULT_ELEMENT_SIZES['table-rectangle'].height
        : DEFAULT_ELEMENT_SIZES.table.height;

    addElement(
      {
        id: '0',
        name: 'Table',
        seats: 1,
        type: 'table',
        shape,
        xCoordinate: 700,
        yCoordinate: 300,
        rotation: 0,
        width,
        height,
        tableId: null,
        status: TableStatuses.AVAILABLE,
        mergedTableId: null,
        customElementName: '',
        serviceAreaId: 0,
      },
      newId =>
        setTimeout(() => {
          zoomToElement(`${newId}-draggable`, 1, 150);
        }, 100),
    );
  };

  const handleCreateObject = (shape: TShape, type: TElementType) => {
    addElement(
      {
        id: '0',
        name: '',
        seats: 0,
        type,
        shape,
        xCoordinate: 700,
        yCoordinate: 300,
        width: DEFAULT_ELEMENT_SIZES.object.width,
        height: DEFAULT_ELEMENT_SIZES.object.height,
        rotation: 0,
        status: TableStatuses.AVAILABLE,
        tableId: null,
        mergedTableId: null,
        customElementName: '',
        serviceAreaId: 0,
      },
      newId =>
        setTimeout(() => {
          zoomToElement(`${newId}-draggable`, 1, 150);
        }, 100),
    );
  };

  const handleCreateWall = () => {
    addElement(
      {
        id: '0',
        name: 'Wall',
        seats: 0,
        type: 'wall',
        shape: 'rectangle',
        xCoordinate: 700,
        yCoordinate: 300,
        width: DEFAULT_ELEMENT_SIZES.wall.width,
        height: DEFAULT_ELEMENT_SIZES.wall.height,
        rotation: 0,
        status: TableStatuses.AVAILABLE,
        tableId: null,
        mergedTableId: null,
        customElementName: '',
        serviceAreaId: 0,
      },
      newId =>
        setTimeout(() => {
          zoomToElement(`${newId}-draggable`, 1, 150);
        }, 100),
    );
  };

  const handleCreateFreeForm = () => {
    addElement(
      {
        id: '0',
        name: 'Custom',
        seats: 0,
        type: 'custom',
        shape: 'custom',
        xCoordinate: 700,
        yCoordinate: 300,
        width: DEFAULT_ELEMENT_SIZES.custom.width,
        height: DEFAULT_ELEMENT_SIZES.custom.height,
        rotation: 0,
        status: TableStatuses.AVAILABLE,
        tableId: null,
        mergedTableId: null,
        customElementName: 'Custom',
        serviceAreaId: 0,
      },
      newId =>
        setTimeout(() => {
          zoomToElement(`${newId}-draggable`, 1, 150);
        }, 100),
    );
  };

  const TABLE_SHAPES = [
    {
      type: 'circle',
      name: t(
        'settingsModule.tableLayoutCreator.bottomSheet.tableShapes.circle',
      ),
      shapeStyles: {
        width: 30,
        height: 30,
        borderRadius: 100,
      },
      callBack: () => handleCreateTable(Shapes.CIRCLE),
    },
    {
      type: 'square',
      name: t(
        'settingsModule.tableLayoutCreator.bottomSheet.tableShapes.square',
      ),
      shapeStyles: {
        width: 30,
        height: 30,
        borderRadius: 5,
      },
      callBack: () => handleCreateTable(Shapes.SQUARE),
    },
    {
      type: 'rectangle',
      name: t(
        'settingsModule.tableLayoutCreator.bottomSheet.tableShapes.rectangle',
      ),
      shapeStyles: {
        width: 60,
        height: 30,
        borderRadius: 5,
      },
      callBack: () => handleCreateTable(Shapes.RECTANGLE),
    },
  ];

  const BARRIER_TYPES = {
    [ElementTypes.WALL]: {
      type: ElementTypes.WALL,
      name: t('settingsModule.tableLayoutCreator.bottomSheet.elements.wall'),
      icon: (
        <Box
          csx={{
            width: '100px',
            height: '15px',
            backgroundColor: 'white',
            border: `2px solid ${theme.colors.semanticBlue}`,
            borderRadius: '5px',
          }}
        />
      ),
    },
    [ElementTypes.CUSTOM]: {
      type: ElementTypes.CUSTOM,
      name: t('settingsModule.tableLayoutCreator.bottomSheet.elements.custom'),
      icon: (
        <Box
          csx={{
            width: '80px',
            height: '20px',
            backgroundColor: 'white',
            borderRadius: '5px',
            border: `2px dashed ${theme.colors.semanticBlue}`,
            backgroundClip: 'white',
            position: 'relative',
          }}>
          <BlueDot position="top-left" />
          <BlueDot position="top-right" />
          <BlueDot position="bottom-left" />
          <BlueDot position="bottom-right" />
        </Box>
      ),
    },
  };

  return (
    <AnimatePresence>
      {Object.values(serviceAreas).length > 0 ? (
        <Box
          ref={bottomSheetRef}
          csx={{
            width: '100%',
            zIndex: 1,
            boxShadow: theme.shadows[1],
          }}>
          <Box csx={bottomSheetHeaderStyles}>
            <Box className="tabsContainer">
              <Button
                variant={tab === 'new-table' ? 'active' : 'transparent'}
                icon={<Icon name="MdAdd" />}
                onClick={() => {
                  setTab('new-table');
                  if (!isBottomSheetOpen) {
                    setIsBottomSheetOpen(true);
                  }
                }}>
                {t('settingsModule.tableLayoutCreator.bottomSheet.newTable')}
              </Button>
              <Button
                variant={tab === 'new-element' ? 'active' : 'transparent'}
                icon={<Icon name="MdAdd" />}
                onClick={() => {
                  setTab('new-element');
                  if (!isBottomSheetOpen) {
                    setIsBottomSheetOpen(true);
                  }
                }}>
                {t('settingsModule.tableLayoutCreator.bottomSheet.newElement')}
              </Button>
            </Box>

            {isBottomSheetOpen && (
              <Button
                variant="icon"
                onClick={() => {
                  setIsBottomSheetOpen(false);
                  setTab(null);
                }}
                csx={{ minWidth: '50px' }}
                icon={<Icon name="MdClear" csx={{ rotate: '90deg' }} />}
              />
            )}
          </Box>

          {tab === 'new-table' ? (
            <AnimatedOpacityContainer>
              <Box
                csx={{
                  display: 'flex',
                  flexGrow: 1,
                  padding: '15px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                {TABLE_SHAPES.map(shape => (
                  <StyledCustomButton key={shape.type} onClick={shape.callBack}>
                    <Box
                      style={shape.shapeStyles}
                      csx={{ backgroundColor: iconColor }}
                    />
                    <Typography
                      variant="caption"
                      fontWeight="medium"
                      color={iconColor}>
                      {shape.name}
                    </Typography>
                  </StyledCustomButton>
                ))}
              </Box>
            </AnimatedOpacityContainer>
          ) : tab === 'new-element' ? (
            <AnimatedOpacityContainer>
              <Box csx={newElementContainer}>
                <Box className="newElement">
                  <Box className="header">
                    <Typography>
                      {t(
                        'settingsModule.tableLayoutCreator.bottomSheet.elements.barriers',
                      )}
                    </Typography>
                  </Box>
                  <Box className="objectsList">
                    <StyledCustomButton onClick={handleCreateWall}>
                      {BARRIER_TYPES.wall.icon}
                      <Typography color="semanticBlue" fontWeight="medium">
                        {BARRIER_TYPES.wall.name}
                      </Typography>
                    </StyledCustomButton>
                    <StyledCustomButton onClick={handleCreateFreeForm}>
                      {BARRIER_TYPES.custom.icon}
                      <Typography color="semanticBlue" fontWeight="medium">
                        {BARRIER_TYPES.custom.name}
                      </Typography>
                    </StyledCustomButton>
                  </Box>
                </Box>
                <Box className="newElement">
                  <Box className="header">
                    <Typography>
                      {t(
                        'settingsModule.tableLayoutCreator.bottomSheet.elements.others',
                      )}
                    </Typography>
                  </Box>

                  <Box className="objectsList">
                    {Object.values(ELEMENT_TYPES).map(
                      object =>
                        object && (
                          <StyledCustomButton
                            key={`object_${object.type}`}
                            onClick={() => {
                              handleCreateObject(Shapes.SQUARE, object.type);
                            }}>
                            {object.icon}
                            <Typography
                              variant="caption"
                              fontWeight="medium"
                              color={iconColor}>
                              {t(
                                `settingsModule.tableLayoutCreator.bottomSheet.elements.${object.type}`,
                              )}
                            </Typography>
                          </StyledCustomButton>
                        ),
                    )}
                  </Box>
                </Box>
              </Box>
            </AnimatedOpacityContainer>
          ) : null}
        </Box>
      ) : null}
    </AnimatePresence>
  );
};

const BlueDot = ({ position }: IBlueDot) => {
  const getDirectionStyles = (currentPosition: TDotPosition) => {
    switch (currentPosition) {
      case 'top-left':
        return {
          top: '-5px',
          left: '-5px',
        };
      case 'top-right':
        return {
          top: '-5px',
          right: '-5px',
        };
      case 'bottom-left':
        return {
          bottom: '-5px',
          left: '-5px',
        };
      case 'bottom-right':
        return {
          bottom: '-5px',
          right: '-5px',
        };
    }
  };
  return (
    <Box
      csx={theme => ({
        position: 'absolute',
        width: '10px',
        height: '10px',
        borderRadius: '10px',
        backgroundColor: theme.colors.semanticBlue,
        ...getDirectionStyles(position),
      })}
    />
  );
};

const StyledCustomButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Box
      csx={theme => ({
        cursor: 'pointer',
        borderRadius: '10px',
        backgroundColor: theme.colors.lighterBlue,
        paddingInline: '10px',
        minWidth: '90px',
        height: '65px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
      })}
      onClick={onClick}>
      {children}
    </Box>
  );
};

export default BottomSheet;
