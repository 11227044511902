import { Theme, css } from '@emotion/react';

export const tooltipContainerStyles = () =>
  css({
    position: 'initial',
    width: 'auto',
    height: 'auto',
    display: 'flex',
  });

export const toolTipContentStyles = (theme: Theme) =>
  css({
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    width: 'auto',
    height: 'auto',
    padding: '10px',
    borderRadius: '6px',
    zIndex: 999,
    inset: '0px auto auto 0px',
    maxWidth: '500px',
  });
