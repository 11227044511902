import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';

const Home = () => {
  return (
    <Box
      csx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Icon name="MdCode" size="50px" color="darkGrey" />
        <Typography
          color="darkGrey"
          fontWeight="bold"
          csx={{ marginBottom: '5px' }}>
          Home Page
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
