import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { selectSelectedLayout } from '@app/state/selectors/settingsSelectors';
import { selectSelectedServiceAreas } from '@app/state/selectors/tableLayoutSelectors';
import { RootState } from '@app/state/store';
import {
  ElementTypes,
  IElement,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AddOrUpdateServiceAreaModal from '../../TopBar/AddOrUpdateServiceAreaModal';
import Wall from '../Wall';
import Element from '../Element';
import TableElement from '../TableElement';
import FreeForm from '../FreeForm';
import { useControls } from 'react-zoom-pan-pinch';
import { elementsListStyles } from './styles';
import { bindActionCreators } from 'redux';

const ElementsList = ({ t }: WithTranslation) => {
  // Redux
  const selectedLayout = useSelector(selectSelectedLayout);
  const serviceAreas = useSelector(selectSelectedServiceAreas);

  const {
    instance: { transformState },
  } = useControls();

  const { setIsBottomSheetOpen } = bindActionCreators(
    actionCreatorsTableLayout,
    useDispatch(),
  );

  const { gridWidth, gridHeight, smallUnitSize } = useSelector(
    (state: RootState) => state.tableLayout.toolSettings,
    shallowEqual,
  );

  // Local state
  const elements = selectedLayout || {};

  const renderElement = (element: IElement, index: number, scale: number) => {
    switch (element.type) {
      case ElementTypes.TABLE:
        return (
          <TableElement
            key={`${element.type}-${element.serviceAreaId}-${element.id}-${index}`}
            element={element}
            layoutProps={{ gridWidth, gridHeight, smallUnitSize, scale }}
          />
        );
      case ElementTypes.STATION:
      case ElementTypes.WINDOW:
      case ElementTypes.DOOR:
      case ElementTypes.PLANT:
        return (
          <Element
            key={`${element.id}-${element.serviceAreaId}-${index}`}
            element={element}
            layoutProps={{ gridWidth, gridHeight, smallUnitSize, scale }}
            isAnimated
          />
        );
      case ElementTypes.WALL:
        return (
          <Wall
            key={`${element.id}-${element.serviceAreaId}-${index}`}
            element={element}
            layoutProps={{ gridWidth, gridHeight, smallUnitSize, scale }}
            isAnimated
          />
        );
      case ElementTypes.CUSTOM:
        return (
          <FreeForm
            key={`${element.type}-${element.serviceAreaId}-${index}`}
            element={element}
            layoutProps={{ gridWidth, gridHeight, smallUnitSize, scale }}
            isAnimated
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {Object.values(elements).length > 0 ? (
        Object.values(elements).map((element, index) =>
          renderElement(element, index, transformState.scale),
        )
      ) : (
        <Box csx={elementsListStyles}>
          <Box className="container">
            {Object.values(serviceAreas).length > 0 ? (
              <>
                <Icon name="MdMap" />
                <Typography variant="subtitle">
                  {t('settingsModule.tableLayoutCreator.noLayout.title')}
                </Typography>
                <Typography
                  fontWeight="medium"
                  color="darkGrey"
                  csx={{ marginBottom: '5px' }}>
                  {t('settingsModule.tableLayoutCreator.noLayout.message')}
                </Typography>
                <Button
                  variant="primary"
                  onClick={() => setIsBottomSheetOpen(true)}
                  icon={<Icon name="MdAddCircle" />}>
                  {t('settingsModule.tableLayoutCreator.buttons.addTable')}
                </Button>
              </>
            ) : (
              <>
                <Icon name="LiaVectorSquareSolid" size="30px" />
                <Typography variant="subtitle">
                  {t('settingsModule.tableLayoutCreator.noServiceArea.title')}
                </Typography>
                <Typography
                  fontWeight="medium"
                  color="darkGrey"
                  csx={{ marginBottom: '10px' }}>
                  {t('settingsModule.tableLayoutCreator.noServiceArea.message')}
                </Typography>
                <AddOrUpdateServiceAreaModal mode="add" showAddLabel />
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ElementsList;
