import { useTheme } from '@emotion/react';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import i18n from '../../../i18n.config';
import Box from '../Box';
import CardsContainer from '../CardsContainer';
import Typography from '../Typography';
import { EStatusCardsContainer, IStatusCardsContainer } from './types';

const RANK_ORDER: Record<EStatusCardsContainer, number> = {
  active: 1,
  inactive: 2,
};

const StatusCardsContainer = <
  T extends { groupStatus: EStatusCardsContainer },
>({
  data,
  renderItem,
  selectedStatus,
  activeText = i18n.t('commonTexts.active'),
  inactiveText = i18n.t('commonTexts.inactive'),
  ...otherProps
}: IStatusCardsContainer<T>) => {
  const theme = useTheme();

  const filteredData = useMemo(
    () =>
      data.filter(element =>
        selectedStatus === 'all'
          ? true
          : element.groupStatus === selectedStatus,
      ),
    [data, selectedStatus],
  );
  const sortedData = useMemo(
    () => sortBy(filteredData, element => RANK_ORDER[element.groupStatus]),
    [filteredData],
  );

  const [inactiveIndex, activeIndex] = useMemo(
    () => [
      sortedData.findIndex(
        element => element.groupStatus === EStatusCardsContainer.INACTIVE,
      ),
      sortedData.findIndex(
        element => element.groupStatus === EStatusCardsContainer.ACTIVE,
      ),
    ],
    [sortedData],
  );

  const renderItemStatus = (item: T, index: number) => (
    <>
      {index === activeIndex &&
        ['all', EStatusCardsContainer.ACTIVE].includes(selectedStatus) && (
          <Box
            csx={{
              display: 'flex',
              gridColumn: '1 / -1',
              alignItems: 'center',
              gap: '5px',
            }}>
            <Typography variant="caption" color="darkestGrey">
              {activeText}
            </Typography>
            <Box
              csx={{
                flexGrow: 1,
                height: '1px',
                backgroundColor: theme.colors.semanticGrey,
              }}
            />
          </Box>
        )}
      {index === inactiveIndex &&
        ['all', EStatusCardsContainer.INACTIVE].includes(selectedStatus) && (
          <Box
            csx={{
              display: 'flex',
              gridColumn: '1 / -1',
              alignItems: 'center',
              gap: '5px',
            }}>
            <Typography variant="caption" color="darkestGrey">
              {inactiveText}
            </Typography>
            <Box
              csx={{
                flexGrow: 1,
                height: '1px',
                backgroundColor: theme.colors.semanticGrey,
              }}
            />
          </Box>
        )}
      {renderItem(item, index)}
    </>
  );
  return (
    <CardsContainer
      data={sortedData}
      renderItem={renderItemStatus}
      {...otherProps}
    />
  );
};

export default StatusCardsContainer;
