import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Table from '@app/components/common/Table';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  selectCurrentSettingsData,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  IGuestCheckCustomizingReceiptDiff,
  IGuestCheckSettings,
  IGuestCourseDiff,
  TChange,
  diffGuestReceiptFactory,
} from '@westondev/tableturn-core';
import some from 'lodash/some';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import CustomizingReceiptDiffTable from '../KitchenTicketDiffModal/CustomizingReceiptDiffTable';
import RenderChange from '../common/RenderChange';

const GuestReceiptDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const differences = useSelector(settingsDifferencesSelector);

  const changeData = useSelector(
    selectCurrentSettingsData,
  ) as IGuestCheckSettings;

  const formattedDifferences = useMemo(() => {
    const diffObject = diffGuestReceiptFactory(
      differences,
      store.getState() as CoreRootState,
    );
    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    return <RenderChange change={change} formatValue={formatValue} />;
  };

  const renderCustomizingReceiptTable = (
    rows: IGuestCheckCustomizingReceiptDiff[],
    field: 'ticketHeader' | 'ticketFooter',
  ) => {
    return (
      <CustomizingReceiptDiffTable
        data={rows}
        field={field}
        changeData={changeData}
        formatValue={formatValue}
      />
    );
  };

  const renderCourseChanges = (rows: IGuestCourseDiff[]) => {
    const columnHelper = createColumnHelper<IGuestCourseDiff>();

    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'order',
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.order',
        ),
        size: 40,
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'sortPriority',
          );

          return (
            <RenderChange
              autoWidth
              change={{
                field: 'order',
                label: '',
                value: changeData.courseOrder[rowData.index].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'value',
        header: t(
          'settingsModule.registerModeSettings.tableService.coursing.course',
        ),
        minSize: 200,
        cell: info => {
          const rowData = info.row.original;

          return (
            <RenderChange
              change={{
                field: 'value',
                label: '',
                value: rowData.label,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={false}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={rows}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            order: 'center',
            value: 'center',
          }}
          title={t(
            'loggedIn.settingsModule.guestCheckSettings.courseSortPriority.title',
          )}
        />
      </Box>
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.basicChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basicChanges.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`guestCheckSettings_basicChanges_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.itemChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('menuScreen.common.bucket.items.plural').toUpperCase()}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.itemChanges.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`guestCheckSettings_itemChanges_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {some(
            formattedDifferences.customizingReceipts,
            item => item.length > 0,
          ) && (
            <>
              <HeaderDiffModal
                name={t(
                  'settingsModule.guestCheckSettings.customizingReceipts.title',
                )}
                color="black"
              />

              {formattedDifferences.customizingReceipts.ticketHeader.length >
                0 &&
                renderCustomizingReceiptTable(
                  formattedDifferences.customizingReceipts.ticketHeader,
                  'ticketHeader',
                )}
              {formattedDifferences.customizingReceipts.ticketFooter.length >
                0 &&
                renderCustomizingReceiptTable(
                  formattedDifferences.customizingReceipts.ticketFooter,
                  'ticketFooter',
                )}
            </>
          )}

          {(formattedDifferences.courseChanges.length > 0 ||
            formattedDifferences.advancedChanges.length > 0) && (
            <>
              <HeaderDiffModal
                name={t(
                  'menuScreen.commonFields.basic.advancedOptions',
                ).toUpperCase()}
                color="black"
              />
              {formattedDifferences.advancedChanges.length > 0 && (
                <Grid columnGap={20} rowGap={10}>
                  {formattedDifferences.advancedChanges.map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`guestCheckSettings_advancedChanges_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
                </Grid>
              )}

              {formattedDifferences.courseChanges.length > 0 &&
                renderCourseChanges(formattedDifferences.courseChanges)}
            </>
          )}
        </>
      )}
    </>
  );
};

export default GuestReceiptDiffModal;
