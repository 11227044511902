import Typography from '@app/components/common/Typography';
import { css } from '@emotion/react';
import { WithTranslation } from 'react-i18next';

interface IOverflowText extends WithTranslation {
  text: string | null;
  rows?: number;
}

const ONE_ROW_OVERFLOW_CSS = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const OverflowText = ({ text, t, rows = 3 }: IOverflowText) => {
  const isNull = text === null;
  const overFlowStyles = css(
    rows === 1
      ? ONE_ROW_OVERFLOW_CSS
      : css({
          display: '-webkit-box',
          WebkitLineClamp: `${rows}`,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }),
  );
  return (
    <Typography
      csx={[
        {
          fontStyle: isNull ? 'italic' : undefined,
        },
        overFlowStyles,
      ]}>
      {text || t('menuScreen.itemsMain.table.varies')}
    </Typography>
  );
};

export default OverflowText;
