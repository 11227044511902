import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';

interface ICustomTag {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  text?: string;
}

const CustomTag = ({ backgroundColor, textColor, text }: ICustomTag) => {
  return (
    <Box
      csx={{
        position: 'absolute',
        bottom: -15,
        right: -29,
        rotate: '-45deg',
        height: '50px',
        width: '80px',
        backgroundColor: backgroundColor || 'red',
        paddingTop: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}>
      <Typography color={textColor} fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

export default CustomTag;
