import Box from '@app/components/common/Box';
import Link from '@app/components/common/Link';
import Modal from '@app/components/common/Modal';
import Typography from '@app/components/common/Typography';
import { FormEvent, useState } from 'react';
import { linkWithText } from '../../../styles';
import { WithTranslation } from 'react-i18next';
import Button from '@app/components/common/Button';
import Input from '@app/components/common/Input';
import Icon from '@app/components/common/Icon';
import { buttonsStyles, modalContentContainerStyles } from './styles';
import { InputMask } from '@react-input/mask';
import { AboveIconStyles } from '../../VerifyEmailStep/ChangeEmailModal/styles';

interface IChangePhoneModal extends WithTranslation {
  onPhoneChanged: (phone: string, phoneMasked: string) => void;
}

const ChangePhoneModal = ({ t, onPhoneChanged }: IChangePhoneModal) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: '',
    phoneNumberMasked: '',
    error: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSetFormData = (
    e: FormEvent<HTMLInputElement> | string,
    field: string,
  ) => {
    const value = typeof e === 'string' ? e : e.currentTarget.value;
    const regex = /^[0-9\b]+$/;

    let valid = true;

    if (value === '' || regex.test(value)) {
      setFormData(prev => ({ ...prev, [field]: value }));
      if (value.length !== 10) {
        setFormData(prev => ({ ...prev, error: true }));
        valid = false;
      } else {
        setFormData(prev => ({ ...prev, error: false }));
      }
    } else {
      valid = false;
    }

    if (valid) setFormData(prev => ({ ...prev, error: false }));
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.phoneNumber.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        onPhoneChanged(formData.phoneNumber, formData.phoneNumberMasked);
        setIsModalActive(false);
        setIsLoading(false);
        setFormData(prev => ({ ...prev, phoneNumber: '' }));
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    setIsModalActive(false);
    setFormData(prev => ({ ...prev, phoneNumber: '' }));
  };

  return (
    <>
      <Box csx={[linkWithText, { marginTop: '15px' }]}>
        <Typography
          variant="body"
          align="center"
          fontWeight="medium"
          color="darkGrey">
          {t('authentication.signUp.wrongPhoneNumber')}
        </Typography>
        <Link to="#" onClick={() => setIsModalActive(true)}>
          {t('authentication.signUp.changePhoneNumber')}
        </Link>
      </Box>
      <Modal
        isActive={isModalActive}
        onModalClose={handleCloseModal}
        size="600px">
        <form
          id="changeEmailForm"
          css={modalContentContainerStyles}
          onSubmit={handleSubmitForm}>
          <Box className="content">
            <Box className="iconsContainer">
              <Icon name="MdPhoneIphone" size="90px" />
              <Icon
                name="MdCached"
                color="semanticBlue"
                size="40px"
                csx={AboveIconStyles}
              />
            </Box>
            <Typography variant="heading" fontWeight="medium" className="title">
              {t('authentication.signUp.changePhone.changePhoneNumber')}
            </Typography>
            <Typography align="center" className="description">
              {t('authentication.signUp.changePhone.description')}
            </Typography>
            <InputMask
              inputWrapperClassName="emailInput"
              id="changePhoneNumber"
              name="changePhoneNumber"
              label={t('authentication.signUp.changePhone.newPhone')}
              placeholder={t('authentication.signUp.accountInfo.phoneNumber')}
              showCaptionSpace
              component={Input}
              mask="(___) ___-____"
              replacement={{ _: /\d/ }}
              onMask={e => {
                setFormData(prev => ({
                  ...prev,
                  phoneNumber: e.detail.input,
                  phoneNumberMasked: e.detail.value,
                }));
                handleSetFormData(e.detail.input, 'phoneNumber');
              }}
              required
              autoFocus
              error={formData.error}
            />
            <Box csx={buttonsStyles}>
              <Button type="submit" variant="active" isLoading={isLoading}>
                {t('authentication.signUp.changeEmail.sendVerificationCode')}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseModal}
                disabled={isLoading}>
                {t('commonButtons.cancel')}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default ChangePhoneModal;
