export type TToastAnimation = {
  initial: object;
  enter: object;
  exit: object;
};

export const TOAST_ANIMATIONS = {
  mobile: {
    initial: { opacity: 0, x: 0, y: -25 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, y: -25 },
  },
  desktop: {
    initial: { opacity: 0, x: 20, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, y: -25 },
  },
};
