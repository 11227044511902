import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Grid from '@app/components/common/Grid';
import { ItemPrepStationSectionKind } from '@app/hocs/types';
import { FormMode } from '@westondev/tableturn-core';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import ItemPrepStationSection from './ItemPrepStationSection';

const PrepStationsSection = ({
  t,
  menuTypeVersionId,
  sectionId,
  itemData,
  mode,
  isDisabled,
  ...accordionSectionProps
}: IItemDetailsSection) => {
  const isMassUpdate = mode === FormMode.MASS_UPDATE;
  return (
    <AccordionSection
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      title={t('menuScreen.itemDetails.prepStationsSection.title')}
      info={t(
        'menuScreen.itemDetails.prepStationsSection.prepStations.toolTip',
      )}
      {...accordionSectionProps}>
      <Grid rowGap={20}>
        <Grid.Item>
          <ItemPrepStationSection
            menuTypeVersionId={menuTypeVersionId}
            sectionId={sectionId}
            itemData={itemData}
            mode={mode}
            kind={ItemPrepStationSectionKind.ADD}
          />
        </Grid.Item>
        {isMassUpdate && (
          <Grid.Item>
            <ItemPrepStationSection
              menuTypeVersionId={menuTypeVersionId}
              sectionId={sectionId}
              itemData={itemData}
              mode={mode}
              kind={ItemPrepStationSectionKind.REMOVE}
            />
          </Grid.Item>
        )}
      </Grid>
    </AccordionSection>
  );
};

export default PrepStationsSection;
