import Input from '../Input';
import { IInput } from '../Input/Input';

interface DatePickerProps extends Omit<IInput, 'onChange' | 'value' | 'type'> {
  utcValue: string;
  onChange: (newValue: string) => void;
  // minValue?: string;
  // maxValue?: string;
}

const DatePicker = ({ utcValue, onChange, ...props }: DatePickerProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedDate = e.target.value;
    onChange(inputtedDate);
  };

  return (
    <Input {...props} type="date" value={utcValue} onChange={handleChange} />
  );
};

export default DatePicker;
