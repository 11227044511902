import { TCsx } from '@emotion/react';
import Box from '../Box';
import Icon from '../Icon';
import Typography from '../Typography';
import { chipContainerStyles, chipStyles } from './styles';
import { ITypography } from '../Typography/Typography';

interface IChip {
  children: React.ReactNode;
  showRemoveIcon?: boolean;
  onDeletePress?: () => void;
  csx?: TCsx;
  textColor?: ITypography['color'];
}
const Chip = ({
  children,
  showRemoveIcon,
  onDeletePress,
  csx,
  textColor,
}: IChip) => {
  return (
    <Box csx={chipContainerStyles}>
      <Box csx={[chipStyles, csx]}>
        <Typography color={textColor || 'black'} align="center">
          {children}
        </Typography>
      </Box>
      {showRemoveIcon && (
        <Box className="removeIcon" onClick={onDeletePress}>
          <Icon name="MdClear" color="textWhite" />
        </Box>
      )}
    </Box>
  );
};

export default Chip;
