import Box from '@app/components/common/Box';
import CustomersList from '@app/components/LoggedIn/Customers/CustomersList';
import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { makeSelectCustomersData } from '@app/state/customers/customersSelectors';
import { CustomersSectionId } from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';

const SECTION_ID = CustomersSectionId.BASIC;

const getMemoizedCustomerData = makeSelectCustomersData(SECTION_ID);

const Customers = () => {
  const { data } = useSelector(getMemoizedCustomerData);

  const match = useMatch(getPathWithOrgData('/customers'));
  const matchCreate = useMatch(getPathWithOrgData('/customers/add'));

  const navigate = useNavigateWithOrg();

  const handleGotoRolesList = () => {
    navigate('/customers');
  };

  const name = matchCreate
    ? 'Adding New Customer'
    : `${data?.firstName} ${data?.lastName}` || '';
  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}>
      <LayoutContent noPadding={false}>
        {!match ? (
          <>
            <BreadCrumbSettings
              title="Customers"
              currentPage={name}
              onClick={handleGotoRolesList}
              isSettings={false}
            />
            <Outlet />
          </>
        ) : (
          <CustomersList />
        )}
      </LayoutContent>
    </Box>
  );
};

export default Customers;
