import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';

const DROPDOWN_DATA = [
  [
    { value: 1, label: 'Option1' },
    { value: 2, label: 'Option2' },
    { value: 3, label: 'Option3' },
  ],
];

const DropdownExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Dropdowns
      </Typography>
      <Typography variant="body" csx={{ marginBottom: '10px' }}>
        Simple, disabled
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
            isDisabled
          />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Label, label disabled and info
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Label"
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Disabled"
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
            isDisabled
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Info"
            info="Info Example"
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Info Disabled"
            info="Info Example"
            isDisabled
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Required
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Required"
            required
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Dropdown
            label="Disabled"
            required
            isDisabled
            placeholder="Select an option"
            data={DROPDOWN_DATA}
            onChange={() => null}
          />
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default DropdownExample;
