import Box from '../Box';

const BlueDot = ({ customColor }: { customColor?: string }) => {
  return (
    <Box
      csx={theme => ({
        borderRadius: 100,
        minWidth: '8px',
        maxWidth: '8px',
        minHeight: '8px',
        maxHeight: '8px',
        backgroundColor: customColor || theme.colors.persistentSemanticBlue,
      })}
    />
  );
};

export default BlueDot;
