import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  CONFIRMATION_MODAL_INITIAL_STATE,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import { actionCreatorsMenuWeb } from '@app/state';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SUB_NAMES = [
  'posName' as const,
  'kdsName' as const,
  'checkName' as const,
  'onlineName' as const,
];

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('modifierItems', SECTION_ID);

const gridItemSizing = {
  mb: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 3,
};

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();
  const { updateModifierItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );
  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );
  const onRefresh = useRefreshMenuWeb();

  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);

  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active }, SECTION_ID)}
      showDefaultLabel
    />
  );

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');
  const kdsNameError = useFormError('kdsName');
  const checkNameError = useFormError('checkName');
  const onlineNameError = useFormError('onlineName');

  const errorMap = {
    posName: posNameError,
    kdsName: kdsNameError,
    checkName: checkNameError,
    onlineName: onlineNameError,
  };

  const handleDelete = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.modifierItems.singular'),
      type: 'delete',
      name: itemData?.name,
      onSuccess: () => {
        setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
        deleteBucketElementWeb();
      },
    });
  }, [deleteBucketElementWeb, itemData?.name, setShowConfirmationModal, t]);

  const handleNameChange = (name: string) => {
    const changes: Record<string, string> = {};
    const previousName = itemData?.name;
    SUB_NAMES.forEach(subName => {
      const value = itemData?.[subName];
      if (!value || value === previousName) {
        changes[subName] = name;
        if (errorMap[subName]?.error) {
          errorMap[subName]?.clear();
        }
      }
    });
    setValue({ ...changes, name }, SECTION_ID);
  };

  const options = useMemo(() => {
    return [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalActive(true),
        isDisabled: false,
      },
      {
        text: t('commonButtons.delete'),
        handler: handleDelete,
        isDisabled: !isMasterMode,
      },
    ];
  }, [handleDelete, isMasterMode, t]);

  const hasSectionError =
    Boolean(nameError?.error) ||
    Boolean(posNameError?.error) ||
    Boolean(kdsNameError?.error) ||
    Boolean(checkNameError?.error) ||
    Boolean(onlineNameError?.error);

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket="modifierItems"
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(() => {
            const _navigate = () =>
              navigate(`/menu/modifiers/modifier-items/${newId}`);
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.REPLACE,
              text: currentMenu.modifierItems[newId]?.name,
              onPress: _navigate,
              pathURL: `/menu/modifiers/modifier-items/${newId}`,
            });
            _navigate();
          }, onRefresh);
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: isCreation
              ? t(
                  'menuScreen.modifierItemDetails.basic.optionsDisabled.creation',
                )
              : t(
                  'menuScreen.modifierItemDetails.basic.optionsDisabled.location',
                ),
          })
        }
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
            <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
              <Input
                required
                caption={nameError?.error}
                error={Boolean(nameError?.error)}
                label={t('loggedIn.commonFields.basic.name')}
                placeholder={t('loggedIn.commonFields.basic.name')}
                value={itemData?.name || ''}
                disabled={!isMasterMode && !isCreation}
                onChange={e => handleNameChange(e.currentTarget.value)}
                onFocus={() => nameError && nameError.clear()}
              />
            </Grid.Item>
            <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
              <Box csx={{ display: 'flex' }}>
                <Input
                  required
                  label={t('loggedIn.commonFields.basic.posName')}
                  placeholder={t('loggedIn.commonFields.basic.posName')}
                  caption={posNameError?.error}
                  error={Boolean(posNameError?.error)}
                  value={itemData?.posName || ''}
                  onChange={posName =>
                    setValue(
                      {
                        posName: posName.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  onFocus={() => posNameError && posNameError.clear()}
                />
              </Box>
            </Grid.Item>
            <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
              <Box csx={{ display: 'flex' }}>
                <Input
                  required
                  label={t('loggedIn.commonFields.basic.kdsName')}
                  placeholder={t('loggedIn.commonFields.basic.kdsName')}
                  caption={kdsNameError?.error}
                  error={Boolean(kdsNameError?.error)}
                  value={itemData?.kdsName || ''}
                  onChange={kdsName =>
                    setValue(
                      {
                        kdsName: kdsName.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  onFocus={() => kdsNameError && kdsNameError.clear()}
                />
              </Box>
            </Grid.Item>
            <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
              <Box csx={{ display: 'flex' }}>
                <Input
                  required
                  label={t('loggedIn.commonFields.basic.checkName')}
                  placeholder={t('loggedIn.commonFields.basic.checkName')}
                  caption={checkNameError?.error}
                  error={Boolean(checkNameError?.error)}
                  value={itemData?.checkName || ''}
                  onChange={checkName =>
                    setValue(
                      {
                        checkName: checkName.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  onFocus={() => checkNameError && checkNameError.clear()}
                />
              </Box>
            </Grid.Item>
            <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
              <Box csx={{ display: 'flex' }}>
                <Input
                  required
                  label={t('loggedIn.commonFields.basic.onlineName')}
                  placeholder={t('loggedIn.commonFields.basic.onlineName')}
                  caption={onlineNameError?.error}
                  error={Boolean(onlineNameError?.error)}
                  value={itemData?.onlineName || ''}
                  onChange={onlineName =>
                    setValue(
                      {
                        onlineName: onlineName.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  onFocus={() => onlineNameError && onlineNameError.clear()}
                />
              </Box>
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
