import Box from '../../Box';
import Switch from '../../Switch';
import ItemCard from '../ItemCard';
import { IItemCardWithSwitch } from './types';

const ItemCardWithSwitch = ({
  switchValue,
  onSwitchChange,
  ...itemCardProps
}: IItemCardWithSwitch) => {
  return (
    <Box csx={{ width: 'Inherit', height: 'inherit', position: 'relative' }}>
      <ItemCard {...itemCardProps} />
      <Box csx={{ position: 'absolute', right: 5, top: 5 }}>
        <Switch size="small" checked={switchValue} onChange={onSwitchChange} />
      </Box>
    </Box>
  );
};

export default ItemCardWithSwitch;
