import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { SAVE_AND_CANCEL_BAR_ID } from '@app/components/LoggedIn/SaveAndCancelBar/types';
import { LOGGED_IN_LAYOUT_ID } from '@app/components/LoggedIn/LoggedInLayout/types';

interface IUseScrollToNewRow {
  skipSaveAndCancelBarCheck: boolean;
}
const DEFAULT_CONFIG: IUseScrollToNewRow = {
  skipSaveAndCancelBarCheck: false,
};

const useScrollToNewRow = ({
  skipSaveAndCancelBarCheck,
}: IUseScrollToNewRow = DEFAULT_CONFIG) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [saveAndCancelBarHeight, setSaveAndCancelBarHeight] = useState(0);
  const scrollToIdRef = useRef<string | null>(null);
  const scrollToNewRow = useCallback((id: string) => {
    scrollToIdRef.current = id;
  }, []);

  const tableObserver = useMemo(() => {
    const _observer = new MutationObserver(mutationsList => {
      if (!scrollToIdRef?.current) return;
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const _element = document.getElementById(scrollToIdRef.current);
          if (_element) {
            setElement(_element);
            scrollToIdRef.current = null;
            break;
          }
        }
      }
    });

    _observer.observe(document.body, { childList: true, subtree: true });

    return _observer;
  }, []);

  useEffect(() => {
    return () => {
      tableObserver?.disconnect();
    };
  }, [tableObserver]);

  useEffect(() => {
    if (skipSaveAndCancelBarCheck) return;

    const handleSaveAndCancelBarMutation = debounce(() => {
      const saveAndCancelBar = document.getElementById(SAVE_AND_CANCEL_BAR_ID);
      if (saveAndCancelBar) {
        setSaveAndCancelBarHeight(saveAndCancelBar.offsetHeight);
      } else {
        setSaveAndCancelBarHeight(0);
      }
    }, 300);

    const saveAndCancelBarObserver = new MutationObserver(mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          handleSaveAndCancelBarMutation();
          break;
        }
      }
    });

    const loggedInLayout = document.getElementById(LOGGED_IN_LAYOUT_ID);
    if (loggedInLayout) {
      saveAndCancelBarObserver.observe(loggedInLayout, {
        childList: true,
        subtree: true,
      });
    } else {
      console.error('Could not find logged in layout');
    }

    return () => {
      saveAndCancelBarObserver.disconnect();
      handleSaveAndCancelBarMutation.cancel();
    };
  }, [skipSaveAndCancelBarCheck]);

  useEffect(() => {
    if (element && (saveAndCancelBarHeight || skipSaveAndCancelBarCheck)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [element, saveAndCancelBarHeight, skipSaveAndCancelBarCheck]);

  return scrollToNewRow;
};

export default useScrollToNewRow;
