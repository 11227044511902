import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  menuTypesAssignedCatSubcategoriesFactory,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICategoryCountMap } from '../types';

interface IAssignedCategoriesSection extends WithTranslation {
  subcategoryCount: ICategoryCountMap;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);

const AssignedSubcategoriesSection = ({
  subcategoryCount,
  t,
}: IAssignedCategoriesSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const subcategories = useSelector(selectSubcategories);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const associatedSubcategoriesIds = itemData?.subcategoryIds;

  const associatedSubcategoriesCardList = useMemo(() => {
    const handleOnClick = (
      id: number,
      text: string,
      openInNewTab?: boolean,
    ) => {
      const pathURL = `/menu/subcategories/${id}`;
      if (openInNewTab) return openNewTabWithOrgData(pathURL);

      const _navigate = () => navigate(pathURL);
      checkForChangesAndNavigateWeb(
        () =>
          addBreadcrumbLocationWeb({
            action: BreadCrumbAction.NAV,
            text,
            onPress: _navigate,
            pathURL,
          }),
        onRefresh,
      );
    };
    return menuTypesAssignedCatSubcategoriesFactory(
      associatedSubcategoriesIds || [],
      subcategories,
      subcategoryCount,
    ).map(card => ({
      ...card,
      onClick: (openInNewTab?: boolean) =>
        handleOnClick(card.id, card.title, openInNewTab),
      isLink: getPathWithOrgData(`/menu/subcategories/${card.id}`),
      tag: card.customTag,
      isActive: card.isStatusActive,
      showStatus: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategories, associatedSubcategoriesIds, subcategoryCount, t]);

  const closeModal = () => {
    setOpenAddExistingItem(false);
  };

  return (
    <AssignedElements
      noElementsMessage={t(
        'menuScreen.categoryDetails.associations.subcategories.emptyCardsMessage',
      )}
      active={openAddExistingItem}
      onModalClose={closeModal}
      elements={associatedSubcategoriesCardList}
      subCardProps={{
        title: t('menuScreen.categoryDetails.associations.subcategories.title'),
        subtitle: t(
          'menuScreen.categoryDetails.associations.subcategories.description',
        ),
      }}
    />
  );
};

export default AssignedSubcategoriesSection;
