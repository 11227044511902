import useItemChangeColor from '@app/hooks/useItemChangeColor';
import { Theme, css } from '@emotion/react';
import { TPricingVersionDiff } from '@westondev/tableturn-core';

export const pricingVersionsButtonsStyles =
  (
    action: TPricingVersionDiff['action'],
    isSelected: boolean,
    selectedIndex: number,
    index: number,
    totalElements: number,
  ) =>
  (theme: Theme) => {
    const {
      selectedColor,
      boxTextAndBorderColor,
      boxColor,
      borderContainerColor,
      boxRemovedColor,
      boxRemovedTextColor,
    } = useItemChangeColor();

    const borderColor =
      action === 'remove' && isSelected
        ? boxRemovedTextColor
        : isSelected
        ? borderContainerColor
        : theme.colors.lightGrey;

    return css({
      width: 200,
      height: 90,
      position: 'relative',
      border: `2px solid ${borderColor}`,
      borderLeft:
        !isSelected && index === selectedIndex + 1
          ? '2px solid transparent'
          : `2px solid ${borderColor}`,
      borderRight:
        index < totalElements - 1 && !isSelected
          ? '2px solid transparent'
          : `2px solid ${borderColor}`,
      backgroundColor:
        action === 'remove' && isSelected
          ? boxRemovedColor
          : isSelected
          ? selectedColor
          : 'transparent',
      zIndex: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      paddingBottom: 0,
      '&:last-of-type': {
        borderTopRightRadius: 15,
      },
      '&:first-of-type': {
        borderTopLeftRadius: 15,
      },
      '& .content': {
        display: 'flex',
        position: 'absolute',
        width: 120,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomLeftRadius: 10,
        backgroundColor: action === 'remove' ? boxRemovedColor : boxColor,
        border: `2px solid ${
          action === 'remove'
            ? boxRemovedTextColor
            : isSelected
            ? boxTextAndBorderColor
            : 'lightGrey'
        }`,
        borderRightWidth: 0,
        top: -2,
        right: 0,
        '& .actionText': {
          color:
            action === 'remove'
              ? boxRemovedTextColor
              : isSelected
              ? boxTextAndBorderColor
              : 'black',
        },
      },
    });
  };
