import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';
export const stepsContainerStyles = (clickable: boolean) => (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.stepContainer': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginBlock: '10px',
      cursor: clickable ? 'pointer' : 'default',
      userSelect: 'none',
      '.step': {
        backgroundColor: 'transparent',
        minWidth: '35px',
        minHeight: '35px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px solid ${theme.colors.lightGrey}`,
        '> p': {
          color: theme.colors.lightGrey,
        },
      },
      '.stepName': {
        display: 'none',
        textAlign: 'center',
        color: theme.colors.lightGrey,
        fontWeight: theme.fontWeights.medium,
      },
      '> svg': {
        fill: theme.colors.lightGrey,
      },
      '&.active ': {
        '.stepName': {
          color: theme.colors.semanticBlue,
          display: 'block',
        },
        '> .step': {
          backgroundColor: theme.colors.semanticBlue,
          border: '2px solid transparent',
          '> p': {
            color: theme.colors.textWhite,
          },
        },
        svg: {
          fill: theme.colors.semanticBlue,
        },
      },
      '&.completed ': {
        '.stepName': {
          color: theme.colors.semanticGreen,
        },
        '> .step': {
          backgroundColor: theme.colors.semanticGreen,
          boxShadow: 'none',
          border: '2px solid transparent',
          '> p': {
            color: theme.colors.textWhite,
          },
        },
        svg: {
          fill: theme.colors.semanticGreen,
        },
      },
      [MQ_MIN_MEDIUM]: {
        '.stepName': {
          display: 'block',
          transition: 'all 0.3s ease-in-out',
        },
      },
    },
  });
