import Box from '@app/components/common/Box';
import CodeVerifier from '@app/components/common/CodeVerifier';
import Typography from '@app/components/common/Typography';
import { useState } from 'react';
import Card from '@app/components/common/Card';

const CodeVerifierExample = () => {
  const [, setCode] = useState({ cv: '' });

  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Code Verifier
      </Typography>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Simple
      </Typography>
      <Box csx={{ display: 'flex', gap: '10px' }}>
        <CodeVerifier
          id="cv"
          length={6}
          getCode={value => setCode(prev => ({ ...prev, cv: value }))}
        />
      </Box>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Caption
      </Typography>
      <Box csx={{ display: 'flex', gap: '10px' }}>
        <CodeVerifier
          id="cvCaption"
          length={6}
          caption="Insert the code we sent to 312 *** ** **."
          getCode={value => setCode(prev => ({ ...prev, cv: value }))}
        />
      </Box>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Error
      </Typography>
      <Box csx={{ display: 'flex', gap: '10px' }}>
        <CodeVerifier
          id="cvError"
          length={6}
          error
          caption="Invalid code. Please try again."
          getCode={value => setCode(prev => ({ ...prev, cv: value }))}
        />
      </Box>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Loading state
      </Typography>
      <Box csx={{ display: 'flex', gap: '10px' }}>
        <CodeVerifier id="cvLoading" length={6} isLoading />
      </Box>
    </Card>
  );
};

export default CodeVerifierExample;
