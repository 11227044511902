import { css } from '@emotion/react';

export const cardContainerStyles = css({
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
});

export const cardContainerSubCardStyles = css({
  padding: '0',
  borderWidth: '0',
});

export const emptyCardContainerStyles = css({ padding: '10px 0' });
