import GenericSelectionModal from '../GenericSelectionModal';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';
import { ICustomSelectionModal } from '../types';

const ItemGroupsSelectionModal = ({
  t,
  active,
  onModalClose,
  buttons,
  onAssociate,
  btnSuccessText,
  emptySectionMessage,
  allowAssociateMultiple = true,
  ...others
}: ICustomSelectionModal<unknown>) => {
  //redux
  return (
    <GenericSelectionModal
      {...others}
      onPress={() => null}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.itemGroups.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.itemGroups.selectedSectionTitle',
      )}
      emptySectionMessage={
        emptySectionMessage
          ? emptySectionMessage
          : t('menuScreen.selectionModal.itemGroups.emptySectionText')
      }
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.itemGroups.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.itemGroups.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      btnSuccessText={btnSuccessText}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}
    />
  );
};

export default ItemGroupsSelectionModal;
