import { useEffect, useState } from 'react';

const STORAGE_KEY = 'state';

const useSessionStorageState = <T>(
  field: string,
  defaultValue?: T | (() => T),
) => {
  const getData = () => {
    const savedData = sessionStorage.getItem(STORAGE_KEY);
    return savedData ? JSON.parse(savedData) : {};
  };

  const [value, setValue] = useState<T>(
    (getData()?.[field] !== undefined ? getData()?.[field] : defaultValue) as T,
  );

  useEffect(() => {
    const data = getData();

    if (field in data && data[field] !== value) {
      setValue(data[field]);
    }
  }, [field, value]);

  const setSessionStorageState = (newValue: T) => {
    const newData = getData();

    newData[field] = newValue;

    setValue(newValue);

    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newData));
  };

  return [value, setSessionStorageState] as [T, (newValue: T) => void];
};
export default useSessionStorageState;
