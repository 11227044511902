import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import CheckboxMassUpdate from '@app/components/common/CheckBoxMassUpdate';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import MoneyInput from '@app/components/common/MoneyInput';
import SwitchMassUpdate from '@app/components/common/SwitchMassUpdate';
import { store } from '@app/state/store';
import {
  EStockZeroActions,
  FormMode,
  IItem,
  IMenuTypeVersion,
  MassUpdateValues,
  actionCreatorsMenu,
  taxVolumeUnitDropdownFactory,
  unitToValue,
  valueToUnit,
} from '@westondev/tableturn-core';
import { mapValues } from 'lodash';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';

const actionToValue = (action: EStockZeroActions | MassUpdateValues) => {
  switch (action) {
    case EStockZeroActions.SET_86:
      return 1;
    case EStockZeroActions.RESET_TO_INITIAL:
      return 2;
    case MassUpdateValues.NO_CHANGES:
      return -1;
    default:
      return 0;
  }
};

const valueToAction = (value: number) => {
  switch (value) {
    case 1:
      return EStockZeroActions.SET_86;
    case 2:
      return EStockZeroActions.RESET_TO_INITIAL;
    case -1:
      return MassUpdateValues.NO_CHANGES;
    default:
      return EStockZeroActions.NONE;
  }
};

const InventorySection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  isCombo,
  mode,
  isDisabled,
  ...props
}: IItemDetailsSection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const propagateChange = (field: keyof IMenuTypeVersion, value: any) => {
    const newMenuTypeVersions = mapValues(
      (store.getState().menu.changeData.data as IItem)?.menuTypeVersions,
      menuTypeVersion => ({ ...menuTypeVersion, [field]: value }),
    );
    setValue({ menuTypeVersions: newMenuTypeVersions }, sectionId);
  };

  const actionWhenCurrentStockZero =
    itemData?.actionWhenCurrentStockZero || EStockZeroActions.NONE;

  const isSectionDisabled =
    !itemData?.trackInventory ||
    itemData?.trackInventory === MassUpdateValues.NO_CHANGES;

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isMassUpdateProp = {
    isMassUpdate,
  };

  return (
    <AccordionSection
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      title={t('menuScreen.itemDetails.inventorySection.title')}
      expandedHeight="75px"
      description={
        isCombo
          ? undefined
          : t(
              `menuScreen.itemDetails.inventorySection.${
                !isMassUpdate ? 'description' : 'shortDescription'
              }`,
            )
      }
      {...props}>
      <Grid rowGap={20}>
        <Grid.Item>
          <Grid rowGap={20} columnGap={20}>
            {!isCombo && (
              <Grid.Item
                sm={6}
                md={4}
                lg={3}
                csx={{ justifyContent: 'flex-end' }}>
                <Input
                  label={t(
                    'menuScreen.itemDetails.inventorySection.universalProductCode',
                  )}
                  placeholder={t(
                    'menuScreen.itemDetails.inventorySection.universalProductCode',
                  )}
                  info={t(
                    'menuScreen.itemDetails.inventorySection.universalProductCodetoolTip',
                  )}
                  value={
                    (itemData as IMenuTypeVersion)?.universalProductCode || ''
                  }
                  onChange={e =>
                    setValue(
                      { universalProductCode: e.target.value },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                />
              </Grid.Item>
            )}
            <Grid.Item
              sm={6}
              md={4}
              lg={3}
              csx={{ justifyContent: 'flex-end' }}>
              <MoneyInput
                info={t('menuScreen.itemDetails.inventorySection.volume')}
                label={t('menuScreen.itemDetails.inventorySection.volume')}
                value={itemData?.volume || 0}
                onValueChange={volume =>
                  setValue({ volume }, sectionId, menuTypeVersionId)
                }
                prefix=""
                suffix=""
                precision={2}
              />
            </Grid.Item>
            <Grid.Item
              sm={6}
              md={4}
              lg={3}
              csx={{ justifyContent: 'flex-end' }}>
              <Dropdown
                label={t('settingsModule.taxesSettings.taxesTable.unit')}
                placeholder={t('commonTexts.placeholderDropdown')}
                data={taxVolumeUnitDropdownFactory()}
                value={itemData ? unitToValue[itemData?.unit] : 0}
                onChange={unit =>
                  setValue(
                    {
                      unit: valueToUnit[unit],
                    },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
        {!isCombo && (
          <>
            <Grid.Item>
              <Grid rowGap={20} columnGap={20}>
                <Grid.Item sm={6} md={4} lg={3}>
                  <SwitchMassUpdate
                    checked={itemData ? itemData.trackInventory : true}
                    onChange={isActive => {
                      propagateChange('trackInventory', isActive);
                    }}
                    label={t(
                      'menuScreen.itemDetails.inventorySection.trackInventory',
                    )}
                    onMassUpdateChange={isActive => {
                      setValue({ trackInventory: isActive }, sectionId);
                    }}
                    {...isMassUpdateProp}
                  />
                </Grid.Item>
                <Grid.Item sm={6} md={4} lg={3}>
                  <CheckboxMassUpdate
                    label={t(
                      'menuScreen.itemDetails.inventorySection.showOnFreshSheet',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.showOnFreshSheettoolTip',
                    )}
                    checked={itemData ? itemData?.showOnFreshSheet : false}
                    onChange={showOnFreshSheet =>
                      propagateChange('showOnFreshSheet', showOnFreshSheet)
                    }
                    onMassUpdateChange={showOnFreshSheet =>
                      setValue({ showOnFreshSheet }, sectionId)
                    }
                    {...isMassUpdateProp}
                  />
                </Grid.Item>
                <Grid.Item sm={6} md={4} lg={3}>
                  <CheckboxMassUpdate
                    label={t(
                      'menuScreen.itemDetails.inventorySection.86ItemWhenCurrentStockIs0',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.86ItemWhenCurrentStockIs0toolTip',
                    )}
                    checked={itemData ? itemData.is86ed : false}
                    onChange={is86ed => propagateChange('is86ed', is86ed)}
                    onMassUpdateChange={is86ed =>
                      setValue({ is86ed }, sectionId)
                    }
                    {...isMassUpdateProp}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Grid.Item>
              <Grid rowGap={20} columnGap={20}>
                <Grid.Item
                  sm={6}
                  md={4}
                  lg={3}
                  csx={{ justifyContent: 'flex-end' }}>
                  <MoneyInput
                    label={t(
                      'menuScreen.itemDetails.inventorySection.initialAmountInStock',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.initialAmountInStocktoolTip',
                    )}
                    prefix=""
                    suffix=""
                    precision={0}
                    value={
                      itemData
                        ? itemData?.initialAmount ===
                          MassUpdateValues.NO_CHANGES
                          ? null
                          : itemData?.initialAmount
                        : 0
                    }
                    onValueChange={initialAmount =>
                      propagateChange('initialAmount', initialAmount)
                    }
                    onChangeEmpty={
                      isMassUpdate
                        ? initialAmount =>
                            propagateChange(
                              'initialAmount',
                              initialAmount === null
                                ? MassUpdateValues.NO_CHANGES
                                : Number(initialAmount),
                            )
                        : undefined
                    }
                    disabled={isSectionDisabled}
                  />
                </Grid.Item>
                <Grid.Item
                  sm={6}
                  md={4}
                  lg={3}
                  csx={{ justifyContent: 'flex-end' }}>
                  <MoneyInput
                    label={t(
                      'menuScreen.itemDetails.inventorySection.currentAmountInStocktoolTip',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.currentAmountInStock',
                    )}
                    prefix=""
                    suffix=""
                    precision={0}
                    value={
                      itemData
                        ? itemData?.currentAmount ===
                          MassUpdateValues.NO_CHANGES
                          ? null
                          : itemData?.currentAmount
                        : 0
                    }
                    onValueChange={currentAmount =>
                      propagateChange('currentAmount', currentAmount)
                    }
                    onChangeEmpty={
                      isMassUpdate
                        ? currentAmount =>
                            propagateChange(
                              'currentAmount',
                              currentAmount === null
                                ? MassUpdateValues.NO_CHANGES
                                : Number(currentAmount),
                            )
                        : undefined
                    }
                    disabled={isSectionDisabled}
                  />
                </Grid.Item>
                <Grid.Item
                  sm={6}
                  md={4}
                  lg={3}
                  csx={{
                    flexDirection: 'column',
                    gap: '15px',
                    justifyContent: 'flex-end',
                  }}>
                  <CheckboxMassUpdate
                    label={t(
                      'menuScreen.itemDetails.inventorySection.showQuantityOnItemCard',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.showQuantityOnItemCardtoolTip',
                    )}
                    checked={itemData ? itemData.showQuantityOnItemCard : false}
                    onChange={showQuantityOnItemCard =>
                      propagateChange(
                        'showQuantityOnItemCard',
                        showQuantityOnItemCard,
                      )
                    }
                    isDisabled={isSectionDisabled}
                    onMassUpdateChange={showQuantityOnItemCard =>
                      setValue({ showQuantityOnItemCard }, sectionId)
                    }
                    {...isMassUpdateProp}
                  />
                  <CheckboxMassUpdate
                    label={t(
                      'menuScreen.itemDetails.inventorySection.resetCurrentStockDaily',
                    )}
                    checked={itemData ? itemData.resetStockDaily : false}
                    onChange={resetStockDaily =>
                      propagateChange('resetStockDaily', resetStockDaily)
                    }
                    isDisabled={isSectionDisabled}
                    onMassUpdateChange={resetStockDaily =>
                      setValue({ resetStockDaily }, sectionId)
                    }
                    {...isMassUpdateProp}
                  />
                </Grid.Item>
                <Grid.Item
                  sm={6}
                  md={4}
                  lg={3}
                  csx={{
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}>
                  <Dropdown
                    label={t(
                      'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.label',
                    )}
                    placeholder={t(
                      'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.placeholder',
                    )}
                    info={t(
                      'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.toolTip',
                    )}
                    data={[
                      ...(isMassUpdate
                        ? [
                            [
                              {
                                label: t('commonTexts.noChanges'),
                                value: -1,
                              },
                            ],
                          ]
                        : []),
                      [
                        {
                          label: t(
                            'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.options.null',
                          ),
                          value: actionToValue(EStockZeroActions.NONE),
                        },
                        {
                          label: t(
                            'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.options.86',
                          ),
                          value: actionToValue(EStockZeroActions.SET_86),
                        },
                        {
                          label: t(
                            'menuScreen.itemDetails.inventorySection.actionWhenCurrentStockZero.options.resetToInitial',
                          ),
                          value: actionToValue(
                            EStockZeroActions.RESET_TO_INITIAL,
                          ),
                        },
                      ],
                    ]}
                    onChange={currentStockZero => {
                      const newValue = valueToAction(currentStockZero);
                      propagateChange('actionWhenCurrentStockZero', newValue);
                    }}
                    value={actionToValue(actionWhenCurrentStockZero)}
                    isDisabled={isSectionDisabled}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </>
        )}
      </Grid>
    </AccordionSection>
  );
};

export default InventorySection;
