import Box from '@app/components/common/Box';
import OptionsModal from '@app/components/common/OptionsModal';
import { subcategoriesDropdownOptionsFactory } from '@app/helpers/factories/menu/subcategoriesFactory';
import { selectMenu, selectSubcategories } from '@app/state/menu/menuSelectors';
import { ChildrenType } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

interface ISubcategoryDropdownSection {
  categoryId: number;
  subcategoryId: number;
  onChange: (newSubcategoryId: number | null) => void;
}

const SubcategoryDropdownSection = ({
  categoryId,
  subcategoryId,
  onChange,
}: ISubcategoryDropdownSection) => {
  const { t } = useTranslation();

  const currentMenu = useSelector(selectMenu, shallowEqual);
  const subcategories = useSelector(selectSubcategories, shallowEqual);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const subcategoriesOptions = useMemo(() => {
    return subcategoriesDropdownOptionsFactory(
      Object.keys(currentMenu.subcategories ?? {}).map(Number),
      true,
    )
      .filter(
        subcategory =>
          subcategory.categoryId === categoryId || subcategory.value === 0,
      )
      .map(subcat =>
        subcat.value
          ? {
              ...subcat,
              showStatus: true,
              isActive: currentMenu.subcategories[subcat.value]?.active,
            }
          : subcat,
      );
  }, [categoryId, currentMenu.subcategories]);

  const childrenType = categoryId
    ? currentMenu.categories[categoryId]?.subcategoryIds?.length > 0
      ? ChildrenType.SUBCATEGORY
      : ChildrenType.ITEM
    : '';

  const isCurrentSubcategoryInactive =
    currentMenu.subcategories[subcategoryId]?.active === false;

  const isDisabled =
    subcategoriesOptions.length === 0 || childrenType === 'item' || !categoryId;

  const subcategoryName = subcategories[subcategoryId]?.name || '';

  return (
    <>
      <Box
        csx={theme => ({
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderBottom: `10px solid ${
            isCurrentSubcategoryInactive
              ? theme.colors.semanticRed
              : theme.colors.lighterGrey
          }`,
          margin: '0 auto',
        })}
      />

      <Box>
        <OptionsModal
          isActiveByDefault={isModalOpen}
          value={subcategoryId}
          onCancel={() => setIsModalOpen(false)}
          onChange={onChange}
          title={t(
            'menuScreen.categoryDetails.associations.subcategories.title',
          )}
          options={subcategoriesOptions}
          placeholder={subcategoryName}
          buttonProps={{
            disabled: isDisabled,
            csx: theme => ({
              width: '100%',
              justifyContent: 'space-between',
              paddingInline: '15px',
              borderColor: isCurrentSubcategoryInactive
                ? theme.colors.semanticRed
                : theme.colors.lightGrey,
            }),
          }}
        />
      </Box>
    </>
  );
};

export default SubcategoryDropdownSection;
