import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import TextAreaInput from '@app/components/common/TextAreaInput';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  IGuestReceiptSettings,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestReceiptSettings>(SECTION_ID);

const SuggestedTips = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  return (
    <Card.SubCard
      title={t(
        'loggedIn.settingsModule.guestReceiptSettings.footerDescription.title',
      )}
      optionsLeft={
        <Switch
          checked={data ? data?.showFooterDescription : true}
          onChange={showFooterDescription =>
            setValue<IGuestReceiptSettings>(
              { showFooterDescription },
              SECTION_ID,
            )
          }
          label={t(
            'settingsModule.guestReceiptSettings.footerDescription.showFooterDescription',
          )}
        />
      }>
      <Grid>
        <Grid.Item>
          <TextAreaInput
            label={t(
              'settingsModule.guestReceiptSettings.footerDescription.addFooterDescription',
            )}
            placeholder={t(
              'settingsModule.guestReceiptSettings.footerDescription.addFooterDescription',
            )}
            value={data?.footerDescription || ''}
            onChange={e =>
              setValue<IGuestReceiptSettings>(
                { footerDescription: e.target.value },
                SECTION_ID,
              )
            }
            csx={{ width: '100%' }}
            disabled={!data?.showFooterDescription}
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default SuggestedTips;
