import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import { TCsx } from '@emotion/react';

const ItemComboTableSubcategoryCell = ({
  subcategoryIds,
  ...props
}: {
  subcategoryIds: number[] | null;
  csx?: TCsx;
}) => {
  const subcategoryName = useReportsFilterText('subcategories', subcategoryIds);

  return <Typography {...props}>{subcategoryName}</Typography>;
};

export default ItemComboTableSubcategoryCell;
