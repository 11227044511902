import { ELoadingModalVariants } from '@app/components/common/LoadingModal/types';
import { MENU_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { getPathWithOrgData } from '@app/helpers/navigation';
import router from '@app/router/router';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { setIsLoadingModal } from '../app/actions';
import { addBreadcrumbLocationWeb } from '../menu/menuWebActions';
import { RootState } from '../store';
import { Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { PostRequestActionTypes } from '../types';
import i18n from '../../i18n.config';

export const postRequestMiddlewareWeb: Middleware =
  (_store: MiddlewareAPI<any, RootState>) =>
  (next: Dispatch) =>
  async (action: PostRequestActionTypes) => {
    if (action.type === 'POST_REQUEST_MENU') {
      const { item, bucketSelected, isDeleting } = action.payload;
      if (isDeleting) return;
      router.navigate(-1);

      await new Promise(resolve => setTimeout(resolve, 0));

      _store.dispatch(
        setIsLoadingModal({
          active: true,
          message: `${i18n.t('commonTexts.createdSuccessfully')}! ${i18n.t(
            'commonTexts.loading',
          )} ${item.text}...`,
          variant: ELoadingModalVariants.Loader,
        }),
      );

      const newPath = getPathWithOrgData(
        `${MENU_SCREEN_BY_BUCKET_NAME[bucketSelected]}/${item.id}`,
      );

      await new Promise(resolve => setTimeout(resolve, 2000));
      _store.dispatch(
        addBreadcrumbLocationWeb({
          action: BreadCrumbAction.NAV,
          text: item.text,
          onPress: () => router.navigate(newPath),
          pathURL: newPath,
        }),
      );
      _store.dispatch(
        setIsLoadingModal({
          active: false,
        }),
      );
    }
    return next(action);
  };
