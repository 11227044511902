import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IModifierItem,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const menuModifierItemAssignedModifierGroupsFactory = (
  modifierItems: Record<number, IModifierItem>,
  menuModifierItemAssignedModifierGroupIds: number[],
): IItemCard[] => {
  return menuModifierItemAssignedModifierGroupIds.map((id: number) => {
    const modifierItem = modifierItems[id] || {};

    return {
      id: modifierItem.id,
      title: modifierItem.name,
    };
  });
};

const AssignedModifierItemsSection = ({
  csx,
  t,
}: IAssignedItemsCombosSection) => {
  // Redux
  const { itemData } = useSelector(getMemoizedItemData);

  const modifierItems = useRootSelector(
    state => state.menu.selectedMode.currentMenu.modifierItems,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  // Local state

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedModifierItemsIds = useMemo(
    () => itemData?.parentModifierItemIds || [],
    [itemData],
  );

  const assignedElementsCardList = useMemo(
    () =>
      menuModifierItemAssignedModifierGroupsFactory(
        modifierItems,
        assignedModifierItemsIds,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedModifierItemsIds],
  );

  return (
    <AssignedElements
      type="modifierItems"
      detailsScreenProps={{
        currentRelationsIds: assignedModifierItemsIds.map(id => `${id}`),
        wantedEntity: 'modifierItems',
      }}
      noElementsMessage={t(
        'menuScreen.modifierGroupDetails.associations.modifierItems.emptyCardsMessage',
      )}
      elements={assignedElementsCardList}
      subCardProps={{
        title: t(
          'menuScreen.modifierGroupDetails.associations.modifierItems.title',
          {
            name: itemData ? itemData.name : '',
          },
        ),
        subtitle: t(
          'menuScreen.modifierGroupDetails.associations.modifierItems.description',
        ),
        isEditButtonDisabled: undefined,
        onEditMode: undefined,
        actionOptions: undefined,
        csx,
      }}
      noCardsPadding
      cardsContainerCsx={{
        border: 'none',
      }}
      renderItem={item => {
        const pathURL = `/menu/modifiers/modifier-items/${item.id}`;
        return (
          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Card.Item
              csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
              {...item}
              showRemoveIcon={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: item.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          </Box>
        );
      }}
    />
  );
};

export default AssignedModifierItemsSection;
