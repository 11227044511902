import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuMasterMode,
  selectMenuMode,
} from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  CONFIRMATION_MODAL_INITIAL_STATE,
  FormMode,
  ISpecialMenuTypeVersion,
  MenuTypes,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import MenuCloneModal from '../../../MenuCloneModal';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SUB_NAMES = ['posName' as const, 'checkName' as const];
const SECTION_ID = SectionId.BASIC;
const getMemoizedItemData = makeSelectItemData('specials', SECTION_ID);

interface IBasicSection extends IAccordionSection, WithTranslation {
  menuTypeVersionId: number;
}

const BasicSection = ({
  t,
  menuTypeVersionId,
  ...accordionSectionProps
}: IBasicSection) => {
  // Redux
  const dispatch = useDispatch();

  const { updateSpecial: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );
  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const {
    deleteBucketElementWeb,
    checkForChangesAndNavigateWeb,
    addBreadcrumbLocationWeb,
  } = bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const { itemData: specialData } = useSelector(getMemoizedItemData);
  const menuType = useRootSelector(state => state.menu.selectedMode.menuType);

  const mode = useSelector(selectMenuMode);
  const isMasterMode = useSelector(selectIsMenuMasterMode);

  // Local state
  const [activeCloneModal, setActiveCloneModal] = useState(false);
  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const nameError = useFormError('name');
  const posNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.posName`,
  );

  const checkNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.checkName`,
  );

  const errorMap = {
    posName: posNameError,
    checkName: checkNameError,
  };

  const sectionHasErrors =
    Boolean(nameError?.error) ||
    Boolean(posNameError?.error) ||
    Boolean(checkNameError?.error);

  const activeSwitch = (
    <Switch
      checked={
        specialData
          ? specialData.menuTypeVersions[menuTypeVersionId]?.active
          : true
      }
      onChange={active => {
        setValue({ active }, SECTION_ID, menuTypeVersionId);
        setValue({}, SectionId.MENU_TYPES);
      }}
      showDefaultLabel
    />
  );

  const handleChangeName = (name: string) => {
    const menuTypeVersionsCloned = cloneDeep(
      specialData?.menuTypeVersions || {},
    );
    const previousName = specialData?.name;
    Object.keys(menuTypeVersionsCloned).forEach(key => {
      SUB_NAMES.forEach(subName => {
        const value =
          menuTypeVersionsCloned[Number(key)]?.[
            subName as keyof ISpecialMenuTypeVersion
          ];
        if (!value || value === previousName) {
          (menuTypeVersionsCloned[Number(key)][
            subName as keyof ISpecialMenuTypeVersion
          ] as string) = name;
          if (errorMap[subName]?.error) {
            errorMap[subName]?.clear();
          }
        }
      });
    });
    setValue({ name, menuTypeVersions: menuTypeVersionsCloned }, SECTION_ID);
    setValue({}, SectionId.HIDDEN);
    setValue({}, SectionId.REGISTER);
  };

  const showDeleteDisabled = () => {
    showToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: t('menuScreen.deleteToast', {
        bucket: t('menuScreen.common.bucket.specials.plural'),
      }),
    });
  };

  return (
    <>
      <MenuCloneModal
        active={activeCloneModal}
        bucket="specials"
        onCancel={() => setActiveCloneModal(false)}
        onClose={() => setActiveCloneModal(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.REPLACE,
                text: currentMenu.specials[newId].name,
                onPress: () => navigate(`/menu/specials/${newId}`),
                pathURL: `/menu/specials/${newId}`,
              }),
            onRefresh,
          );
        }}
        name={specialData ? specialData.name : ''}
      />

      <AccordionSection
        title={t('menuScreen.commonFields.basic.title')}
        hasError={sectionHasErrors}
        titleTextContainerCsx={{ minWidth: '170px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={[
          {
            text: t('commonButtons.clone'),
            handler: () => {
              setActiveCloneModal(true);
            },
          },
          {
            text: t('commonButtons.delete'),
            isDisabled: !isMasterMode,
            disabledHandler: showDeleteDisabled,
            handler: () =>
              setShowConfirmationModal({
                active: true,
                title: t('menuScreen.common.bucket.specials.singular'),
                type: 'delete',
                name: specialData.name,
                onSuccess: () => {
                  setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
                  deleteBucketElementWeb();
                },
              }),
          },
        ]}
        optionsDisabled={mode === FormMode.CREATE}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
            description: t(
              'menuScreen.specialDetails.basic.optionsDisabled.creation',
            ),
          })
        }
        disabledLayer={<NoMenuTypeLayer />}
        {...accordionSectionProps}>
        <Grid columnGap={20} rowGap={20}>
          <Grid.Item>{activeSwitch}</Grid.Item>
          <Grid.Item sm={4}>
            <Input
              info={t('menuScreen.itemDetails.basic.nameInfo')}
              label={t('menuScreen.commonFields.basic.name')}
              placeholder={t('menuScreen.commonFields.basic.name')}
              required
              value={specialData ? specialData.name : ''}
              disabled={
                menuType !== MenuTypes.MASTER_MENU && mode === FormMode.UPDATE
              }
              onChange={({ currentTarget: { value } }) =>
                handleChangeName(value)
              }
              error={Boolean(nameError?.error)}
              caption={nameError?.error}
              onFocus={() => {
                nameError && nameError.clear();
              }}
            />
          </Grid.Item>
          <Grid.Item sm={4}>
            <Input
              info={t('menuScreen.itemDetails.basic.posNameInfo')}
              required
              label={t('menuScreen.commonFields.basic.posName')}
              placeholder={t('menuScreen.commonFields.basic.posName')}
              value={
                specialData
                  ? specialData.menuTypeVersions[menuTypeVersionId]?.posName
                  : ''
              }
              onChange={({ currentTarget: { value: posName } }) => {
                setValue({ posName }, SECTION_ID, menuTypeVersionId);
                setValue({}, SectionId.REGISTER);
              }}
              error={Boolean(posNameError?.error)}
              caption={posNameError?.error}
              onFocus={() => {
                posNameError && posNameError.clear();
              }}
            />
          </Grid.Item>
          <Grid.Item sm={4}>
            <Input
              info={t('menuScreen.itemDetails.basic.checkNameInfo')}
              required
              label={t('menuScreen.commonFields.basic.checkName')}
              placeholder={t('menuScreen.commonFields.basic.checkName')}
              value={
                specialData
                  ? specialData.menuTypeVersions[menuTypeVersionId]?.checkName
                  : ''
              }
              onChange={({ currentTarget: { value: checkName } }) =>
                setValue({ checkName }, SECTION_ID, menuTypeVersionId)
              }
              error={Boolean(checkNameError?.error)}
              caption={checkNameError?.error}
              onFocus={() => {
                checkNameError && checkNameError.clear();
              }}
            />
          </Grid.Item>
        </Grid>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
