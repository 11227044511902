import { CARD_WIDTH } from '@app/components/LoggedIn/MyOrganizations/OrganizationCard/types';
import { NAVBAR_HEIGHT } from '@app/components/LoggedIn/NavBar/types';
import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const myOrganizationsContainerStyles = () =>
  css({
    width: '100%',
    height: '100%',
  });

export const myOrganizationsStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '35px',
    paddingInline: '15px',
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    backgroundColor:
      theme.mode === 'dark' ? theme.colors.white : theme.colors.background,
    [MQ_MIN_SMALL]: {
      padding: '35px',
    },
  });

export const organizationsStyles = () =>
  css({
    gap: '15px',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${
      parseInt(CARD_WIDTH) - 110
    }px, 1fr))`,
    width: '100%',
    [MQ_MIN_SMALL]: {
      gap: '20px',
      gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_WIDTH}, 1fr))`,
    },
  });
