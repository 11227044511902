import { MQ_MIN_LARGE, MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const authCardBodyStyles = () =>
  css({
    width: '100%',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    '.formButtons > button': {
      width: '100% !important',
    },
    height: '100%',
  });

export const authCardContainerStyles = (maxHeight?: string, width?: string) => {
  return css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingInline: '40px',
    '.cardLogo': {
      display: 'none',
    },
    height: '100%',
    maxHeight: '100%',
    [MQ_MIN_SMALL]: {
      paddingInline: 0,
      width,
    },
    [MQ_MIN_LARGE]: {
      maxHeight,
      '.cardLogo': {
        display: 'block',
        '> svg': {
          height: '65px',
        },
      },
    },
  });
};
