import Box from '@app/components/common/Box';
import { createOrganizationCardStyles, organizationCardStyles } from './styles';
import Typography from '@app/components/common/Typography';
import Icon from '@app/components/common/Icon';
import { m } from 'framer-motion';
import TTLogo from '@app/components/common/TTLogo.tsx';
import { WithTranslation } from 'react-i18next';

interface IOrganizationCard extends WithTranslation {
  type?: 'org' | 'create';
  id?: number;
  index?: number;
  image?: string;
  name?: string;
  onClick: (id: number) => void;
}

const OrganizationCard = ({
  t,
  id,
  index,
  type = 'org',
  name,
  image,
  onClick,
}: IOrganizationCard) => {
  return type === 'org' && id && index ? (
    <m.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: index * 0.1 } }}
      css={organizationCardStyles}
      onClick={() => onClick(id)}>
      <Box className="logo">
        {image ? <img draggable={false} src={image} /> : <TTLogo />}
      </Box>
      <Box className="organizationName">
        <Typography variant="subtitle" fontWeight="medium" color="textWhite">
          {name}
        </Typography>
      </Box>
    </m.div>
  ) : (
    <m.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 3 * 0.1 } }}
      css={createOrganizationCardStyles}>
      <Icon name="MdAddCircle" size="50px" color="darkGrey" />
      <Typography
        variant="subtitle"
        fontWeight="medium"
        align="center"
        color="darkGrey"
        csx={{ paddingInline: '20px' }}>
        {t('loggedIn.myOrganizations.addOrganization')}
      </Typography>
    </m.div>
  );
};

export default OrganizationCard;
