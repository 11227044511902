import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { ThemeColors } from '@app/theme/types';
import { Theme, useTheme } from '@emotion/react';
import React from 'react';
import { IButton } from '../Button/Button';
import Typography from '../Typography';
import { ITypography } from '../Typography/Typography';

interface ITableCellActionAndIndicatorProps {
  circleColor: ThemeColors;
  titleColor?: ThemeColors;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonProps?: IButton;
  infoWidth?: string;
  buttonWidth?: string;
  replaceButtonComponent?: React.JSX.Element | null;
  titleProps?: ITypography;
  subtitleProps?: ITypography;
  circleSize?: number;
  onPressButton?: () => void;
}

const TableCellActionAndIndicator = ({
  title,
  subtitle,
  circleColor,
  buttonText,
  buttonProps,
  infoWidth,
  buttonWidth,
  titleColor,
  replaceButtonComponent = null,
  titleProps,
  subtitleProps,
  onPressButton,
  circleSize,
}: ITableCellActionAndIndicatorProps) => {
  const theme = useTheme();
  return (
    <Box
      csx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}>
      <Box csx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          csx={{
            borderRadius: '50%',
            backgroundColor: theme.colors[circleColor as keyof Theme['colors']],
            width: circleSize || '15px',
            height: circleSize || '15px',
            minWidth: circleSize || '15px',
            minHeight: circleSize || '15px',
            marginRight: 10,
          }}
        />
        <Box csx={{ width: infoWidth ? infoWidth : 'auto' }}>
          {title && (
            <Typography
              color={titleColor ? titleColor : 'black'}
              {...titleProps}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography csx={{ fontStyle: 'italic' }} {...subtitleProps}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      {replaceButtonComponent !== undefined ? (
        replaceButtonComponent
      ) : (
        <Button
          csx={{ width: buttonWidth ? buttonWidth : 150 }}
          onClick={onPressButton}
          {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default TableCellActionAndIndicator;
