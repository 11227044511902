import Box from '@app/components/common/Box';
import { selectCurrentItem } from '@app/state/menu/menuSelectors';
import { RootState } from '@app/state/store';
import { displayFlexColumnStyles } from '@app/theme/commonStyles';
import { IItem, IItemErrorFields } from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ErrorRow,
  SectionText,
  SubSectionText,
} from './BucketValidationResolver';
import { ErrorTemplates } from './ErrorTemplates';

const RenderItemErrorBody = () => {
  const { t } = useTranslation();
  const formErrors = useSelector(
    (state: RootState) => state.menu.changeData.errors,
  );
  const formMassUpdateErrors = useSelector(
    (state: RootState) => state.menu.changeMassUpdateData.errors,
  );
  const errors = (
    Object.keys(formErrors).length > 0 ? formErrors : formMassUpdateErrors
  ) as IItemErrorFields;
  const errorsArray = Object.entries(errors);
  const menuTypes = useSelector(
    (state: RootState) => state.menu.masterMenu.menuTypes,
  );

  const changeData = useSelector(selectCurrentItem);

  const commonIssues: [string, string][] = errorsArray.filter(
    ([key]) => key !== 'menuTypeVersions',
  );
  const menuTypeIssues =
    'menuTypeVersions' in errors ? errors.menuTypeVersions : null;

  const renderCommonError = (
    mtkey: string,
    mterr: string,
    transLationParams?: Record<string, string>,
  ) => (
    <ErrorRow key={mtkey}>
      <ErrorTemplates
        field={t(
          `menuScreen.itemDetails.validationFields.${mtkey}` as ParseKeys,
        )}
        errorKey={typeof mterr === 'string' ? mterr : ''}
        translationParams={transLationParams}
      />
    </ErrorRow>
  );

  const renderCustomIdError = (upcError: string) => {
    const customIdItemErrorName = upcError.replace(
      '<<uniqueCustomIdRequired>>',
      '',
    );

    return renderCommonError('customId', 'uniqueCustomIdRequired', {
      name: customIdItemErrorName,
    });
  };

  return (
    <>
      {commonIssues.length > 0 && (
        <Box
          csx={[
            displayFlexColumnStyles,
            { gap: '10px', marginBottom: '15px' },
          ]}>
          {commonIssues.map(([key, value]) =>
            key === 'customId' ? (
              renderCustomIdError(value as string)
            ) : (
              <ErrorRow key={key}>
                <ErrorTemplates
                  errorKey={value}
                  field={t(
                    `menuScreen.itemDetails.validationFields.${key}` as ParseKeys,
                  )}
                />
              </ErrorRow>
            ),
          )}
        </Box>
      )}
      {menuTypeIssues && (
        <Box csx={[displayFlexColumnStyles, { gap: '15px' }]}>
          <SectionText>{t('validations.issuesInMenuTypes')}</SectionText>
          {Object.entries(menuTypeIssues).map(([key, value]) => (
            <Box key={key} csx={[displayFlexColumnStyles, { gap: '10px' }]}>
              <SubSectionText>
                {t('validations.menuTypeTitle', {
                  menuType: menuTypes[Number(key)]?.posName,
                })}
              </SubSectionText>
              {Object.entries(value).map(([mtkey, mterr]) =>
                mtkey === 'ingredients'
                  ? Object.entries(mterr).map(([igId, ig]) => (
                      <Box key={igId}>
                        <SubSectionText>
                          {t('validations.ingredientTitle', {
                            itemGroup: (changeData as IItem).menuTypeVersions[
                              Number(key)
                            ].ingredients[Number(igId)].name,
                          })}
                        </SubSectionText>
                        {Object.entries(ig).map(([igErrorKey, igError]) => (
                          <ErrorRow key={igErrorKey}>
                            <ErrorTemplates
                              field={t(
                                `menuScreen.itemDetails.validationFields.${igErrorKey}` as ParseKeys,
                              )}
                              errorKey={igError as string}
                            />
                          </ErrorRow>
                        ))}
                      </Box>
                    ))
                  : renderCommonError(mtkey, mterr as string),
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default RenderItemErrorBody;
