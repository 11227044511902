import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsApp } from '@app/state';
import { selectRefreshToast } from '@app/state/selectors/appSelectors';
import { store } from '@app/state/store';
import { CoreRootState, RefreshToastVariant } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const RefreshBucketToast = () => {
  // Redux
  const dispatch = useDispatch();
  const { showToast } = bindActionCreators(actionCreatorsApp, dispatch);
  const { title, isActive, variant } = useSelector(selectRefreshToast);

  const onRefreshSettings = useRefreshSettingsWeb();
  const onRefreshMenu = useRefreshMenuWeb();

  useEffect(() => {
    if (isActive) {
      showToast({
        type: 'info',
        title,
        timeout: 5000,
      });
      variant === RefreshToastVariant.MENU
        ? onRefreshMenu()
        : onRefreshSettings(store.getState() as unknown as CoreRootState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return null;
};

export default RefreshBucketToast;
