import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { EAccordionSectionOptionType } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import DisabledBlockLayer from '@app/components/common/DisabledBlockLayer';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Switch from '@app/components/common/Switch';
import SwitchMassUpdate from '@app/components/common/SwitchMassUpdate';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import {
  selectCurrentErrors,
  selectIsMenuMasterMode,
  selectMasterIngredients,
} from '@app/state/menu/menuSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  FormMode,
  IIngredientItem,
  IItemErrorFields,
  INGREDIENT_ITEMS_INITIAL_STATE,
  actionCreatorsMenu,
  generateTabletGeneratedId,
  propagateString,
} from '@westondev/tableturn-core';
import { t } from 'i18next';
import { cloneDeep, max, orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import SectionLayer from '../../../common/SectionLayer';
import { IItemDetailsSection } from '../types';

interface IIngredientsTable {
  id: number | string;
  status: boolean;
  isSelected: boolean;
  name: string;
  posName: string;
  delete: string;
  sortPriority: number;
}

const columnHelper = createColumnHelper<IIngredientsTable>();

const IngredientsSection = ({
  menuTypeVersionId,
  sectionId,
  itemData,
  isDisabled,
  mode,
  ...props
}: IItemDetailsSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const masterMenuIngredients = useSelector(selectMasterIngredients);
  const errors = useSelector(selectCurrentErrors);

  // Local state
  const [additionModal, setAdditionModal] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ingredients = itemData?.ingredients ?? {};
  const ingredientIds = useMemo(
    () => Object.keys(ingredients || {}),
    [ingredients],
  );

  const ingredientsErrors = (errors as IItemErrorFields)?.menuTypeVersions?.[
    menuTypeVersionId
  ]?.ingredients;

  const hasIngredientsError = Object.values(ingredientsErrors || {}).some(
    ingredientError => Object.keys(ingredientError).length > 0,
  );

  const updateIngredient = (
    newValue: any,
    id: number | string,
    field: keyof IIngredientItem,
  ) => {
    setValue(
      {
        ingredients: {
          ...ingredients,
          [id]: {
            ...ingredients[id],
            [field]: newValue,
          },
        },
      },
      sectionId,
      menuTypeVersionId,
    );
  };

  const updateName = (newName: string, id: number | string) => {
    const currentIngredient = ingredients[id];
    const prevName = currentIngredient.name;
    const propagateObj = {
      name: prevName,
      posName: currentIngredient.posName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue(
      {
        ingredients: {
          ...ingredients,
          [id]: {
            ...ingredients[id],
            ...newObj,
          },
        },
      },
      sectionId,
      menuTypeVersionId,
    );
  };

  const handleDelete = useCallback(
    (id: number | string) => {
      let sortPriority = 1;
      const ingredientsCloned = cloneDeep(ingredients);
      delete ingredientsCloned[id];
      const ingredientsClonedArray = Object.values(ingredientsCloned);

      orderBy(ingredientsClonedArray, 'sortPriority').forEach(value => {
        ingredientsCloned[value.ingredientId].sortPriority = sortPriority;
        sortPriority++;
      });

      setValue(
        {
          ingredients: ingredientsCloned,
        },
        sectionId,
        menuTypeVersionId,
      );
    },
    [ingredients, sectionId, menuTypeVersionId, setValue],
  );

  const ingredientsArray = useMemo(
    () =>
      orderBy(
        Object.values(itemData?.ingredients || {}).map(ingredient => ({
          id: ingredient.ingredientId,
          status: ingredient.active,
          isSelected: ingredient.isSelected,
          name: ingredient.name,
          posName: ingredient.posName,
          delete: '',
          sortPriority: ingredient.sortPriority,
        })),
        'sortPriority',
      ),
    [itemData?.ingredients],
  );

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: t('settingsModule.registerModeSettings.general.modTags.status'),
        cell: info => (
          <Switch
            key={`status_${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isActive => {
              updateIngredient(isActive, info.row.original.id, 'active');
            }}
          />
        ),
        size: 80,
      }),
      columnHelper.accessor('isSelected', {
        header: t('menuScreen.itemDetails.ingredients.table.isSelected'),
        cell: info => (
          <Switch
            key={`isSelected_${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isSelected => {
              updateIngredient(isSelected, info.row.original.id, 'isSelected');
            }}
          />
        ),
        size: 80,
      }),
      columnHelper.accessor('name', {
        header: t('menuScreen.itemDetails.basic.name'),
        cell: info => {
          return (
            <InputCell
              required
              isMenuInput
              disabled={!info.row.original.delete && !isMasterMode}
              placeholder="Name"
              value={info.getValue()}
              onChange={e => updateName(e.target.value, info.row.original.id)}
              errorId={`menuTypeVersions.${menuTypeVersionId}.ingredients.${info.row.original.id}.name`}
            />
          );
        },
      }),
      columnHelper.accessor('posName', {
        header: t('menuScreen.itemDetails.basic.posName'),
        cell: info => (
          <InputCell
            required
            isMenuInput
            placeholder={t('menuScreen.itemDetails.basic.posName')}
            value={info.getValue()}
            onChange={e => {
              updateIngredient(e.target.value, info.row.original.id, 'posName');
            }}
            errorId={`menuTypeVersions.${menuTypeVersionId}.ingredients.${info.row.original.id}.posName`}
          />
        ),
      }),
      columnHelper.accessor('delete', {
        header: t('commonButtons.delete'),
        cell: info => {
          return (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              disabled={
                !isMasterMode && !ingredients[info.row.original.id]?.createdAt
              }
              onClick={() => handleDelete(info.row.original.id)}
            />
          );
        },
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode, menuTypeVersionId, ingredientsArray],
  );

  const activeSwitch = (
    <SwitchMassUpdate
      checked={itemData ? itemData?.showIngredients : false}
      onChange={showIngredients =>
        setValue({ showIngredients }, sectionId, menuTypeVersionId)
      }
      isDisabled={isDisabled}
      label={t('menuScreen.itemDetails.ingredients.showIngredients')}
      onMassUpdateChange={isActive => {
        setValue({ showIngredients: isActive }, sectionId, menuTypeVersionId);
      }}
      isMassUpdate={mode === FormMode.MASS_UPDATE}
    />
  );

  const onNewIngredient = () => {
    const nextId = generateTabletGeneratedId();

    const nextSortPriority =
      (max(
        Object.values(itemData?.ingredients || {}).map(
          ingredient => ingredient.sortPriority,
        ) || [],
      ) || 0) + 1;

    setValue(
      {
        ingredients: {
          ...itemData?.ingredients,
          [nextId]: {
            ...INGREDIENT_ITEMS_INITIAL_STATE,
            id: nextId,
            ingredientId: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      sectionId,
      menuTypeVersionId,
    );
  };

  const handleOnAssociate = (newIngredients: IItemSelection[]) => {
    let nextSortPriority =
      (max(
        Object.values(itemData?.ingredients || {}).map(
          ingredient => ingredient.sortPriority,
        ) || [],
      ) || 0) + 1;

    let clonedIngredients = cloneDeep(itemData?.ingredients || {});

    newIngredients.forEach(newIngredient => {
      const newIngredientId = Number(newIngredient.id);
      const newMenuIngredient = masterMenuIngredients[newIngredientId];
      clonedIngredients = {
        ...clonedIngredients,
        [newIngredientId]: {
          ...newMenuIngredient,
          sortPriority: nextSortPriority++,
          isSelected: true,
        },
      };
    });

    setValue({ ingredients: clonedIngredients }, sectionId, menuTypeVersionId);
    setAdditionModal(false);
  };

  const handleOnSortEnd = (newData: IIngredientsTable[]) => {
    const clonedIngredients = cloneDeep(itemData?.ingredients || {});
    newData.forEach((item, index) => {
      clonedIngredients[item.id].sortPriority = index + 1;
    });
    setValue(
      {
        ingredients: clonedIngredients,
      },
      sectionId,
      menuTypeVersionId,
    );
  };

  return (
    <>
      <SelectionModal
        type="ingredients"
        mainScreenProps={{
          entity: 'ingredients',
          currentRelationsIds: ingredientIds,
        }}
        showDescriptionPanel={false}
        showSelectedButtons
        active={additionModal}
        onModalClose={() => setAdditionModal(false)}
        onAssociate={handleOnAssociate}
      />
      <AccordionSection
        disabledLayer={isDisabled && <NoMenuTypeLayer />}
        title={t('menuScreen.itemDetails.ingredients.title')}
        hasError={hasIngredientsError}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        titleTextContainerCsx={{ minWidth: '150px' }}
        options={[
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
            handler: () => onNewIngredient(),
            isDisabled: !isMasterMode,
          },
          {
            text: t(
              'menuScreen.menuLocation.actionButtons.addExistingIngredient',
            ),
            handler: () => setAdditionModal(true),
          },
        ]}
        optionType={EAccordionSectionOptionType.ACTION_BUTTON}
        optionsDisabled={!itemData?.showIngredients}
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
          <Box csx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>{activeSwitch}</Box>
          </Box>
          <Box
            csx={{
              position: 'relative',
            }}>
            {ingredientsArray.length > 0 && (
              <Table
                mode={ETableModes.SORT_VERTICALLY}
                columns={COLUMN_VALUES}
                data={ingredientsArray}
                cellCsx={{
                  padding: '10px',
                  height: '80px',
                }}
                onSortEnd={handleOnSortEnd}
                requiredColumns={['name', 'posName']}
              />
            )}

            {itemData?.showIngredients && ingredientsArray.length === 0 ? (
              <Box csx={{ height: '70px' }}>
                <DisabledBlockLayer>
                  <SectionLayer
                    title={t(
                      'menuScreen.itemDetails.ingredients.table.noIngredientsAssignedToThisItem',
                    )}
                  />
                </DisabledBlockLayer>
              </Box>
            ) : (
              !itemData?.showIngredients && (
                <Box
                  csx={{
                    height: ingredientsArray.length === 0 ? '70px' : 'auto',
                  }}>
                  <DisabledBlockLayer csx={{ top: '0px' }}>
                    <SectionLayer
                      title={t(
                        'menuScreen.itemDetails.ingredients.ingredientsHidden',
                      )}
                    />
                  </DisabledBlockLayer>
                </Box>
              )
            )}
          </Box>
        </Box>
      </AccordionSection>
    </>
  );
};

export default IngredientsSection;
