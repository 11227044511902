import Box from '@app/components/common/Box';
import { loginCardStyles } from './styles';
import Typography from '@app/components/common/Typography';
import Input from '@app/components/common/Input';
import Button from '@app/components/common/Button';
import Link from '@app/components/common/Link';
import { FormEvent, useState } from 'react';
import AuthCard from '@app/components/Authentication/AuthCard';
import {
  linkWithText,
  loginAuthBodySeparation,
} from '@app/components/Authentication/styles';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '@app/state/selectors/appSelectors';
import { useAppDispatch } from '@app/state/store';
import { signIn } from '@app/state/app/actions';
import { useNavigate } from 'react-router-dom';
import { createBroadCastChannel } from '@app/helpers/broadcast';

const SignIn = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);

  // Local state
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState(false);

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await dispatch(signIn(formData.email, formData.password));

    if (isValid) {
      const continuePage = new URLSearchParams(window.location.search).get(
        'continue',
      );
      if (continuePage) {
        return navigate(continuePage);
      }
      const broadcastChannel = createBroadCastChannel('auth');
      broadcastChannel.postMessage({
        action: 'login',
        redirect: '/my-organizations',
      });
    } else {
      setError(true);
    }
  };

  const handleSetFormData = (e: FormEvent<HTMLInputElement>, field: string) => {
    const value = e.currentTarget.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    setError(false);
  };

  return (
    <AuthCard csx={loginCardStyles}>
      <Box className="form" csx={loginAuthBodySeparation}>
        <Typography variant="heading" fontWeight="medium" className="title">
          {t('authentication.welcomeBack')}
        </Typography>
        <form className="formContent" onSubmit={handleSubmitForm}>
          <Input
            className="inputLogin"
            name="email"
            label={t('commonTexts.email')}
            placeholder={t('commonTexts.emailExample')}
            type="email"
            value={formData.email}
            required
            error={error}
            hideRequiredSymbol
            onChange={e => handleSetFormData(e, 'email')}
          />
          <Box className="passwordSection">
            <Input
              className="inputLogin"
              name="password"
              label={t('commonTexts.password')}
              type="password"
              placeholder={t('authentication.login.insertPassword')}
              value={formData.password}
              required
              hideRequiredSymbol
              error={error}
              showCaptionSpace={!error}
              caption={
                error
                  ? t('authentication.login.incorrectEmailOrPassword')
                  : undefined
              }
              onChange={e => handleSetFormData(e, 'password')}
            />
            <Link to="/auth/forgot-password" className="forgotPassword">
              {t('authentication.forgotPassword.forgotPassword')}
            </Link>
          </Box>

          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}
            className="formButtons">
            <Button type="submit" variant="active" isLoading={isLoading}>
              {t('commonButtons.signIn')}
            </Button>
            <Box csx={linkWithText}>
              <Typography variant="body" fontWeight="medium" color="darkGrey">
                {t('authentication.login.dontHaveAnAccount')}
              </Typography>
              <Link to="/auth/sign-up" reloadDocument>
                {t('authentication.login.signUp')}
              </Link>
            </Box>
          </Box>
        </form>
      </Box>
    </AuthCard>
  );
};

export default SignIn;
