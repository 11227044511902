import { MQ_MAX_LARGE } from '@app/theme/types';
import { css } from '@emotion/react';

export const customizerSubsectionStyles = () =>
  css({
    display: 'flex',
    alignItems: 'center',
    borderTopWidth: '1px',
    padding: '10px',
    paddingTop: '20px',
    justifyContent: 'center',
    [MQ_MAX_LARGE]: {
      alignItems: 'flex-start',
    },
  });
