import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { passwordChangedStyles, separatorStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import Confetti from '@app/components/common/Animations/ConfettiExplosion';

const PasswordChangedMessage = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  return (
    <>
      <Confetti />
      <Box csx={passwordChangedStyles}>
        <Box csx={{ position: 'relative', marginBottom: '15px' }}>
          <Icon name="MdLockOutline" size="110px" />
          <Icon
            name="MdCheckCircle"
            color="semanticGreen"
            size="45px"
            csx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              background: 'white',
              borderRadius: '100%',
            }}
          />
        </Box>
        <Typography
          align="center"
          variant="heading"
          fontWeight="medium"
          className="title">
          {t('authentication.passwordChanged.passwordChanged')}
        </Typography>
        <Typography align="center" color="darkestGrey">
          {t(
            'authentication.passwordChanged.passwordHasBeenSuccessfullyChanged',
          )}
        </Typography>
        <Box csx={separatorStyles} />
        <Button
          variant="active"
          csx={{ width: '100%' }}
          onClick={() => navigate('/auth/sign-in')}>
          {t('authentication.passwordChanged.backToSignIn')}
        </Button>
      </Box>
    </>
  );
};

export default PasswordChangedMessage;
