import { TCsx } from '@emotion/react';
import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import ItemCard from './ItemCard';
import SubCard from './SubCard';
import { cardStyles } from './styles';
import SubCardOptions from './SubCardOptions';
import ItemCardWithSwitch from './ItemCardWithSwitch';
import ItemButton from './ItemButton';
interface ICard {
  children?: React.ReactNode;
  title?: string;
  csx?: TCsx;
}

const Card = ({ children, title, csx }: ICard) => {
  return (
    <Box csx={[cardStyles, csx]}>
      {title && (
        <Typography
          color="persistentSemanticBlue"
          fontWeight="medium"
          csx={{ marginBottom: '25px' }}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

Card.Item = ItemCard;
Card.ItemWithSwitch = ItemCardWithSwitch;
Card.SubCard = SubCard;
Card.SubCardOptions = SubCardOptions;
Card.ItemButton = ItemButton;

export default Card;
