import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import { TCsx } from '@emotion/react';

const ItemComboTableReportingGroupCell = ({
  reportingGroupIds,
  ...props
}: {
  reportingGroupIds: number[] | null;
  csx?: TCsx;
}) => {
  const reportingGroupName = useReportsFilterText(
    'reportingGroups',
    reportingGroupIds,
  );

  return <Typography {...props}>{reportingGroupName}</Typography>;
};

export default ItemComboTableReportingGroupCell;
