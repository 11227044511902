import { useCallback } from 'react';
import Icon from '../Icon';
import Input from '../Input';
import { IInput } from '../Input/Input';
import debounce from 'lodash/debounce';

interface ISearchInput extends Omit<IInput, 'onChange'> {
  debounceTimeout?: number;
  onChange?: (value: string) => void;
  onDebounce?: (newText: string) => void;
  onClearPress?: (newText: string) => void;
}
const SearchInput = ({
  debounceTimeout = 300,
  onChange,
  onDebounce,
  onClearPress,
  ...otherProps
}: ISearchInput) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFunc = useCallback(
    debounce(
      (newText: string) => onDebounce && onDebounce(newText),
      debounceTimeout,
    ),
    [],
  );

  const handleSetInputValue = (newText: string) => {
    if (onDebounce) {
      debounceFunc(newText);
    }

    onChange && onChange(newText);
  };

  return (
    <Input
      icon={
        otherProps.value ? (
          <Icon name="MdClear" />
        ) : (
          <Icon name="FaSearch" size="21px" />
        )
      }
      placeholder={otherProps.placeholder || 'Search'}
      onIconPress={() => {
        otherProps.value && handleSetInputValue('');
        onClearPress && onClearPress('');
      }}
      onChange={e => handleSetInputValue(e.target.value)}
      {...otherProps}
    />
  );
};

export default SearchInput;
