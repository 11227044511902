import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { WithTranslation } from 'react-i18next';
import AssignedItemsSection from './AssignedItemsSection';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { upperCase } from 'lodash';

const AssociationsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  return (
    <AccordionSection
      title={
        <Box>
          <Typography>
            {upperCase(t('commonTexts.associated'))}
            {': '}
            <Typography
              csx={{
                fontStyle: 'italic',
                display: 'inline',
              }}>
              {t('menuScreen.ingredientDetails.associations.title')}
            </Typography>
          </Typography>
        </Box>
      }
      {...props}>
      <AssignedItemsSection
        csx={{
          borderWidth: 0,
          padding: 0,
          borderRadius: 0,
        }}
      />
    </AccordionSection>
  );
};

export default AssociationsSection;
