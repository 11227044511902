import {
  actionCreatorsReports,
  EReportsType,
  FormMode,
  getItemComboSalesReportInitialState,
  getReportsChangeDataInitialState,
  getSalesOverviewReportInitialState,
  getEndOfShiftReportInitialState,
  IReportsStore,
  reportsSelectors,
  TReportsPostRequest,
  TReportsStoreData,
} from '@westondev/tableturn-core';
import { AppThunk } from '../store';
import { ILoadReportChangeData, THandleReportsChangeArg } from './types';
import cloneDeep from 'lodash/cloneDeep';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { getRequiredActionsData } from '../menu/menuWebActions';
import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import router from '@app/router/router';

const {
  setReportsSelectedMode,
  setReportsChangeData,
  setReportsChangeDataValues,
  cancelReportsChangesOnPress,
  checkReportsForChangesAndNavigate,
  saveReportsChangesOnSavePress,
  deleteReport,
  fetchReport,
} = actionCreatorsReports;
const {
  selectItemComboSalesReportById,
  selectSalesOverviewReportById,
  selectEndOfShiftReportById,
} = reportsSelectors;

export const changeSelectedReportsModeWeb =
  (locationId: number, locationName: string): AppThunk =>
  (dispatch, getState) => {
    const getSettingsType = (id: number) => {
      // const currentLocationId = getState().app.locationId;
      switch (id) {
        case 0:
          return EReportsType.MASTER;
        // case currentLocationId:
        default:
          return EReportsType.LOCATION;
      }
    };

    const settingsType = getSettingsType(locationId);

    const { location, master } = getState().reports;

    switch (settingsType) {
      case EReportsType.MASTER:
        dispatch(
          setReportsSelectedMode({
            type: EReportsType.MASTER,
            currentReports: master,
            id: 0,
            name: 'Enterprise',
          }),
        );

        break;
      default:
        dispatch(
          setReportsSelectedMode({
            type: EReportsType.LOCATION,
            currentReports: location,
            id: locationId,
            name: locationName,
          }),
        );
        break;
    }
  };

export const loadReportBucketChangeData =
  ({ id = 0, bucket }: ILoadReportChangeData): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const mode = id ? FormMode.UPDATE : FormMode.CREATE;
    const isCreation = mode === FormMode.CREATE;

    let report: IReportsStore[typeof bucket][number] | null = null;

    if (!isCreation)
      switch (bucket) {
        case 'itemComboSalesReports':
          report = selectItemComboSalesReportById(state, id);
          break;

        case 'salesOverviewReports':
          report = selectSalesOverviewReportById(state, id);
          break;

        case 'endOfShiftReports':
          report = selectEndOfShiftReportById(state, id);
          break;

        default:
          return;
      }

    if (isCreation)
      switch (bucket) {
        case 'itemComboSalesReports':
          report = getItemComboSalesReportInitialState();
          break;

        case 'salesOverviewReports':
          report = getSalesOverviewReportInitialState();
          break;

        case 'endOfShiftReports':
          report = getEndOfShiftReportInitialState();
          break;

        default:
          return;
      }

    if (!report) return;

    dispatch(
      setReportsChangeData({
        ...getReportsChangeDataInitialState(),
        id,
        bucket,
        mode,
        data: cloneDeep(report),
        originalData: cloneDeep(report),
      }),
    );
  };

export const handleReportsChange =
  <T extends TReportsStoreData>(v: THandleReportsChangeArg<T>): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const oldData = state.reports.changeData.data as T | null;

    if (!oldData) {
      return;
    }

    const newValue = typeof v === 'function' ? v(oldData) : v;

    dispatch(setReportsChangeDataValues(newValue));
  };

const postReportsSave: TReportsPostRequest = (id, bucket) => () => {
  const url = getPathWithOrgData(REPORTS_SCREEN_BY_BUCKET_NAME[bucket]);
  router.navigate(-1);
  if (!id) {
    return;
  }

  setTimeout(() => {
    router.navigate(`${url}/${id}`);
  }, 0);
};

export const onReportPDF = (
  data: ArrayBuffer | Uint8Array,
): Promise<string> => {
  try {
    const file = new Blob([data], { type: 'application/pdf' });
    return Promise.resolve(URL.createObjectURL(file));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkForReportsChangesAndNavigateWeb =
  (
    onContinue: () => void,
    onRefresh: () => void,
    onContinueResetChangeData = true,
    isSavePressed = false,
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      checkReportsForChangesAndNavigate(
        onContinue,
        onRefresh,
        () => () => null,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
        onContinueResetChangeData,
        isSavePressed,
      ) as unknown as AppThunk<void>,
    );
  };

export const saveReportsChangesOnSaveClickWeb =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    dispatch(
      saveReportsChangesOnSavePress(
        () => null,
        postReportsSave,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
      ),
    ) as unknown as AppThunk;
  };

export const cancelReportsChangesOnPressWeb =
  (): AppThunk => async dispatch => {
    const onNavigate = (bucketName: keyof IReportsStore) => {
      const baseRoute = REPORTS_SCREEN_BY_BUCKET_NAME[bucketName];
      if (!baseRoute) return;
      const route = getPathWithOrgData(baseRoute);
      router.navigate(route);
    };

    dispatch(cancelReportsChangesOnPress(onNavigate));
  };

export const deleteReportWeb =
  (id: number, bucket: keyof IReportsStore, navigate = false): AppThunk =>
  (dispatch, getState) => {
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(getState());

    dispatch(
      deleteReport(
        id,
        bucket,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
      ),
    )
      .then(() => {
        if (!navigate) return;

        const url = getPathWithOrgData(REPORTS_SCREEN_BY_BUCKET_NAME[bucket]);
        router.navigate(url);
      })
      .catch(() => null);
  };

export const fetchReportPDFWeb = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { organizationId, currentLocationId, licenseId } =
    getRequiredActionsData(state);

  const orgUserId = state.loggedInUser.organizationUserId;

  return dispatch(
    fetchReport(
      onReportPDF,
      orgUserId,
      organizationId,
      currentLocationId,
      licenseId,
      webErrorCodesDispatcher,
    ),
  );
};
