import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';

import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import {
  ILicenseSettingsStore,
  getSendAndPayOverrideDropdownOptions,
  getSendToKitchenOverride,
  sendAndPayOverrideValue,
  sendToKitchenOverrideValue,
  valueToSendAndPayOverride,
  valueToSendToKitchenOverride,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';

interface IQuickServiceOrderSettings extends IAccordionSection {
  data: ILicenseSettingsStore;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
}

const QuickServiceOrderSettings = ({
  updateInfo,
  data,
  ...sectionProps
}: IQuickServiceOrderSettings) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;
  return (
    <AccordionSection
      isExpanded={true}
      isExpandable={false}
      expandedHeight="70px"
      title={t(
        'settingsModule.licenseSettings.quickServiceOrderSettings.title',
      )}
      description={t(
        'settingsModule.licenseSettings.quickServiceOrderSettings.description',
      )}
      {...otherProps}>
      <DetailsSubtitle>
        {t(
          'settingsModule.licenseSettings.quickServiceOrderSettings.customerFacingDisplay',
        )}
      </DetailsSubtitle>
      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={6} lg={4} xl={3}>
          <Switch
            label={t(
              'settingsModule.licenseSettings.quickServiceOrderSettings.useCFD',
            )}
            checked={data.useCFD}
            onChange={value => updateInfo('useCFD', value)}
          />
        </Grid.Item>
      </Grid>

      <DetailsSubtitle
        csx={{
          marginTop: '20px',
        }}>
        {t(
          'settingsModule.licenseSettings.quickServiceOrderSettings.sendAndPaymentSettings',
        )}
      </DetailsSubtitle>

      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={6} lg={4} xl={3}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.quickServiceOrderSettings.sendAndPaymentActionButtons',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={getSendAndPayOverrideDropdownOptions()}
            value={
              sendAndPayOverrideValue[
                data.qsrSendAndPayActionButtonsOverride
                  ? data.qsrSendAndPayActionButtonsOverride
                  : 'default'
              ]
            }
            onChange={value =>
              updateInfo(
                'qsrSendAndPayActionButtonsOverride',
                valueToSendAndPayOverride[value],
              )
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.quickServiceOrderSettings.sendOrderToKitchenWhen',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={getSendToKitchenOverride()}
            value={
              sendToKitchenOverrideValue[
                data.sendOrderToKitchenOverride
                  ? data.sendOrderToKitchenOverride
                  : 'default'
              ]
            }
            onChange={value =>
              updateInfo(
                'sendOrderToKitchenOverride',
                valueToSendToKitchenOverride[value],
              )
            }
          />
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default QuickServiceOrderSettings;
