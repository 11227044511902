import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const infoIconStyles =
  (theme: Theme) =>
  (
    size: string | undefined,
    outline: boolean,
    color: string,
    backgroundColor: string | undefined,
  ) =>
    css({
      border: outline ? `1px solid ${color}` : '1px solid transparent',
      borderRadius: '100px',
      paddingInline: '3px',
      paddingBlock: '3px',
      backgroundColor: outline
        ? 'transparent'
        : backgroundColor || theme.colors.lighterGrey,
      '> svg': {
        width: size || '10px',
        height: size || '10px',
      },
      display: 'flex',
      [MQ_MIN_SMALL]: {
        paddingInline: '4px',
        paddingBlock: '4px',
      },
    });
