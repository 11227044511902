import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const genericSelectionModalStyles =
  (showDescriptionPanel = false) =>
  (theme: Theme) =>
    css({
      height: '75%',
      '.modalContent': {
        display: 'grid',
        gridTemplateAreas: `
      "groups"
      ${showDescriptionPanel ? '"descriptionPanel"' : ''}
      "selectedOptions"
      `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: `${
          showDescriptionPanel ? 'auto 1fr auto' : '1fr auto'
        }`,
        overflow: 'hidden',
        '.itemCard': {
          borderColor: theme.colors.lightGrey,
          ':hover': {
            backgroundColor: theme.colors.lighterBlue,
            border: `2px solid ${theme.colors.semanticBlue}`,
          },
          '&.isSelected': {
            border: `2px solid ${theme.colors.semanticBlue} !important`,
            ':hover': {
              backgroundColor: theme.colors.semanticBlue,
            },
          },
        },
        '.optionsContainer': {
          display: 'flex',
          gridArea: 'groups',
          width: '100%',
          overflow: 'hidden',
          backgroundColor: 'white',
          borderBottom: showDescriptionPanel
            ? `1px solid ${theme.colors.lightGrey}`
            : 'none',
          padding: '10px',
          maxHeight: showDescriptionPanel ? '280px' : '100%',
        },
        '.descriptionPanel': {
          gridArea: 'descriptionPanel',
          borderRight: 'none',
          borderBottom: 'none',
          borderLeft: 'none',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: theme.colors.background,
          '.emptySectionContainer': {
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '.searchBar': {
            width: '100%',
            padding: '10px 15px 0px 15px',
          },
          '.noOptions': {
            padding: '10px',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          },
        },
        '.selectedOptions': {
          gridArea: 'selectedOptions',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          '.selectedOptionsHeaderContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
            borderBlock: `1px solid ${theme.colors.lightGrey}`,
          },
          '.selectedOptionsContent': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: '10px',
            padding: '10px',
            overflow: 'visible',
            overflowX: 'auto',
            '.removeIcon': {
              zIndex: 1000,
            },
            '.itemCard': {
              width: '140px',
              ':hover': {
                cursor: 'default',
              },
            },
          },
          '.noOptionsSelectedContainer': {
            display: 'flex',
            height: '70px',
            alignItems: 'center',
          },
        },
      },
      [MQ_MIN_MEDIUM]: {
        '.modalContent': {
          gridTemplateAreas: `
          'groups descriptionPanel selectedOptions'
          `,
          gridTemplateColumns: `${
            showDescriptionPanel ? 'auto 1fr' : '1fr auto'
          } auto`,
          gridTemplateRows: '1fr',
          display: 'grid',
          overflow: 'hidden',
          '.itemCard': {
            height: '90px',
          },
          '.optionsContainer': {
            width: showDescriptionPanel ? '300px' : '100%',
            flexGrow: 1,
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            border: 'none',
            borderRight: showDescriptionPanel
              ? `1px solid ${theme.colors.lightGrey}`
              : 'none',
          },
          '.descriptionPanel': {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto',
            '.noOptions': {
              height: '100%',
              width: '100%',
            },
          },
          '.selectedOptions': {
            gridArea: 'selectedOptions',
            flexGrow: 0,
            width: '170px',
            borderLeft: `1px solid ${theme.colors.lightGrey}`,
            '.selectedOptionsHeaderContainer': {
              borderTop: '0px',
              borderBottom: `1px solid ${theme.colors.lightGrey}`,
              minHeight: '62px',
            },
            '.itemCard': {
              width: '140px',
            },
            '.selectedOptionsContent': {
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
            '.noOptionsSelectedContainer': {
              padding: '10px',
              height: '100%',
              alignItems: 'center',
              p: {
                textAlign: 'center',
              },
            },
          },
        },
      },
    });
