import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import CardsContainer from '@app/components/common/CardsContainer';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import Typography from '@app/components/common/Typography';
import ShowAllPricing from '@app/components/LoggedIn/Menu/Items/ItemDetails/ModifierGroupsSection/ModifierGroup/ShowAllPricing';
import { useAppDispatch } from '@app/state/store';
import {
  INestedModifierItemModifiers,
  ISelectedModifierItem,
  ISelectedModifierItemObj,
  QuantityModes,
  SectionId,
  TQuantityModeTypes,
  actionCreatorsApp,
  actionCreatorsMenu,
  getAdditionalAmountPrice,
  getIncludedAmountPrice,
} from '@westondev/tableturn-core';
import React, { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

interface IContent extends WithTranslation {
  defaultModifierGroupPricingVersionId: number | null;
  modifierGroupPricingVersions: IDropdownItem[][];
  modifierGroupId: number;
  quantityMode: TQuantityModeTypes;
  modifierItems: Record<number, INestedModifierItemModifiers>;
  multiselect: boolean;
  setHiddenModifierItemsCount: (id: number) => void;
  hidePriceIfFree: boolean;
  selectedModifierItemElements: {
    modifierItems: {
      [modifierItemId: number]: ISelectedModifierItem;
    };
    modifierItemsWithHidden: {
      [modifierItemId: number]: ISelectedModifierItem;
    };
    count: number;
  };
  includedModifierItemsCount: ISelectedModifierItemObj;
  additionalModifierItemsCount: ISelectedModifierItemObj;
}

const SECTION_ID = SectionId.MODIFIER_GROUPS;

const modifierItemButtonFactory = (
  modifierItems: Record<number, INestedModifierItemModifiers>,
  selectedModifierItemElements: {
    modifierItems: Record<number, ISelectedModifierItem>;
    modifierItemsWithHidden: Record<number, ISelectedModifierItem>;
    count: number;
  },
  includedModifierItemsCount: Record<number, { count: number }>,
  additionalModifierItemsCount: Record<number, { count: number }>,
  quantityMode: TQuantityModeTypes,
  hidePriceIfFree: boolean,
): IItemCard[] => {
  return Object.values(modifierItems).map((modifier): IItemCard => {
    const price =
      (includedModifierItemsCount[modifier.modifierItemId]?.count ?? 0) *
        (quantityMode === QuantityModes.NUMBER
          ? modifier.includedMasterPrice
          : getIncludedAmountPrice(modifier)) +
      (additionalModifierItemsCount[modifier.modifierItemId]?.count ?? 0) *
        (quantityMode === QuantityModes.NUMBER
          ? modifier.additionalMasterPrice
          : getAdditionalAmountPrice(modifier));
    return {
      id: modifier.modifierItemId,
      title: modifier.name,
      isActive: true,
      isSelected:
        !!selectedModifierItemElements.modifierItemsWithHidden[
          modifier.modifierItemId
        ],
      price: price ? price?.toFixed(2) : hidePriceIfFree ? undefined : '0.00',
      onClick: () => {},
    };
  });
};

const Content = ({
  defaultModifierGroupPricingVersionId,
  modifierGroupPricingVersions,
  modifierGroupId,
  quantityMode,
  modifierItems,
  multiselect,
  hidePriceIfFree,
  selectedModifierItemElements,
  includedModifierItemsCount,
  additionalModifierItemsCount,
  t,
}: IContent) => {
  const appDispatch = useAppDispatch();
  const { changePricingVersion } = bindActionCreators(
    actionCreatorsMenu,
    appDispatch,
  );
  const { showToast } = bindActionCreators(actionCreatorsApp, appDispatch);

  const [showAllPricing, setShowAllPricing] = React.useState(false);

  const handleChangePricingVersion = (
    modifierGroupPricingVersionId: number,
  ) => {
    changePricingVersion(
      modifierGroupId,
      modifierGroupPricingVersionId,
      SECTION_ID,
    );
  };

  const modifierItemButtons = useMemo(
    () =>
      modifierItemButtonFactory(
        modifierItems,
        selectedModifierItemElements,
        includedModifierItemsCount,
        additionalModifierItemsCount,
        quantityMode,
        hidePriceIfFree,
      ),
    [
      modifierItems,
      selectedModifierItemElements,
      includedModifierItemsCount,
      additionalModifierItemsCount,
      quantityMode,
      hidePriceIfFree,
    ],
  );

  return (
    <Box csx={{ padding: '15px' }}>
      <Box
        csx={{
          display: 'flex',
          paddingHorizontal: '10px',
          paddingBottom: '15px',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Checkbox
          label={t(
            'menuScreen.itemDetails.modifierGroupsSection.showAllPricing',
          )}
          checked={showAllPricing}
          onChange={setShowAllPricing}
        />

        <Divider
          direction="vertical"
          csx={{ height: '40px', margin: '0 15px' }}
        />

        <Box
          csx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            csx={{
              marginRight: '10px',
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.pricingVersion')}
          </Typography>
          <Dropdown
            value={defaultModifierGroupPricingVersionId || 0}
            data={modifierGroupPricingVersions}
            placeholder="Select version"
            onChange={handleChangePricingVersion}
          />
        </Box>
      </Box>
      <Box>
        {Object.values(modifierItems).length > 0 ? (
          <Box>
            <CardsContainer
              data={modifierItemButtons}
              csx={{ padding: 0, borderWidth: 0 }}
              noElementsMessage="<Box>noElementsMessage</Box>"
              renderItem={element => {
                const modItemData = modifierItems[Number(element.id) || 0];

                return (
                  <Box
                    csx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      opacity: modItemData.active ? 1 : 0.6,
                    }}>
                    <Card.ItemButton
                      {...element}
                      itemId={modItemData.modifierItemId}
                      onChange={() =>
                        showToast({
                          type: 'info',
                          title: t(
                            'menuScreen.modifierItemDetails.modifier.buttonDisabled.title',
                          ),
                          description: t(
                            'menuScreen.modifierItemDetails.modifier.buttonDisabled.body',
                          ),
                        })
                      }
                      showRemoveIcon={false}
                      hidePrice
                      isHidden={!modItemData.inheritedActive}
                    />
                    {showAllPricing && (
                      <ShowAllPricing
                        modifier={{ ...modItemData, override: {} }}
                        menuTypeVersionId={0}
                        modifierGroupId={modifierGroupId}
                        isMultipleQuantity={
                          quantityMode === QuantityModes.NUMBER ? true : false
                        }
                        isSizeItem={
                          quantityMode === QuantityModes.SIZE ? true : false
                        }
                        isMultiselect={multiselect}
                        isEditable={false}
                      />
                    )}
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box
            csx={{
              width: '100%',
              paddingTop: '20px',
              paddingBottom: '35px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography csx={{ fontSize: 'large' }}>
              {t(
                'menuScreen.itemDetails.modifierGroupsSection.noModifierItemsAssignedToThisGroup',
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Content;
