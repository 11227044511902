import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';

import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { btnBaseStyles } from '../styles';
import Icon from '../../Icon';
import { useTheme } from '@emotion/react';
import { displayFlexRowStyles } from '@app/theme/commonStyles';

interface IWarningConfirmationModal
  extends IConfirmationModal,
    WithTranslation {
  componentDescription?: React.ReactNode;
}

const WarningConfirmationModal = ({
  title,
  description,
  name,
  active,
  onCancel,
  onSuccess,
  btnCommonCsx: btnCommonCsx,
  btnCancelText,
  btnCancelCsx: btnCancelCsx,
  btnSuccessText,
  btnSuccessCsx: btnSuccessCsx,
  runCancelOnClickOutside,
  size,
  t,
  hideSuccess,
  hideCancel,
  btnTerciaryCsx,
  onTerciaryButtonPress,
  btnTerciaryText,
  hideTerciary,
  autoclose = true,
  autoCloseOnCancel = true,
  showRed = false,
  componentDescription,
}: IWarningConfirmationModal) => {
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const theme = useTheme();

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnTerciary = () => {
    if (onTerciaryButtonPress) onTerciaryButtonPress();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
    autoclose && setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  return (
    <Modal
      isActive={active}
      size={size || 420}
      minHeight={220}
      showXCloser={false}
      animationMode="alert"
      title={
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <Icon
            name="MdWarning"
            color={
              showRed ? theme.colors.semanticRed : theme.colors.semanticYellow
            }
          />
          <Box>
            <Typography variant="subtitle" fontWeight="medium">
              {title}
            </Typography>
            {name && <Typography color="darkestGrey">{name}</Typography>}
          </Box>
        </Box>
      }
      footer={
        <>
          {!hideCancel && (
            <Button
              variant="secondary"
              csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
              onClick={handleOnCancel}>
              {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
            </Button>
          )}
          {!hideTerciary && btnTerciaryText && (
            <Button
              csx={[btnBaseStyles, btnCommonCsx, btnTerciaryCsx]}
              onClick={handleOnTerciary}>
              {btnTerciaryText}
            </Button>
          )}
          {!hideSuccess && (
            <Button
              variant={showRed ? 'danger' : 'warning'}
              csx={[btnBaseStyles, btnCommonCsx, btnSuccessCsx]}
              onClick={handleOnModalSuccess}>
              {btnSuccessText ? btnSuccessText : t('commonButtons.ok')}
            </Button>
          )}
        </>
      }
      onModalClose={handleOnModalClose}>
      <Box
        csx={{
          display: 'flex',
          width: '100%',
        }}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowWrap: 'anywhere',
          }}>
          <Box>
            {componentDescription ? (
              componentDescription
            ) : (
              <Typography>{description}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default WarningConfirmationModal;
