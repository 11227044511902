import { WithTranslation } from 'react-i18next';

export enum SaveAndCancelBarTypes {
  MENU = 'menu',
  SETTINGS = 'settings',
  TABLE_LAYOUT = 'tableLayout',
  REPORTS = 'reports',
  CUSTOMERS = 'customers',
}
export interface ISaveAndCancelBar extends WithTranslation {
  type: SaveAndCancelBarTypes;
}

export const SAVE_AND_CANCEL_BAR_ID = 'save-and-cancel-bar';
