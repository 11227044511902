import Box from '@app/components/common/Box';
import Tag86 from '@app/components/common/Card/ItemButton/Tag86';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  selectModifierGroups,
  selectModifierItems,
} from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IModifierItemTableRow,
  menuModifierItemsTableFactoryCore,
  truncateString,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIPairArray } from '../../Items/ItemsList/ItemsTable/helpers';
import OverflowText from '../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const ModifierItemsTable = ({ filterParams, t }: IMainScreenTable) => {
  const modifierItems = useSelector(selectModifierItems);
  const modifierGroups = useSelector(selectModifierGroups);

  const onSort = useSort<IModifierItemTableRow>('title');

  const modifierItemsArray = menuModifierItemsTableFactoryCore(
    modifierItems,
    modifierGroups,
  );

  const modifierItemsArrayFiltered = useMemo(
    () =>
      filter<IModifierItemTableRow>(
        modifierItemsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, modifierItemsArray],
  );

  const customFilterFn = (row: Row<IModifierItemTableRow>) => {
    return (
      row.depth > 0 ||
      modifierItemsArrayFiltered.findIndex(
        item => item.id === row.original.id,
      ) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/modifiers/modifier-items/${info.row.original.id}`}
            status={info.row.original.status}
            statusPath={`selectedMode.currentMenu.modifierItems.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('pricing', {
        header: t('menuScreen.modifierItemsMain.table.headers.is86ed'),
        cell: info => {
          const { isOverflow, arrayToRender } = getIPairArray(info.getValue());

          return (
            <Box csx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              {arrayToRender.map((pricingElement, index) => (
                <Typography
                  key={`pricing-${index}`}
                  csx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  {pricingElement.text && (
                    <Typography fontWeight="bold">
                      {truncateString(pricingElement.text, 15)}:
                    </Typography>
                  )}
                  {pricingElement.description && (
                    <Typography>
                      &nbsp;
                      {pricingElement.description}
                      {isOverflow &&
                        index === arrayToRender.length - 1 &&
                        '...'}
                    </Typography>
                  )}
                </Typography>
              ))}
            </Box>
          );
        },
        size: 300,
      }),
      columnHelper.accessor('is86ed', {
        header: t('menuScreen.modifierItemsMain.table.headers.is86ed'),
        cell: info =>
          info.getValue() ? (
            <Tag86 />
          ) : (
            <Typography>
              {t('menuScreen.modifierItemsMain.table.inStock')}
            </Typography>
          ),
        size: 150,
      }),
      columnHelper.accessor('nestedModifiers', {
        header: t('menuScreen.modifierItemsMain.table.headers.nestedModifiers'),
        cell: info => <OverflowText text={info.getValue()} />,
        size: 300,
      }),
      columnHelper.accessor('modifierGroups', {
        header: t('menuScreen.modifierItemsMain.table.headers.modifierGroups'),
        cell: info => <OverflowText text={info.getValue()} />,
        size: 400,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={modifierItemsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ModifierItemsTable;
