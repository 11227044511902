import TTLogoBlack from '@app/assets/logos/TTLogoBlackWithText.svg?react';
import TTLogoWhite from '@app/assets/logos/TTLogoWithText.svg?react';
import { Theme, useTheme } from '@emotion/react';
import { ThemeColors, ThemeModes } from '@app/theme/types';
import TTLogoSmall from '@app/assets/logos/TTLogo.svg?react';

interface ITTLogo {
  color?: ThemeColors;
  toggleWithTheme?: boolean;
  withText?: boolean;
  svgProps?: React.SVGProps<SVGSVGElement>;
}
const TTLogo = ({
  color = 'black',
  toggleWithTheme = false,
  withText = true,
  svgProps,
}: ITTLogo) => {
  const theme = useTheme();

  const getThemeLogo = () => {
    switch (theme.mode) {
      case ThemeModes.LIGHT:
        return withText ? (
          <TTLogoBlack {...svgProps} />
        ) : (
          <TTLogoSmall fill={theme.colors.black} {...svgProps} />
        );
      case ThemeModes.DARK:
        return withText ? (
          <TTLogoWhite />
        ) : (
          <TTLogoSmall fill={theme.colors.black} {...svgProps} />
        );
    }
  };

  const getLogo = () => {
    const themeColor = theme.colors[color as keyof Theme['colors']] || color;

    if (withText) return <TTLogoBlack fill={themeColor} {...svgProps} />;
    else return <TTLogoSmall fill={themeColor} {...svgProps} />;
  };
  return toggleWithTheme ? getThemeLogo() : getLogo();
};

export default TTLogo;
