import CloneModal from '@app/components/common/CloneModal';
import { ICloneModal } from '@app/components/common/CloneModal/types';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { createBucketElementWeb } from '@app/state/menu/menuWebActions';
import { AppThunk, RootState, useAppDispatch } from '@app/state/store';
import {
  BASE_STORE_INITIAL_STATE,
  CreateBucketRequestType,
  FormMode,
  ICategory,
  ICombo,
  IDiscount,
  IHotButtons,
  IIngredient,
  IItem,
  IItemGroup,
  IMenuStore,
  IMenuType,
  IModifierGroups,
  IModifierItem,
  IReportingGroup,
  ISpecial,
  ISubcategory,
  ITEM_LINKED_MODIFIER_ITEM_INITIAL_STATE,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { shallowEqual, useSelector } from 'react-redux';

const { menuBucketValidationThunks } = actionCreatorsMenu;

type IMenuCloneModal = Omit<ICloneModal, 'title' | 'label' | 'onSuccess'> & {
  active?: boolean;
  onClose?: () => void;
  onClone?: (id: number) => void;
  bucket: keyof IMenuStore;
  name: string;
};

const MenuCloneModal = ({
  t,
  active = false,
  onCancel = () => null,
  onClone = () => null,
  onClose,
  bucket,
  name,
}: IMenuCloneModal) => {
  const { data, id } = useSelector(
    (state: RootState) => state.menu.changeData,
    shallowEqual,
  );
  const menuAccessLocations = useSelector(
    (state: RootState) => state.loggedInUser.menuAccessLocations,
  );

  const dispatch = useAppDispatch();

  const onRefresh = useRefreshMenuWeb();

  const getData = (
    _data:
      | IItem
      | IMenuType
      | ICategory
      | ISubcategory
      | IModifierItem
      | IModifierGroups
      | IDiscount
      | IHotButtons
      | ICombo
      | ISpecial
      | IIngredient
      | IItemGroup
      | IReportingGroup,
    newName: string,
  ) => {
    let newData = cloneDeep({ ..._data });
    switch (bucket) {
      case 'menuTypes':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          menuTypeId: 0,
          itemMenuTypeTemplateIds: (newData as IMenuType).itemIds.reduce(
            (prev, current) => ({ ...prev, [current]: id }),
            {},
          ),
          organizationId: 0,
          locationMenuTypeId: 0,
          locationMenuTypeVersionId: 0,
        } as IMenuType;
        break;
      case 'categories':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          categoryId: 0,
          organizationId: 0,
          locationId: 0,
          createdByLocationId: 0,
          locationCategoryId: 0,
          locationCategoryVersionId: 0,
          itemIds: [],
          menuTypeIds: [],
          subcategoryMenuTypeItemsIds: {},
          subcategoryIds: [],
          otherSubcategoryId: null,
        } as ICategory;
        break;
      case 'subcategories':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          subcategoryId: 0,
          organizationId: 0,
          locationId: 0,
          createdByLocationId: 0,
          locationSubcategoryId: 0,
          itemIds: [],
        } as ISubcategory;
        break;
      case 'modifierGroups':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          kdsName: newName,
          checkName: newName,
          onlineName: newName,
          modifierGroupId: 0,
          locationModifierGroupId: 0,
          locationModifierGroupVersionId: 0,
          organizationId: 0,
          defaultModifierGroupPricingVersionId: 0,
          modifierGroupPricingVersions: Object.values(
            (newData as IModifierGroups).modifierGroupPricingVersions,
          ).reduce((prev, current, currentIndex) => {
            return {
              ...prev,
              [currentIndex + 1]: {
                ...current,
                ...BASE_STORE_INITIAL_STATE,
                organizationId: 0,
                modifierGroupPricingVersionId: currentIndex + 1,
                locationModifierGroupPricingVersionVersionId: 0,
                locationModifierGroupPricingVersionId: 0,
                id: currentIndex + 1,
              },
            };
          }, {}),
          parentModifierItemIds: [],
          itemIds: {},
        } as IModifierGroups;
        break;
      case 'modifierItems':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          kdsName: newName,
          checkName: newName,
          onlineName: newName,
          modifierItemId: 0,
          locationModifierItemId: 0,
          locationModifierItemVersionId: 0,
          organizationId: 0,
          defaultModifierItemPricingVersionId: 0,
          modifierItemPricingVersions: Object.values(
            (newData as IModifierItem).modifierItemPricingVersions,
          ).reduce((prev, current, currentIndex) => {
            return {
              ...prev,
              [currentIndex + 1]: {
                ...current,
                ...BASE_STORE_INITIAL_STATE,
                organizationId: 0,
                modifierItemId: 0,
                modifierItemPricingVersionId: currentIndex + 1,
                locationModifierItemPricingVersionVersionId: 0,
                locationModifierItemPricingVersionId: 0,
                id: currentIndex + 1,
              },
            };
          }, {}),
          parentModifierGroupIds: [],
        } as IModifierItem;
        break;
      case 'items':
        newData = {
          ...(newData as IItem),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          menuTypeVersions: mapValues(
            (newData as IItem).menuTypeVersions,
            menuType => ({
              ...menuType,
              posName: newName,
              kdsName: newName,
              onlineName: newName,
              checkName: newName,
            }),
          ),
          linkedModifierItems: isEmpty((newData as IItem).linkedModifierItems)
            ? {}
            : {
                0: { ...ITEM_LINKED_MODIFIER_ITEM_INITIAL_STATE },
              },
          menuTypeItemId: 0,
          organizationId: 0,
          itemId: 0,
        } as IItem;
        break;
      case 'combos':
        newData = {
          ...(newData as ICombo),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          menuTypeVersions: mapValues(
            (newData as ICombo).menuTypeVersions,
            menuType => ({
              ...menuType,
              posName: newName,
              kitchenName: newName,
              onlineName: newName,
              checkName: newName,
            }),
          ),
          itemId: 0,
          menuTypeItemId: 0,
          organizationId: 0,
          locationIds: (newData as ICombo).locationIds?.filter(locationId =>
            menuAccessLocations.includes(locationId),
          ),
        } as ICombo;
        break;
      case 'discounts':
        newData = {
          ...(newData as IDiscount),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          checkName: newName,
          posName: newName,
          discountId: 0,
          locationId: 0,
          locationDiscountId: 0,
          locationDiscountVersionId: 0,
          locationIds: (newData as IDiscount).locationIds?.filter(locationId =>
            menuAccessLocations.includes(locationId),
          ),
        } as IDiscount;
        break;
      case 'specials':
        newData = {
          ...(newData as ISpecial),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          menuTypeVersions: mapValues(
            (newData as ISpecial).menuTypeVersions,
            menuType => ({
              ...menuType,
              posName: newName,
              checkName: newName,
            }),
          ),
          specialId: 0,
          createdByLocationId: 0,
          organizationId: 0,
          locationIds: (newData as ISpecial).locationIds?.filter(locationId =>
            menuAccessLocations.includes(locationId),
          ),
        } as ISpecial;
        break;
      case 'ingredients':
        newData = {
          ...(newData as IIngredient),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          ingredientId: 0,
        } as IIngredient;
        break;

      case 'itemGroups':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          kdsName: newName,
          checkName: newName,
          onlineName: newName,
          itemGroupId: 0,
          parentComboIds: [],
          items: {},
        } as IItemGroup;

        break;

      case 'reportingGroups':
        newData = {
          ...(newData as IReportingGroup),
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          reportingGroupId: 0,
        } as IReportingGroup;
        break;
    }

    return newData;
  };

  const handleOnSuccess = async (newName: string) => {
    if (data === null) {
      return;
    }
    onClose ? onClose() : onCancel();
    const request: CreateBucketRequestType = {
      bucketSelected: bucket,
      id: 0,
      data: getData(data, newName),
    };
    const bucketValidationFunction = menuBucketValidationThunks(
      bucket,
      data,
      false,
    ) as unknown as AppThunk<boolean>;
    const isValid = await dispatch(bucketValidationFunction);

    if (!isValid) {
      return;
    }

    const { success, id: newId } = await dispatch(
      createBucketElementWeb(onRefresh, request, FormMode.CREATE),
    );

    if (success) {
      onClone(newId);
    }
  };
  return (
    <CloneModal
      active={active}
      title={t(`app.modals.clone.title.${bucket}` as ParseKeys, { name })}
      label={t(`app.modals.clone.description.${bucket}` as ParseKeys, { name })}
      onSuccess={handleOnSuccess}
      onCancel={onCancel}
    />
  );
};

export default MenuCloneModal;
