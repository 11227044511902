import { css, Theme } from '@emotion/react';

export const orderSectionStyles = (theme: Theme) =>
  css({
    '& .avg': {
      borderTop: `1px solid ${theme.colors.lightGrey}`,
      borderBottom: `1px solid ${theme.colors.lightGrey}`,
      borderLeft: `1px solid ${theme.colors.lightGrey}`,
      padding: 10,
    },
    '& .avg:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& .avg:last-child': {
      borderRadius: '0 10px 10px 0',
      borderRight: `1px solid ${theme.colors.lightGrey}`,
    },
  });
