import { linkWithText } from '@app/components/Authentication/styles';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import CodeVerifier from '@app/components/common/CodeVerifier';
import Link from '@app/components/common/Link';
import Typography from '@app/components/common/Typography';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { verifyCodeStepStyles } from './styles';
import ChangePhoneModal from '@app/components/Authentication/SignUp/VerifyPhoneStep/ChangePhoneModal';
import { actionCreatorsApp, apiEndpoints } from '@westondev/tableturn-core';
import { bindActionCreators } from 'redux';
import { CodeVerifierRef } from '@app/components/common/CodeVerifier/types';
import { CallMethods } from '@app/constants';
import { apiCall } from '@app/helpers/apiCall';
import Spinner from '@app/components/common/Spinner';
import { useAppDispatch } from '@app/state/store';
import { changeUnverifiedPhone } from '@app/state/app/actions';
import { changeUnverifiedPhoneLocalStorage } from '@app/helpers/users';

interface IVerifyPhoneStep extends WithTranslation {
  phoneNumber: string;
  onContinue: (code: string) => Promise<void>;
  setPhone: (phone: string, phoneMasked: string) => void;
  resendOnLoad?: boolean;
}

const VerifyPhoneStep = ({
  t,
  phoneNumber,
  onContinue,
  setPhone,
  resendOnLoad,
}: IVerifyPhoneStep) => {
  const codeVerifierRef = useRef<CodeVerifierRef>(null);

  const [code, setCode] = useState({
    value: '',
    error: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const disptach = useAppDispatch();
  const { showToast } = bindActionCreators(actionCreatorsApp, disptach);

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleResetError();
    setIsLoading(true);

    onContinue(code.value)
      .then(() => setIsLoading(false))
      .catch(error => {
        console.error(error);
        setIsLoading(false);
        setCode(prev => ({
          ...prev,
          error: t('validations.invalidCodePleaseTryAgain'),
        }));

        showToast({
          title: 'Error',
          description: t('validations.invalidCodePleaseTryAgain'),
          type: 'error',
        });
      });
  };

  const handlePhoneChange = (phone: string) => {
    disptach(changeUnverifiedPhone(phone))
      .then(() => {
        changeUnverifiedPhoneLocalStorage(phone);
        setPhone(phone, phone);
      })
      .catch(error => {
        console.error(error);
        showToast({
          title: 'Error',
          description: error.response.data.error.message,
          type: 'error',
        });
      });
  };

  const handleResetError = () => {
    setCode(prev => ({ ...prev, error: '' }));
  };

  const cleanPhoneNumber = useMemo(() => {
    return phoneNumber.replace(/\D/g, '');
  }, [phoneNumber]);

  const formattedPhoneNumber = useMemo(() => {
    const mask = '($1) $2-$3';
    return cleanPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, mask);
  }, [cleanPhoneNumber]);

  const handleResend = () => {
    codeVerifierRef.current?.resetInput?.();
    setIsResending(true);
    apiCall(
      CallMethods.POST,
      apiEndpoints.user.resendPhoneVerification(),
      false,
      {
        phoneNumber: cleanPhoneNumber,
      },
    )
      .then(() => {
        setIsResending(false);
        showToast({
          title: 'Success',
          description: t('authentication.verifyCode.codeResent'),
          type: 'success',
        });
      })
      .catch(error => {
        setIsResending(false);
        console.error(error);
        showToast({
          title: 'Error',
          description: t('authentication.verifyCode.codeResendError'),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (phoneNumber && resendOnLoad) {
      handleResend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendOnLoad, phoneNumber]);

  return (
    <Box className="form">
      <form
        className="formContent"
        css={verifyCodeStepStyles}
        onSubmit={handleSubmitForm}>
        <Box className="title">
          <Typography>
            {t('authentication.signUp.verifyCodeInstructions')}{' '}
            <b>{formattedPhoneNumber}</b>{' '}
            {t('authentication.signUp.toVerifyPhoneNumber')}.
          </Typography>
        </Box>
        <Box csx={{ height: '100%', maxWidth: '420px' }}>
          <CodeVerifier
            ref={codeVerifierRef}
            id="confirmPhone"
            length={6}
            isLoading={isLoading}
            error={code.error.length > 0}
            caption={code.error}
            getCode={value => setCode({ value, error: '' })}
            emptyCation
          />
          <Box csx={[linkWithText, { marginBlock: '10px' }]}>
            <Typography align="center" fontWeight="medium" color="darkGrey">
              {t('authentication.verifyCode.didntGetTheCode')}
            </Typography>
            <Link to="." onClick={handleResend}>
              {t('authentication.verifyCode.resend')}
            </Link>

            {isResending && (
              <Spinner csx={{ marginRight: '8px' }} color="lightGrey" />
            )}
          </Box>
        </Box>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            maxWidth: '380px',
          }}>
          <Button
            type="submit"
            variant="active"
            isLoading={isLoading}
            loadingText={t('commonTexts.continue')}
            disabled={code.value.length !== 6}>
            {t('commonTexts.continue')}
          </Button>
        </Box>
      </form>
      <ChangePhoneModal onPhoneChanged={handlePhoneChange} />
    </Box>
  );
};

export default VerifyPhoneStep;
