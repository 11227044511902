import { IPricing } from '@westondev/tableturn-core';
import i18n from '../../../../../../../../i18n.config';
import {
  BASE_TRANSLATION,
  OperationType,
  UpdateType,
} from '../PricingModal/types';

export interface IMassUpdatePrice {
  title: string;
  data: IPricing | null;
  onChange: (
    updateType: UpdateType,
    operation: OperationType | -1,
    price: number,
  ) => void;
}

export const UPDATE_TYPES_TRANSLATIONS = {
  [UpdateType.NONE]: i18n.t(`${BASE_TRANSLATION}.none`),
  [UpdateType.PERCENT]: i18n.t(`${BASE_TRANSLATION}.percent`),
  [UpdateType.FIXED]: i18n.t(`${BASE_TRANSLATION}.fixed`),
  [UpdateType.SET]: i18n.t(`${BASE_TRANSLATION}.setPrice`),
};

export const OPERATION_TYPES_TRANSLATIONS = {
  [OperationType.INCREASE]: i18n.t(`${BASE_TRANSLATION}.increase`),
  [OperationType.DECREASE]: i18n.t(`${BASE_TRANSLATION}.decrease`),
};
