import RolesList from '@app/components/LoggedIn/Settings/Accounts/Roles/RolesList';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import Box from '@app/components/common/Box';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRoleSettingsStore,
  SettingsSectionId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedItemData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const AccountsRoles = () => {
  // Redux
  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    actionCreatorsSettingsWeb.checkForSettingsChangesAndNavigateWeb,
    useDispatch(),
  );

  // Local state
  const match = useMatch(getPathWithOrgData('/settings/accounts/roles'));
  const matchCreate = useMatch(
    getPathWithOrgData('/settings/accounts/roles/add'),
  );
  const navigate = useNavigateWithOrg();

  const { data } = useSelector(getMemoizedItemData);

  const name = matchCreate ? 'Adding New Role' : data?.name || '';

  const handleGotoRolesList = () => {
    checkForSettingsChangesAndNavigateWeb(
      () => {
        navigate('/settings/accounts/roles');
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  return !match ? (
    <>
      <BreadCrumbSettings
        title="Roles"
        currentPage={name}
        onClick={handleGotoRolesList}
      />
      <Box csx={{ padding: '4px 15px 15px 15px' }}>
        <Outlet />
      </Box>
    </>
  ) : (
    <>
      <RolesList />
    </>
  );
};

export default AccountsRoles;
