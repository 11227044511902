import { Theme, css } from '@emotion/react';

export const serviceAreaStyles = (theme: Theme) =>
  css({
    position: 'relative',
    '.removeIcon, .editIcon': {
      position: 'absolute',
      top: -5,
      right: -6,
      backgroundColor: 'red',
      borderRadius: '50%',
      display: 'flex',
      height: '24px',
      width: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        width: '20px',
        height: '20px',
      },
      ':hover': {
        cursor: 'pointer',
      },
    },
    '.editIcon': {
      display: 'none',
      backgroundColor: 'white',
      border: `1px solid ${theme.colors.lightGrey}`,
      svg: {
        fill: theme.colors.textBlack,
        width: '18px',
        height: '18px',
      },
    },
    ':hover': {
      '.editIcon': {
        display: 'flex',
      },
    },
    '.dragIcon': {
      position: 'absolute',
      top: 13,
      left: 0,
    },
    '&[data-dragging="true"]': {
      zIndex: '1 !important',
    },
  });
