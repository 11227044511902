import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import OptionsModal from '@app/components/common/OptionsModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import PrepStationsSelectionModal from '@app/components/common/SelectionModal/PrepStationsSelectionModal';
import StatusCardsContainer from '@app/components/common/StatusCardsContainer';
import { EStatusCardsContainer } from '@app/components/common/StatusCardsContainer/types';
import { prepStationVersionsFactory } from '@app/helpers/factories/settings/kitchenTicketSettings/prepStationVersionsFactory';
import {
  currentPrepStationsSelector,
  makeSelectSettingsData,
} from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IKitchenTicketSettings,
  IPrepStationSettings,
  SettingsSectionId,
  actionCreatorsSettings,
  prepStationsOptionModalFactory,
} from '@westondev/tableturn-core';
import omit from 'lodash/omit';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PrepStationsSectionRef } from './types';

const valueStatus: Record<number, 'all' | EStatusCardsContainer> = {
  0: 'all',
  1: EStatusCardsContainer.ACTIVE,
  2: EStatusCardsContainer.INACTIVE,
};

const SECTION_ID = SettingsSectionId.PREP_STATIONS;
const getMemoizedItemData =
  makeSelectSettingsData<IKitchenTicketSettings>(SECTION_ID);

interface IPrepStationsSection {
  selectedPrepStationVersionId: number;
  setSelectedPrepStationVersionId: (id: number) => void;
}

const PrepStationsSection = forwardRef<
  PrepStationsSectionRef,
  IPrepStationsSection
>(({ selectedPrepStationVersionId, setSelectedPrepStationVersionId }, ref) => {
  useImperativeHandle(ref, () => ({
    setFirstActivePrepStation() {
      setFirstActiveId();
    },
  }));

  // Redux

  const { t } = useTranslation();

  const {
    pushKitchenTicketPrepStationsSettings,
    pullKitchenTicketPrepStationSettings,
  } = bindActionCreators(actionCreatorsSettings, useDispatch());

  const { data } = useSelector(getMemoizedItemData);
  const currentPrepStations = useSelector(currentPrepStationsSelector);

  const prepStationVersions = data?.prepStationVersions;

  const prepStationOptions = useMemo(
    () =>
      prepStationsOptionModalFactory(
        omit(currentPrepStations, [selectedPrepStationVersionId]) as Record<
          number,
          IPrepStationSettings
        >,
      ),
    [currentPrepStations, selectedPrepStationVersionId],
  );

  //Local state
  const [activeDropdown, setActiveDropdown] = useState(1);
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false);
  const [isMultiModalOpen, setIsMultiModalOpen] = useState(false);
  const formattedPrepStations = prepStationVersions
    ? prepStationVersionsFactory(prepStationVersions)
    : [];

  const prepStations = formattedPrepStations.map(_prepStation => ({
    id: _prepStation.id,
    title: _prepStation.name,
    status: _prepStation.active,
    groupStatus: _prepStation.active
      ? EStatusCardsContainer.ACTIVE
      : EStatusCardsContainer.INACTIVE,
    onClick: () => setSelectedPrepStationVersionId(_prepStation.id),
  }));

  const setFirstActiveId = () => {
    const firstActivePrepStation = prepStations.find(
      prepStation => prepStation.groupStatus,
    );
    if (firstActivePrepStation) {
      return setSelectedPrepStationVersionId(firstActivePrepStation.id);
    }

    setActiveDropdown(0);
    setSelectedPrepStationVersionId(prepStations[0].id);
  };

  const getNoElementsMessage = () => {
    switch (activeDropdown) {
      case 0: //All
        return t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.emptyCardsMessage',
        );
      case 1: //Active
        return t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.emptyActiveCardsMessage',
        );
      case 2: //Inactive
        return t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.emptyInactiveCardsMessage',
        );
    }
  };

  const closeSingleModal = () => {
    setIsSingleModalOpen(false);
  };

  const closeMultiModal = () => {
    setIsMultiModalOpen(false);
  };

  const handlePullSettings = (prepStationPullId: number | null) => {
    if (!prepStationPullId) return closeSingleModal();
    pullKitchenTicketPrepStationSettings(
      selectedPrepStationVersionId,
      prepStationPullId,
    );
    closeSingleModal();
  };

  const handlePushSettings = (prepStationsToPush: IItemSelection[]) => {
    pushKitchenTicketPrepStationsSettings(
      selectedPrepStationVersionId,
      prepStationsToPush.map(prepStation => prepStation.id as number),
    );
    closeMultiModal();
  };

  return (
    <>
      <PrepStationsSelectionModal
        onAssociate={handlePushSettings}
        active={isMultiModalOpen}
        onModalClose={closeMultiModal}
        prepStationIds={[selectedPrepStationVersionId]}
        btnSuccessText={t('commonButtons.push')}
        showSelectAll
      />
      <OptionsModal
        noElementMessage={t('app.modals.prepStation.noPrepStations')}
        options={prepStationOptions}
        title={t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.pullFromTitle',
        )}
        isSearchable
        onChange={(prepStationPullId: number | null) =>
          handlePullSettings(prepStationPullId)
        }
        isActiveByDefault={isSingleModalOpen}
        hideTriggerButton={true}
        onCancel={closeSingleModal}
        onOutsidePress={closeSingleModal}
      />
      <Card.SubCard
        required
        showCardShadow
        title={t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.title',
        )}
        description={t(
          'settingsModule.kitchenTicketSettings.prepStationsSection.description',
        )}
        options={
          <>
            <Button
              variant="primary"
              onClick={() => setIsSingleModalOpen(true)}>
              {t(
                'settingsModule.kitchenTicketSettings.prepStationsSection.pullFrom',
              )}
            </Button>
            <Button variant="primary" onClick={() => setIsMultiModalOpen(true)}>
              {t(
                'settingsModule.kitchenTicketSettings.prepStationsSection.pushTo',
              )}
            </Button>
            <Dropdown
              data={[
                [{ label: 'All', value: 0 }],
                [
                  { label: t('commonTexts.active'), value: 1 },
                  { label: t('commonTexts.inactive'), value: 2 },
                ],
              ]}
              value={activeDropdown}
              onChange={setActiveDropdown}
              csx={{ maxWidth: '120px' }}
            />
          </>
        }>
        <StatusCardsContainer
          data={prepStations}
          selectedStatus={valueStatus[activeDropdown]}
          activeText={t(
            'settingsModule.kitchenTicketSettings.prepStationsSection.active',
          )}
          inactiveText={t(
            'settingsModule.kitchenTicketSettings.prepStationsSection.inactive',
          )}
          noElementsMessage={getNoElementsMessage()}
          renderItem={item => {
            const active =
              Number(item.id) === Number(selectedPrepStationVersionId);
            return (
              <Card.Item
                {...item}
                showTag={false}
                showStatus
                isSelected={active}
                isActive={item.status}
              />
            );
          }}
        />
      </Card.SubCard>
    </>
  );
});

PrepStationsSection.displayName = 'PrepStationsSection';

export default PrepStationsSection;
