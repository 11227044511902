export const PHONE_VERIFIED = 'isPhoneVerified';
export const EMAIL_VERIFIED = 'isEmailVerified';

export const getUserVerifiedStatus = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const isEmailVerified = !!userDetails?.emailVerified;
  const isPhoneVerified = !!userDetails?.phoneVerified;
  return {
    isEmailVerified,
    isPhoneVerified,
  };
};

export const validateUserVerified = async () => {
  const { isEmailVerified, isPhoneVerified } = getUserVerifiedStatus();
  const token = localStorage.getItem('auth');

  if (isEmailVerified && isPhoneVerified) {
    return Promise.resolve({
      isEmailVerified,
      isPhoneVerified,
      token,
    });
  } else {
    return Promise.reject({
      isEmailVerified,
      isPhoneVerified,
      token,
    });
  }
};

export const getUserIsVerified = () => {
  const { isEmailVerified, isPhoneVerified } = getUserVerifiedStatus();
  const token = localStorage.getItem('auth');

  if (isEmailVerified && isPhoneVerified)
    return {
      isVerified: true,
      data: {
        isEmailVerified,
        isPhoneVerified,
        token,
      },
    };
  else
    return {
      isVerified: false,
      data: { isEmailVerified, isPhoneVerified, token },
    };
};

export const setEmailVerified = (to: boolean) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  userDetails.emailVerified = to;
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
};

export const setPhoneVerified = (to: boolean) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  userDetails.phoneVerified = to;
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
};

export const changeUnverifiedEmailLocalStorage = (email: string) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  userDetails.email = email;
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
  return Promise.resolve();
};

export const changeUnverifiedPhoneLocalStorage = (phone: string) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  userDetails.phoneNumber = phone;
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
  return Promise.resolve();
};
