import { Theme, css } from '@emotion/react';

export const sortableRowStyles = (theme: Theme) =>
  css({
    zIndex: '0 !important',
    position: 'relative',
    '&[data-dragging="true"]': {
      backgroundColor: theme.colors.lightestExtraGrey,
      boxShadow: theme.shadows[1],
      zIndex: '1 !important',
    },
  });
