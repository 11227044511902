import useElementTypes from '@app/hooks/useElementTypes';
import { ElementTypes, ILayoutProps } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ElementPanHandlerMemo, {
  TElement,
} from '../ElementPanHandler/ElementPanHandler';
import { DEFAULT_ELEMENT_SIZES } from '../types';
import { elementStyles } from '../styles';
import { selectSelectedElement } from '@app/state/selectors/tableLayoutSelectors';

interface IElement extends TElement {
  isAnimated?: boolean;
}

const Element = ({ element, layoutProps, isAnimated = false }: IElement) => {
  // Redux
  const selectedElement = useSelector(selectSelectedElement);

  const ELEMENT_TYPES = useElementTypes({
    color: 'textBlack',
    size: '30px',
    csx: {
      rotate: `${element.rotation * -1}deg`,
    },
  });

  // Local State
  const showControls =
    selectedElement?.id === element.id && selectedElement?.showControls;

  const elementIcon = useMemo(() => {
    switch (element.type) {
      case ElementTypes.STATION:
        return ELEMENT_TYPES[ElementTypes.STATION].icon;
      case ElementTypes.PLANT:
        return ELEMENT_TYPES[ElementTypes.PLANT].icon;
      case ElementTypes.DOOR:
        return ELEMENT_TYPES[ElementTypes.DOOR].icon;
      case ElementTypes.WINDOW:
        return ELEMENT_TYPES[ElementTypes.WINDOW].icon;
      default:
        return null;
    }
  }, [ELEMENT_TYPES, element.type]);

  if (isAnimated)
    return (
      <ElementPanHandlerMemo
        element={element}
        isSelected={selectedElement?.id === element.id}
        showControls={showControls}
        elementStyles={[
          elementStyles,
          {
            width: element.width,
            height: element.height,
          },
        ]}
        defaultElementProps={{
          width: DEFAULT_ELEMENT_SIZES.object.width,
          height: DEFAULT_ELEMENT_SIZES.object.height,
        }}
        layoutProps={layoutProps as ILayoutProps}>
        {elementIcon}
      </ElementPanHandlerMemo>
    );

  // TODO: Implement table layout for register module
  return null;
};

export default Element;
