import Button from '../../Button';
import { IButton } from '../../Button/Button';
import Icon from '../../Icon';

interface IDropdownItemBadge extends IButton {
  text: string;
  allowRemove?: boolean;
}

const DropdownItemBadge = ({
  text,
  allowRemove = true,
  ...rest
}: IDropdownItemBadge) => {
  return (
    <Button
      csx={theme => ({
        height: '50px',
        borderRadius: '5px',
        borderWidth: '1px',
        backgroundColor: theme.colors.lighterBlue,
        borderColor: theme.colors.semanticBlue,
        padding: '0xp 10px',
        color: theme.colors.semanticBlue,
      })}
      {...rest}
      icon={
        allowRemove ? (
          <Icon
            name="MdClear"
            size="15px"
            color="semanticBlue"
            csx={theme => ({
              fill: `${theme.colors.semanticBlue} !important`,
            })}
          />
        ) : undefined
      }>
      {text}
    </Button>
  );
};

export default DropdownItemBadge;
