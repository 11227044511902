import { TCsx } from '@emotion/react';
import { useAnimate } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import Box from '../Box';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import Typography from '../Typography';
import { checkBoxStyles } from './styles';

interface ICheckbox {
  label?: ReactNode;
  checked?: boolean;
  isDisabled?: boolean;
  onChange?: (checked: boolean) => void;
  csx?: TCsx;
  info?: string;
  showNeutral?: boolean;
  showNoChanges?: boolean;
}

const Checkbox = ({
  label,
  checked,
  isDisabled,
  onChange,
  info,
  csx,
  showNeutral,
  showNoChanges,
}: ICheckbox) => {
  const [localState, setLocalState] = useState(false);
  const [scope, animate] = useAnimate();
  const isChecked = checked === undefined ? localState : checked;
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  if (!info && typeof label === 'string') {
    info = label;
  } else if (!info) {
    info = 'TOOLTIP_INFO';
  }

  const showRed = !isChecked && showNeutral;

  useEffect(() => {
    animate(
      '.checkIcon',
      {
        rotate: isChecked || isDisabled || showRed ? '0deg' : '-90deg',
        opacity: isChecked || showRed ? 1 : isDisabled ? 0 : 0.2,
      },
      { duration: 0.1 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, showRed]);

  const handleCheckBoxClick = () => {
    onChange && !isDisabled && onChange(!checked);
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box
      ref={scope}
      csx={[checkBoxStyles, csx]}
      className={isDisabled ? 'disabled' : ''}
      onClick={() => {
        checked === undefined
          ? setLocalState(prev => !prev)
          : handleCheckBoxClick();
      }}>
      <Box className="checkboxContainer">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          readOnly
        />
        <Box
          className={`checkMark ${
            showNeutral && !showNoChanges ? 'neutral' : ''
          }`}>
          <Box
            csx={
              showNeutral && !checked
                ? { display: 'flex', alignItems: 'center' }
                : undefined
            }
            className="checkIcon">
            {!showNoChanges && (
              <Icon
                name={showNeutral && !checked ? 'FaXmark' : 'MdCheck'}
                color={isDisabled ? 'semanticGrey' : 'textWhite'}
                size="20px"
              />
            )}
          </Box>
          {showNoChanges && (
            <Box
              csx={{
                borderBottom: '2px solid lightGrey',
                width: '130%',
                position: 'absolute',
                transform: 'rotate(45deg)',
              }}
            />
          )}
        </Box>
      </Box>
      {info && (
        <div className="infoButton">
          <ToolTip showHover={showHover} content={info} isDisabled={isDisabled}>
            <div />
          </ToolTip>
        </div>
      )}
      {label && (
        <Typography
          className="checkboxLabel"
          csx={
            showNeutral && showNoChanges
              ? { textDecoration: 'line-through' }
              : undefined
          }
          onMouseOver={handlePopUp}
          onMouseOut={() => {
            if (newTimeout) clearTimeout(newTimeout);
            setShowHover(false);
          }}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default Checkbox;
