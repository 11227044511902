import { openNewTabWithOrgData } from '@app/helpers/navigation';
import { TCsx } from '@emotion/react';
import React, { useEffect, useRef } from 'react';

interface IRightClickLink {
  url: string;
  onClick?: () => void;
  children?: React.ReactNode;
  csx?: TCsx;
}

const RightClickLink = ({
  url: pathUrl,
  onClick,
  children,
  csx,
}: IRightClickLink) => {
  const listItemRef = useRef<HTMLAnchorElement>(null);
  const handleClick = (openInNewTab: boolean) => {
    if (openInNewTab) return openNewTabWithOrgData(pathUrl);
    onClick && onClick();
  };

  useEffect(() => {
    const closestAnchor = listItemRef?.current;

    const handleLeft = (e: any) => {
      e.preventDefault();

      if (e.button === 0) {
        handleClick(false);
      } else if (e.button === 1) {
        handleClick(true);
      }
    };
    const handleMiddleClickOtherBrowsers = (e: MouseEvent | Event) => {
      e.preventDefault();
      handleClick(true);
    };

    closestAnchor?.addEventListener('click', handleLeft);
    closestAnchor?.addEventListener('auxclick', handleMiddleClickOtherBrowsers);

    return () => {
      closestAnchor?.removeEventListener('click', handleLeft);
      closestAnchor?.removeEventListener(
        'auxclick',
        handleMiddleClickOtherBrowsers,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathUrl, onClick]);

  return (
    <a
      ref={listItemRef}
      href={pathUrl}
      css={[{ textDecoration: 'none', cursor: 'pointer' }, csx]}>
      {children}
    </a>
  );
};

export default RightClickLink;
