import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { actionCreatorsTableLayoutWeb } from '@app/state';
import {
  selectSelectedElement,
  selectSelectedServiceArea,
  selectSelectedServiceAreas,
} from '@app/state/selectors/tableLayoutSelectors';
import { BREAKPOINTS } from '@app/theme/themes';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ElementTypes,
  IServiceAreaStore,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WithTranslation, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AddOrUpdateServiceAreaModal from './AddOrUpdateServiceAreaModal';
import ServiceAreasList from './ServiceAreasList';
import {
  actionsContainerStyles,
  servicesAreasContainerStyles,
  topBarStyles,
} from './styles';
import { selectSelectedLayout } from '@app/state/selectors/settingsSelectors';
import { Theme } from '@emotion/react';

const TopBar = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useDispatch();
  const serviceAreas = useSelector(selectSelectedServiceAreas);
  const selectedServiceArea = useSelector(selectSelectedServiceArea);
  const selectedElement = useSelector(selectSelectedElement, shallowEqual);
  const selectedLayout = useSelector(selectSelectedLayout, shallowEqual);

  const { changeServiceArea, setLastServiceAreasOrder } = bindActionCreators(
    actionCreatorsTableLayout,
    dispatch,
  );
  const updateLastServiceAreasOrderWeb = bindActionCreators(
    actionCreatorsTableLayoutWeb.updateLastServiceAreasOrderWeb,
    dispatch,
  );

  // Local state
  const [reorderEnabled, setReorderEnabled] = useState(false);
  const [editServiceArea, setEditServiceArea] =
    useState<IServiceAreaStore | null>(null);
  const [isScrollBarVisible, setIsScrollBarVisible] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);

  const serviceAreasRef = useRef<HTMLDivElement>(null);

  const mappedServiceAreas: IServiceAreaStore[] = Object.entries(
    serviceAreas,
  ).map(serviceArea => {
    return {
      ...serviceArea[1],
    };
  });

  const sortedServiceAreas = Object.entries(mappedServiceAreas)
    .sort(([, a], [, b]) => a.sortPriority - b.sortPriority)
    .map(element => element[1]);

  const elementData = useMemo(
    () =>
      selectedElement && selectedLayout
        ? selectedLayout[selectedElement.id]
        : null,
    [selectedElement, selectedLayout],
  );

  useEffect(() => {
    if (!selectedServiceArea && Object.values(serviceAreas).length > 0) {
      changeServiceArea(sortedServiceAreas[0].id);
      handleScrollToStart();
    } else {
      setReorderEnabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceArea]);

  const handleSaveLastServiceAreasOrder = () => {
    updateLastServiceAreasOrderWeb();
    setReorderEnabled(false);
  };

  const handleOnCancel = () => {
    setReorderEnabled(false);
    setLastServiceAreasOrder(null);
  };

  useEffect(() => {
    const handleCheckIfScrollBarVisible = () => {
      if (serviceAreasRef.current) {
        if (
          serviceAreasRef.current.scrollWidth >
          serviceAreasRef.current.clientWidth
        )
          setIsScrollBarVisible(true);
        else setIsScrollBarVisible(false);
      }

      if (window.innerWidth <= parseFloat(BREAKPOINTS.small.max)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleCheckIfScrollBarVisible);
    return () => {
      window.removeEventListener('resize', handleCheckIfScrollBarVisible);
    };
  }, [serviceAreasRef]);

  useEffect(() => {
    if (window.innerWidth <= parseFloat(BREAKPOINTS.small.max)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleScrollToEnd = () => {
    setTimeout(() => {
      if (serviceAreasRef.current) {
        serviceAreasRef.current.scrollTo({
          left:
            serviceAreasRef.current.scrollWidth +
            serviceAreasRef.current.clientWidth,
          behavior: 'instant',
        });
      }
    }, 100);
  };

  const handleScrollToStart = () => {
    if (serviceAreasRef.current) {
      serviceAreasRef.current.scrollTo({
        left: 0,
        behavior: 'instant',
      });
    }
  };

  return (
    <Box csx={{ flexGrow: 0 }}>
      {elementData &&
        !elementData.name &&
        elementData.type === ElementTypes.TABLE && (
          <Box
            csx={(theme: Theme) => ({
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: theme.colors.white,
              opacity: 0.02,
              zIndex: 2,
            })}
          />
        )}
      <Box
        csx={{
          display: 'flex',
          paddingInline: '15px',
          justifyContent: 'space-between',
          paddingTop: '15px',
          alignItems: 'center',
        }}>
        <Typography fontWeight="medium" align="center">
          {t('settingsModule.tableLayoutCreator.serviceAreas')}
        </Typography>
        {isMobile && (
          <ActionButtons
            reorderEnabled={reorderEnabled}
            onCancel={handleOnCancel}
            onDone={handleSaveLastServiceAreasOrder}
            onEdit={() => setReorderEnabled(true)}
            onAdd={handleScrollToEnd}
          />
        )}
      </Box>
      <Box csx={topBarStyles}>
        <Box
          ref={serviceAreasRef}
          csx={[
            servicesAreasContainerStyles,
            {
              paddingTop: isScrollBarVisible ? '5px' : '0px',
            },
          ]}>
          {sortedServiceAreas.length > 0 ? (
            <ServiceAreasList
              serviceAreas={sortedServiceAreas}
              reorderEnabled={reorderEnabled}
              selectedServiceArea={selectedServiceArea}
              setEditServiceArea={setEditServiceArea}
            />
          ) : (
            <Typography>
              {t(
                'settingsModule.tableLayoutCreator.noServiceArea.noServiceAreas',
              )}
            </Typography>
          )}
        </Box>

        {!isMobile && (
          <>
            <Divider direction="vertical" csx={{ height: '50px' }} />
            <ActionButtons
              reorderEnabled={reorderEnabled}
              onCancel={handleOnCancel}
              onDone={handleSaveLastServiceAreasOrder}
              onEdit={() => setReorderEnabled(true)}
              onAdd={handleScrollToEnd}
              showRearrangeButton={sortedServiceAreas.length > 0}
            />
          </>
        )}
      </Box>
      <AddOrUpdateServiceAreaModal
        mode="update"
        serviceArea={editServiceArea}
        setServiceArea={setEditServiceArea}
      />
    </Box>
  );
};
interface IActionButtons {
  reorderEnabled: boolean;
  onCancel: () => void;
  onDone: () => void;
  onEdit: () => void;
  onAdd: () => void;
  showRearrangeButton?: boolean;
}
const ActionButtons = ({
  reorderEnabled,
  onCancel,
  onDone,
  onEdit,
  onAdd,
  showRearrangeButton,
}: IActionButtons) => {
  const { t } = useTranslation();
  return (
    <Box csx={actionsContainerStyles}>
      {reorderEnabled ? (
        <>
          <Button onClick={onCancel}>{t('commonButtons.cancel')}</Button>
          <Button
            variant="primary"
            icon={<Icon name="MdCheck" />}
            className="reorderButton"
            onClick={onDone}>
            <Typography className="buttonText" color="textWhite">
              {t('commonButtons.done')}
            </Typography>
          </Button>
        </>
      ) : (
        <>
          {showRearrangeButton && (
            <Button
              variant="secondary"
              className="reorderButton"
              csx={{ minWidth: '120px', display: 'flex !important' }}
              icon={<Icon name="MdEdit" />}
              onClick={onEdit}>
              <Typography className="buttonText">
                {t('settingsModule.tableLayoutCreator.buttons.rearrange')}
              </Typography>
            </Button>
          )}
          <AddOrUpdateServiceAreaModal mode="add" onAddServiceArea={onAdd} />
        </>
      )}
    </Box>
  );
};

export default TopBar;
