import { css } from '@emotion/react';
import { DEFAULT_COLUMN_SIZE, TBreakpoints } from '../types';
import {
  MQ_MIN_LARGE,
  MQ_MIN_MEDIUM,
  MQ_MIN_SMALL,
  MQ_MIN_X_LARGE,
} from '@app/theme/types';
import isNumber from 'lodash/isNumber';

const returnFirstNotUndefined = (args: (number | undefined)[]) => {
  for (let i = 0; i < args.length; i++) {
    if (isNumber(args[i])) {
      return args[i];
    }
  }
  return undefined;
};

export const itemStyles = (
  { mb, sm, md, lg, xl }: TBreakpoints,
  centered: boolean,
  isOffset = false,
) => {
  const mbOffsetDisplay = mb;
  const smOffsetDisplay = returnFirstNotUndefined([sm, mb]);
  const mdOffsetDisplay = returnFirstNotUndefined([md, sm, mb]);
  const lgOffsetDisplay = returnFirstNotUndefined([lg, md, sm, mb]);
  const xlOffsetDisplay = returnFirstNotUndefined([xl, lg, md, sm, mb]);

  return css(centered ? centeredStyles : undefined, {
    flexBasis: '100%',
    width: '100%',
    gridColumn: `span ${mb || DEFAULT_COLUMN_SIZE}`,
    flexFlow: 'column',
    display:
      isOffset && (mbOffsetDisplay === 0 || mbOffsetDisplay === 12)
        ? 'none'
        : 'flex',
    [MQ_MIN_SMALL]: {
      gridColumn: `span ${sm || mb || DEFAULT_COLUMN_SIZE}`,
      display:
        isOffset && (smOffsetDisplay === 0 || smOffsetDisplay === 12)
          ? 'none'
          : 'flex',
    },
    [MQ_MIN_MEDIUM]: {
      gridColumn: `span ${md || sm || mb || DEFAULT_COLUMN_SIZE}`,
      display:
        isOffset && (mdOffsetDisplay === 0 || mdOffsetDisplay === 12)
          ? 'none'
          : 'flex',
    },
    [MQ_MIN_LARGE]: {
      gridColumn: `span ${lg || md || sm || mb || DEFAULT_COLUMN_SIZE}`,
      display:
        isOffset && (lgOffsetDisplay === 0 || lgOffsetDisplay === 12)
          ? 'none'
          : 'flex',
    },
    [MQ_MIN_X_LARGE]: {
      gridColumn: `span ${xl || lg || md || sm || mb || DEFAULT_COLUMN_SIZE}`,

      display:
        isOffset && (xlOffsetDisplay === 0 || xlOffsetDisplay === 12)
          ? 'none'
          : 'flex',
    },
  });
};

export const centeredStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
