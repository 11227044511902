import Grid from '@app/components/common/Grid';
import { IWithPrepStationVersionId } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import TicketBodyTable from './TicketBodyTable';
import TicketFooterTable from './TicketFooterTable';
import TicketHeaderTable from './TicketHeaderTable';

interface ICustomizingReceipts extends WithTranslation {
  hiddenElements?: Array<'ticketHeader' | 'ticketFooter' | 'ticketBody'>;
  titles?: Partial<{
    ticketHeader: string;
    ticketBody: string;
    ticketFooter: string;
  }>;
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

const CustomizingReceipts = ({
  hiddenElements = [],
  titles = {},
  prepStationVersionId,
}: ICustomizingReceipts) => {
  return (
    <>
      <Grid rowGap={45}>
        {!hiddenElements.includes('ticketHeader') && (
          <Grid.Item>
            <TicketHeaderTable
              prepStationVersionId={prepStationVersionId}
              title={titles.ticketHeader}
            />
          </Grid.Item>
        )}
        {!hiddenElements.includes('ticketBody') && prepStationVersionId && (
          <Grid.Item>
            <TicketBodyTable
              prepStationVersionId={prepStationVersionId}
              title={titles.ticketBody}
            />
          </Grid.Item>
        )}
        {!hiddenElements.includes('ticketFooter') && (
          <Grid.Item>
            <TicketFooterTable
              prepStationVersionId={prepStationVersionId}
              title={titles.ticketFooter}
            />
          </Grid.Item>
        )}
      </Grid>
    </>
  );
};

export default CustomizingReceipts;
