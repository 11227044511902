import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import menuTypeAssignedCombosFactory from '@app/helpers/factories/menu/cardFactories/menuTypes/menuTypeAssignedCombosFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectCombos,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, SectionId } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedCombosSection extends WithTranslation {
  onAssociate: (itemIds: IItemSelection[]) => void;
  onDisassociate: (id: number | string) => void;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);

const AssignedCombosSection = ({
  onDisassociate,
  onAssociate,
  t,
}: IAssignedCombosSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const combos = useSelector(selectCombos);
  const isMasterMenu = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const assignedComboIds = itemData.comboIds ?? [];
  const menuTypeId = itemData?.menuTypeId;
  const enableSimpleMode = itemData?.enableSimpleMode;

  const assignedCombosCardList = useMemo(() => {
    return menuTypeAssignedCombosFactory(
      combos,
      assignedComboIds,
      menuTypeId,
      (id, text, openInNewTab) => {
        const pathURL = `/menu/combos/combos/${id}`;
        if (openInNewTab) return openNewTabWithOrgData(pathURL);

        checkForChangesAndNavigateWeb(
          () =>
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.NAV,
              text,
              onPress: () =>
                navigate(pathURL, {
                  state: { menuTypeId },
                }),
              pathURL,
            }),
          onRefresh,
        );
      },
      id => getPathWithOrgData(`/menu/combos/combos/${id}`),
      enableSimpleMode,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combos, assignedComboIds, enableSimpleMode]);

  const closeModal = () => {
    setOpenAddExistingItem(false);
  };

  return (
    <AssignedElements
      type="combos"
      itemMenuTypeTemplate
      detailsScreenProps={{
        currentRelationsIds: assignedComboIds.map(id => `${id}`),
        wantedEntity: 'combos',
      }}
      noElementsMessage={t(
        'menuScreen.menuTypeDetails.assignedCombos.emptyCardsMessage',
      )}
      active={openAddExistingItem}
      onDisassociate={onDisassociate}
      onAssociate={onAssociate}
      onModalClose={closeModal}
      elements={assignedCombosCardList}
      subCardProps={{
        title: t('menuScreen.menuTypeDetails.assignedCombos.subtitle'),
        subtitle: t('menuScreen.menuTypeDetails.assignedCombos.label'),
        isEditButtonDisabled: !isMasterMenu && !isCreation,
        actionOptions: [
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewCombo'),
            icon: true,
            handler: () =>
              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.ADD,
                    text: t(
                      'menuScreen.menuLocation.actionButtons.addNewComboTag',
                    ),
                    onPress: () => {
                      navigate(
                        `/menu/combos/combos/add?assignedMenuTypeIds=${[
                          menuTypeId,
                        ]}`,
                      );
                    },
                    pathURL: '/menu/combos/combos/add',
                  }),
                onRefresh,
                true,
                false,
                'combos',
              ),
          },
          {
            text: t('menuScreen.menuLocation.actionButtons.addExistingCombo'),
            icon: false,
            handler: () => setOpenAddExistingItem(true),
          },
        ],
        csx: { borderWidth: 0, padding: 0 },
      }}
    />
  );
};

export default AssignedCombosSection;
