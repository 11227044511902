import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import menuTypeAssignedCategoriesFactory from '@app/helpers/factories/menu/cardFactories/menuTypes/menuTypeAssignedCategoriesFactory';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectCategories,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, SectionId } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICategoryCountMap } from '../types';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

interface IAssignedCategoriesSection extends WithTranslation {
  categoryCount: ICategoryCountMap;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);

const AssignedCategoriesSection = ({
  categoryCount,
  t,
}: IAssignedCategoriesSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const categories = useSelector(selectCategories);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const associatedCategoriesIds = itemData?.categoryIds;

  const associatedCategoriesCardList = useMemo(() => {
    return menuTypeAssignedCategoriesFactory(
      associatedCategoriesIds || [],
      categories,
      categoryCount,
      (id: number, text: string, openInNewTab?: boolean) => {
        const pathURL = `/menu/categories/${id}`;
        if (openInNewTab) return openNewTabWithOrgData(pathURL);

        const _navigate = () => navigate(pathURL);
        checkForChangesAndNavigateWeb(
          () =>
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.NAV,
              text,
              onPress: _navigate,
              pathURL,
            }),
          onRefresh,
        );
      },
      id => getPathWithOrgData(`/menu/categories/${id}`),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, associatedCategoriesIds, categoryCount, t]);

  const closeModal = () => {
    setOpenAddExistingItem(false);
  };

  return (
    <AssignedElements
      noElementsMessage={t(
        'menuScreen.menuTypeDetails.itemAssociations.emptyCardsMessage',
      )}
      active={openAddExistingItem}
      onModalClose={closeModal}
      elements={associatedCategoriesCardList}
      subCardProps={{
        title: t('menuScreen.menuTypeDetails.itemAssociations.subtitle'),
        subtitle: t('menuScreen.menuTypeDetails.itemAssociations.label'),
      }}
    />
  );
};

export default AssignedCategoriesSection;
