import { css, Theme } from '@emotion/react';

export const itemsTableNameStyles = (status: boolean) => (theme: Theme) =>
  css({
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    overflow: 'hidden',
    gap: '10px',
    '& .dot': {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
    },
    '& .color': {
      backgroundColor: status
        ? theme.colors.semanticBlue
        : theme.colors.semanticRed,
    },
    '& .svgColor': {
      backgroundColor: 'transparent',
      fill: status ? theme.colors.semanticBlue : theme.colors.semanticRed,
    },
  });
