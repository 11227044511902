import { selectMenu } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import {
  CoreRootState,
  IItem,
  IMenuTypeVersion,
  getIsMenuTypeItemHiddenCore,
  menuItemCardFactoryCore,
} from '@westondev/tableturn-core';

export type { IItemCard } from '@westondev/tableturn-core';

export { HiddenBucket } from '@westondev/tableturn-core';

export const menuItemCardFactory = (items: { [key: number]: IItem }) => {
  const state = store.getState();
  const currentMenu = selectMenu(state as CoreRootState & RootState);
  const { categories, subcategories, menuTypes } = currentMenu;

  return menuItemCardFactoryCore(items, categories, subcategories, menuTypes);
};

export const getIsMenuTypeItemHidden = (
  categoriesSubcategories: IMenuTypeVersion['categoriesSubcategories'],
  bucket: 'items' | 'combos',
  itemId?: number,
  menuTypeVersionId?: number,
) => {
  const state = store.getState();
  const currentMenu = selectMenu(state as CoreRootState);
  const { categories, subcategories, menuTypes } = currentMenu;
  return getIsMenuTypeItemHiddenCore(
    categoriesSubcategories,
    categories,
    subcategories,
    bucket,
    itemId,
    menuTypeVersionId,
    menuTypes,
  );
};
