import Card from '@app/components/common/Card';
import CheckboxMassUpdate from '@app/components/common/CheckBoxMassUpdate';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import {
  actionCreatorsMenu,
  FormMode,
  MassUpdateValues,
  SectionId,
  TCustomizerShape,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAllCoursesDropdownArray } from '../../selectors';
import {
  dropdownContainerStyles,
  generalSettingsCheckboxesStyles,
  generalSettingsCheckboxesSubsectionStyles,
  generalSettingsDropdownSubsectionStyles,
} from './styles';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayGeneralSubsection extends WithTranslation {
  menuTypeVersionId: number;
  courseId?: number | undefined | null;
  allowSharing: boolean | undefined | -1;
  activeOnline: boolean | undefined | MassUpdateValues;
  delivery: boolean | undefined | MassUpdateValues;
  customizer?: boolean | undefined | MassUpdateValues;
  isCombo?: boolean;
  mode?: FormMode;
}
const RegisterDisplayGeneralSubsection = ({
  t,
  menuTypeVersionId,
  courseId,
  allowSharing,
  activeOnline,
  delivery,
  customizer,
  isCombo,
  mode,
}: IRegisterDisplayGeneralSubsection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isMassUpdateProp = {
    isMassUpdate,
  };

  const coursesDropdownArray = useSelector(selectAllCoursesDropdownArray);

  const coursesDropdownOptions = useMemo(() => {
    const options = [
      ...coursesDropdownArray,
      [
        {
          label: t('settingsModule.coursing.noCourse'),
          value: 0,
        },
      ],
    ] as typeof coursesDropdownArray;

    if (isMassUpdate) {
      return [
        [
          {
            label: t('commonTexts.noChanges'),
            value: -1,
          },
        ],
        ...options,
      ];
    }

    return options;
  }, [coursesDropdownArray, isMassUpdate, t]);

  const sharingDropdownOptions = useMemo(() => {
    const options = [
      { label: t('commonTexts.yes'), value: 1 },
      { label: t('commonTexts.no'), value: 0 },
    ];

    if (isMassUpdate) {
      return [
        [
          {
            label: t('commonTexts.noChanges'),
            value: -1,
          },
        ],
        options,
      ];
    }

    return [options];
  }, [t, isMassUpdate]);

  return (
    <Card.SubCard
      title={t(
        'menuScreen.commonSections.registerDisplay.registerDisplayOptions.generalSettings',
      )}>
      <Grid columns={15}>
        {/* General Settings Dropdowns */}
        <Grid.Item mb={15} xl={6} csx={generalSettingsDropdownSubsectionStyles}>
          <Grid rowGap={15} columnGap={15}>
            {!isCombo && (
              <Grid.Item sm={6} csx={dropdownContainerStyles}>
                <Dropdown
                  data={coursesDropdownOptions}
                  onChange={_courseId => {
                    setValue(
                      { courseId: _courseId || null },
                      sectionId,
                      menuTypeVersionId,
                    );
                  }}
                  value={Number(courseId ?? 0)}
                  label={t('menuScreen.itemDetails.basic.coursingLabel')}
                  info={t('menuScreen.itemDetails.basic.coursingInfo')}
                />
              </Grid.Item>
            )}
            <Grid.Item sm={6} csx={dropdownContainerStyles}>
              <Dropdown
                label={
                  isCombo
                    ? t('menuScreen.comboDetails.basic.sharingLabel')
                    : t('menuScreen.itemDetails.basic.sharingLabel')
                }
                info={t('menuScreen.itemDetails.basic.sharingInfo')}
                data={sharingDropdownOptions}
                onChange={_allowSharing =>
                  setValue(
                    {
                      allowSharing:
                        _allowSharing === -1 ? -1 : Boolean(_allowSharing),
                    },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                value={Number(allowSharing ?? 0)}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>

        {/* General Checkboxes Subsection */}
        <Grid.Item
          mb={15}
          xl={9}
          csx={generalSettingsCheckboxesSubsectionStyles}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
              <CheckboxMassUpdate
                info={t(
                  isCombo
                    ? 'menuScreen.comboDetails.basic.showComboOnline'
                    : 'menuScreen.itemDetails.basic.showItemOnlineInfo',
                )}
                label={t(
                  isCombo
                    ? 'menuScreen.comboDetails.basic.showComboOnline'
                    : 'menuScreen.itemDetails.basic.showItemOnline',
                )}
                checked={activeOnline ?? false}
                onChange={_activeOnline =>
                  setValue(
                    { activeOnline: _activeOnline },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                onMassUpdateChange={_activeOnline =>
                  setValue(
                    { activeOnline: _activeOnline },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                {...isMassUpdateProp}
              />
            </Grid.Item>
            <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
              <CheckboxMassUpdate
                info={t('menuScreen.itemDetails.basic.deliveryInfo')}
                label={t('menuScreen.itemDetails.basic.deliveryLabel')}
                checked={delivery ?? false}
                onChange={_delivery =>
                  setValue(
                    { delivery: _delivery },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                onMassUpdateChange={_delivery =>
                  setValue(
                    { delivery: _delivery },
                    sectionId,
                    menuTypeVersionId,
                  )
                }
                {...isMassUpdateProp}
              />
            </Grid.Item>
            {!isCombo && (
              <Grid.Item md={4} csx={generalSettingsCheckboxesStyles}>
                <CheckboxMassUpdate
                  label={t(
                    'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerDisplay',
                  )}
                  info={t(
                    'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.customizerDisplaytoolTip',
                  )}
                  checked={customizer ?? false}
                  onChange={_customizer =>
                    setValue(
                      {
                        customizer: _customizer,
                        customizerShape: _customizer
                          ? TCustomizerShape.SQUARE
                          : '',
                      },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  onMassUpdateChange={_customizer =>
                    setValue(
                      {
                        customizer: _customizer,
                        customizerShape:
                          _customizer &&
                          _customizer !== MassUpdateValues.NO_CHANGES
                            ? TCustomizerShape.SQUARE
                            : '',
                      },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  {...isMassUpdateProp}
                />
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default RegisterDisplayGeneralSubsection;
