import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import ingredientAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/ingredients/ingredientAssignedItemsFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuMasterMode,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
  hideEdit?: boolean;
  hideMoreOptions?: boolean;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('ingredients', SECTION_ID);

const AssignedItemsSection = ({
  csx,
  hideEdit,
  hideMoreOptions,
  t,
}: IAssignedItemsCombosSection) => {
  // Redux
  const items = useSelector(selectItems);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const { itemData } = useSelector(getMemoizedItemData);

  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  // Local state
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedItemIds = itemData?.itemIds ?? [];

  const itemMenuTypeIds = itemData?.itemMenuTypeIds || {};

  const assignedItemsCardList = useMemo(() => {
    return ingredientAssignedItemsFactory(
      items,
      assignedItemIds || [],
      (deletedItemId: number) => {
        const newArray = [...assignedItemIds];
        const index = newArray.indexOf(deletedItemId);
        newArray.splice(index, 1);
        setValue({ itemIds: newArray }, SECTION_ID);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, assignedItemIds]);

  const closeModal = () => setOpenSelectionModal(false);

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const newItemIds = [
      ...assignedItemIds,
      ...buttons.map(button => Number(button.id)),
    ];

    let newItemMenuTypeIds = cloneDeep(itemMenuTypeIds);

    buttons.forEach(button => {
      newItemMenuTypeIds = {
        ...newItemMenuTypeIds,
        [button.id]: button.menuTypeId || [],
      };
    });

    setValue(
      { itemIds: newItemIds, itemMenuTypeIds: newItemMenuTypeIds },
      SECTION_ID,
    );
    closeModal();
  };

  return (
    <AssignedElements
      type="items"
      detailsScreenProps={{
        currentRelationsIds: assignedItemIds.map(id => `${id}`),
        wantedEntity: 'items',
      }}
      noElementsMessage={t(
        'menuScreen.ingredientDetails.associations.emptyCardsMessage',
      )}
      active={openSelectionModal}
      onAssociate={handleOnAssociate}
      onModalClose={closeModal}
      elements={assignedItemsCardList}
      subCardProps={{
        title: t('loggedIn.menuModule.tabs.items'),
        subtitle: t('menuScreen.ingredientDetails.associations.description'),
        isEditButtonDisabled: !isMasterMode,
        onEditMode: hideEdit ? undefined : setEditMode,
        actionOptions: hideMoreOptions
          ? undefined
          : [
              {
                text: t('components.actionButtons.addNewItem'),
                icon: true,
                handler: () =>
                  checkForChangesAndNavigateWeb(
                    () =>
                      addBreadcrumbLocationWeb({
                        action: BreadCrumbAction.ADD,
                        text: t('components.actionButtons.addNewItemTag'),
                        onPress: () => {
                          navigate('/menu/items/add');
                        },
                        pathURL: '/menu/items/add',
                      }),
                    onRefresh,
                    true,
                    false,
                    'items',
                  ),
              },
              {
                text: t('components.actionButtons.addExistingItem'),
                icon: false,
                handler: () => setOpenSelectionModal(true),
              },
            ],
        csx,
      }}
      renderItem={item => {
        const pathURL = `/menu/items/${item.id}`;
        return (
          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Card.Item
              csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
              {...item}
              showRemoveIcon={isEditMode}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                if (isEditMode) {
                  item?.onClick?.(
                    item.id as unknown as any, //FIXME: The onClick expects a MouseEvent type incorrectly
                  );
                  return;
                }
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: item.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          </Box>
        );
      }}
    />
  );
};

export default AssignedItemsSection;
