import Box from '@app/components/common/Box';
import { displayFlexColumnStyles } from '@app/theme/commonStyles';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ErrorRow } from './BucketValidationResolver';
import { ErrorTemplates } from './ErrorTemplates';
import useRootSelector from '@app/hooks/useRootSelector';

const RenderReportErrorBody = () => {
  const { t } = useTranslation();
  const errorsArray = useRootSelector(state =>
    Object.entries(state.reports.changeData.errors ?? {}),
  );

  const renderCommonError = (
    mtkey: string,
    mterr: string,
    transLationParams?: Record<string, string>,
  ) => (
    <ErrorRow key={mtkey}>
      <ErrorTemplates
        field={t(
          `menuScreen.itemDetails.validationFields.${mtkey}` as ParseKeys,
        )}
        errorKey={typeof mterr === 'string' ? mterr : ''}
        translationParams={transLationParams}
      />
    </ErrorRow>
  );

  const renderCustomIdError = (upcError: string) => {
    const customIdItemErrorName = upcError.replace(
      '<<uniqueCustomIdRequired>>',
      '',
    );

    return renderCommonError('customId', 'uniqueCustomIdRequired', {
      name: customIdItemErrorName,
    });
  };

  return (
    <>
      {errorsArray.length > 0 && (
        <Box
          csx={[
            displayFlexColumnStyles,
            { gap: '10px', marginBottom: '15px' },
          ]}>
          {errorsArray.map(([key, value]) =>
            key === 'customId' ? (
              renderCustomIdError(value as string)
            ) : (
              <ErrorRow key={key}>
                <ErrorTemplates
                  errorKey={value}
                  field={t(
                    `menuScreen.itemDetails.validationFields.${key}` as ParseKeys,
                  )}
                />
              </ErrorRow>
            ),
          )}
        </Box>
      )}
    </>
  );
};

export default RenderReportErrorBody;
