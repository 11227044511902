import { CustomMouseSensor } from '@app/sensors/CustomMouseSensor';
import {
  DndContext,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  createSnapModifier,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { Interpolation, Theme, css, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ElementTypes,
  IElement,
  ILayoutProps,
  actionCreatorsTableLayout,
  calculatePositionOfRotatedElement,
} from '@westondev/tableturn-core';
import { ReactNode, memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useControls } from 'react-zoom-pan-pinch';
import { resizeStyles } from './styles';
import Icon from '@app/components/common/Icon';
import {
  MotionValue,
  PanInfo,
  m,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'framer-motion';
import { getNewPositionWithRotation } from '@app/helpers/settings/tableLayoutCreator';
import { actionCreatorsApp } from '@app/state';

export type TElement = {
  element: IElement;
  layoutProps?: ILayoutProps;
};

interface IElementPanHandler extends TElement {
  layoutProps: {
    gridWidth: number;
    gridHeight: number;
    smallUnitSize: number;
    scale: number;
  };
  elementStyles: Interpolation<Theme>;
  defaultElementProps: { width: number; height: number };
  isSelected: boolean;
  showControls: boolean;
  children?: React.ReactNode;
  isDragEnabled?: boolean;
  setIsDragging?: (isDragging: boolean) => void;
}

const ELEMENT_BORDER_RADIUS = 5;
const ROTATION_UNIT = 15;

const ElementPanHandler = ({
  element,
  elementStyles,
  defaultElementProps,
  layoutProps,
  isSelected,
  showControls,
  children,
  isDragEnabled = true,
}: IElementPanHandler) => {
  // Redux
  const dispatch = useDispatch();
  const { selectElement, updateElementAndSaveLastChange } = bindActionCreators(
    actionCreatorsTableLayout,
    dispatch,
  );

  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );

  // Local state
  const theme = useTheme();
  const showControlsOnHover = useMotionValue(false);

  const snapToGrid = useMemo(
    () => createSnapModifier(layoutProps.smallUnitSize),
    [layoutProps],
  );

  const { zoomToElement } = useControls();

  const sensors = useSensors(
    useSensor(CustomMouseSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 200, tolerance: 0 },
    }),
  );

  const elementX = element.xCoordinate;
  const elementY = element.yCoordinate;
  const translateX = useMotionValue(elementX);
  const translateY = useMotionValue(elementY);

  // Element size
  const elementWidth = useMotionValue(element.width);
  const elementHeight = useMotionValue(element.height);
  const elementRotation = useMotionValue(element.rotation);
  const elementRotationBack = useMotionValue(element.rotation);

  const isDragging = useMotionValue(false);
  const isHorizontalResizeActive = useMotionValue(false);
  const isVerticalResizeActive = useMotionValue(false);
  const isDiagonalResizeActive = useMotionValue(false);
  const isRotationActive = useMotionValue(false);

  const isResizing = useMotionValue(false);

  useEffect(() => {
    elementWidth.set(element.width);
  }, [element.width, elementWidth]);

  useEffect(() => {
    elementHeight.set(element.height);
  }, [element.height, element.width, elementHeight]);

  useEffect(() => {
    translateX.set(element.xCoordinate);
    translateY.set(element.yCoordinate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.xCoordinate, element.yCoordinate]);

  useEffect(() => {
    elementRotation.set(element.rotation);
    elementRotationBack.set(element.rotation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.rotation]);

  useMotionValueEvent(isResizing, 'change', _isResizing => {
    if (_isResizing) {
      if (!isVerticalResizeActive.get())
        resizeVerticalAnimatedStyles.set('none');
      if (!isDiagonalResizeActive.get())
        resizeDiagonalAnimatedStyles.set('none');
      if (!isRotationActive.get()) rotationAnimatedStyles.set('none');
    }
  });

  const resizeHorizontalAnimatedStyles = useTransform(() => {
    return showControls || showControlsOnHover.get()
      ? isDragging.get() ||
        (isResizing.get() && !isHorizontalResizeActive.get())
        ? 'none'
        : 'flex'
      : 'none';
  });

  const resizeVerticalAnimatedStyles = useTransform(() => {
    return showControls || showControlsOnHover.get()
      ? isDragging.get() || (isResizing.get() && !isVerticalResizeActive.get())
        ? 'none'
        : 'flex'
      : 'none';
  });

  const resizeDiagonalAnimatedStyles = useTransform(() => {
    return showControls || showControlsOnHover.get()
      ? isDragging.get() || (isResizing.get() && !isDiagonalResizeActive.get())
        ? 'none'
        : 'flex'
      : 'none';
  });

  const rotationAnimatedStyles = useTransform(() => {
    return showControls || showControlsOnHover.get()
      ? isDragging.get() || (isResizing.get() && !isRotationActive.get())
        ? 'none'
        : 'flex'
      : 'none';
  });

  const onResizeHorizontal = (info: PanInfo) => {
    const XPosition = info.offset.x;
    const YPosition = info.offset.y;

    const snapXPosition =
      Math.round(
        (element.rotation === 90 ? YPosition : XPosition) /
          layoutProps.smallUnitSize,
      ) * layoutProps.smallUnitSize;
    const newWidth = element.width + snapXPosition;

    const calculatedValues = calculatePositionOfRotatedElement(
      { ...element, width: newWidth },
      element.xCoordinate,
      element.yCoordinate,
    );

    if (
      element.rotation === 90 ||
      (newWidth >= defaultElementProps.width &&
        newWidth + element.xCoordinate <= layoutProps.gridWidth)
    ) {
      elementWidth.set(newWidth);

      const differenceX =
        translateX.get() - calculatedValues.calculatedLeftXPosition;
      translateX.set(elementX + differenceX);

      const differenceY =
        translateY.get() - calculatedValues.calculatedYPosition;
      translateY.set(elementY + differenceY);
    }
  };

  const onResizeHorizontalEnd = () => {
    isResizing.set(false);
    isHorizontalResizeActive.set(false);
    const realSnapXPosition =
      Math.round(translateX.get() / layoutProps.smallUnitSize) *
      layoutProps.smallUnitSize;
    const realSnapYPosition =
      Math.round(translateY.get() / layoutProps.smallUnitSize) *
      layoutProps.smallUnitSize;

    const calculatedValues = getNewPositionWithRotation(
      {
        ...element,
        width: elementWidth.get(),
        xCoordinate: realSnapXPosition,
        yCoordinate: realSnapYPosition,
        rotation: 0,
      },
      element.rotation,
    );

    if (!calculatedValues) {
      setShowToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });
      elementWidth.set(element.width);
      translateX.set(element.xCoordinate);
      translateY.set(element.yCoordinate);
      return;
    }
    const newData = {
      ...element,
      width: elementWidth.get(),
      xCoordinate: element.rotation
        ? (calculatedValues.xCoordinate as number)
        : element.xCoordinate,
      yCoordinate: element.rotation
        ? (calculatedValues.yCoordinate as number)
        : element.yCoordinate,
    };
    updateElementAndSaveLastChange(element, newData);
  };

  const onResizeVertical = (info: PanInfo) => {
    const XPosition = info.offset.x;
    const YPosition = info.offset.y;

    const snapYPosition =
      Math.round(
        (element.rotation === 90 ? -XPosition : YPosition) /
          layoutProps.smallUnitSize,
      ) * layoutProps.smallUnitSize;
    const newHeight = element.height + snapYPosition;

    const calculatedValues = calculatePositionOfRotatedElement(
      { ...element, height: newHeight },
      element.xCoordinate,
      element.yCoordinate,
    );

    if (
      element.rotation === 90 ||
      (newHeight >= defaultElementProps.height &&
        newHeight + element.yCoordinate <= layoutProps.gridHeight)
    ) {
      elementHeight.set(newHeight);

      const differenceX =
        translateX.get() - calculatedValues.calculatedLeftXPosition;
      translateX.set(elementX + differenceX);

      const differenceY =
        translateY.get() - calculatedValues.calculatedYPosition;
      translateY.set(elementY + differenceY);
    }
  };

  const onResizeVerticalEnd = () => {
    isResizing.set(false);
    isVerticalResizeActive.set(false);

    const realSnapXPosition =
      Math.round(translateX.get() / layoutProps.smallUnitSize) *
      layoutProps.smallUnitSize;
    const realSnapYPosition =
      Math.round(translateY.get() / layoutProps.smallUnitSize) *
      layoutProps.smallUnitSize;

    const calculatedValues = getNewPositionWithRotation(
      {
        ...element,
        height: elementWidth.get(),
        xCoordinate: realSnapXPosition,
        yCoordinate: realSnapYPosition,
        rotation: 0,
      },
      element.rotation,
    );

    if (!calculatedValues) {
      setShowToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });
      elementHeight.set(element.height);
      return;
    }
    const newData = {
      ...element,
      height: elementHeight.get(),
      xCoordinate: element.rotation
        ? (calculatedValues.xCoordinate as number)
        : element.xCoordinate,
      yCoordinate: element.rotation
        ? (calculatedValues.yCoordinate as number)
        : element.yCoordinate,
    };
    updateElementAndSaveLastChange(element, newData);
  };

  const onResizeDiagonal = (info: PanInfo) => {
    const YPosition = info.offset.y;
    const snapYPosition =
      Math.round(YPosition / layoutProps.smallUnitSize) *
      layoutProps.smallUnitSize;

    const newSize = element.height + snapYPosition;
    const newWidth = element.width + snapYPosition;
    const newHeight = element.height + snapYPosition;

    if (
      newSize >= defaultElementProps.height &&
      newWidth >= defaultElementProps.width &&
      newWidth + element.xCoordinate <= layoutProps.gridWidth &&
      newHeight + element.yCoordinate <= layoutProps.gridHeight
    ) {
      elementWidth.set(newWidth);
      elementHeight.set(newHeight);
    }
  };

  const onResizeDiagonalEnd = () => {
    isResizing.set(false);
    isDiagonalResizeActive.set(false);

    const calculatedValues = getNewPositionWithRotation(
      {
        ...element,
        width: elementWidth.get(),
        height: elementHeight.get(),
        rotation: 0,
      },
      element.rotation,
    );

    if (!calculatedValues) {
      setShowToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });
      elementWidth.set(element.width);
      elementHeight.set(element.height);
      return;
    }
    const newData = {
      ...element,
      width: elementWidth.get(),
      height: elementHeight.get(),
      xCoordinate: element.rotation
        ? (calculatedValues.xCoordinate as number)
        : element.xCoordinate,
      yCoordinate: element.rotation
        ? (calculatedValues.yCoordinate as number)
        : element.yCoordinate,
    };
    updateElementAndSaveLastChange(element, newData);
  };

  const onRotation = (info: PanInfo) => {
    const YPosition = info.offset.y;
    const snapYPosition = Math.round(YPosition / ROTATION_UNIT) * ROTATION_UNIT;
    const newRotation = element.rotation + snapYPosition;
    if (newRotation >= -90 && newRotation <= 90) {
      elementRotation.set(newRotation);
    }
  };

  const onRotationEnd = () => {
    isResizing.set(false);
    isRotationActive.set(false);
    const values = getNewPositionWithRotation(element, elementRotation.get());
    let newData = element;

    if (!values) {
      setShowToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });
      elementRotation.set(element.rotation);
    }
    newData = {
      ...element,
      ...values,
    };
    updateElementAndSaveLastChange(element, newData);
  };

  const rotateZBackGround = useTransform(() => {
    return `${elementRotationBack.get()}deg`;
  });

  const rotateBorder = useTransform(() => {
    return isResizing.get()
      ? `1px dashed ${theme.colors.darkerYellow}`
      : 'none';
  });

  const handleSelectElement = () => {
    selectElement({
      id: element.id,
      type: element.type,
      showControls: element.type === ElementTypes.CUSTOM ? true : showControls,
    });
  };
  const handleSelectElementAndFocus = () => {
    selectElement({
      id: element.id,
      type: element.type,
      showControls: element.type === ElementTypes.CUSTOM ? true : showControls,
    });
    if (!isSelected) {
      setTimeout(() => {
        zoomToElement(`${element.id}-draggable`, undefined, 150);
      }, 0);
    }
  };

  return (
    <>
      {element.type === ElementTypes.CUSTOM && (
        <m.div
          css={elementStyles}
          style={{
            width: elementWidth,
            height: elementHeight,
            display: 'flex',
            backgroundColor: 'transparent',
            border: rotateBorder,
            left: translateX,
            top: translateY,
            rotate: rotateZBackGround,
            zIndex: 1,
          }}
        />
      )}

      <DndContext
        sensors={sensors}
        modifiers={[snapToGrid, restrictToParentElement]}
        autoScroll={true}
        onDragEnd={({ delta }) => {
          const newX = element.xCoordinate + delta.x;
          const newY = element.yCoordinate + delta.y;
          const newData = {
            ...element,
            xCoordinate: newX,
            yCoordinate: newY,
          };

          updateElementAndSaveLastChange(element, newData);
        }}>
        <DraggableElement
          isDisabled={!isDragEnabled}
          isSelected={isSelected}
          element={element}
          elementStyles={[elementStyles]}
          onClick={handleSelectElement}
          onDoubleClick={handleSelectElementAndFocus}
          elementTransform={{
            rotation: elementRotation,
            width: elementWidth,
            height: elementHeight,
            x: translateX,
            y: translateY,
            isDraggingMotion: isDragging,
            isResizingMotion: isResizing,
          }}
          showControlsOnHover={showControlsOnHover}
          controls={
            (showControls || element.type === ElementTypes.CUSTOM) && (
              <>
                <m.div
                  onPanStart={() => {
                    isResizing.set(true);
                    isHorizontalResizeActive.setCurrent(true);
                    isVerticalResizeActive.setCurrent(false);
                    const values = calculatePositionOfRotatedElement(
                      { ...element, width: element.width },
                      element.xCoordinate,
                      element.yCoordinate,
                    );
                    translateX.set(values.calculatedLeftXPosition);
                    translateY.set(values.calculatedYPosition);
                  }}
                  onPan={(_, info) => onResizeHorizontal(info)}
                  onPanEnd={onResizeHorizontalEnd}
                  style={{
                    ...resizeStyles.horizontalResizing,
                    display: resizeHorizontalAnimatedStyles,
                  }}>
                  <Icon name="MdDragIndicator" color="persistentSemanticBlue" />
                </m.div>
                <m.div
                  onPanStart={() => {
                    isResizing.set(true);
                    isVerticalResizeActive.set(true);
                    const values = calculatePositionOfRotatedElement(
                      { ...element, width: element.width },
                      element.xCoordinate,
                      element.yCoordinate,
                    );
                    translateX.set(values.calculatedLeftXPosition);
                    translateY.set(values.calculatedYPosition);
                  }}
                  onPan={(_, info) => onResizeVertical(info)}
                  onPanEnd={onResizeVerticalEnd}
                  style={{
                    ...resizeStyles.verticalResizing,
                    display: resizeVerticalAnimatedStyles,
                  }}>
                  <Icon
                    name="MdDragIndicator"
                    color="persistentSemanticBlue"
                    csx={{ rotate: '90deg' }}
                  />
                </m.div>

                <m.div
                  onPanStart={() => {
                    isResizing.set(true);
                    isDiagonalResizeActive.set(true);
                  }}
                  onPan={(_, info) => onResizeDiagonal(info)}
                  onPanEnd={onResizeDiagonalEnd}
                  css={resizeStyles.diagonalResizing}
                  style={{
                    display: resizeDiagonalAnimatedStyles,
                  }}
                />
                <m.div
                  onPanStart={() => {
                    isResizing.set(true);
                    isRotationActive.set(true);
                  }}
                  onPan={(_, info) => onRotation(info)}
                  onPanEnd={onRotationEnd}
                  style={{
                    ...resizeStyles.rotateIcon,
                    display: rotationAnimatedStyles,
                  }}>
                  <Icon
                    name="MdOutlineRotateRight"
                    color="persistentSemanticBlue"
                    size="20px"
                  />
                </m.div>
              </>
            )
          }>
          {children}
        </DraggableElement>
      </DndContext>
    </>
  );
};

const DraggableElement = ({
  isDisabled,
  elementStyles,
  isSelected,
  element,
  children,
  onClick,
  onDoubleClick,
  elementTransform,
  showControlsOnHover,
  controls,
}: {
  isDisabled: boolean;
  isSelected: boolean;
  element: IElement;
  elementStyles: Interpolation<Theme>;
  children: ReactNode;
  elementTransform: {
    width: MotionValue<number>;
    height: MotionValue<number>;
    x: MotionValue<number>;
    y: MotionValue<number>;
    rotation: MotionValue<number>;
    isDraggingMotion: MotionValue<boolean>;
    isResizingMotion: MotionValue<boolean>;
  };
  showControlsOnHover: MotionValue<boolean>;
  controls: ReactNode;
  onClick: () => void;
  onDoubleClick: () => void;
}) => {
  const { width, height, x, y, rotation, isDraggingMotion, isResizingMotion } =
    elementTransform;
  const theme = useTheme();

  const activeStyle = {
    zIndex: 2,
    border: `1px solid ${theme.colors.persistentSemanticBlue}`,
  };
  const inactiveStyle = {
    zIndex: 1,
    border: `1px solid ${theme.colors.semanticGrey}`,
  };

  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useDraggable({
      id: `${element.id}-draggable`,
      disabled: isDisabled,
    });

  const { instance: layoutAreaInstance } = useControls();

  useEffect(() => {
    if (isDragging) {
      layoutAreaInstance.clearPanning(new MouseEvent(''));
    }
    isDraggingMotion.set(isDragging);
  }, [layoutAreaInstance, isDragging, isDraggingMotion]);

  useMotionValueEvent(isResizingMotion, 'change', () => {
    layoutAreaInstance.clearPanning(new MouseEvent(''));
  });

  const moveElementStyle = css({
    borderRadius:
      element.shape === 'circle' ? element.width / 2 : ELEMENT_BORDER_RADIUS,
    ...(isDragging ? activeStyle : isSelected ? activeStyle : inactiveStyle),
    touchAction: 'none',
    cursor: isDragging ? 'default' : 'move',
  });

  const rotateZ = useTransform(() => {
    return `${rotation.get()}deg`;
  });

  return (
    <m.div
      {...attributes}
      onHoverStart={() => {
        if (element.type === ElementTypes.CUSTOM) {
          showControlsOnHover.set(true);
        }
      }}
      onHoverEnd={() => {
        if (element.type === ElementTypes.CUSTOM)
          showControlsOnHover.set(false);
      }}
      id={`${element.id}-draggable`}
      ref={setNodeRef}
      css={[elementStyles, moveElementStyle]}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onDoubleClickCapture={onDoubleClick}
      style={{
        top: y,
        left: x,
        translate: `${transform?.x || 0}px ${transform?.y || 0}px`,
        width,
        height,
        rotateZ,
      }}>
      <div
        css={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...listeners}>
        {children}
      </div>
      {controls}
    </m.div>
  );
};

const ElementPanHandlerMemo = memo(ElementPanHandler, (prev, next) => {
  return (
    prev.isSelected === next.isSelected &&
    prev.showControls === next.showControls &&
    prev.element.shape === next.element.shape &&
    prev.element.name === next.element.name &&
    prev.element.width === next.element.width &&
    prev.element.height === next.element.height &&
    prev.element.rotation === next.element.rotation &&
    prev.element.seats === next.element.seats &&
    prev.element.xCoordinate === next.element.xCoordinate &&
    prev.element.yCoordinate === next.element.yCoordinate &&
    prev.layoutProps.scale === next.layoutProps.scale
  );
});
export default ElementPanHandlerMemo;
