import { TCsx } from '@emotion/react';
import {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  ReactElement,
  RefAttributes,
  forwardRef,
  useState,
} from 'react';
import Box from '../Box';
import Button from '../Button';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import Typography from '../Typography';
import InputNumberWithButtons from './InputNumberWithButtons';
import {
  captionStyles,
  emptyCaptionStyles,
  errorCaptionStyles,
  inputContainerStyles,
  inputPadding,
  inputStyles,
  inputWrapperStyles,
  labelStyles,
} from './styles';

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  emptyLabel?: boolean;
  placeholder?: string;
  error?: boolean;
  caption?: string;
  info?: string;
  icon?: ReactElement;
  onIconPress?: () => void;
  hideRequiredSymbol?: boolean;
  showCaptionSpace?: boolean;
  inputWrapperClassName?: string;
  csx?: TCsx;
  containerCsx?: TCsx;
  css?: never;
  trimOnBlur?: boolean;
}

const Input: ForwardRefExoticComponent<
  IInput & RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, IInput>(
  (
    {
      type = 'text',
      width,
      label,
      emptyLabel,
      placeholder,
      error,
      caption,
      info = label || 'TOOLTIP_INFO',
      icon,
      onIconPress,
      hideRequiredSymbol = false,
      showCaptionSpace = false,
      inputWrapperClassName,
      containerCsx,
      trimOnBlur,
      ...otherProps
    },
    ref,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [inputActive, setInputActive] = useState(false);
    const [showHover, setShowHover] = useState(false);
    const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

    const customInputProps = {
      paddingRight:
        type === 'password' || icon
          ? `${inputPadding - 10}px`
          : `${inputPadding}px`,
    };

    const handleSetPasswordVisible = () => {
      setPasswordVisible(prev => !prev);
    };

    const handlePopUp = () => {
      if (newTimeout) clearTimeout(newTimeout);
      setNewTimeout(
        setTimeout(() => {
          setShowHover(true);
        }, 500),
      );
    };

    return (
      <Box csx={inputWrapperStyles} className={inputWrapperClassName}>
        {(label || emptyLabel) && (
          <label
            onMouseOver={handlePopUp}
            onMouseOut={() => {
              if (newTimeout) clearTimeout(newTimeout);
              setShowHover(false);
            }}
            css={labelStyles}
            className={`${otherProps.disabled ? 'disabled' : ''}`}>
            {info && (
              <div className="infoButton">
                <ToolTip
                  showHover={showHover}
                  content={info}
                  tooltipCsx={{ maxWidth: '250px' }}>
                  <div
                    style={{
                      height: '10px',
                    }}
                  />
                </ToolTip>
              </div>
            )}
            {!emptyLabel ? label : ''}
            {otherProps.required && !hideRequiredSymbol && (
              <span className="required">*</span>
            )}
          </label>
        )}
        <Box
          csx={[inputContainerStyles, { width }, containerCsx]}
          className={`${inputActive ? 'active' : ''} ${error ? 'error' : ''}`}>
          <input
            ref={ref}
            onFocus={() => setInputActive(true)}
            type={passwordVisible ? 'text' : type}
            css={[inputStyles, customInputProps]}
            placeholder={placeholder || 'Insert Text'}
            {...otherProps}
            onBlur={e => {
              setInputActive(false);
              if (trimOnBlur) {
                otherProps.onChange?.({
                  ...e,
                  target: { ...e.target, value: (e.target.value || '').trim() },
                });
              }
              otherProps.onBlur && otherProps.onBlur(e);
            }}
          />
          {type === 'password' ? (
            <Button
              type="button"
              whileHover={{ filter: 'none' }}
              whileTap={{ scale: 1 }}
              variant="icon"
              tabIndex={-1}
              icon={
                <Icon
                  name={passwordVisible ? 'MdVisibilityOff' : 'MdVisibility'}
                />
              }
              onClick={handleSetPasswordVisible}
            />
          ) : (
            icon && (
              <Button
                type="button"
                whileHover={{ filter: 'none' }}
                whileTap={{ scale: 1 }}
                variant="icon"
                icon={icon}
                onClick={onIconPress}
              />
            )
          )}
        </Box>
        {(!caption && !error && showCaptionSpace) ||
        (!caption && error && showCaptionSpace) ? (
          <span css={emptyCaptionStyles}></span>
        ) : (
          (caption || (error && caption)) && (
            <Typography csx={error ? errorCaptionStyles : captionStyles}>
              {caption || error}
            </Typography>
          )
        )}
      </Box>
    );
  },
);

Input.apply = InputNumberWithButtons;

Input.displayName = 'InputComponent';

export default Input;
