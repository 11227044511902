import { store } from '@app/state/store';
import {
  CoreRootState,
  IElement,
  getNewPositionWithRotationCore,
} from '@westondev/tableturn-core';

export {
  calculatePositionOfRotatedElement,
  getWidthAndHeightOfRotatedElement,
} from '@westondev/tableturn-core';

export const getNewPositionWithRotation = (
  element: IElement,
  newRotation: number,
  validateRotationZero = false,
) =>
  getNewPositionWithRotationCore(
    element,
    newRotation,
    store.getState() as unknown as CoreRootState,
    validateRotationZero,
  );
