import { NAVBAR_HEIGHT } from '@app/components/LoggedIn/NavBar/types';
import { MQ_MIN_MEDIUM, MQ_MIN_SMALL, ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const baseModalStyles = (
  theme: Theme,
  width: string | number,
  minHeight: string | number,
  height: string | number,
) =>
  css({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '.backDrop': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor:
        theme.mode === ThemeModes.DARK
          ? 'rgba(75,75,75,.6)'
          : 'rgba(0,0,0,0.5)',
    },
    '.modalContainer': {
      width: '100%',
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      backgroundColor:
        theme.mode === ThemeModes.DARK
          ? theme.colors.background
          : theme.colors.white,
      borderRadius: '10px',
      boxShadow: theme.shadows[2],
      position: 'relative',
      '.close': {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '30px',
      },
      maxWidth: 'calc(100vw - 30px)',
      maxHeight: '95%',
      [MQ_MIN_MEDIUM]: {
        width,
        minHeight,
        height,
      },
    },
    '.modalTitleContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: theme.shadows[1],
      height: '60px',
      padding: '20px',
      zIndex: 99,
    },
    '.modalContent': {
      padding: '20px',
      overflowY: 'auto',
      height: '100%',
      flexGrow: 1,
    },
    '.modalCloseButton': {
      marginTop: '5px',
    },
    '.footer': {
      borderTop: `1px solid ${theme.colors.lightGrey}`,
      paddingInline: '20px',
      paddingBlock: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      flexGrow: 0,
    },
  });

export const rightBaseStyles = (width: string | number) =>
  css({
    position: 'absolute',
    top: '0',
    right: '0',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '.modalContainer': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      [MQ_MIN_SMALL]: {
        width,
      },
    },
  });
export const modalStyles =
  (
    width: string | number,
    minHeight: string | number,
    height: string | number,
    position: 'center' | 'right',
    displayAfterNavBar: boolean,
  ) =>
  (theme: Theme) => {
    const positionStyles = position === 'right' ? rightBaseStyles(width) : {};

    return css(
      baseModalStyles(theme, width, minHeight, height),
      positionStyles,
      {
        top: displayAfterNavBar ? NAVBAR_HEIGHT : 0,
        height: displayAfterNavBar
          ? `calc(100% - ${NAVBAR_HEIGHT}px)`
          : `calc(100% - ${NAVBAR_HEIGHT / 2}px)`,

        '.backDrop': {
          top: displayAfterNavBar ? NAVBAR_HEIGHT : 0,
        },
      },
    );
  };
