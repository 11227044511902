import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { useTheme } from '@emotion/react';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  commonSubsectionStyles,
  previewSubsectionStyles,
  registerDisplaySubsectionStyles,
} from '../../../Categories/CategoriesDetails/BasicSection/styles';
import BgAndTextColorPickers from '../../../common/BgAndTextColorPickers';

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const RegisterDisplaySection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const dispatch = useDispatch();

  const { updateSubcategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const theme = useTheme();

  const { itemData } = useSelector(getMemoizedItemData);

  const backgroundColor = itemData?.backgroundColor || theme.colors.cardWhite;

  const textColor = itemData?.textColor || theme.colors.black;

  const onReset = () => {
    setValue({ backgroundColor: null, textColor: null });
  };

  const onResetBackground = () => {
    setValue({ backgroundColor: null });
  };

  const onResetText = () => {
    setValue({ textColor: null });
  };

  return (
    <AccordionSection
      title={t('menuScreen.commonSections.registerDisplay.title')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      noPadding
      {...props}>
      <>
        <Grid
          rowGap={20}
          columns={21}
          csx={{ alignItems: 'flex-start', padding: '10px 20px 20px' }}>
          <Grid.Item
            mb={12}
            lg={7}
            csx={[
              commonSubsectionStyles,
              registerDisplaySubsectionStyles,
              {
                height: '100%',
                margin: '0px !important',
              },
            ]}>
            <BgAndTextColorPickers
              backgroundColor={backgroundColor}
              textColor={textColor}
              onBackgroundColorChange={color =>
                setValue({ backgroundColor: color })
              }
              onTextColorChange={color => setValue({ textColor: color })}
              onReset={onReset}
              onResetBackground={onResetBackground}
              onResetText={onResetText}
            />
          </Grid.Item>
          <Grid.Item
            mb={12}
            lg={1}
            csx={{
              height: '100%',
            }}>
            <Divider
              lineCsx={{
                borderWidth: '2px',
              }}
              direction="vertical"
              csx={{
                height: '100%',
              }}
            />
          </Grid.Item>
          <Grid.Item
            mb={12}
            lg={5}
            csx={[
              commonSubsectionStyles,
              previewSubsectionStyles,
              {
                height: '100%',
                borderRight: 0,
                margin: '0px !important',
              },
            ]}>
            <DetailsSubtitle>
              {t('menuScreen.categoryDetails.basic.previewTitle')}
            </DetailsSubtitle>
            <Box
              csx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Button
                disableHoverEffect
                csx={{
                  width: '125px',
                  height: '125px',
                  backgroundColor: backgroundColor,
                }}>
                <Typography
                  fontWeight="medium"
                  csx={{
                    color: textColor,
                  }}>
                  {itemData?.posName ||
                    t('loggedIn.commonFields.basic.posName')}
                </Typography>
              </Button>
            </Box>
          </Grid.Item>
        </Grid>
      </>
    </AccordionSection>
  );
};

export default RegisterDisplaySection;
