import { Theme, css } from '@emotion/react';
import { NAVBAR_HEIGHT } from '../NavBar/types';
import { SIDEBAR_WIDTH } from '../SideBar/styles';
import { MQ_MIN_MEDIUM } from '@app/theme/types';

export const layoutContainerStyles = (theme: Theme) =>
  css({
    '.layoutContentContainer': {
      display: 'flex',
      height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
      width: '100%',
      overflow: 'hidden',
    },
    '.contentContainer': {
      backgroundColor: theme.colors.background,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      [MQ_MIN_MEDIUM]: {
        width: `calc(100% - ${SIDEBAR_WIDTH})`,
      },
    },
    '.outletContainer': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: '100%',
      overflow: 'auto',
    },
    '.saveAndCancelBarContainer': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 0,
    },
  });
