import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import Icon from '@app/components/common/Icon';
import MoneyInput from '@app/components/common/MoneyInput';
import OptionsModal from '@app/components/common/OptionsModal';
import Table from '@app/components/common/Table';
import { ETableModes } from '@app/components/common/Table/types';
import Typography from '@app/components/common/Typography';
import { selectRoles } from '@app/state/selectors/settingsSelectors';
import { rolesDropdownOptions } from '@app/state/settings/paymentsFactory';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import { INVISIBLE_CHAR, ITipRolePool } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROW_ID_PREFIX } from '../../RegisterMode/SpecialTags/types';

export interface ITipRolePoolTable {
  id: number | string;
  roleId: number;
  amount: number;
  actions: string;
}

export interface ITipRolePoolTableProps extends WithTranslation {
  data: ITipRolePoolTable[];
  title: string;
  firstColumnTitle: string;
  secondColumnTitle: string;
  noDataText: string;
  hasZeroText: string;
  updateValue: (newValue: Partial<ITipRolePool>, id: number | string) => void;
  newRowText?: string;
  onNewRow?: () => void;
  onRowDelete?: (id: number | string) => void;
  onCellPress?: (id: number | string) => void;
  dropdownOptions?: IDropdownItem[];
  validatePercentages?: boolean;
  customErrorText?: string;
}

const TipRolePoolTable = ({
  t,
  data,
  title,
  firstColumnTitle,
  secondColumnTitle,
  newRowText,
  hasZeroText,
  onNewRow,
  updateValue,
  onRowDelete,
  noDataText,
  onCellPress,
  dropdownOptions,
  validatePercentages,
  customErrorText,
}: ITipRolePoolTableProps) => {
  const roles = useSelector(selectRoles);

  const theme = useTheme();

  const rolesOptions = useMemo(() => {
    const currentRoles = data.map(role => role.roleId);
    return rolesDropdownOptions(roles, currentRoles);
  }, [data, roles]);

  const percentagesSum = useMemo(() => {
    let sum = 0;
    data.forEach(row => {
      sum += row.amount;
    });
    return sum;
  }, [data]);

  const rowHasZero = useMemo(() => {
    for (const row of data) {
      if (row.amount === 0) {
        return true;
      }
    }
    return false;
  }, [data]);

  const columnHelper = createColumnHelper<ITipRolePoolTable>();

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('roleId', {
        header: firstColumnTitle,
        cell: info => (
          <OptionsModal
            options={dropdownOptions || rolesOptions}
            isSearchable
            noElementMessage={t('commonTexts.noRoles')}
            title={firstColumnTitle}
            buttonProps={{
              csx: {
                justifyContent: 'space-between',
                textAlign: 'left',
                width: '100%',
              },
            }}
            customText={roles?.[info.getValue()]?.name}
            iconSize={24}
            onChange={(roleId: number | null) =>
              updateValue({ roleId: roleId as number }, info.row.original.id)
            }
          />
        ),
        minSize: 200,
      }),
      columnHelper.accessor('amount', {
        header: secondColumnTitle,
        cell: info => (
          <MoneyInput
            required
            prefix={INVISIBLE_CHAR}
            suffix="%"
            precision={0}
            minValue={0}
            maxValue={100}
            value={info.getValue()}
            onValueChange={
              onCellPress
                ? () => null
                : val => {
                    updateValue({ amount: val }, info.row.original.id);
                  }
            }
            showSoftInputOnFocus={onCellPress ? false : true}
            onFocus={() => onCellPress && onCellPress(info.row.original.id)}
            error={info.getValue() <= 0 || info.getValue() > 100}
          />
        ),
        size: 250,
      }),
      ...(onRowDelete
        ? [
            columnHelper.accessor('actions', {
              header: t('commonButtons.delete'),
              cell: info =>
                onRowDelete ? (
                  <Button
                    icon={<Icon name="MdDeleteForever" />}
                    variant="danger"
                    csx={{ width: '50px' }}
                    disableHoverEffect
                    onClick={() =>
                      onRowDelete && onRowDelete(info.row.original.id)
                    }
                  />
                ) : null,
              size: 50,
              enableSorting: false,
            }),
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Table
        title={title}
        mode={ETableModes.BASIC}
        data={data}
        columns={COLUMNS}
        cellCsx={{ height: '70px' }}
        alignHeaders={{
          actions: 'center',
        }}
        align={{
          actions: 'center',
        }}
        requiredColumns={['amount', 'roleId']}
        renderEmptyValues
        rowIdPrefix={ROW_ID_PREFIX}
        noDataMessage={noDataText}
        noDataPlaceholder={
          <Box
            csx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70px',
            }}>
            <Typography align="center">{noDataText}</Typography>
          </Box>
        }
        addRowText={
          data.length === Object.keys(roles).length ? undefined : newRowText
        }
        onAddClick={onNewRow}
        listEmptyText={
          <Box
            csx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70px',
            }}>
            <Typography align="center">{hasZeroText}</Typography>
          </Box>
        }
        newRowComponent={
          (!validatePercentages || percentagesSum === 100 || !data.length) &&
          !customErrorText &&
          !rowHasZero ? undefined : (
            <Box
              csx={{
                display: 'flex',
                backgroundColor: theme.colors.lightestRed,
                border: `1px solid ${theme.colors.semanticRed}`,
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 10px',
                gap: 10,
                width: '100%',
                height: '60px',
              }}>
              <Icon name="MdWarning" color="semanticRed" />
              <Typography color="semanticRed">
                {customErrorText
                  ? customErrorText
                  : rowHasZero
                  ? hasZeroText
                  : percentagesSum > 100
                  ? t(
                      'settingsModule.paymentsSettings.tipSettings.percentageGreater',
                    )
                  : t(
                      'settingsModule.paymentsSettings.tipSettings.percentageSmaller',
                    )}
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
};

export default TipRolePoolTable;
