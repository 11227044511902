import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import Dropdown from '@app/components/common/Dropdown';
import Icon from '@app/components/common/Icon';
import NoElementsFound from '@app/components/common/NoElementsFound';
import Table from '@app/components/common/Table';
import DropdownCell from '@app/components/common/Table/DropdownCell';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import TimePicker from '@app/components/common/TimePicker/TimePicker';
import { prepStationsDropdownOptionsFactory } from '@app/helpers/factories/settings';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { actionCreatorsSettingsWeb } from '@app/state';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import {
  areSettingsMasterModeSelector,
  currentPrepStationsSelector,
  makeSelectSettingsData,
  selectOrderTypesAvailable,
} from '@app/state/selectors/settingsSelectors';
import { RootState, store, useAppDispatch } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DayOfWeek,
  IPrinterRuleSettings,
  IPrinterRulesSettings,
  OrderType,
  PRINTER_RULES_ORDER_TYPE_OPTIONS,
  PRINTER_RULE_SETTINGS_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getPrinterRulesId,
  orderTypeDropdownOptionsFactory,
  serviceAreasDropdownOptionsFactory,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { cloneDeep, orderBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ROW_ID_PREFIX } from '../../RegisterMode/SpecialTags/types';
import useRootSelector from '@app/hooks/useRootSelector';

interface IPrinterRulesTableType {
  id: number;
  name: string;
  order: number;
  daysOfWeek: string | null;
  startTime: string | null;
  endTime: string | null;
  orderType: OrderType | null;
  delete: string;
  serviceAreaId: number | null;
  fromPrepStationId: number | null;
  toPrepStationId: number;
}

const SECTION_ID = SettingsSectionId.PRINTER_RULES;
const getMemoizedItemData =
  makeSelectSettingsData<IPrinterRulesSettings>(SECTION_ID);

interface IPrintingRulesSettings extends WithTranslation {
  selectedLicenseId?: string | number;
}

const PrintingRules = ({ t, selectedLicenseId }: IPrintingRulesSettings) => {
  const dispatch = useAppDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const loadPrintingRulesSettingsChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadPrintingRulesSettingsChangeData,
    dispatch,
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const orderTypesAvailable = useSelector(selectOrderTypesAvailable);

  const { data } = useSelector(getMemoizedItemData);

  const prepStations = useSelector(currentPrepStationsSelector);

  const serviceAreas = useSelector(
    (state: RootState) => state.tableLayout.savedData.serviceAreas,
  );

  const isSet = useRootSelector(
    state =>
      state.settings.changeData.settingsSelected === 'printerRules' ||
      state.settings.changeData.settingsSelected ===
        'licenseSpecificPrinterRules',
  );

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);

  const serviceAreasDropdownOptions = useMemo(
    () => serviceAreasDropdownOptionsFactory(t, serviceAreas),
    [t, serviceAreas],
  );

  const orderTypeDropdownOptions = useMemo(
    () => orderTypeDropdownOptionsFactory(t, orderTypesAvailable),
    [t, orderTypesAvailable],
  );

  const updateRow = (
    newValue: number | string | null,
    id: number,
    field: keyof IPrinterRuleSettings,
  ) => {
    const tableObject = store.getState().settings.changeData
      .data as IPrinterRulesSettings;

    setValue<IPrinterRulesSettings>(
      {
        ...tableObject,
        [id]: {
          ...tableObject[id],
          [field]: newValue,
        },
      },
      SECTION_ID,
    );
  };

  const deleteRule = (id: number, errorIds: string[]) => {
    errorIds.forEach(errorId => clearSettingsError(errorId));

    const currentData = store.getState().settings.changeData
      .data as IPrinterRulesSettings;

    const clonedPrintingRules = cloneDeep(currentData);

    delete clonedPrintingRules[id];

    setValue<IPrinterRulesSettings>(
      {
        ...clonedPrintingRules,
      },
      SECTION_ID,
      true,
    );
  };

  const printerRulesTableArray = useMemo(
    () =>
      orderBy(
        Object.keys(data || {}).map(key => ({
          ...data[key],
          id: Number(key),
          delete: '',
        })),
        'order',
      ),
    [data],
  );

  const daysOfWeekApply = (
    selectedDays: Record<DayOfWeek, boolean>,
    id: number,
    field: keyof IPrinterRuleSettings,
  ) => {
    const values: string[] = [];

    const days = [
      DayOfWeek.MONDAY,
      DayOfWeek.TUESDAY,
      DayOfWeek.WEDNESDAY,
      DayOfWeek.THURSDAY,
      DayOfWeek.FRIDAY,
      DayOfWeek.SATURDAY,
      DayOfWeek.SUNDAY,
    ];

    days.forEach(val => {
      if (selectedDays[val]) {
        values.push(val);
      }
    });

    updateRow(values.join(','), id, field);
  };

  const columnHelper = createColumnHelper<IPrinterRulesTableType>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        minSize: 160,
        header: t('settingsModule.printerRulesSettings.printerRulesTable.name'),
        cell: info => {
          return (
            <InputCell
              required
              placeholder={t(
                'settingsModule.printerRulesSettings.printerRulesTable.name',
              )}
              errorId={getPrinterRulesId(info.row.original.id, 'name')}
              value={info.getValue() || ''}
              onChange={e => {
                updateRow(e.currentTarget.value, info.row.original.id, 'name');
              }}
              onFocusError={(errorId, hasError) => {
                hasError && clearSettingsError(errorId);
              }}
            />
          );
        },
      }),
      columnHelper.accessor('serviceAreaId', {
        size: 160,
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.serviceArea',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={serviceAreasDropdownOptions}
            value={info.getValue() === null ? 0 : (info.getValue() as number)}
            onChange={newJustify =>
              updateRow(
                newJustify === 0 ? null : newJustify,
                info.row.original.id,
                'serviceAreaId',
              )
            }
          />
        ),
      }),
      columnHelper.accessor('orderType', {
        size: 160,
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.orderType',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={orderTypeDropdownOptions}
            value={
              info.getValue() === null
                ? 0
                : PRINTER_RULES_ORDER_TYPE_OPTIONS.indexOf(
                    info.getValue() as OrderType,
                  )
            }
            onChange={newOrderType =>
              updateRow(
                PRINTER_RULES_ORDER_TYPE_OPTIONS[newOrderType] === 'all'
                  ? null
                  : PRINTER_RULES_ORDER_TYPE_OPTIONS[newOrderType],
                info.row.original.id,
                'orderType',
              )
            }
          />
        ),
      }),
      columnHelper.accessor('daysOfWeek', {
        size: 160,
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.daysOfWeek',
        ),
        cell: info => (
          <DayOfTheWeekPicker
            selectedDays={info.getValue() || ''}
            onApply={(selectedDays: Record<DayOfWeek, boolean>) =>
              daysOfWeekApply(selectedDays, info.row.original.id, 'daysOfWeek')
            }
          />
        ),
      }),
      columnHelper.accessor('startTime', {
        size: 160,
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.startTime',
        ),
        cell: info => {
          return (
            <TimePicker
              utcValue={info.getValue() as string}
              onChange={newTime =>
                updateRow(newTime, info.row.original.id, 'startTime')
              }
            />
          );
        },
      }),
      columnHelper.accessor('endTime', {
        size: 160,
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.endTime',
        ),
        cell: info => {
          return (
            <TimePicker
              utcValue={info.getValue() as string}
              onChange={newTime =>
                updateRow(newTime, info.row.original.id, 'endTime')
              }
            />
          );
        },
      }),
      columnHelper.accessor('fromPrepStationId', {
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.fromStation',
        ),
        cell: info => (
          <DropdownCell
            errorsId={[
              getPrinterRulesId(info.row.original.id, 'equalPrepStations'),
              getPrinterRulesId(info.row.original.id, 'fromPrepStationId'),
            ]}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={prepStationsDropdownOptionsFactory(prepStations, t, true)}
            value={info.getValue() === null ? 0 : (info.getValue() as number)}
            onChange={newPrepStation =>
              updateRow(
                newPrepStation === 0 ? null : newPrepStation,
                info.row.original.id,
                'fromPrepStationId',
              )
            }
            onPressError={errorId => clearSettingsError(errorId)}
          />
        ),
        size: 160,
      }),
      columnHelper.accessor('toPrepStationId', {
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.toStation',
        ),
        cell: info => (
          <DropdownCell
            errorsId={[
              getPrinterRulesId(info.row.original.id, 'equalPrepStations'),
              getPrinterRulesId(info.row.original.id, 'toPrepStationId'),
            ]}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={prepStationsDropdownOptionsFactory(prepStations)}
            value={info.getValue()}
            onChange={newPrepStation =>
              updateRow(newPrepStation, info.row.original.id, 'toPrepStationId')
            }
            onPressError={errorId => clearSettingsError(errorId)}
          />
        ),
        size: 160,
      }),
      columnHelper.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info => (
          <Box>
            <Button
              csx={{ width: '50px' }}
              variant="danger"
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                deleteRule(info.row.original.id, [
                  getPrinterRulesId(info.row.original.id, 'toPrepStationId'),
                  getPrinterRulesId(info.row.original.id, 'fromPrepStationId'),
                  getPrinterRulesId(info.row.original.id, 'equalPrepStations'),
                  getPrinterRulesId(info.row.original.id, 'name'),
                ])
              }
            />
          </Box>
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepStations, serviceAreasDropdownOptions, orderTypeDropdownOptions, t],
  );

  const handleNewRowScroll = useScrollToNewRow();

  const addRule = () => {
    const dataSnapshot = store.getState().settings.changeData
      .data as IPrinterRulesSettings;
    const originalData = store.getState().settings.changeData
      .originalData as IPrinterRulesSettings;
    const locationId = store.getState().app.locationId;

    let maxId = 0;
    let maxOrder = 0;
    for (const [key] of Object.entries(originalData)) {
      maxId = Math.max(maxId, Number(key));
    }
    for (const [key, val] of Object.entries(dataSnapshot)) {
      maxId = Math.max(maxId, Number(key));
      maxOrder = Math.max(maxOrder, Number(val.order));
    }

    maxOrder++;
    maxId++;

    const newRuleIndex = Object.values(dataSnapshot).length;

    const defaultData: IPrinterRuleSettings = {
      ...PRINTER_RULE_SETTINGS_INITIAL_STATE,
      id: maxId,
      order: maxOrder,
      locationId,
      licenseId: selectedLicenseId ? Number(selectedLicenseId) : null,
    };

    setValue<IPrinterRulesSettings>(
      {
        [maxId]: {
          ...defaultData,
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${newRuleIndex}`);
  };

  useEffect(() => {
    if (!isSet) {
      loadPrintingRulesSettingsChangeData(Number(selectedLicenseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, selectedLicenseId]);

  return (
    <Box>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '0px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title={t('settingsModule.printerRulesSettings.title')}
          headerCsx={{
            padding: '15px',
          }}
          description={
            !isMasterMode || !hasMultipleLocations
              ? t('settingsModule.printerRulesSettings.subTitle')
              : undefined
          }
          options={
            !isMasterMode || !hasMultipleLocations ? (
              <Button
                variant="primary"
                icon={<Icon name="MdAdd" />}
                onClick={addRule}
                csx={{ minWidth: '120px' }}>
                {t('settingsModule.printerRulesSettings.addRuleText')}
              </Button>
            ) : undefined
          }
        />
      </m.div>
      {isSet && (!isMasterMode || !hasMultipleLocations) ? (
        <Box
          csx={{
            padding: '15px',
          }}>
          <Table
            mode={ETableModes.SORT_VERTICALLY}
            data={printerRulesTableArray}
            columns={COLUMN_VALUES}
            cellCsx={{ height: '70px' }}
            alignHeaders={{
              delete: 'center',
            }}
            align={{
              delete: 'center',
            }}
            onSortEnd={(sortedData: IPrinterRulesTableType[]) => {
              sortedData.forEach((item, index) => {
                updateRow(index + 1, item.id, 'order');
              });
            }}
            requiredColumns={['name', 'fromPrepStationId', 'toPrepStationId']}
            renderEmptyValues
            rowIdPrefix={ROW_ID_PREFIX}
            noDataMessage={t(
              'settingsModule.printerRulesSettings.printerRulesTable.emptyDataTitle',
            )}
          />
        </Box>
      ) : (
        <NoElementsFound
          text={t('settingsModule.printerRulesSettings.masterUnavailable')}
          icon={<Icon name="MdLocationPin" color="semanticGrey" />}
        />
      )}
    </Box>
  );
};

export default PrintingRules;
