import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Typography from '@app/components/common/Typography';
import SearchInputExample from './SearchInputExample';
import Card from '@app/components/common/Card';

const InputExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Inputs
      </Typography>
      <Typography variant="body" csx={{ marginBottom: '10px' }}>
        Simple, disabled and password
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert Text" />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert text" disabled />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert Text" type="password" />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Label, label disabled and info
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert label" label="Label" />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert text" label="Disabled" disabled />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input placeholder="Insert Name" label="Info" info="Info Example" />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Caption and Error
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Input
            label="Caption"
            placeholder="Insert text"
            caption="This is a caption"
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input label="Error" placeholder="Insert text" error />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input
            label="Error"
            type="password"
            placeholder="Insert text"
            error
            caption="The password confirmation doesn't match."
          />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Required
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Input label="Required" placeholder="Insert text" required />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input label="Disabled" placeholder="Insert text" disabled required />
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Adornments
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <Input
            label="Icon button"
            placeholder="Insert text"
            icon={<Icon name="MdHelp" />}
            onIconPress={() => alert('This is an alert')}
          />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <Input
            label="Icon"
            placeholder="Insert text"
            icon={<Icon name="MdInsertEmoticon" size="22px" />}
          />
        </Grid.Item>
      </Grid>
      <SearchInputExample />
    </Card>
  );
};

export default InputExample;
