import Box from '@app/components/common/Box';
import { IBox } from '@app/components/common/Box/Box';
import TTLogo from '@app/components/common/TTLogo.tsx';
import { authCardBodyStyles, authCardContainerStyles } from './styles';

interface IAuthCard extends IBox {
  showLogo?: boolean;
  maxHeight?: string;
  width?: string;
}

const AuthCard = ({
  children,
  showLogo = true,
  maxHeight = '600px',
  width = '440px',
  csx,
  ...otherProps
}: IAuthCard) => {
  return (
    <Box
      csx={[authCardContainerStyles(maxHeight, width), csx || {}]}
      {...otherProps}>
      {showLogo && (
        <Box className="cardLogo">
          <TTLogo toggleWithTheme />
        </Box>
      )}
      <Box csx={authCardBodyStyles}>{children}</Box>
    </Box>
  );
};

export default AuthCard;
