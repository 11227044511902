import BlueDot from '@app/components/common/BlueDot';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import Icon from '@app/components/common/Icon';
import MoneyInput from '@app/components/common/MoneyInput';
import NoElementsFound from '@app/components/common/NoElementsFound';
import Typography from '@app/components/common/Typography';
import DraggableContainer from '@app/components/LoggedIn/Menu/common/DragAndDrop/DraggableContainer';
import { openNewTabWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectItems } from '@app/state/menu/menuSelectors';
import { checkForChangesAndNavigateWeb } from '@app/state/menu/menuWebActions';
import { DragEndEvent } from '@dnd-kit/core';
import { useTheme } from '@emotion/react';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  IItemOfComboItemGroup,
  SectionId,
} from '@westondev/tableturn-core';
import { orderBy, some } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IContent extends WithTranslation {
  items: {
    [key: number]: IItemOfComboItemGroup;
  };
  menuTypeVersionId: number;
  itemGroupId: number;
  isEditMode: boolean;
  filteredItemIds: number[];
  showUpCharge: boolean;
}

const SECTION_ID = SectionId.ITEM_GROUPS;

const Content = ({
  items,
  t,
  menuTypeVersionId,
  itemGroupId,
  isEditMode,
  filteredItemIds,
  showUpCharge,
}: IContent) => {
  const menuItems = useSelector(selectItems);

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();
  const theme = useTheme();

  const { updateCombo: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const [activeId, setActiveId] = useState<string | null>(null);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const movedItem = items[Number(active.id)];
        const overItem = items[Number(over?.id)];

        setValue(
          {
            sortPriority: overItem.sortPriority,
          },
          SECTION_ID,
          menuTypeVersionId,
          itemGroupId,
          movedItem.itemId,
        );

        setValue(
          {
            sortPriority: movedItem.sortPriority,
          },
          SECTION_ID,
          menuTypeVersionId,
          itemGroupId,
          overItem.itemId,
        );
      }

      setActiveId(null);
    },
    [items, itemGroupId, menuTypeVersionId, setValue],
  );

  const itemsArray = useMemo(
    () =>
      orderBy(
        Object.values(items || {}).filter(item =>
          isEditMode ? true : filteredItemIds.includes(item.itemId),
        ),
        item =>
          isEditMode ? item.sortPriority : filteredItemIds.indexOf(item.itemId),
      ).map(item => ({
        ...item,
        isHidden:
          !menuItems[item.itemId]?.menuTypeVersions?.[menuTypeVersionId],
        inheritedActive:
          menuItems[item.itemId]?.menuTypeVersions?.[menuTypeVersionId]
            ?.active ?? false,
      })),
    [items, filteredItemIds, isEditMode, menuItems, menuTypeVersionId],
  );

  return (
    <Box csx={{ padding: '15px' }}>
      {Object.values(itemsArray).length > 0 ? (
        <DraggableContainer
          draggableItems={itemsArray.map(item => item.itemId.toString())}
          setActiveId={setActiveId}
          handleDragEnd={handleDragEnd}>
          <CardsContainer
            data={itemsArray}
            csx={{ padding: 0, borderWidth: 0 }}
            noElementsMessage="<Box>noElementsMessage</Box>"
            renderItem={item => (
              <Box
                key={item.itemId.toString() + item.active.toString()}
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                }}>
                <Card.ItemButton
                  {...item}
                  draggable={isEditMode}
                  draggedItemId={activeId}
                  itemId={item.itemId}
                  key={item.itemId}
                  showSwitch
                  isHidden={item.isHidden}
                  switchValue={item.active}
                  showStatus={!item.inheritedActive && !item.isHidden}
                  isActive={item.inheritedActive}
                  nextPrice={item.additionalPrice}
                  totalPrice={item.additionalPrice}
                  onSwitchChange={value => {
                    setValue(
                      {
                        active: value,
                      },
                      SECTION_ID,
                      menuTypeVersionId,
                      itemGroupId,
                      item.itemId,
                    );
                  }}
                  title={
                    <Box
                      csx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                      }}>
                      {some(
                        (item as IItemOfComboItemGroup).override,
                        Boolean,
                      ) && <BlueDot />}
                      <>{menuItems[item.itemId].name}</>
                    </Box>
                  }
                  onClick={openInNewTab => {
                    const pathURL = `/menu/combos/item-groups/${item.id}`;
                    if (openInNewTab) return openNewTabWithOrgData(pathURL);

                    checkForChangesAndNavigateWeb(
                      () =>
                        addBreadcrumbLocationWeb({
                          action: BreadCrumbAction.NAV,
                          text: menuItems[item.itemId].name,
                          onPress: () => navigate(pathURL),
                          pathURL,
                        }),
                      onRefresh,
                    );
                  }}
                />
                {!activeId && showUpCharge && (
                  <Box
                    csx={{
                      position: 'relative',
                    }}>
                    <Box
                      csx={{
                        position: 'absolute',
                        top: '-3px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                        left: 0,
                        right: 0,
                      }}>
                      <Icon
                        name="IoTriangleSharp"
                        size="20px"
                        color={theme.colors.lighterGrey}
                      />
                    </Box>
                    <Box
                      csx={{
                        backgroundColor: theme.colors.white,
                        width: 'inherit',
                        border: `1px solid ${theme.colors.lightGrey}`,
                        borderRadius: '8px',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '15px',
                        flexDirection: 'column',
                        overflow: 'hidden',
                      }}>
                      <Box
                        csx={{
                          width: '100%',
                          backgroundColor: theme.colors.lighterGrey,
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '5px',
                          padding: '5px',
                        }}>
                        <Typography>
                          {t('menuScreen.itemGroupsDetails.basic.upCharge')}
                        </Typography>
                      </Box>
                      <Box
                        csx={{
                          padding: '5px',
                        }}>
                        <MoneyInput
                          value={item.additionalPrice}
                          disabled={!item.active}
                          onValueChange={newValue => {
                            setValue(
                              { additionalPrice: newValue },
                              SECTION_ID,
                              menuTypeVersionId,
                              itemGroupId,
                              item.itemId,
                            );
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          />
        </DraggableContainer>
      ) : (
        <NoElementsFound
          csx={{
            padding: '15px',
          }}
          showBorder={false}
          text={t(
            'menuScreen.comboDetails.itemGroups.noItemsAssignedToThisItemGroup',
          )}
        />
      )}
    </Box>
  );
};

export default Content;
