import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';
import { BUTTON_HEIGHT } from '../Button/styles';

export const accordionStyles = (theme: Theme) =>
  css({
    '.allSectionsSection': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderRadius: '8px',
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[1],
      paddingInline: '20px',
      paddingBlock: '6px',
      marginBottom: '15px',
      '.toggleButtonsContainer': {
        marginRight: '-10px',
        display: 'flex',
        alignItems: 'center',
        '.buttonContent': {
          fontSize: theme.fontSizes.body,
        },
        '> button': {
          height: '40px',
          width: '40px',
          border: 'none',
          '&[data-disabled="false"]': {
            filter: 'none !important',
            svg: {
              fill: theme.colors.persistentSemanticBlue,
            },
            '.buttonContent': {
              color: theme.colors.persistentSemanticBlue,

              fontWeight: theme.fontWeights.medium,
            },
          },
        },
        '> button > .icon ': {
          padding: '0px',
        },
        '> button > .buttonContent': {
          display: 'none ',
        },
        [MQ_MIN_SMALL]: {
          marginRight: '0px',
          '> button': {
            height: BUTTON_HEIGHT,
            width: 'auto',
          },
          '> button > .buttonContent': {
            display: 'block ',
          },
          p: {
            display: 'block',
          },
        },
      },
    },
    '.sectionsSection': {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
  });
