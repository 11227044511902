import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { selectMenu } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import {
  ICombo,
  menuTypeAssignedCombosFactoryCore,
} from '@westondev/tableturn-core';

const menuTypeAssignedCombosFactory = (
  combos: Record<number, ICombo>,
  menuTypeAssignedComboIds: number[],
  menuTypeId: number,
  onClick: (id: number, text: string, openInNewTab?: boolean) => void,
  onIsLink: (id: number) => void,
  enableSimpleMode: boolean,
): IItemCard[] => {
  const { categories, subcategories, menuTypes } = selectMenu(store.getState());
  return <IItemCard[]>menuTypeAssignedCombosFactoryCore(
    combos,
    menuTypeAssignedComboIds,
    menuTypeId,
    () => null,
    categories,
    subcategories,
    menuTypes,
    enableSimpleMode,
  ).map(combo => {
    return {
      id: combo.id,
      onRemoveClick: combo.onDelete,
      isActive: combo.status !== undefined ? combo.status : true,
      title: combo.title,
      isHidden: combo.isHidden,
      showStatus: true,
      onClick: (openInNewTab?: boolean) =>
        onClick(combo.id, combo.title, openInNewTab),
      isLink: onIsLink(combo.id),
    };
  });
};

export default menuTypeAssignedCombosFactory;
