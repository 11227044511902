import { BUCKET_PATH_INDEX } from '@app/constants';
import { setVirtualizedNavigation } from '@app/state/app/actions';
import { store } from '@app/state/store';
import { IBreadCrumbWeb } from '@app/types';
import { BreadCrumbAction } from '@westondev/tableturn-core';

export const resetPathsByBreadcrumbNavigation = (
  removedBreadcrumbs: IBreadCrumbWeb[],
  locationIndex: number,
) => {
  removedBreadcrumbs.map(breadCrumb => {
    const pathsData = localStorage.getItem('path-data');
    if (pathsData) {
      const paths = JSON.parse(pathsData);
      delete paths[breadCrumb.pathURL];
      localStorage.setItem('path-data', JSON.stringify(paths));
    }
  });

  if (removedBreadcrumbs.length === 0) return;

  // Remove the breadcrumbs from the virtualized breadcrumb
  const virtualizedNavigation = store.getState().app.virtualizedNavigation;
  const lastNavMainIndex = virtualizedNavigation.breadcrumb.findLastIndex(
    (item, index) =>
      item.action === BreadCrumbAction.NAV_MAIN &&
      index < virtualizedNavigation.index - 1,
  );

  const newVirtualizedNavigation = virtualizedNavigation.breadcrumb.slice(
    0,
    lastNavMainIndex + locationIndex + 1,
  );
  store.dispatch(
    setVirtualizedNavigation({
      breadcrumb: newVirtualizedNavigation,
      index: newVirtualizedNavigation.length,
    }),
  );
};

export const resetPathsByMainPath = (
  bucketBasePath: string,
  newPath: string,
) => {
  const pathsData = localStorage.getItem('path-data');
  if (pathsData) {
    const paths = JSON.parse(pathsData);

    Object.keys(paths).map(pathKey => {
      if (pathKey.includes(bucketBasePath) && pathKey !== newPath) {
        delete paths[pathKey];
      }
    });

    localStorage.setItem('path-data', JSON.stringify(paths));
  }
};

export const resetPathsByBucketNavigation = (
  currentModule: string,
  bucketPath: string,
  subBucketPath?: string,
) => {
  const pathsData = localStorage.getItem('path-data');
  if (pathsData) {
    const paths = JSON.parse(pathsData);

    if (currentModule === 'menu') {
      Object.keys(paths).map(pathKey => {
        if (pathKey.split('/')[BUCKET_PATH_INDEX] !== bucketPath) {
          delete paths[pathKey];
        }
      });
    } else if (currentModule === 'settings' && bucketPath === 'accounts') {
      if (subBucketPath) {
        Object.keys(paths).map(pathKey => {
          if (
            pathKey.split('/')[BUCKET_PATH_INDEX] === bucketPath &&
            pathKey.split('/')[5] !== subBucketPath
          ) {
            delete paths[pathKey];
          }
        });
      }
    }

    localStorage.setItem('path-data', JSON.stringify(paths));
  }
};
