import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal/Modal';
import { selectRoles } from '@app/state/selectors/settingsSelectors';
import { rolesDropdownOptions } from '@app/state/settings/paymentsFactory';
import {
  IPaymentsSettings,
  ITipRolePool,
  ITipRolesPool,
  ITipShareRule,
  ITipSharingRules,
  SettingsSectionId,
  actionCreatorsSettings,
  generateTabletGeneratedId,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import TipRolePoolTable from '../TipRolePoolTable';

interface ITipSharingRuleModal extends WithTranslation {
  data: ITipShareRule | null;
  sharingRulesData: ITipSharingRules;
  active?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  updateValue: (
    field: keyof IPaymentsSettings,
    newValue: number | string | Partial<ITipRolePool> | Partial<ITipShareRule>,
    id?: number,
  ) => void;
}

const SECTION_ID = SettingsSectionId.PAYMENTS;

const TipSharingRuleModal = ({
  t,
  data,
  active = false,
  onCancel = () => null,
  onSuccess = () => null,
  updateValue,
  sharingRulesData,
}: ITipSharingRuleModal) => {
  const [, setSelectedAmount] = useState(0);
  const [sharingRuleData, setSharingRuleData] = useState<ITipShareRule>({
    id: 0,
    contributorId: 0,
    contribution: 0,
    recipients: {},
  });

  const [, setSelectedCell] = useState<{
    field: 'contributor' | 'recipients' | null;
    id: number | string | null;
  }>({
    field: null,
    id: null,
  });

  const roles = useSelector(selectRoles);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const rolePoolRecipientsData = useMemo(() => {
    return Object.values(sharingRuleData.recipients || {}).map(role => ({
      id: role.id,
      roleId: role.roleId,
      amount: role.amount,
      actions: '',
    }));
  }, [sharingRuleData.recipients]);

  const isContinueDisabled = useMemo(() => {
    let sum = 0;
    for (const recipient of rolePoolRecipientsData) {
      sum += recipient.amount;
      if (recipient.amount === 0) {
        return true;
      }
    }

    if (rolePoolRecipientsData.length === 0) return true;
    if (sum !== 100) return true;
    return false;
  }, [rolePoolRecipientsData]);

  const sharingRulesDropdownOptions = useMemo(() => {
    const currentRoles = Object.values(sharingRulesData).map(
      role => role.contributorId,
    );
    return rolesDropdownOptions(roles, currentRoles);
  }, [sharingRulesData, roles]);

  const onClose = () => {
    onCancel();
  };

  const addRecipient = () => {
    const currentRoles = new Set(
      Object.values(sharingRuleData.recipients as ITipRolesPool).map(
        role => role.roleId,
      ),
    );

    let newRoleId = 0;
    for (const roleId of Object.keys(roles)) {
      if (!currentRoles.has(Number(roleId))) {
        newRoleId = Number(roleId);
        break;
      }
    }
    const newId = generateTabletGeneratedId();

    setSharingRuleData(prevData => ({
      ...prevData,
      recipients: {
        ...prevData.recipients,
        [newId]: {
          id: newId,
          roleId: newRoleId,
          amount: 0,
        },
      },
    }));

    setSelectedCell({ field: 'recipients', id: newId });
    setSelectedAmount(0);
  };

  const deleteRecipient = (id: number | string) => {
    setSharingRuleData(prevData => {
      const newData = { ...prevData.recipients };

      delete newData[id];

      return {
        ...prevData,
        recipients: newData,
      };
    });
  };

  const onContinue = () => {
    if (data && !(data.id in sharingRulesData)) {
      setValue(
        {
          sharingRules: {
            ...sharingRulesData,
            [data.id]: {
              ...sharingRuleData,
            },
          },
        },
        SECTION_ID,
      );
    }
    data && updateValue('sharingRules', sharingRuleData, data.id);
    onSuccess();
  };

  useEffect(() => {
    if (!active) {
      setSharingRuleData({
        id: 0,
        contributorId: 0,
        contribution: 0,
        recipients: {},
      });
      setSelectedCell({ field: null, id: null });
      setSelectedAmount(0);
      return;
    }
    if (active && data && !sharingRuleData.id) {
      setSelectedCell({ field: 'contributor', id: 0 });
      setSelectedAmount(data?.contribution);
    }
    data && setSharingRuleData(prevData => (prevData.id ? prevData : data));
  }, [data, active, sharingRuleData.id]);

  if (!data) return null;
  return (
    <Modal
      fullHeight
      displayAfterNavBar={false}
      isActive={active}
      title={t('settingsModule.paymentsSettings.tipSettings.tipSharing')}
      onModalClose={onClose}
      size={1000}
      footer={
        <>
          <Button csx={{ width: '80px' }} variant="secondary" onClick={onClose}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            csx={{ width: '80px' }}
            disabled={isContinueDisabled || sharingRuleData.contribution === 0}
            variant="primary"
            onClick={onContinue}>
            {t('commonButtons.continue')}
          </Button>
        </>
      }>
      <TipRolePoolTable
        title={t(
          'settingsModule.paymentsSettings.tipSettings.from',
        ).toUpperCase()}
        firstColumnTitle={t(
          'settingsModule.paymentsSettings.tipSettings.contributor',
        )}
        secondColumnTitle={t(
          'settingsModule.paymentsSettings.tipSettings.percentageContributed',
        )}
        hasZeroText={t(
          'settingsModule.paymentsSettings.tipSettings.percentageContributedRequired',
        )}
        data={[
          {
            id: sharingRuleData.id,
            roleId: sharingRuleData.contributorId,
            amount: sharingRuleData.contribution,
            actions: '',
          },
        ]}
        updateValue={(newValue: Partial<ITipRolePool>) => {
          setSharingRuleData(prevData => ({
            ...prevData,
            contributorId: newValue?.roleId ?? prevData.contributorId,
            contribution: newValue?.amount ?? prevData.contribution,
          }));
        }}
        noDataText={t(
          'settingsModule.paymentsSettings.tipSettings.emptyContributorsPool',
        )}
        dropdownOptions={sharingRulesDropdownOptions}
      />
      <Box
        csx={{
          padding: '10px 0px',
        }}
      />
      <TipRolePoolTable
        validatePercentages
        title={t(
          'settingsModule.paymentsSettings.tipSettings.to',
        ).toUpperCase()}
        firstColumnTitle={t(
          'settingsModule.paymentsSettings.tipSettings.recipient',
        )}
        secondColumnTitle={t(
          'settingsModule.paymentsSettings.tipSettings.percentageReceived',
        )}
        hasZeroText={t(
          'settingsModule.paymentsSettings.tipSettings.percentageReceivedRequired',
        )}
        data={rolePoolRecipientsData}
        updateValue={(newValue: Partial<ITipRolePool>, id: number | string) => {
          setSharingRuleData(prevData => ({
            ...prevData,
            recipients: {
              ...prevData.recipients,
              [id]: {
                ...prevData.recipients[id],
                ...newValue,
              },
            },
          }));
        }}
        newRowText={t(
          'settingsModule.paymentsSettings.tipSettings.addRecipients',
        )}
        onNewRow={addRecipient}
        onRowDelete={(id: number | string) => deleteRecipient(id)}
        noDataText={t(
          'settingsModule.paymentsSettings.tipSettings.emptyRecipientsPool',
        )}
        customErrorText={
          rolePoolRecipientsData.length === 0
            ? t('settingsModule.paymentsSettings.tipSettings.noRecipientsError')
            : undefined
        }
      />
    </Modal>
  );
};

export default TipSharingRuleModal;
