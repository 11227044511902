import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import DropdownCell from '@app/components/common/Table/DropdownCell';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IDiscountReason,
  IRegisterSettings,
  REGISTER_DISCOUNT_REASON_INITIAL_STATE,
  SettingsSectionId,
  VoidOrDiscountReasonEnum,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getDiscountReasonId,
  getVoidOrDiscountReasonEnumValue,
  getVoidOrDiscountReasonObject,
  getVoidOrDiscountReasonString,
  propagateString,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const {
  selectRegisterDiscountReasons,
  areSettingsMasterModeSelector,
  makeSelectSettingsData,
} = settingsSelectors;

interface IDiscountReasonsTable {
  id: number;
  voidOrDiscountReason: VoidOrDiscountReasonEnum;
  status: boolean;
  name: string;
  posName: string;
  canDelete: boolean;
  sortPriority: number;
}

const SECTION_ID = SettingsSectionId.TAGS;
const ROW_ID_PREFIX = 'discount-reasons-row-';
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const VOID_OR_DISCOUNT_OPTIONS = [
  VoidOrDiscountReasonEnum.VOID_ONLY,
  VoidOrDiscountReasonEnum.DISCOUNT_ONLY,
  VoidOrDiscountReasonEnum.BOTH,
];

const findVoidOrDiscountReason = (id: VoidOrDiscountReasonEnum) => {
  const index = VOID_OR_DISCOUNT_OPTIONS.findIndex(option => option === id);
  return index + 1;
};

const columnHelper = createColumnHelper<IDiscountReasonsTable>();
// TODO: Add translations
const DiscountReasons = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const actualDiscountReasons = useSelector(selectRegisterDiscountReasons);
  const { data: bigData } = useSelector(getMemoizedItemData);

  // Local state
  const data = bigData?.discountReasons;

  const updateDiscountReason = (
    newValue: any,
    id: number,
    field: keyof IDiscountReason,
  ) => {
    const discountReasons = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.discountReasons;
    setValue<IRegisterSettings>(
      {
        discountReasons: {
          ...discountReasons,
          [id]: {
            ...discountReasons[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateDiscountReasonObject = (
    newValue: Partial<IDiscountReason>,
    id: number,
  ) => {
    const discountReasons = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.discountReasons;
    setValue<IRegisterSettings>(
      {
        discountReasons: {
          ...discountReasons,
          [id]: {
            ...discountReasons[id],
            ...newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateName = (newName: string, id: number) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentDiscountReason = registerData.discountReasons[id];
    const prevName = currentDiscountReason.name;
    const propagateObj = {
      name: prevName,
      posName: currentDiscountReason.posName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        discountReasons: {
          ...registerData.discountReasons,
          [id]: {
            ...registerData.discountReasons[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleNewRowScroll = useScrollToNewRow();

  const onNewDiscountReason = () => {
    const nextId =
      (max(Object.keys(data || {}).map(key => Number(key)) || []) || 0) + 1;

    const nextSortPriority =
      (max(
        Object.values(data || {}).map(modTag => modTag.sortPriority) || [],
      ) || 0) + 1;

    const nextIndex = Object.keys(data || {}).length;

    setValue<IRegisterSettings>(
      {
        discountReasons: {
          ...data,
          [nextId]: {
            ...REGISTER_DISCOUNT_REASON_INITIAL_STATE,
            id: nextId,
            discountReasonId: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const discountReasonsArray = useMemo(
    () =>
      orderBy(
        Object.keys(data || {}).map(key => ({
          id: Number(key),
          status: data?.[Number(key)]?.active,
          voidOrDiscountReason: getVoidOrDiscountReasonEnumValue({
            isVoid: data?.[Number(key)]?.isVoid,
            isDiscount: data?.[Number(key)]?.isDiscount,
          }),
          name: data?.[Number(key)]?.name,
          posName: data?.[Number(key)]?.posName,
          canDelete: actualDiscountReasons[Number(key)] ? false : true,
          sortPriority: data?.[Number(key)]?.sortPriority,
        })),
        'sortPriority',
      ),
    [data, actualDiscountReasons],
  );

  const handleDelete = (id: number) => {
    const discountReasons = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.discountReasons;

    let sortPriority = 1;
    const discountReasonsCloned = cloneDeep(discountReasons);
    delete discountReasonsCloned[id];
    const discountReasonsClonedArray = Object.values(discountReasonsCloned);

    orderBy(discountReasonsClonedArray, 'sortPriority').forEach(value => {
      discountReasonsCloned[value.discountReasonId].sortPriority = sortPriority;
      sortPriority++;
    });

    setValue<IRegisterSettings>(
      {
        discountReasons: discountReasonsCloned,
      },
      SECTION_ID,
    );
  };

  const handleSort = (newData: IDiscountReasonsTable[]) => {
    const clonedDiscountReasons = cloneDeep(data);
    newData.forEach((item, index) => {
      clonedDiscountReasons[item.id].sortPriority = index + 1;
    });
    setValue<IRegisterSettings>(
      {
        discountReasons: clonedDiscountReasons,
      },
      SECTION_ID,
    );
  };

  const voidOrDiscountOptions = useMemo(
    () =>
      VOID_OR_DISCOUNT_OPTIONS.map((option, index) => ({
        label: getVoidOrDiscountReasonString(
          getVoidOrDiscountReasonObject(option),
        ),
        value: index + 1,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: 'Status',
        cell: info => (
          <Switch
            key={`switch-discountReason-${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isActive => {
              updateDiscountReason(isActive, info.row.original.id, 'active');
            }}
          />
        ),
        size: 70,
      }),
      columnHelper.accessor('voidOrDiscountReason', {
        header: 'Order or Seat Tag',
        cell: info => (
          <DropdownCell
            key={`orderOrSeatTag-dropdown-${info.row.original.id}`}
            data={[voidOrDiscountOptions]}
            value={findVoidOrDiscountReason(info.getValue())}
            onChange={newIndex => {
              const newVoidOrDiscountReason =
                VOID_OR_DISCOUNT_OPTIONS[newIndex];
              updateDiscountReasonObject(
                getVoidOrDiscountReasonObject(newVoidOrDiscountReason),
                info.row.original.id,
              );
            }}
            errorsId={[
              `${getDiscountReasonId(
                `${info.row.original.id}`,
                'voidOrDiscount',
              )}`,
            ]}
            onPressError={errorId => clearSettingsError(errorId)}
          />
        ),
        minSize: 150,
      }),

      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => (
          <InputCell
            disabled={!info.row.original.canDelete && !isMasterMode}
            required
            placeholder="Name"
            value={info.getValue()}
            onChange={e =>
              updateName(e.currentTarget.value, info.row.original.id)
            }
            errorId={getDiscountReasonId(
              `${info.row.original.id}`,
              'discountReasons',
            )}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
      }),

      columnHelper.accessor('posName', {
        header: 'POS Name',
        cell: info => (
          <InputCell
            required
            placeholder="POS Name"
            value={info.getValue()}
            onChange={e => {
              updateDiscountReason(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              );
            }}
            errorId={getDiscountReasonId(`${info.row.original.id}`, 'posName')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
      }),

      columnHelper.accessor('canDelete', {
        header: 'Delete',
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}
          />
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMasterMode],
  );

  return (
    <Box>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title="Discount Reasons"
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewDiscountReason}>
              Add Discount Reason
            </Button>
          }
        />
      </m.div>

      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          mode={ETableModes.SORT_VERTICALLY}
          data={discountReasonsArray}
          columns={COLUMNS}
          onSortEnd={handleSort}
          cellCsx={{ height: '70px' }}
          alignHeaders={{
            delete: 'center',
          }}
          align={{
            delete: 'center',
          }}
          requiredColumns={['name', 'posName']}
          renderEmptyValues
          rowIdPrefix={ROW_ID_PREFIX}
        />
      </Box>
    </Box>
  );
};

export default DiscountReasons;
