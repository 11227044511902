import { Theme, css } from '@emotion/react';

export const SAVE_BAR_HEIGHT = 70;
export const saveAndCancelBarStyles = (theme: Theme) =>
  css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '15px',
    height: `${SAVE_BAR_HEIGHT}px`,
    paddingInline: '15px',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[1],
  });
