import CloneModal from '@app/components/common/CloneModal';
import { ICloneModal } from '@app/components/common/CloneModal/types';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { selectCurrentSettingsData } from '@app/state/selectors/settingsSelectors';
import { editSettingsElementWeb } from '@app/state/settings/settingsWebActions';
import { AppThunk, store, useAppDispatch } from '@app/state/store';
import {
  BASE_STORE_INITIAL_STATE,
  CoreRootState,
  CreateSettingsBucketRequestType,
  FormMode,
  IRoleSettingsStore,
  ISettingsChangeData,
  ISettingsStore,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { cloneDeep } from 'lodash';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const { settingsValidationThunks } = actionCreatorsSettings;

type ISettingsCloneModal = Omit<ICloneModal, 'title' | 'label' | 'onSuccess'> &
  WithTranslation & {
    active?: boolean;
    onClose?: () => void;
    onClone?: (id: number) => void;
    settings: keyof ISettingsStore;
    name: string;
  };

const SettingsCloneModal = ({
  t,
  active = false,
  onCancel = () => null,
  onClone = () => null,
  onClose,
  settings,
  name,
}: ISettingsCloneModal) => {
  const data = useSelector(selectCurrentSettingsData);

  const dispatch = useAppDispatch();
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshSettingsWeb();

  const getData = (
    _data: ISettingsChangeData<keyof ISettingsStore>,
    newName: string,
  ) => {
    let newData = cloneDeep({ ..._data });
    switch (settings) {
      case 'roles':
        newData = {
          ...newData,
          ...BASE_STORE_INITIAL_STATE,
          name: newName,
          posName: newName,
          locationId: 0,
          roleId: 0,
          organizationId: 0,
          locationRoleId: 0,
        } as IRoleSettingsStore;
        break;
    }

    return newData;
  };

  const handleOnSuccess = async (newName: string) => {
    if (data === null) {
      return;
    }
    onClose ? onClose() : onCancel();
    const request: CreateSettingsBucketRequestType = {
      settingsSelected: settings,
      id: 0,
      data: getData(data, newName),
    };
    const settingsValidationFunction = settingsValidationThunks(
      settings,
      data,
      false,
    );
    const isValid = await dispatch(
      settingsValidationFunction as unknown as AppThunk<Promise<boolean>>,
    );

    if (!isValid) {
      return;
    }
    const { success, id: newId } = await dispatch(
      editSettingsElementWeb(
        () => onRefresh(store.getState() as unknown as CoreRootState),
        navigate,
        false,
        request,
        FormMode.CREATE,
      ),
    );

    if (success) {
      onClone(newId);
    }
  };
  return (
    <CloneModal
      active={active}
      title={t(`app.modals.clone.title.settings.${settings}` as ParseKeys, {
        name,
      })}
      label={t(
        `app.modals.clone.description.settings.${settings}` as ParseKeys,
        { name },
      )}
      onSuccess={handleOnSuccess}
      onCancel={onCancel}
    />
  );
};

export default SettingsCloneModal;
