import MassUpdateInformationSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/MassUpdateInformationSection';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { formatNumber } from '@app/helpers/format';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectChangeDataId,
  selectChangeMassUpdateDataSelectionCriteria,
  selectCurrentItem,
  selectCurrentMenuItemById,
  selectFinalDiffObject,
  selectNumberOfMeuTypeVersions,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  IItem,
  IItemDiffObject,
  IMassUpdate,
  IMenuTypeToSelect,
  ITEM_INITIAL_STATE,
  TChange,
  TChangeItems,
  TIngredientDiff,
  TInheritedTaxesDiff,
  TMenuTypeVersionDiff,
  TModifierGroupDiff,
  actionCreatorsMenu,
  formatMoney,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import MenuTypeVersionsButtons from '../../common/MenuTypeVersionsButtons';
import TextAreaChange from '../../common/TextAreaChange';
import SelectMenuTypeToApplyChangesModal from '../SpecialsDiffModal/SelectMenuTypeToApplyChangesModal';
import { specialsDiffModalStyles } from '../SpecialsDiffModal/styles';
import ItemPricesDiff from './ItemPricesDiff';
import ModifierGroupsDiff from './ModifierGroupsDiff';

const ItemsDiffModal = () => {
  // Redux
  const dispatch = useDispatch();
  const { setDiffObject } = bindActionCreators(actionCreatorsMenu, dispatch);

  const applyChangesToMenuTypesWeb = bindActionCreators(
    actionCreatorsMenuWeb.applyChangesToMenuTypesWeb,
    dispatch,
  );

  const itemId = useSelector(selectChangeDataId, shallowEqual);

  const finalDiffObject = useSelector(selectFinalDiffObject);
  const originalData = useRootSelector(state =>
    itemId
      ? selectCurrentMenuItemById(state as CoreRootState, itemId)
      : ITEM_INITIAL_STATE,
  ) as IItem;
  const changeData = useSelector(selectCurrentItem) as IItem | IMassUpdate;
  const massUpdateSelectionCriteria = useSelector(
    selectChangeMassUpdateDataSelectionCriteria,
  );
  const numberOfMeuTypeVersions = useRootSelector(state =>
    !!massUpdateSelectionCriteria === false
      ? selectNumberOfMeuTypeVersions(state)
      : 0,
  );

  // local state
  const [selectedMenuTypeVersion, setSelectedMenuTypeVersion] = useState(0);
  const [selectedMenuTypeVersionId, setSelectedMenuTypeVersionId] = useState(0);
  const [applyChangesModalActive, setApplyChangesModalActive] = useState(false);
  const [appliedChanges, setAppliedChanges] = useState<{
    currentMenuType: number;
    menuTypesToApplyChanges: IMenuTypeToSelect[];
  } | null>(null);
  const { t } = useTranslation();

  const { borderContainerColor, selectedColor } = useItemChangeColor();

  const formattedDifferences = finalDiffObject as IItemDiffObject;

  useEffect(() => {
    if (!selectedMenuTypeVersionId) {
      if (formattedDifferences.menuTypeVersions[selectedMenuTypeVersion])
        setSelectedMenuTypeVersionId(
          formattedDifferences.menuTypeVersions[selectedMenuTypeVersion].id,
        );
    } else {
      const newMenuTypeVersionIndex =
        formattedDifferences.menuTypeVersions.findIndex(
          menuTypeVersion => menuTypeVersion.id === selectedMenuTypeVersionId,
        );

      if (
        newMenuTypeVersionIndex !== -1 &&
        newMenuTypeVersionIndex !== selectedMenuTypeVersion
      ) {
        setSelectedMenuTypeVersion(newMenuTypeVersionIndex);
      }
    }
  }, [
    formattedDifferences.menuTypeVersions,
    selectedMenuTypeVersionId,
    selectedMenuTypeVersion,
  ]);

  const categoriesSubcategoriesDiff = useMemo(
    () =>
      formattedDifferences.menuTypeVersions[
        selectedMenuTypeVersion
      ]?.sections.categoriesSubcategories.map(catSubCatChange => ({
        action: catSubCatChange.action,
        id: catSubCatChange.id,
        value: catSubCatChange.value.categoryName,
        subcategoryValue: catSubCatChange.value.subcategoryName,
      })),
    [formattedDifferences, selectedMenuTypeVersion],
  );

  const menuTypeVersionId =
    formattedDifferences?.menuTypeVersions?.[selectedMenuTypeVersion]?.id;

  const modifierGroupsChanges = formattedDifferences.menuTypeVersions[
    selectedMenuTypeVersion
  ]
    ? formattedDifferences.menuTypeVersions[
        selectedMenuTypeVersion
      ].sections.modifierGroups.filter(modifierGroup =>
        modifierGroup.action === 'remove'
          ? true
          : massUpdateSelectionCriteria
          ? !(changeData as IMassUpdate).modifierGroups[modifierGroup.id]
              ?.isAllMod
          : !(changeData as IItem).menuTypeVersions[menuTypeVersionId]
              .modifierGroups[modifierGroup.id]?.isAllMod &&
            !modifierGroup.changes.some(
              change => change.field === 'isAllMod' && change.value === true,
            ),
      )
    : [];

  const premadeModifierGroupsChanges = formattedDifferences.menuTypeVersions[
    selectedMenuTypeVersion
  ]
    ? formattedDifferences.menuTypeVersions[
        selectedMenuTypeVersion
      ].sections.modifierGroups.filter(modifierGroup =>
        modifierGroup.action === 'remove'
          ? false
          : massUpdateSelectionCriteria
          ? (changeData as IMassUpdate).modifierGroups[modifierGroup.id]
              ?.isAllMod === true
          : (changeData as IItem).menuTypeVersions?.[menuTypeVersionId]
              .modifierGroups[modifierGroup.id]?.isAllMod ||
            modifierGroup.changes.some(
              change => change.field === 'isAllMod' && change.value === true,
            ),
      )
    : [];

  useEffect(() => {
    if (formattedDifferences && appliedChanges) {
      applyChangesToMenuTypesWeb(appliedChanges, ({ newMenuTypeVersions }) => {
        const newFinalDiffObject = {
          ...formattedDifferences,
          menuTypeVersions: newMenuTypeVersions,
        };
        setDiffObject(newFinalDiffObject);
        setAppliedChanges(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedChanges]);

  const handleApplyChanges = (menuTypes: IMenuTypeToSelect[]) => {
    setAppliedChanges({
      currentMenuType: selectedMenuTypeVersion,
      menuTypesToApplyChanges: menuTypes,
    });
  };

  const renderMenuTypeVersionsButtons = (
    menuTypeVersions: TMenuTypeVersionDiff[],
  ) => {
    return (
      <MenuTypeVersionsButtons
        menuTypeVersions={menuTypeVersions}
        selectedMenuTypeVersion={selectedMenuTypeVersion}
        onPress={menuTypeVersionIndex => {
          setSelectedMenuTypeVersion(menuTypeVersionIndex);
          setSelectedMenuTypeVersionId(
            menuTypeVersions[menuTypeVersionIndex].id,
          );
        }}
      />
    );
  };

  const formatValue = (value: any, field: string, textValue?: string) => {
    if (
      typeof value === 'boolean' &&
      !(!!massUpdateSelectionCriteria && field === 'ageRestricted')
    ) {
      // if value is boolean return yes, no
      if (field === 'active') {
        return value ? t('commonTexts.active') : t('commonTexts.inactive');
      } else return value ? t('commonTexts.yes') : t('commonTexts.no');
    } else if (
      [
        'ageRestricted',
        'defaultModifierGroupPricingVersionId',
        'courseId',
        'actionWhenCurrentStockZero',
        'suggestiveOrderingItemId',
        'allModsBehavior',
        'unit',
      ].includes(field)
    ) {
      return textValue;
    } else if (field === 'suggestiveOrderingItemPrice') {
      if (value === null) {
        return textValue;
      }
      return formatMoney(null, value);
    } else if (typeof value === 'number') {
      // if value is number, format the number depending on the field
      if (
        [
          'itemPrice',
          'onlinePrice',
          'deliveryPrice',
          'toGoPrice',
          'includedMasterPrice',
          'additionalMasterPrice',
          'includedLightPrice',
          'includedRegularPrice',
          'includedExtraPrice',
          'additionalLightPrice',
          'additionalRegularPrice',
          'additionalExtraPrice',
          'defaultSelectionPrice',
        ].includes(field)
      ) {
        if (textValue === 'percent') return value + '%';
        return formatMoney(null, value);
      } else if (field === 'prepTime') {
        return `${formatNumber(Number(value), {
          delimiter: ',',
        })} min`;
      } else return value;
    } else if (typeof value === 'string') {
      if (field === 'customizerShape') {
        return textValue;
      } else return value;
    } else return value;
  };

  const renderChange = (change: TChangeItems) => {
    return (
      <RenderChange
        formatValue={formatValue}
        change={change}
        renderAsString={
          !!change.textValue ||
          change.field === 'imageUrl' ||
          change.field === 'secondaryImageUrl'
        }
        isImage={
          change.field === 'imageUrl' || change.field === 'secondaryImageUrl'
        }
        colorFields={['backgroundColor', 'textColor', 'kdsColor']}
        defaultColor={{}}
      />
    );
  };

  const renderPrices = () => {
    const prices =
      formattedDifferences.menuTypeVersions[selectedMenuTypeVersion].sections
        .pricing;
    return (
      <ItemPricesDiff
        prices={prices}
        formatValue={formatValue}
        isMassUpdate={!!massUpdateSelectionCriteria}
      />
    );
  };

  const renderIngredientsTable = (ingredients: TIngredientDiff[]) => {
    const columnHelper = createColumnHelper<TIngredientDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'sortPriority',
        header: t(
          'app.modals.registerModeDiffModal.general.modifierTags.sortPriority',
        ),
        meta: {
          getCellContext: (context: CellContext<TIngredientDiff, unknown>) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'sortPriority');

          return (
            <RenderChange
              change={{
                field: 'sortPriority',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].sortPriority
                    : (changeData as IItem).menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].sortPriority,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'active',
        header: t('commonTexts.status'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'active');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].active
                    : (changeData as IItem).menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].active,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'isSelected',
        header: t('menuScreen.itemDetails.ingredients.table.isSelected'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'isSelected');
          return (
            <RenderChange
              change={{
                field: 'active',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].isSelected
                    : (changeData as IItem).menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].isSelected,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('menuScreen.itemDetails.basic.name'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].name
                    : (changeData as IItem).menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'posName',
        header: t('menuScreen.itemDetails.basic.posName'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'posName');
          return (
            <RenderChange
              change={{
                field: 'posName',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].posName
                    : (changeData as IItem).menuTypeVersions[menuTypeVersionId]
                        .ingredients[rowData.tabletGeneratedId].posName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={ingredients}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            sortPriority: 'center',
            active: 'center',
            name: 'center',
            posName: 'center',
            isSelected: 'center',
          }}
          title={t('menuScreen.itemDetails.ingredients.title')}
        />
      </Box>
    );
  };

  const renderModifierGroups = (modifierGroups: TModifierGroupDiff[]) => {
    return (
      <ModifierGroupsDiff
        modifierGroups={modifierGroups}
        formatValue={formatValue}
      />
    );
  };

  return (
    <>
      <SelectMenuTypeToApplyChangesModal
        isActive={applyChangesModalActive}
        setModalActive={() => setApplyChangesModalActive(false)}
        onApplyChanges={handleApplyChanges}
        menuTypeId={
          formattedDifferences &&
          formattedDifferences.menuTypeVersions.length > 0
            ? formattedDifferences.menuTypeVersions[selectedMenuTypeVersion].id
            : 0
        }
      />
      {formattedDifferences && !massUpdateSelectionCriteria && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges
                  .filter(
                    change =>
                      !['locationIds', 'linkedModifierItems'].includes(
                        change.field,
                      ),
                  )
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`master_change_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
              </Grid>
              <Box>
                {formattedDifferences.masterChanges
                  .filter(change => change.field === 'locationIds')
                  .map(renderChange)}
              </Box>
              <Box>
                {formattedDifferences.masterChanges
                  .filter(change => change.field === 'linkedModifierItems')
                  .map(renderChange)}
              </Box>
            </>
          )}
        </>
      )}
      {formattedDifferences &&
        formattedDifferences.menuTypeVersions.length > 0 && (
          <>
            {massUpdateSelectionCriteria ? (
              <>
                <HeaderDiffModal
                  name={t(
                    'app.modals.diffModal.sectionTitles.massUpdateChanges',
                  )}
                  color="black"
                />
                <MassUpdateInformationSection
                  csx={{
                    backgroundColor: selectedColor,
                    border: `2px solid ${borderContainerColor}`,
                  }}
                />
              </>
            ) : (
              <HeaderDiffModal
                name={t(
                  'app.modals.diffModal.sectionTitles.changesByMenuTypes',
                )}
                color="black"
              />
            )}
            {!massUpdateSelectionCriteria && (
              <Typography>
                {t('app.modals.itemsDiff.menuType.description')}
              </Typography>
            )}
            {renderMenuTypeVersionsButtons(
              formattedDifferences.menuTypeVersions,
            )}
            <Box
              csx={[
                specialsDiffModalStyles,
                {
                  backgroundColor: 'white',
                  border: `1px solid ${borderContainerColor}`,
                },
              ]}>
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .action === 'remove' ? (
                <Typography>
                  {t('app.modals.itemsDiff.menuType.menuTypeRemoved')}
                </Typography>
              ) : selectedMenuTypeVersion !== 0 ||
                !!massUpdateSelectionCriteria === false ? (
                <Box>
                  <Button
                    variant="primary"
                    onClick={() => setApplyChangesModalActive(true)}
                    disabled={numberOfMeuTypeVersions === 1}>
                    {t(
                      'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.title',
                    )}
                  </Button>
                </Box>
              ) : undefined}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.basic.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.basic')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.basic.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`basic_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}

              {categoriesSubcategoriesDiff &&
                categoriesSubcategoriesDiff.length > 0 && (
                  <>
                    <HeaderDiffModal
                      name={t(
                        'app.modals.diffModal.sectionTitles.categoriesSubcategories',
                      )}
                    />

                    <Box>
                      {renderChange({
                        field: 'categoriesSubcategories',
                        label: t(
                          'menuScreen.itemDetails.categoriesSubcategories.title',
                        ),
                        value:
                          categoriesSubcategoriesDiff as unknown as TChange['value'],
                      })}
                    </Box>
                  </>
                )}

              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.register.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.itemsDiff.sectionTitles.register')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.register
                      .filter(change => !change.field.includes('Color'))
                      .map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`register_change_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                  </Grid>
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.register
                      .filter(change => change.field.includes('Color'))
                      .map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`register_change_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                  </Grid>
                </>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.pricing.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.itemsDiff.sectionTitles.pricing')}
                  />
                  {renderPrices()}
                </>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.guestCheckDisplay.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.diffModal.sectionTitles.guestCheckDisplay',
                    )}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.guestCheckDisplay.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`guest_check_display_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.inventory.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.itemsDiff.sectionTitles.inventory')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.inventory.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`inventory_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}

              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.moreInfo.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.diffModal.sectionTitles.moreInformation',
                    )}
                  />
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.moreInfo.map(change => (
                    <Fragment key={`change-${change.field}`}>
                      {change.value ? (
                        <TextAreaChange change={change} />
                      ) : (
                        renderChange(change)
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {(formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.ingredients.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.ingredientsExtra.length > 0) && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.ingredients')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.ingredientsExtra.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`ingredients_extra_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.ingredients.length > 0 &&
                    renderIngredientsTable(
                      formattedDifferences.menuTypeVersions[
                        selectedMenuTypeVersion
                      ].sections.ingredients,
                    )}
                </>
              )}
              {(modifierGroupsChanges.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.modifierGroupsExtra.length > 0) && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.itemsDiff.sectionTitles.modifierGroups',
                    )}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.modifierGroupsExtra.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`modifier_groups_extra_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                  {renderModifierGroups(modifierGroupsChanges)}
                </>
              )}
              {premadeModifierGroupsChanges.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.itemsDiff.sectionTitles.premadeModifierGroups',
                    )}
                  />
                  {renderModifierGroups(premadeModifierGroupsChanges)}
                </>
              )}

              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.associations.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.associations')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.associations.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`associations_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}

              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.suggestiveOrdering.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.itemsDiff.sectionTitles.suggestiveOrdering',
                    )}
                  />
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.suggestiveOrdering.map(change => (
                    <Fragment key={`suggestive_ordering_${change.field}`}>
                      {renderChange(change)}
                    </Fragment>
                  ))}
                </>
              )}

              {(formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.prepStations.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.prepStationsChanges.length > 0) && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.itemsDiff.sectionTitles.prepStations')}
                  />
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.prepStationsChanges.length > 0 && (
                    <Grid columnGap={20} rowGap={10}>
                      {formattedDifferences.menuTypeVersions[
                        selectedMenuTypeVersion
                      ].sections.prepStationsChanges.map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`prep_stations_changes_change_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                    </Grid>
                  )}
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.prepStations.length > 0 && (
                    <Box>
                      {renderChange({
                        field: 'prepStationIds',
                        label: t(
                          'menuScreen.itemDetails.prepStationsSection.prepStations.title',
                        ),
                        value:
                          formattedDifferences.menuTypeVersions[
                            selectedMenuTypeVersion
                          ].sections.prepStations,
                      })}
                    </Box>
                  )}
                </>
              )}
              {Object.keys(
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.taxes || {},
              ).length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.taxes')}
                  />
                  <Box>
                    {Object.keys(
                      formattedDifferences.menuTypeVersions[
                        selectedMenuTypeVersion
                      ].sections.taxes,
                    ).map(key => (
                      <Box key={`taxes-${key}`}>
                        {renderChange({
                          field: 'inheritedTaxesIds',
                          label: t(
                            `app.modals.itemsDiff.taxes.${key}` as ParseKeys,
                          ),
                          value:
                            formattedDifferences.menuTypeVersions[
                              selectedMenuTypeVersion
                            ].sections.taxes[
                              key as keyof TInheritedTaxesDiff
                            ] || [],
                        })}
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
    </>
  );
};

export default ItemsDiffModal;
