import { TFontSizes, TFontWeights } from '@app/theme/types';
import { TCsx, Theme, useTheme } from '@emotion/react';
import { HTMLAttributes, ReactNode } from 'react';

export interface ITypography extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
  variant?: TFontSizes;
  color?: keyof Theme['colors'] | (string & NonNullable<unknown>);
  fontWeight?: TFontWeights;
  align?: 'left' | 'right' | 'center';
  className?: string;
  css?: never;
  csx?: TCsx;
  component?: 'p' | 'div' | 'span';
}

const Typography = ({
  children,
  variant = 'body',
  color = 'text',
  align = 'left',
  fontWeight = 'regular',
  csx,
  component = 'p',
  ...otherProps
}: ITypography) => {
  const theme = useTheme();

  const Component = component;

  const baseStyles = {
    textAlign: align,
    fontSize: theme.fontSizes[variant],
    fontWeight: theme.fontWeights[fontWeight],
    color: theme.colors[color as keyof Theme['colors']] || color,
  };

  return (
    <Component css={[baseStyles, csx]} {...otherProps}>
      {children}
    </Component>
  );
};

export default Typography;
