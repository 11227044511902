import Typography from '@app/components/common/Typography';
import { roleButtonStyles } from './styles';
import { HTMLMotionProps } from 'framer-motion';
import Button from '@app/components/common/Button';

interface IRoleButton extends HTMLMotionProps<'button'> {
  name: string;
  subtitle?: string;
}

const RoleButton = ({ name, subtitle, ...otherProps }: IRoleButton) => {
  return (
    <Button whileTap={{ scale: 0.98 }} csx={roleButtonStyles} {...otherProps}>
      <Typography>{name}</Typography>
      {subtitle && (
        <>
          <Typography variant="caption" color="darkGrey" csx={{ marginTop: 3 }}>
            {subtitle}
          </Typography>
        </>
      )}
    </Button>
  );
};

export default RoleButton;
