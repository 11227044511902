import { validateUserVerified } from '@app/helpers/users';
import { getOrganizations } from '@app/state/app/actions';
import { IUserOrganization } from '@app/state/app/types';
import { useAppDispatch } from '@app/state/store';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const useUserOrganizations = ({
  onVerified,
  onNotVerified,
  checkUserVerified = true,
}: {
  onVerified?: () => void;
  onNotVerified?: (err: {
    token: string;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  }) => void;
  checkUserVerified?: boolean;
} = {}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IUserOrganization[]>([]);

  const onVerifiedRef = useRef(onVerified);
  const onNotVerifiedRef = useRef(onNotVerified);

  useEffect(() => {
    onVerifiedRef.current = onVerified;
  }, [onVerified]);

  useEffect(() => {
    onNotVerifiedRef.current = onNotVerified;
  }, [onNotVerified]);

  useEffect(() => {
    const handleGetUserOrganizations = async () => {
      setIsLoading(true);
      const result = await dispatch(getOrganizations());
      setOrganizations(result);
      setIsLoading(false);

      if (!checkUserVerified) return;

      await validateUserVerified()
        .then(() => {
          if (onVerifiedRef.current) {
            onVerifiedRef.current();
          }
        })
        .catch(err => {
          if (onNotVerifiedRef.current) {
            onNotVerifiedRef.current(err);
          } else {
            if (!err.token) {
              navigate('/auth/sign-in');
            }
            const continuePage = new URLSearchParams(
              window.location.search,
            ).get('continue');
            if (location.pathname.includes('/verify') && continuePage) {
              navigate(continuePage, { state: err });
            } else {
              navigate('/verify', { state: err });
            }
          }
        });
    };
    handleGetUserOrganizations();
  }, [checkUserVerified, dispatch, navigate]);

  return { organizations, isLoading };
};

export default useUserOrganizations;
