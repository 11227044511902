import { Theme, css } from '@emotion/react';

export const printerStatusTagStyles =
  (showText: boolean, isActive: boolean) => (theme: Theme) =>
    css({
      height: !showText ? 20 : 30,
      width: !showText ? 20 : 'auto',
      minWidth: !showText ? undefined : 100,
      borderRadius: !showText ? 15 : 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingInline: !showText ? undefined : 10,
      backgroundColor: isActive
        ? theme.colors.darkGreen
        : theme.colors.persistentSemanticRed,
    });
