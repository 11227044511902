import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import { printersSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CoreRootState,
  EPrinterStatus,
  IPrinter,
  PRINTER_INITIAL_STATE,
} from '@westondev/tableturn-core';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditPrinterModal from '../EditPrinterModal';
import PrinterStatusTag from './PrinterStatusTag';

interface IPrintersTable {
  actions: string;
  name: string;
  backupPrinterId: number | null;
  macAddress: string;
  serialNumber: string;
  make: string;
  model: string;
  status: EPrinterStatus;
  type: string;
  id: number;
  positionOne: string;
  positionTwo: string;
}

interface IPrintersTableProps {
  data: (IPrinter & {
    actions: string;
    positionOne: string;
    positionTwo: string;
  })[];
  emptyBySearch: boolean;
}

const PrintersTable = ({ data, emptyBySearch }: IPrintersTableProps) => {
  const printers = useSelector(printersSelector);
  const { t } = useTranslation();
  const [isModalActive, setIsModalActive] = useState(false);
  const [printerData, setPrinterData] = useState<IPrinter>(
    PRINTER_INITIAL_STATE,
  );

  const getPrinterData = (printerId: number) => {
    const updatedPrinters = printersSelector(
      store.getState() as unknown as unknown as CoreRootState,
    );
    return updatedPrinters[printerId];
  };

  const columnHelper = createColumnHelper<IPrintersTable>();

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('settingsModule.devices_licenses.tabletsTable.name'),
        cell: info => info.getValue(),
        minSize: 160,
      }),
      columnHelper.accessor('serialNumber', {
        header: t('settingsModule.devices_licenses.printersTable.deviceSerial'),
        cell: info => info.getValue(),
        enableSorting: false,
        minSize: 140,
      }),
      columnHelper.accessor('model', {
        header: t('settingsModule.devices_licenses.printersTable.model'),
        cell: info => info.getValue(),
        minSize: 100,
      }),
      columnHelper.accessor('type', {
        header: t('settingsModule.devices_licenses.printersTable.type'),
        cell: info =>
          t(`commonTexts.${info.getValue() as 'kitchen' | 'receipt'}`),
        minSize: 100,
      }),
      columnHelper.accessor('backupPrinterId', {
        header: t(
          'settingsModule.devices_licenses.printersTable.backupPrinter',
        ),
        cell: info => {
          return info.getValue() !== 0
            ? printers[info.getValue() as number].name
            : t('navBar.common.printerCenter.noPrinterOption');
        },
        minSize: 140,
      }),
      columnHelper.accessor('status', {
        header: t('settingsModule.devices_licenses.printersTable.status'),
        cell: info => (
          <PrinterStatusTag showStatusText status={info.getValue()} />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('macAddress', {
        header: t('settingsModule.devices_licenses.printersTable.macAddress'),
        cell: info => info.getValue(),
        enableSorting: false,
        minSize: 140,
      }),
      columnHelper.accessor('positionOne', {
        header: t('settingsModule.devices_licenses.printersTable.positionOne'),
        cell: info => info.getValue(),
        enableSorting: false,
        minSize: 140,
      }),
      columnHelper.accessor('positionTwo', {
        header: t('settingsModule.devices_licenses.printersTable.positionTwo'),
        cell: info => info.getValue(),
        enableSorting: false,
        minSize: 140,
      }),
      columnHelper.accessor('actions', {
        header: t('settingsModule.devices_licenses.licensesTable.actions'),
        cell: info => (
          <Button
            icon={<Icon name="MdEdit" />}
            csx={{ width: '50px' }}
            onClick={() => {
              setPrinterData(getPrinterData(info.row.original.id));
              setIsModalActive(true);
            }}
          />
        ),
        size: 70,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleCloseModal = () => {
    setIsModalActive(false);
  };

  return (
    <>
      <EditPrinterModal
        isEdit
        active={isModalActive}
        printerData={printerData}
        onCancel={handleCloseModal}
        onSuccess={handleCloseModal}
      />
      <Table
        renderEmptyValues
        columns={COLUMN_VALUES}
        data={data}
        cellCsx={{ height: '70px' }}
        manualSorting={false}
        alwaysShowSortIcon
        enableAlphabeticalSorting
        noDataMessage={
          emptyBySearch
            ? t(
                'settingsModule.devices_licenses.tabletsTable.emptyBySearchTitle',
              )
            : t('settingsModule.devices_licenses.tabletsTable.emptyDataTitle')
        }
        alignHeaders={{
          actions: 'center',
          status: 'center',
        }}
        align={{
          actions: 'center',
          status: 'center',
        }}
      />
    </>
  );
};

export default PrintersTable;
