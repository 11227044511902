import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';

import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { btnBaseStyles } from '../styles';
import Icon from '../../Icon';
import { useTheme } from '@emotion/react';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { ParseKeys } from 'i18next';

interface IDeleteConfirmationModal extends IConfirmationModal, WithTranslation {
  componentDescription?: React.ReactNode;
}

const DeleteConfirmationModal = ({
  title,
  description,
  name,
  active,
  onCancel,
  onSuccess,
  onTerciaryButtonPress,
  btnCommonCsx: btnCommonCsx,
  btnCancelText,
  btnCancelCsx: btnCancelCsx,
  btnSuccessText,
  btnSuccessCsx: btnSuccessCsx,
  btnTerciaryText,
  btnTerciaryCsx: btnTerciaryCsx,
  runCancelOnClickOutside,
  size,
  t,
  hideSuccess,
  hideCancel,
  hideTerciary,
  overrideTitle,
  autoclose = true,
  autoCloseOnCancel = true,
  type,
}: IDeleteConfirmationModal) => {
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const theme = useTheme();

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnTerciary = () => {
    if (onTerciaryButtonPress) onTerciaryButtonPress();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
    autoclose && setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  return (
    <Modal
      isActive={active}
      size={size || 420}
      minHeight={220}
      showXCloser={false}
      animationMode="alert"
      title={
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <Icon name="MdDeleteForever" color={theme.colors.semanticRed} />
          <Box>
            <Typography variant="subtitle" fontWeight="medium">
              {overrideTitle
                ? title
                : t(
                    `app.modals.${
                      type === 'delete'
                        ? 'deleteConfirmation'
                        : 'disassociateConfirmation'
                    }.title`,
                    { title },
                  )}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <>
          {!hideCancel && (
            <Button
              variant="secondary"
              csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
              onClick={handleOnCancel}>
              {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
            </Button>
          )}
          {!hideTerciary && btnTerciaryText && (
            <Button
              csx={[btnBaseStyles, btnCommonCsx, btnTerciaryCsx]}
              onClick={handleOnTerciary}>
              {btnTerciaryText}
            </Button>
          )}
          {!hideSuccess && (
            <Button
              variant="danger"
              csx={[btnBaseStyles, btnCommonCsx, btnSuccessCsx]}
              onClick={handleOnModalSuccess}>
              {btnSuccessText
                ? btnSuccessText
                : type === 'delete'
                ? t('commonTexts.remove')
                : t('commonTexts.unassociate')}
            </Button>
          )}
        </>
      }
      onModalClose={handleOnModalClose}>
      <Box>
        {name && (
          <Typography
            color="persistentSemanticRed"
            fontWeight="medium"
            csx={{ marginBottom: '8px' }}>
            {name}
          </Typography>
        )}
        <Typography>
          {description
            ? description
            : t(
                `app.modals.${
                  type === 'delete'
                    ? 'deleteConfirmation'
                    : 'disassociateConfirmation'
                }.currentItemMessage` as ParseKeys,
                {
                  name,
                },
              )}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
