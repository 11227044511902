import { TCsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import Box from '../../Box';
import { IBox } from '../../Box/Box';
import { TBreakpoints, TOffsets } from '../types';
import { itemStyles } from './styles';

interface IGridItem extends TBreakpoints, TOffsets {
  children?: ReactNode;
  centered?: boolean;
  csx?: TCsx;
  css?: never;
  className?: string;
  isOffset?: boolean;
  divRef?: React.Ref<HTMLDivElement>;
  boxProps?: IBox;
}

const GridItem = ({ children, centered = false, ...props }: IGridItem) => {
  const {
    mb,
    sm,
    md,
    lg,
    xl,
    mbOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    isOffset = false,
    csx,
    divRef,
    boxProps,
    ...className
  } = props;

  const hasOffset = mbOffset || smOffset || mdOffset || lgOffset || xlOffset;

  return (
    <>
      {!isOffset && (
        <Box
          ref={divRef}
          className="gridItem"
          csx={[itemStyles({ mb, sm, md, lg, xl }, centered), csx]}
          {...className}
          {...boxProps}>
          {children}
        </Box>
      )}

      {hasOffset ? (
        <Box
          className="gridItem"
          csx={itemStyles(
            {
              mb: mbOffset,
              sm: smOffset,
              md: mdOffset,
              lg: lgOffset,
              xl: xlOffset,
            },
            centered,
            true,
          )}
        />
      ) : null}
    </>
  );
};
export default GridItem;
