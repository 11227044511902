import { MQ_MIN_LARGE } from '@app/theme/types';
import { css } from '@emotion/react';

export const resetPasswordCardStyles = () =>
  css({
    '.form': {
      height: '100%',
    },
    '.formContent': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '52px',
      flex: 1,
      '> div:nth-of-type(1)': {
        marginBottom: '18px',
      },
      '> div:nth-of-type(2)': {
        marginBottom: '5px',
      },
    },
    '.formButtons': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '15px',
      flexGrow: 0,
    },
    [MQ_MIN_LARGE]: {
      paddingTop: '20px',
    },
  });
