import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { gridStyles } from './styles';
import Card from '@app/components/common/Card';

const GridExample = () => {
  return (
    <Card>
      <Typography
        variant="subtitle"
        fontWeight="bold"
        csx={{ marginBottom: '10px' }}>
        Grid
      </Typography>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        The Grid container divides the width in 12 spaces. You can individually
        configure the space of each GridItem. Default value for mobile (mb) is
        12.
      </Typography>
      <code>{'<Grid.Item sm={6} md={3} xl={2} />'}</code>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        Example:
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={3} centered csx={gridStyles}>
          <Typography variant="caption">
            mb={12} sm={3}
          </Typography>
        </Grid.Item>
        <Grid.Item sm={3} centered csx={gridStyles}>
          <Typography variant="caption">
            mb={12} sm={3}
          </Typography>
        </Grid.Item>
        <Grid.Item sm={6} centered csx={gridStyles}>
          <Typography variant="caption">
            mb={12} sm={4}
          </Typography>
        </Grid.Item>
        <Grid.Item sm={12} centered csx={gridStyles}>
          <Typography variant="caption">
            mb={12} sm={12}
          </Typography>
        </Grid.Item>
      </Grid>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        columnGap
      </Typography>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        Set the column space for all breakpoints or individually.
      </Typography>
      <code>
        {'columnGap={5}'} {'columnGap={{sm: 5, lg: 2}}'}
      </code>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        rowGap
      </Typography>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        Set the row space for all breakpoints or individually.
      </Typography>
      <code>
        {'rowGap={5}'} {'rowGap={{sm: 5, lg: 2}}'}
      </code>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Centered
      </Typography>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        Use the centered prop to center the content inside the GridItem
      </Typography>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        CSS
      </Typography>
      <Typography variant="caption" csx={{ marginBottom: '10px' }}>
        Override the Grid, GridItem styles
      </Typography>
    </Card>
  );
};

export default GridExample;
