import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  IGuestReceiptSettings,
  INVISIBLE_CHAR,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const inputsSizes = {
  mb: 12,
  sm: 6,
  lg: 3,
};

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestReceiptSettings>(SECTION_ID);

const SuggestedTips = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  return (
    <Card.SubCard
      title={t(
        'loggedIn.settingsModule.guestReceiptSettings.suggestedTips.title',
      )}
      optionsLeft={
        <Switch
          checked={data ? data?.showSuggestedTips : true}
          onChange={showSuggestedTips =>
            setValue<IGuestReceiptSettings>({ showSuggestedTips }, SECTION_ID)
          }
          label={t(
            'settingsModule.guestReceiptSettings.suggestedTips.showSuggestedTips',
          )}
        />
      }>
      <Grid columnGap={25} rowGap={25}>
        <Grid.Item {...inputsSizes}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption1',
            )}
            prefix={INVISIBLE_CHAR}
            suffix="%"
            precision={0}
            maxValue={100}
            value={data?.tipOption1 || 0}
            onValueChange={tipOption1 =>
              setValue<IGuestReceiptSettings>({ tipOption1 }, SECTION_ID)
            }
            disabled={!data?.showSuggestedTips}
          />
        </Grid.Item>
        <Grid.Item {...inputsSizes}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption2',
            )}
            prefix={INVISIBLE_CHAR}
            suffix="%"
            precision={0}
            maxValue={100}
            value={data?.tipOption2 || 0}
            onValueChange={tipOption2 =>
              setValue<IGuestReceiptSettings>({ tipOption2 }, SECTION_ID)
            }
            disabled={!data?.showSuggestedTips}
          />
        </Grid.Item>
        <Grid.Item {...inputsSizes}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption3',
            )}
            prefix={INVISIBLE_CHAR}
            suffix="%"
            precision={0}
            maxValue={100}
            value={data?.tipOption3 || 0}
            onValueChange={tipOption3 =>
              setValue<IGuestReceiptSettings>({ tipOption3 }, SECTION_ID)
            }
            disabled={!data?.showSuggestedTips}
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default SuggestedTips;
