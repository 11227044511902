import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRegisterTicketSettings,
  MODIFIER_DISPLAY_TYPE_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  displayModeDropdownFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterTicketSettings>(SECTION_ID);

const TicketDisplaySettings = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const modifierDisplayTypeOptions = useMemo(
    () => displayModeDropdownFactory(t),
    [t],
  );

  return (
    <Card
      csx={{ height: 'auto' }}
      title={t('settingsModule.registerTicketSettings.ticketDisplay.title')}>
      <Grid rowGap={15}>
        <Grid.Item>
          <Card.SubCard
            title={t(
              'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.title',
            )}>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item mb={12} sm={6} xl={4}>
                <Checkbox
                  label={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.separateSentItems',
                  )}
                  info={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.separateSentItemstoolTip',
                  )}
                  checked={data ? data.separateSentItems : false}
                  onChange={separateSentItems => {
                    const values = {
                      separateSentItems,
                    } as IRegisterTicketSettings;
                    if (!separateSentItems) {
                      values.showSendCourseButton = false;
                    }
                    setValue<IRegisterTicketSettings>(values, SECTION_ID);
                  }}
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} xl={4}>
                <Checkbox
                  label={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.showSendCourseButton',
                  )}
                  info={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.showSendCourseButtontoolTip',
                  )}
                  checked={data ? data.showSendCourseButton : false}
                  isDisabled={!data?.separateSentItems}
                  onChange={showSendCourseButton =>
                    setValue<IRegisterTicketSettings>(
                      { showSendCourseButton },
                      SECTION_ID,
                    )
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} xl={4}>
                <Checkbox
                  label={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.autoCollapseLineItems',
                  )}
                  info={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.autoCollapseLineItemstoolTip',
                  )}
                  checked={data ? data.autoCollapseLineItems : false}
                  onChange={autoCollapseLineItems =>
                    setValue<IRegisterTicketSettings>(
                      { autoCollapseLineItems },
                      SECTION_ID,
                    )
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} xl={4}>
                <Checkbox
                  label={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.showTimestamps',
                  )}
                  info={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.ticketDisplay.showTimestampstoolTip',
                  )}
                  checked={data ? data.showTimestamps : false}
                  onChange={showTimestamps =>
                    setValue<IRegisterTicketSettings>(
                      { showTimestamps },
                      SECTION_ID,
                    )
                  }
                />
              </Grid.Item>
            </Grid>
          </Card.SubCard>
        </Grid.Item>
        <Grid.Item>
          <Card.SubCard
            title={t(
              'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.title',
            )}>
            <Grid rowGap={15}>
              <Grid.Item>
                <Grid columnGap={15} rowGap={15}>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.hideModGroupTitles',
                      )}
                      info={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.hideModGroupTitlestoolTip',
                      )}
                      checked={data ? data.hideModGroupTitles : false}
                      onChange={hideModGroupTitles =>
                        setValue<IRegisterTicketSettings>(
                          { hideModGroupTitles },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.showModifierItemPrice',
                      )}
                      info={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.showModifierItemPricetoolTip',
                      )}
                      checked={data ? data.showModifierItemPrice : false}
                      onChange={showModifierItemPrice =>
                        setValue<IRegisterTicketSettings>(
                          { showModifierItemPrice },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.displayDefaultModifiers',
                      )}
                      info={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.displayDefaultModifierstoolTip',
                      )}
                      checked={data ? data.displayDefaultModifiers : false}
                      onChange={displayDefaultModifiers =>
                        setValue<IRegisterTicketSettings>(
                          { displayDefaultModifiers },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.groupSameModItems',
                      )}
                      info={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.groupSameModItemstoolTip',
                      )}
                      checked={data ? data.groupSameModItems : false}
                      onChange={groupSameModItems =>
                        setValue<IRegisterTicketSettings>(
                          { groupSameModItems },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.addSubForMultiSelectOff',
                      )}
                      info={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.addSubForMultiSelectOfftoolTip',
                      )}
                      checked={data ? data.addSubForMultiSelectOff : false}
                      onChange={addSubForMultiSelectOff =>
                        setValue<IRegisterTicketSettings>(
                          { addSubForMultiSelectOff },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} xl={4}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.noForDeselectedDefaultModifiers',
                      )}
                      checked={
                        data ? data.noForDeselectedDefaultModifiers : false
                      }
                      onChange={noForDeselectedDefaultModifiers =>
                        setValue<IRegisterTicketSettings>(
                          { noForDeselectedDefaultModifiers },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Grid.Item>
              <Grid.Item mb={12} xl={4}>
                <Dropdown
                  label={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.modifierItemDisplayMode.label',
                  )}
                  placeholder={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.modifierItemDisplayMode.label',
                  )}
                  info={t(
                    'settingsModule.registerTicketSettings.ticketDisplay.modifiersDisplay.modifierItemDisplayMode.toolTip',
                  )}
                  data={[modifierDisplayTypeOptions]}
                  value={
                    MODIFIER_DISPLAY_TYPE_OPTIONS.indexOf(
                      data?.modifierItemDisplayMode,
                    ) + 1
                  }
                  onChange={newModifierDisplayType =>
                    setValue<IRegisterTicketSettings>(
                      {
                        modifierItemDisplayMode:
                          MODIFIER_DISPLAY_TYPE_OPTIONS[
                            newModifierDisplayType - 1
                          ],
                      },
                      SECTION_ID,
                    )
                  }
                />
              </Grid.Item>
            </Grid>
          </Card.SubCard>
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default TicketDisplaySettings;
