export interface IItemFreshSheetTable {
  id: number;
  name: string;
  onHandQuantity: number;
  is86ed: boolean;
  delete: boolean;
  sortPriority: number;
}

export const ROW_ID_PREFIX = 'fresh-sheet-row-';
export const FRESH_SHEET_TABLE_ID = 'fresh-sheet-table';
