import UsersList from '@app/components/LoggedIn/Settings/Accounts/Users/UsersList';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import Box from '@app/components/common/Box';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators, createSelector } from '@reduxjs/toolkit';

import {
  IUserObject,
  SettingsSectionId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedItemData = makeSelectSettingsData<IUserObject>(SECTION_ID);

const memoizedUserFullNameFactory = ({ data }: { data: IUserObject }) => {
  return `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}`.trim();
};
const selectMemoizedUserFullName = createSelector(
  getMemoizedItemData,
  memoizedUserFullNameFactory,
);

const AccountsUsers = () => {
  // Redux
  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    actionCreatorsSettingsWeb.checkForSettingsChangesAndNavigateWeb,
    useDispatch(),
  );

  //Local state
  const match = useMatch(getPathWithOrgData('/settings/accounts/users'));
  const matchAdd = useMatch(getPathWithOrgData('/settings/accounts/users/add'));
  const navigate = useNavigateWithOrg();

  const fullName = useSelector(selectMemoizedUserFullName);
  const { t } = useTranslation();

  const handleGotoUsersList = () => {
    checkForSettingsChangesAndNavigateWeb(
      () => {
        navigate('/settings/accounts/users');
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  return !match ? (
    <>
      <BreadCrumbSettings
        title="Users"
        currentPage={
          matchAdd
            ? t('settingsModule.usersSettings.addingNewUser')
            : fullName || ''
        }
        onClick={handleGotoUsersList}
        isSticky
      />
      <Box csx={{ padding: '4px 15px 15px 15px' }}>
        <Outlet />
      </Box>
    </>
  ) : (
    <>
      <UsersList />
    </>
  );
};

export default AccountsUsers;
