import { TCsx } from '@emotion/react';
import { AnimatePresence, m, useAnimate, usePresence } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import Box from '../Box';
import Button from '../Button';
import Icon from '../Icon';
import Typography from '../Typography';
import { modalStyles } from './styles';

interface IModal {
  isActive: boolean;
  position?: 'center' | 'right';
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string | React.ReactNode;
  size?: string | number;
  height?: string | number;
  minHeight?: string | number;
  modalContainerCsx?: TCsx;
  onModalClose?: () => void;
  footer?: React.ReactNode;
  noPadding?: boolean;
  showXCloser?: boolean;
  animationMode?: 'alert' | 'modal' | 'slide-left' | 'none';
  dismissOnOutsideClick?: boolean;
  displayAfterNavBar?: boolean;
  fullHeight?: boolean;
  csx?: TCsx;
}

const Modal = ({
  isActive = false,
  children,
  position = 'center',
  title,
  subtitle,
  size = 'auto',
  minHeight = 'auto',
  height = 'auto',
  modalContainerCsx = {},
  onModalClose,
  footer,
  noPadding,
  showXCloser = true,
  animationMode = 'modal',
  dismissOnOutsideClick = true,
  displayAfterNavBar = false,
  fullHeight,
  csx,
}: IModal) => {
  animationMode = useMemo(() => {
    return position === 'right' ? 'slide-left' : animationMode;
  }, [position, animationMode]);

  const handleModalClose = () => {
    if (onModalClose) onModalClose();
  };
  const handleOutsideClick = () => {
    if (dismissOnOutsideClick) handleModalClose();
  };

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isActive]);

  return createPortal(
    <>
      <AnimatePresence>
        {isActive ? (
          <Box
            csx={[
              modalStyles(
                size,
                minHeight,
                height,
                position,
                displayAfterNavBar,
              ),
              {
                ...(fullHeight && { height: '100%' }),
              },
              csx,
            ]}>
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="backDrop"
              transition={{ duration: 0.2 }}
              onClick={handleOutsideClick}
            />
            <ModalContainer
              animationMode={animationMode}
              modalContainerCsx={modalContainerCsx}>
              {title && (
                <Box className="modalTitleContainer">
                  <Box className="modalTitle">
                    {title && typeof title === 'string' ? (
                      <Typography variant="heading" fontWeight="medium">
                        {title}
                      </Typography>
                    ) : (
                      title
                    )}
                    {subtitle && typeof subtitle === 'string' ? (
                      <Typography variant="caption" fontWeight="regular">
                        {subtitle}
                      </Typography>
                    ) : (
                      subtitle
                    )}
                  </Box>

                  {showXCloser && (
                    <Box className="modalOptions">
                      <CloseButton onClick={handleModalClose} />
                    </Box>
                  )}
                </Box>
              )}
              {!title && showXCloser && (
                <CloseButton onClick={handleModalClose} isAbsolute />
              )}
              <Box
                className="modalContent"
                csx={noPadding ? { padding: '0px !important' } : {}}>
                {children}
              </Box>
              {footer && <Box className="footer">{footer}</Box>}
            </ModalContainer>
          </Box>
        ) : null}
      </AnimatePresence>
    </>,
    document.body,
  );
};

const ModalContainer = ({
  children,
  animationMode,
  modalContainerCsx,
}: {
  children: React.ReactNode;
  animationMode: 'alert' | 'modal' | 'slide-left' | 'none';
  modalContainerCsx: TCsx;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          animationMode === 'alert'
            ? { opacity: 1, scale: 1 }
            : animationMode === 'slide-left'
            ? { opacity: 1, x: 0 }
            : animationMode === 'modal'
            ? { opacity: 1, y: 20 }
            : { opacity: 1, y: 0 },
          {
            duration: 0.2,
          },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          animationMode === 'alert'
            ? { opacity: 0, scale: 0.8 }
            : animationMode === 'slide-left'
            ? { opacity: 0, x: 20 }
            : animationMode === 'modal'
            ? { opacity: 0, y: -20 }
            : { opacity: 1, y: 0 },
          {
            duration: 0.2,
          },
        );
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <m.div
      ref={scope}
      className="modalContainer"
      css={modalContainerCsx}
      initial={
        animationMode === 'alert'
          ? { scale: 0.8 }
          : animationMode === 'slide-left'
          ? { opacity: 0.7, x: 20 }
          : animationMode === 'modal'
          ? { opacity: 0.7, y: -20 }
          : { opacity: 1, y: 0 }
      }>
      {children}
    </m.div>
  );
};

export const CloseButton = ({
  onClick,
  isAbsolute = false,
}: {
  onClick: () => void;
  isAbsolute?: boolean;
}) => (
  <Button
    className="modalCloseButton"
    variant="icon"
    icon={<Icon name="MdClear" size="25px" />}
    csx={isAbsolute ? { position: 'absolute', right: '15px', top: '15px' } : {}}
    onClick={onClick}
  />
);

export default Modal;
