import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import ColorPicker from '@app/components/common/ColorPicker';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import TextAreaInput from '@app/components/common/TextAreaInput';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  displayFlexColumnStyles,
  displayFlexRowStyles,
} from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IOnlineOrderingStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.PAGE_DESIGN;
const getMemoizedItemData =
  makeSelectSettingsData<IOnlineOrderingStore>(SECTION_ID);

const PageDesignSettings = () => {
  const [showHoverPrimary, setShowHoverPrimary] = useState(false);
  const [showHoverSecondary, setShowHoverSecondary] = useState(false);
  const [showHoverTertiary, setShowHoverTertiary] = useState(false);
  const [showHoverQuaternary, setShowHoverQuaternary] = useState(false);

  //Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedItemData);

  const { t } = useTranslation();

  return (
    <Grid rowGap={15} columnGap={15}>
      <Grid.Item sm={6} lg={4}>
        <Checkbox
          label={t('settingsModule.onlineSettings.pageDesign.showItemImage')}
          checked={data.showItemImage}
          onChange={showItemImage => {
            setValue({ showItemImage }, SECTION_ID);
          }}
        />
      </Grid.Item>
      <Grid.Item sm={6} lg={4}>
        <Checkbox
          label={t(
            'settingsModule.onlineSettings.pageDesign.allowSpecialInstructionsOnItems',
          )}
          checked={data.allowSpecialInstructionsOnItems}
          onChange={allowSpecialInstructionsOnItems => {
            setValue({ allowSpecialInstructionsOnItems }, SECTION_ID);
          }}
        />
      </Grid.Item>
      <Grid.Item sm={6} lg={4}>
        <Checkbox
          label={t('settingsModule.onlineSettings.pageDesign.showCalories')}
          checked={data.showCalories}
          onChange={showCalories => {
            setValue({ showCalories }, SECTION_ID);
          }}
        />
      </Grid.Item>
      <Grid.Item>
        <Divider />
      </Grid.Item>
      <Grid.Item>
        <DetailsSubtitle>Branding Colors</DetailsSubtitle>
        <Grid rowGap={15} columnGap={15}>
          <Grid.Item mb={6} sm={6} lg={3} csx={{ gap: '8px' }}>
            <Box csx={displayFlexRowStyles}>
              <ToolTip
                showHover={showHoverPrimary}
                content="Main Color will appear in some titles, buttons, selected tabs, switches and checkboxes">
                <div />
              </ToolTip>
              <Typography
                fontWeight="medium"
                onMouseOver={() => setShowHoverPrimary(true)}
                onMouseOut={() => setShowHoverPrimary(false)}>
                {t('settingsModule.onlineSettings.pageDesign.primaryColor')}
              </Typography>
            </Box>
            <ColorPicker
              color={data.primaryColor}
              onChange={primaryColor => {
                setValue({ primaryColor }, SECTION_ID);
              }}
            />
          </Grid.Item>
          <Grid.Item mb={6} sm={6} lg={3} csx={{ gap: '8px' }}>
            <Box csx={displayFlexRowStyles}>
              <ToolTip
                showHover={showHoverSecondary}
                content="Secondary Color will appear in secondary things like buttons, hovers,
                secondary colors and some icons.">
                <div />
              </ToolTip>
              <Typography
                fontWeight="medium"
                onMouseOver={() => setShowHoverSecondary(true)}
                onMouseOut={() => setShowHoverSecondary(false)}>
                {t('settingsModule.onlineSettings.pageDesign.secondaryColor')}
              </Typography>
            </Box>
            <ColorPicker
              color={data.secondaryColor}
              onChange={secondaryColor => {
                setValue({ secondaryColor }, SECTION_ID);
              }}
            />
          </Grid.Item>
          <Grid.Item mb={6} sm={6} lg={3} csx={{ gap: '8px' }}>
            <Box csx={displayFlexRowStyles}>
              <ToolTip
                showHover={showHoverTertiary}
                content={t(
                  'settingsModule.onlineSettings.pageDesign.tertiaryColor',
                )}>
                <div />
              </ToolTip>
              <Typography
                fontWeight="medium"
                onMouseOver={() => setShowHoverTertiary(true)}
                onMouseOut={() => setShowHoverTertiary(false)}>
                Tertiary Color
              </Typography>
            </Box>
            <ColorPicker
              color={data.tertiaryColor}
              onChange={tertiaryColor => {
                setValue({ tertiaryColor }, SECTION_ID);
              }}
            />
          </Grid.Item>
          <Grid.Item mb={6} sm={6} lg={3} csx={{ gap: '8px' }}>
            <Box csx={displayFlexRowStyles}>
              <ToolTip
                showHover={showHoverQuaternary}
                content="Background Color will appear the background and dialogs">
                <div />
              </ToolTip>
              <Typography
                fontWeight="medium"
                onMouseOver={() => setShowHoverQuaternary(true)}
                onMouseOut={() => setShowHoverQuaternary(false)}>
                {t('settingsModule.onlineSettings.pageDesign.backgroundColor')}
              </Typography>
            </Box>
            <ColorPicker
              color={data.backgroundColor}
              onChange={backgroundColor => {
                setValue({ backgroundColor }, SECTION_ID);
              }}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item>
        <Divider />
      </Grid.Item>
      <Grid.Item>
        <DetailsSubtitle>Banner</DetailsSubtitle>
        <Box csx={[displayFlexColumnStyles, { gap: '15px' }]}>
          <Checkbox
            label={t('settingsModule.onlineSettings.pageDesign.showBanner')}
            checked={data.showBanner}
            onChange={showBanner => {
              setValue({ showBanner }, SECTION_ID);
            }}
          />
          <TextAreaInput
            label={t(
              'settingsModule.onlineSettings.pageDesign.bannerDescription',
            )}
            placeholder={t(
              'settingsModule.onlineSettings.pageDesign.bannerDescription',
            )}
            value={data.bannerDescription}
            onChange={({ currentTarget: { value: bannerDescription } }) => {
              setValue({ bannerDescription }, SECTION_ID);
            }}
          />
        </Box>
      </Grid.Item>
    </Grid>
  );
};

export default PageDesignSettings;
