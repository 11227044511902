import { selectLocations } from '@app/state/menu/menuSelectors';
import { selectLocationId } from '@app/state/selectors/appSelectors';
import { RootState } from '@app/state/store';
import {
  ILocationGroupStore,
  ITEM_INITIAL_STATE,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';

interface IDetailsAddress {
  address1: string | undefined | null;
  state: string | undefined | null;
  city: string | undefined | null;
}

const DiscountSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  showSelectAll = true,
  ...others
}: ICustomSelectionModal<unknown>) => {
  //redux
  const discounts = useSelector(
    (state: RootState) => state.menu.masterMenu.discounts,
  );
  const locationGroups = useSelector(selectLocations);

  const currentLocationId = useSelector(selectLocationId);

  const handleOnPress = (button: IItemSelection) => {
    const _discount = discounts[Number(button.id)] || ITEM_INITIAL_STATE;

    let _locations: IDetailsAddress[] = [];

    Object.values(locationGroups).forEach(
      (locationGroup: ILocationGroupStore) => {
        const currentLocations =
          locationGroup.locations
            .filter(
              location =>
                _discount?.locationIds?.includes(location.id) &&
                location.id !== currentLocationId,
            )
            .map(location => ({
              address1: location?.streetLine1,
              state: location?.state,
              city: location?.city,
            })) || [];

        _locations = [..._locations, ...currentLocations];
      },
    );
  };

  return (
    <GenericSelectionModal
      {...others}
      onPress={handleOnPress}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.discounts.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.discounts.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.discounts.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.discounts.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.discounts.noAvailableCardsText',
      )}
      showDescriptionPanel={false}
      showSelectedButtons={true}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}
      showSelectAll={showSelectAll}
    />
  );
};

export default DiscountSelectionModal;
