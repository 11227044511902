import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { tagStyles } from './styles';
import Icon from '@app/components/common/Icon';
import { MouseEventHandler } from 'react';

const Tag = ({
  children,
  onClick,
  width = '100px',
}: {
  children: React.ReactNode;
  width?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <Box csx={tagStyles(width)} onClick={onClick}>
      <Box className="tag">
        <Typography align="center">{children}</Typography>
      </Box>
      <Box className="removeIcon">
        <Icon name="MdClear" color="textWhite" />
      </Box>
    </Box>
  );
};

export default Tag;
