import Box from '@app/components/common/Box';
import { RadioSelectionStates } from '@app/types';
import { TCsx, Theme } from '@emotion/react';

interface IRadioCircle {
  state: RadioSelectionStates;
  size: number;
  colors?: {
    border: string;
    background: string;
    fill: string;
  };
  csx?: TCsx;
}

const DEFAULT_RADIO_COLOR = {
  border: 'lightGrey',
  fill: 'semanticBlue',
  background: 'lightestGrey',
};

const RadioCircle = ({
  size,
  state,
  colors = DEFAULT_RADIO_COLOR,
  csx,
}: IRadioCircle) => {
  return (
    <Box
      csx={theme => [
        {
          minHeight: size,
          minWidth: size,
          borderRadius: size,
          backgroundColor:
            state === RadioSelectionStates.FULL
              ? theme.colors[colors.fill as keyof Theme['colors']] ||
                colors.fill
              : theme.colors[colors.background as keyof Theme['colors']] ||
                colors.background,
          border: `1px solid ${
            theme.colors[
              (state === RadioSelectionStates.FULL
                ? colors.fill
                : colors.border) as keyof Theme['colors']
            ] || colors.border
          }`,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        csx,
      ]}>
      {state === RadioSelectionStates.SOME && (
        <Box
          csx={theme => ({
            height: size,
            width: size / 2,
            backgroundColor:
              theme.colors[colors.fill as keyof Theme['colors']] || colors.fill,
            borderTopRightRadius: size,
            borderBottomRightRadius: size,
          })}
        />
      )}
    </Box>
  );
};

export default RadioCircle;
