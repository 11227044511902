import { appSelectors } from '@westondev/tableturn-core';
import { createSelector } from 'reselect';
import { RootState } from '../store';

export const {
  selectPopoverData,
  selectIsClockInSuggestionModalOpen,
  selectRefreshToast,
  selectIsRefreshToastActive,
} = appSelectors;

export const selectIsLoading = (state: RootState) => state.app.isLoading;
export const selectToken = (state: RootState) => state.app.token;
export const selectPortals = (state: RootState) => state.app.portals;
export const selectUserDetails = (state: RootState) => state.app.userDetails;
export const selectConfirmationModal = (state: RootState) =>
  state.app.confirmationModal;

export const selectIsMainPortal = createSelector(
  selectPortals,
  portals => portals.length === 1 && portals[0] === 'tooltip',
);
export const selectCurrentSettingsRoles = (state: RootState) =>
  state.settings.selectedMode.currentSettings.roles;
export const selectOrganizationId = (state: RootState) =>
  state.app.organizationId;
export const selectLocationId = (state: RootState) => state.app.locationId;
export const selectOrganizations = (state: RootState) =>
  state.app.organizations;
export const selectLoggedInUser = (state: RootState) => state.loggedInUser;
export const selectLoggedInUserRoleIds = createSelector(
  selectLoggedInUser,
  loggedInUser => loggedInUser.roleIds || [],
);

export const selectCurrentLocationName = createSelector(
  (state: RootState) => state.locations,
  selectLocationId,
  (locations, locationId) => {
    let locationName = '';
    for (const locationGroup of Object.values(locations)) {
      const location = locationGroup.locations.find(l => l.id === locationId);
      if (location) {
        locationName = location.name;
        break;
      }
    }

    return locationName;
  },
);

export const selectUserLocationAccess = createSelector(
  selectLoggedInUser,
  loggedInUser => {
    const locations = loggedInUser.locationIds;
    return locations || [];
  },
);

export const selectLocationUserRoles = createSelector(
  selectCurrentSettingsRoles,
  selectLoggedInUserRoleIds,
  (roles, roleIds) => {
    return (
      roleIds?.map(roleId => roles[roleId]).filter(role => role.active) || []
    );
  },
);
