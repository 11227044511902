import { Theme, css } from '@emotion/react';

export const cardStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[1],
    borderRadius: '8px',
    padding: '15px',
    height: '100%',
    position: 'relative',
  });
