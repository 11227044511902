import useAuth from '@app/hooks/useAuth';
import useOrgData from '@app/hooks/useOrgData';
import { actionCreatorsApp } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  //Redux
  const { refreshCredentialsOnReload, refreshDataOnReload } =
    bindActionCreators(actionCreatorsApp, useDispatch());

  // Local state
  const isAuth = useAuth();
  const isOrgData = useOrgData();

  const continuePath = `?continue=${window.location.pathname}`;

  useEffect(() => {
    if (!isAuth) return;

    refreshCredentialsOnReload();

    if (!isOrgData) return;
    refreshDataOnReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={`/auth/sign-in${continuePath}`} replace />
  );
};

export default PrivateRoute;
