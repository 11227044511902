import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Tag from '@app/components/LoggedIn/Settings/RegisterMode/TagsSettings/OpenItemTypes/Tag';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Icon from '@app/components/common/Icon';
import OptionsModal from '@app/components/common/OptionsModal';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { currentPrepStationsSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IOpenItemType,
  IRegisterSettings,
  REGISTER_OPEN_ITEM_TYPE_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getOpenItemTypeId,
  menuPrepStationsFactory,
  propagateString,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tagsContainer } from './styles';

const {
  openItemTypesSelector,
  areSettingsMasterModeSelector,
  currentTaxesSettingsSelector,
  makeSelectSettingsData,
} = settingsSelectors;

// TODO: Add translations
interface IOpenItemTypesTable {
  id: number;
  status: boolean;
  name: string;
  posName: string;
  canDelete: boolean;
  sortPriority: number;
  prepStationId: number;
  prepStationName: string;
  taxIds: number[];
}

const ROW_ID_PREFIX = 'open-item-types-row-';
const SECTION_ID = SettingsSectionId.TAGS;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const columnHelper = createColumnHelper<IOpenItemTypesTable>();

const OpenItemTypes = () => {
  // Redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const actualOpenItemTypes = useSelector(openItemTypesSelector);
  const prepStations = useSelector(currentPrepStationsSelector);
  const taxes = useSelector(currentTaxesSettingsSelector);
  const { data: bigData } = useSelector(getMemoizedItemData);

  // Local state
  const data = bigData?.openItemTypes;

  const prepStationOptions = useMemo(
    () =>
      menuPrepStationsFactory(
        Object.keys(prepStations).map(id => Number(id)),
        prepStations,
      ).map(prepStation => ({
        label: prepStation.title,
        value: prepStation.id,
      })),
    [prepStations],
  );

  const updateOpenItemType = (
    newValue: any,
    id: number,
    field: keyof IOpenItemType,
  ) => {
    const openItemTypes = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.openItemTypes;
    setValue<IRegisterSettings>(
      {
        openItemTypes: {
          ...openItemTypes,
          [id]: {
            ...openItemTypes[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const openItemTypesArray = useMemo(
    () =>
      orderBy(
        Object.keys(data || {}).map(key => ({
          id: Number(key),
          status: data?.[Number(key)]?.active,
          name: data?.[Number(key)]?.name,
          posName: data?.[Number(key)]?.posName,
          canDelete: actualOpenItemTypes[Number(key)] ? false : true,
          sortPriority: data?.[Number(key)]?.sortPriority,
          prepStationId: data?.[Number(key)]?.prepStationId,
          prepStationName:
            prepStations[data?.[Number(key)]?.prepStationId]?.name ||
            'Print on Other Printer',
          taxIds: data?.[Number(key)]?.taxIds,
          // taxNames: data?.[Number(key)]?.taxIds.map(
          //   taxId => taxes[taxId]?.name,
          // ),
        })),
        'sortPriority',
      ),
    [data, actualOpenItemTypes, prepStations],
  );

  const handleDelete = (id: number) => {
    const openItemTypes = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.openItemTypes;

    let sortPriority = 1;
    const openItemTypesCloned = cloneDeep(openItemTypes);
    delete openItemTypesCloned[id];
    const openItemTypesClonedArray = Object.values(openItemTypesCloned);

    orderBy(openItemTypesClonedArray, 'sortPriority').forEach(value => {
      openItemTypesCloned[value.openItemTypeId].sortPriority = sortPriority;
      sortPriority++;
    });

    setValue<IRegisterSettings>(
      {
        openItemTypes: openItemTypesCloned,
      },
      SECTION_ID,
    );
  };

  const updateName = (newName: string, id: number) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentOpenItemType = registerData.openItemTypes[id];
    const prevName = currentOpenItemType.name;
    const propagateObj = {
      name: prevName,
      posName: currentOpenItemType.posName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        openItemTypes: {
          ...registerData.openItemTypes,
          [id]: {
            ...registerData.openItemTypes[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleNewRowScroll = useScrollToNewRow();

  const onNewOpenItemType = () => {
    const nextId =
      (max(Object.keys(data || {}).map(key => Number(key)) || []) || 0) + 1;

    const nextSortPriority =
      (max(
        Object.values(data || {}).map(
          openItemType => openItemType.sortPriority,
        ) || [],
      ) || 0) + 1;

    const nextIndex = Object.keys(data || {}).length;

    setValue<IRegisterSettings>(
      {
        openItemTypes: {
          ...data,
          [nextId]: {
            ...REGISTER_OPEN_ITEM_TYPE_INITIAL_STATE,
            id: nextId,
            openItemTypeId: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const handleSort = (sortedData: IOpenItemTypesTable[]) => {
    const openItemTypes = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.openItemTypes;

    const openItemTypesCloned = cloneDeep(openItemTypes);
    sortedData.forEach((value, index) => {
      openItemTypesCloned[value.id].sortPriority = index + 1;
    });

    setValue<IRegisterSettings>(
      {
        openItemTypes: openItemTypesCloned,
      },
      SECTION_ID,
    );
  };

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: 'Status',
        cell: info => (
          <Switch
            key={`switch-openItemType-${info.row.original.id}`}
            checked={info.getValue()}
            onChange={isActive => {
              updateOpenItemType(isActive, info.row.original.id, 'active');
            }}
          />
        ),
        size: 70,
      }),

      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => (
          <InputCell
            value={info.getValue()}
            disabled={!info.row.original.canDelete && !isMasterMode}
            required
            placeholder="Name"
            onChange={e =>
              updateName(e.currentTarget.value, info.row.original.id)
            }
            errorId={getOpenItemTypeId(`${info.row.original.id}`, 'name')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 150,
      }),

      columnHelper.accessor('posName', {
        header: 'POS Name',
        cell: info => (
          <InputCell
            value={info.getValue()}
            required
            placeholder="POS Name"
            onChange={e => {
              updateOpenItemType(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              );
            }}
            errorId={getOpenItemTypeId(`${info.row.original.id}`, 'posName')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('prepStationId', {
        header: 'Prep Station',
        cell: info => (
          <Dropdown
            data={[prepStationOptions]}
            placeholder="Prep Station"
            value={info.getValue()}
            onChange={(newPrepStation: number | null) =>
              updateOpenItemType(
                newPrepStation,
                info.row.original.id,
                'prepStationId',
              )
            }
          />
        ),
        minSize: 150,
      }),
      columnHelper.accessor('taxIds', {
        header: 'Taxes',
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              gap: '10px',
              width: '100%',
              alignItems: 'center',
            }}>
            <OptionsModal
              title="Taxes"
              options={Object.keys(taxes)?.map(key => ({
                label: taxes[key]?.name,
                value: taxes[key]?.id,
              }))}
              currentOptionsSelected={info.getValue()?.map(key => ({
                label: taxes[key]?.name,
                value: taxes[key]?.id,
              }))}
              onChangeDone={selectedTaxes => {
                updateOpenItemType(
                  selectedTaxes?.map(tax => tax.value),
                  info.row.original.id,
                  'taxIds',
                );
              }}
              isSearchable
              returnItemOnChange
              activateItemOnSelect={false}
              clearSelectedOptionsOnModalClose={false}
              allowMultipleSelection
              customText="Add Tax"
              buttonProps={{
                icon: <Icon name="MdAdd" />,
                iconPosition: 'left',
                variant: 'primary',
                style: {
                  minWidth: '140px',
                  maxWidth: '140px',
                  justifyContent: 'center',
                },
                disabled: isMasterMode,
              }}
              selectedOptionsLabel="Selected Taxes"
              noOptionsSelectedLabel="No Taxes Selected"
            />
            <Divider csx={{ height: '50px' }} direction="vertical" />
            <Box csx={tagsContainer}>
              {info.getValue().map((taxId, index) => (
                <Tag
                  key={index}
                  onClick={() =>
                    updateOpenItemType(
                      info
                        .getValue()
                        .filter(
                          _taxId => _taxId !== info.row.original.taxIds[index],
                        ),
                      info.row.original.id,
                      'taxIds',
                    )
                  }>
                  {taxes[taxId]?.name}
                </Tag>
              ))}
            </Box>
          </Box>
        ),
        minSize: 440,
      }),
      columnHelper.accessor('canDelete', {
        header: 'Delete',
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}></Button>
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMasterMode, prepStationOptions],
  );

  return (
    <Box>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title="Open Item Types"
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewOpenItemType}>
              Add Open Item Type
            </Button>
          }
        />
      </m.div>

      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          mode={ETableModes.SORT_VERTICALLY}
          data={openItemTypesArray}
          columns={COLUMNS}
          onSortEnd={handleSort}
          cellCsx={{ height: '80px' }}
          alignHeaders={{
            delete: 'center',
          }}
          align={{
            delete: 'center',
          }}
          requiredColumns={['name', 'posName']}
          renderEmptyValues
          rowIdPrefix={ROW_ID_PREFIX}
        />
      </Box>
    </Box>
  );
};

export default OpenItemTypes;
