import { createSelector } from 'reselect';
import { RootState } from '../store';
import {
  EReportsType,
  MenuTypes,
  SettingsTypes,
} from '@westondev/tableturn-core';
import { selectLocationId } from './appSelectors';

export const makeSelectLocationModalMode = () => {
  return createSelector(
    [(state: RootState) => state, (_, variant) => variant],
    (state, variant: 'settings' | 'menu' | 'reports') => {
      if (variant === 'reports') {
        return {
          isMasterMode: state.reports.selectedMode.type === EReportsType.MASTER,
          id: state.reports.selectedMode.id,
          userLocation: selectLocationId(state),
        };
      }

      if (variant === 'settings') {
        return {
          isMasterMode:
            state.settings.selectedMode.settingsType ===
            SettingsTypes.MASTER_SETTINGS,
          id: state.settings.selectedMode.id,
          userLocation: selectLocationId(state),
        };
      }
      return {
        isMasterMode:
          state.menu.selectedMode.menuType === MenuTypes.MASTER_MENU,
        id: state.menu.selectedMode.id,
        userLocation: selectLocationId(state),
      };
    },
  );
};
