import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { btnBaseStyles } from '../styles';
import { useTheme } from '@emotion/react';
import InfoIcon from '../../Icon/custom/InfoIcon';
import { displayFlexRowStyles } from '@app/theme/commonStyles';

interface IInfoConfirmationModal extends IConfirmationModal, WithTranslation {}

const InfoConfirmationModal = ({
  active,
  title,
  onCancel,
  onSuccess,
  btnCommonCsx: btnCommonCsx,
  btnCancelCsx: btnCancelCsx,
  runCancelOnClickOutside,
  size,
  t,
  autoclose = true,
  autoCloseOnCancel = true,
  hideSuccess,
  hideCancel,
  description,
  btnCancelText,
  btnSuccessText,
  btnTerciaryCsx,
  onTerciaryButtonPress,
  btnTerciaryText,
  hideTerciary,
  content,
}: IInfoConfirmationModal) => {
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const theme = useTheme();

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnTerciary = () => {
    if (onTerciaryButtonPress) onTerciaryButtonPress();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
    autoclose && setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  return (
    <Modal
      isActive={active}
      size={size || 420}
      minHeight={220}
      showXCloser={false}
      animationMode="alert"
      title={
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <InfoIcon
            backgroundColor={theme.colors.semanticBlue}
            color={theme.colors.textWhite}
            size="12px"
          />
          <Box>
            <Typography variant="subtitle" fontWeight="medium">
              {title}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        !content ? (
          <>
            {!hideCancel && (
              <Button
                variant="secondary"
                csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
                onClick={handleOnCancel}>
                {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
              </Button>
            )}
            {!hideTerciary && btnTerciaryText && (
              <Button
                csx={[btnBaseStyles, btnCommonCsx, btnTerciaryCsx]}
                onClick={handleOnTerciary}>
                {btnTerciaryText}
              </Button>
            )}
            {!hideSuccess && (
              <Button
                variant="primary"
                csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
                onClick={handleOnModalSuccess}>
                {btnSuccessText ? btnSuccessText : t('commonButtons.ok')}
              </Button>
            )}
          </>
        ) : undefined
      }
      onModalClose={handleOnModalClose}>
      {description && <Typography>{description}</Typography>}
      <Box>{content && content}</Box>
    </Modal>
  );
};

export default InfoConfirmationModal;
