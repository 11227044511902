import { TCsx } from '@emotion/react';
import { Table } from '@tanstack/react-table';
import Box from '../../Box';
import Typography from '../../Typography';
import { tableContainerStyles, tableStyles, tableTitleStyles } from '../styles';
import { ITable } from '../Table';
import { IRenderItem } from '../types';

interface ISelectionTable<T> extends ITable<T> {
  table: Table<T>;
  headerGroups: React.ReactNode;
  renderRow: (item: IRenderItem<T>) => React.ReactNode;
  ItemHeight: number;
  titleContainerCsx?: TCsx;
  titleTextCsx?: TCsx;
}

const SelectionTable = <T,>({
  table,
  headerGroups,
  renderRow,
  noDataComponent,
  noDataPlaceholder,
  bodyCsx: bodyProps,
  showShadow = true,
  title,
  noDataMessage,
  isInverted = false,
  titleContainerCsx,
  titleTextCsx,
  scrollEnabled,
}: ISelectionTable<T>) => {
  return (
    <>
      {title && (
        <Box csx={[tableTitleStyles, titleContainerCsx]}>
          <Typography fontWeight="medium" align="center" csx={titleTextCsx}>
            {title}
          </Typography>
        </Box>
      )}
      <Box csx={tableContainerStyles(showShadow, Boolean(title))}>
        <Box csx={tableStyles(isInverted, scrollEnabled || false)}>
          {headerGroups}
          {table.getRowModel().rows.length > 0 ? (
            <Box
              csx={[
                bodyProps,
                scrollEnabled ? { overflowY: 'auto' } : undefined,
              ]}>
              {table.getRowModel().rows.map(row => {
                return renderRow({
                  index: row.index,
                  row,
                  selectionControls: true,
                  isSelected: row.getIsSelected(),
                });
              })}
            </Box>
          ) : noDataComponent ? (
            noDataComponent
          ) : noDataPlaceholder ? (
            noDataPlaceholder
          ) : (
            <Box
              csx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingBlock: '20px',
              }}>
              <Typography align="center">
                {noDataMessage ?? 'No Data'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SelectionTable;
