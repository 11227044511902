import { createColumnHelper } from '@tanstack/react-table';
import { ITaxesDiff, formatMoney } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import RenderChange from '../../common/RenderChange';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { useMemo } from 'react';
import Table from '@app/components/common/Table';

interface ITaxesTableTypeDiff extends WithTranslation {
  changes: ITaxesDiff[];
}

const TaxesTableTypeDiff = ({ t, changes }: ITaxesTableTypeDiff) => {
  const formatValue = (value: unknown, field: string) => {
    return _formatValue(value, field);
  };

  const columnHelper = createColumnHelper<ITaxesDiff>();

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('changes', {
        id: 'from',
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.from'),
        meta: {
          getCellContext: (context: any) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'from',
                label: '',
                value: formatMoney(
                  null,
                  rowData.changes?.find(item => item.field === 'from')
                    ?.value as number,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={
                rowData.action !== 'remove' &&
                rowData.changes.some(change => change.field === 'from')
              }
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'to',
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.to'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'to',
                label: '',
                value: formatMoney(
                  null,
                  rowData.changes?.find(item => item.field === 'to')
                    ?.value as number,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={
                rowData.action !== 'remove' &&
                rowData.changes.some(change => change.field === 'to')
              }
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'tax',
        header: t('settingsModule.taxesSettings.taxesTable.taxTableType.tax'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'tax',
                label: '',
                value: formatMoney(
                  null,
                  rowData.changes?.find(item => item.field === 'tax')
                    ?.value as number,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={
                rowData.action !== 'remove' &&
                rowData.changes.some(change => change.field === 'tax')
              }
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'repeat',
        header: t(
          'settingsModule.taxesSettings.taxesTable.taxTableType.repeat',
        ),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'repeat',
                label: '',
                value: rowData.changes?.find(item => item.field === 'repeat')
                  ?.value
                  ? t('commonTexts.yes')
                  : t('commonTexts.no'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={
                rowData.action !== 'remove' &&
                rowData.changes.some(change => change.field === 'repeat')
              }
            />
          );
        },
      }),
    ],
    [columnHelper, t],
  );

  if (!changes.length) return null;

  return (
    <Table
      data={changes}
      columns={COLUMNS}
      alignHeaders={{
        from: 'center',
        to: 'center',
        tax: 'center',
        repeat: 'center',
      }}
    />
  );
};

export default TaxesTableTypeDiff;
