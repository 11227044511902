import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import {
  EAccordionSectionOptionType,
  IAccordionSection,
} from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import NoElementsFound from '@app/components/common/NoElementsFound';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import {
  actionCreatorsApp,
  actionCreatorsMenu,
  IModifierGroups,
  IModifierItem,
  modifierGroupsModifierItemFactoryCore,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import NestedModifierGroupCard from './NestedModifierGroupCard';

const sectionId = SectionId.MODIFIER_GROUPS;
const getMemoizedItemData = makeSelectItemData('modifierItems', sectionId);

const NestedModifierGroupsSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  // Redux
  const { itemData: modifierItemData } = useSelector(getMemoizedItemData);

  const { currentMenu, menuType: menuTypeMode } = useSelector(
    (state: RootState) => state.menu.selectedMode,
    shallowEqual,
  );

  const {
    handleDeleteModifierGroupFromModifierItem,
    updateModifierItem: setValue,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());
  const { showToast } = bindActionCreators(actionCreatorsApp, useDispatch());

  // Local state
  const [additionModal, setAdditionModal] = useState(false);

  const modifierGroups = useMemo(
    () => (modifierItemData ? modifierItemData.modifierGroups : {}),
    [modifierItemData],
  );

  const modifierGroupsFormatted = useMemo(
    () =>
      modifierGroupsModifierItemFactoryCore(modifierGroups, store.getState()),
    [modifierGroups],
  );

  const currentModifierGroupsIds = Object.keys(
    Object.values(modifierGroups).reduce((acc, value) => {
      return {
        ...acc,
        [value.modifierGroupId]: value,
      };
    }, {}),
  );

  const handleDeleteModifierGroup = (
    modifierGroupId: number,
    name: string,
    active: boolean,
  ) => {
    handleDeleteModifierGroupFromModifierItem(
      modifierGroupId,
      name,
      active,
      sectionId,
    );
  };

  const handleAddModifierGroup = (modifiersSelected: IItemSelection[]) => {
    if (modifiersSelected && modifiersSelected.length > 0) {
      try {
        // Get sort Priority
        const sortPriority = Object.values(modifierGroups).reduce(
          (acc, modifierGroup) => {
            return acc > modifierGroup.sortPriority
              ? acc
              : modifierGroup.sortPriority;
          },
          0,
        );
        // Generate the structure for the selected modifierGroups
        const newModifierGroups = modifiersSelected.reduce(
          (acc, modifierSelected, index) => {
            const modifierGroupKey = Number(modifierSelected.id);

            const modifierGroup: IModifierGroups =
              currentMenu.modifierGroups[modifierGroupKey];

            const modifierItems = Object.values(
              modifierGroup.modifierItems,
            ).reduce((accItems, item) => {
              const modifierItem: IModifierItem =
                currentMenu.modifierItems[Number(item.id)];

              return {
                ...accItems,
                [Number(modifierItem.modifierItemId)]: {
                  id: 0,
                  locationId: null,
                  createdAt: modifierItem.createdAt,
                  updatedAt: modifierItem.updatedAt,
                  deletedAt: modifierItem.deletedAt,
                  createdByLocationId: modifierItem.createdByLocationId,
                  sortPriority: 0,
                  defaultQuantitySelected: item.defaultQuantitySelected,
                  defaultAmountSelected: item.defaultAmountSelected,
                  requiredModifierItems: item.requiredModifierItems,
                  includedModifierItems: item.includedModifierItems,
                  isSelected: item.isSelected,
                  active: item.active,
                  inheritedActive: item.inheritedActive && item.active,
                  modifierItemId: modifierItem.modifierItemId,
                  modifierGroupModifierItemId: modifierItem.modifierItemId,
                  defaultModifierGroupPricingVersionId:
                    modifierItem.defaultModifierItemPricingVersionId,
                  parent: 'modifierGroup',
                  modifierGroupId: modifierGroupKey,
                  defaultSelectionPrice: 0,
                },
              };
            }, {});

            return {
              ...acc,
              [modifierGroupKey]: {
                id: modifierGroup.id,
                createdAt: modifierGroup.createdAt,
                updatedAt: modifierGroup.updatedAt,
                deletedAt: modifierGroup.deletedAt,
                modifierGroupMenuTypeItemId: 0,
                locationId: modifierGroup.locationId,
                sortPriority: sortPriority + index + 1,
                active: true,
                inheritedActive: modifierGroup.active,
                isSelected: false,
                defaultQuantitySelected: 0,
                defaultAmountSelected: 'regular',
                requiredModifierItems: modifierGroup.requiredModifierItems,
                includedModifierItems: modifierGroup.includedModifierItems,
                defaultModifierGroupPricingVersionId:
                  modifierGroup.defaultModifierGroupPricingVersionId,
                modifierGroupId: modifierGroup.modifierGroupId,
                modifierGroupModifierItemId: modifierGroupKey,
                parent: 'modifierItem',
                defaultSelectionPrice: 0,
                modifierItems,
              },
            };
          },
          {},
        );

        const currentModifiers = modifierGroups;

        setValue(
          {
            modifierGroups: {
              ...currentModifiers,
              ...newModifierGroups,
            },
          },
          sectionId,
        );
      } catch (error) {
        showToast({
          title: 'Error',
          description: 'Error on Mapping Modifier Groups',
          isActive: true,
          type: 'error',
        });
      }
    }
    setAdditionModal(false);
  };
  return (
    <>
      <SelectionModal
        type="modifierGroups"
        detailsScreenProps={{
          currentRelationsIds: currentModifierGroupsIds,
          wantedEntity: 'modifierGroups',
        }}
        active={additionModal}
        onModalClose={() => setAdditionModal(false)}
        onAssociate={handleAddModifierGroup}
        showDescriptionPanel={false}
        showSelectedButtons
      />
      <AccordionSection
        {...props}
        title={t(
          'menuScreen.itemDetails.modifierGroupsSection.nestedModifiers',
        )}
        info={t(
          'menuScreen.itemDetails.modifierGroupsSection.nestedModifierstoolTip',
        )}
        optionType={EAccordionSectionOptionType.ACTION_BUTTON}
        options={[
          {
            text: t(
              'menuScreen.itemDetails.modifierGroupsSection.addModifierGroup',
            ),
            handler: () => setAdditionModal(true),
          },
        ]}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: t('menuScreen.common.addDisabled.modifierGroups'),
          })
        }>
        {modifierGroupsFormatted.length > 0 ? (
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
            {modifierGroupsFormatted.map((modifierGroup, index) => (
              <NestedModifierGroupCard
                key={`modifier_group_${modifierGroup.modifierGroupId}`}
                onDeleteModifierGroupPress={handleDeleteModifierGroup}
                menuTypeVersionId={0}
                isFirst={
                  modifierGroup.sortPriority ===
                  modifierGroupsFormatted[0].sortPriority
                }
                isLast={
                  modifierGroup.sortPriority ===
                  modifierGroupsFormatted[modifierGroupsFormatted.length - 1]
                    .sortPriority
                }
                prevModifierGroupId={
                  index > 0
                    ? modifierGroupsFormatted[index - 1]
                        .modifierGroupModifierItemId
                    : -1
                }
                nextModifierGroupId={
                  index < Object.values(modifierGroups).length - 1
                    ? modifierGroupsFormatted[index + 1]
                        .modifierGroupModifierItemId
                    : -1
                }
                menuTypeMode={menuTypeMode}
                {...modifierGroup}
              />
            ))}
          </Box>
        ) : (
          <NoElementsFound
            text={t(
              'menuScreen.itemDetails.modifierGroupsSection.noModifierGroupsAssignedToThisItem',
            )}
          />
        )}
      </AccordionSection>
    </>
  );
};

export default NestedModifierGroupsSection;
