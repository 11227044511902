import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import { useState } from 'react';

const CheckBoxesExample = () => {
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);

  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchChecked1, setSwitchChecked1] = useState(false);
  return (
    <>
      <Card>
        <Typography
          variant="subtitle"
          fontWeight="bold"
          csx={{ marginBottom: '10px' }}>
          CheckBox
        </Typography>
        <Typography variant="body" csx={{ marginBottom: '10px' }}>
          Simple, disabled and with Label
        </Typography>
        <Grid columnGap={10} rowGap={10}>
          <Grid.Item sm={2} md={4}>
            <Checkbox checked={checked} onChange={setChecked} />
          </Grid.Item>
          <Grid.Item sm={5} md={4}>
            <Checkbox label="Label" checked={checked1} onChange={setChecked1} />
          </Grid.Item>
          <Grid.Item sm={5} md={4}>
            <Checkbox label="Disabled" checked={true} isDisabled />
          </Grid.Item>
        </Grid>
      </Card>
      <Card>
        <Typography
          variant="subtitle"
          fontWeight="bold"
          csx={{ marginBottom: '10px' }}>
          Switch
        </Typography>
        <Typography variant="body" csx={{ marginBottom: '10px' }}>
          Simple, disabled and with Label
        </Typography>
        <Grid columnGap={10} rowGap={10}>
          <Grid.Item sm={2} md={4}>
            <Switch checked={switchChecked} onChange={setSwitchChecked} />
          </Grid.Item>
          <Grid.Item sm={5} md={4}>
            <Switch
              label="Label"
              checked={switchChecked1}
              onChange={setSwitchChecked1}
            />
          </Grid.Item>
          <Grid.Item sm={5} md={4}>
            <Switch label="Disabled" checked={true} isDisabled />
          </Grid.Item>
        </Grid>
      </Card>
    </>
  );
};

export default CheckBoxesExample;
