import {
  FormMode,
  IItem,
  IMassUpdate,
  IMenuTypeVersion,
  SectionId,
} from '@westondev/tableturn-core';

export enum ItemPrepStationSectionKind {
  ADD,
  REMOVE,
}

export interface ISectionData {
  menuTypeVersionId?: number;
  sectionId: SectionId;
  sectionId1?: SectionId;
  itemData?: IMenuTypeVersion | IMassUpdate;
  item?: IItem;
  itemName?: string;
  menuTypeVersions?: {
    [key: number]: IMenuTypeVersion;
  };
  mode?: FormMode;
  kind?: ItemPrepStationSectionKind;
}
