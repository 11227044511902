import Box from '@app/components/common/Box';
import Icon from '../..';
import { IIcon } from '../../Icon';
import { Theme, useTheme } from '@emotion/react';

import { infoIconStyles } from './styes';

interface IInfoIcon extends IIcon {
  outline?: boolean;
  backgroundColor?: string;
}

const InfoIcon = ({
  size,
  color = 'black',
  outline = false,
  backgroundColor,
  ...boxProps
}: IInfoIcon) => {
  const theme = useTheme();
  const colorTheme = theme.colors[color as keyof Theme['colors']] || color;
  return (
    <Box
      className="infoIcon"
      csx={infoIconStyles(theme)(size, outline, colorTheme, backgroundColor)}
      {...boxProps}>
      <Icon name="FaInfo" color={color} />
    </Box>
  );
};

export default InfoIcon;
