export const ROUTE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SETTINGS: '/settings',
};

export const MENU_URLS = {
  '1': 'menus',
  '2': 'categories',
  '3': 'subcategories',
  '4': 'items',
  '5': 'modifiers',
  '6': 'combos',
  '51': 'modifiers/modifier-groups',
  '52': 'modifiers/modifier-items',
  '53': 'modifiers/ingredients',
  '54': 'combos/combos',
  '55': 'combos/item-groups',
  '7': 'specials',
  '8': 'discounts',
  '9': 'reporting-groups',
};

export const SETTINGS_URLS = {
  '1': 'general',
  '11': 'general/general-settings',
  '12': 'general/online-ordering',
  '2': 'accounts',
  '21': 'accounts/users',
  '22': 'accounts/roles',
  '3': 'printing',
  '31': 'printing/prep-stations',
  '32': 'printing/printing-rules',
  '33': 'printing/ticket',
  '331': 'printing/ticket/register-ticket',
  '332': 'printing/ticket/kitchen-ticket',
  '333': 'printing/ticket/guest-receipt',
  '334': 'printing/ticket/guest-check',
  '4': 'management',
  '5': 'lock-screen',
  '6': 'home-screen',
  '7': 'register',
  '8': 'payments',
  '9': 'taxes',
};
