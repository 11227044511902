import LayoutContent from '@app/components/LoggedIn/LoggedInLayout/LayoutContent';
import TableLayoutDetails from '@app/components/LoggedIn/Settings/TableLayout/TableLayoutDetails';

const TableLayout = () => {
  return (
    <LayoutContent noPadding>
      <TableLayoutDetails />
    </LayoutContent>
  );
};

export default TableLayout;
