import Grid from '@app/components/common/Grid';
import SearchInput from '@app/components/common/SearchInput';
import Typography from '@app/components/common/Typography';
import { useState } from 'react';

const SearchInputExample = () => {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  return (
    <>
      <Typography
        variant="body"
        csx={{ marginBottom: '10px', marginTop: '20px' }}>
        Search Inputs
      </Typography>
      <Grid columnGap={10} rowGap={10}>
        <Grid.Item sm={6} md={4}>
          <SearchInput label="Search" value={input1} onChange={setInput1} />
        </Grid.Item>
        <Grid.Item sm={6} md={4}>
          <SearchInput
            label="Debounce Search"
            value={input2}
            onChange={setInput2}
            debounceTimeout={500}
            onDebounce={() => alert('Alert after 500ms!!')}
          />
        </Grid.Item>
      </Grid>
    </>
  );
};

export default SearchInputExample;
