import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  IModifierGroups,
  IModifierGroupsItems,
  IModifierItem,
  MODIFIER_GROUP_ITEMS_OVERRIDE_INITIAL_STATE,
} from '@westondev/tableturn-core';

export const generateNewItemModifierGroup = (
  modifiersSelected: IItemSelection[],
  modifierGroups: { [key: number]: IModifierGroupsItems },
  currentMenuModifierGroups: { [key: number]: IModifierGroups },
  currentMenuModifierItems: { [key: number]: IModifierItem },
  isAllMod = false,
): { [key: number]: IModifierGroupsItems } => {
  if (modifiersSelected && modifiersSelected.length > 0) {
    // Get sort Priority
    const sortPriority = Object.values(modifierGroups).reduce(
      (acc, modifierGroup) => {
        return acc > modifierGroup.sortPriority
          ? acc
          : modifierGroup.sortPriority;
      },
      0,
    );
    // Generate the structure for the selected modifierGroups
    const newModifierGroups = modifiersSelected.reduce(
      (acc, modifierSelected, index) => {
        // Get the modifier group from the current menu
        const modifierGroup: IModifierGroups =
          currentMenuModifierGroups[Number(modifierSelected.id)];

        // Get pricing versions relations
        const defaultModifierGroupPricingVersionId =
          modifierGroup.defaultModifierGroupPricingVersionId;

        const modifierGroupPricingVersions =
          modifierGroup.modifierGroupPricingVersions[
            defaultModifierGroupPricingVersionId
          ]?.modItemPricingVersions;

        // Get the modifier Items and extract the necessary that for the modifierItem
        const modifierItems = Object.values(modifierGroup.modifierItems).reduce(
          (accItems, item) => {
            // modifierGroup.modifierGroupPricingVersions[159].modItemPricingVersions
            // Get the modifier Item data
            const modifierItem: IModifierItem =
              currentMenuModifierItems[Number(item.id)];
            const pricingVersionId = modifierGroupPricingVersions[item.id];
            // Get the default pricing version
            const pricingVersion =
              modifierItem.modifierItemPricingVersions[pricingVersionId];
            // Return the correct structure for the modifierItem
            return {
              ...accItems,
              [Number(modifierItem.modifierItemId)]: {
                id: 0,
                locationId: null,
                createdAt: modifierItem.createdAt,
                updatedAt: modifierItem.updatedAt,
                deletedAt: modifierItem.deletedAt,
                modifierItemPricingVersionId:
                  modifierItem.defaultModifierItemPricingVersionId,
                includedMasterPrice: pricingVersion.includedMasterPrice,
                additionalMasterPrice: pricingVersion.additionalMasterPrice,
                quarterPricePercent: pricingVersion.quarterPricePercent,
                halfPricePercent: pricingVersion.halfPricePercent,
                includedLightPrice: pricingVersion.includedLightPrice,
                includedRegularPrice: pricingVersion.includedRegularPrice,
                includedExtraPrice: pricingVersion.includedExtraPrice,
                additionalLightPrice: pricingVersion.additionalLightPrice,
                additionalRegularPrice: pricingVersion.additionalRegularPrice,
                additionalExtraPrice: pricingVersion.additionalExtraPrice,
                sortPriority: 0,
                defaultQuantitySelected: item.defaultQuantitySelected,
                isSelected: false,
                active: true,
                inheritedActive: item.inheritedActive && item.active,
                defaultAmountSelected: item.defaultAmountSelected,
                modifierItemId: pricingVersion.modifierItemId,
                override: {},
              },
            };
          },
          {},
        );
        // Return the correct structure for the modifierItem
        return {
          ...acc,
          [Number(modifierSelected.id)]: {
            id: modifierGroup.id,
            createdAt: modifierGroup.createdAt,
            updatedAt: modifierGroup.updatedAt,
            deletedAt: modifierGroup.deletedAt,
            modifierGroupMenuTypeItemId: 0,
            locationId: modifierGroup.locationId,
            sortPriority: sortPriority + index + 1,
            active: true,
            inheritedActive: modifierGroup.active,
            override: MODIFIER_GROUP_ITEMS_OVERRIDE_INITIAL_STATE,
            requiredModifierItems: modifierGroup.requiredModifierItems,
            includedModifierItems: modifierGroup.includedModifierItems,
            defaultModifierGroupPricingVersionId:
              modifierGroup.defaultModifierGroupPricingVersionId,
            modifierGroupId: modifierGroup.modifierGroupId,
            modifierItems,
            allowNoneSelection: modifierGroup.allowNoneSelection,
            isAllMod,
          },
        };
      },
      {},
    );
    return newModifierGroups;
  }
  return {};
};
