import {
  getHasChanges,
  setVirtualizedNavigationOnCache,
  setVirtualizedNavigation,
  checkForAllChangesAndNavigate,
  checkForChangesOnRefreshWeb,
} from '@app/state/app/actions';
import { useAppDispatch } from '@app/state/store';
import { useEffect, useRef } from 'react';
import { Location, useBlocker } from 'react-router-dom';
import useRootSelector from './useRootSelector';
import { actionCreatorsMenu } from '@westondev/tableturn-core';
const { setBreadCrumb } = actionCreatorsMenu;

const useBrowserButtons = () => {
  const dispatch = useAppDispatch();
  const blockNext = useRef(false);

  const virtualizedNavigation = useRootSelector(
    state => state.app.virtualizedNavigation,
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation, historyAction }) => {
      // Check if there are changes before navigating and block the navigation
      const hasChanges = dispatch(getHasChanges());
      const isDifferentPath =
        currentLocation.pathname !== nextLocation.pathname;
      if (hasChanges && !nextLocation.state?.omitBlocker)
        return hasChanges && isDifferentPath;

      // Don't allow navigation the the orgId and locationId are not the same or if you are coming from my-organizations
      if (historyAction === 'POP') {
        const currentOrg = location.pathname.split('/')[1];
        const nextOrg = nextLocation.pathname.split('/')[1];
        const currentLoc = location.pathname.split('/')[2];
        const nextLoc = nextLocation.pathname.split('/')[2];
        const comingFromMyOrganizations =
          currentLocation.pathname.includes('my-organizations');
        if (
          currentOrg !== nextOrg ||
          currentLoc !== nextLoc ||
          comingFromMyOrganizations
        ) {
          document.location.reload();
        }
      }
      updateBreadcrumbHistory(currentLocation, nextLocation, historyAction);

      return false;
    },
  );

  useEffect(() => {
    // If the blocker is active, check for changes and navigate if save or discard is clicked
    if (blocker.state === 'blocked') {
      dispatch(
        checkForAllChangesAndNavigate(() => {
          blocker.proceed(); // Unblock the navigation
          updateBreadcrumbHistory(
            // Update the breadcrumb history
            { ...blocker.location, pathname: '' },
            blocker.location,
            'POP',
          );
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);

  const updateBreadcrumbHistory = (
    currentLocation: Location<any>,
    nextLocation: Location<any>,
    historyAction: string,
  ) => {
    // If the breadcrumb is not the same as the next location and the action is POP, update the breadcrumb
    if (
      currentLocation.pathname !== nextLocation.pathname &&
      historyAction === 'POP'
    ) {
      if (!virtualizedNavigation) {
        dispatch(setVirtualizedNavigationOnCache('replace', nextLocation));
      }

      const isPushingExisting =
        virtualizedNavigation.breadcrumb[virtualizedNavigation.index]
          ?.pathURL === nextLocation.pathname &&
        nextLocation.key ===
          virtualizedNavigation.breadcrumb[virtualizedNavigation.index]?.key
          ? true
          : false;

      const isKeyInTempBreadcrumb = virtualizedNavigation.breadcrumb.find(
        item => item.key === nextLocation.key,
      );
      if (!isKeyInTempBreadcrumb) {
        document.location.reload();
      }
      // Add the next element to the breadcrumb and increment the index
      if (isPushingExisting) {
        const lastNavMainIndex = virtualizedNavigation.breadcrumb.findLastIndex(
          (item, index) => {
            return (
              item.action === 'nav-main' && index < virtualizedNavigation.index
            );
          },
        );
        if (lastNavMainIndex !== -1) {
          dispatch(
            setBreadCrumb(
              virtualizedNavigation.breadcrumb.slice(
                lastNavMainIndex,
                virtualizedNavigation.index + 1,
              ),
            ),
          );
        } else {
          dispatch(setBreadCrumb([]));
        }
        dispatch(
          setVirtualizedNavigation({ index: virtualizedNavigation.index + 1 }),
        );
      } else {
        if (blockNext.current) {
          blockNext.current = false;
          return;
        }
        // Remove the last element from the breadcrumb and decrement the index
        const currentIndex = virtualizedNavigation.index - 1;
        const lastNavMainIndex = virtualizedNavigation.breadcrumb.findLastIndex(
          (item, index) => {
            return item.action === 'nav-main' && index < currentIndex;
          },
        );
        if (
          currentIndex < 0 ||
          currentIndex < virtualizedNavigation.initialIndex ||
          (virtualizedNavigation.breadcrumb.length === 0 &&
            virtualizedNavigation.index === 0)
        ) {
          document.location.reload();
        } else {
          if (lastNavMainIndex !== -1) {
            const newBreadcrumb = virtualizedNavigation.breadcrumb.slice(
              lastNavMainIndex,
              currentIndex,
            );

            dispatch(setBreadCrumb(newBreadcrumb));
          } else {
            dispatch(setBreadCrumb([]));
          }
          dispatch(setVirtualizedNavigation({ index: currentIndex }));
        }
      }
    } else if (historyAction === 'PUSH') {
      // Push the next location to the breadcrumb
      if (
        currentLocation.pathname === nextLocation.pathname &&
        nextLocation.state?.blockNext
      ) {
        blockNext.current = true;
        return;
      }

      dispatch(setVirtualizedNavigationOnCache('replace', nextLocation));
    }
  };

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      dispatch(
        checkForChangesOnRefreshWeb(() => {
          e.preventDefault();
          e.returnValue = '';
        }),
      );
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default useBrowserButtons;
