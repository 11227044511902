import { css } from '@emotion/react';

export const collapsibleStyles = () =>
  css({
    overflow: 'hidden',
    height: 0,
  });

export const arrowContainerStyles = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50px',
  });
