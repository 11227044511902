import {
  Children,
  ReactElement,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../Box';
import Button from '../Button';
import Divider from '../Divider';
import Icon from '../Icon';
import Typography from '../Typography';
import { IAccordionSection } from './AccordionSection/types';
import { accordionStyles } from './styles';
import { IAccordion, IAccordionRef } from './types';
import useAccordionStateRestoration from '@app/hooks/useAccordionStateRestoration';

const Accordion = forwardRef<IAccordionRef, IAccordion>(
  (
    {
      onExpandAll,
      onCollapseAll,
      children,
      showExpandCollapseButtons = true,
      getExpandedState,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<(boolean | number)[]>([]);
    const { setAccordionState, accordionState } =
      useAccordionStateRestoration();

    const areaAllExpanded =
      expanded.length > 0 ? expanded.every(exp => exp) : false;
    const areaAllCollapsed = expanded.every(exp => !exp || exp === -1);

    const getInitialExpanded = useCallback(() => {
      const newExpandedArray = Children.map(
        children as ReactElement<IAccordionSection>[],
        child => child as ReactElement<IAccordionSection>,
      );

      const newExpanded = newExpandedArray.map(
        (child: ReactElement<IAccordionSection>) => {
          if (!child) return false;

          // -1 = not expandable
          return child.props.isExpandable !== undefined &&
            child.props.isExpandable === false
            ? -1
            : child.props.isExpanded || false;
        },
      );
      return newExpanded;
    }, [children]);

    useEffect(() => {
      if (expanded.length === 0 && !accordionState.length) {
        setExpanded(getInitialExpanded());
      }
    }, [children, expanded, getInitialExpanded, accordionState]);

    useEffect(() => {
      setExpanded(accordionState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (expanded.length !== 0) {
        setAccordionState(expanded);
      }

      if (getExpandedState) {
        getExpandedState(expanded);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const onToggleExpanded = (index: number) => {
      setExpanded(prev => {
        const nextExpanded = [...prev];
        nextExpanded[index] = !nextExpanded[index];
        return nextExpanded;
      });
    };

    const handleToggleExpandOrCollapseAll = useCallback(
      (expand: boolean) => {
        let newExpanded = Array(Children.count(children));

        if (expand) {
          newExpanded.fill(true);
          onExpandAll && onExpandAll();
        } else {
          newExpanded = getInitialExpanded().map(isExpanded =>
            typeof isExpanded === 'boolean' ? false : isExpanded,
          );
          onCollapseAll && onCollapseAll();
        }
        setExpanded(newExpanded);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [children, expanded],
    );

    useImperativeHandle(ref, () => ({
      expandSection: (index: number) => {
        setExpanded(prev => {
          const nextExpanded = [...prev];
          nextExpanded[index] = true;
          return nextExpanded;
        });
      },
    }));

    return (
      <Box csx={accordionStyles}>
        {showExpandCollapseButtons && (
          <Box className="allSectionsSection">
            <Typography variant="body" csx={{ textTransform: 'uppercase' }}>
              {t('commonTexts.allSections')}
            </Typography>
            <Box className="toggleButtonsContainer">
              <Button
                variant="transparent"
                icon={<Icon name="MdChevronRight" csx={{ rotate: '90deg' }} />}
                iconPosition="right"
                onClick={() => handleToggleExpandOrCollapseAll(true)}
                data-disabled={areaAllExpanded}
                disabled={areaAllExpanded}>
                <span className="buttonContent">
                  {t('commonTexts.expandAll')}
                </span>
              </Button>
              <Divider
                direction="vertical"
                csx={{ marginInline: '10px', height: '40px' }}
              />
              <Button
                variant="transparent"
                icon={<Icon name="MdChevronRight" csx={{ rotate: '-90deg' }} />}
                iconPosition="right"
                onClick={() => handleToggleExpandOrCollapseAll(false)}
                data-disabled={areaAllCollapsed}
                disabled={areaAllCollapsed}>
                <span className="buttonContent">
                  {' '}
                  {t('commonTexts.collapseAll')}
                </span>
              </Button>
            </Box>
          </Box>
        )}
        <Box className="sectionsSection">
          {Children.map(children, (child, index) => {
            return cloneElement(child as React.ReactElement, {
              index,
              key: `accordionSection-${index}`,
              isExpanded: expanded[index],
              onToggleExpanded,
            });
          })}
        </Box>
      </Box>
    );
  },
);

Accordion.displayName = 'Accordion';

export default Accordion;
