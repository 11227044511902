import { selectCategories } from '@app/state/menu/menuSelectors';
import { ICategory, CATEGORIES_INITIAL_STATE } from '@westondev/tableturn-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import Icon from '../../Icon';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';

const CategoriesSelectionModal = ({
  t,
  active,
  onModalClose,
  buttons,
  onAssociate,
  showDescriptionPanel,
  showSelectedButtons,
  scrollCardSectionNumberOfColumns,
  showSelectAll,

  ...others
}: ICustomSelectionModal<unknown>) => {
  //redux
  const categories = useSelector(selectCategories);

  //local
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();

  const handleOnPress = (button: IItemSelection) => {
    const _category = categories[Number(button.id)] || CATEGORIES_INITIAL_STATE;
    setSelectedCategory(_category);
  };

  return (
    <GenericSelectionModal
      {...others}
      onPress={button => handleOnPress(button)}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.categories.title')}
      showDescriptionPanel={showDescriptionPanel}
      showSelectedButtons={showSelectedButtons}
      scrollCardSectionNumberOfColumns={scrollCardSectionNumberOfColumns}
      showSelectAll={showSelectAll}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.categories.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.categories.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.categories.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.categories.noAvailableCardsText',
      )}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}>
      {({ onSelect, isButtonSelected }) => (
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            flexGrow: 1,
          }}>
          <Card.SubCard>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography
                  color="persistentSemanticBlue"
                  fontWeight="medium"
                  csx={{ marginBottom: '10px' }}>
                  {selectedCategory?.name}
                </Typography>
                <Box csx={{ display: 'flex', gap: '10px' }}>
                  <Typography color="darkestGrey">
                    {t('loggedIn.commonFields.basic.posName')}:{' '}
                    {selectedCategory?.posName}
                  </Typography>
                  <Typography color="darkestGrey">
                    ID: {selectedCategory?.id}
                  </Typography>
                </Box>
              </Box>

              <Box csx={{ display: 'flex', flexDirection: 'row' }}>
                {isButtonSelected ? (
                  <Button
                    icon={<Icon name="FaTrashAlt" />}
                    variant="danger"
                    csx={{ paddingRight: '20px', paddingLeft: '20px' }}
                    onClick={() => onSelect()}
                  />
                ) : (
                  <Button
                    onClick={() => onSelect()}
                    csx={{ paddingRight: '20px', paddingLeft: '20px' }}>
                    {t('menuScreen.selectionModal.selectButton')}
                  </Button>
                )}
              </Box>
            </Box>
          </Card.SubCard>
        </Box>
      )}
    </GenericSelectionModal>
  );
};

export default CategoriesSelectionModal;
