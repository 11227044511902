import { TCsx } from '@emotion/react';
import {
  DayOfWeek,
  abbreviateDays,
  selectedDaysOfWeekFactory,
} from '@westondev/tableturn-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import Box from '../Box';
import Button from '../Button';
import Divider from '../Divider';
import { dropDownContainerStyles } from '../Dropdown/styles';
import Grid from '../Grid';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import Typography from '../Typography';

const daysOfTheWeekArray = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
  DayOfWeek.SUNDAY,
];

const SELECTED_DAYS_INITIAL_STATE = {
  [DayOfWeek.MONDAY]: false,
  [DayOfWeek.TUESDAY]: false,
  [DayOfWeek.WEDNESDAY]: false,
  [DayOfWeek.THURSDAY]: false,
  [DayOfWeek.FRIDAY]: false,
  [DayOfWeek.SATURDAY]: false,
  [DayOfWeek.SUNDAY]: false,
};

const dayLabels = {
  [DayOfWeek.MONDAY]: 'Monday',
  [DayOfWeek.TUESDAY]: 'Tuesday',
  [DayOfWeek.WEDNESDAY]: 'Wednesday',
  [DayOfWeek.THURSDAY]: 'Thursday',
  [DayOfWeek.FRIDAY]: 'Friday',
  [DayOfWeek.SATURDAY]: 'Saturday',
  [DayOfWeek.SUNDAY]: 'Sunday',
};

interface IDayOfTheWeekPicker extends WithTranslation {
  isDisabled?: boolean;
  containerCsx?: TCsx;
  selectedDays: string;
  disabledDays?: DayOfWeek[];
  onApply: (selectedDays: Record<DayOfWeek, boolean>) => void;
  children?: (onToggle: () => void) => React.ReactElement;
}

const DayOfTheWeekPicker = ({
  t,
  isDisabled,
  selectedDays: selectedDaysString,
  disabledDays = [],
  onApply,
  children,
  containerCsx,
}: IDayOfTheWeekPicker) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [_selectedDays, _setSelectedDays] = useState(
    SELECTED_DAYS_INITIAL_STATE,
  );

  const selectedDaysLabel = useMemo(() => {
    return abbreviateDays(t, selectedDaysString);
  }, [selectedDaysString, t]);

  const areAllDaysSelected = Object.values(_selectedDays).every(Boolean);

  const handleAllClick = useCallback(() => {
    if (!areAllDaysSelected) {
      _setSelectedDays({
        [DayOfWeek.MONDAY]: true,
        [DayOfWeek.TUESDAY]: true,
        [DayOfWeek.WEDNESDAY]: true,
        [DayOfWeek.THURSDAY]: true,
        [DayOfWeek.FRIDAY]: true,
        [DayOfWeek.SATURDAY]: true,
        [DayOfWeek.SUNDAY]: true,
      });
    } else {
      _setSelectedDays(SELECTED_DAYS_INITIAL_STATE);
    }
  }, [areAllDaysSelected]);

  useEffect(() => {
    _setSelectedDays(selectedDaysOfWeekFactory(selectedDaysString));
  }, [selectedDaysString]);

  useEffect(() => {
    if (!isExpanded) {
      _setSelectedDays(SELECTED_DAYS_INITIAL_STATE);
    }
  }, [isExpanded]);

  return (
    <ToolTip
      isToolTipActive={isExpanded}
      dismissOnContentClick={false}
      triggerCsx={children ? undefined : { width: '100%' }}
      onClickOutside={() => setIsExpanded(false)}
      mode="click"
      direction='top'
      content={
        <Box csx={{ width: '240px' }}>
          <Grid csx={{ gap: '10px' }}>
            {daysOfTheWeekArray.map(day => {
              return (
                <Grid.Item mb={6} key={day}>
                  <DayOfTheWeekButton
                    label={dayLabels[day]}
                    onClick={() =>
                      _setSelectedDays({
                        ..._selectedDays,
                        [day]: !_selectedDays[day],
                      })
                    }
                    isSelected={_selectedDays[day]}
                    disabled={disabledDays.includes(day)}
                  />
                </Grid.Item>
              );
            })}
            <Grid.Item mb={6}>
              <DayOfTheWeekButton
                label="All"
                onClick={handleAllClick}
                isSelected={areAllDaysSelected}
              />
            </Grid.Item>
          </Grid>
          <Divider csx={{ margin: '10px' }} />
          <Button
            variant="secondary"
            onClick={() => {
              setIsExpanded(false);
              onApply(_selectedDays);
            }}>
            Apply
          </Button>
        </Box>
      }>
      {children ? (
        children(() => setIsExpanded(prev => !prev))
      ) : (
        <Box csx={[dropDownContainerStyles, containerCsx]}>
          <Button
            onClick={() => setIsExpanded(true)}
            csx={[
              {
                width: '100%',
                justifyContent: 'space-between',
                paddingInline: '15px',
              },
            ]}
            disabled={isDisabled}
            iconPosition="right"
            icon={
              <Box className="dropDownArrow" csx={{ display: 'flex' }}>
                <Icon
                  name="MdChevronRight"
                  color="black"
                  csx={{ rotate: '90deg' }}
                />
              </Box>
            }
            className={`${isExpanded ? 'active' : 'inactive'} ${
              !isDisabled ? 'noSelected' : 'selected'
            }`}>
            <Box
              csx={{
                overflow: 'hidden',
              }}>
              <Typography
                csx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                {selectedDaysLabel}
              </Typography>
            </Box>
          </Button>
        </Box>
      )}
    </ToolTip>
  );
};

const DayOfTheWeekButton = ({
  label,
  onClick,
  isSelected,
  disabled,
}: {
  label: string;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
}) => {
  return (
    <Button
      variant={isSelected ? 'active' : 'secondary'}
      onClick={onClick}
      className={`${isSelected ? 'active' : 'inactive'} ${
        isSelected ? 'noSelected' : 'selected'
      }`}
      disabled={disabled}>
      {label}
    </Button>
  );
};

export default DayOfTheWeekPicker;
