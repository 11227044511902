import { store } from '@app/state/store';
import {
  CoreRootState,
  IItem,
  freshSheetArrayFactoryCore,
  isShowedInRegisterCore,
} from '@westondev/tableturn-core';

export const isShowedInRegister = (item: IItem) =>
  isShowedInRegisterCore(item, store.getState() as unknown as CoreRootState);

export const freshSheetArrayFactory = (items: { [itemId: number]: IItem }) =>
  freshSheetArrayFactoryCore(
    items,
    store.getState() as unknown as CoreRootState,
  );
