import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const iconStyles = css({
  width: '20px',
  height: '20px',
  [MQ_MIN_SMALL]: {
    width: '24px',
    height: '24px',
  },
});
