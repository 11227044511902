import { css } from '@emotion/react';

export const combosSelectionModalStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '.titleContainer': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });
