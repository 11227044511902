import { Theme, css } from '@emotion/react';

export const tagStyles =
  (width = '100px') =>
  (theme: Theme) =>
    css({
      position: 'relative',
      '.tag': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.semanticBlue}`,
        borderRadius: '30px',
        width,
        height: '50px',
        backgroundColor: theme.colors.lighterBlue,
      },
      '.removeIcon': {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: 'red',
        borderRadius: '50%',
        display: 'flex',
        padding: '1px',
        ':hover': {
          cursor: 'pointer',
        },
      },
    });
