import InventorySection from '@app/components/LoggedIn/Menu/Ingredients/IngredientDetalis/InventorySection';
import AssociationsSection from '@app/components/LoggedIn/Menu/ModifierItems/ModifierItemsDetails/AssociationsSection';
import BasicSection from '@app/components/LoggedIn/Menu/ModifierItems/ModifierItemsDetails/BasicSection';
import PricingSection from '@app/components/LoggedIn/Menu/ModifierItems/ModifierItemsDetails/PricingSection';
import NestedModifierGroupsSection from '@app/components/LoggedIn/Menu/ModifierItems/ModifierItemsDetalis/NestedModifierGroupsSection';
import Accordion from '@app/components/common/Accordion';
import useIsSet from '@app/hooks/useIsSet';
import { actionCreatorsMenuWeb } from '@app/state';
import { actionCreatorsMenu, IModifierItem } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const BUCKET = 'modifierItems';

const ModifierItemsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const modifierGroupId = Number(useLocation().state?.modifierGroupId);

  const { loadMenuBucketChangeData, setActiveStateInBreadcrumbWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const resetChangeData = bindActionCreators(
    actionCreatorsMenu.resetChangeData,
    dispatch,
  );

  const isSet = useIsSet(BUCKET, id === undefined ? undefined : Number(id));

  useEffect(() => {
    if (!isSet) {
      const prefillData: Partial<IModifierItem> = {
        parentModifierGroupIds: modifierGroupId ? [modifierGroupId] : undefined,
      };
      loadMenuBucketChangeData({
        bucket: BUCKET,
        id: Number(id),
        prefillData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id, modifierGroupId]);

  useEffect(() => {
    return () => {
      resetChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSet) return;
    setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'update');
    return () => {
      setActiveStateInBreadcrumbWeb(Number(id), BUCKET, 'reset');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isSet]);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <PricingSection />
        <InventorySection bucket={BUCKET} />
        <NestedModifierGroupsSection />
        <AssociationsSection />
      </Accordion>
    )
  );
};

export default ModifierItemsDetails;
