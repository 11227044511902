import { settingsSelectors } from '@westondev/tableturn-core';
export const {
  specialTagsSelector,
  selectSettingsHasChanges,
  selectSettingsSelectedModeId,
  selectSettingsBreadCrumb,
  selectSettingsChangeData,
  selectIsSettingsCreationMode,
  selectLocationCourses,
  selectAllowOpenTabsOnTableLayout,
  selectShowTimerOnTableLayout,
  selectShowSeatsOnTableLayout,
  selectAllowForTableButton,
  selectEnableDragDropForSeatPopover,
  selectIsShowPriceFreeEnabled,
  selectOpenItems,
  selectDefaultServiceAreaId,
  selectOpenItemTypes,
  selectAllCoursesArray,
  selectActiveCoursesArray,
  selectRegisterRestaurantMode,
  selectRestaurantMode,
  currentGuestReceiptSettingsSelector,
  selectHasLocationOpenItems,
  selectCurrentSettings,
  selectCustomizerShape,
  selectIsShowSendItemsModalEnabled,
  selectCustomizerPartition,
  selectSuppressSeating,
  selectIsSeatingModeEnabled,
  selectSeatingMode,
  selectIsQuantityBar,
  selectIsAutoTriggerEnabled,
  selectIsForTableButtonEnabled,
  selectSettingsBarLocationText,
  selectIsSeatOneDescriptionEnabled,
  selectShowConfirmPopupToRemoveItem,
  selectShowConfirmPopUpOnLeaveIncompleteItem,
  selectShowConfirmPopUpOnClearUnsentWithinAnItem,
  selectShowConfirmPopUpWhenSendingItemsInSeatView,
  selectIsCoursingEnabled,
  selectCoursingModeActive,
  selectQSRSendAndPayActionButtonMode,
  selectIsSendIndividualItemButtonDisabled,
  selectSettingsPlateFee,
  selectOverrideDefaultMenuTypeId,
  selectDefaultMenuTypeTimes,
  selectDefaultMenuTypeId,
  selectDefaultSeatsOnNewOrder,
  selectIsDisplayOrderDetailsOnNewOrderTsr,
  selectIsDisplayOrderDetailsOnNewOrderQsr,
  selectIsDisplayOrderDetailsOnNewOrder,
  selectSelectedLayout,
  selectTicketElementSettings,
  selectIsTicketAutoCollapSeLineItems,
  selectEnforceItemGroupOrderForCombos,
  selectLocationRoles,
  selectLocationGeneralSettings,
  selectCurrentSettingsData,
  selectCurrentSettingsOriginalData,
  selectSettingsDataAndSectionId,
  selectLoggedUserCanDeleteOrders,
  selectLoggedUserCanEditOtherUsersOrders,
  selectLoggedUserCanOverrideLicenseEditingId,
  selectLoggedUserCanTransferOrders,
  selectLoggedUserCanManageTables,
  selectLoggedUserCanChangeMenuType,
  selectLoggedUserBehaviorOnNewOrder,
  createSettingsSectionSelector,
  selectRegisterSettingsAndSectionId,
  selectRegisterDiscountReasons,
  selectSettingsType,
  areSettingsMasterModeSelector,
  selectIsTicketSeatBarSettingEnabled,
  createSettingsErrorSelector,
  makeSelectSettingsData,
  selectIsSendCourseButtonEnabled,
  selectIsCateringAvailable,
  selectIsDeliveryAvailable,
  selectIsDineInAvailable,
  selectIsToGoAvailable,
  orderSeatTagsSelector,
  defaultMenuTypeTimesSelector,
  modifierTagsSelector,
  openItemTypesSelector,
  selectShowModTagBar,
  settingsErrorsSelector,
  devicesSelector,
  licensesSelector,
  printersSelector,
  selectOrderTypesAvailable,
  selectOrderTypesAvailableOnViewTables,
  canSwipeAndDeleteTicketElementSelector,
  settingsDifferencesSelector,
  currentRegisterTicketSettingsSelector,
  currentKitchenTicketSettingsSelector,
  currentPrepStationsSettingsSelector,
  currentPrintingRulesSettingsSelector,
  currentLicenseSpecificPrintingRulesSettingsSelector,
  currentPrintingRulesSettingsModeSelector,
  currentTaxesSettingsSelector,
  currentGeneralSettingsSelector,
  currentLicenseSettingsSettingsSelector,
  currentDevicesSelector,
  currentTabletsSelector,
  currentLicensesSelector,
  actualSettingsVersionSelector,
  selectCurrentTimeZone,
  selectGeneralSettings,
  selectStreetLine1CurrentSettings,
  selectShowSentItemModificationsOnRegisterTicket,
  selectRoles,
  selectRoleCardsList,
  selectSomePrinterHasError,
  selectRegisterDiscountReasonsModalOptions,
  selectLocationTaxes,
  currentPrintersSelector,
  currentTerminalsSelector,
  currentPrepStationsSelector,
  currentPaymentsSettingsSelector,
  selectCurrentSettingsFinalDiffObject,
  selectPrepStationVersionsOfCurrentKitchenTicketSettings,
  selectNumberOfPrepStationVersions,
  selectPrepStationsSettingsForDetailsScreen,
  selectPrinterRulesForDetailsScreen,
  selectLicenseSpecificPrinterRulesForDetailsScreen,
  selectDevicesForDetailsScreen,
  selectLicenseSettingsForDetailsScreen,
  selectLicensesForDetailsScreen,
  selectTerminalsForDetailsScreen,
  adsSelector,
} = settingsSelectors;
