import GenericSelectionModal from '../GenericSelectionModal';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';
import { ICustomSelectionModal } from '../types';

const ModifierItemsSelectionModal = ({
  t,
  active,
  onModalClose,
  buttons,
  onAssociate,
  allowAssociateMultiple,
  ...others
}: ICustomSelectionModal<unknown>) => {
  return (
    <GenericSelectionModal
      {...others}
      onPress={() => null}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      allowAssociateMultiple={allowAssociateMultiple}
      title={t('menuScreen.selectionModal.modifierItems.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.modifierItems.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.modifierItems.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.modifierItems.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.modifierItems.noAvailableCardsText',
      )}
      showDescriptionPanel={false}
      showSelectedButtons={true}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}
    />
  );
};

export default ModifierItemsSelectionModal;
