import Typography from '@app/components/common/Typography';
import { t } from 'i18next';
import { linkWithText } from '../../styles';
import Link from '@app/components/common/Link';
import Box from '@app/components/common/Box';
import { needAssistanceButtonStyles, needAssistanceStyles } from './styles';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { useState } from 'react';
import Modal from '@app/components/common/Modal';

interface INeedAssistance {
  isInline?: boolean;
  isCentered?: boolean;
}

const NeedAssistance = ({ isInline, isCentered }: INeedAssistance) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowNeedHelpModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Box
        csx={needAssistanceStyles}
        className={`needAssistance ${isInline ? 'inline' : ''}`}>
        <Box className="title">
          <Typography variant="caption" fontWeight="medium" color="textWhite">
            {t('authentication.needHelp')}
          </Typography>
        </Box>
        <Box className="links">
          <Box csx={linkWithText}>
            <Link
              to="tel:111-222-333"
              variant="caption"
              color={isInline ? undefined : 'textWhite'}
              underline="hover">
              111-222-333
            </Link>
          </Box>
          <Box className="dot" />
          <Box csx={linkWithText}>
            <Link
              to="mailto:support@tableturnpos.com"
              variant="caption"
              color={isInline ? undefined : 'textWhite'}
              underline="hover">
              support@tableturnpos.com
            </Link>
          </Box>
        </Box>
      </Box>
      {/* Need Help button */}
      <Box
        csx={[
          needAssistanceButtonStyles,
          isCentered ? { display: 'block !important' } : {},
        ]}>
        <Button
          icon={<Icon name="MdHelp" size="30px" color="textWhite" />}
          variant="transparent"
          onClick={handleShowNeedHelpModal}>
          <Typography className="caption" color="textWhite" variant="caption">
            {t('authentication.needHelp')}
          </Typography>
        </Button>
      </Box>
      <Modal
        isActive={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        size="350px">
        <Box>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '15px',
            }}>
            <Box
              csx={{
                borderBottom: '1px solid lightGrey',
                paddingBottom: '15px',
              }}>
              <Typography variant="subtitle" fontWeight="medium" color="black">
                {t('authentication.needHelp')}
              </Typography>
            </Box>
            <Box csx={{ paddingTop: '10px', display: 'flex' }}>
              <Typography
                color={isInline ? undefined : 'black'}
                csx={{ marginRight: '5px' }}>
                {t('commonTexts.phone')}:
              </Typography>{' '}
              <Link
                to="tel:111-222-333"
                color={isInline ? undefined : 'semanticBlue'}
                underline="hover">
                111-222-333
              </Link>
            </Box>
            <Box
              csx={{
                paddingTop: '10px',
                display: 'flex',
                flexWrap: 'wrap',
              }}>
              <Typography
                color={isInline ? undefined : 'black'}
                csx={{ marginRight: '5px' }}>
                {t('commonTexts.email')}:
              </Typography>{' '}
              <Link
                to="mailto:support@tableturnpos.com"
                color={isInline ? undefined : 'semanticBlue'}
                underline="hover">
                support@tableturnpos.com
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NeedAssistance;
