import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { ILocationGroupStore, menuSelectors } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface ILocationsSubsection extends WithTranslation {
  locationIds: number[];
  onAssociate: (roleIds: IItemSelection[]) => void;
  onDisassociate: (id: number) => void;
}

const LocationsSubsection = ({
  t,
  locationIds,
  onAssociate,
  onDisassociate,
}: ILocationsSubsection) => {
  const locationGroups = useSelector(menuSelectors.selectLocations);
  const [openAddExistingLocation, setOpenAddExistingLocation] = useState(false);

  const locations = useMemo(
    () =>
      Object.values(locationGroups)
        .map(locationGroup => (locationGroup as ILocationGroupStore).locations)
        .flat()
        .map(location => ({
          ...location,
          title: location.name,
          description: location.streetLine1,
          id: location.id,
        }))
        .filter(location => locationIds.includes(location.id)),
    [locationGroups, locationIds],
  );

  const handleAssociate = (selectedLocations: IItemSelection[]) => {
    onAssociate(selectedLocations);
    setOpenAddExistingLocation(false);
  };

  return (
    <>
      <SelectionModal
        type="locationsWithRoles"
        btnSuccessText={t('menuScreen.selectionModal.header.options.associate')}
        itemMenuTypeTemplate
        showDescriptionPanel
        scrollCardSectionNumberOfColumns={2}
        active={openAddExistingLocation}
        onModalClose={() => setOpenAddExistingLocation(false)}
        onAssociate={handleAssociate}
      />

      <Card.SubCardOptions
        actionOptions={[
          {
            handler: () => setOpenAddExistingLocation(true),
            text: 'Associate Locations And Roles',
          },
        ]}
        title={t(
          'settingsModule.userDetailsSettings.associations.locationsSubsectionTitle',
        )}
        subtitle={t(
          'settingsModule.userDetailsSettings.associations.locationsSubsectionSubtitle',
        )}>
        <CardsContainer
          data={locations}
          noElementsMessage={t(
            'settingsModule.userDetailsSettings.associations.emptyLocationsMessage',
          )}
          renderItem={location => (
            <Card.Item
              onRemoveClick={() => onDisassociate(location.id)}
              isActive
              title={location.title}
              subTitle={location.description}
            />
          )}
        />
      </Card.SubCardOptions>
    </>
  );
};

export default LocationsSubsection;
