import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import { MQ_MIN_LARGE, MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const forgotPasswordCardStyles = () =>
  css({
    ' .form': {
      '> .formContent': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '51px',
        '> .codeVerifier': {
          marginBottom: `${117 - CAPTION_HEIGHT}px`,
        },
        [MQ_MIN_SMALL]: {
          marginTop: '52px',
          '> .codeVerifier': {
            marginBottom: `${119 - CAPTION_HEIGHT}px`,
          },
        },
      },
    },
    [MQ_MIN_LARGE]: {
      paddingTop: '20px',
    },
  });
