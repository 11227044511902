import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import { INVISIBLE_CHAR, IPricing } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import {
  BASE_TRANSLATION,
  OperationType,
  UpdateType,
} from '../PricingModal/types';
import { massUpdatePriceStyles } from './styles';
import { IMassUpdatePrice } from './types';

const MassUpdatePrice = ({
  t,
  title,
  data,
  onChange,
}: IMassUpdatePrice & WithTranslation) => {
  const isPercent = useMemo(
    () => data?.updateType === UpdateType.PERCENT,
    [data?.updateType],
  );

  const isSet = useMemo(
    () => data?.updateType === UpdateType.SET,
    [data?.updateType],
  );

  const UPDATE_TYPES = useMemo(
    () => [
      [
        {
          label: t(`${BASE_TRANSLATION}.none`),
          value: UpdateType.NONE,
        },
        {
          label: t(`${BASE_TRANSLATION}.percent`),
          value: UpdateType.PERCENT,
        },
        { label: t(`${BASE_TRANSLATION}.fixed`), value: UpdateType.FIXED },
        { label: t(`${BASE_TRANSLATION}.setPrice`), value: UpdateType.SET },
      ],
    ],
    [t],
  );

  const INCREASE_UPDATE_TYPES = useMemo(
    () => [
      [
        {
          label: t(`${BASE_TRANSLATION}.increase`),
          value: OperationType.INCREASE,
        },
        {
          label: t(`${BASE_TRANSLATION}.decrease`),
          value: OperationType.DECREASE,
        },
      ],
    ],
    [t],
  );

  return (
    <Grid csx={massUpdatePriceStyles}>
      <Grid.Item className="header">
        <Typography color="textBlue">{title}</Typography>
      </Grid.Item>
      <Grid.Item className="content">
        <Grid rowGap={20}>
          <Grid.Item>
            <Dropdown
              csx={{
                '& label': {
                  display: 'block',
                },
              }}
              label={t('menuScreen.itemDetails.modalPricingList.updateType')}
              data={UPDATE_TYPES}
              placeholder={t(`${BASE_TRANSLATION}.updateType`)}
              value={data === null ? UpdateType.NONE : data?.updateType}
              onChange={value => {
                if (data === null) {
                  onChange(
                    value,
                    value === UpdateType.SET ? -1 : OperationType.INCREASE,
                    0,
                  );
                  return;
                }
                if (isSet) {
                  onChange(
                    value,
                    value === UpdateType.SET ? -1 : OperationType.INCREASE,
                    value === UpdateType.PERCENT
                      ? Math.trunc(data?.price)
                      : data?.price,
                  );
                  return;
                }
                if (value === UpdateType.PERCENT && !isPercent) {
                  onChange(value, data?.operation, Math.trunc(data?.price));
                  return;
                }
                if (value === UpdateType.SET && !isSet) {
                  onChange(value, -1, data?.price);
                  return;
                }
                onChange(value, data?.operation, data?.price);
              }}
            />
          </Grid.Item>
          <Grid.Item>
            <Dropdown
              csx={{
                '& label': {
                  display: 'block',
                },
              }}
              isDisabled={data === null || data?.updateType === UpdateType.SET}
              label={t(
                'menuScreen.itemDetails.pricingSection.increaseOrDecrease',
              )}
              data={INCREASE_UPDATE_TYPES}
              placeholder={t(
                'menuScreen.itemDetails.pricingSection.increaseOrDecrease',
              )}
              value={data === null ? OperationType.INCREASE : data?.operation}
              onChange={value => {
                onChange(
                  (data as IPricing).updateType,
                  value,
                  (data as IPricing).price,
                );
              }}
            />
          </Grid.Item>
          <Grid.Item>
            <MoneyInput
              disabled={data === null}
              placeholder="0"
              prefix={!isPercent ? '$' : INVISIBLE_CHAR}
              suffix={isPercent ? '%' : INVISIBLE_CHAR}
              precision={isPercent ? 0 : 2}
              maxValue={isPercent ? 100 : undefined}
              value={data === null ? 0 : data?.price}
              label={t(`${BASE_TRANSLATION}.modifyPricesBy`)}
              onValueChange={newPrice => {
                if (isPercent)
                  onChange(
                    (data as IPricing).updateType,
                    (data as IPricing).operation,
                    Math.trunc(newPrice),
                  );
                else {
                  onChange(
                    (data as IPricing).updateType,
                    (data as IPricing).operation,
                    newPrice,
                  );
                }
              }}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

export default MassUpdatePrice;
