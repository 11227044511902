import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Switch from '@app/components/common/Switch';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import PickupAvailabilityTable from './PickupAvailabilityTable';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import { WithTranslation } from 'react-i18next';
import {
  IOnlineOrderingStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';

interface IPickupSettings extends WithTranslation, IAccordionSection {}

const SECTION_ID = SettingsSectionId.PICKUP;
const SECTION_ID_ALL = SettingsSectionId.ALL;
const getMemoizedItemData =
  makeSelectSettingsData<IOnlineOrderingStore>(SECTION_ID);

const PickupSettings = ({ t, ...accordionSectionProps }: IPickupSettings) => {
  // Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const activeSwitch = (
    <Switch
      label={t('settingsModule.onlineSettings.pickup.pickupIsActive')}
      checked={data.pickupIsActive}
      onChange={pickupIsActive => {
        if (!pickupIsActive && !data.deliveryIsActive) {
          return setValue(
            { pickupIsActive, acceptingOrders: false },
            SECTION_ID_ALL,
          );
        }

        setValue({ pickupIsActive }, SECTION_ID_ALL);
      }}
    />
  );

  return (
    <AccordionSection
      title={t('settingsModule.onlineSettings.pickup.title')}
      titleTextContainerCsx={{ minWidth: '170px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...accordionSectionProps}>
      <Grid rowGap={15}>
        <Grid.Item>{activeSwitch}</Grid.Item>
        <Grid.Item>
          <Divider />
        </Grid.Item>
        <Grid.Item md={4}>
          <MoneyInput
            value={data.pickupBufferTime}
            onValueChange={(pickupBufferTime: number) => {
              setValue({ pickupBufferTime }, SECTION_ID);
            }}
            label={t('settingsModule.onlineSettings.pickup.pickupBufferTime')}
            prefix=""
            suffix=" min"
            precision={0}
            info="The time it takes to prepare an order for pick-up"
          />
        </Grid.Item>
        <Grid.Item>
          <PickupAvailabilityTable data={data} />
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default PickupSettings;
