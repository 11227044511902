import {
  selectItems,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import {
  ISubcategory,
  SUBCATEGORY_INITIAL_STATE,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import CardsContainer from '../../CardsContainer';
import Icon from '../../Icon';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';

const SubcategoriesSelectionModal = ({
  t,
  active,
  onModalClose,
  buttons,
  onAssociate,
  showDescriptionPanel,
  showSelectedButtons,
  scrollCardSectionNumberOfColumns,
  showSelectAll,
  ...others
}: ICustomSelectionModal<unknown>) => {
  //redux
  const subcategories = useSelector(selectSubcategories);
  const items = useSelector(selectItems);

  //local
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<ISubcategory>();

  const handleOnPress = (button: IItemSelection) => {
    const _subcategory =
      subcategories[Number(button.id)] || SUBCATEGORY_INITIAL_STATE;
    setSelectedSubcategory(_subcategory);
  };

  const associatedItems = useMemo(
    () => Object.values(items).map(item => ({ title: item.name })),
    [items],
  );

  return (
    <GenericSelectionModal
      {...others}
      onPress={button => handleOnPress(button)}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.subcategories.title')}
      showDescriptionPanel={showDescriptionPanel}
      showSelectedButtons={showSelectedButtons}
      scrollCardSectionNumberOfColumns={scrollCardSectionNumberOfColumns}
      showSelectAll={showSelectAll}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.subcategories.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.subcategories.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.subcategories.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.subcategories.noAvailableCardsText',
      )}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}>
      {({ onSelect, isButtonSelected }) => (
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            flexGrow: 1,
          }}>
          <Card.SubCard>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography
                  color="persistentSemanticBlue"
                  fontWeight="medium"
                  csx={{ marginBottom: '10px' }}>
                  {selectedSubcategory?.name}
                </Typography>
                <Box csx={{ display: 'flex', gap: '10px' }}>
                  <Typography color="darkestGrey">
                    {t('loggedIn.commonFields.basic.posName')}:{' '}
                    {selectedSubcategory?.posName}
                  </Typography>
                  <Typography color="darkestGrey">
                    ID: {selectedSubcategory?.id}
                  </Typography>
                </Box>
              </Box>

              <Box csx={{ display: 'flex', flexDirection: 'row' }}>
                {isButtonSelected ? (
                  <Button
                    icon={<Icon name="FaTrashAlt" />}
                    variant="danger"
                    csx={{ paddingRight: '20px', paddingLeft: '20px' }}
                    onClick={() => onSelect()}
                  />
                ) : (
                  <Button
                    onClick={() => onSelect()}
                    csx={{ paddingRight: '20px', paddingLeft: '20px' }}>
                    {t('menuScreen.selectionModal.selectButton')}
                  </Button>
                )}
              </Box>
            </Box>
          </Card.SubCard>

          <Card.SubCard
            csx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Box
              csx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}>
              <Typography>{t('loggedIn.menuModule.tabs.items')}</Typography>

              <CardsContainer
                csx={{ flexGrow: 1 }}
                data={associatedItems}
                renderItem={item => <Card.Item {...item} />}
              />
            </Box>
          </Card.SubCard>
        </Box>
      )}
    </GenericSelectionModal>
  );
};

export default SubcategoriesSelectionModal;
