import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import { MQ_MIN_LARGE, MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const loginCardStyles = () =>
  css({
    '.form': {
      '> .formContent': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '53px',
        '.passwordSection': {
          position: 'relative',
          marginBottom: `${70 - CAPTION_HEIGHT}px`,
        },
        '.forgotPassword': {
          position: 'absolute',
          top: 0,
          right: 0,
        },
        '> div:nth-of-type(1)': {
          marginBottom: '18px',
        },
        'button[type="submit"]': {
          width: '100%',
        },
        [MQ_MIN_SMALL]: {
          marginTop: '50px',
        },
      },
    },
    [MQ_MIN_LARGE]: {
      paddingTop: '20px',
    },
  });
