import { useState, useMemo, useEffect } from 'react';
import AuthCard from '@app/components/Authentication/AuthCard';
import Typography from '@app/components/common/Typography';
import Box from '@app/components/common/Box';
import Stepper from '@app/components/common/Stepper';
import StepperForm from '@app/components/common/StepperForm';
import VerifyEmailStep from '@app/components/Authentication/SignUp/VerifyEmailStep';
import VerifyPhoneStep from '@app/components/Authentication/SignUp/VerifyPhoneStep';
import { IStep } from '@app/components/common/Stepper/types';
import { apiCall } from '@app/helpers/apiCall';
import { CallMethods } from '@app/constants';
import { apiEndpoints } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { registrationCardStyles } from '@app/views/Authentication/SignUp/styles';
import {
  getUserVerifiedStatus,
  setEmailVerified,
  setPhoneVerified,
} from '@app/helpers/users';
import { IUserDetails } from '@app/state/app/types';
import useUserOrganizations from '@app/hooks/useUserOrganizations';
import NavBar from '@app/components/LoggedIn/NavBar';
import { BroadcastChannel } from 'broadcast-channel';

const Verify = ({ t }: WithTranslation) => {
  const [{ isEmailVerified, isPhoneVerified }, setState] = useState(
    getUserVerifiedStatus(),
  );

  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
  });

  const initialSteps = useMemo(() => {
    return {
      [0]: {
        name: t('authentication.signUp.steps.confirmEmail'),
        active: !isEmailVerified,
        completed: isEmailVerified,
      },
      [1]: {
        name: t('authentication.signUp.steps.confirmPhone'),
        active: isEmailVerified && !isPhoneVerified,
        completed: isPhoneVerified,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const [steps, setSteps] = useState<IStep>(initialSteps);

  const activeStep = useMemo(() => {
    return Object.values(steps).findIndex(step => step.active);
  }, [steps]);

  const areAllCompleted = useMemo(() => {
    return Object.values(steps).some(step => !step.completed) ? false : true;
  }, [steps]);

  const handleContinueVerifyEmail = async (code: string) => {
    return apiCall(CallMethods.POST, apiEndpoints.user.verifyEmail(), false, {
      email: formData.email,
      code,
    }).then(() => {
      setSteps(prev => ({
        ...prev,
        [0]: { ...prev[0], completed: true, active: false },
        [1]: { ...prev[1], active: true },
      }));
      setEmailVerified(true);
    });
  };

  const handleContinueVerifyPhone = async (code: string) => {
    return apiCall(CallMethods.POST, apiEndpoints.user.verifyPhone(), false, {
      phoneNumber: formData.phoneNumber,
      code,
    }).then(() => {
      setSteps(prev => ({
        ...prev,
        [activeStep]: { ...prev[activeStep], completed: true, active: false },
      }));
      setPhoneVerified(true);
      const continuePage = new URLSearchParams(window.location.search).get(
        'continue',
      );
      const broadcastChannel = new BroadcastChannel('auth');
      broadcastChannel.postMessage({
        action: 'login',
        redirect: continuePage || '/my-organizations',
      });
      return Promise.resolve();
    });
  };

  const handleSetEmail = (email: string) => {
    setFormData(prev => ({ ...prev, email }));
  };

  const handleSetPhone = (phoneNumber: string) => {
    setFormData(prev => ({ ...prev, phoneNumber }));
  };

  const orgActions = useMemo(() => {
    return {
      onVerified: () => {
        const continuePage = new URLSearchParams(window.location.search).get(
          'continue',
        );

        const broadcastChannel = new BroadcastChannel('auth');
        broadcastChannel.postMessage({
          action: 'refresh',
          redirect: continuePage || '/my-organizations',
        });
      },
      onNotVerified: (err: {
        token: string;
        isEmailVerified: boolean;
        isPhoneVerified: boolean;
      }) => {
        setState({
          isEmailVerified: err.isEmailVerified,
          isPhoneVerified: err.isPhoneVerified,
        });
      },
    };
  }, []);

  useUserOrganizations(orgActions);

  useEffect(() => {
    const userDetails = JSON.parse(
      localStorage.getItem('userDetails') ?? '{}',
    ) as IUserDetails;
    const _phoneNumber = userDetails.phoneNumber;
    const _email = userDetails.email;
    setFormData({ phoneNumber: _phoneNumber, email: _email });
  }, []);

  if (isEmailVerified === undefined && isPhoneVerified === undefined) {
    return null;
  }

  return (
    <Box
      csx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <NavBar />

      <Box
        csx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <AuthCard
          csx={registrationCardStyles}
          maxHeight="700px"
          width="100%"
          showLogo={false}>
          <Typography
            variant="heading"
            align="center"
            fontWeight="medium"
            className={`title ${areAllCompleted ? 'success' : ''}`}>
            Account Verification
          </Typography>

          <Box className="stepperArea">
            <Stepper steps={steps} clickable={false} />
          </Box>

          <StepperForm activeStep={activeStep}>
            <VerifyEmailStep
              email={formData.email}
              onContinue={handleContinueVerifyEmail}
              setEmail={handleSetEmail}
              resendOnLoad
            />
            <VerifyPhoneStep
              phoneNumber={formData.phoneNumber}
              onContinue={handleContinueVerifyPhone}
              setPhone={handleSetPhone}
              resendOnLoad
            />
          </StepperForm>
        </AuthCard>
      </Box>
    </Box>
  );
};

export default Verify;
