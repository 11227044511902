import { IFontSizes } from './types';

export const colors = {
  //Red
  darkestExtraRed: '#973022',
  darkestRed: '#973022',
  darkerRed: '#B23929',
  darkRed: '#CE422F',
  semanticRed: '#ef4d37',
  persistentSemanticRed: '#ef4d37',
  lightRed: '#f3c5be',
  lighterRed: '#f8dfdc',
  lightestRed: '#fdf1ed',
  //Blue
  darkestExtraBlue: '#1A2364',
  darkestBlue: '#1e3282',
  darkerBlue: '#1e4bb0',
  persistentDarkerBlue: '#1e4bb0',
  darkBlue: '#286AD8',
  semanticBlue: '#348DFC',
  persistentSemanticBlue: '#348DFC',
  lightBlue: '#80A5C9',
  lighterBlue: '#E6F2FF',
  lightestBlue: '#F4F8F9',
  deeperBlue: '#2F669D',
  deepBlue: '#3673b0',
  lightSkyBlue: '#80CEFF',
  //Green
  darkestGreen: '#336717',
  darkerGreen: '#41831d',
  darkGreen: '#5aa34d',
  semanticGreen: '#5dbb29',
  lightGreen: '#b6e78a',
  lighterGreen: '#f6ffeb',
  lightestGreen: '#E0F1E3',
  //Yellow
  darkestYellow: '#BF9626',
  // darkerYellow: '#F1BB29',
  darkerYellow: '#cfb200',
  darkYellow: '#E6C70B',
  semanticYellow: '#F5D92F',
  lightYellow: '#ffffb3',
  //Grey
  darkestGrey: '#454545',
  darkerGrey: '#5b5b5b',
  darkGrey: '#878788',
  semanticGrey: '#a0a0a1',
  persistentSemanticGrey: '#a0a0a1',
  lightGrey: '#C6C6C8',
  lighterGrey: '#E6E6E8',
  lightestGrey: '#F2F2F2',
  lightestExtraGrey: '#F6F6F6',
  black: '#212427',
  white: '#FFFFFF',
  //Orange
  darkOrange: '#CC6020',
  semanticOrange: '#E56C24',
  lightOrange: '#EC9561',
  lighterOrange: '#F5C8AD',
  // Purple
  semanticPurple: '#4A1DCB',
};

const colorsDark = {
  //Red
  darkestRed: colors.darkestExtraRed,
  darkRed: colors.darkRed,
  semanticRed: '#EA8C7F',
  persistentSemanticRed: colors.semanticRed,
  lightRed: colors.lightRed,
  //Blue
  darkerBlue: colors.darkestBlue,
  darkBlue: colors.darkerBlue,
  persistentDarkerBlue: colors.persistentDarkerBlue,
  semanticBlue: colors.darkBlue,
  persistentSemanticBlue: colors.persistentSemanticBlue,
  lightBlue: '#92CCFD',
  lighterBlue: colors.darkestExtraBlue,
  deepBlue: colors.lightBlue,
  deeperBlue: colors.lighterBlue,
  //Green
  darkerGreen: colors.darkestGreen,
  semanticGreen: colors.darkGreen,
  //Yellow
  darkerYellow: '#F1BB29',
  darkYellow: colors.lightYellow,
  semanticYellow: colors.darkYellow,
  lightYellow: colors.semanticYellow,
  // Grey
  darkestGrey: '#8290B4',
  darkerGrey: '#6D7DA7',
  darkGrey: '#5B6B97',
  semanticGrey: '#4E5C82',
  persistentSemanticGrey: colors.semanticGrey,
  lightGrey: '#414D6D',
  lighterGrey: '#343E57',
  lightestGrey: '#1B202D',
  lightestExtraGrey: '#1F2433',
  black: '#FFF',
  white: '#282F42',
  //Orange
  darkOrange: '#CC6020',
  semanticOrange: '#CE7039',
  lightOrange: '#D89973',
  lighterOrange: '#E8BDA4',
};

export const MODES = {
  light: 'light',
  dark: 'dark',
};

export const FONT_WEIGHTS = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

export const FONT_SIZES = {
  heading: '28px',
  subtitle: '20px',
  body: '16px',
  caption: '14px',
  caption2: '13px',
};

export const FONT_SIZES_MOBILE = {
  heading: '23px',
  subtitle: '16px',
  body: '15px',
  caption: '14px',
  caption2: '12px',
};

const SHADOWS = {
  [0.8]: '0px 0px 5px 0px rgba(3, 8, 25, 0.08);',
  [1]: '0px 0px 5px 0px rgba(3, 8, 25, 0.18);',
  [1.1]: '0px 0px 5px 0px rgba(3, 8, 25, 0.25);',
  [2]: '0px 0px 10px 0px rgba(3, 8, 25, 0.18);',
  [3]: '0px 0px 30px 0px rgba(3, 8, 25, 0.18);',
  [4]: '0px 0px 45px 0px rgba(3, 8, 25, 0.18);',
};

export const BREAKPOINTS = {
  mobile: {
    min: '0px',
    max: '575.98px',
  },
  small: {
    min: '576px',
    max: '767.98px',
  },
  medium: {
    min: '768px',
    max: '991.98px',
  },
  large: {
    min: '992px',
    max: '1199.98px',
  },
  xLarge: {
    min: '1200px',
  },
};

export const getFontSizes = (): IFontSizes => {
  const isMobile =
    window.innerWidth <
    parseInt(BREAKPOINTS['small'].min.replace('px', ''), 10);

  if (!isMobile) return FONT_SIZES;

  return {
    heading: FONT_SIZES_MOBILE['heading'],
    subtitle: FONT_SIZES_MOBILE['subtitle'],
    body: FONT_SIZES_MOBILE['body'],
    caption: FONT_SIZES_MOBILE['caption'],
    caption2: FONT_SIZES_MOBILE['caption2'],
  };
};

const LightTheme = {
  colors: {
    ...colors,
    background: colors.lightestExtraGrey,
    cardWhite: colors.white,
    cardGrey: '#F6F6F6',
    text: '#212427',
    textWhite: colors.white,
    textBlack: '#212427',
    textBlue: colors.semanticBlue,
  },
  fontWeights: FONT_WEIGHTS,
  fontSizes: getFontSizes(),
  breakpoints: BREAKPOINTS,
  shadows: SHADOWS,
  mode: 'light',
};

export type BaseTheme = typeof LightTheme;

const DarkTheme = {
  ...LightTheme,
  colors: {
    ...LightTheme.colors,
    ...colorsDark,
    background: colorsDark.lightestExtraGrey,
    cardWhite: colorsDark.semanticGrey,
    cardGrey: colorsDark.semanticGrey,
    text: colors.white,
    textBlue: colorsDark.lightBlue,
  },
  mode: 'dark',
};

export { LightTheme, DarkTheme };
