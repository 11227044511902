import ActionButtons from './ActionButtons';

export enum MenuActionBtnTypes {
  CREATE_NEW_CATEGORY = 'CREATE_NEW_CATEGORY',
  CREATE_NEW_CATEGORY_LOCATION = 'CREATE_NEW_CATEGORY_LOCATION',
  CREATE_NEW_MENU_TYPE = 'CREATE_NEW_MENU_TYPE',
  CREATE_NEW_MENU_TYPE_LOCATION = 'CREATE_NEW_MENU_TYPE_LOCATION',
  LOCATION_OPTIONS = 'LOCATION_OPTIONS',
  ITEM_OPTIONS = 'ITEM_OPTIONS',
  CREATE_NEW_SUBCATEGORY = 'CREATE_NEW_SUBCATEGORY',
  CREATE_NEW_SUBCATEGORY_LOCATION = 'CREATE_NEW_SUBCATEGORY_LOCATION',
  SUBCATEGORY_OR_ITEM = 'SUBCATEGORY_OR_ITEM',
  CREATE_NEW_MODIFIER_ITEM = 'CREATE_NEW_MODIFIER_ITEM',
  CREATE_NEW_MODIFIER_ITEM_LOCATION = 'CREATE_NEW_MODIFIER_ITEM_LOCATION',
  CREATE_NEW_MODIFIER_GROUP = 'CREATE_NEW_MODIFIER_GROUP',
  CREATE_NEW_MODIFIER_GROUP_LOCATION = 'CREATE_NEW_MODIFIER_GROUP_LOCATION',
  CREATE_NEW_ITEM = 'CREATE_NEW_ITEM',
  CREATE_NEW_ITEM_LOCATION = 'CREATE_NEW_ITEM_LOCATION',
  CREATE_NEW_INGREDIENT = 'CREATE_NEW_INGREDIENT',
  CREATE_NEW_INGREDIENT_LOCATION = 'CREATE_NEW_INGREDIENT_LOCATION',
  CREATE_NEW_DISCOUNT = 'CREATE_NEW_DISCOUNT',
  CREATE_NEW_DISCOUNT_LOCATION = 'CREATE_NEW_DISCOUNT_LOCATION',
  CREATE_NEW_COMBO = 'CREATE_NEW_COMBO',
  CREATE_NEW_COMBO_LOCATION = 'CREATE_NEW_COMBO_LOCATION',
  CREATE_NEW_SPECIAL = 'CREATE_NEW_SPECIAL',
  CREATE_NEW_SPECIAL_LOCATION = 'CREATE_NEW_SPECIAL_LOCATION',
  CREATE_NEW_ROLE = 'CREATE_NEW_ROLE',
}

export enum UserActionBtnTypes {
  CREATE_NEW_USER = 'CREATE_NEW_USER',
  CREATE_NEW_USER_LOCATION = 'CREATE_NEW_USER_LOCATION',
}

export const MENU_BASE_TRANSLATION = 'menuScreen.menuLocation.actionButtons';

export const DEFAULT_OPTIONS_LIST: Record<
  MenuActionBtnTypes | UserActionBtnTypes,
  [string, boolean][]
> = {
  [MenuActionBtnTypes.CREATE_NEW_CATEGORY]: [['addNewCategory', true]],
  [MenuActionBtnTypes.CREATE_NEW_CATEGORY_LOCATION]: [
    ['addExistingCategory', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_MENU_TYPE]: [['addNewMenuType', false]],
  [MenuActionBtnTypes.CREATE_NEW_MENU_TYPE_LOCATION]: [
    ['addNewMenuType', false],
    ['addExistingMenuType', false],
  ],
  [MenuActionBtnTypes.LOCATION_OPTIONS]: [['addNewLocation', false]],
  [MenuActionBtnTypes.ITEM_OPTIONS]: [],
  [MenuActionBtnTypes.CREATE_NEW_SUBCATEGORY_LOCATION]: [
    ['addExistingSubcategory', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_SUBCATEGORY]: [['addNewSubcategory', true]],
  [MenuActionBtnTypes.SUBCATEGORY_OR_ITEM]: [
    ['addNewSubcategory', false],
    ['addExistingSubcategory', false],
    ['divider', false],
    ['addNewItem', false],
    ['addExistingItem', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_MODIFIER_ITEM_LOCATION]: [
    ['addNewModifierItem', false],
    ['addExistingModifierItem', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_MODIFIER_ITEM]: [
    ['addNewModifierItem', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_MODIFIER_GROUP_LOCATION]: [
    ['addNewModifierGroup', false],
    ['addExistingModifierGroup', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_MODIFIER_GROUP]: [
    ['addNewModifierGroup', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_ITEM]: [['addNewItem', false]],
  [MenuActionBtnTypes.CREATE_NEW_ITEM_LOCATION]: [['addNewItem', false]],
  [MenuActionBtnTypes.CREATE_NEW_INGREDIENT]: [['addNewIngredient', false]],
  [MenuActionBtnTypes.CREATE_NEW_INGREDIENT_LOCATION]: [
    ['addNewIngredient', false],
    ['addExistingIngredient', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_DISCOUNT]: [['addNewDiscount', false]],
  [MenuActionBtnTypes.CREATE_NEW_DISCOUNT_LOCATION]: [
    ['addNewDiscount', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_COMBO]: [['addNewCombo', false]],
  [MenuActionBtnTypes.CREATE_NEW_COMBO_LOCATION]: [['addNewCombo', false]],
  [MenuActionBtnTypes.CREATE_NEW_SPECIAL]: [['addNewSpecial', false]],
  [MenuActionBtnTypes.CREATE_NEW_SPECIAL_LOCATION]: [['addNewSpecial', false]],
  [UserActionBtnTypes.CREATE_NEW_USER]: [['addNewUser', false]],
  [UserActionBtnTypes.CREATE_NEW_USER_LOCATION]: [
    ['addNewUser', false],
    ['addExistingUser', false],
  ],
  [MenuActionBtnTypes.CREATE_NEW_ROLE]: [['addNewRole', false]],
};

export default ActionButtons;
