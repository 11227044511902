import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import Divider from '@app/components/common/Divider';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { useTranslation } from 'react-i18next';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import {
  createSettingsErrorSelector,
  makeSelectSettingsData,
} from '@app/state/selectors/settingsSelectors';
import {
  IOnlineOrderingStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import OnlineMenuTimesTable from './OnlineMenuTimesTable';
import { actionCreatorsApp } from '@app/state';
import MoneyInput from '@app/components/common/MoneyInput';

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedItemData =
  makeSelectSettingsData<IOnlineOrderingStore>(SECTION_ID);

const errorSelector = createSettingsErrorSelector(SECTION_ID);

interface IBasicSettings extends IAccordionSection {}
const BasicSettings = ({ ...accordionSectionProps }: IBasicSettings) => {
  const sectionHasError = useSelector(errorSelector);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { setShowToast } = bindActionCreators(actionCreatorsApp, useDispatch());

  const { t } = useTranslation();

  const { data } = useSelector(getMemoizedItemData);

  const activeSwitch = (
    <Switch
      label={t('settingsModule.onlineSettings.basic.acceptingOrders')}
      checked={data.acceptingOrders}
      onChange={acceptingOrders => {
        setValue({ acceptingOrders }, SECTION_ID);
      }}
      isDisabled={!data.pickupIsActive && !data.deliveryIsActive}
      onClickDisabled={() => {
        setShowToast({
          title: t(
            'settingsModule.onlineSettings.basic.acceptingOrdersDisabled.title',
          ),
          description: t(
            'settingsModule.onlineSettings.basic.acceptingOrdersDisabled.description',
          ),
          type: 'info',
        });
      }}
    />
  );

  return (
    <AccordionSection
      title={t('loggedIn.commonTitles.basic')}
      titleTextContainerCsx={{ minWidth: '170px' }}
      hasError={sectionHasError}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...accordionSectionProps}>
      <Box>
        <Grid rowGap={15}>
          <Grid.Item>{activeSwitch}</Grid.Item>
          <Grid.Item mb={16}>
            <Divider />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={6} lg={4}>
            <MoneyInput
              label={t('settingsModule.onlineSettings.basic.scheduleOutOrder')}
              prefix=""
              precision={0}
              maxLength={3}
              value={data.scheduleOutOrder}
              onValueChange={scheduleOutOrder => {
                setValue({ scheduleOutOrder }, SECTION_ID);
              }}
            />
          </Grid.Item>
          <Grid.Item mb={16}>
            <OnlineMenuTimesTable data={data} />
          </Grid.Item>
        </Grid>
      </Box>
    </AccordionSection>
  );
};

export default BasicSettings;
