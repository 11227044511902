import { RootState, store } from '@app/state/store';
import { CoreRootState, useRefreshMenu } from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';

const useRefreshMenuWeb = () => {
  const dispatch = useDispatch();
  const onRefresh = useRefreshMenu(
    dispatch,
    store.getState as () => CoreRootState & RootState,
  );

  return onRefresh;
};

export default useRefreshMenuWeb;
