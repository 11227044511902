import { Theme, css } from '@emotion/react';

export const itemContainerStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: '10px',
    padding: '10px',
  });
