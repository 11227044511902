import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import { TCsx } from '@emotion/react';
import { OrderType } from '@westondev/tableturn-core';

const ItemComboTableOrderTypesCell = ({
  orderTypes,
  ...props
}: {
  orderTypes: null | OrderType[];
  csx?: TCsx;
}) => {
  const orderTypesNames = useReportsFilterText('orderTypes', orderTypes);

  return <Typography {...props}>{orderTypesNames}</Typography>;
};

export default ItemComboTableOrderTypesCell;
