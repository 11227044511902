import { MQ_MAX_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';

export const itemMassUpdateModalStyles = css({
  '& .button': {
    width: 230,
    height: 60,
  },
  '& .filters-grid': {
    paddingLeft: '10%',
    paddingRight: '10%',
    [MQ_MAX_MEDIUM]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});
