import { TCsx } from '@emotion/react';
import { Table } from '@tanstack/react-table';
import Box from '../../Box';
import Typography from '../../Typography';
import { tableContainerCardsStyles, tableTitleStyles } from '../styles';
import { ITable } from '../Table';
import { IRenderItem } from '../types';

interface ICardsTable<T> extends ITable<T> {
  table: Table<T>;
  headerGroups: React.ReactNode;
  renderRow: (item: IRenderItem<T>) => React.ReactNode;
  ItemHeight: number;
  titleContainerCsx?: TCsx;
  titleTextCsx?: TCsx;
}

const CardsTable = <T,>({
  table,
  headerGroups,
  renderRow,
  noDataComponent,
  noDataPlaceholder,
  bodyCsx: bodyProps,
  containerCsx,
  showShadow = true,
  title,
  titleContainerCsx,
  titleTextCsx,
}: ICardsTable<T>) => {
  return (
    <>
      {title && (
        <Box csx={[tableTitleStyles, titleContainerCsx]}>
          <Typography fontWeight="medium" align="center" csx={titleTextCsx}>
            {title}
          </Typography>
        </Box>
      )}
      <Box
        csx={[
          tableContainerCardsStyles(showShadow, Boolean(title)),
          containerCsx,
        ]}>
        <span data-hide-on-mobile>{headerGroups}</span>
        <Box>
          {table.getRowModel().rows.length > 0 ? (
            <Box csx={bodyProps}>
              {table.getRowModel().rows.map(row => {
                const headers = table.getHeaderGroups()[0].headers;
                return renderRow({
                  index: row.index,
                  row,
                  headers,
                });
              })}
            </Box>
          ) : noDataComponent ? (
            noDataComponent
          ) : (
            noDataPlaceholder
          )}
        </Box>
      </Box>
    </>
  );
};

export default CardsTable;
