import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import menuTypeAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/menuTypes/menuTypeAssignedItemsFactory';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  BreadCrumbScreens,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedItemsSection extends WithTranslation {
  onAssociate: (itemIds: IItemSelection[]) => void;
  onDisassociate: (id: number | string) => void;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);

const AssignedItemsSection = ({
  onDisassociate,
  onAssociate,
  t,
}: IAssignedItemsSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const items = useSelector(selectItems);
  const isMasterMenu = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const assignedItemIds = itemData.itemIds ?? [];
  const menuTypeId = itemData?.menuTypeId;
  const enableSimpleMode = itemData?.enableSimpleMode;

  const assignedItemsCardList = useMemo(() => {
    return menuTypeAssignedItemsFactory(
      items,
      assignedItemIds,
      menuTypeId,
      (id, text, openInNewTab) => {
        const pathURL = `/menu/items/${id}`;
        if (openInNewTab) return openNewTabWithOrgData(pathURL);

        const _navigate = () =>
          navigate(pathURL, {
            state: { menuTypeId },
          });
        checkForChangesAndNavigateWeb(
          () =>
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.NAV,
              text,
              onPress: _navigate,
              pathURL,
            }),
          onRefresh,
        );
      },
      id => getPathWithOrgData(`/menu/items/${id}`),
      enableSimpleMode,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, assignedItemIds, enableSimpleMode]);

  const closeModal = () => {
    setOpenAddExistingItem(false);
  };

  return (
    <AssignedElements
      type="items"
      detailsScreenProps={{
        currentRelationsIds: assignedItemIds.map(id => `${id}`),
        wantedEntity: 'items',
      }}
      noElementsMessage={t(
        'menuScreen.menuTypeDetails.assignedItems.emptyCardsMessage',
      )}
      active={openAddExistingItem}
      onDisassociate={onDisassociate}
      onAssociate={onAssociate}
      onModalClose={closeModal}
      elements={assignedItemsCardList}
      subCardProps={{
        title: t('menuScreen.menuTypeDetails.assignedItems.subtitle'),
        subtitle: t('menuScreen.menuTypeDetails.assignedItems.label'),
        isEditButtonDisabled: !isMasterMenu && !isCreation,
        actionOptions: [
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewItem'),
            icon: true,
            handler: () =>
              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.ADD,
                    text: t('menuScreen.menuLocation.actionButtons.addNewItem'),
                    screen: BreadCrumbScreens.ITEMS_DETAILS,
                    onPress: () => {
                      navigate(
                        `/menu/items/add?assignedMenuTypeIds=${[menuTypeId]}`,
                      );
                    },
                    pathURL: '/menu/items/add',
                  }),
                onRefresh,
                true,
                false,
                'items',
              ),
          },
          {
            text: t('menuScreen.menuLocation.actionButtons.addExistingItem'),
            icon: false,
            handler: () => setOpenAddExistingItem(true),
          },
        ],
        csx: { borderWidth: 0, padding: 0 },
      }}
    />
  );
};

export default AssignedItemsSection;
