import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { INVALID_DAYS_INITIAL_STATE } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/initialState';
import { sliderContainerStyles } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/styles';
import { IInvalidDays } from '@app/components/LoggedIn/Settings/RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import DisabledBlockLayer from '@app/components/common/DisabledBlockLayer';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import WeekTabs from '@app/components/common/Tabs/WeekTabs';
import { WEEK_TABS_DATA } from '@app/components/common/Tabs/WeekTabs/types';
import Typography from '@app/components/common/Typography';
import { getDayWithErrors } from '@app/helpers/settings/timeBasedMenuTypes';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import { defaultMenuTypeTimesSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DayOfWeek,
  HOUR_MS,
  IOnlineOrderingStore,
  ONLINE_AVAILABILITY_TIME_INITIAL_STATE,
  SettingsSectionId,
  actionCreatorsSettings,
  dateSliderHasErrorValue,
  dayOperationalHours,
  generateTabletGeneratedId,
} from '@westondev/tableturn-core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
interface TDeliveryAvailabilityTable {
  id: number;
  time: [number, number];
  delete: string;
  bannedRanges: Array<[number, number]>;
  ruleId: number | string;
}

const SECTION_ID = SettingsSectionId.DELIVERY;
const MS_8_AM = HOUR_MS * 8;
const MS_12_PM = HOUR_MS * 12;

interface IDeliveryAvailabilityTable extends WithTranslation {
  data: IOnlineOrderingStore;
}
const DeliveryAvailabilityTable = ({ t, data }: IDeliveryAvailabilityTable) => {
  //Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const currentDefaultMenuTypeTimes = useSelector(defaultMenuTypeTimesSelector);

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >({ tabId: 1, subTabId: null });
  const [invalidDays, setInvalidDaysState] = useState<IInvalidDays>(
    INVALID_DAYS_INITIAL_STATE,
  );

  const invalidDaysRef = useRef(invalidDays);
  const columnHelperCustom = createColumnHelper<TDeliveryAvailabilityTable>();

  const selectedDay = useMemo(
    () =>
      activeTab?.tabId
        ? WEEK_TABS_DATA[activeTab?.tabId - 1].dayOfWeek
        : DayOfWeek.MONDAY,
    [activeTab],
  );

  const operationalDayHours = useMemo(() => {
    return data.deliveryAvailability[selectedDay] || [];
  }, [data.deliveryAvailability, selectedDay]);

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('time', {
        header: 'Time',
        cell: info => {
          const bannedRanges = info.row.original.bannedRanges.sort(
            (a, b) => a[0] - b[0],
          );

          const hasError =
            invalidDaysRef?.current?.[selectedDay] &&
            invalidDaysRef.current[selectedDay].includes(
              info.row.original.ruleId,
            );

          return (
            <Box
              key={`slider-time-${info.row.original.id}`}
              csx={theme => sliderContainerStyles(theme, hasError)}>
              <CustomSlider
                step={900000}
                value={info.getValue()}
                min={0}
                max={86399000}
                showStepMarks={false}
                bannedRanges={bannedRanges}
                valueLabelDisplay="always"
                labelDirection="bottom"
                customValueLabel={currentVale => {
                  return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                    .toUTC()
                    .toFormat('hh:mm a');
                }}
                debounceTimeout={200}
                onValueChange={range => {
                  if (!Array.isArray(range)) {
                    return;
                  }

                  updateTime(
                    dateTimeMsToTodayDate(range[0], true),
                    info.row.original.id,
                    'startTime',
                  );
                  updateTime(
                    dateTimeMsToTodayDate(range[1], true),
                    info.row.original.id,
                    'endTime',
                  );
                }}
                renderLimits={value => (
                  <Typography
                    fontWeight="medium"
                    variant="caption"
                    csx={{ whiteSpace: 'nowrap' }}>
                    {DateTime.fromISO(dateTimeMsToTodayDate(value))
                      .toUTC()
                      .toFormat('hh:mm a')}
                  </Typography>
                )}
              />
            </Box>
          );
        },
        minSize: 300,
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                handleDelete(info.row.original.id, info.row.original.ruleId)
              }
            />
          ) as unknown as string,
        size: 50,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedDay],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const setInvalidDays = (
    newInvalidDays:
      | IInvalidDays
      | ((prevInvalidDays: IInvalidDays) => IInvalidDays),
  ) => {
    if (typeof newInvalidDays === 'function') {
      setInvalidDaysState(prevInvalidDays => {
        const newInvalidDaysResult = newInvalidDays(prevInvalidDays);
        invalidDaysRef.current = newInvalidDaysResult;
        return newInvalidDaysResult;
      });
    } else {
      invalidDaysRef.current = newInvalidDays;
      setInvalidDaysState(newInvalidDays);
    }
  };

  const clearInvalidDayById = (ruleId: string | number) => {
    setInvalidDays(prevInvalidDays => ({
      ...prevInvalidDays,
      [selectedDay]: prevInvalidDays[selectedDay].filter(
        invalidDay => invalidDay !== ruleId,
      ),
    }));
  };

  const timesArray: TDeliveryAvailabilityTable[] = useMemo(() => {
    return operationalDayHours
      ? operationalDayHours.map((value, key) => {
          const bannedRanges: Array<[number, number]> = operationalDayHours
            .slice(0, key)
            .map(prevMenuTypes => [
              getHourMs(prevMenuTypes.startTime),
              getHourMs(prevMenuTypes.endTime),
            ]);
          const time: [number, number] = [
            getHourMs(value.startTime),
            getHourMs(value.endTime),
          ];

          const hasError = dateSliderHasErrorValue(time, bannedRanges);
          if (!hasError) {
            clearInvalidDayById(value.id);
          } else if (!invalidDays[selectedDay].includes(value.id)) {
            setInvalidDays(prevInvalidDays => ({
              ...prevInvalidDays,
              [selectedDay]: [...prevInvalidDays[selectedDay], value.id],
            }));
          }
          return {
            id: key,
            time,
            delete: currentDefaultMenuTypeTimes[selectedDay].some(
              menuTypeTimes => menuTypeTimes.id === value.id,
            )
              ? ''
              : 'canDelete',
            bannedRanges,
            ruleId: value.id,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationalDayHours, selectedDay, currentDefaultMenuTypeTimes]);

  const onNewTime = () => {
    const menuTypes = data.deliveryAvailability[selectedDay] || [];
    setValue(
      {
        deliveryAvailability: {
          ...data?.deliveryAvailability,
          [selectedDay]: [
            ...menuTypes,
            {
              ...ONLINE_AVAILABILITY_TIME_INITIAL_STATE,
              id: generateTabletGeneratedId(),
              startTime: dateTimeMsToTodayDate(MS_8_AM, true),
              endTime: dateTimeMsToTodayDate(MS_12_PM, true),
            },
          ],
        },
      },
      SECTION_ID,
    );
  };

  const invalidDaysObject = useMemo(
    () => getDayWithErrors(data?.deliveryAvailability),
    [data?.deliveryAvailability],
  );

  const updateTime = (
    newValue: any,
    id: number,
    field: keyof dayOperationalHours,
  ) => {
    const clonedTimes = cloneDeep(
      store.getState().settings.changeData.data as IOnlineOrderingStore,
    )?.deliveryAvailability;

    clonedTimes[selectedDay][id][field as 'id'] = newValue;
    setValue<IOnlineOrderingStore>(
      {
        deliveryAvailability: clonedTimes,
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number, ruleId: number | string) => {
    const deliveryAvailability = (
      store.getState().settings.changeData.data as IOnlineOrderingStore
    )?.deliveryAvailability;
    const clonedTimes = cloneDeep(deliveryAvailability);
    clonedTimes[selectedDay].splice(id, 1);

    setValue<IOnlineOrderingStore>(
      {
        deliveryAvailability: clonedTimes,
      },
      SECTION_ID,
    );
    clearInvalidDayById(ruleId);
  };

  const onCopyRules = (selectedDays: Record<DayOfWeek, boolean>) => {
    const clonedTimes = cloneDeep(
      (store.getState().settings.changeData.data as IOnlineOrderingStore)
        .deliveryAvailability,
    );
    Object.entries(selectedDays).forEach(([day, value]) => {
      if (day === selectedDay || !value) {
        return;
      }
      clonedTimes[day as 'monday'] = cloneDeep(
        clonedTimes[selectedDay as 'monday'],
      );
    });
    setValue<IOnlineOrderingStore>(
      { deliveryAvailability: clonedTimes },
      SECTION_ID,
    );
  };

  return (
    <Grid columnGap={15} rowGap={15}>
      <Grid.Item mb={12}>
        <Card.SubCard csx={{ paddingInline: 0, overflow: 'hidden' }}>
          <Box
            csx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
              paddingInline: '10px',
            }}>
            <Box
              csx={{
                flex: 1 / 3,
              }}>
              <Checkbox
                label={t(
                  'settingsModule.onlineSettings.delivery.copyPickupTimes',
                )}
                checked={data.copyPickupTimes}
                onChange={copyPickupTimes => {
                  setValue({ copyPickupTimes }, SettingsSectionId.ALL);

                  if (copyPickupTimes) {
                    setValue(
                      { deliveryAvailability: data.pickupAvailability },
                      SECTION_ID,
                    );
                  }
                }}
              />
            </Box>
            <Typography
              fontWeight="medium"
              color="semanticBlue"
              align="center"
              csx={{ flex: 1 / 3, textTransform: 'uppercase' }}>
              {t('settingsModule.onlineSettings.delivery.deliveryAvailability')}
            </Typography>
            <Box csx={{ flex: 1 / 3 }} />
          </Box>
          <Box csx={{ position: 'relative' }}>
            {data.copyPickupTimes && (
              <DisabledBlockLayer csx={{ zIndex: 3 }}>
                <SectionLayer
                  icon={<Fragment />}
                  title={t(
                    'settingsModule.onlineSettings.delivery.deliveryAvailabilitySetToPickupTimes',
                  )}
                />
              </DisabledBlockLayer>
            )}
            <WeekTabs
              activeTab={activeTab}
              onSelectedTabChange={handleOnSelectedTabChange}
              error={invalidDaysObject}
              csx={theme => ({
                borderRadius: 0,
                boxShadow: 'none',
                paddingInline: '5px',
                borderTop: `1px solid ${theme.colors.lighterGrey}`,
              })}
            />

            <Table
              columns={COLUMNS}
              data={timesArray}
              cellCsx={{ height: '100px' }}
              containerCsx={{ border: 'none', borderRadius: 0 }}
              align={{
                delete: 'center',
              }}
              alignHeaders={{
                delete: 'center',
              }}
              showShadow={false}
              noDataComponent={
                <Box
                  csx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBlock: '20px',
                  }}>
                  <Typography align="center">
                    {t(
                      'settingsModule.registerModeSettings.general.timeBasedMenuType.noData',
                    )}
                  </Typography>
                </Box>
              }
              styleForSmallerScreens="card"
              newRowComponent={
                <Box
                  csx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}>
                  <DayOfTheWeekPicker
                    selectedDays=""
                    onApply={onCopyRules}
                    disabledDays={[selectedDay]}>
                    {onToggle => (
                      <Button
                        icon={<Icon name="MdFileCopy" />}
                        csx={{ minWidth: '150px' }}
                        onClick={onToggle}
                        disabled={timesArray.length === 0}>
                        {t(
                          'settingsModule.registerModeSettings.general.timeBasedMenuType.copyRules.button',
                        )}
                      </Button>
                    )}
                  </DayOfTheWeekPicker>
                  <Button
                    onClick={onNewTime}
                    variant="primary"
                    csx={{ minWidth: '150px' }}
                    icon={<Icon name="MdAdd" />}>
                    {t(
                      'loggedIn.settingsModule.generalSettings.hoursOfOperation.addTime',
                    )}
                  </Button>
                </Box>
              }
            />
          </Box>
        </Card.SubCard>
      </Grid.Item>
    </Grid>
  );
};

export default DeliveryAvailabilityTable;
