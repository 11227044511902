import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import SettingsCloneModal from '@app/components/LoggedIn/Settings/SettingsCloneModal';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import ColorPicker from '@app/components/common/ColorPicker';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import ResetButton from '@app/components/common/ResetButton';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  selectIsSettingsCreationMode,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { MQ_MIN_LARGE } from '@app/theme/types';
import { IGenericError } from '@app/types';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  CoreRootState,
  IRoleSettingsStore,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;
const SUB_NAMES = ['posName' as const];

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const BasicSettings = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshSettingsWeb();
  const dispatch = useDispatch();

  //Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const { checkForSettingsChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    dispatch,
  );

  const { data } = useSelector(getMemoizedRoleData);
  const settingsErrors = useSelector(settingsErrorsSelector);
  const isMasterMode = useSelector(
    settingsSelectors.areSettingsMasterModeSelector,
  );
  const isCreationMode = useSelector(selectIsSettingsCreationMode);
  const [activeCloneModal, setActiveCloneModal] = useState(false);

  // Local state
  const theme = useTheme();

  const handleNameChange = (name: string) => {
    const changes: Record<string, string> = {};
    const previousName = data?.name;

    SUB_NAMES.forEach(subName => {
      const value = data?.[subName];
      if (!value || value === previousName) {
        changes[subName] = name;

        if (errorMap[subName]) {
          clearSettingsError(subName);
        }
      }
    });
    setValue({ ...changes, name }, SECTION_ID);
  };

  const hasNameError =
    (settingsErrors?.name?.data as IGenericError)?.message || '';
  const hasPosNameError =
    (settingsErrors?.posName?.data as IGenericError)?.message || '';

  const sectionError = Boolean(hasNameError) || Boolean(hasPosNameError);

  const errorMap = {
    posName: hasPosNameError,
  };

  const textColor = data?.textColor || theme.colors.black;
  const backgroundColor = data?.backgroundColor || theme.colors.cardWhite;

  const onReset = () => {
    setValue<IRoleSettingsStore>(
      { backgroundColor: null, textColor: null },
      SECTION_ID,
    );
  };

  const onResetBackground = () => {
    setValue<IRoleSettingsStore>({ backgroundColor: null }, SECTION_ID);
  };

  const onResetText = () => {
    setValue<IRoleSettingsStore>({ textColor: null }, SECTION_ID);
  };

  const activeSwitch = (
    <Switch
      checked={data ? data.active : false}
      onChange={active => setValue<IRoleSettingsStore>({ active }, SECTION_ID)}
      showDefaultLabel
    />
  );

  return (
    <>
      <SettingsCloneModal
        active={activeCloneModal}
        settings="roles"
        onCancel={() => setActiveCloneModal(false)}
        onClose={() => setActiveCloneModal(false)}
        onClone={newId => {
          checkForSettingsChangesAndNavigateWeb(
            () => {
              navigate(`/settings/accounts/roles/${newId}`);
            },
            () => onRefresh(store.getState() as unknown as CoreRootState),
            navigate,
          );
        }}
        name={data ? data.name : ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        titleTextContainerCsx={{ minWidth: '150px' }}
        hasError={sectionError}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={[
          {
            text: t('commonButtons.clone'),
            handler: () => setActiveCloneModal(true),
          },
        ]}
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid rowGap={20} columnGap={20}>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={[
                displayFlexEndColumnStyles,
                { [MQ_MIN_LARGE]: { paddingBottom: '55px' } },
              ]}>
              <Input
                required
                label={t('menuScreen.commonFields.basic.name')}
                placeholder={t('menuScreen.commonFields.basic.name')}
                value={data?.name || ''}
                error={!!hasNameError}
                caption={hasNameError}
                disabled={!isMasterMode && !isCreationMode}
                showCaptionSpace={Boolean(hasPosNameError)}
                onFocus={() => {
                  hasNameError && clearSettingsError('name');
                }}
                onChange={name => handleNameChange(name.target.value)}
              />
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={[
                displayFlexEndColumnStyles,
                { [MQ_MIN_LARGE]: { paddingBottom: '55px' } },
              ]}>
              <Box csx={{ display: 'flex' }}>
                <Input
                  required
                  label={t('menuScreen.commonFields.basic.posName')}
                  placeholder={t('menuScreen.commonFields.basic.posName')}
                  value={data?.posName || ''}
                  error={!!hasPosNameError}
                  caption={hasPosNameError}
                  showCaptionSpace={Boolean(hasNameError)}
                  onFocus={() =>
                    hasPosNameError && clearSettingsError('posName')
                  }
                  onChange={posName =>
                    setValue<IRoleSettingsStore>(
                      {
                        posName: posName.target.value,
                      },
                      SECTION_ID,
                    )
                  }
                />
                <Divider direction="vertical" csx={{ marginLeft: '15px' }} />
              </Box>
            </Grid.Item>
            <Grid.Item mb={12} sm={12} md={12} lg={6}>
              <DetailsSubtitle noMargin>
                {t(
                  'settingsModule.roleDetailsSettings.basic.displayOptions.title',
                )}
              </DetailsSubtitle>
              <Typography
                variant="caption"
                color="darkestGrey"
                csx={{ marginBottom: '15px', marginTop: '3px' }}>
                {t(
                  'settingsModule.roleDetailsSettings.basic.displayOptions.description',
                )}
              </Typography>
              <Box
                csx={{ display: 'flex', alignItems: 'flex-end', gap: '15px' }}>
                <ColorPicker
                  color={textColor}
                  onChange={newTextColor =>
                    setValue<IRoleSettingsStore>(
                      {
                        textColor: newTextColor,
                      },
                      SECTION_ID,
                    )
                  }
                  label={t('menuScreen.commonFields.basic.textColor')}
                  showHexInput
                  onReset={onResetText}
                />
                <ColorPicker
                  color={backgroundColor}
                  onChange={newBackgroundColor =>
                    setValue<IRoleSettingsStore>(
                      {
                        backgroundColor: newBackgroundColor,
                      },
                      SECTION_ID,
                    )
                  }
                  onReset={onResetBackground}
                  label={t('menuScreen.commonFields.basic.backgroundColor')}
                  showHexInput
                />
                <Box csx={{ paddingBottom: '55px' }}>
                  <Typography csx={{ marginBottom: '8px' }}>
                    {t('commonButtons.reset')}
                  </Typography>
                  <ResetButton onClick={onReset} />
                </Box>
              </Box>
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSettings;
