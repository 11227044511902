import { useEffect } from 'react';

const useOutsideClick = (
  refs: React.RefObject<HTMLDivElement | HTMLButtonElement>[],
  isEnabled: boolean,
  callback: (value: boolean) => void,
) => {
  const handleClickOutside = (event: any) => {
    let totalOfRefsChecked = 0;
    if (!refs) return;
    refs.forEach(ref => {
      if (event.target && ref.current && !ref.current.contains(event.target)) {
        totalOfRefsChecked += 1;
      }
    });
    if (totalOfRefsChecked === refs.length && isEnabled) callback(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);
};

export default useOutsideClick;
