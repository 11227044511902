import { TCsx, Theme, css, useTheme } from '@emotion/react';
import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { linkStyles } from './styles';

interface ILink extends LinkProps, HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  underline?: 'always' | 'hover' | 'none';
  variant?: keyof Theme['fontSizes'];
  color?: keyof Theme['colors'] | (string & NonNullable<unknown>);
  csx?: TCsx;
  css?: never;
}

const Link = ({ children, underline, csx, variant, ...otherProps }: ILink) => {
  const theme = useTheme();
  const mergedStyles = useMemo(() => {
    const textDecoration = css({
      textDecoration: underline === 'always' ? 'underline' : 'none',
      ':hover': {
        textDecoration: underline === 'hover' ? 'underline' : 'none',
      },
      fontSize: theme.fontSizes[variant || 'body'],
      color:
        theme.colors[otherProps.color as keyof Theme['colors']] ||
        otherProps.color ||
        undefined,
    });

    return [linkStyles, textDecoration];
  }, [otherProps.color, theme.colors, theme.fontSizes, underline, variant]);

  return (
    <RouterLink css={[mergedStyles, csx]} {...otherProps}>
      {children}
    </RouterLink>
  );
};

export default Link;
