import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { useState } from 'react';
import Icon from '../Icon';
import Modal from '../Modal';
import Typography from '../Typography';
import { IFilterModal } from './types';

const FilterModal = ({
  t,
  children,
  containerProps,
  isActive,
  onClearAll,
  isClearAllDisabled,
  triggerButtonProps,
}: IFilterModal) => {
  // Local
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box {...containerProps}>
        <Button
          csx={theme => ({
            height: '100%',
            border: `1px solid ${theme.colors.lightGrey}`,
            borderRadius: '5px',
            marginLeft: '10px',
          })}
          variant={isActive || isOpen ? 'active' : undefined}
          onClick={() => setIsOpen(current => !current)}
          icon={
            <Icon
              name="MdFilterAlt"
              color={isActive || isOpen ? 'textWhite' : 'black'}
              size="25px"
            />
          }
          {...triggerButtonProps}
        />
      </Box>
      <Modal size={800} isActive={isOpen} onModalClose={() => setIsOpen(false)}>
        <Box
          csx={theme => ({
            zIndex: 2,
            height: '70px',
            display: 'flex',
            flexDirection: 'row',
            borderWidth: '1px',
            borderColor: theme.colors.lightGrey,
            alignItems: 'center',
            justifyContent: 'center',
          })}>
          <Icon
            name="MdFilterAlt"
            color="darkBlue"
            csx={{
              marginRight: '5px',
            }}
          />
          <Typography
            color="darkBlue"
            variant="heading"
            align="center"
            fontWeight="bold"
            csx={{
              marginLeft: '5px',
              padding: '15px 0px',
            }}>
            {t('viewAllOrdersScreen.modals.filter.title')}
          </Typography>
          {onClearAll && (
            <Button
              color="primaryDark"
              csx={{
                width: '140px',
                right: '20px',
                marginLeft: '10px',
                padding: '15px 0px',
                borderRadius: '5px',
              }}
              onClick={onClearAll}
              disabled={isClearAllDisabled}>
              {t('commonButtons.clearAll')}
            </Button>
          )}
        </Box>
        {children}
      </Modal>
    </>
  );
};

export default FilterModal;
