import { actionCreatorsSettingsWeb } from '@app/state';
import { printersSelector } from '@app/state/selectors/settingsSelectors';
import {
  CreateSettingsBucketRequestType,
  EPrinterType,
  FormMode,
  IPrinter,
  IPrinterDiff,
  printerDropdownOptionsFactory,
  printerModalHelpers,
} from '@westondev/tableturn-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import GridItem from '@app/components/common/Grid/GridItem';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import Typography from '@app/components/common/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Divider from '@app/components/common/Divider';
import OptionsModal from '@app/components/common/OptionsModal';

const {
  printerModelLabelToValue,
  printerTypeToValue,
  printersMakeOptions,
  printerMakeLabelToValue,
  printerMakeValueToLabel,
  printerModelValueToLabel,
  printerModelOptionsByMake,
  paperWidthByModel,
  valueToPrinterType,
  paperWidthValueToLabel,
} = printerModalHelpers;

interface IEditPrinterModal extends WithTranslation {
  active?: boolean;
  isEdit?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  printerData?: IPrinter;
}

type TPrinterDropdownFields =
  | 'make'
  | 'model'
  | 'paperWidth'
  | 'type'
  | 'backupPrinter';

const EditPrinterModal = ({
  t,
  active = false,
  onCancel = () => null,
  onSuccess = () => null,
  printerData,
  isEdit = false,
}: IEditPrinterModal) => {
  const [optionsModalActive, setOptionsModalActive] =
    useState<TPrinterDropdownFields | null>();
  const [printerFormData, setPrinterFormData] = useState({
    name: printerData?.name || '',
    backupPrinterId: printerData?.backupPrinterId || 0,
    macAddress: printerData?.macAddress || '',
    serialNumber: printerData?.serialNumber || '',
    make: printerData?.make || printerModalHelpers.PRINTERS_MAKE.EPSON,
    model: printerData?.model || '',
    paperWidth: printerData?.paperWidth || 0,
    type: printerData?.type || EPrinterType.KITCHEN,
    dpi: printerData?.dpi ? String(printerData?.dpi) : '203',
  });
  const { editSettingsElementWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );
  const printers = useSelector(printersSelector);

  useEffect(() => {
    setPrinterFormData({
      name: printerData?.name || '',
      backupPrinterId: printerData?.backupPrinterId || 0,
      macAddress: printerData?.macAddress || '',
      serialNumber: printerData?.serialNumber || '',
      make: printerData?.make || printerModalHelpers.PRINTERS_MAKE.EPSON,
      model: printerData?.model || '',
      paperWidth: printerData?.paperWidth || 0,
      type: printerData?.type || EPrinterType.KITCHEN,
      dpi: printerData?.dpi ? String(printerData?.dpi) : '203',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const printersOptions = useMemo(
    () => printerDropdownOptionsFactory(printers, printerData?.id || 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printers, printerData, t],
  );

  const modelOptions = useMemo(() => {
    const makeValue = printerMakeLabelToValue[printerFormData.make];
    const modelOptionList = makeValue
      ? printerModelOptionsByMake[makeValue] ?? []
      : [];

    return [modelOptionList];
  }, [printerFormData.make]);

  const paperWidthOptions = useMemo(() => {
    const optionsList = printerFormData.model
      ? paperWidthByModel[
          printerFormData.model as printerModalHelpers.PRINTERS_MODEL
        ] ?? []
      : [];

    return [
      optionsList.map(width => ({
        label: paperWidthValueToLabel[width],
        value: width,
      })),
    ];
  }, [printerFormData.model]);

  const typeOptions = useMemo(
    () => [
      {
        label: t('commonTexts.kitchen'),
        value: printerTypeToValue[EPrinterType.KITCHEN],
      },
      {
        label: t('commonTexts.receipt'),
        value: printerTypeToValue[EPrinterType.RECEIPT],
      },
    ],
    [t],
  );

  const handleOnSuccess = async (isRemove = false) => {
    onCancel();

    const printerChanges = [
      {
        field: 'backupPrinterId',
        label: 'Backup printer',
        value:
          printerFormData.backupPrinterId === 0
            ? null
            : printerFormData.backupPrinterId,
      },
      {
        field: 'name',
        label: 'Name',
        value: printerFormData.name.trim(),
      },
      {
        field: 'serialNumber',
        label: 'Serial number',
        value: printerFormData.serialNumber.trim(),
      },
      {
        field: 'make',
        label: 'Make',
        value: printerFormData.make.trim(),
      },
      {
        field: 'model',
        label: 'Model',
        value: printerFormData.model.trim(),
      },
      {
        field: 'macAddress',
        label: 'Mac address',
        value: printerFormData.macAddress.trim(),
      },
      {
        field: 'type',
        label: 'Type',
        value: printerFormData.type,
      },
      {
        field: 'dpi',
        label: 'DPI',
        value: Number(printerFormData.dpi.trim()),
      },
      {
        field: 'paperWidth',
        label: 'Paper width',
        value: printerFormData.paperWidth,
      },
    ];

    const request: CreateSettingsBucketRequestType = {
      settingsSelected: 'printers',
      id: printerData?.id || 0,
      data: {},
      diffLocationIds: [],
      diff: {
        id: printerData?.id || 0,
        changes: isRemove ? [] : printerChanges,
        action: isRemove ? 'remove' : isEdit ? 'update' : 'add',
      } as IPrinterDiff,
      successMessage: t(
        `settingsModule.devices_licenses.editPrinterModal.${
          isRemove ? 'successRemove' : isEdit ? 'success' : 'successAdd'
        }`,
      ),
    };
    await editSettingsElementWeb(
      () => null,
      () => null,
      false,
      request,
      FormMode.UPDATE,
    );
    onSuccess();
  };

  const handleOnChange = useCallback(
    (field: keyof IPrinter, value: string | number | null) => {
      setPrinterFormData(_printerFormData => ({
        ..._printerFormData,
        [field]: value,
      }));
    },
    [],
  );

  const enableSave = () => {
    return printerFormData.name.trim().length > 0 &&
      printerFormData.serialNumber.trim().length > 0 &&
      printerFormData.macAddress.trim().length > 0 &&
      printerFormData.make.trim().length > 0 &&
      printerFormData.model.trim().length > 0 &&
      Number(printerFormData.dpi.trim())
      ? false
      : true;
  };

  const handlePaperWidthChange = useCallback(
    (newPaperWidth: number) => {
      handleOnChange('paperWidth', newPaperWidth);
    },
    [handleOnChange],
  );

  const handlePrinterModelChange = useCallback(
    (newPrinterModelChange: number) => {
      const newPrinterModel = printerModelValueToLabel[newPrinterModelChange];

      if (!newPrinterModel || printerFormData.model === newPrinterModel) return;

      handleOnChange('model', newPrinterModel);

      const defaultPaperWidth = paperWidthByModel[newPrinterModel]?.[0];

      if (defaultPaperWidth) {
        handlePaperWidthChange(defaultPaperWidth);
      }
    },
    [printerFormData.model, handleOnChange, handlePaperWidthChange],
  );

  const handlePrinterMakeChange = useCallback(
    (newPrinterMakeChange: number) => {
      const newPrinterMake = printerMakeValueToLabel[newPrinterMakeChange];

      if (!newPrinterMake || printerFormData.make === newPrinterMake) return;

      handleOnChange('make', newPrinterMake);

      const defaultModel =
        printerModelOptionsByMake[newPrinterMakeChange]?.[0]?.label;
      if (defaultModel)
        handlePrinterModelChange(printerModelLabelToValue[defaultModel]);
    },
    [printerFormData.make, handleOnChange, handlePrinterModelChange],
  );

  const optionsModalTitle = useMemo(
    (): Record<TPrinterDropdownFields, string> => ({
      make: t('settingsModule.devices_licenses.printersTable.make'),
      model: t('settingsModule.devices_licenses.printersTable.model'),
      paperWidth: t('settingsModule.devices_licenses.printersTable.paperWidth'),
      type: t('settingsModule.devices_licenses.printersTable.type'),
      backupPrinter: t('navBar.common.printerCenter.selectBackupPrinter'),
    }),
    [t],
  );

  const optionsModalOptions = useMemo(
    (): Record<TPrinterDropdownFields, { label: string; value: number }[]> => ({
      make: printersMakeOptions.flat(),
      model: modelOptions.flat(),
      paperWidth: paperWidthOptions.flat(),
      type: typeOptions,
      backupPrinter: printersOptions,
    }),
    [modelOptions, paperWidthOptions, printersOptions, typeOptions],
  );

  const optionsModalOnChange = useMemo(
    () => ({
      make: handlePrinterMakeChange,
      model: handlePrinterModelChange,
      paperWidth: handlePaperWidthChange,
      type: (value: number | null) =>
        value && handleOnChange('type', valueToPrinterType[value]),
      backupPrinter: (value: number | null) =>
        handleOnChange('backupPrinterId', value),
    }),
    [
      handleOnChange,
      handlePaperWidthChange,
      handlePrinterMakeChange,
      handlePrinterModelChange,
    ],
  );

  const closeModal = useCallback(() => {
    setOptionsModalActive(null);
  }, []);

  return (
    <>
      <OptionsModal
        title={optionsModalTitle[optionsModalActive || 'backupPrinter']}
        options={optionsModalOptions[optionsModalActive || 'backupPrinter']}
        onChange={(value: number | null) => {
          if (value) {
            optionsModalOnChange[optionsModalActive || 'backupPrinter'](value);
          }
          closeModal();
        }}
        containerStyle={{
          marginRight: '2px',
        }}
        isActiveByDefault={!!optionsModalActive}
        hideTriggerButton
        onChangeDone={closeModal}
        onCancel={closeModal}
        onOutsidePress={closeModal}
        isSearchable
      />

      <Modal
        size={1200}
        isActive={active}
        title={t(
          `settingsModule.devices_licenses.editPrinterModal.${
            isEdit ? 'title' : 'titleAdd'
          }`,
        )}
        subtitle={
          printerData?.serialNumber ? (
            <Typography variant="caption">
              {`${t(
                'settingsModule.devices_licenses.printersTable.deviceSerial',
              )}: ${printerData.serialNumber}`}
            </Typography>
          ) : undefined
        }
        onModalClose={onCancel}
        footer={
          <Box
            csx={{
              gap: '10px',
              display: 'flex',
            }}>
            <Button onClick={onCancel} csx={{ minWidth: '100px' }}>
              {t('commonButtons.cancel')}
            </Button>
            {isEdit && (
              <Button
                variant="danger"
                onClick={() => handleOnSuccess(true)}
                csx={{ minWidth: '100px' }}>
                {t(
                  'settingsModule.devices_licenses.editPrinterModal.deletePrinter',
                )}
              </Button>
            )}
            <Button
              variant="primary"
              disabled={enableSave()}
              onClick={() => handleOnSuccess()}
              csx={{ minWidth: '100px' }}>
              {t('commonButtons.save')}
            </Button>
          </Box>
        }>
        <Grid columnGap={30} rowGap={20}>
          <GridItem sm={4}>
            <Input
              value={printerFormData.name}
              label={t('commonTexts.name')}
              placeholder={t('commonTexts.name')}
              onChange={_name => handleOnChange('name', _name.target.value)}
            />
          </GridItem>
          <GridItem sm={4}>
            <Input
              value={printerFormData.serialNumber}
              label={t(
                'settingsModule.devices_licenses.printersTable.deviceSerial',
              )}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.deviceSerial',
              )}
              onChange={_serialNumber =>
                handleOnChange('serialNumber', _serialNumber.target.value)
              }
            />
          </GridItem>
          <GridItem sm={4}>
            <Input
              value={printerFormData.macAddress}
              label={t(
                'settingsModule.devices_licenses.printersTable.macAddress',
              )}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.macAddress',
              )}
              onChange={_macAddress =>
                handleOnChange('macAddress', _macAddress.target.value)
              }
            />
          </GridItem>

          <GridItem mb={12}>
            <Divider
              csx={{ paddingBottom: '10px' }}
              lineCsx={{
                borderColor: 'lightGrey',
              }}
            />
          </GridItem>

          <GridItem sm={4}>
            <Dropdown
              label={t('settingsModule.devices_licenses.printersTable.make')}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.make',
              )}
              data={printersMakeOptions}
              value={printerMakeLabelToValue[printerFormData.make]}
              onChange={() => null}
              onActive={() => setOptionsModalActive('make')}
              isEditable={false}
              isDisabled
            />
          </GridItem>
          <GridItem sm={4}>
            <Dropdown
              label={t('settingsModule.devices_licenses.printersTable.model')}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.model',
              )}
              data={modelOptions}
              isDisabled={!modelOptions?.[0]?.length}
              value={printerModelLabelToValue[printerFormData.model] ?? 0}
              onChange={() => null}
              onActive={() => setOptionsModalActive('model')}
              isEditable={false}
            />
          </GridItem>
          <GridItem sm={4}>
            <Dropdown
              label={t(
                'settingsModule.devices_licenses.printersTable.paperWidth',
              )}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.paperWidth',
              )}
              data={paperWidthOptions}
              isDisabled={Number(paperWidthOptions?.[0]?.length) <= 1}
              value={printerFormData.paperWidth ?? 0}
              onChange={() => null}
              onActive={() => setOptionsModalActive('paperWidth')}
              isEditable={false}
            />
          </GridItem>

          <GridItem mb={12}>
            <Divider
              csx={{ paddingBottom: '10px' }}
              lineCsx={{
                borderColor: 'lightGrey',
              }}
            />
          </GridItem>

          <GridItem sm={4}>
            <Dropdown
              label={t('settingsModule.devices_licenses.printersTable.type')}
              placeholder={t(
                'settingsModule.devices_licenses.printersTable.type',
              )}
              data={[typeOptions]}
              value={printerTypeToValue[printerFormData.type]}
              onChange={() => null}
              onActive={() => setOptionsModalActive('type')}
              isEditable={false}
            />
          </GridItem>
          <GridItem sm={4}>
            <Dropdown
              label={t(
                'settingsModule.devices_licenses.printersTable.backupPrinter',
              )}
              placeholder={t('navBar.common.printerCenter.selectBackupPrinter')}
              data={[printersOptions]}
              onChange={() => null}
              value={printerFormData.backupPrinterId || 0}
              onActive={() => setOptionsModalActive('backupPrinter')}
              isEditable={false}
            />
          </GridItem>
        </Grid>
      </Modal>
    </>
  );
};

export default EditPrinterModal;
