import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { ISubCardOptions } from '@app/components/common/Card/SubCardOptions/SubCardOptions';
import CardsContainer from '@app/components/common/CardsContainer';
import DisabledBlockLayer from '@app/components/common/DisabledBlockLayer';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { ISelectionModal } from '@app/components/common/SelectionModal/SelectionModal';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { TCsx } from '@emotion/react';
import { SortOptions, filter, useSort } from '@westondev/tableturn-core';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';

interface IAssignedElements<T> extends WithTranslation {
  type?: ISelectionModal<T>['type'];
  active?: ISelectionModal<T>['active'];
  itemMenuTypeTemplate?: ISelectionModal<T>['itemMenuTypeTemplate'];
  detailsScreenProps?: ISelectionModal<T>['detailsScreenProps'];
  showDescriptionPanel?: ISelectionModal<T>['showDescriptionPanel'];
  showSelectedButtons?: ISelectionModal<T>['showSelectedButtons'];
  filterSelectedItems?: ISelectionModal<T>['filterSelectedItems'];
  groupByMenuType?: ISelectionModal<T>['groupByMenuType'];
  onAssociate?: ISelectionModal<T>['onAssociate'];
  onDisassociate?: (id: number | string) => void;
  elements: IItemCard[];
  subCardProps: ISubCardOptions;
  onModalClose?: ISelectionModal<T>['onModalClose'];
  noElementsMessage?: string;
  noElementsComponent?: ReactElement;
  hideSortButton?: boolean;
  renderItem?: (element: IItemCard, index: number) => JSX.Element;
  filterFunction?: (buttons: IItemSelection[]) => IItemSelection[];
  cardsDisabledLayer?: ReactNode;
  subCardDescription?: React.ReactNode;
  isSortDisabled?: boolean;
  customSort?: (sortOption: SortOptions | null) => void;
  cardsContainerCsx?: TCsx;
  noCardsPadding?: boolean;
  noCardsContainerPadding?: boolean;
  showHeader?: boolean;
  showSubcategoryDropdown?: boolean;
}

const AssignedElements = <T,>({
  onDisassociate,
  onAssociate = () => null,
  t,
  elements,
  subCardProps,
  type,
  active,
  onModalClose = () => null,
  detailsScreenProps,
  noElementsMessage,
  noElementsComponent,
  itemMenuTypeTemplate,
  showDescriptionPanel,
  showSelectedButtons,
  hideSortButton,
  renderItem,
  filterFunction,
  cardsDisabledLayer,
  subCardDescription,
  isSortDisabled,
  customSort,
  filterSelectedItems,
  groupByMenuType,
  cardsContainerCsx,
  noCardsPadding,
  noCardsContainerPadding,
  showHeader,
  showSubcategoryDropdown,
}: IAssignedElements<T>) => {
  const [isEditMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchFilter, setSearchFilter] = useState(inputValue);
  const [sort, setSort] = useState<SortOptions | null>(null);
  const [sortedList, setSortedList] = useState<IItemCard[]>(elements);
  const onSort = useSort<IItemCard>('title');

  useEffect(() => {
    setSortedList(
      filter<IItemCard>(elements, { title: searchFilter }, sort, onSort),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, sort, elements]);

  const handleElementsAssociation = (selectedElements: IItemSelection[]) => {
    onAssociate(selectedElements);
    onModalClose();
  };

  const handleCustomSort = (sortOption: SortOptions | null) => {
    setSort(sortOption);
    customSort && customSort(sortOption);
  };

  return (
    <>
      {type && (
        <SelectionModal
          type={type as ISelectionModal<unknown>['type']}
          showSubcategoryDropdown={showSubcategoryDropdown}
          itemMenuTypeTemplate={itemMenuTypeTemplate}
          detailsScreenProps={detailsScreenProps}
          onAssociate={handleElementsAssociation}
          onModalClose={onModalClose}
          active={active}
          allowAssociateMultiple
          showDescriptionPanel={showDescriptionPanel}
          btnSuccessText={t('commonButtons.associate')}
          showSelectedButtons={showSelectedButtons}
          filterFunction={filterFunction}
          groupByMenuType={groupByMenuType}
          filterSelectedItems={filterSelectedItems}
        />
      )}
      <Card.SubCardOptions
        onEditMode={onDisassociate && elements.length ? setEditMode : undefined}
        noCardsPadding={noCardsContainerPadding}
        showHeader={showHeader}
        searchBarProps={{
          value: inputValue,
          onDebounce: setSearchFilter,
          onSort: hideSortButton
            ? undefined
            : customSort
            ? handleCustomSort
            : sortOption => setSort(sortOption),
          setInputValue: setInputValue,
          sortValue: sort,
          isSortDisabled,
        }}
        {...subCardProps}>
        <Box csx={{ position: 'relative' }}>
          {cardsDisabledLayer && (
            <DisabledBlockLayer>{cardsDisabledLayer}</DisabledBlockLayer>
          )}
          {subCardDescription && subCardDescription}
          {noElementsComponent && sortedList.length === 0 ? (
            <Box csx={[displayFlexRowCenterStyles, { paddingBlock: '10px' }]}>
              {noElementsComponent}
            </Box>
          ) : (
            <CardsContainer
              noCardsPadding={noCardsPadding}
              csx={cardsContainerCsx}
              data={sortedList}
              noElementsMessage={noElementsMessage}
              renderItem={(element, index) =>
                renderItem ? (
                  renderItem(element, index)
                ) : (
                  <Card.Item
                    {...element}
                    showRemoveIcon={isEditMode}
                    onRemoveClick={() =>
                      element.id && onDisassociate?.(element.id)
                    }
                    isLink={element.isLink}
                  />
                )
              }
            />
          )}
        </Box>
      </Card.SubCardOptions>
    </>
  );
};
export default AssignedElements;
