import { IDropdownItemCore } from '@westondev/tableturn-core';
import { IDropdownBase } from '../types';

interface IDropdownExtended extends IDropdownBase {
  value?: number;
  caption?: string;
  showCaptionSpace?: boolean;
  labelVariant?: TLabelVariants;
  showOptionsModal?: boolean;
}

export type TLabelVariants = 'inside' | 'outside';

export enum ELabelVariants {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

interface OnChangeReturnNumber extends IDropdownExtended {
  returnItemOnChange?: false;
  onChange: (value: number) => void;
}
interface OnChangeReturnItem extends IDropdownExtended {
  returnItemOnChange: true;
  onChange: (value: IDropdownItemCore) => void;
}

export type TSingleSelectDropdown = OnChangeReturnNumber | OnChangeReturnItem;
