import GenericSelectionModal from '../GenericSelectionModal';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';
import { ICustomSelectionModal } from '../types';

const ReportingGroupsSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  showDescriptionPanel = true,
  btnSuccessText,
  showSelectedButtons,
  showOnly,
  ...others
}: ICustomSelectionModal<unknown>) => {
  return (
    <GenericSelectionModal
      {...others}
      showOnly={showOnly}
      onPress={() => null}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={
        showOnly
          ? t('menuScreen.selectionModal.reportingGroups.selectedSectionTitle')
          : t('menuScreen.selectionModal.reportingGroups.title')
      }
      selectedSectionTitle={t(
        'menuScreen.selectionModal.reportingGroups.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.reportingGroups.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.reportingGroups.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.reportingGroups.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      btnSuccessText={btnSuccessText}
      showSelectedButtons={showSelectedButtons}
      showCommonFilter
      defaultFilterValue={MenuTypeStatus.active}
    />
  );
};

export default ReportingGroupsSelectionModal;
