import Box from '@app/components/common/Box';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input from '@app/components/common/Input';
import {
  ElementTypes,
  IElement,
  ISelectedElement,
  Shapes,
  TShape,
  actionCreatorsApp,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { useTheme } from '@emotion/react';
import { DEFAULT_ELEMENT_SIZES } from '../../TableLayoutCreator/types';
import { getNewPositionWithRotation } from '@app/helpers/settings/tableLayoutCreator';
import Typography from '@app/components/common/Typography';
import Grid from '@app/components/common/Grid';
import SeatsInput from './SeatsInput';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
const SHAPES = [
  {
    type: Shapes.CIRCLE,
    name: 'Circle',
    shapeStyles: {
      width: 25,
      height: 25,
      borderRadius: 100,
    },
  },
  {
    type: Shapes.SQUARE,
    name: 'Square',
    shapeStyles: {
      width: 25,
      height: 25,
      borderRadius: 5,
    },
  },
  {
    type: Shapes.RECTANGLE,
    name: 'Rectangle',
    shapeStyles: {
      width: 40,
      height: 25,
      borderRadius: 5,
    },
  },
];

interface IDataOptions extends WithTranslation {
  selectedElement: ISelectedElement;
  elementData: IElement;
}

const DataOptions = ({ t, elementData }: IDataOptions) => {
  // Redux
  const updateElementAndSaveLastChange = bindActionCreators(
    actionCreatorsTableLayout.updateElementAndSaveLastChange,
    useDispatch(),
  );
  const showToast = bindActionCreators(
    actionCreatorsApp.showToast,
    useDispatch(),
  );
  // Local state
  const theme = useTheme();
  const [error, setError] = useState<string>('');

  useEffect(() => {
    elementData.name.length === 0 && elementData.type === ElementTypes.TABLE
      ? setError(t('validations.nameRequired'))
      : setError('');
  }, [t, elementData.name, elementData.type]);

  const iconColor = theme.colors.semanticBlue;

  const handleUpdateElementShape = (shape: TShape) => {
    // check shapes and change the size depending on the shape
    let newElement: IElement;
    if (shape === Shapes.CIRCLE || shape === Shapes.SQUARE) {
      newElement = {
        ...elementData,
        shape,
        width:
          elementData.width > elementData.height
            ? elementData.width - (elementData.width - elementData.height)
            : elementData.width,
        height: elementData.height,
        rotation: shape === 'circle' ? 0 : elementData.rotation,
      };
    } else {
      newElement = {
        ...elementData,
        shape,
        width:
          elementData.width === elementData.height
            ? elementData.width +
              DEFAULT_ELEMENT_SIZES['table-rectangle'].growXSize
            : elementData.width,
        height: elementData.height,
      };
    }
    const values = getNewPositionWithRotation(
      newElement,
      newElement.rotation,
      true,
    );
    if (!values) {
      showToast({
        title:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.title',
        description:
          'settingsModule.tableLayoutCreator.messages.elementOverflows.description',
        isActive: true,
        type: 'error',
      });
      return;
    }

    if (values.xCoordinate && values.yCoordinate) {
      newElement.xCoordinate = values.xCoordinate as number;
      newElement.yCoordinate = values.yCoordinate as number;
    }

    updateElementAndSaveLastChange(elementData, newElement);
  };

  const handleUpdateElementData = (values: { [key: string]: unknown }) => {
    updateElementAndSaveLastChange(elementData, {
      ...elementData,
      ...values,
    });
  };

  return (
    <>
      {(elementData.type === ElementTypes.TABLE ||
        elementData.type === ElementTypes.CUSTOM) && (
        <>
          <Grid.Item>
            <Input
              label={t('settingsModule.tableLayoutCreator.sideBar.name')}
              min={0}
              value={elementData.name}
              onChange={e => {
                const newValue = e.currentTarget.value;
                handleUpdateElementData({
                  name: newValue,
                });
              }}
              error={error ? true : false}
              caption={error}
            />
          </Grid.Item>
          {elementData.type !== ElementTypes.CUSTOM && (
            <>
              <Grid.Item>
                <Typography fontWeight="medium">
                  {t(
                    'settingsModule.tableLayoutCreator.sideBar.selectTableType',
                  )}
                </Typography>
                <Box
                  csx={{
                    display: 'flex',
                    marginTop: '10px',
                    gap: '8px',
                  }}>
                  {SHAPES.map(shape => (
                    <div
                      key={shape.type}
                      css={{
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '65px',
                        backgroundColor:
                          elementData.shape === shape.type
                            ? theme.colors.semanticBlue
                            : theme.colors.lighterBlue,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        elementData.shape !== shape.type &&
                        handleUpdateElementShape(shape.type)
                      }>
                      <Box
                        style={shape.shapeStyles}
                        csx={{
                          backgroundColor:
                            elementData.shape === shape.type
                              ? theme.colors.textWhite
                              : iconColor,
                          marginBottom: '8px',
                        }}
                      />
                      <Typography
                        variant="caption2"
                        fontWeight="medium"
                        color={
                          elementData.shape === shape.type
                            ? theme.colors.textWhite
                            : iconColor
                        }>
                        {t(
                          `settingsModule.tableLayoutCreator.bottomSheet.tableShapes.${shape.type}` as ParseKeys,
                        )}
                      </Typography>
                    </div>
                  ))}
                </Box>
              </Grid.Item>
              <SeatsInput elementData={elementData} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default DataOptions;
