import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import Dropdown from '../../Dropdown';
import { TDropDownCell } from './types';
import { useSelector } from 'react-redux';

const DropdownCell = ({ errorsId, onPressError, ...props }: TDropDownCell) => {
  const settingsErrors = useSelector(settingsErrorsSelector);
  const [hasError, errorId] = lookForErrors();

  function lookForErrors(): [boolean, string] {
    let errorFound = false;
    let errorName = '';
    errorsId.forEach(error => {
      if (settingsErrors[error || '']) {
        errorFound = Boolean(settingsErrors[error || '']);
        errorName = error;
      }
    });

    return [errorFound, errorName];
  }

  return (
    <Dropdown
      error={hasError}
      onActive={() => hasError && onPressError && onPressError(errorId)}
      {...props}
    />
  );
};

export default DropdownCell;
