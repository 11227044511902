import { store } from '@app/state/store';
import {
  CoreRootState,
  IMenuStore,
  getDetailsScreenDataCore,
  getLocationsCore,
  getMainScreenDataCore,
} from '@westondev/tableturn-core';

export const getMainScreenData = (
  entity: keyof IMenuStore,
  currentRelationsIds?: string[],
) => {
  return getMainScreenDataCore(
    entity,
    store.getState() as unknown as CoreRootState,
    currentRelationsIds,
  );
};

export const getDetailsScreenData = <T>(
  currentRelationsIds: T[],
  wantedEntity: keyof IMenuStore,
  filterFunction?: (elements: string[]) => string[],
) => {
  return getDetailsScreenDataCore(
    currentRelationsIds,
    wantedEntity,
    store.getState() as unknown as CoreRootState,
    filterFunction,
  );
};

export const getLocations = (
  extraLocationIds: number[] = [],
  isSettingsAccessLocations?: boolean,
) => {
  return getLocationsCore(
    extraLocationIds,
    store.getState() as unknown as CoreRootState,
    isSettingsAccessLocations,
  );
};
