import { getCellSizes } from '@app/helpers/components/tableHelpers';
import { RadioSelectionStates } from '@app/types';
import { CSSObject, SerializedStyles, TCsx } from '@emotion/react';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  FilterFn,
  RowSelectionState,
  SortDirection,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isNil from 'lodash/isNil';
import {
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.config';
import { accordionStyles } from '../Accordion/styles';
import Box from '../Box';
import Button from '../Button';
import { CHECKBOX_SIZE } from '../Checkbox/styles';
import Divider from '../Divider';
import Grid from '../Grid';
import Icon from '../Icon';
import RadioCircle from '../Icon/custom/RadioCircle';
import Typography from '../Typography';
import CardsTable from './CardsTable';
import TableCard from './CardsTable/TableCard';
import DefaultTable from './DefaultTable';
import SelectionTable from './SelectionTable';
import SortableTable from './SortableTable';
import TableRow from './TableRow';
import { boxAlign } from './TableRow/types';
import { headerRowStyles, headerStyles } from './styles';
import { ETableModes, IRenderItem, TTableModes } from './types';

export interface ITable<TData> {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  scrollEnabled?: boolean;
  columnVisibility?: VisibilityState;
  noPadding?: boolean;
  isStriped?: boolean;
  align?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  alignHeaders?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  isInverted?: boolean;
  headerCsx?: TCsx;
  bodyCsx?: TCsx;
  cellCsx?: TCsx;
  headerCellCsx?: TCsx;
  bodyCellCsx?: TCsx;
  listEmptyText?: React.ReactElement;
  newRowComponent?: React.ReactElement;
  loadingComponent?: React.ReactElement | undefined;
  renderEmptyValues?: boolean;
  enableAlphabeticalSorting?: boolean;
  noDataMessage?: string;
  columnSorting?: SortingState;
  onSortPress?: (value: {
    sortDirection: SortDirection | false;
    columnId: string;
  }) => void;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  persistentScrollbar?: boolean;
  nestedScrollEnabled?: boolean;
  noDataPlaceholder?: React.ReactNode;
  showLinesOneEmptyCell?: boolean;
  manualSorting?: boolean;
  alwaysShowSortIcon?: boolean;
  noDataComponent?: React.ReactNode;
  styleForSmallerScreens?: 'card' | 'scroll';
  mode?: TTableModes;
  onSortEnd?: (newData: TData[]) => void;
  showShadow?: boolean;
  rowIdPrefix?: string;
  title?: string;
  onAddClick?: () => void;
  addRowText?: string;
  requiredColumns?: string[];
  enableMultiRowSelection?: boolean;
  containerCsx?: TCsx;
  onRowSelectionChange?: (selectedRows: RowSelectionState) => void;
  customSortingState?: SortingState;
  columnFilters?: ColumnFiltersState;
  filterFns?: Record<string, FilterFn<any>>;
  canExpandAll?: boolean;
}

const Table = <T extends object>(props: ITable<T>) => {
  let { cellCsx: cellProps } = props;
  const {
    data,
    columns,
    columnVisibility,
    noPadding = false,
    isStriped = false,
    align = {},
    alignHeaders = {},
    isInverted = false,
    headerCsx,
    headerCellCsx,
    bodyCellCsx,
    enableAlphabeticalSorting = false,
    onSortPress,
    renderEmptyValues,
    columnSorting,
    manualSorting = true,
    alwaysShowSortIcon = false,
    styleForSmallerScreens = 'scroll',
    mode = ETableModes.BASIC,
    rowIdPrefix,
    title,
    onAddClick,
    addRowText,
    requiredColumns = [],
    enableMultiRowSelection = true,
    onRowSelectionChange,
    newRowComponent,
    customSortingState,
    columnFilters,
    filterFns,
    scrollEnabled,
    canExpandAll,
  } = props;

  const [sorting, setSorting] = useState<SortingState>(columnSorting || []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { t } = useTranslation();

  const canExpand = useMemo(
    () =>
      data.some(
        row =>
          'children' in row &&
          Array.isArray(row.children) &&
          row.children.length > 0,
      ),
    [data],
  );

  const table = useReactTable({
    data,
    columns,
    onExpandedChange: setExpanded,
    // getRowCanExpand: row => true,
    getRowCanExpand: row =>
      'children' in row.original &&
      Array.isArray(row.original.children) &&
      row.original.children.length > 0,
    getSubRows: data.some(row => 'children' in row)
      ? row => (row as T & { children: T[] })?.children
      : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: -1,
      minSize: -1,
      maxSize: -1,
    },
    state: {
      sorting: customSortingState || sorting,
      rowSelection,
      expanded,
      columnFilters,
    },
    initialState: {
      columnVisibility: columnVisibility ? columnVisibility : {},
      sorting: customSortingState || columnSorting,
    },
    filterFns,
    enableSorting: Boolean(customSortingState) || enableAlphabeticalSorting,
    getFilteredRowModel: getFilteredRowModel(),
    manualSorting: manualSorting,
    onSortingChange: setSorting,
    enableRowSelection: mode === ETableModes.SELECTION,
    enableMultiRowSelection,
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    onRowSelectionChange && onRowSelectionChange(rowSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  cellProps = cellProps as CSSObject;
  const ItemHeight =
    cellProps && cellProps.height ? parseInt(cellProps.height as string) : 50;

  const renderRow = useCallback(
    (_item: IRenderItem<T>) => {
      const item = { ..._item, isExpanded: _item.row.getIsExpanded() };
      let bodyCellPropsCopy = bodyCellCsx;

      if (data.length === item.index + 1) {
        bodyCellPropsCopy = {
          ...(bodyCellCsx as SerializedStyles),
          borderBottomWidth: 0,
        };
      }
      const currentRow = item.row.getAllCells()[0].getContext();
      const hasMeta = item.row.getAllCells()[0].getContext().cell.column
        .columnDef.meta;
      const showRemoved = hasMeta
        ? hasMeta.getCellContext(currentRow).removed
        : false;

      if (styleForSmallerScreens === 'card')
        return (
          <TableCard
            key={item.row.id}
            cellCsx={cellProps}
            item={item}
            noPadding={noPadding}
            isStriped={isStriped}
            itemHeight={ItemHeight}
            renderEmptyValues={renderEmptyValues}
            align={align}
            columns={columns}
            bodyCellCsx={bodyCellPropsCopy}
            showRemoved={showRemoved}
          />
        );
      return (
        <TableRow
          key={item.row.id}
          cellProps={cellProps}
          item={item}
          noPadding={noPadding}
          isStriped={isStriped}
          itemHeight={ItemHeight}
          renderEmptyValues={renderEmptyValues}
          align={align}
          columns={columns}
          bodyCellCsx={bodyCellPropsCopy}
          showRemoved={showRemoved}
          rowIdPrefix={rowIdPrefix}
          isTableInverted={isInverted}
        />
      );
    },
    [
      bodyCellCsx,
      data.length,
      styleForSmallerScreens,
      cellProps,
      noPadding,
      isStriped,
      ItemHeight,
      renderEmptyValues,
      align,
      columns,
      rowIdPrefix,
      isInverted,
    ],
  );

  const renderHeaderGroups = useCallback(() => {
    return table.getHeaderGroups().map(headerGroup => (
      <Box
        key={headerGroup.id}
        csx={headerStyles(title ? true : false, isInverted)}>
        {mode === ETableModes.SORT_VERTICALLY && (
          <Box
            csx={{
              minWidth: '60px',
            }}
          />
        )}
        <Box
          csx={[
            headerRowStyles(
              mode === ETableModes.SORT_VERTICALLY
                ? 'scroll'
                : styleForSmallerScreens,
              isInverted,
            ),
            {
              paddingLeft: noPadding
                ? '0px'
                : mode === ETableModes.SORT_VERTICALLY
                ? '10px'
                : '25px',
            },
            headerCsx,
          ]}>
          {mode === 'selection' && (
            <Button
              variant="icon"
              onClick={() => table.toggleAllRowsSelected()}
              icon={
                <RadioCircle
                  size={CHECKBOX_SIZE - 5}
                  state={
                    table.getIsAllRowsSelected()
                      ? RadioSelectionStates.FULL
                      : table.getIsSomeRowsSelected()
                      ? RadioSelectionStates.SOME
                      : RadioSelectionStates.EMPTY
                  }
                  csx={{ marginLeft: '5px' }}
                />
              }
            />
          )}

          {headerGroup.headers.map((header, index) => (
            <Box
              key={header.id}
              csx={[
                {
                  display: 'flex',
                  alignItems: 'center',
                  ...getCellSizes(header.column, isInverted),
                  cursor: enableAlphabeticalSorting ? 'pointer' : 'default',
                  userSelect: enableAlphabeticalSorting ? 'none' : 'auto',
                  paddingLeft: index === 0 && canExpand ? 47 : undefined,
                },
                headerCellCsx,
              ]}
              onClick={() => {
                if (header.column.getCanSort()) {
                  onSortPress &&
                    onSortPress({
                      sortDirection: header.column.getNextSortingOrder(),
                      columnId: header.column.id,
                    });
                  header.column.toggleSorting();
                }
              }}>
              <Box
                csx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: boxAlign[alignHeaders[header.column.id]],
                  width: '100%',
                }}>
                {header.isPlaceholder ? null : !isNil(
                    header.getContext().header,
                  ) ? (
                  !isValidElement(header.column.columnDef.header) ? (
                    <Typography
                      fontWeight="medium"
                      csx={{
                        textAlign: alignHeaders[header.column.id],
                      }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Typography>
                  ) : (
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )
                  )
                ) : (
                  <Typography align="center">
                    {'No Header'.toUpperCase()}
                  </Typography>
                )}
                {requiredColumns.includes(header.column.id) && (
                  <Typography
                    color="persistentSemanticRed"
                    csx={{ marginLeft: '4px' }}>
                    *
                  </Typography>
                )}
                {header.column.getIsSorted() ? (
                  <Icon
                    name={`FaSortAlpha${
                      header.column.getIsSorted() === 'asc' ? 'Down' : 'DownAlt'
                    }`}
                    color="semanticBlue"
                    size="19px"
                    csx={{ marginLeft: '10px' }}
                  />
                ) : (
                  enableAlphabeticalSorting &&
                  alwaysShowSortIcon &&
                  header.column.getCanSort() && (
                    <Icon
                      name="FaSortAlphaDown"
                      color="lightGrey"
                      size="19px"
                      csx={{ marginLeft: '10px' }}
                    />
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    ));
  }, [
    alignHeaders,
    alwaysShowSortIcon,
    enableAlphabeticalSorting,
    headerCellCsx,
    headerCsx,
    isInverted,
    mode,
    noPadding,
    onSortPress,
    requiredColumns,
    styleForSmallerScreens,
    table,
    title,
    canExpand,
  ]);

  const areAllCollapsed =
    expanded !== true && Object.keys(expanded).length === 0;

  return (
    <Grid csx={scrollEnabled ? { height: '100%' } : undefined}>
      {canExpandAll && (
        <Grid.Item>
          <Box csx={[accordionStyles, {}]}>
            <Box
              className="allSectionsSection"
              csx={{
                marginBottom: '0px !important',
                justifyContent: 'flex-end !important',
              }}>
              <Box className="toggleButtonsContainer">
                <Button
                  variant="transparent"
                  icon={
                    <Icon name="MdChevronRight" csx={{ rotate: '90deg' }} />
                  }
                  iconPosition="right"
                  onClick={table.getToggleAllRowsExpandedHandler()}
                  data-disabled={table.getIsAllRowsExpanded()}
                  disabled={table.getIsAllRowsExpanded()}>
                  <span className="buttonContent">
                    {t('commonTexts.expandAll')}
                  </span>
                </Button>
                <Divider
                  direction="vertical"
                  csx={{ marginInline: '10px', height: '40px' }}
                />
                <Button
                  variant="transparent"
                  icon={
                    <Icon name="MdChevronRight" csx={{ rotate: '-90deg' }} />
                  }
                  iconPosition="right"
                  onClick={() => setExpanded({})}
                  data-disabled={areAllCollapsed}
                  disabled={areAllCollapsed}>
                  <span className="buttonContent">
                    {' '}
                    {t('commonTexts.collapseAll')}
                  </span>
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid.Item>
      )}
      <Grid.Item csx={scrollEnabled ? { overflowY: 'hidden' } : undefined}>
        <Grid
          rowGap={10}
          csx={
            scrollEnabled
              ? { justifyContent: 'center', maxHeight: '100%' }
              : undefined
          }>
          <Grid.Item
            mb={12}
            csx={scrollEnabled ? { overflowY: 'hidden' } : undefined}>
            {styleForSmallerScreens === 'card' ? (
              <CardsTable
                {...props}
                table={table}
                headerGroups={renderHeaderGroups()}
                renderRow={renderRow}
                ItemHeight={ItemHeight}
              />
            ) : mode === ETableModes.SORT_VERTICALLY ? (
              <SortableTable
                {...props}
                table={table}
                headerGroups={renderHeaderGroups()}
                renderRow={renderRow}
                ItemHeight={ItemHeight}
              />
            ) : mode === ETableModes.SELECTION ? (
              <SelectionTable
                {...props}
                table={table}
                headerGroups={renderHeaderGroups()}
                renderRow={renderRow}
                ItemHeight={ItemHeight}
              />
            ) : (
              <DefaultTable
                {...props}
                table={table}
                headerGroups={renderHeaderGroups()}
                renderRow={renderRow}
                ItemHeight={ItemHeight}
              />
            )}
          </Grid.Item>
          {newRowComponent && <Grid.Item mb={12}>{newRowComponent}</Grid.Item>}
          {onAddClick && (
            <Grid.Item mb={12}>
              <Box csx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="primary"
                  onClick={() => onAddClick()}
                  icon={<Icon name="MdOutlineAdd" />}>
                  {addRowText || i18n.t('components.table.addNewRow')}
                </Button>
              </Box>
            </Grid.Item>
          )}
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

export default Table;
