import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { WithTranslation } from 'react-i18next';
import ModifierItemsSection from './ModifierItemsSection';

const AssociatedModItems = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  return (
    <AccordionSection
      title={t(
        'menuScreen.modifierGroupDetails.associations.modifierItems.modItems',
      )}
      titleTextContainerCsx={{ minWidth: '150px' }}
      {...props}>
      <ModifierItemsSection />
    </AccordionSection>
  );
};

export default AssociatedModItems;
