import Grid from '@app/components/common/Grid';
import InputNumberWithButtons from '@app/components/common/Input/InputNumberWithButtons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { IElement, actionCreatorsTableLayout } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface ISeatsInput extends WithTranslation {
  elementData: IElement;
}
const SeatsInput = ({ t, elementData }: ISeatsInput) => {
  // Redux
  const updateElementAndSaveLastChange = bindActionCreators(
    actionCreatorsTableLayout.updateElementAndSaveLastChange,
    useDispatch(),
  );

  const handleUpdateSeats = (values: { [key: string]: unknown }) => {
    if (elementData) {
      const newElement = {
        ...elementData,
        ...values,
      };
      updateElementAndSaveLastChange(elementData, newElement);
    }
  };

  return (
    <Grid.Item>
      <InputNumberWithButtons
        type="number"
        min={1}
        label={t('settingsModule.tableLayoutCreator.sideBar.seats')}
        value={elementData.seats}
        onChange={seats => {
          handleUpdateSeats({ seats });
        }}
      />
    </Grid.Item>
  );
};

export default SeatsInput;
