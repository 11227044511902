import AuthContainerLayout from '@app/components/Authentication/AuthContainerLayout';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useAuth from '@app/hooks/useAuth';
import { Navigate } from 'react-router-dom';

const PrivateRouteAuth = () => {
  const isAuth = useAuth();

  return isAuth ? (
    <Navigate to={getPathWithOrgData('/home')} replace />
  ) : (
    <AuthContainerLayout />
  );
};

export default PrivateRouteAuth;
