import { TCsx, css } from '@emotion/react';
import { HTMLMotionProps, m } from 'framer-motion';
import { ReactNode, forwardRef, useLayoutEffect, useMemo, useRef } from 'react';
import Spinner from '../Spinner';
import { buttonLinkStyles, buttonVariants } from './styles';

export type IButton = Omit<HTMLMotionProps<'button'>, 'onClick'> & {
  children?: ReactNode;
  variant?:
    | 'primary'
    | 'active'
    | 'secondary'
    | 'success'
    | 'disabled'
    | 'warning'
    | 'danger'
    | 'transparent'
    | 'icon';
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom';
  isLoading?: boolean;
  loadingText?: string;
  onClickDisabled?: () => void;
  csx?: TCsx;
  css?: never;
  disableHoverEffect?: boolean;
  disableClickEffect?: boolean;
  onClick?: (openInNewTab: boolean) => void;
};

type IButtonLink = IButton & {
  to?: string;
};

const POSITION_DIRECTION = {
  left: 'row' as const,
  right: 'row-reverse' as const,
  top: 'column' as const,
  bottom: 'column-reverse' as const,
};

const ButtonLink = forwardRef<HTMLButtonElement, IButtonLink>(
  (
    {
      children,
      variant = 'secondary',
      icon,
      iconPosition = 'left',
      isLoading = false,
      disableHoverEffect = false,
      loadingText,
      onClickDisabled,
      csx,
      disableClickEffect,
      onClick,
      to = '',
      ...otherProps
    },
    ref,
  ) => {
    const isButtonDisabled = otherProps.disabled || isLoading;
    const linkRef = useRef<HTMLAnchorElement>(null);

    const buttonVariantStyles =
      buttonVariants[
        variant !== 'transparent' && isButtonDisabled ? 'disabled' : variant
      ];

    const dynamicButtonStyles = css({
      flexDirection: POSITION_DIRECTION[iconPosition],
    });

    const iconClass = useMemo(() => {
      return children
        ? iconPosition === 'left'
          ? ' icon-left'
          : iconPosition === 'right'
          ? ' icon-right'
          : iconPosition === 'top'
          ? ' icon-top'
          : ' icon-bottom'
        : '';
    }, [iconPosition, children]);

    useLayoutEffect(() => {
      if (isButtonDisabled) return;

      const closestAnchor = linkRef?.current;

      const handleLeft = (e: any) => {
        e.preventDefault();

        if (e.button === 0) {
          handleClick(false);
        } else if (e.button === 1) {
          handleClick(true);
        }
      };
      const handleMiddleClickOtherBrowsers = (e: MouseEvent | Event) => {
        e.preventDefault();
        handleClick(true);
      };

      const handleClick = (openInNewTab: boolean) => {
        onClick && onClick(openInNewTab);
      };

      closestAnchor?.addEventListener('click', handleLeft);
      closestAnchor?.addEventListener(
        'auxclick',
        handleMiddleClickOtherBrowsers,
      );

      return () => {
        closestAnchor?.removeEventListener('click', handleLeft);
        closestAnchor?.removeEventListener(
          'auxclick',
          handleMiddleClickOtherBrowsers,
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isButtonDisabled]);

    return (
      <a ref={linkRef} href={to} css={buttonLinkStyles}>
        <m.button
          ref={ref}
          whileTap={
            !isButtonDisabled && !disableClickEffect
              ? { scale: 0.97 }
              : undefined
          }
          transition={{ duration: 0.05 }}
          css={theme => [
            buttonVariantStyles(theme, {
              isDisabled: isButtonDisabled,
              disableHoverEffect,
            }),
            dynamicButtonStyles,
            csx,
          ]}
          {...otherProps}
          disabled={onClickDisabled ? false : otherProps.disabled}
          onClick={isButtonDisabled ? onClickDisabled : () => null}>
          {isLoading ? (
            <>
              <Spinner className="spinner" />
              {loadingText || children}
            </>
          ) : (
            <>
              {icon && <span className={`icon${iconClass}`}>{icon}</span>}
              {children}
            </>
          )}
        </m.button>
      </a>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
