import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { selectNumberOfPrepStationVersions } from '@app/state/selectors/settingsSelectors';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface IApplyChangesToOtherPrepStationsButton extends WithTranslation {
  onPress: () => void;
}

const ApplyChangesToOtherPrepStationsButton = ({
  t,
  onPress,
}: IApplyChangesToOtherPrepStationsButton) => {
  const numberOfPrepStationVersions = useSelector(
    selectNumberOfPrepStationVersions,
  );

  return (
    <Box>
      <Button
        csx={{ paddingLeft: 20, paddingRight: 20 }}
        variant="primary"
        onClick={onPress}
        disabled={numberOfPrepStationVersions === 1}>
        {t(
          'app.modals.kitchenTicketDiffModal.prepStations.applyChangesToOtherPrepStation.title',
        )}
      </Button>
    </Box>
  );
};

export default ApplyChangesToOtherPrepStationsButton;
