import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  BreadCrumbScreens,
  IModifierGroups,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('modifierItems', SECTION_ID);

const AssignedModifierGroupsSection = ({
  csx,
  t,
}: IAssignedItemsCombosSection) => {
  const dispatch = useDispatch();
  // Redux
  const { itemData: modifierItemData } = useSelector(getMemoizedItemData);
  const modifierGroups = useRootSelector(
    state => state.menu.selectedMode.currentMenu.modifierGroups,
  );

  const { updateModifierItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  // Local state
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedModifierGroupsIds = useMemo(
    () => modifierItemData?.parentModifierGroupIds || [],
    [modifierItemData],
  );

  const menuModifierItemAssignedModifierGroupsFactory = useCallback(
    (
      _modifierGroups: Record<number, IModifierGroups>,
      menuModifierItemAssignedModifierGroupIds: number[],
    ): IItemCard[] => {
      return menuModifierItemAssignedModifierGroupIds.map((id: number) => {
        const modifierGroup = _modifierGroups[id] || {};
        return {
          id: modifierGroup.id,
          title: modifierGroup.name,
          onRemoveClick: () => {
            const newParentModifierGroupIds = [
              ...menuModifierItemAssignedModifierGroupIds,
            ].filter(_id => _id !== id);

            setValue(
              { parentModifierGroupIds: newParentModifierGroupIds },
              SECTION_ID,
            );
          },
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const assignedElementsCardList = useMemo(
    () =>
      menuModifierItemAssignedModifierGroupsFactory(
        modifierGroups,
        assignedModifierGroupsIds,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedModifierGroupsIds],
  );

  const handleOnAssociate = useCallback(
    (newModifierGroups: IItemSelection[]) => {
      let newParentModifierGroupIds = [
        ...(modifierItemData?.parentModifierGroupIds ?? []),
      ];

      newModifierGroups.forEach(modifierGroup => {
        if (!newParentModifierGroupIds.includes(modifierGroup.id as number)) {
          newParentModifierGroupIds = [
            ...newParentModifierGroupIds,
            modifierGroup.id as number,
          ];
        }
      });

      setValue(
        { parentModifierGroupIds: newParentModifierGroupIds },
        SECTION_ID,
      );

      setIsSelectionModalOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modifierItemData.parentModifierGroupIds],
  );

  return (
    <AssignedElements
      type="modifierGroups"
      detailsScreenProps={{
        currentRelationsIds: assignedModifierGroupsIds.map(id => `${id}`),
        wantedEntity: 'modifierGroups',
      }}
      noElementsMessage={t(
        'menuScreen.modifierItemDetails.associations.modifierGroups.emptyCardsMessage',
      )}
      noCardsPadding
      cardsContainerCsx={{
        border: 'none',
      }}
      elements={assignedElementsCardList}
      active={isSelectionModalOpen}
      onModalClose={() => setIsSelectionModalOpen(false)}
      subCardProps={{
        title: t(
          'menuScreen.modifierItemDetails.associations.modifierGroups.title',
          {
            name: modifierItemData ? modifierItemData.name : '',
          },
        ),
        subtitle: t(
          'menuScreen.modifierItemDetails.associations.modifierGroups.description',
        ),
        isEditButtonDisabled: undefined,
        onEditMode: () => setEditMode(!isEditMode),
        actionOptions: [
          {
            text: t(
              'menuScreen.menuLocation.actionButtons.addNewModifierGroup',
            ),
            icon: false,
            handler: () =>
              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.ADD,
                    text: t(
                      'menuScreen.menuLocation.actionButtons.addNewModifierGroupTag',
                    ),
                    screen: BreadCrumbScreens.MODIFIER_GROUPS_DETAILS,
                    onPress: () =>
                      navigate('/menu/modifiers/modifier-groups/add', {
                        state: {
                          id: 0,
                          modifierItemId: modifierItemData?.modifierItemId,
                        },
                      }),
                    pathURL: '/menu/modifiers/modifier-groups/add',
                  }),
                onRefresh,
                true,
                false,
                'modifierGroups',
              ),
          },
          {
            text: t(
              'menuScreen.menuLocation.actionButtons.addExistingModifierGroup',
            ),
            icon: false,
            handler: () => setIsSelectionModalOpen(true),
          },
        ],
        csx,
      }}
      onAssociate={handleOnAssociate}
      renderItem={item => {
        const pathURL = `/menu/modifiers/modifier-groups/${item.id}`;
        return (
          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Card.Item
              csx={{ borderColor: `${theme.colors.lightGrey} !important` }}
              {...item}
              showRemoveIcon={isEditMode}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);
                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: item.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          </Box>
        );
      }}
    />
  );
};

export default AssignedModifierGroupsSection;
