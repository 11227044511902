import Icon from '@app/components/common/Icon';
import { Interpolation, Theme } from '@emotion/react';
import { ElementTypes } from '@westondev/tableturn-core';

const useElementTypes = ({
  ...props
}: {
  color?: string;
  size?: string;
  csx?: Interpolation<Theme>;
}) => {
  const ELEMENT_TYPES = {
    [ElementTypes.STATION]: {
      type: ElementTypes.STATION,
      icon: <Icon name="MdMyLocation" {...props} />,
    },
    [ElementTypes.WINDOW]: {
      type: ElementTypes.WINDOW,
      icon: <Icon name="MdOutlineWindow" {...props} />,
    },
    [ElementTypes.DOOR]: {
      type: ElementTypes.DOOR,
      icon: <Icon name="MdSensorDoor" {...props} />,
    },
    [ElementTypes.PLANT]: {
      type: ElementTypes.PLANT,
      icon: <Icon name="MdGrass" {...props} />,
    },
  };
  return ELEMENT_TYPES;
};

export default useElementTypes;
