import { IServiceAreaStore } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

export const DATA_INITIAL_STATE = {
  id: '0',
  name: '',
  revenueCenterId: 0,
  isActive: true,
};

export interface IUpdateServiceAreaModal extends WithTranslation {
  mode: 'update';
  showAddLabel?: false;
  serviceArea: IServiceAreaStore | null;
  setServiceArea: (value: null) => void;
  onAddServiceArea?: void;
}

export interface IAddServiceAreaModal extends WithTranslation {
  mode: 'add';
  showAddLabel?: boolean;
  onAddServiceArea: () => void;
  serviceArea?: null;
  setServiceArea?: void;
}

export type TAddOrUpdateServiceAreaModal =
  | IUpdateServiceAreaModal
  | IAddServiceAreaModal;

export interface IData {
  name: string;
  revenueCenterId: number;
  isActive: boolean;
  id?: string;
}
