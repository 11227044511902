import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import UnderDevelopment from '@app/components/common/UnderDevelopment';
import useIsSet from '@app/hooks/useIsSet';
import { actionCreatorsSettingsWeb } from '@app/state';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TicketDisplaySettings from './TicketDisplaySettings';

const RegisterTicket = () => {
  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    useDispatch(),
  );

  const isSet = useIsSet('registerTicket');

  useEffect(() => {
    if (!isSet)
      loadSettingsBucketChangeData({
        setting: 'registerTicket',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return (
    <Grid columnGap={15} rowGap={15}>
      <Grid.Item mb={12} md={12} lg={6}>
        <TicketDisplaySettings />
      </Grid.Item>
      <Grid.Item mb={12} md={12} lg={6} csx={[displayFlexRowCenterStyles]}>
        <Card
          csx={{
            width: '100%',
          }}>
          <UnderDevelopment pageName="Preview" />
        </Card>
      </Grid.Item>
    </Grid>
  );
};

export default RegisterTicket;
