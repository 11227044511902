export const getPathWithOrgData = (pathToNavigate: string) => {
  const currentPath = location.pathname.split('/');
  const orgId = currentPath[1];
  const locationId = currentPath[2];

  if (!pathToNavigate.startsWith('/')) {
    pathToNavigate = `/${pathToNavigate}`;
  }
  const _pathToNavigate = pathToNavigate.split('/');

  if (parseInt(_pathToNavigate[1]) && parseInt(_pathToNavigate[2]))
    return pathToNavigate;

  return `/${orgId}/${locationId}${pathToNavigate}`;
};

export const getOrgDataFromPath = () => {
  const currentPath = location.pathname.split('/');
  const orgId = parseInt(currentPath[1]);
  const locationId = parseInt(currentPath[2]);

  return { orgId, locationId };
};

export const getOrgDataFromLocalStorage = (): {
  organizationId: number;
  locationId: number;
  roleId: number;
} => {
  const { orgId, locationId } = getOrgDataFromPath();
  const localStorageKey = `orgData-${orgId}-${locationId}`;

  const orgData = localStorage.getItem(localStorageKey);

  return JSON.parse(orgData || '{}');
};

export const openNewTabWithOrgData = (pathToNavigate: string) => {
  const path = getPathWithOrgData(pathToNavigate);
  window.open(path, '_blank');
};
