import { css } from '@emotion/react';
import { TBreakpoints } from './types';
import {
  MQ_MIN_LARGE,
  MQ_MIN_MEDIUM,
  MQ_MIN_SMALL,
  MQ_MIN_X_LARGE,
} from '@app/theme/types';

export const flexStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
});

export const gridContainer = (columns: number | TBreakpoints) => {
  if (typeof columns === 'number')
    return css({
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
    });

  const { mb, sm, md, lg, xl } = columns;

  return css({
    display: 'grid',
    gridTemplateColumns: `repeat(${mb || 12}, minmax(0, 1fr))`,
    [MQ_MIN_SMALL]: {
      gridTemplateColumns: `repeat(${sm || mb || 12}, minmax(0, 1fr))`,
    },
    [MQ_MIN_MEDIUM]: {
      gridTemplateColumns: `repeat(${md || sm || mb || 12}, minmax(0, 1fr))`,
    },
    [MQ_MIN_LARGE]: {
      gridTemplateColumns: `repeat(${
        lg || md || sm || mb || 12
      }, minmax(0, 1fr))`,
    },
    [MQ_MIN_X_LARGE]: {
      gridTemplateColumns: `repeat(${
        xl || lg || md || sm || mb || 12
      }, minmax(0, 1fr))`,
    },
  });
};

export const columnGapStyles = (columnGap: number | TBreakpoints) => {
  if (typeof columnGap === 'number') return { columnGap: `${columnGap}px` };

  const { mb, sm, md, lg, xl } = columnGap;

  return css({
    columnGap: `${mb || 0}px`,
    [MQ_MIN_SMALL]: {
      columnGap: `${sm || mb || 0}px`,
    },
    [MQ_MIN_MEDIUM]: {
      columnGap: `${md || sm || mb || 0}px`,
    },
    [MQ_MIN_LARGE]: {
      columnGap: `${lg || md || sm || mb || 0}px`,
    },
    [MQ_MIN_X_LARGE]: {
      columnGap: `${xl || lg || md || sm || mb || 0}px`,
    },
  });
};

export const rowGapStyles = (rowGap: number | TBreakpoints) => {
  if (typeof rowGap === 'number') return { rowGap: `${rowGap}px` };

  const { mb, sm, md, lg, xl } = rowGap;
  return css({
    rowGap: `${mb || 0}px`,
    [MQ_MIN_SMALL]: {
      rowGap: `${sm || mb || 0}px`,
    },
    [MQ_MIN_MEDIUM]: {
      rowGap: `${md || sm || mb || 0}px`,
    },
    [MQ_MIN_LARGE]: {
      rowGap: `${lg || md || sm || mb || 0}px`,
    },
    [MQ_MIN_X_LARGE]: {
      rowGap: `${xl || lg || md || sm || mb || 0}px`,
    },
  });
};
