import { css } from '@emotion/react';

export const elementStyles = css({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: '1px solid transparent',
  backgroundColor: 'white',
  userSelect: 'none',
  cursor: 'pointer',
});

export const tableLayoutCreatorStyles = css({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  flexGrow: 1,
  overflow: 'hidden',
  '> .container': {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '> .layoutAndSidebarContainer': {
      display: 'flex',
      position: 'relative',
      flexGrow: 1,
      overflow: 'hidden',
    },
  },
});
