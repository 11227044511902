import Box from '@app/components/common/Box';
import { EItemTypes } from '../types';
import QuantityButtons from '../QuantityButtons';
import CustomTag from '../CustomTag';

interface IItemButtonFooter {
  isSelected: boolean;
  isMultipleQuantity?: boolean;
  itemType?: EItemTypes;
  isSizeItem?: boolean;
  increment?: () => void;
  decrement?: () => void;
  customTag?: {
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
    text?: string;
  };
}

const ItemButtonFooter = ({
  isMultipleQuantity,
  isSizeItem,
  isSelected,
  itemType,
  increment,
  decrement,
  customTag,
}: IItemButtonFooter) => {
  return (
    <Box
      csx={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        paddingTop:
          (isMultipleQuantity || itemType === EItemTypes.SIZE) && isSelected
            ? 1
            : 0,
      }}>
      {/* + - Buttons */}
      {(isMultipleQuantity || isSizeItem) && isSelected && (
        <QuantityButtons increment={increment} decrement={decrement} />
      )}
      {/* Allergen Tag */}
      {customTag && customTag.text !== '' && !isSelected && (
        <CustomTag {...customTag} />
      )}
    </Box>
  );
};

export default ItemButtonFooter;
