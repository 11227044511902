import Box from '@app/components/common/Box';
import Collapsible from '@app/components/common/List/Collapsible';
import ListItem from '@app/components/common/List/ListItem';
import { ITab } from '@app/components/common/Tabs/types';
import { BUCKET_PATH_INDEX, MODULE_PATH_INDEX } from '@app/constants';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import { getPathWithOrgData } from '@app/helpers/navigation';
import {
  resetPathsByBucketNavigation,
  resetPathsByMainPath,
} from '@app/helpers/restoration';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { MENU_URLS } from '@app/router/types';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { selectMenuTypes } from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TCollapsibleListsMenu, TCollapsibleListsMenuState } from '../types';

const MenuTabs = () => {
  // Redux
  const menuTypes = useRootSelector(
    stateSelector => Object.keys(selectMenuTypes(stateSelector) || {}).length,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const checkForChangesAndNavigateWeb = bindActionCreators(
    actionCreatorsMenuWeb.checkForChangesAndNavigateWeb,
    dispatch,
  );
  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);

  const setBreadCrumb = bindActionCreators(
    actionCreatorsMenu.setBreadCrumb,
    dispatch,
  );

  // Local state
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigateWithOrg();

  const [collapsibleItems, setCollapsibleItems] =
    useState<TCollapsibleListsMenuState>({
      modifiers: false,
      combos: false,
    });

  const areButtonsDisabled = menuTypes === 0;

  const showPressDisabled = () => {
    setShowToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: t('menuScreen.bucketDisabled'),
    });
  };

  const TABS_DATA: (ITab & { key: string })[] = [
    { id: 1, key: 'menuTypes', name: 'loggedIn.menuModule.tabs.menuTypes' },
    {
      id: 2,
      key: 'categories',
      name: 'loggedIn.menuModule.tabs.categories',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 3,
      key: 'subcategories',
      name: 'loggedIn.menuModule.tabs.subcategories',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 4,
      key: 'items',
      name: 'loggedIn.menuModule.tabs.items',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 5,
      key: 'modifiers',
      name: 'loggedIn.menuModule.tabs.modifiers.modifiers',
      subTabs: [
        { id: 51, name: 'loggedIn.menuModule.tabs.modifiers.modifierGroups' },
        { id: 52, name: 'loggedIn.menuModule.tabs.modifiers.modifierItems' },
        { id: 53, name: 'loggedIn.menuModule.tabs.modifiers.ingredients' },
      ],
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 6,
      key: 'combos',
      name: 'loggedIn.menuModule.tabs.combos.combos',
      subTabs: [
        { id: 54, name: 'loggedIn.menuModule.tabs.combos.combos' },
        { id: 55, name: 'loggedIn.menuModule.tabs.combos.itemGroups' },
      ],
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 7,
      key: 'specials',
      name: 'loggedIn.menuModule.tabs.specials',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 8,
      key: 'discounts',
      name: 'loggedIn.menuModule.tabs.discounts',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
    {
      id: 9,
      key: 'reportingGroups',
      name: 'menuScreen.common.bucket.reportingGroups.plural',
      buttonProps: {
        onClickDisabled: showPressDisabled,
      },
      isDisabled: areButtonsDisabled,
    },
  ];

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >();

  const getNavigationPath = (tabId: number, subTabId: number | null) => {
    return `/menu/${
      subTabId
        ? MENU_URLS[subTabId.toString() as keyof typeof MENU_URLS]
        : MENU_URLS[tabId.toString() as keyof typeof MENU_URLS]
    }`;
  };

  const currentPath = location.state?.currentPath || location.pathname;

  const handleSelectCurrentTab = (path: string) => {
    let pathIndex = Object.entries(MENU_URLS).find(
      ([, pathName]) => pathName === path,
    )?.[0];
    if (isNaN(Number(pathIndex))) {
      pathIndex = pathIndex = Object.entries(MENU_URLS).find(
        ([, pathName]) =>
          pathName === path.split('/')[0] + '/' + path.split('/')[1],
      )?.[0];
    }

    if (path.split('/').length > 1) {
      const mainPath = Object.values(MENU_URLS).indexOf(path.split('/')[0]) + 1;
      setSelectedTab({ tabId: mainPath, subTabId: Number(pathIndex) });
      const keyToNavigate = path.split('/')[0];
      handleCloseTheOtherCollapsibleItems(keyToNavigate);
    } else {
      setSelectedTab({ tabId: Number(pathIndex), subTabId: null });
      handleCloseTheOtherCollapsibleItems(path);
    }
  };

  useEffect(() => {
    const path = location.pathname.replace(getPathWithOrgData('/menu/'), '');

    handleSelectCurrentTab(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
    openInNewTab = false,
  ) => {
    checkForChangesAndNavigateWeb(() => {
      const newPath = getPathWithOrgData(getNavigationPath(tabId, subTabId));

      if (openInNewTab) return window.open(newPath, '_blank');

      setSelectedTab({ tabId, subTabId });

      if (currentPath !== newPath) {
        handleResetBreadcrumbToSelectedBucket(tabId, subTabId);

        navigate(newPath);
        const currentModule = currentPath.split('/')[MODULE_PATH_INDEX];
        resetPathsByBucketNavigation(
          currentModule,
          newPath.split('/')[BUCKET_PATH_INDEX],
        );

        if (currentModule && newPath.includes(currentModule)) return;

        const keyToNavigate = newPath.split('/')[BUCKET_PATH_INDEX];
        handleCloseTheOtherCollapsibleItems(keyToNavigate);

        resetPathsByMainPath('/menu/', newPath);
      } else {
        handleChangeCollapsibleItems(newPath as TCollapsibleListsMenu);
      }
    }, useRefreshMenuWeb);
  };

  const handleResetBreadcrumbToSelectedBucket = (
    tabId: number,
    subTabId: number | null,
  ) => {
    const mainBreadcrumb = getMainBreadcrumbBucket(tabId, subTabId, navigate);

    if (mainBreadcrumb)
      setBreadCrumb([{ ...mainBreadcrumb, action: BreadCrumbAction.NAV_MAIN }]);
  };

  const handleChangeCollapsibleItems = (
    key: TCollapsibleListsMenu,
    forceToggle?: boolean,
  ) => {
    if ((key in collapsibleItems && !collapsibleItems[key]) || forceToggle)
      setCollapsibleItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCloseTheOtherCollapsibleItems = (keyToNavigate: string) => {
    const newCollapsibleItems = Object.keys(collapsibleItems).reduce(
      (acc, key) => {
        if (key === keyToNavigate) {
          acc[key as TCollapsibleListsMenu] = true;
        } else {
          acc[key as TCollapsibleListsMenu] = false;
        }
        return acc;
      },
      {} as TCollapsibleListsMenuState,
    );
    setCollapsibleItems(newCollapsibleItems);
  };

  return (
    <>
      {TABS_DATA.map(tab => {
        return tab.subTabs ? (
          <Box key={tab.id} csx={{ marginLeft: '15px' }}>
            <Collapsible
              collapsibleKey={tab.key}
              csx={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '15px',
                marginTop: '5px',
              }}
              key={tab.id}
              isExpanded={collapsibleItems[tab.key as TCollapsibleListsMenu]}
              listItemProps={{
                isActive: activeTab?.tabId === tab.id,
                text: t(tab.name as ParseKeys),
                onClick: openInNewTab => {
                  if (tab.isDisabled) {
                    tab?.buttonProps?.onClickDisabled &&
                      tab?.buttonProps?.onClickDisabled();
                    return;
                  }
                  if (tab.subTabs)
                    handleOnSelectedTabChange(
                      tab.id,
                      tab.subTabs[0].id,
                      openInNewTab,
                    );
                },
                onIconRightClick: () => {
                  if (tab.isDisabled) {
                    return;
                  }
                  handleChangeCollapsibleItems(
                    tab.key as TCollapsibleListsMenu,
                    true,
                  );
                },
                isLink: getPathWithOrgData(
                  getNavigationPath(tab.id, tab.subTabs[0].id),
                ),
              }}>
              {tab.subTabs.map(subTab => (
                <ListItem
                  key={`${tab.id}_subTab_${subTab.id}`}
                  isSubItem
                  isActive={activeTab?.subTabId === subTab.id}
                  text={t(subTab.name as ParseKeys)}
                  onClick={openInNewTab =>
                    handleOnSelectedTabChange(tab.id, subTab.id, openInNewTab)
                  }
                  isLink={getPathWithOrgData(
                    getNavigationPath(tab.id, subTab.id),
                  )}
                />
              ))}
            </Collapsible>
          </Box>
        ) : (
          <Box key={tab.id} csx={{ marginLeft: '15px' }}>
            <ListItem
              key={tab.id}
              isSubItem
              isActive={activeTab?.tabId === tab.id}
              text={t(tab.name as ParseKeys)}
              onClick={openInNewTab => {
                if (tab.isDisabled) {
                  tab?.buttonProps?.onClickDisabled &&
                    tab?.buttonProps?.onClickDisabled();
                  return;
                }
                handleOnSelectedTabChange(tab.id, null, openInNewTab);
              }}
              isLink={getPathWithOrgData(getNavigationPath(tab.id, null))}
            />
          </Box>
        );
      })}
    </>
  );
};

export default MenuTabs;
