import Typography from '@app/components/common/Typography';
import { IGenericError } from './types';
import { ErrorRow } from '../../BucketValidationConfirmationModal/BucketValidationResolver';

const GenericError = ({ message }: IGenericError) => {
  return (
    <ErrorRow>
      <Typography>{message}</Typography>
    </ErrorRow>
  );
};

export default GenericError;
