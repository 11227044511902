import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import useRootSelector from '@app/hooks/useRootSelector';
import { handleReportsChange } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const ReportReportingGroupsFilter = () => {
  const setValues = bindActionCreators(handleReportsChange, useDispatch());
  const reportingGroupIds = useRootSelector(
    state => state.reports.changeData?.data?.reportingGroupIds,
  );
  const menuReportingGroups = useRootSelector(
    state => state.menu.selectedMode.currentMenu.reportingGroups,
  );

  const reportingGroupsOptionsArray = useMemo(() => {
    return Object.entries(menuReportingGroups).map(([k, v]) => ({
      value: Number(k),
      label: v.name,
    }));
  }, [menuReportingGroups]);

  const reportingGroupIdsValueText = useReportsFilterText(
    'reportingGroups',
    reportingGroupIds,
  );

  const currentReportingGroupIdsSelected = useMemo(() => {
    const reportingGroupIdsSet = new Set(reportingGroupIds ?? []);
    return reportingGroupsOptionsArray.filter(v =>
      reportingGroupIdsSet.has(v.value),
    );
  }, [reportingGroupIds, reportingGroupsOptionsArray]);

  const handleOnChangeDone = useCallback(
    (selectedReportingGroupOptions: IOption[]) => {
      if (
        !selectedReportingGroupOptions?.length ||
        selectedReportingGroupOptions?.some(v => v.value === 0)
      ) {
        setValues(prev => ({ ...prev, reportingGroupIds: null }));
        return;
      }

      const selectedReportingGroupIds = selectedReportingGroupOptions.map(
        v => v.value,
      );

      setValues(prev => ({
        ...prev,
        reportingGroupIds: selectedReportingGroupIds,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid.Item mb={12} sm={6} md={3}>
      <Typography csx={labelStyles}>
        {t('reports.fields.reportingGroupIds')}
      </Typography>
      <OptionsModal
        title={t('reports.fields.reportingGroupIds')}
        customText={reportingGroupIdsValueText}
        options={reportingGroupsOptionsArray}
        currentOptionsSelected={currentReportingGroupIdsSelected}
        onChangeDone={handleOnChangeDone}
        clearOptionsLabel={t('commonButtons.selectAll')}
        isSearchable
        returnItemOnChange
        allowMultipleSelection
        buttonProps={{
          csx: {
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
      />
    </Grid.Item>
  );
};

export default ReportReportingGroupsFilter;
