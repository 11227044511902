import BlueDot from '@app/components/common/BlueDot';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import ColorPicker from '@app/components/common/ColorPicker';
import Divider from '@app/components/common/Divider';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import {
  displayFlexRowCenterStyles,
  displayFlexRowStyles,
} from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import {
  actionCreatorsMenu,
  formatMoney,
  IModifierGroupsItemsOverride,
  MenuTypes,
  QuantityModes,
  SectionId,
  TPriceCalcModes,
  TQuantityModeTypes,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SectionId.MODIFIER_GROUPS;

interface IModifierItemInfo {
  isSelected: boolean;
  defaultQuantitySelected: number;
  inheritedActive: boolean;
}

interface IOptions extends WithTranslation {
  active?: boolean;
  isEditable?: boolean;
  showTags?: boolean;
  override: IModifierGroupsItemsOverride;
  menuTypeMode: MenuTypes;
  menuTypeVersionId: number;
  modifierGroupId: number;
  modifierGroupInfo: {
    quantityMode: TQuantityModeTypes;
    requiredModifierItems: number;
    includedModifierItems: number;
    multiselect: boolean;
    totalPrice: number;
    priceCalcMode: TPriceCalcModes;
    backgroundColor?: string | null;
    textColor?: string | null;
    maximumModifierItems: number;
  };
  hideColors?: boolean;
  modifierItemsArray: { [modifierItemId: number]: IModifierItemInfo };
  disabledLayerBackgroundColor?: string;
  hiddenModifierItemsCount: number;
  setIncludedRequiredAmounts?: (
    included: number,
    required: number,
    maximum: number,
  ) => void;
}
const generateNumericValuesForDropdown = (max: number) => {
  return Array.from(Array(max).keys()).map(key => {
    return { label: `${key}`, value: key };
  });
};

const Options = ({
  t,
  override,
  active = true,
  isEditable = true,
  modifierItemsArray: modifierItems,
  hiddenModifierItemsCount,
  menuTypeMode,
  menuTypeVersionId,
  modifierGroupId,
  setIncludedRequiredAmounts = () => null,
  modifierGroupInfo = {
    includedModifierItems: 0,
    multiselect: false,
    priceCalcMode: 'max',
    quantityMode: 'Off',
    requiredModifierItems: 0,
    totalPrice: 0,
    backgroundColor: null,
    textColor: null,
    maximumModifierItems: 0,
  },
  hideColors,
}: IOptions) => {
  // Redux
  const { hideModifierGroup } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const theme = useTheme();
  const {
    includedModifierItems,
    multiselect,
    quantityMode,
    requiredModifierItems,
    totalPrice,
    backgroundColor: _backgroundColor,
    textColor: _textColor,
    maximumModifierItems,
  } = modifierGroupInfo;

  const backgroundColor = _backgroundColor || theme.colors.cardWhite;
  const textColor = _textColor || theme.colors.black;

  const [includedDropdownValues, setIncludedDropdownValues] = useState(
    generateNumericValuesForDropdown(
      multiselect
        ? quantityMode === QuantityModes.OFF
          ? Object.values(modifierItems).length + 1 - hiddenModifierItemsCount
          : Object.values(modifierItems).length + 1
        : 2,
    ),
  );

  const [prevHiddenCount, setPrevHiddenCount] = useState(0);

  const numberOfSelectedModifierItems = useMemo(() => {
    if (quantityMode === QuantityModes.NUMBER) {
      return Object.values(modifierItems)
        .filter(item => item.isSelected && item.inheritedActive)
        .reduce(
          (acc, currentValue) => acc + currentValue.defaultQuantitySelected,
          0,
        );
    }
    return Object.values(modifierItems).filter(
      item => item.isSelected && item.inheritedActive,
    ).length;
  }, [modifierItems, quantityMode]);

  useEffect(() => {
    const modifierItemsLength = Object.values(modifierItems).length;

    setIncludedDropdownValues(
      generateNumericValuesForDropdown(
        multiselect
          ? quantityMode === QuantityModes.OFF
            ? modifierItemsLength + 1 - hiddenModifierItemsCount
            : modifierItemsLength + 1
          : 2,
      ),
    );

    if (modifierItemsLength === hiddenModifierItemsCount)
      setIncludedRequiredAmounts(0, 0, 0);
    else if (
      includedModifierItems >= includedDropdownValues.length - 1 &&
      includedDropdownValues.length !== 1 &&
      hiddenModifierItemsCount > prevHiddenCount
    )
      setIncludedRequiredAmounts(includedDropdownValues.length - 2, 0, 0);

    setPrevHiddenCount(hiddenModifierItemsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenModifierItemsCount]);

  const handleHideModifierGroup = (isActive: boolean) => {
    hideModifierGroup(
      menuTypeVersionId,
      modifierGroupId,
      SECTION_ID,
      !isActive,
    );
  };

  return (
    <Box
      csx={{
        width: 'inherit',
        height: 'inherit',
        position: 'relative',
      }}>
      <Box
        csx={{
          display: 'flex',
          opacity: active ? undefined : 0.5,
          padding: '5px 15px',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.lighterGrey}`,
          minWidth: menuTypeMode === MenuTypes.MASTER_MENU ? '1050px' : '900px',
        }}>
        <Typography>
          {t('menuScreen.itemDetails.modifierGroupsSection.selectedDefault')}:{' '}
          {numberOfSelectedModifierItems}
        </Typography>
        <Divider direction="vertical" csx={{ height: '45px' }} />
        <Typography>
          {t('menuScreen.itemDetails.modifierGroupsSection.totalPrice')}:{' '}
          {formatMoney('', totalPrice)}
        </Typography>

        <Divider direction="vertical" csx={{ height: '45px' }} />

        <Box
          csx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {override.maximumModifierItems && <BlueDot />}
          <Typography
            csx={{
              marginRight: isEditable ? '5px' : '0px',
              marginLeft: '5px',
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.maximum')}:
          </Typography>
          {isEditable ? (
            <MoneyInput
              value={maximumModifierItems}
              showPlaceholder
              placeholder={t('menuScreen.modifierGroupDetails.basic.noMax')}
              precision={0}
              prefix=""
              width={90}
              onValueChange={value =>
                setIncludedRequiredAmounts(
                  includedModifierItems,
                  requiredModifierItems,
                  value,
                )
              }
              maxValue={99}
            />
          ) : (
            <Typography>
              {' '}
              {!maximumModifierItems
                ? t('menuScreen.modifierGroupDetails.basic.noMax')
                : maximumModifierItems}
            </Typography>
          )}
        </Box>

        <Box
          csx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {override.requiredModifierItems && <BlueDot />}
          <Typography
            csx={{
              marginRight: '5px',
              marginLeft: '5px',
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.required')}:
          </Typography>
          <MoneyInput
            prefix=""
            value={requiredModifierItems}
            precision={0}
            onValueChange={value =>
              setIncludedRequiredAmounts(
                includedModifierItems,
                value,
                maximumModifierItems,
              )
            }
            placeholder=""
            width={70}
            maxValue={99}
          />
        </Box>

        <Divider direction="vertical" csx={{ height: '45px' }} />

        <Box
          csx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {override.includedModifierItems && <BlueDot />}
          <Typography
            csx={{
              marginRight: '5px',
              marginLeft: '5px',
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.included')}:
          </Typography>
          {isEditable ? (
            <MoneyInput
              prefix=""
              value={includedModifierItems}
              precision={0}
              onValueChange={value =>
                setIncludedRequiredAmounts(
                  value,
                  requiredModifierItems,
                  maximumModifierItems,
                )
              }
              placeholder=""
              width={70}
              maxValue={99}
            />
          ) : (
            <Typography fontWeight="medium">{includedModifierItems}</Typography>
          )}
        </Box>

        {!hideColors && (
          <>
            <Divider direction="vertical" csx={{ height: '45px' }} />
            <Box csx={displayFlexRowStyles}>
              <Typography csx={{ marginRight: isEditable ? 5 : 0 }}>
                {t(
                  'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.background',
                )}
                :
              </Typography>
              <ColorPicker isDisabled color={backgroundColor} />
            </Box>
            <Box csx={displayFlexRowStyles}>
              <Typography csx={{ marginRight: isEditable ? 5 : 0 }}>
                {t(
                  'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.text',
                )}
                :
              </Typography>
              <ColorPicker isDisabled color={textColor} />
            </Box>
          </>
        )}

        {menuTypeMode === MenuTypes.MASTER_MENU && (
          <>
            <Divider direction="vertical" csx={{ height: '45px' }} />
            <Checkbox
              checked={!active}
              label={
                <Box csx={[displayFlexRowCenterStyles, { gap: '5px' }]}>
                  {override.active && <BlueDot />}
                  {t(
                    'menuScreen.itemDetails.modifierGroupsSection.hideModifierGroup',
                  )}
                </Box>
              }
              onChange={handleHideModifierGroup}
            />
          </>
        )}
      </Box>
      {!active && (
        <Box
          csx={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,.8)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
    </Box>
  );
};

export default Options;
