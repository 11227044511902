import ButtonCategory from '@app/components/LoggedIn/Register/CategoriesBar/ButtonCategory';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import { checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction } from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  BreadCrumbScreens,
  ICategory,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import BgAndTextColorPickers from '../../../common/BgAndTextColorPickers';
import {
  commonSubsectionStyles,
  previewSubsectionStyles,
  registerDisplaySubsectionStyles,
} from './styles';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('categories', SECTION_ID);

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const dispatch = useDispatch();
  const { updateCategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const { deleteBucketElementWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );
  const { showToast, setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );

  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);

  const navigate = useNavigateWithOrg();
  const theme = useTheme();

  const [previewActive, setPreviewActive] = useState(false);
  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active })}
      showDefaultLabel
    />
  );

  const backgroundColor =
    itemData?.backgroundColor || theme.colors.semanticBlue;

  const textColor = itemData?.textColor || theme.colors.textWhite;

  const onReset = () => {
    setValue({ backgroundColor: null, textColor: null });
  };

  const onResetBackground = () => {
    setValue({ backgroundColor: null });
  };

  const onResetText = () => {
    setValue({ textColor: null });
  };

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');

  const handleDeleteDisabled = useCallback(() => {
    showToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: t('menuScreen.deleteToast', {
        bucket: t('menuScreen.common.bucket.categories.plural'),
      }),
    });
  }, [t, showToast]);

  const options = useMemo(() => {
    if (isCreation) {
      return [];
    }

    return [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalActive(true),
        isDisabled: false,
      },
      {
        text: t('commonButtons.delete'),
        handler: () => {
          setShowConfirmationModal({
            active: true,
            title: t('menuScreen.common.bucket.categories.singular'),
            type: 'delete',
            name: itemData?.name,
            onSuccess: () => {
              deleteBucketElementWeb();
            },
          });
        },
        isDisabled: !isMasterMode,
        disabledHandler: handleDeleteDisabled,
      },
    ];
  }, [
    handleDeleteDisabled,
    isCreation,
    isMasterMode,
    t,
    itemData?.name,
    deleteBucketElementWeb,
    setShowConfirmationModal,
  ]);

  const hasSectionError =
    Boolean(nameError?.error) || Boolean(posNameError?.error);

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket="categories"
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(
            () => {
              const _navigate = () => navigate(`/menu/categories/${newId}`);
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.REPLACE,
                text: currentMenu.categories[newId].name,
                onPress: _navigate,
                screen: BreadCrumbScreens.CATEGORIES_DETAILS,
                pathURL: `/menu/categories/${newId}`,
              });
              _navigate();
            },
            () => null,
          );
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('menuScreen.categoryDetails.basic.title')}
        info={t('menuScreen.categoryDetails.basic.toolTip')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: isCreation
              ? t('menuScreen.categoryDetails.basic.optionsDisabled.creation')
              : t('menuScreen.categoryDetails.basic.optionsDisabled.location'),
          })
        }
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid rowGap={20} columnGap={20}>
            <Grid.Item mb={12} sm={6} md={6} lg={3}>
              <Input
                label={t('menuScreen.categoryDetails.basic.name')}
                placeholder={t('menuScreen.categoryDetails.basic.name')}
                required
                caption={nameError?.error}
                error={Boolean(nameError?.error)}
                value={itemData?.name || ''}
                disabled={!isMasterMode && !isCreation}
                onChange={name => {
                  setValue({
                    name: name.target.value,
                  });

                  if (
                    itemData.posName === '' ||
                    itemData.posName === itemData.name
                  ) {
                    setValue({
                      posName: name.target.value,
                    });
                  }

                  if (itemData.posName === '' && posNameError) {
                    posNameError.clear();
                  }
                }}
                onFocus={() => nameError && nameError.clear()}
              />
            </Grid.Item>
            <Grid.Item mb={12} sm={6} md={6} lg={3}>
              <Input
                label={t('menuScreen.categoryDetails.basic.posName')}
                placeholder={t('menuScreen.categoryDetails.basic.posName')}
                required
                caption={posNameError?.error}
                error={Boolean(posNameError?.error)}
                value={itemData?.posName || ''}
                onChange={posName =>
                  setValue({
                    posName: posName.target.value,
                  })
                }
                onFocus={() => posNameError && posNameError.clear()}
              />
            </Grid.Item>
            <Grid.Item>
              <Grid>
                <Grid.Item
                  sm={12}
                  md={12}
                  lg={6}
                  xl={4}
                  csx={[
                    commonSubsectionStyles,
                    registerDisplaySubsectionStyles,
                  ]}>
                  <BgAndTextColorPickers
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    onBackgroundColorChange={color =>
                      setValue({ backgroundColor: color })
                    }
                    onTextColorChange={color => setValue({ textColor: color })}
                    onReset={onReset}
                    onResetBackground={onResetBackground}
                    onResetText={onResetText}
                  />
                </Grid.Item>
                <Grid.Item
                  sm={12}
                  md={12}
                  lg={6}
                  xl={4}
                  csx={[commonSubsectionStyles, previewSubsectionStyles]}>
                  <Typography
                    color="persistentSemanticBlue"
                    fontWeight="medium"
                    csx={{ marginBottom: '25px' }}>
                    {t('menuScreen.categoryDetails.basic.previewTitle')}
                  </Typography>
                  <Box
                    csx={{
                      display: 'flex',
                      flexGrow: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ButtonCategory
                      isActive={previewActive}
                      isLastElement
                      onClick={() => setPreviewActive(prev => !prev)}
                      category={
                        {
                          name:
                            itemData?.posName ||
                            t('loggedIn.commonFields.basic.posName'),
                          backgroundColor: backgroundColor,
                          textColor: textColor,
                        } as ICategory
                      }
                    />
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
