import { css } from '@emotion/react';

export const dragControlStyles = () =>
  css({
    display: 'flex',
    minWidth: 50,
    maxWidth: 50,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    ':active': {
      cursor: 'grabbing',
    },
  });
