import { Theme, css } from '@emotion/react';

export const errorContainerStyles = () =>
  css({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  });

export const errorStyles = (theme: Theme) =>
  css({
    fontWeight: theme.fontWeights.medium,
    color: theme.colors.darkestGrey,
  });

export const redDotStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.persistentSemanticRed,
    width: '10px',
    height: '10px',
    marginRight: '10px',
    borderRadius: '50%',
  });
