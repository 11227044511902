import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import ImageUploader from '@app/components/common/ImageUploader';
import { labelStyles } from '@app/components/common/Input/styles';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import {
  actionCreatorsMenu,
  FormMode,
  MassUpdateValues,
  SectionId,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const sectionId = SectionId.REGISTER;

interface IRegisterDisplayImagesSubsection extends WithTranslation {
  menuTypeVersionId: number | undefined;
  imageUri: string | null | undefined;
  secondaryImageUri: string | null | undefined;
  mode: FormMode | undefined;
  itemName: string;
  isCombo: boolean | undefined;
}

const RegisterDisplayImagesSubsection = ({
  t,
  mode,
  menuTypeVersionId,
  isCombo,
  itemName,
  imageUri,
  secondaryImageUri,
}: IRegisterDisplayImagesSubsection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  return (
    <Grid
      rowGap={20}
      columnGap={20}
      columns={{
        mb: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 15,
      }}
      csx={theme => ({
        paddingBottom: '20px',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
      })}>
      <Grid.Item sm={6} md={4} xl={3} csx={{ justifyContent: 'flex-end' }}>
        <ToolTip
          showHover={showHover}
          content={t('menuScreen.itemDetails.basic.itemImageInfo')}>
          <div />
        </ToolTip>
        <Typography
          csx={labelStyles}
          onMouseOver={handlePopUp}
          onMouseOut={() => {
            if (newTimeout) clearTimeout(newTimeout);
            setShowHover(false);
          }}>
          {isCombo
            ? t('menuScreen.comboDetails.registerDisplay.uploadImage')
            : t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.uploadImage',
              )}
        </Typography>
        <Box csx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 20 }}>
          <ImageUploader
            csx={{ marginTop: '0' }}
            onChange={(imageUrl: string | null) => {
              setValue({ imageUrl }, sectionId, menuTypeVersionId);
            }}
            source={
              imageUri !== MassUpdateValues.NO_CHANGES ? imageUri : undefined
            }
            modalProps={{
              title: isMassUpdate
                ? t('menuScreen.itemDetails.basic.massUpdateImageLabel')
                : t('menuScreen.itemDetails.basic.itemImageLabel'),
              subtitle: isMassUpdate
                ? t('menuScreen.itemDetails.basic.massUpdateImageSubtitle')
                : itemName,
            }}
            uploadButtonText={
              isMassUpdate
                ? t(
                    'menuScreen.itemDetails.basic.massUpdateOptionsUploadButton',
                  )
                : undefined
            }
            deleteButtonText={
              isMassUpdate
                ? t(
                    'menuScreen.itemDetails.basic.massUpdateOptionsDeleteButton',
                  )
                : undefined
            }
            showNoChanges={isMassUpdate}
          />
          {isMassUpdate && !imageUri && (
            <Typography
              csx={theme => [
                labelStyles,
                { color: `${theme.colors.semanticRed} !important` },
              ]}>
              {t('menuScreen.itemDetails.basic.massUpdateImageRemoved')}
            </Typography>
          )}
        </Box>
      </Grid.Item>
      <Grid.Item sm={6} md={4} xl={3} csx={{ justifyContent: 'flex-end' }}>
        <Typography csx={labelStyles}>
          {isCombo
            ? t('menuScreen.comboDetails.registerDisplay.uploadSecondaryImage')
            : t(
                'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.uploadSecondaryImage',
              )}
        </Typography>

        <Box csx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 20 }}>
          <ImageUploader
            csx={{ marginTop: '0' }}
            onChange={(secondaryImageUrl: string | null) => {
              setValue({ secondaryImageUrl }, sectionId, menuTypeVersionId);
            }}
            source={
              secondaryImageUri !== MassUpdateValues.NO_CHANGES
                ? secondaryImageUri
                : undefined
            }
            modalProps={{
              title: isMassUpdate
                ? t('menuScreen.itemDetails.basic.massUpdateImageLabel')
                : t('menuScreen.itemDetails.basic.itemImageLabel'),
              subtitle: isMassUpdate
                ? t('menuScreen.itemDetails.basic.massUpdateImageSubtitle')
                : itemName,
            }}
            uploadButtonText={
              isMassUpdate
                ? t(
                    'menuScreen.itemDetails.basic.massUpdateOptionsUploadButton',
                  )
                : undefined
            }
            deleteButtonText={
              isMassUpdate
                ? t(
                    'menuScreen.itemDetails.basic.massUpdateOptionsDeleteButton',
                  )
                : undefined
            }
            showNoChanges={isMassUpdate}
          />
          {isMassUpdate && !secondaryImageUri && (
            <Typography
              csx={theme => [
                labelStyles,
                { color: `${theme.colors.semanticRed} !important` },
              ]}>
              {t('menuScreen.itemDetails.basic.massUpdateImageRemoved')}
            </Typography>
          )}
        </Box>
      </Grid.Item>
    </Grid>
  );
};

export default RegisterDisplayImagesSubsection;
