import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
  selectMenu,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  CONFIRMATION_MODAL_INITIAL_STATE,
  IReportingGroup,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('reportingGroups', SECTION_ID);

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();

  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );

  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );

  const onRefresh = useRefreshMenuWeb();

  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);

  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue<IReportingGroup>({ active }, SECTION_ID)}
      showDefaultLabel
    />
  );

  const nameError = useFormError('name');

  const handleDelete = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.reportingGroups.singular'),
      type: 'delete',
      name: itemData?.name,
      onSuccess: () => {
        setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
        deleteBucketElementWeb();
      },
    });
  }, [deleteBucketElementWeb, itemData?.name, setShowConfirmationModal, t]);

  const options = useMemo(() => {
    const _options = [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalActive(true),
        isDisabled: false,
      },
      {
        text: t('commonButtons.delete'),
        handler: handleDelete,
        isDisabled: !isMasterMode,
      },
    ];

    return isCreation ? [] : _options;
  }, [handleDelete, isCreation, isMasterMode, t]);

  const hasSectionError = Boolean(nameError?.error);

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket="reportingGroups"
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = selectMenu(store.getState());
          checkForChangesAndNavigateWeb(() => {
            const _navigate = () => navigate(`/menu/reporting-groups/${newId}`);
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.REPLACE,
              text: currentMenu.reportingGroups[newId]?.name,
              onPress: _navigate,
              pathURL: `/menu/reporting-groups/${newId}`,
            });
            _navigate();
          }, onRefresh);
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: isCreation
              ? t('menuScreen.reportingGroupDetails.optionsDisabled.creation')
              : t('menuScreen.reportingGroupDetails.optionsDisabled.location'),
          })
        }
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
            <Grid.Item
              mb={12}
              sm={6}
              md={6}
              lg={3}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                caption={nameError?.error}
                error={Boolean(nameError?.error)}
                label={t('loggedIn.commonFields.basic.name')}
                placeholder={t('loggedIn.commonFields.basic.name')}
                value={itemData?.name || ''}
                onChange={name => {
                  setValue<IReportingGroup>(
                    {
                      name: name.target.value,
                    },
                    SECTION_ID,
                  );
                }}
                onFocus={() => nameError && nameError.clear()}
              />
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
