import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Grid from '@app/components/common/Grid';
import {
  IRegisterSettings,
  SettingsSectionId,
  actionCreatorsSettings,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.END_OF_DAY;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const EndOfDaySettings = ({ t }: WithTranslation) => {
  // Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  return (
    <Grid rowGap={15} columnGap={15}>
      <Grid.Item md={12}>
        <Card.SubCard
          title={t(
            'settingsModule.registerModeSettings.endOfDay.clockOut.title',
          )}>
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.requireNoOpenOrders',
                )}
                checked={data.requireNoOpenOrders || false}
                onChange={requireNoOpenOrders =>
                  setValue<IRegisterSettings>(
                    { requireNoOpenOrders },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>

            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.requireNonCashTips',
                )}
                checked={data.requireNonCashTips || false}
                onChange={requireNonCashTips =>
                  setValue<IRegisterSettings>(
                    { requireNonCashTips },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>

            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.requireServerBankSettlement',
                )}
                checked={data.requireServerBankSettlement || false}
                onChange={requireServerBankSettlement => {
                  setValue<IRegisterSettings>(
                    { requireServerBankSettlement },
                    SECTION_ID,
                  );
                  if (!requireServerBankSettlement) {
                    setValue<IRegisterSettings>(
                      { netNonCashTipsOnSettlement: false },
                      SECTION_ID,
                    );
                  }
                }}
              />
            </Grid.Item>

            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.requireCashtipReporting',
                )}
                checked={data.requireCashtipReporting || false}
                onChange={requireCashtipReporting =>
                  setValue<IRegisterSettings>(
                    { requireCashtipReporting },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>

            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.netNonCashTipsOnSettlement',
                )}
                isDisabled={!data.requireServerBankSettlement}
                checked={data.netNonCashTipsOnSettlement || false}
                onChange={netNonCashTipsOnSettlement =>
                  setValue<IRegisterSettings>(
                    { netNonCashTipsOnSettlement },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>

            <Grid.Item
              mb={12}
              sm={6}
              csx={{
                justifyContent: 'center',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.registerModeSettings.endOfDay.clockOut.requireDrawerSettlement',
                )}
                checked={data.requireDrawerSettlement || false}
                onChange={requireDrawerSettlement =>
                  setValue<IRegisterSettings>(
                    { requireDrawerSettlement },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
          </Grid>
        </Card.SubCard>
      </Grid.Item>
    </Grid>
  );
};

export default EndOfDaySettings;
