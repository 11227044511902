import { css } from '@emotion/react';

export const accountCreatedStyles = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    '.message': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '500px',
      '.title': {
        marginBottom: '15px',
      },
      '.messageBody': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        flexGrow: 1,
      },
      '.iconsContainer': {
        position: 'relative',
        marginBottom: '10px',
      },
      button: {
        maxWidth: '380px',
      },
    },
  });
