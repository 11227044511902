import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import SearchInput from '@app/components/common/SearchInput';
import Tabs from '@app/components/common/Tabs';
import { IActiveTab, ITab } from '@app/components/common/Tabs/types';
import { selectDrawers } from '@app/state/selectors/registerSelectors';
import { currentDevicesSelector } from '@app/state/selectors/settingsSelectors';
import { ITerminals } from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { devicesTabs, mainTabs } from '../types';
import EditPrinterModal from './EditPrinterModal';
import EditTabletModal from './EditTabletModal';
import PrintersTable from './PrintersTable';
import TabletsTable from './TabletsTable/TabletsTable';
import TerminalsTable from './TerminalsTable';
import EditTerminalModal from './TerminalsTable/EditTerminalModal';
import { devicesStyles } from './styles';

const DEVICES_DATA: ITab[] = [
  {
    id: 1,
    name: 'loggedIn.settingsModule.devicesAndLicenses.tabs.devices.tablets',
  },
  {
    id: 2,
    name: 'loggedIn.settingsModule.devicesAndLicenses.tabs.devices.printers',
  },
  {
    id: 3,
    name: 'loggedIn.settingsModule.devicesAndLicenses.tabs.devices.terminals',
  },
];

interface IDevices extends WithTranslation {
  mainActiveTab: IActiveTab;
}
const Devices = ({ mainActiveTab, t }: IDevices) => {
  //Redux
  const devices = useSelector(currentDevicesSelector);
  const drawers = useSelector(selectDrawers);

  const [activeTab, setSelectedTab] = useState<IActiveTab | undefined>();
  const [searchText, setSearchText] = useState('');
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    setSelectedTab({ tabId: 1, subTabId: null });
  }, []);

  const terminals: ITerminals = useMemo(
    () => devices?.terminals || {},
    [devices?.terminals],
  );
  const printers = useMemo(() => devices?.printers || {}, [devices?.printers]);
  const tablets = useMemo(() => devices?.tablets || {}, [devices?.tablets]);

  const tabletsTableArray = useMemo(
    () =>
      mainActiveTab.tabId === mainTabs.DEVICES &&
      activeTab?.tabId === devicesTabs.TABLETS
        ? Object.keys(tablets || {})
            .map(key => ({
              ...tablets[key],
              id: Number(key),
              actions: '',
            }))
            .filter(item => {
              return (
                item.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                  -1 ||
                item.androidId
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) !== -1
              );
            })
        : [],

    [mainActiveTab.tabId, activeTab?.tabId, tablets, searchText],
  );

  const addDeviceOption = useMemo(() => {
    if (mainActiveTab.tabId !== mainTabs.DEVICES) {
      return <></>;
    }

    switch (activeTab?.tabId) {
      case devicesTabs.PRINTERS:
        return (
          <Button
            variant="primary"
            icon={<Icon name="MdAdd" />}
            csx={{ minWidth: '140px' }}
            onClick={() => setIsModalActive(true)}>
            {t('settingsModule.devices_licenses.addPrinter')}
          </Button>
        );
      case devicesTabs.TABLETS:
        return (
          <Button
            variant="primary"
            icon={<Icon name="MdAdd" />}
            csx={{ minWidth: '140px' }}
            onClick={() => setIsModalActive(true)}>
            {t('settingsModule.devices_licenses.addTablet')}
          </Button>
        );
      case devicesTabs.TERMINALS:
        return (
          <Button
            variant="primary"
            icon={<Icon name="MdAdd" />}
            csx={{ minWidth: '140px' }}
            onClick={() => setIsModalActive(true)}>
            {t('settingsModule.devices_licenses.addTerminal')}
          </Button>
        );
    }
  }, [t, activeTab?.tabId, mainActiveTab.tabId]);

  const printersTableArray = useMemo(() => {
    if (
      mainActiveTab.tabId !== mainTabs.DEVICES ||
      activeTab?.tabId !== devicesTabs.PRINTERS
    ) {
      return [];
    }

    const printersPostions: {
      [key: string]: { posOneDrawer: string; posTwoDrawer: string };
    } = {};

    Object.values(drawers).forEach(drawer => {
      const drawerPrinterId = String(drawer.printerId);
      const drawerPosition = drawer.position;

      if (!(drawerPrinterId in printersPostions)) {
        printersPostions[drawerPrinterId] = {
          posOneDrawer: '- - -',
          posTwoDrawer: '- - -',
        };
      }

      if (drawerPosition === 1) {
        printersPostions[drawerPrinterId].posOneDrawer = drawer.name;
      }
      if (drawerPosition === 2) {
        printersPostions[drawerPrinterId].posTwoDrawer = drawer.name;
      }
    });

    return Object.keys(printers)
      .map(key => ({
        ...printers[key],
        id: Number(key),
        positionOne: printersPostions[key]?.posOneDrawer || '- - -',
        positionTwo: printersPostions[key]?.posTwoDrawer || '- - -',
        backupPrinterId: printers[key]?.backupPrinterId || 0,
        actions: '',
      }))
      .filter(item => {
        return (
          item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          item.serialNumber.toLowerCase().indexOf(searchText.toLowerCase()) !==
            -1
        );
      });
  }, [activeTab?.tabId, mainActiveTab.tabId, printers, searchText, drawers]);

  const terminalsTableArray = useMemo(
    () =>
      mainActiveTab.tabId === mainTabs.DEVICES &&
      activeTab?.tabId === devicesTabs.TERMINALS
        ? Object.keys(terminals)
            .map(key => ({
              ...terminals[key],
              id: Number(key),
              actions: '',
            }))
            .filter(item => {
              return (
                item.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                  -1 ||
                String(item.tpn)
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) !== -1 ||
                item.authKey.toLowerCase().indexOf(searchText.toLowerCase()) !==
                  -1
              );
            })
        : [],

    [mainActiveTab.tabId, activeTab?.tabId, terminals, searchText],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const closeModal = () => {
    setIsModalActive(false);
  };

  return (
    <Box
      csx={{
        padding: '15px',
      }}>
      <HeaderTitle title="Devices Settings" options={addDeviceOption} />
      <Card csx={devicesStyles}>
        <Grid rowGap={15} columnGap={15}>
          <Grid.Item lg={8} xl={9}>
            <Tabs
              data={DEVICES_DATA}
              activeTab={activeTab}
              onSelectedTabChange={handleOnSelectedTabChange}
              csx={{
                zIndex: 1,
                borderRadius: '10px',
                padding: '0',
                height: '50px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
            />
          </Grid.Item>
          <Grid.Item
            md={12}
            lg={4}
            xl={3}
            csx={{ height: '100%', justifyContent: 'center' }}>
            <SearchInput value={searchText} onChange={setSearchText} />
          </Grid.Item>
          <Grid.Item>
            {activeTab?.tabId === devicesTabs.TABLETS ? (
              <>
                <EditTabletModal active={isModalActive} onCancel={closeModal} />
                <TabletsTable
                  data={tabletsTableArray}
                  emptyBySearch={
                    tabletsTableArray?.length === 0 &&
                    Object.keys(tablets).length > 0
                  }
                />
              </>
            ) : activeTab?.tabId === devicesTabs.PRINTERS ? (
              <>
                <EditPrinterModal
                  active={isModalActive}
                  onCancel={closeModal}
                  onSuccess={closeModal}
                />

                <PrintersTable
                  data={printersTableArray}
                  emptyBySearch={
                    tabletsTableArray?.length === 0 &&
                    Object.keys(tablets).length > 0
                  }
                />
              </>
            ) : activeTab?.tabId === devicesTabs.TERMINALS ? (
              <>
                <EditTerminalModal
                  isActive={isModalActive}
                  onCancel={closeModal}
                  onSuccess={closeModal}
                  isAdd
                />
                <TerminalsTable
                  data={terminalsTableArray}
                  emptyBySearch={
                    terminalsTableArray?.length === 0 &&
                    Object.keys(terminals).length > 0
                  }
                />
              </>
            ) : null}
          </Grid.Item>
        </Grid>
      </Card>
    </Box>
  );
};

export default Devices;
