import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CategoryFilteredScreenContainer from '@app/components/common/CategoryFilteredScreenContainer';
import { ICategoryFilteredScreenContainer } from '@app/components/common/CategoryFilteredScreenContainer/CategoryFilteredScreenContainer';
import Grid from '@app/components/common/Grid';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCardComboList,
  selectCategoriesDropdownList,
  selectIsMenuMasterMode,
  selectMenuTypesDropdownList,
  selectSubcategoriesDropdownList,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { BREAKPOINTS } from '@app/theme/themes';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction, IComboCard } from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CombosTable from './CombosTable';

const CombosList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const { assignItemsToLocationWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const breadcrumbs = useSelector(selectBreadCrumbs);
  const combosCardList = useSelector(selectCardComboList);
  const categoriesOptions = useSelector(selectCategoriesDropdownList);
  const subcategoriesOptions = useSelector(selectSubcategoriesDropdownList);
  const menuTypesOptions = useSelector(selectMenuTypesDropdownList);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  // Local state
  const navigate = useNavigateWithOrg();
  const [additionModalState, setAdditionModalState] = useState(false);
  const [selectedMenuTypeId, setSelectedMenuTypeId] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  const handleResize = () => {
    if (window.innerWidth <= parseFloat(BREAKPOINTS.medium.max)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    // Window resize listener change isMobile state
    window.addEventListener('resize', handleResize);

    if (window.innerWidth <= parseFloat(BREAKPOINTS.medium.max)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const categoriesSet = useMemo(
    () => new Set(combosCardList.map(item => item.categoryId).flat()),
    [combosCardList],
  );

  const categoriesOptionsFiltered = useMemo(
    () =>
      categoriesOptions.filter(category => categoriesSet.has(category.value)),
    [categoriesOptions, categoriesSet],
  );

  const menusSet = useMemo(
    () => new Set(combosCardList.map(item => item.menuTypeId).flat()),
    [combosCardList],
  );

  const menusOptionsFiltered = useMemo(
    () => menuTypesOptions.filter(menu => menusSet.has(menu.value)),
    [menuTypesOptions, menusSet],
  );

  const dropdowns = useMemo(
    (): ICategoryFilteredScreenContainer<IComboCard>['dropdowns'] => [
      {
        id: 'menuTypeId',
        data: isMobile
          ? [[{ label: t('commonTexts.all'), value: 0 }], menusOptionsFiltered]
          : [
              [
                {
                  label:
                    menuTypesOptions.find(
                      _menuType => _menuType.value === selectedMenuTypeId,
                    )?.label || t('commonTexts.all'),
                  value: selectedMenuTypeId,
                },
              ],
            ],
        label: 'Menu',
        defaultValue: 0,
        dataExample: 0,
        isEditable: isMobile ? true : false,
      },
      {
        id: 'categoryId',
        data: [
          [{ label: t('commonTexts.all'), value: 0 }],
          categoriesOptionsFiltered,
        ],
        label: 'Category',
        defaultValue: 0,
        dataExample: 0,
      },
      {
        id: 'subcategoryId',
        data: [],
        getData: ({ categoryId: categoryIdFilter }) => {
          let _subcategoriesOptions: typeof subcategoriesOptions = [];
          if (typeof categoryIdFilter !== 'string') {
            _subcategoriesOptions = subcategoriesOptions.filter(
              option => option.categoryId === categoryIdFilter,
            );
          }
          return [
            [{ label: t('commonTexts.all'), value: 0 }],
            _subcategoriesOptions,
          ];
        },
        label: 'Subcategory',
        defaultValue: 0,
        dataExample: 0,
        getIsDisabled: (data, { categoryId: categoryIdFilter }) => {
          if (typeof categoryIdFilter === 'string' || categoryIdFilter === 0)
            return true;
          return !data[1].length;
        },
      },
    ],
    [
      categoriesOptionsFiltered,
      subcategoriesOptions,
      t,
      menusOptionsFiltered,
      selectedMenuTypeId,
      isMobile,
      menuTypesOptions,
    ],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(6, 54, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewCombo = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewComboTag'),
      onPress: () => navigate('/menu/combos/combos/add'),
      pathURL: '/menu/combos/combos/add',
    });
  };

  const actionDropdownOptions = useMemo(
    () => {
      const actions = [
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewCombo'),
          handler: handleAddNewCombo,
        },
      ];

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  const handleItemAssociation = (buttons: IItemSelection[]) => {
    assignItemsToLocationWeb(
      buttons.map(button => Number(button.id)),
      'combos',
    );
    setAdditionModalState(false);
  };

  return (
    <>
      <SelectionModal
        type="combos"
        mainScreenProps={{ entity: 'combos' }}
        active={additionModalState}
        onModalClose={() => setAdditionModalState(false)}
        onAssociate={handleItemAssociation}
      />

      <CategoryFilteredScreenContainer
        dropdowns={dropdowns}
        menusSet={menusSet}
        menuTypeOnChange={(id, value) => {
          if (id === 'menuTypeId') {
            setSelectedMenuTypeId(value);
          }
        }}
        breakpoint="medium"
        data={combosCardList}
        optionList={actionDropdownOptions}
        noItemsText={t('commonTexts.noneFound', {
          elementName: t('screenNames.CombosScreen'),
        })}
        sortAvailable={!showListView}
        groupFilters
        showChildren={showListView}
        customButtons={
          <Grid.Item
            mb={12}
            sm={showListView ? 7 : 6}
            md={showListView ? 7 : 6}
            lg={showListView ? 5 : 4}
            xl={showListView ? 6 : 5}>
            <Box
              csx={theme => ({
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                borderLeft: `1px solid ${theme.colors.lightGrey}`,
                paddingLeft: '15px',
              })}>
              <Switch
                checked={showListView}
                onChange={isActive => setShowListView(isActive)}
                label={t('menuScreen.itemsMain.showListView')}
              />
            </Box>
          </Grid.Item>
        }
        renderItem={combo => {
          const pathURL = `/menu/combos/combos/${combo.id}`;
          return (
            <Card.Item
              {...combo}
              isActive={true}
              showStatus={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: combo.title,
                  onPress: () => {
                    navigate(pathURL, {
                      state: { menuTypeId: selectedMenuTypeId },
                    });
                  },
                  statusPath: `selectedMode.currentMenu.combos.${combo.id}.active`,
                  pathURL,
                });
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}>
        {filterParams => <CombosTable filterParams={filterParams} />}
      </CategoryFilteredScreenContainer>
    </>
  );
};

export default CombosList;
