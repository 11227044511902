import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import ButtonLink from '@app/components/common/Button/ButtonLink';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { checkForChangesAndNavigateWeb } from '@app/state/menu/menuWebActions';
import { useTheme } from '@emotion/react';
import {
  BreadCrumbAction,
  FormMode,
  MenuTypes,
  PriceCalcModes,
  QuantityModes,
  TPriceCalcModes,
  TQuantityModeTypes,
} from '@westondev/tableturn-core';
import { upperCase } from 'lodash';
import { useRef } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IHeader extends WithTranslation {
  posName: string;
  active: boolean;
  modifierGroupId: number;
  onDeleteModifierGroupPress: (modifierGroupId: number, name: string) => void;
  menuTypeMode: MenuTypes;
  modifierGroupInfo: {
    quantityMode: TQuantityModeTypes;
    multiselect: boolean;
    priceCalcMode: TPriceCalcModes;
    allowNoneSelection: boolean;
  };
  mode?: FormMode;
  handleHideModifierGroup: (modifierGroupId: number, active: boolean) => void;
}

const Header = ({
  t,
  active,
  posName,
  modifierGroupId,
  menuTypeMode,
  onDeleteModifierGroupPress,
  modifierGroupInfo = {
    allowNoneSelection: false,
    multiselect: false,
    priceCalcMode: 'max',
    quantityMode: 'Off',
  },
  mode,
}: IHeader) => {
  const { multiselect, priceCalcMode, quantityMode, allowNoneSelection } =
    modifierGroupInfo;

  const dispatch = useDispatch();

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const checkForChangesAndNavigate = bindActionCreators(
    checkForChangesAndNavigateWeb,
    dispatch,
  );

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();
  const theme = useTheme();
  const listItemRef = useRef<HTMLDivElement>(null);

  const pathURL = `/menu/modifiers/modifier-groups/${modifierGroupId}`;

  return (
    <>
      <Box
        ref={listItemRef}
        csx={{
          display: 'flex',
          paddingBlock: '10px',
          gap: '10px',
        }}>
        <Box csx={{ display: 'flex', gap: '10px', opacity: active ? 1 : 0.4 }}>
          <PillDisplay
            isActive={allowNoneSelection}
            label={t(
              'menuScreen.itemDetails.modifierGroupsSection.allowNoneSelection',
            )}
            value={allowNoneSelection ? 'ON' : 'OFF'}
          />
          <PillDisplay
            isActive={quantityMode !== QuantityModes.OFF}
            label={t('menuScreen.itemDetails.modifierGroupsSection.quantity')}
            value={
              quantityMode === QuantityModes.OFF
                ? upperCase(quantityMode)
                : quantityMode
            }
          />
          <PillDisplay
            isActive={multiselect}
            label={t(
              'menuScreen.itemDetails.modifierGroupsSection.multiselect',
            )}
            value={multiselect ? 'ON' : 'OFF'}
          />
          <PillDisplay
            isActive={true}
            label={t(
              'menuScreen.itemDetails.modifierGroupsSection.pricingMode',
            )}
            value={
              priceCalcMode === PriceCalcModes.MAX ? 'Maximize' : 'Minimize'
            }
          />
        </Box>
        <Divider
          direction="vertical"
          lineCsx={{ borderColor: theme.colors.lightGrey }}
        />
        {mode !== FormMode.MASS_UPDATE && (
          <ButtonLink
            to={getPathWithOrgData(pathURL)}
            variant="primary"
            iconPosition="right"
            icon={<Icon name="MdExitToApp" />}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);

              checkForChangesAndNavigate(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.NAV,
                    text: posName,
                    onPress: () => navigate(pathURL),
                    pathURL,
                  }),
                onRefresh,
              );
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.editModifier')}
          </ButtonLink>
        )}
      </Box>
      <Button
        csx={{
          display: 'flex',
          minWidth: '50px',
          height: '100%',
          borderTopWidth: '0',
          borderRightWidth: '0',
          borderLeftWidth: '1px',
          borderBottomWidth: '0',
          borderRadius: '0',
        }}
        variant={
          menuTypeMode === MenuTypes.MASTER_MENU ||
          mode === FormMode.MASS_UPDATE
            ? 'danger'
            : 'primary'
        }
        onClick={() => onDeleteModifierGroupPress(modifierGroupId, posName)}
        icon={
          menuTypeMode === MenuTypes.MASTER_MENU ||
          mode === FormMode.MASS_UPDATE ? (
            <Icon name="MdDeleteForever" />
          ) : active ? (
            <Icon name="MdVisibility" />
          ) : (
            <Box>
              <Icon name="MdVisibilityOff" color="textWhite" />
              <Typography variant="caption2" color="textWhite">
                {t('commonTexts.hidden')}
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
};

interface IPillDisplay {
  isActive: boolean;
  label: string;
  value: string;
}

export const PillDisplay = ({ isActive, label, value }: IPillDisplay) => {
  const theme = useTheme();

  const redLabelAndButton = theme.colors.semanticRed;
  const blueText = theme.colors.semanticBlue;
  const tagBackgroundColor = theme.colors.lighterBlue;
  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
      }}>
      <Typography csx={{ whiteSpace: 'nowrap' }}>{label}</Typography>
      <Box
        csx={{
          display: 'flex',
          borderRadius: '100px',
          padding: '15px',
          borderWidth: isActive ? '2px' : 0,
          borderColor: isActive ? blueText : redLabelAndButton,
          backgroundColor: isActive ? tagBackgroundColor : redLabelAndButton,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant="caption"
          csx={{
            fontWeight: theme.fontWeights.medium,
            color: isActive ? blueText : theme.colors.textWhite,
          }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
