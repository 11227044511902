import i18n from '../../i18n.config';

export const formatValue = (value: unknown, field: string): string => {
  if (typeof value !== 'boolean') return value as string;

  if (field !== 'active')
    return value ? i18n.t('commonTexts.yes') : i18n.t('commonTexts.no');

  return value ? i18n.t('commonTexts.active') : i18n.t('commonTexts.inactive');
};
