import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import { TCsx } from '@emotion/react';
import { OrderStatus } from '@westondev/tableturn-core';

const ItemComboTableOrderStatusesCell = ({
  orderStatuses: orderStatus,
  ...props
}: {
  orderStatuses: null | OrderStatus[];
  csx?: TCsx;
}) => {
  const orderStatusNames = useReportsFilterText('orderStatuses', orderStatus);

  return <Typography {...props}>{orderStatusNames}</Typography>;
};

export default ItemComboTableOrderStatusesCell;
