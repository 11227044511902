import { ForwardedRef } from 'react';

export const VALID_KEYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Backspace',
  'Tab',
  'Enter',
];

export interface CodeVerifierRef {
  resetInput: () => void;
}

export type CodeVerifierComponetRef = ForwardedRef<CodeVerifierRef>;
