import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Typography from '@app/components/common/Typography';
import {
  areSettingsMasterModeSelector,
  makeSelectSettingsData,
  selectOrderTypesAvailable,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import {
  SettingsSectionId,
  ITaxesSettings,
  actionCreatorsSettings,
  OrderType,
  ITaxTypeData,
  ITableTax,
  ITaxSetting,
  TaxType,
  VolumeUnitType,
  taxTypeDropdownFactory,
  taxTypeToValue,
  valueToTaxType,
  typesText,
  ITableTaxData,
  orderTypeDropdownOptionsFactory,
  PRINTER_RULES_ORDER_TYPE_OPTIONS,
  actionCreatorsSettingsChangeData,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import TaxTypeForm from './TaxTypeForm';
import TaxTableForm from './TaxTableForm';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import { getTaxesId } from './helpers';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SECTION_ID = SettingsSectionId.TAXES;
const getMemoizedItemData = makeSelectSettingsData<ITaxesSettings>(SECTION_ID);

const TaxesDetails = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();

  const existingTaxId = useMatch(getPathWithOrgData('/settings/taxes/:id'))
    ?.params.id;
  const isCreateMode = useMatch(getPathWithOrgData('/settings/taxes/add'));
  const newTaxId = useLocation()?.state?.id;
  const taxId = isCreateMode ? newTaxId : existingTaxId;

  const isDisabled = !useSelector(areSettingsMasterModeSelector);

  const { data } = useSelector(getMemoizedItemData);

  const orderTypesAvailable = useSelector(selectOrderTypesAvailable);

  const orderTypeDropdownOptions = useMemo(
    () => orderTypeDropdownOptionsFactory(t, orderTypesAvailable),
    [t, orderTypesAvailable],
  );

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const settingsErrors = useSelector(settingsErrorsSelector);

  const taxData: ITaxSetting | undefined = data?.[taxId || ''];

  useEffect(() => {
    if (isCreateMode && !taxData) navigate('/settings/taxes');
  }, [navigate, isCreateMode, taxData]);

  const updateRow = (
    newValue: number | string | OrderType[] | ITaxTypeData | ITableTax | null,
    id: number,
    field: keyof ITaxSetting,
  ) => {
    const tableObject = store.getState().settings.changeData
      .data as ITaxesSettings;

    if (field === 'tableTypeData') {
      setValue<ITaxesSettings>(
        {
          ...tableObject,
          [id]: {
            ...tableObject[id],
            tableTypeData: {
              ...tableObject[id].tableTypeData,
              ...(newValue as ITableTax),
            },
          },
        },
        SECTION_ID,
      );
      return;
    }

    setValue<ITaxesSettings>(
      {
        ...tableObject,
        [id]: {
          ...tableObject[id],
          [field]: newValue,
        },
      },
      SECTION_ID,
    );
  };

  const addTaxTableTypeRow = (id: keyof ITaxesSettings) => {
    const changeData = store.getState().settings.changeData;
    const tableObject = changeData.data as ITaxesSettings;
    const originalData = changeData.originalData as ITaxesSettings;

    let maxId = 0;
    for (const [key] of Object.entries(originalData[id]?.tableTypeData || {})) {
      maxId = Math.max(maxId, Number(key));
    }

    let newInitial = 0;
    for (const [key, value] of Object.entries(
      tableObject[id]?.tableTypeData || {},
    )) {
      newInitial = Math.max(newInitial, value.to);
      maxId = Math.max(maxId, Number(key));
    }

    maxId++;
    newInitial += 0.01;
    newInitial = Number(newInitial.toFixed(2));

    const defaultData: ITableTaxData = {
      from:
        Object.entries(tableObject[id]?.tableTypeData || {}).length === 0
          ? 0
          : newInitial,
      to: 0,
      tax: 0,
      repeat: true,
      id: maxId,
    };

    setValue<ITaxesSettings>(
      {
        ...tableObject,
        [id]: {
          ...tableObject[id],
          tableTypeData: {
            ...tableObject[id].tableTypeData,
            [maxId]: defaultData,
          },
        },
      },
      SECTION_ID,
    );
  };

  const updateOrderTypes = (values: IDropdownItem[]) => {
    const sortedValues = values.sort((a, b) => a.label.localeCompare(b.label));
    for (const { value } of sortedValues) {
      if (value === 0) {
        const sortedOrderTypes = orderTypeDropdownOptions[0].sort((a, b) =>
          a.label.localeCompare(b.label),
        );
        const orderTypes: string = sortedOrderTypes
          .slice(1)
          .map(
            orderType =>
              PRINTER_RULES_ORDER_TYPE_OPTIONS[orderType.value] as OrderType,
          )
          .join(',');
        updateRow(orderTypes, taxData?.id, 'orderTypes');
        return;
      }
    }

    const orderTypes: string = sortedValues
      .map(
        orderType =>
          PRINTER_RULES_ORDER_TYPE_OPTIONS[orderType.value] as OrderType,
      )
      .join(',');
    updateRow(orderTypes, taxData?.id, 'orderTypes');
  };

  const updateType = (newValue: TaxType, id: number) => {
    const tableObject = store.getState().settings.changeData
      .data as ITaxesSettings;

    let typeData = null;
    let tableTypeData = null;

    if (newValue === TaxType.FIXED || newValue === TaxType.PERCENT) {
      typeData = {
        unit: null,
        interval: null,
        rate: 0,
      };
      tableTypeData = null;
    } else if (newValue === TaxType.VOLUMETRIC) {
      typeData = { unit: VolumeUnitType.ML, interval: 0, rate: 0 };
      tableTypeData = null;
    } else {
      typeData = null;
      tableTypeData = {};
    }

    setValue<ITaxesSettings>(
      {
        ...tableObject,
        [id]: {
          ...tableObject[id],
          taxType: newValue,
          typeData,
          tableTypeData,
        },
      },
      SECTION_ID,
    );
  };

  const generateDropdownOptionForOrderTypes = () => {
    const selectedOptions = orderTypeDropdownOptions[0]
      .filter(dropdownItem => {
        const orderTypes = taxData?.orderTypes.split(',');
        for (const orderType of orderTypes) {
          if (
            dropdownItem.value ===
            PRINTER_RULES_ORDER_TYPE_OPTIONS.indexOf(orderType)
          )
            return true;
        }
      })
      .map(dropdownItem => dropdownItem.value);

    return selectedOptions;
  };

  return (
    taxData && (
      <>
        <Accordion showExpandCollapseButtons={false}>
          <AccordionSection
            title={t('settingsModule.taxesSettings.titleLong')}
            isExpanded
            isExpandable={false}>
            <Grid
              rowGap={20}
              columnGap={15}
              csx={{
                '& > div': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
              }}>
              <Grid.Item mb={12} sm={6} md={6} xl={3}>
                <Input
                  required
                  disabled={isDisabled}
                  label={t('settingsModule.taxesSettings.taxesTable.name')}
                  placeholder={t(
                    'settingsModule.taxesSettings.taxesTable.name',
                  )}
                  value={taxData?.name}
                  onChange={e =>
                    updateRow(e.currentTarget.value, taxData?.id, 'name')
                  }
                  error={Boolean(
                    settingsErrors[getTaxesId(taxData?.id, 'name')],
                  )}
                  caption={
                    settingsErrors[getTaxesId(taxData?.id, 'name')]?.data
                      ?.message
                  }
                  onFocus={() =>
                    clearSettingsError(getTaxesId(taxData?.id, 'name'))
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} md={6} xl={3}>
                <Dropdown.MultiSelect
                  required
                  returnItemOnChange
                  label={t(
                    'settingsModule.taxesSettings.taxesTable.orderTypes',
                  )}
                  placeholder={t('commonTexts.placeholderDropdown')}
                  data={orderTypeDropdownOptions}
                  value={generateDropdownOptionForOrderTypes()}
                  onChange={values => updateOrderTypes(values)}
                  isDisabled={isDisabled}
                  error={Boolean(
                    settingsErrors[getTaxesId(taxData?.id, 'orderTypes')],
                  )}
                  // FIXME: activate when caption is added
                  // caption={
                  //   settingsErrors[getTaxesId(taxData?.id, 'orderTypes')]?.data
                  //     ?.message
                  // }
                />
              </Grid.Item>

              <Grid.Item mb={12} sm={6} md={6} xl={3}>
                <Dropdown.SingleSelect
                  required
                  label={t(
                    'settingsModule.taxesSettings.taxesTable.selectTaxType',
                  )}
                  placeholder={t('commonTexts.placeholderDropdown')}
                  data={taxTypeDropdownFactory()}
                  value={taxTypeToValue[taxData?.taxType]}
                  onChange={value =>
                    updateType(valueToTaxType[value], taxData?.id)
                  }
                  isDisabled={isDisabled}
                  error={Boolean(
                    settingsErrors[getTaxesId(taxData?.id, 'taxType')],
                  )}
                  // FIXME: activate when caption is added
                  // caption={
                  //   settingsErrors[getTaxesId(taxData?.id, 'taxType')]?.data
                  //     ?.message
                  // }
                />
              </Grid.Item>
            </Grid>

            <Divider csx={{ margin: '20px 0' }} />

            <Box
              csx={{
                gap: '20px',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography
                csx={theme => ({
                  fontWeight: theme.fontWeights.medium,
                  color: theme.colors.semanticBlue,
                })}>
                {t('settingsModule.taxesSettings.taxesTable.taxType')}:{' '}
                {typesText[taxData?.taxType]}
              </Typography>
              {taxData?.taxType === TaxType.TABLE ? (
                <TaxTableForm
                  taxData={taxData}
                  updateRow={updateRow}
                  isDisabled={isDisabled}
                  addTaxTableTypeRow={addTaxTableTypeRow}
                />
              ) : (
                <TaxTypeForm
                  taxData={taxData}
                  updateRow={updateRow}
                  isDisabled={isDisabled}
                />
              )}
            </Box>
          </AccordionSection>
        </Accordion>
      </>
    )
  );
};

export default TaxesDetails;
