import {
  DEFAULT_MAX_SIZE,
  DEFAULT_MIN_SIZE,
  DEFAULT_SIZE,
} from '@app/components/common/Table/types';
import { Column } from '@tanstack/react-table';

const getCellSizes = <T>(column: Column<T, unknown>, isInverted: boolean) => {
  const columnDef = column.columnDef;

  if (isInverted) {
    return {
      flexGrow: 1,
      flexBasis: 30,
      minWidth: 'auto',
      maxWidth: 'auto',
    };
  }
  if (
    columnDef.minSize === DEFAULT_MIN_SIZE &&
    columnDef.size === DEFAULT_SIZE &&
    columnDef.maxSize === DEFAULT_MAX_SIZE
  )
    return {
      flexGrow: 1,
      flexBasis: 100,
      minWidth: 100,
      maxWidth: 'none',
    };

  const minWidth =
    columnDef.minSize === DEFAULT_MIN_SIZE && columnDef.size !== DEFAULT_SIZE
      ? columnDef.size
      : columnDef.minSize;
  return {
    flexGrow: columnDef.maxSize === DEFAULT_MAX_SIZE ? 1 : 0,
    flexBasis: minWidth,
    minWidth,
    maxWidth:
      columnDef.maxSize === DEFAULT_MAX_SIZE
        ? columnDef.size !== DEFAULT_SIZE
          ? columnDef.size
          : 'none'
        : columnDef.maxSize,
  };
};

export { getCellSizes };
