import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { menuTaxesFactory } from '@westondev/tableturn-core';
import without from 'lodash//without';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GenericSelectionModal from '../GenericSelectionModal';
import { ISelectionModal } from '../SelectionModal';

type ITaxesSelectionModal = Omit<ISelectionModal<unknown>, 'buttons' | 'type'> &
  WithTranslation & {
    taxIds: number[];
  };

const TaxesSelectionModal = ({
  t,
  active,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  btnSuccessText,
  taxIds,
}: ITaxesSelectionModal) => {
  //redux
  const taxes = useSelector(currentTaxesSettingsSelector);

  const buttons = useMemo(() => {
    const availablePrepStations = without(
      Object.keys(taxes).map(prepStationId => Number(prepStationId)),
      ...taxIds,
    );

    return menuTaxesFactory(availablePrepStations, taxes);
  }, [taxIds, taxes]);

  return (
    <>
      {active && (
        <GenericSelectionModal
          onPress={() => null}
          onAssociate={onAssociate}
          onModalClose={onModalClose}
          active={active}
          buttons={buttons}
          title={t('menuScreen.selectionModal.taxes.title')}
          selectedSectionTitle={t(
            'menuScreen.selectionModal.taxes.selectedSectionTitle',
          )}
          emptySectionMessage={t(
            'menuScreen.selectionModal.taxes.emptySectionText',
          )}
          noCardSelectedMessage={t(
            'menuScreen.selectionModal.taxes.noCardSelectedText',
          )}
          noAvailableCardsMessage={t(
            'menuScreen.selectionModal.taxes.noAvailableCardsText',
          )}
          allowAssociateMultiple={allowAssociateMultiple}
          showDescriptionPanel={false}
          showSelectedButtons
          btnSuccessText={btnSuccessText}
        />
      )}
    </>
  );
};

export default TaxesSelectionModal;
