import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import DatePicker from '@app/components/common/DatePicker/DatePicker';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import {
  TDropdownData,
  IDropdownItem,
} from '@app/components/common/Dropdown/types';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import useLoadReportsChangeData from '@app/hooks/useLoadReportsChangeData';
import { handleReportsChange } from '@app/state/reports/reportsWebActions';
import {
  actionCreatorsReports,
  EGroupByItemComboSalesReport,
  EReportPeriod,
  getPeriodTranslation,
  IItemComboSalesReport,
  IReportsStore,
  TReportsErrors,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ReportFiltersSection from './ReportFiltersSection';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { labelStyles } from '@app/components/common/Input/styles';
import useRootSelector from '@app/hooks/useRootSelector';
import { ParseKeys } from 'i18next';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { useCallback } from 'react';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import EndOfShiftReportOwnerFilter from './EndOfShiftReportFilters/EndOfShiftReportOwnerFilter';

const { periodValueToPeriod, periodToPeriodValue } = Object.values(
  EReportPeriod,
).reduce(
  (acc, value) => {
    acc.periodValueToPeriod[acc.index] = value;
    acc.periodToPeriodValue[value] = acc.index;
    acc.index++;
    return acc;
  },
  { index: 0, periodValueToPeriod: {}, periodToPeriodValue: {} } as {
    index: number;
    periodValueToPeriod: Record<number, EReportPeriod>;
    periodToPeriodValue: Record<EReportPeriod, number>;
  },
);
const periodDropdownOptions: TDropdownData = [
  Object.values(EReportPeriod).map(
    (period, idx): IDropdownItem => ({
      label: getPeriodTranslation(period),
      value: idx,
    }),
  ),
];

interface IReportForm extends WithTranslation {
  bucket: keyof IReportsStore;
}

const ReportForm = ({ t, bucket }: IReportForm) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();
  const setValues = bindActionCreators(handleReportsChange, dispatch);
  const resetError = bindActionCreators(
    actionCreatorsReports.resetReportsFieldErrors,
    dispatch,
  );

  const id = useParams().id;
  const isAdd = useMatch(getPathWithOrgData('/reports/:bucket/add'));

  const report = useLoadReportsChangeData<typeof bucket>(
    isAdd ? 0 : Number(id),
    bucket,
  );

  const nameError = useRootSelector(
    state => state.reports.changeData.errors?.name ?? '',
  );
  const groupByError = useRootSelector(
    state =>
      (
        state.reports.changeData
          .errors as TReportsErrors<'itemComboSalesReports'>
      )?.groupBy ?? '',
  );

  const startDateError = useRootSelector(
    state => state.reports.changeData.errors?.startDate ?? '',
  );
  const endDateError = useRootSelector(
    state => state.reports.changeData.errors?.endDate ?? '',
  );

  const handleGoBack = useCallback(() => {
    const path = REPORTS_SCREEN_BY_BUCKET_NAME[bucket];
    if (!id) {
      return navigate(path);
    }
    navigate(`${path}/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, id]);

  if (!report) return;

  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Button onClick={handleGoBack} variant="transparent">
          <Icon name="MdArrowBack" size="32px" />
        </Button>

        <Typography variant="heading" fontWeight="medium">
          {t(`reports.addEditScreen.${bucket}${id ? 'Edit' : 'Add'}`)}
        </Typography>
      </Box>
      <Divider
        lineCsx={theme => ({
          borderColor: theme.colors.lightGrey,
        })}
      />

      <Card
        csx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          padding: 20,
        }}>
        <Typography variant="subtitle" fontWeight="medium">
          {t('reports.addEditScreen.reportSettings')}
        </Typography>

        <Switch
          checked={report.isActive}
          onChange={v =>
            setValues(_values => ({
              ..._values,
              isActive: v,
            }))
          }
          showDefaultLabel
        />
        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12} sm={6} md={3}>
            <Input
              required
              value={report.name}
              onChange={e =>
                setValues(_values => ({
                  ..._values,
                  name: e.currentTarget.value,
                }))
              }
              label={t('reports.fields.name')}
              error={!!nameError}
              caption={nameError && t(`validations.${nameError}` as ParseKeys)}
              onFocus={() => resetError('name')}
            />
          </Grid.Item>

          {bucket === 'itemComboSalesReports' && (
            <Grid.Item mb={12} sm={6} md={3}>
              <Typography csx={labelStyles}>
                {t('reports.fields.groupBy')}
              </Typography>
              <Dropdown
                data={[
                  [
                    {
                      label: t('reports.fields.groupByOptions.category'),
                      value: 0,
                    },
                    {
                      label: t(
                        'reports.fields.groupByOptions.reporting_groups',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                value={
                  (report as IItemComboSalesReport).groupBy ===
                  EGroupByItemComboSalesReport.CATEGORY
                    ? 0
                    : 1
                }
                onChange={v => {
                  setValues({
                    ...report,
                    groupBy:
                      v === 0
                        ? EGroupByItemComboSalesReport.CATEGORY
                        : EGroupByItemComboSalesReport.REPORTING_GROUPS,
                  });
                  resetError<'itemComboSalesReports'>('groupBy');
                }}
                error={!!groupByError}
                caption={
                  groupByError && t(`validations.${groupByError}` as ParseKeys)
                }
              />
            </Grid.Item>
          )}

          {bucket === 'endOfShiftReports' && (
            <Grid.Item mb={12} sm={6} md={3}>
              <EndOfShiftReportOwnerFilter />
            </Grid.Item>
          )}
        </Grid>

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <Typography variant="subtitle" fontWeight="medium" color="darkBlue">
          {t('reports.fields.period')}
        </Typography>
        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12} sm={6} md={3}>
            <Typography csx={labelStyles}>Report Period</Typography>
            <Dropdown
              data={periodDropdownOptions}
              onChange={v =>
                setValues({
                  ...report,
                  period:
                    periodValueToPeriod[v as keyof typeof periodValueToPeriod],
                })
              }
              value={periodToPeriodValue[report.period]}
            />
          </Grid.Item>
          {report.period === EReportPeriod.CUSTOM && (
            <>
              <Grid.Item mb={12} sm={6} md={3}>
                <Typography csx={labelStyles}>
                  {t('reports.fields.startDate')}
                </Typography>
                <DatePicker
                  required
                  utcValue={report.startDate}
                  onChange={v =>
                    setValues(_values => ({
                      ..._values,
                      startDate: v,
                    }))
                  }
                  error={!!startDateError}
                  caption={
                    startDateError &&
                    t(`validations.${startDateError}` as ParseKeys)
                  }
                  onFocus={() => resetError('startDate')}
                />
              </Grid.Item>

              <Grid.Item mb={12} sm={6} md={3}>
                <Typography csx={labelStyles}>
                  {t('reports.fields.endDate')}
                </Typography>
                <DatePicker
                  required
                  utcValue={report.endDate}
                  onChange={v =>
                    setValues(_values => ({
                      ..._values,
                      endDate: v,
                    }))
                  }
                  error={!!endDateError}
                  caption={
                    endDateError &&
                    t(`validations.${endDateError}` as ParseKeys)
                  }
                  onFocus={() => resetError('endDate')}
                />
              </Grid.Item>
            </>
          )}
        </Grid>

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <ReportFiltersSection />
      </Card>
    </Box>
  );
};

export default ReportForm;
