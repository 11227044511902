import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import { errorContainerStyles, errorStyles } from './styles';

export const ErrorTemplates = ({
  field,
  errorKey,
  translationParams,
}: {
  field: string;
  errorKey: string;
  translationParams?: Record<string, string>;
}) => {
  const { t } = useTranslation();
  const [bucket, error] = errorKey.includes('-')
    ? errorKey.split('-')
    : ['Item', errorKey];

  switch (error) {
    case 'nameRequired':
    case 'posNameRequired':
    case 'kdsNameRequired':
    case 'checkNameRequired':
    case 'onlineNameRequired':
    case 'promptMessageRequired':
      return (
        <Box csx={errorContainerStyles}>
          <Typography csx={errorStyles}>{`${field} `}</Typography>
          <Typography csx={errorStyles}>
            {' '}
            {t('validations.notEmpty')}.
          </Typography>
        </Box>
      );
    case 'categoryRequired':
    case 'subcategoryRequired':
      return (
        <Box csx={errorContainerStyles}>
          <Typography csx={errorStyles}>
            {t('validations.itemMustBelongTo', { bucket })}
          </Typography>
          <Typography csx={errorStyles}>{field}</Typography>.
        </Box>
      );
    case 'itemGroupUniqueNameRequired':
    case 'ingredientNameRepeated':
      return (
        <Box csx={errorContainerStyles}>
          <Typography csx={errorStyles}>{t('commonTexts.name')}</Typography>
          <Typography csx={errorStyles}>{t(`validations.${error}`)}</Typography>
        </Box>
      );
    default:
      return (
        <Typography csx={errorStyles}>
          {t(`validations.${error}` as ParseKeys, {
            bucket,
            ...translationParams,
          })}
        </Typography>
      );
  }
};
