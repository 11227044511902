import Box from '@app/components/common/Box';
import { CSSProperties, forwardRef } from 'react';
import { ItemProps } from './types';

// eslint-disable-next-line react/display-name
const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ withOpacity, isDragging, style, children, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      transformOrigin: '50% 50%',
      cursor: isDragging ? 'grabbing' : 'grab',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style,
    };

    return (
      <Box ref={ref} style={inlineStyles} {...props}>
        {children}
      </Box>
    );
  },
);

export default Item;
