import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { ParseKeys } from 'i18next';
import { WithTranslation } from 'react-i18next';
import DataOptions from './DataOptions';
import { shallowEqual, useSelector } from 'react-redux';
import { selectSelectedElement } from '@app/state/selectors/tableLayoutSelectors';
import { selectSelectedLayout } from '@app/state/selectors/settingsSelectors';
import SizeOptions from './SizeOptions';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { actionCreatorsTableLayout } from '@westondev/tableturn-core';
import { bindActionCreators } from '@reduxjs/toolkit';
import useOutsideClick from '@app/hooks/useOutsideClick';
import { useEffect, useMemo, useRef } from 'react';
import {
  tableLayoutContentStyles,
  tableLayoutHeaderStyles,
  tableLayoutSidebarStyles,
} from './styles';
import { AnimatePresence, m, useAnimate, usePresence } from 'framer-motion';
import { checkIfTableNameHasError } from '@app/state/settings/tableLayoutWebActions';
import { useAppDispatch } from '@app/state/store';

const TableLayoutSideBar = ({ t }: WithTranslation) => {
  // Redux
  const selectedElement = useSelector(selectSelectedElement, shallowEqual);
  const selectedLayout = useSelector(selectSelectedLayout, shallowEqual);

  const dispatch = useAppDispatch();
  const { deleteElementFromLayout, duplicateElement, selectElement } =
    bindActionCreators(actionCreatorsTableLayout, dispatch);

  const sideBarRef = useRef<HTMLDivElement>(null);

  useOutsideClick([sideBarRef], true, async () => {
    handleCheckIfTableNameHasErrorAndContinue(() => null);
  });

  // Local state
  const elementData = useMemo(
    () =>
      selectedElement && selectedLayout
        ? selectedLayout[selectedElement.id]
        : null,
    [selectedElement, selectedLayout],
  );

  const handleCheckIfTableNameHasErrorAndContinue = async (
    onContinue: () => void,
  ) => {
    const hasError = await dispatch(checkIfTableNameHasError());
    if (!hasError) {
      onContinue();
    }
  };

  return (
    <>
      <AnimatePresence>
        {selectedElement && (
          <AnimatedOpacityContainer>
            <Box ref={sideBarRef} csx={tableLayoutSidebarStyles}>
              <Box csx={tableLayoutHeaderStyles}>
                <Button
                  variant="icon"
                  onClick={() =>
                    handleCheckIfTableNameHasErrorAndContinue(() => {
                      selectElement(null);
                    })
                  }
                  csx={{ marginRight: '10px' }}
                  icon={<Icon name="MdClear" />}
                />
                {selectedElement && (
                  <Typography fontWeight="medium" variant="subtitle">
                    {t(
                      'settingsModule.tableLayoutCreator.sideBar.detailsPanel',
                      {
                        type: t(
                          `settingsModule.tableLayoutCreator.bottomSheet.elements.${selectedElement?.type}` as ParseKeys,
                        ),
                      },
                    )}
                  </Typography>
                )}
              </Box>
              <Box csx={tableLayoutContentStyles}>
                <Grid rowGap={20} csx={{ padding: 15 }}>
                  {selectedElement && elementData && (
                    <>
                      <DataOptions
                        key="tlc-data-options"
                        selectedElement={selectedElement}
                        elementData={elementData}
                      />
                      <SizeOptions
                        key="tlc-size-options"
                        selectedElement={selectedElement}
                        elementData={elementData}
                      />
                    </>
                  )}
                </Grid>
              </Box>
              <Box
                csx={theme => [
                  displayFlexRowStyles,
                  {
                    gap: '10px',
                    paddingInline: '15px',
                    paddingBlock: '10px',
                    flexGrow: 0,
                    borderTop: `1px solid ${theme.colors.lightGrey}`,
                  },
                ]}>
                <Button
                  variant="danger"
                  csx={{ width: '100%' }}
                  icon={<Icon name="MdDeleteForever" />}
                  onClick={deleteElementFromLayout}>
                  {t('commonButtons.remove')}
                </Button>
                <Button
                  variant="secondary"
                  csx={{ width: '100%' }}
                  icon={<Icon name="MdContentCopy" />}
                  onClick={() =>
                    handleCheckIfTableNameHasErrorAndContinue(() =>
                      duplicateElement(),
                    )
                  }>
                  {t('commonButtons.duplicate')}
                </Button>
              </Box>
            </Box>
          </AnimatedOpacityContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export const AnimatedOpacityContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: 1 },
          {
            duration: 0.1,
          },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: 0 },
          {
            duration: 0,
          },
        );
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <m.div ref={scope} style={{ zIndex: 3 }} initial={{ opacity: 0 }}>
      {children}
    </m.div>
  );
};

export default TableLayoutSideBar;
