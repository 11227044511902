import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';

export const toolStyles = css({
  position: 'absolute',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingBlock: '8px',
  paddingInline: '15px',
  alignItems: 'flex-start',
  zIndex: 1,
  '> div': {
    gap: '7px',
    button: {
      width: '40px',
      height: '30px',
    },
  },
  [MQ_MIN_MEDIUM]: {
    top: '5px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '> div': {
      button: {
        width: '50px',
        height: '50px',
      },
    },
  },
});
