import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import {
  selectCombos,
  selectItems,
  selectReportingGroups,
} from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IReportingGroupTableRow,
  menuReportingGroupsTableFactoryCore,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const ReportingGroupsTable = ({ filterParams, t }: IMainScreenTable) => {
  const reportingGroups = useSelector(selectReportingGroups);
  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);

  const onSort = useSort<IReportingGroupTableRow>('title');

  const reportingGroupsArray = menuReportingGroupsTableFactoryCore(
    reportingGroups,
    items,
    combos,
  );

  const reportingGroupsArrayFiltered = useMemo(
    () =>
      filter<IReportingGroupTableRow>(
        reportingGroupsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, reportingGroupsArray],
  );

  const customFilterFn = (row: Row<IReportingGroupTableRow>) => {
    return (
      row.depth > 0 ||
      reportingGroupsArrayFiltered.findIndex(
        reportingGroup => reportingGroup.id === row.original.id,
      ) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/reporting-groups/${info.row.original.id}`}
            status={info.row.original.isActive}
            statusPath={`selectedMode.currentMenu.reportingGroups.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.categoryMain.table.headers.itemCount'),
        cell: info => {
          return (
            <Box csx={{ display: 'flex', width: '100%' }}>
              <Box csx={{ flex: 1 }}>{info.row.original.itemCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.comboCount}</Box>
            </Box>
          );
        },
        size: 150,
      }),
      columnHelper.accessor('items', {
        header: t('menuScreen.ingredientsMain.table.headers.items'),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
      columnHelper.accessor('combos', {
        header: t('menuScreen.reportingGroupsMain.table.headers.combos'),
        cell: info => <OverflowText text={info.getValue()} />,
        minSize: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={reportingGroupsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ReportingGroupsTable;
