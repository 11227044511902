import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import { TDropdownData } from '@app/components/common/Dropdown/types';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import {
  actionCreatorsMenu,
  INestedModifierGroupModifiers,
  MenuTypes,
  SectionId,
  useModifierItemPriceCalc,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Content from '../Content';
import NestedModifierGroupCardHeaderOptions from './NestedModifierGroupCardHeaderOptions';
import NestedModifierGroupOptions from './NestedModifierGroupOptions';

interface INestedModifierGroupCard
  extends WithTranslation,
    INestedModifierGroupModifiers {
  modifierGroupPricingVersions: TDropdownData;
  isFirst: boolean;
  isLast: boolean;
  menuTypeVersionId: number;
  prevModifierGroupId: number;
  nextModifierGroupId: number;
  onDeleteModifierGroupPress: (
    modifierGroupId: number,
    name: string,
    active: boolean,
  ) => void;
  menuTypeMode: MenuTypes;
}

const sectionId = SectionId.MODIFIER_GROUPS;

const NestedModifierGroupCard = ({
  t,
  modifierItems,
  modifierGroupPricingVersions,
  posName,
  description,
  requiredModifierItems,
  includedModifierItems,
  quantityMode,
  multiselect,
  active,
  defaultModifierGroupPricingVersionId,
  isFirst,
  isLast,
  // prevModifierGroupId,
  // nextModifierGroupId,
  onDeleteModifierGroupPress,
  menuTypeMode,
  priceCalcMode,
  modifierGroupId,
  hidePriceIfFree,
  inheritedActive,
}: INestedModifierGroupCard) => {
  const { hideModifierGroupFromModifierItem } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const [hiddenModifierItemsCount, setHiddenModifierItemsCount] = useState(0);
  const handleHideModifierItem = (countValue: number) => {
    setHiddenModifierItemsCount(prev => prev + countValue);
  };

  const isHidden = !active || !inheritedActive;
  const showContent = !isHidden || menuTypeMode === MenuTypes.MASTER_MENU;

  const {
    selectedModifierItemElements,
    includedModifierItemsCount,
    additionalModifierItemsCount,
    totalPrice,
  } = useModifierItemPriceCalc(
    modifierItems,
    includedModifierItems,
    quantityMode,
    priceCalcMode,
  );

  return (
    <Card.SubCard
      title={posName}
      description={t(
        'menuScreen.itemDetails.modifierGroupsSection.modifierName',
        { name: description },
      )}
      csx={{
        padding: 0,
        '.subCardHeader': { marginBottom: showContent ? undefined : 0 },
        '.subCardTitle': { padding: '15px' },
      }}
      options={
        <NestedModifierGroupCardHeaderOptions
          posName={posName}
          active={!isHidden}
          modifierGroupId={modifierGroupId}
          onDeleteModifierGroupPress={onDeleteModifierGroupPress}
          menuTypeMode={menuTypeMode}
          modifierGroupInfo={{
            multiselect,
            quantityMode,
            priceCalcMode,
          }}
        />
      }>
      {showContent && (
        <>
          <Divider
            csx={{ marginTop: '-15px' }}
            lineCsx={theme => ({
              borderColor: theme.colors.lightGrey,
            })}
          />
          <Box
            csx={{
              display: 'flex',
            }}>
            <Box csx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <NestedModifierGroupOptions
                active={!isHidden}
                includedModifierItems={includedModifierItems}
                requiredModifierItems={requiredModifierItems}
                modifierGroupId={modifierGroupId}
                modifierItemsArray={modifierItems}
                multiselect={multiselect}
                quantityMode={quantityMode}
                hiddenModifierItemsCount={hiddenModifierItemsCount}
                totalPrice={totalPrice}
              />
              <Box
                csx={{
                  position: 'relative',
                }}>
                <Content
                  selectedModifierItemElements={selectedModifierItemElements}
                  includedModifierItemsCount={includedModifierItemsCount}
                  additionalModifierItemsCount={additionalModifierItemsCount}
                  defaultModifierGroupPricingVersionId={
                    defaultModifierGroupPricingVersionId
                  }
                  modifierGroupId={modifierGroupId}
                  modifierGroupPricingVersions={modifierGroupPricingVersions}
                  modifierItems={modifierItems}
                  multiselect={multiselect}
                  quantityMode={quantityMode}
                  setHiddenModifierItemsCount={handleHideModifierItem}
                  hidePriceIfFree={hidePriceIfFree}
                />
                {isHidden && (
                  <Box
                    csx={{
                      position: 'absolute',
                      inset: 0,
                      width: '100%',
                      height: '100%',
                    }}>
                    <Box
                      csx={{
                        zIndex: 2,
                        position: 'absolute',
                        inset: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}>
                      <Box
                        csx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 10,
                        }}>
                        <Icon name="MdVisibilityOff" size="26px" />
                        <Typography fontWeight="medium" variant="subtitle">
                          {t(
                            'menuScreen.itemDetails.modifierGroupsSection.modifierGroupHidden',
                          )}
                        </Typography>
                      </Box>
                      <Button
                        variant="primary"
                        onClick={() => {
                          hideModifierGroupFromModifierItem(
                            modifierGroupId,
                            sectionId,
                            true,
                          );
                        }}
                        csx={{
                          marginTop: 10,
                        }}>
                        {t(
                          'menuScreen.itemDetails.modifierGroupsSection.activate',
                        )}
                      </Button>
                    </Box>
                    <Box
                      csx={{
                        position: 'absolute',
                        inset: 0,
                        backgroundColor: 'white',
                        opacity: 0.6,
                        zIndex: 1,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              csx={theme => ({
                width: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderLeft: `1px solid ${theme.colors.lightGrey}`,
              })}>
              <Button
                variant="primary"
                disabled={isFirst}
                csx={{
                  borderWidth: 0,
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  height: '60px',
                }}
                icon={<Icon name="MdKeyboardArrowUp" />}
              />
              <Button
                variant="primary"
                disabled={isLast}
                csx={{
                  borderWidth: 0,
                  borderRadius: 0,
                  borderTopWidth: 1,
                  height: '60px',
                }}
                icon={<Icon name="MdKeyboardArrowDown" />}
              />
            </Box>
          </Box>
        </>
      )}
    </Card.SubCard>
  );
};

export default NestedModifierGroupCard;
