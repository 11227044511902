import BlueDot from '../../BlueDot';
import Box from '../../Box';
import Typography, { ITypography } from '../../Typography/Typography';
import Tag86 from './Tag86';
import { SIZES } from './types';

interface ITopRightLabel {
  isActive: boolean;
  isMultipleQuantity?: boolean;
  isSizeItem?: boolean;
  is86ed?: boolean;
  quantity?: number;
  quantityString?: string | null | undefined;
  inventoryLabel?: string;
  textProps?: Partial<ITypography>;
  quantityOverride?: boolean;
}

const TopRightLabel = ({
  isActive,
  isMultipleQuantity,
  isSizeItem,
  is86ed,
  quantity = 1,
  quantityOverride,
  quantityString,
  inventoryLabel,
  textProps,
}: ITopRightLabel) => {
  return (
    <Box
      csx={{
        marginTop: 5,
        alignItems: 'center',
        gap: 3,
      }}>
      {/* Quantity Counter */}
      {(isSizeItem || isMultipleQuantity) && isActive && (
        <Box
          csx={theme => ({
            backgroundColor: theme.colors.lightYellow,
            height: 25,
            width: isSizeItem ? 'auto' : 40,
            paddingInline: '8px',
            borderRadius: 8,
            border: `1px solid ${theme.colors.darkestYellow}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2px',
          })}>
          {quantityOverride && <BlueDot />}
          <Typography color="textBlack" variant="caption">
            {quantityString
              ? quantityString
              : isSizeItem
              ? SIZES[quantity]
              : `x${quantity}`}
          </Typography>
        </Box>
      )}
      {is86ed && !isActive && <Tag86 />}
      {!isActive && !is86ed && inventoryLabel && (
        <Typography color="semanticBlue" fontWeight="medium" {...textProps}>
          {inventoryLabel}
        </Typography>
      )}
    </Box>
  );
};

export default TopRightLabel;
