import { Theme } from '@emotion/react';
import { BREAKPOINTS } from './themes';

export type IFontSizes = {
  heading: string;
  subtitle: string;
  body: string;
  caption: string;
  caption2: string;
};
export type TFontSizes =
  | 'heading'
  | 'subtitle'
  | 'body'
  | 'caption'
  | 'caption2';
export type TFontWeights = 'light' | 'regular' | 'medium' | 'bold';

export type ThemeColors =
  | keyof Theme['colors']
  | (string & NonNullable<unknown>);

export enum ThemeModes {
  LIGHT = 'light',
  DARK = 'dark',
}

export const MQ_MIN_MOBILE = `@media (min-width: ${BREAKPOINTS['mobile'].min})`;
export const MQ_MIN_SMALL = `@media (min-width: ${BREAKPOINTS['small'].min})`;
export const MQ_MIN_MEDIUM = `@media (min-width: ${BREAKPOINTS['medium'].min})`;
export const MQ_MIN_LARGE = `@media (min-width: ${BREAKPOINTS['large'].min})`;
export const MQ_MIN_X_LARGE = `@media (min-width: ${BREAKPOINTS['xLarge'].min})`;

export const MQ_MAX_MOBILE = `@media (max-width: ${BREAKPOINTS['mobile'].max})`;
export const MQ_MAX_SMALL = `@media (max-width: ${BREAKPOINTS['small'].max})`;
export const MQ_MAX_MEDIUM = `@media (max-width: ${BREAKPOINTS['medium'].max})`;
export const MQ_MAX_LARGE = `@media (max-width: ${BREAKPOINTS['large'].max})`;

export const MQ_MIN_HEIGHT_SMALL = '@media (min-height: 640px)';
export const MQ_MIN_HEIGHT_MEDIUM = '@media (min-height: 890px)';
