import { displayFlexRowStyles } from '@app/theme/commonStyles';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import ActionDropdown from '../ActionDropdown';
import Box from '../Box';
import Button from '../Button';
import Icon from '../Icon';
import { IActionButtons } from './types';

const ActionButtons = ({
  optionList,
  onEditAction,
  isEditButtonDisabled,
  isEditButtonActive,
  disabled,
  onClickDisabled,
  onEditClickDisabled,
  actionDropdownTriggerCsx,
}: IActionButtons) => {
  const [editButtonActive, setEditButtonState] = useState(false);

  const isDropdownDisabled = optionList?.length === 1 || disabled;

  useEffect(() => {
    if (!isNil(isEditButtonActive)) {
      setEditButtonState(isEditButtonActive);
    }
  }, [isEditButtonActive]);

  const handleEdit = () => {
    setEditButtonState(current => !current);
    onEditAction && onEditAction(!editButtonActive);
  };

  const handleDropdownOnPress = () => {
    if (optionList?.length === 1) {
      optionList[0].handler();
      return;
    }
  };

  return (
    <>
      <Box csx={[displayFlexRowStyles, { gap: '10px', width: 'auto' }]}>
        {onEditAction && (
          <Button
            variant={editButtonActive ? 'active' : 'secondary'}
            icon={<Icon name="MdEdit" />}
            csx={{ minWidth: '50px', width: '50px' }}
            disabled={isEditButtonDisabled}
            onClick={handleEdit}
            onClickDisabled={onEditClickDisabled}
          />
        )}
        {optionList && optionList.length > 0 && (
          <ActionDropdown
            options={optionList}
            isDisabled={isDropdownDisabled}
            trigger={
              <Button
                variant="primary"
                icon={<Icon name="MdAddCircle" />}
                csx={[
                  {
                    width: '100%',
                    minWidth: '50px',
                  },
                  actionDropdownTriggerCsx,
                ]}
                onClick={handleDropdownOnPress}
                disabled={disabled}
                onClickDisabled={onClickDisabled}
              />
            }
          />
        )}
      </Box>
    </>
  );
};

export default ActionButtons;
