import { TCsx } from '@emotion/react';
import React, { HTMLAttributes, forwardRef } from 'react';

export interface IBox extends Omit<HTMLAttributes<HTMLDivElement>, 'css'> {
  children?: React.ReactNode;
  csx?: TCsx;
  css?: never;
}
const Box = forwardRef<HTMLDivElement, IBox>(({ csx, ...otherProps }, ref) => {
  return (
    <div ref={ref} css={[{ boxSizing: 'border-box' }, csx]} {...otherProps} />
  );
});
Box.displayName = 'BoxComponent';
export default Box;
