import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import BasicSettings from '@app/components/LoggedIn/Settings/General/OnlineOrdering/BasicSettings';
import DeliverySettings from '@app/components/LoggedIn/Settings/General/OnlineOrdering/DeliverySettings';
import PageDesignSettings from '@app/components/LoggedIn/Settings/General/OnlineOrdering/PageDesignSettings';
import PickupSettings from '@app/components/LoggedIn/Settings/General/OnlineOrdering/PickupSettings';
import TipSettings from '@app/components/LoggedIn/Settings/General/OnlineOrdering/TipSettings';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const OnlineOrdering = () => {
  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    useDispatch(),
  );

  const { t } = useTranslation();

  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'online',
  );

  useEffect(() => {
    if (!isSet)
      loadSettingsBucketChangeData({
        setting: 'online',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return (
    <>
      <HeaderTitle
        title={t('loggedIn.settingsModule.onlineOrderingSettings.title')}
      />
      {isSet && (
        <Accordion onExpandAll={() => null} onCollapseAll={() => null}>
          <BasicSettings isExpanded />
          <PickupSettings />
          <DeliverySettings />
          <AccordionSection
            title={t('settingsModule.onlineSettings.pageDesign.title')}
            description={t(
              'settingsModule.onlineSettings.pageDesign.description',
            )}>
            <PageDesignSettings />
          </AccordionSection>
          <AccordionSection
            title={t('settingsModule.onlineSettings.tips.title')}
            description={t('settingsModule.onlineSettings.tips.description')}>
            <TipSettings />
          </AccordionSection>
        </Accordion>
      )}
    </>
  );
};

export default OnlineOrdering;
