import { Theme, css } from '@emotion/react';

export const socialMediaBoxStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    padding: 20,
  });
