import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IServiceAreaStore,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { serviceAreaStyles } from './styles';
import { actionCreatorsApp } from '@app/state';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect } from 'react';

interface IServiceArea extends WithTranslation {
  id: string;
  index: number;
  serviceArea: IServiceAreaStore;
  selectedServiceArea: IServiceAreaStore | null;
  reorderEnabled: boolean;
  onClickEditServiceArea: (value: IServiceAreaStore) => void;
  isOneDragging: boolean;
  setIsDragging: (value: boolean) => void;
}

const ServiceArea = ({
  t,
  index,
  serviceArea,
  selectedServiceArea,
  reorderEnabled,
  onClickEditServiceArea,
  isOneDragging = false,
  setIsDragging,
}: IServiceArea) => {
  // Redux
  const dispatch = useDispatch();
  const { changeServiceArea, removeServiceAreAndResetState } =
    bindActionCreators(actionCreatorsTableLayout, dispatch);

  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  // Local state
  const {
    attributes,
    setNodeRef,
    transform,
    transition,
    isDragging,
    listeners,
  } = useSortable({
    id: index + 1,
  });

  useEffect(() => {
    setIsDragging(isDragging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSelectServiceArea = (serviceAreaId: string) => {
    changeServiceArea(serviceAreaId);
  };

  const handleOnClickDelete = () => {
    setShowConfirmationModal({
      active: true,
      title: t('settingsModule.tableLayoutCreator.serviceArea'),
      name: serviceArea.name,
      type: 'delete',
      runCancelOnClickOutside: true,
      onSuccess: () => {
        removeServiceAreAndResetState(serviceArea.id);
      },
    });
  };

  const handleOnClickEdit = () => {
    onClickEditServiceArea(serviceArea);
  };

  return (
    <div
      ref={setNodeRef}
      css={serviceAreaStyles}
      data-dragging={isDragging}
      style={style}
      {...attributes}>
      <Box csx={{ overflow: 'hidden', position: 'relative' }}>
        <Button
          {...(reorderEnabled ? { whileTap: {} } : {})}
          variant={
            serviceArea.id === selectedServiceArea?.id ? 'active' : 'secondary'
          }
          csx={{ minWidth: '130px', paddingInline: '10px' }}
          onClick={() => {
            handleSelectServiceArea(serviceArea.id);
          }}
          {...listeners}
          disableHoverEffect={reorderEnabled}>
          {serviceArea.name}
        </Button>
        {reorderEnabled && (
          <Box className="dragIcon">
            <Icon
              name="MdDragIndicator"
              color="lightGrey"
              size="24px"
              csx={theme => ({
                backgroundColor:
                  serviceArea.id === selectedServiceArea?.id
                    ? theme.colors.persistentSemanticBlue
                    : 'white',
                width: '17px',
                marginLeft: '-4px',
                borderRadius: '5px',
              })}
            />
          </Box>
        )}
      </Box>
      {!isOneDragging && (
        <>
          {reorderEnabled ? (
            <Box
              className="removeIcon"
              onClick={
                reorderEnabled ? handleOnClickDelete : handleOnClickEdit
              }>
              <Icon name="MdClear" color="textWhite" />
            </Box>
          ) : (
            <Box
              className="editIcon"
              onClick={
                reorderEnabled ? handleOnClickDelete : handleOnClickEdit
              }>
              <Icon name="MdEdit" color="textWhite" />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ServiceArea;
