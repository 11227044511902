import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import {
  CustomersSectionId,
  formatMoney,
  lastOrdersTableFactory,
} from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';

import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Table from '@app/components/common/Table';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { makeSelectCustomersData } from '@app/state/customers/customersSelectors';

import Grid from '@app/components/common/Grid';
import OrderStatusLabel from '@app/components/common/OrderStatusLabel';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { selectLocationList } from '@app/state/menu/menuSelectors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { orderSectionStyles } from './styles';
import { columnHelper, ROW_ID_PREFIX } from './types';

interface ICardInfoSection extends IAccordionSection {}

const SECTION_ID = CustomersSectionId.ORDERS;

const getMemoizedCustomerData = makeSelectCustomersData(SECTION_ID);

const OrdersSection = ({ ...accordionSectionProps }: ICardInfoSection) => {
  const { t } = useTranslation();

  const locationList = useSelector(selectLocationList);

  const { data } = useSelector(getMemoizedCustomerData);

  const previousOrdersArray = useMemo(
    () => lastOrdersTableFactory(data?.previousOrders, locationList),
    [data?.previousOrders, locationList],
  );

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('customersScreen.orders.lastOrders.table.orderId'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 200,
      }),
      columnHelper.accessor('createdAt', {
        header: t('customersScreen.orders.lastOrders.table.createdAt'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 200,
      }),
      columnHelper.accessor('total', {
        header: t('customersScreen.orders.lastOrders.table.total'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
      columnHelper.accessor('locationName', {
        header: t('customersScreen.orders.lastOrders.table.locationId'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 250,
      }),
      columnHelper.accessor('status', {
        header: t('customersScreen.orders.lastOrders.table.status'),
        cell: info => <OrderStatusLabel status={info.getValue()} />,
        minSize: 250,
      }),
    ],
    [t],
  );

  return (
    <>
      <AccordionSection
        title={t('customersScreen.orders.title')}
        titleTextContainerCsx={{ minWidth: '150px' }}
        {...accordionSectionProps}>
        <Grid csx={orderSectionStyles} rowGap={15}>
          <Grid.Item>
            <Grid columns={15}>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t('customersScreen.orders.summary.totalOfOrdersPlaced')}
                </Typography>
                <Typography variant="heading">
                  {data?.totalOfOrdersPlaced}
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t('customersScreen.orders.summary.avgSpend')}
                </Typography>
                <Typography variant="heading">
                  {formatMoney(null, data?.avgSpend)}
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t('customersScreen.orders.summary.lifetimeSpend')}
                </Typography>
                <Typography variant="heading">
                  {formatMoney(null, data?.lifetimeSpend)}
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t('customersScreen.orders.summary.avgTip')}
                </Typography>
                <Typography variant="heading">
                  {data?.avgTip.toFixed(2)}%
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t('customersScreen.orders.summary.avgTipAmount')}
                </Typography>
                <Typography variant="heading">
                  {formatMoney(null, data?.avgTipAmount)}
                </Typography>
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item>
            <Grid columns={15}>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t(
                    'customersScreen.orders.summary.percentageBreakdownOfDineIn',
                  )}
                </Typography>
                <Typography variant="heading">
                  {data?.percentageBreakdownOfDineIn.toFixed(2)}%
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t(
                    'customersScreen.orders.summary.percentageBreakdownOfDelivery',
                  )}
                </Typography>
                <Typography variant="heading">
                  {data?.percentageBreakdownOfDelivery.toFixed(2)}%
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t(
                    'customersScreen.orders.summary.percentageBreakdownOfToGo',
                  )}
                </Typography>
                <Typography variant="heading">
                  {data?.percentageBreakdownOfToGo.toFixed(2)}%
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3}>
                <Typography variant="caption" color="darkGrey">
                  {t(
                    'customersScreen.orders.summary.percentageBreakdownOfCatering',
                  )}
                </Typography>
                <Typography variant="heading">
                  {data?.percentageBreakdownOfCatering.toFixed(2)}%
                </Typography>
              </Grid.Item>
              <Grid.Item className="avg" mb={3} />
            </Grid>
          </Grid.Item>
          <Grid.Item csx={{ marginTop: 10, marginBottom: 10 }}>
            <DetailsSubtitle noMargin>
              {t('customersScreen.orders.lastOrders.title')}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item>
            <Box
              csx={{
                height: '100%',
              }}>
              <Table
                scrollEnabled
                nestedScrollEnabled
                data={previousOrdersArray}
                columns={COLUMN_VALUES}
                cellCsx={{
                  height: '70px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                rowIdPrefix={ROW_ID_PREFIX}
                noDataPlaceholder={
                  <SectionLayer
                    title={t('customersScreen.orders.lastOrders.emptyDataText')}
                    csx={{
                      justifyContent: 'center',
                      paddingTop: 30,
                      paddingBottom: 20,
                    }}
                  />
                }
              />
            </Box>
          </Grid.Item>
        </Grid>
      </AccordionSection>
    </>
  );
};

export default OrdersSection;
