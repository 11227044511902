import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

const StickyContainer = ({
  children,
  isSticky = true,
}: {
  children: ReactNode;
  isSticky?: boolean;
}) => {
  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 0px 5px 0px rgba(3, 8, 25, 0.18)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  return (
    <m.div
      css={[
        displayFlexRowStyles,
        isSticky
          ? {
              position: 'sticky',
              top: 0,
              zIndex: 4,
              padding: '15px',
            }
          : {},
      ]}
      style={{ backgroundColor, boxShadow }}>
      {children}
    </m.div>
  );
};

export default StickyContainer;
