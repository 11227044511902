import Box from '@app/components/common/Box';
import { forgotPasswordCardStyles } from './styles';
import Typography from '@app/components/common/Typography';
import Button from '@app/components/common/Button';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CodeVerifier from '@app/components/common/CodeVerifier';
import AuthCard from '@app/components/Authentication/AuthCard';
import { WithTranslation } from 'react-i18next';
import {
  linkWithText,
  loginAuthBodySeparation,
} from '@app/components/Authentication/styles';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '@app/state/selectors/appSelectors';
import { sendVerificationCode, verifyCode } from '@app/state/app/actions';
import { useAppDispatch } from '@app/state/store';
import Link from '@app/components/common/Link';
import Spinner from '@app/components/common/Spinner';

const VerifyCode = ({ t }: WithTranslation) => {
  //Redux
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);

  // Local state
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState({
    value: '',
    error: '',
  });
  const [isResendingCode, setIsResendingCode] = useState(false);

  const currentEmail = location.state?.email;

  useEffect(() => {
    if (!currentEmail) navigate('/auth/forgot-password');
  }, [currentEmail, navigate]);

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleResetError();
    const isValid = await dispatch(verifyCode(currentEmail, code.value));

    if (isValid) {
      navigate('/auth/forgot-password/reset-password', {
        state: { email: currentEmail, code: code.value },
      });
    } else {
      setCode(prev => ({ ...prev, error: 'Invalid code' }));
    }
  };

  const handleResetError = () => {
    setCode(prev => ({ ...prev, error: '' }));
  };

  const handleResendCode = async (e: any) => {
    e.preventDefault();
    setIsResendingCode(true);
    await dispatch(sendVerificationCode(currentEmail));
    setIsResendingCode(false);
  };

  return (
    <AuthCard csx={forgotPasswordCardStyles}>
      <Box className="form" csx={loginAuthBodySeparation}>
        <Box csx={{ position: 'relative' }}>
          <Typography variant="heading" fontWeight="medium" className="title">
            {t('authentication.verifyCode.enterVerificationCode')}
          </Typography>
          <Typography
            variant="caption"
            className="title"
            csx={{ position: 'absolute' }}>
            {t('authentication.verifyCode.weHaveSentAVerificationCode')}{' '}
            <b>{currentEmail}</b>.
          </Typography>
        </Box>
        <form className="formContent" onSubmit={handleSubmitForm}>
          <Box className="codeVerifier">
            <CodeVerifier
              length={6}
              isLoading={isLoading}
              error={code.error.length > 0}
              getCode={value => setCode({ value, error: '' })}
              emptyCation
            />
          </Box>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}
            className="formButtons">
            <Button
              type="submit"
              variant="active"
              isLoading={isLoading && !isResendingCode}
              loadingText={t('authentication.verifyCode.verifyingCode')}
              disabled={code.value.length !== 6 || isResendingCode}>
              {t('authentication.verifyCode.verifyCode')}
            </Button>
            <Button
              type="button"
              onClick={() => navigate('/auth/forgot-password')}>
              {t('commonButtons.cancel')}
            </Button>
            <Box csx={linkWithText} className="noAccount">
              <Typography variant="body" fontWeight="medium" color="darkGrey">
                {t('authentication.verifyCode.didntGetTheCode')}
              </Typography>
              <Link
                to="#"
                color={isResendingCode ? 'lightGrey' : 'primary'}
                onClick={e => !isResendingCode && handleResendCode(e)}
                csx={{ display: 'flex', alignItems: 'center' }}>
                {isResendingCode
                  ? `${t('authentication.verifyCode.resending')}`
                  : t('authentication.verifyCode.resend')}
                {isResendingCode && (
                  <Spinner
                    color="lightGrey"
                    size="16px"
                    csx={{ marginLeft: '4px' }}
                  />
                )}
              </Link>
            </Box>
          </Box>
        </form>
      </Box>
    </AuthCard>
  );
};

export default VerifyCode;
