import { css } from '@emotion/react';

export const specialsDiffModalStyles = () => {
  return css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 15,
    borderWidth: 2,
    zIndex: 1,
    padding: 10,
  });
};
