import React from 'react';
import Box from '../Box';
import { TCsx } from '@emotion/react';
import { disabledBlockLayerStyles } from './styles';

interface IDisabledBlockLayer {
  csx?: TCsx;
  backdropCsx?: TCsx;
  children?: React.ReactNode;
}

const DisabledBlockLayer = ({
  csx,
  backdropCsx,
  children,
}: IDisabledBlockLayer) => {
  return (
    <Box csx={[disabledBlockLayerStyles, csx]}>
      <Box className="backdrop" csx={backdropCsx} />
      <Box className="container">{children}</Box>
    </Box>
  );
};

export default DisabledBlockLayer;
