import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { useAnimate } from 'framer-motion';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import Box from '../../Box';
import Button from '../../Button';
import Divider from '../../Divider';
import Icon from '../../Icon';
import ListItem from '../../List/ListItem';
import ToolTip from '../../ToolTip';
import Typography from '../../Typography';
import { subTabStyles } from '../styles';
import { ITab } from '../types';

interface ISubTabButton extends WithTranslation {
  item: ITab;
  activeSubTab: { tabId: number; subTab: ITab } | null;
  isDragging?: boolean;
  isActive?: boolean;
  isTooltipDisabled?: boolean;
  setActiveSubTab: (data: { tabId: number; subTab: ITab }) => void;
  setIsTooltipDisabled: (value: boolean) => void;
}
const SubTabButton = ({
  t,
  item,
  activeSubTab,
  isDragging,
  isActive,
  isTooltipDisabled,
  setActiveSubTab,
  setIsTooltipDisabled,
}: ISubTabButton) => {
  const subTabs = item.subTabs as ITab[];
  const [isSubTabOpen, setIsSubTabOpen] = useState(false);
  const [scope, animate] = useAnimate();
  const [subTabMenu, setSubTabMenu] = useState<ITab | null>(null);

  useEffect(() => {
    animate(
      '.dropDownArrow',
      { rotate: isSubTabOpen ? 180 : 0 },
      { duration: 0.1 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubTabOpen]);

  useEffect(() => {
    if (!activeSubTab || activeSubTab.tabId !== item.id || !activeSubTab.subTab)
      setSubTabMenu(null);
  }, [activeSubTab, item.id]);

  const handleSubTabClick = (tab: { tabId: number; subTab: ITab }) => {
    setActiveSubTab(tab);
  };

  const handleSubTabButtonClick = () => {
    if (!isTooltipDisabled && !item.isDisabled) setIsSubTabOpen(true);
    else setIsTooltipDisabled(false);
  };

  return (
    <ToolTip
      mode="click"
      direction='bottom'
      onClickOutside={() => setIsSubTabOpen(false)}
      isDisabled={isTooltipDisabled || item.isDisabled}
      dismissOnContentClick={false}
      isToolTipActive={isSubTabOpen}
      content={
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            width: '166px',
            gap: '5px',
          }}>
          {subTabMenu ? (
            <Box csx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Button
                icon={<Icon name="MdArrowBack" color="darkGrey" />}
                variant="transparent"
                csx={[
                  displayFlexRowStyles,
                  { height: '35px', justifyContent: 'left' },
                ]}
                onClick={() => setSubTabMenu(null)}>
                <Typography color="darkGrey" fontWeight="medium">
                  {t(subTabMenu.name as ParseKeys)}
                </Typography>
              </Button>
              <Divider csx={{ marginTop: '5px', marginBottom: '10px' }} />
              {subTabMenu.subTabs?.map(subTab => {
                return (
                  <ListItem
                    key={`subTab-${subTab.id}`}
                    isSubItem
                    isActive={activeSubTab?.subTab.id === subTab.id}
                    onClick={() => {
                      handleSubTabClick({ tabId: item.id, subTab });
                      setIsSubTabOpen(false);
                    }}
                    showLeftIcon={false}
                    text={t(subTab.name as ParseKeys)}
                  />
                );
              })}
            </Box>
          ) : (
            subTabs.map(subTab => {
              const hasSubTabs =
                subTab.subTabs && subTab.subTabs.length > 0 ? true : false;
              return (
                <ListItem
                  key={`subTab-${subTab.id}`}
                  isSubItem
                  isActive={activeSubTab?.subTab.id === subTab.id}
                  onClick={() => {
                    if (hasSubTabs) {
                      setSubTabMenu(subTab);
                      return;
                    }

                    handleSubTabClick({ tabId: item.id, subTab });
                    setIsSubTabOpen(false);
                  }}
                  showLeftIcon={false}
                  rightIcon={
                    hasSubTabs ? <Icon name="MdChevronRight" /> : undefined
                  }
                  text={t(subTab.name as ParseKeys)}
                />
              );
            })
          )}
        </Box>
      }>
      <Box ref={scope}>
        <Button
          csx={[
            subTabStyles,
            isDragging ? { cursor: 'grabbing !important' } : {},
          ]}
          variant={isActive ? 'active' : 'transparent'}
          className={`${isActive ? 'active' : ''} ${
            activeSubTab && activeSubTab.tabId === item.id ? 'withSubTab' : ''
          }`}
          iconPosition="right"
          icon={
            <Box className="dropDownArrow" csx={{ display: 'flex' }}>
              <Icon name="MdChevronRight" />
            </Box>
          }
          onClick={handleSubTabButtonClick}
          onClickDisabled={item.buttonProps?.onClickDisabled}
          disabled={item.isDisabled}
          data-disabled={item.isDisabled}>
          <Box className="headerContainer">
            <Typography className="mainHeader">
              {t(item.name as ParseKeys)}
            </Typography>
            {activeSubTab && activeSubTab.tabId === item.id && (
              <span className="subHeader">
                {t(activeSubTab?.subTab.name as ParseKeys)}
              </span>
            )}
          </Box>
        </Button>
      </Box>
    </ToolTip>
  );
};

export default SubTabButton;
