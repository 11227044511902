import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import { selectChangeDataDifferences } from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TChange } from '@app/types';
import {
  CoreRootState,
  diffItemGroupsFactory,
  formatMoney,
  IItemGroupDiffObject,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';

const ItemGroupsDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(selectChangeDataDifferences);

  const {
    itemChangeColor,
    modifierItemBoxBorderColor,
    modifierItemBoxColor,
    boxTextAndBorderColor,
    boxColor,
    boxRemovedColor,
    boxRemovedTextColor,
  } = useItemChangeColor();

  const formattedDifferences = useMemo(() => {
    return diffItemGroupsFactory(
      differences,
      store.getState() as CoreRootState & RootState,
    );
  }, [differences]);

  const formatValue = (value: any, field: string) => {
    if (['additionalPrice'].includes(field)) {
      return formatMoney(null, value);
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange, idx = 0) => {
    if (!change.field) return null;
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        key={`${change.field}_${change.id}_${idx}`}
      />
    );
  };

  const renderModifierItems = (
    changes: IItemGroupDiffObject['associations'],
  ) => {
    return (
      <>
        <Grid
          columnGap={10}
          rowGap={10}
          csx={{
            width: '100%',
            height: '100%',
          }}>
          {changes.map(_change => (
            <Grid.Item
              mb={12}
              sm={6}
              md={4}
              key="item_group"
              csx={{
                backgroundColor: modifierItemBoxColor,
                border: `1px solid ${modifierItemBoxBorderColor}`,
                padding: 8,
                borderRadius: 10,
              }}>
              <Box
                csx={{
                  width: '100%',
                  borderRadius: 10,
                  backgroundColor: 'white',
                  height: 66,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `1px solid ${boxTextAndBorderColor}`,
                }}>
                <Typography fontWeight="medium" color="black">
                  {_change.label}
                </Typography>
              </Box>
              {_change.changes.length > 0 && (
                <Box
                  csx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: '20px',
                    padding: '10px 20px',
                  }}>
                  {_change.changes.map((change, index) => (
                    <Box
                      key={`itemChange_${index}`}
                      csx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBlock: 10,
                      }}>
                      <Box csx={{ width: 70 }}>
                        <Typography variant="caption2" color={itemChangeColor}>
                          {change.label}:
                        </Typography>
                      </Box>
                      <Box
                        csx={{
                          width: 70,
                          height: 35,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor:
                            change.value === false &&
                            change.field !== 'isSelected'
                              ? boxRemovedColor
                              : boxColor,
                          borderRadius: 10,
                          border: `1px solid ${
                            change.value === false &&
                            change.field !== 'isSelected'
                              ? boxRemovedTextColor
                              : boxTextAndBorderColor
                          }`,
                        }}>
                        <Typography
                          color={
                            change.value === false &&
                            change.field !== 'isSelected'
                              ? boxRemovedTextColor
                              : boxTextAndBorderColor
                          }
                          variant="caption2"
                          align="center">
                          {formatValue(change.value as string, change.field)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid.Item>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {formattedDifferences.associations.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.associations')}
                color="black"
              />

              {renderModifierItems(formattedDifferences.associations)}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ItemGroupsDiffModal;
