import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { RootState } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import Typography from '../../Typography';
import GeneralSettingsDiffModal from './GeneralSettingsDiffModal';
import GuestCheckDiffModal from './GuestCheckDiffModal';
import GuestReceiptDiffModal from './GuestReceiptDiffModal';
import KitchenTicketDiffModal from './KitchenTicketDiffModal';
import LockScreenDiffModal from './LockScreenDiffModal';
import PaymentsDiffModal from './PaymentsDiffModal';
import PrepStationsDiffModal from './PrepStationsDiffModal';
import PrintingRulesDiffModal from './PrintingRulesDiffModal';
import RegisterModeDiffModal from './RegisterModeDiffModal';
import RegisterTicketDiffModal from './RegisterTicketDiffModal';
import RolesDiffModal from './RolesDiffModal';
import { descriptionStyles } from './styles';
import TaxesDiffModal from './TaxesDiffModal';
import { SETTINGS_DICTIONARY } from './types';
import UserSettingsDiffModal from './UserSettingsDiffModal';
import OnlineOrderingSettingsDiffModal from './OnlineOrderingSettingsDiffModal';

interface IDifferencesModal extends IConfirmationModal, WithTranslation {}

const SettingsDiffConfirmationModal = ({
  title,
  active,
  description,
  onCancel,
  onSuccess,
  btnCancelText,
  btnSuccessText,
  runCancelOnClickOutside,
  t,
  hideCancel,
  hideSuccess,
}: IDifferencesModal) => {
  // Redux
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const differences = useSelector(settingsDifferencesSelector);

  const settingsSelected = useSelector(
    (state: RootState) => state.settings.changeData.settingsSelected,
  );

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
  };

  const theme = useTheme();
  const boxTextColor = theme.colors.darkBlue;

  return (
    <Modal
      isActive={active}
      size={1225}
      onModalClose={handleOnModalClose}
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title}
          </Typography>
          <Typography color={boxTextColor}>
            {settingsSelected && SETTINGS_DICTIONARY[settingsSelected]}
          </Typography>
        </Box>
      }
      footer={
        <>
          {btnCancelText && (
            <Button variant="secondary" onClick={handleOnModalClose}>
              {t('commonButtons.cancel')}
            </Button>
          )}
          {!hideCancel && (
            <Button variant="secondary" onClick={handleOnCancel}>
              {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
            </Button>
          )}
          {!hideSuccess && (
            <Button
              variant="primary"
              icon={<Icon name="MdArrowForward" />}
              iconPosition="right"
              onClick={handleOnModalSuccess}>
              {btnSuccessText ? btnSuccessText : t('commonButtons.continue')}
            </Button>
          )}
        </>
      }>
      <Box csx={descriptionStyles}>
        <Box csx={{ marginRight: '12px' }}>
          <Icon name="MdWarning" color="darkYellow" />
        </Box>
        <Typography>{description}</Typography>
      </Box>
      {settingsSelected === 'general' ? (
        <GeneralSettingsDiffModal />
      ) : settingsSelected === 'online' ? (
        <OnlineOrderingSettingsDiffModal />
      ) : settingsSelected === 'register' ? (
        <RegisterModeDiffModal />
      ) : settingsSelected === 'users' ? (
        <UserSettingsDiffModal />
      ) : settingsSelected === 'roles' ? (
        <RolesDiffModal />
      ) : settingsSelected === 'lockScreen' ? (
        <LockScreenDiffModal />
      ) : settingsSelected === 'prepStations' ? (
        <PrepStationsDiffModal />
      ) : settingsSelected === 'registerTicket' ? (
        <RegisterTicketDiffModal />
      ) : settingsSelected === 'kitchenTicket' ? (
        <KitchenTicketDiffModal />
      ) : settingsSelected === 'guestCheck' ? (
        <GuestCheckDiffModal />
      ) : settingsSelected === 'guestReceipt' ? (
        <GuestReceiptDiffModal />
      ) : settingsSelected === 'printerRules' ||
        settingsSelected === 'licenseSpecificPrinterRules' ? (
        <PrintingRulesDiffModal />
      ) : settingsSelected === 'taxes' ? (
        <TaxesDiffModal />
      ) : settingsSelected === 'payments' ? (
        <PaymentsDiffModal />
      ) : (
        <Typography>{JSON.stringify(differences, null, 3)}</Typography>
      )}
    </Modal>
  );
};

export default SettingsDiffConfirmationModal;
