import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ICategory,
  SectionId,
  getMenuCategorySubcategoryMenuTypeElements,
  menuCategoryAssignedSubcategoriesFactory,
  useMenuDetailsForm,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import without from 'lodash/without';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('categories', SECTION_ID);

const AssignedSubcategoriesSection = ({ t }: WithTranslation) => {
  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const subcategories = useSelector(selectSubcategories);
  const { itemData } = useSelector(getMemoizedItemData);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigateWithOrg();

  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const { getValue, setValue } = useMenuDetailsForm('categories');

  const assignedSubcategoryMenuTypeItemsIds: ICategory['subcategoryMenuTypeItemsIds'] =
    getValue('subcategoryMenuTypeItemsIds');

  const assignedSubcategoryMenuTypeCombosIds: ICategory['subcategoryMenuTypeCombosIds'] =
    getValue('subcategoryMenuTypeCombosIds');
  const name = getValue('name');

  const subcategoryIds = getValue('subcategoryIds');

  const onDisassociate = useCallback(
    (deletedSubcategoryId: number | string) => {
      if (typeof deletedSubcategoryId === 'string') {
        return;
      }

      const newSubcategoryMenuTypeItemsIds = cloneDeep(
        assignedSubcategoryMenuTypeItemsIds,
      );
      const newSubcategoryMenuTypeCombosIds = cloneDeep(
        assignedSubcategoryMenuTypeCombosIds,
      );

      delete newSubcategoryMenuTypeItemsIds[deletedSubcategoryId];
      delete newSubcategoryMenuTypeCombosIds[deletedSubcategoryId];

      setValue({
        subcategoryMenuTypeItemsIds: newSubcategoryMenuTypeItemsIds,
        subcategoryMenuTypeCombosIds: newSubcategoryMenuTypeCombosIds,
        itemIds: getMenuCategorySubcategoryMenuTypeElements(
          newSubcategoryMenuTypeItemsIds,
        ),
        comboIds: getMenuCategorySubcategoryMenuTypeElements(
          newSubcategoryMenuTypeCombosIds,
        ),
        subcategoryIds: without(subcategoryIds || [], deletedSubcategoryId),
      });
    },
    [
      assignedSubcategoryMenuTypeItemsIds,
      assignedSubcategoryMenuTypeCombosIds,
      setValue,
      subcategoryIds,
    ],
  );

  const assignedItemsCardList = useMemo(() => {
    return menuCategoryAssignedSubcategoriesFactory(
      name || '"Name"',
      subcategories,
      subcategoryIds,
      assignedSubcategoryMenuTypeItemsIds,
      assignedSubcategoryMenuTypeCombosIds,
      onDisassociate,
    ).map(subcategoryCard => {
      const pathURL = `/menu/subcategories/${subcategoryCard.id}`;

      return {
        ...subcategoryCard,
        title: subcategoryCard.title,
        tag: subcategoryCard.customTag,
        showStatus: true,
        isActive: subcategoryCard.status,
        onClick: (openInNewTab?: boolean) => {
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          subcategoryCard.id > 0 &&
            checkForChangesAndNavigateWeb(
              () =>
                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: subcategoryCard.title,
                  onPress: () => navigate(pathURL),
                  pathURL,
                }),
              onRefresh,
            );
        },
        isLink: getPathWithOrgData(pathURL),
      } as IItemCard;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subcategories,
    subcategoryIds,
    assignedSubcategoryMenuTypeItemsIds,
    name,
    assignedSubcategoryMenuTypeCombosIds,
    subcategoryIds,
  ]);

  const handleCloseModal = useCallback(() => {
    setOpenSelectionModal(false);
  }, []);

  const handleOnAssociate = useCallback(
    (buttons: IItemSelection[]) => {
      const newSubcategoryMenuTypeItemsIds = cloneDeep(
        assignedSubcategoryMenuTypeItemsIds,
      );

      const newSubcategoryMenuTypeCombosIds = cloneDeep(
        assignedSubcategoryMenuTypeCombosIds,
      );

      buttons.forEach(button => {
        const currentMenuTypeItemIds = cloneDeep(
          subcategories[Number(button.id)].menuTypeItemIds,
        );

        const currentMenuTypeComboIds = cloneDeep(
          subcategories[Number(button.id)].menuTypeComboIds,
        );

        const forEach = (
          menuTypeItemIds: ICategory['subcategoryMenuTypeItemsIds'][0],
          elementToFilter: ICategory['subcategoryMenuTypeItemsIds'][0],
        ) => {
          Object.entries(menuTypeItemIds).forEach(([menuTypeId, itemIds]) => {
            itemIds.forEach(itemId => {
              const newIds = without(
                get(elementToFilter, `${menuTypeId}`) || [],
                itemId,
              );
              set(elementToFilter, `${menuTypeId}`, newIds);
            });
          });
        };

        if (Object.keys(currentMenuTypeItemIds).length > 0) {
          Object.values(newSubcategoryMenuTypeItemsIds).forEach(
            menuTypeItemIds => forEach(menuTypeItemIds, currentMenuTypeItemIds),
          );
          newSubcategoryMenuTypeItemsIds[Number(button.id)] =
            currentMenuTypeItemIds;
        }

        if (Object.keys(currentMenuTypeComboIds).length > 0) {
          Object.values(newSubcategoryMenuTypeCombosIds).forEach(
            menuTypeComboIds =>
              forEach(menuTypeComboIds, currentMenuTypeComboIds),
          );
          newSubcategoryMenuTypeCombosIds[Number(button.id)] =
            currentMenuTypeComboIds;
        }
      });

      setValue({
        subcategoryMenuTypeItemsIds: newSubcategoryMenuTypeItemsIds,
        subcategoryMenuTypeCombosIds: newSubcategoryMenuTypeCombosIds,
        itemIds: getMenuCategorySubcategoryMenuTypeElements(
          newSubcategoryMenuTypeItemsIds,
        ),
        comboIds: getMenuCategorySubcategoryMenuTypeElements(
          newSubcategoryMenuTypeCombosIds,
        ),
        subcategoryIds: [
          ...subcategoryIds,
          ...buttons.map(button => Number(button.id)),
        ],
      });

      handleCloseModal();
    },
    [
      assignedSubcategoryMenuTypeItemsIds,
      assignedSubcategoryMenuTypeCombosIds,
      handleCloseModal,
      setValue,
      subcategories,
      subcategoryIds,
    ],
  );

  return (
    <AssignedElements
      type="subcategories"
      detailsScreenProps={{
        currentRelationsIds: Object.keys(assignedItemsCardList) || [],
        wantedEntity: 'subcategories',
      }}
      noElementsMessage={t(
        'menuScreen.categoryDetails.associations.subcategories.emptyCardsMessage',
      )}
      active={openSelectionModal}
      onModalClose={handleCloseModal}
      onAssociate={handleOnAssociate}
      onDisassociate={onDisassociate}
      elements={assignedItemsCardList}
      subCardProps={{
        title: t('loggedIn.menuModule.tabs.subcategories'),
        subtitle: t(
          'menuScreen.categoryDetails.associations.subcategories.emptyCardsMessage',
        ),
        actionOptions: [
          {
            text: t('components.actionButtons.addNewSubcategory'),
            icon: true,
            handler: () =>
              checkForChangesAndNavigateWeb(
                () =>
                  addBreadcrumbLocationWeb({
                    action: BreadCrumbAction.ADD,
                    text: t('components.actionButtons.addNewSubcategoryTag'),
                    onPress: () => {
                      navigate('/menu/subcategories/add', {
                        state: { categoryId: itemData?.categoryId },
                      });
                    },
                    pathURL: '/menu/subcategories/add',
                  }),
                onRefresh,
                true,
                false,
                'subcategories',
              ),
          },
          {
            text: t('components.actionButtons.addExistingSubcategory'),
            icon: false,
            handler: () => setOpenSelectionModal(true),
          },
        ],
        csx: { borderWidth: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 },
      }}
    />
  );
};

export default AssignedSubcategoriesSection;
