import { isNumber } from 'lodash';
import i18n from '../../i18n.config';
import { ParseKeys } from 'i18next';

export enum EntityTypes {
  ITEM = 'item',
  COMBO = 'combo',
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  MENU_TYPE = 'menuType',
  MODIFIER_ITEM = 'modifierItem',
  MODIFIER_GROUP = 'modifierGroup',
  LOCATION = 'location',
  ROLE = 'role',
  USER = 'user',
}

export const getItemCardTag = (
  entity: EntityTypes,
  count?: number,
  totalCount?: number,
) => {
  // TODO: Add other translations for EntityTypes to core if needed
  if (isNumber(count) && count === totalCount) {
    return i18n.t(`components.menuCard.tag.${entity}All` as ParseKeys);
  }
  return i18n.t(
    `components.menuCard.tag.${entity}${
      totalCount ? 'Fraction' : 'Count'
    }` as ParseKeys,
    { count, total: totalCount },
  );
};
