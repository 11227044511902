import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { css } from '@emotion/react';

export const optionTypeButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  '.triangleIcon': {
    rotate: '180deg',
    position: 'absolute',
    bottom: -12,
  },
  [MQ_MIN_MEDIUM]: {
    '.triangleIcon': {
      display: 'flex',
      rotate: '90deg',
      bottom: 'auto',
      right: -20,
      marginTop: '4px',
    },
  },
});
