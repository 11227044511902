import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IServiceAreaStore,
  actionCreatorsTableLayout,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ServiceArea from '../ServiceArea';

interface IServiceAreasList {
  serviceAreas: IServiceAreaStore[];
  reorderEnabled: boolean;
  selectedServiceArea: IServiceAreaStore | null;
  setEditServiceArea: (value: IServiceAreaStore | null) => void;
}
const ServiceAreasList = ({
  serviceAreas,
  reorderEnabled,
  selectedServiceArea,
  setEditServiceArea,
}: IServiceAreasList) => {
  // Redux
  const setLastServiceAreasOrder = bindActionCreators(
    actionCreatorsTableLayout.setLastServiceAreasOrder,
    useDispatch(),
  );

  // Local state
  const [localSortedItems, setLocalSortedItems] =
    useState<IServiceAreaStore[]>(serviceAreas);

  const [isOneDragging, setIsOneDragging] = useState(false);

  useEffect(() => {
    setLocalSortedItems(serviceAreas);
  }, [serviceAreas]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 100, tolerance: 0 },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const newOrderRows = [...localSortedItems];
      const oldIndex = newOrderRows.findIndex(
        (_, index) => index + 1 === active.id,
      );
      const newIndex = newOrderRows.findIndex(
        (_, index) => index + 1 === over?.id,
      );
      newOrderRows.splice(newIndex, 0, newOrderRows.splice(oldIndex, 1)[0]);
      setLocalSortedItems(newOrderRows);
      setLastServiceAreasOrder(
        newOrderRows.map((item, index) => ({
          id: item.id,
          sortPriority: index,
          width: 0,
          x: 0,
        })),
      );
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}>
      <SortableContext
        items={localSortedItems.map((_, index) => index + 1)}
        strategy={horizontalListSortingStrategy}
        disabled={!reorderEnabled}>
        {Object.values(localSortedItems).map((serviceArea, index) => (
          <ServiceArea
            key={`service_area_${serviceArea.id}`}
            selectedServiceArea={selectedServiceArea}
            serviceArea={serviceArea}
            id={serviceArea.id}
            index={index}
            reorderEnabled={reorderEnabled}
            onClickEditServiceArea={setEditServiceArea}
            isOneDragging={isOneDragging}
            setIsDragging={setIsOneDragging}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default ServiceAreasList;
