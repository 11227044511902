import Box from '@app/components/common/Box';
import { RootState } from '@app/state/store';
import { ICombo, IComboErrorFields } from '@westondev/tableturn-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ErrorRow,
  HorizontalLine,
  SectionText,
  SubSectionText,
  VCenteredView,
} from './BucketValidationResolver';
import { ErrorTemplates } from './ErrorTemplates';
import { ParseKeys } from 'i18next';
import { selectCurrentItem } from '@app/state/menu/menuSelectors';

const RenderComboErrorBody = () => {
  const { t } = useTranslation();
  const formErrors = useSelector(
    (state: RootState) => state.menu.changeData.errors,
  );
  const errors = formErrors as IComboErrorFields;
  const errorsArray = Object.entries(errors);
  const menuTypes = useSelector(
    (state: RootState) => state.menu.masterMenu.menuTypes,
  );

  const changeData = useSelector(selectCurrentItem);

  const commonIssues: [string, string][] = errorsArray.filter(
    ([key]) => key !== 'menuTypeVersions',
  );
  const menuTypeIssues =
    'menuTypeVersions' in errors ? errors.menuTypeVersions : null;

  return (
    <>
      {commonIssues.length > 0 && (
        <Box style={{ marginBottom: '18px' }}>
          <VCenteredView>
            <SectionText>{t('validations.issues')}</SectionText>
            <HorizontalLine />
          </VCenteredView>
          {commonIssues.map(([key, value]) => (
            <ErrorRow key={key}>
              <ErrorTemplates
                errorKey={value}
                field={t(
                  `menuScreen.itemDetails.validationFields.${key}` as ParseKeys,
                )}
              />
            </ErrorRow>
          ))}
        </Box>
      )}
      {menuTypeIssues && (
        <>
          <VCenteredView>
            <SectionText>{t('validations.issuesInMenuTypes')}</SectionText>
            <HorizontalLine />
          </VCenteredView>
          {Object.entries(menuTypeIssues).map(([key, value]) => (
            <React.Fragment key={key}>
              <SubSectionText>
                {t('validations.menuTypeTitle', {
                  menuType: menuTypes[Number(key)]?.posName,
                })}
              </SubSectionText>
              {Object.entries(value).map(([mtkey, mterr]) =>
                mtkey === 'itemGroups' ? (
                  Object.entries(mterr).map(([igId, ig]) => (
                    <Box key={igId} csx={{ paddingLeft: '50px' }}>
                      <SubSectionText>
                        {t('validations.itemGroupTitle', {
                          itemGroup: (changeData as ICombo).menuTypeVersions[
                            Number(key)
                          ].itemGroups[igId].name,
                        })}
                      </SubSectionText>
                      {Object.entries(ig).map(([igErrorKey, igError]) => (
                        <ErrorRow key={igErrorKey}>
                          <ErrorTemplates
                            field={t(
                              `menuScreen.itemDetails.validationFields.${igErrorKey}` as ParseKeys,
                            )}
                            errorKey={igError as string}
                          />
                        </ErrorRow>
                      ))}
                    </Box>
                  ))
                ) : (
                  <ErrorRow key={mtkey}>
                    <ErrorTemplates
                      field={t(
                        `menuScreen.itemDetails.validationFields.${mtkey}` as ParseKeys,
                      )}
                      errorKey={typeof mterr === 'string' ? mterr : ''}
                    />
                  </ErrorRow>
                ),
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default RenderComboErrorBody;
