import type { MouseEvent } from 'react';
import { MouseSensor, PointerSensorOptions } from '@dnd-kit/core';

enum MouseButton {
  leftClick = 0,
  middleClick = 1,
  RightClick = 2,
}

export class CustomMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: (
        { nativeEvent: event }: MouseEvent,
        { onActivation }: PointerSensorOptions,
      ) => {
        if (
          event.button === MouseButton.RightClick ||
          event.button === MouseButton.middleClick
        ) {
          return false;
        }

        onActivation?.({ event });

        return true;
      },
    },
  ];
}
