import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CombosSelectionModal from '../CombosSelectionModal';
import { ICustomSelectionModal } from '../types';
import { IItemSelection } from '../GenericSelectionModal/types';
import { selectMenu } from '@app/state/menu/menuSelectors';
import { factoriesEntitiesMap } from '@app/helpers/factories/factoriesMap';
import { getDetailsScreenData } from '@app/helpers/modals/additionModal';

type ICombosItemSelectionModal = Omit<ICustomSelectionModal<unknown>, 'buttons'> & {
  filterFunction?: (buttons: IItemSelection[]) => IItemSelection[];
};

const ItemsAndCombosSelectionModal = ({
  t,
  active,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  showDescriptionPanel = true,
  btnSuccessText,
  filterFunction,
}: ICombosItemSelectionModal) => {
  //redux
  const locationMenu = useSelector(selectMenu);

  const buttons = useMemo(() => {
    const itemsDiff = getDetailsScreenData([], 'items');

    const itemsEntities: { [key: number]: any } = {};

    itemsDiff.forEach(id => {
      itemsEntities[Number(id)] = locationMenu.items[Number(id)];
    });

    const itemsSelection: IItemSelection[] = (
      factoriesEntitiesMap('items', itemsEntities) || []
    ).map(item => ({ ...item, entity: 'items' }) as IItemSelection);

    const combosDiff = getDetailsScreenData([], 'combos');

    const combosEntities: { [key: number]: any } = {};

    combosDiff.forEach(id => {
      combosEntities[Number(id)] = locationMenu.combos[Number(id)];
    });

    const combosSelection: IItemSelection[] = (
      factoriesEntitiesMap('combos', combosEntities) || []
    ).map(combo => ({ ...combo, entity: 'combos' }) as IItemSelection);

    const mergedButtons = orderBy(
      [...itemsSelection, ...combosSelection],
      element => element.id,
    );

    return filterFunction ? filterFunction(mergedButtons) : mergedButtons;
  }, [locationMenu, filterFunction]);

  return (
    <CombosSelectionModal
      key={`open-${active}`}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons || []}
      title={t('menuScreen.selectionModal.combosItems.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.combosItems.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.combosItems.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.combosItems.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.combosItems.noAvailableCardsText',
      )}
      emptyLocationsMessage={t(
        'menuScreen.selectionModal.combosItems.locations.emptyCardsMessage',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      btnSuccessText={btnSuccessText}
    />
  );
};

export default ItemsAndCombosSelectionModal;
