import { Theme, css } from '@emotion/react';

export const itemCardStyles =
  (
    isSelected: boolean,
    isDisabled: boolean,
    showStatus: boolean,
    fontColor?: string,
    backgroundColor?: string,
  ) =>
  (theme: Theme) =>
    css({
      '> .active': {
        backgroundColor: `${
          backgroundColor
            ? backgroundColor
            : isSelected
            ? theme.colors.semanticBlue
            : theme.colors.white
        } !important`,
        border: `2px solid ${theme.colors.semanticBlue} !important`,
      },
      '> .inactive': {
        backgroundColor: `${
          backgroundColor
            ? backgroundColor
            : isSelected
            ? theme.colors.persistentSemanticRed
            : theme.colors.lightestRed
        } !important`,
        border: `2px solid ${theme.colors.persistentSemanticRed} !important`,
        '& .title': {
          color: `${
            fontColor
              ? fontColor
              : isSelected
              ? theme.colors.textWhite
              : theme.colors.semanticRed
          } !important`,
        },
      },
      '.itemCard': {
        width: '100%',
        height: '90px',
        backgroundColor: backgroundColor
          ? backgroundColor
          : isDisabled
          ? theme.colors.lightestGrey
          : isSelected
          ? theme.colors.semanticBlue
          : theme.colors.white,
        borderRadius: '8px',
        padding: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: isDisabled
          ? `2px solid ${theme.colors.lightGrey}`
          : isSelected
          ? `2px solid ${theme.colors.darkBlue} !important`
          : '2px solid transparent',
        userSelect: 'none',
        textDecoration: 'none',
        '.test': {
          backgroundColor: 'black !important',
        },
        '.itemCardContent': {
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          '.title': {
            textAlign: 'center',
            color: fontColor
              ? fontColor
              : isDisabled
              ? theme.colors.lightGrey
              : isSelected
              ? theme.colors.textWhite
              : theme.colors.text,
          },
          '.subtitle': {
            color: fontColor
              ? fontColor
              : isDisabled
              ? theme.colors.lightGrey
              : isSelected
              ? theme.colors.textWhite
              : theme.colors.darkGrey,
          },
          p: {
            textAlign: 'center',
          },
        },
        '.itemCardStatus': {
          position: 'absolute',
          left: '10px',
          bottom: '5px',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          '.dot': {
            height: '10px',
            width: '10px',
            borderRadius: '50%',
          },
        },
        '.itemCardTopTag': {
          position: 'absolute',
          right: '10px',
          top: '5px',
          '& p': {
            color: isSelected ? theme.colors.textWhite : theme.colors.black,
          },
        },
        '.active': {
          '& div': {
            backgroundColor: isSelected
              ? theme.colors.textWhite
              : theme.colors.semanticBlue,
          },
          '& p': {
            color: isSelected
              ? theme.colors.textWhite
              : theme.colors.semanticBlue,
          },
        },
        ':hover': {
          cursor: 'pointer',
          '.title': {
            color: `${
              fontColor
                ? fontColor
                : isDisabled
                ? theme.colors.lightGrey
                : isSelected
                ? theme.colors.textWhite
                : theme.colors.semanticBlue
            }`,
            fontWeight: theme.fontWeights.medium,
          },
        },
        '.inactive': {
          '& div': {
            backgroundColor: isSelected
              ? theme.colors.textWhite
              : theme.colors.persistentSemanticRed,
          },
          '& p': {
            color: isSelected
              ? theme.colors.textWhite
              : theme.colors.persistentSemanticRed,
          },
        },
        '&[data-active="true"]': {
          border: isDisabled
            ? `2px solid ${theme.colors.lightGrey}`
            : `2px solid ${theme.colors.semanticBlue}`,
          ':hover': {
            cursor: 'pointer',
            '.title': {
              color: `${
                fontColor
                  ? fontColor
                  : isDisabled
                  ? theme.colors.lightGrey
                  : isSelected
                  ? theme.colors.textWhite
                  : theme.colors.semanticBlue
              } !important`,
              fontWeight: theme.fontWeights.medium,
            },
          },
        },
        '&[data-active="false"]': {
          border: `2px solid ${theme.colors.darkerRed} !important`,
          '.itemCardTag': {
            backgroundColor: theme.colors.lighterRed,
            color: theme.colors.semanticRed,
          },
          ':hover': {
            cursor: 'pointer',
            '.title': {
              color: `${
                fontColor
                  ? fontColor
                  : isDisabled
                  ? theme.colors.lightGrey
                  : isSelected
                  ? theme.colors.textWhite
                  : theme.colors.persistentSemanticRed
              } !important`,
              fontWeight: theme.fontWeights.medium,
            },
          },
        },
      },
      '.footerIcon': {
        position: 'absolute',
        bottom: 3,
        right: 5,
      },
      '.hiddenLabel': {
        position: 'absolute',
        bottom: showStatus || isSelected ? undefined : 3,
        left: showStatus || isSelected ? undefined : 5,
        top: showStatus || isSelected ? 3 : undefined,
        right: showStatus || isSelected ? 5 : undefined,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      },
    });

export const removeIconStyles = (theme: Theme) =>
  css({
    position: 'absolute',
    top: -8,
    right: -8,
    backgroundColor: theme.colors.semanticRed,
    borderRadius: '50%',
    display: 'flex',
    padding: '3px',
    zIndex: 1,
    border: `2px solid ${theme.colors.semanticRed}`,
    ':hover': {
      cursor: 'pointer',
    },
  });

export const dragIconStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.lightGrey,
    width: '14px',
    marginLeft: '-3px',
    borderRadius: '5px',
    position: 'absolute',
    zIndex: 1,
    alignSelf: 'center',
    bottom: 0,
    top: 0,
  });

export const editIconStyles = (theme: Theme) =>
  css({
    position: 'absolute',
    top: -8,
    right: -8,
    backgroundColor: theme.colors.white,
    borderRadius: '50%',
    border: `2px solid ${theme.colors.lightGrey}`,
    display: 'flex',
    padding: '3px',
    ':hover': {
      cursor: 'pointer',
    },
  });
