import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal/Modal';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsSettingsWeb } from '@app/state';
import {
  currentLicenseSettingsSettingsSelector,
  currentLicensesSelector,
} from '@app/state/selectors/settingsSelectors';
import {
  CreateSettingsBucketRequestType,
  FormMode,
  ITerminal,
  ITerminalDiff,
  TChange,
  licensesDropdownOptionsFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IEditTerminalModal extends WithTranslation {
  isActive?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  terminalData?: ITerminal;
  isAdd?: boolean;
}

const EditTerminalModal = ({
  t,
  isActive = false,
  onCancel = () => null,
  onSuccess = () => null,
  terminalData,
  isAdd = false,
}: IEditTerminalModal) => {
  const { editSettingsElementWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const navigate = useNavigateWithOrg();

  const [newName, setNewName] = useState(terminalData?.name || '');
  const [newTpn, setNewTpn] = useState(String(terminalData?.tpn || ''));
  const [newAuthKey, setNewAuthKey] = useState(terminalData?.authKey || '');
  const [newLicenseIds, setNewLicenseIds] = useState(
    terminalData?.licenseIds || [],
  );

  const licenses = useSelector(currentLicensesSelector);
  const licensesSettings = useSelector(currentLicenseSettingsSettingsSelector);

  useEffect(() => {
    setNewName(terminalData?.name || '');
    setNewTpn(String(terminalData?.tpn || ''));
    setNewAuthKey(terminalData?.authKey || '');
    setNewLicenseIds(terminalData?.licenseIds || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const licenseOptions = useMemo(
    () => licensesDropdownOptionsFactory(licenses || {}, licensesSettings),
    [licenses, licensesSettings],
  );

  const getLicensesDiff = (terminalDat: ITerminal) => {
    const oldLicenses = new Set(terminalDat.licenseIds);
    const newLicenses = new Set(newLicenseIds);

    const diff: { action: 'add' | 'remove'; id: number }[] = [];

    terminalDat.licenseIds.forEach(licenseId => {
      if (!newLicenses.has(licenseId)) {
        diff.push({
          action: 'remove',
          id: licenseId,
        });
      }
    });

    newLicenseIds.forEach(licenseId => {
      if (!oldLicenses.has(licenseId)) {
        diff.push({
          action: 'add',
          id: licenseId,
        });
      }
    });
    return diff;
  };

  const handleOnUpdate = async () => {
    onCancel();

    if (!terminalData) return;

    const request: CreateSettingsBucketRequestType = {
      settingsSelected: 'terminals',
      id: terminalData.id,
      data: {},
      diffLocationIds: [],
      diff: {
        changes: [
          {
            field: 'authKey',
            label: 'Auth Key',
            value: newAuthKey.trim(),
          } as TChange,
          {
            field: 'name',
            label: 'Name',
            value: newName.trim(),
          } as TChange,
          ...(newTpn.trim() !== terminalData?.tpn.trim()
            ? [
                {
                  field: 'tpn',
                  label: 'Terminal Profile Number',
                  value: newTpn.trim(),
                } as TChange,
              ]
            : []),
        ],
        licenseChanges: getLicensesDiff(terminalData),
        action: 'update',
      } as ITerminalDiff,
      successMessage: t(
        'settingsModule.devices_licenses.editTerminalModal.success',
      ),
    };

    await editSettingsElementWeb(
      () => null,
      navigate,
      false,
      request,
      FormMode.UPDATE,
    );
    onSuccess();
  };

  const handleOnDelete = async () => {
    onCancel();

    if (!terminalData) return;

    const request: CreateSettingsBucketRequestType = {
      settingsSelected: 'terminals',
      id: terminalData.id,
      data: {},
      diffLocationIds: [],
      diff: {
        changes: [],
        licenseChanges: [],
        action: 'remove',
      } as ITerminalDiff,
      successMessage: t(
        'settingsModule.devices_licenses.editTerminalModal.deleteSuccess',
      ),
    };
    await editSettingsElementWeb(
      () => null,
      navigate,
      false,
      request,
      FormMode.UPDATE,
    );
    onSuccess();
  };

  const handleOnAdd = async () => {
    onCancel();

    const request: CreateSettingsBucketRequestType = {
      settingsSelected: 'terminals',
      id: 0,
      data: {},
      diffLocationIds: [],
      diff: {
        changes: [
          {
            field: 'authKey',
            label: 'Auth Key',
            value: newAuthKey.trim(),
          } as TChange,
          {
            field: 'tpn',
            label: 'Terminal Profile Number',
            value: newTpn.trim(),
          } as TChange,
          {
            field: 'name',
            label: 'Name',
            value: newName.trim(),
          } as TChange,
          {
            field: 'licenseIds',
            label: 'Associated Licenses',
            value: newLicenseIds,
          },
        ],
        licenseChanges: [],
        action: 'add',
      } as ITerminalDiff,
      successMessage: t(
        'settingsModule.devices_licenses.editTerminalModal.success',
      ),
    };

    await editSettingsElementWeb(
      () => null,
      navigate,
      false,
      request,
      FormMode.UPDATE,
    );
    onSuccess();
  };

  return (
    <Modal
      isActive={isActive}
      size={500}
      title={
        terminalData && (
          <Box>
            <Typography
              variant="subtitle"
              fontWeight="medium"
              csx={{ marginBottom: '2px' }}>
              {t('settingsModule.devices_licenses.editTerminalModal.title')}
            </Typography>
            <Typography color="darkBlue">{terminalData.name}</Typography>
          </Box>
        )
      }
      onModalClose={onCancel}
      footer={
        <>
          <Button csx={{ width: '100px' }} onClick={onCancel}>
            {t('commonButtons.cancel')}
          </Button>
          {isAdd ? (
            <Button
              disabled={
                newName.trim().length === 0 ||
                String(newTpn).trim().length === 0 ||
                newAuthKey.trim().length === 0
              }
              variant="primary"
              icon={<Icon name="MdSave" />}
              onClick={handleOnAdd}>
              {t('commonButtons.save')}
            </Button>
          ) : (
            <>
              <Button
                variant="danger"
                csx={{ width: '100px' }}
                onClick={handleOnDelete}>
                {t(
                  'settingsModule.devices_licenses.editTerminalModal.deleteTerminal',
                )}
              </Button>
              <Button
                disabled={
                  newName.trim().length === 0 ||
                  String(newTpn).trim().length === 0 ||
                  newAuthKey.trim().length === 0
                }
                variant="primary"
                icon={<Icon name="MdSave" />}
                onClick={handleOnUpdate}>
                {t('commonButtons.saveChanges')}
              </Button>
            </>
          )}
        </>
      }>
      <Grid rowGap={15}>
        <Grid.Item>
          <Input
            value={newName}
            label={t('settingsModule.devices_licenses.terminalsTable.name')}
            placeholder={t(
              'settingsModule.devices_licenses.terminalsTable.name',
            )}
            onChange={e => setNewName(e.currentTarget.value)}
          />
        </Grid.Item>
        <Grid.Item>
          <Input
            value={newTpn}
            label={t('settingsModule.devices_licenses.terminalsTable.tpn')}
            placeholder={t(
              'settingsModule.devices_licenses.terminalsTable.tpn',
            )}
            onChange={e => setNewTpn(e.currentTarget.value)}
          />
        </Grid.Item>
        <Grid.Item>
          <Input
            value={newAuthKey}
            label={t('settingsModule.devices_licenses.terminalsTable.authKey')}
            placeholder={t(
              'settingsModule.devices_licenses.terminalsTable.authKey',
            )}
            onChange={e => setNewAuthKey(e.currentTarget.value)}
          />
        </Grid.Item>

        <Grid.Item>
          <Box>
            <Typography fontWeight="medium" csx={{ marginBottom: '8px' }}>
              {t(
                'settingsModule.devices_licenses.editTerminalModal.associatedLicense',
              )}
            </Typography>
            <OptionsModal
              options={licenseOptions}
              allowMultipleSelection
              isSearchable
              noElementMessage={t(
                'settingsModule.devices_licenses.terminalsTable.noLicenses',
              )}
              title={t(
                'settingsModule.devices_licenses.editTerminalModal.associateLicense',
              )}
              buttonProps={{
                children:
                  newLicenseIds.length === 0
                    ? t(
                        'settingsModule.devices_licenses.terminalsTable.noLicenses',
                      )
                    : newLicenseIds
                        .map(
                          licenseId =>
                            licensesSettings[
                              licenses[licenseId]?.licenseSettingsId
                            ]?.nickname || '',
                        )
                        .join(', '),
                style: {
                  justifyContent: 'space-between',
                  width: '100%',
                },
              }}
              currentOptionsSelected={newLicenseIds.map(licenseId => ({
                value: licenseId,
                label:
                  licensesSettings[licenses[licenseId]?.licenseSettingsId]
                    ?.nickname || '',
              }))}
              onChangeDone={(licenseIds: IOption[]) => {
                const ids = licenseIds.map(licenseId => licenseId.value);
                setNewLicenseIds(ids);
              }}
            />
          </Box>
        </Grid.Item>
      </Grid>
    </Modal>
  );
};

export default EditTerminalModal;
