import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import Typography from '@app/components/common/Typography';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  SettingsSectionId,
  IPrepStationsSettings,
  actionCreatorsSettings,
  prepStationsOptionModalFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IPrepStationsSettings>(SECTION_ID);

const DefaultPrepStation = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const prepStationOptions = useMemo(
    () => prepStationsOptionModalFactory(data?.prepStations || {}),
    [data?.prepStations],
  );

  return (
    <Card>
      <Typography fontWeight="medium" csx={{ marginBottom: '5px' }}>
        {t(
          'settingsModule.prepStationsSettings.defaultPrepStationSection.title',
        )}
      </Typography>
      <Typography>
        {t(
          'settingsModule.prepStationsSettings.defaultPrepStationSection.description',
        )}
      </Typography>

      <Box
        csx={{
          maxWidth: '260px',
          marginTop: '30px',
        }}>
        <label css={labelStyles}>
          {t(
            'settingsModule.prepStationsSettings.defaultPrepStationSection.defaultPrepStationId.label',
          )}
        </label>
        <OptionsModal
          options={prepStationOptions}
          value={data?.defaultPrepStationId || 0}
          specialOptions={[
            {
              label: t(
                'settingsModule.prepStationsSettings.defaultPrepStationSection.defaultPrepStationId.noDefaultPrepStation',
              ),
              value: 0,
            },
          ]}
          title={t(
            'settingsModule.prepStationsSettings.defaultPrepStationSection.defaultPrepStationId.label',
          )}
          placeholder={t(
            'settingsModule.prepStationsSettings.defaultPrepStationSection.defaultPrepStationId.noDefaultPrepStation',
          )}
          onChange={(defaultPrepStationId: number | null) =>
            setValue<IPrepStationsSettings>(
              {
                defaultPrepStationId:
                  defaultPrepStationId === 0 ? null : defaultPrepStationId,
              },
              SECTION_ID,
            )
          }
          buttonProps={{
            csx: {
              width: '100%',
              justifyContent: 'space-between',
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default DefaultPrepStation;
