import Box from '@app/components/common/Box';
import { HeaderTableStyles } from './styles';
import Typography from '@app/components/common/Typography';

interface IHeaderTable {
  title: string;
}
const HeaderTable = ({ title }: IHeaderTable) => {
  return (
    <Box csx={HeaderTableStyles}>
      <Typography variant="body">{title}</Typography>
    </Box>
  );
};

export default HeaderTable;
