import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { WithTranslation } from 'react-i18next';

interface IEmptyField extends WithTranslation {
  label?: string;
}

const EmptyField = ({ t, label }: IEmptyField) => {
  return (
    <Box>
      {label && (
        <Box
          csx={{
            width: '350px',
          }}>
          <Typography csx={{ marginRight: '5px' }} color="black">
            {label}
          </Typography>
        </Box>
      )}
      <Box
        csx={theme => ({
          width: '100%',
          height: '40px',
          backgroundColor: theme.colors.lighterGrey,
          borderRadius: '10px',
          border: `1px solid ${theme.colors.lightGrey}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
        })}>
        <Icon name="MdFormatClear" color="black" />
        <Typography color="black">{t('commonTexts.emptyField')}</Typography>
      </Box>
    </Box>
  );
};

export default EmptyField;
