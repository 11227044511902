import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import ImageUploader from '@app/components/common/ImageUploader';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import { ETableModes } from '@app/components/common/Table/types';
import TextAreaInput from '@app/components/common/TextAreaInput';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { adsSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  IAd,
  IRegisterSettings,
  REGISTER_AD_INITIAL_STATE,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getAdId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ADS_TABLE_ID,
  IAdsTable,
  ROW_ID_PREFIX,
  SECTION_ID,
  columnHelper,
  getMemoizedItemData,
} from './types';

const { areSettingsMasterModeSelector } = settingsSelectors;

const AdsTable = () => {
  // redux
  const dispatch = useDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const clearSettingsError = bindActionCreators(
    actionCreatorsSettingsChangeData.clearSettingsError,
    dispatch,
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const actualAds = useSelector(adsSelector);
  const { data: bigData } = useSelector(getMemoizedItemData);

  // Local state
  const data = bigData?.ads;

  const handleScrollToNewRow = useScrollToNewRow();

  const updateAd = (newValue: any, id: number, field: keyof IAd) => {
    const ads = (store.getState().settings.changeData.data as IRegisterSettings)
      ?.ads;
    setValue<IRegisterSettings>(
      {
        ads: {
          ...ads,
          [id]: {
            ...ads[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const onNewAd = () => {
    const nextId =
      (max(Object.keys(data || {}).map(key => Number(key)) || []) || 0) + 1;

    const nextSortPriority =
      (max(Object.values(data || {}).map(ad => ad.sortPriority) || []) || 0) +
      1;

    setValue<IRegisterSettings>(
      {
        ads: {
          ...data,
          [nextId]: {
            ...REGISTER_AD_INITIAL_STATE,
            id: nextId,
            sortPriority: nextSortPriority,
          },
        },
      },
      SECTION_ID,
    );

    const nextIndex = Object.keys(data || {}).length;

    handleScrollToNewRow(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const adsArray = useMemo(() => {
    return data
      ? orderBy(
          Object.keys(data || {}).map(key => ({
            id: Number(key),
            active: data?.[Number(key)]?.active,
            sortPriority: data?.[Number(key)]?.sortPriority,
            imageUrl: data?.[Number(key)]?.imageUrl,
            title: data?.[Number(key)]?.title,
            text: data?.[Number(key)]?.text,
            price: data?.[Number(key)]?.price,
            delete: (actualAds || {})[Number(key)] ? '' : 'canDelete',
          })),
          'sortPriority',
        )
      : [];
  }, [data, actualAds]);

  const handleDelete = (id: number) => {
    const ads = (store.getState().settings.changeData.data as IRegisterSettings)
      ?.ads;

    let sortPriority = 1;
    const adsCloned = cloneDeep(ads);
    delete adsCloned[id];
    const adsClonedArray = Object.values(adsCloned);

    orderBy(adsClonedArray, 'sortPriority').forEach(value => {
      adsCloned[value.id].sortPriority = sortPriority;
      sortPriority++;
    });

    setValue<IRegisterSettings>(
      {
        ads: adsCloned,
      },
      SECTION_ID,
    );
  };

  const handleOnSort = (newData: IAdsTable[]) => {
    const clonedAds = cloneDeep(data);
    newData.forEach((item, index) => {
      clonedAds[item.id].sortPriority = index + 1;
    });
    setValue<IRegisterSettings>(
      {
        ads: clonedAds,
      },
      SECTION_ID,
    );
  };

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor('active', {
        header: t(
          'app.modals.registerModeDiffModal.general.specialTags.active',
        ),
        cell: info => (
          <Box csx={{ alignItems: 'center' }}>
            <Switch
              onChange={active => {
                updateAd(active, info.row.original.id, 'active');
              }}
              checked={info.getValue()}
            />
          </Box>
        ),
        size: 70,
      }),
      columnHelper.accessor('imageUrl', {
        header: t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.imageUrl',
        ),
        cell: info => (
          <Box csx={{ alignItems: 'center' }}>
            <ImageUploader
              csx={{ marginTop: 0, width: '190px' }}
              onChange={(imageUrl: string | null) => {
                updateAd(imageUrl, info.row.original.id, 'imageUrl');
              }}
              source={
                info.getValue() !== null ? info.getValue() || '' : undefined
              }
              modalProps={{
                title: t(
                  'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.image.title',
                ),
                subtitle: '',
              }}
            />
          </Box>
        ),
        maxSize: 200,
        minSize: 200,
      }),
      columnHelper.accessor('title', {
        header: t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.title',
        ),
        cell: info => (
          <Box csx={{ width: '90%' }}>
            <InputCell
              placeholder={t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.title',
              )}
              value={info.getValue()}
              onChange={e =>
                updateAd(e.currentTarget.value, info.row.original.id, 'title')
              }
              errorId={`${getAdId(`${info.row.original.id}`, 'title')}`}
              onFocusError={(errorId, hasError) =>
                hasError && clearSettingsError(errorId)
              }
            />
          </Box>
        ),
        minSize: 180,
      }),
      columnHelper.accessor('text', {
        header: t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.text',
        ),
        cell: info => (
          <Box csx={{ width: '90%' }}>
            <TextAreaInput
              placeholder={t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.text',
              )}
              value={info.getValue()}
              onChange={e =>
                updateAd(e.currentTarget.value, info.row.original.id, 'text')
              }
            />
          </Box>
        ),
        minSize: 220,
      }),
      columnHelper.accessor('price', {
        header: t(
          'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.price',
        ),
        cell: info => (
          <MoneyInput
            value={info.getValue() || 0}
            precision={2}
            onValueChange={newValue =>
              updateAd(newValue, info.row.original.id, 'price')
            }
          />
        ),
        maxSize: 130,
        minSize: 130,
      }),
      columnHelper.accessor('delete', {
        header: 'Delete',
        cell: info => (
          <Button
            color="danger"
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}></Button>
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode, theme],
  );

  return (
    <Box id={ADS_TABLE_ID}>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title={t(
            'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.title',
          )}
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewAd}>
              {t(
                'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.adsTable.addAd',
              )}
            </Button>
          }
        />
      </m.div>
      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          mode={ETableModes.SORT_VERTICALLY}
          data={adsArray}
          columns={COLUMNS}
          onSortEnd={handleOnSort}
          cellCsx={{ height: '100px' }}
          alignHeaders={{
            delete: 'center',
          }}
          align={{
            delete: 'center',
          }}
          renderEmptyValues
          rowIdPrefix={ROW_ID_PREFIX}
        />
      </Box>
    </Box>
  );
};

export default AdsTable;
