import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import DisabledBlockLayer from '@app/components/common/DisabledBlockLayer';
import Divider from '@app/components/common/Divider';
import { TDropdownData } from '@app/components/common/Dropdown/types';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  FormMode,
  IModifierGroupsItems,
  MenuTypes,
  SectionId,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Content from './Content';
import Header from './Header';
import Options from './Options';
import RearrangeSection from './RearrangeSection';

interface IModifierGroup extends WithTranslation, IModifierGroupsItems {
  modifierGroupPricingVersions: TDropdownData;
  description: string;
  isFirst: boolean;
  isLast: boolean;
  menuTypeVersionId: number;
  prevModifierGroupId: number;
  nextModifierGroupId: number;
  onDeleteModifierGroupPress: (modifierGroupId: number, name: string) => void;
  menuTypeMode: MenuTypes;
  mode?: FormMode;
  handleHideModifierGroup: (modifierGroupId: number) => void;
  isPreMadeModifierGroup?: boolean;
  disabledMessage?: string;
  isDisabled?: boolean;
  backgroundColor: string | null;
  textColor: string | null;
}

const SECTION_ID = SectionId.MODIFIER_GROUPS;

const ModifierGroup = ({
  t,
  modifierItems,
  modifierGroupPricingVersions,
  modifierGroupId,
  posName,
  description,
  requiredModifierItems,
  includedModifierItems,
  maximumModifierItems,
  quantityMode,
  multiselect,
  allowNoneSelection,
  active,
  defaultModifierGroupPricingVersionId,
  isFirst,
  isLast,
  prevModifierGroupId,
  nextModifierGroupId,
  menuTypeVersionId,
  onDeleteModifierGroupPress,
  menuTypeMode,
  priceCalcMode,
  defaultSelectionPrice,
  hidePriceIfFree,
  override,
  mode,
  handleHideModifierGroup,
  isPreMadeModifierGroup,
  isDisabled,
  disabledMessage,
  backgroundColor,
  textColor,
  inheritedActive,
}: IModifierGroup) => {
  // Redux
  const dispatch = useDispatch();

  const { updateItem: setValue, hideModifierGroup } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );
  const [hiddenModifierItemsCount, setHiddenModifierItemsCount] = useState(0);

  const handleHideModifierItem = (countValue: number) => {
    setHiddenModifierItemsCount(prev => prev + countValue);
  };

  const setIncludedRequiredAmountsToRedux = (
    included: number,
    required: number,
    maximum: number,
  ) => {
    setValue(
      {
        includedModifierItems: included,
        requiredModifierItems: required,
        maximumModifierItems: maximum,
      },
      SECTION_ID,
      mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
      modifierGroupId,
      undefined,
    );
  };

  const isHidden = !active || !inheritedActive;

  return (
    <Box
      csx={{
        width: 'inherit',
        height: 'inherit',
        position: 'relative',
      }}>
      <Card.SubCard
        title={posName}
        description={t(
          'menuScreen.itemDetails.modifierGroupsSection.modifierName',
          { name: description },
        )}
        csx={{
          padding: 0,
          '.subCardTitle': { padding: '15px' },
          overflow: 'hidden',
        }}
        options={
          <Header
            posName={posName}
            modifierGroupId={modifierGroupId}
            onDeleteModifierGroupPress={onDeleteModifierGroupPress}
            menuTypeMode={menuTypeMode}
            active={!isHidden}
            modifierGroupInfo={{
              multiselect,
              quantityMode,
              priceCalcMode,
              allowNoneSelection,
            }}
            mode={mode}
            handleHideModifierGroup={handleHideModifierGroup}
          />
        }>
        <Divider csx={{ marginTop: '-15px' }} />
        {(!isHidden || menuTypeMode === MenuTypes.MASTER_MENU) && (
          <Box
            csx={{
              display: 'flex',
            }}>
            <Box
              csx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                overflow: 'hidden',
                position: 'relative',
              }}>
              {!isPreMadeModifierGroup && (
                <Options
                  active={!isHidden}
                  showTags={false}
                  override={override}
                  menuTypeMode={menuTypeMode}
                  menuTypeVersionId={menuTypeVersionId}
                  modifierGroupId={modifierGroupId}
                  modifierItemsArray={modifierItems}
                  hiddenModifierItemsCount={hiddenModifierItemsCount}
                  setIncludedRequiredAmounts={setIncludedRequiredAmountsToRedux}
                  modifierGroupInfo={{
                    includedModifierItems,
                    requiredModifierItems,
                    maximumModifierItems,
                    multiselect,
                    quantityMode,
                    priceCalcMode,
                    totalPrice: defaultSelectionPrice,
                    backgroundColor,
                    textColor,
                  }}
                />
              )}
              <Content
                defaultModifierGroupPricingVersionId={
                  defaultModifierGroupPricingVersionId
                }
                override={override}
                menuTypeVersionId={menuTypeVersionId}
                modifierGroupId={modifierGroupId}
                modifierGroupPricingVersions={modifierGroupPricingVersions}
                modifierItems={modifierItems}
                multiselect={multiselect}
                quantityMode={quantityMode}
                setHiddenModifierItemsCount={handleHideModifierItem}
                includedModifierItems={includedModifierItems}
                priceCalcMode={priceCalcMode}
                defaultSelectionPrice={defaultSelectionPrice}
                hidePriceIfFree={hidePriceIfFree}
                mode={mode}
              />
              {isHidden && (
                <DisabledBlockLayer>
                  <Box
                    csx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <SectionLayer
                      title={t(
                        'menuScreen.itemDetails.modifierGroupsSection.modifierGroupHidden',
                      )}
                      icon={<Icon name="MdVisibilityOff" size="40px" />}
                    />
                    <Button
                      variant="primary"
                      onClick={() =>
                        hideModifierGroup(
                          menuTypeVersionId,
                          modifierGroupId,
                          SECTION_ID,
                          true,
                        )
                      }>
                      Activate
                    </Button>
                  </Box>
                </DisabledBlockLayer>
              )}
            </Box>

            <RearrangeSection
              isFirst={isFirst}
              isLast={isLast}
              menuTypeVersionId={menuTypeVersionId}
              modifierGroupId={modifierGroupId}
              nextModifierGroupId={nextModifierGroupId}
              prevModifierGroupId={prevModifierGroupId}
              isPreMadeModifierGroup={isPreMadeModifierGroup}
            />
          </Box>
        )}
      </Card.SubCard>
      {isDisabled && (
        <DisabledBlockLayer csx={{ top: 0, userSelect: 'none' }}>
          <Typography variant="subtitle">{disabledMessage}</Typography>
        </DisabledBlockLayer>
      )}
    </Box>
  );
};

export default ModifierGroup;
