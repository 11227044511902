import { Theme, css } from '@emotion/react';

export const descriptionStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.lightestExtraGrey,
    borderWidth: 1,
    borderColor: 'lightGrey',
    borderRadius: '8px',
    paddingInline: '10px',
    paddingBlock: '10px',
  });
