import { BUTTON_HEIGHT } from '@app/components/common/Button/styles';
import { Theme, css } from '@emotion/react';

export const toggleStyles = css({
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '10px',
  flexGrow: 0,
});

export const selectLocationGroupRowStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.lightGrey}`,
    overflow: 'hidden',
    height: BUTTON_HEIGHT,
  });
