import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  selectCategoriesDropdownList,
  selectSubcategoriesDropdownList,
} from '@app/state/menu/menuSelectors';
import { handleReportsChange } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const ReportCategoriesSubcategoriesFilters = ({ t }: WithTranslation) => {
  const setValues = bindActionCreators(handleReportsChange, useDispatch());
  const categoryIds = useRootSelector(
    state => state.reports.changeData?.data?.categoryIds,
  );
  const subcategoryIds = useRootSelector(
    state => state.reports.changeData?.data?.subcategoryIds,
  );

  const categoriesValueText = useReportsFilterText('categories', categoryIds);
  const subcategoriesValueText = useReportsFilterText(
    'subcategories',
    subcategoryIds,
  );

  const categoryOptions = useRootSelector(selectCategoriesDropdownList);
  const subcategoryDropdownList = useRootSelector(
    selectSubcategoriesDropdownList,
  );
  const subcategoryOptions = useMemo(() => {
    if (!categoryIds?.length) return subcategoryDropdownList;

    const categoryIdSet = new Set(categoryIds);

    return subcategoryDropdownList.filter(subcat =>
      categoryIdSet.has(subcat.categoryId),
    );
  }, [subcategoryDropdownList, categoryIds]);

  const currentCategoriesSelected = useMemo(
    () => categoryOptions.filter(v => categoryIds?.includes(v.value)),
    [categoryIds, categoryOptions],
  );

  const currentSubcategoriesSelected = useMemo(
    () => subcategoryOptions.filter(v => subcategoryIds?.includes(v.value)),
    [subcategoryIds, subcategoryOptions],
  );

  const handleCategoriesChangeDone = useCallback(
    (selectedCategoryOptions: IOption[]) => {
      if (
        !selectedCategoryOptions?.length ||
        selectedCategoryOptions?.some(v => v.value === 0)
      ) {
        setValues(prev => ({
          ...prev,
          categoryIds: null,
        }));
        return;
      }

      const newCategoryIds = selectedCategoryOptions.map(v => v.value);

      const newCategoryIdsSet = new Set(newCategoryIds);

      const newSubcategoryIdsAvailableSet = new Set(
        subcategoryDropdownList
          .filter(subcat => newCategoryIdsSet.has(subcat.categoryId))
          .map(subcat => subcat.value),
      );

      setValues(prev => {
        let newSubcategoryIds = (prev.subcategoryIds?.filter(subcatId =>
          newSubcategoryIdsAvailableSet.has(subcatId),
        ) ?? null) as number[] | null;

        if (!newSubcategoryIds?.length) newSubcategoryIds = null;

        return {
          ...prev,
          categoryIds: newCategoryIds,
          subcategoryIds: newSubcategoryIds,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subcategoryDropdownList],
  );

  const handleSubcategoriesChangeDone = useCallback(
    (selectedSubcategoryOptions: IOption[]) => {
      if (
        !selectedSubcategoryOptions?.length ||
        selectedSubcategoryOptions?.some(v => v.value === 0)
      ) {
        setValues(prev => ({
          ...prev,
          subcategoryIds: null,
        }));
        return;
      }

      const newSubcategoryIds = selectedSubcategoryOptions.map(v => v.value);
      setValues(prev => ({
        ...prev,
        subcategoryIds: newSubcategoryIds,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Grid.Item mb={12} sm={6} md={3}>
        <Typography csx={labelStyles}>
          {t('reports.fields.categoryIds')}
        </Typography>
        <OptionsModal
          title={t('reports.fields.categoryIds')}
          customText={categoriesValueText}
          options={categoryOptions}
          specialOptionsPosition="top"
          currentOptionsSelected={currentCategoriesSelected}
          onChangeDone={handleCategoriesChangeDone}
          clearOptionsLabel={t('commonButtons.selectAll')}
          isSearchable
          returnItemOnChange
          allowMultipleSelection
          buttonProps={{
            csx: {
              justifyContent: 'space-between',
              paddingLeft: 20,
              paddingRight: 20,
            },
          }}
        />
      </Grid.Item>

      <Grid.Item mb={12} sm={6} md={3}>
        <Typography csx={labelStyles}>
          {t('reports.fields.subcategoryIds')}
        </Typography>
        <OptionsModal
          title={t('reports.fields.subcategoryIds')}
          customText={subcategoriesValueText}
          options={subcategoryOptions}
          currentOptionsSelected={currentSubcategoriesSelected}
          onChangeDone={handleSubcategoriesChangeDone}
          clearOptionsLabel={t('commonButtons.selectAll')}
          isSearchable
          returnItemOnChange
          allowMultipleSelection
          buttonProps={{
            csx: {
              justifyContent: 'space-between',
              paddingLeft: 20,
              paddingRight: 20,
            },
            disabled: !subcategoryOptions.length,
          }}
        />
      </Grid.Item>
    </>
  );
};

export default ReportCategoriesSubcategoriesFilters;
