import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectIsMenuMasterMode } from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  FormMode,
  IMassUpdate,
  IMenuTypeVersion,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';

import Card from '@app/components/common/Card';
import SwitchMassUpdate from '@app/components/common/SwitchMassUpdate';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { IItemDetailsSection } from '../types';
import CategoriesSubsection from './CategoriesSubsection';
import PricingSubsection from './PricingSubsection';

const SUB_NAMES = [
  'posName' as const,
  'kdsName' as const,
  'checkName' as const,
  'onlineName' as const,
];

const gridSubNameSizing = {
  mb: 15,
  sm: 5,
  md: 5,
  lg: 3,
  xl: 3,
};

const BasicSection = ({
  t,
  menuTypeVersionId,
  menuTypeVersions,
  mode,
  itemData,
  isCombo,
  itemName,
  sectionId,
  isDisabled,
  itemId,
  categoriesSubsectionRef,
  ...props
}: IItemDetailsSection) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );
  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );
  const onRefresh = useRefreshMenuWeb();

  const isMasterMode = useSelector(selectIsMenuMasterMode);

  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isMassUpdateProp = {
    isMassUpdate,
  };

  const activeSwitch = (
    <SwitchMassUpdate
      checked={itemData ? itemData.active : false}
      onChange={isActive => {
        setValue({ active: isActive }, sectionId, menuTypeVersionId);
        setValue({}, SectionId.MENU_TYPES);
      }}
      showDefaultLabel
      onMassUpdateChange={isActive => {
        setValue({ active: isActive }, sectionId, menuTypeVersionId);
        setValue({}, SectionId.MENU_TYPES);
      }}
      {...isMassUpdateProp}
      isDisabled={isDisabled}
    />
  );

  const nameError = useFormError('name');
  const posNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.posName`,
  );
  const kdsNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.kdsName`,
  );
  const checkNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.checkName`,
  );
  const onlineNameError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.onlineName`,
  );

  const errorMap = {
    posName: posNameError,
    kdsName: kdsNameError,
    checkName: checkNameError,
    onlineName: onlineNameError,
  };

  const hasSectionError =
    Boolean(nameError?.error) ||
    Boolean(posNameError?.error) ||
    Boolean(kdsNameError?.error) ||
    Boolean(checkNameError?.error) ||
    Boolean(onlineNameError?.error);

  const showDeleteDisabled = () => {
    showToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: t('menuScreen.deleteToast', {
        bucket: t(
          `menuScreen.common.bucket.${isCombo ? 'combos' : 'items'}.plural`,
        ),
      }),
    });
  };

  const options = useMemo(() => {
    const _options = [
      {
        text: t('commonButtons.clone'),
        handler: () => {
          setIsCloneModalActive(true);
        },
      },
      {
        text: t('commonButtons.delete'),
        isDisabled: !isMasterMode,
        disabledHandler: showDeleteDisabled,
        handler: () =>
          setShowConfirmationModal({
            active: true,
            title: t(
              `menuScreen.common.bucket.${
                isCombo ? 'combos' : 'items'
              }.singular`,
            ),
            type: 'delete',
            name: itemName,
            onSuccess: () => {
              deleteBucketElementWeb();
            },
          }),
      },
    ];

    return mode !== FormMode.MASS_UPDATE ? _options : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteBucketElementWeb,
    isCombo,
    isMasterMode,
    itemName,
    mode,
    setShowConfirmationModal,
    t,
  ]);

  const handleNameChange = (name: string) => {
    const menuTypeVersionsCloned = cloneDeep(menuTypeVersions || {});
    const previousName = itemName;
    Object.keys(menuTypeVersionsCloned).forEach(key => {
      SUB_NAMES.forEach(subName => {
        const value =
          menuTypeVersionsCloned[Number(key)]?.[
            subName as keyof IMenuTypeVersion
          ];
        if (!value || value === previousName) {
          (menuTypeVersionsCloned[Number(key)][
            subName as keyof IMenuTypeVersion
          ] as string) = name;

          if (errorMap[subName]?.error) {
            errorMap[subName]?.clear();
          }
        }
      });
    });
    setValue({ name, menuTypeVersions: menuTypeVersionsCloned }, sectionId);
    setValue({}, SectionId.HIDDEN);
    setValue({}, SectionId.REGISTER);
  };

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket={isCombo ? 'combos' : 'items'}
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(() => {
            const pathURL = isCombo
              ? `/menu/combos/combos/${newId}`
              : `/menu/items/${newId}`;
            const _navigate = () => {
              return navigate(pathURL);
            };
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.REPLACE,
              text: isCombo
                ? currentMenu.combos[newId].name
                : currentMenu.items[newId].name,
              onPress: _navigate,
              pathURL,
            });
            _navigate();
          }, onRefresh);
        }}
        name={itemName || ''}
      />
      <AccordionSection
        id={sectionId}
        title={t('loggedIn.commonTitles.basic')}
        required={!isMassUpdate}
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        disabledLayer={isDisabled && <NoMenuTypeLayer />}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={mode === FormMode.CREATE}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
            description: t(
              'menuScreen.itemDetails.basic.optionsDisabled.creation',
            ),
          })
        }
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}>
          {activeSwitch}

          {/* Names Subsection */}
          {!isMassUpdate && (
            <Card.SubCard title={t('menuScreen.itemDetails.basic.namesTitle')}>
              <Grid
                rowGap={20}
                columnGap={20}
                columns={15}
                csx={{ alignItems: 'flex-start' }}>
                <Grid.Item
                  {...gridSubNameSizing}
                  csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    info={t('menuScreen.itemDetails.basic.nameInfo')}
                    label={t('loggedIn.commonFields.basic.name')}
                    placeholder={t('loggedIn.commonFields.basic.name')}
                    value={itemName || ''}
                    disabled={!isMasterMode && mode === FormMode.UPDATE}
                    onChange={e => handleNameChange(e.currentTarget.value)}
                    caption={nameError?.error}
                    error={Boolean(nameError?.error)}
                    onFocus={() => nameError && nameError.clear()}
                  />
                </Grid.Item>
                <Grid.Item
                  {...gridSubNameSizing}
                  csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    info={t('menuScreen.itemDetails.basic.posNameInfo')}
                    label={t('loggedIn.commonFields.basic.posName')}
                    placeholder={t('loggedIn.commonFields.basic.posName')}
                    value={
                      itemData ? (itemData as IMenuTypeVersion)?.posName : ''
                    }
                    onChange={({ currentTarget: { value: posName } }) =>
                      setValue(
                        {
                          posName,
                        },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    error={Boolean(posNameError?.error)}
                    caption={posNameError?.error}
                    onFocus={() => posNameError && posNameError.clear()}
                  />
                </Grid.Item>
                <Grid.Item
                  {...gridSubNameSizing}
                  csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    info={t('menuScreen.itemDetails.basic.kdsNameInfo')}
                    label={t('loggedIn.commonFields.basic.kdsName')}
                    placeholder={t('loggedIn.commonFields.basic.kdsName')}
                    value={
                      itemData ? (itemData as IMenuTypeVersion)?.kdsName : ''
                    }
                    onChange={kdsName =>
                      setValue(
                        {
                          kdsName: kdsName.currentTarget.value,
                        },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    error={Boolean(kdsNameError?.error)}
                    caption={kdsNameError?.error}
                    onFocus={() => kdsNameError && kdsNameError.clear()}
                  />
                </Grid.Item>
                <Grid.Item
                  {...gridSubNameSizing}
                  csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    info={t('menuScreen.itemDetails.basic.checkNameInfo')}
                    label={t('loggedIn.commonFields.basic.checkName')}
                    placeholder={t('loggedIn.commonFields.basic.checkName')}
                    value={
                      itemData ? (itemData as IMenuTypeVersion)?.checkName : ''
                    }
                    onChange={checkName =>
                      setValue(
                        {
                          checkName: checkName.currentTarget.value,
                        },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    error={Boolean(checkNameError?.error)}
                    caption={checkNameError?.error}
                    onFocus={() => checkNameError && checkNameError.clear()}
                  />
                </Grid.Item>
                <Grid.Item
                  {...gridSubNameSizing}
                  csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    info={t('menuScreen.itemDetails.basic.onlineNameInfo')}
                    label={t('loggedIn.commonFields.basic.onlineName')}
                    placeholder={t('loggedIn.commonFields.basic.onlineName')}
                    value={
                      itemData ? (itemData as IMenuTypeVersion)?.onlineName : ''
                    }
                    onChange={onlineName =>
                      setValue(
                        {
                          onlineName: onlineName.currentTarget.value,
                        },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    error={Boolean(onlineNameError?.error)}
                    caption={onlineNameError?.error}
                    onFocus={() => onlineNameError && onlineNameError.clear()}
                  />
                </Grid.Item>
              </Grid>
            </Card.SubCard>
          )}

          {/* Pricing Subsection */}
          <PricingSubsection
            isCombo={isCombo}
            itemData={itemData}
            menuTypeVersionId={menuTypeVersionId}
            itemId={itemId || 0}
            isMassUpdate={isMassUpdate}
          />

          {/* Categories / Subcategories Subsection */}
          <CategoriesSubsection
            ref={categoriesSubsectionRef}
            mode={mode}
            categoriesSubcategories={
              (itemData as IMenuTypeVersion)?.categoriesSubcategories ?? {}
            }
            menuTypeVersionId={menuTypeVersionId}
          />
          {isMassUpdate && (
            <Box csx={{ marginTop: 20 }}>
              <CategoriesSubsection
                mode={mode}
                categoriesSubcategories={
                  (itemData as IMassUpdate)?.removeCategoriesSubcategories ?? {}
                }
                menuTypeVersionId={menuTypeVersionId}
                field="removeCategoriesSubcategories"
              />
            </Box>
          )}
        </Box>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
