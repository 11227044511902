import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { EAccordionSectionOptionType } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import NoElementsFound from '@app/components/common/NoElementsFound';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { selectItemGroups } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  COMBO_ITEM_GROUP_INITIAL_STATE,
  FormMode,
  IComboMenuTypeVersion,
  IItemGroupOfCombo,
} from '@westondev/tableturn-core';
import { cloneDeep, orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import NestedItemsGroupCard from './NestedItemsGroupCard';
import { actionCreatorsMenuWeb } from '@app/state';
import { IItemDetailsSection } from '../../../Items/ItemDetails/types';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const ItemGroupsSection = ({
  t,
  isCombo,
  mode,
  isDisabled,
  menuTypeVersionId,
  sectionId,
  itemData: itemDataProps,
  ...props
}: IItemDetailsSection) => {
  const {
    rearrangeItemGroups,
    rearrangeItemGroupsToLastOrFirst,
    updateCombo: setValue,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const navigate = useNavigateWithOrg();

  const theme = useTheme();

  const itemData = itemDataProps as unknown as IComboMenuTypeVersion;
  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const itemGroups = useMemo(
    () => itemData?.itemGroups ?? {},
    [itemData?.itemGroups],
  );

  const itemGroupsArray = useMemo(
    () =>
      orderBy(
        Object.entries(itemGroups || {}),
        itemGroup => itemGroup[1].sortPriority,
      ),
    [itemGroups],
  );

  const itemGroupIds = useMemo(
    () => itemGroupsArray.map(([itemGroupId]) => `${itemGroupId}`),
    [itemGroupsArray],
  );

  const itemGroupNames = useMemo(
    () => Object.values(itemGroups || {}).map(itemGroup => itemGroup.name),
    [itemGroups],
  );

  const handleRearrangePress = (
    order: number,
    itemGroupId: number | string,
    prevItemGroupId: number,
    nextItemGroupId: number,
  ) => {
    try {
      rearrangeItemGroups(
        itemGroupId,
        sectionId,
        order,
        prevItemGroupId,
        nextItemGroupId,
        menuTypeVersionId,
      );
    } catch (error) {
      console.error('Error on sorting Item Groups:', error);
    }
  };

  const handleToTopRearrangePress = (
    toTop: boolean,
    itemGroupId: number | string,
  ) => {
    try {
      rearrangeItemGroupsToLastOrFirst(
        itemGroupId,
        sectionId,
        toTop ? 'first' : 'last',
        menuTypeVersionId,
      );
    } catch (error) {
      console.error('Error on sorting Item Groups:', error);
    }
  };

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const itemGroupsCloned = cloneDeep(itemGroups);

    buttons.forEach((button, index) => {
      const bucketItemGroup = selectItemGroups(store.getState())[
        button.id as number
      ];
      itemGroupsCloned[button.id] = {
        ...COMBO_ITEM_GROUP_INITIAL_STATE,
        sortPriority: itemGroupIds.length + index + 1,
        itemGroupId: button.id,
        items: bucketItemGroup.items,
        posName: bucketItemGroup.posName,
        name: bucketItemGroup.name,
        active: bucketItemGroup.active,
      } as IItemGroupOfCombo;
    });
    setValue({ itemGroups: itemGroupsCloned }, sectionId, menuTypeVersionId);
    closeModal();
  };

  const closeModal = () => setOpenSelectionModal(false);

  return (
    <>
      <SelectionModal
        type="itemGroups"
        detailsScreenProps={{
          currentRelationsIds: itemGroupIds,
          wantedEntity: 'itemGroups',
        }}
        onAssociate={handleOnAssociate}
        onModalClose={closeModal}
        active={openSelectionModal}
        groupByMenuType
      />

      <AccordionSection
        disabledLayer={isDisabled && <NoMenuTypeLayer />}
        title={t('menuScreen.comboDetails.itemGroups.title')}
        expandedHeight="75px"
        optionType={EAccordionSectionOptionType.ACTION_BUTTON}
        description={
          isCombo ? (
            <Box
              csx={{
                display: 'flex',
              }}>
              <p>
                {t('menuScreen.comboDetails.itemGroups.description')}
                {' ('}
                <span
                  style={{
                    borderRadius: 100,
                    minWidth: '8px',
                    maxWidth: '8px',
                    minHeight: '8px',
                    maxHeight: '8px',
                    display: 'inline-block',
                    backgroundColor: theme.colors.persistentSemanticBlue,
                  }}></span>
                &#41;.
              </p>
            </Box>
          ) : (
            t(
              `menuScreen.itemDetails.inventorySection.${
                mode !== FormMode.MASS_UPDATE
                  ? 'description'
                  : 'shortDescription'
              }`,
            )
          )
        }
        options={[
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewItemGroup'),
            handler: () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.ADD,
                text: t(
                  'menuScreen.menuLocation.actionButtons.addNewItemGroupTag',
                ),
                onPress: () => navigate('/menu/combos/item-groups/add'),
                pathURL: '/menu/combos/item-groups/add',
              }),
            icon: true,
          },
          {
            text: t(
              'menuScreen.menuLocation.actionButtons.addExistingItemGroup',
            ),
            handler: () => setOpenSelectionModal(true),
            icon: false,
          },
        ]}
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
          }}>
          {itemGroupsArray.length > 0 ? (
            itemGroupsArray.map(([, itemGroup], index) => {
              const hasNameError =
                itemGroupNames.filter(
                  itemGroupName => itemGroupName === itemGroup.name,
                ).length > 1;

              const prevItemGroupId =
                index > 0 ? itemGroupsArray[index - 1][0] : -1;

              const nextItemGroupId =
                index < itemGroupsArray.length - 1
                  ? itemGroupsArray[index + 1][0]
                  : -1;

              return (
                <NestedItemsGroupCard
                  {...itemGroup}
                  hasNameError={hasNameError}
                  key={`item_group_${itemGroup.itemGroupId}`}
                  itemGroup={itemGroup}
                  menuTypeVersionId={menuTypeVersionId}
                  isFirst={
                    itemGroup.sortPriority ===
                    itemGroupsArray[0][1].sortPriority
                  }
                  isLast={
                    itemGroup.sortPriority ===
                    itemGroupsArray[itemGroupsArray.length - 1][1].sortPriority
                  }
                  itemGroupId={itemGroup.itemGroupId}
                  onArrowUpPress={() => {
                    handleRearrangePress(
                      -1,
                      itemGroup.itemGroupId,
                      Number(prevItemGroupId),
                      Number(nextItemGroupId),
                    );
                  }}
                  onArrowDownPress={() => {
                    handleRearrangePress(
                      1,
                      itemGroup.itemGroupId,
                      Number(prevItemGroupId),
                      Number(nextItemGroupId),
                    );
                  }}
                  onToTopPress={() => {
                    handleToTopRearrangePress(true, itemGroup.itemGroupId);
                  }}
                  onToBottomPress={() => {
                    handleToTopRearrangePress(false, itemGroup.itemGroupId);
                  }}
                />
              );
            })
          ) : (
            <NoElementsFound
              text={t(
                'menuScreen.comboDetails.itemGroups.noItemGroupsAssignedToThisCombo',
              )}
              csx={{
                padding: '30px',
              }}
              showBorder
            />
          )}
        </Box>
      </AccordionSection>
    </>
  );
};

export default ItemGroupsSection;
