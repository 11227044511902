import {
  selectCurrentSettingsData,
  selectCurrentSettingsOriginalData,
} from '@app/state/selectors/settingsSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DateTime,
  IOnlineOrderingSettingsDiffObject,
  IOnlineOrderingStore,
  ITimeBasedMenuTypeDiff,
  TOnlineAvailabilityTime,
  TOnlineMenuTimesDiff,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ACTIVE_TAB_DAY_OF_WEEK,
  TABS_DATA,
} from '../../RegisterModeDiffModal/types';
import RenderChange from '../../common/RenderChange';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import { dateTimeMsToTodayDate, getHourMs } from '@app/helpers/time/time';
import Typography from '@app/components/common/Typography';
import Box from '@app/components/common/Box';
import { formatValue } from '@app/helpers/modals/diffModal';
import HeaderTable from '../../../common/HeaderTable';
import Tabs from '@app/components/common/Tabs';
import Table from '@app/components/common/Table';
import { useTheme } from '@emotion/react';

interface IAvailabilityTableDiff extends WithTranslation {
  menuTypeRules: TOnlineMenuTimesDiff;
  formattedDifferences: IOnlineOrderingSettingsDiffObject | null;
  sectionField: 'pickupAvailability' | 'deliveryAvailability';
}
const AvailabilityTableDiff = ({
  menuTypeRules,
  formattedDifferences,
  sectionField,
  t,
}: IAvailabilityTableDiff) => {
  // Redux
  const changeData = useSelector(
    selectCurrentSettingsData,
  ) as IOnlineOrderingStore;

  const originalData = useSelector(
    selectCurrentSettingsOriginalData,
  ) as IOnlineOrderingStore;

  // Local state
  const theme = useTheme();

  const [activeTab, setSelectedTab] = useState<{
    tabId: number;
    subTabId: number | null;
  }>({ tabId: 1, subTabId: null });

  useEffect(() => {
    const defaultMenuTypeTimes = Object.entries(
      (sectionField === 'pickupAvailability'
        ? formattedDifferences?.pickup?.pickupAvailability
        : formattedDifferences?.delivery?.deliveryAvailability) || {},
    );
    let index = 0;
    for (const menuTypeTime of defaultMenuTypeTimes) {
      index++;
      if (menuTypeTime[1].length > 0) {
        setSelectedTab({ tabId: index, subTabId: null });
        break;
      }
    }
  }, [formattedDifferences, sectionField]);

  const columnHelperCustom = createColumnHelper<TOnlineAvailabilityTime>();

  const dayOfWeek = ACTIVE_TAB_DAY_OF_WEEK[
    activeTab.tabId
  ] as keyof typeof menuTypeRules;
  const tableDataOfDay = changeData[sectionField][dayOfWeek];

  const COLUMNS = [
    columnHelperCustom.accessor('id', {
      id: 'time',
      header: t(
        'app.modals.registerModeDiffModal.general.timeBasedMenuType.time',
      ),
      minSize: 670,
      cell: info => {
        const menuTypeRule = info.row.original;
        const index = info.row.index;

        const changedMenuTypeRule = menuTypeRules[dayOfWeek].find(
          _changedMenuTypeRule => _changedMenuTypeRule.index === index,
        ) as ITimeBasedMenuTypeDiff;

        const originalMenuTypeData =
          originalData[sectionField]?.[dayOfWeek][index];
        const changedMenuTypeData =
          changeData[sectionField]?.[dayOfWeek][index];

        const hasChanged =
          originalMenuTypeData &&
          changedMenuTypeData &&
          ((Boolean(changedMenuTypeRule) &&
            originalMenuTypeData.id === changedMenuTypeData.id) ||
            (!originalMenuTypeData && changedMenuTypeRule.action === 'add') ||
            typeof changedMenuTypeData.id === 'string');

        return (
          <RenderChange
            height="85px"
            change={{
              field: 'time',
              label: '',
              value: (
                <CustomSlider
                  step={900000}
                  value={
                    !hasChanged
                      ? [
                          getHourMs(menuTypeRule.startTime),
                          getHourMs(menuTypeRule.endTime),
                        ]
                      : changedMenuTypeRule.action === 'remove'
                      ? [
                          getHourMs(originalMenuTypeData.startTime),
                          getHourMs(originalMenuTypeData.endTime),
                        ]
                      : [
                          getHourMs(changedMenuTypeData.startTime),
                          getHourMs(changedMenuTypeData.endTime),
                        ]
                  }
                  min={0}
                  max={86399000}
                  showStepMarks={false}
                  valueLabelDisplay="always"
                  labelDirection="bottom"
                  customValueLabel={currentVale => {
                    return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                      .toUTC()
                      .toFormat('hh:mm a');
                  }}
                  isDisabled
                  debounceTimeout={200}
                  renderLimits={value => (
                    <Box csx={{ width: '70px' }}>
                      <Typography fontWeight="medium" variant="caption">
                        {DateTime.fromISO(dateTimeMsToTodayDate(value))
                          .toUTC()
                          .toFormat('hh:mm a')}
                      </Typography>
                    </Box>
                  )}
                />
              ),
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={
              hasChanged
                ? changedMenuTypeRule.changes.some(change =>
                    ['startTime', 'endTime'].includes(change.field),
                  )
                : false
            }
          />
        );
      },
    }),
  ];

  const disabledTabs = Object.values(menuTypeRules).reduce(
    (acc: number[], menuTypeRule, index) => {
      if (menuTypeRule.length === 0) {
        return [...acc, index + 1];
      } else return acc;
    },
    [],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  return (
    <>
      <HeaderTable
        title={
          sectionField === 'pickupAvailability'
            ? t('settingsModule.onlineSettings.pickup.title')
            : t('settingsModule.onlineSettings.delivery.title')
        }
      />
      <Tabs
        data={TABS_DATA}
        activeTab={activeTab}
        onSelectedTabChange={handleOnSelectedTabChange}
        csx={{
          boxShadow: 'none',
          border: `1px solid ${theme.colors.lighterGrey}`,
        }}
        disabledTabs={disabledTabs}
      />
      <Table
        columns={COLUMNS}
        data={tableDataOfDay}
        cellCsx={{ height: '100px' }}
        showShadow={false}
      />
    </>
  );
};

export default AvailabilityTableDiff;
