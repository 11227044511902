import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsSettings,
  IRegisterSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { socialMediaBoxStyles } from './styles';

interface ISocialMediaBox extends WithTranslation {
  socialMedia: string;
  switchField: 'isFacebookActive' | 'isInstagramActive' | 'isXActive';
  accountField: 'facebookAccount' | 'instagramAccount' | 'xAccount';
  switchValue: boolean;
  accountValue: string;
}

const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY;

const SocialMediaBox = ({
  socialMedia,
  switchField,
  accountField,
  switchValue,
  accountValue,
  t,
}: ISocialMediaBox) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  return (
    <Card.SubCard csx={{ padding: 0 }}>
      <Box csx={socialMediaBoxStyles}>
        <Typography>{socialMedia}</Typography>
        <Switch
          checked={switchValue}
          onChange={isActive =>
            setValue<IRegisterSettings>({ [switchField]: isActive }, SECTION_ID)
          }
        />
      </Box>
      <Box csx={{ padding: 20, width: '90%' }}>
        <Input
          label={t(
            'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.addYourUserName',
            { socialMedia },
          )}
          placeholder={t(
            'settingsModule.registerModeSettings.customerFacingDisplay.adsSettings.addYourUserName',
            { socialMedia },
          )}
          value={accountValue}
          onChange={newAccountText =>
            setValue<IRegisterSettings>(
              { [accountField]: newAccountText.target.value },
              SECTION_ID,
            )
          }
        />
      </Box>
    </Card.SubCard>
  );
};

export default SocialMediaBox;
