export const DEFAULT_ELEMENT_SIZES = {
  table: {
    width: 50,
    height: 50,
    maxSize: 200,
    growXSize: 50,
    growYSize: 50,
  },
  'table-rectangle': {
    width: 100,
    height: 50,
    maxSize: 400,
    growXSize: 100,
    growYSize: 50,
  },
  'table-circle': {
    width: 50,
    height: 50,
    maxSize: 200,
    growXSize: 50,
    growYSize: 50,
  },
  object: {
    width: 50,
    height: 50,
    maxSize: 90,
    growXSize: 20,
    growYSize: 20,
  },
  custom: {
    width: 100,
    height: 50,
    maxSize: -1,
    growXSize: 50,
    growYSize: 50,
  },
  wall: {
    width: 300,
    height: 20,
    maxSize: 800,
    growXSize: 100,
    growYSize: 0,
  },
};
