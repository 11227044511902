import { TCsx, useTheme } from '@emotion/react';
import { Cell, ColumnDef, flexRender } from '@tanstack/react-table';
import Box from '../../../Box';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { isValidElement, memo } from 'react';
import Typography from '../../../Typography';
import { tableCardStyles } from './styles';
import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { getCellSizes } from '@app/helpers/components/tableHelpers';
import { BOX_ALIGN, IRenderItem } from '../../types';
import { FlexAlignType } from '../../TableRow/types';

interface ITableCard<T> {
  item: IRenderItem<T>;
  noPadding?: boolean;
  isStriped?: boolean;
  itemHeight?: number;
  cellCsx?: TCsx;
  bodyCellCsx?: TCsx;
  align?: {
    [key: string]: 'left' | 'center' | 'right';
  };
  renderEmptyValues?: boolean;
  columns: ColumnDef<T, any>[];
  showRemoved?: boolean;
}

// eslint-disable-next-line react-refresh/only-export-components
const TableCard = <T,>({
  noPadding,
  cellCsx,
  isStriped,
  bodyCellCsx,
  itemHeight,
  align = {},
  showRemoved,
  ...props
}: ITableCard<T>) => {
  const theme = useTheme();
  const { row, index: rowIndex, headers } = props.item;

  const renderTextValue = (cell: Cell<T, unknown>) => {
    return !isNil(cell.getContext().getValue()) &&
      cell.getContext().getValue() !== '' ? (
      <Typography>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Typography>
    ) : (
      <Typography align="left" color="black">
        - - -
      </Typography>
    );
  };

  return (
    <Box
      csx={[
        tableCardStyles,
        cellCsx,
        {
          backgroundColor: showRemoved
            ? theme.colors.lightRed
            : !isStriped
            ? undefined
            : rowIndex % 2 === 0
            ? theme.colors.lightestExtraGrey
            : theme.colors.white,
          borderBottom: !isStriped
            ? `1px solid ${theme.colors.lightGrey}`
            : 'none',
          paddingInline: noPadding ? '0px' : '20px',
          width: '100%',
          gap: '10px',
          height: 'auto',
          [MQ_MIN_MEDIUM]: {
            height: itemHeight,
            paddingInline: noPadding ? '0px' : '25px',
          },
        },
      ]}>
      {row.getVisibleCells().map((cell: Cell<T, unknown>, index: number) => (
        <Box
          key={cell.id}
          id={cell.id}
          csx={[
            {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              [MQ_MIN_MEDIUM]: {
                alignItems: BOX_ALIGN[align[cell.column.id]] as FlexAlignType,
                ...getCellSizes(cell.column, false),
              },
            },
            bodyCellCsx,
          ]}>
          <Box className="cardCellHeader">
            {headers && headers.length > 0 && (
              <Typography fontWeight="medium">
                {flexRender(
                  headers[index].column.columnDef.header,
                  headers[index].getContext(),
                )}
              </Typography>
            )}
          </Box>
          <Box className="cardCellContent">
            {typeof cell?.column?.columnDef?.cell === 'function'
              ? isValidElement(cell.column.columnDef.cell(cell.getContext()))
                ? flexRender(cell.column.columnDef.cell, cell.getContext())
                : renderTextValue(cell)
              : renderTextValue(cell)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const memoizedTableCard = memo(TableCard, (prevProps, nextProps) => {
  return (
    prevProps.columns === nextProps.columns &&
    isEqual(prevProps.item.row.original, nextProps.item.row.original) &&
    isEqual(prevProps.cellCsx, nextProps.cellCsx) &&
    isEqual(prevProps.bodyCellCsx, nextProps.bodyCellCsx) &&
    isEqual(prevProps.align, nextProps.align)
  );
});

export default memoizedTableCard as typeof TableCard;
