import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import StatusIndicator from '@app/components/common/StatusIndicator';
import Table from '@app/components/common/Table';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import { currentLicenseSettingsSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import { CoreRootState, ILicense, ITablet } from '@westondev/tableturn-core';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditTabletModal from '../EditTabletModal';

interface ITabletsTable {
  androidId: string;
  status: string;
  appVersion: string;
  macAddress: string;
  license: ILicense;
  name: string;
  id: number;
  actions: string;
}

interface ITabletsTableProps {
  data: (ITablet & { actions: string })[];
  emptyBySearch: boolean;
}

const TabletsTable = ({ data, emptyBySearch }: ITabletsTableProps) => {
  //Redux
  const { deactivateLicenseWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );
  const licensesSettings = useSelector(currentLicenseSettingsSettingsSelector);

  const onRefreshSettings = useRefreshSettingsWeb();

  // Local state
  const { t } = useTranslation();

  const [editTabletData, setEditTabletData] =
    useState<Partial<ITablet> | null>();

  const deactivateLicense = useCallback(
    (id: number, licenseId: number) => {
      const nickname = licensesSettings[licenseId]?.nickname ?? '';
      deactivateLicenseWeb(
        () => onRefreshSettings(store.getState() as unknown as CoreRootState),
        id,
        licenseId,
        nickname,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensesSettings],
  );

  const columnHelper = createColumnHelper<ITabletsTable>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('settingsModule.devices_licenses.tabletsTable.name'),
        cell: info => info.getValue(),
        minSize: 160,
      }),
      columnHelper.accessor('androidId', {
        header: ' Android ID #',
        cell: info => info.getValue(),
        enableSorting: false,
        minSize: 150,
      }),
      columnHelper.accessor('status', {
        header: t('settingsModule.devices_licenses.tabletsTable.status'),
        cell: info =>
          info.getValue() !== '' ? capitalize(info.getValue()) : '-',
        sortingFn: (rowA, rowB, columnId) => {
          if (
            rowA.getValue(columnId) === 'active' &&
            rowB.getValue(columnId) !== 'active'
          )
            return 1;
          if (
            rowA.getValue(columnId) !== 'active' &&
            rowB.getValue(columnId) === 'active'
          )
            return -1;
          return 0;
        },
        invertSorting: true,
        minSize: 120,
      }),
      columnHelper.accessor('appVersion', {
        header: t('settingsModule.devices_licenses.tabletsTable.appVersion'),
        cell: info => (info.getValue() !== '' ? info.getValue() : '-'),
        minSize: 160,
        invertSorting: true,
      }),
      columnHelper.accessor('license', {
        header: t('settingsModule.devices_licenses.tabletsTable.license'),
        cell: info => (
          <StatusIndicator
            titleColor={
              info.getValue().status !== 'active' ? 'semanticRed' : 'black'
            }
            title={
              info.getValue().status !== 'active'
                ? t('settingsModule.devices_licenses.tabletsTable.notLicensed')
                : licensesSettings[info.getValue()?.licenseSettingsId]
                    ?.nickname ?? ''
            }
            circleColor={
              info.getValue().status !== 'active'
                ? 'semanticRed'
                : 'semanticGreen'
            }
            replaceButtonComponent={null}
          />
        ),
        sortingFn: (rowA, rowB, columnId) => {
          if (
            (rowA.getValue(columnId) as ILicense).status === 'active' &&
            (rowB.getValue(columnId) as ILicense).status !== 'active'
          )
            return -1;
          if (
            (rowA.getValue(columnId) as ILicense).status !== 'active' &&
            (rowB.getValue(columnId) as ILicense).status === 'active'
          )
            return 1;

          const nicknameA =
            licensesSettings[
              (rowA.getValue(columnId) as ILicense).licenseSettingsId
            ]?.nickname ?? '';
          const nicknameB =
            licensesSettings[
              (rowB.getValue(columnId) as ILicense).licenseSettingsId
            ]?.nickname ?? '';
          return nicknameA.localeCompare(nicknameB);
        },
        sortDescFirst: false,
        minSize: 180,
      }),

      columnHelper.accessor('actions', {
        header: t('settingsModule.devices_licenses.licensesTable.actions'),
        cell: info => {
          const name = info.row.original.name;
          const androidId = info.row.original.androidId;
          const status = info.row.original.license.status;
          const id = info.row.original.id;
          const license = info.row.original.license;

          return (
            <Box csx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Button
                variant="danger"
                disabled={status !== 'active'}
                onClick={
                  status !== 'active'
                    ? () => null
                    : () => deactivateLicense(id, info.row.original.license.id)
                }>
                {t('settingsModule.devices_licenses.tabletsTable.deactivate')}
              </Button>
              <Button
                csx={{ width: '50px' }}
                icon={<Icon name="MdEdit" />}
                onClick={() => {
                  setEditTabletData({
                    name,
                    androidId,
                    status,
                    id,
                    license,
                  });
                }}
              />
            </Box>
          );
        },
        size: 160,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensesSettings, deactivateLicense],
  );

  return (
    <>
      <EditTabletModal
        active={!!editTabletData}
        onCancel={() => setEditTabletData(null)}
        onDeactivate={() => {
          deactivateLicense(
            editTabletData?.id as number,
            editTabletData?.license?.id as number,
          );
        }}
        isEdit
        tabletData={editTabletData}
      />
      <Table
        renderEmptyValues
        columns={COLUMN_VALUES}
        data={data}
        cellCsx={{ height: '70px' }}
        manualSorting={false}
        alwaysShowSortIcon
        enableAlphabeticalSorting
        noDataMessage={
          emptyBySearch
            ? t(
                'settingsModule.devices_licenses.tabletsTable.emptyBySearchTitle',
              )
            : t('settingsModule.devices_licenses.tabletsTable.emptyDataTitle')
        }
        alignHeaders={{
          actions: 'center',
          status: 'center',
          appVersion: 'center',
        }}
        align={{
          actions: 'center',
          status: 'center',
          appVersion: 'center',
        }}
      />
    </>
  );
};

export default TabletsTable;
