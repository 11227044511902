import { useTheme } from '@emotion/react';

const useItemChangeColor = () => {
  const theme = useTheme();
  const subtitleColor = theme.colors.semanticBlue;
  const itemChangeColor = theme.colors.textBlack;
  const selectedColor = theme.colors.lighterBlue;
  const modifierItemBoxColor = theme.colors.lightestBlue;
  const modifierItemBoxBorderColor = theme.colors.lightGrey;
  const boxTextAndBorderColor = theme.colors.persistentSemanticBlue;
  const boxColor = theme.colors.lighterBlue;
  const borderContainerColor = theme.colors.semanticBlue;
  const boxRemovedColor = theme.colors.lighterRed;
  const boxRemovedTextColor = theme.colors.darkRed;

  const optionsColor = theme.colors.white;
  return {
    subtitleColor,
    itemChangeColor,
    selectedColor,
    modifierItemBoxBorderColor,
    modifierItemBoxColor,
    boxRemovedColor,
    optionsColor,
    boxRemovedTextColor,
    boxColor,
    borderContainerColor,
    boxTextAndBorderColor,
  };
};

export default useItemChangeColor;
