import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';

import {
  ITaxesSettings,
  SettingsSectionId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';
import TaxesTable from './TaxesTable';
import { useEffect, useMemo } from 'react';
import {
  checkForSettingsChangesAndNavigateWeb as checkForSettingsChangesAndNavigateWebAction,
  loadSettingsBucketChangeData as loadSettingsBucketChangeDataAction,
} from '@app/state/settings/settingsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import useRootSelector from '@app/hooks/useRootSelector';
import Box from '@app/components/common/Box';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedItemData = makeSelectSettingsData<ITaxesSettings>(SECTION_ID);

const TaxesSettings = () => {
  const dispatch = useDispatch();
  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    checkForSettingsChangesAndNavigateWebAction,
    dispatch,
  );
  const loadSettingsBucketChangeData = bindActionCreators(
    loadSettingsBucketChangeDataAction,
    dispatch,
  );

  const isBaseTaxes = !!useMatch(getPathWithOrgData('/settings/taxes'));

  const taxId = useMatch(getPathWithOrgData('/settings/taxes/:id'))?.params.id;
  const isAdd = useMatch(getPathWithOrgData('/settings/taxes/add'));

  const navigate = useNavigateWithOrg();

  const { data } = useSelector(getMemoizedItemData);
  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'taxes',
  );

  const taxName = useMemo(() => {
    if (!data || !taxId) {
      return '';
    }
    if (isAdd) {
      return 'Adding Tax';
    }
    if (data[taxId]) {
      return data[taxId].name || '';
    }
    return '';
  }, [data, isAdd, taxId]);

  useEffect(() => {
    if (!isSet) loadSettingsBucketChangeData({ setting: 'taxes' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  return !isBaseTaxes ? (
    <>
      <BreadCrumbSettings
        title="Taxes"
        currentPage={taxName}
        onClick={() =>
          checkForSettingsChangesAndNavigateWeb(
            () => navigate('/settings/taxes'),
            () => null,
            navigate,
          )
        }
        isSticky
      />
      <Box csx={{ padding: '4px 15px 15px 15px' }}>
        <Outlet />
      </Box>
    </>
  ) : (
    <>
      <TaxesTable />
    </>
  );
};

export default TaxesSettings;
