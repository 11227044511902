import { getSelectionModalMenuTypeFilterValue } from '@app/helpers/menu/modals/itemsSelectionModal';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  selectItems,
  selectMenu,
  selectMenuTypes,
} from '@app/state/menu/menuSelectors';
import { useTheme } from '@emotion/react';
import { IItem, ITEM_INITIAL_STATE } from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import ItemCard from '../../Card/ItemCard';
import { IItemCard } from '../../Card/ItemCard/ItemCard';
import Dropdown from '../../Dropdown';
import Grid from '../../Grid';
import Icon from '../../Icon';
import StatusCardsContainer from '../../StatusCardsContainer';
import { EStatusCardsContainer } from '../../StatusCardsContainer/types';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';

const valueStatus: Record<number, 'all' | EStatusCardsContainer> = {
  0: 'all',
  1: EStatusCardsContainer.ACTIVE,
  2: EStatusCardsContainer.INACTIVE,
};

type ISelectionMenuTypeCard = IItemCard & {
  groupStatus: EStatusCardsContainer;
  isStatusActive: boolean;
  id: number;
};

const ItemsSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  itemMenuTypeTemplate,
  allowAssociateMultiple = true,
  showDescriptionPanel,
  btnSuccessText,
  showSelectedButtons,
  showOnly,
  associateOnSelect,
  itemMultipleMenuTypeTemplate,
  itemMultipleMenuTypeTemplateDescription,
  filterFunction = elements => elements,
  groupByMenuType = false,
  ...others
}: ICustomSelectionModal<unknown>) => {
  const [selectedItem, setSelectedItem] = useState<IItem>(ITEM_INITIAL_STATE);

  const [selectedButton, setSelectedButton] = useState<IItemSelection>();
  const [menuTypeFilter, setMenuTypeFilter] = useState([0]);
  const [activeStatus, setActiveStatus] = useState(1);
  const [menuTypes, setMenuTypes] = useState<ISelectionMenuTypeCard[]>([]);
  const [selectedMenuTypes, setSelectedMenuTypes] = useState<number[]>([]);
  const [block, setBlock] = useState(false);
  const [selectedMenuTypeDescription, setSelectedMenuTypeDescription] =
    useState('');

  const theme = useTheme();

  //redux
  const items = useRootSelector(state => state.menu.masterMenu.items);
  const locationItems = useSelector(selectItems);
  const locationMenuTypes = useSelector(selectMenuTypes);

  const currentMenu = useSelector(selectMenu);

  useEffect(() => {
    if (block) {
      return;
    }
    const firstMenuType = menuTypes
      .filter(menuType =>
        valueStatus[activeStatus] === 'inactive'
          ? !menuType.groupStatus
          : menuType.groupStatus,
      )
      .shift();
    if (firstMenuType?.id) {
      setSelectedMenuTypes([firstMenuType?.id]);
      if (typeof firstMenuType?.title !== 'string') {
        setSelectedMenuTypeDescription('');
      } else {
        setSelectedMenuTypeDescription(firstMenuType?.title || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatus, menuTypes]);

  const handleOnPress = (button: IItemSelection, isSelected: boolean) => {
    setSelectedButton(button);
    const _item = items[Number(button.id)] || ITEM_INITIAL_STATE;
    setSelectedItem(_item);

    const currentItem = currentMenu.items[Number(button.id)];

    const _menuTypes: ISelectionMenuTypeCard[] = Object.keys(
      currentItem ? currentItem.menuTypeVersions : {},
    ).map((menuTypeId): ISelectionMenuTypeCard => {
      const currentMenuType = currentMenu.menuTypes[Number(menuTypeId)];
      return {
        id: Number(menuTypeId),
        title: currentMenuType?.name,
        isStatusActive:
          currentItem.menuTypeVersions[Number(menuTypeId)]?.active,
        groupStatus: currentMenuType?.active
          ? EStatusCardsContainer.ACTIVE
          : EStatusCardsContainer.INACTIVE,
        onClick: () => {
          setSelectedMenuTypes(prevSelectedMenuType => {
            if (!itemMultipleMenuTypeTemplate) {
              return [Number(menuTypeId)];
            }
            const newSelectedMenuTypes = prevSelectedMenuType?.includes(
              Number(menuTypeId),
            )
              ? prevSelectedMenuType.filter(
                  _prevSelectedMenuType =>
                    _prevSelectedMenuType !== Number(menuTypeId),
                )
              : [...prevSelectedMenuType, Number(menuTypeId)];
            return newSelectedMenuTypes;
          });
          setSelectedMenuTypeDescription(currentMenuType?.name);
        },
      };
    });

    // first menu type inside the item
    const firstMenuType =
      _menuTypes.find(menuType => menuType.isStatusActive) ||
      _menuTypes.find(menuType => !menuType.isStatusActive);

    setBlock(Boolean(button.menuTypeId));

    setSelectedMenuTypes(
      button.menuTypeId || (firstMenuType?.id ? [firstMenuType?.id] : []),
    );
    setSelectedMenuTypeDescription(
      button.description ||
        (firstMenuType ? currentMenu.menuTypes[firstMenuType?.id].name : ''),
    );

    setMenuTypes(_menuTypes);
    if (isSelected) {
      const groupStatus = _menuTypes.find(
        element => button.menuTypeId?.includes(element.id),
      )?.groupStatus;
      if (groupStatus && groupStatus === EStatusCardsContainer.ACTIVE) {
        setActiveStatus(1);
      } else {
        setActiveStatus(2);
      }
    } else {
      setActiveStatus(1);
    }
  };

  const getNoElementsMessage = () => {
    switch (activeStatus) {
      case 0: //All
        return t('menuScreen.itemDetails.menuTypes.emptyCardsMessage');
      case 1: //Active
        return t('menuScreen.itemDetails.menuTypes.emptyActiveCardsMessage');
      case 2: //Inactive
        return t('menuScreen.itemDetails.menuTypes.emptyInactiveCardsMessage');
    }
  };

  const handleDropdownChange = (value: number) => {
    setActiveStatus(value);
    setBlock(false);
  };

  const customButtons = useMemo(() => {
    if (!groupByMenuType) return filterFunction(buttons);
    const _buttons: IItemSelection[] = [];
    buttons.forEach(button => {
      const _item = locationItems[Number(button.id)];
      Object.values(_item.menuTypeVersions).forEach(menuType => {
        const description = `(${
          locationMenuTypes[menuType.menuTypeId]?.name || ''
        })`;
        _buttons.push({
          ...button,
          description,
          isHidden: undefined,
          menuTypeId: [menuType.menuTypeId],
          count: 1,
        });
      });
    });

    return filterFunction(_buttons);
  }, [
    buttons,
    groupByMenuType,
    locationItems,
    locationMenuTypes,
    filterFunction,
  ]);

  const menuTypeOptions = useMemo(() => {
    const menuTypeSet = new Set<number>();
    customButtons.forEach(button => {
      button.menuTypeId?.forEach(menuTypeId => menuTypeSet.add(menuTypeId));
    });

    return [
      { label: t('commonTexts.all'), value: 0 },
      ...Array.from(menuTypeSet).map(menuTypeId => ({
        label: locationMenuTypes[menuTypeId]?.name || '',
        value: menuTypeId,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customButtons, t]);

  const filteredButtons = useMemo(
    () =>
      menuTypeFilter[0] === 0
        ? customButtons
        : customButtons.filter(
            customButton =>
              customButton.menuTypeId?.some(menuTypeId =>
                menuTypeFilter.includes(menuTypeId),
              ),
          ),
    [customButtons, menuTypeFilter],
  );

  const onMenuTypeFilterClear = () => setMenuTypeFilter([0]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFilter = (option: unknown, _id = 0) => {
    setMenuTypeFilter(
      getSelectionModalMenuTypeFilterValue(option as number, menuTypeFilter),
    );
  };

  return (
    <GenericSelectionModal
      {...others}
      associateOnSelect={associateOnSelect}
      filterOptions={
        groupByMenuType
          ? [
              {
                id: 1,
                label: t('menuScreen.common.bucket.menuTypes.plural'),
                options: menuTypeOptions,
              },
            ]
          : undefined
      }
      filterValue={
        groupByMenuType ? [{ id: 1, values: menuTypeFilter }] : undefined
      }
      onFilter={onFilter}
      onFilterClear={() => onMenuTypeFilterClear()}
      groupByMenuType={groupByMenuType}
      showOnly={showOnly}
      onPress={handleOnPress}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={filteredButtons}
      currentButtonInfo={
        itemMenuTypeTemplate
          ? {
              id: selectedButton?.id || 0,
              description: itemMultipleMenuTypeTemplate
                ? ''
                : selectedMenuTypeDescription,
              menuTypeId: selectedMenuTypes,
            }
          : undefined
      }
      title={
        showOnly
          ? t('menuScreen.selectionModal.items.selectedSectionTitle')
          : t('menuScreen.selectionModal.items.title')
      }
      selectedSectionTitle={t(
        'menuScreen.selectionModal.items.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.items.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.items.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.items.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      btnSuccessText={btnSuccessText}
      showSelectedButtons={showSelectedButtons}>
      {({ onSelect, isButtonSelected }) => (
        <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Card.SubCard>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography
                  color="persistentSemanticBlue"
                  fontWeight="medium"
                  csx={{ marginBottom: '10px' }}>
                  {selectedItem.name}
                </Typography>
                <Typography color="darkestGrey">
                  ID: {selectedItem?.itemId}
                </Typography>
              </Box>
              {allowAssociateMultiple && (
                <Box csx={{ display: 'flex', flexDirection: 'row' }}>
                  {isButtonSelected ? (
                    <Button
                      icon={<Icon name="FaTrashAlt" />}
                      variant="danger"
                      csx={{ paddingRight: '20px', paddingLeft: '20px' }}
                      onClick={() =>
                        onSelect(
                          (itemMenuTypeTemplate ||
                            itemMultipleMenuTypeTemplate) &&
                            selectedMenuTypes
                            ? {
                                menuTypeId: selectedMenuTypes,
                                description: itemMultipleMenuTypeTemplate
                                  ? ''
                                  : selectedMenuTypeDescription,
                              }
                            : {},
                        )
                      }
                    />
                  ) : (
                    <Button
                      onClick={() =>
                        onSelect(
                          (itemMenuTypeTemplate ||
                            itemMultipleMenuTypeTemplate) &&
                            selectedMenuTypes
                            ? {
                                menuTypeId: selectedMenuTypes,
                                description: itemMultipleMenuTypeTemplate
                                  ? ''
                                  : selectedMenuTypeDescription,
                              }
                            : {},
                        )
                      }
                      csx={{ paddingRight: '20px', paddingLeft: '20px' }}>
                      {t('menuScreen.selectionModal.selectButton')}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Card.SubCard>
          {(itemMenuTypeTemplate || itemMultipleMenuTypeTemplate) && (
            <Card.SubCard
              title={t('loggedIn.menuModule.tabs.menuTypes')}
              description={
                itemMultipleMenuTypeTemplateDescription ||
                t('menuScreen.selectionModal.items.menuTypes.description')
              }
              required>
              <Box>
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingVertical: '5px',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}>
                  <Box
                    csx={{
                      width: '100%',
                      backgroundColor: theme.colors.lightestExtraGrey,
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}>
                    <Grid columnGap={10}>
                      <Grid.Item
                        mb={4}
                        sm={6}
                        md={8}
                        csx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}>
                        <Typography variant="caption" fontWeight="bold">
                          {t('menuScreen.selectionModal.items.menuTypes.label')}
                        </Typography>
                      </Grid.Item>
                      <Grid.Item
                        mb={8}
                        sm={6}
                        md={4}
                        csx={{
                          paddingRight: '20px',
                        }}>
                        <Dropdown
                          data={[
                            [{ label: 'All', value: 0 }],
                            [
                              { label: t('commonTexts.active'), value: 1 },
                              { label: t('commonTexts.inactive'), value: 2 },
                            ],
                          ]}
                          onChange={status => handleDropdownChange(status)}
                          value={activeStatus}
                        />
                      </Grid.Item>
                    </Grid>
                  </Box>
                </Box>
                <StatusCardsContainer
                  data={menuTypes}
                  csx={{
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  }}
                  contentCsx={{
                    gridTemplateColumns:
                      'repeat(auto-fill, minmax(100px, 1fr))',
                  }}
                  selectedStatus={valueStatus[activeStatus]}
                  activeText={t(
                    'menuScreen.selectionModal.items.menuTypes.status.active',
                  )}
                  inactiveText={t(
                    'menuScreen.selectionModal.items.menuTypes.status.inactive',
                  )}
                  noElementsMessage={getNoElementsMessage()}
                  renderItem={menuType => (
                    <ItemCard
                      {...menuType}
                      isActive={undefined}
                      csx={{ height: '50px !important' }}
                      isSelected={
                        !menuType.id
                          ? false
                          : selectedMenuTypes?.includes(menuType.id)
                      }
                    />
                  )}
                />
              </Box>
            </Card.SubCard>
          )}
          <Box csx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Card.SubCard>
              <Box
                csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Typography>
                  {t(
                    'loggedIn.menuModule.itemDetails.itemLocation.locations.sectionTitle',
                  )}
                </Typography>
              </Box>
            </Card.SubCard>
          </Box>
        </Box>
      )}
    </GenericSelectionModal>
  );
};

export default ItemsSelectionModal;
