import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ESpecialApplyTypes,
  INVISIBLE_CHAR,
  IWithMenuTypeVersionId,
  SectionId,
  TDiscountType,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const APPLY_TO_OPTIONS: Record<number, ESpecialApplyTypes> = {
  1: ESpecialApplyTypes.ITEM,
  2: ESpecialApplyTypes.ORDER,
  3: ESpecialApplyTypes.BOGO,
};

const DISCOUNT_TYPE_OPTIONS: Record<number, TDiscountType> = {
  1: TDiscountType.PERCENT,
  2: TDiscountType.FIXED,
  3: TDiscountType.OVERRIDE,
};
const SECTION_ID = SectionId.SPECIAL_SCHEME;
const getMemoizedSpecialData = makeSelectItemData('specials', SECTION_ID);

type ISpecialSchemeSection = WithTranslation &
  IWithMenuTypeVersionId &
  IAccordionSection;

const SpecialsSchemeSection = ({
  t,
  menuTypeVersionId,
  ...accordionSectionProps
}: ISpecialSchemeSection) => {
  const { itemData: specialData } = useSelector(getMemoizedSpecialData);

  const { updateSpecial: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const menuTypeVersion = specialData?.menuTypeVersions?.[menuTypeVersionId];

  const applyTo = menuTypeVersion?.specialApplyTo;

  const discountType = menuTypeVersion?.discountType;

  const discountAmount = menuTypeVersion?.discountAmount;

  const thresholdAmount = menuTypeVersion?.thresholdAmount;

  const buyQuantity = menuTypeVersion?.buyQuantity;

  const getQuantity = menuTypeVersion?.getQuantity;

  const applyToDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(APPLY_TO_OPTIONS).find(
          key => APPLY_TO_OPTIONS[Number(key)] === applyTo,
        ),
      ),
    [applyTo],
  );

  const typeDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(DISCOUNT_TYPE_OPTIONS).find(
          key => DISCOUNT_TYPE_OPTIONS[Number(key)] === discountType,
        ),
      ),
    [discountType],
  );

  const isPercentage = useMemo(
    () => discountType === TDiscountType.PERCENT,
    [discountType],
  );

  const isOverride = useMemo(
    () => discountType === TDiscountType.OVERRIDE,
    [discountType],
  );

  const buyQuantityError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.buyQuantity`,
  );

  const getQuantityError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.getQuantity`,
  );

  const sectionHasError =
    Boolean(buyQuantityError?.error) || Boolean(getQuantityError?.error);

  return (
    <AccordionSection
      title={t('menuScreen.specialDetails.scheme.title')}
      info={t('menuScreen.specialDetails.scheme.toolTip')}
      hasError={sectionHasError}
      {...accordionSectionProps}>
      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={4} md={4} lg={4} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'menuScreen.discountDetails.discountScheme.inputs.applyTo.label',
            )}
            placeholder={t(
              'menuScreen.discountDetails.discountScheme.inputs.applyTo.placeholder',
            )}
            data={[
              [
                {
                  label: t(
                    'menuScreen.specialDetails.scheme.applyTo.options.item',
                  ),
                  value: 1,
                },
                {
                  label: t(
                    'menuScreen.specialDetails.scheme.applyTo.options.order',
                  ),
                  value: 2,
                },
                {
                  label: t(
                    'menuScreen.specialDetails.scheme.applyTo.options.bogo',
                  ),
                  value: 3,
                },
              ],
            ]}
            onChange={newApplyTo => {
              const newValue =
                newApplyTo in APPLY_TO_OPTIONS
                  ? APPLY_TO_OPTIONS[newApplyTo]
                  : undefined;
              setValue(
                {
                  specialApplyTo: newValue,
                  buyQuantity: newValue === ESpecialApplyTypes.BOGO ? 1 : 0,
                  getQuantity: newValue === ESpecialApplyTypes.BOGO ? 1 : 0,
                  discountType:
                    newValue === ESpecialApplyTypes.ORDER &&
                    discountType === TDiscountType.OVERRIDE
                      ? TDiscountType.FIXED
                      : discountType,
                },
                SECTION_ID,
                menuTypeVersionId,
              );
              let associationsValues;

              if (newValue === ESpecialApplyTypes.ITEM) {
                associationsValues = { getItemIds: [] };
              } else if (newValue === ESpecialApplyTypes.ORDER) {
                associationsValues = { getItemIds: [], buyItemIds: [] };
              } else {
                associationsValues = {};
              }
              setValue(
                associationsValues,
                SectionId.ASSOCIATION,
                menuTypeVersionId,
              );
            }}
            value={applyToDropdownValue}
          />
        </Grid.Item>
        {applyTo === ESpecialApplyTypes.BOGO && (
          <>
            <Grid.Item
              mb={6}
              sm={4}
              md={4}
              lg={4}
              csx={displayFlexEndColumnStyles}>
              <MoneyInput
                label={t(
                  'menuScreen.specialDetails.scheme.inputs.buyQuantity.label',
                )}
                info={t(
                  'menuScreen.specialDetails.scheme.inputs.buyQuantity.toolTip',
                )}
                error={Boolean(buyQuantityError?.error)}
                caption={
                  buyQuantityError?.error
                    ? t(
                        'menuScreen.specialDetails.scheme.inputs.buyQuantity.error',
                      )
                    : undefined
                }
                placeholder="0"
                precision={0}
                prefix={INVISIBLE_CHAR}
                value={buyQuantity}
                onValueChange={newBuyQuantity =>
                  setValue(
                    { buyQuantity: newBuyQuantity },
                    SECTION_ID,
                    menuTypeVersionId,
                  )
                }
                onFocus={() => buyQuantityError && buyQuantityError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              mb={6}
              sm={4}
              md={4}
              lg={4}
              csx={displayFlexEndColumnStyles}>
              <MoneyInput
                label={t(
                  'menuScreen.specialDetails.scheme.inputs.getQuantity.label',
                )}
                info={t(
                  'menuScreen.specialDetails.scheme.inputs.getQuantity.toolTip',
                )}
                error={Boolean(getQuantityError?.error)}
                caption={
                  getQuantityError?.error
                    ? t(
                        'menuScreen.specialDetails.scheme.inputs.getQuantity.error',
                      )
                    : undefined
                }
                placeholder="0"
                precision={0}
                prefix={INVISIBLE_CHAR}
                value={getQuantity}
                onValueChange={newGetQuantity =>
                  setValue(
                    {
                      getQuantity: newGetQuantity,
                    },
                    SECTION_ID,
                    menuTypeVersionId,
                  )
                }
                onFocus={() => getQuantityError && getQuantityError.clear()}
              />
            </Grid.Item>
          </>
        )}
        <Grid.Item mb={12} sm={4} lg={4} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'menuScreen.discountDetails.discountScheme.inputs.discountType.label',
            )}
            placeholder={t(
              'menuScreen.discountDetails.discountScheme.inputs.discountType.placeholder',
            )}
            info={t('menuScreen.specialDetails.scheme.discountTypetoolTip')}
            data={[
              [
                {
                  label: t(
                    'menuScreen.discountDetails.discountScheme.inputs.discountType.options.percent',
                  ),
                  value: 1,
                },
                {
                  label: t(
                    'menuScreen.discountDetails.discountScheme.inputs.discountType.options.fixed',
                  ),
                  value: 2,
                },
                ...(applyTo === ESpecialApplyTypes.ORDER
                  ? []
                  : [
                      {
                        label: t(
                          'menuScreen.discountDetails.discountScheme.inputs.discountType.options.override',
                        ),
                        value: 3,
                      },
                    ]),
              ],
            ]}
            onChange={newDiscountType => {
              const newValue =
                newDiscountType in DISCOUNT_TYPE_OPTIONS
                  ? DISCOUNT_TYPE_OPTIONS[newDiscountType]
                  : undefined;

              const newValueIsPercent = newValue === TDiscountType.PERCENT;
              setValue(
                {
                  discountType: newValue,
                  discountAmount:
                    newValueIsPercent && discountAmount > 100
                      ? 100
                      : discountAmount,
                  thresholdAmount: newValueIsPercent ? thresholdAmount : 0,
                },
                SECTION_ID,
                menuTypeVersionId,
              );
            }}
            value={typeDropdownValue}
          />
        </Grid.Item>
        <Grid.Item
          mb={discountType === TDiscountType.PERCENT ? 6 : 12}
          sm={4}
          lg={4}
          csx={displayFlexEndColumnStyles}>
          <MoneyInput
            label={t(
              `menuScreen.discountDetails.discountScheme.inputs.value.${
                isPercentage ? 'percentage' : isOverride ? 'override' : 'amount'
              }.label`,
            )}
            info={
              isPercentage
                ? t(
                    'menuScreen.specialDetails.scheme.discountPercentagetoolTip',
                  )
                : isOverride
                ? t('menuScreen.specialDetails.scheme.discountOverridetoolTip')
                : t('menuScreen.specialDetails.scheme.discountAmounttoolTip')
            }
            prefix={isPercentage ? INVISIBLE_CHAR : '$'}
            placeholder="0"
            suffix={isPercentage ? '%' : INVISIBLE_CHAR}
            precision={isPercentage ? 0 : 2}
            maxValue={isPercentage ? 100 : undefined}
            value={discountAmount}
            onValueChange={newDiscountAmount =>
              setValue(
                { discountAmount: newDiscountAmount },
                SECTION_ID,
                menuTypeVersionId,
              )
            }
          />
        </Grid.Item>

        {discountType === TDiscountType.PERCENT && (
          <Grid.Item mb={6} sm={4} lg={4} csx={displayFlexEndColumnStyles}>
            <MoneyInput
              label={t(
                'menuScreen.specialDetails.scheme.inputs.thresholdAmount.label',
              )}
              prefix="$"
              placeholder="0"
              suffix={INVISIBLE_CHAR}
              precision={2}
              value={thresholdAmount}
              onValueChange={newThresholdAmount =>
                setValue(
                  { thresholdAmount: newThresholdAmount },
                  SECTION_ID,
                  menuTypeVersionId,
                )
              }
            />
          </Grid.Item>
        )}
      </Grid>
    </AccordionSection>
  );
};

export default SpecialsSchemeSection;
