import { selectSubcategories } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { subcategoriesDropdownOptionsFactoryCore } from '@westondev/tableturn-core';

export const subcategoriesDropdownOptionsFactory = (
  subcategoryIds: number[],
  showNoSubcategoryOption = false,
) =>
  subcategoriesDropdownOptionsFactoryCore(
    subcategoryIds,
    selectSubcategories(store.getState()),
    showNoSubcategoryOption,
  );
