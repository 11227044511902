import {
  MQ_MIN_LARGE,
  MQ_MIN_MEDIUM,
  MQ_MIN_SMALL,
  ThemeModes,
} from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const registrationCardStyles = (theme: Theme) =>
  css({
    '.form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.formContent': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingInline: '0px',
        [MQ_MIN_LARGE]: {
          paddingInline: '20px',
        },
      },
    },
    '&.sticky': {
      position: 'fixed',
      top: '0px',
      marginTop: '0px',
      backgroundColor:
        theme.mode === ThemeModes.DARK
          ? theme.colors.background
          : theme.colors.white,
    },
    'button[type="submit"]': {
      width: '100%',
    },

    '.title.success': {
      paddingBlock: '15px',
    },
    '.stepperArea': {
      borderBottom: `1px solid ${theme.colors.lightGrey}`,
      marginBottom: '40px',
      marginTop: '0px',
      [MQ_MIN_LARGE]: {
        paddingInline: '20px',
      },
      '&.sticky': {
        position: 'sticky',
        top: 0,
        backgroundColor:
          theme.mode === ThemeModes.DARK
            ? theme.colors.background
            : theme.colors.white,
      },
    },
    '.title': {
      paddingTop: '20px',
    },
    [MQ_MIN_MEDIUM]: {
      'button[type="submit"]': {
        width: '380px',
      },
      '.title.success': {
        paddingBlock: '40px',
      },
    },
    [MQ_MIN_LARGE]: {
      width: '700px !important',
      '.title': {
        paddingTop: '20px',
      },
    },
  });

export const gridContainerStyles = () =>
  css({
    columnGap: '20px',
    '.form': {
      width: '100%',
      flex: 1,
    },
    [MQ_MIN_SMALL]: {
      columnGap: '30px',
    },
  });
