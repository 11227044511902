import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import OptionsModal from '@app/components/common/OptionsModal';
import Switch from '@app/components/common/Switch';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { currentPrepStationsSelector } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  CoursingModes,
  IRegisterSettings,
  NavigationActionOnSendType,
  RegisterSeatingModes,
  SettingsSectionId,
  TICKET_SEAT_BAR_OPTIONS,
  actionCreatorsSettings,
  prepStationsOptionModalFactory,
  registerModeTicketSeatBarOptionsFactory,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SEATING_MODE_OPTIONS: Record<number, RegisterSeatingModes> = {
  3: RegisterSeatingModes.HYBRID,
};

const NAVIGATION_ACTION_OPTIONS: Record<number, NavigationActionOnSendType> = {
  1: NavigationActionOnSendType.LOGOUT,
  2: NavigationActionOnSendType.VIEW_TABLES,
  3: NavigationActionOnSendType.VIEW_ORDERS,
};

const COURSING_MODE_OPTIONS: CoursingModes[] = [
  CoursingModes.PREDEFINED,
  CoursingModes.ON_THE_FLY,
];

const SECTION_ID = SettingsSectionId.TABLE_SERVICE;
const getMemoizedSettingsData =
  settingsSelectors.makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const TableServiceSettings = ({ t }: WithTranslation) => {
  const { data } = useSelector(getMemoizedSettingsData);
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const prepStations = useSelector(currentPrepStationsSelector);

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const navigate = useNavigateWithOrg();

  const enableSeating = data.seating;
  const enableCoursing = data.coursing;
  const enableSeatMode = false;

  const seatingModeDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(SEATING_MODE_OPTIONS).find(
          key => SEATING_MODE_OPTIONS[Number(key)] === data?.seatingMode,
        ),
      ),
    [data?.seatingMode],
  );

  const navigationActionDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(NAVIGATION_ACTION_OPTIONS).find(
          key =>
            NAVIGATION_ACTION_OPTIONS[Number(key)] ===
            data?.navigationActionOnSendButton,
        ),
      ),
    [data?.navigationActionOnSendButton],
  );

  const coursingModeValue = useMemo(
    () => COURSING_MODE_OPTIONS.indexOf(data?.coursingMode) + 1,
    [data?.coursingMode],
  );

  const prepStationOptions = useMemo(
    () => prepStationsOptionModalFactory(prepStations || {}),
    [prepStations],
  );

  const ticketSeatBarOptions = useMemo(
    () => registerModeTicketSeatBarOptionsFactory(t),
    [t],
  );

  const ticketSeatBarDropdownValue = useMemo(
    () => TICKET_SEAT_BAR_OPTIONS.indexOf(data?.ticketSeatBar) + 1,
    [data?.ticketSeatBar],
  );

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <>
      <Grid>
        <Grid.Item sm={12}>
          <Grid rowGap={15}>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tableService.general.title',
                )}>
                <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={12} lg={6}>
                    <Card.SubCard
                      title={t(
                        'settingsModule.registerModeSettings.tableService.general.seating.title',
                      )}
                      description={t(
                        'settingsModule.registerModeSettings.tableService.general.seating.description',
                      )}>
                      <Box
                        csx={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                        }}>
                        <Switch
                          checked={enableSeating}
                          onChange={seating =>
                            setValue<IRegisterSettings>({ seating }, SECTION_ID)
                          }
                        />
                      </Box>
                      <Grid
                        rowGap={15}
                        csx={{
                          filter: !enableSeating ? 'opacity(.2)' : 'none',
                        }}>
                        {enableSeatMode && (
                          <Grid.Item lg={12} xl={6} mbOffset={0} xlOffset={6}>
                            <Dropdown
                              label={t(
                                'settingsModule.registerModeSettings.tableService.general.seating.seatingMode.label',
                              )}
                              placeholder={t(
                                'settingsModule.registerModeSettings.tableService.general.seating.seatingMode.label',
                              )}
                              info={t(
                                'settingsModule.registerModeSettings.tableService.general.seating.seatingMode.toolTip',
                              )}
                              data={[
                                [
                                  {
                                    value: 3,
                                    label: t(
                                      'settingsModule.registerModeSettings.tableService.general.seating.seatingMode.options.hybrid',
                                    ),
                                  },
                                ],
                              ]}
                              onChange={seatingMode => {
                                const newValue =
                                  seatingMode in SEATING_MODE_OPTIONS
                                    ? SEATING_MODE_OPTIONS[seatingMode]
                                    : undefined;
                                setValue(
                                  {
                                    seatingMode: newValue,
                                  },
                                  SECTION_ID,
                                );
                              }}
                              value={seatingModeDropdownValue}
                            />
                          </Grid.Item>
                        )}
                        <Grid.Item lg={12} xl={6} mbOffset={0} xlOffset={6}>
                          <Dropdown
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.ticketSeatBar.label',
                            )}
                            placeholder={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.ticketSeatBar.label',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.ticketSeatBar.toolTip',
                            )}
                            data={[ticketSeatBarOptions]}
                            onChange={ticketSeatBar => {
                              setValue(
                                {
                                  ticketSeatBar:
                                    TICKET_SEAT_BAR_OPTIONS[ticketSeatBar - 1],
                                },
                                SECTION_ID,
                              );
                            }}
                            value={ticketSeatBarDropdownValue}
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.allowForTableButton',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.allowForTabletoolTip',
                            )}
                            checked={data.allowForTableButton}
                            onChange={allowForTableButton =>
                              setValue<IRegisterSettings>(
                                {
                                  allowForTableButton,
                                },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.enableDragDropForSeatPopover',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.enableDragDropForSeatPopovertooltip',
                            )}
                            checked={data.enableDragDropForSeatPopover}
                            onChange={enableDragDropForSeatPopover =>
                              setValue<IRegisterSettings>(
                                { enableDragDropForSeatPopover },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.attachNoSeatToToGo',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.attachNoSeatToToGotoolTip',
                            )}
                            checked={data ? data.attachNoSeatToToGo : false}
                            onChange={attachNoSeatToToGo =>
                              setValue<IRegisterSettings>(
                                {
                                  attachNoSeatToToGo,
                                },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.showConfirmPopUpWhenSendingItemsInSeatView',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.showConfirmPopUpWhenSendingItemsInSeatViewtoolTip',
                            )}
                            checked={
                              data.showConfirmPopUpWhenSendingItemsInSeatView
                            }
                            onChange={showConfirmPopUpWhenSendingItemsInSeatView =>
                              setValue<IRegisterSettings>(
                                { showConfirmPopUpWhenSendingItemsInSeatView },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.defaultSeatsOnNewOrder',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.defaultSeatsOnNewOrdertoolTip',
                            )}
                            checked={data.defaultSeatsOnNewOrder}
                            onChange={defaultSeatsOnNewOrder =>
                              setValue<IRegisterSettings>(
                                { defaultSeatsOnNewOrder },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.displayOrderDetailsOnNewOrderTsr',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.seating.displayOrderDetailsOnNewOrderTsrtoolTip',
                            )}
                            checked={data.displayOrderDetailsOnNewOrderTsr}
                            onChange={displayOrderDetailsOnNewOrderTsr =>
                              setValue<IRegisterSettings>(
                                { displayOrderDetailsOnNewOrderTsr },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                      </Grid>
                    </Card.SubCard>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={12} lg={6} csx={{ height: '100%' }}>
                    <Card.SubCard
                      csx={{ height: '100%' }}
                      title={t(
                        'settingsModule.registerModeSettings.tableService.general.sendToKitchen.title',
                      )}>
                      <Grid rowGap={15} columnGap={15}>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showPopUpOnNavigateIfItemsOnTicketNotSentToKitchen',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showPopUpOnNavigateIfItemsOnTicketNotSentToKitchentoolTip',
                            )}
                            checked={
                              data.showPopUpOnNavigateIfItemsOnTicketNotSentToKitchen
                            }
                            onChange={showPopUpOnNavigateIfItemsOnTicketNotSentToKitchen =>
                              setValue<IRegisterSettings>(
                                {
                                  showPopUpOnNavigateIfItemsOnTicketNotSentToKitchen,
                                },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showIndicatorWhenItemsOnTicketNotSentToKitchen',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showIndicatorWhenItemsOnTicketNotSentToKitchentoolTip',
                            )}
                            checked={
                              data.showIndicatorWhenItemsOnTicketNotSentToKitchen
                            }
                            onChange={showIndicatorWhenItemsOnTicketNotSentToKitchen =>
                              setValue<IRegisterSettings>(
                                {
                                  showIndicatorWhenItemsOnTicketNotSentToKitchen,
                                },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item mb={12}>
                          <Checkbox
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showSendItemsModal',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.showSendItemsModal',
                            )}
                            checked={data.showSendItemsModal}
                            onChange={showSendItemsModal =>
                              setValue<IRegisterSettings>(
                                { showSendItemsModal },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                        <Grid.Item
                          csx={{
                            maxWidth: '250px',
                          }}>
                          <Dropdown
                            label={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.label',
                            )}
                            placeholder={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.label',
                            )}
                            info={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.toolTip',
                            )}
                            data={[
                              [
                                {
                                  value: 1,
                                  label: t(
                                    'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.options.logout',
                                  ),
                                },
                                {
                                  value: 2,
                                  label: t(
                                    'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.options.viewTables',
                                  ),
                                },
                                {
                                  value: 3,
                                  label: t(
                                    'settingsModule.registerModeSettings.tableService.general.sendToKitchen.navigationActionOnSendButton.options.viewOrders',
                                  ),
                                },
                              ],
                            ]}
                            onChange={navigationAction => {
                              const newValue =
                                navigationAction in NAVIGATION_ACTION_OPTIONS
                                  ? NAVIGATION_ACTION_OPTIONS[navigationAction]
                                  : undefined;
                              setValue(
                                {
                                  navigationActionOnSendButton: newValue,
                                },
                                SECTION_ID,
                              );
                            }}
                            value={navigationActionDropdownValue}
                          />
                        </Grid.Item>
                        <Grid.Item
                          csx={{
                            maxWidth: '250px',
                          }}>
                          <Box>
                            <ToolTip
                              showHover={showHover}
                              content={t(
                                'settingsModule.registerModeSettings.tableService.general.sendToKitchen.prepStationForFireTicketIdTooltip',
                              )}>
                              <div />
                            </ToolTip>
                            <Typography
                              onMouseOver={handlePopUp}
                              onMouseOut={() => {
                                if (newTimeout) clearTimeout(newTimeout);
                                setShowHover(false);
                              }}
                              fontWeight="medium"
                              csx={{ marginBottom: '8px' }}>
                              {t(
                                'settingsModule.registerModeSettings.tableService.general.sendToKitchen.prepStationForFireTicketId',
                              )}
                            </Typography>
                          </Box>
                          <OptionsModal
                            placeholder={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.prepStationForFireTicketId',
                            )}
                            title={t(
                              'settingsModule.registerModeSettings.tableService.general.sendToKitchen.prepStationForFireTicketId',
                            )}
                            noElementMessage={t(
                              'app.modals.prepStation.noPrepStations',
                            )}
                            options={prepStationOptions}
                            value={data?.prepStationForFireTicketId || 0}
                            buttonProps={{
                              csx: {
                                justifyContent: 'space-between',
                                paddingInline: '15px',
                              },
                            }}
                            isSearchable
                            specialOptions={[
                              {
                                label: t(
                                  'app.modals.prepStation.noPrepStation',
                                ),
                                value: 0,
                              },
                            ]}
                            specialOptionsPosition="top"
                            onChange={(
                              prepStationForFireTicketId: number | null,
                            ) =>
                              setValue<IRegisterSettings>(
                                {
                                  prepStationForFireTicketId:
                                    prepStationForFireTicketId === 0
                                      ? null
                                      : prepStationForFireTicketId,
                                },
                                SECTION_ID,
                              )
                            }
                          />
                        </Grid.Item>
                      </Grid>
                    </Card.SubCard>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tableService.coursing.title',
                )}
                description={t(
                  'settingsModule.registerModeSettings.tableService.coursing.description',
                )}>
                <Box
                  csx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                  }}>
                  <Switch
                    checked={enableCoursing}
                    onChange={coursing => {
                      setValue<IRegisterSettings>(
                        {
                          coursing,
                        },
                        SECTION_ID,
                      );
                      if (coursing) {
                        setValue<IRegisterSettings>(
                          {
                            autoTriggering: false,
                          },
                          SECTION_ID,
                        );
                      }
                    }}
                  />
                </Box>
                <Grid rowGap={15} csx={{ width: '100%', position: 'relative' }}>
                  {!enableCoursing && (
                    <Box
                      csx={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        marginTop: '15px',
                      }}>
                      <Typography>
                        {t(
                          'settingsModule.registerModeSettings.tableService.coursing.disabledOverlay',
                        )}
                      </Typography>
                    </Box>
                  )}

                  <Grid.Item
                    mb={12}
                    lg={6}
                    csx={{
                      marginTop: '10px',
                      filter: !enableCoursing ? 'opacity(.2)' : 'none',
                    }}>
                    <Dropdown
                      label={t(
                        'settingsModule.registerModeSettings.tableService.coursing.coursingMode.label',
                      )}
                      placeholder={t(
                        'settingsModule.registerModeSettings.tableService.coursing.coursingMode.label',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.tableService.coursing.coursingMode.toolTip',
                      )}
                      isDisabled={!enableCoursing}
                      data={[
                        [
                          {
                            value: 1,
                            label: t(
                              'settingsModule.registerModeSettings.tableService.coursing.coursingMode.options.predefined',
                            ),
                          },
                          {
                            value: 2,
                            label: t(
                              'settingsModule.registerModeSettings.tableService.coursing.coursingMode.options.onTheFly',
                            ),
                          },
                        ],
                      ]}
                      value={coursingModeValue}
                      onChange={coursingMode => {
                        const coursingModeString =
                          COURSING_MODE_OPTIONS[coursingMode - 1];
                        setValue<IRegisterSettings>(
                          { coursingMode: coursingModeString },
                          SECTION_ID,
                        );
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item md={12} csx={{ position: 'relative' }}>
                    {coursingModeValue === 2 && enableCoursing && (
                      <Box
                        csx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography>
                          {t(
                            'settingsModule.registerModeSettings.tableService.coursing.disabledOnTheFlyOverlay',
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Card.SubCard
                      title={t(
                        'settingsModule.registerModeSettings.tableService.coursing.linkTitle',
                      )}
                      csx={{
                        filter:
                          !enableCoursing || coursingModeValue === 2
                            ? 'opacity(.2)'
                            : 'none',
                        userSelect: 'none',
                      }}>
                      <Grid
                        columnGap={20}
                        rowGap={15}
                        csx={{ alignItems: 'center' }}>
                        <Grid.Item mb={12} sm={6} lg={8}>
                          <Typography>
                            {t(
                              'settingsModule.registerModeSettings.tableService.coursing.linkDescription',
                            )}
                          </Typography>
                        </Grid.Item>
                        <Grid.Item mb={12} sm={6} lg={4}>
                          <Button
                            variant="primary"
                            disabled={
                              !enableCoursing || coursingModeValue === 2
                            }
                            onClick={() =>
                              navigate('/settings/register/coursing')
                            }>
                            {t(
                              'settingsModule.registerModeSettings.tableService.coursing.linkButton',
                            )}
                          </Button>
                        </Grid.Item>
                      </Grid>
                    </Card.SubCard>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tableService.tableLayout.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12}>
                    <Typography
                      variant="caption"
                      csx={{ marginBottom: '15px' }}>
                      {t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={3}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.showSeatsOnTableLayout',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.showSeatsOnTableLayouttoolTip',
                      )}
                      checked={data.showSeatsOnTableLayout}
                      onChange={showSeatsOnTableLayout =>
                        setValue<IRegisterSettings>(
                          { showSeatsOnTableLayout },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={3}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.showTimerOnTableLayout',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.showTimerOnTableLayouttooltip',
                      )}
                      checked={data.showTimerOnTableLayout}
                      onChange={showTimerOnTableLayout =>
                        setValue<IRegisterSettings>(
                          { showTimerOnTableLayout },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={3}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.useCleaningStatusOnTableLayout',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.useCleaningStatusOnTableLayouttoolTip',
                      )}
                      checked={data.useCleaningStatusOnTableLayout}
                      onChange={useCleaningStatusOnTableLayout =>
                        setValue<IRegisterSettings>(
                          { useCleaningStatusOnTableLayout },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={3}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.allowOpenTabsOnTableLayout',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.tableService.tableLayout.allowOpenTabsOnTableLayouttooltip',
                      )}
                      checked={data.allowOpenTabsOnTableLayout}
                      onChange={allowOpenTabsOnTableLayout =>
                        setValue<IRegisterSettings>(
                          { allowOpenTabsOnTableLayout },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default TableServiceSettings;
