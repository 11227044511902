import Box from '@app/components/common/Box';
import {
  isCustomersPath,
  isMenuPath,
  isReportsPath,
} from '@app/helpers/location';
import useBrowserButtons from '@app/hooks/useBrowserButtons';
import useOrgData from '@app/hooks/useOrgData';
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import NavBar from '../NavBar';
import SaveAndCancelBar from '../SaveAndCancelBar';
import { SaveAndCancelBarTypes } from '../SaveAndCancelBar/types';
import SideBar from '../SideBar';
import { layoutContainerStyles } from './styles';
import { LOGGED_IN_LAYOUT_ID } from './types';

const LoggedInLayout = () => {
  // Local state
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const isOrgData = useOrgData();
  const location = useLocation();
  const isMenu = isMenuPath(location.pathname);
  const isReports = isReportsPath(location.pathname);
  const isCustomers = isCustomersPath(location.pathname);
  const isTableLayout = location.pathname.includes('table-layout');

  useBrowserButtons();

  return isOrgData ? (
    <Box csx={layoutContainerStyles} id={LOGGED_IN_LAYOUT_ID}>
      <NavBar onToggleSideBar={setIsSideBarOpen} />
      <Box className="layoutContentContainer">
        <SideBar isOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
        <Box className="contentContainer">
          <Box className="outletContainer">
            <Outlet />
          </Box>
          <Box csx="saveAndCancelBarContainer">
            <SaveAndCancelBar
              type={
                isMenu
                  ? SaveAndCancelBarTypes.MENU
                  : isReports
                  ? SaveAndCancelBarTypes.REPORTS
                  : isTableLayout
                  ? SaveAndCancelBarTypes.TABLE_LAYOUT
                  : isCustomers
                  ? SaveAndCancelBarTypes.CUSTOMERS
                  : SaveAndCancelBarTypes.SETTINGS
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to="/my-organizations" replace />
  );
};

export default LoggedInLayout;
