import { Theme, css } from '@emotion/react';

export const headerTitleStyles = (theme: Theme) =>
  css({
    paddingBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    borderBottom: `1px solid ${theme.colors.lighterGrey}`,
    minHeight: '56px',
    gap: '15px',
  });
