import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import { TModifierGroupDiff } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

interface ModifierGroupsDiff extends WithTranslation {
  modifierGroups: TModifierGroupDiff[];
  formatValue: (value: any, field: string, textValue?: string) => string;
}

const ModifierGroupsDiff = ({
  t,
  modifierGroups,
  formatValue,
}: ModifierGroupsDiff) => {
  const {
    subtitleColor,
    itemChangeColor,
    selectedColor,
    modifierItemBoxBorderColor,
    modifierItemBoxColor,
    boxTextAndBorderColor,
    boxColor,
    borderContainerColor,
    boxRemovedColor,
    boxRemovedTextColor,
    optionsColor,
  } = useItemChangeColor();

  // Iterate over modifier groups extract sortPriority and show it on top
  return modifierGroups.map((modifierGroup, modifierGroupIndex) => {
    const sortPriority = modifierGroup.changes.filter(
      change => change.field === 'sortPriority',
    );
    const changes = modifierGroup.changes.filter(
      change => change.field !== 'sortPriority',
    );

    const wasRemoved = modifierGroup.action === 'remove';
    const wasAdded = modifierGroup.action === 'add';
    return (
      <Box
        key={`modifierGroup_${modifierGroup.label}`}
        csx={{
          backgroundColor: 'white',
          borderRadius: 10,
          marginBottom:
            modifierGroups.length > 0 &&
            modifierGroupIndex !== modifierGroups.length - 1
              ? 15
              : 0,
          width: '100%',
          border: `2px solid ${borderContainerColor}`,
          overflow: 'hidden',
        }}>
        <Box csx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            {/* Header */}
            <Box
              csx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingInline: 20,
                paddingBlock: 16,
              }}>
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box>
                  <Typography
                    color={subtitleColor}
                    fontWeight="medium"
                    csx={{ marginBottom: 2 }}>
                    {modifierGroup.label}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="darkestGrey"
                    csx={{ marginBottom: 13 }}>
                    {t(
                      'menuScreen.itemDetails.modifierGroupsSection.modifierName',
                      { name: modifierGroup.label },
                    )}
                  </Typography>
                </Box>
                {wasAdded && (
                  <Box
                    csx={{
                      minWidth: 80,
                      height: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: boxColor,
                      borderRadius: 10,
                      border: `1px solid ${boxTextAndBorderColor}`,
                      marginLeft: 15,
                      paddingInline: 10,
                    }}>
                    <Typography
                      csx={{
                        alignContent: 'center',
                      }}
                      color={boxTextAndBorderColor}
                      align="center">
                      {t('app.modals.itemsDiff.menuType.newModifierGroup')}
                    </Typography>
                  </Box>
                )}
              </Box>
              {wasRemoved ? (
                <Box
                  csx={{
                    minWidth: 80,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: boxRemovedColor,
                    borderRadius: 10,
                    border: `1px solid ${boxRemovedTextColor}`,
                    marginLeft: 15,
                    paddingInline: 10,
                  }}>
                  <Typography
                    csx={{
                      alignContent: 'center',
                    }}
                    color={boxRemovedTextColor}
                    align="center">
                    {t('app.modals.diffModal.removed')}
                  </Typography>
                </Box>
              ) : (
                sortPriority.length === 1 && (
                  <Box csx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{sortPriority[0].label}:</Typography>
                    <Box
                      csx={{
                        minWidth: 80,
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: boxColor,
                        borderRadius: 10,
                        border: `1px solid ${boxTextAndBorderColor}`,
                        marginLeft: 15,
                        paddingInline: 10,
                      }}>
                      <Typography
                        csx={{
                          alignContent: 'center',
                        }}
                        color={boxTextAndBorderColor}
                        align="center">
                        {sortPriority[0].value as string}
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
            </Box>
            {/* Options */}
            {!wasRemoved && changes.length > 0 && (
              <Box
                csx={theme => ({
                  paddingInline: 20,
                  paddingBlock: 16,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: 63,
                  borderTop: `1px solid ${theme.colors.lightGrey}`,
                  borderBottom: `1px solid ${theme.colors.lightGrey}`,
                  backgroundColor: optionsColor,
                })}>
                {changes.map((change, index) => (
                  <Box
                    csx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 25,
                    }}
                    key={`option_${change.modifierGroupId}_${index}`}>
                    <Typography csx={{ marginRight: 15 }}>
                      {change.label}:
                    </Typography>
                    <Box
                      csx={{
                        minWidth: 100,
                        height: 35,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:
                          change.value === false ? boxRemovedColor : boxColor,
                        borderRadius: 10,
                        border: `1px solid ${
                          change.value === false
                            ? boxRemovedTextColor
                            : boxTextAndBorderColor
                        }`,
                        paddingInline: 10,
                      }}>
                      <Typography
                        color={
                          change.value === false
                            ? boxRemovedTextColor
                            : boxTextAndBorderColor
                        }
                        align="center">
                        {formatValue(
                          change.value as string,
                          change.field,
                          change.textValue,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {!wasRemoved && modifierGroup.itemsChanged.length > 0 && (
              <Box
                csx={{
                  display: 'flex',
                  justifyContent:
                    modifierGroup.itemsChanged.length > 5
                      ? 'center'
                      : 'flex-start',
                  width: '100%',
                  backgroundColor: selectedColor,
                }}>
                <Box
                  csx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: 10,
                    gap: 10,
                  }}>
                  {modifierGroup.itemsChanged.map(item => (
                    <Box
                      key={`item_${item.label}_${modifierGroupIndex}`}
                      csx={{
                        backgroundColor: modifierItemBoxColor,
                        width: 306,
                        border: `1px solid ${modifierItemBoxBorderColor}`,
                        padding: 8,
                        borderRadius: 10,
                      }}>
                      <Box
                        csx={{
                          width: 290,
                          borderRadius: 10,
                          backgroundColor: 'white',
                          height: 66,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${boxTextAndBorderColor}`,
                        }}>
                        <Typography fontWeight="medium" color="black">
                          {item.label}
                        </Typography>
                      </Box>
                      {item.changes.length > 0 && (
                        <Box
                          csx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                          }}>
                          {item.changes.map((change, index) => (
                            <Box
                              key={`itemChange_${item.label}_${index}`}
                              csx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBlock: 10,
                              }}>
                              <Box csx={{ width: 70 }}>
                                <Typography
                                  variant="caption2"
                                  color={itemChangeColor}>
                                  {change.label}:
                                </Typography>
                              </Box>
                              <Box
                                csx={{
                                  width: 70,
                                  height: 35,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor:
                                    change.value === false &&
                                    change.field !== 'isSelected'
                                      ? boxRemovedColor
                                      : boxColor,
                                  borderRadius: 10,
                                  border: `1px solid ${
                                    change.value === false &&
                                    change.field !== 'isSelected'
                                      ? boxRemovedTextColor
                                      : boxTextAndBorderColor
                                  }`,
                                }}>
                                <Typography
                                  color={
                                    change.value === false &&
                                    change.field !== 'isSelected'
                                      ? boxRemovedTextColor
                                      : boxTextAndBorderColor
                                  }
                                  variant="caption2"
                                  align="center">
                                  {formatValue(
                                    change.value as string,
                                    change.field,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  });
};

export default ModifierGroupsDiff;
