import { useMemo } from 'react';
import { WithTranslation, useTranslation } from 'react-i18next';
import Tabs from '..';
import { ITabs } from '../types';
import { ParseKeys } from 'i18next';
import { WEEK_TABS_DATA } from './types';

type IWeekTabs = Omit<ITabs, 'data' | keyof WithTranslation> & {
  error?: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
};

const WeekTabs = ({
  error = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  ...otherProps
}: IWeekTabs) => {
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      WEEK_TABS_DATA.map(tab => ({
        ...tab,
        name: t(`commonTexts.${tab.name}` as ParseKeys),
        buttonProps: error[tab.dayOfWeek]
          ? {
              className: `danger${
                otherProps.activeTab?.tabId === tab.id ? '-active' : ''
              }`,
            }
          : undefined,
      })),
    [error, otherProps.activeTab, t],
  );
  return <Tabs data={data} csx={{ borderRadius: '8px' }} {...otherProps} />;
};

export default WeekTabs;
