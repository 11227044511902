import ActionButtons, {
  DEFAULT_OPTIONS_LIST,
  UserActionBtnTypes,
} from '@app/components/common/ActionButtons';
import { IActionOption } from '@app/components/common/ActionButtons/types';
import Box from '@app/components/common/Box';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { checkExistingUser } from '@app/helpers/settings/users';
import { associateUsersToLocationWeb } from '@app/state/location/location';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { RootState, useAppDispatch } from '@app/state/store';
import {
  ICategoryCard,
  IDiscountCard,
  IItemCard,
  IMenuTypeCard,
  IModifierGroupCard,
  IModifierItemCard,
  IRoleCard,
  ISpecialCard,
  ISubcategoryCard,
  IUserCard,
  actionCreatorsApp,
} from '@westondev/tableturn-core';
import zipWith from 'lodash/zipWith';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TUserCreationData } from './types';
import UserEmailModal from './UserEmailModal';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

export type TBucketCard =
  | IItemCard
  | ICategoryCard
  | ISubcategoryCard
  | IMenuTypeCard
  | IModifierItemCard
  | IModifierGroupCard
  | IDiscountCard
  | IUserCard
  | IRoleCard
  | ISpecialCard;

const AddUserModal = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useAppDispatch();
  //redux
  const selectedMode = useSelector(
    (state: RootState) => state.settings.selectedMode,
  );

  const { showToast, setIsLoadingModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const settingsType = selectedMode.settingsType;

  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const usersInMaster = useSelector(
    (state: RootState) => state.settings.master.users,
  );

  const { users } = selectedMode.currentSettings;

  const [additionModalState, setAdditionModalState] = useState(false);
  const [newUserModalState, setNewUserModalState] = useState(false);

  const defaultHandler = (title: string, userData: TUserCreationData) => {
    navigate('/settings/accounts/users/add', {
      state: {
        userData,
        title,
      },
    });
  };

  const actionDropdownOptions = useMemo(
    () => {
      const optionsArray = !isMasterMode
        ? DEFAULT_OPTIONS_LIST[UserActionBtnTypes.CREATE_NEW_USER_LOCATION]
        : DEFAULT_OPTIONS_LIST[UserActionBtnTypes.CREATE_NEW_USER];

      const handlersArray = isMasterMode
        ? [() => setNewUserModalState(true)]
        : [() => setNewUserModalState(true), () => setAdditionModalState(true)];

      return zipWith(
        optionsArray,
        handlersArray,
        ([text, icon], handler): IActionOption => ({
          text: t(
            `settingsModule.usersSettings.${
              text as 'addNewUser' | 'addExistingUser'
            }`,
          ),
          handler,
          icon,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, settingsType],
  );

  const handleUserAssociation = (buttons: IItemSelection[]) => {
    associateUsersToLocationWeb(buttons);
    setAdditionModalState(false);
  };

  const closeEmailModal = () => {
    setNewUserModalState(false);
  };

  const handleSearchUser = async (
    newUserEmail: string,
    setErrors:
      | React.Dispatch<
          React.SetStateAction<{
            email: string;
          }>
        >
      | undefined,
  ) => {
    setIsLoadingModal({
      active: true,
      message: `${t(
        'settingsModule.usersSettings.loader.text',
      )} ${newUserEmail}`,
    });

    const userData = await checkExistingUser(newUserEmail);

    const isUserInOrg =
      userData && usersInMaster[userData.organizationUserId] !== undefined;

    if (isUserInOrg) {
      const userCompleteInfo = usersInMaster[userData.organizationUserId];
      const isUserInCurrentLocation =
        String(userData.organizationUserId) in users;

      setIsLoadingModal({ active: false });

      if (isUserInCurrentLocation) {
        setNewUserModalState(true);
        showToast({
          type: 'error',
          title: t(
            'settingsModule.usersSettings.emailErrorToast.emailToastErrorTitleLocation',
          ),
          description: t(
            'settingsModule.usersSettings.emailErrorToast.emailToastErrorDescription',
          ),
        });
        setErrors &&
          setErrors({
            email: t(
              'settingsModule.usersSettings.emailErrorToast.emailToastErrorDescription',
            ),
          });
      } else {
        defaultHandler(t('settingsModule.usersSettings.addNewUserTag'), {
          pin: userCompleteInfo.pin,
          posName: userCompleteInfo.posName,
          isUserVerifiedToOrg: Boolean(userCompleteInfo.isUserVerifiedToOrg),
          emailExists: true,
          user: {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
            isUserVerifiedToTableTurn: Boolean(
              userCompleteInfo.user.isUserVerifiedToTableTurn,
            ),
          },
        });
      }
    } else {
      setIsLoadingModal({ active: false });
      defaultHandler(
        t('settingsModule.usersSettings.addNewUserTag'),
        userData
          ? {
              isUserVerifiedToOrg: false,
              emailExists: true,
              user: {
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                phoneNumber: userData.phoneNumber,
                isUserVerifiedToTableTurn: Boolean(
                  userData.isUserVerifiedToTableTurn,
                ),
              },
            }
          : {
              isUserVerifiedToOrg: false,
              emailExists: false,
              user: {
                email: newUserEmail,
                isUserVerifiedToTableTurn: false,
              },
            },
      );
    }
  };

  return (
    <Box>
      <ActionButtons optionList={actionDropdownOptions} />
      <SelectionModal
        type="users"
        btnSuccessText={t(
          'settingsModule.usersSettings.selectionModal.header.options.associate',
        )}
        showDescriptionPanel
        scrollCardSectionNumberOfColumns={2}
        itemMenuTypeTemplate
        active={additionModalState}
        onModalClose={() => setAdditionModalState(false)}
        onAssociate={handleUserAssociation}
      />
      <UserEmailModal
        title={t('settingsModule.usersSettings.addNewUser')}
        subtitle={t('settingsModule.usersSettings.enterEmailAddress')}
        isActive={newUserModalState}
        onClose={closeEmailModal}
        onContinue={handleSearchUser}
      />
    </Box>
  );
};

export default AddUserModal;
