import { DayOfWeek } from '@westondev/tableturn-core';
import { ITab } from '../types';

export const WEEK_TABS_DATA: (ITab & { dayOfWeek: DayOfWeek })[] = [
  { id: 1, name: 'monday', dayOfWeek: DayOfWeek.MONDAY },
  { id: 2, name: 'tuesday', dayOfWeek: DayOfWeek.TUESDAY },
  { id: 3, name: 'wednesday', dayOfWeek: DayOfWeek.WEDNESDAY },
  { id: 4, name: 'thursday', dayOfWeek: DayOfWeek.THURSDAY },
  { id: 5, name: 'friday', dayOfWeek: DayOfWeek.FRIDAY },
  { id: 6, name: 'saturday', dayOfWeek: DayOfWeek.SATURDAY },
  { id: 7, name: 'sunday', dayOfWeek: DayOfWeek.SUNDAY },
];
