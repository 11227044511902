import { TCsx } from '@emotion/react';
import { SortOptions } from '@westondev/tableturn-core';
import { ReactNode } from 'react';
import ActionButtons from '../../ActionButtons';
import { IActionOption } from '../../ActionButtons/types';
import Box from '../../Box';
import Grid from '../../Grid';
import SearchInput from '../../SearchInput';
import SortButton from '../../SortButton';
import Typography from '../../Typography';
import { subCardStyles } from '../SubCard/styles';
import { searchBarStyles } from './styles';

export interface ISubCardOptions {
  children?: React.ReactNode;
  title?: string;
  titleComponent?: ReactNode;
  subtitle?: string;
  onEditMode?: (status: boolean) => void;
  isEditButtonDisabled?: boolean;
  isEditButtonActive?: boolean;
  onEditPressDisabled?: () => void;
  isActionButtonDisabled?: boolean;
  onActionPressDisabled?: () => void;
  onLocationMode?: () => void;
  required?: boolean;
  actionOptions?: IActionOption[];
  searchBarProps?: {
    value: string;
    onSort?: (sortOption: SortOptions | null) => void;
    setInputValue: (value: string) => void;
    onDebounce?: (text: string) => void;
    isSortDisabled?: boolean;
    isSearchBarDisabled?: boolean;
    sortValue?: SortOptions | null;
  };
  showHeader?: boolean;
  csx?: TCsx;
  extraOptions?: ReactNode;
  searchBarCsx?: TCsx;
  extraOptionsCsx?: TCsx;
  noCardsPadding?: boolean;
}

const SubCardOptions = ({
  children,
  title,
  titleComponent,
  onEditMode,
  isEditButtonDisabled = false,
  isEditButtonActive,
  onEditPressDisabled,
  isActionButtonDisabled,
  onActionPressDisabled,
  actionOptions,
  required = false,
  searchBarProps,
  showHeader = true,
  subtitle,
  csx,
  extraOptions,
  searchBarCsx,
  extraOptionsCsx,
  noCardsPadding = false,
}: ISubCardOptions) => {
  return (
    <Box csx={[subCardStyles(false, noCardsPadding), csx]}>
      {showHeader && (
        <Box className="subCardHeader">
          {(title || subtitle || searchBarProps || titleComponent) && (
            <Grid rowGap={10}>
              {(title || subtitle || titleComponent) && (
                <>
                  <Grid.Item
                    mb={12}
                    sm={searchBarProps || extraOptions ? 12 : 7}
                    md={searchBarProps || extraOptions ? 12 : 8}
                    xl={searchBarProps || extraOptions ? 12 : 9}>
                    <Box
                      csx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}>
                      <div css={{ display: 'flex', gap: '5px' }}>
                        {title && (
                          <Typography
                            color="persistentSemanticBlue"
                            fontWeight="medium">
                            {title}
                          </Typography>
                        )}
                        {required && (
                          <Typography color="persistentSemanticRed">
                            *
                          </Typography>
                        )}
                        {titleComponent && titleComponent}
                      </div>
                      {subtitle && (
                        <Typography color="darkestGrey" variant="caption">
                          {subtitle}
                        </Typography>
                      )}
                    </Box>
                  </Grid.Item>

                  {!searchBarProps && (
                    <Grid.Item
                      mb={12}
                      sm={extraOptions ? 12 : 5}
                      md={extraOptions ? 12 : 4}
                      lg={4}
                      xl={3}
                      csx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: '10px',
                      }}>
                      {extraOptions}
                      <ActionButtons
                        onEditAction={
                          onEditMode
                            ? (status: boolean) => onEditMode(status)
                            : undefined
                        }
                        isEditButtonDisabled={isEditButtonDisabled}
                        isEditButtonActive={isEditButtonActive}
                        optionList={actionOptions}
                        onEditClickDisabled={onEditPressDisabled}
                        disabled={isActionButtonDisabled}
                        onClickDisabled={onActionPressDisabled}
                        actionDropdownTriggerCsx={{ maxWidth: '50px' }}
                      />
                    </Grid.Item>
                  )}
                </>
              )}

              <Grid.Item>
                <Grid columnGap={5} rowGap={5}>
                  {searchBarProps ? (
                    <>
                      <Grid.Item
                        mb={12}
                        sm={extraOptions ? 12 : 6}
                        md={extraOptions ? 12 : 6}
                        lg={5}
                        xl={6}
                        csx={[
                          searchBarStyles(extraOptions ? true : false),
                          searchBarCsx,
                        ]}>
                        <Box
                          csx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                          }}>
                          <SearchInput
                            onChange={searchBarProps?.setInputValue}
                            value={searchBarProps?.value}
                            onDebounce={searchBarProps?.onDebounce}
                            disabled={
                              searchBarProps
                                ? searchBarProps.isSearchBarDisabled
                                : false
                            }
                          />
                          {searchBarProps?.onSort && (
                            <Box>
                              <SortButton
                                onSort={searchBarProps?.onSort}
                                value={searchBarProps?.sortValue}
                                disabled={searchBarProps?.isSortDisabled}
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid.Item>
                    </>
                  ) : (
                    <Grid.Item
                      isOffset
                      mbOffset={12}
                      smOffset={8}
                      mdOffset={8}
                      lgOffset={7}
                      xlOffset={6}
                    />
                  )}
                  {searchBarProps && (
                    <Grid.Item
                      mb={12}
                      sm={extraOptions ? 12 : 6}
                      md={extraOptions ? 12 : 6}
                      lg={7}
                      xl={6}
                      csx={[
                        {
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          gap: '10px',
                        },
                        extraOptionsCsx,
                      ]}>
                      {extraOptions}
                      <ActionButtons
                        onEditAction={
                          onEditMode
                            ? (status: boolean) => onEditMode(status)
                            : undefined
                        }
                        isEditButtonDisabled={isEditButtonDisabled}
                        isEditButtonActive={isEditButtonActive}
                        optionList={actionOptions}
                        onEditClickDisabled={onEditPressDisabled}
                        disabled={isActionButtonDisabled}
                        onClickDisabled={onActionPressDisabled}
                        actionDropdownTriggerCsx={{ maxWidth: '50px' }}
                      />
                    </Grid.Item>
                  )}
                </Grid>
              </Grid.Item>
            </Grid>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SubCardOptions;
