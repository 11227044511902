import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { IItemTableRow, truncateString } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { getIPairArray } from '../helpers';

interface ICategoryColumn extends WithTranslation {
  value: IItemTableRow['categoriesSubcategories'];
  truncateLength?: number;
  rows?: number;
}

const CategoryColumn = ({
  value,
  t,
  truncateLength = 15,
  rows,
}: ICategoryColumn) => {
  const { array, isOverflow, isNull, arrayToRender } = getIPairArray(
    value,
    rows,
  );
  return (
    <Box csx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {isNull || array.length === 0 ? (
        <Typography
          csx={{
            fontStyle: isNull ? 'italic' : undefined,
          }}>
          {isNull
            ? t('menuScreen.itemsMain.table.varies')
            : t('menuScreen.itemsMain.table.noCategories')}
        </Typography>
      ) : (
        arrayToRender.map((categorySubcategory, index) => (
          <Typography
            key={`category-${index}`}
            csx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            <Typography fontWeight="bold">
              {truncateString(categorySubcategory.text, truncateLength)}
              {categorySubcategory.description && ':'}
            </Typography>
            {categorySubcategory.description && (
              <Typography>
                &nbsp;
                {truncateString(
                  categorySubcategory.description,
                  truncateLength,
                  isOverflow && index === arrayToRender.length - 1,
                )}
                {isOverflow && index === arrayToRender.length - 1 && '...'}
              </Typography>
            )}
          </Typography>
        ))
      )}
    </Box>
  );
};

export default CategoryColumn;
