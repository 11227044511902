import { Theme, css } from '@emotion/react';

export const statusBlockStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  });

export const barStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.semanticGreen,
    minWidth: '5px',
    height: '100%',
    borderRadius: '8px',
  });
