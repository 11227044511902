import { Theme, css } from '@emotion/react';

export const HeaderTableStyles = (theme: Theme) =>
  css({
    paddingBlock: '10px',
    backgroundColor: theme.colors.lightestGrey,
    border: `1px solid ${theme.colors.lighterGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
