import Card from '@app/components/common/Card';
import Icon from '@app/components/common/Icon';
import Spinner from '@app/components/common/Spinner';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';

const LoadingPDFCard = () => {
  const pdfUrl = useRootSelector(state => state.reports.changeData.reportUrl);

  const isLoading = useRootSelector(
    state => state.reports.changeData.isReportPdfLoading,
  );
  const errorLoading = !isLoading && !pdfUrl;
  const errorDisplaying = !isLoading && !!pdfUrl;
  return (
    <Card
      csx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flex: 1,
        padding: 20,
      }}>
      {isLoading && <Spinner size="150px" color="darkGrey" />}
      {isLoading && (
        <Typography variant="heading" color="darkGrey">
          Loading Document
        </Typography>
      )}

      {(errorLoading || errorDisplaying) && (
        <Icon name="IoWarning" size="100px" color="darkestGrey" />
      )}
      {(errorLoading || errorDisplaying) && (
        <Typography
          variant="heading"
          color="darkestGrey"
          csx={{ fontSize: '35px', textAlign: 'center' }}>
          {errorLoading
            ? 'Error Loading Document'
            : 'Error Displaying Document'}
        </Typography>
      )}
      {errorDisplaying && (
        <Typography
          variant="subtitle"
          color="darkestGrey"
          csx={{ textAlign: 'center', fontSize: '25px' }}>
          {/* This happens when the browser is incompatible with showing PDF */}
          Your browser does not support displaying PDFs. Please download the
          document to view it.
        </Typography>
      )}
    </Card>
  );
};

export default LoadingPDFCard;
