import { Theme, css } from '@emotion/react';

export const chipContainerStyles = () =>
  css({
    position: 'relative',
    '.removeIcon': {
      position: 'absolute',
      top: -5,
      right: -5,
      backgroundColor: 'red',
      borderRadius: '50%',
      display: 'flex',
      ':hover': {
        cursor: 'pointer',
      },
    },
  });
export const chipStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.semanticBlue}`,
    borderRadius: '30px',
    width: '100px',
    maxWidth: '100px',
    height: '50px',
    backgroundColor: theme.colors.lighterBlue,
  });
