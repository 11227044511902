import DropdownButton from '@app/components/common/Dropdown/DropdownButton/DropdownButton';
import GenericSelectionModal from '@app/components/common/SelectionModal/GenericSelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  isMenuPath,
  isReportsPath,
  isSettingsPath,
} from '@app/helpers/location';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  actionCreatorsMenuWeb,
  actionCreatorsReports,
  actionCreatorsSettingsWeb,
  actionCreatorsWeb,
} from '@app/state';
import {
  changeLocation,
  getNewLocationPath,
  updateLocationPath,
  updateTabTitle,
} from '@app/state/app/actions';
import {
  selectIsMenuMasterMode,
  selectLocations,
} from '@app/state/menu/menuSelectors';
import {
  selectLoggedInUser,
  selectUserLocationAccess,
} from '@app/state/selectors/appSelectors';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { store, useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { getLocationsDropdownData } from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RoleSelectorModal from '../RoleSelectorModal';
import useOrgData from '@app/hooks/useOrgData';
import { initializeTableLayoutOnRefreshWeb } from '@app/state/settings/tableLayoutWebActions';

const DISALLOWED_MASTER_LOCATIONS = [
  'settings/devices-and-licenses',
  '/menu/fresh-sheet',
];

const LocationDropdown = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const locationPath = useLocation().pathname;
  const isOrgData = useOrgData();

  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    actionCreatorsSettingsWeb.checkForSettingsChangesAndNavigateWeb,
    dispatch,
  );
  const checkForCurrentUserDetailsLocationAccessAndContinue =
    bindActionCreators(
      actionCreatorsSettingsWeb.checkForCurrentUserDetailsLocationAccessAndContinue,
      dispatch,
    );

  const checkForReportsChangesAndNavigate = bindActionCreators(
    actionCreatorsReports.checkForReportsChangesAndNavigateWeb,
    dispatch,
  );

  const {
    checkForChangesAndNavigateWeb,
    checkForFreshSheetChangesAndNavigateWeb,
  } = bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const { setMasterLocationWeb } = bindActionCreators(
    actionCreatorsWeb,
    dispatch,
  );

  const menuSelectedMode = useRootSelector(state => state.menu.selectedMode);
  const settingsSelectedMode = useRootSelector(
    state => state.settings.selectedMode,
  );
  const isMenuMasterMode = useRootSelector(selectIsMenuMasterMode);
  const isSettingsMasterMode = useRootSelector(areSettingsMasterModeSelector);
  const isLoading = useRootSelector(state => state.app.isLoading);
  const currentLocationId = useRootSelector(state => state.app.locationId);
  const locationGroups = useSelector(selectLocations);
  const locationsWithUserAccess = useRootSelector(selectUserLocationAccess);

  const hasMultipleLocations = useRootSelector(
    selectShouldRenderLocationDropdown,
  );
  const loggedInUser = useRootSelector(selectLoggedInUser);

  const shouldShowMasterLocation = useMemo(
    () =>
      !DISALLOWED_MASTER_LOCATIONS.some(location =>
        locationPath.includes(location),
      ),
    [locationPath],
  );

  useEffect(() => {
    if (
      shouldShowMasterLocation &&
      !hasMultipleLocations &&
      (!isMenuMasterMode || !isSettingsMasterMode)
    ) {
      setMasterLocationWeb();
    }
  }, [
    hasMultipleLocations,
    setMasterLocationWeb,
    isMenuMasterMode,
    isSettingsMasterMode,
    shouldShowMasterLocation,
  ]);

  // Local state
  const [locationSelectionModalOpen, setLocationSelectionModalOpen] =
    useState(false);
  const [roleSelectorModalOpen, setRoleSelectorModalOpen] = useState(false);

  useEffect(() => {
    if (isOrgData && !loggedInUser.loggedInRole.id && isLoading)
      setRoleSelectorModalOpen(true);
  }, [isOrgData, loggedInUser, isLoading]);

  const selectedMode = useMemo(() => {
    if (isMenuPath(locationPath)) {
      return menuSelectedMode;
    } else {
      return settingsSelectedMode;
    }
  }, [locationPath, menuSelectedMode, settingsSelectedMode]);

  const locationOptions: IItemSelection[] = useMemo(() => {
    const locations: IItemSelection[] = getLocationsDropdownData(
      locationGroups,
      locationsWithUserAccess,
      !shouldShowMasterLocation,
    ).map(
      ({ value, label }): IItemSelection => ({
        id: value,
        title: label,
        isSelected: value === selectedMode.id,
      }),
    );
    const masterLocation = locations.find(location => location.id === 0);
    const currentLocation = locations.find(
      location => location.id === currentLocationId,
    );
    const allOtherLocations = locations.filter(
      location => location.id !== 0 && location.id !== currentLocationId,
    );
    const firstArray: IItemSelection[] = [];

    if (masterLocation) firstArray.push(masterLocation);
    if (currentLocation) firstArray.push(currentLocation);
    const secondArray = allOtherLocations;
    firstArray.push(...secondArray);
    return firstArray;
  }, [
    currentLocationId,
    locationGroups,
    locationsWithUserAccess,
    selectedMode.id,
    shouldShowMasterLocation,
  ]);

  const handleOnChangeSelectedMode = async (
    selection: IItemSelection[],
    openInNewTab?: boolean,
  ) => {
    if (selection.length === 0) return;

    const id = Number(selection[0].id);
    const locationName = selection[0].title;
    const state = store.getState();
    const isFreshSheetPage = Boolean(state.menu.changeFreshSheetData.data);

    if (openInNewTab && id > 0) {
      return window.open(getNewLocationPath(id));
    }
    setLocationSelectionModalOpen(false);

    if (id === selectedMode.id) {
      return;
    }

    if (isMenuPath(locationPath)) {
      if (isFreshSheetPage)
        return checkForFreshSheetChangesAndNavigateWeb(() => {
          handleChangeLocation(id);
        }, false);
      checkForChangesAndNavigateWeb(
        () => {
          handleChangeLocation(id);
        },
        useRefreshMenuWeb,
        false,
      );
    } else if (isSettingsPath(locationPath)) {
      checkForCurrentUserDetailsLocationAccessAndContinue(
        id,
        locationName,
        () => {
          checkForSettingsChangesAndNavigateWeb(
            () => {
              handleChangeLocation(id);
            },
            useRefreshSettingsWeb,
            () => null,
            false,
          );
        },
      );
    } else if (isReportsPath(locationPath)) {
      checkForReportsChangesAndNavigate(
        () => {
          handleChangeLocation(id);
        },
        () => null,
        true,
        false,
      );
    } else {
      // TODO: Custom logic for other pages
      handleChangeLocation(id);
    }
  };

  const handleChangeLocation = async (id: number) => {
    const location = locationOptions
      .flat()
      .find(_location => _location.id === id);

    if (location) {
      const shouldOpenRoleSelector = await dispatch(
        changeLocation(id, location.title),
      );
      dispatch(updateTabTitle());
      dispatch(updateLocationPath(location.id));

      if (document.location.pathname.includes('table-layout')) {
        dispatch(initializeTableLayoutOnRefreshWeb());
      }

      setRoleSelectorModalOpen(shouldOpenRoleSelector);
    }
  };

  const { selectedLocationLabel, selectedLocationId } = useMemo(() => {
    const location = locationOptions
      .flat()
      .find(_location => _location.id === selectedMode.id);

    return {
      selectedLocationLabel: location?.title || '',
      selectedLocationId: location?.id || 0,
    };
  }, [locationOptions, selectedMode.id]);

  return hasMultipleLocations ? (
    <>
      <DropdownButton
        isDisabled={isLoading}
        isExpanded={locationSelectionModalOpen}
        isLoading={isLoading}
        handleOpenDropDown={() => setLocationSelectionModalOpen(true)}
        hasSelectedOption={true}
        inputContainerProps={{}}
        selectedOptionLabel={selectedLocationLabel}
        error={false}
      />
      <GenericSelectionModal
        title={t('menuScreen.selectionModal.locations.titleSingular')}
        buttons={locationOptions || []}
        onAssociate={handleOnChangeSelectedMode}
        onIsLink={id => (id ? getNewLocationPath(id) : undefined)}
        onModalClose={() => setLocationSelectionModalOpen(false)}
        active={locationSelectionModalOpen}
        showDescriptionPanel={false}
        showSelectedButtons={false}
        associateOnSelect
        selectedSectionTitle={t(
          'menuScreen.selectionModal.locations.selectedSectionTitle',
        )}
        emptySectionMessage={t(
          'menuScreen.selectionModal.locations.emptySectionText',
        )}
        noCardSelectedMessage={t(
          'menuScreen.selectionModal.locations.noCardSelectedText',
        )}
        noAvailableCardsMessage={t(
          'menuScreen.selectionModal.locations.noAvailableCardsText',
        )}
        onPress={console.warn}
        currentButtonInfo={{
          id: selectedLocationId,
        }}
      />
      <RoleSelectorModal
        isActive={roleSelectorModalOpen}
        onModalClose={() => {
          setRoleSelectorModalOpen(false);
        }}
      />
    </>
  ) : null;
};

export default LocationDropdown;
