import BreadCrumb from '@app/components/common/BreadCrumb/BreadCrumb';
import { WithTranslation } from 'react-i18next';
import StickyContainer from '../../Menu/common/NavSwitch/StickyContainer';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { useCallback } from 'react';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import { IReportsStore } from '@westondev/tableturn-core';
import { AnyAction, bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsReports } from '@app/state';
import { useDispatch } from 'react-redux';
import Box from '@app/components/common/Box';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { MQ_MAX_MEDIUM } from '@app/theme/types';

interface IBreadCrumbReports extends WithTranslation {
  bucket: keyof IReportsStore;
  id: number;
  isPDFView: boolean;
}

const TITLE_BY_BUCKET: Record<keyof IReportsStore, string> = {
  itemComboSalesReports: 'Item/Combo Sales',
  salesOverviewReports: 'Sales Overview',
  endOfShiftReports: 'End of Shift',
};

const BreadCrumbReports = ({
  t,
  bucket,
  id,
  isPDFView,
}: IBreadCrumbReports) => {
  const dispatch = useDispatch();
  const navigate = useNavigateWithOrg();
  const route = REPORTS_SCREEN_BY_BUCKET_NAME[bucket];

  const {
    checkForReportsChangesAndNavigateWeb: checkForChangesAndNavigate,
    fetchReportPDFWeb,
  } = bindActionCreators(actionCreatorsReports, dispatch);

  const reportName = useRootSelector(
    state => state.reports.master[bucket]?.[id]?.name,
  );

  const pdfUrl = useRootSelector(state => state.reports.changeData.reportUrl);

  const isLoading = useRootSelector(
    state => state.reports.changeData.isReportPdfLoading,
  );

  const handleDownloadClick = useCallback(() => {
    if (!pdfUrl) return;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${reportName}.pdf`;
    link.click();
  }, [pdfUrl, reportName]);

  const handleReloadPdf = useCallback(() => {
    dispatch(fetchReportPDFWeb() as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = useCallback(() => {
    checkForChangesAndNavigate(
      () => {
        navigate(route);
      },
      //TODO: useRefreshReportsWeb,
      () => null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <StickyContainer>
      <Box csx={[displayFlexRowStyles, { width: '100%' }]}>
        <Box csx={[displayFlexRowStyles, { flex: 1 }]}>
          <BreadCrumb
            isLast={false}
            breadCrumb={{
              action: 'nav-main',
              text: TITLE_BY_BUCKET[bucket],
              onPress: onClick,
              pathURL: document.location.pathname,
            }}
            onClick={onClick}
            showActiveStyle={false}
            isSettings
          />
          <BreadCrumb
            isLast={true}
            breadCrumb={{
              action: 'edit',
              text: reportName ?? 'Adding New Report',
              onPress: () => null,
              pathURL: document.location.pathname,
              statusPath: '12321',
            }}
            onClick={() => null}
            showActiveStyle={true}
            isSettings
          />
        </Box>
        {isPDFView && (
          <Box csx={[displayFlexRowStyles, { gap: 15, paddingLeft: 15 }]}>
            <Button
              csx={{
                width: 174,
                [MQ_MAX_MEDIUM]: {
                  width: 60,
                  '> span.icon.icon-left': {
                    paddingRight: '0px',
                    position: 'relative',
                  },
                },
              }}
              icon={<Icon name="MdDownload" />}
              variant="secondary"
              onClick={handleDownloadClick}
              isLoading={isLoading}
              disabled={isLoading || !pdfUrl}>
              <span
                css={{
                  [MQ_MAX_MEDIUM]: {
                    display: 'none',
                  },
                }}>
                {t('commonButtons.download')}
              </span>
            </Button>

            <Button
              csx={{
                width: 174,
                [MQ_MAX_MEDIUM]: {
                  width: 60,
                  '> span.icon.icon-left': {
                    paddingRight: '0px',
                    position: 'relative',
                  },
                },
              }}
              icon={<Icon name="MdOutlineRotateRight" />}
              variant="secondary"
              onClick={handleReloadPdf}
              isLoading={isLoading}
              disabled={isLoading || !pdfUrl}>
              <span
                css={{
                  [MQ_MAX_MEDIUM]: {
                    display: 'none',
                  },
                }}>
                {t('commonButtons.reload')}
              </span>
            </Button>
          </Box>
        )}
      </Box>
    </StickyContainer>
  );
};
export default BreadCrumbReports;
