import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { TChange } from '@app/types';
import { diffRegisterTicketFactory } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import RenderChange from '../common/RenderChange';

const RegisterTicketDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(settingsDifferencesSelector);

  const formattedDifferences = useMemo(() => {
    return diffRegisterTicketFactory(differences);
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (field === 'modifierItemDisplayMode') {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return <RenderChange change={change} formatValue={formatValue} />;
  };
  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.ticketDisplay.length > 0 && (
            <>
              <HeaderDiffModal
                name={t(
                  'settingsModule.registerTicketSettings.ticketDisplay.title',
                )}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.ticketDisplay.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`registerModeSettings_ticketDisplay_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default RegisterTicketDiffModal;
