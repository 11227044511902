import { store } from '@app/state/store';
import {
  CoreRootState,
  IMenuTypeVersion,
  menuTypeVersionsFactoryCore,
  menuTypeVersionsSelectionFactoryCore,
} from '@westondev/tableturn-core';

export const menuTypeVersionsFactory = (menuTypeVersions: {
  [key: number]: IMenuTypeVersion;
}) =>
  menuTypeVersionsFactoryCore(
    menuTypeVersions,
    store.getState() as CoreRootState,
  );

export const menuTypeVersionsSelectionFactory = (menuTypeVersions: {
  [key: number]: IMenuTypeVersion;
}) =>
  menuTypeVersionsSelectionFactoryCore(
    menuTypeVersions,
    store.getState() as CoreRootState,
  );
