import { Theme, css } from '@emotion/react';

export const subCardStyles =
  (showCardShadow: boolean, noCardsPadding: boolean) => (theme: Theme) =>
    css({
      border: showCardShadow ? 'none' : `1px solid ${theme.colors.lightGrey}`,
      boxShadow: showCardShadow ? theme.shadows[1] : 'none !important',
      borderRadius: '8px',
      height: 'max-content',
      position: 'relative',
      backgroundColor: theme.colors.white,
      padding: noCardsPadding ? '0px' : '15px',
      '> .subCardHeader': {
        marginBottom: '15px',
        '.subCardTitle': {
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        },
      },
    });
