import FilterModal from '@app/components/common/FilterModal';
import Box from '../../Box';
import FilterModalOption from './FilterModalOption';
import { TFilterGenericSelectionModal } from './types';

const FilterGenericSelectionModal = <T,>({
  options,
  onFilter,
  currentValue = [],
  onClear,
  isClearDisabled,
  onClearAll,
  isClearAllDisabled,
  isActive,
  triggerButtonProps,
}: TFilterGenericSelectionModal<T>) => {
  return (
    <FilterModal
      triggerButtonProps={triggerButtonProps}
      onClearAll={onClearAll}
      isClearAllDisabled={isClearAllDisabled}
      isActive={isActive}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        {options.map(
          (option, index) =>
            option.options.length > 0 && (
              <FilterModalOption
                key={index}
                index={index}
                option={option}
                onFilter={onFilter}
                currentValue={currentValue}
                onClear={onClear}
                isClearDisabled={isClearDisabled}
              />
            ),
        )}
      </Box>
    </FilterModal>
  );
};

export default FilterGenericSelectionModal;
