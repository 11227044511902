import Typography from '@app/components/common/Typography';
import { TCsx } from '@emotion/react';
import { EGroupByItemComboSalesReport } from '@westondev/tableturn-core';

const ItemComboTableGroupByCell = ({
  groupBy,
  ...props
}: {
  groupBy: EGroupByItemComboSalesReport;
  csx?: TCsx;
}) => {
  switch (groupBy) {
    case EGroupByItemComboSalesReport.CATEGORY:
      return <Typography {...props}>Category</Typography>;
    case EGroupByItemComboSalesReport.REPORTING_GROUPS:
      return <Typography {...props}>Reporting Group</Typography>;
    default:
      return <Typography {...props}>All</Typography>;
  }
};

export default ItemComboTableGroupByCell;
