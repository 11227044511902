import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const buttonsStyles = () =>
  css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  });

export const modalContentContainerStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBlock: '30px',
    '.content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [MQ_MIN_SMALL]: {
        width: '380px',
      },
      '.iconsContainer': {
        position: 'relative',
        marginBottom: '15px',
      },
      '.title': {
        marginBottom: '15px',
      },
      '.emailInput': {
        marginBlock: '40px',
      },
    },
  });
