import {
  actionCreatorsCustomers,
  actionCreatorsCustomersChangeData,
  CUSTOMER_INITIAL_STATE,
  CustomersSectionId,
  FormMode,
  getCustomersVersionWithStateCore,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { getRequiredActionsData } from '../menu/menuWebActions';
import { AppThunk } from '../store';
import { selectCustomers } from './customersSelectors';
import { ILoadCustomerChangeData } from './types';

const { setCustomersChangeData } = actionCreatorsCustomersChangeData;

export const loadCustomersChangeData =
  ({ id = 0 }: ILoadCustomerChangeData): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const mode = id ? FormMode.UPDATE : FormMode.CREATE;

    const isCreation = mode === FormMode.CREATE;

    const customerId = Number(id);
    const settingsObject = isCreation
      ? CUSTOMER_INITIAL_STATE
      : selectCustomers(state)[customerId];
    const customersVersion = getCustomersVersionWithStateCore(state) || 0;

    dispatch(
      setCustomersChangeData({
        id: customerId,
        data: cloneDeep(settingsObject),
        originalData: cloneDeep(settingsObject),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: CustomersSectionId.ALL,
        customersVersion,
        selectedLocations: [],
        mode,
      }),
    );
  };

export const fetchCustomersWeb =
  (
    page: number,
    search?: string,
  ): AppThunk<
    Promise<{
      success: boolean;
      totalPages: number;
      totalCustomers: number;
      currentPage: number;
    }>
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);
    return dispatch(
      actionCreatorsCustomers.fetchCustomers(
        organizationId,
        currentLocationId,
        currentLocationId,
        licenseId,
        page,
        true,
        search,
      ),
    );
  };
