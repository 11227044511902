import Box from '@app/components/common/Box';
import RenderChange from '@app/components/common/ConfirmationModal/SettingsDiffConfirmationModal/common/RenderChange';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { formatValue } from '@app/helpers/modals/diffModal';
import { TChangeItems, formatMoney } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

interface IItemTotalPricesDiff extends WithTranslation {
  prices: TChangeItems[];
  hasDefaultModifiersPrice: boolean;
}

const ItemTotalPricesDiff = ({
  prices,
  hasDefaultModifiersPrice,
  t,
}: IItemTotalPricesDiff) => {
  const totalItemPrice = prices.find(
    priceChange => priceChange.field === 'totalItemPrice',
  );
  const totalOnlinePrice = prices.find(
    priceChange => priceChange.field === 'totalOnlinePrice',
  );
  const totalDeliveryPrice = prices.find(
    priceChange => priceChange.field === 'totalDeliveryPrice',
  );
  const totalToGoPrice = prices.find(
    priceChange => priceChange.field === 'totalToGoPrice',
  );

  prices = prices.filter(price => !price.fo);
  return (
    <Grid
      columnGap={15}
      rowGap={15}
      csx={theme => ({
        borderBlock: `1px solid ${theme.colors.lightGrey}`,
        marginBottom: '15px',
        paddingBlock: '15px',
      })}>
      {(prices.find(priceChange => priceChange.field === 'itemPrice') ||
        hasDefaultModifiersPrice) && (
        <PriceSection
          title={`${t('app.modals.itemsDiff.pricing.total')} ${t(
            'app.modals.itemsDiff.pricing.onlinePrice',
          )}`}
          description={`${t('app.modals.itemsDiff.pricing.onlinePrice')} + ${t(
            'app.modals.itemsDiff.pricing.modPrice',
          )}`}
          value={formatMoney(
            '',
            totalItemPrice ? Number(totalItemPrice.value) : 0,
          )}
        />
      )}
      {(prices.find(priceChange => priceChange.field === 'onlinePrice') ||
        hasDefaultModifiersPrice) && (
        <PriceSection
          title={`${t('app.modals.itemsDiff.pricing.total')} ${t(
            'app.modals.itemsDiff.pricing.onlinePrice',
          )}`}
          description={`${t('app.modals.itemsDiff.pricing.onlinePrice')} + ${t(
            'app.modals.itemsDiff.pricing.modPrice',
          )}`}
          value={formatMoney(
            '',
            totalOnlinePrice ? Number(totalOnlinePrice.value) : 0,
          )}
        />
      )}
      {(prices.find(priceChange => priceChange.field === 'deliveryPrice') ||
        hasDefaultModifiersPrice) && (
        <PriceSection
          title={`${t('app.modals.itemsDiff.pricing.total')} ${t(
            'app.modals.itemsDiff.pricing.deliveryPrice',
          )}`}
          description={`${t(
            'app.modals.itemsDiff.pricing.deliveryPrice',
          )} + ${t('app.modals.itemsDiff.pricing.modPrice')}`}
          value={formatMoney(
            '',
            totalDeliveryPrice ? Number(totalDeliveryPrice.value) : 0,
          )}
        />
      )}
      {(prices.find(priceChange => priceChange.field === 'toGoPrice') ||
        hasDefaultModifiersPrice) && (
        <PriceSection
          title={`${t('app.modals.itemsDiff.pricing.total')} ${t(
            'app.modals.itemsDiff.pricing.toGoPrice',
          )}`}
          description={`${t('app.modals.itemsDiff.pricing.toGoPrice')} + ${t(
            'app.modals.itemsDiff.pricing.modPrice',
          )}`}
          value={formatMoney(
            '',
            totalToGoPrice ? Number(totalToGoPrice.value) : 0,
          )}
        />
      )}
    </Grid>
  );
};

export const PriceSection = ({
  title,
  description,
  value,
}: {
  title: string;
  description: string;
  value: string;
}) => {
  return (
    <Grid.Item
      sm={6}
      md={6}
      lg={4}
      xl={4}
      csx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '15px',
      }}>
      <Box csx={{ width: 330 }}>
        <Typography fontWeight="medium">{title}</Typography>
        <Typography>{description}</Typography>
      </Box>
      <RenderChange
        showLabel={false}
        formatValue={formatValue}
        change={{
          field: 'item',
          label: '',
          value: value,
        }}
      />
    </Grid.Item>
  );
};

export default ItemTotalPricesDiff;
