import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import { ParseKeys } from 'i18next';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Divider from '../../Divider';
import InfoIcon from '../../Icon/custom/InfoIcon';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { btnBaseStyles } from '../styles';
import { getBucketBody } from './BucketValidationResolver/BucketValidationResolver';
import useRootSelector from '@app/hooks/useRootSelector';
interface IBucketValidationConfirmationModal
  extends IConfirmationModal,
    WithTranslation {
  canDiscard?: boolean;
}

const BucketValidationConfirmationModal = ({
  active,
  onCancel,
  onSuccess,
  btnCommonCsx: btnCommonCsx,
  btnCancelCsx: btnCancelCsx,
  runCancelOnClickOutside,
  size,
  t,
  autoclose = true,
  autoCloseOnCancel = true,
  canDiscard,
}: IBucketValidationConfirmationModal) => {
  // Redux
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );
  const bucketSelected = useRootSelector(
    state => state.menu.changeData.bucketSelected,
  );
  const massUpdateBucketSelected = useRootSelector(
    state => state.menu.changeMassUpdateData.bucketSelected,
  );
  const reportsBucketSelected = useRootSelector(
    state => state.reports.changeData.bucket,
  );

  const data = useMemo(
    () =>
      getBucketBody(
        bucketSelected || massUpdateBucketSelected || reportsBucketSelected,
      ),
    [bucketSelected, massUpdateBucketSelected, reportsBucketSelected],
  );

  //Local state
  const theme = useTheme();

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
    autoclose && setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  return (
    <Modal
      isActive={active}
      size={size || 500}
      showXCloser={false}
      animationMode="alert"
      title={
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <InfoIcon
            backgroundColor={theme.colors.semanticBlue}
            color={theme.colors.textWhite}
            size="12px"
          />
          <Box>
            <Typography variant="subtitle" fontWeight="medium">
              {t('validations.validationFailed', {
                bucket: reportsBucketSelected
                  ? t(
                      `menuScreen.common.reports.${reportsBucketSelected}.singular`,
                    )
                  : t(
                      `menuScreen.common.bucket.${
                        bucketSelected || massUpdateBucketSelected
                      }.singular` as ParseKeys,
                    ),
              })}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <>
          {canDiscard && (
            <Button
              variant="secondary"
              csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
              onClick={handleOnModalSuccess}>
              {t('commonButtons.discardChanges')}
            </Button>
          )}
          <Button
            variant="primary"
            csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
            onClick={handleOnCancel}>
            {t('commonButtons.ok')}
          </Button>
        </>
      }
      onModalClose={handleOnModalClose}>
      <Box
        csx={{
          display: 'flex',
          width: '100%',
        }}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowWrap: 'anywhere',
          }}>
          <Typography fontWeight="medium">
            {t(
              canDiscard
                ? 'validations.discardSubtitle'
                : 'validations.subtitle',
            )}
          </Typography>
          <Divider
            label={t('validations.issues')}
            csx={{ marginBlock: '15px' }}
          />
          {data}
        </Box>
      </Box>
    </Modal>
  );
};

export default BucketValidationConfirmationModal;
