import {
  getOrderStatusName,
  OrderStatus,
  OrderType,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import useRootSelector from './useRootSelector';
import { useMemo } from 'react';

const useReportsFilterText = (
  bucket:
    | 'ownerIds'
    | 'categories'
    | 'subcategories'
    | 'reportingGroups'
    | 'orderStatuses'
    | 'orderTypes',
  values: number[] | OrderStatus[] | OrderType[] | null | undefined,
): string => {
  const { t } = useTranslation();

  const currentMenuBucket = useRootSelector(
    state => state.menu.selectedMode.currentMenu?.[bucket as 'categories'],
  );

  const masterUsers = useRootSelector(state => state.settings.master.users);

  return useMemo(() => {
    if (!values) {
      return t('commonTexts.all');
    }

    if (bucket === 'orderStatuses') {
      const translatedNames = values.map(status =>
        getOrderStatusName(status as OrderStatus),
      );
      return translatedNames.join(', ');
    }

    if (bucket === 'orderTypes') {
      const translatedNames = values.map(orderType =>
        t(`reports.fields.orderTypeOptions.${orderType as OrderType}`),
      );
      return translatedNames.join(', ');
    }

    if (bucket === 'ownerIds') {
      const names = values.map(value => {
        const data = masterUsers?.[value as number];

        return `${data?.user?.firstName || ''} ${
          data?.user?.lastName || ''
        }`.trim();
      });
      return names.join(', ');
    }

    const names = values.map(
      value => currentMenuBucket[value as number]?.name || '-',
    );
    return names.join(', ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, currentMenuBucket]);
};

export default useReportsFilterText;
