import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import FilterGenericSelectionModal from '@app/components/common/SelectionModal/FilterGenericSelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import menuCategoryAssignedItemsFactory from '@app/helpers/factories/menu/cardFactories/categories/menuCategoryAssignedItemsFactory';
import { getSelectionModalMenuTypeFilterValue } from '@app/helpers/menu/modals/itemsSelectionModal';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectCurrentItem,
  selectMenu,
  selectMenuTypes,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { RootState, store } from '@app/state/store';
import { TCsx, useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ICategory,
  IItemCard,
  IMenuStore,
  NestedKeyOf,
  SectionId,
  actionCreatorsMenu,
  getMenuCategoryMenuTypeElements,
  getMenuCategorySubcategoryMenuTypeElements,
  menuSelectors,
  subcategoriesDropdownOptionsFactoryCore,
} from '@westondev/tableturn-core';
import { setWith, sortBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import without from 'lodash/without';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { selectDataByBucketName } = menuSelectors;

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
  hideEdit?: boolean;
  hideMoreOptions?: boolean;
  showSubcategory?: boolean;
  bucket: 'items' | 'combos';
}

const SECTION_ID = SectionId.ASSOCIATION;

const getMemoizedItemData = makeSelectItemData('categories', SECTION_ID);

const subcategoriesDropdownOptionsFactory = (
  subcategoryIds: number[],
  showNoSubcategoryOption = false,
) =>
  subcategoriesDropdownOptionsFactoryCore(
    subcategoryIds,
    selectSubcategories(store.getState()),
    showNoSubcategoryOption,
  );

const AssignedItemsCombosSection = ({
  csx,
  hideEdit,
  hideMoreOptions,
  showSubcategory,
  t,
  bucket,
}: IAssignedItemsCombosSection) => {
  // Redux
  const subcategories = useSelector(selectSubcategories);
  const { itemData } = useSelector(getMemoizedItemData);
  const { id: categoryId } = useParams();
  const menuTypes = useSelector(selectMenuTypes);

  const { updateCategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const currentElementChangeData = useSelector((state: RootState) =>
    selectDataByBucketName(state, 'categories'),
  );

  const getValue = (
    keyString?: NestedKeyOf<IMenuStore['categories'][number]>,
  ): any => {
    if (keyString) return get(currentElementChangeData, keyString);

    return currentElementChangeData;
  };

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  // Local state
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [menuTypeFilter, setMenuTypeFilter] = useState([0]);
  const [filteredList, setFilteredList] = useState<
    typeof assignedElementsCardList
  >([]);
  const isItem = bucket === 'items';
  const elementsField = isItem ? 'itemIds' : 'comboIds';
  const subcategoryMenuTypeElementsField = isItem
    ? 'subcategoryMenuTypeItemsIds'
    : 'subcategoryMenuTypeCombosIds';
  const bucketUrl = bucket === 'combos' ? 'combos/combos' : bucket;

  const elements = useSelector((state: RootState) => selectMenu(state)[bucket]);

  const navigate = useNavigateWithOrg();

  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const assignedSubcategoryMenuTypeElementsIds: ICategory['subcategoryMenuTypeItemsIds'] =
    getValue(subcategoryMenuTypeElementsField);

  const subcategoryIds = getValue('subcategoryIds');

  const otherSubcategoryId = getValue('otherSubcategoryId');

  const getElementSubcategoryId = (elementId: number, menuTypeId: number) => {
    const data = selectDataByBucketName(
      store.getState(),
      'categories',
    ) as ICategory;
    const _assignedSubcategoryMenuTypElementsIds =
      data[subcategoryMenuTypeElementsField];
    const currentSubcategoryId = Object.keys(
      _assignedSubcategoryMenuTypElementsIds,
    ).find(
      subcategoryId =>
        _assignedSubcategoryMenuTypElementsIds[Number(subcategoryId)][
          menuTypeId
        ]?.includes?.(elementId),
    );

    return currentSubcategoryId
      ? Number(currentSubcategoryId) === 0
        ? 0
        : Number(currentSubcategoryId)
      : otherSubcategoryId || 0;
  };

  const deleteMenuTypeElementFromSubcategory = (
    deletedElementId: number,
    deletedMenuTypeId: number,
    deleteSubcategory?: boolean,
  ) => {
    const newAssignedSubcategoryMenuTypeElementsIds = cloneDeep(
      assignedSubcategoryMenuTypeElementsIds,
    );
    const subcategoryId =
      getElementSubcategoryId(deletedElementId, deletedMenuTypeId) || 0;
    const newElementIds = without(
      get(
        newAssignedSubcategoryMenuTypeElementsIds,
        `${subcategoryId}.${deletedMenuTypeId}`,
      ),
      deletedElementId,
    );
    if (newElementIds.length === 0) {
      unset(
        newAssignedSubcategoryMenuTypeElementsIds,
        `${subcategoryId}.${deletedMenuTypeId}`,
      );
    } else {
      set(
        newAssignedSubcategoryMenuTypeElementsIds,
        `${subcategoryId}.${deletedMenuTypeId}`,
        newElementIds,
      );
    }
    const allElementIds = getMenuCategoryMenuTypeElements(
      newAssignedSubcategoryMenuTypeElementsIds[subcategoryId],
    );

    (deleteSubcategory || subcategoryId === 0) &&
      allElementIds.length === 0 &&
      delete newAssignedSubcategoryMenuTypeElementsIds[Number(subcategoryId)];

    setValue({
      [subcategoryMenuTypeElementsField]:
        newAssignedSubcategoryMenuTypeElementsIds,
    });
  };

  const assignedElementsCardList = useMemo(
    () => {
      const cards = menuCategoryAssignedItemsFactory(
        elements,
        menuTypes,
        assignedSubcategoryMenuTypeElementsIds,
        (id, text, openInNewTab) => {
          const pathURL = `/menu/${isItem ? 'items' : 'combos/combos'}/${id}`;
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          const _navigate = () =>
            navigate(pathURL, {
              state: { categoryId: itemData?.categoryId },
            });
          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text,
                onPress: _navigate,
                pathURL,
              }),
            onRefresh,
          );
        },
        (deletedElementId: number, deletedMenuTypeId: number) => {
          deleteMenuTypeElementFromSubcategory(
            deletedElementId,
            deletedMenuTypeId,
            true,
          );
          const currentData = selectCurrentItem(store.getState()) as ICategory;
          const newElementIds = getMenuCategorySubcategoryMenuTypeElements(
            currentData[subcategoryMenuTypeElementsField],
          );
          setValue({ [elementsField]: newElementIds });
        },
      );

      return sortBy(cards, ['menuTypeId', 'id']);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, menuTypes, assignedSubcategoryMenuTypeElementsIds],
  );

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const currentMenuTypeElementIds: ICategory['subcategoryMenuTypeItemsIds'][0] =
      cloneDeep(getValue(subcategoryMenuTypeElementsField)[0]) || {};

    const clonedAssignedSubcategoryMenuTypeElementsIds = cloneDeep(
      assignedSubcategoryMenuTypeElementsIds,
    );

    buttons.forEach(button => {
      const menuTypeId = button.menuTypeId;
      const elementIds = get(currentMenuTypeElementIds, `${menuTypeId}`) || [];

      if (!elementIds.includes(button.id)) {
        elementIds.unshift(button.id);
      }

      set(currentMenuTypeElementIds, `${menuTypeId}`, elementIds);

      const subcategoryElementIds: number[] =
        get(clonedAssignedSubcategoryMenuTypeElementsIds, `0.${menuTypeId}`) ||
        [];

      if (!subcategoryElementIds.includes(button.id as number)) {
        subcategoryElementIds.push(button.id as number);
      }
      setWith(
        clonedAssignedSubcategoryMenuTypeElementsIds,
        `0.${menuTypeId}`,
        subcategoryElementIds,
        Object,
      );
    });

    const newElementIds = getMenuCategoryMenuTypeElements(
      currentMenuTypeElementIds,
    );

    setValue({
      [elementsField]: newElementIds,

      [subcategoryMenuTypeElementsField]:
        clonedAssignedSubcategoryMenuTypeElementsIds,
    });
    setOpenSelectionModal(false);
  };

  const handleDropdownChange = (
    element: number,
    menuTypeId: number,
    value: number,
    hasToDelete?: boolean,
  ) => {
    batch(() => {
      // get the updated data in case that this function is used in the same render, like a loop
      hasToDelete &&
        deleteMenuTypeElementFromSubcategory(element, menuTypeId, true);
      const data = store.getState().menu.changeData.data as ICategory;
      const subcategoryMenuTypeElementsIdsCloned = cloneDeep(
        data[subcategoryMenuTypeElementsField],
      );
      let newElementIds: number[] =
        get(subcategoryMenuTypeElementsIdsCloned, `${value}.${menuTypeId}`) ||
        [];
      newElementIds = [...newElementIds, element];
      setWith(
        subcategoryMenuTypeElementsIdsCloned,
        `${value}.${menuTypeId}`,
        newElementIds,
        Object,
      );
      setValue({
        [subcategoryMenuTypeElementsField]:
          subcategoryMenuTypeElementsIdsCloned,
      });
    });
  };

  const closeModal = () => setOpenSelectionModal(false);

  useEffect(() => {
    if (!assignedElementsCardList) {
      return;
    }
    const list = menuTypeFilter.includes(0)
      ? assignedElementsCardList
      : [...assignedElementsCardList].filter(element =>
          menuTypeFilter.includes(element.menuTypeId),
        );
    setFilteredList(list);
  }, [assignedElementsCardList, menuTypeFilter]);

  const menuTypeOptions = useMemo(() => {
    const menuTypeSet = new Set<number>();
    assignedElementsCardList.forEach(button =>
      menuTypeSet.add(button.menuTypeId),
    );

    return [
      { label: t('commonTexts.all'), value: 0 },
      ...Array.from(menuTypeSet).map(menuTypeId => ({
        label: menuTypes[menuTypeId]?.name || '',
        value: menuTypeId,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedElementsCardList, t]);

  const currentSubcategories: Array<{ value: number; label: string }> = useMemo(
    () => subcategoriesDropdownOptionsFactory(subcategoryIds, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subcategoryIds, subcategories, t],
  );

  const filterFunction = useCallback(
    (newElements: IItemSelection[]) =>
      newElements.filter(element => {
        return !Object.values(assignedSubcategoryMenuTypeElementsIds).some(
          menuTypeElementsIds => {
            const menuType =
              get(menuTypeElementsIds, `${element.menuTypeId}`) || [];
            if (menuType.includes(element.id)) {
              return true;
            }

            return false;
          },
        );
      }),
    [assignedSubcategoryMenuTypeElementsIds],
  );

  const onMenuTypeFilterClear = () => setMenuTypeFilter([0]);

  const onFilter = (option: number) => {
    setMenuTypeFilter(
      getSelectionModalMenuTypeFilterValue(option, menuTypeFilter),
    );
  };

  const menuTypeFilterNode = useMemo(
    () => (
      <FilterGenericSelectionModal<number>
        options={[
          {
            id: 1,
            label: t('menuScreen.common.bucket.menuTypes.plural'),
            options: menuTypeOptions,
          },
        ]}
        currentValue={[{ id: 1, values: menuTypeFilter }]}
        onFilter={onFilter as (options: unknown, id: number) => void}
        onClear={onMenuTypeFilterClear}
        triggerButtonProps={{
          csx: { minWidth: '50px', width: '50px' },
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuTypeOptions, menuTypeFilter],
  );

  return (
    <AssignedElements
      type={bucket}
      detailsScreenProps={{
        currentRelationsIds: [],
        wantedEntity: bucket,
      }}
      showSelectedButtons
      noElementsMessage={t(
        `menuScreen.categoryDetails.associations.${bucket}.emptyCardsMessage`,
      )}
      groupByMenuType
      filterSelectedItems={false}
      active={openSelectionModal}
      onAssociate={handleOnAssociate}
      filterFunction={filterFunction}
      onModalClose={closeModal}
      elements={filteredList}
      subCardProps={{
        title:
          bucket === 'combos'
            ? t('loggedIn.menuModule.tabs.combos.combos')
            : t('loggedIn.menuModule.tabs.items'),
        subtitle: t(
          `menuScreen.categoryDetails.associations.${bucket}.description`,
        ),
        extraOptions: menuTypeFilterNode,
        onEditMode: hideEdit ? undefined : setEditMode,
        actionOptions: hideMoreOptions
          ? undefined
          : [
              {
                text: t(
                  `components.actionButtons.${
                    isItem ? 'addNewItem' : 'addNewCombo'
                  }`,
                ),
                icon: true,
                handler: () => {
                  checkForChangesAndNavigateWeb(
                    () =>
                      addBreadcrumbLocationWeb({
                        action: BreadCrumbAction.ADD,
                        text: t(
                          `components.actionButtons.${
                            isItem ? 'addNewItemTag' : 'addNewComboTag'
                          }`,
                        ),
                        onPress: () => {
                          navigate(`/menu/${bucketUrl}/add`, {
                            state: {
                              categoryId,
                            },
                          });
                        },
                        pathURL: `/menu/${bucketUrl}/add`,
                      }),
                    onRefresh,
                    true,
                    false,
                    'items',
                  );
                },
              },
              {
                text: t(
                  `components.actionButtons.${
                    isItem ? 'addExistingItem' : 'addExistingCombo'
                  }`,
                ),
                icon: false,
                handler: () => setOpenSelectionModal(true),
              },
            ],
        csx,
      }}
      renderItem={item => {
        const itemTyped = item as IItemCard & {
          menuTypeId: number;
          description: string;
        };

        const _bucket = bucket === 'combos' ? 'combos/combos' : bucket;
        const pathURL = `/menu/${_bucket}/${itemTyped.id}`;

        const subcategoryId = showSubcategory
          ? getElementSubcategoryId(itemTyped.id || 0, itemTyped.menuTypeId)
          : 1;

        return (
          <Box csx={{ display: 'flex', flexDirection: 'column' }}>
            <Card.Item
              csx={{
                borderColor: `${theme.colors.lightGrey} !important`,
              }}
              {...itemTyped}
              subTitle={itemTyped.description}
              showRemoveIcon={isEditMode}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                checkForChangesAndNavigateWeb(
                  () =>
                    addBreadcrumbLocationWeb({
                      action: BreadCrumbAction.NAV,
                      text: itemTyped.title as string,
                      onPress: () => navigate(pathURL),
                      pathURL,
                    }),
                  onRefresh,
                );
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
            {showSubcategory && (
              <>
                <Box
                  csx={{
                    width: 0,
                    height: 0,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderBottom: `10px solid ${theme.colors.lighterGrey}`,
                    margin: '0 auto',
                  }}
                />

                <Box>
                  <Dropdown
                    placeholder="Select Subcategory"
                    value={subcategoryId}
                    data={[currentSubcategories]}
                    onChange={value => {
                      handleDropdownChange(
                        itemTyped.id,
                        itemTyped.menuTypeId,
                        value || 0,
                        true,
                      );
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        );
      }}
    />
  );
};

export default AssignedItemsCombosSection;
