import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { TChange } from '@app/types';
import { useTheme } from '@emotion/react';
import { Fragment } from 'react';

interface ITextAreaChange {
  change: TChange;
}
const TextAreaChange = ({ change }: ITextAreaChange) => {
  const theme = useTheme();
  const itemChangeColor = theme.colors.textBlack;
  const boxColor = theme.colors.lighterBlue;
  const boxTextAndBorderColor = theme.colors.persistentSemanticBlue;

  if (!change.field) return <></>;
  return (
    <Fragment key={`change-${change.field}`}>
      <Box csx={{ marginBlock: '8px' }}>
        <Box csx={{ width: '180px', marginBottom: '6px' }}>
          <Typography color={itemChangeColor}>{change.label}</Typography>
        </Box>
        <Box
          csx={{
            width: '100%',
            padding: 15,
            backgroundColor: boxColor,
            borderRadius: 10,
            border: `1px solid ${boxTextAndBorderColor}`,
          }}>
          <Typography color={boxTextAndBorderColor}>
            {change.value as string}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TextAreaChange;
