import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import ColorPicker from '@app/components/common/ColorPicker';
import Icon from '@app/components/common/Icon';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import InputCell from '@app/components/common/Table/InputCell';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  DEFAULT_SPECIAL_TAG_COLOR,
  DEFAULT_SPECIAL_TAG_TEXT_COLOR,
  IRegisterSettings,
  ISpecialTag,
  REGISTER_SPECIAL_TAG_INITIAL_STATE,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  getSpecialTagId,
  propagateString,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { m, useMotionValue, useTransform } from 'framer-motion';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ROW_ID_PREFIX,
  SECTION_ID,
  columnHelper,
  getMemoizedItemData,
} from './types';

const { specialTagsSelector, areSettingsMasterModeSelector } =
  settingsSelectors;

// TODO: Add translations
const SpecialTags = () => {
  // Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  const y = useMotionValue(0);
  const theme = useTheme();

  const backgroundColor = useTransform(
    y,
    [0, 10],
    [theme.colors.lightestExtraGrey, theme.colors.white],
  );

  const boxShadow = useTransform(
    y,
    [0, 10],
    [
      '0px 0px 5px 0px rgba(3, 8, 25, 0)',
      '0px 5px 5px 0px rgba(3, 8, 25, 0.10)',
    ],
  );

  useEffect(() => {
    const element = document.getElementById('layout-content');
    if (!element) return;

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);

    function handleScroll(e: Event) {
      const scroll = e.target as HTMLDivElement;
      y.set(scroll.scrollTop);
    }
  }, [y]);

  const { data: bigData } = useSelector(getMemoizedItemData);
  const data = bigData?.specialTags;
  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const actualSpecialTags = useSelector(specialTagsSelector);

  // Local state
  const handleNewRowScroll = useScrollToNewRow();

  const updateSpecialTag = (
    newValue: any,
    id: number,
    field: keyof ISpecialTag,
  ) => {
    const specialTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.specialTags;
    setValue<IRegisterSettings>(
      {
        specialTags: {
          ...specialTags,
          [id]: {
            ...specialTags[id],
            [field]: newValue,
          },
        },
      },
      SECTION_ID,
    );
  };

  const onNewSpecialTag = () => {
    const nextId =
      (max(Object.keys(data || {}).map(key => Number(key)) || []) || 0) + 1;
    const newRowIndex = Object.keys(data || {}).length;
    setValue<IRegisterSettings>(
      {
        specialTags: {
          ...data,
          [nextId]: {
            ...REGISTER_SPECIAL_TAG_INITIAL_STATE,
            id: nextId,
            specialTagId: nextId,
          },
        },
      },
      SECTION_ID,
    );

    handleNewRowScroll(`${ROW_ID_PREFIX}${newRowIndex}`);
  };

  const updateName = (newName: string, id: number) => {
    const registerData = store.getState().settings.changeData
      .data as IRegisterSettings;
    const currentSpecialTag = registerData.specialTags[id];
    const prevName = currentSpecialTag.name;
    const propagateObj = {
      name: prevName,
      posName: currentSpecialTag.posName,
    };
    const newObj = propagateString(newName, prevName, propagateObj, true);

    setValue<IRegisterSettings>(
      {
        specialTags: {
          ...registerData.specialTags,
          [id]: {
            ...registerData.specialTags[id],
            ...newObj,
          },
        },
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number) => {
    const specialTags = (
      store.getState().settings.changeData.data as IRegisterSettings
    )?.specialTags;

    const specialTagsCloned = cloneDeep(specialTags);
    delete specialTagsCloned[id];

    setValue<IRegisterSettings>(
      {
        specialTags: specialTagsCloned,
      },
      SECTION_ID,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: 'Status',
        cell: info => (
          <Switch
            key={`switch-specialTag-${info.row.original.id}`}
            checked={info.getValue()}
            onChange={active =>
              updateSpecialTag(active, info.row.original.id, 'active')
            }
          />
        ),
        size: 70,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => (
          <InputCell
            value={info.getValue()}
            disabled={!info.row.original.canDelete && !isMasterMode}
            required
            placeholder="Name"
            onChange={e =>
              updateName(e.currentTarget.value, info.row.original.id)
            }
            errorId={getSpecialTagId(`${info.row.original.id}`, 'name')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 220,
      }),
      columnHelper.accessor('posName', {
        header: 'POS Name',
        cell: info => (
          <InputCell
            required
            placeholder="POS Name"
            value={info.getValue()}
            onChange={e =>
              updateSpecialTag(
                e.currentTarget.value,
                info.row.original.id,
                'posName',
              )
            }
            errorId={getSpecialTagId(`${info.row.original.id}`, 'posName')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 220,
      }),
      columnHelper.accessor('abbreviation', {
        header: 'Abbreviation',
        cell: info => (
          <InputCell
            required
            placeholder="Abbreviation"
            value={info.getValue()}
            onChange={e =>
              updateSpecialTag(
                e.currentTarget.value,
                info.row.original.id,
                'abbreviation',
              )
            }
            errorId={getSpecialTagId(`${info.row.original.id}`, 'abbreviation')}
            onFocusError={(errorId, hasError) =>
              hasError && clearSettingsError(errorId)
            }
          />
        ),
        minSize: 200,
      }),
      columnHelper.accessor('color', {
        header: 'Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || DEFAULT_SPECIAL_TAG_COLOR}
            onChange={color =>
              updateSpecialTag(color, info.row.original.id, 'color')
            }
            onReset={() =>
              updateSpecialTag(null, info.row.original.id, 'color')
            }
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('textColor', {
        header: 'Text Color',
        cell: info => (
          <ColorPicker
            color={info.getValue() || DEFAULT_SPECIAL_TAG_TEXT_COLOR}
            onChange={color =>
              updateSpecialTag(color, info.row.original.id, 'textColor')
            }
            onReset={() =>
              updateSpecialTag(null, info.row.original.id, 'textColor')
            }
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('canDelete', {
        header: 'Delete',
        cell: info => (
          <Button
            variant="danger"
            csx={{ width: '50px' }}
            icon={<Icon name="MdDeleteForever" />}
            disabled={!info.getValue() && !isMasterMode}
            onClick={() => handleDelete(info.row.original.id)}
          />
        ),
        size: 70,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  const specialTagsArray = useMemo(
    () =>
      Object.keys(data || {}).map(key => ({
        id: Number(key),
        status: data?.[Number(key)]?.active,
        name: data?.[Number(key)]?.name,
        posName: data?.[Number(key)]?.posName,
        abbreviation: data?.[Number(key)]?.abbreviation,
        color: data?.[Number(key)]?.color || DEFAULT_SPECIAL_TAG_COLOR,
        textColor:
          data?.[Number(key)]?.textColor || DEFAULT_SPECIAL_TAG_TEXT_COLOR,
        canDelete: actualSpecialTags[Number(key)] ? false : true,
      })),
    [data, actualSpecialTags],
  );

  return (
    <div>
      <m.div
        style={{ backgroundColor, boxShadow }}
        css={{
          position: 'sticky',
          top: '80px',
          zIndex: 4,
        }}>
        <HeaderTitle
          title="Special Tags"
          headerCsx={{
            padding: '15px',
          }}
          options={
            <Button
              variant="primary"
              icon={<Icon name="MdAdd" />}
              onClick={onNewSpecialTag}>
              Add Special Tag
            </Button>
          }
        />
      </m.div>
      <Box
        csx={{
          padding: '15px',
        }}>
        <Table
          scrollEnabled
          nestedScrollEnabled
          data={specialTagsArray}
          columns={COLUMN_VALUES}
          align={{
            delete: 'center',
          }}
          alignHeaders={{
            delete: 'center',
          }}
          requiredColumns={['name', 'posName', 'abbreviation']}
          cellCsx={{ height: '70px' }}
          rowIdPrefix={ROW_ID_PREFIX}
          renderEmptyValues
        />
      </Box>
    </div>
  );
};

export default SpecialTags;
