import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Input from '@app/components/common/Input';
import TextAreaInput from '@app/components/common/TextAreaInput';
import useScrollToTop from '@app/hooks/useScrollToTop';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { useAppDispatch } from '@app/state/store';
import {
  FIELD_LABELS,
  ILockScreenSettingsStore,
  LockScreenBasicFieldLabels,
  LockScreenTabsFieldLabels,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import { actionCreatorsSettingsWeb } from '@app/state';
import { WithTranslation } from 'react-i18next';

const SECTION_ID = SettingsSectionId.LOCK_SCREEN;
const getMemoizedItemData =
  makeSelectSettingsData<ILockScreenSettingsStore>(SECTION_ID);

const LockScreenSettings = ({ t }: WithTranslation) => {
  useScrollToTop('layout-content');

  // Redux
  const dispatch = useAppDispatch();
  const loadSettingsBucketChangeData = bindActionCreators(
    actionCreatorsSettingsWeb.loadSettingsBucketChangeData,
    dispatch,
  );

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const { data } = useSelector(getMemoizedItemData);

  useEffect(() => {
    loadSettingsBucketChangeData({
      setting: 'lockScreen',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderTitle title={t('settingsModule.lockScreenSettings.title')} />
      <Accordion>
        <AccordionSection
          id="basic"
          expandedHeight="70px"
          isExpanded={true}
          title={t('settingsModule.lockScreenSettings.basic.title')}
          description={t(
            'settingsModule.lockScreenSettings.basic.description',
          )}>
          <Box>
            <DetailsSubtitle>
              {t('settingsModule.lockScreenSettings.basic.general.title')}
            </DetailsSubtitle>
            <Checkbox
              label={
                FIELD_LABELS[
                  LockScreenBasicFieldLabels.showGiftCardOptionsButton
                ]
              }
              checked={data?.showGiftCardOptionsButton}
              onChange={checked => {
                setValue(
                  {
                    showGiftCardOptionsButton: checked,
                  },
                  SECTION_ID,
                );
              }}
            />
          </Box>
          <Divider csx={{ marginTop: 15, marginBottom: 10 }} />
          <Box>
            <DetailsSubtitle>
              {t('settingsModule.lockScreenSettings.basic.announcement.title')}
            </DetailsSubtitle>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item>
                <Input
                  disabled={false}
                  label={t(
                    'settingsModule.lockScreenSettings.basic.announcement.titleInput.label',
                  )}
                  placeholder={t(
                    'settingsModule.lockScreenSettings.basic.announcement.titleInput.label',
                  )}
                  value={data?.announcementTitle || ''}
                  onChange={e => {
                    setValue(
                      {
                        announcementTitle: e.currentTarget.value,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item mb={12}>
                <Grid rowGap={15} columnGap={15}>
                  <Grid.Item mb={9} xl={11}>
                    <TextAreaInput
                      maxLength={1000}
                      value={data?.announcementDescription || ''}
                      onChange={e => {
                        setValue(
                          {
                            announcementDescription: e.currentTarget.value,
                          },
                          SECTION_ID,
                        );
                      }}
                      placeholder={t(
                        'settingsModule.lockScreenSettings.basic.announcement.descriptionInput.label',
                      )}
                      label={t(
                        'settingsModule.lockScreenSettings.basic.announcement.descriptionInput.label',
                      )}
                    />
                  </Grid.Item>
                  <Grid.Item
                    mb={3}
                    xl={1}
                    csx={{
                      flexDirection: 'row',
                      height: '100%',
                      alignItems: 'flex-end',
                    }}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setValue(
                          {
                            announcementDescription: '',
                          },
                          SECTION_ID,
                        );
                      }}
                      disabled={data?.announcementDescription === ''}
                      csx={{
                        height: '90px',
                      }}>
                      Clear
                    </Button>
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            </Grid>
          </Box>
        </AccordionSection>
        <AccordionSection
          id="tabs"
          expandedHeight="70px"
          isExpanded={true}
          title={t('settingsModule.lockScreenSettings.tabs.title')}
          description={t('settingsModule.lockScreenSettings.tabs.description')}>
          <Box>
            <DetailsSubtitle>
              {t('settingsModule.lockScreenSettings.tabs.title')}
            </DetailsSubtitle>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Checkbox
                  label={
                    FIELD_LABELS[LockScreenTabsFieldLabels.showFreshSheetTab]
                  }
                  checked={data?.showFreshSheetTab}
                  onChange={checked => {
                    setValue(
                      {
                        showFreshSheetTab: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Checkbox
                  label={
                    FIELD_LABELS[LockScreenTabsFieldLabels.showSeatingLayoutTab]
                  }
                  checked={data?.showSeatingLayoutTab}
                  onChange={checked => {
                    setValue(
                      {
                        showSeatingLayoutTab: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Checkbox
                  label={
                    FIELD_LABELS[LockScreenTabsFieldLabels.showEmployeesTab]
                  }
                  checked={data?.showEmployeesTab}
                  onChange={checked => {
                    setValue(
                      {
                        showEmployeesTab: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Checkbox
                  label={
                    FIELD_LABELS[LockScreenTabsFieldLabels.showLocationsTab]
                  }
                  checked={data?.showLocationsTab}
                  onChange={checked => {
                    setValue(
                      {
                        showLocationsTab: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
            </Grid>
          </Box>
        </AccordionSection>
      </Accordion>
    </>
  );
};

export default LockScreenSettings;
