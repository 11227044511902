import TTLogo from '@app/assets/logos/TTLogoWithText.svg?react';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import useUserOrganizations from '@app/hooks/useUserOrganizations';
import { actionCreatorsApp } from '@app/state';
import { selectOrganizationId } from '@app/state/selectors/appSelectors';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import NavBarButtons from './NavBarButtons/NavBarButtons';
import { navBarStyles } from './styles';
import ToggleSideBarButton from './ToggleSideBarButton';
import ButtonLink from '@app/components/common/Button/ButtonLink';

interface INavBar {
  onToggleSideBar?: (value: boolean) => void;
}

const NavBar = ({ onToggleSideBar }: INavBar) => {
  const checkForAllChangesAndNavigate = bindActionCreators(
    actionCreatorsApp.checkForAllChangesAndNavigate,
    useDispatch(),
  );

  const location = useLocation();

  const { organizations } = useUserOrganizations({
    checkUserVerified: false,
  });
  const orgId = useSelector(selectOrganizationId);

  const orgName = useMemo(() => {
    const org = organizations.find(_org => _org.id === orgId);
    return org?.name || '';
  }, [organizations, orgId]);

  return (
    <Box csx={navBarStyles}>
      <Box className="left">
        <ToggleSideBarButton
          onToggleSideBar={value => onToggleSideBar && onToggleSideBar(value)}
        />
        <ButtonLink
          to="/"
          variant="transparent"
          className="logo"
          csx={{ padding: '0' }}
          onClick={openInNewTab => {
            if (openInNewTab) return window.open('/');
            checkForAllChangesAndNavigate(() => {
              window.location.href = '/';
            });
          }}>
          <TTLogo />
        </ButtonLink>
      </Box>
      {location.pathname !== '/my-organizations' && orgName && (
        <Box className="orgName">
          <Typography
            variant="subtitle"
            color="white"
            fontWeight="medium"
            csx={{
              fontSize: '23px',
            }}>
            {orgName}
          </Typography>
        </Box>
      )}
      <Box className="right">
        <NavBarButtons />
      </Box>
    </Box>
  );
};

export default NavBar;
