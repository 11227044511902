import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const TAB_WIDTH = 150;
export const TAB_WIDTH_MOBILE = TAB_WIDTH - 20;
export const TABS_HEIGHT = 70;
export const TAB_SEPARATION = 5;
export const SCROLL_BUTTON_WIDTH = 42;

export const tabsStyles = (theme: Theme) =>
  css({
    height: `${TABS_HEIGHT}px`,
    overflowX: 'scroll',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '15px',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.colors.white,
    gap: `${TAB_SEPARATION}px`,
    position: 'relative',
    zIndex: 1,
    '::-webkit-scrollbar': {
      height: 0,
      background: 'transparent',
    },
  });

export const tabStyles = (theme: Theme) =>
  css({
    minWidth: `${TAB_WIDTH - 20}px`,
    maxWidth: `${TAB_WIDTH - 20}px`,
    borderWidth: '0',
    color: theme.colors.darkGrey,
    fontWeight: theme.fontWeights.medium,
    filter: 'none !important',
    svg: {
      rotate: '90deg',
      fill: theme.colors.darkGrey,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lighterBlue,
      color: theme.colors.persistentSemanticBlue,
      svg: {
        fill: theme.colors.persistentSemanticBlue,
      },
    },
    '&.active': {
      backgroundColor: theme.colors.persistentSemanticBlue,
      color: theme.colors.textWhite,
      svg: {
        fill: theme.colors.textWhite,
      },
    },
    '&.danger-active': {
      color: theme.colors.textWhite,
      backgroundColor: theme.colors.persistentSemanticRed,
      svg: {
        fill: theme.colors.textWhite,
      },
    },
    '&.danger': {
      color: theme.colors.darkRed,
      backgroundColor: theme.colors.lighterRed,
      svg: {
        fill: theme.colors.textWhite,
      },
    },
    '&[data-disabled="true"]': {
      cursor: 'grab',
      backgroundColor: theme.colors.lightestGrey,
      color: theme.colors.lightGrey,
    },
    [MQ_MIN_SMALL]: {
      minWidth: `${TAB_WIDTH}px`,
      maxWidth: `${TAB_WIDTH}px`,
    },
  });

export const subTabStyles = (theme: Theme) =>
  css({
    minWidth: `${TAB_WIDTH_MOBILE}px`,
    maxWidth: `${TAB_WIDTH_MOBILE}px`,
    borderWidth: '0',
    color: theme.colors.darkGrey,
    p: {
      color: theme.colors.darkGrey,
      fontWeight: theme.fontWeights.medium,
    },
    filter: 'none !important',
    svg: {
      rotate: '90deg',
      fill: theme.colors.darkGrey,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lighterBlue,
      color: theme.colors.persistentSemanticBlue,
      p: {
        color: theme.colors.persistentSemanticBlue,
      },
      svg: {
        fill: theme.colors.persistentSemanticBlue,
      },
    },
    '&.withSubTab': {
      '.icon': {
        paddingLeft: '0 !important',
      },
      color: theme.colors.darkGrey,
      '.headerContainer': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '.mainHeader': {
          fontSize: theme.fontSizes.caption,
          fontWeight: theme.fontWeights.regular,
        },
        '.subHeader': {
          overflow: 'hidden',
          width: `${TAB_WIDTH - 50}px`,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: theme.fontSizes.caption,
          fontWeight: theme.fontWeights.medium,
          textAlign: 'center',
          [MQ_MIN_SMALL]: {
            width: `${TAB_WIDTH - 40}px`,
          },
        },
      },
      ':hover': {
        color: theme.colors.persistentSemanticBlue,
      },
    },
    '&.active': {
      justifyContent: 'space-between',
      backgroundColor: theme.colors.persistentSemanticBlue,
      color: `${theme.colors.textWhite} !important`,
      p: {
        color: `${theme.colors.textWhite} !important`,
      },
      svg: {
        fill: theme.colors.textWhite,
      },
    },
    [MQ_MIN_SMALL]: {
      minWidth: `${TAB_WIDTH}px`,
      maxWidth: `${TAB_WIDTH}px`,
    },
    '&[data-disabled="true"]': {
      cursor: 'grab',
      backgroundColor: theme.colors.lightestGrey,
      p: {
        color: theme.colors.lightGrey,
      },
      svg: {
        fill: theme.colors.lightGrey,
      },
    },
  });

export const scrollButtonStyles = (theme: Theme) =>
  css({
    top: '0px',
    width: `${SCROLL_BUTTON_WIDTH}px`,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.blur': {
      position: 'absolute',
      height: '100%',
      width: '35px',
      backgroundColor: 'white',
      zIndex: 1,
    },
    '.arrowIcon': {
      display: 'flex',
      right: '10px',
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      justifyContent: 'center',
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[1],
      alignItems: 'center',
      cursor: 'pointer',
      borderWidth: '0',
      zIndex: 2,
      padding: '0',
      margin: '0',
    },
  });
