import { Theme, css } from '@emotion/react';

export const linkStyles = (theme: Theme) =>
  css({
    color: theme.colors.semanticBlue,
    ':hover': {
      color: theme.colors.lightBlue,
    },
    ':active': {
      color: theme.colors.semanticBlue,
    },
  });
