import en from '@app/assets/translations/en.json';
import es from '@app/assets/translations/es.json';
import { enTranslation, esTranslation } from '@westondev/tableturn-core';
import i18n from 'i18next';
import { merge } from 'lodash';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: merge(en, enTranslation),
  },
  es: {
    translation: merge(es, esTranslation),
  },
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

if (i18n.services.formatter) {
  i18n.services.formatter.add('DATE', (value, lng, { timeZone }) => {
    const dateFormat: DateTimeFormatOptions = {
      weekday: 'long',
      month: 'short',
      day: '2-digit',
      timeZone,
    };

    if (lng)
      return DateTime.fromJSDate(value)
        .setLocale(lng)
        .toLocaleString(dateFormat);
    else return DateTime.fromJSDate(value).toLocaleString(dateFormat);
  });
  i18n.services.formatter.add('WEEKDAY', (value, lng, { timeZone }) => {
    const dateFormat: DateTimeFormatOptions = {
      weekday: 'long',
      timeZone,
    };

    if (lng)
      return DateTime.fromJSDate(value)
        .setLocale(lng)
        .toLocaleString(dateFormat);
    else return DateTime.fromJSDate(value).toLocaleString(dateFormat);
  });

  i18n.services.formatter.add('DATE_HUGE', (value, lng) => {
    if (lng)
      return DateTime.fromJSDate(value)
        .setLocale(lng)
        .toLocaleString(DateTime.DATE_HUGE);
    else return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_HUGE);
  });

  i18n.services.formatter.add(
    'TIME',
    (value, lng, { timeZone, addSeconds }) => {
      const timeFormat: DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        ...(addSeconds ? { second: '2-digit' } : {}),
        timeZone,
      };

      let date = DateTime.fromJSDate(value);
      if (!date.isInDST) {
        date = date.plus({ hours: 1 });
      }
      if (lng) return date.setLocale(lng).toLocaleString(timeFormat);
      else return date.toLocaleString(timeFormat);
    },
  );
}

export default i18n;
