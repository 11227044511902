import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import {
  printersSelector,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import {
  getLicensesSettingsId,
  ILicenseSettingsStore,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DefaultPrinterModal from './DefaultPrinterModal';

interface IPrinterSettingsProps extends IAccordionSection {
  data: ILicenseSettingsStore;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
  licenseSettingsId: string | number;
  licenseId?: string | number;
  onShowPrintingRules: () => void;
}

const PrinterSettings = ({
  updateInfo,
  data,
  licenseSettingsId,
  licenseId,
  onShowPrintingRules,
  ...sectionProps
}: IPrinterSettingsProps) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;
  const [isModalActive, setIsModalActive] = useState(false);

  const settingsErrors = useSelector(settingsErrorsSelector);
  const printers = useSelector(printersSelector);

  const availablePrinters = useMemo(
    () => [
      {
        label: t(
          'settingsModule.licenseSettings.printerSettings.defaultCheckPrinter.noPrinter',
        ),
        value: 0,
        id: 0,
      },
      ...Object.values(printers).map(printer => ({
        label: printer.name,
        value: printer.id,
        id: printer.id,
      })),
    ],
    [printers, t],
  );

  const closeModal = () => {
    setIsModalActive(false);
  };

  return (
    <>
      <DefaultPrinterModal
        active={isModalActive}
        onCancel={closeModal}
        onSuccess={closeModal}
        updateInfo={updateInfo}
        availablePrinters={availablePrinters}
      />

      <AccordionSection
        isExpanded={true}
        isExpandable={false}
        expandedHeight="70px"
        title={t('settingsModule.licenseSettings.printerSettings.title')}
        description={t(
          'settingsModule.licenseSettings.printerSettings.description',
        )}
        {...otherProps}>
        <DetailsSubtitle>
          {t('settingsModule.licenseSettings.printerSettings.printing')}
        </DetailsSubtitle>
        <Grid columnGap={20} rowGap={20}>
          <Grid.Item sm={6} lg={4}>
            <Checkbox
              label={t(
                'settingsModule.licenseSettings.printerSettings.autoPrint',
              )}
              checked={data.autoPrintChit}
              onChange={autoPrint => updateInfo('autoPrintChit', autoPrint)}
            />
          </Grid.Item>
          <Grid.Item sm={6} lg={5}>
            <Checkbox
              label={t(
                'settingsModule.licenseSettings.printerSettings.printReceiptOnPayment',
              )}
              checked={data.printReceiptOnPayment}
              onChange={printReceiptOnPayment =>
                updateInfo('printReceiptOnPayment', printReceiptOnPayment)
              }
            />
          </Grid.Item>
          <Grid.Item sm={6} lg={4}>
            <Dropdown
              label={t(
                'settingsModule.licenseSettings.printerSettings.defaultCheckPrinter.description',
              )}
              placeholder={t('commonTexts.placeholderDropdown')}
              data={[availablePrinters]}
              value={
                data.defaultCheckPrinterId === null
                  ? 0
                  : data.defaultCheckPrinterId
              }
              onChange={() => null}
              error={Boolean(
                settingsErrors[getLicensesSettingsId('defaultCheckPrinter')],
              )}
              onActive={() => setIsModalActive(true)}
              isEditable={false}
            />
          </Grid.Item>
          {licenseSettingsId !== 0 && licenseId && (
            <Grid.Item sm={12}>
              <Card.SubCard title="Printer Rules">
                <Grid columnGap={20} rowGap={15}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography color="darkestGrey">
                      {t(
                        'settingsModule.licenseSettings.printerSettings.overrideDescription',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <Button variant="primary" onClick={onShowPrintingRules}>
                      {t(
                        'settingsModule.licenseSettings.printerSettings.viewEditPrintingRules',
                      )}
                    </Button>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
          )}
        </Grid>
      </AccordionSection>
    </>
  );
};

export default PrinterSettings;
