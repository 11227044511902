import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import { CAPTION_HEIGHT } from '@app/components/common/Input/styles';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import {
  checkExistingUser,
  generateUniqueUserPin,
} from '@app/helpers/settings/users';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsApp } from '@app/state';
import {
  setIsLoadingModal as setIsLoadingModalAction,
  showToast as showToastAction,
} from '@app/state/app/actions';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import {
  areSettingsMasterModeSelector,
  selectIsSettingsCreationMode,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import { useAppDispatch } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { IGenericError } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IUserObject,
  SettingsSectionId,
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  hiddenPhoneNumberMask,
  phoneNumberMask,
  settingsSelectors,
  userPinMask,
} from '@westondev/tableturn-core';
import { mask, unMask } from 'node-masker';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UserEmailModal from '../../UsersList/AddUserModal/UserEmailModal';
import { TUserCreationData } from '../../UsersList/AddUserModal/types';
import StatusBlock from '../StatusBlock';
import { EUserDetailsStatus } from '../StatusBlock/types';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.BASIC;
const getMemoizedItemData = makeSelectSettingsData<IUserObject>(SECTION_ID);

const BasicSettings = (accordionSectionProps: IAccordionSection) => {
  // Redux
  const dispatch = useAppDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );
  const { setShowToast } = bindActionCreators(actionCreatorsApp, dispatch);
  const setIsLoadingModal = bindActionCreators(
    setIsLoadingModalAction,
    dispatch,
  );
  const showToast = bindActionCreators(showToastAction, dispatch);

  const navData = useLocation().state?.userData as TUserCreationData;

  const isCreationMode = useSelector(selectIsSettingsCreationMode);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);
  const settingsErrors = useSelector(settingsErrorsSelector);

  const organizationUsers = useRootSelector(
    state => state.settings.master.users,
  );
  const usersInLocation = useRootSelector(
    state => state.settings.selectedMode.currentSettings.users,
  );

  const { data } = useSelector(getMemoizedItemData);

  // Local state
  const { t } = useTranslation();
  const [currentEmailAlreadyExists, setCurrentEmailAlreadyExists] =
    useState(false);
  const [userEmailModalState, setUserEmailModalState] = useState(false);
  const [hasPhoneNumberChanged, setHasPhoneNumberChanged] = useState(false);

  const hasFirstNameError =
    (settingsErrors?.firstName?.data as IGenericError)?.message || '';

  const hasLastNameError =
    (settingsErrors?.lastName?.data as IGenericError)?.message || '';

  const hasPhoneNumberError =
    (settingsErrors?.phoneNumber?.data as IGenericError)?.message || '';

  const hasPosNameError =
    (settingsErrors?.posName?.data as IGenericError)?.message || '';

  const haspayrollIdrror =
    (settingsErrors?.payrollId?.data as IGenericError)?.message || '';

  const hasPinError =
    (settingsErrors?.pin?.data as IGenericError)?.message || '';

  const hasAccountErrors = Boolean(
    hasFirstNameError || hasLastNameError || hasPhoneNumberError,
  );

  const hasOrganizationErrors = Boolean(hasPosNameError || hasPinError);

  const clearErrors = () => {
    clearSettingsError('firstName');
    clearSettingsError('lastName');
    clearSettingsError('posName');
    clearSettingsError('phoneNumber');
    clearSettingsError('pin');
  };

  const handleSearchUserOnCreation = async (userEmail: string) => {
    setUserEmailModalState(false);

    setIsLoadingModal({
      active: true,
      message: `${t('settingsModule.usersSettings.loader.text')} ${userEmail}`,
      icon: <Icon color="black" name="MdOutlineEmail" />,
    });
    const userData = await checkExistingUser(userEmail);

    clearErrors();
    setHasPhoneNumberChanged(false);

    if (!userData) {
      setIsLoadingModal({ active: false });
      setCurrentEmailAlreadyExists(false);

      setValue(
        {
          user: {
            email: userEmail,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            isUserVerifiedToTableTurn: false,
          },
        },
        SECTION_ID,
      );

      return;
    }

    setCurrentEmailAlreadyExists(true);

    const organizationUserData = organizationUsers[userData.organizationUserId];

    if (!organizationUserData) {
      setIsLoadingModal({ active: false });

      setValue(
        {
          user: {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
            isUserVerifiedToTableTurn: userData.isUserVerifiedToTableTurn,
          },
        },
        SECTION_ID,
      );

      return;
    }

    const isUserInCurrentLocation =
      userData.organizationUserId in usersInLocation;

    setIsLoadingModal({ active: false });

    if (isUserInCurrentLocation) {
      setUserEmailModalState(true);
      showToast({
        type: 'error',
        title: t(
          'settingsModule.usersSettings.emailErrorToast.emailToastErrorTitleLocation',
        ),
        description: t(
          'settingsModule.usersSettings.emailErrorToast.emailToastErrorDescription',
        ),
      });
    } else {
      setValue(
        {
          pin: organizationUserData.pin,
          posName: organizationUserData.posName,
          user: {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
            isUserVerifiedToTableTurn: userData.isUserVerifiedToTableTurn,
          },
        },
        SECTION_ID,
      );
    }
  };

  const handleSearchUserOnEdition = async (
    userEmail: string,
    setErrors:
      | React.Dispatch<
          React.SetStateAction<{
            email: string;
          }>
        >
      | undefined,
  ) => {
    setUserEmailModalState(false);

    setIsLoadingModal({
      active: true,
      message: `${t('settingsModule.usersSettings.loader.text')} ${userEmail}`,
      icon: <Icon name="MdOutlineEmail" />,
    });
    const userData = await checkExistingUser(userEmail);

    if (!userData) {
      setIsLoadingModal({ active: false });
      setCurrentEmailAlreadyExists(false);

      setValue(
        {
          ...data,
          user: {
            ...data.user,
            email: userEmail,
            isUserVerifiedToTableTurn: false,
          },
        },
        SECTION_ID,
      );

      return;
    }

    setCurrentEmailAlreadyExists(true);

    const organizationUserData = organizationUsers[userData.organizationUserId];
    const originalUserData = organizationUsers[data.organizationUserId];

    setIsLoadingModal({ active: false });

    if (!organizationUserData || userEmail === originalUserData.user.email) {
      setValue(
        {
          ...data,
          user: {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
            isUserVerifiedToTableTurn:
              userEmail === originalUserData.user.email
                ? organizationUserData.user.isUserVerifiedToTableTurn
                : userData.isUserVerifiedToTableTurn,
          },
        },
        SECTION_ID,
      );

      return;
    }

    setUserEmailModalState(true);
    showToast({
      type: 'error',
      title: t(
        'settingsModule.usersSettings.emailErrorToast.emailToastErrorTitleOrganization',
      ),
      description: t(
        'settingsModule.usersSettings.emailErrorToast.emailToastErrorDescription',
      ),
    });
    setErrors &&
      setErrors({
        email: t(
          'settingsModule.usersSettings.emailErrorToast.emailToastErrorDescription',
        ),
      });
  };

  const handleOnSearchUser = (
    userEmail: string,
    setErrors:
      | React.Dispatch<
          React.SetStateAction<{
            email: string;
          }>
        >
      | undefined,
  ) => {
    if (isCreationMode) handleSearchUserOnCreation(userEmail);
    else handleSearchUserOnEdition(userEmail, setErrors);
  };

  useEffect(() => {
    setCurrentEmailAlreadyExists(!!navData?.emailExists);
  }, [navData?.emailExists]);

  const activeSwitch = (
    <Switch
      label="Active"
      checked={data ? data.active : false}
      onChange={isActive => {
        setValue<IUserObject>({ active: isActive }, SECTION_ID);
      }}
      isDisabled={isMasterMode && hasMultipleLocations}
      info={
        isMasterMode
          ? t('settingsModule.usersSettings.activeSwitchInfo')
          : undefined
      }
    />
  );

  const isPhoneNumberCensored =
    data?.user.isUserVerifiedToTableTurn &&
    !data?.isUserVerifiedToOrg &&
    !hasPhoneNumberChanged &&
    data?.user.phoneNumber.length;

  return (
    <>
      <UserEmailModal
        title={
          isCreationMode
            ? t('settingsModule.userDetailsSettings.basic.changeEmailTitle')
            : t('settingsModule.userDetailsSettings.basic.changeAccountTitle')
        }
        subtitle={t('settingsModule.usersSettings.enterEmailAddress')}
        isActive={userEmailModalState}
        onClose={() => setUserEmailModalState(false)}
        onContinue={handleOnSearchUser}
      />
      <AccordionSection
        title={t('menuScreen.commonFields.basic.title')}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        titleTextContainerCsx={{ minWidth: '150px' }}
        hasError={hasAccountErrors || hasOrganizationErrors}
        optionsDisabled={isCreationMode || !isMasterMode}
        onOptionsClickDisabled={() =>
          setShowToast({
            type: 'info',
            title: t('commonTexts.alert'),
            description: isCreationMode
              ? t(
                  'settingsModule.userDetailsSettings.basic.optionsDisabled.creation',
                )
              : t(
                  'settingsModule.userDetailsSettings.basic.optionsDisabled.location',
                ),
          })
        }
        required
        {...accordionSectionProps}>
        {activeSwitch}
        <Divider csx={{ marginBlock: '20px' }} />
        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12}>
            <StatusBlock
              isCreation={isCreationMode}
              isUserStatus
              status={
                data.user.isUserVerifiedToTableTurn
                  ? EUserDetailsStatus.VERIFIED
                  : isCreationMode
                  ? currentEmailAlreadyExists
                    ? EUserDetailsStatus.UNVERIFIED
                    : EUserDetailsStatus.NEW
                  : EUserDetailsStatus.UNVERIFIED
              }
            />
          </Grid.Item>
          <Grid.Item mb={12}>
            <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'flex-end' }}>
              <Grid.Item
                mb={12}
                sm={6}
                lg={4}
                xl={3}
                csx={{
                  flexDirection: 'row',
                  gap: '15px',
                }}>
                <Box
                  csx={{
                    width: '100%',
                    marginBottom: hasAccountErrors
                      ? `${CAPTION_HEIGHT}px`
                      : '0px',
                  }}>
                  <Typography>
                    {t('commonTexts.email')}{' '}
                    <span css={{ color: 'red' }}>*</span>
                  </Typography>
                  <Typography color="darkGrey" csx={{ marginBlock: '9px' }}>
                    {data?.user?.email || ''}
                  </Typography>
                  <Button
                    onClick={() => setUserEmailModalState(true)}
                    variant="primary"
                    icon={
                      <Icon
                        name={isCreationMode ? 'MdOutlineEmail' : 'MdGroups'}
                      />
                    }>
                    {isCreationMode
                      ? t(
                          'settingsModule.userDetailsSettings.basic.changeEmailBtn',
                        )
                      : t(
                          'settingsModule.userDetailsSettings.basic.changeUserBtn',
                        )}
                  </Button>
                </Box>
                <Divider direction="vertical" data-hide-on-mobile />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Input
                  required
                  label={t('authentication.signUp.accountInfo.firstName')}
                  placeholder={t('authentication.signUp.accountInfo.firstName')}
                  disabled={data?.user?.isUserVerifiedToTableTurn}
                  value={data?.user?.firstName || ''}
                  onChange={firstName =>
                    setValue<IUserObject>(
                      {
                        user: {
                          ...data.user,
                          firstName: firstName.currentTarget.value,
                        },
                      },
                      SECTION_ID,
                    )
                  }
                  error={!!hasFirstNameError}
                  caption={hasFirstNameError}
                  showCaptionSpace={hasAccountErrors}
                  onFocus={() =>
                    hasFirstNameError && clearSettingsError('firstName')
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Input
                  required
                  label={t('authentication.signUp.accountInfo.lastName')}
                  placeholder={t('authentication.signUp.accountInfo.lastName')}
                  disabled={data.user.isUserVerifiedToTableTurn}
                  value={data?.user?.lastName || ''}
                  onChange={lastName =>
                    setValue<IUserObject>(
                      {
                        user: {
                          ...data.user,
                          lastName: lastName.currentTarget.value,
                        },
                      },
                      SECTION_ID,
                    )
                  }
                  error={!!hasLastNameError}
                  caption={hasLastNameError}
                  showCaptionSpace={hasAccountErrors}
                  onFocus={() =>
                    hasLastNameError && clearSettingsError('lastName')
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} lg={4} xl={3}>
                <Input
                  required
                  label={t('commonTexts.phone')}
                  placeholder="Phone"
                  disabled={data.user.isUserVerifiedToTableTurn}
                  value={
                    data
                      ? !isPhoneNumberCensored
                        ? mask(data?.user.phoneNumber, phoneNumberMask)
                        : mask(
                            `xxxxxxxx${data?.user.phoneNumber.slice(-2)}`,
                            hiddenPhoneNumberMask,
                          )
                      : ''
                  }
                  onChange={phoneNumber => {
                    if (!hasPhoneNumberChanged) setHasPhoneNumberChanged(true);
                    let unmaskedPhoneNumber = unMask(
                      phoneNumber.currentTarget.value,
                    );
                    unmaskedPhoneNumber =
                      unmaskedPhoneNumber.length > 10
                        ? unmaskedPhoneNumber.slice(0, -1)
                        : unmaskedPhoneNumber;
                    setValue<IUserObject>(
                      {
                        user: {
                          ...data.user,
                          phoneNumber: unmaskedPhoneNumber,
                        },
                      },
                      SECTION_ID,
                    );
                  }}
                  error={!!hasPhoneNumberError}
                  caption={hasPhoneNumberError}
                  showCaptionSpace={hasAccountErrors}
                  onFocus={() =>
                    hasPhoneNumberError && clearSettingsError('phoneNumber')
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item mb={12}>
            <Divider />
          </Grid.Item>
          <Grid.Item mb={12}>
            <StatusBlock
              status={
                data.isUserVerifiedToOrg
                  ? EUserDetailsStatus.VERIFIED
                  : EUserDetailsStatus.UNVERIFIED
              }
              hideResendButton={!data.user.isUserVerifiedToTableTurn}
            />
          </Grid.Item>
          <Grid.Item mb={12}>
            <Grid columnGap={15} rowGap={15}>
              <Grid.Item mb={12} md={6} lg={4} xl={3}>
                <Input
                  required
                  label={t('menuScreen.itemDetails.basic.posName')}
                  placeholder={t('menuScreen.itemDetails.basic.posName')}
                  value={data?.posName || ''}
                  onChange={posName =>
                    setValue<IUserObject>(
                      {
                        posName: posName.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  error={!!hasPosNameError}
                  caption={hasPosNameError}
                  onFocus={() =>
                    hasPosNameError && clearSettingsError('posName')
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} md={6} lg={4} xl={3}>
                <Input
                  required
                  label={t('settingsModule.userDetailsSettings.basic.pin')}
                  placeholder={t(
                    'settingsModule.userDetailsSettings.basic.pin',
                  )}
                  info={t(
                    'settingsModule.userDetailsSettings.basic.pintoolTip',
                  )}
                  value={data ? mask(data?.pin, userPinMask) : ''}
                  onChange={pin => {
                    setValue<IUserObject>(
                      {
                        pin:
                          pin.currentTarget.value.length > 4
                            ? pin.currentTarget.value.slice(0, -1)
                            : pin.currentTarget.value,
                      },
                      SECTION_ID,
                    );
                  }}
                  error={!!hasPinError}
                  caption={hasPinError}
                  onFocus={() => hasPinError && clearSettingsError('pin')}
                />
              </Grid.Item>
              <Grid.Item
                mb={6}
                md={6}
                lg={4}
                xl={3}
                csx={[
                  displayFlexEndColumnStyles,
                  {
                    marginBottom: hasOrganizationErrors
                      ? `${CAPTION_HEIGHT}px`
                      : '0px',
                  },
                ]}>
                <Button
                  variant="primary"
                  icon={<Icon name="FaUndo" size="18px" />}
                  onClick={() => {
                    const pin = generateUniqueUserPin();
                    setValue({ pin }, SECTION_ID);
                  }}>
                  Generate New PIN
                </Button>
              </Grid.Item>
              <Grid.Item
                mb={6}
                md={6}
                lg={4}
                xl={3}
                csx={[
                  displayFlexEndColumnStyles,
                  {
                    marginBottom: hasOrganizationErrors
                      ? `${CAPTION_HEIGHT}px`
                      : '0px',
                  },
                ]}>
                <Button variant="primary" icon={<Icon name="MdCreditCard" />}>
                  Link Card
                </Button>
              </Grid.Item>
            </Grid>
            <Grid
              columnGap={15}
              rowGap={15}
              csx={{
                marginTop: '20px',
              }}>
              <Grid.Item mb={12} md={6} lg={4} xl={3}>
                <Input
                  label={t('menuScreen.itemDetails.basic.payrollId')}
                  placeholder={t('menuScreen.itemDetails.basic.payrollId')}
                  value={data?.payrollId || ''}
                  onChange={payrollId =>
                    setValue<IUserObject>(
                      {
                        payrollId: payrollId.currentTarget.value,
                      },
                      SECTION_ID,
                    )
                  }
                  error={!!haspayrollIdrror}
                  caption={haspayrollIdrror}
                  onFocus={() =>
                    haspayrollIdrror && clearSettingsError('payrollId')
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </AccordionSection>
    </>
  );
};

export default BasicSettings;
