import BlueDot from '@app/components/common/BlueDot';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import { EItemTypes } from '@app/components/common/Card/ItemButton/types';
import CardsContainer from '@app/components/common/CardsContainer';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import MoneyInput from '@app/components/common/MoneyInput';
import NoElementsFound from '@app/components/common/NoElementsFound';
import Typography from '@app/components/common/Typography';
import { useAppDispatch } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  actionCreatorsMenu,
  FormMode,
  getAdditionalAmountPrice,
  getIncludedAmountPrice,
  handleDecrementModifierItem,
  handleIncrementModifierItemQuantity,
  handleSetDefaultSelected,
  IItem,
  IMassUpdate,
  IModifierGroupModifierItem,
  IModifierGroupsItems,
  IModifierGroupsItemsOverride,
  IModifierItemOfModifierGroup,
  QuantityModes,
  ReturnTypes,
  SectionId,
  TPriceCalcModes,
  TQuantityModeTypes,
  useModifierItemPriceCalc,
} from '@westondev/tableturn-core';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import ShowAllPricing from '../ShowAllPricing';
import { quantityModeConversion } from './types';
import Icon from '@app/components/common/Icon';

interface IContent extends WithTranslation {
  defaultModifierGroupPricingVersionId: number | null;
  modifierGroupPricingVersions: IDropdownItem[][];
  menuTypeVersionId: number;
  modifierGroupId: number;
  quantityMode: TQuantityModeTypes;
  modifierItems: {
    [key: number]: IModifierGroupModifierItem;
  };
  multiselect: boolean;
  includedModifierItems: number;
  setHiddenModifierItemsCount: (id: number) => void;
  priceCalcMode: TPriceCalcModes;
  defaultSelectionPrice: number;
  hidePriceIfFree: boolean;
  override: IModifierGroupsItemsOverride;
  mode?: FormMode;
}

const sectionId = SectionId.MODIFIER_GROUPS;

const Content = ({
  t,
  defaultModifierGroupPricingVersionId,
  modifierGroupPricingVersions,
  menuTypeVersionId,
  modifierGroupId,
  quantityMode,
  modifierItems,
  multiselect,
  includedModifierItems,
  setHiddenModifierItemsCount,
  priceCalcMode,
  defaultSelectionPrice,
  override,
  mode,
}: IContent) => {
  // Redux
  const appDispatch = useAppDispatch();

  const {
    updateItem: setValue,
    changePricingVersion,
    updateModPrice,
    getDataFromChangeData,
  } = bindActionCreators(actionCreatorsMenu, appDispatch);

  const [showAllPricing, setShowAllPricing] = React.useState(false);
  const [editMaximums, setEditMaximums] = React.useState(false);

  const theme = useTheme();

  const {
    selectedModifierItemElements,
    includedModifierItemsCount,
    additionalModifierItemsCount,
    totalPrice,
    totalDifferenceObj,
  } = useModifierItemPriceCalc(
    modifierItems,
    includedModifierItems,
    quantityMode,
    priceCalcMode,
  );

  const handleChangePricingVersion = (
    modifierGroupPricingVersionId: number,
  ) => {
    changePricingVersion(
      modifierGroupId,
      modifierGroupPricingVersionId,
      sectionId,
      menuTypeVersionId,
    );
  };
  const handleHideModifierItem = (
    isSwitchActive: boolean,
    modifierItemId: number,
  ) => {
    setHiddenModifierItemsCount(isSwitchActive ? 1 : -1);
    setValue(
      { active: isSwitchActive },
      sectionId,
      mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
      modifierGroupId,
      modifierItemId,
    );
  };

  useEffect(() => {
    if (defaultSelectionPrice !== totalPrice) {
      updateModPrice(modifierGroupId, sectionId, totalPrice, menuTypeVersionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, modifierGroupId, menuTypeVersionId]);

  const modifierItemsOrdered = useMemo(() => {
    const formattedModifierItems = Object.values(modifierItems).map(
      modifierItem => ({
        ...modifierItem,
        title: modifierItem.name,
        isSelected: modifierItem.isSelected,
      }),
    );
    return orderBy(
      formattedModifierItems,
      modifierItem => modifierItem.sortPriority,
    );
  }, [modifierItems]);

  const onChange = (modifierItemId: number, isSelected: boolean) => {
    const item = getDataFromChangeData() as unknown as IItem | IMassUpdate;
    const data = handleSetDefaultSelected(modifierItemId, isSelected, {
      multiselect,
      modifierItems:
        mode === FormMode.MASS_UPDATE
          ? ((item as IMassUpdate).modifierGroups[modifierGroupId]
              ?.modifierItems as unknown as {
              [key: number]: IModifierItemOfModifierGroup;
            })
          : ((item as IItem).menuTypeVersions[menuTypeVersionId]
              ?.modifierGroups[modifierGroupId]?.modifierItems as unknown as {
              [key: number]: IModifierItemOfModifierGroup;
            }),
      quantityMode,
    });

    if (data?.type === ReturnTypes.modifierItemData) {
      setValue(
        data.data as IModifierGroupModifierItem,
        sectionId,
        mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
        modifierGroupId,
        modifierItemId,
      );
    } else if (data?.type === ReturnTypes.modifierGroupData) {
      setValue(
        data.data as unknown as IModifierGroupsItems,
        sectionId,
        mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
        modifierGroupId,
      );
    }
  };

  const onIncrement = (modifierItemId: number) => {
    const item = getDataFromChangeData() as unknown as IItem | IMassUpdate;
    setValue(
      handleIncrementModifierItemQuantity(Number(modifierItemId), {
        multiselect,
        modifierItems:
          mode === FormMode.MASS_UPDATE
            ? ((item as IMassUpdate).modifierGroups[modifierGroupId]
                ?.modifierItems as unknown as {
                [key: number]: IModifierItemOfModifierGroup;
              })
            : ((item as IItem).menuTypeVersions[menuTypeVersionId]
                ?.modifierGroups[modifierGroupId]?.modifierItems as unknown as {
                [key: number]: IModifierItemOfModifierGroup;
              }),
        quantityMode,
      }).data,
      sectionId,
      mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
      modifierGroupId,
      modifierItemId,
    );
  };

  const handleOnMaximumQuantityChangeModifierItem = (
    newMaximum: number,
    modifierItemId: number,
  ) => {
    setValue(
      { maximumQuantity: newMaximum },
      sectionId,
      mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
      modifierGroupId,
      modifierItemId,
    );
  };

  const onDecrement = (modifierItemId: number) => {
    const item = getDataFromChangeData() as unknown as IItem | IMassUpdate;
    setValue(
      handleDecrementModifierItem(Number(modifierItemId), {
        multiselect,
        modifierItems:
          mode === FormMode.MASS_UPDATE
            ? ((item as IMassUpdate).modifierGroups[modifierGroupId]
                ?.modifierItems as unknown as {
                [key: number]: IModifierItemOfModifierGroup;
              })
            : ((item as IItem).menuTypeVersions[menuTypeVersionId]
                ?.modifierGroups[modifierGroupId]?.modifierItems as unknown as {
                [key: number]: IModifierItemOfModifierGroup;
              }),
        quantityMode,
      }).data,
      sectionId,
      mode === FormMode.MASS_UPDATE ? 1 : menuTypeVersionId,
      modifierGroupId,
      modifierItemId,
    );
  };

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        csx={{
          display: 'flex',
          paddingHorizontal: '10px',
          paddingBottom: '15px',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Checkbox
          checked={showAllPricing}
          label={t(
            'menuScreen.itemDetails.modifierGroupsSection.showAllPricing',
          )}
          onChange={setShowAllPricing}
        />

        <Divider
          direction="vertical"
          csx={{ height: '40px', margin: '0 15px' }}
        />

        <Checkbox
          checked={editMaximums}
          label={t(
            'menuScreen.modifierGroupDetails.associations.modifierGroups.editMaximums',
          )}
          onChange={setEditMaximums}
        />

        <Divider
          direction="vertical"
          csx={{ height: '40px', margin: '0 15px' }}
        />

        <Box
          csx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {override.defaultModifierGroupPricingVersionId && <BlueDot />}
          <Typography
            csx={{
              marginRight: '10px',
              marginLeft: '5px',
            }}>
            {t('menuScreen.itemDetails.modifierGroupsSection.pricingVersion')}
          </Typography>
          <Dropdown
            value={defaultModifierGroupPricingVersionId || 0}
            data={modifierGroupPricingVersions}
            placeholder="Select version"
            onChange={handleChangePricingVersion}
          />
        </Box>
      </Box>
      <Box csx={{ flexGrow: 1 }}>
        {modifierItemsOrdered.length > 0 ? (
          <Box>
            <CardsContainer
              data={modifierItemsOrdered}
              csx={{ padding: 0, borderWidth: 0 }}
              renderItem={modifierItem => (
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}>
                  <Card.ItemButton
                    {...modifierItem}
                    key={`modifier_${modifierGroupId}_${modifierItem.modifierItemId}`}
                    itemId={modifierItem.modifierItemId}
                    switchValue={modifierItem.active}
                    onSwitchChange={isActive =>
                      handleHideModifierItem(
                        isActive,
                        modifierItem.modifierItemId,
                      )
                    }
                    isSelected={modifierItem.isSelected}
                    totalPrice={
                      (includedModifierItemsCount[modifierItem.modifierItemId]
                        ?.count ?? 0) *
                        (quantityMode === QuantityModes.NUMBER
                          ? modifierItem.includedMasterPrice
                          : getIncludedAmountPrice(modifierItem)) +
                      (additionalModifierItemsCount[modifierItem.modifierItemId]
                        ?.count ?? 0) *
                        (quantityMode === QuantityModes.NUMBER
                          ? modifierItem.additionalMasterPrice
                          : getAdditionalAmountPrice(modifierItem))
                    }
                    nextPrice={totalDifferenceObj[modifierItem.modifierItemId]}
                    isMultipleQuantity={quantityMode === QuantityModes.NUMBER}
                    isQuantityOff={quantityMode === QuantityModes.OFF}
                    itemType={
                      quantityMode === QuantityModes.SIZE
                        ? EItemTypes.SIZE
                        : EItemTypes.QUANTITY
                    }
                    onIncrement={id => onIncrement(Number(id))}
                    onDecrement={id => onDecrement(Number(id))}
                    selectedSize={
                      quantityModeConversion[modifierItem.defaultAmountSelected]
                    }
                    selectedQuantity={
                      selectedModifierItemElements.modifierItemsWithHidden[
                        modifierItem.modifierItemId
                      ]?.count ?? 0
                    }
                    onChange={isActive => {
                      modifierItem.active
                        ? onChange(modifierItem.modifierItemId, isActive)
                        : handleHideModifierItem(
                            true,
                            modifierItem.modifierItemId,
                          );
                    }}
                    title={
                      <>
                        {modifierItem.override?.isSelected && (
                          <BlueDot
                            customColor={
                              modifierItem.isSelected ? theme.colors.white : ''
                            }
                          />
                        )}{' '}
                        {modifierItem.name}
                      </>
                    }
                    isDisabled={!modifierItem.active}
                    quantityOverride={
                      modifierItem.override?.defaultAmountSelected ||
                      modifierItem.override?.defaultQuantitySelected
                    }
                    showSwitch
                    isHidden={!modifierItem.inheritedActive}
                    renderHiddenIfIsActive
                    hidePrice
                  />
                  {showAllPricing && (
                    <ShowAllPricing
                      modifier={modifierItem}
                      menuTypeVersionId={menuTypeVersionId}
                      modifierGroupId={modifierGroupId}
                      isMultipleQuantity={
                        quantityMode === QuantityModes.NUMBER ? true : false
                      }
                      isSizeItem={
                        quantityMode === QuantityModes.SIZE ? true : false
                      }
                      isMultiselect={multiselect}
                    />
                  )}
                  {editMaximums && (
                    <Box
                      csx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <Box
                        csx={{
                          backgroundColor: theme.colors.lightestGrey,
                          padding: '5px',
                          marginBottom: '5px',
                          flexDirection: 'row',
                        }}>
                        {modifierItem.override?.maximumQuantity && <BlueDot />}
                        <Typography align="center">
                          {t(
                            'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.maximumQuantity',
                          )}
                        </Typography>
                      </Box>
                      <MoneyInput
                        value={modifierItem.maximumQuantity}
                        showPlaceholder
                        placeholder={t(
                          'menuScreen.modifierGroupDetails.basic.noMax',
                        )}
                        prefix=""
                        precision={0}
                        onValueChange={value =>
                          handleOnMaximumQuantityChangeModifierItem(
                            value,
                            modifierItem.modifierItemId,
                          )
                        }
                        maxValue={99}
                      />
                    </Box>
                  )}
                </Box>
              )}
            />
          </Box>
        ) : (
          <NoElementsFound
            text={t(
              'menuScreen.itemDetails.modifierGroupsSection.noModifierItemsAssignedToThisGroup',
            )}
            icon={<Icon size="40px" name="MdSubtitlesOff" color="textBlack" />}
            textProps={{
              color: 'black',
              variant: 'subtitle',
              fontWeight: 'medium',
            }}
            csx={{ height: '100%' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Content;
