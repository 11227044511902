import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import { IItemCard } from '@app/components/common/Card/ItemCard/ItemCard';
import Icon from '@app/components/common/Icon';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import TaxesSelectionModal from '@app/components/common/SelectionModal/TaxesSelectionModal';
import Typography from '@app/components/common/Typography';
import {
  makeSelectItemData,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  SectionId,
  actionCreatorsMenu,
  formatTax,
} from '@westondev/tableturn-core';
import without from 'lodash/without';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AssignedElements from '../../../AssignedElements';

const SECTION_ID = SectionId.BASIC;

type ITaxesSection = IAccordionSection &
  WithTranslation & {
    mode?: 'menuTypes' | 'categories' | 'subcategories';
  };

const TaxesSection = ({
  t,
  title,
  mode = 'menuTypes',
  ...props
}: ITaxesSection) => {
  const getMemoizedItemData = useMemo(
    () => makeSelectItemData(mode, SECTION_ID),
    [mode],
  );

  const {
    updateMenuType: setMenuTypeValue,
    updateCategory: setCategoryValue,
    updateSubcategory: setSubcategoryValue,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);

  const { itemData } = useSelector(getMemoizedItemData);
  const settingsTaxes = useSelector(currentTaxesSettingsSelector);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const hasMultipleLocations = useSelector(selectShouldRenderLocationDropdown);

  const menuTypeTaxes = useMemo(() => {
    return itemData?.taxIds || [];
  }, [itemData]);

  const assignedTaxes: IItemCard[] = useMemo(() => {
    const cards = (menuTypeTaxes || []).map(id => {
      const settingsTax = settingsTaxes[id];

      let description = formatTax(settingsTax.taxType, settingsTax.typeData);

      description =
        description === 'N/A'
          ? t('settingsModule.taxesSettings.taxesTable.taxTypes.table')
          : description;

      return {
        id: id,
        title: settingsTaxes[id].name,
        isActive: true,
        subTitle: description,
      };
    });

    return cards;
  }, [menuTypeTaxes, settingsTaxes, t]);

  let sectionTitle = '';
  let emptyElementMessage = '';
  let description = '';
  let info = '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let setValue = (_: number[]) => {
    return null as unknown;
  };

  switch (mode) {
    case 'menuTypes':
      sectionTitle = t('menuScreen.menuTypeDetails.taxes.title');
      emptyElementMessage = t(
        'menuScreen.menuTypeDetails.taxes.emptyCardsMessage',
      );
      description = t('menuScreen.menuTypeDetails.taxes.menuTypeTaxes');
      setValue = (taxIds: number[]) => setMenuTypeValue({ taxIds });
      break;
    case 'categories':
      sectionTitle = t('menuScreen.categoryDetails.taxesSection.title');
      emptyElementMessage = t(
        'menuScreen.categoryDetails.taxesSection.emptyCardsMessage',
      );
      description = t('menuScreen.categoryDetails.taxesSection.categoryTaxes');
      setValue = (taxIds: number[]) => setCategoryValue({ taxIds });
      info = t('menuScreen.categoryDetails.taxesSection.toolTip');
      break;
    case 'subcategories':
      sectionTitle = t('menuScreen.subcategoryDetails.taxesSection.title');
      emptyElementMessage = t(
        'menuScreen.subcategoryDetails.taxesSection.emptyCardsMessage',
      );
      description = t(
        'menuScreen.subcategoryDetails.taxesSection.subcategoryTaxes',
      );
      info = t('menuScreen.subcategoryDetails.taxesSection.toolTip');
      setValue = (taxIds: number[]) => setSubcategoryValue({ taxIds });
      break;
  }

  const onDisassociate = (taxId: number | string) => {
    const newMenuTypeTaxes = without(menuTypeTaxes, Number(taxId));

    setValue(newMenuTypeTaxes);
  };

  const closeModal = () => {
    setIsTaxesModalOpen(false);
  };

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));

    const newTaxes = [...menuTypeTaxes, ...newButtonIds];

    setValue(newTaxes);
    closeModal();
  };

  return (
    <>
      <TaxesSelectionModal
        onAssociate={handleOnAssociate}
        active={isTaxesModalOpen}
        onModalClose={closeModal}
        taxIds={menuTypeTaxes}
      />
      <AccordionSection
        title={title || t('menuScreen.categoryDetails.taxesSection.title')}
        info={info}
        description={description}
        disabledLayer={
          isMasterMode && hasMultipleLocations ? (
            <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
              <Icon size="30px" name="MdSubtitlesOff" color="textBlack" />
              <Typography variant="subtitle">
                {t('commonTexts.noLocationMode')}
              </Typography>
            </Box>
          ) : undefined
        }
        {...props}>
        <AssignedElements
          active={isTaxesModalOpen}
          noElementsMessage={emptyElementMessage}
          onDisassociate={onDisassociate}
          elements={assignedTaxes}
          subCardProps={{
            title: sectionTitle,
            actionOptions: [
              {
                text: t('components.actionButtons.addExistingTax'),
                icon: false,
                handler: () => setIsTaxesModalOpen(true),
              },
            ],
          }}
          hideSortButton
        />
      </AccordionSection>
    </>
  );
};

export default TaxesSection;
