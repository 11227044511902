import ActionButtons from '@app/components/common/ActionButtons';
import { IActionOption } from '@app/components/common/ActionButtons/types';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import DropdownDots from '@app/components/common/DropdownDots';
import Icon from '@app/components/common/Icon';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import { TCsx } from '@emotion/react';
import { useAnimate } from 'framer-motion';
import { useEffect, useState } from 'react';
import { EAccordionSectionOptionType } from '../types';
import { sectionHeaderStyles } from './styles';

const upperCase = (str: string) => str.toUpperCase();

interface ISectionHeader {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  optionType?: EAccordionSectionOptionType;
  options?: IActionOption[];
  customOptions?: any;
  titleProps?: any;
  expandedTitleContainerProps?: any;
  collapsedTitleContainerProps?: any;
  titleContainerProps?: any;
  isExpanded?: boolean;
  isExpandable?: boolean;
  expandedHeight?: string;
  onToggleExpanded?: () => void;
  collapsedHeaderComponent?: any;
  info?: string;
  required?: boolean;
  optionsDisabled?: boolean;
  isTitleUpperCase?: boolean;
  onOptionsClickDisabled?: () => void;
  hasError?: boolean;
  titleTextContainerCsx?: TCsx;
  showCollapsedHeaderComponent?: boolean;
  isButtonDisabled?: boolean;
  isEditButtonDisabled?: boolean;
  onEditAction?: () => void;
  onEditClickDisabled?: () => void;
}
const SectionHeader = ({
  title,
  description,
  titleProps,
  titleContainerProps,
  isExpanded = true,
  isExpandable = true,
  expandedHeight: _expandedHeight,
  onToggleExpanded,
  required = false,
  isTitleUpperCase,
  hasError = false,
  titleTextContainerCsx,
  isButtonDisabled = false,
  collapsedHeaderComponent,
  optionType,
  options,
  optionsDisabled,
  onOptionsClickDisabled,
  isEditButtonDisabled,
  onEditAction,
  onEditClickDisabled,
  info,
}: ISectionHeader) => {
  const [scope, animate] = useAnimate();
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  if (!info && typeof title === 'string') {
    info = title;
  } else if (!info) {
    info = 'TOOLTIP_INFO';
  }

  useEffect(() => {
    animate(
      '.arrowIcon',
      {
        rotate: isExpanded ? '-90deg' : '90deg',
      },
      { duration: 0.2 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const handleToggleOnClick = () => {
    onToggleExpanded && onToggleExpanded();
  };

  const expandedHeight = _expandedHeight ?? '70px';

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <>
      <Box
        ref={scope}
        csx={[
          sectionHeaderStyles(hasError),
          {
            minHeight: isExpanded ? expandedHeight : '50px',
          },
          { opacity: isButtonDisabled ? 0.5 : 1 },
        ]}>
        <Box
          csx={{
            display: 'flex',
          }}>
          {isExpandable ? (
            <Button
              className="arrowButton"
              onClick={handleToggleOnClick}
              data-active={isExpanded}
              disabled={isButtonDisabled}>
              <Box className="arrowIcon" csx={{ display: 'flex' }}>
                <Icon name="MdChevronRight" color="darkestGrey" />
              </Box>
            </Button>
          ) : (
            <Box className="arrowIcon" csx={{ width: '10px' }} />
          )}
        </Box>
        <Box
          csx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Box
            csx={{
              display: 'flex',
              paddingLeft: '10px',
              flexWrap: 'wrap',
              alignItems: 'center',
              paddingBlock: '10px',
            }}
            {...titleContainerProps}>
            <Box csx={titleTextContainerCsx}>
              {(title || info) && (
                <Box
                  onMouseOver={handlePopUp}
                  onMouseOut={() => {
                    if (newTimeout) clearTimeout(newTimeout);
                    setShowHover(false);
                  }}
                  csx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  {info && (
                    <div className="infoButton">
                      <ToolTip
                        showHover={showHover}
                        content={info}
                        tooltipCsx={{ maxWidth: '250px' }}>
                        <div />
                      </ToolTip>
                    </div>
                  )}
                  {title && typeof title === 'string' && (
                    <Typography
                      className="sectionHeaderTitle"
                      fontWeight="regular"
                      {...titleProps}>
                      {isTitleUpperCase ? upperCase(title) : title}
                    </Typography>
                  )}
                  {title && typeof title !== 'string' && (
                    <Typography
                      className="sectionHeaderTitle"
                      fontWeight="regular"
                      {...titleProps}>
                      {title}
                    </Typography>
                  )}
                  {required && (
                    <Typography
                      csx={{
                        marginLeft: '8px',
                      }}
                      fontWeight="bold"
                      color="red">
                      *
                    </Typography>
                  )}
                </Box>
              )}
              {description && typeof description === 'string' && isExpanded && (
                <Typography
                  variant="caption"
                  color="darkestGrey"
                  csx={{ marginTop: '3px' }}>
                  {description}
                  {required && !title && (
                    <Typography variant="caption" fontWeight="bold" color="red">
                      {' '}
                      *
                    </Typography>
                  )}
                </Typography>
              )}
              {description && typeof description !== 'string' && isExpanded && (
                <>{description}</>
              )}
            </Box>
            {collapsedHeaderComponent &&
              !isExpanded &&
              collapsedHeaderComponent}
          </Box>
          <Box csx={{ display: 'flex', alignItems: 'center' }}>
            {options && optionType === EAccordionSectionOptionType.ELLIPSIS && (
              <DropdownDots
                isDisabled={optionsDisabled}
                options={options}
                onClickDisabled={onOptionsClickDisabled}
              />
            )}
            {options &&
              optionType === EAccordionSectionOptionType.ACTION_BUTTON &&
              isExpanded && (
                <Box csx={{ padding: '10px' }}>
                  <ActionButtons
                    optionList={options}
                    disabled={optionsDisabled}
                    onClickDisabled={onOptionsClickDisabled}
                    isEditButtonDisabled={isEditButtonDisabled}
                    onEditAction={onEditAction}
                    onEditClickDisabled={onEditClickDisabled}
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionHeader;
