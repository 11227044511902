import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import useItemChangeColor from '@app/hooks/useItemChangeColor';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectChangeDataId,
  selectFinalDiffObject,
  selectNumberOfMeuTypeVersions,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IAvailabilityType,
  IMenuTypeToSelect,
  ISpecial,
  ISpecialDiffObject,
  SPECIAL_INITIAL_STATE,
  TChangeItems,
  TSpecialMenuTypeVersionDiff,
  actionCreatorsMenu,
  formatMoney,
} from '@westondev/tableturn-core';
import some from 'lodash/some';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import MenuTypeVersionsButtons from '../../common/MenuTypeVersionsButtons';
import TextAreaChange from '../../common/TextAreaChange';
import AvailabilityTableDiff from './AvailabilityTableDiff';
import SelectMenuTypeToApplyChangesModal from './SelectMenuTypeToApplyChangesModal';
import { specialsDiffModalStyles } from './styles';

const SpecialsDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const { setDiffObject } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const applySpecialChangesToMenuTypesWeb = bindActionCreators(
    actionCreatorsMenuWeb.applySpecialChangesToMenuTypesWeb,
    useDispatch(),
  );

  const finalDiffObject = useSelector(selectFinalDiffObject);
  const specialId = useSelector(selectChangeDataId, shallowEqual);
  const originalData = useRootSelector(
    state =>
      state.menu.selectedMode.currentMenu.specials[specialId] ||
      SPECIAL_INITIAL_STATE,
  ) as ISpecial;
  const numberOfMeuTypeVersions = useSelector(selectNumberOfMeuTypeVersions);

  const formattedDifferences = finalDiffObject as ISpecialDiffObject;

  // local state
  const [selectedMenuTypeVersion, setSelectedMenuTypeVersion] = useState(0);
  const [applyChangesModalActive, setApplyChangesModalActive] = useState(false);
  const [appliedChanges, setAppliedChanges] = useState<{
    currentMenuType: number;
    menuTypesToApplyChanges: IMenuTypeToSelect[];
  } | null>(null);
  const { borderContainerColor } = useItemChangeColor();

  const selectedMenuTypeVersionId = useMemo(
    () =>
      formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]?.id || -1,
    [selectedMenuTypeVersion, formattedDifferences],
  );

  useEffect(() => {
    if (formattedDifferences && appliedChanges) {
      applySpecialChangesToMenuTypesWeb(
        appliedChanges,
        ({ newMenuTypeVersions }) => {
          const newFinalDiffObject = {
            ...formattedDifferences,
            menuTypeVersions: newMenuTypeVersions,
          };
          setDiffObject(newFinalDiffObject);
          setAppliedChanges(null);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedChanges]);

  const renderMenuTypeVersionsButtons = (
    menuTypeVersions: TSpecialMenuTypeVersionDiff[],
  ) => {
    return (
      <MenuTypeVersionsButtons
        menuTypeVersions={menuTypeVersions}
        selectedMenuTypeVersion={selectedMenuTypeVersion}
        onPress={setSelectedMenuTypeVersion}
      />
    );
  };

  const renderAvailabilityTable = (times: IAvailabilityType) => {
    return (
      <AvailabilityTableDiff
        availabilityRules={times}
        formattedDifferences={
          formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
            .sections.availability
        }
        originalData={originalData.menuTypeVersions[selectedMenuTypeVersionId]}
      />
    );
  };

  const formatValue = (value: any, field: string, textValue?: string) => {
    if (['specialApplyTo', 'discountType', 'discountAmount'].includes(field)) {
      return textValue;
    } else if (typeof value === 'number') {
      // if value is number, format the number depending on the field
      if (['thresholdAmount'].includes(field)) {
        return formatMoney(null, value);
      } else return value.toString();
    } else if (typeof value === 'string') {
      if (field === 'customizerShape') {
        return textValue;
      } else return value;
    } else return _formatValue(value, field);
  };

  const renderChange = (change: TChangeItems) => {
    return <RenderChange formatValue={formatValue} change={change} />;
  };
  const handleApplyChanges = (menuTypes: IMenuTypeToSelect[]) => {
    setAppliedChanges({
      currentMenuType: selectedMenuTypeVersion,
      menuTypesToApplyChanges: menuTypes,
    });
  };

  return (
    <>
      <SelectMenuTypeToApplyChangesModal
        isActive={applyChangesModalActive}
        setModalActive={() => setApplyChangesModalActive(false)}
        onApplyChanges={handleApplyChanges}
        menuTypeId={
          formattedDifferences &&
          formattedDifferences.menuTypeVersions.length > 0
            ? formattedDifferences.menuTypeVersions[selectedMenuTypeVersion].id
            : 0
        }
      />

      {formattedDifferences && (
        <>
          {formattedDifferences.masterChanges.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.commonChanges')}
                color="black"
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.masterChanges
                  .filter(change => change.field !== 'locationIds')
                  .map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`master_change_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
                <Grid.Item>
                  {formattedDifferences.masterChanges
                    .filter(change => change.field === 'locationIds')
                    .map(change => (
                      <Fragment key={`master_change_location_${change.field}}`}>
                        {renderChange(change)}
                      </Fragment>
                    ))}
                </Grid.Item>
              </Grid>
            </>
          )}
        </>
      )}
      {formattedDifferences &&
        formattedDifferences.menuTypeVersions.length > 0 && (
          <>
            <HeaderDiffModal
              name={t('app.modals.diffModal.sectionTitles.changesByMenuTypes')}
              color="black"
            />
            <Typography>
              {t('app.modals.combosDiff.menuType.description')}
            </Typography>

            {renderMenuTypeVersionsButtons(
              formattedDifferences.menuTypeVersions,
            )}
            <Box
              csx={[
                specialsDiffModalStyles,
                {
                  backgroundColor: 'white',
                  border: `1px solid ${borderContainerColor}`,
                },
              ]}>
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .action === 'remove' ? (
                <Typography align="center" color="persistentSemanticRed">
                  {t('app.modals.itemsDiff.menuType.menuTypeRemoved')}
                </Typography>
              ) : (
                <Box>
                  <Button
                    variant="primary"
                    onClick={() => setApplyChangesModalActive(true)}
                    disabled={numberOfMeuTypeVersions === 1}>
                    {t(
                      'app.modals.itemsDiff.menuType.applyChangesToOtherMenuTypes.title',
                    )}
                  </Button>
                </Box>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.basic.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.basic')}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.basic.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`basic_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.moreInfo.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.diffModal.sectionTitles.moreInformation',
                    )}
                  />
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.moreInfo.map((change, index) => (
                    <Fragment key={`change-${index}`}>
                      {change.value ? (
                        <TextAreaChange change={change} />
                      ) : (
                        renderChange(change)
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {some(
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.availability,
                days => days.length > 0,
              ) && (
                <Box>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.specialsDiff.sectionTitles.availability',
                    )}
                  />
                  {renderAvailabilityTable(
                    formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.availability,
                  )}
                </Box>
              )}
              {formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.specialsScheme.length > 0 && (
                <>
                  <HeaderDiffModal
                    name={t(
                      'app.modals.specialsDiff.sectionTitles.specialsScheme',
                    )}
                  />
                  <Grid columnGap={20} rowGap={10}>
                    {formattedDifferences.menuTypeVersions[
                      selectedMenuTypeVersion
                    ].sections.specialsScheme.map(change => (
                      <Grid.Item
                        mb={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`specialsScheme_change_${change.field}`}>
                        {renderChange(change)}
                      </Grid.Item>
                    ))}
                  </Grid>
                </>
              )}
              {(formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                .sections.associations.buyItems.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.associations.getItems.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.associations.buyCombos.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.associations.getCombos.length > 0 ||
                formattedDifferences.menuTypeVersions[selectedMenuTypeVersion]
                  .sections.associations.getItemsChanges.length > 0) && (
                <>
                  <HeaderDiffModal
                    name={t('app.modals.diffModal.sectionTitles.associations')}
                  />
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.associations.buyItems.length > 0 &&
                    renderChange({
                      field: 'buyItemIds',
                      label:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.buyItems[0].label,
                      value:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.buyItems,
                    })}
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.associations.buyCombos.length > 0 &&
                    renderChange({
                      field: 'buyComboIds',
                      label:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.buyCombos[0].label,
                      value:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.buyCombos,
                    })}

                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.associations.getItemsChanges.length > 0 && (
                    <Grid columnGap={20} rowGap={10}>
                      {formattedDifferences.menuTypeVersions[
                        selectedMenuTypeVersion
                      ].sections.associations.getItemsChanges.map(change => (
                        <Grid.Item
                          mb={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={`getItemsChanges_${change.field}`}>
                          {renderChange(change)}
                        </Grid.Item>
                      ))}
                    </Grid>
                  )}
                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.associations.getItems.length > 0 &&
                    renderChange({
                      field: 'getItemIds',
                      label: t(
                        'app.modals.specialsDiff.getItemsFields.getItemIds.title.bogo.items',
                      ),
                      value:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.getItems,
                    })}

                  {formattedDifferences.menuTypeVersions[
                    selectedMenuTypeVersion
                  ].sections.associations.getCombos.length > 0 &&
                    renderChange({
                      field: 'getCombos',
                      label: t(
                        'app.modals.specialsDiff.getItemsFields.getItemIds.title.bogo.combos',
                      ),
                      value:
                        formattedDifferences.menuTypeVersions[
                          selectedMenuTypeVersion
                        ].sections.associations.getCombos,
                    })}
                </>
              )}
            </Box>
          </>
        )}
    </>
  );
};

export default SpecialsDiffModal;
