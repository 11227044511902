import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Slider from '@app/components/common/Slider';
import Switch from '@app/components/common/Switch';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import DeliveryAvailabilityTable from './DeliveryAvailabilityTable';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import {
  IOnlineOrderingStore,
  SettingsSectionId,
  actionCreatorsSettings,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { WithTranslation } from 'react-i18next';

interface IDeliverySettings extends WithTranslation, IAccordionSection {}

const SECTION_ID_ALL = SettingsSectionId.ALL;
const SECTION_ID = SettingsSectionId.DELIVERY;
const getMemoizedItemData =
  makeSelectSettingsData<IOnlineOrderingStore>(SECTION_ID);

const DeliverySettings = ({
  t,
  ...accordionSectionProps
}: IDeliverySettings) => {
  // Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const activeSwitch = (
    <Switch
      label="Offer Delivery"
      checked={data.deliveryIsActive}
      onChange={deliveryIsActive => {
        if (!deliveryIsActive && !data.pickupIsActive) {
          return setValue(
            { deliveryIsActive, acceptingOrders: false },
            SECTION_ID_ALL,
          );
        }
        setValue({ deliveryIsActive }, SECTION_ID_ALL);
      }}
    />
  );

  return (
    <AccordionSection
      title={t('settingsModule.onlineSettings.delivery.title')}
      titleTextContainerCsx={{ minWidth: '170px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...accordionSectionProps}>
      <Grid rowGap={15}>
        <Grid.Item>{activeSwitch}</Grid.Item>
        <Grid.Item>
          <Divider />
        </Grid.Item>
        <Grid.Item md={4}>
          <MoneyInput
            value={data.deliveryBufferTime}
            label={t(
              'settingsModule.onlineSettings.delivery.deliveryBufferTime',
            )}
            prefix=""
            suffix=" min"
            precision={0}
            onValueChange={(deliveryBufferTime: number) => {
              setValue({ deliveryBufferTime }, SECTION_ID);
            }}
            info="The time it takes to prepare an order for delivery"
          />
        </Grid.Item>

        <Grid.Item>
          <DeliveryAvailabilityTable data={data} />
        </Grid.Item>
        <Grid.Item>
          <DetailsSubtitle csx={{ marginBottom: '40px' }}>
            {t('settingsModule.onlineSettings.delivery.deliveryRadius')}
          </DetailsSubtitle>
          <Slider
            value={data.deliveryRadius}
            step={1}
            max={100}
            showStepMarks={false}
            onValueChange={deliveryRadius =>
              setValue({ deliveryRadius }, SECTION_ID)
            }
            valueLabelDisplay="always"
            customValueLabel={_value => ` ${_value} ${'mi'} `}
          />
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default DeliverySettings;
