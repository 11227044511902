import {
  selectLocations,
  selectMasterSpecials,
  selectMenu,
} from '@app/state/menu/menuSelectors';
import { selectLocationId } from '@app/state/selectors/appSelectors';
import { useTheme } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ILocationGroupStore,
  ISpecial,
  SPECIAL_INITIAL_STATE,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import ItemCard from '../../Card/ItemCard';
import { IItemCard } from '../../Card/ItemCard/ItemCard';
import Dropdown from '../../Dropdown';
import Grid from '../../Grid';
import Icon from '../../Icon';
import NoElementsFound from '../../NoElementsFound';
import StatusCardsContainer from '../../StatusCardsContainer';
import { EStatusCardsContainer } from '../../StatusCardsContainer/types';
import Table from '../../Table';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';

interface IDetailsAddress {
  address1: string | undefined | null;
  state: string | undefined | null;
  city: string | undefined | null;
}

const valueStatus: Record<number, 'all' | EStatusCardsContainer> = {
  0: 'all',
  1: EStatusCardsContainer.ACTIVE,
  2: EStatusCardsContainer.INACTIVE,
};

type ISelectionMenuTypeCard = IItemCard & {
  groupStatus: EStatusCardsContainer;
  id: number;
};

const SpecialsSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  itemMenuTypeTemplate,
  allowAssociateMultiple = true,
  showDescriptionPanel = true,
  btnSuccessText,
  showSelectedButtons,
  itemMultipleMenuTypeTemplateDescription,
  showSelectAll = true,
  ...others
}: ICustomSelectionModal<unknown>) => {
  const theme = useTheme();
  const [selectedSpecial, setSelectedSpecial] = useState<ISpecial>(
    SPECIAL_INITIAL_STATE,
  );
  const [selectedButton, setSelectedButton] = useState<IItemSelection>();
  const [locations, setLocations] = useState<IDetailsAddress[]>([]);
  const [activeStatus, setActiveStatus] = useState(1);
  const [menuTypes, setMenuTypes] = useState<ISelectionMenuTypeCard[]>([]);
  const [selectedMenuTypes, setSelectedMenuTypes] = useState<number[]>([]);
  const [block, setBlock] = useState(false);
  const [selectedMenuTypeDescription, setSelectedMenuTypeDescription] =
    useState('');

  //redux
  const specials = useSelector(selectMasterSpecials);
  const locationGroups = useSelector(selectLocations);
  const currentLocationId = useSelector(selectLocationId);
  const currentMenu = useSelector(selectMenu);

  useEffect(() => {
    if (block) {
      return;
    }
    const firstMenuType = menuTypes
      .filter(el =>
        valueStatus[activeStatus] === 'inactive'
          ? el.groupStatus === EStatusCardsContainer.INACTIVE
          : el.groupStatus === EStatusCardsContainer.ACTIVE,
      )
      .shift();
    if (firstMenuType?.id) {
      setSelectedMenuTypes([firstMenuType?.id]);
      setSelectedMenuTypeDescription((firstMenuType?.title as string) || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatus, menuTypes]);

  const columnHelper = createColumnHelper<IDetailsAddress>();

  const COLUMN_VALUES = [
    columnHelper.accessor('state', {
      header: 'State',
      cell: info => info.getValue(),
      size: 70,
    }),
    columnHelper.accessor('city', {
      header: 'City',
      cell: info => info.getValue(),
      size: 130,
    }),
    columnHelper.accessor('address1', {
      header: 'Address',
      cell: info => info.getValue(),
    }),
  ];

  const handleOnPress = (button: IItemSelection, isSelected: boolean) => {
    setSelectedButton(button);
    const _special = specials[Number(button.id)] || SPECIAL_INITIAL_STATE;
    setSelectedSpecial(_special);

    let _locations: IDetailsAddress[] = [];

    Object.values(locationGroups).forEach(
      (locationGroup: ILocationGroupStore) => {
        const currentLocations =
          locationGroup.locations
            .filter(
              location =>
                _special?.locationIds?.includes(location.id) &&
                location.id !== currentLocationId,
            )
            .map(location => ({
              address1: location?.streetLine1,
              state: location?.state,
              city: location?.city,
            })) || [];

        _locations = [..._locations, ...currentLocations];
      },
    );

    const currenSpecial = currentMenu.specials[Number(button.id)];

    const _menuTypes: ISelectionMenuTypeCard[] = Object.keys(
      currenSpecial ? currenSpecial.menuTypeVersions : {},
    ).map((menuTypeId): ISelectionMenuTypeCard => {
      const currentMenuType = currentMenu.menuTypes[Number(menuTypeId)];
      return {
        id: Number(menuTypeId),
        title: currentMenuType?.name,
        isActive: currenSpecial.menuTypeVersions[Number(menuTypeId)]?.active,
        groupStatus: currentMenuType?.active
          ? EStatusCardsContainer.ACTIVE
          : EStatusCardsContainer.INACTIVE,
        onClick: () => {
          setSelectedMenuTypes([Number(menuTypeId)]);
          setSelectedMenuTypeDescription(currentMenuType?.name);
        },
      };
    });

    // first menu type inside the item
    const firstMenuType =
      _menuTypes.find(el => el.isActive) || _menuTypes.find(el => !el.isActive);

    setLocations(_locations);
    setBlock(Boolean(button.menuTypeId));
    setSelectedMenuTypes(
      button.menuTypeId || (firstMenuType?.id ? [firstMenuType?.id] : []),
    );
    setSelectedMenuTypeDescription(
      button.description ||
        (firstMenuType ? currentMenu.menuTypes[firstMenuType?.id].name : ''),
    );
    setMenuTypes(_menuTypes);
    if (!isSelected) {
      setActiveStatus(1);
      return;
    }

    const groupStatus = _menuTypes.find(
      element => button.menuTypeId?.includes(element.id),
    )?.groupStatus;
    if (groupStatus) {
      setActiveStatus(1);
    } else {
      setActiveStatus(2);
    }
  };

  const getNoElementsMessage = () => {
    switch (activeStatus) {
      case 0: //All
        return t('loggedIn.menuModule.itemDetails.menuTypes.emptyCardsMessage');
      case 1: //Active
        return t(
          'loggedIn.menuModule.itemDetails.menuTypes.emptyActiveCardsMessage',
        );
      case 2: //Inactive
        return t(
          'loggedIn.menuModule.itemDetails.menuTypes.emptyInactiveCardsMessage',
        );
    }
  };

  const handleDropdownChange = (value: number) => {
    setActiveStatus(value);
    setBlock(false);
  };

  return (
    <GenericSelectionModal
      {...others}
      onPress={handleOnPress}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      currentButtonInfo={
        itemMenuTypeTemplate
          ? {
              id: selectedButton?.id || 0,
              description: selectedMenuTypeDescription,
              menuTypeId: selectedMenuTypes,
            }
          : undefined
      }
      title={t('menuScreen.selectionModal.specials.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.specials.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.specials.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.specials.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.specials.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      btnSuccessText={btnSuccessText}
      showSelectedButtons={showSelectedButtons}
      showSelectAll={showSelectAll}>
      {({ onSelect, isButtonSelected }) => (
        <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Card.SubCard>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography
                  color="persistentSemanticBlue"
                  fontWeight="medium"
                  csx={{ marginBottom: '10px' }}>
                  {selectedSpecial.name}
                </Typography>

                <Typography color="darkestGrey">
                  ID: {selectedSpecial?.specialId}
                </Typography>
              </Box>
              {allowAssociateMultiple && (
                <Box csx={{ display: 'flex', flexDirection: 'row' }}>
                  {isButtonSelected ? (
                    <Button
                      icon={<Icon name="FaTrashAlt" />}
                      variant="danger"
                      csx={{ paddingRight: '20px', paddingLeft: '20px' }}
                      onClick={() =>
                        onSelect(
                          itemMenuTypeTemplate && selectedMenuTypes
                            ? {
                                menuTypeId: selectedMenuTypes,
                                description: selectedMenuTypeDescription,
                              }
                            : {},
                        )
                      }
                    />
                  ) : (
                    <Button
                      onClick={() =>
                        onSelect(
                          itemMenuTypeTemplate && selectedMenuTypes
                            ? {
                                menuTypeId: selectedMenuTypes,
                                description: selectedMenuTypeDescription,
                              }
                            : {},
                        )
                      }
                      csx={{ paddingRight: '20px', paddingLeft: '20px' }}>
                      {t('menuScreen.selectionModal.selectButton')}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Card.SubCard>
          {itemMenuTypeTemplate && (
            <Card.SubCard
              title={t('loggedIn.menuModule.tabs.menuTypes')}
              description={
                itemMultipleMenuTypeTemplateDescription ||
                t('menuScreen.selectionModal.items.menuTypes.description')
              }
              required>
              <Box>
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingVertical: '5px',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}>
                  <Box
                    csx={{
                      width: '100%',
                      backgroundColor: theme.colors.lightestExtraGrey,
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}>
                    <Grid columnGap={10}>
                      <Grid.Item
                        mb={4}
                        sm={6}
                        md={8}
                        csx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}>
                        <Typography variant="caption" fontWeight="bold">
                          {t('menuScreen.selectionModal.items.menuTypes.label')}
                        </Typography>
                      </Grid.Item>
                      <Grid.Item
                        mb={8}
                        sm={6}
                        md={4}
                        csx={{
                          paddingRight: '20px',
                        }}>
                        <Dropdown
                          data={[
                            [{ label: 'All', value: 0 }],
                            [
                              { label: t('commonTexts.active'), value: 1 },
                              { label: t('commonTexts.inactive'), value: 2 },
                            ],
                          ]}
                          onChange={status => handleDropdownChange(status)}
                          value={activeStatus}
                        />
                      </Grid.Item>
                    </Grid>
                  </Box>
                </Box>
                <StatusCardsContainer
                  data={menuTypes}
                  csx={{
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  }}
                  contentCsx={{
                    gridTemplateColumns:
                      'repeat(auto-fill, minmax(100px, 1fr))',
                  }}
                  selectedStatus={valueStatus[activeStatus]}
                  activeText={t(
                    'menuScreen.selectionModal.items.menuTypes.status.active',
                  )}
                  inactiveText={t(
                    'menuScreen.selectionModal.items.menuTypes.status.inactive',
                  )}
                  noElementsMessage={getNoElementsMessage()}
                  renderItem={menuType => (
                    <ItemCard
                      {...menuType}
                      isActive={undefined}
                      csx={{ height: '50px !important' }}
                      isSelected={
                        !menuType.id
                          ? false
                          : selectedMenuTypes?.includes(menuType.id)
                      }
                    />
                  )}
                />
              </Box>
            </Card.SubCard>
          )}
          <Box csx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Card.SubCard>
              <Box
                csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Typography>
                  {t(
                    'loggedIn.menuModule.itemDetails.itemLocation.locations.sectionTitle',
                  )}
                </Typography>

                {locations.length === 0 ? (
                  <NoElementsFound
                    csx={{
                      backgroundColor: 'background',
                      flexGrow: 1,
                    }}
                    text={t(
                      'loggedIn.menuModule.itemDetails.itemLocation.locations.emptyCardsMessage',
                    )}
                    icon={<Icon name="MdLocationOff" color="lightGrey" />}
                  />
                ) : (
                  <Table columns={COLUMN_VALUES} data={locations} />
                )}
              </Box>
            </Card.SubCard>
          </Box>
        </Box>
      )}
    </GenericSelectionModal>
  );
};

export default SpecialsSelectionModal;
