import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { getTimeFromUTC } from '@app/helpers/time/time';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  currentPrepStationsSelector,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  IItemPrepStationDiff,
  IOpenItemTypeDiff,
  IPrinterRulesSettings,
  OrderType,
  abbreviateDays,
  diffPrinterRulesFactory,
  orderTypeText,
} from '@westondev/tableturn-core';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderTable from '../../common/HeaderTable';
import RenderChange from '../common/RenderChange';

const PrintingRulesDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(settingsDifferencesSelector);
  const serviceAreas = useRootSelector(
    state => state.tableLayout.savedData.serviceAreas,
  );
  const changeData = useRootSelector(
    state => state.settings.changeData.data,
  ) as IPrinterRulesSettings;
  const originalData = useRootSelector(
    state => state.settings.changeData.originalData,
  ) as IPrinterRulesSettings;
  const prepStations = useSelector(currentPrepStationsSelector);

  const formattedDifferences = useMemo(() => {
    if (!differences) return null;
    const diffObject = diffPrinterRulesFactory(differences);
    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      [
        'defaultMenuTypeId',
        'qsrSendAndPayActionButtons',
        'defaultRestaurantMode',
        'coursingMode',
        'seatingMode',
      ].includes(field)
    ) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderOpenItemTypesTable = (openItemTypes: IItemPrepStationDiff[]) => {
    const columnHelper = createColumnHelper<IItemPrepStationDiff>();

    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'name',
        header: t('settingsModule.printerRulesSettings.printerRulesTable.name'),
        meta: {
          getCellContext: (
            context: CellContext<IOpenItemTypeDiff, unknown>,
          ) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'name');
          return (
            <RenderChange
              change={{
                field: 'name',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].name
                    : changeData[rowData.id].name,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'serviceArea',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.serviceArea',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'serviceAreaId',
          );
          return (
            <RenderChange
              change={{
                field: 'serviceArea',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].serviceAreaId !== null
                      ? serviceAreas[
                          originalData[rowData.id].serviceAreaId as number
                        ].name
                      : t('commonTexts.all')
                    : changeData[rowData.id].serviceAreaId !== null
                    ? serviceAreas[
                        changeData[rowData.id].serviceAreaId as number
                      ].name
                    : t('commonTexts.all'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'orderType',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.orderType',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'orderType',
          );
          return (
            <RenderChange
              change={{
                field: 'orderType',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].orderType !== null
                      ? orderTypeText(
                          t,
                          originalData[rowData.id].orderType as OrderType,
                        )
                      : t('commonTexts.all')
                    : changeData[rowData.id].orderType !== null
                    ? orderTypeText(
                        t,
                        changeData[rowData.id].orderType as OrderType,
                      )
                    : t('commonTexts.all'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'daysOfWeek',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.daysOfWeek',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'daysOfWeek',
          );
          return (
            <RenderChange
              change={{
                field: 'daysOfWeek',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? abbreviateDays(t, originalData[rowData.id].daysOfWeek)
                    : abbreviateDays(t, changeData[rowData.id].daysOfWeek),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'startTime',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.startTime',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'startTime',
          );
          return (
            <RenderChange
              change={{
                field: 'startTime',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].startTime !== null
                      ? getTimeFromUTC(
                          originalData[rowData.id].startTime as string,
                          false,
                          false,
                          DateTime.TIME_SIMPLE,
                        )
                      : 'No Time Selected'
                    : changeData[rowData.id].startTime !== null
                    ? getTimeFromUTC(
                        changeData[rowData.id].startTime as string,
                        false,
                        false,
                        DateTime.TIME_SIMPLE,
                      )
                    : 'No Time Selected',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'endTime',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.endTime',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'endTime',
          );
          return (
            <RenderChange
              change={{
                field: 'endTime',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].endTime !== null
                      ? getTimeFromUTC(
                          originalData[rowData.id].endTime as string,
                          false,
                          false,
                          DateTime.TIME_SIMPLE,
                        )
                      : 'No Time Selected'
                    : changeData[rowData.id].endTime !== null
                    ? getTimeFromUTC(
                        changeData[rowData.id].endTime as string,
                        false,
                        false,
                        DateTime.TIME_SIMPLE,
                      )
                    : 'No Time Selected',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'prepStation',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.fromStation',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'fromPrepStationId',
          );
          return (
            <RenderChange
              change={{
                field: 'prepStation',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].fromPrepStationId !== null
                      ? prepStations[
                          originalData[rowData.id].fromPrepStationId as number
                        ]?.name
                      : t('commonTexts.all')
                    : changeData[rowData.id].fromPrepStationId !== null
                    ? prepStations[
                        changeData[rowData.id].fromPrepStationId as number
                      ]?.name
                    : t('commonTexts.all'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'toPrepStation',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.toStation',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'toPrepStationId',
          );
          return (
            <RenderChange
              change={{
                field: 'toPrepStation',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].toPrepStationId !== null
                      ? prepStations[
                          originalData[rowData.id].toPrepStationId as number
                        ]?.name
                      : t('commonTexts.all')
                    : changeData[rowData.id].toPrepStationId !== null
                    ? prepStations[
                        changeData[rowData.id].toPrepStationId as number
                      ]?.name
                    : t('commonTexts.all'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'order',
        header: t(
          'settingsModule.printerRulesSettings.printerRulesTable.order',
        ),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged = rowData.changes.some(
            change => change.field === 'order',
          );
          return (
            <RenderChange
              change={{
                field: 'order',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData[rowData.id].order
                    : changeData[rowData.id].order,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];

    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <HeaderTable title={t('settingsModule.printerRulesSettings.title')} />
        <Table
          data={openItemTypes}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            name: 'center',
            serviceArea: 'center',
            orderType: 'center',
            daysOfWeek: 'center',
            startTime: 'center',
            endTime: 'center',
            prepStation: 'center',
            toPrepStation: 'center',
            order: 'center'
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      {formattedDifferences &&
        formattedDifferences.length > 0 &&
        renderOpenItemTypesTable(formattedDifferences)}
    </Box>
  );
};

export default PrintingRulesDiffModal;
