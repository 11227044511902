import { IItemTableRow } from '@westondev/tableturn-core';

export const getIPairArray = (
  input: IItemTableRow['categoriesSubcategories'],
  maxRows = 3,
) => {
  const isNull = input === null;

  const array = input || [];
  const isOverflow = array.length > maxRows - 1;
  const arrayToRender = isOverflow ? array.slice(0, maxRows) : array;

  return { isNull, isOverflow, arrayToRender, array };
};
