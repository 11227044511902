import { Theme, useTheme } from '@emotion/react';
import { IIcon } from '../../Icon';

const SeatIcon = ({ size, color = 'black' }: IIcon) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      fill={theme.colors[color as keyof Theme['colors']] || color}
      id="seatIcon"
      x="0px"
      y="0px"
      viewBox="0 0 481 481">
      <g>
        <path d="M349,237V18c0-9.925-8.075-18-18-18H150c-9.925,0-18,8.075-18,18v219H349z" />
        <path d="M381,300v-25c0-9.925-8.075-18-18-18H118c-9.925,0-18,8.075-18,18v25c0,9.925,8.075,18,18,18h245 C372.925,318,381,309.925,381,300z" />
        <path d="M125,338v133c0,5.523,4.477,10,10,10s10-4.477,10-10V338H125z" />
        <path d="M336,338v133c0,5.523,4.478,10,10,10s10-4.477,10-10V338H336z" />
      </g>
    </svg>
  );
};

export default SeatIcon;
