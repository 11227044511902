import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Modal from '@app/components/common/Modal/Modal';

import { actionCreatorsApp } from '@app/state';
import { selectLocations } from '@app/state/menu/menuSelectors';
import { massPriceUpdateWeb } from '@app/state/menu/menuWebActions';

import { RootState, useAppDispatch } from '@app/state/store';
import {
  ILocationGroupItemPrices,
  IPricingLocationGroupRow,
  IPricingLocationRow,
  SortOptions,
  filter,
  useSort,
} from '@westondev/tableturn-core';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import itemPricingModalReducer from './ItemPricingModalReducer';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import Typography from '@app/components/common/Typography';
import { BASE_TRANSLATION, COMBO_BASE_TRANSLATION } from './types';
import Icon from '@app/components/common/Icon';
import SearchInput from '@app/components/common/SearchInput';
import SortButton from '@app/components/common/SortButton';
import PricingLocationGroupRow from './PricingLocationGroupRow';
import useRootSelector from '@app/hooks/useRootSelector';
import MassPriceUpdateSection from './MassPriceUpdateSection';
import { MQ_MIN_MEDIUM } from '@app/theme/types';

interface IPricingModal {
  itemId: number;
  menuTypeId: number;
  locationIds: number[];
  itemName: string;
  isActive: boolean;
  onCloseModal: () => void;
  isCombo?: boolean;
}

interface ILocationsObject {
  [key: number]: {
    locationGroupId: number;
    name: string;
    locations: {
      [key: number]: {
        name: string;
        locationId: number;
      };
    };
  };
}

const PricingModal = ({
  // itemId,
  menuTypeId,
  // locationIds,
  itemName,
  isActive,
  onCloseModal,
  isCombo = false,
}: IPricingModal) => {
  const dispatch = useAppDispatch();

  const locationsGroups = useSelector(selectLocations);

  const isLoadingModal = useSelector(
    (state: RootState) => state.app.isLoadingModal,
  );

  const { setIsLoadingModal } = bindActionCreators(actionCreatorsApp, dispatch);

  const [baseState, setBaseState] =
    React.useState<ILocationGroupItemPrices | null>(null);

  const initialState = useMemo<IPricingLocationGroupRow[] | null>(() => {
    const itemData = baseState;
    if (!itemData) return null;
    const locationsObj: ILocationsObject = Object.values(locationsGroups)
      .map(locGrp => ({
        locationGroupId: locGrp.id,
        name: locGrp?.name,
        locations: locGrp?.locations?.reduce(
          (acc, loc) => ({
            ...acc,
            [loc.id]: { locationId: loc.id, name: loc.streetLine1 },
          }),
          {},
        ),
      }))
      .reduce(
        (acc, locGrp) => ({ ...acc, [locGrp.locationGroupId]: locGrp }),
        {},
      );

    return Object.entries(itemData).map(([key, locGrp]) => ({
      locationGroupId: Number(key),
      name: locationsObj[Number(key)]?.name,
      locations: locGrp?.map<IPricingLocationRow>(location => ({
        selected: false,
        locationId: location.locationId,
        name: locationsObj[Number(key)]?.locations?.[location.locationId]?.name,
        itemPrice: location.itemPrice,
        onlinePrice: location.onlinePrice,
        deliveryPrice: location.deliveryPrice,
        comboBasePrice: location.comboBasePrice,
      })),
    }));
  }, [locationsGroups, baseState]);

  const getItemPriceListData = async () => {
    try {
      setIsLoadingModal({ active: true });
      // FIXME: replace for the real endpoint when the CORS issue is fixed
      // const response = await dispatch(
      //   getItemPriceListByIdAndMenuTypeIdWeb(
      //     itemId,
      //     menuTypeId,
      //     locationIds,
      //     isCombo,
      //   ),
      // );
      const response: any = {
        '0': [
          {
            locationGroupId: 0,
            locationId: 1046,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
        ],
        '1': [
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 2,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 2,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 1,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
          {
            locationGroupId: 1,
            locationId: 4,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
        ],
        '47': [
          {
            locationGroupId: 47,
            locationId: 3,
            itemPrice: 1,
            onlinePrice: 1,
            deliveryPrice: 1,
          },
        ],
      };
      setBaseState(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingModal({ active: false });
    }
  };

  useEffect(() => {
    if (isActive) getItemPriceListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <>
      {initialState && !isLoadingModal.active ? (
        <ItemPricingListModal
          isActive={isActive}
          menuTypeId={menuTypeId}
          initialState={initialState}
          itemName={itemName}
          onCloseModal={onCloseModal}
          isCombo={isCombo}
        />
      ) : null}
    </>
  );
};

const ItemPricingListModal = ({
  isActive,
  initialState,
  itemName,
  menuTypeId,
  onCloseModal,
  isCombo,
}: {
  isActive: boolean;
  initialState: IPricingLocationGroupRow[];
  itemName: string;
  menuTypeId: number;
  onCloseModal: () => void;
  isCombo: boolean;
}) => {
  // Redux

  const dispatch = useAppDispatch();
  const selectedModeLocationId = useRootSelector(
    state => state.menu.selectedMode.id,
  );

  // local state
  const { t } = useTranslation();
  const [hasChanged, setHasChanged] = useState(false);
  const [locationGroupsList, update] = useReducer(
    itemPricingModalReducer,
    initialState,
  );
  const hasLocationSelected = useMemo(
    () =>
      locationGroupsList.reduce(
        (acc, locGroup) =>
          acc ||
          locGroup.locations.map(location => location.selected).includes(true),
        false,
      ),
    [locationGroupsList],
  );

  const onSort = useSort<IPricingLocationGroupRow>('name');
  const [searchValue, setSearchValue] = useState('');
  const [massCardIsExpanded, setIsExpanded] = useState(true);
  const [sort, setSort] = useState<SortOptions | null>(null);
  const [slicedList, setSlicedList] = useState<IPricingLocationGroupRow[]>([]);

  React.useEffect(() => {
    setSlicedList(
      filter<IPricingLocationGroupRow>(
        locationGroupsList,
        { name: searchValue },
        sort,
        onSort,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationGroupsList, sort, searchValue]);

  const onRefresh = useRefreshMenuWeb();

  const handleMassUpdate = () => {
    const request = {
      menuTypeId: menuTypeId,
      locationId: selectedModeLocationId,
      hash: '',
      pricingUpdates: locationGroupsList.reduce((prevGroup, currentGroup) => {
        return {
          ...prevGroup,
          ...currentGroup.locations.reduce((prevLocation, currentLocation) => {
            return {
              ...prevLocation,
              [currentLocation.locationId]: {
                deliveryPrice: currentLocation.deliveryPrice,
                itemPrice: currentLocation.itemPrice,
                onlinePrice: currentLocation.onlinePrice,
                comboBasePrice: currentLocation.comboBasePrice,
              },
            };
          }, {}),
        };
      }, {}),
    };

    // FIXME: add this when CORS is fixed
    const enabled = false;
    if (enabled) {
      dispatch(massPriceUpdateWeb(request, isCombo));
      onRefresh();
    }
    onCloseModal && onCloseModal();
  };

  return (
    <Modal
      isActive={isActive}
      onModalClose={onCloseModal}
      size={1200}
      title={t(`${BASE_TRANSLATION}.title`, { itemName })}
      noPadding
      footer={
        <>
          <Button variant="secondary" onClick={onCloseModal}>
            {t('commonButtons.cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={!hasChanged}
            onClick={handleMassUpdate}>
            {t('commonButtons.save')}
          </Button>
        </>
      }>
      <Box
        csx={theme => ({
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.colors.lightGrey}`,
          minHeight: massCardIsExpanded ? 115 : 0,
          padding: massCardIsExpanded ? 20 : 0,
          position: 'relative',
        })}>
        {massCardIsExpanded && (
          <Box csx={{ zIndex: 5 }}>
            <MassPriceUpdateSection
              hasLocationSelected={hasLocationSelected}
              handleUpdatePrice={(priceName, updateType, amount) => {
                update({
                  type: 'massChangePrice',
                  priceName,
                  updateType,
                  amount,
                  isCombo,
                });
                setHasChanged(true);
              }}
              isCombo={isCombo}
            />
          </Box>
        )}
        <Box
          csx={theme => ({
            position: 'absolute',
            bottom: 0,
            left: '50%',
            border: `1px solid ${theme.colors.lightGrey}`,
            backgroundColor: 'white',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            width: 267,
            height: 50,
            transform: 'translate(-50%, 100%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 0,
          })}
          onClick={() => {
            setIsExpanded(current => !current);
          }}>
          <Typography color="darkestGrey" fontWeight="medium">
            {t(`${BASE_TRANSLATION}.updateSectionTitle`)}
          </Typography>
          <Icon
            name={
              massCardIsExpanded ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'
            }
            color="darkestGrey"
          />
        </Box>
      </Box>

      <Box
        csx={theme => ({
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          backgroundColor: theme.colors.lightestExtraGrey,
          paddingTop: '60px',
        })}>
        <Box csx={{ display: 'flex', gap: '10px' }}>
          <SearchInput onChange={setSearchValue} value={searchValue} />
          <SortButton onSort={setSort} />
        </Box>
        <Box>
          <Box
            csx={theme => ({
              backgroundColor: 'white',
              borderTopLeftRadius: 11,
              borderTopRightRadius: 11,
              border: `1px solid ${theme.colors.lightGrey}`,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingInline: '10px',
              [MQ_MIN_MEDIUM]: {
                paddingInline: '80px',
              },
            })}>
            <Box>
              <Typography fontWeight="medium">
                {t(`${BASE_TRANSLATION}.location`)}
              </Typography>
            </Box>
            <Typography fontWeight="medium">
              {t(
                isCombo
                  ? `${COMBO_BASE_TRANSLATION}.comboBasePrice`
                  : `${BASE_TRANSLATION}.itemPrice`,
              )}
            </Typography>
            <Typography fontWeight="medium">
              {t(`${BASE_TRANSLATION}.onlinePrice`)}
            </Typography>
            <Typography fontWeight="medium">
              {t(`${BASE_TRANSLATION}.deliveryPrice`)}
            </Typography>
          </Box>
          <Box
            csx={{
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,

              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}>
            {slicedList.map(item => (
              <PricingLocationGroupRow
                key={`location-group-${item.locationGroupId}`}
                isCombo={isCombo}
                {...item}
                handleLocationGroupToggle={(locationGroupId, state) =>
                  update({
                    type: 'toggleLocationGroup',
                    locationGroup: locationGroupId,
                    state,
                    isCombo,
                  })
                }
                handleLocationToggle={(locationGroupId, locationId) =>
                  update({
                    type: 'toggleLocation',
                    locationGroup: locationGroupId,
                    location: locationId,
                    isCombo,
                  })
                }
                handlePriceChange={(
                  locationGroupId,
                  locationId,
                  priceName,
                  payload,
                ) => {
                  update({
                    type: 'changePrice',
                    locationGroup: locationGroupId,
                    location: locationId,
                    priceName,
                    payload,
                    isCombo,
                  });
                  setHasChanged(true);
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PricingModal;
