import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';

import Icon from '@app/components/common/Icon';
import OptionsModal from '@app/components/common/OptionsModal';
import StatusIndicator from '@app/components/common/StatusIndicator';
import Table from '@app/components/common/Table';
import {
  currentLicenseSettingsSettingsSelector,
  currentLicensesSelector,
  currentTerminalsSelector,
} from '@app/state/selectors/settingsSelectors';
import { createColumnHelper } from '@tanstack/react-table';
import { ITerminal, TERMINAL_INITIAL_STATE } from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditTerminalModal from './EditTerminalModal';

interface ITerminalsTable {
  name: string;
  tpn: string;
  authKey: string;
  licenseIds: number[];
  id: number;
  actions: string;
}

interface ITerminalsTableProps {
  data: (ITerminal & { actions: string })[];
  emptyBySearch: boolean;
}

const TerminalsTable = ({ data, emptyBySearch }: ITerminalsTableProps) => {
  //Redux
  const licensesSettings = useSelector(currentLicenseSettingsSettingsSelector);
  const licenses = useSelector(currentLicensesSelector);
  const terminals = useSelector(currentTerminalsSelector);

  // Local state
  const { t } = useTranslation();
  const [isModalActive, setIsModalActive] = useState(false);
  const [terminalData, setTerminalData] = useState<ITerminal>(
    TERMINAL_INITIAL_STATE,
  );

  const handleCloseModal = () => {
    setIsModalActive(false);
  };

  const columnHelper = createColumnHelper<ITerminalsTable>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('settingsModule.devices_licenses.terminalsTable.name'),
        cell: info => info.getValue(),
        minSize: 160,
      }),
      columnHelper.accessor('tpn', {
        header: t('settingsModule.devices_licenses.terminalsTable.tpn'),
        cell: info => info.getValue(),
        minSize: 260,
      }),
      columnHelper.accessor('authKey', {
        header: t('settingsModule.devices_licenses.terminalsTable.authKey'),
        cell: info => info.getValue(),
        minSize: 140,
        enableSorting: false,
      }),
      columnHelper.accessor('licenseIds', {
        header: t('settingsModule.devices_licenses.terminalsTable.licenses'),
        cell: info => {
          if (info.getValue().length === 0)
            return t(
              'settingsModule.devices_licenses.terminalsTable.noLicenses',
            );

          const limit = 4;

          let overflows = false;
          if (info.getValue().length > limit) overflows = true;

          return (
            <Box
              csx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                gap: '15px',
              }}>
              <Box
                csx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  overflowY: 'auto',
                  height: '50px',
                }}>
                {(info.getValue() as number[])
                  .slice(0, 5)
                  .map((licenseId, index) => {
                    const license = licenses[licenseId] || {};
                    const licenseSettings =
                      licensesSettings[license?.licenseSettingsId] || {};

                    let title = licenseSettings.nickname;

                    if (index === 4 && overflows) title += '...';

                    return (
                      <Box
                        key={licenseId}
                        csx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <StatusIndicator
                          title={title}
                          circleColor={
                            license.status !== 'active'
                              ? 'semanticRed'
                              : 'semanticGreen'
                          }
                        />
                      </Box>
                    );
                  })}
              </Box>
              {overflows && (
                <OptionsModal
                  options={info.getValue().map(associatedLicenseId => {
                    return {
                      label:
                        licensesSettings[
                          licenses[associatedLicenseId]?.licenseSettingsId
                        ]?.nickname || '',
                      value: associatedLicenseId,
                    };
                  })}
                  isSearchable
                  noElementMessage={t(
                    'settingsModule.devices_licenses.terminalsTable.noLicenses',
                  )}
                  title={t(
                    'settingsModule.devices_licenses.terminalsTable.associatedLicenses',
                  )}
                  buttonProps={{
                    icon: null,
                    children: t('commonTexts.viewAll') as string,
                    style: { minWidth: '100px', maxWidth: '100px' },
                  }}
                  hideFooterButtons
                  onChange={() => null}
                  dismissOnSelect={false}
                  onChangeDone={() => null}
                />
              )}
            </Box>
          );
        },
        enableSorting: false,
        minSize: 320,
      }),
      columnHelper.accessor('actions', {
        header: t('settingsModule.devices_licenses.licensesTable.actions'),
        cell: info => (
          <Button
            icon={<Icon name="MdEdit" />}
            csx={{ width: '50px' }}
            onClick={() => {
              setTerminalData(terminals[info.row.original.id]);
              setIsModalActive(true);
            }}
          />
        ),
        size: 70,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, terminals, licensesSettings],
  );

  return (
    <>
      <EditTerminalModal
        isActive={isModalActive}
        terminalData={terminalData}
        onCancel={handleCloseModal}
        onSuccess={handleCloseModal}
      />
      <Table
        renderEmptyValues
        columns={COLUMN_VALUES}
        data={data}
        cellCsx={{ height: '70px' }}
        manualSorting={false}
        alwaysShowSortIcon
        enableAlphabeticalSorting
        noDataMessage={
          emptyBySearch
            ? t(
                'settingsModule.devices_licenses.terminalsTable.emptyBySearchTitle',
              )
            : t('settingsModule.devices_licenses.terminalsTable.emptyDataTitle')
        }
        alignHeaders={{
          actions: 'center',
        }}
        align={{
          actions: 'center',
        }}
      />
    </>
  );
};

export default TerminalsTable;
