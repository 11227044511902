import Box from '@app/components/common/Box';
import { Outlet, useLocation } from 'react-router-dom';
import {
  authenticationContainerStyles,
  authenticationContainerStylesCentered,
} from './styles';
import { logoBackgroundStyles } from '@app/components/AppLoader/styles';
import NeedAssistance from './NeedAssistance';
import { useEffect, useState } from 'react';
import TTLogo from '@app/assets/logos/TTLogoWithText.svg?react';
import Link from '@app/components/common/Link';

interface IAuthContainerLayout {
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const AuthContainerLayout = ({ subtitle, children }: IAuthContainerLayout) => {
  const location = useLocation();

  const [isCentered, setIsCentered] = useState<boolean | null>(null);

  useEffect(() => {
    if (
      location.pathname.includes('sign-up') ||
      location.pathname.includes('verify') ||
      location.pathname.includes('org-invite')
    ) {
      setIsCentered(true);
    } else {
      setIsCentered(false);
    }
  }, [location.pathname]);

  if (isCentered === null) {
    return null;
  }

  return (
    <Box csx={logoBackgroundStyles}>
      <Box
        csx={
          isCentered
            ? authenticationContainerStylesCentered
            : authenticationContainerStyles
        }>
        <Box className="content">
          {subtitle}
          <Box className="body">{children ?? <Outlet />}</Box>
        </Box>
        <Box className="logoContent">
          <Link to="/">
            <TTLogo className="logo" />
          </Link>
          <NeedAssistance isCentered={isCentered} />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthContainerLayout;
