import { Theme, css } from '@emotion/react';

export const sliderContainerStyles = (theme: Theme, hasError: boolean) =>
  css({
    paddingBottom: '20px',
    paddingInline: '10px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: hasError ? theme.colors.lighterRed : 'transparent',
    height: '80px',
    width: '100%',
    justifyContent: 'center',
    borderRadius: '8px',
  });
