import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const LogoContainerStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
    img: {
      width: '90px',
      height: '90px',
      borderRadius: '100%',
      objectFit: 'cover',
    },
  });

export const nameDivisorStyles = (theme: Theme) =>
  css({
    borderBottom: `2px solid ${theme.colors.semanticBlue}`,
    width: '40px',
    marginTop: '5px',
  });

export const pullingOrDataStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  marginTop: '30px',
  marginBottom: '25px',
});

export const successMessageStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '.organizationContainer': {
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.logoContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBlock: '-10px',
    },
  });

export const TagStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    minWidth: '250px',
    paddingInline: '20px',
    height: '40px',
    backgroundColor: theme.colors.lighterBlue,
    borderRadius: '50px',
    marginTop: '10px',
    p: {
      color: theme.colors.darkBlue,
    },
    svg: {
      fill: theme.colors.darkBlue,
    },
  });

export const itemsContainerStyles = (theme: Theme) =>
  css({
    maxHeight: '280px',
    overflow: 'auto',
    paddingInline: '5px',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.colors.lightestGrey,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.colors.lightGrey,
      borderRadius: '10px',
    },
    [MQ_MIN_SMALL]: {
      paddingInline: '10px',
    },
  });
