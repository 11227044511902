import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const optionsModalStyles = (theme: Theme) =>
  css({
    height: '75%',
    '.modalContent': {
      display: 'grid',
      gridTemplateAreas: `
      "groups"
      "options"
      "selectedOptions"
      `,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr auto',
      overflow: 'hidden',
      '.itemCard': {
        borderColor: theme.colors.lightGrey,
        ':hover': {
          backgroundColor: theme.colors.lighterBlue,
          border: `2px solid ${theme.colors.semanticBlue}`,
        },
        '&.isSelected': {
          border: `2px solid ${theme.colors.semanticBlue} !important`,
          ':hover': {
            backgroundColor: theme.colors.semanticBlue,
          },
        },
      },
      '.groupsContainer': {
        display: 'flex',
        gridArea: 'groups',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        '.groups': {
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflowY: 'hidden',
          overflowX: 'auto',
          gap: '10px',
          paddingBlock: '10px',
          paddingInline: '10px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
        '.clearOptionsContainer': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 0,
          paddingInline: '10px',
          borderLeft: `1px solid ${theme.colors.lightGrey}`,
        },
      },
      '.options': {
        gridArea: 'options',
        borderRight: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
        gap: '12px',
        paddingBlock: '10px',
        backgroundColor: theme.colors.background,
        '.optionsContent': {
          paddingInline: '15px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
          gap: '10px',
          width: '100%',
          overflow: 'auto',
          itemCard: {
            height: '90px !important',
          },
        },
        '.searchBar': {
          width: '100%',
          padding: '0px 15px 0px 15px',
        },
        '.noOptions': {
          padding: '10px',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '.selectedOptions': {
        gridArea: 'selectedOptions',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        '.selectedOptionsHeaderContainer': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          borderBlock: `1px solid ${theme.colors.lightGrey}`,
        },
        '.selectedOptionsContent': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: '10px',
          padding: '10px',
          overflow: 'visible',
          overflowX: 'auto',
          '.removeIcon': {
            zIndex: 1000,
          },
          '.itemCard': {
            width: '140px',
            ':hover': {
              cursor: 'default',
            },
          },
        },
        '.noOptionsSelectedContainer': {
          display: 'flex',
          height: '70px',
          alignItems: 'center',
        },
      },
    },
    [MQ_MIN_MEDIUM]: {
      '.modalContent': {
        gridTemplateAreas: `
          'groups options selectedOptions'
          `,
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: '1fr',
        display: 'grid',
        overflow: 'hidden',
        '.itemCard': {
          height: '90px',
        },
        '.groupsContainer': {
          width: '170px',
          flexDirection: 'column',
          height: '100%',
          border: 'none',
          borderRight: `1px solid ${theme.colors.lightGrey}`,
          '.groups': {
            flexDirection: 'column',
            alignItems: 'center',
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingInline: '0px',
          },
          '.clearOptionsContainer': {
            paddingBlock: '10px',
            flexGrow: 0,
            borderTop: `1px solid ${theme.colors.lightGrey}`,
          },
        },
        '.options': {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
          '.optionsContent': {
            alignContent: 'start',
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
          },
          '.noOptions': {
            height: '100%',
            width: '100%',
          },
        },
        '.selectedOptions': {
          gridArea: 'selectedOptions',
          flexGrow: 0,
          width: '170px',
          borderLeft: `1px solid ${theme.colors.lightGrey}`,
          '.selectedOptionsHeaderContainer': {
            borderTop: '0px',
            borderBottom: `1px solid ${theme.colors.lightGrey}`,
            minHeight: '62px',
          },
          '.itemCard': {
            width: '140px',
          },
          '.selectedOptionsContent': {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          '.noOptionsSelectedContainer': {
            padding: '10px',
            height: '100%',
            alignItems: 'center',
            p: {
              textAlign: 'center',
            },
          },
        },
      },
    },
  });

export const optionsModalButtonTextStyles = () =>
  css({
    color: 'currentcolor',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  });
