import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsSettings,
  IRegisterSettings,
  registerModeSuggestedTioOptionsFactory,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SECTION_ID = SettingsSectionId.CUSTOMER_FACING_DISPLAY;

function Tips({ t, data }: WithTranslation & { data: IRegisterSettings }) {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const tipOptions = useMemo(
    () => registerModeSuggestedTioOptionsFactory(t),
    [t],
  );

  const suggestedTipValue = useMemo(
    () =>
      data?.defaultSuggestedTip
        ? Number(data.defaultSuggestedTip.replace('suggestedTip', ''))
        : 0,
    [data?.defaultSuggestedTip],
  );

  return (
    <Card.SubCard title={t('settingsModule.onlineSettings.tips.title')}>
      <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'center' }}>
        <Grid.Item
          mb={12}
          csx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '35px',
          }}>
          <Switch
            checked={data ? data?.showTippingOptionsAtCheckout : false}
            onChange={showTippingOptionsAtCheckout =>
              setValue<IRegisterSettings>(
                { showTippingOptionsAtCheckout },
                SECTION_ID,
              )
            }
            label={t(
              'settingsModule.onlineSettings.tips.showTippingAtCheckout',
            )}
          />
          <Switch
            checked={data ? data?.showCfdTipSelectionOnRegister : true}
            onChange={showCfdTipSelectionOnRegister =>
              setValue<IRegisterSettings>(
                { showCfdTipSelectionOnRegister },
                SECTION_ID,
              )
            }
            label={t(
              'settingsModule.onlineSettings.tips.showCfdTipSelectionOnRegister',
            )}
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
          <Dropdown
            label={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.tips.defaultSuggestedTip.label',
            )}
            placeholder={t(
              'settingsModule.registerModeSettings.customerFacingDisplay.tips.defaultSuggestedTip.label',
            )}
            data={[tipOptions]}
            onChange={newDefaultSuggestedTip =>
              setValue<IRegisterSettings>(
                {
                  defaultSuggestedTip: `suggestedTip${
                    newDefaultSuggestedTip as 1
                  }`,
                },
                SECTION_ID,
              )
            }
            isDisabled={!data?.showTippingOptionsAtCheckout}
            value={suggestedTipValue}
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption1',
            )}
            prefix=""
            suffix="%"
            precision={0}
            maxValue={99}
            value={data?.suggestedTip1 || 0}
            onValueChange={suggestedTip1 =>
              setValue<IRegisterSettings>({ suggestedTip1 }, SECTION_ID)
            }
            disabled={!data?.showTippingOptionsAtCheckout}
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption2',
            )}
            prefix=""
            suffix="%"
            precision={0}
            maxValue={99}
            value={data?.suggestedTip2 || 0}
            onValueChange={suggestedTip2 =>
              setValue<IRegisterSettings>({ suggestedTip2 }, SECTION_ID)
            }
            disabled={!data?.showTippingOptionsAtCheckout}
          />
        </Grid.Item>
        <Grid.Item mb={12} sm={6} md={6} lg={6} xl={3}>
          <MoneyInput
            label={t(
              'settingsModule.guestReceiptSettings.suggestedTips.tipOption3',
            )}
            prefix=""
            suffix="%"
            precision={0}
            maxValue={99}
            value={data?.suggestedTip3 || 0}
            onValueChange={suggestedTip3 =>
              setValue<IRegisterSettings>({ suggestedTip3 }, SECTION_ID)
            }
            disabled={!data?.showTippingOptionsAtCheckout}
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
}

export default Tips;
