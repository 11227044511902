import MultiSelectDropdown from './MultiSelectDropdown';
import SingleSelectDropdown from './SingleSelectDropdown';
import { TSingleSelectDropdown } from './SingleSelectDropdown/types';

const Dropdown = (props: TSingleSelectDropdown) => {
  return <SingleSelectDropdown {...props} />;
};

Dropdown.SingleSelect = SingleSelectDropdown;
Dropdown.MultiSelect = MultiSelectDropdown;

export default Dropdown;
