import { selectAllCoursesArray } from '@app/state/selectors/settingsSelectors';
import { courseDropdownFactory } from '@westondev/tableturn-core';
import { createSelector } from 'reselect';

const selectSortedAllCoursesArray = createSelector(
  selectAllCoursesArray,
  courses => courses.sort((a, b) => a.sortPriority - b.sortPriority),
);
export const selectAllCoursesDropdownArray = createSelector(
  selectSortedAllCoursesArray,
  courseDropdownFactory,
);
