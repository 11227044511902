import AddressInfoSection from '@app/components/LoggedIn/Customers/CustomerDetails/AddressInfoSection/AddressInfoSection';
import BasicSection from '@app/components/LoggedIn/Customers/CustomerDetails/BasicSection/BasicSection';
import CardInfoSection from '@app/components/LoggedIn/Customers/CustomerDetails/CardInfoSection/CardInfoSection';
import OrdersSection from '@app/components/LoggedIn/Customers/CustomerDetails/OrdersSection/OrdersSection';
import Accordion from '@app/components/common/Accordion';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { actionCreatorsCustomersWeb } from '@app/state';
import { selectIsCustomerSet } from '@app/state/customers/customersSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actionCreatorsCustomersChangeData } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';

const CustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const loadCustomersChangeData = bindActionCreators(
    actionCreatorsCustomersWeb.loadCustomersChangeData,
    dispatch,
  );

  const resetCustomersChangeData = bindActionCreators(
    actionCreatorsCustomersChangeData.resetCustomersChangeData,
    dispatch,
  );

  const isCreation = useMatch(getPathWithOrgData('/customers/add'));

  const isSet = useSelector(selectIsCustomerSet);

  useEffect(() => {
    if (!isSet)
      loadCustomersChangeData({
        id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, id]);

  useEffect(() => {
    return () => {
      resetCustomersChangeData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isSet && (
      <Accordion>
        <BasicSection isExpanded />
        <AddressInfoSection />
        {isCreation ? <></> : <CardInfoSection />}
        {isCreation ? <></> : <OrdersSection />}
      </Accordion>
    )
  );
};

export default CustomerDetails;
