import { DarkTheme, LightTheme } from '@app/theme/themes';
import {
  OrderStatus,
  useOrderStatusColor,
  useOrderStatusName,
} from '@westondev/tableturn-core';
import Box from '../Box';
import Typography from '../Typography';

interface IOrderStatusLabel {
  status: OrderStatus;
}

const OrderStatusLabel = ({ status }: IOrderStatusLabel) => {
  const statusName = useOrderStatusName(status);
  const statusColor = useOrderStatusColor(status, LightTheme, DarkTheme);
  return (
    <Box
      csx={{
        display: 'flex',
        alignItems: 'center',
        gap: 6,
      }}>
      <Box
        csx={{
          height: 14,
          width: 14,
          borderRadius: '50%',
          backgroundColor: statusColor.bg,
        }}
      />
      <Typography>{statusName}</Typography>
    </Box>
  );
};

export default OrderStatusLabel;
