import { TCsx } from '@emotion/react';
import Box from '../Box';
import { IBox } from '../Box/Box';
import Typography from '../Typography';
import { ITypography } from '../Typography/Typography';
import {
  dividerContainerStyles,
  dividerStyles,
  dividerVerticalStyles,
} from './styles';

interface IDivider extends IBox {
  direction?: 'horizontal' | 'vertical';
  label?: string;
  labelProps?: ITypography;
  lineCsx?: TCsx;
}
const Divider = ({
  direction = 'horizontal',
  label,
  labelProps,
  csx,
  lineCsx,
  ...otherProps
}: IDivider) => {
  return (
    <Box csx={[dividerContainerStyles, csx]} {...otherProps}>
      {label && (
        <Typography
          fontWeight="medium"
          align="center"
          color="persistentSemanticBlue"
          csx={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}
          {...labelProps}>
          {label}
        </Typography>
      )}
      <Box
        csx={[
          direction === 'horizontal' ? dividerStyles : dividerVerticalStyles,
          lineCsx,
        ]}
      />
    </Box>
  );
};

export default Divider;
