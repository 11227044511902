import { TDropdownData } from '@app/components/common/Dropdown/types';
import { store } from '@app/state/store';
import {
  CoreRootState,
  IPrepStationsSettings,
  TDifference,
  diffPaymentsFactory as diffPaymentsFactoryCore,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

export const prepStationsDropdownOptionsFactory = (
  prepStations: IPrepStationsSettings['prepStations'],
  t?: WithTranslation['t'],
  showAll = false,
): TDropdownData => {
  const prepStationOptions: TDropdownData = [[]];
  if (t && showAll) {
    prepStationOptions[0].push({
      label: t('commonTexts.all'),
      value: 0,
    });
  }

  for (const stationId in prepStations || {}) {
    prepStationOptions[0].push({
      label:
        prepStations[stationId].name +
        (prepStations[stationId].active ? '' : ' (Inactive)'),
      value: Number(stationId),
      textProps: prepStations[stationId].active
        ? undefined
        : {
            color: 'persistentSemanticRed',
          },
    });
  }

  return prepStationOptions;
};
export const diffPaymentsFactory = (differences: TDifference[] = []) => {
  return diffPaymentsFactoryCore(
    differences,
    store.getState() as unknown as CoreRootState,
  );
};
