import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import SearchInput from '@app/components/common/SearchInput';
import {
  currentLicenseSettingsSettingsSelector,
  currentLicensesSelector,
} from '@app/state/selectors/settingsSelectors';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { devicesStyles } from '../Devices/styles';
import LicensesTable from './LicensesTable';
import LicenseSettings from './LicensesTable/LicenseSettings';
import { TSelectedLicenseSettings } from './LicensesTable/types';
import Box from '@app/components/common/Box';

const Licenses = () => {
  // Redux
  const licenses = useSelector(currentLicensesSelector);
  const licensesSettings = useSelector(currentLicenseSettingsSettingsSelector);

  // Local state
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const [showLicenseDetails, setShowLicenseDetails] =
    useState<TSelectedLicenseSettings | null>(null);

  const licensesTableArray = useMemo(
    () =>
      Object.keys(licenses || {})
        .map(key => ({
          ...licenses[key],
          id: Number(key),
          nickname: licensesSettings[licenses[key].licenseSettingsId].nickname,
          actions: '',
        }))
        .filter(item => {
          return (
            item.nickname.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        }),
    [licenses, licensesSettings, searchText],
  );

  return (
    <>
      {showLicenseDetails ? (
        <LicenseSettings
          selectedLicense={showLicenseDetails}
          onBackClick={() => setShowLicenseDetails(null)}
        />
      ) : (
        <Box
          csx={{
            padding: '15px',
          }}>
          <HeaderTitle
            title="Licenses Settings"
            options={
              <Button
                variant="primary"
                icon={<Icon name="MdAdd" />}
                csx={{ minWidth: '140px' }}
                onClick={() =>
                  setShowLicenseDetails({
                    licenseSettingsId: 0,
                  })
                }>
                {t('settingsModule.devices_licenses.addLicenses')}
              </Button>
            }
          />
          <Card csx={devicesStyles}>
            <Grid rowGap={15} columnGap={15}>
              <Grid.Item
                mbOffset={12}
                smOffset={6}
                lgOffset={8}
                xlOffset={9}
                isOffset
              />
              <Grid.Item mb={12} sm={6} md={6} lg={4} xl={3}>
                <SearchInput value={searchText} onChange={setSearchText} />
              </Grid.Item>
              <Grid.Item>
                <LicensesTable
                  data={licensesTableArray}
                  emptyBySearch={
                    licensesTableArray?.length === 0 &&
                    Object.keys(licenses || {}).length > 0
                  }
                  setShowLicenseDetails={setShowLicenseDetails}
                />
              </Grid.Item>
            </Grid>
          </Card>
        </Box>
      )}
    </>
  );
};

export default Licenses;
