import { SettingsErrorsId } from '@westondev/tableturn-core';
import GenericError from '../GenericError';
import { IGenericError } from '../GenericError/types';
import TimeBasedMenuTypeError from '../TimeBasedMenuTypeError';
import { ITimeBasedMenuTypeError } from '../TimeBasedMenuTypeError/types';
import { ISwitchError } from './types';

const SwitchError = ({ type, data }: ISwitchError) => {
  switch (type) {
    case SettingsErrorsId.TIME_BASED_MENU_TYPE:
      return <TimeBasedMenuTypeError {...(data as ITimeBasedMenuTypeError)} />;
    case SettingsErrorsId.GENERIC_ERROR:
      return <GenericError {...(data as IGenericError)} />;
    default:
      return <></>;
  }
};

export default SwitchError;
