import { TCsx } from '@emotion/react';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import Spinner from '../../Spinner';
import Typography from '../../Typography';
import { useEffect } from 'react';
import { useAnimate } from 'framer-motion';

interface IDropdownButton {
  isDisabled?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  handleOpenDropDown?: () => void;
  hasSelectedOption?: boolean;
  selectedOptionLabel?: string | null | undefined;
  selectedOptionColor?: string | undefined;
  placeholder?: string;
  customText?: string | null;
  customTextColor?: string;
  loadingText?: string;
  error?: boolean;
  inputContainerProps?: TCsx;
  label?: string;
}

const DropdownButton = ({
  isDisabled,
  isExpanded,
  isLoading,
  handleOpenDropDown,
  hasSelectedOption,
  selectedOptionLabel,
  selectedOptionColor,
  placeholder,
  customText,
  customTextColor,
  loadingText = 'Loading',
  error,
  inputContainerProps,
  label,
}: IDropdownButton) => {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    animate(
      '.dropDownArrow',
      { rotate: isExpanded ? 180 : 0 },
      { duration: 0.1 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <Button
      ref={scope}
      csx={[
        {
          width: '100%',
          justifyContent: 'space-between',
          paddingInline: '15px',
        },
        inputContainerProps,
      ]}
      disabled={isDisabled}
      iconPosition="right"
      icon={
        <Box className="dropDownArrow" csx={{ display: 'flex' }}>
          <Icon
            name="MdChevronRight"
            color="black"
            csx={{
              rotate: '90deg',
            }}
          />
        </Box>
      }
      onClick={handleOpenDropDown}
      className={`${isExpanded ? 'active' : 'inactive'} ${
        customTextColor
          ? ''
          : hasSelectedOption
          ? 'selected'
          : !isDisabled
          ? 'noSelected'
          : ''
      } ${error ? 'error' : ''}`}>
      <Box csx={{ overflow: 'hidden', display: 'flex' }}>
        {isLoading && (
          <Spinner csx={{ marginRight: '8px' }} color="lightGrey" />
        )}
        <Box
          csx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '2px',
          }}>
          {label && (
            <Typography
              variant="caption2"
              fontWeight="medium"
              color="semanticBlue">
              {label}
            </Typography>
          )}
          <Typography
            csx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            color={
              isDisabled
                ? undefined
                : customTextColor
                ? customTextColor
                : selectedOptionColor || undefined
            }>
            {isLoading
              ? loadingText
              : customText
              ? customText
              : selectedOptionLabel || placeholder}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default DropdownButton;
