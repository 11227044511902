import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import FilterGenericSelectionModal from '@app/components/common/SelectionModal/FilterGenericSelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectReportingGroups } from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  menuItemReportingGroupsFactory,
  SectionId,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AssignedElements from '../../../AssignedElements';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';

const ReportingGroupsSection = ({
  t,
  item,
  sectionId,
  isDisabled,
  isCombo,
  ...props
}: IItemDetailsSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const reportingGroups = useSelector(selectReportingGroups, shallowEqual);

  const [filter, setFilter] = useState(1);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const closeModal = () => setOpenSelectionModal(false);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigate();

  const assignedReportingGroupIds = item?.reportingGroupIds;

  const reportingGroupIds = useMemo(
    () => assignedReportingGroupIds?.map(id => id.toString()) || [],
    [assignedReportingGroupIds],
  );

  const assignedItemsCardList = useMemo(() => {
    return menuItemReportingGroupsFactory(
      reportingGroups,
      assignedReportingGroupIds || [],
    )
      .filter(card => (filter === 0 ? true : card.activeFilter === filter))
      .map(card => ({
        ...card,
        showStatus: true,
        isActive: card.isActive,
        onClick: (openInNewTab?: boolean) => {
          const text = card.title;
          const id = card.id;

          const pathURL = `/menu/reporting-groups/${id}`;
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          const _navigate = () => navigate(getPathWithOrgData(pathURL));
          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text,
                onPress: _navigate,
                pathURL,
              }),
            onRefresh,
          );
        },
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingGroups, assignedReportingGroupIds, filter]);

  const menuTypeFilterNode = useMemo(
    () => (
      <FilterGenericSelectionModal<number>
        options={[
          {
            id: 1,
            label: t('menuScreen.common.bucket.menuTypes.plural'),
            options: [
              { label: t('commonTexts.all'), value: 0 },
              { label: t('commonTexts.active'), value: 1 },
              { label: t('commonTexts.inactive'), value: 2 },
            ],
          },
        ]}
        currentValue={[{ id: 1, values: [filter] }]}
        onFilter={options => setFilter(options as number)}
        onClear={() => {
          setFilter(0);
        }}
        triggerButtonProps={{
          csx: { minWidth: '50px', width: '50px' },
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  const onNewReportingGroup = (text: string) => {
    checkForChangesAndNavigateWeb(() => {
      const pathURL = '/menu/reporting-groups/add';

      const _navigate = () => navigate(getPathWithOrgData(pathURL));
      addBreadcrumbLocationWeb({
        action: BreadCrumbAction.ADD,
        text,
        onPress: _navigate,
      });
    }, onRefresh);
  };

  const onDisassociate = (deletedReportingGroupId: number | string) => {
    if (typeof deletedReportingGroupId === 'string') {
      return;
    }
    const newElementIds = (assignedReportingGroupIds || []).filter(
      id => id !== deletedReportingGroupId,
    );
    setValue({ reportingGroupIds: newElementIds }, SectionId.REPORTING_GROUPS);
  };

  const handleOnAssociate = (buttons: IItemSelection[]) => {
    const newElementIds = [
      ...(assignedReportingGroupIds || []),
      ...buttons.map(button => Number(button.id)),
    ];
    setValue({ reportingGroupIds: newElementIds }, SectionId.REPORTING_GROUPS);
    closeModal();
  };

  return (
    <AccordionSection
      id={sectionId}
      title={t('menuScreen.itemDetails.reportingSection.title')}
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      {...props}>
      <AssignedElements
        type="reportingGroups"
        detailsScreenProps={{
          currentRelationsIds: reportingGroupIds,
          wantedEntity: 'reportingGroups',
        }}
        active={openSelectionModal}
        onModalClose={closeModal}
        onAssociate={handleOnAssociate}
        onDisassociate={onDisassociate}
        noElementsMessage={t(
          `menuScreen.${
            isCombo ? 'comboDetails' : 'itemDetails'
          }.reportingSection.emptyCardsMessage`,
        )}
        elements={assignedItemsCardList}
        subCardProps={{
          title: t('menuScreen.common.bucket.reportingGroups.plural'),
          csx: { borderWidth: 0, padding: 0 },
          extraOptions: menuTypeFilterNode,
          actionOptions: [
            {
              text: t(
                'menuScreen.menuLocation.actionButtons.addNewReportingGroup',
              ),
              icon: true,
              handler: () =>
                onNewReportingGroup(
                  t(
                    'menuScreen.menuLocation.actionButtons.addNewReportingGroup',
                  ),
                ),
            },
            {
              text: t(
                'menuScreen.menuLocation.actionButtons.addExistingReportingGroup',
              ),
              handler: () => setOpenSelectionModal(true),
            },
          ],
        }}
      />
    </AccordionSection>
  );
};

export default ReportingGroupsSection;
