import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Checkbox from '@app/components/common/Checkbox';
import CheckboxMassUpdate from '@app/components/common/CheckBoxMassUpdate';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import MoneyInput from '@app/components/common/MoneyInput';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import TextAreaInput from '@app/components/common/TextAreaInput';
import Typography from '@app/components/common/Typography';
import { selectItems } from '@app/state/menu/menuSelectors';
import { MQ_MIN_LARGE } from '@app/theme/types';
import {
  actionCreatorsMenu,
  FormMode,
  useFormError,
} from '@westondev/tableturn-core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import { itemContainerStyles } from './styles';

const SuggestiveOrderingSection = ({
  t,
  menuTypeVersionId,
  itemData,
  itemId: itemDataId,
  sectionId,
  isDisabled,
  mode,
  ...props
}: IItemDetailsSection) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const items = useSelector(selectItems);

  const [openSelectionModal, setOpenSelectionModal] = useState(false);

  const specialPriceError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.suggestiveOrderingItemPrice`,
  );
  const promptError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.suggestiveOrderingPrompt`,
  );

  const itemError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.suggestiveOrderingItemId`,
  );

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isMassUpdateProp = {
    isMassUpdate,
  };

  const handleOnAssociate = (item: IItemSelection[]) => {
    setValue(
      { suggestiveOrderingItemId: Number(item[0].id) },
      sectionId,
      menuTypeVersionId,
    );
    itemError?.error && itemError?.clear();
    setOpenSelectionModal(false);
  };
  const itemId = itemData?.suggestiveOrderingItemId;
  const itemName = itemId ? items[itemId]?.name : '';
  const isEnabled = itemData ? itemData?.suggestiveOrdering : false;

  const hasSectionError =
    Boolean(itemError?.error) ||
    Boolean(promptError?.error) ||
    Boolean(specialPriceError?.error);

  return (
    <>
      <SelectionModal
        type="items"
        allowAssociateMultiple={false}
        detailsScreenProps={{
          currentRelationsIds: [`${itemDataId ? itemDataId : 0}`],
          wantedEntity: 'items',
        }}
        onAssociate={handleOnAssociate}
        onModalClose={() => setOpenSelectionModal(false)}
        active={openSelectionModal}
      />

      <AccordionSection
        disabledLayer={isDisabled && <NoMenuTypeLayer />}
        title={t('menuScreen.itemDetails.suggestiveOrdering.title')}
        info={t('menuScreen.itemDetails.suggestiveOrdering.toolTip')}
        hasError={hasSectionError}
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
          <CheckboxMassUpdate
            label={t(
              'menuScreen.itemDetails.suggestiveOrdering.promptCheckText',
            )}
            info={t(
              'menuScreen.itemDetails.suggestiveOrdering.promptCheckTexttoolTip',
            )}
            checked={isEnabled}
            onChange={suggestiveOrdering => {
              if (!suggestiveOrdering) {
                setValue(
                  {
                    suggestiveOrdering,
                    suggestiveOrderingItemId: 0,
                    suggestiveOrderingItemPrice: null,
                    suggestiveOrderingPrompt: '',
                  },
                  sectionId,
                  menuTypeVersionId,
                );
                itemError?.clear();
                promptError?.clear();
              } else {
                setValue(
                  { suggestiveOrdering, suggestiveOrderingItemPrice: null },
                  sectionId,
                  menuTypeVersionId,
                );
              }
            }}
            onMassUpdateChange={suggestiveOrdering => {
              if (!suggestiveOrdering) {
                setValue(
                  {
                    suggestiveOrdering,
                    suggestiveOrderingItemId: 0,
                    suggestiveOrderingItemPrice: null,
                    suggestiveOrderingPrompt: '',
                  },
                  sectionId,
                  menuTypeVersionId,
                );
                itemError?.clear();
                promptError?.clear();
              } else {
                setValue(
                  {
                    suggestiveOrdering,
                    suggestiveOrderingItemPrice: null,
                  },
                  sectionId,
                  menuTypeVersionId,
                );
              }
            }}
            {...isMassUpdateProp}
          />
          <Grid rowGap={20} columnGap={20}>
            <Grid.Item md={4} lg={3}>
              <Box csx={itemContainerStyles}>
                <Button
                  csx={{
                    minHeight: '130px',
                    minWidth: '150px',
                    maxWidth: '150px',
                    position: 'relative',
                  }}
                  icon={
                    !itemId ? (
                      <Icon name="MdAddCircle" csx={{ marginBottom: '5px' }} />
                    ) : undefined
                  }
                  iconPosition="top"
                  disabled={!isEnabled}
                  onClick={() => setOpenSelectionModal(true)}>
                  {itemId
                    ? itemName
                    : t(
                        'menuScreen.itemDetails.suggestiveOrdering.addButtonText',
                      )}

                  {itemId ? (
                    <Box csx={{ position: 'absolute', right: 5, top: 5 }}>
                      <Icon name="MdEdit" />
                    </Box>
                  ) : null}
                </Button>
                {Boolean(itemError?.error) && (
                  <Typography color="persistentSemanticRed" align="center">
                    {itemError?.error}
                  </Typography>
                )}
              </Box>
            </Grid.Item>

            <Grid.Item md={8} lg={9}>
              <Grid columnGap={20} rowGap={15}>
                <Grid.Item
                  md={12}
                  lg={5}
                  xl={3}
                  csx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    [MQ_MIN_LARGE]: {
                      paddingBottom: '20px',
                    },
                  }}>
                  <Checkbox
                    label={t(
                      'menuScreen.itemDetails.suggestiveOrdering.allowSpecialPrice',
                    )}
                    info={t(
                      'menuScreen.itemDetails.suggestiveOrdering.allowSpecialPricetoolTip',
                    )}
                    isDisabled={!isEnabled}
                    checked={itemData?.suggestiveOrderingItemPrice !== null}
                    onChange={allowSpecialPrice => {
                      setValue(
                        {
                          suggestiveOrderingItemPrice: allowSpecialPrice
                            ? 0
                            : null,
                        },
                        sectionId,
                        menuTypeVersionId,
                      );
                    }}
                  />
                </Grid.Item>
                <Grid.Item md={12} lg={7} xl={4}>
                  <MoneyInput
                    label={t(
                      'menuScreen.itemDetails.suggestiveOrdering.inputs.suggestionPrice.label',
                    )}
                    placeholder={t(
                      'menuScreen.itemDetails.suggestiveOrdering.inputs.suggestionPrice.placeholder',
                    )}
                    info={t(
                      'menuScreen.itemDetails.suggestiveOrdering.inputs.suggestionPrice.toolTip',
                    )}
                    disabled={
                      !isEnabled ||
                      itemData?.suggestiveOrderingItemPrice === null
                    }
                    value={
                      itemData ? itemData?.suggestiveOrderingItemPrice ?? 0 : 0
                    }
                    onValueChange={price =>
                      setValue(
                        {
                          suggestiveOrderingItemPrice: price,
                        },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    error={!!specialPriceError?.error}
                    caption={specialPriceError?.error}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Box
                    csx={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'flex-end',
                    }}>
                    <TextAreaInput
                      label={t(
                        'menuScreen.itemDetails.suggestiveOrdering.inputs.prompt.label',
                      )}
                      placeholder={t(
                        'menuScreen.itemDetails.suggestiveOrdering.inputs.prompt.placeholder',
                      )}
                      info={t(
                        'menuScreen.itemDetails.suggestiveOrdering.inputs.prompt.toolTip',
                      )}
                      disabled={!isEnabled}
                      value={itemData ? itemData?.suggestiveOrderingPrompt : ''}
                      onChange={e =>
                        setValue(
                          { suggestiveOrderingPrompt: e.target.value },
                          sectionId,
                          menuTypeVersionId,
                        )
                      }
                      error={!!promptError?.error}
                      caption={promptError?.error}
                      onFocus={() => {
                        promptError?.clear();
                      }}
                    />
                    <Button
                      variant="primary"
                      disabled={!isEnabled}
                      onClick={() => {
                        setValue(
                          { suggestiveOrderingPrompt: '' },
                          sectionId,
                          menuTypeVersionId,
                        );
                      }}
                      csx={{ height: '90px', minWidth: '90px' }}>
                      {t('commonButtons.clear')}
                    </Button>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Box>
      </AccordionSection>
    </>
  );
};

export default SuggestiveOrderingSection;
