export const SETTINGS_DICTIONARY: Record<keyof any, string> = {
  register: 'Register Mode',
  lockScreen: 'Lock Screen',
  general: 'General',
  roles: 'Roles',
  registerTicket: 'Register Ticket',
  kitchenTicket: 'Kitchen Ticket',
  prepStations: 'Printer Locations',
  printerRules: 'Printing Rules',
  licenseSpecificPrinterRules: 'License Specific Printing Rules',
  devices: 'Devices',
  licenses: 'Licenses',
  licenseSettings: 'License Settings',
  users: 'User Details',
  taxes: 'Taxes',
};
