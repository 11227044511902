import Card from '@app/components/common/Card';
import TopBar from '../TopBar';
import TableLayoutCreator from '../TableLayoutCreator';

const TableLayoutDetails = () => {
  return (
    <>
      <Card
        csx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          height: '100%',
        }}>
        <TopBar />
        <TableLayoutCreator />
      </Card>
    </>
  );
};

export default TableLayoutDetails;
