import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal/Modal';
import {
  SectionId,
  SubSections,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import React, { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IAddOrUpdatePricingVersion {
  t: WithTranslation['t'];
  pricingVersion: { name: string; id: number } | null;
  selectedPricingVersionId: number;
  setEditablePricingVersion: (value: {
    data: { name: string; id: number } | null;
    mode: 'add' | 'edit';
  }) => void;
  setSelectedPricingVersionId: (currentPricingVersion: number) => void;
  mode: 'add' | 'edit';
  modifier: 'groups' | 'items';
  isDisableDelete?: boolean;
}

const AddOrUpdatePricingVersion = ({
  t,
  pricingVersion,
  selectedPricingVersionId,
  setEditablePricingVersion,
  setSelectedPricingVersionId,
  mode,
  modifier,
  isDisableDelete,
}: IAddOrUpdatePricingVersion) => {
  //Redux
  const {
    removePricingVersionFromModifierGroup,
    removePricingVersionFromModifierItem,
    updateModifierGroup,
    updateModifierItem,
    addPricingVersionForModifierGroup,
    addPricingVersionForModifierItem,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const [data, setData] = React.useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: '',
  });

  useEffect(() => {
    if (pricingVersion) {
      setData({
        id: pricingVersion.id,
        name: pricingVersion.name,
      });
    }
  }, [pricingVersion]);

  const handleCancel = () => {
    setEditablePricingVersion({ data: null, mode });
  };

  const handleUpdatePricingVersion = () => {
    if (modifier === 'groups') {
      updateModifierGroup(
        { name: data.name },
        SectionId.ASSOCIATION,
        SubSections.PRICING_VERSION,
        data.id,
      );
    } else {
      updateModifierItem({ name: data.name }, SectionId.PRICING, data.id);
    }
    setEditablePricingVersion({ data: null, mode });
  };
  const handleAddPricingVersion = () => {
    if (modifier === 'groups') {
      addPricingVersionForModifierGroup(
        selectedPricingVersionId,
        data.name,
        (newPricingVersionsIds: string[]) => {
          setSelectedPricingVersionId(
            Number(newPricingVersionsIds[newPricingVersionsIds.length - 1]),
          );
        },
      );
    } else {
      addPricingVersionForModifierItem(
        selectedPricingVersionId,
        data.name,
        (newPricingVersionsIds: string[]) => {
          setSelectedPricingVersionId(
            Number(newPricingVersionsIds[newPricingVersionsIds.length - 1]),
          );
        },
      );
    }
    setEditablePricingVersion({ data: null, mode });
  };

  const handleOnPressDelete = () => {
    if (modifier === 'groups') {
      removePricingVersionFromModifierGroup(
        data.id,
        (newPricingVersionsIds: string[]) => {
          !newPricingVersionsIds.includes(`${selectedPricingVersionId}`) &&
            setSelectedPricingVersionId(Number(newPricingVersionsIds[0]));
        },
      );
    } else {
      removePricingVersionFromModifierItem(
        data.id,
        (newPricingVersionsIds: string[]) => {
          !newPricingVersionsIds.includes(`${selectedPricingVersionId}`) &&
            setSelectedPricingVersionId(Number(newPricingVersionsIds[0]));
        },
      );
    }

    setEditablePricingVersion({ data: null, mode });
  };

  return (
    <>
      <Modal
        isActive={pricingVersion ? true : false}
        size="650px"
        title={
          mode === 'edit'
            ? t(
                'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.modals.updatePricingVersion',
              )
            : t(
                'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.modals.addPricingVersion',
              )
        }
        onModalClose={handleCancel}
        footer={
          <>
            <Button
              variant="secondary"
              csx={{ width: '80px' }}
              onClick={handleCancel}>
              {t('commonButtons.cancel')}
            </Button>
            <Button
              disabled={data.name.length > 0 ? false : true}
              variant="primary"
              csx={{ width: '80px' }}
              onClick={() => {
                if (mode === 'edit') {
                  handleUpdatePricingVersion();
                } else {
                  handleAddPricingVersion();
                }
              }}>
              {mode === 'edit'
                ? t('commonButtons.save')
                : t('commonButtons.add')}
            </Button>
          </>
        }>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
          <Input
            value={data.name}
            label={
              mode === 'edit'
                ? t(
                    'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.modals.pricingVersionName',
                  )
                : t(
                    'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.modals.pricingVersionName',
                  )
            }
            placeholder="Enter pricing Version Name"
            onChange={e => setData(prev => ({ ...prev, name: e.target.value }))}
          />

          {mode === 'edit' && (
            <>
              <Divider
                direction="vertical"
                csx={{ marginInline: '10px', height: '50px' }}
              />
              <Button
                variant="danger"
                disabled={isDisableDelete}
                onClickDisabled={handleOnPressDelete}
                onClick={handleOnPressDelete}
                csx={{ width: '70px' }}
                icon={<Icon name="MdDeleteForever" size="20px" />}
              />
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AddOrUpdatePricingVersion;
