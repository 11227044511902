import { Theme } from '@emotion/react';

export const massUpdatePriceStyles = (theme: Theme) => ({
  borderRadius: 10,
  border: `1px solid ${theme.colors.lightGrey}`,
  overflow: 'hidden',
  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  '& .content': {
    backgroundColor: theme.colors.lightestGrey,
    padding: '14px 13px',
  },
});
