import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectSpecials } from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IMenuTypeVersion,
  menuItemSpecialsCardFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AssignedElements from '../../../AssignedElements';
import NoMenuTypeLayer from '../../../common/NoMenuTypeLayer';
import { IItemDetailsSection } from '../types';
import { openNewTabWithOrgData } from '@app/helpers/navigation';

const SpecialsSection = ({
  t,
  menuTypeVersionId,
  itemData: itemDataProp,
  sectionId,
  isDisabled,
  ...props
}: IItemDetailsSection) => {
  const { checkForChangesAndNavigateWeb, addBreadcrumbLocationWeb } =
    bindActionCreators(actionCreatorsMenuWeb, useDispatch());

  const specials = useSelector(selectSpecials, shallowEqual);

  const onRefresh = useRefreshMenuWeb();
  const navigate = useNavigate();

  const assignedSpecials = (itemDataProp as IMenuTypeVersion).specials;

  const assignedItemsCardList = useMemo(() => {
    return menuItemSpecialsCardFactory(specials, assignedSpecials).map(
      card => ({
        ...card,
        showStatus: true,
        isActive: card.active,
        onClick: (openInNewTab?: boolean) => {
          const text = card.title;
          const id = card.id;

          const pathURL = `/menu/specials/${id}`;
          if (openInNewTab) return openNewTabWithOrgData(pathURL);

          const _navigate = () =>
            navigate(pathURL, {
              state: { menuTypeId: menuTypeVersionId },
            });
          checkForChangesAndNavigateWeb(
            () =>
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text,
                onPress: _navigate,
                pathURL,
              }),
            onRefresh,
          );
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specials, assignedSpecials, menuTypeVersionId]);

  return (
    <AccordionSection
      id={sectionId}
      title={t('menuScreen.common.bucket.specials.plural')}
      disabledLayer={isDisabled && <NoMenuTypeLayer />}
      {...props}>
      <AssignedElements
        noElementsMessage={t(
          'menuScreen.itemDetails.itemLocation.specials.emptyCardsMessage',
        )}
        elements={assignedItemsCardList}
        subCardProps={{
          title: t('menuScreen.common.bucket.specials.plural'),
          csx: { borderWidth: 0, padding: 0 },
        }}
      />
    </AccordionSection>
  );
};

export default SpecialsSection;
