import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import ButtonLink from '@app/components/common/Button/ButtonLink';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { checkForChangesAndNavigateWeb } from '@app/state/menu/menuWebActions';
import { useTheme } from '@emotion/react';
import {
  BreadCrumbAction,
  MenuTypes,
  PriceCalcModes,
  QuantityModes,
  TPriceCalcModes,
  TQuantityModeTypes,
} from '@westondev/tableturn-core';
import { upperCase } from 'lodash';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

interface INestedModifierGroupCardOptions extends WithTranslation {
  posName: string;
  modifierGroupId: number;
  active: boolean;
  onDeleteModifierGroupPress: (
    modifierGroupId: number,
    name: string,
    active: boolean,
  ) => void;
  menuTypeMode: MenuTypes;
  modifierGroupInfo: {
    quantityMode: TQuantityModeTypes;
    multiselect: boolean;
    priceCalcMode: TPriceCalcModes;
  };
}

const NestedModifierGroupCardHeaderOptions = ({
  posName,
  modifierGroupId,
  menuTypeMode,
  onDeleteModifierGroupPress,
  modifierGroupInfo = {
    multiselect: false,
    priceCalcMode: 'max',
    quantityMode: 'Off',
  },
  t,
  active,
}: INestedModifierGroupCardOptions) => {
  const { multiselect, priceCalcMode, quantityMode } = modifierGroupInfo;

  const dispatch = useDispatch();

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const checkForChangesAndNavigate = bindActionCreators(
    checkForChangesAndNavigateWeb,
    dispatch,
  );

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();
  const theme = useTheme();

  const isMasterMenu = menuTypeMode === MenuTypes.MASTER_MENU;

  const pathURL = `/menu/modifiers/modifier-groups/${modifierGroupId}`;

  return (
    <>
      <Box
        csx={{
          display: 'flex',
          paddingBlock: '10px',
          gap: '15px',
        }}>
        <PillDisplay
          isActive={quantityMode !== QuantityModes.OFF}
          label={t('menuScreen.itemDetails.modifierGroupsSection.quantity')}
          value={
            quantityMode === QuantityModes.OFF
              ? upperCase(quantityMode)
              : quantityMode
          }
        />
        <PillDisplay
          isActive={multiselect}
          label={t('menuScreen.itemDetails.modifierGroupsSection.multiselect')}
          value={multiselect ? 'ON' : 'OFF'}
        />
        <PillDisplay
          isActive={priceCalcMode === PriceCalcModes.MAX}
          label={t('menuScreen.itemDetails.modifierGroupsSection.pricingMode')}
          value={priceCalcMode === PriceCalcModes.MAX ? 'Maximize' : 'Minimize'}
        />
        <Divider
          direction="vertical"
          lineCsx={{ borderColor: theme.colors.lightGrey }}
        />

        <ButtonLink
          to={getPathWithOrgData(pathURL)}
          variant="primary"
          iconPosition="right"
          icon={<Icon name="MdExitToApp" />}
          onClick={(openInNewTab?: boolean) => {
            if (openInNewTab) return openNewTabWithOrgData(pathURL);

            checkForChangesAndNavigate(
              () =>
                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: posName,
                  onPress: () => navigate(pathURL),
                  pathURL,
                }),
              onRefresh,
            );
          }}>
          {t('menuScreen.itemDetails.modifierGroupsSection.editModifier')}
        </ButtonLink>
      </Box>
      <Button
        csx={{
          display: 'flex',
          minWidth: '50px',
          height: '100%',
          borderTopWidth: '0',
          borderRightWidth: '0',
          borderLeftWidth: '1px',
          borderBottomWidth: '0',
          borderRadius: '0',
        }}
        variant={isMasterMenu ? 'danger' : 'primary'}
        onClick={() =>
          onDeleteModifierGroupPress(modifierGroupId, posName, active)
        }
        icon={
          isMasterMenu ? (
            <Icon name="MdDeleteForever" />
          ) : active ? (
            <Icon name="MdVisibility" />
          ) : (
            <Box>
              <Icon name="MdVisibilityOff" color="textWhite" />
              <Typography variant="caption2" color="textWhite">
                {t('commonTexts.hidden')}
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
};

interface IPillDisplay {
  isActive: boolean;
  label: string;
  value: string;
}

const PillDisplay = ({ isActive, label, value }: IPillDisplay) => {
  const theme = useTheme();

  const redLabelAndButton = theme.colors.semanticRed;
  const blueText = theme.colors.semanticBlue;
  const tagBackgroundColor = theme.colors.lighterBlue;
  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
      }}>
      <Box
        csx={{
          display: 'flex',
          borderRadius: '100px',
          minWidth: '10px',
          minHeight: '10px',
          backgroundColor: isActive ? blueText : redLabelAndButton,
        }}
      />
      <Typography csx={{ whiteSpace: 'nowrap' }}>{label}</Typography>
      <Box
        csx={{
          display: 'flex',
          borderRadius: '100px',
          padding: '15px',
          borderWidth: isActive ? '2px' : 0,
          borderColor: isActive ? blueText : redLabelAndButton,
          backgroundColor: isActive ? tagBackgroundColor : redLabelAndButton,

          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant="caption"
          csx={{
            fontWeight: theme.fontWeights.medium,
            color: isActive ? blueText : theme.colors.textWhite,
          }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default NestedModifierGroupCardHeaderOptions;
