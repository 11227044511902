import { DayOfWeek } from '@westondev/tableturn-core';

export const FAKE_OPERATIONAL_DAY_HOURS_DATA = {
  monday: [
    {
      id: 1,
      day: 'monday' as DayOfWeek,
      generalSettingsId: 1,
      menuTypeId: 286,
      startTime: '2024-03-19T05:00:00Z',
      endTime: '2024-03-19T12:00:00Z',
    },
  ],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};
