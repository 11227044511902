import Box from '@app/components/common/Box';
import { forgotPasswordCardStyles } from './styles';
import Typography from '@app/components/common/Typography';
import Input from '@app/components/common/Input';
import Button from '@app/components/common/Button';
import { FormEvent, useState } from 'react';
import AuthCard from '@app/components/Authentication/AuthCard';
import { WithTranslation } from 'react-i18next';
import {
  linkWithText,
  loginAuthBodySeparation,
} from '@app/components/Authentication/styles';
import Link from '@app/components/common/Link';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '@app/state/selectors/appSelectors';
import { sendVerificationCode } from '@app/state/app/actions';
import { useAppDispatch } from '@app/state/store';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = ({ t }: WithTranslation) => {
  //Redux
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
  });

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.email.length > 0) {
      await dispatch(sendVerificationCode(formData.email));
      navigate('verify-code', { state: { email: formData.email } });
    }
  };

  const handleSetFormData = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setFormData(prev => ({ ...prev, email: value, error: '' }));
  };

  return (
    <AuthCard csx={forgotPasswordCardStyles}>
      <Box className="form" csx={loginAuthBodySeparation}>
        <Box csx={{ position: 'relative' }}>
          <Typography variant="heading" fontWeight="medium" className="title">
            {t('authentication.forgotPassword.forgotPassword')}
          </Typography>
          <Typography
            variant="caption"
            className="title"
            csx={{
              position: 'absolute',
            }}>
            {t('authentication.forgotPassword.enterYourEmailToBegin')}
          </Typography>
        </Box>

        <form className="formContent" onSubmit={handleSubmitForm}>
          <Input
            name="email"
            label={t('commonTexts.email')}
            placeholder={t('commonTexts.emailExample')}
            type="email"
            value={formData.email}
            required
            hideRequiredSymbol
            readOnly={isLoading}
            showCaptionSpace
            onChange={handleSetFormData}
          />
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}
            className="formButtons">
            <Button
              type="submit"
              variant="active"
              isLoading={isLoading}
              loadingText={t('authentication.forgotPassword.sendingCode')}>
              {t('authentication.forgotPassword.sendCode')}
            </Button>
            <Box csx={linkWithText}>
              <Typography variant="body" fontWeight="medium" color="darkGrey">
                {t('authentication.forgotPassword.rememberPassword')}
              </Typography>
              <Link to="/auth/sign-in">{t('commonButtons.signIn')}</Link>
            </Box>
          </Box>
        </form>
      </Box>
    </AuthCard>
  );
};

export default ForgotPassword;
