import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import { selectIsMenuMasterMode } from '@app/state/menu/menuSelectors';
import {
  INestedModifierItemModifiers,
  QuantityModes,
  SectionId,
  TQuantityModeTypes,
  actionCreatorsMenu,
  formatMoney,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IOptions extends WithTranslation {
  active: boolean;
  quantityMode: TQuantityModeTypes;
  modifierItemsArray: {
    [modifierItemId: number]: INestedModifierItemModifiers;
  };
  requiredModifierItems: number;
  includedModifierItems: number;
  modifierGroupId: number;
  multiselect: boolean;
  hiddenModifierItemsCount: number;
  totalPrice: number;
}
const generateNumericValuesForDropdown = (max: number) => {
  return Array.from(Array(max).keys()).map(key => {
    return { label: `${key}`, value: key };
  });
};

const NestedModifierGroupOptions = ({
  t,
  active,
  quantityMode,
  requiredModifierItems,
  includedModifierItems,
  modifierGroupId,
  multiselect,
  modifierItemsArray: modifierItems,
  hiddenModifierItemsCount,
  totalPrice,
}: IOptions) => {
  const {
    updateModifierItemModifierGroups: setValue,
    hideModifierGroupFromModifierItem,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const sectionId = SectionId.MODIFIER_GROUPS;

  const isMasterMode = useSelector(selectIsMenuMasterMode);

  const [includedNumericValues, setIncludedNumericValues] = useState(
    generateNumericValuesForDropdown(
      multiselect
        ? quantityMode === QuantityModes.OFF
          ? Object.values(modifierItems).length + 1 - hiddenModifierItemsCount
          : Object.values(modifierItems).length + 1
        : 2,
    ),
  );

  const [prevHiddenCount, setPrevHiddenCount] = useState(0);

  const getSelectedDefault = useMemo(() => {
    if (quantityMode === QuantityModes.NUMBER) {
      return Object.values(modifierItems)
        .filter(item => item.isSelected)
        .reduce(
          (acc, currentValue) => acc + currentValue.defaultQuantitySelected,
          0,
        );
    }
    return Object.values(modifierItems).filter(item => item.isSelected).length;
  }, [modifierItems, quantityMode]);

  useEffect(() => {
    const modifierItemsLength = Object.values(modifierItems).length;
    setIncludedNumericValues(
      generateNumericValuesForDropdown(
        multiselect
          ? quantityMode === QuantityModes.OFF
            ? modifierItemsLength + 1 - hiddenModifierItemsCount
            : modifierItemsLength + 1
          : 2,
      ),
    );

    if (modifierItemsLength === hiddenModifierItemsCount)
      setValue(
        { includedModifierItems: 0, requiredModifierItems: 0 },
        sectionId,
        modifierGroupId,
      );
    else if (
      includedModifierItems >= includedNumericValues.length - 1 &&
      includedNumericValues.length !== 1 &&
      hiddenModifierItemsCount > prevHiddenCount
    ) {
      setValue(
        {
          includedModifierItems: includedNumericValues.length - 2,
          requiredModifierItems: 0,
        },
        sectionId,
        modifierGroupId,
      );
    }
    setPrevHiddenCount(hiddenModifierItemsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenModifierItemsCount]);

  const handleHideModifierGroup = (isActive: boolean) => {
    hideModifierGroupFromModifierItem(modifierGroupId, sectionId, !isActive);
  };

  return (
    <Box csx={{ width: 'inherit', height: 'inherit', position: 'relative' }}>
      <>
        <Box
          csx={{
            display: 'flex',
            opacity: active ? undefined : 0.5,
            padding: '10px 15px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography>
            {t('menuScreen.itemDetails.modifierGroupsSection.selectedDefault')}:{' '}
            {getSelectedDefault}
          </Typography>

          <Divider
            direction="vertical"
            csx={{ height: '45px' }}
            lineCsx={theme => ({ borderColor: theme.colors.lightGrey })}
          />

          <Typography>
            {t('menuScreen.itemDetails.modifierGroupsSection.totalPrice')}:{' '}
            {formatMoney('', totalPrice)}
          </Typography>

          <Divider
            direction="vertical"
            csx={{ height: '45px' }}
            lineCsx={theme => ({ borderColor: theme.colors.lightGrey })}
          />

          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography
              csx={{
                marginRight: '10px',
              }}>
              {t('menuScreen.itemDetails.modifierGroupsSection.required')}:
            </Typography>
            <MoneyInput
              prefix=""
              disabled={!active}
              value={requiredModifierItems}
              precision={0}
              onValueChange={value =>
                setValue(
                  { requiredModifierItems: value },
                  sectionId,
                  modifierGroupId,
                )
              }
              placeholder=""
              width={70}
              maxValue={99}
            />
          </Box>

          <Divider
            direction="vertical"
            csx={{ height: '45px' }}
            lineCsx={theme => ({ borderColor: theme.colors.lightGrey })}
          />

          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography
              csx={{
                marginRight: '10px',
              }}>
              {t('menuScreen.itemDetails.modifierGroupsSection.included')}:
            </Typography>
            <MoneyInput
              prefix=""
              disabled={!active}
              value={includedModifierItems}
              precision={0}
              onValueChange={value =>
                setValue(
                  {
                    includedModifierItems: value,
                    requiredModifierItems:
                      value < requiredModifierItems ? 0 : requiredModifierItems,
                  },
                  sectionId,
                  modifierGroupId,
                )
              }
              placeholder=""
              width={70}
              maxValue={99}
            />
          </Box>

          {isMasterMode && (
            <>
              <Divider
                direction="vertical"
                csx={{ height: '45px' }}
                lineCsx={theme => ({ borderColor: theme.colors.lightGrey })}
              />

              <Checkbox
                isDisabled={!active}
                checked={!active}
                label={t(
                  'menuScreen.itemDetails.modifierGroupsSection.hideModifierGroup',
                )}
                onChange={handleHideModifierGroup}
              />
            </>
          )}
        </Box>
        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />
      </>
    </Box>
  );
};

export default NestedModifierGroupOptions;
