import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IGuestCheckSettings,
  KITCHEN_PRINTER_COLOR_OPTIONS,
  KITCHEN_TICKET_TEXT_POSITION_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  kitchenPrinterColorOptionsFactory,
  textPositionOptionsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestCheckSettings>(SECTION_ID);

const TaxIndicators = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  const kitchenPrinterColorOptions = useMemo(
    () => kitchenPrinterColorOptionsFactory(t),
    [t],
  );

  const textPositionOptions = useMemo(() => textPositionOptionsFactory(t), [t]);

  return (
    <Card.SubCard
      title={t('settingsModule.guestCheckSettings.taxIndicators.title')}
      optionsLeft={
        <Switch
          checked={data ? data?.showTaxIndicator : true}
          onChange={showTaxIndicator =>
            setValue<IGuestCheckSettings>({ showTaxIndicator }, SECTION_ID)
          }
          label={t(
            'settingsModule.guestCheckSettings.taxIndicators.showTaxIndicator',
          )}
        />
      }
      csx={{ height: '100%' }}>
      <Grid rowGap={15} columnGap={15}>
        <Grid.Item mb={12} md={6} lg={3}>
          <Dropdown
            label={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicatorPosition',
            )}
            placeholder={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicatorPosition',
            )}
            data={[textPositionOptions]}
            value={
              KITCHEN_TICKET_TEXT_POSITION_OPTIONS.indexOf(
                data?.taxIndicatorPosition,
              ) + 1 || 0
            }
            onChange={newTaxIndicatorPosition =>
              setValue<IGuestCheckSettings>(
                {
                  taxIndicatorPosition:
                    KITCHEN_TICKET_TEXT_POSITION_OPTIONS[
                      newTaxIndicatorPosition - 1
                    ],
                },
                SECTION_ID,
              )
            }
            isDisabled={!data?.showTaxIndicator}
          />
        </Grid.Item>
        <Grid.Item mb={12} md={6} lg={3}>
          <Dropdown
            label={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicatorColor',
            )}
            placeholder={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicatorColor',
            )}
            data={[kitchenPrinterColorOptions]}
            value={
              KITCHEN_PRINTER_COLOR_OPTIONS.indexOf(data?.taxIndicatorColor) +
                1 || 0
            }
            onChange={newTaxIndicatorColor =>
              setValue<IGuestCheckSettings>(
                {
                  taxIndicatorColor:
                    KITCHEN_PRINTER_COLOR_OPTIONS[newTaxIndicatorColor - 1],
                },
                SECTION_ID,
              )
            }
            isDisabled={!data?.showTaxIndicator}
          />
        </Grid.Item>
        <Grid.Item mb={12} md={6} lg={3}>
          <Input
            label={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicator',
            )}
            placeholder={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxIndicator',
            )}
            value={data?.taxIndicator || ''}
            onChange={taxIndicator =>
              setValue<IGuestCheckSettings>(
                { taxIndicator: taxIndicator.target.value },
                SECTION_ID,
              )
            }
            disabled={!data?.showTaxIndicator}
          />
        </Grid.Item>
        <Grid.Item mb={12} md={6} lg={3}>
          <Input
            label={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxExemptIndicator',
            )}
            placeholder={t(
              'settingsModule.guestCheckSettings.taxIndicators.taxExemptIndicator',
            )}
            value={data?.taxExemptIndicator || ''}
            onChange={taxExemptIndicator =>
              setValue<IGuestCheckSettings>(
                { taxExemptIndicator: taxExemptIndicator.target.value },
                SECTION_ID,
              )
            }
            disabled={!data?.showTaxIndicator}
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default TaxIndicators;
