import {
  NavigateFunction,
  NavigateOptions,
  useParams,
  useNavigate as useReactRouterNavigate,
} from 'react-router-dom';

const useNavigateWithOrg = (includeOrgData = true) => {
  const navigate = useReactRouterNavigate();
  const params = useParams();

  const navigateWithOrg = (path: string, options?: NavigateOptions) => {
    // check if path has / at the beginning
    if (!path.startsWith('/')) {
      path = `/${path}`;
    }
    // Add the orgId and locationId to the paths
    let pathWithOrgData = '';
    if (includeOrgData) {
      const _pathToNavigate = path.split('/');
      if (parseInt(_pathToNavigate[1]) && parseInt(_pathToNavigate[2]))
        pathWithOrgData = path;
      else pathWithOrgData = `/${params.orgId}/${params.locationId}${path}`;
    } else pathWithOrgData = path;

    // Call the original navigate function with the modified path and options
    navigate(pathWithOrgData, options);
  };

  return navigateWithOrg as NavigateFunction;
};

export default useNavigateWithOrg;
