import { ThemeColors } from '@app/theme/types';
import { TCsx, Theme, useTheme } from '@emotion/react';
import { iconStyles } from './styles';
import { ICONS, TIcons } from './types';

export interface IIcon {
  name?: TIcons;
  size?: string;
  color?: ThemeColors;
  csx?: TCsx;
  className?: string;
}

const Icon = ({
  name = 'MdInsertEmoticon',
  size,
  color = 'black',
  csx,
  className,
}: IIcon) => {
  const theme = useTheme();
  const CustomIcon = ICONS[name];

  return (
    <CustomIcon
      color={theme.colors[color as keyof Theme['colors']] || color}
      css={[
        size
          ? {
              width: size,
              height: size,
            }
          : iconStyles,
        csx,
      ]}
      size={size}
      className={className}
    />
  );
};

export default Icon;
