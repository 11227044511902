import Box from '@app/components/common/Box';
import {
  currentTaxesSettingsSelector,
  selectCurrentSettingsData,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import {
  ITableTaxData,
  ITaxesDiff,
  ITaxesSettings,
  diffTaxesFactory,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import TaxesTableDiff from './TaxesTableDiff';
import TaxesTableTypeDiff from './TaxesTableTypeDiff';

const TaxesDiffModal = ({ t }: WithTranslation) => {
  const differences = useSelector(settingsDifferencesSelector);

  const changeData = useSelector(selectCurrentSettingsData) as ITaxesSettings;
  const originalData = useSelector(
    currentTaxesSettingsSelector,
  ) as ITaxesSettings;

  const formattedDifferences = useMemo(() => {
    const diffObject = diffTaxesFactory(differences);
    return diffObject;
  }, [differences]);

  const { taxesDiff, taxTableDiff } = useMemo(() => {
    const tableTypeData = formattedDifferences?.length
      ? formattedDifferences[0]?.changes.find(
          change => change.field === 'tableTypeData',
        )?.value
      : null;

    const ids = Object.keys(tableTypeData || {});

    const tableTypeRows: ITaxesDiff[] = ids.map(id => ({
      action: 'add' as const,
      id: Number(id),
      label: id,
      changes: Object.keys((tableTypeData as any)?.[id] || {}).map(field => ({
        field,
        label: field,
        value: (tableTypeData as any)?.[id][field],
      })),
    }));

    const _taxesDiffs = [];
    const _taxTableDiff = tableTypeRows;
    for (const diff of formattedDifferences ?? []) {
      if (!('taxId' in diff)) {
        const _diff = cloneDeep(diff);

        const typeDataChangeIndex = _diff.changes.findIndex(
          change =>
            change.field === 'typeData' && typeof change.value === 'object',
        );
        if (typeDataChangeIndex !== -1) {
          const typeDataChange = _diff.changes[typeDataChangeIndex];
          _diff.changes.splice(typeDataChangeIndex, 1);

          Object.entries(typeDataChange?.value ?? {}).forEach(
            ([field, value]) => {
              _diff.changes.push({
                field,
                label: field,
                value,
              });
            },
          );
        }

        _taxesDiffs.push(_diff);
      } else {
        const _diff = cloneDeep(diff);
        const dataToFill =
          _diff.action === 'remove' ? originalData : changeData;
        const originalTaxEntry =
          dataToFill[_diff.taxId || 0]?.tableTypeData?.[_diff.id];

        const fields: (keyof ITableTaxData)[] = [
          'from',
          'to',
          'tax',
          'repeat',
          'id',
        ];
        fields.map(field => {
          const value = originalTaxEntry?.[field] ?? '';
          _diff.changes.push({
            field,
            label: field,
            value,
          });
        });

        _taxTableDiff.push(_diff);
      }
    }

    return { taxesDiff: _taxesDiffs, taxTableDiff: _taxTableDiff };
  }, [changeData, formattedDifferences, originalData]);

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.length > 0 && (
            <>
              <HeaderDiffModal name={t('settingsModule.taxesSettings.title')} />

              <TaxesTableDiff taxes={taxesDiff} />

              <Box csx={{ margin: '10px' }} />

              <TaxesTableTypeDiff changes={taxTableDiff} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default TaxesDiffModal;
