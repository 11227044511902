import { css } from '@emotion/react';

export const hiddenSectionStyles = css({
  display: 'flex',
  gap: '15px',
});

export const eyeIconStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingInline: '8px',
  paddingRight: '5px',
});
