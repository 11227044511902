import { RootState } from '@app/state/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Spinner from '../Spinner';
import Typography from '../Typography';
import { ParseKeys } from 'i18next';
import Box from '../Box';
import Icon from '../Icon';

import { loadingModalStyles } from './styles';
import TTLogo from '../TTLogo.tsx';
import {
  ELoadingModalVariants,
  ILoadingModalExtended,
  ILoadingModalProps,
} from './types.ts';
import TTLogoAnimated from '@app/assets/animated/TTLogoAnimated.tsx';
import { logoBackgroundStyles } from '@app/components/AppLoader/styles.ts';
import { displayFlexRowStyles } from '@app/theme/commonStyles.ts';

const LoadingModal = ({
  mode = 'state',
  isLoadingLocal,
}: ILoadingModalProps) => {
  // Redux
  const isLoadingModal = useSelector(
    (state: RootState) => state.app.isLoadingModal as ILoadingModalExtended,
  );
  const t = useTranslation().t;

  const isLocalMode = mode === 'local';

  const isLoadingData =
    mode === 'local' && isLoadingLocal ? isLoadingLocal : isLoadingModal;

  const variant = isLoadingData.variant || ELoadingModalVariants.Loader;
  if (!isLoadingData.active) return null;

  return variant === ELoadingModalVariants.Loader ? (
    <Box csx={loadingModalStyles(mode)}>
      <Box className="spinnerContainer">
        <Spinner
          size={isLocalMode ? '100px' : '170px'}
          strokeWidth="7px"
          color={isLocalMode ? 'lightGrey' : 'textWhite'}
        />
        <Box className="icon">
          {isLoadingData.icon ? (
            <Icon
              name="FaInfo"
              size={isLocalMode ? '20px' : '40px'}
              color={isLocalMode ? 'lightGrey' : 'textWhite'}
            />
          ) : (
            <TTLogo
              withText={false}
              svgProps={{ width: isLocalMode ? '40px' : '70px' }}
              color={isLocalMode ? 'lightGrey' : 'textWhite'}
            />
          )}
        </Box>
      </Box>
      {isLoadingData.message && (
        <Typography
          variant={isLocalMode ? 'body' : 'subtitle'}
          color={isLocalMode ? 'lightGrey' : 'textWhite'}
          fontWeight="medium"
          csx={{ marginTop: '10px' }}>
          {t(isLoadingData.message as ParseKeys)}
        </Typography>
      )}
    </Box>
  ) : (
    <Box csx={loadingModalStyles(mode)}>
      <Box csx={logoBackgroundStyles}>
        <Box
          csx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '40px',
          }}>
          <TTLogoAnimated width="100%" height="120px" animationType="pulse" />
          <Box
            csx={[
              displayFlexRowStyles,
              {
                gap: 8,
                position: 'absolute',
                bottom: 60,
                justifyContent: 'center',
              },
            ]}>
            <Box className="spinnerContainer">
              <Spinner
                size="40px"
                strokeWidth="7px"
                color={isLocalMode ? 'lightGrey' : 'textWhite'}
              />
              {variant === ELoadingModalVariants.RefreshError && (
                <Box className="icon">
                  <Icon
                    name="MdClear"
                    color="persistentSemanticRed"
                    csx={{
                      position: 'absolute',
                      alignSelf: 'center',
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography color="textWhite" variant="subtitle">
              {isLoadingData.message ||
                'Refreshing Organization Data. Please wait'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingModal;
