import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Grid from '@app/components/common/Grid';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import {
  ESpecialApplyTypes,
  IWithMenuTypeVersionId,
  SectionId,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ItemsSection from './ItemsSection';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { upperCase } from 'lodash';

const SECTION_ID = SectionId.ASSOCIATION;
const getMemoizedSpecialData = makeSelectItemData('specials', SECTION_ID);

type ISpecialAssociationsSection = WithTranslation &
  IWithMenuTypeVersionId &
  IAccordionSection;

const SHOW_ITEMS_SECTION_ARRAY = [
  ESpecialApplyTypes.BOGO,
  ESpecialApplyTypes.ITEM,
];

const AssociationsSection = ({
  t,
  menuTypeVersionId,
  ...accordionSectionProps
}: ISpecialAssociationsSection) => {
  // Redux
  const { itemData: specialData } = useSelector(getMemoizedSpecialData);

  // Local state
  const applyTo =
    specialData?.menuTypeVersions?.[menuTypeVersionId]?.specialApplyTo;

  const showItemsSection = SHOW_ITEMS_SECTION_ARRAY.includes(applyTo);

  return (
    showItemsSection && (
      <AccordionSection
        title={
          <Box>
            <Typography>
              {upperCase(t('commonTexts.associated'))}
              {': '}
              <Typography
                csx={{
                  fontStyle: 'italic',
                  display: 'inline',
                }}>
                {t('menuScreen.specialDetails.associations.title')}
              </Typography>
            </Typography>
          </Box>
        }
        {...accordionSectionProps}>
        <Grid rowGap={20}>
          {showItemsSection && (
            <Grid.Item>
              <ItemsSection
                menuTypeVersionId={menuTypeVersionId}
                variant="buy"
                data={specialData}
              />
            </Grid.Item>
          )}
          {applyTo === ESpecialApplyTypes.BOGO && (
            <Grid.Item>
              <ItemsSection
                menuTypeVersionId={menuTypeVersionId}
                variant="get"
                data={specialData}
              />
            </Grid.Item>
          )}
        </Grid>
      </AccordionSection>
    )
  );
};

export default AssociationsSection;
