import ButtonLink from '@app/components/common/Button/ButtonLink';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';

import { WithTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const TagsSettings = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const location = useLocation();
  const specialTagsPath = getPathWithOrgData('/settings/register/special-tags');
  const seatOrderTagsPath = getPathWithOrgData(
    '/settings/register/seat-and-order-tags',
  );
  const modTagsPath = getPathWithOrgData('/settings/register/mod-tags');
  const discountReasonsPath = getPathWithOrgData(
    '/settings/register/discount-reasons',
  );
  const openItemTypesPath = getPathWithOrgData(
    '/settings/register/open-item-types',
  );

  return (
    <>
      <Grid>
        <Grid.Item sm={12}>
          <Grid rowGap={10} columnGap={10}>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.general.specialTags.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography>
                      {t(
                        'settingsModule.registerModeSettings.general.specialTags.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <ButtonLink
                      to={specialTagsPath}
                      variant="primary"
                      onClick={openInNewTab => {
                        if (openInNewTab)
                          return openNewTabWithOrgData(specialTagsPath);

                        navigate(specialTagsPath, { state: location.state });
                      }}>
                      {t(
                        'settingsModule.registerModeSettings.general.specialTags.linkText',
                      )}
                    </ButtonLink>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tags.seatOrderTags.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography>
                      {t(
                        'settingsModule.registerModeSettings.tags.seatOrderTags.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <ButtonLink
                      to={seatOrderTagsPath}
                      variant="primary"
                      onClick={openInNewTab => {
                        if (openInNewTab)
                          return openNewTabWithOrgData(seatOrderTagsPath);

                        navigate(seatOrderTagsPath, {
                          state: location.state,
                        });
                      }}>
                      {t(
                        'settingsModule.registerModeSettings.tags.seatOrderTags.linkText',
                      )}
                    </ButtonLink>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.general.modTags.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography>
                      {t(
                        'settingsModule.registerModeSettings.general.modTags.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <ButtonLink
                      to={modTagsPath}
                      variant="primary"
                      onClick={openInNewTab => {
                        if (openInNewTab)
                          return openNewTabWithOrgData(modTagsPath);

                        navigate(modTagsPath, {
                          state: location.state,
                        });
                      }}>
                      {t(
                        'settingsModule.registerModeSettings.general.modTags.linkText',
                      )}
                    </ButtonLink>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.general.discountReasons.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography>
                      {t(
                        'settingsModule.registerModeSettings.general.discountReasons.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <ButtonLink
                      to={discountReasonsPath}
                      variant="primary"
                      onClick={openInNewTab => {
                        if (openInNewTab)
                          return openNewTabWithOrgData(discountReasonsPath);

                        navigate(discountReasonsPath, {
                          state: location.state,
                        });
                      }}>
                      {t(
                        'settingsModule.registerModeSettings.general.discountReasons.linkText',
                      )}
                    </ButtonLink>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.tags.openItemTypes.title',
                )}>
                <Grid columnGap={20} rowGap={15} csx={{ alignItems: 'center' }}>
                  <Grid.Item mb={12} sm={6} lg={8}>
                    <Typography>
                      {t(
                        'settingsModule.registerModeSettings.tags.openItemTypes.description',
                      )}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item mb={12} sm={6} lg={4}>
                    <ButtonLink
                      to={openItemTypesPath}
                      variant="primary"
                      onClick={openInNewTab => {
                        if (openInNewTab)
                          return openNewTabWithOrgData(openItemTypesPath);

                        navigate(openItemTypesPath, {
                          state: location.state,
                        });
                      }}>
                      {t(
                        'settingsModule.registerModeSettings.tags.openItemTypes.linkText',
                      )}
                    </ButtonLink>
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default TagsSettings;
